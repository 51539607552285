import { ISJArapport, ZSJArapportSchema } from "mc-shared/zod/report/Elektro/sjaSchema";
import * as R from "ramda";
import React, { useEffect } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { useDebounce } from "usehooks-ts";
import { SafeParseReturnType, ZodError } from "zod";
import { IMulticonsultProject, Paths, PathValue } from "../../../../../../../types/global.types";
import { HSpace, VSpace } from "../../../../../components";
import { useAppDispatch } from "../../../../../hooks";
import { doGetSingleMaconomyProject } from "../../reportBoardAPI";
import SJADeltagere from "./SJADeltagere";
import SJAGenerellInfo from "./SJAGenerellInfo";
import SJAOptions from "./SJAOptions";
import { setShowSuccessMessageThunk } from "../../../../../global/globalSlice";

const SJARapport: React.FC<{
  data: ISJArapport;
  onError: (errors: ZodError<ISJArapport>) => void;
  onUpdateEditData: (data: ISJArapport, docxData: ISJArapport) => void;
  projectId?: string;
}> = ({ data, onUpdateEditData, onError, projectId }) => {
  const [editData, setEditData] = React.useState(R.clone(data));
  const [failedToFetchFromMaconomy, setFailedToFetchFromMaconomy] = React.useState<boolean>(false);
  const [dataIsFetchedFromMaconomy, setDataIsFetchedFromMaconomy] = React.useState<boolean>(false);
  const debouncedValue = useDebounce<ISJArapport>(editData, 1000);

  useEffect(() => {
    onUpdateEditData(data, data);
  }, []);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const parsedResult: SafeParseReturnType<ISJArapport, ISJArapport> = ZSJArapportSchema.safeParse(editData);
    if (parsedResult.success === false) {
      onError(parsedResult.error);
    } else {
      onUpdateEditData(editData, editData);
    }
  }, [debouncedValue]);

  React.useEffect(() => {
    setEditData(null);
    setTimeout(() => {
      setEditData(R.clone(data));
      fetchFromMaconomyAndSetData();
    }, 50);
  }, [data]);

  const fetchFromMaconomyAndSetData = async () => {
    try {
      setFailedToFetchFromMaconomy(false);
      const multiconsultProject: IMulticonsultProject = await doGetSingleMaconomyProject(projectId);
      if (multiconsultProject == null) {
        setFailedToFetchFromMaconomy(true);
        return;
      }
      dispatch(setShowSuccessMessageThunk("Hentet data fra maconomy"));
      let updatedData = R.clone(editData);
      updatedData.generellInfo.oppdragsNummer = String(multiconsultProject.ID);
      updatedData.generellInfo.oppdragsNavn = multiconsultProject.DisplayName;
      setEditData({
        ...editData,
        ...updatedData,
      });
      setDataIsFetchedFromMaconomy(true);
    } catch (error) {
      setFailedToFetchFromMaconomy(true);
    }
  };

  const updateData = <P extends Paths<ISJArapport, 4>>(path: P, value: PathValue<ISJArapport, P>) => {
    setEditData((prevState) => {
      const updatedData = R.assocPath(path.split("."), value, prevState);
      return updatedData;
    });
  };

  return (
    <div style={{ height: "100%", width: "1800px" }}>
      {editData != null && dataIsFetchedFromMaconomy && (
        <>
          <div className="d-flex" style={{ width: "1800px" }}>
            <SJAGenerellInfo editData={editData} updateData={updateData} />
            <HSpace />
            <SJADeltagere editData={editData} updateData={updateData} />
          </div>
          <VSpace />
          <Card>
            <CardBody>
              <CardTitle tag="h4" style={{ marginBottom: "16px" }}>
                Huskeliste
              </CardTitle>
              <h5>
                Før vi skal ut av kontoret (befaring, test etc.) skal vi utarbeide en skriftlig SJA. Her er stegene i
                prioritert rekkefølge for dette:
              </h5>
              <ol className="pl-4">
                <li>Snakk med kunden på forhånd - hvilke HMS/SHA instrukser gjelder, og hvem har ansvar.</li>
                <li>Les vår feltinstruks.</li>
                <li>SJA fylles ut i MCApps - se veiledning på wiki (Wiki-side om utarbeidelse av SJA).</li>
                <li>Oversend SJA til kunden.</li>
                <li>Ta med utfylt SJA på plassen - revider hvis noe er endret.</li>
                <li>Alltid ta en gjennomgang av SJA på plassen før man starter med befaring/test etc.</li>
                <li>Hvis det er endringer - Ta TO!</li>
              </ol>
              <p style={{ marginTop: "16px" }}>
                For mer informasjon, se{" "}
                <a
                  href="https://multiconsultas.sharepoint.com/sites/KNVElektroteknikk13/SitePages/HMS-og-FSE.aspx"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  SJA-tetningslinjer
                </a>
                .
              </p>
            </CardBody>
          </Card>
          <VSpace />
          <SJAOptions editData={editData} updateData={updateData} />
          <div style={{ height: "200px" }} />
        </>
      )}
    </div>
  );
};

export default SJARapport;
