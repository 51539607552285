import { IGKLufthastighetmaalingIArbeisomraadeResultater, IGKRapport } from "mc-shared/zod/report/GK/gkReportSchema";
import React, { useEffect } from "react";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../../types/global.types";
import { HSpace, McInput } from "../../../../../../components";
import ReportDocxImageContainer from "../../../ReportBoard/ReportDocxImageContainer";
import { beregnetForholdMellomToVerdier, beregnetGjennomsnittligLufthastighetsMaaling } from "../gkGlobalUtils";

const LufthastighetsmaalingIArbeidsomraade: React.FC<{
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
  data: IGKLufthastighetmaalingIArbeisomraadeResultater;
}> = ({ data, onUpdateEditData }) => {
  useEffect(() => {
    utdataBeregninger();
  }, [data.inndata.maaleresultater, data.inndata.maaleresultater.length]);

  useEffect(() => {
    updateMaaleresultaterForholdstall();
  }, [data.utdata.gjennomsnittligHastighet]);

  const addNewRow = () => {
    const maaleresultaterArray = data.inndata.maaleresultater;
    maaleresultaterArray.push({
      maaleresultat: null,
      forholdMellomMaaleresultatOgGjennomsnittshastighet: null,
    });

    onUpdateEditData(
      "testResultater.lufthastighetsMaalingIArbeidsomraade.inndata.maaleresultater",
      maaleresultaterArray,
    );

    return maaleresultaterArray;
  };

  const removeLastRow = () => {
    const updatedMaaleresultater = data.inndata.maaleresultater;

    if (updatedMaaleresultater.length > 0) {
      updatedMaaleresultater.pop(); // Remove the last element
      onUpdateEditData(
        "testResultater.lufthastighetsMaalingIArbeidsomraade.inndata.maaleresultater",
        updatedMaaleresultater,
      );
    }
  };

  const updateMaaleresultater = (resultat: string | number, i: number, parameter: string) => {
    const maaleresultater = [...data.inndata.maaleresultater];
    maaleresultater[i][parameter] = resultat;
    maaleresultater[i].forholdMellomMaaleresultatOgGjennomsnittshastighet = beregnetForholdMellomToVerdier(
      maaleresultater[i].maaleresultat,
      data.utdata.gjennomsnittligHastighet,
    );
    onUpdateEditData("testResultater.lufthastighetsMaalingIArbeidsomraade.inndata.maaleresultater", maaleresultater);
  };

  const updateMaaleresultaterForholdstall = () => {
    const maaleresultater = [...data.inndata.maaleresultater];
    maaleresultater.forEach((resultat) => {
      resultat.forholdMellomMaaleresultatOgGjennomsnittshastighet = beregnetForholdMellomToVerdier(
        resultat.maaleresultat,
        data.utdata.gjennomsnittligHastighet,
      );
    });
    onUpdateEditData("testResultater.lufthastighetsMaalingIArbeidsomraade.inndata.maaleresultater", maaleresultater);
  };

  const utdataBeregninger = () => {
    const gjennomsnittligHastighet = beregnetGjennomsnittligLufthastighetsMaaling(
      data.inndata.maaleresultater.map((resultat) => resultat.maaleresultat),
    );
    const utdata = {
      gjennomsnittligHastighet: gjennomsnittligHastighet,
    };
    onUpdateEditData("testResultater.lufthastighetsMaalingIArbeidsomraade.utdata", utdata);
  };

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Lufthastighetsmåling i arbeidsområde</CardTitle>
        <>
          <div className="d-flex flex-column mt-2">
            <b>Måletiden er 60 sekunder &#40;s&#41;:</b>
          </div>
          <div className="mt-2">
            <b>Legg til flere punkter ved behov:</b>
            <div className="d-flex">
              <Button color="success" onClick={addNewRow}>
                Legg til rad
              </Button>
              <HSpace />
              <Button color="danger" onClick={removeLastRow} disabled={data.inndata.maaleresultater.length < 9}>
                Fjern siste rad
              </Button>
            </div>
          </div>
          <div className="mt-4 d-flex flex-column">
            <b>Fyll inn måleresultater &#40;m/s&#41;:</b>
            {data.inndata.maaleresultater.map((resultat, i) => (
              <McInput
                key={i}
                value={resultat.maaleresultat}
                normalizeNumbers={true}
                type="text"
                onBlur={(e) => updateMaaleresultater(Number(e.target.value), i, "maaleresultat")}
                placeholder="Skriv inn maaleresultat"
                className="w-25 mb-1"
              />
            ))}
          </div>
          <div className="d-flex justify-content-between">
            <p className="mb-0">
              Gjennomsnittlig hastighet &#40;m/s&#41; =
              <b>
                {" "}
                {beregnetGjennomsnittligLufthastighetsMaaling(
                  data.inndata.maaleresultater.map((resultat) => resultat.maaleresultat),
                )?.toFixed(2) ?? ""}
              </b>
            </p>
            <p className="mb-0">Formel: &#40;Va = &#40;SUM Vn&#41; / N&#41;</p>
          </div>
          <hr className="mt-0" />
          <div className="d-flex flex-column mt-2">
            <b>Fyll inn display-verdi &#40;m/s&#41;:</b>
            <McInput
              value={data.inndata.displayVerdi}
              normalizeNumbers={true}
              type="text"
              onBlur={(e) =>
                onUpdateEditData(
                  "testResultater.lufthastighetsMaalingIArbeidsomraade.inndata.displayVerdi",
                  Number(e.target.value),
                )
              }
              className="w-25"
            />
          </div>
          <div className="w-50 mt-4">
            <div>
              <ReportDocxImageContainer
                docxImageTag="lufthastighetsMaalingIArbeidsomraade"
                title="Bilde av luftstrøm"
              />
            </div>
            <b>Kommentar:</b>
            <McInput
              value={data.inndata.kommentar}
              onChange={(e) =>
                onUpdateEditData(
                  "testResultater.lufthastighetsMaalingIArbeidsomraade.inndata.kommentar",
                  e.target.value,
                )
              }
              type="textarea"
              dynamicHeight={true}
            />
          </div>
        </>
      </CardBody>
    </Card>
  );
};

export default LufthastighetsmaalingIArbeidsomraade;
