import update from "immutability-helper";
import {
  IColumnTemplate,
  IDocxTemplate,
  IRisiko,
  IRisikoEvaluationCategory,
  IRiskoBoard,
} from "../../../../../types/global.types";
import {
  ADD_RISIKO,
  DELETE_RISIKO,
  RESET_RISIKO,
  SET_ACTIVE_RISIKO_ACTION_ID,
  SET_ACTIVE_RISIKO_BOARD,
  SET_ACTIVE_RISIKO_TAB,
  SET_ACTIVE_VIEW_RISIKO,
  SET_EVALUATIONBAR_OPEN,
  SET_FILTRED_BY_CHART_OR_MATRIX_RISIKO_IDS,
  SET_GIS_WAS_LOADED,
  SET_GLOBAL_SEARCH_STRING,
  SET_RISIKO_BOARDS,
  SET_RISIKO_CATEGORIES,
  SET_RISIKO_DOCX_TEMPLATES,
  SET_RISIKO_ITEMS,
  SET_RISIO_BOARD_TEMPLATES,
  SET_RISKO_COLUMN_TEMPLATE,
  SET_SELECTED_RISIKO,
  SET_SELECTED_RISIKO_GIS_MAP,
  UPDATE_RISIKO_ITEM,
} from "./constants";
import { ISelectedGisMap } from "../../../types";
import { ITableTemplate } from "mc-shared/zod/issueSchema";

export enum RisikoViewEnum {
  TABLE = "TABLE",
  GIS = "GIS",
}

export enum RisikoItemTabEnum {
  INFO = "Info",
  LOG = "Logg",
  ACTIONS = "Tiltak & Statuslogger",
  FILES = "Filer",
  GIS = "GIS",
}

export interface ISelectedRisiko {
  _id: string;
  activeItemTab: RisikoItemTabEnum;
}

export interface IRisikoReducer {
  items: IRisiko[];
  filtredItems: IRisiko[];
  categories: IRisikoEvaluationCategory[];
  selectedRisiko: ISelectedRisiko;
  activeTableTemplate: ITableTemplate;
  boards: IRiskoBoard[];
  activeBoard: IRiskoBoard;
  activeTab: string;
  evaluationBarOpen: boolean;
  activeColumnTemplate: IColumnTemplate;
  filtredByChartOrMatrixRiskoIds: string[];
  activeRisikoActionId: string;
  globalSearchString: string;
  activeView: RisikoViewEnum;
  gisWasLoaded: boolean;
  selectedGisMap: ISelectedGisMap;
  docxTemplates: IDocxTemplate[];
  risikoBoardTemplates: IRiskoBoard[];
}

const initialSettings = {
  items: null,
  selectedRisiko: null,
  activeTableTemplate: null,
  categories: null,
  boards: null,
  activeBoard: null,
  activeTab: "RISIKOS",
  evaluationBarOpen: false,
  activeColumnTemplate: null,
  filtredByChartOrMatrixRiskoIds: [],
  activeRisikoActionId: null,
  globalSearchString: "",
  activeView: RisikoViewEnum.TABLE,
  gisWasLoaded: false,
  selectedGisMap: null,
  docxTemplates: null,
  risikoBoardTemplates: null,
};

const risikoReducer = (state = initialSettings, action) => {
  switch (action.type) {
    case SET_RISIKO_ITEMS:
      return { ...state, items: action.payload.items };
    case UPDATE_RISIKO_ITEM:
      return update(state, {
        items: { [action.payload.itemIndex]: { $set: action.payload.item } },
      });
    case ADD_RISIKO: {
      return update(state, { items: { $push: [action.payload.item] } });
    }
    case SET_SELECTED_RISIKO:
      return { ...state, selectedRisiko: action.payload.selectedRisiko };
    case DELETE_RISIKO:
      return {
        ...state,
        items: update(state.items, { $splice: [[action.payload.index, 1]] }),
      };
    case SET_RISIKO_CATEGORIES:
      return { ...state, categories: action.payload.categories };
    case SET_RISIKO_BOARDS:
      return { ...state, boards: action.payload.boards };
    case SET_ACTIVE_RISIKO_BOARD:
      return { ...state, activeBoard: action.payload.activeBoard };
    case SET_ACTIVE_RISIKO_ACTION_ID:
      return { ...state, activeRisikoActionId: action.payload.activeRiskoActionId };
    case SET_ACTIVE_RISIKO_TAB:
      return { ...state, activeTab: action.payload.activeTab };
    case SET_EVALUATIONBAR_OPEN:
      return { ...state, evaluationBarOpen: action.payload.evaluationBarOpen };
    case SET_RISKO_COLUMN_TEMPLATE:
      return { ...state, activeColumnTemplate: action.payload.activeColumnTemplate };
    case SET_FILTRED_BY_CHART_OR_MATRIX_RISIKO_IDS:
      return { ...state, filtredByChartOrMatrixRiskoIds: action.payload.filtredByChartOrMatrixRiskoIds };
    case SET_GLOBAL_SEARCH_STRING:
      return { ...state, globalSearchString: action.payload.globalSearchString };
    case SET_ACTIVE_VIEW_RISIKO: {
      return { ...state, activeView: action.payload.activeView };
    }
    case SET_GIS_WAS_LOADED: {
      return { ...state, gisWasLoaded: action.payload.bool };
    }
    case SET_SELECTED_RISIKO_GIS_MAP: {
      return { ...state, selectedGisMap: action.payload };
    }
    case SET_RISIKO_DOCX_TEMPLATES: {
      return { ...state, docxTemplates: action.payload };
    }
    case SET_RISIO_BOARD_TEMPLATES: {
      return { ...state, risikoBoardTemplates: action.payload };
    }
    case RESET_RISIKO:
      return initialSettings;
    default:
      return state;
  }
};

export default risikoReducer;
