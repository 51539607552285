import { IDisciplin } from "../../../../../types/global.types";

export const DISCIPLINER: IDisciplin[] = [
  {
    short: "ARK",
    long: "Arkitekt",
  },
  {
    short: "LARK",
    long: "Landskapsarkitekt",
  },
  {
    short: "PLAN",
    long: "Arealplanleggere",
  },
  {
    short: "SHA",
    long: "Sikkerhet Helse og Arbeidsmiljø",
  },
  {
    short: "RIA",
    long: "Rådgivende ingeniør Akustikk",
  },
  {
    short: "RIAkva",
    long: "Rådgivende ingeniør Akvateknikk",
  },
  {
    short: "RIB",
    long: "Rådgivende ingeniør Bygg",
  },
  {
    short: "RIBko",
    long: "Rådgivende ingeniør Konstruksjoner",
  },
  {
    short: "RIBfy",
    long: "Rådgivende ingeniør Bygningsfysikk",
  },
  {
    short: "RIBr",
    long: "Rådgivende ingeniør Brann",
  },
  {
    short: "RIE",
    long: "Rådgivende ingeniør Elektroteknikk",
  },
  {
    short: "RIEaut",
    long: "Rådgivende ingeniør ITB & Automasjon",
  },
  {
    short: "RIElys",
    long: "Rådgivende ingeniør Lysdesign",
  },
  {
    short: "RIEn",
    long: "Rådgivende ingeniør Energi",
  },
  {
    short: "RIEsv",
    long: "Rådgivende ingeniør IKT & Sikkerhet",
  },
  {
    short: "RIFDV",
    long: "Rådgivende Ingeniør FDV",
  },
  {
    short: "RIG",
    long: "Rådgivende ingeniør Geoteknikk",
  },
  {
    short: "RIGberg",
    long: "Rådgivende ingeniør GEO, Bergteknikk",
  },
  {
    short: "RIGbes",
    long: "Rådgivende ingeniør GEO, Besiktigelser",
  },
  {
    short: "RIGm",
    long: "Rådgivende ingeniør GEO, Miljøgeologi",
  },
  {
    short: "RIGvib",
    long: "Rådgivende ingeniør GEO, Måleteknikk",
  },
  {
    short: "RIJ",
    long: "Rådgivende ingeniør Jernbane",
  },
  {
    short: "RILU",
    long: "Rådgivende ingeniør Luftkvalitet",
  },
  {
    short: "RIM",
    long: "Rådgivende ingeniør Ytre Miljø",
  },
  {
    short: "RIVei",
    long: "Rådgivende ingeniør Vei",
  },
  {
    short: "RIVA",
    long: "Rådgivende ingeniør Vann og avløp",
  },
  {
    short: "RIV",
    long: "Rådgivende ingeniør VVS",
  },
  {
    short: "PGK",
    long: "Prosjekteringsgruppe koordinator",
  },
  {
    short: "Kunde",
    long: "Kunde",
  },
  {
    short: "Annet",
    long: "Annet",
  },
];
