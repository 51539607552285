import { IRisiko } from "../../../../../types/global.types";
import { stringExistOnAnyProperty } from "../../utils";
import { IViewPoint } from "../../../types";
import { IRootState } from "../../../store";

export const getRisikosFiltredInChart = (state: IRootState): IRisiko[] => {
  if (state.risikoReducer.filtredByChartOrMatrixRiskoIds.length === 0) {
    return state.risikoReducer.items;
  } else {
    return state.risikoReducer.items.filter(
      (item) => state.risikoReducer.filtredByChartOrMatrixRiskoIds.indexOf(item._id) !== -1,
    );
  }
};

export const getRisikosIdsFiltredOnGlobalSearch = (state: IRootState): string[] => {
  const { items, globalSearchString } = state.risikoReducer;
  if (globalSearchString === "") {
    return null;
  } else {
    return items.reduce((acc, risko) => {
      if (stringExistOnAnyProperty(risko, globalSearchString)) {
        acc.push(risko._id);
      }
      return acc;
    }, []);
  }
};

export const getRisikoWithGisViewPoints = (state: IRootState): IViewPoint[] => {
  const { items } = state.risikoReducer;

  const givenRisikosWithGisPoints = items.filter((risiko) => {
    return risiko.gis?.gisPoint?.x != null;
  });

  return givenRisikosWithGisPoints.map((risiko) => {
    return {
      _id: risiko._id,
      gis: risiko.gis,
      title: risiko.title,
      status: {
        title: risiko.state,
        color: "blue",
      },
    };
  });
};
