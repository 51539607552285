import React from "react";
import { connect } from "react-redux";
import { IRootState } from "../store";

const FailedMessage: React.FC<any> = ({ failedMessage }: { failedMessage: { show: boolean; text: string } }) => (
  <>
    {failedMessage.text !== "" && (
      <div
        style={{
          position: "fixed",
          backgroundColor: "rgba(243, 65, 65, 0.82)",
          color: "white",
          bottom: 0,
          opacity: failedMessage.show ? 1 : 0,
          transition: "opacity 0.5s",
          boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
          right: 0,
          margin: "25px",
          padding: "15px",
          borderRadius: "6px",
          width: "400px",
          wordWrap: "break-word",
          fontSize: "16px",
          fontWeight: "bold",
          zIndex: 5000001,
          backdropFilter: "blur(4px)",
        }}
      >
        <div className="d-flex">
          <div className="d-flex align-items-center mr-3">
            <i className="fa fa-check-circle" style={{ fontSize: "45px" }} />
          </div>
          <div>
            <h4 style={{ color: "white", fontWeight: "bold" }}>Mislykket!</h4>
            {failedMessage.text?.split("\n").map((row: string, i: number) => (
              <div key={i}>{row}</div>
            ))}
          </div>
        </div>
      </div>
    )}
  </>
);

const mapStateToProps = (state: IRootState) => ({
  failedMessage: state.globalReducer.failedMessage,
});

export default connect(mapStateToProps, {})(FailedMessage);
