import Axios from "axios";
import saveAs from "file-saver";
import fileDownload from "js-file-download";
import { IPostDocxBody } from "mc-shared/zod/issueSchema";
import {
  IBasicItem,
  IDocxTemplate,
  IGisPointAndCamera,
  IIssue,
  IIssueUserStat,
  ITag,
  taskOrIssueENUM,
} from "../../../../../types/global.types";
import { formatShortDate } from "../admin/selectors";

export const doCreateIssueDepricated = async (
  projectId: string,
  issueBoardId: string,
  issueTitle: string,
  taskOrIssue: taskOrIssueENUM,
  issueIdIfItIsATask?: string,
  tags?: string[],
  connectedMeeting?: string,
  clientAccess?: boolean,
  gis?: IGisPointAndCamera,
  gisImage?: string,
  connectedChecklistItem?: string,
  connectedRisiko?: string,
  connectedMeetingAgendaItem?: string,
): Promise<IIssue> => {
  try {
    const createdIssueFetch = await Axios({
      method: "POST",
      url: `/api/project/${projectId}/issue/board/${issueBoardId}/type/${taskOrIssue}`,
      data: {
        title: issueTitle,
        issueId: issueIdIfItIsATask,
        tags,
        connectedMeeting,
        clientAccess,
        gis,
        gisImage,
        connectedChecklistItem,
        connectedRisiko,
        connectedMeetingAgendaItem,
      },
    });
    return createdIssueFetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doFetchUniqIssueTagsOnBoardDepricated = async (
  projectId: string,
  issueBoardId: string,
): Promise<ITag[]> => {
  try {
    const tagsFetch = await Axios.get(`/api/project/${projectId}/issue/board/${issueBoardId}/tags`);
    return tagsFetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doDeleteMemberFromProject = async (projectId: string, memberId: string) => {
  try {
    const resp = await Axios.delete(`/api/project/${projectId}/member/${memberId}`);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetIssuesStartinWithPns = async (projectId: string, pns: string): Promise<IIssue[]> => {
  try {
    const fetch = await Axios.get(`/api/project/${projectId}/issue/start-with-pns/${pns}`);
    return fetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetUsersIssueStats = async (projectId: string): Promise<IIssueUserStat[]> => {
  try {
    const fetch = await Axios.get(`/api/project/${projectId}/issue/users-issue-stats`);
    return fetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetIssueDocxTemplates = async (projectId: string): Promise<IDocxTemplate[]> => {
  try {
    const fetch = await Axios.get(`/api/project/${projectId}/issue/docxtemplates`);
    return fetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doDownloadIssueDocxTemplate = async (projectId: string, docxTemplate: IDocxTemplate) => {
  try {
    const response = await Axios({
      url: `/api/project/${projectId}/issue/download-docx-template`,
      method: "PUT",
      responseType: "blob",
      data: { docxTemplate },
    });
    fileDownload(response.data, docxTemplate.fileName);
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export interface IGenerateIssueDocxArgs {
  params: {
    projectId: string;
  };
  body: IPostDocxBody;
}

export const doGenerateIssueDocx = async (args: IGenerateIssueDocxArgs) => {
  try {
    const file = await Axios.post(
      `/api/project/${args.params.projectId}/issue/generate-docx`,
      { ...args.body },
      {
        responseType: "arraybuffer",
      },
    );
    const blob = new Blob([file.data], { type: "application/x-msdownload" });

    saveAs(blob, `saker-${formatShortDate(new Date())}.docx`);
  } catch (error) {
    const decoder = new TextDecoder();
    const message = decoder.decode(error.response?.data as ArrayBuffer);
    return Promise.reject({ type: "backend", message });
  }
};

export const doUploadIssueDocxTemplate = async (projectId: string, event: React.ChangeEvent<HTMLInputElement>) => {
  try {
    const data = new FormData();
    data.append("fileToUpload", event.target.files[0]);
    await Axios.request({
      method: "POST",
      url: `/api/project/${projectId}/issue-docx-template.add`,
      data,
      headers: {
        "content-type": `multipart/form-data`,
      },
    });
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetBasicIssueInfo = async (projectId: string, boardId: string): Promise<IBasicItem[]> => {
  try {
    const fetch = await Axios.get(`/api/project/${projectId}/issue/board/${boardId}/basic`);
    return fetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};
