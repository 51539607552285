import {
  IGKPartikkelTellingEuGmpOptions,
  IGKPartikkelTellingForUtstyrResultater,
  IGKRapport,
} from "mc-shared/zod/report/GK/gkReportSchema";
import * as R from "ramda";
import { useEffect } from "react";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../../../types/global.types";
import { HSpace, McDropdown, McInput } from "../../../../../../../components";
import ReportDocxImageContainer from "../../../../ReportBoard/ReportDocxImageContainer";
import { EU_GMP_KLASSER } from "./partikkelTellingTabeller";
import {
  beregnetMinimumsvolumPerProevetaking,
  beregnetVolumPerProevetaking,
  euGmpTabellverdi,
  PARTIKKELSTOERRELSER_GMP,
  sammenlignetVerdiMellomKonsentrasjonOgKlassegrensenivaa,
} from "./partikkelTellingUtils";

const PartikkelTellingForUtstyr: React.FC<{
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
  data: IGKPartikkelTellingForUtstyrResultater;
}> = ({ data, onUpdateEditData }) => {
  useEffect(() => {
    utdataBeregninger();
  }, [
    data.inndata.maaleresultater,
    data.inndata.maalehasighetTilPartikkelteller,
    data.inndata.maaletid,
    data.inndata.euGmpKlasse,
    data.inndata.maaletid,
  ]);

  const ROMAKTIVITET = "At rest";

  const addNewRow = () => {
    const newTilleggsRomResultat = {
      maalepunktNr: "",
      maaleresultat: null,
      sammenligningMellomKonsentrasjonOgKlassegrensenivaa: null,
    };

    const updatedMaaleresultater = {
      ...data.inndata.maaleresultater,
      size05: [...data.inndata.maaleresultater.size05, newTilleggsRomResultat],
      size5: [...data.inndata.maaleresultater.size5, newTilleggsRomResultat],
    };

    onUpdateEditData("testResultater.partikkelTellingForUtstyr.inndata.maaleresultater", updatedMaaleresultater);

    return updatedMaaleresultater;
  };

  const removeLastRow = () => {
    const updatedMaaleresultater = {
      ...data.inndata.maaleresultater,
      size05: [...data.inndata.maaleresultater.size05],
      size5: [...data.inndata.maaleresultater.size5],
    };
    if (updatedMaaleresultater.size05.length > 0) {
      updatedMaaleresultater.size05.pop(); // Remove the last element from size05
    }
    if (updatedMaaleresultater.size5.length > 0) {
      updatedMaaleresultater.size5.pop(); // Remove the last element from size5
    }
    onUpdateEditData("testResultater.partikkelTellingForUtstyr.inndata.maaleresultater", updatedMaaleresultater);
  };

  const updateMaaleresultater = (
    resultat: string | number,
    i: number,
    parameter: string,
    partikkelstoerrelse: string,
  ) => {
    const maaleresultater = R.clone(data.inndata.maaleresultater);
    maaleresultater[partikkelstoerrelse] = R.clone(data.inndata.maaleresultater[partikkelstoerrelse]);
    maaleresultater[partikkelstoerrelse][i][parameter] = resultat;
    if (partikkelstoerrelse === PARTIKKELSTOERRELSER_GMP[0].value) {
      maaleresultater[partikkelstoerrelse][i].sammenligningMellomKonsentrasjonOgKlassegrensenivaa =
        sammenlignetVerdiMellomKonsentrasjonOgKlassegrensenivaa(
          maaleresultater[partikkelstoerrelse][i].maaleresultat,
          euGmpTabellverdi(PARTIKKELSTOERRELSER_GMP[0].label, data.inndata.euGmpKlasse, ROMAKTIVITET),
        );
    }
    if (partikkelstoerrelse === PARTIKKELSTOERRELSER_GMP[1].value) {
      maaleresultater[partikkelstoerrelse][i].sammenligningMellomKonsentrasjonOgKlassegrensenivaa =
        sammenlignetVerdiMellomKonsentrasjonOgKlassegrensenivaa(
          maaleresultater[partikkelstoerrelse][i].maaleresultat,
          euGmpTabellverdi(PARTIKKELSTOERRELSER_GMP[1].label, data.inndata.euGmpKlasse, ROMAKTIVITET),
        );
    }
    onUpdateEditData("testResultater.partikkelTellingForUtstyr.inndata.maaleresultater", maaleresultater);
  };

  const utdataBeregninger = () => {
    const volumPerProevetaking = beregnetVolumPerProevetaking(
      data.inndata.maalehasighetTilPartikkelteller,
      data.inndata.maaletid,
    );
    const klasseGrenseNivaaEUGMP_05 = euGmpTabellverdi(
      PARTIKKELSTOERRELSER_GMP[0].label,
      data.inndata.euGmpKlasse,
      ROMAKTIVITET,
    );
    const klasseGrenseNivaaEUGMP_5 = euGmpTabellverdi(
      PARTIKKELSTOERRELSER_GMP[1].label,
      data.inndata.euGmpKlasse,
      ROMAKTIVITET,
    );
    const minimumsvolumPerProevetakingEUGMP_05 = beregnetMinimumsvolumPerProevetaking(klasseGrenseNivaaEUGMP_05);
    const minimumsvolumPerProevetakingEUGMP_5 = beregnetMinimumsvolumPerProevetaking(klasseGrenseNivaaEUGMP_5);
    const utdata = {
      volumPerProevetaking: volumPerProevetaking,
      minimumsvolumPerProevetakingEUGMP_05: minimumsvolumPerProevetakingEUGMP_05,
      minimumsvolumPerProevetakingEUGMP_5: minimumsvolumPerProevetakingEUGMP_5,
      klasseGrenseNivaaEUGMP_05: klasseGrenseNivaaEUGMP_05,
      klasseGrenseNivaaEUGMP_5: klasseGrenseNivaaEUGMP_5,
    };
    onUpdateEditData("testResultater.partikkelTellingForUtstyr.utdata", utdata);
  };

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Partikkeltelling for utstyr</CardTitle>
        <b>Velg EU-GMP-klasse &#40;-&#41;:</b>
        <div className="mb-4" style={{ width: "100px" }}>
          <McDropdown
            currentValue={data.inndata.euGmpKlasse}
            onChange={(_, item) =>
              onUpdateEditData(
                "testResultater.partikkelTellingForUtstyr.inndata.euGmpKlasse",
                item as IGKPartikkelTellingEuGmpOptions,
              )
            }
            defaultTitle="Velg EU-GMP-klasse"
            options={EU_GMP_KLASSER.map((klasse) => klasse.label)}
          />
        </div>
        <div className="d-flex flex-column mt-4">
          <b>Fyll inn målehastigheten til partikkeltelleren &#40;l/s&#41;:</b>
          <McInput
            value={data.inndata.maalehasighetTilPartikkelteller}
            onBlur={(e) =>
              onUpdateEditData(
                "testResultater.partikkelTellingForUtstyr.inndata.maalehasighetTilPartikkelteller",
                Number(e.target.value),
              )
            }
            normalizeNumbers={true}
            type="text"
            className="w-25"
          />
        </div>
        <div className="d-flex flex-column mt-4">
          <b>Fyll inn måletiden &#40;s&#41;:</b>
          <McInput
            value={data.inndata.maaletid}
            onBlur={(e) =>
              onUpdateEditData("testResultater.partikkelTellingForUtstyr.inndata.maaletid", Number(e.target.value))
            }
            normalizeNumbers={true}
            type="text"
            className="w-25"
            min={60}
          />
        </div>
        <div className="d-flex justify-content-between">
          <p className="mb-0">
            Prøvetakingsvolum&#40;l&#41; ={" "}
            <b>
              {beregnetVolumPerProevetaking(
                data.inndata.maalehasighetTilPartikkelteller,
                data.inndata.maaletid,
              )?.toFixed(2) ?? ""}
            </b>
          </p>
          <p className="mb-0">Formel: &#40;Vs = Ps * tm&#41;</p>
        </div>
        <hr className="mt-0" />
        <div className="mt-4">
          <div className="d-flex flex-column">
            <b>Hvor mange målinger skal utføres? &#40;-&#41;:</b>
            <div className="d-flex">
              <Button color="success" onClick={addNewRow} disabled={data.inndata.maaleresultater.size05.length >= 4}>
                Legg til rad
              </Button>
              <HSpace />
              <Button color="danger" onClick={removeLastRow} disabled={data.inndata.maaleresultater.size05.length <= 1}>
                Fjern siste rad
              </Button>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column mt-2">
          <p className="mb-0">
            Klassegrensenivået i henhold til EU-GMP partikkelstørrelse 0,5μm &#40;antall/m<sup>3</sup>&#41;:
          </p>
          <b className="mb-2">
            {data.inndata.euGmpKlasse === ""
              ? "-"
              : euGmpTabellverdi(PARTIKKELSTOERRELSER_GMP[0].label, data.inndata.euGmpKlasse, ROMAKTIVITET)}
          </b>
        </div>
        <div className="d-flex justify-content-between">
          <p className="mb-0">
            Minimumvolumet for 0,5μm &#40;l&#41; ={" "}
            <b>
              {beregnetMinimumsvolumPerProevetaking(data.utdata.klasseGrenseNivaaEUGMP_05 as number)?.toFixed(2) ?? ""}
            </b>
          </p>
          <p className="mb-0">Formel: &#40;VS = &#40;20 / Cn&#41; * 1000&#41;</p>
        </div>
        <hr className="mt-0" />
        <div className="mt-4">
          <b>Fyll inn måleresultater for partikkelstørrelse 0,5μm:</b>
        </div>
        {data.utdata.volumPerProevetaking > data.utdata.minimumsvolumPerProevetakingEUGMP_05 ? (
          <div className="mt-0 d-flex flex-column">
            <table>
              <thead>
                <tr>
                  <th>Målepunkt &#40;-&#41;</th>
                  <th>
                    Måleresultat &#40;antall/m<sup>3</sup>&#41;
                  </th>
                </tr>
              </thead>
              {data.inndata.maaleresultater.size05.map((resultat, i) => (
                <tbody key={i}>
                  <tr>
                    <td>
                      <McInput
                        value={resultat.maalepunktNr}
                        type="text"
                        onChange={(e) => updateMaaleresultater(e.target.value, i, "maalepunktNr", "size05")}
                        placeholder="Beskrivelse av punkt"
                      />
                    </td>
                    <td>
                      <McInput
                        value={resultat.maaleresultat}
                        normalizeNumbers={true}
                        type="text"
                        onBlur={(e) => updateMaaleresultater(Number(e.target.value), i, "maaleresultat", "size05")}
                        placeholder="Måleresultat"
                      />
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
        ) : (
          <b>Prøvetakingsvolumet må være høyere enn minimumskravet</b>
        )}
        <div className="d-flex flex-column mt-4">
          <p className="mb-0">
            Klassegrensenivået i henhold til EU-GMP partikkelstørrelse 5μm &#40;antall/m<sup>3</sup>&#41;:
          </p>
          <b className="mb-2">
            {data.inndata.euGmpKlasse === ""
              ? "-"
              : euGmpTabellverdi(PARTIKKELSTOERRELSER_GMP[1].label, data.inndata.euGmpKlasse, ROMAKTIVITET)}
          </b>
        </div>
        <div className="d-flex justify-content-between mb-0 mt-0">
          <p className="mb-0">
            Minimumvolumet for 5μm &#40;l&#41; ={" "}
            <b>
              {beregnetMinimumsvolumPerProevetaking(data.utdata.klasseGrenseNivaaEUGMP_5 as number)?.toFixed(2) ?? ""}
            </b>
          </p>
          <p className="mb-0">Formel: &#40;VS = &#40;20 / Cn&#41; * 1000&#41;</p>
        </div>
        <hr className="mt-0 mb-2" />
        <div className="mt-0">
          <b>Fyll inn måleresultater for partikkelstørrelse 5μm:</b>
        </div>
        {data.utdata.volumPerProevetaking > data.utdata.minimumsvolumPerProevetakingEUGMP_5 ? (
          <div className="mt-0 d-flex flex-column">
            <table>
              <thead>
                <tr>
                  <th>Målepunkt &#40;-&#41;</th>
                  <th>
                    Måleresultat &#40;antall/m<sup>3</sup>&#41;
                  </th>
                </tr>
              </thead>
              {data.inndata.maaleresultater.size5.map((resultat, i) => (
                <tbody key={i}>
                  <tr>
                    <td>
                      <McInput
                        value={resultat.maalepunktNr}
                        type="text"
                        onChange={(e) => updateMaaleresultater(e.target.value, i, "maalepunktNr", "size5")}
                        placeholder="Beskrivelse av punkt"
                      />
                    </td>
                    <td>
                      <McInput
                        value={resultat.maaleresultat}
                        normalizeNumbers={true}
                        type="text"
                        onBlur={(e) => updateMaaleresultater(Number(e.target.value), i, "maaleresultat", "size5")}
                        placeholder="Måleresultat"
                      />
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
        ) : (
          <b>Prøvetakingsvolumet må være høyere enn minimumskravet</b>
        )}
        <div className="mt-2">
          <ReportDocxImageContainer docxImageTag="partikkelTellingForUtstyr" title="Målepunkter i rommet" />
          <div className="w-50">
            <b>Kommentar:</b>
            <McInput
              value={data.inndata.kommentar}
              onChange={(e) =>
                onUpdateEditData("testResultater.partikkelTellingForUtstyr.inndata.kommentar", e.target.value)
              }
              type="textarea"
              dynamicHeight={true}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default PartikkelTellingForUtstyr;
