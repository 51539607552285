import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader, UncontrolledTooltip } from "reactstrap";
import snarkdown from "snarkdown";
import { doGetReleaseNotes } from "./routes/admin/restQueries";

const ReleaseNotesButton: React.FC<{
  onClickReleaseNotesTimeStamp: () => void;
  lastOpenedDate: string;
}> = ({ onClickReleaseNotesTimeStamp, lastOpenedDate }) => {
  const [showReleaseNotesModal, setShowReleaseNotesModal] = useState<boolean>(false);
  const [releaseNotesText, setReleaseNotesText] = useState<string>("");
  const [releaseNotes, setReleaseNotes] = useState<string>("");

  useEffect(() => {
    getReleaseNotes();
  }, []);

  const getReleaseNotes = async () => {
    const _releaseNotes = await doGetReleaseNotes();
    setReleaseNotes(_releaseNotes);
  };

  const hasNewReleaseNotes = () => {
    const extractedLastDateFromReleaseNotes = releaseNotes.match(/(\d{2})\.(\d{2})\.(\d{4})/);

    if (extractedLastDateFromReleaseNotes) {
      const [_, day, month, year] = extractedLastDateFromReleaseNotes;

      const extractedDate = `${year}-${month}-${day}`;

      const extractedDateObj = new Date(extractedDate);

      if (extractedDateObj > new Date(lastOpenedDate)) {
        return true;
      } else {
        return false;
      }
    }
  };

  const showReleaseNotes = async (): Promise<void> => {
    onClickReleaseNotesTimeStamp();
    setShowReleaseNotesModal(!showReleaseNotesModal);
    setReleaseNotesText(snarkdown(releaseNotes));
  };

  return (
    <>
      <Button
        id="showReleaseNotesButton"
        color="transparent"
        className="nav-button nav-button-hover"
        style={{ fontSize: "16px", padding: "0.4rem" }}
        onClick={showReleaseNotes}
      >
        <i className={`fa fa-rocket fa-fw ${hasNewReleaseNotes() === true ? "faa-ring animated text-primary" : ""}`} />
      </Button>
      <UncontrolledTooltip target="showReleaseNotesButton">Vis versjonshistorikk</UncontrolledTooltip>
      <Modal
        fade={false}
        style={{ maxWidth: "60vw", maxHeight: "90%" }}
        isOpen={showReleaseNotesModal}
        toggle={() => setShowReleaseNotesModal(!showReleaseNotesModal)}
      >
        <ModalHeader toggle={() => setShowReleaseNotesModal(!showReleaseNotesModal)}>Versjonshistorikk</ModalHeader>
        <ModalBody>
          <div dangerouslySetInnerHTML={{ __html: releaseNotesText }} />
        </ModalBody>
      </Modal>
    </>
  );
};

export default ReleaseNotesButton;
