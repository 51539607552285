import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import { useHistory } from "react-router";
import { Alert, Button } from "reactstrap";
import { IMeeting } from "../../../../../../types/meeting.types";
import { AG_GRID_LOCALE_NOR } from "../../../../agGridLocale";
import { DisplayDueDate, FailedAlert } from "../../../../components";
import { useAppSelector } from "../../../../hooks";
import { ISSUE_COMPLETED_VALUE, ISSUE_EXPIRED } from "../../issuesApp/issueConstants";
import { useGetMeetingInstancesWhereUserIsAParticipantQuery } from "../../meeting/meetingRTK";

const UserParticipantInMeeting: React.FC<{}> = () => {
  const project = useAppSelector((state) => state.adminReducer.project);
  const user = useAppSelector((state) => state.globalReducer.user);

  const { data: meetingsForUser = [], isError } = useGetMeetingInstancesWhereUserIsAParticipantQuery({
    projectId: project?._id,
    userId: user._id,
  });

  const history = useHistory();

  const goToMeeting = async (meeting: IMeeting.Meeting) => {
    if (meeting != null) {
      history.push(`/project/${meeting.project}/meetings/${meeting._id}`);
    }
  };

  return (
    <>
      {isError === true && <FailedAlert errorMessage="Kunne ikke laste inn dine møter" />}
      {isError === false && meetingsForUser.length === 0 && (
        <div className="flex-fill overflow-auto px-1">
          <Alert color="success mt-5">
            <i className="fa fa-check fa-fw" />
            Du har ingen kommende møter.
          </Alert>
        </div>
      )}
      {isError === false && meetingsForUser.length > 0 && (
        <>
          <div className="ag-theme-quartz h-100 w-100">
            <AgGridReact
              rowData={meetingsForUser}
              columnDefs={createActiveMeetingsColumns(goToMeeting)}
              domLayout={"autoHeight"}
              rowSelection="single"
              animateRows={true}
              suppressContextMenu={true}
              localeText={AG_GRID_LOCALE_NOR}
              defaultColDef={{ suppressHeaderMenuButton: true }}
            />
          </div>
        </>
      )}
    </>
  );
};

export default UserParticipantInMeeting;

const createActiveMeetingsColumns = (goToMeeting: (item: IMeeting.Meeting) => void) => {
  const columns: ColDef<any>[] = [
    {
      field: "name",
      headerName: "Møte",
      cellRenderer: (params) => {
        return (
          <>
            <div className="d-flex align-items-center">
              <Button className="p-0 m-0" onClick={() => goToMeeting(params.data)} color="link">
                {params.value}
              </Button>
            </div>
          </>
        );
      },
      filter: "agTextColumnFilter",
      flex: 1,
    },
    {
      field: "meetingSerie.name",
      headerName: "Møteserie",
      filter: "agTextColumnFilter",
      flex: 1,
    },
    {
      field: "dateOfMeeting",
      headerName: "Dato",
      cellRenderer: (params) => {
        if (moment(params.value).isValid() === false || params.value == null || !params.value) {
          return "-";
        }
        return (
          <DisplayDueDate
            dueDate={params.value}
            isComplete={params.data.status === ISSUE_COMPLETED_VALUE || params.data.status === ISSUE_EXPIRED}
          />
        );
      },
      filter: "agDateColumnFilter",
    },
  ];
  return columns;
};
