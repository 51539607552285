import { IInterval, IValueLabel } from "../../../../../../types/global.types";

export const MEETING_INTERVALS: IInterval[] = [
  {
    id: "1",
    name: "Hver uke",
  },
  {
    id: "2",
    name: "Hver andre uke",
  },
];

export enum MeetingSeriesStatusEnums {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
  DELETED = "DELETED",
}

export const MEETINGS_SERIES_STATUSES: IValueLabel[] = [
  {
    label: "Aktiv",
    value: MeetingSeriesStatusEnums.ACTIVE,
  },
  {
    label: "Arkivert",
    value: MeetingSeriesStatusEnums.ARCHIVED,
  },
  {
    label: "Slettet",
    value: MeetingSeriesStatusEnums.DELETED,
  },
];
