import React from "react";
import { DropdownItem } from "reactstrap";
import { sortArrayByString } from "../app/utils";
import { IBootstrapTableColumnConfig } from "../types";

const ColumnsSection: React.FC<{
  section: string;
  searchTerm: string;
  columns: IBootstrapTableColumnConfig[];
  toggles: any;
  customColumnToggle: any;
  onColumnToggle: any;
}> = ({ section, columns, toggles, customColumnToggle, onColumnToggle, searchTerm }) => {
  return (
    <>
      <DropdownItem header>
        <b>{section.toUpperCase()}</b>
      </DropdownItem>
      {sortArrayByString(columns, "text")
        .filter((column) => {
          if (column.text !== "") {
            return column.text.toLowerCase().search(searchTerm.toLowerCase()) !== -1;
          } else {
            return false;
          }
        })
        .map((column, idx) => ({
          ...column,
          toggle: toggles[column.dataField],
        }))
        .map((column, index) => (
          <DropdownItem
            toggle={false}
            key={index}
            tag="button"
            className="d-flex"
            onClick={() => {
              customColumnToggle(column);
              onColumnToggle(column.dataField);
            }}
          >
            {column.text}
            <div className="flex-fill" />
            {column.toggle && <i className="fa fa-check fa-fw" />}
          </DropdownItem>
        ))}
    </>
  );
};
export default ColumnsSection;
