import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({ baseUrl: "/api" }),
  tagTypes: [
    "News",
    "Issue",
    "IssueBoard",
    "MCBIM",
    "Project",
    "ProjectDashboard",
    "Checklist",
    "Meeting",
    "MeetingAgenda",
    "MeetingSerie",
    "Global",
    "AzureBlobStorage",
    "Stats",
    "IssueFormulas",
  ],
  keepUnusedDataFor: 15,
  endpoints: () => ({}),
});

export const getCacheKey = (queries: any, cacheKeyPattern: string) => {
  const matches = Object.keys(queries)
    .filter((q) => q.startsWith(cacheKeyPattern))
    .sort((a: string, b: string) => {
      if (queries[a]?.fulfilledTimeStamp! > queries[b]?.fulfilledTimeStamp!) {
        return -1;
      } else if (queries[a]?.fulfilledTimeStamp! > queries[b]?.fulfilledTimeStamp!) {
        return 1;
      } else {
        return 0;
      }
    });
  if (matches.length === 0) {
    return null;
  }
  return JSON.parse(removeOuterParentheses(matches[0].replace(cacheKeyPattern, "")));
};

function removeOuterParentheses(inputString) {
  // Check if the string starts and ends with parentheses
  if (inputString.charAt(0) === "(" && inputString.charAt(inputString.length - 1) === ")") {
    // Remove the first and last characters
    return inputString.substring(1, inputString.length - 1);
  } else {
    // If the string doesn't start and end with parentheses, return the original string
    return inputString;
  }
}
