import React, { useCallback, useMemo, useRef } from "react";
import { Alert, Badge, Button } from "reactstrap";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { IFileAgGrid, IValueLabel } from "../../../../types/global.types";
import { formatShortDate } from "../../app/routes/admin/selectors";

import { ColDef, IsRowSelectable } from "ag-grid-community";
import { AG_GRID_LOCALE_NOR } from "../../agGridLocale";

const FileTableAgGrid: React.FC<{
  files: IFileAgGrid[];
  getSelectedFilesCb: (files: any[]) => void;
  filterExtension?: IValueLabel;
}> = ({ files, getSelectedFilesCb, filterExtension }) => {
  const gridRef = useRef<AgGridReact>(); // Optional - for accessing Grid's API

  const [noFilesWithGivenFilter, setNoFilesWithGivenFilter] = React.useState<boolean>(null);

  React.useEffect(() => {
    if (filterExtension == null) {
      setNoFilesWithGivenFilter(false);
    } else {
      const _noFilesWithGivenFilter =
        files?.some((file) => file.name.toUpperCase().endsWith(filterExtension.value.toUpperCase())) === false;
      setNoFilesWithGivenFilter(_noFilesWithGivenFilter);
    }
  }, [files]);

  const buttonListener = useCallback((e) => {
    const selectedFiles = gridRef.current.api.getSelectedRows();
    getSelectedFilesCb(selectedFiles);
    gridRef.current.api.deselectAll();
  }, []);
  //

  // Each Column Definition results in one Column.
  const columnDefs = useMemo<ColDef[]>(
    () => [
      {
        field: "name",
        filter: true,
        headerName: "Navn",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        width: 400,
        showDisabledCheckboxes: true,
        resizable: true,
      },
      {
        field: "lastUpdated",
        filter: true,
        type: ["dateColumn"],
        valueFormatter: (params) => formatShortDate(params.value),
      },
    ],
    [],
  );

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      suppressMovable: true,
    }),
    [],
  );

  const isRowSelectable = useMemo<IsRowSelectable>(() => {
    return (params) => {
      return params?.data?.disabled !== true;
    };
  }, []);

  return (
    <div className="d-flex flex-column ag-theme-alpine h-100 w-100 overflow-auto">
      <div className="d-flex" style={{ height: "40px" }}>
        {filterExtension != null && (
          <Badge className="d-flex align-items-center mc-gray-500 mt-1 mb-1" size="sm">
            <span>
              <i className="fa fa-filter fa-fw" />
              <b>{filterExtension.value}</b>
            </span>
          </Badge>
        )}
        <div className="flex-fill" />
        <Button className="mt-1 mb-1" color="mc-blue" onClick={buttonListener}>
          <i className="fa fa-angle-double-right" />
        </Button>
      </div>
      <div className="flex-fill" />
      <>
        {noFilesWithGivenFilter ? (
          <Alert>Ingen {filterExtension.label}-filer i denne mappen</Alert>
        ) : (
          <AgGridReact
            ref={gridRef} // Ref for accessing Grid's API
            rowData={
              files?.filter((file) =>
                filterExtension == null ? true : file.name?.toUpperCase().endsWith(filterExtension.value.toUpperCase()),
              ) || []
            } // Row Data for Rows
            columnDefs={columnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            rowSelection="multiple" // Options - allows click selection of rows
            suppressRowClickSelection={true}
            isRowSelectable={isRowSelectable}
            localeText={AG_GRID_LOCALE_NOR}
          />
        )}
      </>
    </div>
  );
};

export default FileTableAgGrid;
