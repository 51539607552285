import React from "react";
import { useHistory } from "react-router-dom";
import { IMeeting } from "../../../../../../../types/meeting.types";
import { FailedAlert } from "../../../../../components";
import { ConnectedIssues } from "../../../../../components/ConnectedIssues/ConnectedIssues";
import CreateItemWithBoardSelectorModal from "../../../../../components/CreateItemWithBoardSelectorModal/CreateItemWithBoardSelectorModal";
import { MCApplicationsUrlEnum } from "../../../../../frontendConstants";
import { useAppSelector } from "../../../../../hooks";
import ConnectExistingIssueToItemModalWrapper from "../../../../ConnectExistingIssueToItemModalWrapper";
import { getProjectIdFromUrl } from "../../../../utils";
import { useGetIssueBoardsQuery } from "../../../issuesApp/IssueBoardRTK";
import { useCreateIssueMutation } from "../../../issuesApp/issueRTK";
import { useConnectOrDisconnectIssueFromAgendaItemMutation } from "../../meetingAgendaRTK";

const AgendaConnectedIssues: React.FC<{
  agendaItem: IMeeting.AgendaItem;
  defaultIssueBoard?: { name: string; _id: string };
}> = ({ agendaItem, defaultIssueBoard }) => {
  const [newIssueModalOpen, setNewIssueModalOpen] = React.useState<boolean>(false);
  const [connectExistingModalOpen, setConnectExistingModalOpen] = React.useState<boolean>(false);

  const history = useHistory();
  const projectId = getProjectIdFromUrl(history.location.pathname);

  const { data: issueBoards } = useGetIssueBoardsQuery({
    projectId: useAppSelector((state) => state.adminReducer.project._id),
  });

  const [connectOrDisconnect, { isError: connectOrDiscionnectError }] =
    useConnectOrDisconnectIssueFromAgendaItemMutation();
  const [createIssue, { isError: createIssueError }] = useCreateIssueMutation();
  const apps = useAppSelector((state) => state.globalReducer.apps);
  const project = useAppSelector((state) => state.adminReducer.project);
  const isError = connectOrDiscionnectError || createIssueError;

  const onCreateIssue = async (boardId: string) => {
    await createIssue({
      projectId: projectId,
      issueBoardId: boardId,
      issueTitle: "",
      taskOrIssue: "ISSUE",
      connectedMeetingAgendaItem: agendaItem._id,
    });
    setNewIssueModalOpen(false);
  };

  const onConnect = async (boardId: string, issueId: string) => {
    await connectOrDisconnect({
      projectId: projectId,
      agendaId: agendaItem._id,
      issueId: issueId,
      connectOrDisconnect: "CONNECT",
    });

    setConnectExistingModalOpen(false);
  };

  return (
    <>
      {isError === true && <FailedAlert />}
      {connectExistingModalOpen === true && (
        <ConnectExistingIssueToItemModalWrapper
          defaultIssueBoard={defaultIssueBoard}
          onConnected={(boardId, issueId) => onConnect(boardId, issueId)}
          projectId={projectId}
          closeModal={() => setConnectExistingModalOpen(false)}
          blockedIssueIds={agendaItem.connectedIssues.map((issue) => issue._id)}
        />
      )}
      {newIssueModalOpen === true && (
        <CreateItemWithBoardSelectorModal
          defaultIssueBoard={defaultIssueBoard}
          boards={(issueBoards || []).map((board) => {
            return {
              project: board.project,
              name: board.name,
              _id: board._id,
              status: board.status,
            };
          })}
          onCreate={onCreateIssue}
          modalTitle="Koble sak til møte"
          toggle={() => setNewIssueModalOpen(false)}
          currentApp={MCApplicationsUrlEnum.ISSUE}
          apps={apps}
          projectId={project._id}
          createButtonText="Opprett sak"
        />
      )}
      <ConnectedIssues
        defaultIssueBoard={defaultIssueBoard}
        createNewFromDefault={(id) => onCreateIssue(id)}
        issues={agendaItem.connectedIssues?.filter((issue) => issue.archived !== true)}
        createNewIssue={() => setNewIssueModalOpen(true)}
        disconnectIssue={(issueId) =>
          connectOrDisconnect({
            projectId: projectId,
            agendaId: agendaItem._id,
            issueId: issueId,
            connectOrDisconnect: "DISCONNECT",
          })
        }
        connectExistingIssue={() => setConnectExistingModalOpen(true)}
        isPrintMode={false}
        titleLabel="Koblede saker"
        projectId={projectId}
      />
    </>
  );
};

export default AgendaConnectedIssues;
