import * as R from "ramda";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { connect } from "react-redux";
import { Badge, Button } from "reactstrap";
import { IProject, IRisikoEvaluationCategorySelection, IScoreColor } from "../../../../../../../types/global.types";
import { HSpace } from "../../../../../components";
import { ToastMessagesEnums } from "../../../../../frontendConstants";
import { IBootstrapTableColumnConfig } from "../../../../../types";
import { doUpdateScoreColors } from "../../risikoAPI";
import { getEvaluationColor } from "../../util";
import { ColorDropdown } from "../../../../../components/ColorDropDown";
import { IRootState } from "../../../../../store";
import { setShowSuccessMessageThunk } from "../../../../../global/globalSlice";

interface IProps {
  scoreColors: IScoreColor[];
  probabilities: IRisikoEvaluationCategorySelection[];
  consequences: IRisikoEvaluationCategorySelection[];
  project: IProject;
  loadCategories: any;
  setShowSuccessMessageThunk: any;
  evaluationCategoryId: string;
}

interface IState {
  editMode: boolean;
  columns: IBootstrapTableColumnConfig[];
  rows: any[];
  scoreColors: IScoreColor[];
}

class ScoreColor extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      columns: null,
      rows: null,
      scoreColors: R.clone(this.props.scoreColors),
    };
  }
  setScoreColor = (scoreColor: IScoreColor, color: string, value) => {
    const consequenceValue = value.split("|")[0];
    const probabilityValue = value.split("|")[1];
    const rows = this.state.rows;
    if (scoreColor == null) {
      this.setState(
        (prevState) => ({
          scoreColors: [
            ...prevState.scoreColors,
            {
              consequenceValue: Number(consequenceValue),
              probabilityValue: Number(probabilityValue),
              color: color,
            },
          ],
          rows: [],
        }),
        () => {
          this.setState({
            rows,
          });
        },
      );
    } else {
      const scoreColorIndex = this.state.scoreColors.findIndex(
        (scoreColor) =>
          scoreColor.consequenceValue === Number(consequenceValue) &&
          scoreColor.probabilityValue === Number(probabilityValue),
      );
      const scoreColors = this.state.scoreColors;
      scoreColors[scoreColorIndex].color = color;
      this.setState({ scoreColors, rows: [] }, () => {
        this.setState({ rows });
      });
    }
  };

  editColorFormatter = (scoreColor, value) => {
    return (
      <ColorDropdown
        activeColor={scoreColor ? scoreColor.color : "brown"}
        setColor={(color) => this.setScoreColor(scoreColor, color, value)}
      />
    );
  };

  colorizeScoreColor = (value) => {
    const consequenceValue = value.split("|")[0];
    const probabilityValue = value.split("|")[1];
    const scoreColor = getEvaluationColor(this.state.scoreColors, consequenceValue, probabilityValue);
    if (this.state.editMode) {
      return this.editColorFormatter(scoreColor, value);
    }
    return (
      <span>
        <Badge style={{ width: "100%", height: "35px" }} color={scoreColor != null ? scoreColor.color : "brown"}>
          <h4>{scoreColor ? scoreColor.color.toUpperCase() : `Farge not set`}</h4>
        </Badge>
      </span>
    );
  };

  componentWillReceiveProps(nextProps) {
    this.createTable();
  }

  componentDidMount(): void {
    this.createTable();
  }

  createTable = () => {
    const columns = this.props.probabilities.map((probability, index) => {
      return {
        dataField: `C${index}`,
        text: probability.name,
        formatExtraData: {
          probabilityValue: probability.value,
        },
        formatter: this.colorizeScoreColor,
      };
    });
    columns.unshift({
      dataField: "consequence",
      text: "",
      formatExtraData: {
        probabilityValue: null,
      },
      formatter: null,
    });

    const rows = this.props.consequences.map((consequence, index) => {
      let rowObj = {
        consequence: consequence.name,
      };
      columns.forEach((column) => {
        if (column.dataField !== "consequence") {
          rowObj[column.dataField] = `${consequence.value}|${column.formatExtraData.probabilityValue}`;
        }
      });
      return rowObj;
    });

    this.setState(
      {
        columns,
        rows,
      },
      () => {},
    );
  };

  setEditSelection = (bool: boolean) => {
    this.setState({ editMode: bool });
  };

  cancel = () => {
    this.setState({ scoreColors: R.clone(this.props.scoreColors) }, () => {
      this.setState({ editMode: false });
    });
  };

  save = async (evaluationCategoryId: string) => {
    // @ts-ignore
    await doUpdateScoreColors(this.props.project._id, evaluationCategoryId, this.state.scoreColors);
    this.props.loadCategories();
    this.setState({ editMode: false });
    this.props.setShowSuccessMessageThunk(ToastMessagesEnums.UPDATED);
  };

  render() {
    return (
      <>
        <div className="d-flex">
          <h5 className="align-self-center" style={{ marginTop: "3px" }}>
            Poeng
          </h5>
          <div className="flex-fill" />
          {this.state.editMode === false && (
            <Button id="edit" onClick={() => this.setEditSelection(true)} color="primary" outline>
              Rediger
              <i className="fa fa-pencil fa-fw" />
            </Button>
          )}
        </div>
        {this.state.editMode === false && this.state.rows != null && this.state.columns != null && (
          <BootstrapTable keyField="consequence" data={this.state.rows} columns={this.state.columns} />
        )}
        {this.state.editMode && (
          <>
            <BootstrapTable keyField="consequence" data={this.state.rows} columns={this.state.columns} />
            <Actions save={this.save} cancel={this.cancel} evaluationCategoryId={this.props.evaluationCategoryId} />
          </>
        )}
      </>
    );
  }
}

const Actions = ({ evaluationCategoryId, save, cancel }) => (
  <div className="d-flex">
    <div className="flex-fill" />
    <Button color="secondary" onClick={() => cancel(false)}>
      Avbryt
    </Button>
    <HSpace />
    <Button color="success" onClick={() => save(evaluationCategoryId)}>
      Oppdater
      <i className="fa fa-floppy-o" />
    </Button>
  </div>
);

const mapStateToProps = (state: IRootState) => ({
  project: state.adminReducer.project,
});
// @ts-ignore
export default connect(mapStateToProps, {
  setShowSuccessMessageThunk,
})(ScoreColor);
