import Axios from "axios";
import {
  IChecklistConnectOrDisconnectIssuePOSTBody,
  IChecklistStatus,
  IChecklistTemplateTypesEnums,
  ICheckTypes,
  IRowMoveInfo,
} from "mc-shared/zod/checklistSchema";
import {
  IChecklistCategory,
  IChecklistItem,
  IChecklistItemRow,
  IChecklistParts,
  IChecklistPopulated,
  IUpdatedChecklistItem,
} from "../../../../../types/checklist.types";
import { IHistoryLogDepricated } from "../../../../../types/global.types";
import { IMeeting } from "../../../../../types/meeting.types";

export const doGetChecklistTemplates = async (projectId: string): Promise<IChecklistPopulated[]> => {
  try {
    const resp = await Axios.get(`/api/project/${projectId}/checklist/templates`);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doUploadChecklistJsonTemplate = async (jsonTemplate: string): Promise<IChecklistPopulated[]> => {
  try {
    const resp = await Axios.post(`/api/checklist/templates`, { jsonTemplate });
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetActiveProjectChecklistsCreatedByLoggedInUser = async (
  projectId: string,
  userId: string,
): Promise<IChecklistPopulated[]> => {
  try {
    const resp = await Axios.get(`/api/project/${projectId}/checklist/user/${userId}`);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetSingleProjectChecklistParts = async (
  projectId: string,
  checklistId: string,
): Promise<IChecklistParts> => {
  try {
    const resp = await Axios.get(`/api/project/${projectId}/checklist/${checklistId}/parts`);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetChecklistRowLogs = async (
  projectId: string,
  checklistId: string,
  rowId: string,
): Promise<IHistoryLogDepricated[]> => {
  try {
    const resp = await Axios.get(`/api/project/${projectId}/checklist/${checklistId}/logs/${rowId}`);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doAddChecklist = async (
  name: string,
  pns: string,
  projectId: string,
  checklistTemplateId: string,
  status: IChecklistStatus,
  checkTypes: ICheckTypes,
  isTemplate: boolean = false,
  templateType: IChecklistTemplateTypesEnums,
): Promise<IChecklistPopulated> => {
  try {
    const resp = await Axios.post(`/api/project/${projectId}/checklist/add`, {
      checklistTemplateId,
      name,
      pns,
      checkTypes,
      status,
      isTemplate,
      templateType,
    });
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doAddChecklistCategory = async (
  projectId: string,
  checklistId: string,
  categoryCount: number,
): Promise<IChecklistCategory> => {
  try {
    const resp = await Axios.post(`/api/project/${projectId}/checklist/${checklistId}/category`, { categoryCount });
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doAddChecklistItem = async (
  projectId: string,
  checklistId: string,
  categoryId: string,
  itemCount: number,
): Promise<IChecklistItem> => {
  try {
    const resp = await Axios.post(`/api/project/${projectId}/checklist/${checklistId}/item`, { categoryId, itemCount });
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doRemoveChecklistCategory = async (
  projectId: string,
  checklistId: string,
  categoryId: string,
): Promise<IChecklistPopulated> => {
  try {
    const resp = await Axios.delete(`/api/project/${projectId}/checklist/${checklistId}/category/${categoryId}`);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doRemoveChecklistItem = async (
  projectId: string,
  checklistId: string,
  itemId: string,
): Promise<IChecklistPopulated> => {
  try {
    const resp = await Axios.delete(`/api/project/${projectId}/checklist/${checklistId}/item/${itemId}`);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doUpdateChecklist = async <T>(
  projectId: string,
  checklistId: string,
  attribute: string,
  newValue: T,
  oldValue: T,
): Promise<IChecklistPopulated> => {
  try {
    const resp = await Axios.put(`/api/project/${projectId}/checklist/${checklistId}`, {
      attribute,
      newValue,
      oldValue,
    });
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doUpdateChecklistCategory = async <T extends keyof IChecklistItemRow>(
  projectId: string,
  checklistId: string,
  categoryId: string,
  attribute: string,
  newValue: IChecklistItemRow[T],
  oldValue: string,
): Promise<IChecklistCategory> => {
  try {
    const resp = await Axios.put(`/api/project/${projectId}/checklist/${checklistId}/category/${categoryId}`, {
      attribute,
      newValue,
      oldValue,
    });
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doUpdateChecklistItem = async <T extends keyof IChecklistItemRow>(
  projectId: string,
  checklistId: string,
  itemId: string,
  path: string,
  newValue: IChecklistItemRow[T],
  oldValue: string,
): Promise<IUpdatedChecklistItem> => {
  try {
    const resp = await Axios.put(`/api/project/${projectId}/checklist/${checklistId}/item/${itemId}`, {
      path,
      newValue,
      oldValue,
    });
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetSingleChecklistItem = async (projectId: string, checklistItemId: string): Promise<IChecklistItem> => {
  try {
    const resp = await Axios.get(`/api/project/${projectId}/checklist/checklistitem/${checklistItemId}`);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doConnectOrDisconnectIssueFromChecklistItem = async (
  projectId: string,
  body: IChecklistConnectOrDisconnectIssuePOSTBody,
): Promise<IMeeting.AgendaItem[]> => {
  try {
    const fetch = await Axios.put(`/api/project/${projectId}/checklist/issue/connect-disconnect-issue`, { ...body });
    return fetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doUpdateChecklistRowPosition = async (
  projectId: string,
  moveInfo: IRowMoveInfo,
): Promise<IChecklistCategory[] | IChecklistItem[]> => {
  try {
    const fetch = await Axios.post(`/api/project/${projectId}/checklist/updateRowPosition`, moveInfo);
    return fetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};
