export const SET_PROJECT = "adminReducer/SET_PROJECT";
export const SET_PROJECT_APPS = "adminReducer/SET_PROJECT_APPS";
export const UPDATE_MEMBER_GROUP = "adminReducer/UPDATE_MEMBER";
export const SET_PROJECT_NAME = "adminReducer/SET_PROJECT_NAME";
export const SET_PROJECT_USE_DASHBOARD_AS_DEFAULT = "adminReducer/SET_PROJECT_USE_DASHBOARD_AS_DEFAULT";
export const SET_ORG_CHART = "adminReducer/SET_ORG_CHART";
export const SET_PROJECT_ARC_GIS = "adminReducer/SET_PROJECT_ARC_GIS";
export const RESET_PROJECT = "adminReducer/RESET_PROJECT";
export const SET_RISIKOCOLUMN_TEMPLATES = "adminReducer/SET_RISIKOCOLUMN_TEMPLATES";
export const SET_USE_DEFAULT_DISCIPLINES = "adminReducer/SET_USE_DEFAULT_DISCIPLINES";
export const SET_DISCIPLINES = "adminReducer/SET_DISCIPLINES";
export const SET_MILESTONES = "adminReducer/SET_MILESTONES";
export const SET_MCBIM = "adminReducer/SET_MCBIM";
export const SET_PNS = "adminReducer/SET_PNS";
export const SET_MCBIM_PROJECT_SPACES = "adminReducer/SET_MCBIM_PROJECT_SPACES";
