import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import * as R from "ramda";
import { IIssue } from "../../../../../../../types/global.types";
import { OtherAttributeEnum } from "./BreeamDashboard";

const BreeamBarChart = ({ items, dataFields, issueBoard }) => {
  const calculateTotals = (items, dataFields) => {
    try {
      const possiblePointTitles = [
        "Kriteriet er utelatt",
        "Oppnår ikke poeng for kriteriet",
        "Usikker- avklaring",
        "Antas for krevende å oppnå",
      ];

      const groupedItems = R.groupBy((item: IIssue) => item?.bucket, items);

      const totals = Object.values(groupedItems).map((group: any[]) => ({
        bucket: group[0].bucket,
        total: R.sum(R.map((item) => Number(item.otherAttributesData?.[OtherAttributeEnum.PlannedPoints] ?? 0), group)),
      }));
      return dataFields.map((field, i) => ({
        name: field,
        y: totals.find((t) => t.bucket === field)?.total || 0,
        color: possiblePointTitles.includes(field) ? "#a7a7a7" : "#f59426",
      }));
    } catch (error) {}
  };

  const options: any = {
    chart: {
      type: "bar",
    },
    title: {
      text: "Status for BREEAM kriterier",
    },
    colors: ["#f59426"],
    xAxis: {
      type: "category",
      title: {
        text: "",
      },
      labels: {
        rotation: 0,
        style: {
          fontSize: "14px",
          fontFamily: "helvetica, arial, Verdana, sans-serif",
        },
      },
      lineWidth: 2,
      lineColor: "#e3e3e3",
    },
    yAxis: {
      min: 0,
      title: {
        text: "Antall poeng",
      },
      lineWidth: 2,
      gridLineWidth: 0,
      lineColor: "#e3e3e3",
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: "6px",
        dataLabels: {
          enabled: true,
        },
        groupPadding: 0,
      },
    },
    series: [
      {
        data: calculateTotals(items, dataFields),
        dataLabels: {
          enabled: true,
          color: "#414141",
          //     align: "right",
          style: {
            fontSize: "12px",
            textOutline: false,
            fontWeight: "600",
          },
        },
      },
    ],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export const BreeamStackedBarChart = ({ items, dataFields, issueBoard }) => {
  const preanalyseSeries = [
    { color: "lightgray", name: "Antas ikke oppnåelig" },
    { color: "red", name: "Krever mye" },
    { color: "orange", name: "Krever mer" },
    { color: "#FFEA00", name: "Krever noe" },
    { color: "green", name: "Antas oppnås" },
  ];
  const calculateTotals = (items) => {
    try {
      const customPreanalysePath: string[] = ["B", "dropdownOptions"];
      const otherAttributesPreanalysis: string[] = R.path(customPreanalysePath, issueBoard.otherAttributesConfig) || [];

      const groupedItems = R.groupBy(
        (item: IIssue) =>
          otherAttributesPreanalysis.length
            ? item?.otherAttributesData?.B?.toString() ?? ""
            : item?.bucket?.toString() ?? "",
        items,
      );
      const totals = Object.values(groupedItems).map((group: any[]) => ({
        type: otherAttributesPreanalysis.length ? group[0]?.otherAttributesData.B : group[0].bucket,
        totalsArray: dataFields.map((field) => {
          const filteredItems = group.filter((item) => item.typeOfIssue === field);
          const isNotAchievable =
            group[0].bucket === "Antas ikke oppnåelig" || group[0]?.otherAttributesData.B === "Antas ikke oppnåelig";
          const total = R.sum(
            R.map(
              (item) =>
                Number(
                  item.otherAttributesData?.[
                    isNotAchievable ? OtherAttributeEnum.MaxPoints : OtherAttributeEnum.PlannedPoints
                  ] ?? 0,
                ),
              filteredItems,
            ),
          );
          return total;
        }),
      }));
      return preanalyseSeries.map((field) => ({
        ...field,
        data: totals.find((t) => t.type === field.name)?.totalsArray || [],
      }));
    } catch (error) {
      return { data: [] };
    }
  };

  const options: any = {
    chart: {
      type: "bar",
    },
    title: {
      text: "Status for BREEAM kriterier",
    },
    colors: ["#f59426"],
    xAxis: {
      //   type: "category",
      title: {
        text: "",
      },
      categories: dataFields,
      labels: {
        rotation: 0,
        style: {
          fontSize: "14px",
          fontFamily: "helvetica, arial, Verdana, sans-serif",
        },
      },
      lineWidth: 2,
      lineColor: "#e3e3e3",
    },
    yAxis: {
      min: 0,
      title: {
        text: "Antall poeng",
      },
      lineWidth: 2,
      gridLineWidth: 0,
      lineColor: "#e3e3e3",
    },
    legend: {
      enabled: true,
      symbolRadius: 0,
      itemStyle: {
        fontSize: "15px",
        color: "#636363",
      },
    },
    plotOptions: {
      series: {
        stacking: "normal",
        borderRadius: "4px",
        groupPadding: 0.1,
      },
    },
    series: calculateTotals(items),
  };
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default BreeamBarChart;
