import { ColDef } from "ag-grid-community";
import React from "react";
import Switch from "react-switch";
import { IDisciplin, IProject } from "../../../../../types/global.types";
import { FoldableSection, HSpace, VSpace } from "../../../components";
import ViewEditTable, { VETEmptyValidator } from "../../../components/ViewEditTable/ViewEditTable";
import { ToastMessagesEnums } from "../../../frontendConstants";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { setDisciplinesInRedux, setUseDefaultDisciplinesInRedux } from "../../actions";
import { DISCIPLINER } from "../start/constants";
import { doUpdateDefaultDisciplines, doUpdateDisciplines } from "./restQueries";
import { setShowSuccessMessageThunk } from "../../../global/globalSlice";

const ProjectDisciplineSettingsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const project = useAppSelector((state) => state.adminReducer.project as IProject);
  const toggleDefaultDisciplines = async (bool) => {
    await doUpdateDefaultDisciplines(project._id, bool);
    dispatch(setUseDefaultDisciplinesInRedux(bool));
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.UPDATED));
  };

  const updateDisciplines = async (data: IDisciplin[]) => {
    const _disciplines = await doUpdateDisciplines(project._id, data);
    dispatch(setDisciplinesInRedux(_disciplines));
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.UPDATED));
  };

  const columns: ColDef<IDisciplin>[] = [
    {
      field: "short",
      headerName: "Forkortelse",
      editable: true,
    },
    {
      field: "long",
      headerName: "Disiplin",
      editable: true,
      flex: 1,
    },
  ];

  const newItemTemplateObject = {
    short: "",
    long: "",
  };

  return (
    <div className="d-flex flex-column w-100">
      <div className="d-flex">
        Bruk standarddisipliner
        <HSpace />
        <Switch
          onColor="#00a76d"
          onChange={(bool) => toggleDefaultDisciplines(bool)}
          checked={project.useDefaultDisciplines}
        />
      </div>
      {project.useDefaultDisciplines === true && (
        <FoldableSection folded>
          <FoldableSection.Header>
            <h5 className="mt-2">Standarddisipliner</h5>
          </FoldableSection.Header>
          <FoldableSection.Body>
            <ViewEditTable
              validators={[VETEmptyValidator, VETEmptyValidator]}
              data={DISCIPLINER}
              columns={columns}
              onSave={updateDisciplines}
              newItemTemplateObject={newItemTemplateObject}
            />
          </FoldableSection.Body>
        </FoldableSection>
      )}
      <FoldableSection>
        <FoldableSection.Header>
          <h5 className="mt-2">Disipliner for prosjekt</h5>
        </FoldableSection.Header>
        <FoldableSection.Body>
          <ViewEditTable
            validators={[VETEmptyValidator, VETEmptyValidator]}
            data={project.disciplines}
            columns={columns}
            onSave={updateDisciplines}
            newItemTemplateObject={newItemTemplateObject}
            canEdit={true}
          />
        </FoldableSection.Body>
      </FoldableSection>
      <VSpace />
    </div>
  );
};

export default ProjectDisciplineSettingsPage;
