import { ColDef } from "ag-grid-community";
import React from "react";
import { Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";
import { IBoard } from "../../../../../../types/global.types";
import { FailedAlert, MCLoader } from "../../../../components";
import ViewEditTable from "../../../../components/ViewEditTable/ViewEditTable";
import { useAppSelector } from "../../../../hooks";
import { useUpdateIssueBoardTypesMutation } from "../IssueBoardRTK";

const IssueBoardTypes: React.FC<{ issueBoard: IBoard }> = ({ issueBoard }) => {
  const project = useAppSelector((state) => state.adminReducer.project);
  const types = issueBoard.types;
  const [data, setData] = React.useState<{ name: string }[]>(types);
  const [updateTypes, { isLoading, error }] = useUpdateIssueBoardTypesMutation();

  const colDefs: ColDef<{ name: string }>[] = [
    {
      field: "name",
      headerName: "Navn",
      editable: true,
    },
  ];

  React.useEffect(() => {
    const _data = types.map((item) => ({ name: item.name }));
    setData(_data);
  }, [types]);

  const newItemTemplateObject = {
    name: "",
  };

  const saveTypes = async (types: { name: string }[]) => {
    if (types.length === 0) {
      setData([...data]);
      return alert("Du må ha minst en type");
    }

    updateTypes({
      projectId: project._id,
      issueBoardId: issueBoard._id,
      types,
    });
  };

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h3">Typer</CardTitle>
        {isLoading === true && <MCLoader loading={isLoading} />}
        {error != null && <FailedAlert />}
        {error == null && (
          <>
            <CardSubtitle tag="h6" className="mb-2 text-muted" />
            {data != null && (
              <ViewEditTable
                columns={colDefs}
                data={data}
                onSave={saveTypes}
                newItemTemplateObject={newItemTemplateObject}
                canEdit={true}
              />
            )}
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default IssueBoardTypes;
