import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { IGisPointAndCamera, IIssue } from "../../../../../../types/global.types";
import { McInput, VSpace } from "../../../../components";
import { useAppSelector } from "../../../../hooks";
import { IPostIssue, useCreateIssueMutation } from "../issueRTK";
import { selectSingleIssueBoard } from "../issueSlice";
import { getIssueRegisterNameOption } from "../issueUtils";

const AddIssueModal: React.FC<{
  isOpen: boolean;
  toggle: () => void;
  onCreateItem: (item: IIssue) => void;
  issueBoardId: string;
  gis?: IGisPointAndCamera;
  gisImage?: string; // base64
  connectedRisikoId?: string;
}> = ({ isOpen, onCreateItem, toggle, issueBoardId, gis, gisImage, connectedRisikoId }) => {
  const [name, setName] = useState("");

  const project = useAppSelector((state) => state.adminReducer.project);

  const issueBoard = useSelector(selectSingleIssueBoard);

  const [addNewIssue, { isLoading }] = useCreateIssueMutation();

  const add = async () => {
    const input: IPostIssue = {
      projectId: project._id,
      issueBoardId: issueBoardId,
      issueTitle: name,
      taskOrIssue: "ISSUE",
      issueIdIfItIsATask: null,
      tags: null,
      connectedMeeting: null,
      clientAccess: true,
      gis,
      gisImage: gisImage,
      connectedChecklistItem: null,
      connectedRisiko: connectedRisikoId,
      connectedMeetingAgendaItem: null,
    };
    const resp = await addNewIssue(input).unwrap();
    onCreateItem(resp);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{getIssueRegisterNameOption(issueBoard?.issueNameSetting, "new")}</ModalHeader>
      <ModalBody>
        <McInput
          autoFocus
          placeholder={`Skriv inn navn på ${getIssueRegisterNameOption(
            issueBoard?.issueNameSetting,
            "new",
          )?.toLowerCase()}`}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <VSpace />
      </ModalBody>
      <ModalFooter>
        <Button disabled={name === "" || isLoading === true} color="success" onClick={() => add()}>
          OPPRETT
        </Button>
        <Button color="secondary" onClick={toggle}>
          Avbryt
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddIssueModal;
