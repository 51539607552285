import { IColumnTemplate, IRisiko, IRisikoEvaluationCategory, IRiskoBoard } from "../../../../../types/global.types";
import { ToastMessagesEnums } from "../../../frontendConstants";
import { ISelectedGisMap } from "../../../types";
import {
  ADD_RISIKO,
  DELETE_RISIKO,
  RESET_RISIKO,
  SET_ACTIVE_RISIKO_ACTION_ID,
  SET_ACTIVE_RISIKO_BOARD,
  SET_ACTIVE_RISIKO_TAB,
  SET_ACTIVE_VIEW_RISIKO,
  SET_EVALUATIONBAR_OPEN,
  SET_FILTRED_BY_CHART_OR_MATRIX_RISIKO_IDS,
  SET_GIS_WAS_LOADED,
  SET_GLOBAL_SEARCH_STRING,
  SET_RISIKO_BOARDS,
  SET_RISIKO_CATEGORIES,
  SET_RISIKO_DOCX_TEMPLATES,
  SET_RISIKO_ITEMS,
  SET_RISIO_BOARD_TEMPLATES,
  SET_RISKO_COLUMN_TEMPLATE,
  SET_SELECTED_RISIKO,
  SET_SELECTED_RISIKO_GIS_MAP,
  UPDATE_RISIKO_ITEM,
} from "./constants";
import { ISelectedRisiko, RisikoViewEnum } from "./reducer";
import { doDeleteRisiko, doGetRisikoDocxTemplates, doGetRisikos, doGetSingleRisiko } from "./risikoAPI";
import { addRisikoEvaluationKeysToRisiko } from "./util";
import { doGetRisikoBoardTemplates } from "./riskoBoardAPI";
import { AppThunk } from "../../../store";
import { removeAppModalInRedux, setShowSuccessMessageThunk } from "../../../global/globalSlice";

export const fetchRisikosAndSetInRedux =
  (risikoBoard: IRiskoBoard, risikoEvaluationCategories, projectId: string): AppThunk =>
  async (dispatch: any, getState) => {
    let items = await doGetRisikos(projectId, risikoBoard._id);
    let itemsWihtEvaluationsObj = items.map((item) => {
      return addRisikoEvaluationKeysToRisiko(item, risikoEvaluationCategories, risikoBoard);
    });
    dispatch({
      type: SET_RISIKO_ITEMS,
      payload: {
        items: itemsWihtEvaluationsObj,
      },
    });
  };

export const fetchRisikoAndReplaceItInRedux =
  (itemId: string): AppThunk =>
  async (dispatch: any, getState) => {
    if (itemId == null) {
      return null;
    }
    let { project } = getState().adminReducer;
    let { items, categories, activeBoard } = getState().risikoReducer;
    if (items == null) {
      return;
    }
    const index = items.findIndex((i) => i._id === itemId);
    const item = await doGetSingleRisiko(project._id, itemId);
    const itemWithEvaluationKeys = addRisikoEvaluationKeysToRisiko(item, categories, activeBoard);
    dispatch({
      type: UPDATE_RISIKO_ITEM,
      payload: {
        itemIndex: index,
        item: itemWithEvaluationKeys,
      },
    });
  };

export const addRisikoRedux =
  (item: IRisiko): AppThunk =>
  async (dispatch: any, getState) => {
    let { categories, activeBoard } = getState().risikoReducer;
    const itemWithEvaluationKeys = addRisikoEvaluationKeysToRisiko(item, categories, activeBoard);
    dispatch({
      type: ADD_RISIKO,
      payload: {
        item: itemWithEvaluationKeys,
      },
    });
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.RISIKO_ADDED));
  };

export const archiveRisikoAndRemoveInRedux =
  (risikoId: string): AppThunk =>
  async (dispatch: any, getState) => {
    let { items } = getState().risikoReducer;
    const project = getState().adminReducer.project;
    await doDeleteRisiko(project._id, risikoId);
    const index = items.findIndex((item) => item._id === risikoId);
    if (index !== -1) {
      dispatch({
        type: DELETE_RISIKO,
        payload: {
          index,
        },
      });
      dispatch(removeAppModalInRedux(risikoId));
      dispatch(setShowSuccessMessageThunk("Risiko arkivert"));
      dispatch(setSelectedRisikoInRedux(null));
    } else {
      console.log("index is -1!");
    }
  };

export const setRisikoCategoriesInRedux =
  (categories: IRisikoEvaluationCategory[]): AppThunk =>
  async (dispatch: any, getState) => {
    dispatch({
      type: SET_RISIKO_CATEGORIES,
      payload: {
        categories,
      },
    });
  };

export const setRisikoBoardsInRedux =
  (boards: IRiskoBoard[]): AppThunk =>
  async (dispatch: any, getState) => {
    dispatch({
      type: SET_RISIKO_BOARDS,
      payload: {
        boards,
      },
    });
  };

export const setActiveRisikoBoard =
  (activeBoard: IRiskoBoard): AppThunk =>
  async (dispatch: any, getState) => {
    dispatch({
      type: SET_ACTIVE_RISIKO_BOARD,
      payload: {
        activeBoard,
      },
    });
  };
export const setActiveRiskoTabRedux =
  (activeTab): AppThunk =>
  async (dispatch: any, getState) => {
    dispatch({
      type: SET_ACTIVE_RISIKO_TAB,
      payload: {
        activeTab,
      },
    });
  };

export const setEvaluationBarOpenInRedux =
  (evaluationBarOpen: boolean): AppThunk =>
  async (dispatch: any, getState) => {
    dispatch({
      type: SET_EVALUATIONBAR_OPEN,
      payload: {
        evaluationBarOpen,
      },
    });
  };

export const resetRisikoRedux = () => async (dispatch) => {
  dispatch({
    type: RESET_RISIKO,
  });
};

export const setRiskoColumnTemplateInRedux = (activeColumnTemplate: IColumnTemplate) => async (dispatch) => {
  dispatch({
    type: SET_RISKO_COLUMN_TEMPLATE,
    payload: {
      activeColumnTemplate,
    },
  });
};

export const setFiltredByChartOrMatrixRisikoIdsInRedux =
  (filtredByChartOrMatrixRiskoIds: string[]) => async (dispatch) => {
    dispatch({
      type: SET_FILTRED_BY_CHART_OR_MATRIX_RISIKO_IDS,
      payload: {
        filtredByChartOrMatrixRiskoIds,
      },
    });
  };

export const setSelectedRisikoInRedux =
  (_selectedRisiko: ISelectedRisiko): AppThunk =>
  async (dispatch: any, getState) => {
    let { selectedRisiko } = getState().risikoReducer;
    if (_selectedRisiko == null) {
      dispatch(removeAppModalInRedux(selectedRisiko?._id));
    }

    dispatch({
      type: SET_SELECTED_RISIKO,
      payload: {
        selectedRisiko: _selectedRisiko,
      },
    });
  };

export const setGlobalSearchStringInRisikoRedux = (globalSearchString: string) => async (dispatch) => {
  dispatch({
    type: SET_GLOBAL_SEARCH_STRING,
    payload: {
      globalSearchString,
    },
  });
};

export const setActiveRisikoViewRedux =
  (view: RisikoViewEnum): AppThunk =>
  async (dispatch: any, getState) => {
    let { activeView } = getState().risikoReducer;
    if (activeView === view || view == null) {
      return;
    }

    dispatch({
      type: SET_ACTIVE_VIEW_RISIKO,
      payload: {
        activeView: view,
      },
    });

    setTimeout(() => {
      if (view === RisikoViewEnum.GIS) {
        dispatch({
          type: SET_GIS_WAS_LOADED,
          payload: {
            bool: true,
          },
        });
      }
    }, 100);
  };

export const setActiveRisikoActionIdInRedux = (activeRiskoActionId: string) => async (dispatch) => {
  dispatch({
    type: SET_ACTIVE_RISIKO_ACTION_ID,
    payload: {
      activeRiskoActionId,
    },
  });
};

export const setSelectedGisMapInRisikoRedux = (selectedGisMap: ISelectedGisMap) => async (dispatch) => {
  dispatch({
    type: SET_SELECTED_RISIKO_GIS_MAP,
    payload: selectedGisMap,
  });
};

export const fetchAndSetRisikoDocxTemplates = (): AppThunk => async (dispatch: any, getState) => {
  let { project } = getState().adminReducer;
  if (project == null) {
    console.warn("project not loaded");
    return;
  }
  const docxTemplates = await doGetRisikoDocxTemplates(project._id);
  dispatch({
    type: SET_RISIKO_DOCX_TEMPLATES,
    payload: docxTemplates,
  });
};

export const fetchAndSetRisikoBoardTemplatesThunk = (): AppThunk => async (dispatch: any, getState) => {
  let { project } = getState().adminReducer;
  if (project == null) {
    console.warn("project not loaded");
    return;
  }
  const risikoBoardTemplates = await doGetRisikoBoardTemplates(project._id);
  dispatch({
    type: SET_RISIO_BOARD_TEMPLATES,
    payload: risikoBoardTemplates,
  });
};
