import { ICheckTypes, ISingleCheckType } from "mc-shared/zod/checklistSchema";
import * as R from "ramda";
import React from "react";
import { Button } from "reactstrap";
import { VSpace } from "../../../components";
import { Checkbox, HSpace, McInput } from "../../../components/";

const CheckTypesSelector: React.FC<{
  onChange: (types: ICheckTypes) => void;
  checkTypes: ICheckTypes;
  disabled?: boolean;
  inputIsEditable: boolean;
}> = ({ onChange: setCheckTypes, checkTypes, disabled, inputIsEditable }) => {
  const [showMore, setShowMore] = React.useState<boolean>(false);
  return (
    <div className="d-flex flex-column">
      <SingleChecklistType
        disabled
        inputIsEditable
        checklistType={checkTypes.check1}
        onChecklistTypeChanged={(checklistType) => setCheckTypes({ ...checkTypes, check1: checklistType })}
      />
      <SingleChecklistType
        disabled={false}
        inputIsEditable
        checklistType={checkTypes.check2}
        onChecklistTypeChanged={(checklistType) => setCheckTypes({ ...checkTypes, check2: checklistType })}
      />
      <SingleChecklistType
        disabled={false}
        inputIsEditable
        checklistType={checkTypes.check3}
        onChecklistTypeChanged={(checklistType) => setCheckTypes({ ...checkTypes, check3: checklistType })}
      />
      <div>
        <Button className="p-0 m-0" color="link" onClick={() => setShowMore(!showMore)}>
          {showMore === true ? "-Vis færre" : "+Vis flere"}
        </Button>
      </div>
      {showMore === true && (
        <>
          <p className="mr-2">
            <i className="fa fa-info-circle fa-fw text-info" />
            OBS! Disse rollene kommer ikke med i generert utskrift
          </p>
          {Object.keys(removeChecklistType123(checkTypes)).map((key) => (
            <SingleChecklistType
              disabled={false}
              inputIsEditable
              checklistType={checkTypes[key]}
              onChecklistTypeChanged={(checklistType) => setCheckTypes({ ...checkTypes, [key]: checklistType })}
            />
          ))}
        </>
      )}
    </div>
  );
};

const removeChecklistType123 = (checkTypes: ICheckTypes) => {
  let newCheckTypes = R.clone(checkTypes);
  delete newCheckTypes.check1;
  delete newCheckTypes.check2;
  delete newCheckTypes.check3;

  //Add checks here if needed

  return newCheckTypes;
};

export default CheckTypesSelector;

const SingleChecklistType: React.FC<{
  inputIsEditable: boolean;
  disabled: boolean;
  checklistType: ISingleCheckType;
  onChecklistTypeChanged: (checklisType: ISingleCheckType) => void;
}> = ({ inputIsEditable, disabled, checklistType, onChecklistTypeChanged }) => {
  return (
    <>
      <div className="d-flex">
        <Checkbox
          disabled={disabled}
          isChecked={checklistType.active}
          setChecked={() => onChecklistTypeChanged({ ...checklistType, active: !checklistType.active })}
          text={inputIsEditable === false ? checklistType.name : ""}
        />
        {inputIsEditable === true && (
          <>
            <HSpace />
            <McInput
              required={true}
              disabled={checklistType.active === false}
              value={checklistType.name}
              onBlur={(e) => onChecklistTypeChanged({ ...checklistType, name: e.target.value })}
            />
          </>
        )}
      </div>
      <VSpace />
    </>
  );
};
