import { AllEnterpriseModule, LicenseManager, ModuleRegistry, provideGlobalGridOptions } from "ag-grid-enterprise";
import Axios from "axios";
import * as R from "ramda";
import {
  FlatHierarchy,
  IColumnTemplate,
  IDisciplin,
  IMilestone,
  IOrgChart,
  IPNSProps,
  IProject,
  IProjectArcGis,
  IProjectMcBimSettings,
} from "../../../types/global.types";
import { IMcBimMongo } from "../../../types/mcbimMono.types";
import { apiSlice } from "../apiSlice";
import { ToastMessagesEnums } from "../frontendConstants";
import { AppThunk } from "../store";
import {
  RESET_PROJECT,
  SET_DISCIPLINES,
  SET_MCBIM,
  SET_MCBIM_PROJECT_SPACES,
  SET_MILESTONES,
  SET_ORG_CHART,
  SET_PNS,
  SET_PROJECT,
  SET_PROJECT_APPS,
  SET_PROJECT_ARC_GIS,
  SET_PROJECT_NAME,
  SET_PROJECT_USE_DASHBOARD_AS_DEFAULT,
  SET_RISIKOCOLUMN_TEMPLATES,
  SET_USE_DEFAULT_DISCIPLINES,
  UPDATE_MEMBER_GROUP,
} from "./constants";
import { doCheckIfUserIsMemberOfProject, doUpdateMcBim, doUpdatePns } from "./projectApi";
import {
  doAutoRefreshMaconomyUserList,
  doGetApps,
  doGetIsDev,
  doGetIsProduction,
  doGetSingleProject,
} from "./routes/admin/restQueries";
import { resetMcBimInRedux } from "./routes/admin/mcbim/mcBimSlice";
import { resetIssueRedux } from "./routes/issuesApp/issueSlice";
import { resetProjectDashboardRedux } from "./routes/projectdashboard/projectDashboardSlice";
import { resetRisikoRedux } from "./routes/risiko/actions";
import {
  loadProjectsThunk,
  setAppsInRedux,
  setIsProductionInRedux,
  setShowSuccessMessageThunk,
  setUserInRedux,
} from "../global/globalSlice";

export const logicWhenAppStarts = (history) => async (dispatch) => {
  const pathName = history.location.pathname;
  if (
    pathName.includes("password-reset") === true ||
    pathName.includes("accept-invite") === true ||
    pathName.includes("login") === true
  ) {
    return;
  }
  const isProduction = await doGetIsProduction();
  const isDev = await doGetIsDev();
  dispatch(setIsProductionInRedux(isProduction));
  ModuleRegistry.registerModules([AllEnterpriseModule]);
  LicenseManager.setLicenseKey(
    "Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-059505}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Multiconsult}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{MCApps}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{MCApps}_need_to_be_licensed___{MCApps}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{6_May_2025}____[v3]_[0102]_MTc0NjQ4NjAwMDAwMA==a97d592e91289cf9525d2d4e5c1de16b",
  );
  provideGlobalGridOptions({ theme: "legacy" });
  const apps = await doGetApps();
  dispatch(setAppsInRedux(apps));
  Axios.get(`/api/loggedin?path=${history.location.pathname}`)
    .then((userCall) => {
      dispatch(setUserInRedux(userCall.data));
    })
    .catch((error) => {
      if (error.response?.data?.code === "USER_TERMINATED") {
        history.push("/terminated");
        return;
      }
      if (error?.response?.data?.path != null) {
        if (error.response.data.path !== "/projects") {
          localStorage.setItem("gotolink", `${error.response.data.path}${history.location.search}`);
          if (error.response.data.path?.includes("doksam")) {
            window.location.assign("/api/login");
            return;
          }
          history.push("/login");
          return;
        } else {
          // User is not on login page and is not loggedin
          if (isDev === true) {
            history.push("/login");
          }
          if (isDev === false) {
            if (isProduction === true) {
              window.location.href = "https://mcapps.multiconsult.no/start";
            }
            if (isProduction === false) {
              window.location.href = "https://mcapps-test.multiconsult.no/start";
            }
          }
        }
      } else {
        history.push("/login");
      }
    });
};

export const logicWhenProjectOpens =
  (match, history, sync = false) =>
  async (dispatch) => {
    dispatch(resetProjectInRedux());
    let isLoggedin = false;
    try {
      const userCall = await Axios.get("/api/loggedin");
      const userIsMemberOfProject = await doCheckIfUserIsMemberOfProject(match.params.projectId);
      if (userIsMemberOfProject === false) {
        return history.push("/projects");
      }
      dispatch(apiSlice.util.resetApiState());
      dispatch(loadProject(match.params.projectId, sync));
      isLoggedin = true;
      dispatch(setUserInRedux(userCall.data));
      dispatch(loadProjectsThunk());
      dispatch(resetProjectDashboardRedux());
    } catch (error) {
      if (isLoggedin) {
        throw error;
      } else {
        return alert("Du har ikke tilgang til dette prosjektet");
      }
    }
  };

export const updateRecentProjectsList = (projectId: number) => {
  try {
    const recentProjects = JSON.parse(localStorage.getItem("sortByRecentProject") || "null") || {
      isSortByRecent: true,
      recentItemIds: [],
    };
    const updatedRecentItemIds = [projectId, ...recentProjects.recentItemIds.filter((item) => item !== projectId)];
    const updatedRecentProjects = {
      isSortedByRecent: recentProjects.isSortedByRecent,
      recentItemIds: updatedRecentItemIds,
    };

    localStorage.setItem("sortByRecentProject", JSON.stringify(updatedRecentProjects));
  } catch (error) {
    console.error("Failed to update recent projects list", error);
  }
};

export const loadProject =
  (projectId: string, sync = false) =>
  async (dispatch) => {
    if (projectId == null) {
      throw new Error("ProjectId cannot be undefined");
    }
    const project = await doGetSingleProject(projectId);
    let projectToSet: IProject = R.clone(project);
    projectToSet.members = projectToSet.members.filter(
      (member) => member.user != null && member.user?.name != null && member.user?.maconomyStatus !== "TERMINATED",
    );
    dispatch(resetRisikoRedux());
    dispatch(resetIssueRedux());
    dispatch(resetMcBimInRedux());
    dispatch({
      type: SET_PROJECT,
      payload: {
        project: projectToSet,
      },
    });
    updateRecentProjectsList(project.maconomy.projectNo);
    if (sync) {
      try {
        doAutoRefreshMaconomyUserList(project._id);
      } catch (error) {
        console.error(error);
      }
    }
  };

export const setProjectAppsInRedux = (projectApps) => async (dispatch) => {
  dispatch({
    type: SET_PROJECT_APPS,
    payload: {
      apps: projectApps,
    },
  });
};

export const setProjectMemberGroupInRedux = (group: string, memberIndex) => async (dispatch) => {
  dispatch({
    type: UPDATE_MEMBER_GROUP,
    payload: {
      group,
      memberIndex,
    },
  });
};

export const setProjectNameInRedux = (name: string) => async (dispatch) => {
  dispatch({
    type: SET_PROJECT_NAME,
    payload: {
      name,
    },
  });
};

export const setProjectUseDashboardAsDefault = (useDashboardAsDefaultStartPage: boolean) => async (dispatch) => {
  dispatch({
    type: SET_PROJECT_USE_DASHBOARD_AS_DEFAULT,
    payload: {
      useDashboardAsDefaultStartPage,
    },
  });
};

export const setOrgChartInRedux = (orgChart: IOrgChart) => async (dispatch) => {
  dispatch({
    type: SET_ORG_CHART,
    payload: {
      orgChart,
    },
  });
};

export const setProjectArcgisInRedux = (arcgis: IProjectArcGis) => async (dispatch) => {
  dispatch({
    type: SET_PROJECT_ARC_GIS,
    payload: {
      arcgis,
    },
  });
};

export const setRisikoColumnTemplatesinRedux = (risikoColumnTemplates: IColumnTemplate[]) => async (dispatch) => {
  dispatch({
    type: SET_RISIKOCOLUMN_TEMPLATES,
    payload: {
      risikoColumnTemplates,
    },
  });
};
export const resetProjectInRedux = () => (dispatch) => {
  dispatch({ type: RESET_PROJECT });
};

export const setUseDefaultDisciplinesInRedux = (useDefaultDisciplines: boolean) => async (dispatch) => {
  dispatch({
    type: SET_USE_DEFAULT_DISCIPLINES,
    payload: {
      useDefaultDisciplines,
    },
  });
};

export const setDisciplinesInRedux = (disciplines: IDisciplin[]) => async (dispatch) => {
  dispatch({
    type: SET_DISCIPLINES,
    payload: {
      disciplines,
    },
  });
};

export const setMilestonesInRedux = (milestones: IMilestone[]) => async (dispatch) => {
  dispatch({
    type: SET_MILESTONES,
    payload: {
      milestones,
    },
  });
};

export const updateMcBimThunk =
  (mcbim: IProjectMcBimSettings): AppThunk =>
  async (dispatch: any, getState) => {
    const project = getState().adminReducer.project;
    const resp = await doUpdateMcBim(project._id, mcbim);
    dispatch({
      type: SET_MCBIM,
      payload: {
        mcbim: resp,
      },
    });
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.UPDATED));
  };

export const updateProjectPnsAndSetInRedux =
  (flatHierarchy: FlatHierarchy<IPNSProps>): AppThunk =>
  async (dispatch: any, getState) => {
    const project = getState().adminReducer.project;
    const updatedPns = await doUpdatePns(project._id, flatHierarchy);
    dispatch({
      type: SET_PNS,
      payload: updatedPns,
    });
  };

export const setMcBimSpacesThunk = (mcbimSpaces: IMcBimMongo.SpaceBasicInfo[]) => async (dispatch) => {
  dispatch({
    type: SET_MCBIM_PROJECT_SPACES,
    payload: {
      mcbimSpaces,
    },
  });
};
