import { CellEditRequestEvent, ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import * as R from "ramda";
import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { IBoard, IIssue, IMember, IUser } from "../../../../../../../types/global.types";
import { AG_GRID_LOCALE_NOR } from "../../../../../agGridLocale";
import { Avatar, CopyIssueButton, CreateNewButton, DisplayDueDate } from "../../../../../components";
import { StatusBadgeRenderer } from "../../../../../components/ConnectedIssues/ConnectedIssues";
import { AppModalEnums } from "../../../../../frontendConstants";
import { addAppModalThunk } from "../../../../../global/globalSlice";
import { useAppDispatch } from "../../../../../hooks";
import { formatShortDate } from "../../../admin/selectors";
import {
  ISSUE_COLUMN_LABELS,
  ISSUE_COMPLETED_VALUE,
  ISSUE_DATAFILEDS_ENUM,
  ISSUE_EXPIRED,
  ISSUE_SECTIONS,
  ISSUE_STATES_OPTIONS,
} from "../../issueConstants";
import { IssueIdWithAksjonCompletedCount } from "../../IssueIdWithAksjonCompletedCount";
import {
  useCopyIssueMutation,
  useCreateIssueMutation,
  useDoUpdateIssueMutation,
  useGetConnectedTasksQuery,
} from "../../issueRTK";
import { getIssueActionNameOption } from "../../issueUtils";

const TaskMiniTable: React.FC<{
  issueId: string;
  issueLocalId: number | string;
  projectId: string;
  members: IMember[];
  issueBoardId: string;
  fullWidth: boolean;
  issueBoard?: IBoard;
}> = ({ issueId, projectId, members, issueBoardId, issueBoard }) => {
  const [copyOfTasks, setCopyOfTasks] = useState(null);
  const { data: tasks, isLoading: isFetchingTasks } = useGetConnectedTasksQuery({
    issueId: issueId,
    projectId: projectId,
  });

  const [addNewIssue, { isLoading, isSuccess }] = useCreateIssueMutation();

  const [updateIssue, { isLoading: isUpdating }] = useDoUpdateIssueMutation();

  React.useEffect(() => {
    if (tasks != null) {
      setCopyOfTasks(R.clone(tasks));
    }
  }, [tasks, isSuccess]);

  const [copyIssue, { isLoading: isCopying }] = useCopyIssueMutation();
  const copy = async (id: string, includeTasks: boolean): Promise<void> => {
    copyIssue({
      projectId: projectId,
      issueId: id,
      includeTasks: includeTasks,
    }).unwrap();
  };

  const history = useHistory();
  const setTask = (issue: IIssue) => {
    const searchParams = new URLSearchParams(history.location.search);
    searchParams.set("id", issue._id);
    history.push(`${history.location.pathname}?${searchParams.toString()}`);
    dispatch(
      addAppModalThunk({
        itemId: issue._id,
        boardId: issueBoardId,
        projectId,
        app: AppModalEnums.ISSUE,
      }),
    );
  };

  const dispatch = useAppDispatch();

  const onCellEditRequest = (event: CellEditRequestEvent<IIssue>) => {
    const issue = event.data;
    let updateValue;

    if (event.colDef.field.startsWith("assignedTo")) {
      updateValue = typeof event.newValue !== "string" ? event.newValue?._id : event.newValue;
    } else if (event.colDef.field.startsWith("status")) {
      updateValue = event.newValue.value;
    } else if (event.colDef.field.startsWith("priority")) {
      updateValue = event.newValue.key;
    } else if (event.colDef.field.startsWith("dates")) {
      if (event.newValue == null) {
        updateValue = "";
      } else {
        updateValue = event.newValue;

        if (updateValue.toISOString() === event.oldValue?.toISOString()) {
          return;
        }
      }
    } else {
      updateValue = event.newValue;
    }

    updateIssue({
      projectId: projectId,
      issueId: issue._id,
      attr: event.colDef.field,
      value: updateValue,
      oldValue: event.oldValue?.name ?? "",
      issueBoardId: issueBoardId,
    });
  };

  const agDateCellEditor = (dateType: string) => {
    return {
      editable: true,
      type: ISSUE_SECTIONS.DATES,
      cellEditor: "agDateCellEditor",
      valueGetter: (params) => {
        if (params.data.dates && params.data.dates[dateType]) {
          return new Date(params.data.dates[dateType]);
        } else {
          return null;
        }
      },
    };
  };

  const agUserCellEditor = (userType: string) => {
    return {
      minWidth: 150,
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      valueGetter: (params) => {
        return params.data[userType] ?? null;
      },
      cellEditorParams: {
        values: members.map((m) => m.user),
        // cellRenderer: (params) => <UserCellRenderer height="15px" user={params.value} />,
        allowTyping: true,
        filterList: true,
        formatValue: (value) => value?.name ?? "",
      },
    };
  };

  const getAgGridIssueColTextAndLabel = (dataField: ISSUE_DATAFILEDS_ENUM): { field: string; headerName: string } => {
    return {
      field: dataField,
      headerName: ISSUE_COLUMN_LABELS[dataField],
    };
  };

  const AgGridUserCellRenderer: React.FC<{ user: IUser }> = ({ user }) => {
    return user != null ? (
      <div className="d-flex align-items-center">
        <Avatar id={user._id} />
        <div className="ml-2">{user.name}</div>
      </div>
    ) : (
      <div></div>
    );
  };

  const columns: ColDef<any>[] = [
    {
      ...getAgGridIssueColTextAndLabel(ISSUE_DATAFILEDS_ENUM.ID),
      maxWidth: 165,
      // sort: "desc",
      cellRenderer: (params) => (
        <IssueIdWithAksjonCompletedCount id={params.value} issue={params.data} setSelectedIssue={setTask} />
      ),
    },
    {
      ...getAgGridIssueColTextAndLabel(ISSUE_DATAFILEDS_ENUM.TITLE),
      minWidth: 200,
      // maxWidth: 400,
      flex: 3,
      editable: true,
    },
    {
      ...getAgGridIssueColTextAndLabel(ISSUE_DATAFILEDS_ENUM.ASSIGNEDTO_NAME),
      ...agUserCellEditor("assignedTo"),
      cellRenderer: (params) => <AgGridUserCellRenderer user={params.data.assignedTo} />,
    },
    {
      ...getAgGridIssueColTextAndLabel(ISSUE_DATAFILEDS_ENUM.DATES_DUE),
      width: 127,
      ...agDateCellEditor("due"),

      cellRenderer: (params) => {
        if (moment(params.value).isValid() === false || params.value == null || !params.value) {
          return "-";
        }
        return (
          <DisplayDueDate
            dueDate={params.value}
            isComplete={
              params.data != null &&
              (params.data.status === ISSUE_COMPLETED_VALUE || params.data.status === ISSUE_EXPIRED)
            }
          />
        );
      },
    },
    {
      ...getAgGridIssueColTextAndLabel(ISSUE_DATAFILEDS_ENUM.DATES_COMPLETED),
      // minWidth: 125,
      ...agDateCellEditor("completed"),
      cellRenderer: (params) => formatShortDate(params.value, true),
    },
    {
      ...getAgGridIssueColTextAndLabel(ISSUE_DATAFILEDS_ENUM.STATUS),
      editable: true,
      valueGetter: (params) => {
        const status = ISSUE_STATES_OPTIONS.find((opt) => opt.value === params.data.status);
        return status;
      },
      valueSetter: (params) => {
        return params.newValue.value;
      },
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: ISSUE_STATES_OPTIONS,
        formatValue: (value) => value.value,
        valueListMaxHeight: 220,
      },
      // minWidth: 130,
      cellRenderer: StatusBadgeRenderer,
    },
    {
      field: "_id",
      headerName: "Lag kopi",
      width: 90,
      minWidth: 90,
      suppressMovable: true,
      resizable: false,
      sortable: false,
      cellClass: "grid-display-block",
      cellRenderer: (params) => (
        <div className="w-100 d-flex justify-content-center">
          <CopyIssueButton issueId={params.data._id} taskOrIssue={"TASK"} copyAndSet={copy} />
        </div>
      ),
    },
  ];

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      minWidth: 50,
      suppressMovable: false,
      resizable: true,
      suppressHeaderMenuButton: true,
      editable: false,
      // wrapText: true,
      autoHeight: true,
      flex: 1,
      autoHeaderHeight: true,
      cellClass: "p-1",
      headerClass: "px-2 py-1",
      cellStyle: {
        whiteSpace: "normal",
        lineHeight: "1.5",
        wordBreak: "break-word",
      },
    }),
    [],
  );

  return (
    <>
      <div className="bg-white" id="dropdownContainer">
        <div className="d-flex align-items-center">
          <h5 className="mr-2 mb-0">{getIssueActionNameOption(issueBoard?.taskNameSetting, "plural")}</h5>
          <CreateNewButton
            disabled={isLoading}
            outline
            onClick={() =>
              addNewIssue({
                projectId: projectId,
                issueBoardId: issueBoardId,
                issueTitle: "",
                taskOrIssue: "TASK",
                issueIdIfItIsATask: issueId,
              })
            }
            tooltip={getIssueActionNameOption(issueBoard?.taskNameSetting, "new")}
            size="sm"
          />
        </div>
        <div className="ag-theme-quartz h-100 w-100">
          <AgGridReact
            defaultColDef={defaultColDef}
            rowHeight={54}
            loading={isFetchingTasks || isLoading}
            popupParent={document.querySelector("#dropdownContainer") || undefined}
            domLayout={"autoHeight"}
            columnDefs={columns}
            getRowId={(p) => p.data._id}
            rowData={copyOfTasks as IIssue[]}
            stopEditingWhenCellsLoseFocus={true}
            animateRows={false}
            readOnlyEdit={true}
            onCellEditRequest={onCellEditRequest}
            suppressContextMenu={true}
            maintainColumnOrder={true}
            localeText={AG_GRID_LOCALE_NOR}
          />
        </div>
      </div>
    </>
  );
};

export default TaskMiniTable;
