import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Badge } from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { IBootstrapTableColumnConfig } from "../../../../types";
import { STANDARD_COLORS } from "../../../globalConstants";
import { getSelectedGisMapToLoadByDefault } from "../../../utils";
import { NoGisGroup } from "../../gis/ArcGisMap";
import { fetchRisikoAndReplaceItInRedux, setSelectedGisMapInRisikoRedux } from "../actions";
import { doUpdateRisiko } from "../risikoAPI";
import MissingRiskoModal from "./MissingRiskoModal";
import RisikoActionBar from "./RisikoActionBar";
import RisikoGIS from "./RisikoGIS";
import RisikoTable from "./RisikoTable/RisikoTable";

const RisikoTab: React.FC<{
  columns: IBootstrapTableColumnConfig[];
  setSelectedItemInMainTable: (riskoId: string, boardId: string, projectId: string) => void;
}> = ({ columns, setSelectedItemInMainTable }) => {
  const [showNoRisiko, setShowNoRisiko] = React.useState<boolean>(false);

  const dispatch = useAppDispatch();

  const project = useAppSelector((state) => state.adminReducer.project);
  const items = useAppSelector((state) => state.risikoReducer.items);
  const categories = useAppSelector((state) => state.risikoReducer.categories);
  const activeBoard = useAppSelector((state) => state.risikoReducer.activeBoard);
  const activeView = useAppSelector((state) => state.risikoReducer.activeView);
  const gisWasLoaded = useAppSelector((state) => state.risikoReducer.gisWasLoaded);
  const selectedGisMap = useAppSelector((state) => state.risikoReducer.selectedGisMap);

  React.useEffect(() => {
    if (selectedGisMap == null && project.arcgis?.groupId != null) {
      const _selectedGisMap = getSelectedGisMapToLoadByDefault(project.arcgis);
      dispatch(setSelectedGisMapInRisikoRedux(_selectedGisMap));
    }
  }, []);

  let history = useHistory();

  useEffect(() => {
    if (history.location.search.indexOf("id") !== -1) {
      const indexOf = history.location.search.indexOf("=");
      const id = history.location.search.substr(indexOf + 1);
      if (id != null) {
        const risiko = items && items.find((item) => item._id === id);
        if (risiko != null) {
          setSelectedItemInMainTable(id, activeBoard._id, project._id);
        } else {
          setShowNoRisiko(true);
          history.replace(`/project/${project._id}/risiko/${activeBoard._id}`);
        }
      }
    }
  }, [history.location.search]);

  const saveRisiko = async (dataField: string, risikoId: string, newValue, oldValue) => {
    if (dataField.startsWith("evaluations")) {
      const column = columns.find((col) => col.dataField === dataField);
      const evaluation = categories.find((category) => category._id === column.evaluationCategoryId);
      await doUpdateRisiko(project._id, risikoId, column.dataField, newValue, oldValue, evaluation._id);
    } else {
      await doUpdateRisiko(project._id, risikoId, dataField, newValue, oldValue);
    }
    await dispatch(fetchRisikoAndReplaceItInRedux(risikoId));
  };

  return (
    <div className="h-100 d-flex flex-column flex-child-container">
      {showNoRisiko && <MissingRiskoModal close={() => setShowNoRisiko(false)} />}
      <RisikoActionBar />
      <hr className="p-0 m-0" />
      {items != null && columns != null && columns.length > 0 && (
        <>
          {activeView === "TABLE" && (
            <RisikoTable columns={columns} columnTemplates={project.risikoColumnTemplates} saveCb={saveRisiko} />
          )}
          {activeView === "GIS" && (
            <>
              {gisWasLoaded === true && (
                <>
                  {selectedGisMap?.mapId != null ? (
                    <RisikoGIS
                      projectId={project._id}
                      boardId={activeBoard._id}
                      mapId={selectedGisMap.mapId}
                      risikos={items}
                    />
                  ) : (
                    <NoGisGroup />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default RisikoTab;

export const RisikoScoreFormatter = (cell, row, rowIndex, extraData) => {
  try {
    const evaluationObj = row.evaluationsObj[extraData.columnName.replace(/\s/g, "_")];
    const score = evaluationObj.score;

    if (evaluationObj.color != null) {
      return (
        <Badge
          style={{
            padding: "8px 12px",
            backgroundColor: `${STANDARD_COLORS[evaluationObj.color]}cc`,
            width: "100px",
          }}
          className="d-flex greybadge"
        >
          <div style={{ color: "#3c3c3c", fontSize: "12px", margin: "auto" }}>{score}</div>
        </Badge>
      );
    } else {
      return <div style={{ marginLeft: "6px" }}>-</div>;
    }
  } catch (err) {
    console.log(err);
    return <div style={{ marginLeft: "6px" }}>error</div>;
  }
};
