import Axios from "axios";
import { IStats } from "../../../../../types/stats.types";

export const doGetProjectStats = async (
  fromDate: Date,
  toDate: Date,
  appName: string,
): Promise<IStats.TotalAppStarts[]> => {
  try {
    const fetch = await Axios.post(`/api/stats/projects`, { fromDate, toDate, appName });
    return fetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetSingleProjectStats = async (
  fromDate: Date,
  toDate: Date,
  projectId: string,
): Promise<IStats.ProjectStats> => {
  try {
    const fetch = await Axios.post(`/api/stats/project/${projectId}`, { fromDate, toDate });
    return fetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};
