import { ColDef } from "ag-grid-community";
import { IMcBimAttrValue } from "mc-shared/zod/mcbimSchema";
import moment from "moment";
import * as R from "ramda";
import { useEffect, useState } from "react";
import paginationFactory from "react-bootstrap-table2-paginator";
import { utils, writeFile } from "xlsx";
import { IIssue, IMultiOptionsColors, IMyTask, IProject, IProjectArcGis } from "../../../types/global.types";
import { IMultiPageExcelExport } from "../components/Bim360DirectoryAccess/types";
import { DimensionEnums } from "../frontendConstants";
import { IBootstrapTableColumnConfig, ISelectedGisMap, ISODate, ITaskCompleteCount } from "../types";
import { formatShortDate } from "./routes/admin/selectors";
import { escapeRegExp } from "lodash";

export const getExcelRows = (columns: IBootstrapTableColumnConfig[], rows: any[]) => {
  return R.clone(rows).reduce((acc, row) => {
    const attr = [];
    columns?.forEach((column) => {
      const value = R.path(column.dataField.split("."), row);
      let formattedValue = value;
      if (
        (moment(value).isValid() &&
          (column.dataField.toUpperCase().includes("TIME") || column.dataField.toUpperCase().includes("DATE"))) ||
        column.dataField.toUpperCase().includes("CREATEDAT") ||
        column.dataField.toUpperCase().includes("UPDATEDAT")
      ) {
        try {
          formattedValue = formatShortDate(String(value));
        } catch (error) {
          console.error(error);
        }
      }
      attr.push(formattedValue);
    });
    acc.push(attr);
    return acc;
  }, []);
};

export const getAgGridRows = (columns: ColDef[], rows: any[]): any[][] => {
  return R.clone(rows).reduce((acc, row) => {
    const attr: any[] = [];
    columns?.forEach((column) => {
      const value = R.path(column.field?.split(".") || [], row);
      let formattedValue = value;
      if (
        (moment(value).isValid() &&
          (column.field?.toUpperCase().includes("TIME") || column.field?.toUpperCase().includes("DATE"))) ||
        column.field?.toUpperCase().includes("CREATEDAT") ||
        column.field?.toUpperCase().includes("UPDATEDAT")
      ) {
        try {
          formattedValue = formatShortDate(String(value));
        } catch (error) {
          console.error(error);
        }
      }
      attr.push(formattedValue);
    });
    acc.push(attr);
    return acc;
  }, []);
};

export const multiPageExportExcel = (
  columns: IBootstrapTableColumnConfig[],
  pages: IMultiPageExcelExport[],
  fileprefix: string,
  ignoreColumns?: string[],
) => {
  const columnsToExport =
    ignoreColumns == null
      ? columns
      : columns.filter((column) => {
          return R.includes(column.dataField, ignoreColumns) === false;
        });

  const wb = utils.book_new();

  pages.forEach((valueArr) => {
    const rows = getExcelRows(columnsToExport, valueArr.arr);
    const excelData = [columnsToExport.map((col) => col.text), ...rows];
    const ws = utils.aoa_to_sheet(excelData);

    utils.book_append_sheet(wb, ws, valueArr.pageName);
  });

  writeFile(wb, `${fileprefix}_${JSON.stringify(new Date())}.xlsx`);
};

export const exportExcel = (
  columns: any[],
  arr: any[],
  fileprefix: string,
  ignoreColumns?: string[],
  isAgGrid?: boolean,
) => {
  const columnsToExport = columns?.filter((column) => {
    if (ignoreColumns == null) {
      return true;
    }
    const dataField = isAgGrid === true ? column.field : column.dataField;
    return R.includes(dataField, ignoreColumns) === false;
  });
  const rows = isAgGrid === true ? getAgGridRows(columnsToExport, arr) : getExcelRows(columnsToExport, arr);
  const excelData = [columnsToExport?.map((col) => (isAgGrid === true ? col.headerName : col.text)), ...rows];
  const ws = utils.aoa_to_sheet(excelData);
  const wb = utils.book_new();

  utils.book_append_sheet(wb, ws, "Data");
  writeFile(wb, `${fileprefix}_${JSON.stringify(new Date())}.xlsx`);
};

export const createDistinctDropdownFilter = (arr: any[], key?: string, useDisplayValue = false) => {
  try {
    const diff = function (a, b) {
      return a - b;
    };

    let filter;

    const uniqItems =
      key != null ? R.pipe(R.map(R.path(key.split("."))), R.uniq, R.sort(diff))(arr) : R.uniq(arr || []);

    filter = uniqItems.sort().reduce((acc, item) => {
      if (item != null) {
        let val = item;
        if (val === true) {
          val = "Sant";
        }
        if (val === false) {
          val = "Falskt";
        }

        if (useDisplayValue === true) {
          const arrValue = arr.find((i) => i.value === item);
          acc[item] = arrValue != null ? arrValue.displayValue : val;
        } else {
          acc[item] = val;
        }
      }
      return acc;
    }, {});
    return filter;
  } catch (error) {}
};

export const createMultiFilterOptions = (states: IMultiOptionsColors[]): object => {
  let obj = {};
  states.forEach((state) => {
    if (state.displayName != null) {
      obj[state.key] = state.displayName;
    } else {
      obj[state.key] = state.key;
    }
  });
  return obj;
};

export const isEmpty = (val: any): boolean => {
  return val == null || val === "" || val === undefined || val === "null" || val === "undefined";
};

export const getUserInitials = (name: string) => {
  if (name == null) {
    return "";
  }

  const names = name.split(" ");
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }

  return initials;
};

export const isWithinLastXDaysFrontend = (date: string, days: number) => {
  const thirtyDaysAgo = moment().subtract(days, "days");
  const mDate = moment(date);
  return moment(thirtyDaysAgo).isBefore(mDate);
};

export const count = (arr: any[], valFunc: any): number => {
  let count = 0;

  if (arr === null) {
    return count;
  }

  arr.forEach((item) => {
    if (valFunc(item)) {
      count++;
    }
  });

  return count;
};

export const summarizeByPropFrontend = (arr: any[], prop?: string): number => {
  if (arr == null) {
    return null;
  }
  if (prop != null) {
    let count = 0;
    arr.forEach((item) => {
      count = count + item[prop];
    });
    return count;
  } else {
    return R.sum(arr);
  }
};

export const sortArrayByDate = <T>(arr: T[], key: string, desc: boolean = true): T[] => {
  try {
    if (arr == null) {
      return [];
    }
    const sorted = R.clone(arr).sort(function compare(a, b) {
      var dateA = new Date(a[key]);
      var dateB = new Date(b[key]);
      // @ts-ignore
      return dateB.getTime() - dateA.getTime();
    });
    if (desc) {
      return sorted;
    } else {
      return sorted.reverse();
    }
  } catch (err) {
    console.warn("failde to sort by date");
    console.warn(err);
    return arr;
  }
};

export const sortArrayByNumber = <T>(arr: T[], key): T[] => {
  return [...arr].sort(function (a, b) {
    const valA = R.path(key.split("."), a);
    const valB = R.path(key.split("."), b);

    if (valA < valB) {
      return -1;
    }
    if (valA > valB) {
      return 1;
    }
    return 0;
  });
};

export const countArrByProp = (arr: any[], property: string, value: any): number => {
  if (arr == null || Array.isArray(arr) === false) {
    console.error("Array is null or not array!");
    return 0;
  }
  return arr.reduce((acc, item) => {
    if (item[property] === value) {
      acc++;
    }
    return acc;
  }, 0);
};

export const sortArrayByString = <T>(arr: T[], key: string): T[] => {
  if (arr == null) {
    return [];
  }
  return [...arr].sort(function (a, b) {
    const valA = R.path<string>(key.split("."), a);
    const valB = R.path<string>(key.split("."), b);

    const _a = valA == null ? "" : valA.toUpperCase();
    const _b = valB == null ? "" : valB.toUpperCase();

    if (_a < _b) {
      return -1;
    }
    if (_a > _b) {
      return 1;
    }
    return 0;
  });
};

export const getUniqItemsInStrings = (arr: any[], key: string): string[] => {
  return [...arr].reduce((acc, item) => {
    const val = R.path(key.split("."), item);
    if (acc.indexOf(val) === -1) {
      acc.push(val);
    }
    return acc;
  }, []);
};

export const getUniqItemsInObjects = <T>(arr: T[], key: string): T[] => {
  if (arr == null) {
    return [];
  }
  return [...arr].reduce((acc, item) => {
    const val = R.path(key.split("."), item);
    const itemExist =
      acc.find((_item) => {
        return R.path(key.split("."), _item) === val;
      }) != null;

    if (itemExist === false) {
      acc.push(item);
    }
    return acc;
  }, []);
};

export const formatTags = (data: { _id: string }[]): string[] => {
  //@ts-ignore
  return sortArrayByString(data, "_id").map((tag) => tag._id);
};

export const calcSum = (prop) => R.pipe(R.pluck(prop), R.sum);

// @ts-ignore
export const kFormatter = (num: number) => Math.sign(num) * (Math.abs(num) / 1000).toFixed(0);

export const thousandSeperator = (num: number) =>
  Math.round(num)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, "  ");

export const getHighestId = R.pipe(R.pluck("id"), R.reduce(R.max, -Infinity));

export const getHighestNumberInArr = (arr: number[]): number => Math.max(...arr);

export const getHighestNumberInArrWithObjects = (arr: any[], key: string): number => {
  if (arr.length === 0) {
    return 0;
  }
  return getHighestNumberInArr(R.pluck(key, arr));
};

export const kFormatWithThousandSeperator = R.pipe(kFormatter, thousandSeperator);

export const getArrWithNumbers = (highestNumberInArr: number): number[] => {
  const arr = [];
  for (let i = 1; i <= highestNumberInArr; i++) {
    arr.push(i);
  }
  return arr;
};

//TODO: Check nestled objects and arrays
export const stringExistOnAnyProperty = (object: object, str: string): boolean => {
  return Object.values(object).some((val) => {
    const stringVal = String(val);
    return typeof stringVal === "string" ? stringVal.toUpperCase().includes(str.toUpperCase()) : false;
  });
};

export const taskPassFilter = (
  isDateOverDue: boolean,
  searchText: string,
  app: string,
  project: string,
  task: IMyTask,
): boolean => {
  if (isDateOverDue === true) {
    if (task.dueDate == null) {
      return false;
    }
    if (dateIsExpired(task.dueDate) === false) {
      return false;
    }
  }
  if (searchText !== "") {
    if (
      task?.project.maconomy.name?.toLowerCase().includes(searchText.toLowerCase()) === false &&
      String(task?.project.maconomy.projectNo)?.toLowerCase().includes(searchText.toLowerCase()) === false &&
      task?.text?.toLocaleLowerCase().includes(searchText?.toLocaleLowerCase()) === false
    ) {
      return false;
    }
  }
  if (app != null) {
    if (app !== task.appNorsk) {
      return false;
    }
  }
  if (project != null) {
    if (project !== task.project?.maconomy?.name) {
      return false;
    }
  }
  return true;
};

export const dateIsExpired = (isoDate: ISODate): boolean => {
  if (isoDate == null) {
    return null;
  }
  if (new Date(isoDate).toISOString() === isoDate) {
    return moment().isAfter(isoDate);
  }
  return null;
};

export const blurInput = (handleEnter: () => void, handleEsc: () => void, isInvalid?: boolean) => {
  if (!isInvalid) {
    handleEnter();
  } else {
    handleEsc();
  }
};

export const blurOnEnter = (e: React.KeyboardEvent<HTMLInputElement>, handleEnter: () => void, isInvalid?: boolean) => {
  if (e.key === "Enter" && e.shiftKey) {
    if (!isInvalid) {
      handleEnter();
    }
  }
};

export const stopPropagationOnEscape = (e: React.KeyboardEvent<HTMLInputElement>, handleEsc: () => void) => {
  if (e.key === "Escape") {
    e.stopPropagation();
    handleEsc();
  }
};
export const immediateInterval = (callback: (...args: any[]) => void, ms: number) => {
  callback();
  return setInterval(callback, ms);
};

export const getSelectedGisMapToLoadByDefault = (arcgis: IProjectArcGis): ISelectedGisMap => {
  if (arcgis == null) {
    return null;
  }
  if (arcgis.default2dMap == null && arcgis.default3dMap == null) {
    return null;
  }
  if (arcgis.default2dMap != null) {
    return {
      dimension: DimensionEnums.TWODIMENSION,
      mapId: arcgis.default2dMap,
    };
  }
  if (arcgis.default3dMap != null) {
    return {
      dimension: DimensionEnums.THEREEDIMENSION,
      mapId: arcgis.default3dMap,
    };
  }
};

export const projectHasADefaultArcgisMap = (project: IProject): boolean => {
  return (project.arcgis?.default3dMap == null && project.arcgis?.default2dMap == null) === false;
};

export const projectHasValidArcGisConnectionWithMaps = (project: IProject): boolean => {
  return project.arcgis?.groupId != null && projectHasADefaultArcgisMap(project) === true;
};

export const removeItemFromArray = <T>(arr: T[], key: string, value: string): T[] => {
  return arr.filter((item) => R.path(key.split("."), item) !== value);
};

export const getDefaultBootstrapTablePaginationOptions = (nrOfItems: number) => {
  return paginationFactory({
    sizePerPage: 50,
    sizePerPageList: [
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "Vis alt",
        value: nrOfItems || 1000,
      },
    ],
  });
};

export const arraysEqual = (a, b): boolean => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (JSON.stringify(a[i]) !== JSON.stringify(b[i])) {
      return false;
    }
  }
  return true;
};

const hasUncompletedWithOverdueDates = (hasOverdueDates: boolean, completedNr: number, totalNr: number) => {
  return hasOverdueDates && completedNr !== totalNr;
};

export const getIssuesCompletedCount = (connectedIssues: IIssue[], darkText = true): ITaskCompleteCount => {
  const nonArchivedIssues = connectedIssues?.filter((issue) => issue.archived !== true) || [];
  const completedCount = countArrByProp(nonArchivedIssues, "status", "Fullført");
  const issueHasOverDueDates = nonArchivedIssues.some(
    (issue) => moment().isAfter(issue.dates?.due) && issue.status !== "Fullført",
  );
  return {
    darkText,
    totalNr: nonArchivedIssues.length,
    showWarning: hasUncompletedWithOverdueDates(issueHasOverDueDates, completedCount, nonArchivedIssues.length),
    completedCount: completedCount,
  };
};

export const isMyTasksUrl = (): boolean => {
  const tasksRegex = /\/tasks/;
  return tasksRegex.test(window.location.href);
};

export const emptyValueValidator = (newValue, row, column) => {
  if (newValue === "" || newValue == null) {
    return {
      valid: false,
      message: column.text + " kan ikke være tom",
    };
  }
  return true;
};

export const getPsetFromMcBimAttrStr = (value: IMcBimAttrValue) => {
  const split = value.split(".");
  return split[1];
};

export const getPropertyFromMcBimAttrStr = (value: IMcBimAttrValue) => {
  const split = value.split(".");
  return split[2];
};

export const mcbimAttrIsAValidPsetAndPropertyString = (str: string) =>
  getPsetFromMcBimAttrStr(str) !== "" && getPropertyFromMcBimAttrStr(str) !== "";

//format milliseconds to a string with minutes:seconds:milliseconds
//e.g 501 = 00:00:501, 1000 = 00:01:000, 60000 = 01:00:000
export const formatMillisecondsToString = (milliseconds: number): string => {
  if (milliseconds == null || milliseconds === 0) {
    return ""; //empty string if null/0/undefined
  }
  const minutes = Math.floor(milliseconds / 60000);
  const remainder = milliseconds % 60000;
  const seconds = Math.floor(remainder / 1000);
  const ms = remainder % 1000;
  return minutes + ":" + (seconds < 10 ? "0" : "") + seconds + ":" + (ms < 100 ? "0" : "") + (ms < 10 ? "0" : "") + ms;
};

export const getProjectIdFromUrl = (historyLocationPathName: string) => {
  return historyLocationPathName.split("/")[2];
};

export const getRandomString = (length: number) => {
  return Math.random()
    .toString(36)
    .substring(2, length + 2);
};
export const sortItemsByRecentIds = <T>(
  items: T[],
  recentIds: (string | number)[],
  idSelector: (item: T) => string | number,
): T[] => {
  const recentIndexMap = new Map();
  recentIds.forEach((id, index) => {
    recentIndexMap.set(id, index);
  });

  items.sort((a, b) => {
    const indexA = recentIndexMap.get(idSelector(a));
    const indexB = recentIndexMap.get(idSelector(b));

    if (indexA !== undefined && indexB !== undefined) {
      return indexA - indexB;
    }
    if (indexA !== undefined) {
      return -1;
    }
    if (indexB !== undefined) {
      return 1;
    }
    return 0;
  });

  return items;
};

export const isDeveloper = (userId: string) => {
  const developers = [
    { name: "Mathias", id: "61f9574c553da241919abdb8" },
    { name: "Per", id: "5c88d20edbb10a37c75aa7ae" },
    { name: "Martin", id: "61fa2db0553da241919ec6ba" },
    { name: "Aeron", id: "623d710918661b5d59207fdb" },
  ];
  return developers.some((developer) => developer.id === userId);
};

export const filterPattern = (value: string) => {
  // looks at beginning of each word in string, allows special characters
  return new RegExp(`(^|[^\\p{L}\\p{M}])${escapeRegExp(value)}`, "iu");
};

export const useNetworkStatus = () => {
  const [isOnline, setOnline] = useState(navigator.onLine);

  const updateNetworkStatus = () => {
    setOnline(navigator.onLine);
  };

  useEffect(() => {
    // Initial check
    updateNetworkStatus();

    // Add event listeners
    window.addEventListener("online", updateNetworkStatus);
    window.addEventListener("offline", updateNetworkStatus);

    return () => {
      window.removeEventListener("online", updateNetworkStatus);
      window.removeEventListener("offline", updateNetworkStatus);
    };
  }, []);

  return { isOnline };
};
