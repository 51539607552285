import React from "react";
import {
  IChecklistItemPopulated,
  IChecklistItemRow,
  IStampSettings,
  onChecklistRowChange,
} from "../../../../../../../types/checklist.types";
import { Paths, PathValue } from "../../../../../../../types/global.types";
import { FoldableSection, McInput } from "../../../../../components";
import { AppModalEnums } from "../../../../../frontendConstants";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { updateChecklistCategoryThunk, updateChecklistItemThunk } from "../../checklistSlice";
import ChecklistComments from "./ChecklistComments";
import ChecklistConnecetedIssues from "./ChecklistConnecetedIssues";
import "./ChecklistDetails.css";
import ChecklistDetailsRowChecksSection from "./ChecklistDetailsRowChecksSection";
import { setShowSuccessMessageThunk } from "../../../../../global/globalSlice";

const ChecklistDetails: React.FC<{
  checklistRow: IChecklistItemRow;
  isItem: boolean;
  isTemplate: boolean;
  onAssignedToChange: <T extends keyof IChecklistItemRow>(
    path: string,
    newValue: IChecklistItemRow[T],
    oldValue: string,
  ) => void;
  editable: boolean;
  updateRowAttribute: <P extends Paths<IChecklistItemPopulated, 4>>(
    rowId: string,
    path: P,
    oldValue: string,
    newValue: PathValue<IChecklistItemPopulated, P>,
  ) => void;
  onOpenIssue: (props: { itemId: string; boardId: string; app: AppModalEnums }) => void;
  stampSettings: IStampSettings;
}> = ({ checklistRow, onAssignedToChange, updateRowAttribute, isItem, isTemplate, editable, stampSettings }) => {
  const project = useAppSelector((state) => state.adminReducer.project);

  const checklist = useAppSelector((state) => state.checklistReducer.checklist);
  const dispatch = useAppDispatch();

  const updateRowParameter: onChecklistRowChange = async (oldValue, newValue, parameter) => {
    if (oldValue === newValue) {
      return;
    }

    if (isItem === true) {
      dispatch(updateChecklistItemThunk(checklistRow._id, parameter, newValue, oldValue?.toString()));
      dispatch(setShowSuccessMessageThunk("Sjekklisterad oppdatert"));
    } else {
      dispatch(updateChecklistCategoryThunk(checklistRow._id, parameter, newValue, oldValue?.toString()));
      dispatch(setShowSuccessMessageThunk("Sjekklistekategori oppdatert"));
    }
  };

  return (
    <div>
      <FoldableSection>
        <FoldableSection.Header>
          <div className="d-flex flex-column">
            <div className="section-title mr-4">
              {editable === true ? (
                <>
                  <div>
                    <h5>Tittel</h5>
                    <McInput
                      type={isItem ? "textarea" : "text"}
                      dynamicHeight={isItem ? true : false}
                      required={true}
                      onBlur={(e) => updateRowParameter(checklistRow.title, e.target.value, "title")}
                      value={checklistRow.title}
                      style={{ fontSize: "1.2rem" }}
                      className="mb-3"
                    />
                  </div>
                  {isTemplate !== true && (
                    <div>
                      <h5>Referanse</h5>
                      <McInput
                        required={false}
                        onBlur={(e) => updateRowParameter(checklistRow.reference, e.target.value, "reference")}
                        value={checklistRow.reference}
                        style={{ fontSize: "1.2rem" }}
                      />
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div style={{ fontSize: "1.2rem" }}>{checklistRow.title}</div>
                  <div style={{ fontSize: "1.0rem", opacity: 0.5 }}>{checklistRow.reference}</div>
                </>
              )}
            </div>
          </div>
        </FoldableSection.Header>
        <FoldableSection.Body>
          <div className="d-flex flex-column w-100 mt-2 mb-2">
            <ChecklistDetailsRowChecksSection
              updateRowAttribute={updateRowAttribute}
              onAssignedToChange={onAssignedToChange}
              checkTypes={checklist.checkTypes}
              users={project.members?.map((member) => member.user)}
              isItem={isItem}
              isTemplate={isTemplate}
              checklistRow={checklistRow}
              editable={editable}
              onValueChange={updateRowParameter}
              stampSettings={stampSettings}
            />
          </div>
        </FoldableSection.Body>
      </FoldableSection>
      {isTemplate === false && isItem === true && (
        <ChecklistConnecetedIssues checklistRow={checklistRow} projectId={project._id} />
      )}
      {isTemplate === false && isItem === true && checklistRow?.comments != null && (
        <ChecklistComments comments={checklistRow.comments} checklistItemId={checklistRow._id} />
      )}
    </div>
  );
};

export default ChecklistDetails;
