import classnames from "classnames";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Alert, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { IBim360Info } from "../../../../../types/forge.types";
import { useAppSelector } from "../../../hooks";
import ConnectBIM360ProjectModal from "../admin/bim360Settings/ConnectBIM360ProjectModal";
import { projectIsConnectedToBim360 } from "../admin/selectors";
import { BIM360DocManagement } from "./BIM360DocManagement";
import Bim360Members from "./Bim360Members";

export const Bim360Explorer: React.FC<{
  bim360: IBim360Info;
  initActiveFolder?: any;
  projectId: string;
}> = ({ bim360, initActiveFolder = null, projectId }) => {
  let history = useHistory();
  const project = useAppSelector((state) => state.adminReducer.project);

  const [activeTab, setActiveTab] = React.useState<"docs" | "members">("docs");

  const bim360Connected = useSelector(projectIsConnectedToBim360);

  const closeConnectBIM360 = (successConnect: boolean) => {
    if (successConnect !== true) {
      history.push(`/project/${project._id}/apps`);
    }
  };

  return (
    <div className="d-flex flex-column w-100 h-100 p-2">
      <Alert color="danger">
        <h3>Den tilsvarende funksjonaliteten er nå tilgjengelig i ACC. Denne søknaden blir derfor fjernet 15. mars.</h3>
      </Alert>
      {bim360Connected === false ? (
        <ConnectBIM360ProjectModal toggle={closeConnectBIM360} />
      ) : (
        <>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "docs" })}
                onClick={() => {
                  setActiveTab("docs");
                }}
              >
                Dokument
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "members" })}
                onClick={() => {
                  setActiveTab("members");
                }}
              >
                Medlemmer
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent className="h-100 w-100 overflow-auto" activeTab={activeTab}>
            {activeTab === "docs" && (
              <TabPane className="d-flex h-100 w-100 pt-2" tabId="docs">
                <BIM360DocManagement bim360={bim360} projectId={projectId} />
              </TabPane>
            )}
            {activeTab === "members" && (
              <TabPane className="d-flex h-100 w-100 pt-2" tabId="members">
                <Bim360Members
                  bim360AccountId={bim360.accountId}
                  projectId={projectId}
                  bim360ProjectId={bim360.projectId}
                />
              </TabPane>
            )}
          </TabContent>
        </>
      )}
    </div>
  );
};
