import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { IBoard } from "../../../../../../types/global.types";
import { useUpdateIssueBoardDisabledColumnsMutation } from "../IssueBoardRTK";
import DisabledIssueColumns from "./DisableIssueColumns";
import { FailedAlert } from "../../../../components";

const IssueColumnSettings: React.FC<{ issueBoard: IBoard }> = ({ issueBoard }) => {
  const [updateBoards, { isLoading: updateBoardsLoading, error: updateBoardsError }] =
    useUpdateIssueBoardDisabledColumnsMutation();

  const [editMode, setEditMode] = React.useState(false);
  return (
    <Card>
      <CardBody>
        <CardTitle tag="h3">Deaktiverte kolonner</CardTitle>
        {updateBoardsError != null && <FailedAlert />}
        <DisabledIssueColumns
          updateBoards={(disabledColumns) =>
            updateBoards({
              body: {
                disabledColumns: disabledColumns,
              },
              params: {
                projectId: issueBoard.project,
                issueBoardId: issueBoard._id,
              },
            })
          }
          board={issueBoard}
          isEditable
          editMode={editMode}
          editCb={(bool) => setEditMode(bool)}
        />
      </CardBody>
    </Card>
  );
};

export default IssueColumnSettings;
