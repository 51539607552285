import React from "react";
import { useHistory } from "react-router-dom";
import { IValueLabel } from "../../../../types/global.types";
import ListWithArrowItems from "../../components/ListWithArrowItems/ListWithArrowItems";
import { useAppSelector } from "../../hooks";
import { useGetSingleChecklistQuery } from "../routes/checklist/checklistRTK";
import { useGetSingleIssueBoardQuery } from "../routes/issuesApp/IssueBoardRTK";
import { getIssueBoardIdFromUrl, pathIsInIssueApp } from "../routes/issuesApp/issueUtils";
import { useGetSingleMeetingInstanceQuery } from "../routes/meeting/meetingRTK";
import { convertProjectUrlToValueAndLabels } from "./projectBreadCrumbsUtils";

const ProjectBreadcrumbs: React.FC = () => {
  const [items, setItems] = React.useState<IValueLabel[]>(null);

  const history = useHistory();

  const project = useAppSelector((state) => state.adminReducer.project);
  const risikoBoards = useAppSelector((state) => state.risikoReducer.boards);
  const batchjobs = useAppSelector((state) => state.batchJobReducer.batchJobs);
  const reports = useAppSelector((state) => state.reportReducer.reportBoards);
  const riskoBoard = useAppSelector((state) => state.risikoReducer.activeBoard);
  const useDashboardAsDefaultStartPage = project.useDashboardAsDefaultStartPage;
  const projectName = project?.maconomy?.name;

  const location = history.location;
  const issueBoardId = getIssueBoardIdFromUrl(location.pathname);
  const meetingId = location.pathname.split("/")[4];
  const checklistId = location.pathname.split("/")[5];
  const urlIsIssueApp = pathIsInIssueApp(location.pathname);
  const urlIsMeetingApp = history.location.pathname.includes("meetings");
  const urlIsChecklistApp = history.location.pathname.includes("checklist");

  const { data: issueBoard, isLoading: boardLoading } = useGetSingleIssueBoardQuery(
    {
      projectId: project._id,
      issueBoardId: issueBoardId,
    },
    {
      skip: urlIsIssueApp === false || issueBoardId == null,
    },
  );

  const { data: activeMeeting } = useGetSingleMeetingInstanceQuery(
    {
      projectId: project._id,
      meetingId: meetingId,
    },
    {
      skip: urlIsMeetingApp === false || meetingId == null,
    },
  );

  const { data: checklist } = useGetSingleChecklistQuery(
    {
      projectId: project._id,
      checklistId: checklistId,
    },
    {
      skip: urlIsChecklistApp === false || checklistId == null,
    },
  );

  React.useEffect(() => {
    const _items = convertProjectUrlToValueAndLabels(
      history.location.pathname,
      projectName,
      issueBoard,
      risikoBoards || [],
      checklist,
      batchjobs,
      useDashboardAsDefaultStartPage,
      reports,
      activeMeeting,
    );
    setItems(_items);

    const prefix = process.env.NODE_ENV === "development" ? "[D]" : "";
    const lastItemLabel = _items[_items.length - 1]?.label;

    document.title = `${prefix} MCApps - ${lastItemLabel}`;
  }, [
    history.location.pathname,
    batchjobs?.length,
    risikoBoards?.length,
    checklist,
    issueBoard,
    riskoBoard,
    reports,
    activeMeeting,
  ]);

  const isLoading = boardLoading;

  return (
    <>{items?.length > 1 && isLoading === false && <ListWithArrowItems listArrowItems={items} key={projectName} />}</>
  );
};

export default ProjectBreadcrumbs;
