import { IUpdateIssueResp } from "mc-shared/types/issuesResponses.types";
import { IAllFilter, IHistoryLog } from "mc-shared/zod/commonSchema";
import {
  IComment,
  IGisPointAndCamera,
  IHistoryLogDepricated,
  IIssue,
  IIssueStat,
  ITag,
  taskOrIssueENUM,
} from "../../../../../types/global.types";
import { apiSlice, getCacheKey } from "../../../apiSlice";
import { IEditorMetadata } from "../../../components/MultiComment/MultiComment";
import { ToastMessagesEnums } from "../../../frontendConstants";
import { removeAppModalInRedux, setShowSuccessMessageThunk } from "../../../global/globalSlice";
import { IRootState } from "../../../store";
import { addMissingKeysIssue, convertHistoryIssueVal } from "./issueUtils";

interface IIssueBoardInput {
  projectId: string;
  boardId: string;
}

interface IIssueQueryInput {
  projectId: string;
  issueId: string;
}

interface IIssueChangeBoardQueryInput extends IIssueQueryInput {
  boardIdToChangeTo: string;
}

interface ICopyIssueInput extends IIssueQueryInput {
  includeTasks: boolean;
}

interface IIssueAndTasksQueryInput extends IIssueQueryInput {
  boardIdToExclude: string;
}

export interface IIssueGetAllQueryInput {
  projectId: string;
  issueBoardId: string;
  allFilters: IAllFilter[];
  freeTextSearch: string;
  pns: string;
}

export interface IPostIssue {
  projectId: string;
  issueBoardId: string;
  issueTitle: string;
  taskOrIssue: taskOrIssueENUM;
  issueIdIfItIsATask?: string;
  tags?: string[];
  connectedMeeting?: string;
  clientAccess?: boolean;
  gis?: IGisPointAndCamera;
  gisImage?: string;
  connectedChecklistItem?: string;
  connectedRisiko?: string;
  connectedMeetingAgendaItem?: string;
}

export interface IUpdateIssue {
  projectId: string;
  issueBoardId: any;
  issueId: string;
  attr: string;
  value: any;
  oldValue: any;
  gisImage?: string;
  order?: number;
}

interface IQueryPostComment {
  projectId: string;
  issueId: string;
  text: string;
  metadata: IEditorMetadata;
}

interface IQueryDeleteComment {
  projectId: string;
  issueId: string;
  commentId: string;
}

interface IQueryUpdateComment {
  projectId: string;
  issueId: string;
  text: string;
  metadata: IEditorMetadata;
  commentId: string;
}

interface IProjectIssueId {
  projectId: string;
  issueId: string;
}

interface IQueryIssueTodoAdd extends IProjectIssueId {
  text: string;
}

interface IQueryIssueTodoToggle extends IProjectIssueId {
  todoId: string;
  isChecked: boolean;
}

interface IQueryIssueTodoUpdate extends IProjectIssueId {
  todoId: string;
  text: string;
}

interface IQueryIssueTodoDelete extends IProjectIssueId {
  todoId: string;
}

export const issueRTK = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    doGetAllIssues: builder.query<IIssue[], IIssueGetAllQueryInput>({
      query: (input) => ({
        url: `/project/${input.projectId}/issue/board/${input.issueBoardId}`,
        method: "POST",
        body: {
          allFilters: input.allFilters,
          freeTextSearch: input.freeTextSearch,
          pns: input.pns,
        },
      }),
      transformResponse: (resp: IIssue[]) => {
        return resp.map((issue) => {
          return addMissingKeysIssue(issue, "");
        });
      },
      providesTags: ["Issue"],
    }),
    doGetSingleIssue: builder.query<IIssue, IIssueQueryInput>({
      query: (input) => `project/${input.projectId}/issue/item/${input.issueId}`,
      providesTags: ["Issue"],
    }),
    doUpdateIssue: builder.mutation<IUpdateIssueResp, IUpdateIssue>({
      query(input) {
        return {
          url: `/project/${input.projectId}/issue/board/${input.issueBoardId}/item/${input.issueId}`,
          method: "PUT",
          body: {
            attr: input.attr,
            value: input.value,
            oldValue: input.oldValue,
            gisImage: input.gisImage,
            order: input.order,
          },
        };
      },
      async onQueryStarted({ issueId }, { dispatch, queryFulfilled, getState }) {
        patchAllIssuesAfterSingleIssueUpdate(issueId, { dispatch, queryFulfilled, getState });
      },
      invalidatesTags: ["Issue", "Meeting", "IssueFormulas"],
    }),
    createIssue: builder.mutation<IIssue, IPostIssue>({
      query(postIssue) {
        return {
          url: `/project/${postIssue.projectId}/issue/board/${postIssue.issueBoardId}/type/${postIssue.taskOrIssue}`,
          method: "POST",
          body: {
            title: postIssue.issueTitle,
            issueId: postIssue.issueIdIfItIsATask,
            tags: postIssue.tags,
            connectedMeeting: postIssue.connectedMeeting,
            clientAccess: postIssue.clientAccess,
            gis: postIssue.gis,
            gisImage: postIssue.gisImage,
            connectedChecklistItem: postIssue.connectedChecklistItem,
            connectedRisiko: postIssue.connectedRisiko,
            connectedMeetingAgendaItem: postIssue.connectedMeetingAgendaItem,
          },
        };
      },
      async onQueryStarted({ issueTitle, ...createIssueParams }, { dispatch, queryFulfilled, getState }) {
        const resp = await queryFulfilled;

        const createdIssue = addMissingKeysIssue(resp.data, "");
        const queries = getState().api.queries;
        const cacheKey = getCacheKey(queries, "doGetAllIssues");

        if (createIssueParams.taskOrIssue === "ISSUE") {
          const patchResult = dispatch(
            issueRTK.util.updateQueryData("doGetAllIssues", cacheKey, (draft) => {
              draft.push(createdIssue);
            }),
          );
        }
        if (createIssueParams.taskOrIssue === "TASK") {
          const patchResult = dispatch(
            issueRTK.util.updateQueryData("doGetAllIssues", cacheKey, (draft) => {
              draft.map((issue) => {
                if (issue._id === createIssueParams.issueIdIfItIsATask) {
                  (issue.connectedTasks as IIssue[]).push(createdIssue);
                }
                return issue;
              });
            }),
          );
        }

        dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.ISSUE_ADDED));
      },
      invalidatesTags: ["Issue", "Meeting", "IssueFormulas"],
    }),
    getConnectedTasks: builder.query<IIssue[], IIssueQueryInput>({
      query: (input) => `/project/${input.projectId}/issue/tasks/connected/${input.issueId}`,
      providesTags: ["Issue"],
    }),
    connectIssueOrTaskFromOtherBoardsToIssue: builder.mutation<
      IIssue,
      {
        projectId: string;
        issueOrTaskId: string;
        issueId: string;
        connectOrDisconnect: "CONNECT" | "DISCONNECT";
      }
    >({
      query(input) {
        return {
          url: `/project/${input.projectId}/issue/task/connect-disconnect-issue-or-task-from-other-boards`,
          method: "POST",
          body: {
            issueOrTaskId: input.issueOrTaskId,
            issueId: input.issueId,
            connectOrDisconnect: input.connectOrDisconnect,
          },
        };
      },
      invalidatesTags: ["Issue"],
    }),
    copyIssue: builder.mutation<IIssue, ICopyIssueInput>({
      query(input) {
        return {
          url: `/project/${input.projectId}/issue/item/copy/${input.issueId}`,
          method: "POST",
          body: {
            includeTasks: input.includeTasks,
          },
        };
      },
      async onQueryStarted(
        { issueId, ...createIssueParams },
        { dispatch, queryFulfilled, requestId, extra, getState },
      ) {
        const resp = await queryFulfilled;
        const createdIssue = resp.data;
        const queries = getState().api.queries;
        const cacheKey = getCacheKey(queries, "doGetAllIssues");

        if (resp.data.taskOrIssue === "ISSUE") {
          const patchResult = dispatch(
            issueRTK.util.updateQueryData("doGetAllIssues", cacheKey, (draft) => {
              draft.push(createdIssue);
            }),
          );
          if (patchResult.patches.length === 0) {
            console.warn("No patches were made to the cache");
          }
        }

        dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.ISSUE_ADDED));
      },
      invalidatesTags: ["Issue"],
    }),
    getUniqueTagsOnBoard: builder.query<ITag[], IIssueBoardInput>({
      query: (input) => `/project/${input.projectId}/issue/board/${input.boardId}/tags`,
      providesTags: ["IssueBoard"],
    }),
    archiveIssue: builder.mutation<IIssue, IIssueQueryInput>({
      query(input) {
        return {
          url: `/project/${input.projectId}/issue/item/${input.issueId}/archive`,
          method: "PUT",
        };
      },
      async onQueryStarted(
        { issueId, ...createIssueParams },
        { dispatch, queryFulfilled, requestId, extra, getState },
      ) {
        const resp = await queryFulfilled;
        const taskOrIssue = resp.data.taskOrIssue;
        const queries = getState().api.queries;
        const cacheKey = getCacheKey(queries, "doGetAllIssues");

        if (taskOrIssue === "ISSUE") {
          const patchResult = dispatch(
            issueRTK.util.updateQueryData("doGetAllIssues", cacheKey, (draft) => {
              const index = draft.findIndex((issue) => issue._id === issueId);
              draft.splice(index, 1);
            }),
          );
        }
        if (taskOrIssue === "TASK") {
          const patchResult = dispatch(
            issueRTK.util.updateQueryData("doGetAllIssues", cacheKey, (draft) => {
              draft.map((issue) => {
                if (issue._id === resp.data.connectedIssue?._id) {
                  const index = (issue.connectedTasks as IIssue[]).findIndex((task) => task._id === issueId);
                  (issue.connectedTasks as IIssue[]).splice(index, 1);
                }
                return issue;
              });
            }),
          );
        }

        /*if (patchResult.inversePatches.length === 0) {
          // console.log(cacheKey);
        }*/
        dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.ISSUE_ARCHIVED));
      },
      invalidatesTags: ["Issue", "Meeting"],
    }),
    addIssueComment: builder.mutation<IComment[], IQueryPostComment>({
      query(input) {
        return {
          url: `/project/${input.projectId}/issue/item/${input.issueId}/comment`,
          method: "POST",
          body: {
            text: input.text,
            metadata: input.metadata,
          },
        };
      },
      invalidatesTags: ["Issue"],
    }),
    deleteIssueComment: builder.mutation<IComment[], IQueryDeleteComment>({
      query(input) {
        return {
          url: `/project/${input.projectId}/issue/item/${input.issueId}/comment/${input.commentId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Issue"],
    }),
    updateIssueComment: builder.mutation<IComment[], IQueryUpdateComment>({
      query(input) {
        return {
          url: `/project/${input.projectId}/issue/item/${input.issueId}/comment/${input.commentId}`,
          method: "PUT",
          body: {
            text: input.text,
            metadata: input.metadata,
          },
        };
      },
      invalidatesTags: ["Issue"],
    }),
    addIssueTodo: builder.mutation<IUpdateIssueResp, IQueryIssueTodoAdd>({
      query(input) {
        return {
          url: `/project/${input.projectId}/issue/todo/${input.issueId}/add`,
          method: "POST",
          body: {
            text: input.text,
          },
        };
      },
      async onQueryStarted({ issueId }, { dispatch, queryFulfilled, getState }) {
        patchAllIssuesAfterSingleIssueUpdate(issueId, { dispatch, queryFulfilled, getState });
      },
      invalidatesTags: ["Issue"],
    }),
    updateIssueTodo: builder.mutation<IUpdateIssueResp, IQueryIssueTodoUpdate>({
      query(input) {
        return {
          url: `/project/${input.projectId}/issue/todo/${input.issueId}/update`,
          method: "PUT",
          body: {
            text: input.text,
            todoId: input.todoId,
          },
        };
      },
      async onQueryStarted({ issueId }, { dispatch, queryFulfilled, getState }) {
        patchAllIssuesAfterSingleIssueUpdate(issueId, { dispatch, queryFulfilled, getState });
      },
      invalidatesTags: ["Issue"],
    }),
    deleteIssueTodo: builder.mutation<IUpdateIssueResp, IQueryIssueTodoDelete>({
      query(input) {
        return {
          url: `/project/${input.projectId}/issue/todo/${input.issueId}/delete/${input.todoId}`,
          method: "DELETE",
        };
      },
      async onQueryStarted({ issueId }, { dispatch, queryFulfilled, getState }) {
        patchAllIssuesAfterSingleIssueUpdate(issueId, { dispatch, queryFulfilled, getState });
      },
      invalidatesTags: ["Issue"],
    }),
    toggleIssueTodo: builder.mutation<void, IQueryIssueTodoToggle>({
      query(input) {
        return {
          url: `/project/${input.projectId}/issue/todo/${input.issueId}/toggle`,
          method: "PUT",
          body: {
            isChecked: input.isChecked,
            todoId: input.todoId,
          },
        };
      },
      async onQueryStarted({ issueId }, { dispatch, queryFulfilled, getState }) {
        patchAllIssuesAfterSingleIssueUpdate(issueId, { dispatch, queryFulfilled, getState });
      },
      invalidatesTags: ["Issue"],
    }),

    toggleClientAccess: builder.mutation<void, IIssueQueryInput>({
      query(input) {
        return {
          url: `/project/${input.projectId}/issue/item/${input.issueId}/clientaccess`,
          method: "PUT",
        };
      },
      invalidatesTags: ["Issue"],
    }),
    toggleSubscription: builder.mutation<void, IIssueQueryInput>({
      query(input) {
        return {
          url: `/project/${input.projectId}/issue/item/${input.issueId}/subscribe`,
          method: "PUT",
        };
      },
      invalidatesTags: ["Issue"],
    }),
    getIssueHistoryLogs: builder.query<IHistoryLog[], IIssueQueryInput>({
      query: (input) => `/project/${input.projectId}/issue/log/item/${input.issueId}`,
      transformResponse: (resp: IHistoryLogDepricated[]) => {
        return resp.map((issue) => {
          return {
            _id: issue._id,
            dataField: issue.column,
            columnDisplayName: issue.column,
            createdAt: issue.createdAt,
            newValue: convertHistoryIssueVal(issue.newValue),
            oldValue: convertHistoryIssueVal(issue.oldValue),
            updatedAt: issue.updatedAt,
            editedBy: issue?.user?.name ?? issue.userName,
          };
        });
      },
    }),
    getIssueStats: builder.query<IIssueStat, IIssueGetAllQueryInput>({
      query(input) {
        return {
          url: `/project/${input.projectId}/issue/board/${input.issueBoardId}/stats`,
          method: "POST",
          body: {
            allFilters: input.allFilters,
            freeTextSearch: input.freeTextSearch,
            pns: input.pns,
          },
        };
      },
    }),
    changeBoardOfIssueItem: builder.mutation<IIssue, IIssueChangeBoardQueryInput>({
      query(input) {
        return {
          url: `/project/${input.projectId}/issue/item/${input.issueId}/board`,
          method: "PUT",
          body: {
            boardIdToChangeTo: input.boardIdToChangeTo,
          },
        };
      },
      async onQueryStarted(
        { issueId, ...updateIssueParams },
        { dispatch, queryFulfilled, requestId, extra, getState },
      ) {
        const resp = await queryFulfilled;
        const updatedIssue = resp.data;
        const queries = getState().api.queries;
        const cacheKey = getCacheKey(queries, "doGetAllIssues");

        const patchResult = dispatch(
          issueRTK.util.updateQueryData("doGetAllIssues", cacheKey, (draft) => {
            const index = draft.findIndex((issue) => issue._id === issueId);
            draft.splice(index, 1);
          }),
        );
        if (patchResult.inversePatches.length === 0) {
          console.warn("No patches were made to the cache");
        }

        dispatch(removeAppModalInRedux(issueId));
        dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.UPDATED));
      },
      invalidatesTags: ["Issue"],
    }),
    getRisikoConnectedIssues: builder.query<IIssue[], IIssueBoardInput>({
      query: (input) => `/project/${input.projectId}/risiko/board/${input.boardId}/connectedissues`,
      providesTags: ["Issue"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useDoGetAllIssuesQuery,
  useDoGetSingleIssueQuery,
  useCreateIssueMutation,
  useDoUpdateIssueMutation,
  useGetConnectedTasksQuery,
  useConnectIssueOrTaskFromOtherBoardsToIssueMutation,
  useCopyIssueMutation,
  useGetUniqueTagsOnBoardQuery,
  useArchiveIssueMutation,
  useAddIssueCommentMutation,
  useDeleteIssueCommentMutation,
  useUpdateIssueCommentMutation,
  useAddIssueTodoMutation,
  useUpdateIssueTodoMutation,
  useDeleteIssueTodoMutation,
  useToggleIssueTodoMutation,
  useToggleClientAccessMutation,
  useToggleSubscriptionMutation,
  useGetIssueHistoryLogsQuery,
  useGetIssueStatsQuery,
  useChangeBoardOfIssueItemMutation,
  useGetRisikoConnectedIssuesQuery,
} = issueRTK;

const patchAllIssuesAfterSingleIssueUpdate = async (issueId: string, { dispatch, queryFulfilled, getState }) => {
  const resp = await queryFulfilled;
  const data: IUpdateIssueResp = resp.data;
  if (data.updatedIssue == null) {
    console.warn("No updatedIssue in response");
    return;
  }
  const updatedIssue = data.updatedIssue;
  const taskOrIssue = updatedIssue.taskOrIssue;
  const queries = getState().api.queries;

  const allFilters = (getState() as unknown as IRootState).issueReducerNew?.allFilters;
  const taskOrIssueFilter = allFilters?.find((filter) => filter.dataField === "taskOrIssue");
  const showTasksIsEnabled = taskOrIssueFilter?.bool === true;

  const cacheKey = getCacheKey(queries, "doGetAllIssues");
  if (taskOrIssue === "ISSUE" || showTasksIsEnabled === true) {
    const patchResult = dispatch(
      issueRTK.util.updateQueryData("doGetAllIssues", cacheKey, (draft) => {
        draft.map((issue) => {
          if (issue._id === issueId) {
            Object.assign(issue, updatedIssue);
          }
          return issue;
        });
      }),
    );
  }
  if (taskOrIssue === "TASK") {
    const patchResult = dispatch(
      issueRTK.util.updateQueryData("doGetAllIssues", cacheKey, (draft) => {
        const connectedIssueId = updatedIssue.connectedIssue?._id;
        draft.map((issue) => {
          if (issue._id === connectedIssueId) {
            (issue.connectedTasks as IIssue[]).map((task) => {
              if (task._id === issueId) {
                Object.assign(task, updatedIssue);
              }
            });
          }
          return issue;
        });
      }),
    );
  }

  if (resp.data.issuesWithKanbanOrder != null) {
    const patchResult = dispatch(
      issueRTK.util.updateQueryData("doGetAllIssues", cacheKey, (draft) => {
        draft.map((issue) => {
          const issueWithKanbanOrder = resp.data.issuesWithKanbanOrder.find((item) => item._id === issue._id);
          if (issueWithKanbanOrder != null) {
            issue.kanbanOrder = issueWithKanbanOrder.kanbanOrder;
          }
          return issue;
        });
      }),
    );
    if (patchResult.patches.length === 0) {
      console.warn("No patches were made to the cache");
    }
  }

  dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.UPDATED));
};
