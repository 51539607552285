import { KontrollAvLuftstroemEnum } from "mc-shared/zod/report/GK/gkReportConstants";
import { IGKKontrollAvLuftStroemResultater, IGKRapport } from "mc-shared/zod/report/GK/gkReportSchema";
import React, { useEffect } from "react";
import { Button, Card, CardBody, CardTitle, FormGroup, Input, Label } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../../../types/global.types";
import { HSpace, McDropdown, McInput } from "../../../../../../../components";
import ReportDocxImageContainer from "../../../../ReportBoard/ReportDocxImageContainer";
import {
  beregnetGjennomsnittligHastighet,
  beregnetLufthastighetUniformitet,
  beregnetStandardAvvik,
  beregnetVarians,
} from "../../gkGlobalUtils";

const KontrollAvLuftstroem: React.FC<{
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
  data: IGKKontrollAvLuftStroemResultater;
}> = ({ data, onUpdateEditData }) => {
  const TESTMETODER: KontrollAvLuftstroemEnum[] = [
    KontrollAvLuftstroemEnum.STREAMER,
    KontrollAvLuftstroemEnum.ROEYKELLERPARTIKKEL,
    KontrollAvLuftstroemEnum.LUFTHASTIGHET,
  ];
  const valgtMetode = data.inndata.valgtMetode;
  useEffect(() => {
    utdataBeregninger();
  }, [
    data.inndata.lufthastighetsMetodeResultater.maaleresultater,
    data.inndata.lufthastighetsMetodeResultater.maaleresultater.length,
  ]);

  const addNewRow = () => {
    const maaleresultaterArray = data.inndata.lufthastighetsMetodeResultater.maaleresultater;
    maaleresultaterArray.push(null);

    onUpdateEditData(
      "testResultater.kontrollAvLuftstroem.inndata.lufthastighetsMetodeResultater.maaleresultater",
      maaleresultaterArray,
    );

    return maaleresultaterArray;
  };

  const removeLastRow = () => {
    const updatedMaaleresultater = data.inndata.lufthastighetsMetodeResultater.maaleresultater;

    if (updatedMaaleresultater.length > 0) {
      updatedMaaleresultater.pop(); // Remove the last element
      onUpdateEditData(
        "testResultater.kontrollAvLuftstroem.inndata.lufthastighetsMetodeResultater.maaleresultater",
        updatedMaaleresultater,
      );
    }
  };

  const updateMaaleresultater = (resultat, i) => {
    const maaleresultater = [...data.inndata.lufthastighetsMetodeResultater.maaleresultater];
    maaleresultater[i] = resultat;
    onUpdateEditData(
      "testResultater.kontrollAvLuftstroem.inndata.lufthastighetsMetodeResultater.maaleresultater",
      maaleresultater,
    );
  };

  const utdataBeregninger = () => {
    if (data.inndata.valgtMetode === KontrollAvLuftstroemEnum.LUFTHASTIGHET) {
      const gjennomsnittligHastighet = beregnetGjennomsnittligHastighet(
        data.inndata.lufthastighetsMetodeResultater.maaleresultater.map((resultat) => resultat),
        data.inndata.lufthastighetsMetodeResultater.maaleresultater.length,
      );
      let varians;
      if (gjennomsnittligHastighet != null) {
        varians = beregnetVarians(
          data.inndata.lufthastighetsMetodeResultater.maaleresultater.map((resultat) => resultat),
          gjennomsnittligHastighet,
        );
      }
      const standardAvvik = beregnetStandardAvvik(varians);
      const lufthastighetUniformitet = beregnetLufthastighetUniformitet(gjennomsnittligHastighet, standardAvvik);
      const utdata = {
        gjennomsnittligHastighet: gjennomsnittligHastighet,
        varians: varians,
        standardAvvik: standardAvvik,
        lufthastighetUniformitet: lufthastighetUniformitet,
      };
      onUpdateEditData("testResultater.kontrollAvLuftstroem.utdata", utdata);
    }
  };

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Kontroll av luftmønster</CardTitle>
        <b>Hvilken metode skal brukes?</b>
        <div className="w-25">
          <McDropdown
            currentValue={data.inndata.valgtMetode}
            onChange={(_, item) =>
              onUpdateEditData("testResultater.kontrollAvLuftstroem.inndata.valgtMetode", item as string)
            }
            defaultTitle="Hvilken testmetode skal brukes?"
            options={TESTMETODER}
          />
        </div>
        {(valgtMetode === KontrollAvLuftstroemEnum.STREAMER ||
          valgtMetode === KontrollAvLuftstroemEnum.ROEYKELLERPARTIKKEL) && (
          <div className="w-50 mt-4">
            <div>
              <b>Beskrivelse av luftmønster:</b>
              <McInput
                value={data.inndata.streamerMetodeResultater.beskrivelseAvLuftstroem}
                onChange={(e) =>
                  onUpdateEditData(
                    "testResultater.kontrollAvLuftstroem.inndata.streamerMetodeResultater.beskrivelseAvLuftstroem",
                    e.target.value,
                  )
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
            <div>
              <FormGroup className="d-flex flex-column" check>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.streamerMetodeResultater.godkjent === true}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.kontrollAvLuftstroem.inndata.streamerMetodeResultater.godkjent",
                        true,
                      )
                    }
                  />
                  Godkjent
                </Label>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.streamerMetodeResultater.godkjent === false}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.kontrollAvLuftstroem.inndata.streamerMetodeResultater.godkjent",
                        false,
                      )
                    }
                  />
                  Ikke godkjent
                </Label>
              </FormGroup>
            </div>
          </div>
        )}
        {data.inndata.valgtMetode === KontrollAvLuftstroemEnum.LUFTHASTIGHET && (
          <>
            <div className="mt-4">
              <div className="d-flex flex-column">
                <b>Hvor mange målinger skal utføres? &#40;Minimum 2&#41;:</b>
                <div className="d-flex">
                  <Button color="success" onClick={addNewRow}>
                    Legg til rad
                  </Button>
                  <HSpace />
                  <Button
                    color="danger"
                    onClick={removeLastRow}
                    disabled={data.inndata.lufthastighetsMetodeResultater.maaleresultater.length < 3}
                  >
                    Fjern siste rad
                  </Button>
                </div>
              </div>
            </div>
            {data.inndata.lufthastighetsMetodeResultater.maaleresultater.length > 0 && (
              <>
                <div className="mt-4 d-flex flex-column">
                  <b>Fyll inn måleresultater &#40;m/s&#41;:</b>
                  {data.inndata.lufthastighetsMetodeResultater.maaleresultater.map((a, i) => (
                    <McInput
                      className="mb-1 w-25"
                      key={i}
                      value={a}
                      normalizeNumbers={true}
                      type="text"
                      onBlur={(e) => updateMaaleresultater(Number(e.target.value), i)}
                    />
                  ))}
                </div>
                <div className="d-flex justify-content-between">
                  <p className="mb-0">
                    Gjennomsnittlig hastighet &#40;m/s&#41; =
                    <b>
                      {" "}
                      {beregnetGjennomsnittligHastighet(
                        data.inndata.lufthastighetsMetodeResultater.maaleresultater.map((resultat) => resultat),
                        data.inndata.lufthastighetsMetodeResultater.maaleresultater.length,
                      )?.toFixed(3) ?? ""}
                    </b>
                  </p>
                  <p className="mb-0">Formel: &#40;Va = &#40;SUM Vn&#41; / N&#41;</p>
                </div>
                <hr className="mt-0" />
                <div className="d-flex justify-content-between">
                  <p className="mb-0">
                    Varians &#40;-&#41; =
                    <b>
                      {" "}
                      {beregnetVarians(
                        data.inndata.lufthastighetsMetodeResultater.maaleresultater.map((resultat) => resultat),
                        data.utdata.gjennomsnittligHastighet,
                      )?.toFixed(4) ?? ""}
                    </b>
                  </p>
                  <p className="mb-0">
                    Formel: &#40;VRS = &#40;&#40;SUM Vn<sup>2</sup>&#41; - N * &#40;Va<sup>2</sup>&#41;&#41; / &#40;N -
                    1&#41;&#41;
                  </p>
                </div>
                <hr className="mt-0" />
                <div className="d-flex justify-content-between">
                  <p className="mb-0">
                    Standardavvik &#40;-&#41; =<b> {beregnetStandardAvvik(data.utdata.varians)?.toFixed(4) ?? ""}</b>
                  </p>
                  <p className="mb-0">
                    Formel: &#40;Sigma = VRS<sup>0,5</sup>&#41;
                  </p>
                </div>
                <hr className="mt-0" />
                <div className="d-flex justify-content-between">
                  <p className="mb-0">
                    Lufthastighetens uniformitet &#40;-&#41; ={" "}
                    <b>
                      {beregnetLufthastighetUniformitet(
                        data.utdata.gjennomsnittligHastighet,
                        data.utdata.standardAvvik,
                      )?.toFixed(2) ?? ""}
                    </b>
                  </p>
                  <p className="mb-0">Formel: &#40;Uv = &#40;1 - &#40;Sigma / Va&#41;&#41; * 100&#41;</p>
                </div>
                <hr className="mt-0" />
              </>
            )}
          </>
        )}
        <div className="w-50 mt-4">
          <div>
            <ReportDocxImageContainer docxImageTag="kontrollAvLuftstroem" title="Målepunkter i rommet" />
          </div>
          <b>Kommentar:</b>
          <McInput
            value={data.inndata.kommentar}
            onChange={(e) => onUpdateEditData("testResultater.kontrollAvLuftstroem.inndata.kommentar", e.target.value)}
            type="textarea"
            dynamicHeight={true}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default KontrollAvLuftstroem;
