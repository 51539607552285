import { AccessLevelsEnum } from "mc-shared/enums/enums";
import React from "react";
import { FailedAlert, MCLoader } from "../../../components";
import { Checkbox, McDropdown, TitleWithValue, VSpace } from "../../../components/";
import SingleImageUpload from "../../../components/SingleImageUpload/SingleImageUpload";
import { ToastMessagesEnums } from "../../../frontendConstants";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { setProjectNameInRedux, setProjectUseDashboardAsDefault } from "../../actions";
import {
  useDeleteProjectLogoMutation,
  useGetProjectLogoTypeAzureBlobStorageFileQuery,
  useUploadLogoTypeMutation,
} from "../../projectRTK";
import { useChangeProjectDefaultMutation, useFetchLayoutsQuery } from "../projectdashboard/ProjectDashboardRTK";
import { doSaveProjectSettings } from "./restQueries";
import { setShowSuccessMessageThunk } from "../../../global/globalSlice";

const GeneralProjectSettingsPage: React.FC = () => {
  const project = useAppSelector((state) => state.adminReducer.project);
  const { data: allLayouts } = useFetchLayoutsQuery(project._id);
  const [changeProjectDefault] = useChangeProjectDefaultMutation();

  const dispatch = useAppDispatch();
  const saveName = async (oldval, newval) => {
    saveSettings(newval, project.useDashboardAsDefaultStartPage);
  };

  const saveUseDashboardAsDefault = () => {
    saveSettings(project.maconomy.name, !project.useDashboardAsDefaultStartPage);
  };

  const saveSettings = async (name: string, useDashboardAsDefaultStartPage: boolean) => {
    const updated = await doSaveProjectSettings(project._id, name, useDashboardAsDefaultStartPage);
    dispatch(setProjectNameInRedux(updated.name));
    dispatch(setProjectUseDashboardAsDefault(updated.useDashboardAsDefaultStartPage));
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.UPDATED));
  };

  const handleChangeProjectDefault = async (newDefaultId: string, prevDefaultId: string) => {
    await changeProjectDefault({ newDefaultId, prevDefaultId, projectId: project._id });
    dispatch(setShowSuccessMessageThunk("Default dashboard lagret"));
  };

  return (
    <div>
      <TitleWithValue value={project.maconomy.name} title="Prosjektnavn" editable saveCb={saveName} type="text" />
      <VSpace />
      <Checkbox
        text="Bruk dashboard som startside"
        isChecked={project.useDashboardAsDefaultStartPage}
        setChecked={saveUseDashboardAsDefault}
        disabled={false}
      />
      <VSpace />
      <h5>Dashboardoppsett</h5>
      <p>Velg hvilket oppsett prosjektmedlemmer skal bruke som default:</p>
      <div style={{ maxWidth: "200px" }}>
        {allLayouts && (
          <McDropdown
            currentValue={allLayouts.find((item) => item.isProjectDefault === true)}
            options={allLayouts.filter((item) => item.access.level === AccessLevelsEnum.PROJECT)}
            displayKey="title"
            onChange={(oldValue, newValue) => handleChangeProjectDefault(newValue._id, oldValue?._id)}
          />
        )}
      </div>
      <p className="gray-600-text mt-2" style={{ fontSize: ".9em", fontStyle: "italic" }}>
        Bare oppsett med tilgang satt som "Prosjekt" skal vises
      </p>
      <VSpace />
      <ProjectLogoTypeUpload projectId={project._id} />
    </div>
  );
};

export default GeneralProjectSettingsPage;

const ProjectLogoTypeUpload: React.FC<{ projectId: string }> = ({ projectId }) => {
  const {
    data: logoAzureFile,
    isLoading: loadingLogo,
    isError: errorFetchingLogo,
  } = useGetProjectLogoTypeAzureBlobStorageFileQuery({ projectId });

  const [deleteLogo, { isError: errorDeletingFile, isLoading: loadingDelete }] = useDeleteProjectLogoMutation();
  const [uploadLogo, { isError: errorUploadLogo, isLoading: uploadLoading }] = useUploadLogoTypeMutation();

  const isError = errorDeletingFile || errorFetchingLogo || errorUploadLogo;

  const isLoading = loadingDelete || loadingLogo || uploadLoading;

  const path = `/api/image/${logoAzureFile?.blobName}`;
  return (
    <>
      {isLoading && <MCLoader />}
      {isError && <FailedAlert />}
      <SingleImageUpload
        title="Logotype"
        onFileUpload={(image) =>
          uploadLogo({
            image,
            projectId,
          })
        }
        onFileDelete={() => deleteLogo({ projectId })}
        path={path}
      />
    </>
  );
};
