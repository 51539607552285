import React from "react";
import { Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";
import { IBatchJobSchedule } from "../../../../types/global.types";
import CheckOrWarn from "../CheckOrWarn";
import ScheduleSelector from "../ScheduleSelector/ScheduleSelector";

const ExportSchedule: React.FC<{
  scheduleOptions: IBatchJobSchedule;
  onSave: (jobEnabled: boolean, jobInterval: string) => void;
  isValid: boolean;
  disabled: boolean;
}> = ({ scheduleOptions, onSave, isValid, disabled }) => {
  return (
    <Card>
      <CardBody>
        <CardTitle tag="h3">
          <CheckOrWarn bool={isValid} />
          Planlegging
        </CardTitle>
        <CardSubtitle tag="h6" className="mb-2 text-muted">
          Velg når og med hvilket intervall eksporten skal utføres
        </CardSubtitle>
        <ScheduleSelector
          scheduleActive={scheduleOptions?.jobEnabled === true && disabled === false}
          onSave={onSave}
          expression={scheduleOptions?.jobInterval ?? ""}
          disabled={disabled}
        />
      </CardBody>
    </Card>
  );
};

export default ExportSchedule;
