import { IAzureBlobFile } from "mc-shared/zod/azureSchema";
import { IDocxItemIndata } from "mc-shared/zod/commonSchema";
import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { IDocxTemplate, IIssue } from "../../../../../../types/global.types";
import { apiSlice } from "../../../../apiSlice";
import { DocxReport, DocxReportDropdown, FailedAlert, MCLoader } from "../../../../components";
import { ToastMessagesEnums } from "../../../../frontendConstants";
import { setShowSuccessMessageThunk } from "../../../../global/globalSlice";
import { useGetOfficalMulticonsultIssueTempaltesQuery } from "../../../../globalRTK";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  useDeleteProjectIssueDocxTemplateMutation,
  useGetProjectIssueboardDocxTemplatesQuery,
  useGetProjectIssueDocxTemplatesQuery,
  useUploadIssueboardDocxMutation,
} from "../../../projectRTK";
import { getProjectIdFromUrl } from "../../../utils";
import { downloadAzureBlobFile } from "../../admin/restQueries";
import { formatShortDate } from "../../admin/selectors";
import { TemplateLevelEnum } from "../../checklist/constants";
import { doGenerateIssueDocx, doUploadIssueDocxTemplate, IGenerateIssueDocxArgs } from "../issueAPIDepricated";
import { ISSUE_DOCX_DEMO_DATA } from "../issueConstants";
import { getFiltersWithActiveOption, getIssueBoardIdFromUrl } from "../issueUtils";
import { AgGridReact } from "ag-grid-react";

const IssueDocxTemplates: React.FC<{ issues: IIssue[]; gridRef: React.RefObject<AgGridReact> }> = ({
  issues,
  gridRef,
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [docxModalOpen, setDocxModalOpen] = useState<boolean>(false);
  const allFilters = useAppSelector((state) => state.issueReducerNew.allFilters);
  const projectId = getProjectIdFromUrl(history.location.pathname);
  const [uploadIssueboardTemplate, { isError: errorUploadIssueboardTemplate, isLoading: uploadLoading }] =
    useUploadIssueboardDocxMutation();
  const [deleteTemplate, { isError: deleteTemplateError }] = useDeleteProjectIssueDocxTemplateMutation();

  const issueBoardId = getIssueBoardIdFromUrl(history.location.pathname);
  const activeFilters = useMemo(() => getFiltersWithActiveOption(allFilters), [allFilters]);

  const {
    data: projectIssueboardDocxTemplates = [],
    isLoading: isLoadingProjectIssueboardTemplates,
    isError: getProjectIssueboardTemplatesError,
  } = useGetProjectIssueboardDocxTemplatesQuery({ projectId, issueBoardId });

  const {
    data: projectIssueDocxTemplates = [],
    isLoading: isLoadingProjectIssueTemplates,
    isError: getProjectIssueTemplatesError,
  } = useGetProjectIssueDocxTemplatesQuery({ projectId });

  const {
    data: officialIssueTemplates = [],
    isLoading: isLoadingIssueTemplates,
    isError: getIssueTemplatesError,
  } = useGetOfficalMulticonsultIssueTempaltesQuery("");

  const projectIssueboardDocxTemplatesFormatted = useMemo(() => {
    return projectIssueboardDocxTemplates.map((template) => {
      return {
        _id: template.blobName,
        fileName: template.fileName,
        isMulticonsult: false,
        uploadedDate: new Date(template.uploadedAt),
        uploadedBy: {
          name: template.uploadedBy,
        },
        templateLevel: TemplateLevelEnum.BOARD,
      };
    });
  }, [projectIssueboardDocxTemplates]);

  const projectDocxTemplatesFormatted = useMemo(() => {
    return projectIssueDocxTemplates.map((template) => {
      return {
        _id: template.blobName,
        fileName: template.fileName,
        isMulticonsult: false,
        uploadedDate: new Date(template.uploadedAt),
        uploadedBy: {
          name: template.uploadedBy,
        },
        templateLevel: TemplateLevelEnum.PROJECT,
      };
    });
  }, [projectIssueDocxTemplates]);

  const officalDocxTemplatesFormatted = officialIssueTemplates.map((template) => {
    const docxTemplate: IDocxTemplate = {
      _id: template.blobName,
      fileName: template.fileName,
      isMulticonsult: true,
      uploadedDate: new Date(template.uploadedAt),
      uploadedBy: {
        name: template.uploadedBy,
      },
      templateLevel: TemplateLevelEnum.MULTICONSULT,
    };
    return docxTemplate;
  });

  const docxTemplates = [
    ...officalDocxTemplatesFormatted,
    ...projectDocxTemplatesFormatted,
    ...projectIssueboardDocxTemplatesFormatted,
  ];

  const uploadDocxTempalte = async (event: React.ChangeEvent<HTMLInputElement>, level: string) => {
    console.log("uploadDocxTempalte", event, level);
    if (level === TemplateLevelEnum.PROJECT) {
      await doUploadIssueDocxTemplate(projectId, event);
      dispatch(apiSlice.util.invalidateTags(["IssueBoard"]));
    } else if (level === TemplateLevelEnum.BOARD) {
      console.log("Board template upload not implemented");
      uploadIssueboardTemplate({
        projectId: projectId,
        boardId: issueBoardId,
        event: event,
      });
    }
  };
  const getIssueIds = (issues: IIssue[]) => {
    let rowIds: string[] = [];
    if (gridRef?.current?.api) {
      gridRef?.current?.api.forEachNodeAfterFilter((rowNode) => {
        if (rowNode.data?._id) {
          rowIds.push(rowNode.data._id);
        }
      });
    } else rowIds = issues.map((issue) => issue._id);
    return rowIds;
  };
  const generateIssueDocx = async (docxTemplate: IDocxTemplate) => {
    const d = new Date().toISOString();
    const docxData: IDocxItemIndata = {
      generatedDate: formatShortDate(d),
      itemsIds: getIssueIds(issues),
    };
    const selectedDocxTemplate = [
      ...officialIssueTemplates,
      ...projectIssueDocxTemplates,
      ...projectIssueboardDocxTemplates,
    ].find((tmpl) => tmpl.fileName === docxTemplate.fileName) as IAzureBlobFile;
    const postArgs: IGenerateIssueDocxArgs = {
      params: {
        projectId: projectId,
      },
      body: {
        templateBlobName: selectedDocxTemplate?.blobName,
        data: docxData,
        allFilters: activeFilters,
        issueBoardId: issueBoardId,
      },
    };
    await doGenerateIssueDocx(postArgs);
  };

  const isErrored = getProjectIssueTemplatesError || deleteTemplateError || getProjectIssueboardTemplatesError;
  const isLoading = isLoadingProjectIssueTemplates || isLoadingIssueTemplates || isLoadingProjectIssueboardTemplates;
  return (
    <>
      {isLoading && <MCLoader />}
      {isErrored && <FailedAlert />}
      {docxModalOpen === true && (
        <DocxReport
          demoDocxData={ISSUE_DOCX_DEMO_DATA}
          docxTemplates={docxTemplates}
          uploadCompleedCb={() => dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.UPLOAD_SUCCESFULL))}
          uploadFunc={uploadDocxTempalte}
          generateDocx={generateIssueDocx}
          downloadTemplate={(docxTemplate) => downloadAzureBlobFile(docxTemplate._id, docxTemplate.fileName)}
          deleteDocx={(docxTemplate: IDocxTemplate) =>
            deleteTemplate({
              projectId: projectId,
              fullBlobId: docxTemplate._id,
            })
          }
          closeModal={() => setDocxModalOpen(false)}
        />
      )}
      <DocxReportDropdown
        docxTemplates={docxTemplates}
        generateDocx={generateIssueDocx}
        openDocxModal={() => setDocxModalOpen(true)}
        buttonColor="secondary"
        buttonText="Utskrift"
      />
    </>
  );
};

export default IssueDocxTemplates;
