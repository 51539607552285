import { CellEditRequestEvent, ColDef, GridApi } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-enterprise";
import { AgGridReact, CustomCellRendererProps } from "ag-grid-react";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { IIssue, IMinUser, IValueAndColor } from "../../../../types/global.types";
import { AG_GRID_LOCALE_NOR } from "../../agGridLocale";
import { useGetProjectQuery } from "../../app/projectRTK";
import { ISSUE_COMPLETED_VALUE, ISSUE_EXPIRED, ISSUE_STATES_OPTIONS } from "../../app/routes/issuesApp/issueConstants";
import { useDoUpdateIssueMutation } from "../../app/routes/issuesApp/issueRTK";
import { convertValueAndColorToMultiDisplayColor, getTaskCompleteCount } from "../../app/routes/issuesApp/issueUtils";
import {
  addIssueIdToTableExpandedInRedux,
  removeIssueIdFromTableExpandedInRedux,
} from "../../app/routes/meeting/meetingSlice";
import { AppModalEnums } from "../../frontendConstants";
import { addAppModalThunk } from "../../global/globalSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  Avatar,
  ClickableTableIdButton,
  ConfirmDialog,
  DisplayDueDate,
  FailedAlert,
  OptionBadge,
  TaskCompletedCount,
} from "../index";
import { TaskForAIssueTable } from "./TaskForAIssueTable";

export const ConnectedIssues: React.FC<{
  issues: IIssue[];
  createNewIssue: () => void;
  connectExistingIssue?: () => void;
  createNewFromDefault?: (id: string) => void;
  disconnectIssue: (_id: string) => void;
  isPrintMode: boolean;
  titleLabel: string;
  useClassContainer?: boolean;
  defaultIssueBoard?: { name: string; _id: string };
  forceRefetchOfData?: () => void;
  disableCellEdit?: boolean;
  hideRegisterColumn?: boolean;
  projectId: string;
}> = ({
  issues,
  createNewIssue,
  connectExistingIssue,
  createNewFromDefault,
  disconnectIssue,
  isPrintMode,
  titleLabel,
  useClassContainer,
  defaultIssueBoard,
  forceRefetchOfData,
  disableCellEdit,
  hideRegisterColumn = true,
  projectId,
}) => {
  const [updateIssue, { isError }] = useDoUpdateIssueMutation();

  const issueIdsTableExpandeded = useAppSelector((state) => state.meetingReducer.issueIdsTableExpandeded);
  const { data: project, isLoading: projectLoading } = useGetProjectQuery(projectId);
  const [gridApi, setGridApi] = useState<GridApi>(null);

  const onGridReady = (params) => {
    params.api.autoSizeColumns(["id", "dates.due", "status"]);
    setGridApi(params.api);
  };

  useEffect(() => {
    if (gridApi != null) {
      issues.forEach((issue) => {
        const isExpanded = issueIdsTableExpandeded.includes(issue._id);
        if (isExpanded === true) {
          gridApi.getRowNode(issue._id).setExpanded(true);
        } else {
          gridApi.getRowNode(issue._id).setExpanded(false);
        }
      });
    }
  }, [issueIdsTableExpandeded, gridApi]);

  const dispatch = useAppDispatch();

  const toggleExpand = (_id: string) => {
    const idExist = issueIdsTableExpandeded.includes(_id);
    if (idExist === true) {
      dispatch(removeIssueIdFromTableExpandedInRedux(_id));
    } else {
      dispatch(addIssueIdToTableExpandedInRedux(_id));
    }
  };

  const columnDefs: ColDef<IIssue, any>[] = [
    {
      hide: isPrintMode,
      width: 40,
      cellClass: "px-1",
      editable: false,
      suppressAutoSize: true,
      cellRenderer: (params) => (
        <ConfirmDialog
          title="Fjerne forbindelsen?"
          message="Er du sikker på at du vil fjerne koblingen til denne saken?"
          confirmCb={() => disconnectIssue(params.data._id)}
        >
          <Button size="sm" color="secondary" outline style={{ fontSize: "0.7rem" }}>
            <i className="fa fa-close" />
          </Button>
        </ConfirmDialog>
      ),
    },
    {
      field: "id",
      editable: false,
      sortable: false,
      autoHeight: false,
      headerName: "Id",
      cellClass: "px-0",
      cellRenderer: (props) => {
        const { node, api, data } = props;
        return (
          <>
            {data?.taskOrIssue === "ISSUE" && hideRegisterColumn === true && (
              <div
                onClick={() => toggleExpand(data._id)}
                className={`expandIcon${node.expanded === true ? " rotateIcon" : ""}`}
              >
                <i className="fa fa-angle-down"></i>
              </div>
            )}
            <ClickableTableIdButton
              id={data?.id}
              _id={data?._id}
              setItem={() =>
                dispatch(
                  addAppModalThunk({
                    itemId: data?._id,
                    boardId: data?.issueBoard?._id ? data?.issueBoard?._id : data?.issueBoard,
                    projectId: project?._id,
                    app: AppModalEnums.ISSUE,
                  }),
                )
              }
            />
            <TaskCompletedCount taskCompleteCount={getTaskCompleteCount(data, true)} />
          </>
        );
      },
    },
    {
      field: "title",
      headerName: "Tittel",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "assignedTo",
      headerName: "Ansv. for utførelse",
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: project?.members.map((m) => m.user),
        // cellRenderer: (params) => <UserCellRenderer height="15px" user={params.value} />,
        allowTyping: true,
        filterList: true,
        formatValue: (value) => value?.name ?? "",
      },
      cellRenderer: (params) => (
        <UserCellRenderer
          user={params.value}
          disableClear={disableCellEdit === true}
          onClear={() => {
            updateIssue({
              projectId: params.data.project as string,
              issueId: params.data._id,
              attr: "assignedTo",
              value: null,
              oldValue: params.value?.name ?? "",
              issueBoardId: params.data.issueBoard?._id ? params.data.issueBoard_id : params.data.issueBoard,
            });
          }}
        />
      ),
    },
    {
      field: "dates.due",
      cellEditor: "agDateCellEditor",
      headerName: "Tidsfrist",
      filter: "agDateColumnFilter",
      valueGetter: (params) => {
        return params.data?.dates?.due === null ? null : new Date(params.data?.dates?.due);
      },
      valueSetter: (params) => {
        return params.newValue.toISOString();
      },
      cellRenderer: (params) => {
        if (moment(params?.value).isValid() === false || params?.value == null || !params?.value) {
          return "-";
        }
        return (
          <DisplayDueDate
            dueDate={params?.value}
            isComplete={
              params.data != null &&
              (params.data.status === ISSUE_COMPLETED_VALUE || params.data.status === ISSUE_EXPIRED)
            }
          />
        );
      },
    },
    {
      field: "status",
      valueGetter: (params) => {
        const status = ISSUE_STATES_OPTIONS.find((opt) => opt.value === params.data.status);
        return status;
      },
      valueSetter: (params) => {
        return params.newValue.value;
      },
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: ISSUE_STATES_OPTIONS,
        cellRenderer: StatusBadgeRenderer,
        valueListMaxHeight: 220,
      },
      headerName: "Status",
      width: 120,
      cellRenderer: StatusBadgeRenderer,
    },
    {
      field: "issueBoard.name",
      headerName: "Register",
      hide: hideRegisterColumn,
    },
  ];

  const onCellEditRequest = (event: CellEditRequestEvent<IIssue>) => {
    const issue = event.data;
    let updateValue;

    if (event.colDef.field.startsWith("assignedTo")) {
      updateValue = typeof event.newValue !== "string" ? event.newValue?._id : event.newValue;
    } else if (event.colDef.field.startsWith("status")) {
      updateValue = event.newValue.value;
    } else if (event.colDef.field.startsWith("dates")) {
      if (event.newValue == null) {
        updateValue = "";
      } else {
        updateValue = event.newValue;

        if (updateValue.toISOString() === event.oldValue?.toISOString()) {
          return;
        }
      }
    } else {
      updateValue = event.newValue;
    }
    updateIssue({
      projectId: typeof issue.project === "string" ? issue.project : issue.project._id,
      issueId: issue._id,
      attr: event.colDef.field,
      value: updateValue,
      oldValue: event.oldValue?.name ?? "",
      issueBoardId: issue?.issueBoard as string,
    });
    if (forceRefetchOfData != null) {
      forceRefetchOfData();
    }
  };

  const onRowClicked = (params) => {
    if (params.event.defaultPrevented) {
      return null;
    }
    params.api.refreshCells({ force: true });
  };

  const detailCellRenderer = ({ data, node, api }: CustomCellRendererProps) => {
    return <TaskForAIssueTable issue={data} />;
  };

  return (
    <div className={useClassContainer === true ? "connectedIssueContainer" : ""}>
      {isError === true && <FailedAlert />}
      <div className="d-flex align-items-center mb-1">
        <h5 className="mb-0 mr-1">{titleLabel}</h5>
        {isPrintMode === false && (
          <>
            {connectExistingIssue == null && (
              <Button size="sm" color="success" outline onClick={createNewIssue}>
                <i className="fa fa-plus fa-fw" />
              </Button>
            )}
            {connectExistingIssue != null && (
              <UncontrolledDropdown>
                <DropdownToggle color="success" outline size="sm" style={{ padding: "4px 0.4rem", height: "1.5rem" }}>
                  <i className="fa fa-plus fa-fw" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={createNewIssue}>Ny</DropdownItem>
                  {defaultIssueBoard != null && (
                    <DropdownItem onClick={() => createNewFromDefault && createNewFromDefault(defaultIssueBoard._id)}>
                      Ny i {defaultIssueBoard.name}
                    </DropdownItem>
                  )}
                  <DropdownItem onClick={connectExistingIssue}>Koble eksisterende</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            )}
          </>
        )}
      </div>
      {issues?.length > 0 && (
        <div className="d-flex flex-column ag-theme-quartz">
          <AgGridReact
            defaultColDef={{
              sortable: true,
              suppressHeaderMenuButton: true,
              editable: !disableCellEdit,
              cellStyle: {
                whiteSpace: "normal",
                lineHeight: "1.5",
                wordBreak: "break-word",
              },
              autoHeight: true,
            }}
            domLayout={"autoHeight"}
            columnDefs={columnDefs}
            getRowId={(p) => p.data._id}
            rowData={issues}
            stopEditingWhenCellsLoseFocus={true}
            animateRows={false}
            readOnlyEdit={true}
            onCellEditRequest={onCellEditRequest}
            suppressContextMenu={true}
            masterDetail={true}
            detailRowAutoHeight={true}
            onRowClicked={onRowClicked}
            detailCellRenderer={detailCellRenderer}
            onGridReady={onGridReady}
            localeText={AG_GRID_LOCALE_NOR}
            rowHeight={45}
            popupParent={document.body}
          />
        </div>
      )}
    </div>
  );
};

export const StatusBadgeRenderer = (params) => {
  return (
    <OptionBadge
      asBadge={false}
      noWrap={true}
      activeOption={(params?.value as IValueAndColor)?.value}
      options={convertValueAndColorToMultiDisplayColor(ISSUE_STATES_OPTIONS)}
    />
  );
};

export const UserCellRenderer = ({
  user,
  height = "15px",
  onClear,
  disableClear = false,
}: {
  user: IMinUser;
  height?: string;
  onClear?: () => void;
  disableClear?: boolean;
}) => {
  return user != null ? (
    <div className="d-flex align-items-center">
      <Avatar height={height} id={user?._id} />
      <div>{user?.name}</div>
      {onClear != null && disableClear !== true && <i onClick={onClear} className="fa fa-close ml-2 mcdd-clear" />}
    </div>
  ) : (
    <></>
  );
};
