import React from "react";
import { Alert, Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";
import { VSpace } from "..";
import { IBim360FolderWithPermissionItems } from "../../../../types/global.types";
import ExcelExport from "../ExcelExport/ExcelExport";
import MultiPageExcelExport from "../ExcelExport/MultiPageExcelExport";
import BIM360DirectoryAccessTable, { BIM360DirectoryAccessTableColumns } from "./BIM360DirectoryAccessTable";
import SubjectTypeFilters from "./SubjectTypeFilters";
import { IMultiPageExcelExport, IPermissionRows, ISubjectTypesWithOptions } from "./types";
import { getAllPermissionRows, getPermissionRows, getSubjectTypesWithOptions } from "./utils";

const Bim360DirectoryAccess: React.FC<{
  folderPermissions: IBim360FolderWithPermissionItems[];
  folderName: string;
}> = ({ folderPermissions, folderName }) => {
  const [subjectTypesWithOptions, setSubjectTypesWithOptions] = React.useState<ISubjectTypesWithOptions[]>([]);
  const [activeSubjectType, setActiveSubjectType] = React.useState<string>(null);
  const [activeOption, setActiveOption] = React.useState<string>(null);
  const [permissionRows, setPermissionRows] = React.useState<IPermissionRows[]>(null);
  const [multiPermissionRows, setMultiPermissionRows] = React.useState<IMultiPageExcelExport[]>([]);
  const [failed, setFailed] = React.useState<boolean>();

  React.useEffect(() => {
    const _subjectTypesWithOptions = getSubjectTypesWithOptions(folderPermissions);
    setSubjectTypesWithOptions(_subjectTypesWithOptions);
  }, []);

  React.useEffect(() => {
    if (activeOption != null) {
      try {
        setFailed(false);
        const _permissionRows = getPermissionRows(folderPermissions, activeSubjectType, activeOption);
        setPermissionRows(_permissionRows);
      } catch (error) {
        setFailed(true);
      }
    }
  }, [activeOption]);

  React.useEffect(() => {
    if (subjectTypesWithOptions.length > 0) {
      const items = subjectTypesWithOptions.find((item) => item.subjectType === activeSubjectType).options;
      const permissions = getAllPermissionRows(folderPermissions, activeSubjectType, items);
      setMultiPermissionRows(permissions);
    }
  }, [activeSubjectType]);

  return (
    <Card className="p-2">
      <CardTitle tag="h3">BIM360 rettigheter</CardTitle>
      <CardSubtitle tag="h6" className="mb-2 text-muted">
        {folderName}
      </CardSubtitle>
      <CardBody>
        {subjectTypesWithOptions.length !== 0 && (
          <SubjectTypeFilters
            subjectTypesWithOptions={subjectTypesWithOptions}
            activeSubjectType={activeSubjectType}
            activeOption={activeOption}
            setActiveSubjectType={setActiveSubjectType}
            setActiveOption={setActiveOption}
          />
        )}
        <VSpace />
        <div>
          <div className="d-flex">
            {activeOption != null && (
              <span className="mr-2">
                <ExcelExport
                  data={permissionRows}
                  columns={BIM360DirectoryAccessTableColumns}
                  filePrefix="bim360_permissions"
                />
              </span>
            )}
            {activeSubjectType != null && (
              <span>
                <MultiPageExcelExport
                  columns={BIM360DirectoryAccessTableColumns}
                  data={multiPermissionRows}
                  filePrefix="bim360_permissions"
                />
              </span>
            )}
          </div>
        </div>
        {permissionRows != null && activeOption != null && activeSubjectType != null && (
          <>
            <VSpace />
            <BIM360DirectoryAccessTable permissionRows={permissionRows} />
          </>
        )}
        {failed && (
          <Alert color="danger">Något gick fel vid analysen av data. Kontakta support på mcapps@multiconsult.no.</Alert>
        )}
      </CardBody>
    </Card>
  );
};

export default Bim360DirectoryAccess;
