import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Button, Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";
import { IMinUser, IProject } from "../../../../../../../types/global.types";
import { IMcBimMongo } from "../../../../../../../types/mcbimMono.types";
import {
  COL_WIDTHS,
  createHeaderStyle,
  headerSortAndFilterFormatter,
  sortCaret,
} from "../../../../../components/columnGlobal";
import ConfirmDialog from "../../../../../components/ConfirmDialog";
import { ToastMessagesEnums } from "../../../../../frontendConstants";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { IBootstrapTableColumnConfig } from "../../../../../types";
import { formatShortDate } from "../../selectors";
import { doGetMcBimMongoFileVersions } from "../McBimMongoAPI";
import { useRemoveMcFileDataMutation } from "../McBimRTK";
import { isMcBimAdmin } from "../mcBimSlice";
import { InfoDialog } from "./McBimMongoExtractedFiles";
import { setShowSuccessMessageThunk } from "../../../../../global/globalSlice";

const FileVersions: React.FC<{ file: IMcBimMongo.FileIdAndName; project: IProject }> = ({ file, project }) => {
  const [fileVersions, setFileVersions] = useState<IMcBimMongo.FileStats[]>([]);
  const [nrOfDeletedDocuments, setNrOfDeletedDocuments] = useState<number>(null);

  const user = useAppSelector((state) => state.globalReducer.user);
  const userIsMcBimAdmin = isMcBimAdmin(project.mcbim.admins as IMinUser[], user?._id);

  const [removeFiles, { isLoading: removeFilesLoading, error: removeFilesError }] = useRemoveMcFileDataMutation();

  React.useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const _fileVersions = await doGetMcBimMongoFileVersions(project._id, file);
    setFileVersions(_fileVersions);
  };

  const dispatch = useAppDispatch();
  const removeSpecificFileVersion = async (fileStorageId: string, fileUploadedAt: string) => {
    const resp = await removeFiles({ projectId: project._id, fileStorageId, fileUploadedAt }).unwrap();
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.UPDATED));
    setFileVersions(fileVersions.filter((f) => f._id.fileStorageId !== fileStorageId));
    setNrOfDeletedDocuments(resp.deletedDocuments);
  };

  const columns: IBootstrapTableColumnConfig[] = [
    {
      dataField: "",
      text: "",
      headerStyle: createHeaderStyle(COL_WIDTHS.BUTTON),
      formatter: (cell: string, row) => {
        return (
          <ConfirmDialog
            title="Slett fil?"
            message={`Er du sikker på at du vil slette versjon ${formatShortDate(row._id.fileUploadedAt)} av filen ${
              row._id.fileName
            }?`}
            confirmCb={() => removeSpecificFileVersion(row._id.fileStorageId, row._id.fileUploadedAt)}
          >
            <Button disabled={userIsMcBimAdmin === false} onClick={() => console.log("click")}>
              <i className="fa fa-trash" />
            </Button>
          </ConfirmDialog>
        );
      },
    },
    {
      dataField: "_id.fileUploadedAt",
      text: "Fil opplastet",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell: string) => formatShortDate(cell),
      headerFormatter: headerSortAndFilterFormatter,
    },
    {
      dataField: "count",
      text: "Antall bimobjekter",
      sort: true,
      sortCaret: sortCaret,
      headerFormatter: headerSortAndFilterFormatter,
    },
  ];

  return (
    <Card>
      {nrOfDeletedDocuments && (
        <InfoDialog deletedDocuments={nrOfDeletedDocuments} toggle={() => setNrOfDeletedDocuments(null)} />
      )}
      <CardBody>
        <CardTitle tag="h4">Versjoner</CardTitle>
        <CardSubtitle tag="h6" className="mb-2 text-muted">
          {`Totalt ${fileVersions?.length} versjoner av filen ${file.fileName}`}
        </CardSubtitle>
        <BootstrapTable
          defaultSorted={[
            {
              dataField: "_id.fileUploadedAt",
              order: "desc",
            },
          ]}
          wrapperClasses="smalltable"
          bootstrap4
          bordered={false}
          keyField="_id"
          data={fileVersions || []}
          columns={columns}
        />
      </CardBody>
    </Card>
  );
};

export default FileVersions;
