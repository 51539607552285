import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React from "react";

export const CircleChart: React.FC<{ name: string; data: any[]; setSelectedColor: any }> = ({
  name,
  data,
  setSelectedColor,
}) => {
  // @ts-ignore
  const options: Highcharts.Options = {
    accessibility: { enabled: false }, //to remove warning
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      height: "180px",
      backgroundColor: "transparent",
      spacingTop: 0,
      spacingRight: 0,
      spacingBottom: 0,
      spacingLeft: 0,
      margin: [0, 0, 0, 0],
    },
    title: {
      text: "_",
      style: {
        color: "transparent",
      },
    },
    /*subtitle: {
      text: "Click on a slice to filter Risikoer",
    },*/
    tooltip: {
      pointFormat: "<b>{point.percentage:.1f}%</b>",
    },
    plotOptions: {
      pie: {
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: false,
        point: {
          events: {
            click: (e) => {
              setSelectedColor(name, e.point.color);
            },
          },
        },
      },
    },
    series: [
      {
        name: "Score",
        colorByPoint: true,
        type: null,
        data,
      },
    ],
    credits: {
      enabled: false,
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
