import React, { useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ISpecificationTable } from "../../types";
import { VSpace } from "../index";
import ExcelImportValidator, { ValidatorProps, ValidatorRef } from "./ExcelImportValidator";

export const ExcelImportValidatorWithSpecTable: React.FC<
  {
    specificationTable?: ISpecificationTable;
    closeAfterSuccessfulImport?: boolean;
  } & ValidatorProps
> = ({ specificationTable, onValidated, closeAfterSuccessfulImport, ...props }) => {
  const [specificationModalOpen, setSpecificationModalOpen] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>(null);

  return (
    <div>
      <Button color="primary" onClick={() => setSpecificationModalOpen(true)}>
        Importer excel-fil
      </Button>
      {specificationTable != null && specificationModalOpen === true && (
        <SpecificationModal
          specificationTable={specificationTable}
          close={() => setSpecificationModalOpen(false)}
          onValidated={(result) => {
            if (result.errors != null) {
              setErrors(result.errors);
            } else {
              onValidated(result);
              if (closeAfterSuccessfulImport === true) {
                setSpecificationModalOpen(false);
              }
            }
          }}
          {...props}
        />
      )}

      {errors?.length > 0 && (
        <Modal isOpen toggle={() => setErrors(null)}>
          <ModalHeader toggle={() => setErrors(null)}>Feil i Excel-fil</ModalHeader>
          <ModalBody>
            <ul>
              {errors.map((error, i) => (
                <li key={i}>
                  <pre
                    style={{
                      whiteSpace: "pre-wrap",
                      wordWrap: "break-word",
                      fontFamily: "inherit",
                    }}
                  >
                    {error}
                  </pre>
                </li>
              ))}
            </ul>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => setErrors(null)}>
              Lukk
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};

export default ExcelImportValidatorWithSpecTable;

const SpecificationModal: React.FC<
  {
    specificationTable: ISpecificationTable;
    close: () => void;
  } & ValidatorProps
> = ({ specificationTable, close, ...props }) => {
  const validatorRef = React.useRef<ValidatorRef>(null);
  const specificationTableData = useMemo(() => {
    return specificationTable.data.map((row, index) => {
      return { id: index, ...row };
    });
  }, [specificationTable.data]);

  const specificationTableColumns = useMemo(() => {
    return [
      {
        dataField: "id",
        text: "id",
        hidden: true,
      },
      ...specificationTable.columns,
    ];
  }, [specificationTable.columns]);

  return (
    <Modal isOpen>
      <ModalHeader toggle={close}>Spesifikasjon</ModalHeader>
      <ModalBody>
        <h5>Excel-filen skal inneholde disse kolonnene</h5>
        <BootstrapTable
          wrapperClasses="smalltable h-100 overflow-auto px-1"
          bootstrap4
          bordered={false}
          hover
          keyField="id"
          data={specificationTableData}
          columns={specificationTableColumns}
        />
        <VSpace />
        <ExcelImportValidator ref={validatorRef} {...props}>
          <Button color="primary" onClick={() => validatorRef.current.selectFile()}>
            Velg fil
          </Button>
        </ExcelImportValidator>
      </ModalBody>
    </Modal>
  );
};
