import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { FlatHierarchy, IPNSProps } from "../../../../../../../types/global.types";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { HierarchyNode } from "../../../../hierarchyNodeUtils";
import { useGetProjectQuery } from "../../../../projectRTK";
import { getProjectIdFromUrl } from "../../../../utils";

//https://github.com/highcharts/highcharts/issues/11353 - fix for highcharts after v9.0.0 current version in mcapps is 7.2.1
//regarding error [Violation] Added non-passive event listener to a scroll-blocking 'touchmove' event. Consider marking event handler as 'passive' to make the page more responsive.

// This component is used in project dashboard. Needs to load mccos and mcbim if not already loaded
export const MmiTab: React.FC = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const projectId = getProjectIdFromUrl(location.pathname);

  const { data: project } = useGetProjectQuery(projectId);

  const mcBimIsActivated = project?.mcbim?.activated;
  /*
  const mcCosMcBimLoading = useAppSelector((state) => state.mcCosReducer.mcCosMcBimLoading);
  const mcCosProject = useAppSelector((state) => state.mcCosReducer.mcCosProject);
  const mcBimFailed = useAppSelector((state) => state.mcCosReducer.mcbimFailedToLoad);
  const mcCosFailed = useAppSelector((state) => state.mcCosReducer.mccosFailedToLoad);*/
  const pnsItems: FlatHierarchy<IPNSProps> = useAppSelector((state) => state.adminReducer.project.pns);
  const [selectedPnsNode, setSelectedPnsNode] = useState<HierarchyNode<IPNSProps>[]>(null);

  //only load if mccosProject == null - aka. the MmiTab is used as dashboard widget - loaddmccos and mcbim
  /*React.useEffect(() => {
    if (mcCosProject == null && project != null && mcBimIsActivated === true) {
      dispatch(loadMcCosAndMcBimThunk());
    }
  }, [mcCosProject, project, dispatch]);*/

  /*const MMI_IS_NOT_SET = mcCosProject?.mmiSettings?.mcbimAttr === "psets.NOT_SET.NOT_SET";*/

  return (
    <div className="d-flex flex-column w-100 h-100 overflow-auto">
      Temporarily removed
      {/* {(mcCosMcBimLoading === true || mcCosProject == null) && <MCLoader loading />}
      {(mcBimFailed === true || mcCosFailed === true) && (
        <Alert color="danger">
          Feil ved innlasting av MCBIM / MMI-appen. Prøv å last inn siden på nytt. Ved vedvarende feil, kontakt support
          på mcapps@multiconsult.no
        </Alert>
      )}
      {(mcBimIsActivated !== true || mcCosProject == null) && (
        <Alert color="info">
          COS & MMI Appen er ikke satt opp for prosjektet.{" "}
          <Link to={`/project/${project?._id}/bimai`}>Gå til COS & MMI Appen</Link> for å konfigurere
        </Alert>
      )}
      {mcCosProject != null && MMI_IS_NOT_SET === true && project != null && (
        <div className="p-2" style={{ width: "500px" }}>
          <Alert>
            <p>
              For å bruke dette dashbordet trenger du spesifikke MMI-egenskaper. Dette gjøres under
              <Link
                className="ml-1"
                to={`/project/${project?._id}/bimai/${BIMAISECTIONENUMS.mcbimsettings}/${BIMAIPAGEENUMS.mccos_mmipns}`}
              >
                MMI & PNS-egenskaper
              </Link>
            </p>
          </Alert>
        </div>
      )}
      {mcCosProject != null && mcBimFailed !== true && mcCosFailed !== true && MMI_IS_NOT_SET === false && (
        <>
          <MmiFilter />
          <div className="d-flex" style={{ height: "100%" }}>
            {mcCosProject.wbs.isActive === true && pnsItems?.length > 0 && (
              <HierarchyTreeSelector
                style={{
                  minWidth: "200px",
                  maxWidth: "400px",
                  background: "#ededed",
                }}
                isVisible={true}
                nodeList={pnsItems}
                idProp={"pnsId"}
                titleProp={"title"}
                onSelectionChange={(nodes) => {
                  setSelectedPnsNode(nodes); //got error: A non-serializable value was detected in an action, in the path: `payload` if using PayloadAction<HierarchyNode<IPNSProps>>. therefore using node.props, pns tree needs node
                  const pnsIds = nodes.map((node) => node.props.pnsId);
                  dispatch(setPnsRedux(pnsIds));
                }}
                selectedNodeIds={selectedPnsNode ? selectedPnsNode.map((node) => node.id) : []}
                multiSelect={true}
              />
            )}
            <Card className="w-100">
              <CardBody>
                <CardTitle tag="h4">MMI I Prosjektet</CardTitle>
                <VSpace />
                <MmiCharts />
              </CardBody>
            </Card>
          </div>
        </>
      )}*/}
    </div>
  );
};
