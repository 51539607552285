import Axios from "axios";
import saveAs from "file-saver";
import { IFeatureToggle } from "mc-shared/types/global";
import { INameAndUseDashboardAsDefaultStartPage } from "../../../../../mc-shared/types/project.types";
import { IBIM360 } from "../../../../../types/bim360.types";
import {
  IApp,
  IBim360FolderContent,
  IBIM360FolderPermissionItem,
  IBim360NameUrnType,
  IBimObject,
  ICustomReturnRespForgeFolderCall,
  IDisciplin,
  IError,
  IFilterItem,
  IFilterItemIdAndGlobalIds,
  IGraphAPIUser,
  IIfcfileLabel,
  IifcSpaceFile,
  IMaconomyProject,
  IMilestone,
  IOrgChart,
  IProject,
  IProjectApp,
  ISpaceDepricated,
  ISpaceFileWithFloorsAndSpaces,
  IUsageLog,
} from "../../../../../types/global.types";
import React from "react";

export const translateFile = async (
  objectId: string,
  fileName: string,
  projectId: string,
  itemUrn: string,
  bim360ProjectId: string,
) => {
  try {
    const urn = btoa(objectId);
    await Axios.request({
      method: "POST",
      url: `/api/project/${projectId}/forge/modelderivate/job/${urn}`,
      data: {
        fileName,
        itemUrn: btoa(itemUrn),
        bim360ProjectId,
      },
    });
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const putUpdateBim360FolderUrn = async (projectId, folderUrn) => {
  try {
    await Axios.put(`/api/project/${projectId}/forge/folderurn/${folderUrn}`);
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetBim360FolderContent = async (
  projectId: string,
  bim360ProjectId: string,
  folderurn: string,
): Promise<ICustomReturnRespForgeFolderCall> => {
  try {
    const folderContent = await Axios.get(
      `/api/project/${projectId}/forge/bim360Project/${bim360ProjectId}/folder/${folderurn}/foldercontent`,
    );
    return folderContent.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetBim360FolderPermission = async (
  projectId: string,
  bim360ProjectId: string,
  folderurn: string,
): Promise<IBIM360FolderPermissionItem[]> => {
  try {
    const folderContent = await Axios.get(
      `/api/project/${projectId}/forge/bim360Project/${bim360ProjectId}/folder/${folderurn}/folderpermission`,
    );
    return folderContent.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const getForgeFileTranslationStatus = async (projectId, folderurn) => {
  try {
    const folderContent = await Axios.get(`/api/project/${projectId}/forge/file/translationstatus?urn=${folderurn}`);
    return folderContent;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetBim360RootFolders = async (
  projectId: string,
  bim360ProjectId: string,
): Promise<IBim360NameUrnType[]> => {
  try {
    const rootFolders = await Axios.get(`/api/project/${projectId}/forge/bim360project/${bim360ProjectId}/rootfolders`);
    return rootFolders.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetParentOfBim360Folder = async (
  projectId: string,
  bim360ProjectId: string,
  fodlerId: string,
): Promise<IBim360FolderContent> => {
  try {
    const parentFolder = await Axios.get(
      `/api/project/${projectId}/forge/bim360project/${bim360ProjectId}/folder/${fodlerId}/parent`,
    );
    return parentFolder.data.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetBim360Users = async (): Promise<IBIM360.User[]> => {
  try {
    const bim360UsersFetch = await Axios.get(`/api/bim360users`);
    return bim360UsersFetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetBim360OProjects = async (projectId: string, bim360AccountId: string) => {
  try {
    const bim360ProjectsFetch = await Axios.get(
      `/api/project/${projectId}/forge/bim360account/${bim360AccountId}/projects`,
    );
    return bim360ProjectsFetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetBim360OProjectUsers = async (projectId: string, bim360ProjectId: string): Promise<IBIM360.User[]> => {
  try {
    const bim360ProjectsFetch = await Axios.get(
      `/api/project/${projectId}/forge/bim360project/${bim360ProjectId}/users`,
    );
    return bim360ProjectsFetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGenerateDocx = async (
  data: any,
  templateName: string,
  outputName: string,
  isManuallyUploaded: boolean,
) => {
  try {
    const file = await Axios.put(
      "/api/rapport/generate",
      { data, templateName, isManuallyUploaded },
      {
        responseType: "arraybuffer",
      },
    );
    const blob = new Blob([file.data], { type: "application/x-msdownload" });
    saveAs(blob, `${outputName}.docx`);
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doCreateOrJoinProject = async (maconomy: IMaconomyProject) => {
  try {
    const projectFetch = await Axios.put(`/api/user/project`, { maconomy });
    return projectFetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doCreateProject = async (maconomy: IMaconomyProject) => {
  try {
    const projectFetch = await Axios.put(`/api/user/project/create`, { maconomy });
    return projectFetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doJoinProject = async (projectId) => {
  try {
    const projectFetch = await Axios.put(`/api/user/project/${projectId}/join`);
    return projectFetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doConnectBim360ProjectToMCAppsProject = async (projectId: string, bim360ProjectId) => {
  try {
    const projectFetch = await Axios.put(`/api/project/${projectId}/forge/connectBim360/${bim360ProjectId}`);
    return projectFetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetBim360Project = async (projectId: string, bim360ProjectId: string) => {
  try {
    const projectFetch = await Axios.get(`/api/project/${projectId}/forge/bim360project/${bim360ProjectId}`);
    return projectFetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetOsloIfcGeom = async () => {
  try {
    const officeGeomFetch = await Axios.get("/api/ifcgeom/oslo");
    return officeGeomFetch.data.filter((space) => space.longName !== "Area");
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetForgeToken = async (projectId: string) => {
  try {
    const tokenFetch = await Axios.get(`/api/project/${projectId}/forge/token`);
    return tokenFetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doAddExternalMemberToProject = async (email: string, group: string, projectId: string, name: string) => {
  try {
    const updatedProject = await Axios.put(`/api/project/${projectId}/addExternalMember/${email}`, { group, name });
    return updatedProject;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const emailExist = async (email: string) => {
  try {
    const emailExist = await Axios.get(`/api/email/${email}`);
    return emailExist;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doAutoRefreshMaconomyUserList = async (projectId: string) => {
  try {
    await Axios.put(`/api/project/${projectId}/auto-refresh-maconomy`);
    return "OK";
  } catch (error) {
    return Promise.reject(error);
  }
};

export const doGetErrors = async (): Promise<IError[]> => {
  try {
    const resp = await Axios.get(`/api/error`);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doDeleteError = async (errorId: string) => {
  try {
    const resp = await Axios.delete(`/api/error/${errorId}`);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetSingleProjectWithAccessResp = async (
  projectId: string,
  cb: (project: IProject, noAccess: boolean) => void,
) => {
  try {
    const resp = await Axios.get(`/api/project/${projectId}/single`);
    cb(resp.data, false);
  } catch (error) {
    if (error.response.data === "unauthorized, c33") {
      cb(null, true);
    } else {
      return Promise.reject({ type: "backend", message: error.response?.data });
    }
  }
};

export const doGetSingleProject = async (projectId: string): Promise<IProject> => {
  try {
    const resp = await Axios.get(`/api/project/${projectId}/single`);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doSetJoinTeamsToTrue = async () => {
  try {
    const resp = await Axios.put(`/api/user/update-join-teams`);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetIfcSpaceFiles = async (projectId: string): Promise<IifcSpaceFile[]> => {
  try {
    const resp = await Axios.get(`/api/project/${projectId}/ifcspacefile`);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetSingleIfcSpaceFile = async (
  projectId: string,
  spaceFileId: string,
): Promise<ISpaceFileWithFloorsAndSpaces> => {
  try {
    const resp = await Axios.get(`/api/project/${projectId}/ifcspacefile/${spaceFileId}`);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetSpacesGroupedByFilter = async (
  projectId: string,
  spaceFileId: string,
  property: string,
  filterItems: IFilterItem[],
): Promise<IFilterItemIdAndGlobalIds[]> => {
  try {
    const resp = await Axios.put(`/api/project/${projectId}/ifcspacefile/aggregate/filter-items`, {
      spaceFileId,
      property,
      filterItems,
    });
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetSingleSpace = async (
  projectId: string,
  spaceFileId: string,
  spaceId: string,
): Promise<ISpaceDepricated> => {
  try {
    const resp = await Axios.get(`/api/project/${projectId}/ifcspacefile/${spaceFileId}/space/${spaceId}`);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doSaveIfcLabels = async (projectId: string, ifcfileLabels: IIfcfileLabel[]) => {
  try {
    await Axios.put(`/api/project/${projectId}/ifcfileLabels`, { ifcfileLabels });
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doSearchBimObjects = async (
  projectId: string,
  forgeFile: string,
  propertyName: string,
  value: string,
  pset: string,
): Promise<IBimObject[]> => {
  try {
    const resp = await Axios.put(`/api/bimobject/project/${projectId}/search`, {
      forgeFile,
      propertyName,
      value,
      pset,
    });
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetMsGraphUserByEmail = async (fullEmail: string): Promise<IGraphAPIUser> => {
  try {
    const resp = await Axios.get(`/api/user/msuser/${fullEmail}`);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doSaveProjectApps = async (projectId: string, projectApps: IProjectApp[]): Promise<IProjectApp[]> => {
  try {
    const fetch = await Axios.put(`/api/project/${projectId}/projectapps`, { apps: projectApps });
    return fetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetApps = async (): Promise<IApp[]> => {
  try {
    const fetch = await Axios.get(`/api/apps`);
    return fetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doSaveProjectSettings = async (
  projectId: string,
  name: string,
  useDashboardAsDefaultStartPage: boolean,
): Promise<INameAndUseDashboardAsDefaultStartPage> => {
  try {
    const fetch = await Axios.put(`/api/project/${projectId}/settings`, { name, useDashboardAsDefaultStartPage });
    return fetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetIsProduction = async (): Promise<boolean> => {
  try {
    const fetch = await Axios.get("/api/is-production");
    return fetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetIsDev = async (): Promise<boolean> => {
  try {
    const fetch = await Axios.get("/api/is-dev");
    return fetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doSaveProjectOrgChart = async (projectId: string, orgChart: IOrgChart): Promise<IOrgChart> => {
  try {
    const fetch = await Axios.put(`/api/project/${projectId}/orgchart`, { orgChart });
    return fetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetReleaseNotes = async (): Promise<string> => {
  try {
    const fetch = await Axios.get(`api/releasenotes`);
    return fetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doLogUsage = async (project: IProject, appName: string, feature: string): Promise<void> => {
  try {
    const log: IUsageLog = {
      appName,
      feature,
      project: project._id,
    };

    await Axios.post(`/api/log`, log);
  } catch (error) {
    console.error(error);
  }
};

export const doLogError = async (errorMessage: string, errorStack: string, url: string): Promise<void> => {
  try {
    await Axios.post(`/api/errorlog`, {
      errorMessage,
      errorStack,
      url,
    });
  } catch (error) {
    console.error(error);
  }
};

export const doTestEmail = async (template: string) => {
  try {
    const resp = await Axios.post(`/api/123-mail-test-123/template/${template}`);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doSendUserMessage = async (message: string, subject: string) => {
  try {
    await Axios.post(`/api/user-message`, { subject, message });
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doTestReceiveEmail = async () => {
  try {
    const resp = await fetch(`/api/123-mail-test-123/receive-email`, {
      method: "POST",
      body: JSON.stringify({ email: "john.smith@gmail.com", name: "john", message: "hi!" }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return resp;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doUpdateDefaultDisciplines = async (
  projectId: string,
  useDefaultDisciplines: boolean,
): Promise<string> => {
  try {
    const resp = await Axios.put(`/api/project/${projectId}/usedefaultdisciplines`, { useDefaultDisciplines });
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doUpdateDisciplines = async (projectId: string, disciplines: IDisciplin[]): Promise<IDisciplin[]> => {
  try {
    const resp = await Axios.put(`/api/project/${projectId}/disciplines`, { disciplines });
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doUpdateMilestones = async (projectId: string, milestones: IMilestone[]): Promise<IMilestone[]> => {
  try {
    const resp = await Axios.put(`/api/project/${projectId}/milestones`, { milestones });
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetFeatureToggle = async (featureGroup: string, featureName: string): Promise<IFeatureToggle> => {
  try {
    const resp = await Axios.get(`/api/featuretoggle/${featureGroup}/${featureName}`);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doUpdateFeatureToggle = async (featureToggle: IFeatureToggle): Promise<IFeatureToggle> => {
  try {
    const resp = await Axios.put(`/api/featuretoggle`, { featureToggle });
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doImportRowsMcCosNRH = async (): Promise<{ success: boolean; rowCount: number }> => {
  try {
    const fetch = await Axios.post(`/api/json-to-rows`);
    return fetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doUploadOfficialMeetingDocxTemplate = async (event: React.ChangeEvent<HTMLInputElement>) => {
  try {
    const data = new FormData();
    data.append("fileToUpload", event.target.files[0]);
    const resp = await Axios.request({
      method: "POST",
      url: `/api/meeting/template`,
      data,
      headers: {
        "content-type": `multipart/form-data`,
      },
    });
    return Promise.resolve(resp);
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const downloadAzureBlobFile = async (fullBlobPath: string, fileName: string) => {
  try {
    const resp = await Axios.post(`/api/download-blob-file`, { fullBlobPath }, { responseType: "blob" });
    const blob = new Blob([resp.data], { type: "application/octet-stream" });
    saveAs(blob, fileName);
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};
