import Board, { moveCard } from "@lourenci/react-kanban";
import "@lourenci/react-kanban/dist/styles.css";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { IBoard, IIssue, IProject } from "../../../../../../../types/global.types";
import { VSpace } from "../../../../../components";
import { AppModalEnums } from "../../../../../frontendConstants";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { IssueKanbanGroupingViewEnum } from "../../issueConstants";
import { useDoUpdateIssueMutation } from "../../issueRTK";
import { convertIssuesToKanbanBoardColumns, getIssueUrlPath } from "../../issueUtils";
import { IKanbanBoard, IKanbanCard, IKanbanColumn, IKanbanFrom, IKanbanTo } from "../../types";
import { KanbanCard } from "./KanbanCard/KanbanCard";
import { addAppModalThunk } from "../../../../../global/globalSlice";

const IssueKanban: React.FC<{
  issues: IIssue[];
  issueBoard: IBoard;
  project: IProject;
}> = ({ issues, issueBoard, project }) => {
  const dispatch = useAppDispatch();

  const [kanbanBoardColumns, setKanbanBoardColumns] = useState<IKanbanColumn<IIssue>[]>();

  const [updateIssue, { isLoading: isUpdating, error: errorUpdate }] = useDoUpdateIssueMutation();

  const kanbanGroupingView = useAppSelector((state) => state.issueReducerNew.kanbanGroupingView);

  const history = useHistory();

  useEffect(() => {
    const _issues: IIssue[] =
      kanbanGroupingView === IssueKanbanGroupingViewEnum.BUCKET
        ? issues.filter((issue) => issue.taskOrIssue === "ISSUE")
        : issues;
    const _kanbanBoardColumns = convertIssuesToKanbanBoardColumns(_issues, kanbanGroupingView, issueBoard.buckets);
    setKanbanBoardColumns(_kanbanBoardColumns);
  }, [kanbanGroupingView, issueBoard.allFilters, issues]);

  const onCardDragEnd = async (card: IKanbanCard<IIssue>, from: IKanbanFrom, to: IKanbanTo) => {
    const fromCol = kanbanBoardColumns.find((col) => col.id === from.fromColumnId);
    const toCol = kanbanBoardColumns.find((col) => col.id === to.toColumnId);
    const updatedBoard: IKanbanBoard<IIssue> = moveCard({ columns: kanbanBoardColumns }, from, to);

    setKanbanBoardColumns(updatedBoard.columns);

    updateIssue({
      projectId: project._id,
      issueBoardId: issueBoard._id,
      issueId: card.content._id,
      attr: kanbanGroupingView.toLowerCase(),
      value: toCol.value,
      oldValue: fromCol.value,
      order: to.toPosition,
    });
  };

  const openIssue = (issue: IIssue) => {
    history.push(getIssueUrlPath(history.location.pathname, issue._id));
    dispatch(
      addAppModalThunk({
        itemId: issue._id,
        boardId: issueBoard._id,
        projectId: project._id,
        app: AppModalEnums.ISSUE,
      }),
    );
  };

  return (
    <div className="h-100 w-100 d-flex flex-column" style={{ overflow: "auto" }}>
      <VSpace />
      <hr className="m-0" />
      {kanbanBoardColumns != null && (
        <Board
          disableColumnDrag
          onCardDragEnd={onCardDragEnd}
          children={{
            columns: kanbanBoardColumns,
          }}
          renderCard={({ content }) => <KanbanCard openContent={openIssue} content={content} />}
        />
      )}
    </div>
  );
};

export default IssueKanban;

export interface IKanBanbanGroupoingItem {
  label: string;
  value: IssueKanbanGroupingViewEnum;
}
