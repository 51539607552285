import React, { useState } from "react";
import { Button } from "reactstrap";
import McInput from "../McInput/McInput";
import "./ContactForm.css";

const ContactForm: React.FC<{
  sendMessage: (message: string, subject: string) => Promise<void>;
  prefilledSubject?: string;
}> = ({ sendMessage, prefilledSubject }) => {
  const [message, setMessage] = useState<string>("");
  const [subject, setSubject] = useState<string>(prefilledSubject != null ? prefilledSubject : "");

  return (
    <div className="d-flex flex-column align-items-center send-message-container">
      <div className="d-flex flex-column align-items-center w-75 pl-7">
        <div className="w-75 input-container">
          <h5 className="truncateHeaderText">Emne</h5>
          <McInput className="mb-2" autoFocus={true} value={subject} onChange={(e) => setSubject(e.target.value)} />
          <h5 className="truncateHeaderText">Melding</h5>
          <McInput
            type="textarea"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Synspunkter, forslag, problemer..."
          />
        </div>
        <Button
          className="m-3"
          color="success"
          disabled={message.length < 10 || subject.length < 1}
          onClick={() => sendMessage(subject, message)}
        >
          Send
        </Button>
        <div className="d-flex justify-content-center w-100 email">
          Eller send en e-post til: &nbsp;
          <a href="mailto:mcapps@multiconsult.no" rel="noreferrer" target="_blank">
            mcapps@multiconsult.no
          </a>
        </div>
      </div>
      <div className="envelope">
        <i className="fa fa-envelope fa-fw" color="primary" />
      </div>
    </div>
  );
};

export default ContactForm;
