import { IIssue } from "../../../../../../../types/global.types";
import { IGanttChartSeriesDataItem } from "../../../../../types";
import { isEmpty } from "../../../../utils";
import { ISSUE_STATES_OPTIONS } from "../../issueConstants";

export const getGanttSeries = (items: IIssue[], taskEnabled: boolean): IGanttChartSeriesDataItem[] => {
  if (taskEnabled === false) {
    return items.reduce((acc, item) => {
      if (isEmpty(item.dates?.plannedStart) === false && isEmpty(item.dates?.due) === false) {
        acc.push(formatItem(item));
      }
      return acc;
    }, []);
  } else {
    return items.reduce((acc, item) => {
      if (isEmpty(item.dates?.plannedStart) === false && isEmpty(item.dates?.due) === false) {
        let formatedItem = formatItem(item);
        if (item.taskOrIssue === "TASK") {
          //@ts-ignore
          const parent = items.find((_itm) => _itm._id === item.connectedIssue._id);
          if (
            parent != null &&
            isEmpty(parent?.dates?.plannedStart) === false &&
            isEmpty(parent?.dates?.due) === false
          ) {
            //@ts-ignore
            formatedItem.parent = item.connectedIssue?._id;
            acc.push(formatedItem);
          }
        } else {
          //@ts-ignore
          // formatedItem.collapsed = true;
          acc.push(formatedItem);
        }
      }
      return acc;
    }, []);
  }
};
const formatItem = (item) => {
  const color = ISSUE_STATES_OPTIONS.find((it) => it.value === item.status);

  return {
    name: `<a href="${window.location.href.split("?")[0]}?id=${item._id}"> ${item.id}</a>: ${item.title}`,
    id: item._id, // "prepare_building",
    start: getGanttDate(item.dates.plannedStart),
    end: getGanttDate(item.dates.due),
    //@ts-ignore
    owner: item.owner && item.owner.name,
    assignedTo: item.assignedTo && item.assignedTo.name,
    color: color && color.color,
    //parent: null,
  };
};

const getGanttDate = (date) => {
  let today = new Date(date);
  today.setUTCHours(0);
  today.setUTCMinutes(0);
  today.setUTCSeconds(0);
  today.setUTCMilliseconds(0);
  //@ts-ignore
  today = today.getTime();
  return today;
};
