import React, { useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { useHistory } from "react-router-dom";
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { CreateNewButton, McDropdown, VSpace } from "..";
import { IApp, IBasicItem } from "../../../../types/global.types";
import { IRegister } from "../../../../types/register.types";
import { MCApplicationsUrlEnum } from "../../frontendConstants";

const ConnectExistingItemModal: React.FC<{
  items: IBasicItem[];
  onSelectBoard: (boardId) => void;
  selectedBoard: IRegister.BoardPopulated;
  boards: IRegister.BoardPopulated[];
  onConnect: (boardId, itemId) => void;
  modalTitle: string;
  toggle: () => void;
  currentApp: MCApplicationsUrlEnum;
  apps: IApp[];
  projectId: string;
  connectButtonText?: string;
}> = ({
  items,
  onSelectBoard,
  selectedBoard,
  boards,
  onConnect,
  modalTitle,
  toggle,
  apps,
  currentApp,
  projectId,
  connectButtonText = "Koble sak",
}) => {
  const history = useHistory();
  const [singleSelections, setSingleSelections] = useState([]);
  const fullApp = apps.find((app) => app.name === currentApp);
  const registerUrl = `/project/${projectId}/${fullApp?.label};`;

  const goToRegister = () => {
    history?.push(registerUrl);
  };
  return (
    <Modal isOpen size="md" toggle={toggle}>
      <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
      <ModalBody>
        {boards.length > 0 ? (
          <>
            <h5>Velg register</h5>
            <McDropdown
              currentValue={selectedBoard}
              onChange={(oldVal, newVal) => onSelectBoard(newVal)}
              options={boards}
              displayKey="name"
            />
          </>
        ) : (
          <>
            <Alert color="info">Det er ikke satt opp noe register ennå.</Alert>
            <Button color="link" onClick={() => goToRegister()}>
              Gå til registeradministrasjon for {apps.find((app) => app.name === currentApp)?.label}
            </Button>
          </>
        )}
        <VSpace />
        {items?.length > 0 && selectedBoard != null && (
          <Typeahead
            allowNew={false}
            selected={singleSelections}
            id="1"
            onChange={setSingleSelections}
            placeholder="Velg sak"
            labelKey="displayValue"
            options={items}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <CreateNewButton
          disabled={singleSelections[0]?._id == null}
          label={connectButtonText}
          onClick={() => onConnect(selectedBoard._id, singleSelections[0]._id)}
        />
        <Button color="secondary" onClick={() => toggle()}>
          Lukk
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConnectExistingItemModal;
