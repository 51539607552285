import { OrgChart } from "d3-org-chart";
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Button, ButtonGroup } from "reactstrap";
import { IParentChildItem } from "../../../../types/global.types";
import Styles from "./parentChildChart.module.css";
import { debounce, parseHex } from "./utils";

const ParentChildChart: React.FC<{
  items: IParentChildItem[];
  onClick: (id: string) => void;
  onToggleFullScreen?: () => void;
  fullScreen?: boolean;
  viewerMode?: boolean;
}> = ({ items, onClick, onToggleFullScreen, fullScreen, viewerMode = false }) => {
  const chart = useMemo(() => new OrgChart(), []);
  const d3Ref = useRef<HTMLDivElement>(null);
  const holder = useRef<HTMLDivElement>(null);

  const [formattedItems, setFormattedItems] = useState<any>();

  if (holder.current && chart) {
    chart.svgHeight(holder.current.clientHeight);
  }

  const format = () => {
    const formatted = items.map((item) => {
      const { parent = "", id } = item;
      return {
        ...item,
        connectorLineColor: {
          red: 0,
          green: 0,
          blue: 0,
          alpha: 0.6,
        },
        nodeId: id,
        parentNodeId: parent.length !== 0 ? parent : null,
      };
    });
    setFormattedItems(formatted);
  };

  useEffect(() => {
    const onResize = () => {
      if (holder.current) {
        chart.svgHeight(holder.current.clientHeight);
        chart.render();
      }
    };
    const debouncedFn = debounce(onResize, 100);
    window.addEventListener("resize", debouncedFn);
    return () => {
      window.removeEventListener("resize", debouncedFn);
    };
  }, [chart]);

  useEffect(() => {
    format();
  }, [items]);

  useLayoutEffect(() => {
    if (d3Ref.current && formattedItems) {
      try {
        chart
          .container(d3Ref.current)
          .compactMarginBetween((d) => 100)
          .siblingsMargin((d) => 600)
          .data(formattedItems)
          .svgWidth(500)
          .initialZoom(viewerMode ? 0.2 : 0.8)
          .onNodeClick((d: any) => {
            onClick(d);
          })
          .nodeContent((d: any, i: any, arr: any, state: any) => {
            const {
              label,
              labelColor = "inherit",
              subLabel,
              subLabelColor = "inherit",
              bgColor = "#000",
              bgOpacity = 1,
              textColor = "inherit",
              textsFontSize = "inherit",
              texts = [],
            } = d.data;
            const backgroundColor = {
              ...parseHex(bgColor),
              alpha: bgOpacity,
            };
            const { red, green, blue, alpha } = backgroundColor;
            const outputColor = `rgba(${red},${green},${blue},${alpha})`;
            const allTexts = texts.map((text: String) => `<p>${text}</p>`).join("");
            return `<div class="${Styles.card}" style="background-color:${outputColor};">
                            <div>
                                <h2 style="color: ${labelColor}; font-size:18px;"><b>${label}</b></h2>
                                <p style="color: ${subLabelColor};">${subLabel}</p>
                            </div>
                            <div class=${Styles.texts} style="font-size: ${textsFontSize}; color: ${textColor} ">
                                ${allTexts}
                            </div>
                        </div>`;
          })
          .render()
          .expandAll();
      } catch (err) {
        console.error(err);
      }
    }
  }, [formattedItems, d3Ref, chart, onClick]);

  const expandAll = () => {
    chart.expandAll();
    chart.fit();
  };

  const collapsAll = () => {
    chart.collapseAll();
    chart.fit();
  };

  return (
    <div className={`position-relative ${Styles.holder}`}>
      <div className="position-absolute d-flex w-100 justify-content-end" style={{ zIndex: 1 }}>
        {fullScreen != null && viewerMode != true && (
          <Button onClick={() => onToggleFullScreen && onToggleFullScreen()}>
            {fullScreen === true ? <i className="fa fa-chevron-right" /> : <i className="fa fa-chevron-left" />}
          </Button>
        )}
        <div className="d-flex flex-grow-1"></div>
        <ButtonGroup>
          <Button onClick={() => expandAll()}>
            <i className="fa fa-expand" />
          </Button>
          <Button onClick={() => chart.fit()}>
            <i className="fa fa-search" />
          </Button>
          <Button onClick={() => collapsAll()}>
            <i className="fa fa-compress" />
          </Button>
        </ButtonGroup>
      </div>
      <div ref={holder} className={Styles.holder}>
        <div id="parent-child-chart" ref={d3Ref} className={Styles.svgHolder} />
      </div>
    </div>
  );
};

export default ParentChildChart;
