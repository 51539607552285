import React from "react";
import { apiSlice } from "../../../apiSlice";
import { FailedAlert, MCLoader } from "../../../components";
import NewDocxUpload from "../../../components/DocxReport/NewDocxUpload/NewDocxUpload";

import FileTable from "../../../components/FileUploader/FileTable";
import { setShowSuccessMessageThunk } from "../../../global/globalSlice";
import {
  useDeleteAzFileMutation,
  useGetOfficalMulticonsultIssueTempaltesQuery,
  useUploadOfficalMulticonsultIssueTempalteMutation,
} from "../../../globalRTK";
import { useAppDispatch } from "../../../hooks";
import { downloadAzureBlobFile } from "../admin/restQueries";

export const OfficialIssueTemplateUpload: React.FC = () => {
  const dispatch = useAppDispatch();

  const [uploadIssueDocx, { isError: uploadError, isLoading: uploadLoading }] =
    useUploadOfficalMulticonsultIssueTempalteMutation();

  const upload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const data = new FormData();
    data.append("fileToUpload", event.target.files[0]);
    await uploadIssueDocx(data);
    dispatch(apiSlice.util.invalidateTags(["Global"]));
    if (event.target != null) {
      event.target.value = null;
    }
    dispatch(setShowSuccessMessageThunk("Malen er lastet opp"));
  };

  const {
    data: issueTemplates = [],
    isLoading: templatesLoading,
    isError: getIssueTemplatesError,
  } = useGetOfficalMulticonsultIssueTempaltesQuery("");

  const [deleteOfficialTemplate, { isError: deleteOfficalTemplateError, isLoading: deleteLoading }] =
    useDeleteAzFileMutation();

  const isError = deleteOfficalTemplateError || getIssueTemplatesError || uploadError;

  const isLoading = templatesLoading || deleteLoading || uploadLoading;

  return (
    <div>
      {isLoading === true && <MCLoader />}
      {isError === true && <FailedAlert />}
      <NewDocxUpload
        docxTemplates={[]}
        uploadCompleedCb={() => alert("File successfully uploaded")}
        uploadFunc={upload}
        demoData={{ name: "hello" }}
      />
      <hr />
      <FileTable
        downloadFile={(blobName, fileName) => downloadAzureBlobFile(blobName, fileName)}
        files={issueTemplates}
        deleteFile={(blobName) => deleteOfficialTemplate(blobName)}
      />
    </div>
  );
};
