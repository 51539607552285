import { apiSlice } from "../../../apiSlice";

export const errorRTK = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    deleteAllErrors: builder.mutation<string, void>({
      query: () => ({
        url: `/error`,
        method: "DELETE",
      }),
      invalidatesTags: ["Global"],
    }),
  }),
});

export const { useDeleteAllErrorsMutation } = errorRTK;
