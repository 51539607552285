import React, { useState } from "react";
import { Button, ButtonGroup } from "reactstrap";
import { IViewPoint } from "../../types";
import { ConfirmDialog } from "../index";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";

const Viewpoints: React.FC<{
  viewPoints: IViewPoint[];
  selectedViewPointId: string;
  onClickOnRow: (_id: string) => void;
  onClickOnOpen: (_id: string) => void;
  onDeleteViewPoint: (_id: string) => void;
}> = ({ viewPoints, selectedViewPointId, onClickOnRow, onClickOnOpen, onDeleteViewPoint }) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  const deleteVp = (_id: string) => {
    onDeleteViewPoint(_id);
  };

  const columns: ColDef[] = [
    {
      field: "title",
      headerName: "Navn",
      cellClass: "px-0",
      flex: 1,
      maxWidth: 200,
      autoHeight: true,
      cellStyle: {
        whiteSpace: "normal",
        lineHeight: "1.5",
        wordBreak: "break-word",
      },
      onCellClicked: (params) => onClickOnRow(params.data._id),
      cellRenderer: (params) => {
        return (
          <div
            className="pl-2 w-100 two-line-text-truncate"
            style={{ borderLeft: params.data._id === selectedViewPointId ? "5px solid #3b92e1" : "" }}
          >
            {params.value}
          </div>
        );
      },
    },
    {
      field: "status.title",
      headerName: "Status",
      width: 80,
      onCellClicked: (params) => onClickOnRow(params.data._id),
    },
    {
      field: "_id",
      headerName: "",
      cellClass: "d-flex",
      width: 80,
      cellRenderer: (params) => {
        return (
          <ButtonGroup>
            <Button
              size="sm"
              outline
              color="mc-blue"
              style={{ borderStyle: "none" }}
              onClick={() => onClickOnOpen(params.value)}
            >
              <i className="fa fa-ellipsis-h" />
            </Button>
            <ConfirmDialog
              title="Slette viewpoint"
              message="Er du sikker på at du vil slette denne viewpointen?"
              confirmCb={() => deleteVp(params.value)}
            >
              <Button size="sm" outline color="mc-blue" style={{ borderStyle: "none" }}>
                <i className="fa fa-trash-o" />
              </Button>
            </ConfirmDialog>
          </ButtonGroup>
        );
      },
    },
  ];

  return (
    <div className="d-flex border position-relative h-100 overflow-auto">
      <Button
        className="d-flex align-self-center align-items-center justify-content-center"
        color="success"
        onClick={() => setIsCollapsed(!isCollapsed)}
        style={{ height: "100px", width: "34px", position: "absolute", left: "-34px" }}
      >
        <i className={isCollapsed ? "fa fa-angle-double-left fa-fw fa-2x" : "fa fa-angle-double-right fa-fw fa-2x"} />
      </Button>
      {isCollapsed === false && (
        <div style={{ width: "400px" }}>
          <div className="ag-theme-quartz h-100 w-100">
            <AgGridReact
              domLayout={"autoHeight"}
              rowData={viewPoints || []}
              defaultColDef={{
                suppressHeaderMenuButton: true,
                cellClass: "d-block px-2",
              }}
              headerHeight={35}
              columnDefs={columns}
              suppressClickEdit={true}
              animateRows={false}
              suppressContextMenu={true}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Viewpoints;
