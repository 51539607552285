import * as R from "ramda";
import React from "react";
import {
  FlatHierarchy,
  IColsBySection,
  IIssue,
  IMember,
  IPNSProps,
  IRisikoEvaluationCategory,
  IRiskoBoard,
  ITag,
} from "../../../../types/global.types";
import { IBootstrapTableColumnConfig } from "../../types";
import { COMMON_COLUMN_SECTIONS } from "../columnGlobal";
import ColumnsForm from "./ColumnsForm";

const MainItem: React.FC<{
  item: any;
  displayBySections: boolean;
  columns: IBootstrapTableColumnConfig[];
  saveCb: <T extends keyof IIssue>(dataField: T, newValue: IIssue[T], oldValue: string) => void;
  pnsItems?: FlatHierarchy<IPNSProps>;
  boardId: string;
  fetchUniqueTags?: (projectId: string, boardId: string) => Promise<ITag[]>;
  evaluationCategories?: IRisikoEvaluationCategory[];
  risikoBoard?: IRiskoBoard;
  projectId: string;
  members: IMember[];
}> = ({
  item,
  displayBySections,
  columns,
  saveCb,
  pnsItems,
  boardId,
  fetchUniqueTags,
  evaluationCategories,
  risikoBoard,
  projectId,
  members,
}) => {
  const [sections, setSections] = React.useState<IColsBySection>();

  React.useEffect(() => {
    if (displayBySections) {
      const nonEmptySections = columns.filter(
        (col) => col.section != null && col.section !== COMMON_COLUMN_SECTIONS.HISTORY,
      );
      const _sections = R.groupBy((col) => col.section, nonEmptySections);
      setSections(_sections);
    }
  }, []);

  return (
    <div className="d-flex">
      {displayBySections &&
        sections != null &&
        Object.keys(sections).map((sectionName) => {
          return (
            <div className="ml-3 mt-3" key={sectionName} style={{ flex: "1 1 0", width: 0 }}>
              <h4>{sectionName.toUpperCase()}</h4>
              <ColumnsForm
                key={sectionName}
                item={item}
                columns={columns.filter((col) => col.section === sectionName)}
                saveCb={saveCb}
                pnsItems={pnsItems}
                projectId={projectId}
                members={members}
                boardId={boardId}
                fetchUniqueTags={fetchUniqueTags}
                evaluationCategories={evaluationCategories}
                risikoBoard={risikoBoard}
              />
            </div>
          );
        })}
      {displayBySections !== true && (
        <ColumnsForm
          fetchUniqueTags={fetchUniqueTags}
          boardId={boardId}
          item={item}
          pnsItems={pnsItems}
          columns={columns}
          saveCb={saveCb}
          projectId={projectId}
          members={members}
        />
      )}
    </div>
  );
};

export default MainItem;
