import React, { useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import { Button, UncontrolledTooltip } from "reactstrap";
import { HSpace, VSpace } from "../../../../components";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { setHideDeletedMeetingSeriesInRedux } from "../meetingSlice";
import { MeetingSeriesTable } from "./MeetingSeriesTable";
import NewMeetingSeriesModal from "./NewMeetingSeriesModal";

export const MeetingSeries: React.FC = () => {
  const [newMeetingSerieModal, setNewMeetingSerieModalOpen] = useState<boolean>(false);
  const refContainer = useRef(null);

  const hideDeletedMeetingSeries = useAppSelector((state) => state.meetingReducer.hideDeletedMeetingSeries);

  const dispatch = useAppDispatch();
  return (
    <div className="d-flex flex-column h-100 w-100 overflow-auto p-2">
      <div className="d-flex">
        <Button color="success" onClick={() => setNewMeetingSerieModalOpen(true)}>
          <i className="fa fa-plus fa-fw" />
          Ny møteserie
        </Button>
        <div className="flex-fill" />
        <Button
          active={hideDeletedMeetingSeries === false}
          onClick={() => dispatch(setHideDeletedMeetingSeriesInRedux(!hideDeletedMeetingSeries))}
        >
          Vis slettede møteserier
        </Button>
        <HSpace />
        <ReactToPrint
          trigger={() => (
            <div>
              <Button color="secondary" id="tabellutskrift">
                <i className="fa fa-print fa-fw" />
              </Button>
              <UncontrolledTooltip placement="top" target="tabellutskrift">
                Tabellutskrift
              </UncontrolledTooltip>
            </div>
          )}
          content={() => refContainer.current}
        />
      </div>
      <VSpace />
      {newMeetingSerieModal === true && <NewMeetingSeriesModal toggle={() => setNewMeetingSerieModalOpen(false)} />}
      <div ref={refContainer}>
        <MeetingSeriesTable />
      </div>
    </div>
  );
};
