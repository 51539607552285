import React from "react";

const CheckIcon: React.FC<{ completedNr: number; totalNr: number }> = ({ completedNr, totalNr }) => {
  if (completedNr === totalNr && completedNr !== 0) {
    return <i className="fa fa-check-circle fa-fw success-text" />;
  }
  if (completedNr !== totalNr || totalNr === 0) {
    return <i className="fa fa-check-circle fa-fw" />;
  }
};
export default CheckIcon;
