import * as _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Badge, Button } from "reactstrap";
import { useElementSize } from "usehooks-ts";
import { HSpace, SingleTag } from ".";
import { ITag } from "../../../types/global.types";
import { sortArrayByString } from "../app/utils";
import "./Tags/Tags.css";

const Tags: React.FC<{
  tags: string[];
  fetchUniqTags: (...args: string[]) => Promise<ITag[]>;
  fetchArgs: string[];
  updateCb: (tags: string[]) => void;
  showAddButton?: boolean;
}> = ({ tags, fetchUniqTags, fetchArgs, updateCb, showAddButton }) => {
  const [addMode, setAddMode] = useState(false);
  const [options, setOptions] = useState<string[]>();
  const [addButtonHidden, setAddButtonHidden] = useState(true);
  const [visibleTags, setVisibleTags] = useState(tags);
  const [hideExpand, setHideExpand] = useState(true);
  const [containElementRef, { height }] = useElementSize();
  const textElementRef = useRef<HTMLDivElement>(null);
  const textHeight = textElementRef?.current?.scrollHeight ?? 0;

  useEffect(() => {
    setAddMode(false);
    if (showAddButton) {
      setAddButtonHidden(false);
    }
  }, []);

  const addTag = async (tagToAdd) => {
    //hides delay while updating db
    let newArray = [...tags];
    setAddMode(false);
    newArray.unshift(tagToAdd);
    setVisibleTags(newArray);
    const uniqTags = _.uniq([tagToAdd, ...tags]);
    updateCb(uniqTags);
  };

  const deleteTag = async (tagToDelete) => {
    let tagsToUpdate = tags.filter((tag) => tag !== tagToDelete);
    setVisibleTags(tagsToUpdate);
    updateCb(tagsToUpdate);
  };

  const onTypeaheadChange = (arr) => {
    if (arr != null) {
      if (arr[0] != null) {
        if (typeof arr[0] === "string") {
          addTag(arr[0]);
        } else {
          addTag(arr[0].name);
        }
      }
    }
  };

  const _setAddMode = async () => {
    const allUniqTags = await fetchUniqTags(...fetchArgs);
    setAddMode(true);
    setOptions(sortArrayByString(allUniqTags, "_id").map((tag) => tag._id));
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        className="d-flex"
        onMouseEnter={() => setAddButtonHidden(false)}
        onMouseLeave={() => setAddButtonHidden(!showAddButton)}
        style={{ overflow: "hidden", height: `${hideExpand ? "50px" : "fit-content"}` }}
        ref={containElementRef}
      >
        <div className={` d-flex align-items-center ${addButtonHidden ? "fadein" : "fadeout"}`}>
          {addMode === false && (
            <Button
              color="mc-blue"
              style={{ height: "2em", marginLeft: "-10px", zIndex: 1000, position: "absolute" }}
              size="sm"
              onClick={() => _setAddMode()}
            >
              <i className="fa fa-plus"></i>
            </Button>
          )}
        </div>
        <HSpace />
        <div
          className="w-100 d-flex flex-wrap justify-content-start"
          style={{ alignItems: `${textHeight > 50 ? "flex-start" : "center"}` }}
          ref={textElementRef}
        >
          {addMode && (
            <>
              <Badge
                style={{ padding: "3px 6px", position: "absolute", top: "-2px" }}
                // pill
                className="d-flex greybadge"
              >
                <div className="d-flex align-items-center">
                  <i className="fa fa-tag fa-fw" style={{ fontSize: "10px", marginRight: "2px" }} />
                  {options != null && (
                    <Typeahead
                      inputProps={{ className: "tagInput" }}
                      clearButton
                      id="selections-example"
                      // @ts-ignore
                      labelKey="name"
                      options={options || []}
                      placeholder="Skriv inn en tag ..."
                      onChange={(arrVal) => onTypeaheadChange(arrVal)}
                      allowNew
                      autoFocus
                    />
                  )}
                  <i
                    onClick={() => setAddMode(false)}
                    className="fa fa-close fa-fw"
                    style={{ fontSize: "10px", cursor: "pointer", marginLeft: "2px" }}
                  />
                </div>
              </Badge>
              <div style={{ width: "150px", height: "20px", margin: "3px" }}></div>
            </>
          )}
          {(visibleTags || []).map((tag, index) => (
            <div key={`C${index}`} className="d-flex m-0 p-0">
              <SingleTag key={index} displayValue={tag} deleteTag={deleteTag} />
            </div>
          ))}
        </div>
        {(textHeight > 52 || height > 50) && (
          <div className="d-flex align-items-end" style={{ width: "30px", position: "relative", paddingBottom: "5px" }}>
            <button
              className={`expandText addIcon ${hideExpand === true ? "" : " rotateIcon"}`}
              onClick={() => setHideExpand(!hideExpand)}
            >
              <i className="fa fa-angle-down"></i>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Tags;
