import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { MCApplicationsUrlEnum } from "../../../frontendConstants";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { doLogUsage } from "../admin/restQueries";
import { BatchJobTemplateEnum } from "./batchJobConstants";
import { fetchAndSetBatchJobsThunk, getBatchJobBoardThunk } from "./batchJobSlice";
import Bim360RevitToIfc from "./templates/Bim360RevitToIfc/Bim360RevitToIfc";
import Bim360SheetExtractor from "./templates/Bim360SheetExtractor/Bim360SheetExtractor";

const BatchJobBoard: React.FC<{ batchJobId: string; template: string }> = ({ batchJobId, template }) => {
  const project = useAppSelector((state) => state.adminReducer.project);
  const activeBatchJob = useAppSelector((state) => state.batchJobReducer.activeBatchJob);
  const batchJobs = useAppSelector((state) => state.batchJobReducer.batchJobs);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getBatchJobBoardThunk(batchJobId));
    if (batchJobs == null) {
      dispatch(fetchAndSetBatchJobsThunk());
    }
  }, [batchJobId, template]);

  useEffect(() => {
    doLogUsage(project, MCApplicationsUrlEnum.BATCHJOB, "open");
  }, []);

  return (
    <div className="p-2 h-100 w-100 overflow-auto">
      <h6 className="gray-500-text">Batchjob: {activeBatchJob?.template}</h6>
      <Switch>
        <Route
          path={`/project/${project._id}/batchjob/${BatchJobTemplateEnum.BIM360REVITTOIFC.toLowerCase()}/${batchJobId}`}
        >
          {activeBatchJob != null && <Bim360RevitToIfc />}
        </Route>
        <Route
          path={`/project/${
            project._id
          }/batchjob/${BatchJobTemplateEnum.BIM360SHEETEXTRACTOR.toLowerCase()}/${batchJobId}`}
        >
          {activeBatchJob != null && <Bim360SheetExtractor />}
        </Route>
      </Switch>
    </div>
  );
};

export default BatchJobBoard;
