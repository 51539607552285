import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { CreateNewButton, McDropdown } from "..";
import { IApp, IBoard } from "../../../../types/global.types";
import { MCApplicationsUrlEnum } from "../../frontendConstants";

const CreateItemWithBoardSelectorModal: React.FC<{
  boards: IBoard[];
  defaultIssueBoard?: { name: string; _id: string };
  onCreate: (boardId: string) => void;
  modalTitle: string;
  toggle: () => void;
  currentApp: MCApplicationsUrlEnum;
  apps: IApp[];
  projectId: string;
  createButtonText?: string;
}> = ({
  boards,
  onCreate,
  modalTitle,
  toggle,
  apps,
  currentApp,
  projectId,
  createButtonText = "Opprett",
  defaultIssueBoard,
}) => {
  const [selectedBoard, setSelectedBoard] = React.useState<IBoard>(null);

  useEffect(() => {
    if (defaultIssueBoard != null) {
      setSelectedBoard(boards.find((board) => board._id === defaultIssueBoard._id));
    } else if (boards.length > 0) {
      setSelectedBoard(boards[0]);
    } else {
      setSelectedBoard(null);
    }
  }, [defaultIssueBoard, boards]);

  const history = useHistory();

  const fullApp = apps.find((app) => app.name === currentApp);
  const registerUrl = `/project/${projectId}/${fullApp?.label};`;

  const goToRegister = () => {
    history?.push(registerUrl);
  };
  return (
    <Modal isOpen size="md" toggle={toggle}>
      <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
      <ModalBody>
        {boards.length > 0 ? (
          <>
            <h5>Velg register</h5>
            <McDropdown
              currentValue={selectedBoard}
              onChange={(oldVal, newVal) => setSelectedBoard(newVal)}
              options={boards}
              displayKey="name"
            />
          </>
        ) : (
          <>
            <Alert color="info">Det er ikke satt opp noe register ennå.</Alert>
            <Button color="link" onClick={() => goToRegister()}>
              Gå til registeradministrasjon for {apps.find((app) => app.name === currentApp)?.label}
            </Button>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <CreateNewButton
          disabled={selectedBoard == null}
          label={createButtonText}
          onClick={() => onCreate(selectedBoard._id)}
        />
        <Button color="secondary" onClick={() => toggle()}>
          Lukk
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateItemWithBoardSelectorModal;
