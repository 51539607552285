import Axios from "axios";
import { ForgeBIM360FolderContentCallResponse } from "../../../../types/forge.types";

export const doGetBim360ItemInfo = async (
  projectId,
  bim360ProjectId: string,
  itemUrn: string,
): Promise<ForgeBIM360FolderContentCallResponse.IForgeFileItem> => {
  try {
    const resp = await Axios.get(`/api/project/${projectId}/forge/bim360project/${bim360ProjectId}/item/${itemUrn}`);
    return resp.data?.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};
