import React from "react";
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { IValueLabelGeneric } from "../../../../../types/global.types";
import { DropdownSelectorDeprecated, HSpace } from "../../../components";
import { BatchJobTemplateEnum } from "./batchJobConstants";

const NewBatchJobModal: React.FC<{
  onCreate: (name: string, template: BatchJobTemplateEnum) => void;
  closeModal: () => void;
  templates: IValueLabelGeneric<string>[];
}> = ({ onCreate, closeModal, templates }) => {
  const [name, setName] = React.useState<string>("");
  const [template, setTemplate] = React.useState<BatchJobTemplateEnum>(null);

  return (
    <Modal isOpen toggle={closeModal}>
      <ModalHeader className="gray-dark" modalclassname="mc-orange-text" toggle={closeModal}>
        Ny BatchJob
      </ModalHeader>
      <ModalBody>
        <div className="w-100">
          <div className="d-flex align-items-center mb-2">
            <div style={{ width: "20%" }}>Navn</div>
            <div style={{ width: "80%" }}>
              <Input placeholder="Angi navn på batch-jobb" value={name} onChange={(e) => setName(e.target.value)} />
            </div>
          </div>
          <div className="d-flex align-items-center mb-2">
            <div style={{ width: "20%" }}>Mal</div>
            <div style={{ width: "80%" }}>
              <DropdownSelectorDeprecated
                items={templates}
                displayKey="label"
                fullWidth
                setItem={(_template) => setTemplate(_template.value)}
                activeItem={templates.find((temp) => temp.value === template)}
                selectText={template != null ? template : "Velg batch-jobb-mal"}
                color="mc-blue"
              />
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button disabled={name === "" || template == null} color="success" onClick={() => onCreate(name, template)}>
          Opprett
        </Button>
        <HSpace />
        <Button color="secondary" onClick={closeModal}>
          Avbryt
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default NewBatchJobModal;
