import React, { useState } from "react";
import { IRisiko } from "../../../../../types/global.types";
import { FileTableDepricated, MCLoader } from "../../../components/";
import FileUploaderDepricated from "../../../components/FileUploader/FileUploaderDepricated";
import { doDeleteRisikoFile, doDownloadRisikoFile, doRisikoFileUpload } from "./risikoAPI";

const RisikoFiles: React.FC<{ projectId: string; risiko: IRisiko; fetchAndSetRisiko: () => void }> = ({
  projectId,
  risiko,
  fetchAndSetRisiko,
}) => {
  const [loading, setLoading] = useState(false);

  const uploadFile = async (event) => {
    setLoading(true);
    await doRisikoFileUpload(event, projectId, risiko._id);
    setLoading(false);
    fetchAndSetRisiko();
  };

  const downloadFile = async (fileId: string, filename) => {
    setLoading(true);
    await doDownloadRisikoFile(projectId, fileId, risiko._id, filename);
    setLoading(false);
  };

  const deleteFile = async (fileId: string) => {
    setLoading(true);
    await doDeleteRisikoFile(projectId, risiko._id, fileId);
    setLoading(false);
    fetchAndSetRisiko();
  };

  return (
    <div>
      {loading === false ? (
        <>
          <FileUploaderDepricated onFileUpload={uploadFile} />
          <FileTableDepricated
            downloadFile={downloadFile}
            files={risiko.uploadedFiles}
            deleteFile={deleteFile}
            folderPath={`/api/image/depricated/risiko/${risiko?._id}`}
            //TODO: update upload and delete path to use azure, use /api/image/${project._id}/risiko
          />
        </>
      ) : (
        <MCLoader loading />
      )}
    </div>
  );
};

export default RisikoFiles;
