import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch, useHistory, withRouter } from "react-router-dom";
import { IApp, IMember, IProject, IProjectApp, IUser } from "../../../types/global.types";
import { Sidebar } from "../components/";
import ErrorBoundary from "../util/ErrorBoundary";
import asyncComponent from "../util/asyncComponent";
import MCNavbar from "./MCNavbar";
import ProjectBreadcrumbs from "./ProjectBreadCrumbs/ProjectBreadcrumbs";
import { logicWhenProjectOpens } from "./actions";
import AppSettingsPage from "./routes/admin/AppSettingsPage";
import MembersPage from "./routes/admin/members/MembersPage";
import { doGetSingleProjectWithAccessResp, doJoinProject } from "./routes/admin/restQueries";
import { isEmailDomainMulticonsult } from "./routes/admin/selectors";
import BatchJobApp from "./routes/batchjob/BatchJobApp";
import BatchJobBoard from "./routes/batchjob/BatchJobBoard";
import { Bim360Explorer } from "./routes/bim360explorer/Bim360Explorer";
import { BIMAIPAGEENUMS, BIMAISECTIONENUMS } from "./routes/bimai/bimAiConstants";
import ChecklistApp from "./routes/checklist/ChecklistApp";
import ChecklistBoards from "./routes/checklist/ChecklistBoards";
import { TypeOfChecklistEnum } from "./routes/checklist/ChecklistConstants";
import IssueApp from "./routes/issuesApp/IssueApp";
import IssueBoards from "./routes/issuesApp/IssueBoards";
import { MeetingApp } from "./routes/meeting/MeetingApp";
import SingleMeeting from "./routes/meeting/SingleMeeting/SingleMeeting";
import PnsApp from "./routes/pns/PnsApp";
import ProjectDashboard from "./routes/projectdashboard/ProjectDashboard";
import ReportApp from "./routes/report/ReportApp";
import MainReportPage from "./routes/report/ReportBoard/MainReportPage";
import RisikoApp from "./routes/risiko/RisikoApp";
import RisikoBoards from "./routes/risiko/RisikoBoards";
import AppsPage from "./routes/start/AppsPage";
import { IRootState } from "../store";
import { selectUserMember } from "../global/globalSlice";

interface IProps {
  match: {
    url: string;
  };
  drawerType: string;
  navigationStyle: string;
  horizontalNavPosition: string;
  project: IProject;
  history: any;
  location: any;
  client: any;
  logicWhenProjectOpens: any;
  user: IUser;
  isMulticonsultUser: boolean;
  member: IMember;
  apps: IApp[];
}

interface IState {
  showLoadingSync: boolean;
  project: IProject;
  enabledApps: any;
}

class ProjectApp extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      showLoadingSync: false,
      project: null,
      enabledApps: null,
    };
  }

  init = async () => {
    // @ts-ignore
    doGetSingleProjectWithAccessResp(this.props.match.params.projectId, async (project, noAccess) => {
      if (noAccess) {
        try {
          // @ts-ignore
          project = await doJoinProject(this.props.match.params.projectId);
        } catch (error) {
          return this.props.history.push(`/no-access`);
        }
      }
      this.setState({ enabledApps: null });
      const enabledApps = []; //getEnabledApps(project);
      this.setState({ project, enabledApps });
      const isUserMulticonsult = isEmailDomainMulticonsult(this.props.user && this.props.user.email);
      if (isUserMulticonsult && process.env.NODE_ENV !== "development") {
        this.props.logicWhenProjectOpens(this.props.match, this.props.client, this.props.history, true);
      } else {
        this.props.logicWhenProjectOpens(this.props.match, this.props.client, this.props.history, false);
      }
    });
  };

  async componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
    if (this.getProjectId(this.props.location.pathname) !== this.getProjectId(prevProps.location.pathname)) {
      this.init();
    }
  }

  getProjectId = (url) => {
    const arr = url.split("/");
    return arr[2];
  };

  isCollapsed = (): boolean => {
    return window.location.href.endsWith("apps") === false || window.location.href.endsWith("settings/apps");
  };

  render() {
    const queryParams = new URLSearchParams(this.props.location.search);
    const isClean = queryParams.get("doksam") === "true";
    const { match } = this.props;
    return (
      <div className="mc-grid-template">
        <div style={{ gridArea: "header" }}>{!isClean && <MCNavbar isStartPage={false} />}</div>
        <div className="" style={{ gridArea: "sidebar" }}>
          {!isClean && this.props.project != null && <Sidebar isCollapsed={this.isCollapsed()} />}
        </div>
        {this.props.project != null && this.props.user != null && (
          <>
            <div style={{ gridArea: "bread" }}>{!isClean && <ProjectBreadcrumbs />}</div>
            <div className="d-flex overflow-hidden h-100">
              <Switch>
                <Route path={`${match.url}/apps`}>
                  <ErrorBoundary>
                    <AppsPage />
                  </ErrorBoundary>
                </Route>
                <Route
                  path={`${match.url}/members`}
                  component={() => (
                    <ErrorBoundary>
                      <MembersPage projectId={this.props.project._id} />
                    </ErrorBoundary>
                  )}
                />
                <Route
                  path={`${match.url}/settings`}
                  component={() => <AppSettingsPage projectId={this.props.project._id} />}
                />
                {memberHasAccessToApp(
                  this.props.member,
                  this.props.project.apps,
                  this.props.apps.find((app) => app.name === "bim360explorer"),
                ) && (
                  <Route path={`${match.url}/bim360explorer`}>
                    <ErrorBoundary>
                      <Bim360Explorer bim360={this.props.project.bim360} projectId={this.props.project._id} />
                    </ErrorBoundary>
                  </Route>
                )}
                {memberHasAccessToApp(
                  this.props.member,
                  this.props.project.apps,
                  this.props.apps.find((app) => app.name === "issues"),
                ) && (
                  <Route exact path={`${match.url}/issues`}>
                    <ErrorBoundary>
                      <IssueBoards />
                    </ErrorBoundary>
                  </Route>
                )}
                {memberHasAccessToApp(
                  this.props.member,
                  this.props.project.apps,
                  this.props.apps.find((app) => app.name === "issues"),
                ) && (
                  <Route
                    path={`${match.url}/issues/:issueBoardId`}
                    exact
                    render={(props) => (
                      <ErrorBoundary>
                        <IssueApp issueBoardId={props.match.params.issueBoardId} />
                      </ErrorBoundary>
                    )}
                  />
                )}
                {memberHasAccessToApp(
                  this.props.member,
                  this.props.project.apps,
                  this.props.apps.find((app) => app.name === "risiko"),
                ) && (
                  <Route
                    path={`${match.url}/risiko`}
                    exact
                    render={() => (
                      <ErrorBoundary>
                        <RisikoBoards />
                      </ErrorBoundary>
                    )}
                  />
                )}
                {memberHasAccessToApp(
                  this.props.member,
                  this.props.project.apps,
                  this.props.apps.find((app) => app.name === "risiko"),
                ) && (
                  <Route
                    path={`${match.url}/risiko/:risikoBoardId`}
                    exact
                    render={(props) => (
                      <ErrorBoundary>
                        <RisikoApp risikoBoardId={props.match.params.risikoBoardId} />
                      </ErrorBoundary>
                    )}
                  />
                )}
                {memberHasAccessToApp(
                  this.props.member,
                  this.props.project.apps,
                  this.props.apps.find((app) => app.name === "pns"),
                ) && (
                  <Route
                    path={`${match.url}/pns`}
                    exact
                    render={() => (
                      <ErrorBoundary>
                        <PnsApp />
                      </ErrorBoundary>
                    )}
                  />
                )}

                <Route
                  path={`${match.url}/checklist`}
                  exact
                  component={() => <Redirect to={`${match.url}/checklist/${TypeOfChecklistEnum.CHECKLIST}`} />}
                />

                {memberHasAccessToApp(
                  this.props.member,
                  this.props.project.apps,
                  this.props.apps.find((app) => app.name === "checklist"),
                ) && (
                  <Route
                    path={`${match.url}/checklist/:typoeofchecklist`}
                    render={(props) => (
                      <ErrorBoundary>
                        <ChecklistBoards typeOfChecklist={props.match.params.typoeofchecklist as TypeOfChecklistEnum} />
                      </ErrorBoundary>
                    )}
                    exact
                  />
                )}

                {memberHasAccessToApp(
                  this.props.member,
                  this.props.project.apps,
                  this.props.apps.find((app) => app.name === "checklist"),
                ) && (
                  <Route
                    path={`${match.url}/checklist/:typoeofchecklist/:checklistId/`}
                    exact
                    render={(props) => (
                      <ErrorBoundary>
                        <ChecklistApp checklistId={props.match.params.checklistId} />
                      </ErrorBoundary>
                    )}
                  />
                )}

                <Route path={`${match.url}/dashboard`} exact component={() => <ProjectDashboard />} />
                {memberHasAccessToApp(
                  this.props.member,
                  this.props.project.apps,
                  this.props.apps.find((app) => app.name === "batchjob"),
                ) && (
                  <Route
                    path={`${match.url}/batchjob`}
                    exact
                    component={() => (
                      <ErrorBoundary>
                        <BatchJobApp />
                      </ErrorBoundary>
                    )}
                  />
                )}

                {memberHasAccessToApp(
                  this.props.member,
                  this.props.project.apps,
                  this.props.apps.find((app) => app.name === "batchjob"),
                ) && (
                  <Route
                    path={`${match.url}/batchjob/:template/:batchJobId`}
                    render={(props) => (
                      <ErrorBoundary>
                        <BatchJobBoard
                          template={props.match.params.template}
                          batchJobId={props.match.params.batchJobId}
                        />
                      </ErrorBoundary>
                    )}
                  />
                )}

                {memberHasAccessToApp(
                  this.props.member,
                  this.props.project.apps,
                  this.props.apps.find((app) => app.name === "reports"),
                ) && (
                  <Route
                    path={`${match.url}/reports`}
                    exact
                    component={() => (
                      <ErrorBoundary>
                        <ReportApp />
                      </ErrorBoundary>
                    )}
                  />
                )}

                {memberHasAccessToApp(
                  this.props.member,
                  this.props.project.apps,
                  this.props.apps.find((app) => app.name === "reports"),
                ) && (
                  <Route
                    path={`${match.url}/reports/:reportId`}
                    render={(props) => (
                      <ErrorBoundary>
                        <MainReportPage reportId={props.match.params.reportId} />
                      </ErrorBoundary>
                    )}
                  />
                )}

                {memberHasAccessToApp(
                  this.props.member,
                  this.props.project.apps,
                  this.props.apps.find((app) => app.name === "meetings"),
                ) && (
                  <Route
                    path={`${match.url}/meetings`}
                    render={(props) => (
                      <ErrorBoundary>
                        <MeetingApp />
                      </ErrorBoundary>
                    )}
                    exact
                  />
                )}

                {memberHasAccessToApp(
                  this.props.member,
                  this.props.project.apps,
                  this.props.apps.find((app) => app.name === "meetings"),
                ) && (
                  <Route
                    path={`${match.url}/meetings/:meetingId`}
                    render={(props) => (
                      <ErrorBoundary>
                        <SingleMeeting meetingId={props.match.params.meetingId} />
                      </ErrorBoundary>
                    )}
                    exact
                  />
                )}
                <Route component={asyncComponent(() => import("../components/Error404"))} />
              </Switch>
            </div>
          </>
        )}
      </div>
    );
  }
}

const BimAiRedirect: React.FC = () => {
  const history = useHistory();

  const location = history.location;

  useEffect(() => {
    history.replace(`${location.pathname}/${BIMAISECTIONENUMS.mcbimsettings}/${BIMAIPAGEENUMS.mcbim_admins}`);
  }, []);

  return <h1>hello</h1>;
};

const mapStateToProps = (state: IRootState) => ({
  project: state.adminReducer.project,
  user: state.globalReducer.user,
  member: selectUserMember(state),
  apps: state.globalReducer.apps,
});

export default withRouter(
  // @ts-ignore
  connect(mapStateToProps, {
    logicWhenProjectOpens,
  })(ProjectApp),
);

export const memberHasAccessToApp = (member: IMember, projectApps: IProjectApp[], app: IApp) => {
  const projectApp = projectApps.find((papp) => papp.app === app?.name);
  if (projectApp == null) {
    return false;
  }
  if (member?.isPassive === true) {
    return false;
  }

  if (member?.group == null) {
    return true;
  }

  if (projectApp.active && (member.group === "INTERNAL" || member.group === "ADMIN")) {
    return true;
  }

  if (projectApp.access.partner && member.group === "PARTNER") {
    return true;
  }

  if (projectApp.access.client && member.group === "CLIENT") {
    return true;
  }
  return false;
};
