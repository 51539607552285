import React, { useState } from "react";
import { Button } from "reactstrap";

export const DeleteConfirmDepricated = ({ id, deleteCb, text, deleteModeCb = null }) => {
  const [deleteMode, setDeleteMode] = useState(false);

  const _setDeleteMode = () => {
    setDeleteMode(!deleteMode);
    if (deleteModeCb != null) {
      deleteModeCb(!deleteMode);
    }
  };

  return (
    <>
      {deleteMode === false && (
        <>
          <Button id={id} color="danger" size="sm" outline className="ml-2" onClick={() => _setDeleteMode()}>
            <i className="fa fa-trash fa-fw" />
          </Button>
        </>
      )}
      {deleteMode && (
        <div>
          <p className="text-danger">{text}</p>
          <div className="d-flex">
            <Button outline color="danger" size="sm" onClick={() => deleteCb()}>
              <i className="fa fa-trash fa-fw" />
              Slett
            </Button>
            <Button outline color="prmary" size="sm" onClick={() => _setDeleteMode()}>
              <i className="fa fa-close fa-fw" />
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
