import { IGKMaalingAvTrykkfallOverHepafilterResultater, IGKRapport } from "mc-shared/zod/report/GK/gkReportSchema";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../../types/global.types";
import { HSpace, McInput } from "../../../../../../components";
import ReportDocxImageContainer from "../../../ReportBoard/ReportDocxImageContainer";

const MaalingAvTrykkfallOverHepaFilter: React.FC<{
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
  data: IGKMaalingAvTrykkfallOverHepafilterResultater;
}> = ({ data, onUpdateEditData }) => {
  const addNewRow = () => {
    const newFilter = {
      filterNr: "",
      maaleresultat: null,
    };

    const updatedMaaleresultater = [...data.inndata.maaleresultater, newFilter];

    onUpdateEditData("testResultater.maalingAvTrykkfallOverHepafilter.inndata.maaleresultater", updatedMaaleresultater);

    return updatedMaaleresultater;
  };

  const removeLastRow = () => {
    const updatedMaaleresultater = [...data.inndata.maaleresultater];

    if (updatedMaaleresultater.length > 0) {
      updatedMaaleresultater.pop(); // Remove the last element
      onUpdateEditData(
        "testResultater.maalingAvTrykkfallOverHepafilter.inndata.maaleresultater",
        updatedMaaleresultater,
      );
    }
  };

  const updateMaaleresultater = (resultat: string | number, i: number, parameter: string) => {
    const maaleresultater = [...data.inndata.maaleresultater];
    maaleresultater[i][parameter] = resultat;
    onUpdateEditData("testResultater.maalingAvTrykkfallOverHepafilter.inndata.maaleresultater", maaleresultater);
  };

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Måling av trykkfall over HEPA-filter</CardTitle>
        <div className="d-flex flex-column">
          <b>Hvor mange målinger skal utføres?:</b>
          <div className="d-flex">
            <Button color="success" onClick={addNewRow}>
              Legg til rad
            </Button>
            <HSpace />
            <Button color="danger" onClick={removeLastRow}>
              Fjern siste rad
            </Button>
          </div>
        </div>
        {data.inndata.maaleresultater.length > 0 && (
          <div className="mt-4 d-flex flex-column w-50">
            <b>Fyll inn måleresultater:</b>
            <table>
              <thead>
                <tr>
                  <th>Filternummer &#40;-&#41;</th>
                  <th>Trykkfall &#40;Pa&#41;</th>
                </tr>
              </thead>
              {data.inndata.maaleresultater.map((filter, i) => (
                <tbody key={i}>
                  <tr>
                    <td>
                      <McInput
                        value={filter.filterNr}
                        type="text"
                        onChange={(e) => updateMaaleresultater(e.target.value, i, "filterNr")}
                        placeholder="Skriv inn filternummer"
                      />
                    </td>
                    <td>
                      <McInput
                        value={filter.maaleresultat}
                        normalizeNumbers={true}
                        type="text"
                        onBlur={(e) => updateMaaleresultater(Number(e.target.value), i, "maaleresultat")}
                        placeholder="Trykkfall"
                      />
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
        )}
        <div className="mt-4">
          <ReportDocxImageContainer
            docxImageTag="maalingAvTrykkfallOverHepafilter"
            title="Nummerering av HEPA-filter"
          />
          <div className="w-50">
            <b>Kommentar:</b>
            <McInput
              value={data.inndata.kommentar}
              onChange={(e) =>
                onUpdateEditData("testResultater.maalingAvTrykkfallOverHepafilter.inndata.kommentar", e.target.value)
              }
              type="textarea"
              dynamicHeight={true}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default MaalingAvTrykkfallOverHepaFilter;
