import { IBatchjob } from "../../../../../types/batchjob.types";
import { IValueLabel } from "../../../../../types/global.types";

export enum BatchjobStatusEnum {
  ACTIVE = "ACTIVE",
  IN_DEVELOPMENT = "IN_DEVELOPMENT",
  REMOVED = "REMOVED",
  INACTIVE = "INACTIVE",
}

export enum BatchJobTemplateEnum {
  BIM360REVITTOIFC = "BIM360REVITTOIFC",
  BIM360SHEETEXTRACTOR = "BIM360SHEETEXTRACTOR",
}

export const BATCHJOBTEMPLATES: IBatchjob.TBatchjobTemplate[] = [
  BatchJobTemplateEnum.BIM360REVITTOIFC,
  BatchJobTemplateEnum.BIM360SHEETEXTRACTOR,
];

export const BATCHJOB_STATUSES: IValueLabel[] = [
  {
    label: "Aktiv",
    value: BatchjobStatusEnum.ACTIVE,
  },
  {
    label: "Under utvikling",
    value: BatchjobStatusEnum.IN_DEVELOPMENT,
  },
  {
    label: "Inaktiv",
    value: BatchjobStatusEnum.INACTIVE,
  },
  {
    label: "Fjernet",
    value: BatchjobStatusEnum.REMOVED,
  },
];
