import { useState } from "react";
import { MoonLoader } from "react-spinners";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { IMultipleFilterOptions } from "../../types";
import MainFilterBar from "../MainFilterBar/MainFilterBar";
import SearchInput from "../SearchInput/SearchInput";
import { VSpace } from "../index";

function FreeTextSearch({
  search,
  onModalClose,
  manualIsLoading,
  children,
  placeholder,
  displayNoResultsText,
  multipleFilterItems,
  updateMultiFilterItemOption,
  clearAllFilters,
  resetMultiFilter,
  selectAllMultiFilter,
}: {
  search: (query: string) => Promise<void>;
  children?: React.ReactNode;
  manualIsLoading?: boolean;
  placeholder?: string;
  onModalClose: () => void;
  displayNoResultsText: boolean;
  multipleFilterItems?: IMultipleFilterOptions[];
  updateMultiFilterItemOption?: (title: string, value: string) => void;
  clearAllFilters?: () => void;
  resetMultiFilter?: (dataField: string) => void;
  selectAllMultiFilter?: (dataField: string) => void;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);

  const executeSearch = async () => {
    setLoading(true);
    await search(searchQuery);
    setLoading(false);
  };

  return (
    <>
      <SearchInput
        style={{ fontSize: "1rem" }}
        placeholder={placeholder != null ? placeholder : "Søk"}
        onFocus={(e) => {
          e.target.blur();
          setIsOpen(true);
        }}
      />
      <Modal
        style={{ maxWidth: "40vw" }}
        isOpen={isOpen}
        toggle={() => setIsOpen(!isOpen)}
        onClosed={onModalClose}
        scrollable={true}
      >
        <ModalHeader toggle={() => setIsOpen(!isOpen)}>Søk</ModalHeader>
        <ModalBody className="d-flex flex-column" style={{ height: "500px" }}>
          <div className="w-100 mb-3" style={{ position: "relative", display: "inline-block" }}>
            {multipleFilterItems != null && (
              <div className="border pt-2 px-2 d-flex w-100 mc-gray-200">
                <MainFilterBar
                  selectAllMultiFilter={selectAllMultiFilter}
                  resetMultiFilter={resetMultiFilter}
                  clearAllFilters={clearAllFilters}
                  updateMultiFilterItemOption={updateMultiFilterItemOption}
                  multipleFilterItems={multipleFilterItems}
                />
              </div>
            )}
            <VSpace />
            <SearchInput
              autoFocus
              disabled={loading || manualIsLoading}
              style={{ fontSize: "1rem", paddingRight: "2.1rem" }}
              placeholder={placeholder != null ? placeholder : "Søk"}
              onKeyDown={async (e) => {
                if (e.key === "Enter") {
                  await executeSearch();
                }
              }}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <VSpace />
            <div
              style={{
                position: "absolute",
                right: 10,
                top: "50%",
                transform: "translateY(-50%)",
                pointerEvents: "none",
              }}
            >
              {loading === true ||
                (manualIsLoading === true && <MoonLoader size={15} loading={loading || manualIsLoading} />)}
            </div>
          </div>
          <div className="d-flex flex-column overflow-auto flex-grow-1">
            {displayNoResultsText === true ? (
              <div className="d-flex justify-content-center text-secondary!">
                <i>Ingen søkeresultater funnet</i>
              </div>
            ) : (
              children
            )}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default FreeTextSearch;
