import React from "react";
import { UncontrolledTooltip } from "reactstrap";
import { IBoard } from "../../../../types/global.types";
import { groupBoardsByStatus } from "../../app/routes/issuesApp/issueUtils";
import { IBoardByGroupKey } from "../../types";
import { MoveIssueIcon } from "../CustomIcons/CustomIcons";
import McDropdownGrouped from "../McDropdownGrouped/McDropdownGrouped";

const ChangeItemRegisterSelector: React.FC<{
  boards: IBoard[];
  activeBoard: IBoard;
  onChangeBoard: (board: IBoard) => void;
}> = ({ boards, activeBoard, onChangeBoard }) => {
  const [groupsWithBoards, setGroupsWithBoards] = React.useState<IBoardByGroupKey>(null);

  React.useEffect(() => {
    const _groupsWithBoards = groupBoardsByStatus(boards);
    setGroupsWithBoards(_groupsWithBoards);
  }, []);

  return (
    <div className="d-flex m-0 p-0">
      <div id="columndropdownd">
        <McDropdownGrouped
          className="btn-sm p-0 m-0"
          style={{ width: "150px" }}
          groups={groupsWithBoards}
          selectionChanged={onChangeBoard}
          displayKey={"name"}
          icon={
            <>
              <MoveIssueIcon style={{ fontSize: "1rem" }} />
            </>
          }
          selectedItem={activeBoard}
        />
        <UncontrolledTooltip placement="top" target="columndropdownd">
          Flytt sak til register
        </UncontrolledTooltip>
      </div>
    </div>
  );
};

export default ChangeItemRegisterSelector;
