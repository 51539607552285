import { ColDef } from "ag-grid-community";
import { IPredefinedTags } from "mc-shared/zod/issueBoardSchema";
import React from "react";
import Switch from "react-switch";
import { Card, CardBody, CardTitle } from "reactstrap";
import { IBoard } from "../../../../../../types/global.types";
import { FailedAlert } from "../../../../components";
import ViewEditTable, { VETEmptyValidator } from "../../../../components/ViewEditTable/ViewEditTable";
import {
  useUpdateIssueBoardPredefinedTagsMutation,
  useUpdateIssueBoardUsePredefinedTagsMutation,
} from "../IssueBoardRTK";

const IssuePredefinedTags: React.FC<{ issueBoard: IBoard }> = ({ issueBoard }) => {
  const [updateBoardCustomTags, { error: updateBoardCustomTagsError }] = useUpdateIssueBoardPredefinedTagsMutation();
  const [updateBoardUseCustomTags, { error: updateBoardErrorUseCustomTags }] =
    useUpdateIssueBoardUsePredefinedTagsMutation();

  const columnSettings: ColDef<IPredefinedTags>[] = [
    {
      field: "title",
      headerName: "Tittel",
      editable: true,
    },
  ];

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h3">Forhåndsdefinerte etiketter</CardTitle>
        {updateBoardErrorUseCustomTags != null || (updateBoardCustomTagsError != null && <FailedAlert />)}
        <div className="d-flex">
          <p className="mr-2">
            <i className="fa fa-info-circle fa-fw text-info" />
            Bruk forhåndsdefinerte etiketter for register
          </p>
          <Switch
            onColor="#00a76d"
            onChange={(useCustomTags) =>
              updateBoardUseCustomTags({
                projectId: issueBoard.project,
                issueBoardId: issueBoard._id,
                useCustomTags,
              })
            }
            checked={issueBoard.usePredefinedTags === true}
          />
        </div>
        <ViewEditTable
          canEdit={issueBoard.usePredefinedTags === true}
          columns={columnSettings}
          validators={[VETEmptyValidator]}
          data={issueBoard.predefinedTags ?? []}
          onSave={(tags) => {
            updateBoardCustomTags({
              projectId: issueBoard.project,
              issueBoardId: issueBoard._id,
              tags,
            });
          }}
          newItemTemplateObject={{
            title: "",
          }}
        />
      </CardBody>
    </Card>
  );
};

export default IssuePredefinedTags;
