import React from "react";
import { useHistory } from "react-router-dom";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { apiSlice } from "../../../apiSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useGetIssueBoardsQuery } from "./IssueBoardRTK";
import { resetIssueRedux } from "./issueSlice";
import { getIssueBoardIdFromUrl } from "./issueUtils";

const BoardSelector: React.FC<{}> = () => {
  const project = useAppSelector((state) => state.adminReducer.project);

  const dispatch = useAppDispatch();

  const { data: issueBoards = [], isLoading } = useGetIssueBoardsQuery({
    projectId: project._id,
  });

  const history = useHistory();

  const activeIssueBoardId = getIssueBoardIdFromUrl(history.location.pathname);

  const activeBoard = issueBoards.find((board) => board._id === activeIssueBoardId);

  const goToBoard = (boardId: string) => {
    dispatch(apiSlice.util.invalidateTags(["IssueBoard"]));
    history.replace(`/project/${project._id}/issues/${boardId}`);
  };

  const goToBoardSelection = () => {
    dispatch(apiSlice.util.invalidateTags(["IssueBoard"]));
    dispatch(resetIssueRedux());
    history.replace(`/project/${project._id}/issues`);
  };

  return (
    <UncontrolledDropdown>
      {activeBoard == null && isLoading === false && <span>Register ikke funnet</span>}
      {activeBoard != null && (
        <>
          <DropdownToggle color="secondary" style={{ minWidth: "200px" }} caret>
            {activeBoard.name}
          </DropdownToggle>
          <DropdownMenu>
            {issueBoards
              .filter((board) => {
                return board.status === "ACTIVE";
              })
              .map((board, index) => (
                <DropdownItem key={index} onClick={() => goToBoard(board._id)}>
                  {board.name}
                </DropdownItem>
              ))}
            <DropdownItem divider />
            <DropdownItem onClick={() => goToBoardSelection()}>Gå til Utvalg</DropdownItem>
          </DropdownMenu>
        </>
      )}
    </UncontrolledDropdown>
  );
};

export default BoardSelector;
