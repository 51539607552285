import { ColDef, ModuleRegistry, ValueFormatterParams } from "ag-grid-community";
import { ExcelExportModule } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";
import { IBim360FolderContent } from "../../../../../types/global.types";
import { MCLoader } from "../../../components/";
import { getDistinctCustomAttributes } from "./util";

export const Bim360FileTable: React.FC<{
  files: IBim360FolderContent[];
  loading: boolean;
  folder: IBim360FolderContent;
  onSelect?: any;
  selected?: string[];
}> = ({ files, loading, folder, onSelect, selected }) => {
  const dateFormatter = (params: ValueFormatterParams<IBim360FolderContent>) => {
    let date;

    if (typeof params.value === "string") {
      date = new Date(params.value);
    } else if (params.value instanceof Date) {
      date = params.value;
    } else {
      return params.value;
    }

    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;

    return `${date.getFullYear()}.${month}.${day}`;
  };

  const cols: ColDef<IBim360FolderContent>[] = [
    {
      field: "attributes.displayName",
      headerName: "Navn",
    },
    {
      field: "fileType",
      headerName: "Filtype",
    },
    {
      field: "folderName",
      headerName: "Mappe",
    },
    {
      field: "attributes.createTime",
      headerName: "Opprettet",
      valueFormatter: dateFormatter,
    },
    {
      field: "attributes.createUserName",
      headerName: "Opprettet av",
    },
    {
      field: "attributes.lastModifiedTime",
      headerName: "Sist endret",
      valueFormatter: dateFormatter,
    },
    {
      field: "attributes.lastModifiedUserName",
      headerName: "Sist endret av",
    },
  ];

  const [columns, setColumns] = useState<ColDef<IBim360FolderContent>[]>(null);

  useEffect(() => {
    if (files.length > 0 && loading === false) {
      const distinctCustomAttributes = getDistinctCustomAttributes(files);
      const customAttrCols: ColDef<IBim360FolderContent>[] = distinctCustomAttributes.map((attr) => {
        const _attr = attr.replace(/ /g, "_");
        return {
          field: `customAttributes.${_attr}`,
          text: _attr,
        };
      });

      setColumns(cols.concat(customAttrCols));
    }
  }, [loading]);

  const getData = () => {
    return files?.filter((file) => file?.fileType !== ".REVIEW");
  };

  const name =
    folder?.attributes?.displayName?.indexOf("root-folder") === -1 ? folder?.attributes?.displayName : "Root";

  ModuleRegistry.register(ExcelExportModule);

  return (
    <div className="d-flex flex-column h-100 jr-card overflow-auto">
      <div className="jr-card-header d-flex align-items-center">
        <h3 className="mb-0">
          <span>Filer i {name}</span>
        </h3>
      </div>
      {loading && <MCLoader loading text="Laster filer..." />}
      <h4>Antall filer: {files.length}</h4>
      <div className="d-flex flex-column h-100 p-1 overflow-auto">
        {files.length > 0 && (
          <div className="ag-theme-alpine h-100 w-100" style={{ overflow: "auto" }}>
            <AgGridReact
              modules={[ExcelExportModule]}
              columnDefs={columns}
              rowData={getData()}
              pagination={true}
              paginationPageSize={50}
              suppressRowClickSelection={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};
