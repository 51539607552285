import React from "react";
import { FormGroup, Label } from "reactstrap";
import { UserWithAvatar } from "..";
import { IMinUserWithCanBeRemovedProp } from "../../../../types/global.types";

const UserList: React.FC<{
  addedUsers: IMinUserWithCanBeRemovedProp[];
  condensed?: boolean;
}> = ({ addedUsers, condensed = false }) => {
  return (
    <>
      {addedUsers.map((user, i) => {
        return (
          <FormGroup key={i} className="mb-0">
            <Label
              check
              className="d-flex titleWithDelete"
              style={{ height: `${condensed === true ? "30px" : "auto"}` }}
            >
              <UserWithAvatar user={user} height={condensed === true ? "25px" : "30px"} />
            </Label>
          </FormGroup>
        );
      })}
    </>
  );
};

export default UserList;
