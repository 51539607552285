import { IBIM360FolderPermissionItem, IBim360FolderWithPermissionItems } from "../../../../types/global.types";

import * as R from "ramda";
import { IMultiPageExcelExport, IPermissionRows, ISubjectTypesWithOptions } from "./types";

export const getAllPermissionRows = (
  folders: IBim360FolderWithPermissionItems[],
  subjectType: string,
  options: string[],
): IMultiPageExcelExport[] => {
  return options.map((option) => {
    return {
      pageName: option,
      arr: getPermissionRows(folders, subjectType, option),
    };
  });
};

export const getPermissionRows = (
  folders: IBim360FolderWithPermissionItems[],
  subjectType: string,
  option: string,
): IPermissionRows[] => {
  try {
    return folders.map((folder, index) => {
      const permission = folder.permissions.find(
        (permission) => permission.subjectType === subjectType && permission.name === option,
      );
      const permissionExist = permission != null;
      const permissions = permission != null ? [...permission.actions, ...permission.inheritActions] : null;

      return {
        index,
        folderName: folder.folderName,
        path: folder.path,
        permissions: {
          view: hasPermission("VIEW", permissions, permissionExist),
          publish: hasPermission("PUBLISH", permissions, permissionExist),
          download: hasPermission("DOWNLOAD", permissions, permissionExist),
          collaborate: hasPermission("COLLABORATE", permissions, permissionExist),
          control: hasPermission("CONTROL", permissions, permissionExist),
          edit: hasPermission("EDIT", permissions, permissionExist),
        },
      };
    });
  } catch (error) {
    console.error(error);
    return null;
  }
};

const hasPermission = (permission: string, permissions: string[], permissionExist: boolean): boolean => {
  if (permissionExist === false) {
    return false;
  }
  return permissions?.indexOf(permission) !== -1;
};

const getUniqSubjectTypeOptions = (permissionItems: IBIM360FolderPermissionItem[]): string[] => {
  const options = permissionItems.map((permission) => permission.name);
  return R.uniq(options);
};

export const getSubjectTypesWithOptions = (
  bim360FoldersWithPermissionItems: IBim360FolderWithPermissionItems[],
): ISubjectTypesWithOptions[] => {
  const permissions = R.flatten(R.pluck("permissions", bim360FoldersWithPermissionItems));
  const groupedByStatus = R.groupBy((permissionItem) => permissionItem.subjectType, permissions);
  return Object.keys(groupedByStatus).map((subjectType) => {
    return {
      subjectType: subjectType,
      options: getUniqSubjectTypeOptions(groupedByStatus[subjectType]),
    };
  });
};
