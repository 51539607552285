import React from "react";
import { IMeeting } from "../../../../../../../types/meeting.types";
import { FailedAlert, TodoList } from "../../../../../components";
import { useAppSelector } from "../../../../../hooks";
import {
  useAddAgendaPreperationItemTodoMutation,
  useDeleteAgendaPreperationItemToDoMutation,
  useToggleAgendaPreperationItemTodoMutation,
  useUpdateAgendaPreperationItemToDoMutation,
} from "../../meetingAgendaRTK";

const AgendaPrepTodoItems: React.FC<{
  agendaItem: IMeeting.AgendaItem;
}> = ({ agendaItem }) => {
  const project = useAppSelector((state) => state.adminReducer.project);

  const [addAgendaPrepItemToDo, { isError: addPrepError }] = useAddAgendaPreperationItemTodoMutation();
  const [updateAgendaPropItemToDo, { isError: updatePrepError }] = useUpdateAgendaPreperationItemToDoMutation();
  const [deleteAgendaPrepItemToDo, { isError: deletePrepError }] = useDeleteAgendaPreperationItemToDoMutation();
  const [toggleAgendaPrepItemToDo, { isError: togglePrepError }] = useToggleAgendaPreperationItemTodoMutation();

  const prepItemError = addPrepError || updatePrepError || deletePrepError || togglePrepError;

  return (
    <>
      {prepItemError === true && <FailedAlert />}
      <h5>Forberedelser</h5>
      <TodoList
        todos={agendaItem.todos}
        addNewTodo={(text) => addAgendaPrepItemToDo({ projectId: project._id, agendaId: agendaItem._id, text })}
        updateTodo={(_id, text) =>
          updateAgendaPropItemToDo({ projectId: project._id, agendaId: agendaItem._id, todoId: _id, text })
        }
        deleteTodo={(_id) =>
          deleteAgendaPrepItemToDo({ projectId: project._id, agendaId: agendaItem._id, todoId: _id })
        }
        toggleTodo={(_id, isChecked) =>
          toggleAgendaPrepItemToDo({
            projectId: project._id,
            agendaId: agendaItem._id,
            todoId: _id,
            isChecked: isChecked,
          })
        }
        showComplete={false}
      />
    </>
  );
};

export default AgendaPrepTodoItems;
