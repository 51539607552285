import React, { useEffect } from "react";
import { Button, Input, InputGroup } from "reactstrap";
import { useDebounce } from "usehooks-ts";
import { ISSUE_ACTION_NAME_OPTIONS } from "../../app/routes/issuesApp/issueConstants";
import { ILabelAndIsActive, IMultipleFilterOptions } from "../../types";
import { ActionIcon } from "../CustomIcons/CustomIcons";
import DropdownFilter from "../DropdownFilter/DropdownFilter";
import HSpace from "../HSpace";

const MainFilterBar: React.FC<{
  multipleFilterItems?: IMultipleFilterOptions[];
  updateMultiFilterItemOption?: (title: string, value: string) => void;
  resetMultiFilter: (dataField) => void;
  selectAllMultiFilter?: (dataField) => void;
  booleanFilterItems?: ILabelAndIsActive[];
  updateBooleanFilterItem?: (value: string) => void;
  onUpdateSearch?: (text: string) => void;
  freeTextSearchEnabled?: boolean;
  clearAllFilters?: () => void;
}> = ({
  multipleFilterItems,
  booleanFilterItems,
  updateBooleanFilterItem,
  resetMultiFilter,
  selectAllMultiFilter,
  updateMultiFilterItemOption,
  onUpdateSearch,
  freeTextSearchEnabled = false,
  clearAllFilters,
}) => {
  const [search, setSearch] = React.useState<string>("");
  const debouncedValue = useDebounce<string>(search, 500);
  useEffect(() => {
    if (onUpdateSearch != null) {
      onUpdateSearch(debouncedValue);
    }
  }, [debouncedValue]);

  React.useEffect(() => {
    if (multipleFilterItems == null && booleanFilterItems == null) {
      alert("either multipleFilterItems or booleanFilterItems must be provided");
    }
  }, []);

  const filterHasAnFilterOptionActive = (multipleFilterOption: IMultipleFilterOptions) => {
    return multipleFilterOption.options.some((option) => option.isActive === true);
  };

  const hasActiveFilter =
    multipleFilterItems.some((filterItem) => filterHasAnFilterOptionActive(filterItem)) ||
    booleanFilterItems?.some((booleanFilterItem) => booleanFilterItem.isActive === true);

  return (
    <>
      <div className="d-flex flex-wrap">
        {freeTextSearchEnabled === true && (
          <div className="d-flex margin-auto mb-2" style={{ width: "150px" }}>
            <InputGroup>
              <Input
                placeholder="Søk.."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                style={{ height: "2.2rem" }}
              />
            </InputGroup>
            <HSpace />
          </div>
        )}
        {(booleanFilterItems || []).map((item) => {
          return (
            <div className="mr-2 mb-2" key={item.label}>
              <Button
                color="white"
                className="d-flex align-items-center"
                onClick={() => updateBooleanFilterItem(item.label)}
                style={{ height: "2.2rem" }}
              >
                {ISSUE_ACTION_NAME_OPTIONS.some((option) => item.label === `Vis ${option.plural}`) && (
                  <ActionIcon
                    className="text-secondary"
                    style={{ marginRight: "3px", fontSize: "1.1em", marginBottom: "3px" }}
                  />
                )}
                {item.label}
                {item.isActive === true && <i className="fa fa-check-circle primary-text fa-fw fa-1x" />}
              </Button>
            </div>
          );
        })}
        {(multipleFilterItems || []).map((option) => {
          return (
            <div className="mr-2 mb-2" key={option.title}>
              <DropdownFilter
                enableSearch={option.options?.length > 10}
                filterOptions={option.options}
                title={option.title}
                onClick={updateMultiFilterItemOption}
                unSelectAll={resetMultiFilter != null ? () => resetMultiFilter(option.title) : null}
                selectAll={selectAllMultiFilter != null ? () => selectAllMultiFilter(option.title) : null}
              />
              <HSpace />
            </div>
          );
        })}
        {hasActiveFilter && (
          <div>
            <Button color="mc-blue" onClick={clearAllFilters}>
              <i className="fa fa-close fa-fw" />
              Rens
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default MainFilterBar;
