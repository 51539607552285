import { IGeoTeknikkRapport } from "mc-shared/zod/report/Geoteknik/geoteknikSchema";
import { clone } from "ramda";
import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { McDropdown, McInput } from "../../../../components";

const GeoteknikGenrellInfo: React.FC<{
  editData: IGeoTeknikkRapport;
  updateData: (key: string, value: any) => void;
}> = ({ editData, updateData }) => {
  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Generelt</CardTitle>
        <table className="reportTable">
          <tbody>
            <tr>
              <td>1.1 </td>
              <td>Oppdragsnavn: </td>
              <td>
                <McInput
                  required
                  value={editData.generellInfo?.oppdragsNavn}
                  onChange={(e) => updateData("generellInfo.oppdragsNavn", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>1.2 </td>
              <td>Oppdragsnummer: </td>
              <td>
                <McInput
                  required
                  value={editData.generellInfo?.oppdragsNummer}
                  onChange={(e) => updateData("generellInfo.oppdragsNummer", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>1.3 </td>
              <td>Oppdragsgiver: </td>
              <td>
                <McInput
                  value={editData.generellInfo?.oppdragsGiver.navn}
                  onChange={(e) => updateData("generellInfo.oppdragsGiver.navn", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>1.4 </td>
              <td>Oppdragsleder: </td>
              <td>
                <McInput
                  value={editData.generellInfo?.oppdragsLeder}
                  onChange={(e) => updateData("generellInfo.oppdragsLeder", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>1.5 </td>
              <td>Kontaktperson: </td>
              <td>
                <McInput
                  value={editData.generellInfo?.oppdragsGiver?.kontaktPerson}
                  onChange={(e) => updateData("generellInfo.oppdragsGiver.kontaktPerson", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>1.6 </td>
              <td>HøydeSystem: </td>
              <td>
                <McInput
                  value={editData.prosjektInformasjon?.hoydeSystem}
                  onChange={(e) => updateData("prosjektInformasjon.hoydeSystem", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>1.9 </td>
              <td>Eiendomsinformasjon </td>
            </tr>
            <tr>
              <td></td>
              <td>Gnr:</td>
              <td>
                <McInput
                  type="number"
                  value={editData.prosjektInformasjon?.eiendomsInformasjon.gnr}
                  onChange={(e) => updateData("prosjektInformasjon.eiendomsInformasjon.gnr", Number(e.target.value))}
                />
              </td>
            </tr>
            <tr>
              <td></td>
              <td>Bnr:</td>
              <td>
                <McInput
                  type="number"
                  value={editData.prosjektInformasjon?.eiendomsInformasjon.bnr}
                  onChange={(e) => updateData("prosjektInformasjon.eiendomsInformasjon.bnr", Number(e.target.value))}
                />
              </td>
            </tr>
            <tr>
              <td></td>
              <td>Snr:</td>
              <td>
                <McInput
                  type="number"
                  value={editData.prosjektInformasjon?.eiendomsInformasjon.snr}
                  onChange={(e) => updateData("prosjektInformasjon.eiendomsInformasjon.snr", Number(e.target.value))}
                />
              </td>
            </tr>
            <tr>
              <td></td>
              <td>By:</td>
              <td>
                <McInput
                  value={editData.prosjektInformasjon?.eiendomsInformasjon.by}
                  onChange={(e) => updateData("prosjektInformasjon.eiendomsInformasjon.by", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>1.10 </td>
              <td>Tilgjengelighet: </td>
              <td>
                <McDropdown
                  currentValue={editData.prosjektInformasjon?.tilgjengelighet}
                  onChange={(_, item) => updateData("prosjektInformasjon.tilgjengelighet", item)}
                  options={["Åpen", "Begrenset", "Lukket"]}
                />
              </td>
            </tr>
            <tr>
              <td>1.11 </td>
              <td>Saksbehandler: </td>
              <td>
                <McInput
                  value={editData.prosjektInformasjon?.saksbehandler}
                  onChange={(e) => updateData("prosjektInformasjon.saksbehandler", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>1.12 </td>
              <td>Ansvarlig enhet: </td>
              <td>
                <McDropdown
                  currentValue={editData.prosjektInformasjon.ansvarligEnhet}
                  onChange={(_, item) => updateData("prosjektInformasjon.ansvarligEnhet", item)}
                  options={clone(GEOTEKNIK_DEPARTMENTS)}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </CardBody>
    </Card>
  );
};

export default GeoteknikGenrellInfo;

export const GEOTEKNIK_DEPARTMENTS = [
  "-",
  "10232011 Geoteknikk Sør",
  "10233011 Geoteknikk Samferdsel og bygg Vest",
  "10233016 Geoteknikk Energi og Industri Vest",
  "10234011 Geoteknikk Samferdsel Midt",
  "10234015 Geoteknikk Bygg og Industri Midt",
  "10234016 Geoteknikk Naturfare Midt",
  "10235011 Geoteknikk Nord",
  "10235014 Grunnundersøkelser Nord",
  "10101010 Geoteknikk Industri og Energi Oslo",
  "10101020 Geoteknikk B&E Oslo",
  "10101080 Geoteknikk Samferdsel Oslo",
  "10101050 Grunnundersøkelser Oslo",
  "10112011 Geofag BVT",
  "10111063 Østfold",
];
