import {
  LUFTHASTIGHETSMAALING_OG_DOKU_AV_LAMINAER_LUFTSTROEM_METODER,
  LufthastighetsmaalingOgDokuAvLaminaerLuftstroemEnum,
} from "mc-shared/zod/report/GK/gkReportConstants";
import {
  IGKLufthastighetsmaalingOgDokuAvLaminaerLuftstroemResultater,
  IGKRapport,
} from "mc-shared/zod/report/GK/gkReportSchema";
import React, { useEffect } from "react";
import { Button, Card, CardBody, CardTitle, FormGroup, Input, Label } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../../../types/global.types";
import { DropdownFilterGeneric, HSpace, McInput } from "../../../../../../../components";
import ReportDocxImageContainer from "../../../../ReportBoard/ReportDocxImageContainer";
import {
  beregnetForholdMellomToVerdier,
  beregnetGjennomsnittligLufthastighetsMaaling,
  beregnetLufthastighetUniformitet,
  beregnetStandardAvvik,
} from "../../gkGlobalUtils";
import { beregnetVariansLufthastighetsMaaling } from "./lufthastighetsmaalingOgDokuAvLaminaerLuftstroemUtils";

const LufthastighetsmaalingOgDokuAvLaminaerLuftstroem: React.FC<{
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
  data: IGKLufthastighetsmaalingOgDokuAvLaminaerLuftstroemResultater;
}> = ({ data, onUpdateEditData }) => {
  useEffect(() => {
    utdataBeregninger();
  }, [
    data.inndata.lufthastighetsMaalingResultater.maaleresultater,
    data.inndata.lufthastighetsMaalingResultater.maaleresultater.length,
  ]);

  useEffect(() => {
    updateMaaleresultaterForholdstall();
  }, [data.utdata.gjennomsnittligHastighet]);

  const addOrRemoveTest = (test: LufthastighetsmaalingOgDokuAvLaminaerLuftstroemEnum) => {
    data.inndata.valgtMetode[test] = !data.inndata.valgtMetode[test];
    onUpdateEditData(
      "testResultater.lufthastighetsmaalingOgDokuAvLaminaerLuftstroem.inndata.valgtMetode",
      data.inndata.valgtMetode,
    );
  };

  const addNewRow = () => {
    const maaleresultaterArray = data.inndata.lufthastighetsMaalingResultater.maaleresultater;
    maaleresultaterArray.push({
      maaleresultat: null,
      forholdMellomMaaleresultatOgGjennomsnittshastighet: null,
    });

    onUpdateEditData(
      "testResultater.lufthastighetsmaalingOgDokuAvLaminaerLuftstroem.inndata.lufthastighetsMaalingResultater.maaleresultater",
      maaleresultaterArray,
    );

    return maaleresultaterArray;
  };

  const removeLastRow = () => {
    const updatedMaaleresultater = data.inndata.lufthastighetsMaalingResultater.maaleresultater;

    if (updatedMaaleresultater.length > 0) {
      updatedMaaleresultater.pop(); // Remove the last element
      onUpdateEditData(
        "testResultater.lufthastighetsmaalingOgDokuAvLaminaerLuftstroem.inndata.lufthastighetsMaalingResultater.maaleresultater",
        updatedMaaleresultater,
      );
    }
  };

  const updateMaaleresultater = (resultat: string | number, i: number, parameter: string) => {
    if (data.inndata.valgtMetode[LufthastighetsmaalingOgDokuAvLaminaerLuftstroemEnum.LUFTHASTIGHETSMAALING] === true) {
      const maaleresultater = [...data.inndata.lufthastighetsMaalingResultater.maaleresultater];
      maaleresultater[i][parameter] = resultat;
      maaleresultater[i].forholdMellomMaaleresultatOgGjennomsnittshastighet = beregnetForholdMellomToVerdier(
        maaleresultater[i].maaleresultat,
        data.utdata.gjennomsnittligHastighet,
      );
      onUpdateEditData(
        "testResultater.lufthastighetsmaalingOgDokuAvLaminaerLuftstroem.inndata.lufthastighetsMaalingResultater.maaleresultater",
        maaleresultater,
      );
    }
  };

  const updateMaaleresultaterForholdstall = () => {
    if (data.inndata.valgtMetode[LufthastighetsmaalingOgDokuAvLaminaerLuftstroemEnum.LUFTHASTIGHETSMAALING] === true) {
      const maaleresultater = [...data.inndata.lufthastighetsMaalingResultater.maaleresultater];
      maaleresultater.forEach((resultat) => {
        resultat.forholdMellomMaaleresultatOgGjennomsnittshastighet = beregnetForholdMellomToVerdier(
          resultat.maaleresultat,
          data.utdata.gjennomsnittligHastighet,
        );
      });
      onUpdateEditData(
        "testResultater.lufthastighetsmaalingOgDokuAvLaminaerLuftstroem.inndata.lufthastighetsMaalingResultater.maaleresultater",
        maaleresultater,
      );
    }
  };

  const utdataBeregninger = () => {
    if (data.inndata.valgtMetode[LufthastighetsmaalingOgDokuAvLaminaerLuftstroemEnum.LUFTHASTIGHETSMAALING] === true) {
      const gjennomsnittligHastighet = beregnetGjennomsnittligLufthastighetsMaaling(
        data.inndata.lufthastighetsMaalingResultater.maaleresultater.map((resultat) => resultat.maaleresultat),
      );
      const varians = beregnetVariansLufthastighetsMaaling(
        data.inndata.lufthastighetsMaalingResultater.maaleresultater.map((resultat) => resultat.maaleresultat),
        gjennomsnittligHastighet,
      );
      const standardAvvik = beregnetStandardAvvik(varians);
      const lufthastighetUniformitet = beregnetLufthastighetUniformitet(gjennomsnittligHastighet, standardAvvik);
      const utdata = {
        gjennomsnittligHastighet: gjennomsnittligHastighet,
        varians: varians,
        standardAvvik: standardAvvik,
        lufthastighetUniformitet: lufthastighetUniformitet,
      };
      onUpdateEditData("testResultater.lufthastighetsmaalingOgDokuAvLaminaerLuftstroem.utdata", utdata);
    }
  };

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Lufthastighetsmåling og dokumentasjon av laminær luftstrøm</CardTitle>
        <b>Hvilken metode skal brukes?</b>
        <div className="w-25 mb-4">
          <DropdownFilterGeneric
            filterOptions={LUFTHASTIGHETSMAALING_OG_DOKU_AV_LAMINAER_LUFTSTROEM_METODER.map((test) => {
              return {
                ...test,
                isActive: data.inndata.valgtMetode[test.value],
              };
            })}
            onClick={(value) => addOrRemoveTest(value)}
            title="Velg tester"
            color="secondary"
            textColor="text-black"
          />
        </div>
        {data.inndata.valgtMetode[LufthastighetsmaalingOgDokuAvLaminaerLuftstroemEnum.LUFTHASTIGHETSMAALING] ===
          true && (
          <>
            <h4>Lufthastighetsmåling</h4>
            <div className="d-flex flex-column mt-2">
              <b>Måletiden er 60 sekunder &#40;s&#41;:</b>
            </div>
            <div className="mt-2">
              <b>Legg til flere punkter ved behov:</b>
              <div className="d-flex">
                <Button color="success" onClick={addNewRow}>
                  Legg til rad
                </Button>
                <HSpace />
                <Button
                  color="danger"
                  onClick={removeLastRow}
                  disabled={data.inndata.lufthastighetsMaalingResultater.maaleresultater.length < 9}
                >
                  Fjern siste rad
                </Button>
              </div>
            </div>
            <div className="mt-4 d-flex flex-column">
              <b>Fyll inn måleresultater &#40;m/s&#41;:</b>
              {data.inndata.lufthastighetsMaalingResultater.maaleresultater.map((resultat, i) => (
                <McInput
                  key={i}
                  value={resultat.maaleresultat}
                  normalizeNumbers={true}
                  type="text"
                  onBlur={(e) => updateMaaleresultater(Number(e.target.value), i, "maaleresultat")}
                  className="w-25 mb-1"
                />
              ))}
            </div>
            <div className="d-flex justify-content-between">
              <p className="mb-0">
                Gjennomsnittlig hastighet &#40;m/s&#41; =
                <b>
                  {" "}
                  {beregnetGjennomsnittligLufthastighetsMaaling(
                    data.inndata.lufthastighetsMaalingResultater.maaleresultater.map(
                      (resultat) => resultat.maaleresultat,
                    ),
                  )?.toFixed(2) ?? ""}
                </b>
              </p>
              <p className="mb-0">Formel: &#40;Va = &#40;SUM Vn&#41; / N&#41;</p>
            </div>
            <hr className="mt-0" />
            <div className="d-flex justify-content-between">
              <p className="mb-0">
                Varians &#40;-&#41; =
                <b>
                  {" "}
                  {beregnetVariansLufthastighetsMaaling(
                    data.inndata.lufthastighetsMaalingResultater.maaleresultater.map(
                      (resultat) => resultat.maaleresultat,
                    ),
                    data.utdata.gjennomsnittligHastighet,
                  )?.toFixed(4) ?? ""}
                </b>
              </p>
              <p className="mb-0">
                Formel: &#40;VRS = &#40;&#40;SUM Vn<sup>2</sup>&#41; - N * &#40;Va<sup>2</sup>&#41;&#41; / &#40;N -
                1&#41;&#41;
              </p>
            </div>
            <hr className="mt-0" />
            <div className="d-flex justify-content-between">
              <p className="mb-0">
                Standardavvik &#40;-&#41; =<b> {beregnetStandardAvvik(data.utdata.varians)?.toFixed(4) ?? ""}</b>
              </p>
              <p className="mb-0">
                Formel: &#40;Sigma = VRS<sup>0,5</sup>&#41;
              </p>
            </div>
            <hr className="mt-0" />
            <div className="d-flex justify-content-between">
              <p className="mb-0">
                Lufthastighetens uniformitet &#40;-&#41; ={" "}
                <b>
                  {beregnetLufthastighetUniformitet(
                    data.utdata.gjennomsnittligHastighet,
                    data.utdata.standardAvvik,
                  )?.toFixed(2) ?? ""}
                </b>
              </p>
              <p className="mb-0">Formel: &#40;Uv = &#40;1 - &#40;Sigma / Va&#41;&#41; * 100&#41;</p>
            </div>
            <hr className="mt-0 mb-4" />
          </>
        )}
        {data.inndata.valgtMetode[LufthastighetsmaalingOgDokuAvLaminaerLuftstroemEnum.DOKUMENTASJON] === true && (
          <>
            <h4>Dokumentasjon</h4>
            <div className="w-50 mt-2">
              <div>
                <b>Beskrivelse av luftstrøm:</b>
                <McInput
                  value={data.inndata.dokumentasjonAvLuftstroemResultater.beskrivelseAvLuftstroem}
                  onChange={(e) =>
                    onUpdateEditData(
                      "testResultater.lufthastighetsmaalingOgDokuAvLaminaerLuftstroem.inndata.dokumentasjonAvLuftstroemResultater.beskrivelseAvLuftstroem",
                      e.target.value,
                    )
                  }
                  type="textarea"
                  dynamicHeight={true}
                />
              </div>
              <div>
                <FormGroup className="d-flex flex-column" check>
                  <Label check>
                    <Input
                      type="radio"
                      checked={data.inndata.dokumentasjonAvLuftstroemResultater.godkjent === true}
                      onChange={() =>
                        onUpdateEditData(
                          "testResultater.lufthastighetsmaalingOgDokuAvLaminaerLuftstroem.inndata.dokumentasjonAvLuftstroemResultater.godkjent",
                          true,
                        )
                      }
                    />
                    Godkjent
                  </Label>
                  <Label check>
                    <Input
                      type="radio"
                      checked={data.inndata.dokumentasjonAvLuftstroemResultater.godkjent === false}
                      onChange={() =>
                        onUpdateEditData(
                          "testResultater.lufthastighetsmaalingOgDokuAvLaminaerLuftstroem.inndata.dokumentasjonAvLuftstroemResultater.godkjent",
                          false,
                        )
                      }
                    />
                    Ikke godkjent
                  </Label>
                </FormGroup>
              </div>
            </div>
          </>
        )}
        <div className="w-50 mt-4">
          <div>
            <ReportDocxImageContainer
              docxImageTag="lufthastighetsmaalingOgDokuAvLaminaerLuftstroem"
              title="Bilde av luftstrøm"
            />
          </div>
          <b>Kommentar:</b>
          <McInput
            value={data.inndata.kommentar}
            onChange={(e) =>
              onUpdateEditData(
                "testResultater.lufthastighetsmaalingOgDokuAvLaminaerLuftstroem.inndata.kommentar",
                e.target.value,
              )
            }
            type="textarea"
            dynamicHeight={true}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default LufthastighetsmaalingOgDokuAvLaminaerLuftstroem;
