import moment from "moment";
import "moment/dist/locale/nb";
import React from "react";
import { Button, ButtonGroup, CardBody, CardText, CardTitle, ListGroupItem, Progress } from "reactstrap";
import { IValueLabel } from "../../../../types/global.types";
import { IRegister } from "../../../../types/register.types";
import { AccessView } from "../AccessComp/AccessComp";
import { MCLoader } from "../index";
import "./RegisterBoards.css";

const RegisterBoards: React.FC<{
  registerBoards: IRegister.BoardPopulated[];
  onClickRegisterBoard: (board: IRegister.BoardPopulated) => void;
  statuses: IValueLabel[];
  activeStatus: string;
  setActiveStatus: (str: string) => void;
  addNew: () => void;
  loading?: boolean;
}> = ({ registerBoards, onClickRegisterBoard, statuses, activeStatus, setActiveStatus, addNew, loading }) => {
  return (
    <>
      {registerBoards != null && (
        <div className="d-flex flex-column">
          <div className="d-flex mb-2">
            <ButtonGroup className="w-100 mr-2">
              {statuses.map((status, index) => (
                <Button
                  className="d-flex justify-content-center"
                  key={index}
                  style={{ maxWidth: "200px" }}
                  active={activeStatus === status.value}
                  onClick={() => setActiveStatus(status.value)}
                >
                  <div className="pr-1">{status.label}</div>
                  <div
                    style={{
                      borderRadius: "50%",
                      width: "1rem",
                      height: "1rem",
                      fontSize: "0.75rem",
                      backgroundColor: "#999999d9",
                    }}
                  >
                    {registerBoards.filter((board) => board.status === status.value).length}
                  </div>
                </Button>
              ))}
            </ButtonGroup>
            <Button color="success" onClick={() => addNew()}>
              <i className="fa fa-plus" />
            </Button>
          </div>
          <div></div>
          <div className="p-1">
            {registerBoards.map((registerBoard, index) => {
              return (
                registerBoard.status === activeStatus && (
                  <ListGroupItem key={index} action style={{ padding: "0" }}>
                    <CardBody style={{ padding: "0.4rem" }}>
                      <div className="d-flex align-items-center">
                        <CardTitle
                          className="flex-grow-1"
                          tag="h5"
                          style={{
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            marginBottom: "0.2rem",
                            cursor: "pointer",
                            fontWeight: "normal",
                            fontSize: "1rem",
                            maxWidth: "100%",
                            width: "max-content",
                          }}
                          onClick={() => onClickRegisterBoard(registerBoard)}
                        >
                          {registerBoard.name}
                        </CardTitle>
                        {registerBoard.progress != null && (
                          <Progress multi className="w-25 thin-progress">
                            {registerBoard.progress.map((progress, index) => {
                              return (
                                <Progress
                                  bar
                                  style={{ backgroundColor: progress.color }}
                                  value={progress.percentage}
                                  key={index}
                                />
                              );
                            })}
                          </Progress>
                        )}
                      </div>
                      {registerBoard.createdFromTemplate != null && (
                        <CardText style={{ fontSize: "0.8rem", margin: "0px" }}>
                          Mal: {registerBoard.createdFromTemplate}
                        </CardText>
                      )}

                      <CardText style={{ fontSize: "0.8rem", margin: "0px" }}>
                        Opprettet {moment(registerBoard.createdAt).fromNow()} av {registerBoard.createdBy?.name}
                      </CardText>
                      {registerBoard.access != null && <AccessView access={registerBoard.access} />}
                    </CardBody>
                  </ListGroupItem>
                )
              );
            })}
          </div>
        </div>
      )}
      {registerBoards.length === 0 && loading === false && (
        <p>Her var det tomt. Trykk på plussknappen for å opprette ny.</p>
      )}
      {loading === true && <MCLoader loading />}
    </>
  );
};

export default RegisterBoards;
