import React from "react";
import { IFileAgGrid, IFolderAgGrid, IValueLabel } from "../../../../types/global.types";
import FolderAndFileSelector from "./FolderAndFileSelector";
import HandleSelectedFilesTable from "./HandleSelectedFilesTable";

const FileNavigatorWithSelectedFiles: React.FC<{
  folders: IFolderAgGrid[];
  files: IFileAgGrid[];
  goBack: () => void;
  currentFolder?: IFolderAgGrid;
  onSelectActiveFolder: (folder: IFolderAgGrid) => void;
  onGoToNextFolder: (folder: IFolderAgGrid) => void;
  filterExtension?: IValueLabel;
  highlightedFolder?: IFolderAgGrid;
  filesAlreadySelected?: IFolderAgGrid[];
  onAbort?: () => void;
  onSave?: (files: IFileAgGrid[]) => void;
  disableAbort?: boolean;
}> = ({
  folders,
  files,
  goBack,
  currentFolder,
  onSelectActiveFolder,
  onGoToNextFolder,
  filterExtension,
  highlightedFolder,
  filesAlreadySelected,
  onAbort,
  onSave,
  disableAbort,
}) => {
  const [selectedFiles, setSelectedFiles] = React.useState<IFileAgGrid[]>(filesAlreadySelected);

  const abort = () => {
    setSelectedFiles([]);
    onAbort();
  };

  const removeFilesFromSelectedFiles = (givenFiles: IFileAgGrid[]) => {
    setSelectedFiles((_files) =>
      _files.filter((file) => {
        return givenFiles.find((f) => f.id === file.id) == null;
      }),
    );
  };

  const addFilesToSelectedFiles = (givenFiles: IFileAgGrid[]) => {
    setSelectedFiles((_files) => {
      return [..._files, ...givenFiles];
    });
  };

  return (
    <div className="d-flex h-100 w-100 overflow-auto">
      <FolderAndFileSelector
        folders={folders}
        files={files.map((file) => {
          return {
            ...file,
            disabled: selectedFiles?.find((f) => f.id === file.id) != null,
          };
        })}
        currentFolder={currentFolder}
        goBack={goBack}
        getSelectedFilesCb={addFilesToSelectedFiles}
        onSelectActiveFolder={onSelectActiveFolder}
        onGoToNextFolder={onGoToNextFolder}
        filterExtension={filterExtension}
        highlightedFolder={highlightedFolder}
        canGoBack={currentFolder != null}
      />
      <div className="d-flex ml-2">
        <HandleSelectedFilesTable
          files={selectedFiles}
          onSave={() => onSave(selectedFiles)}
          onAbort={abort}
          disableAbort={disableAbort}
          deleteFilesCb={(_files) => removeFilesFromSelectedFiles(_files)}
        />
      </div>
    </div>
  );
};

export default FileNavigatorWithSelectedFiles;
