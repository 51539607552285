import { IMultiOptionsColors } from "../../../../../types/global.types";
import { STANDARD_COLORS } from "../../globalConstants";
import { IRisikoView } from "./types";
import { RisikoViewEnum } from "./reducer";
import moment from "moment/moment";

export const defaultColumns = [];

export const RISIKO_STATES: IMultiOptionsColors[] = [
  {
    key: "Åpen",
    displayName: "Åpen",
    color: STANDARD_COLORS.blue,
  },
  {
    key: "Lukket",
    displayName: "Lukket",
    color: STANDARD_COLORS.orange,
  },
  {
    key: "Avsluttet",
    displayName: "Avsluttet",
    color: STANDARD_COLORS.red,
  },
  {
    key: "Utkast",
    displayName: "Utkast",
    color: STANDARD_COLORS.teal,
  },
  {
    key: "Utgår",
    displayName: "Utgår",
    color: STANDARD_COLORS.grey,
  },
];

export const RISIKO_LAV_MODERAT_HOY: string[] = ["Lav", "Moderat", "Høy"];

export const RISIKO_VIEWS: IRisikoView[] = [
  {
    key: RisikoViewEnum.TABLE,
    label: "Tabell",
    icon: "table",
  },
  {
    key: RisikoViewEnum.GIS,
    label: "GIS",
    icon: "globe",
  },
];

export const RISIKO_DOCX_DEMO_DATA = {
  project: {
    maconomy: {
      projectNo: "123",
      name: "Mitt prosjekt",
    },
  },
  generatedDate: moment().format("YYYY.MM.DD"),
  risikos: [
    [
      {
        state: "Åpen",
        object: "ett objekt",
        identified: "identifisert",
        comment: "en kommentar",
        bookmark: "ett  bokmerke",
        referenceUrl: "www.google.se",
        description: "en beskrivelse",
        cause: "en årsak",
        consequenceDescription: "en konsekvens",
        probability: "0–25%",
        pns: "1.3",
        owner: {
          name: "Per Ström",
        },
        uncertainty: {
          value: "Lav",
        },
        vulnerability: {
          value: "Moderat",
        },
        pnsCodes: ["1.3"],
        deleted: false,
        tags: [],
        risikoActions: [
          {
            isCompleted: false,
            archived: false,
            comments: [
              {
                itemId: "6374ed57aae591ffb1294dd3",
                createdBy: {
                  _id: "5c88d20edbb10a37c75aa7ae",
                  name: "Per Ström",
                },
                text: "test",
                updatedAt: "2022-11-16T14:11:53.905Z",
                createdAt: "2022-11-16T14:11:53.905Z",
                __v: 0,
              },
            ],
            title: "test",
            assignedTo: {
              name: "Per Ström",
            },
            description: "test",
            dueDate: "2022.04.01",
            notifyWithEmailOnComplete: false,
            id: 2756,
            createdAt: "2022.04.01",
            updatedAt: "2022.04.01",
          },
        ],
        id: 2833,
        title: "en tittel",
        createdBy: {
          name: "Per Ström",
        },

        statelogs: [
          {
            deleted: false,
            text: "en risiko status logg",
            createdDate: "2022.04.01",
            createdBy: "5c88d20edbb10a37c75aa7ae",
          },
        ],
        updatedAt: "2022.04.01",
        createdAt: "2022.04.01",
        lastUpdatedBy: {
          name: "Per Ström",
        },
        evaluationsObj: {
          Kost: {
            consequence: "Av budsjettsum: 0-10%",
            consequenceValue: 1,
            score: 1,
            color: "green",
          },
          Fremdrift: {
            consequence: "3 – 9 mnd",
            consequenceValue: 2,
            score: 2,
            color: "green",
          },
          Kvalitet: {
            consequence: "Høy",
            consequenceValue: 4,
            score: 4,
            color: "orange",
          },
          _SUM_: 7,
        },
      },
    ],
  ],
};
