import { IssueViewEnum } from "mc-shared/enums/enums";
import {
  IMultiOptionsColors,
  IValueAndColor,
  IValueLabel,
  IValueLabelGeneric,
} from "../../../../../types/global.types";
import { IIssueActionNameOption, IIssueRegisterNameOption } from "../../../../../types/issue.types";
import { STANDARD_COLORS } from "../../globalConstants";
import { IKanBanbanGroupoingItem } from "./IssuesTab/Kanban/IssueKanban";
import { IIssueView } from "./types";

export const ISSUE_COMPLETED_VALUE = "Fullført";
export const ISSUE_EXPIRED = "Utgår";

export enum IssueBoardStatusEnums {
  ACTIVE = "ACTIVE",
  COMPLETED = "COMPLETED",
  DELETED = "DELETED",
}
export const YES_NO: IValueLabelGeneric<boolean>[] = [
  {
    value: true,
    label: "Ja",
  },
  {
    value: false,
    label: "Nei",
  },
];

export const ISSUE_BOARD_STATUSES: IValueLabel[] = [
  {
    value: IssueBoardStatusEnums.ACTIVE,
    label: "Aktiv",
  },
  {
    value: IssueBoardStatusEnums.COMPLETED,
    label: "Lukket",
  },
  {
    value: IssueBoardStatusEnums.DELETED,
    label: "Slettet",
  },
];

export enum IssueTabsEnum {
  INFO = "Info",
  LOG = "Logg",
  ACTIONS = "Aksjoner",
  FILES = "Filer",
  // GIS = "GIS",
}

export enum IssueKanbanGroupingViewEnum {
  STATUS = "STATUS",
  BUCKET = "BUCKET",
  TIDSFRIST = "TIDSFRIST",
}

export enum IssueSortValueEnum {
  ID = "ID",
  START_DATE = "Startdato",
  END_DATE = "Sluttdato",
}

export const ISSUE_KANBAN_GROUPING_VIEWS: IKanBanbanGroupoingItem[] = [
  {
    label: "Status",
    value: IssueKanbanGroupingViewEnum.STATUS,
  },
  {
    label: "Bucket",
    value: IssueKanbanGroupingViewEnum.BUCKET,
  },
  {
    label: "Tidsfrist (beta)",
    value: IssueKanbanGroupingViewEnum.TIDSFRIST,
  },
];

export const ISSUE_SECTIONS = {
  COMMON: "GENERELL",
  DATES: "DATO",
  TIME: "TID",
  HISTORY: "HISTORIKK",
  RISIKO: "RISIKO",
  METACOLUMN: "METACOLUMN", //used for utility columns
} as const;

export enum BREEAM_DASHBOARD_PHASES {
  PREANALYSE = "Preanalyse",
  ANALYSE = "Sertifisering",
}

export const getIssueColTextAndLabel = (dataField: ISSUE_DATAFILEDS_ENUM): { dataField: string; text: string } => {
  return {
    dataField: dataField,
    text: ISSUE_COLUMN_LABELS[dataField],
  };
};

export const getIssueDataFieldFromColLabel = (colLabel: string): string => {
  if (colLabel === "Ansv. for utførelse") {
    return "assignedTo";
  }
  return Object.keys(ISSUE_COLUMN_LABELS).find((key) => ISSUE_COLUMN_LABELS[key] === colLabel) as string;
};

export enum ISSUE_DATAFILEDS_ENUM {
  ID = "id",
  TITLE = "title",
  PNS = "pns",
  CREATEDBY_NAME = "createdBy.name",
  LASTUPDATEDBY_NAME = "lastUpdatedBy.name",
  OWNER_NAME = "owner.name",
  ASSIGNEDTO_NAME = "assignedTo.name",
  ASSIGNED_TO = "assignedTo",
  UPDATEDAT = "updatedAt",
  CREATEDAT = "createdAt",
  DATES_PLANNEDSTART = "dates.plannedStart",
  DATES_DUE = "dates.due",
  DATES_COMPLETED = "dates.completed",
  TAGS = "tags",
  STATUS = "status",
  PRIORITY = "priority",
  DESCRIPTION = "description",
  CONCLUSION = "conclusion",
  REFERENCEURL = "referenceUrl",
  DISCIPLIN = "disciplin",
  TYPEOFISSUE = "typeOfIssue",
  GIS = "gis",
  BUCKET = "bucket",
  HOURS_ORIGNALESTIMATE = "hours.orignalEstimate",
  HOURS_REMAINING = "hours.remaining",
  HOURS_COMPLETED = "hours.completed",
  ITEMMEMBERS = "itemMembers",
  SPACE = "space",
  TASK_OR_ISSUE = "taskOrIssue",
  MINE_SAKER = "assignedOrMember",
  DUE_DATE = "dueDate", // Tidfrist-filter (Idag, denna månad etc)
  RISK_PROBABILITY = "risiko.probability",
  RISK_CATEGORY_COMMENT = "risiko.categoryComment",
}

export const ISSUE_COLUMNS = [
  ISSUE_DATAFILEDS_ENUM.ID,
  ISSUE_DATAFILEDS_ENUM.TITLE,
  ISSUE_DATAFILEDS_ENUM.PNS,
  ISSUE_DATAFILEDS_ENUM.CREATEDBY_NAME,
  ISSUE_DATAFILEDS_ENUM.LASTUPDATEDBY_NAME,
  ISSUE_DATAFILEDS_ENUM.OWNER_NAME,
  ISSUE_DATAFILEDS_ENUM.ASSIGNEDTO_NAME,
  ISSUE_DATAFILEDS_ENUM.UPDATEDAT,
  ISSUE_DATAFILEDS_ENUM.CREATEDAT,
  ISSUE_DATAFILEDS_ENUM.DATES_PLANNEDSTART,
  ISSUE_DATAFILEDS_ENUM.DATES_DUE,
  ISSUE_DATAFILEDS_ENUM.DATES_COMPLETED,
  ISSUE_DATAFILEDS_ENUM.TAGS,
  ISSUE_DATAFILEDS_ENUM.STATUS,
  ISSUE_DATAFILEDS_ENUM.PRIORITY,
  ISSUE_DATAFILEDS_ENUM.DESCRIPTION,
  ISSUE_DATAFILEDS_ENUM.CONCLUSION,
  ISSUE_DATAFILEDS_ENUM.REFERENCEURL,
  ISSUE_DATAFILEDS_ENUM.DISCIPLIN,
  ISSUE_DATAFILEDS_ENUM.TYPEOFISSUE,
  ISSUE_DATAFILEDS_ENUM.GIS,
  ISSUE_DATAFILEDS_ENUM.BUCKET,
  ISSUE_DATAFILEDS_ENUM.HOURS_ORIGNALESTIMATE,
  ISSUE_DATAFILEDS_ENUM.HOURS_REMAINING,
  ISSUE_DATAFILEDS_ENUM.HOURS_COMPLETED,
  ISSUE_DATAFILEDS_ENUM.ITEMMEMBERS,
  ISSUE_DATAFILEDS_ENUM.SPACE,
  ISSUE_DATAFILEDS_ENUM.DUE_DATE,
  ISSUE_DATAFILEDS_ENUM.RISK_PROBABILITY,
  ISSUE_DATAFILEDS_ENUM.RISK_CATEGORY_COMMENT,
];

export const ISSUE_COLUMN_LABELS = {
  [ISSUE_DATAFILEDS_ENUM.ID]: "ID",
  [ISSUE_DATAFILEDS_ENUM.TITLE]: "Tittel",
  [ISSUE_DATAFILEDS_ENUM.PNS]: "PNS",
  [ISSUE_DATAFILEDS_ENUM.CREATEDBY_NAME]: "Opprettet av",
  [ISSUE_DATAFILEDS_ENUM.LASTUPDATEDBY_NAME]: "Sist endret av",
  [ISSUE_DATAFILEDS_ENUM.OWNER_NAME]: "Eier",
  [ISSUE_DATAFILEDS_ENUM.ASSIGNEDTO_NAME]: "Ansv. for utførelse",
  [ISSUE_DATAFILEDS_ENUM.ASSIGNED_TO]: "Ansv. for utførelse",
  [ISSUE_DATAFILEDS_ENUM.UPDATEDAT]: "Sist endret",
  [ISSUE_DATAFILEDS_ENUM.CREATEDAT]: "Opprettet",
  [ISSUE_DATAFILEDS_ENUM.DATES_PLANNEDSTART]: "Planlagt start",
  [ISSUE_DATAFILEDS_ENUM.DATES_DUE]: "Tidsfrist dato",
  [ISSUE_DATAFILEDS_ENUM.DATES_COMPLETED]: "Fullført dato",
  [ISSUE_DATAFILEDS_ENUM.TAGS]: "Etiketter",
  [ISSUE_DATAFILEDS_ENUM.STATUS]: "Status",
  [ISSUE_DATAFILEDS_ENUM.PRIORITY]: "Prioritet",
  [ISSUE_DATAFILEDS_ENUM.DESCRIPTION]: "Beskrivelse",
  [ISSUE_DATAFILEDS_ENUM.CONCLUSION]: "Konklusjon",
  [ISSUE_DATAFILEDS_ENUM.REFERENCEURL]: "Referanse URL",
  [ISSUE_DATAFILEDS_ENUM.DISCIPLIN]: "Ansv. Disiplin",
  [ISSUE_DATAFILEDS_ENUM.TYPEOFISSUE]: "Type",
  [ISSUE_DATAFILEDS_ENUM.GIS]: "GIS",
  [ISSUE_DATAFILEDS_ENUM.BUCKET]: "Buckets",
  [ISSUE_DATAFILEDS_ENUM.HOURS_ORIGNALESTIMATE]: "Estimert",
  [ISSUE_DATAFILEDS_ENUM.HOURS_REMAINING]: "Gjenstående",
  [ISSUE_DATAFILEDS_ENUM.HOURS_COMPLETED]: "Brukt",
  [ISSUE_DATAFILEDS_ENUM.ITEMMEMBERS]: "Medlemmer",
  [ISSUE_DATAFILEDS_ENUM.SPACE]: "Space",
  [ISSUE_DATAFILEDS_ENUM.TASK_OR_ISSUE]: "Vis aksjoner",
  [ISSUE_DATAFILEDS_ENUM.MINE_SAKER]: "Mine Saker",
  [ISSUE_DATAFILEDS_ENUM.DUE_DATE]: "Tidsfrist", // Idag, Denna uke, Denne månad etc
  [ISSUE_DATAFILEDS_ENUM.RISK_PROBABILITY]: "Sannsynlighet",
  [ISSUE_DATAFILEDS_ENUM.RISK_CATEGORY_COMMENT]: "Risiko Kommentar",
};

// Reversed hashmap
export const ISSUE_COLUMN_DATAFIELDS = Object.entries(ISSUE_COLUMN_LABELS).reduce((acc, [key, value]) => {
  acc[value] = key;
  return acc;
});

export const DEFAULT_VISIBLE_ISSUE_COLUMNS = [
  ISSUE_DATAFILEDS_ENUM.ID,
  ISSUE_DATAFILEDS_ENUM.TITLE,
  ISSUE_DATAFILEDS_ENUM.DATES_DUE,
  ISSUE_DATAFILEDS_ENUM.ASSIGNEDTO_NAME,
  ISSUE_DATAFILEDS_ENUM.STATUS,
  ISSUE_DATAFILEDS_ENUM.TAGS,
];

//TODO: Theese are copied to backend. Fetch this from backend
export const ISSUE_STATES_OPTIONS: IValueAndColor[] = [
  {
    value: "Opprettet",
    color: STANDARD_COLORS.blue,
  },
  {
    value: "I prosess",
    color: STANDARD_COLORS.orange,
  },
  {
    value: "På hold",
    color: STANDARD_COLORS.yellow,
  },
  {
    value: "Fullført",
    color: STANDARD_COLORS.green,
  },
  {
    value: "Utgår",
    color: STANDARD_COLORS.grey,
  },
];

export const ISSUE_PRIORTY_OPTIONS: IMultiOptionsColors[] = [
  {
    key: "HIGH",
    displayName: "Høy",
    color: STANDARD_COLORS.red,
  },
  {
    key: "MEDIUM",
    displayName: "Middels",
    color: STANDARD_COLORS.orange,
  },
  {
    key: "LOW",
    displayName: "Lav",
    color: STANDARD_COLORS.blue,
  },
];

export const ISSUE_DUEDATE_OPTIONS: IValueAndColor[] = [
  {
    value: "Forsen",
    color: STANDARD_COLORS.red,
  },
  {
    value: "I dag",
    color: STANDARD_COLORS.orange,
  },
  {
    value: "I morgen",
    color: STANDARD_COLORS.yellow,
  },
  {
    value: "Denne uken",
    color: STANDARD_COLORS.green,
  },
  {
    value: "Neste uke",
    color: STANDARD_COLORS.green,
  },
  {
    value: "Fremtidig",
    color: STANDARD_COLORS.blue,
  },
  {
    value: "Ingen dato",
    color: STANDARD_COLORS.grey,
  },
];

export const ISSUE_VIEWS: IIssueView[] = [
  {
    key: IssueViewEnum.TABLE,
    label: "Tabell",
    icon: "table",
  },
  {
    key: IssueViewEnum.KANBAN,
    label: "Kanban",
    icon: "square",
  },
  {
    key: IssueViewEnum.GANTT,
    label: "Gantt",
    icon: "calendar",
  },
  {
    key: IssueViewEnum.DASHBOARD,
    label: "Dashboard",
    icon: "area-chart",
  },
  {
    key: IssueViewEnum.GIS,
    label: "GIS",
    icon: "globe",
  },
];

export const ISSUE_REGISTER_NAME_OPTIONS: IIssueRegisterNameOption[] = [
  {
    type: "Sak",
    singular: "Sak",
    plural: "Saker",
    register: "Saksregister",
    new: "Ny sak",
  },
  {
    type: "Risiko",
    singular: "Risiko",
    plural: "Risikoer",
    register: "Risikoregister",
    new: "Ny risiko",
  },
  {
    type: "Avvik",
    singular: "Avvik",
    plural: "Avvik",
    register: "Avviksregister",
    new: "Nytt avvik",
  },
  {
    type: "Endring",
    singular: "Endring",
    plural: "Endringer",
    register: "Endringsregister",
    new: "Ny endring",
  },
  {
    type: "BREEAM",
    singular: "Kriterie",
    plural: "Kriterier",
    register: "BREEAM-register",
    new: "Nytt kriterie",
  },
  {
    type: "Dokument",
    singular: "Dokument",
    plural: "Dokumenter",
    register: "Dokumentregister",
    new: "Nytt dokument",
  },
  {
    type: "Kontrollobjekt",
    singular: "Kontrolobjekter",
    plural: "Kontrolobjekter",
    register: "Kontrolobjektregister",
    new: "Nytt kontrolobjekt",
  },
];

export const ISSUE_ACTION_NAME_OPTIONS: IIssueActionNameOption[] = [
  {
    singular: "Aksjon",
    plural: "Aksjoner",
    new: "Ny aksjon",
  },
  {
    singular: "Oppgave",
    plural: "Oppgaver",
    new: "Ny oppgave",
  },
  {
    singular: "Tiltak",
    plural: "Tiltak",
    new: "Nytt tiltak",
  },
];

export enum IssueDashboardOptionsEnum {
  STANDARD = "Standard",
  BREEAM_PREANALYSE = "BREEAM Infrastructure preanalyse",
  BREEAM = "BREEAM Infrastructure",
  RISIKO = "Risiko",
}

export const ISSUE_DASHBOARD_OPTIONS: IssueDashboardOptionsEnum[] = [
  IssueDashboardOptionsEnum.STANDARD,
  // IssueDashboardOptionsEnum.BREEAM_PREANALYSE,
  IssueDashboardOptionsEnum.BREEAM,
  IssueDashboardOptionsEnum.RISIKO,
];

export const TYPE_OF_ISSUES: string[] = ["Oppgave", "Beslutning", "Informasjon", "Notater"];

export const ISSUE_GIS_CONNECTION: IMultiOptionsColors[] = [
  {
    key: "Yes",
    displayName: "Ja",
  },
  {
    key: "No",
    displayName: "Nei",
  },
];

export const ISSUE_COLUMN_LS_KEY = "issue___columns";

export const ISSUE_KANBAN_GROUPING_LS_KEY = "issue_kanban_grouping";

export const ISSUE_VIEW_LS_KEY = "issue_view";

export const ISSUE_FILTERS_LS_KEY = "ISSUE_FILTERS";

export const ISSUE_DASHBOARD_LS_KEY = "issue_dashboard";

export const ISSUE_DOCX_DEMO_DATA = {
  generatedAt: "2022.12.21",
  project: {
    maconomy: {
      projectNo: "10010",
      name: "Mitt prosjekt",
    },
  },
  board: {
    name: "Mitt register",
    createdBy: {
      name: "Per Ström",
    },
    createdAt: "2022.12.21",
  },
  issues: [
    {
      _id: "63a2b8a0d22ab12f18719b56",
      id: 8014,
      title: "test",
      description: "Beskrivelse av sak..",
      typeOfIssue: "Oppgave",
      tags: ["tag1"],
      archived: false,
      clientAccess: true,
      itemMembers: [],
      taskOrIssue: "ISSUE",
      createdBy: {
        _id: "5c88d20edbb10a37c75aa7ae",
        name: "Per Ström",
      },
      connectedIssue: null,
      status: "Fullført",
      priority: "MEDIUM",
      dates: {
        plannedStart: "2022.12.21",
        due: "2022.12.22",
        completed: "2022.12.21",
      },
      owner: {
        _id: "5c88d20edbb10a37c75aa7ae",
        name: "Per Ström",
      },
      bucket: "Å gjøre",
      updatedAt: "2022.12.21",
      createdAt: "2022.12.21",
      __v: 3,
      lastUpdatedBy: {
        _id: "5c88d20edbb10a37c75aa7ae",
        name: "Per Ström",
      },
      assignedTo: {
        _id: "5c88d20edbb10a37c75aa7ae",
        name: "Per Ström",
      },
      hours: {
        orignalEstimate: 33,
        completed: 44,
        remaining: 11,
      },
      pns: "1.1",
      referenceUrl: "www.google.com",
      conclusion: "en konklusjon",
      comments: [
        {
          deleted: false,
          _id: "63a2c7489cd017600098ba37",
          text: "en kommentar på en sak",
          createdDate: "2022.12.21",
          createdBy: {
            _id: "5c88d20edbb10a37c75aa7ae",
            name: "Per Ström",
          },
        },
      ],
      connectedTasks: [
        {
          _id: "63a2b8a4d22ab12f18719b57",
          tags: ["tag5", "tag6"],
          archived: false,
          id: 8015,
          taskOrIssue: "TASK",
          createdBy: {
            _id: "5c88d20edbb10a37c75aa7ae",
            name: "Per Ström",
          },
          title: "tittel på en aksjon",
          status: "Fullført",
          priority: "MEDIUM",
          dates: {
            plannedStart: "2022.12.21",
            due: "",
            completed: "2022.12.21",
          },
          owner: {
            _id: "5cc7f8172f1bc77274ba4e05",
            name: "Øystein Mejlænder-Larsen",
          },
          bucket: "Å gjøre",
          comments: [
            {
              deleted: false,
              _id: "63a2c7159cd017600098ba34",
              text: "en kommentar på en aksjon",
              createdDate: "2022.12.21",
              createdBy: {
                _id: "5c88d20edbb10a37c75aa7ae",
                name: "Per Ström",
              },
            },
          ],
          updatedAt: "2022.12.21",
          createdAt: "2022.12.21",
          lastUpdatedBy: {
            _id: "5c88d20edbb10a37c75aa7ae",
            name: "Per Ström",
          },
          assignedTo: {
            _id: "5c88d20edbb10a37c75aa7ae",
            name: "Per Ström",
          },
          typeOfIssue: "Informasjon",
          pns: "1.5",
          description: "beskrivelse av aksjon",
          referenceUrl: "www.google.se",
          hours: {
            orignalEstimate: 33,
            completed: 33,
            remaining: 44,
          },
          conclusion: "en konklusjon på en aksjon",
          connectedIssue: {
            id: 8014,
            title: "test",
          },
          itemMembers: "",
          issueIdTaskId: "8014-8015",
        },
      ],
      todos: [
        {
          _id: "63a2c7429cd017600098ba36",
          completedDate: "2022.12.21",
          completedBy: {
            _id: "5c88d20edbb10a37c75aa7ae",
            name: "Per Ström",
          },
          listIndex: 0,
          text: "en todo för en sak",
          updatedBy: {
            _id: "5c88d20edbb10a37c75aa7ae",
            name: "Per Ström",
          },
          createdBy: {
            _id: "5c88d20edbb10a37c75aa7ae",
            name: "Per Ström",
          },
          createdAt: "2022.12.21",
        },
      ],
      otherAttributesData: {
        A: "Antas ivaretatt av byggherrens kontroll og kontraktskrav",
        B: "Dokumentasjonen er spesifikasjoner for anbude",
      },
      daysUntilDue: 6,
      daysSinceClosed: 3,
    },
  ],
  filters: [
    {
      dataField: "typeOfIssue",
      options: [
        {
          value: "Informasjon",
          label: "Informasjon",
          isActive: true,
        },
      ],
    },
    {
      dataField: "assignedTo",
      options: [
        {
          value: "5f4f96404511102e06f64fa8",
          label: "Bjørnar Foldøy Byberg",
          isActive: true,
        },
      ],
    },
  ],
};
