import React, { useEffect, useState } from "react";
import { Alert, Button, Input } from "reactstrap";
import { IRisikoEvaluationCategorySelection } from "../../../../../../../types/global.types";
import { HSpace } from "../../../../../components/";
import { isEmpty } from "../../../../utils";
import classNames from "classnames";

export const EditCategorySelections = ({ save, addSelectionItem, selections, cancel }) => {
  const [editedSelections, setEditedSelections] = useState<IRisikoEvaluationCategorySelection[]>(selections);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (selections.length === 0) {
      addSelectionItem();
    }
  }, []);

  useEffect(() => {
    const _isDisabled =
      editedSelections.some((selection) => isEmpty(selection.name) || isEmpty(selection.value)) ||
      editedSelections.length === 0;
    setIsDisabled(_isDisabled);
  }, [editedSelections]);

  const updateSelection = (key: "name" | "value" | "description", index: number, value: string) => {
    const _selections = [...selections];
    _selections[index][key] = value;
    setEditedSelections(_selections);
  };

  return (
    <>
      <RiskoSettingsChangeAlert />
      <div className="react-bootstrap-table">
        <table className="table table-bordered">
          <caption style={{ captionSide: "top" }}>Alternativ</caption>
          <thead>
            <tr>
              <th>Navn</th>
              <th>Beskrivelse</th>
              <th>Verdi</th>
            </tr>
          </thead>
          <tbody>
            {selections.map((selection, index) => (
              <tr key={index}>
                <td>
                  <Input
                    className={classNames(selection.name === "" && "form-control is-invalid")}
                    key={index}
                    autoComplete="false"
                    type="text"
                    autoFocus
                    placeholder="Navnet på utvalget, for eksempel: 31-70%"
                    value={selection.name}
                    onChange={(e) => updateSelection("name", index, e.target.value)}
                  />
                </td>
                <td>
                  <Input
                    key={index}
                    autoComplete="false"
                    type="text"
                    autoFocus
                    placeholder="Beskrivelse, for eksempel: Det har skjedd lignende hendelser i tilsvarende prosjekt / 1 gang hvert 1.-5. år"
                    value={selection.description}
                    onChange={(e) => updateSelection("description", index, e.target.value)}
                  />
                </td>
                <td>
                  <Input
                    className={classNames(selection.value === "" && "form-control is-invalid")}
                    key={index}
                    autoComplete="false"
                    type="number"
                    min={0}
                    max={15}
                    placeholder="Skriv inn et tall> 0"
                    value={selection.value}
                    onChange={(e) => updateSelection("value", index, e.target.value)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Actions
          cancel={cancel}
          addSelectionItem={addSelectionItem}
          save={save}
          disabled={isDisabled}
          editedSelections={editedSelections}
        />
      </div>
    </>
  );
};

const Actions = ({ addSelectionItem, save, cancel, disabled, editedSelections }) => (
  <div className="d-flex">
    <Button color="secondary" onClick={() => addSelectionItem()}>
      <i className="fa fa-plus-circle fa-fw" />
    </Button>
    <div className="flex-fill" />
    <Button color="secondary" onClick={() => cancel(false)}>
      Avbryt
    </Button>
    <HSpace />
    <Button disabled={disabled} color="success" onClick={() => save(editedSelections)}>
      Oppdater
      <i className="fa fa-floppy-o fa-fw" />
    </Button>
  </div>
);

export const RiskoSettingsChangeAlert: React.FC = () => {
  return (
    <Alert color="danger">
      Advarsel: Risikoer som brukte eksisterende verdier (navn) fra denne listen, oppdateres ikke. For å få de nye
      verdiene for eksisterende risiko, må hver risiko oppdateres individuelt
    </Alert>
  );
};
