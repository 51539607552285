import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  IProject,
  IRisikoEvaluationCategory,
  IRisikoEvaluationCategorySelection,
  IRiskoBoard,
} from "../../../../../../../types/global.types";
import { HSpace, VSpace } from "../../../../../components/";
import { DeleteConfirmDepricated } from "../../../DeleteConfirmDepricated";
import { setRisikoCategoriesInRedux } from "../../actions";
import {
  doCreateRisikoEvaluationCategory,
  doDeleteRisikoEvaluationCategory,
  doGetRisikoEvaluationCategories,
  doUpdateRisikoEvaluationCategory,
} from "../../risikoAPI";
import CategorySelections from "../CategorySelectionsEditor/CategorySelections";
import ScoreColor from "./ScoreColor";
import { IRootState } from "../../../../../store";
import { setShowSuccessMessageThunk } from "../../../../../global/globalSlice";

interface IProps {
  project: IProject;
  setShowSuccessMessageThunk: any;
  updateRisikoEvaluationCategory: any;
  setRisikoCategoriesInRedux: any;
  risikoBoard: IRiskoBoard;
}

interface IState {
  categories: IRisikoEvaluationCategory[];
  newEvaluationCategoryDialogOpen: boolean;
}

class RisikoEvaluationCategoriesDepricated extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      newEvaluationCategoryDialogOpen: false,
    };
  }

  componentDidMount() {
    this.loadCategories();
  }

  loadCategories = async () => {
    this.setState({ categories: null });
    const categoriesCall = await doGetRisikoEvaluationCategories(this.props.project._id, this.props.risikoBoard._id);
    this.setState({ categories: categoriesCall });
  };

  saveEvaluationCategory = async (evaluationCategory) => {
    await doUpdateRisikoEvaluationCategory(this.props.project._id, evaluationCategory);
    this.props.setShowSuccessMessageThunk("Evaluering oppdatert");
  };

  toggleNewEvaluationDialog = () => {
    this.setState({ newEvaluationCategoryDialogOpen: !this.state.newEvaluationCategoryDialogOpen });
  };

  saveNewEvaluationCategory = async (name: string) => {
    await doCreateRisikoEvaluationCategory(this.props.project._id, this.props.risikoBoard._id, {
      project: this.props.project._id,
      name: name,
    });
    this.toggleNewEvaluationDialog();
    await this.loadCategories();
    this.props.setShowSuccessMessageThunk("Evalueringskategori lagt til");
  };

  execDelete = async (category) => {
    await doDeleteRisikoEvaluationCategory(this.props.project._id, category);
    this.loadCategories();
    this.props.setShowSuccessMessageThunk("Evalueringskategori slettet");
  };

  saveCategorySelection = async (
    selections: IRisikoEvaluationCategorySelection[],
    category: IRisikoEvaluationCategory,
  ) => {
    let categoryUpdate = category;
    categoryUpdate.consequenceSelections = selections;
    await doUpdateRisikoEvaluationCategory(this.props.project._id, categoryUpdate);
    const _boards = await doGetRisikoEvaluationCategories(this.props.project._id, this.props.risikoBoard?._id);
    this.props.setRisikoCategoriesInRedux(_boards);
    this.loadCategories();
    this.props.setShowSuccessMessageThunk("Konsekvenser oppdatert");
  };

  render() {
    return (
      <Card>
        <CardBody>
          <CardTitle tag="h3">Evalueringskategorier</CardTitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted">
            Innstillinger som brukes i evalueringskategorier
          </CardSubtitle>
          {(this.state.categories || []).map((category) => {
            return (
              <div key={category._id}>
                <EvaluationCategoryName
                  category={category}
                  saveName={this.saveEvaluationCategory}
                  categories={this.state.categories}
                />
                <ConsequenceSelections
                  category={category}
                  selections={category.consequenceSelections}
                  save={this.saveCategorySelection}
                />
                <VSpace />
                <ScoreColor
                  loadCategories={this.loadCategories}
                  scoreColors={category.scoreColors}
                  probabilities={this.props.risikoBoard.probabilitySelections}
                  evaluationCategoryId={category._id}
                  consequences={category.consequenceSelections}
                />
                {this.state.categories && this.state.categories.length > 0 && (
                  <DeleteConfirmDepricated
                    id={category.name}
                    deleteCb={() => this.execDelete(category)}
                    text="Are you sure you wish to delete this category? This cannot be undone!"
                  />
                )}
                <hr />
              </div>
            );
          })}
          {this.state.newEvaluationCategoryDialogOpen && (
            <AddNewEvaluationCategoryModal
              categories={this.state.categories}
              toggle={this.toggleNewEvaluationDialog}
              saveNewEvaluationCategory={this.saveNewEvaluationCategory}
            />
          )}
          <Button color="success" onClick={() => this.toggleNewEvaluationDialog()}>
            Opprett ny
          </Button>
        </CardBody>
      </Card>
    );
  }
}

const ConsequenceSelections: React.FC<{
  category: IRisikoEvaluationCategory;
  selections: IRisikoEvaluationCategorySelection[];
  save: any;
}> = ({ category, selections, save }) => {
  const _save = (_selections: IRisikoEvaluationCategorySelection[]) => {
    save(_selections, category);
  };
  return <CategorySelections selections={category.consequenceSelections} saveCb={_save} name="Konsekvens" />;
};

const EvaluationCategoryName = ({ categories, category, saveName }) => {
  const [editMode, setEditMode] = useState(false);
  const [newName, setNewName] = useState(category.name);
  const nameExists =
    categories.find((category) => category.name.toUpperCase() === newName.toUpperCase()) != null &&
    category.name !== newName;
  const save = (category) => {
    category.name = newName;
    setEditMode(false);
    saveName(category);
  };

  const _setNewName = (val) => {
    setNewName(val.replace(/ /g, "_"));
  };

  return (
    <div className="d-flex">
      {editMode === false && (
        <>
          <h4>
            <b>{category.name.replaceAll("_", " ")}</b>
          </h4>
          <Button color="primary" size="sm" outline className="ml-2" onClick={() => setEditMode(!editMode)}>
            <i className="fa fa-pencil fa-fw" />
          </Button>
        </>
      )}
      {editMode && (
        <>
          <Input
            style={{ width: "150px" }}
            autoComplete="false"
            type="text"
            autoFocus
            placeholder="Skriv inn navn"
            value={newName.replaceAll("_", " ")}
            onChange={(e) => _setNewName(e.target.value)}
          />
          {nameExists && <p className="text-danger mt-2">Navnet eksisterer allerede</p>}
          <Button size="sm" color="success" disabled={nameExists} className="ml-2" onClick={() => save(category)}>
            <i className="fa fa-close fa-fw" />
          </Button>

          <HSpace />
          <Button size="sm" color="secondary" onClick={() => setEditMode(false)}>
            Avbryt
          </Button>
        </>
      )}
    </div>
  );
};

const AddNewEvaluationCategoryModal = ({ categories, toggle, saveNewEvaluationCategory }) => {
  const [name, setName] = useState("");

  const _setName = (value) => {
    setName(value.replace(/ /g, "_"));
  };

  const nameExists =
    categories.find((category) => category.name.toUpperCase() === name.toUpperCase().replace(/ /g, "_")) != null;
  return (
    <Modal isOpen toggle={toggle} size="sm">
      <ModalHeader toggle={toggle}>Ny evaluering</ModalHeader>
      <ModalBody>
        <Input
          autoComplete="false"
          type="text"
          autoFocus
          placeholder="Skriv inn navnet på den nye evalueringskategorien, f.eks. Kvalitet, Tid osv."
          value={name.replaceAll("_", " ")}
          onChange={(e) => _setName(e.target.value)}
        />
        {nameExists && <p className="text-danger mt-2">Navnet eksisterer allerede</p>}
      </ModalBody>
      <ModalFooter>
        <Button color="success" disabled={nameExists} onClick={() => saveNewEvaluationCategory(name)}>
          Opprett
        </Button>
        <HSpace />
        <Button color="secondary" onClick={toggle}>
          Avbryt
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = (state: IRootState) => ({
  project: state.adminReducer.project,
});
// @ts-ignore
export default connect(mapStateToProps, {
  updateRisikoEvaluationCategory: doUpdateRisikoEvaluationCategory,
  setShowSuccessMessageThunk,
  setRisikoCategoriesInRedux,
})(RisikoEvaluationCategoriesDepricated);
