import React, { Children, cloneElement, ReactNode, useEffect, useState } from "react";
import {
  DropdownItem,
  DropdownItemProps,
  DropdownMenu,
  DropdownMenuProps,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import McInput from "../McInput/McInput";
import "./McDropdownBase.css";

interface IMcDropdownBaseCompound {
  Display: typeof McDropdownBaseDisplay;
  Option: typeof McDropdownBaseOption;
}

export interface IMcDropdownBase extends Omit<DropdownMenuProps, "onToggle"> {
  children: ReactNode;
  customTrigger?: ReactNode;
  multiSelect?: boolean;
  filterActive?: boolean;
  disabled?: boolean;
  onClear?: () => void;
  onFilter?: (value: string) => void;
  onToggle?: (isOpen: boolean) => void;
}

const McDropdownBase: React.FC<IMcDropdownBase> & IMcDropdownBaseCompound = ({
  multiSelect = false,
  children,
  customTrigger,
  color,
  filterActive = false,
  disabled = false,
  onClear,
  onFilter,
  onToggle,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [triggeredOnce, setTriggeredOnce] = useState<boolean>(false);
  const [menuItems, setMenuItems] = useState<ReactNode>(null);
  const [customTriggerInternal, setCustomTriggerInternal] = useState<ReactNode>(null);

  const escBehaviour = (e: React.KeyboardEvent) => {
    if (e.key === "Escape") {
      setIsOpen(false);
      e.stopPropagation();
    } else if (multiSelect === false && e.key === "Enter") {
      setIsOpen(false);
    } else if (multiSelect === true && e.key === "Enter") {
      e.stopPropagation();
    }
  };

  const clearValue = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    if (onClear != null) {
      onClear();
    }
  };

  const toggleDropdown = () => {
    if (onToggle != null) {
      onToggle(!isOpen);
    }
    setTriggeredOnce(true);
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const childWithToggle: ReactNode[] = [];
    let firstOption = 1;

    if (filterActive === true) {
      childWithToggle.push(
        <McDropdownBaseOption key="mcdd__search" toggle={false}>
          <McInput
            autoFocus={true}
            onChange={(e) => {
              if (onFilter != null) {
                onFilter(e.target.value);
              }
            }}
          />
        </McDropdownBaseOption>,
      );
    }

    if (React.isValidElement(customTrigger)) {
      const trigger = cloneElement(customTrigger, {
        ...customTrigger.props,
        onKeyDown: escBehaviour,
        onKeyUp: (e) => e.stopPropagation(),
        onClick: toggleDropdown,
      });

      setCustomTriggerInternal(trigger);
      firstOption = 0;
    }

    Children.forEach<ReactNode>(children, (child: JSX.Element, i) => {
      if (i >= firstOption && child != null) {
        childWithToggle.push(
          cloneElement(child, {
            ...child.props,
            toggle: child.props.toggle == null ? multiSelect === false : child.props.toggle,
          }),
        );
      }
    });

    setMenuItems(childWithToggle);
  }, [children]);

  return (
    <UncontrolledDropdown isOpen={isOpen} toggle={toggleDropdown}>
      {customTriggerInternal != null ? (
        customTriggerInternal
      ) : (
        <DropdownToggle
          disabled={disabled}
          color={color}
          onKeyDown={escBehaviour}
          onKeyUp={(e) => e.stopPropagation()}
          className="w-100 d-flex align-items-center"
          style={{ padding: ".4rem 1rem", height: "2.2rem" }}
        >
          <div className="truncateHeaderText">{children[0]}</div>
          <div className="flex-fill" />
          {onClear != null && disabled !== true && <i onClick={clearValue} className="fa fa-close ml-2 mcdd-clear" />}
          <i className="fa fa-caret-down ml-2" />
        </DropdownToggle>
      )}
      {triggeredOnce === true && (
        <DropdownMenu
          onKeyDown={escBehaviour}
          onKeyUp={(e) => e.stopPropagation()}
          style={{ maxHeight: "50vh", overflow: "auto" }}
          {...props}
        >
          {menuItems}
        </DropdownMenu>
      )}
    </UncontrolledDropdown>
  );
};

const McDropdownBaseDisplay: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({ children }) => {
  return <div className="d-flex align-items-center">{children}</div>;
};

function McDropdownBaseOption({
  selected,
  children,
  toggle = true,
  ...props
}: React.PropsWithChildren<
  DropdownItemProps & {
    selected?: boolean;
  }
>) {
  return (
    <DropdownItem
      {...props}
      toggle={toggle}
      className={`${props.className || ""}${selected === true ? " mcdd-selected" : ""}`}
    >
      {children}
    </DropdownItem>
  );
}

McDropdownBase.Display = McDropdownBaseDisplay;
McDropdownBase.Option = McDropdownBaseOption;

export default McDropdownBase;
