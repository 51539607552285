import classnames from "classnames";
import React from "react";
import { shallowEqual } from "react-redux";
import { Nav, NavItem, NavLink } from "reactstrap";
import { VSpace } from "../../../../components";
import GlobalFileUploaderTable from "../../../../components/FileUploader/GlobalFileUploaderTable";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { fetchAndSetReportsThunk, fetchAndSetSingleReportThunk, resetReportApp } from "../reportSlice";
import ReportLogg from "./ReportLogg";
import SettingsReport from "./SettingsReport";
import SingleReport from "./SingleReport";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

const MainReportPage: React.FC<{ reportId: string }> = ({ reportId }) => {
  const [activeTab, setActiveTab] = React.useState<"REPORT" | "SETTINGS" | "LOGG" | "FILES">("REPORT");

  const project = useAppSelector((state) => state.adminReducer.project);

  const activeReport = useAppSelector((state) => state.reportReducer.activeReport, shallowEqual);
  const activeVersion = useAppSelector((state) => state.reportReducer.activeVersion, shallowEqual);
  const editedData = useAppSelector((state) => state.reportReducer.editedData, shallowEqual);

  const isEditedDataEqual = JSON.stringify(editedData) === JSON.stringify(activeVersion?.data);

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    init();
  }, []);

  const init = () => {
    dispatch(resetReportApp());
    dispatch(fetchAndSetReportsThunk());
    dispatch(fetchAndSetSingleReportThunk(reportId));
  };

  const handleTabChange = (tab: "REPORT" | "SETTINGS" | "LOGG" | "FILES") => {
    if (isEditedDataEqual === false) {
      const confirmChange = window.confirm("You have unsaved changes, are you sure you want to leave?");
      if (!confirmChange) return;
    }
    setActiveTab(tab);
  };

  return (
    <div className="d-flex flex-column w-100 p-2 overflow-auto">
      {activeReport != null && (
        <>
          <Nav tabs>
            <NavItem className="custom">
              <NavLink
                className={classnames({ active: activeTab === "REPORT", custom: true })}
                onClick={() => handleTabChange("REPORT")}
              >
                Rapport
              </NavLink>
            </NavItem>
            <NavItem className="custom">
              <NavLink
                className={classnames({ active: activeTab === "FILES", custom: true })}
                onClick={() => handleTabChange("FILES")}
              >
                Filer
              </NavLink>
            </NavItem>
            <NavItem className="custom">
              <NavLink
                className={classnames({ active: activeTab === "SETTINGS", custom: true })}
                onClick={() => handleTabChange("SETTINGS")}
              >
                Innstillinger
              </NavLink>
            </NavItem>
            <NavItem className="custom">
              <NavLink
                className={classnames({ active: activeTab === "LOGG", custom: true })}
                onClick={() => handleTabChange("LOGG")}
              >
                Logg
              </NavLink>
            </NavItem>
          </Nav>
          <VSpace />
          {activeTab === "REPORT" && <SingleReport />}
          {activeTab === "SETTINGS" && <SettingsReport reportBoard={activeReport} />}
          {activeTab === "LOGG" && <ReportLogg />}
          {activeTab === "FILES" && <Files />}
        </>
      )}
    </div>
  );
};

export default MainReportPage;

const Files: React.FC = () => {
  const activeReport = useAppSelector((state) => state.reportReducer.activeReport, shallowEqual);

  const columns: ColDef[] = [
    {
      field: "docxImageTag",
      headerName: "Docx tag",
      cellRenderer: (params) => {
        return <b>%{params.value}</b>;
      },
    },
    { field: "filename", headerName: "Filnavn", flex: 1 },
    { field: "height", headerName: "Höjd" },
    { field: "width", headerName: "Bredd" },
  ];

  return (
    <div>
      <GlobalFileUploaderTable path={`${activeReport.project}/report/${activeReport._id}/files`} />
      <hr />
      <h4>Bilder</h4>
      <div className="ag-theme-alpine" style={{ width: "100%" }}>
        <AgGridReact
          domLayout="autoHeight"
          rowData={activeReport.uploadedFiles}
          columnDefs={columns}
          defaultColDef={{ resizable: true, sortable: true, filter: true }}
          pagination={true}
          paginationPageSize={10}
        />
      </div>
    </div>
  );
};
