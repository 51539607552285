import * as R from "ramda";
import React, { useRef, useState } from "react";
import { useHistory } from "react-router";
import { Alert } from "reactstrap";
import { IDocxTemplate } from "../../../../../types/global.types";
import { pathIsInIssueApp } from "../../../app/routes/issuesApp/issueUtils";
import { DocxErrorModal } from "../DocxErrorModal";

const DocxUpload: React.FC<{
  uploadCompleedCb: () => void;
  uploadFunc: (event: React.ChangeEvent<HTMLInputElement>, level?: string) => void;
  docxTemplates?: IDocxTemplate[];
}> = ({ uploadCompleedCb, uploadFunc, docxTemplates }) => {
  const [dupWarning, setDupWarning] = useState<boolean>(false);
  const [errors, setErrors] = useState(null);
  const [keyy, setKeyy] = useState(null);
  const templateInputRef = useRef(null);

  const history = useHistory();
  const location = history.location;

  const urlIsIssueApp = pathIsInIssueApp(location.pathname);

  const uploadFile = async (event: React.ChangeEvent<HTMLInputElement>, level: string) => {
    if (docxTemplates?.find((tmpl) => event.target.files[0].name === tmpl.fileName) != null) {
      setDupWarning(true);
      return;
    }
    setDupWarning(false);

    setErrors(null);
    try {
      await uploadFunc(event, level);
      uploadCompleedCb();
      setKeyy("");
    } catch (err) {
      const errorToSet = R.pathOr(err, ["response", "data", "error", "error", "properties"], err);
      setErrors(errorToSet);
      setKeyy("");
    }
    templateInputRef.current.value = null;
  };
  return (
    <div className="d-flex flex-column border p-2">
      {dupWarning && <Alert color="danger p-3">En mal med samme filnavn finnes allerede</Alert>}
      <input
        type="file"
        key={keyy}
        accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-word.document.macroEnabled.12"
        onChange={(event) => uploadFile(event, "PROJECT")}
        ref={templateInputRef}
      />
      {urlIsIssueApp === true && (
        <input
          type="file"
          key={"board"}
          accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-word.document.macroEnabled.12"
          onChange={(event) => uploadFile(event, "BOARD")}
          ref={templateInputRef}
        />
      )}
      {errors != null && <DocxErrorModal toggle={() => setErrors(null)} errors={errors} />}
    </div>
  );
};

export default DocxUpload;
