import { Fragment } from "react";
import { Nav, NavLink } from "reactstrap";
import { ISectionWithItems } from "../../types";
import "./tabsidebar.css";
import { IValueLabel } from "../../../../types/global.types";

const TabSidebar: React.FC<{
  sections: ISectionWithItems[];
  activeTab: string;
  onClick: (arg: IValueLabel) => void;
  collapsed?: boolean;
}> = ({ activeTab, sections, onClick, collapsed }) => {
  const TabSection: React.FC<{ section: ISectionWithItems }> = ({ section }) => {
    const tabSection = section.items.map((item, index) => {
      return (
        <Fragment key={index}>
          {(item.showItem === undefined || item.showItem) && (
            <NavLink
              key={index}
              style={{ width: "190px" }}
              className={`sidebar-tab ${item.value === activeTab && "active"}`}
              onClick={() => onClick(item)}
            >
              <div>{item.label}</div>
            </NavLink>
          )}
        </Fragment>
      );
    });
    return <>{tabSection}</>;
  };
  const allSections = sections.map((section, index) => {
    return (
      <div key={section.label}>
        <div className="font-weight-bold ml-2 mt-3" style={{ fontSize: "1.1rem", color: "#4e4e4e" }}>
          {section.label}
        </div>
        <TabSection section={section} key={section.label} />
      </div>
    );
  });

  return (
    <div className={`settingsSidebar overflow-hidden ${collapsed ? "settingsSidebar-collapsed" : ""}`}>
      <Nav className="d-flex flex-column">{allSections}</Nav>
    </div>
  );
};

export default TabSidebar;
