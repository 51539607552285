import React from "react";
import { Button } from "reactstrap";

const AddButton: React.FC<{ onClick: (boolean) => void }> = ({ onClick }) => {
  return (
    <Button color="mc-blue" onClick={() => onClick(true)}>
      <i className="fa fa-plus "></i>
    </Button>
  );
};

export default AddButton;
