import { useEffect, useState } from "react";
import "./SidePanel.css";

const SidePanel: React.FC<{
  onClick?: (arg) => void;
  collapsed?: boolean;
  children: JSX.Element | JSX.Element[];
}> = ({ onClick, collapsed, children }) => {
  const [render, setRender] = useState(!collapsed);

  useEffect(() => {
    if (!collapsed) setRender(true);
  }, [collapsed]);

  const onAnimationEnd = () => {
    if (collapsed) setRender(false);
  };
  return (
    <>
      {render && (
        <div
          style={{
            animation: `${collapsed ? "slideOut" : "slideIn"} ease-in-out .3s`,
          }}
          onAnimationEnd={onAnimationEnd}
          className={"notificationSidebar"}
        >
          {children}
        </div>
      )}
    </>
  );
};

export default SidePanel;
