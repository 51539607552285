import { IEnergiBrukPerTimme } from "mc-shared/zod/report/Energi/Energi_601_schema";

export const convertEnergiBrukPerTimmeFromExcel = (excelData: any[]): IEnergiBrukPerTimme[] => {
  return excelData.reduce((acc, row, index) => {
    if (index > 12) {
      const energiBrukPerTimme: IEnergiBrukPerTimme = {
        utetemp: row[12],
        rommoppv: row[13],
        ventverme: row[14],
        romkyl: row[15],
        ventkyl: row[16],
        effektbelysning: row[17],
        effektutstyr: row[18],
        effektvifter: row[19],
        produksjonSolcell: row[20],
      };
      acc.push(energiBrukPerTimme);
    }

    return acc;
  }, [] as IEnergiBrukPerTimme[]);
};
