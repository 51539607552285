import * as _ from "lodash";
import * as R from "ramda";
import { IIssueUserStat, IMember, IOrgChartItem, IParentChildItem } from "../../../../../types/global.types";
import { getHighestNumberInArr } from "../../utils";

export const getParentInLevelItem = (levelId: string): string => {
  const splitArr = String(levelId).split(".");
  const dropLast = R.dropLast(1, splitArr);
  return dropLast.join(".");
};

export const formatOrganisationItemsToParentChildItems = (
  orgChartItems: IOrgChartItem[],
  members: IMember[],
  userIsseuStats: IIssueUserStat[],
): IParentChildItem[] => {
  const parentChildItems = orgChartItems.map((orgItem) => {
    const parent = getParentInLevelItem(orgItem.level);
    const member = members.find((member) => member.user?.name === orgItem.nameOfUser);
    const issueStat = member && userIsseuStats.find((issueStat) => issueStat.userId === member.user?._id);
    const item: IParentChildItem = {
      id: orgItem.level,
      parent: parent,
      label: orgItem.text,
      labelColor: "#f8992e",
      subLabel: orgItem.nameOfUser,
      subLabelColor: "#fff",
      bgColor: "#262626",
      bgOpacity: 1,
      /* texts: ["36 items in risiko", "11 items in sakshantering"],*/
      textsFontSize: "12px",
      textColor: "#fff",
      imageUrl: `/api/image/${member?.user?._id}`,
      nrOfOpenIssues: issueStat && issueStat.openIssuesCount,
      nrOfOverdueIssues: issueStat && issueStat.overDueIssuesCount,
    };
    return item;
  });

  const parents = parentChildItems.filter((item) => item.parent === "");
  if (parents.length > 1) {
    const rootParent = {
      bgColor: "#262626",
      bgOpacity: 1,
      id: "root",
      label: "root",
      labelColor: "#f8992e",
      parent: "",
      subLabel: "1",
      subLabelColor: "#fff",
      textColor: "#fff",
      textsFontSize: "12px",
    };
    const _parentsChildItems = parentChildItems.map((item) => {
      return {
        ...item,
        parent: item.parent === "" ? "root" : item.parent,
      };
    });
    _parentsChildItems.unshift(rootParent);
    return _parentsChildItems;
  } else {
    return parentChildItems;
  }
};

export const getNumberOfNodeLevels = (nodes: IParentChildItem[]): number => {
  const nodeIds = R.pluck("id", nodes);
  const numberOfDots = nodeIds.map((id) => {
    const splitByDot = id.split(".");
    return splitByDot.length;
  });
  return getHighestNumberInArr(numberOfDots);
};

export const hasNodeNumberOfLevels = (numberOfLevels: number, node: IParentChildItem) => {
  if (numberOfLevels == null) {
    return true;
  }
  const splitByDot = node?.id.split(".");
  return splitByDot.length <= numberOfLevels;
};

export const isOrgChartLevelItemsValid = (orgChartItems: IOrgChartItem[]) => {
  return orgChartItems.every(
    (orgChartItem) =>
      _.isEmpty(orgChartItem?.text) === false && isOrgChartLevelValid(orgChartItems, orgChartItem.level),
  );
};

export const orgChartItemsAreUniq = (orgChartItems: IOrgChartItem[]) => {
  const nrOfUniqItems = _.uniq(orgChartItems.map((item) => item && item.level)).length;
  return nrOfUniqItems === orgChartItems.length;
};

export const isOrgChartLevelValid = (orgChartItems: IOrgChartItem[], level: string): boolean => {
  if (orgChartItemsAreUniq(orgChartItems) === false || level == null || level === "0" || typeof level !== "string") {
    return false;
  }
  if (level.includes(".") === true) {
    const parentLevel = getParentInLevelItem(level);
    if (orgChartItems.find((item) => item.level === parentLevel) == null) {
      return false;
    }
  }
  const patt = /^(?:[0-9]+[0-9]*\.?)+$/;
  const result = level?.match(patt);
  return result != null;
};

export const convertExcelToOrgChartItems = (arr: any[]): IOrgChartItem[] => {
  try {
    const _arr = R.clone(arr);
    if (isNaN(arr[0])) {
      _arr.shift();
    }
    const orgChartItems: IOrgChartItem[] = _arr.reduce((acc, item) => {
      if (item[0] != null && item[1] != null) {
        acc.push({
          level: String(item[0]), // 1.2.1
          text: item[1], // VVS
          nameOfUser: item[2], // Per Ström
        });
      }
      return acc;
    }, []);
    const isValid = isOrgChartLevelItemsValid(orgChartItems);
    if (isValid === false) {
      throw Error("Not valid import");
    }
    return orgChartItems;
  } catch (error) {
    console.error(error);
    return null;
  }
};
