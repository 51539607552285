import { Button, UncontrolledTooltip } from "reactstrap";
import { multiPageExportExcel } from "../../app/utils";
import { IBootstrapTableColumnConfig } from "../../types";

interface MultiProps {
  filePrefix: string;
  data: any[];
  columns: IBootstrapTableColumnConfig[];
}

const MultiPageExcelExport: React.FC<MultiProps> = ({ filePrefix, columns, data }) => {
  const exportPages = () => {
    multiPageExportExcel(columns, data, filePrefix);
  };

  return (
    <div>
      <UncontrolledTooltip placement="top" target="multiExportExcel">
        Eksportere alle til Excel
      </UncontrolledTooltip>
      <Button id="multiExportExcel" onClick={() => exportPages()}>
        <i className="fa fa-file-excel-o" />
        <i className="fa fa-file-excel-o" />
      </Button>
    </div>
  );
};

export default MultiPageExcelExport;
