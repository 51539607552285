import { AgChartOptions } from "ag-charts-community";
import { AgCharts } from "ag-charts-react";
import React from "react";

interface IHighchartDataItem {
  name: string;
  y: number;
  color?: string;
}

export const StatusCircleChart: React.FC<{ circleChartDataItems: IHighchartDataItem[] }> = ({
  circleChartDataItems,
}) => {
  const itemTotalCount = circleChartDataItems.reduce((acc, item) => acc + item.y, 0);
  const options: AgChartOptions = {
    height: 400,
    data: circleChartDataItems,
    animation: {
      enabled: true,
    },
    title: {
      text: "Status",
      fontWeight: "bold",
      fontSize: 18,
    },
    series: [
      {
        type: "pie",
        angleKey: "y",
        legendItemKey: "name",
        sectorLabelKey: "y",
        calloutLabelKey: "name",
        cornerRadius: 2,
        fills: circleChartDataItems.map((item) => item.color),
        tooltip: {
          renderer: (params) => {
            const datum = params.datum as IHighchartDataItem;
            const percentage = ((datum.y / itemTotalCount) * 100).toFixed(1);
            return {
              title: datum.name,
              content: `Status: ${percentage}%`,
            };
          },
        },
      },
    ],
    legend: {
      item: {
        label: {
          fontSize: 14,
        },
      },
    },
  };

  return <AgCharts options={options} />;
};
