import React from "react";
import { formatShortDate } from "../../admin/selectors";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import { AG_GRID_LOCALE_NOR } from "../../../../agGridLocale";
import { useAppSelector } from "../../../../hooks";

const ReportLogg: React.FC = () => {
  const activeReport = useAppSelector((state) => state.reportReducer.activeReport);

  const columns: ColDef[] = [
    {
      field: "_id",
      headerName: "Id",
      hide: true,
    },
    {
      field: "versionNumber",
      headerName: "Version",
    },
    {
      field: "createdAt",
      headerName: "Opprettet",
      initialSort: "desc",
      cellRenderer: (params) => formatShortDate(params.value),
    },
    {
      field: "savedBy.name",
      headerName: "Lagret av",
    },
    {
      field: "message",
      headerName: "Melding",
      flex: 1,
    },
  ];

  return (
    <div>
      {activeReport != null && (
        <div className="ag-theme-quartz h-100 w-100">
          <AgGridReact
            domLayout={"autoHeight"}
            rowData={activeReport.versions || []}
            defaultColDef={{
              suppressHeaderMenuButton: true,
              cellClass: "d-block",
            }}
            headerHeight={35}
            columnDefs={columns}
            suppressClickEdit={true}
            animateRows={false}
            suppressContextMenu={true}
            localeText={AG_GRID_LOCALE_NOR}
          />
        </div>
      )}
    </div>
  );
};

export default ReportLogg;
