import saveAs from "file-saver";
import { IAzureBlobFile } from "mc-shared/zod/azureSchema";
import { apiSlice } from "../apiSlice";

export const azureBlobStorageRTK = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFilesFromAzureBlobStorage: builder.query<IAzureBlobFile[] | [], { projectId: string; path: string }>({
      query: ({ projectId, path }) => ({
        url: `/project/${projectId}/azureblobstorage/files-in-directory.get`,
        method: "POST",
        body: { path },
      }),
      providesTags: ["AzureBlobStorage"],
    }),
    uploadFileToAzureBlobStorage: builder.mutation<void, { projectId: string; file: File; path: string }>({
      query: ({ projectId, file, path }) => {
        const formData = new FormData();
        formData.append("fileToUpload", file);
        formData.append("path", path);
        return {
          url: `/project/${projectId}/azureblobstorage/file.upload`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["AzureBlobStorage"],
    }),
    fetchFileFromAzureBlobStorage: builder.mutation<
      any | IAzureBlobFile,
      { projectId: string; fileId: string; fileName: string }
    >({
      query: ({ projectId, fileId, fileName }) => ({
        url: `/project/${projectId}/azureblobstorage/file.download`,
        method: "POST",
        body: { fileId },
        responseHandler: async (response) => {
          const blob = await response.blob();
          saveAs(blob, fileName);
        },
        cache: "no-cache",
      }),
      invalidatesTags: ["AzureBlobStorage"],
    }),
    deleteFileInAzureBlobStorage: builder.mutation<void, { projectId: string; fileId: string }>({
      query: ({ projectId, fileId }) => {
        return {
          url: `/project/${projectId}/azureblobstorage/file.delete`,
          method: "POST",
          body: { fileId },
        };
      },
      invalidatesTags: ["AzureBlobStorage"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetFilesFromAzureBlobStorageQuery,
  useUploadFileToAzureBlobStorageMutation,
  useFetchFileFromAzureBlobStorageMutation,
  useDeleteFileInAzureBlobStorageMutation,
} = azureBlobStorageRTK;
