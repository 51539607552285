import { FailedAlert, McInput, VSpace } from "../../../components";
import { useCreateServiceAccountMutation } from "../../../globalRTK";
import { Alert, Button } from "reactstrap";
import React from "react";

export const ServiceAccounts: React.FC = () => {
  const [createServiceAccount, { isError, isSuccess, isUninitialized, data }] = useCreateServiceAccountMutation();

  const [serviceName, setServiceName] = React.useState<string>("");
  return (
    <div>
      {isSuccess && (
        <Alert color="success">
          <b>{data.apiKey}</b>
        </Alert>
      )}
      {isError && <FailedAlert />}
      <McInput
        style={{ width: "100%" }}
        type="text"
        value={serviceName}
        onChange={(e) => setServiceName(e.target.value)}
        name="text"
      />
      <VSpace />
      <Button
        color="success"
        disabled={isUninitialized === false}
        onClick={() =>
          createServiceAccount({
            serviceName,
          })
        }
      >
        Add
      </Button>
    </div>
  );
};
