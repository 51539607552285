import React from "react";
import { Button, Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";
import { TitleWithValue } from "..";
import { IMinUser, IValueLabel } from "../../../../types/global.types";
import { IRegister } from "../../../../types/register.types";
import CheckOrWarn from "../CheckOrWarn";
import DropdownEditDepricated from "../DropdownEditDepricated";

const GeneralSettingsCard: React.FC<{
  board: IRegister.BoardPopulated;
  saveCb: <K extends keyof IRegister.BoardPopulated, V>(
    board: IRegister.BoardPopulated,
    prop: K,
    oldValue: V,
    newValue: V,
  ) => void;
  allUsers: IMinUser[];
  statuses: IValueLabel[];
  hideAccess?: boolean;
  useIsValid: boolean;
  isValid?: boolean;
  canStart?: boolean;
  startNow?: () => void;
}> = ({ board, saveCb, allUsers, statuses, isValid, canStart, startNow, hideAccess = false, useIsValid }) => {
  const getLabel = (value: string) => {
    return statuses.find((status) => status.value === value).label;
  };

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h3">
          {useIsValid === true && <CheckOrWarn bool={isValid} />}
          Generelt
        </CardTitle>
        <CardSubtitle tag="h6" className="mb-2 text-muted" />
        <TitleWithValue
          title="Navn"
          value={board.name}
          editable
          saveCb={(oldValue, newValue) => saveCb(board, "name", oldValue, newValue)}
        />
        <DropdownEditDepricated
          title="Status"
          options={statuses.map((status) => status.label)}
          value={board.status}
          saveCb={(oldValue, newValue) =>
            saveCb(board, "status", oldValue, statuses.find((stat) => stat.label === newValue).value)
          }
          editable
          formatter={getLabel}
        />
        {startNow != null && (
          <div>
            <Button disabled={canStart} onClick={startNow}>
              Starta nu
            </Button>
          </div>
        )}
        {/*{hideAccess !== true && (
          <AccessHandlerEdit
            access={board.access}
            updateAccess={(oldValue, newValue) => saveCb(board, "access", oldValue, newValue)}
            allUsers={allUsers}
            createdByUserId={board.createdBy._id}
          />
        )}*/}
      </CardBody>
    </Card>
  );
};

export default GeneralSettingsCard;
