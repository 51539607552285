import React, { useState } from "react";
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { IArcGisGroup } from "../../../../../../types/global.types";
import { apiSlice } from "../../../../apiSlice";
import { DropdownSelectorDeprecated, MCLoader } from "../../../../components";
import { VSpace } from "../../../../components/";
import { ToastMessagesEnums } from "../../../../frontendConstants";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { setProjectArcgisInRedux } from "../../../actions";
import { doConnectArcgisGroupToProject, doGetMulticonsultGroups } from "../../gis/GisAPI";
import { setShowSuccessMessageThunk } from "../../../../global/globalSlice";

const ConnectArcgisModal: React.FC<{ toggle: any }> = ({ toggle }) => {
  const dispatch = useAppDispatch();
  const project = useAppSelector((state) => state.adminReducer.project);

  const [arcGisGroups, setArcGisGroups] = React.useState<IArcGisGroup[]>(null);
  const [selectedArcGisGroup, setSelectedArcGisGroup] = useState<IArcGisGroup>(null);
  const [failedWarning, setFailedWarning] = useState<boolean>(false);

  React.useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      const _arcGisGroups = await doGetMulticonsultGroups(project?._id);
      setArcGisGroups(_arcGisGroups);
    } catch (error) {
      setFailedWarning(true);
      console.error(error);
    }
  };

  const connectArcgis = async () => {
    const arcgis = await doConnectArcgisGroupToProject(project._id, selectedArcGisGroup.id);
    dispatch(setProjectArcgisInRedux(arcgis));
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.UPDATED));
    dispatch(apiSlice.util.invalidateTags(["Project"]));
    setTimeout(() => {
      toggle(true);
    }, 50);
  };

  return (
    <Modal isOpen toggle={toggle} style={{ height: "300px" }}>
      <ModalHeader>Koble til Multiconsult Cloud GIS gruppe</ModalHeader>
      <ModalBody>
        {arcGisGroups != null ? (
          <DropdownSelectorDeprecated
            color="mc-blue"
            activeItem={selectedArcGisGroup}
            setItem={setSelectedArcGisGroup}
            items={arcGisGroups}
            fullWidth
            selectText={selectedArcGisGroup == null ? "Velg gruppe" : selectedArcGisGroup.title}
            displayKey="title"
            enableSearch
          />
        ) : (
          <MCLoader loading />
        )}
        <VSpace />
        <Alert color="info">Finner du ikke gruppen din? Kontakt support (mcapps@multiconsult.no)</Alert>
        <VSpace />
        {failedWarning && (
          <Alert color="danger">Kunne ikke hente grupper. Kontakt support (mcapps@multiconsult.no)</Alert>
        )}
      </ModalBody>
      <ModalFooter>
        <Button disabled={selectedArcGisGroup == null} color="success" onClick={() => connectArcgis()}>
          Koble til gruppen
        </Button>
        <Button color="secondary" onClick={() => toggle(false)}>
          Lukk
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConnectArcgisModal;
