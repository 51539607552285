import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup, Input } from "reactstrap";
import { emailExist } from "../admin/restQueries";
import { emailShouldLoginWithOffice365, validateEmail } from "../admin/selectors";
import multiconsultlogo from "./Multiconsult_logo.svg";
import { doLoginLocal, sendResetPassword } from "../../userApi";
import { McTitle, VSpace } from "../../../components";

const Login = () => {
  const [forgotPassword, setForgotPassword] = useState(false);

  return (
    <div className="d-flex flex-column gray-200 w-100 h-100 pt-5">
      <img src={multiconsultlogo} alt="..." style={{ height: "30px", marginBottom: "20px" }} />
      <div style={{ width: "300x", borderRadius: "5px" }} className="login-box">
        <LoginForm forgotPassword={forgotPassword} setForgotPassword={setForgotPassword} />
      </div>
    </div>
  );
};

type LoginState = "default" | "loading" | "error";

const LoginForm: React.FC<{ forgotPassword: boolean; setForgotPassword: any }> = ({
  forgotPassword,
  setForgotPassword,
}) => {
  const [state, setState] = useState<LoginState>("default");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [emailNotFoundWarning, setEmailNotFound] = useState(false);

  const submit = async () => {
    setState("loading");

    await doLoginLocal(email, password)
      .then((resp) => {
        window.location.assign("/");
      })
      .catch((err) => {
        setState("error");
      });
  };

  const onEmailChange = (value) => {
    if (emailShouldLoginWithOffice365(value)) {
      window.location.assign("/api/login");
    } else {
      setEmail(value);
    }
    setShowPassword(validateEmail(value) && forgotPassword === false);
  };

  const isEmailValid = validateEmail(email);

  const showPasswordInput =
    showPassword && isEmailValid && emailShouldLoginWithOffice365(email) === false && emailNotFoundWarning === false;

  return (
    <>
      {forgotPassword === false && (
        <>
          <Button color="mc-blue" onClick={() => window.location.assign("/api/login")} block>
            Logg inn som Multiconsult-bruker
          </Button>
          <VSpace />
          <div className="w-100" style={{ border: "1px solid #ccc" }} />
          <VSpace />
          <Form style={{ width: "100%" }}>
            <FormGroup>
              <McTitle title="Ekstern bruker">
                <Input
                  type="email"
                  placeholder="Angi Epost (invitasjon kreves)"
                  bsSize="lg"
                  value={email}
                  onChange={(e) => onEmailChange(e.target.value.toLocaleLowerCase())}
                />
              </McTitle>
            </FormGroup>
            {showPasswordInput && (
              <FormGroup>
                <Input
                  type="password"
                  placeholder="Password"
                  bsSize="lg"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormGroup>
            )}
            <EmailNotFoundWarning
              email={email}
              emailNotFoundWarning={emailNotFoundWarning}
              setEmailNotFound={setEmailNotFound}
            />
            {state === "error" && isEmailValid && <p className="text-danger">Beklager. Feil passord</p>}
            {isEmailValid && emailNotFoundWarning === false && (
              <FormGroup>
                <p className="small-text">
                  Glemt passordet?
                  <Button size="sm" color="link" onClick={() => setForgotPassword(true)}>
                    Tilbakestill passordet ditt
                  </Button>
                </p>
                {password?.length > 0 && (
                  <Button
                    block
                    color="mc-blue"
                    disabled={state === "loading" || !email || !password || emailNotFoundWarning}
                    onClick={submit}
                  >
                    Logg Inn
                  </Button>
                )}
              </FormGroup>
            )}
          </Form>
        </>
      )}
      {forgotPassword && <PasswordReset />}
    </>
  );
};

const PasswordReset = () => {
  const [emailToSendPasswordReset, setEmailToSenPasswordRest] = useState("");
  const [emailNotFoundWarning, setEmailNotFound] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [isValidateEmail, setIsValidateEmail] = useState(false);

  const resetPassword = async () => {
    localStorage.clear();
    deleteAllCookies();
    sessionStorage.clear();
    //Remove all cookies
    await sendResetPassword(emailToSendPasswordReset);
    setIsSent(true);
  };

  const onEmailChange = (value) => {
    setEmailNotFound(false);
    setIsValidateEmail(validateEmail(value));
    if (emailShouldLoginWithOffice365(value)) {
      window.location.assign("/api/login");
    } else {
      setEmailToSenPasswordRest(value);
    }
  };

  return (
    <>
      <div className="border w-100 p-3">
        {isSent === false ? (
          <>
            <p className="small-text">
              Skriv inn e-postadressen din nedenfor, så sender vi deg en lenke for å tilbakestille passordet ditt.
            </p>
            <Input
              type="text"
              placeholder="Skriv inn epost"
              value={emailToSendPasswordReset}
              onChange={(e) => onEmailChange(e.target.value)}
            />
            <Button
              color="secondary"
              className="mt-2"
              block
              disabled={emailNotFoundWarning || validateEmail(emailToSendPasswordReset) === false}
              onClick={() => resetPassword()}
            >
              Tilbakestill passord
            </Button>
            {isValidateEmail === true && (
              <EmailNotFoundWarning
                email={emailToSendPasswordReset}
                emailNotFoundWarning={emailNotFoundWarning}
                setEmailNotFound={setEmailNotFound}
              />
            )}
          </>
        ) : (
          <h3>Hjelp er på vei</h3>
        )}
      </div>
    </>
  );
};

const EmailNotFoundWarning = ({ email, emailNotFoundWarning, setEmailNotFound }) => {
  useEffect(() => {
    async function fetchData() {
      if (validateEmail(email)) {
        const emailExistFetch = await emailExist(email);
        setEmailNotFound(emailExistFetch.data === false);
      }
    }

    fetchData();
  });

  return (
    <>
      {emailNotFoundWarning && (
        <p className="mt-2 text-danger">Vi fant ikke denne e-posten i systemet vårt. Skrev du det riktig?</p>
      )}
    </>
  );
};

export default Login;

function deleteAllCookies() {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    debugger;
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    debugger;
  }
}
