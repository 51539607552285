import {
  IGKFotometerResultater,
  IGKInneslutningsLekkasjetestMedFotometerResultater,
  IGKRapport,
} from "mc-shared/zod/report/GK/gkReportSchema";
import { Button, Card, CardBody, CardTitle, FormGroup, Input, Label } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../../../types/global.types";
import { HSpace, McInput } from "../../../../../../../components";
import ReportDocxImageContainer from "../../../../ReportBoard/ReportDocxImageContainer";
import { beregnetMaksimalLekkasjeverdi } from "../../gkGlobalUtils";

const InneslutningslekkasjetestMedFotometer: React.FC<{
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
  data: IGKInneslutningsLekkasjetestMedFotometerResultater;
}> = ({ data, onUpdateEditData }) => {
  const addNewRow = () => {
    const newFilter: IGKFotometerResultater = {
      maalepunktNr: "",
      konstruksjonstype: "",
      maaleresultaterUrenSone: null,
      godkjent: null,
      maaleresultaterRenSone: null,
      maksimalLekkasjeverdi: null,
      beskrivelseAvLekkasjeLokasjon: "",
    };

    const updatedMaaleresultater: IGKFotometerResultater[] = [...data.inndata.maaleresultater, newFilter];

    onUpdateEditData(
      "testResultater.inneslutningsLekkasjeTestMedFotometer.inndata.maaleresultater",
      updatedMaaleresultater,
    );

    return updatedMaaleresultater;
  };

  const removeLastRow = () => {
    const updatedMaaleresultater = [...data.inndata.maaleresultater];

    if (updatedMaaleresultater.length > 0) {
      updatedMaaleresultater.pop(); // Remove the last element
      onUpdateEditData(
        "testResultater.inneslutningsLekkasjeTestMedFotometer.inndata.maaleresultater",
        updatedMaaleresultater,
      );
    }
  };

  const updateMaaleresultater = (resultat: string | number | boolean, i: number, parameter: string) => {
    const maaleresultater = [...data.inndata.maaleresultater];
    maaleresultater[i][parameter] = resultat;
    maaleresultater[i].maksimalLekkasjeverdi = beregnetMaksimalLekkasjeverdi(
      maaleresultater[i].maaleresultaterUrenSone,
    );
    onUpdateEditData("testResultater.inneslutningsLekkasjeTestMedFotometer.inndata.maaleresultater", maaleresultater);
  };

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Inneslutningslekkasjetest med fotometer</CardTitle>
        <div className="d-flex flex-column">
          <b>Hvor mange målinger skal utføres? &#40;-&#41;:</b>
          <div className="d-flex">
            <Button color="success" onClick={addNewRow}>
              Legg til rad
            </Button>
            <HSpace />
            <Button color="danger" onClick={removeLastRow}>
              Fjern siste rad
            </Button>
          </div>
        </div>
        {Number(data.inndata.maaleresultater.length) > 0 && (
          <div className="mt-4 d-flex flex-column">
            <b>Fyll inn måleresultater:</b>
            <table>
              <thead>
                <tr>
                  <th>Målepunktnummer &#40;-&#41;</th>
                  <th>Konstruksjonstype &#40;-&#41;</th>
                  <th>
                    Måleresultat uren sone &#40;mg/m<sup>3</sup>&#41;
                  </th>
                  <th className="d-flex justify-content-center">Verdi høyere enn 0,01%?</th>
                  <th>Måleresultat ren sone &#40;%&#41;</th>
                  <th>Beskrivelse av lekkasjelokasjon &#40;-&#41;</th>
                </tr>
              </thead>
              {data.inndata.maaleresultater.map((filter, i) => (
                <tbody key={i}>
                  <tr>
                    <td>
                      <McInput
                        value={filter.maalepunktNr}
                        type="text"
                        onChange={(e) => updateMaaleresultater(e.target.value, i, "maalepunktNr")}
                        placeholder="Skriv inn målepunktnummer"
                      />
                    </td>
                    <td>
                      <McInput
                        value={filter.konstruksjonstype}
                        type="text"
                        onChange={(e) => updateMaaleresultater(e.target.value, i, "konstruksjonstype")}
                        placeholder="Skriv inn konstruksjonstype"
                      />
                    </td>
                    <td>
                      <McInput
                        value={filter.maaleresultaterUrenSone}
                        normalizeNumbers={true}
                        type="text"
                        onBlur={(e) => updateMaaleresultater(Number(e.target.value), i, "maaleresultaterUrenSone")}
                        placeholder="Måleresultat uren sone"
                      />
                    </td>
                    <td>
                      <FormGroup
                        className="d-flex flex-row justify-content-around align-items-center"
                        check
                        style={{ backgroundColor: "#ebecec", borderRadius: "6px", height: "2.2rem" }}
                      >
                        <Label check>
                          <Input
                            type="radio"
                            checked={filter.godkjent === true}
                            onChange={() => updateMaaleresultater(true, i, "godkjent")}
                          />
                          Ja
                        </Label>
                        <Label check>
                          <Input
                            type="radio"
                            checked={filter.godkjent === false}
                            onChange={() => updateMaaleresultater(false, i, "godkjent")}
                          />
                          Nei
                        </Label>
                      </FormGroup>
                    </td>
                    {filter.godkjent === true && (
                      <>
                        <td>
                          <McInput
                            value={filter.maaleresultaterRenSone}
                            normalizeNumbers={true}
                            type="text"
                            onBlur={(e) => updateMaaleresultater(Number(e.target.value), i, "maaleresultaterRenSone")}
                            placeholder="Måleresultat ren sone"
                          />
                        </td>
                        <td>
                          <McInput
                            value={filter.beskrivelseAvLekkasjeLokasjon}
                            type="text"
                            onChange={(e) => updateMaaleresultater(e.target.value, i, "beskrivelseAvLekkasjeLokasjon")}
                            placeholder="Beskrivelse av lekkasjelokasjon"
                          />
                        </td>
                      </>
                    )}
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
        )}
        {Number(data.inndata.maaleresultater.length) > 0 && (
          <div className="mt-4">
            <ReportDocxImageContainer docxImageTag="inneslutningsLekkasjeTestMedFotometer" title="Målepunkter" />
            <div className="w-50">
              <b>Kommentar:</b>
              <McInput
                value={data.inndata.kommentar}
                onChange={(e) =>
                  onUpdateEditData(
                    "testResultater.inneslutningsLekkasjeTestMedFotometer.inndata.kommentar",
                    e.target.value,
                  )
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default InneslutningslekkasjetestMedFotometer;
