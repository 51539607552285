import {
  FUNKSJONSKONTROLL_AVTREKKSSKAP_TESTER_METODER,
  FunksjonskontrollAvtrekksskapTesterEnum,
} from "mc-shared/zod/report/GK/gkReportConstants";
import { IGKFunksjonskontrollAvtrekksskapResultater, IGKRapport } from "mc-shared/zod/report/GK/gkReportSchema";
import React from "react";
import { Card, CardBody, CardTitle, FormGroup, Input, Label } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../../../types/global.types";
import { DropdownFilterGeneric, McInput } from "../../../../../../../components";

const FunksjonskontrollAvtrekksskap: React.FC<{
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
  data: IGKFunksjonskontrollAvtrekksskapResultater;
}> = ({ data, onUpdateEditData }) => {
  const addOrRemoveTest = (test: FunksjonskontrollAvtrekksskapTesterEnum) => {
    data.inndata.valgtMetode[test] = !data.inndata.valgtMetode[test];
    onUpdateEditData("testResultater.funksjonskontrollAvtrekksskap.inndata.valgtMetode", data.inndata.valgtMetode);
  };

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Funksjonskontroll av avtrekksskap</CardTitle>
        <b>Velg hvilke tester som skal utføres?</b>
        <div className="w-25 mb-4">
          <DropdownFilterGeneric
            filterOptions={FUNKSJONSKONTROLL_AVTREKKSSKAP_TESTER_METODER.map((test) => {
              return {
                ...test,
                isActive: data.inndata.valgtMetode[test.value],
              };
            })}
            onClick={(value) => addOrRemoveTest(value)}
            title="Velg tester"
            color="secondary"
            textColor="text-black"
          />
        </div>
        {data.inndata.valgtMetode[FunksjonskontrollAvtrekksskapTesterEnum.KONTROLLAVBETJENINGSPANEL] === true && (
          <>
            <h4>Kontroll av betjeningspanel</h4>
            <div>
              <FormGroup className="d-flex flex-column" check>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.kontrollAvBetjeningspanelResultater.godkjent === true}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollAvtrekksskap.inndata.kontrollAvBetjeningspanelResultater.godkjent",
                        true,
                      )
                    }
                  />
                  Godkjent
                </Label>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.kontrollAvBetjeningspanelResultater.godkjent === false}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollAvtrekksskap.inndata.kontrollAvBetjeningspanelResultater.godkjent",
                        false,
                      )
                    }
                  />
                  Ikke godkjent
                </Label>
              </FormGroup>
            </div>
            <div className="w-50 mt-2 mb-4">
              <b>Kommentar:</b>
              <McInput
                value={data.inndata.kontrollAvBetjeningspanelResultater.kommentar}
                onChange={(e) =>
                  onUpdateEditData(
                    "testResultater.funksjonskontrollAvtrekksskap.inndata.kontrollAvBetjeningspanelResultater.kommentar",
                    e.target.value,
                  )
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
          </>
        )}
        {data.inndata.valgtMetode[FunksjonskontrollAvtrekksskapTesterEnum.NOEDTEST] === true && (
          <>
            <h4>Nødtest</h4>
            <div>
              <FormGroup className="d-flex flex-column" check>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.noedtestResultater.godkjent === true}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollAvtrekksskap.inndata.noedtestResultater.godkjent",
                        true,
                      )
                    }
                  />
                  Godkjent
                </Label>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.noedtestResultater.godkjent === false}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollAvtrekksskap.inndata.noedtestResultater.godkjent",
                        false,
                      )
                    }
                  />
                  Ikke godkjent
                </Label>
              </FormGroup>
            </div>
            <div className="w-50 mt-2 mb-4">
              <b>Kommentar:</b>
              <McInput
                value={data.inndata.noedtestResultater.kommentar}
                onChange={(e) =>
                  onUpdateEditData(
                    "testResultater.funksjonskontrollAvtrekksskap.inndata.noedtestResultater.kommentar",
                    e.target.value,
                  )
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
          </>
        )}
        {data.inndata.valgtMetode[FunksjonskontrollAvtrekksskapTesterEnum.LYSNIVAA] === true && (
          <>
            <h4>Lysnivå</h4>
            <div className="d-flex flex-column mb-2">
              <b>Fyll inn måleresultat &#40;lux&#41;:</b>
              <McInput
                value={data.inndata.lysnivaaResultater.maaleresultat}
                onBlur={(e) =>
                  onUpdateEditData(
                    "testResultater.funksjonskontrollAvtrekksskap.inndata.lysnivaaResultater.maaleresultat",
                    Number(e.target.value),
                  )
                }
                normalizeNumbers={true}
                type="text"
                className="w-25"
              />
            </div>
            <div>
              <FormGroup className="d-flex flex-column" check>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.lysnivaaResultater.godkjent === true}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollAvtrekksskap.inndata.lysnivaaResultater.godkjent",
                        true,
                      )
                    }
                  />
                  Godkjent
                </Label>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.lysnivaaResultater.godkjent === false}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollAvtrekksskap.inndata.lysnivaaResultater.godkjent",
                        false,
                      )
                    }
                  />
                  Ikke godkjent
                </Label>
              </FormGroup>
            </div>
            <div className="w-50 mt-2 mb-4">
              <b>Kommentar:</b>
              <McInput
                value={data.inndata.lysnivaaResultater.kommentar}
                onChange={(e) =>
                  onUpdateEditData(
                    "testResultater.funksjonskontrollAvtrekksskap.inndata.lysnivaaResultater.kommentar",
                    e.target.value,
                  )
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
          </>
        )}
        {data.inndata.valgtMetode[FunksjonskontrollAvtrekksskapTesterEnum.LYDNIVAA] === true && (
          <>
            <h4>Lydnivå</h4>
            <div className="d-flex flex-column mb-2">
              <b>Fyll inn måleresultat &#40;dB&#41;:</b>
              <McInput
                value={data.inndata.lydnivaaResultater.maaleresultat}
                onBlur={(e) =>
                  onUpdateEditData(
                    "testResultater.funksjonskontrollAvtrekksskap.inndata.lydnivaaResultater.maaleresultat",
                    Number(e.target.value),
                  )
                }
                normalizeNumbers={true}
                type="text"
                className="w-25"
              />
            </div>
            <div>
              <FormGroup className="d-flex flex-column" check>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.lydnivaaResultater.godkjent === true}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollAvtrekksskap.inndata.lydnivaaResultater.godkjent",
                        true,
                      )
                    }
                  />
                  Godkjent
                </Label>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.lydnivaaResultater.godkjent === false}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollAvtrekksskap.inndata.lydnivaaResultater.godkjent",
                        false,
                      )
                    }
                  />
                  Ikke godkjent
                </Label>
              </FormGroup>
            </div>
            <div className="w-50 mt-2 mb-4">
              <b>Kommentar:</b>
              <McInput
                value={data.inndata.lydnivaaResultater.kommentar}
                onChange={(e) =>
                  onUpdateEditData(
                    "testResultater.funksjonskontrollAvtrekksskap.inndata.lydnivaaResultater.kommentar",
                    e.target.value,
                  )
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
          </>
        )}
        {data.inndata.valgtMetode[FunksjonskontrollAvtrekksskapTesterEnum.DEKONTAMINERINGSARRANGEMENT] === true && (
          <>
            <h4>Dekontamineringsarragement</h4>
            <div>
              <FormGroup className="d-flex flex-column" check>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.dekontamineringsarrangement.godkjent === true}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollAvtrekksskap.inndata.dekontamineringsarrangement.godkjent",
                        true,
                      )
                    }
                  />
                  Godkjent
                </Label>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.dekontamineringsarrangement.godkjent === false}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollAvtrekksskap.inndata.dekontamineringsarrangement.godkjent",
                        false,
                      )
                    }
                  />
                  Ikke godkjent
                </Label>
              </FormGroup>
            </div>
            <div className="w-50 mt-2 mb-4">
              <b>Kommentar:</b>
              <McInput
                value={data.inndata.dekontamineringsarrangement.kommentar}
                onChange={(e) =>
                  onUpdateEditData(
                    "testResultater.funksjonskontrollAvtrekksskap.inndata.dekontamineringsarrangement.kommentar",
                    e.target.value,
                  )
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default FunksjonskontrollAvtrekksskap;
