import React from "react";
import CheckTypesSelector from "../CheckTypesSelector";
import { ICheckTypes } from "mc-shared/zod/checklistSchema";

const ChecklistTypes: React.FC<{
  editable?: boolean;
  onChange: (types: ICheckTypes) => void;
  checkTypes: ICheckTypes;
}> = ({ editable = false, onChange, checkTypes }) => {
  const updateCheckTypes = async (types: ICheckTypes) => {
    if (types.check1.active === false && types.check2.active === false && types.check3.active === false) {
      return;
    }

    onChange(types);
  };

  return editable === false ? (
    <CheckTypesSelector inputIsEditable={editable} checkTypes={checkTypes} onChange={updateCheckTypes} disabled />
  ) : (
    editable === true && (
      <CheckTypesSelector inputIsEditable={editable} checkTypes={checkTypes} onChange={updateCheckTypes} />
    )
  );
};

export default ChecklistTypes;
