import { DekontamineringAvSikkerhetskabinettEnum } from "mc-shared/zod/report/GK/gkReportConstants";
import { IGKDekontamineringAvSikkerhetskabinettResultater, IGKRapport } from "mc-shared/zod/report/GK/gkReportSchema";
import React from "react";
import { Card, CardBody, CardTitle, FormGroup, Input, Label } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../../types/global.types";
import { McDropdown, McInput } from "../../../../../../components";
import ReportDocxImageContainer from "../../../ReportBoard/ReportDocxImageContainer";

const DekontamineringAvSikkerhetskabinett: React.FC<{
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
  data: IGKDekontamineringAvSikkerhetskabinettResultater;
}> = ({ data, onUpdateEditData }) => {
  const TESTMETODER: DekontamineringAvSikkerhetskabinettEnum[] = [
    DekontamineringAvSikkerhetskabinettEnum.INNEISIKKERHETSKABINETT,
    DekontamineringAvSikkerhetskabinettEnum.UTENFORSIKKERHETSKABINETT,
    DekontamineringAvSikkerhetskabinettEnum.TILKOBLETAVKAST,
  ];

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Dekontaminering av sikkerhetskabinett</CardTitle>
        <b>Hvilken metode skal brukes?</b>
        <div className="w-25">
          <McDropdown
            currentValue={data.inndata.valgtMetode}
            onChange={(_, item) =>
              onUpdateEditData("testResultater.dekontamineringAvSikkerhetskabinett.inndata.valgtMetode", item as string)
            }
            defaultTitle="Hvilken testmetode skal brukes?"
            options={TESTMETODER}
          />
        </div>
        <div className="w-50 mt-4">
          <b>Utført syklus:</b>
          <div>
            <FormGroup className="d-flex flex-column" check>
              <Label check>
                <Input
                  type="radio"
                  checked={data.inndata.utfoertSyklus === true}
                  onChange={() =>
                    onUpdateEditData("testResultater.dekontamineringAvSikkerhetskabinett.inndata.utfoertSyklus", true)
                  }
                />
                Utført
              </Label>
              <Label check>
                <Input
                  type="radio"
                  checked={data.inndata.utfoertSyklus === false}
                  onChange={() =>
                    onUpdateEditData("testResultater.dekontamineringAvSikkerhetskabinett.inndata.utfoertSyklus", false)
                  }
                />
                Ikke utført
              </Label>
            </FormGroup>
          </div>
        </div>
        <div className="d-flex flex-column mt-4">
          <b>Fyll inn syklusstart &#40;-&#41;:</b>
          <McInput
            value={data.inndata.syklusstart}
            onChange={(e) =>
              onUpdateEditData("testResultater.dekontamineringAvSikkerhetskabinett.inndata.syklusstart", e.target.value)
            }
            type="time"
            className="w-25"
          />
        </div>
        <div className="d-flex flex-column mt-2">
          <b>Fyll inn syklusslutt &#40;-&#41;:</b>
          <McInput
            value={data.inndata.syklusslutt}
            onChange={(e) =>
              onUpdateEditData("testResultater.dekontamineringAvSikkerhetskabinett.inndata.syklusslutt", e.target.value)
            }
            type="time"
            className="w-25"
          />
        </div>
        <div className="d-flex flex-column mt-2">
          <b>Fyll inn høyeste PPM &#40;partikler/million&#41;:</b>
          <McInput
            value={data.inndata.hoeyestePPM}
            onBlur={(e) =>
              onUpdateEditData(
                "testResultater.dekontamineringAvSikkerhetskabinett.inndata.hoeyestePPM",
                Number(e.target.value),
              )
            }
            normalizeNumbers={true}
            type="text"
            className="w-25"
          />
        </div>
        <div className="d-flex flex-column mt-2">
          <b>Fyll inn luftfuktighet &#40;%&#41;:</b>
          <McInput
            value={data.inndata.luftfuktighet}
            onBlur={(e) =>
              onUpdateEditData(
                "testResultater.dekontamineringAvSikkerhetskabinett.inndata.luftfuktighet",
                Number(e.target.value),
              )
            }
            normalizeNumbers={true}
            type="text"
            className="w-25"
          />
        </div>
        <div className="d-flex flex-column mt-2">
          <b>
            Fyll inn temperatur &#40;C<sup>o</sup>&#41;:
          </b>
          <McInput
            value={data.inndata.temperatur}
            onBlur={(e) =>
              onUpdateEditData(
                "testResultater.dekontamineringAvSikkerhetskabinett.inndata.temperatur",
                Number(e.target.value),
              )
            }
            normalizeNumbers={true}
            type="text"
            className="w-25"
          />
        </div>
        <div className="mt-4">
          <ReportDocxImageContainer docxImageTag="dekontamineringAvSikkerhetskabinett" title="Plassering av sensor" />
          <div className="w-50">
            <b>Kommentar:</b>
            <McInput
              value={data.inndata.kommentar}
              onChange={(e) =>
                onUpdateEditData("testResultater.dekontamineringAvSikkerhetskabinett.inndata.kommentar", e.target.value)
              }
              type="textarea"
              dynamicHeight={true}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default DekontamineringAvSikkerhetskabinett;
