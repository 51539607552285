import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { z, ZodError } from "zod";
import { Alert } from "reactstrap";
import { useCreateOrGetDoksamChecklistQuery } from "../globalRTK";
import { FailedAlert, MCLoader } from "../components";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useHistory } from "react-router";

const ZChecklistDoksamParams = z.object({
  projectNo: z.string().regex(/^\d{8}-\d{2}$/, "projectNo must be in the format of 8 digits, a hyphen, and 2 digits"),
  doksamDocId: z.string().min(3),
  spItemId: z.string().min(3),
  templateId: z
    .string()
    .regex(/^\d{4,5}$/, "templateId must be a string of digits with a length between 4 and 5 characters"),
  check1: z
    .string()
    .regex(
      /^[a-z]{1,10}$/,
      "check1 (upn) must be a string of lowercase letters with a length between 1 and 10 characters",
    )
    .optional(),
  check2: z
    .string()
    .regex(
      /^[a-z]{1,10}$/,
      "check2 (upn) must be a string of lowercase letters with a length between 1 and 10 characters",
    )
    .optional(),
});

export type IChecklistDoksamParams = z.infer<typeof ZChecklistDoksamParams>;

const DoksamChecklistPath: React.FC<{ searchStr: string }> = ({ searchStr }) => {
  const [parsedParams, setParsedParams] = useState<IChecklistDoksamParams | null>(null);
  const [paramsError, setParamsError] = useState<ZodError | null>(null);

  const history = useHistory();

  const getFirstEightDigitFromProjectNo = (projectNo: string | undefined) => {
    if (projectNo == null) {
      return "";
    }
    return projectNo.split("-")[0];
  };

  const getSubtask = (projectNo: string | undefined) => {
    if (projectNo == null) {
      return "";
    }
    return projectNo.split("-")[1];
  };


  const { data, isLoading, isError, error, isSuccess } = useCreateOrGetDoksamChecklistQuery(
    {
      projectNo: Number(getFirstEightDigitFromProjectNo(parsedParams?.projectNo)),
      projectSubTask: getSubtask(parsedParams?.projectNo),
      cheklistTemplateId: Number(parsedParams?.templateId),
      doksamDokId: parsedParams?.doksamDocId,
      spItemId: parsedParams?.spItemId,
      check1: parsedParams?.check1,
      check2: parsedParams?.check2,
    },
    {
      skip: parsedParams == null,
    },
  );

  useEffect(() => {
    if (isSuccess === true && data != null) {
      history.push(`/project/${data.projectId}/checklist/instance/${data.checklistId}?doksam=true`);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    localStorage.removeItem("gotolink");
    const parsed = queryString.parse(searchStr);
    setParamsError(null);
    try {
      const params = ZChecklistDoksamParams.parse(parsed);
      setParsedParams(params);
    } catch (error) {
      console.log(error);
      setParamsError(error);
    }
  }, [searchStr]);

  const errorMessage = error as FetchBaseQueryError;
  return (
    <div>
      {isError && <FailedAlert errorMessage={errorMessage?.data as any} />}
      {isLoading && <MCLoader />}
      {paramsError != null && (
        <div>
          <h4>Feil nettadresseparametere</h4>
          {paramsError.errors.map((err, i) => (
            <Alert color="danger" key={i}>
              <div key={i}>{JSON.stringify(err)}</div>
            </Alert>
          ))}
        </div>
      )}
    </div>
  );
};

export default DoksamChecklistPath;
