const ExcelDoc = () => {
  return (
    <>
      <h3 className={"mb-2"}>Koble Excel til MCBIM-database</h3>
      <h4 className="mb-3 mt-5">
        <div className="textCircle">1</div> Start Microsoft Excel
      </h4>
      <div style={{ marginLeft: "3em" }}>
        <p>Start Microsoft Excel og åpne et tomt regneark.</p>
      </div>
      <h4 className="mb-3 mt-5">
        <div className="textCircle">2</div> Bruk Data Connection Wizard til å sette opp tilkoblingen
      </h4>
      <div style={{ marginLeft: "3em" }}>
        <img
          src="images/PowerBI/image012.png"
          alt="excel-1"
          className="mb-3"
          style={{ width: "400px", boxShadow: "9px 10px 15px -10px #363a5135" }}
        />
        <p>
          1. Klikk på <b>Data</b>-fanen.
        </p>

        <p>
          2. Velg <b>From Other Sources &gt; From Data Connection Wizard</b>
        </p>

        <p>
          3. Velg <b>ODBC DSN</b> fra listen over datakildealternativer og klikk <b>Next</b>.
        </p>

        <p>
          4. Velg DSN som er opprettet for din Atlas-cluster og klikk <b>Next</b>.
        </p>

        <p>
          5. Velg en database og samling fra hvilken du vil importere data og klikk <b>Next</b>.
        </p>

        <p>
          6. Lagre datakoblingsfilen og klikk på <b>Finish</b>.
        </p>

        <p>
          Hvis du ønsker å bruke denne tilkoblingen på nytt i fremtiden, kan du velge den fra{" "}
          <b>Data &gt; Get External Data &gt; Existing Connections</b>-menyen.
        </p>

        <p>
          {" "}
          7.I det siste veivinduet, angi et format for regnearket ditt. Klikk <b>OK</b> når du er ferdig.
        </p>
      </div>
    </>
  );
};

export default ExcelDoc;
