import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import MainFilterBar from "../../../../components/MainFilterBar/MainFilterBar";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { ILabelAndIsActive } from "../../../../types";
import {
  clearAllMeetingFilterThunk,
  toggleAllMultiMeetingsFilterInRedux,
  toggleMeetingBoolFilterThunk,
  toggleOptionInMeetingFiltersInRedux,
} from "../meetingSlice";
import { ColDef } from "ag-grid-community";
import { IMeeting } from "../../../../../../types/meeting.types";

const MeetingDbFilterBar: React.FC<{ columns: ColDef<IMeeting.Meeting>[] }> = ({ columns }) => {
  const allFilters = useAppSelector((state) => state.meetingReducer.allFilters);

  const dispatch = useAppDispatch();

  const getFormattedMultifilterItems = () => {
    return (allFilters ?? []).reduce((acc, allFilterItem) => {
      if (allFilterItem.options != null) {
        const col = columns?.find((_col) => _col.field.startsWith(allFilterItem.dataField));
        if (col != null) {
          const filterItem = {
            title: col.headerName,
            options: allFilterItem.options,
          };
          acc.push(filterItem);
        }
      }
      return acc;
    }, []);
  };

  const getFormattedBoolFilters = (): ILabelAndIsActive[] => {
    return (allFilters ?? []).reduce((acc, allFilterItem) => {
      if (allFilterItem.bool != null) {
        const col = columns?.find((_col) => _col.field.startsWith(allFilterItem.dataField));
        if (col != null) {
          const filterItem: ILabelAndIsActive = {
            label: col.headerName,
            isActive: allFilterItem.bool,
          };
          acc.push(filterItem);
        }
      }
      return acc;
    }, []);
  };

  const multipleFilterItems = useMemo(() => getFormattedMultifilterItems(), [JSON.stringify(allFilters)]);
  const boolFilterItems = useMemo(() => getFormattedBoolFilters(), [JSON.stringify(allFilters)]);

  const getDataField = (colTitle: string): string => {
    console.log("colTitle", colTitle);
    return columns.find((col) => col.headerName === colTitle)?.field;
  };

  const history = useHistory();

  const reset = () => {
    history.push(`${history.location.pathname}`);
    dispatch(clearAllMeetingFilterThunk());
  };

  return (
    <>
      {multipleFilterItems != null && (
        <div className="border pt-2 px-2 d-flex w-100 mc-gray-200">
          <MainFilterBar
            // onUpdateSearch={(str) => dispatch(setMeetingFreeTextSearchInRedux(str))}
            // searchText={freeTextSearch}
            multipleFilterItems={multipleFilterItems}
            booleanFilterItems={boolFilterItems}
            updateMultiFilterItemOption={(title, value) =>
              dispatch(
                toggleOptionInMeetingFiltersInRedux({
                  dataField: getDataField(title),
                  optionValue: value,
                }),
              )
            }
            updateBooleanFilterItem={(title) =>
              dispatch(toggleMeetingBoolFilterThunk(columns.find((col) => col.headerName === title)?.field))
            }
            clearAllFilters={reset}
            resetMultiFilter={(title) =>
              dispatch(
                toggleAllMultiMeetingsFilterInRedux({
                  dataField: getDataField(title),
                  isActive: false,
                }),
              )
            }
            selectAllMultiFilter={(title) =>
              dispatch(
                toggleAllMultiMeetingsFilterInRedux({
                  dataField: getDataField(title),
                  isActive: true,
                }),
              )
            }
          />
        </div>
      )}
    </>
  );
};

export default MeetingDbFilterBar;
