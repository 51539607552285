import moment from "moment";
import React from "react";
import { Button, UncontrolledTooltip } from "reactstrap";
import { INotification } from "../../../types/global.types";

const NotificationButton: React.FC<{
  toggleNotificationTab: () => void;
  notifications: INotification[];
  lastOpenedDate: string;
}> = ({ toggleNotificationTab, notifications, lastOpenedDate }) => {
  const hasNewNotifications = () => {
    return notifications.some((notification) => moment(notification.createdAt).isAfter(lastOpenedDate));
  };

  return (
    <>
      <Button
        id="notificationButton"
        color="transparent"
        className="nav-button nav-button-hover"
        style={{ fontSize: "16px", padding: "0.4rem" }}
        onClick={toggleNotificationTab}
      >
        <i className={`fa fa-bell ${hasNewNotifications() === true ? "faa-ring animated text-primary" : ""}`} />
      </Button>
      <UncontrolledTooltip target="notificationButton">Vis notifikasjoner</UncontrolledTooltip>
    </>
  );
};

export default NotificationButton;
