import McInput, { TMcInput } from "../McInput/McInput";

const SearchInput: React.FC<TMcInput> = (props) => {
  return (
    <div className="w-100" style={{ position: "relative", display: "inline-block" }}>
      <McInput {...props} />
      <div style={{ position: "absolute", right: 10, top: "50%", transform: "translateY(-50%)" }}>
        <i className="fa fa-search fa-fw" style={{ color: "#c4c4c4" }} />
      </div>
    </div>
  );
};

export default SearchInput;
