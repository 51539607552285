import React from "react";
import {
  useDeleteFileInAzureBlobStorageMutation,
  useFetchFileFromAzureBlobStorageMutation,
  useGetFilesFromAzureBlobStorageQuery,
  useUploadFileToAzureBlobStorageMutation,
} from "../../app/azureBlobStorageRTK";
import MCLoader from "../MCLoader/MCLoader";
import FileTable from "./FileTable";
import FileUploader from "./FileUploader";

const GlobalFileUploaderTable: React.FC<{ path: string; disableUploader?: boolean; imageOnly?: boolean }> = ({
  path,
  disableUploader = false,
  imageOnly = false,
}) => {
  const projectId = path?.split("/")[0];

  const [uploadFile, { isLoading: fileIsUploading }] = useUploadFileToAzureBlobStorageMutation();
  const [deleteFile] = useDeleteFileInAzureBlobStorageMutation();
  const [triggerDownload] = useFetchFileFromAzureBlobStorageMutation();
  const {
    data: files,
    isLoading: loadingFiles,
    isError: errorFetchingFiles,
  } = useGetFilesFromAzureBlobStorageQuery({ projectId, path });

  return (
    <div>
      {loadingFiles === false && fileIsUploading === false ? (
        <>
          {disableUploader === false && (
            <FileUploader
              onFileUpload={(file: File) => uploadFile({ projectId, file, path })}
              files={files}
              imageOnly={imageOnly}
            />
          )}
          <FileTable
            downloadFile={(fileId: string, fileName: string) => {
              triggerDownload({ projectId, fileId, fileName });
            }}
            files={files || []}
            deleteFile={(fileId: string) => {
              deleteFile({ projectId, fileId });
            }}
            folderPath={path}
          />
        </>
      ) : (
        <MCLoader loading />
      )}
    </div>
  );
};

export default GlobalFileUploaderTable;
