export const beregnetSluttkonsentrasjon = (
  luftskifter: number,
  bakgrunnskonsentrasjonFoerTest: number,
  startkonsentrasjon: number,
): number => {
  if (bakgrunnskonsentrasjonFoerTest > startkonsentrasjon || bakgrunnskonsentrasjonFoerTest === 0) {
    return null;
  } else {
    return -(1 / luftskifter) * Math.log(bakgrunnskonsentrasjonFoerTest / startkonsentrasjon);
  }
};
