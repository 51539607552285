import { IGeoTeknikkRapport, ZGeoTeknikkRapportSchema } from "mc-shared/zod/report/Geoteknik/geoteknikSchema";
import * as R from "ramda";
import React, { useEffect } from "react";
import { useDebounce } from "usehooks-ts";
import { SafeParseReturnType, ZodError } from "zod";
import { IMulticonsultProject, Paths, PathValue } from "../../../../../../types/global.types";
import { IMaconomyCoordinates } from "../../../../../../types/report.types";
import { VSpace } from "../../../../components";
import { useAppDispatch } from "../../../../hooks";
import { doGetMProject } from "../../../projectApi";
import GeoteknikGenrellInfo, { GEOTEKNIK_DEPARTMENTS } from "./GeoteknikGenerellInfo";
import GeoteknikMaps, { convertMaconomyCoordinatesToGeoteknikCoordinates } from "./GeoteknikMaps";
import GeoteknikSettings from "./GeoteknikSettings";
import SubMcProject from "./SubMcProject";
import { setShowSuccessMessageThunk } from "../../../../global/globalSlice";

const GeoteknikOmraadestabilitetsRapport: React.FC<{
  data: IGeoTeknikkRapport;
  onError: (errors: ZodError<IGeoTeknikkRapport>) => void;
  onUpdateEditData: (data: IGeoTeknikkRapport, docxData: IGeoTeknikkRapport) => void;
  projectId: string;
  userName: string;
}> = ({ data, onUpdateEditData, projectId, userName, onError }) => {
  const [editData, setEditData] = React.useState(R.clone(data));
  const debouncedValue = useDebounce<IGeoTeknikkRapport>(editData, 1000);

  useEffect(() => {
    onUpdateEditData(data, data);
  }, []);

  useEffect(() => {
    const parsedResult: SafeParseReturnType<IGeoTeknikkRapport, IGeoTeknikkRapport> =
      ZGeoTeknikkRapportSchema.safeParse(editData);

    if (parsedResult.success === false) {
      onError(parsedResult.error);
    } else {
      onUpdateEditData(editData, editData);
    }
  }, [debouncedValue]);

  const [failedToFetchFromMaconomy, setFailedToFetchFromMaconomy] = React.useState<boolean>(false);

  React.useEffect(() => {
    setEditData(null);
    setTimeout(() => {
      setEditData(data);
    }, 50);
  }, [data]);

  const dispatch = useAppDispatch();

  const fetchFromMaconomyAndSetData = async (subProjectNo: string) => {
    try {
      setFailedToFetchFromMaconomy(false);
      const multiconsultProject: IMulticonsultProject = await doGetMProject(projectId, subProjectNo);
      if (multiconsultProject == null) {
        setFailedToFetchFromMaconomy(true);
        return;
      }
      dispatch(setShowSuccessMessageThunk("Hentet data fra maconomy"));

      const maconomyCoordinates: IMaconomyCoordinates = {
        UTMN:
          typeof multiconsultProject.UTMN === "string"
            ? Number(multiconsultProject.UTMN.replace(",", "."))
            : multiconsultProject.UTMN,
        GNR: Number(multiconsultProject.GNR),
        BNR: Number(multiconsultProject.BNR),
        UTME:
          typeof multiconsultProject.UTME === "string"
            ? Number(multiconsultProject.UTME.replace(",", "."))
            : multiconsultProject.UTME,
        UTMZ: Number(multiconsultProject.UTMZ),
      };

      const koordinater = convertMaconomyCoordinatesToGeoteknikCoordinates(maconomyCoordinates);

      let updatedData = R.clone(editData);
      updatedData.generellInfo.oppdragsNummer = String(multiconsultProject.ID);
      updatedData.generellInfo.oppdragsNavn = multiconsultProject.DisplayName;
      updatedData.prosjektInformasjon.koordinater = koordinater;
      updatedData.prosjektInformasjon.saksbehandler = userName;
      updatedData.generellInfo.oppdragsLeder = (multiconsultProject.ProjectManager as any)?.FullName;
      updatedData.prosjektInformasjon.eiendomsInformasjon.bnr = Number(multiconsultProject.BNR);
      updatedData.prosjektInformasjon.eiendomsInformasjon.gnr = Number(multiconsultProject.GNR);
      updatedData.generellInfo.oppdragsGiver.navn = multiconsultProject.CustomerName;
      updatedData.generellInfo.oppdragsGiver.kontaktPerson = multiconsultProject.InvoiceName;

      const geoteknikDepartment = GEOTEKNIK_DEPARTMENTS.find((d) => d.startsWith(String(multiconsultProject.UnitId)));

      updatedData.prosjektInformasjon.ansvarligEnhet = geoteknikDepartment || "";

      setEditData({
        ...editData,
        ...updatedData,
      });
    } catch (error) {
      setFailedToFetchFromMaconomy(true);
    }
  };

  const setLabText = (text: string) => {
    updateData("laboratorieresultater", text);
  };

  const updateData = <P extends Paths<IGeoTeknikkRapport, 4>>(path: P, value: PathValue<IGeoTeknikkRapport, P>) => {
    setEditData((prevState) => {
      const updatedData = R.assocPath(path.split("."), value, prevState);
      return updatedData;
    });
  };

  return (
    <div style={{ height: "100%", width: "800px" }}>
      {editData != null && (
        <>
          <GeoteknikSettings
            veiledningsTekst={editData.innstillinger?.veiledningsTekst}
            eksempelTekst={editData.innstillinger?.eksempelTekst}
            updateVeiledningsTekst={(bool) => updateData("innstillinger.veiledningsTekst", bool)}
            updateEksempelTekst={(bool) => updateData("innstillinger.eksempelTekst", bool)}
          />
          <VSpace />
          <SubMcProject onFetchFromMaconomy={fetchFromMaconomyAndSetData} failed={failedToFetchFromMaconomy} />
          <VSpace />
          <GeoteknikGenrellInfo editData={editData} updateData={updateData} />
        </>
      )}
      <VSpace />
      <GeoteknikMaps
        geoteknikKoordinater={editData?.prosjektInformasjon?.koordinater}
        onUpdateGeoteknikKoordinater={(koordinater) => updateData("prosjektInformasjon.koordinater", koordinater)}
        sirkelDiameter={editData?.prosjektInformasjon?.sirkelDiameter}
        onUpdateSirkelDiameter={updateData}
        zoomLevel={editData?.prosjektInformasjon?.zoomLevel}
        onUpdateZoomLevel={(zoomLevel) => updateData("prosjektInformasjon.zoomLevel", zoomLevel)}
      />
      <div style={{ height: "400px" }} />
    </div>
  );
};

export default GeoteknikOmraadestabilitetsRapport;
