import moment from "moment";
import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import { Badge, Modal, ModalBody, ModalHeader } from "reactstrap";
import { IMCBIMAPI } from "../../../../../../../types/mcbim.types";
import {
  COL_WIDTHS,
  createHeaderStyle,
  headerSortAndDropDownFilter,
  headerSortAndFilterFormatter,
  sortCaret,
} from "../../../../../components/columnGlobal";
import { IBootstrapTableColumnConfig } from "../../../../../types";
import { STANDARD_COLORS } from "../../../../globalConstants";
import { formatMillisecondsToString } from "../../../../utils";
import McBimSingleJob from "./McBimSingleJob";

export const getMcBimFileJobStatusColor = (status: IMCBIMAPI.JobStatus): string => {
  switch (status) {
    case "failed":
      return STANDARD_COLORS.red;
    case "in-progress":
      return STANDARD_COLORS.blue;
    case "queued":
      return STANDARD_COLORS.yellow;
    case "completed":
      return STANDARD_COLORS.green;

    default:
      return STANDARD_COLORS.red;
  }
};

const McJobTable: React.FC<{
  jobs: IMCBIMAPI.IJob[];
  projectId: string;
}> = ({ jobs, projectId }) => {
  const [selectedJob, setSelectedJob] = useState<IMCBIMAPI.IJob>(null);

  const columns: IBootstrapTableColumnConfig[] = [
    {
      headerFormatter: headerSortAndFilterFormatter,
      dataField: "_id",
      text: "",
      headerStyle: createHeaderStyle(COL_WIDTHS.ID),
      formatter: (cell, row: IMCBIMAPI.IJob) => {
        return (
          <div className="d-flex justify-content-center align-items-center h-100">
            <i style={{ cursor: "pointer" }} className="fa fa-info-circle" onClick={() => setSelectedJob(row)} />
          </div>
        );
      },
    },
    {
      dataField: "jobId",
      text: "Jobb ID",
      hidden: true,
    },
    {
      headerFormatter: headerSortAndFilterFormatter,
      dataField: "createdAt",
      text: "Opprettet",
      sort: true,
      sortCaret: sortCaret,
      headerStyle: {
        width: "150px",
      },
      formatter: (cell, row: IMCBIMAPI.IJob) => {
        return <div>{moment(row.createdAt).format("YYYY.MM.DD-HH:mm")}</div>;
      },
    },
    {
      headerFormatter: headerSortAndDropDownFilter,
      dataField: "status",
      text: `Status`,
      sort: true,
      sortCaret: sortCaret,
      headerStyle: createHeaderStyle(COL_WIDTHS.FILL),
      formatter: (cell, row: IMCBIMAPI.IJob) => {
        return (
          <Badge style={{ padding: "8px 12px" }} pill className="d-flex greybadge">
            <div className="statusdot" style={{ backgroundColor: getMcBimFileJobStatusColor(row.status) }}></div>
            <div style={{ color: STANDARD_COLORS.grey, fontSize: "12px" }}>{row.status.toUpperCase()}</div>
          </Badge>
        );
      },
    },
    {
      headerFormatter: headerSortAndFilterFormatter,
      dataField: "duration",
      text: "Tid brukt [min:sek:ms]",
      sort: true,
      sortCaret: sortCaret,
      headerStyle: createHeaderStyle(COL_WIDTHS.ID),
      formatter: formatMillisecondsToString,
    },
    {
      headerFormatter: headerSortAndFilterFormatter,
      dataField: "completed",
      text: "Antall fullførte filer",
      sort: true,
      sortCaret: sortCaret,
      headerStyle: createHeaderStyle(COL_WIDTHS.ID),
    },
    {
      headerFormatter: headerSortAndFilterFormatter,
      dataField: "failed",
      text: "Antall mislykkede filer",
      sort: true,
      sortCaret: sortCaret,
      headerStyle: createHeaderStyle(COL_WIDTHS.ID),
      formatter: (cell, row: IMCBIMAPI.IJob) => {
        return (
          <p>
            {cell > 0 && <i className="fa fa-warning fa-fw text-danger" />}
            {cell}
          </p>
        );
      },
    },
  ];

  return (
    <div
      className="d-flex justify-content-center mt-2 w-100"
      style={{ height: "calc(100% - 150px)", overflow: "auto" }}
    >
      <div className="border w-100">
        {jobs != null && (
          <BootstrapTable
            wrapperClasses="smalltable"
            defaultSorted={[
              {
                dataField: "updatedAt",
                order: "desc",
              },
            ]}
            filter={filterFactory()}
            bootstrap4
            bordered={false}
            striped
            hover
            keyField="_id"
            data={jobs}
            columns={columns}
            classes={"table-sm"}
          />
        )}
      </div>
      {selectedJob != null && (
        <JobModal
          date={selectedJob?.createdAt}
          projectId={projectId}
          jobId={selectedJob?.jobId}
          toggle={() => setSelectedJob(null)}
        />
      )}
    </div>
  );
};

const JobModal: React.FC<{
  jobId: string;
  date: string;
  projectId: string;
  toggle: () => void;
}> = ({ jobId, date, projectId, toggle }) => {
  return (
    <Modal style={{ width: "80vw", maxWidth: "80vw" }} isOpen toggle={toggle}>
      <ModalHeader toggle={toggle}>Synkronisering {moment(date).format("YYYY.MM.DD-hh:mm")}</ModalHeader>
      <ModalBody>
        <McBimSingleJob projectId={projectId} jobId={jobId} />
      </ModalBody>
    </Modal>
  );
};

export default McJobTable;
