import { ColDef } from "ag-grid-community";
import { ISamsvarserklaring, ISpesificationTableRow } from "mc-shared/zod/reportSchemas";
import * as R from "ramda";
import React from "react";
import { McInput } from "../../../../../components";
import ViewEditTable from "../../../../../components/ViewEditTable/ViewEditTable";
import "./RapportSamsvarserklaring.css";

interface ISpecification {
  spesifikasjonsId: string;
  spesifikasjonsType: string;
  argang: string;
}

const RapportSamsvarserklaring: React.FC<{
  samsvarserklaring: ISamsvarserklaring;
  onUpdateEditData: (data: ISamsvarserklaring, docxData) => void;
}> = ({ samsvarserklaring, onUpdateEditData }) => {
  const [editData, setEditData] = React.useState<ISamsvarserklaring>(R.clone(samsvarserklaring));

  React.useEffect(() => {
    onUpdateEditData(editData, editData);
  }, [editData]);

  React.useEffect(() => {
    setEditData(null);
    setTimeout(() => {
      setEditData(samsvarserklaring);
    }, 50);
  }, [samsvarserklaring]);

  const columns: ColDef<ISpesificationTableRow>[] = [
    {
      field: "spesifikasjonsId",
      headerName: "Id",
      editable: true,
      flex: 1,
    },
    {
      field: "spesifikasjonsType",
      headerName: "Navn",
      editable: true,
      flex: 1,
    },
    {
      field: "argang",
      headerName: "Årgang",
      editable: true,
      flex: 1,
    },
  ];

  const newItemTemplateObject = {
    spesifikasjonsId: "",
    spesifikasjonsType: "",
    argang: "",
  };

  const updateData = (key: string, value: string | ISpesificationTableRow[]) => {
    const updated = R.assocPath(key.split("."), value, editData);
    setEditData({
      ...editData,
      ...updated,
    });
  };

  const updateAndreTekniskeSpesifikasjoner = (viewTableData: ISpecification[]): void => {
    const convertDataToISpesificationTableRows: ISpesificationTableRow[] = viewTableData?.map((data) => {
      return {
        spesifikasjonsId: data.spesifikasjonsId,
        spesifikasjonsType: data.spesifikasjonsType,
        argang: data.argang,
      };
    });
    updateData("andreTekniskeSpesifikasjoner", convertDataToISpesificationTableRows);
  };

  return (
    <div>
      {editData != null && (
        <>
          <h3>Opplysninger om prosjekterende</h3>
          <table className="reportTable">
            <tbody>
              <tr>
                <td>1.1 </td>
                <td>Prosjekterende: </td>
                <td>
                  <McInput
                    value={editData.prosjekterende?.navn}
                    onChange={(e) => updateData("prosjekterende.navn", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td>1.2 </td>
                <td>Kontaktperson: </td>
                <td>
                  <McInput
                    value={editData.prosjekterende?.kontaktPerson}
                    onChange={(e) => updateData("prosjekterende.kontaktPerson", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td>1.3 </td>
                <td>Adresse: </td>
                <td>
                  <McInput
                    value={editData.prosjekterende?.adresse}
                    onChange={(e) => updateData("prosjekterende.adresse", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td>1.4 </td>
                <td>Postnummer: </td>
                <td>
                  <McInput
                    value={editData.prosjekterende?.postNr}
                    onChange={(e) => updateData("prosjekterende.postNr", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td>1.5 </td>
                <td>Sted: </td>
                <td>
                  <McInput
                    value={editData.prosjekterende?.sted}
                    onChange={(e) => updateData("prosjekterende.sted", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td>1.6 </td>
                <td>Telefon: </td>
                <td>
                  <McInput
                    value={editData.prosjekterende?.telefon}
                    onChange={(e) => updateData("prosjekterende.telefon", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td>1.7 </td>
                <td>E-post: </td>
                <td>
                  <McInput
                    value={editData.prosjekterende?.email}
                    onChange={(e) => updateData("prosjekterende.email", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td>1.8 </td>
                <td>Oppdragsnummer: </td>
                <td>
                  <McInput
                    value={editData.generellInfo?.oppdragsNummer}
                    onChange={(e) => updateData("prosjekterende.oppdragsNummer", e.target.value)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <hr />
          <h3>Opplysninger om oppdragsgiver/kunde</h3>
          <table className="reportTable">
            <tbody>
              <tr>
                <td>2.1 </td>
                <td>Oppdragsgiver: </td>
                <td>
                  <McInput
                    value={editData.generellInfo?.oppdragsGiver?.navn}
                    onChange={(e) => updateData("generellInfo.oppdragsGiver.navn", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td>2.2 </td>
                <td>Kontaktperson: </td>
                <td>
                  <McInput
                    value={editData.generellInfo?.oppdragsGiver?.kontaktPerson}
                    onChange={(e) => updateData("generellInfo.oppdragsGiver.kontaktPerson", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td>2.3 </td>
                <td>Adresse: </td>
                <td>
                  <McInput
                    value={editData.generellInfo?.oppdragsGiver?.adresse}
                    onChange={(e) => updateData("generellInfo.oppdragsGiver.adresse", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td>2.4 </td>
                <td>Postnummer: </td>
                <td>
                  <McInput
                    value={editData.generellInfo?.oppdragsGiver?.postNr}
                    onChange={(e) => updateData("generellInfo.oppdragsGiver.postNr", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td>2.5 </td>
                <td>Sted: </td>
                <td>
                  <McInput
                    value={editData.generellInfo?.oppdragsGiver?.sted}
                    onChange={(e) => updateData("generellInfo.oppdragsGiver.sted", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td>2.6 </td>
                <td>Telefon: </td>
                <td>
                  <McInput
                    value={editData.generellInfo?.oppdragsGiver?.telefon}
                    onChange={(e) => updateData("generellInfo.oppdragsGiver.telefon", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td>2.7 </td>
                <td>E-post: </td>
                <td>
                  <McInput
                    value={editData.generellInfo?.oppdragsGiver?.email}
                    onChange={(e) => updateData("generellInfo.oppdragsGiver.email", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td>2.8 </td>
                <td>Kundenummer/prosjektnummer: </td>
                <td>
                  <McInput
                    value={editData.generellInfo?.oppdragsGiver?.kundenummerEllerProsjektnummer}
                    onChange={(e) =>
                      updateData("generellInfo.oppdragsGiver.kundenummerEllerProsjektnummer", e.target.value)
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <hr />
          <h3>Opplysninger om anlegget/prosjektet</h3>
          <table className="reportTable">
            <tbody>
              <tr>
                <td>3.1 </td>
                <td>Anleggsnavn/prosjektnavn: </td>
                <td>
                  <McInput
                    value={editData.anleggEllerprosjekt?.anleggsnavnEllerProsjektnavn}
                    onChange={(e) => updateData("anleggEllerprosjekt.anleggsnavnEllerProsjektnavn", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td>3.2 </td>
                <td>Adresse: </td>
                <td>
                  <McInput
                    value={editData.anleggEllerprosjekt?.adresse}
                    onChange={(e) => updateData("anleggEllerprosjekt.adresse", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td>3.3 </td>
                <td>Postnummer: </td>
                <td>
                  <McInput
                    value={editData.anleggEllerprosjekt?.postNr}
                    onChange={(e) => updateData("anleggEllerprosjekt.postNr", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td>3.4 </td>
                <td>Sted: </td>
                <td>
                  <McInput
                    value={editData.anleggEllerprosjekt?.sted}
                    onChange={(e) => updateData("anleggEllerprosjekt.sted", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td>3.5 </td>
                <td>SSB-nr.: </td>
                <td>
                  <McInput
                    value={editData.anleggEllerprosjekt?.SSBnr}
                    onChange={(e) => updateData("anleggEllerprosjekt.SSBnr", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td>3.6 </td>
                <td>Type anlegg: </td>
                <td>
                  <McInput
                    value={editData.anleggEllerprosjekt?.typeAnlegg}
                    onChange={(e) => updateData("anleggEllerprosjekt.typeAnlegg", e.target.value)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <hr />
          <div>
            <h3>Beskrivelse av fravik fra anvendte normer</h3>
            <McInput
              value={editData.fravikFraAnvendteNormer}
              onChange={(e) => updateData("fravikFraAnvendteNormer", e.target.value)}
              type="textarea"
              dynamicHeight={true}
            />
          </div>
          <hr />
          <h3>Andre tekniske spesifikasjoner</h3>
          <ViewEditTable
            data={editData.andreTekniskeSpesifikasjoner}
            columns={columns}
            onSave={updateAndreTekniskeSpesifikasjoner}
            newItemTemplateObject={newItemTemplateObject}
            canEdit={true}
          />
          <hr />
          <div>
            <h3>Beskrivelse av oppdraget</h3>
            <McInput
              value={editData.beskrivelseAvOppdrag}
              onChange={(e) => updateData("beskrivelseAvOppdrag", e.target.value)}
              type="textarea"
              dynamicHeight={true}
            />
          </div>
          <hr />
          <h3>Underlagsdokumentasjon (FEL §12)</h3>
          <p>
            Den dokumentasjonen som er benyttet som underlag for samsvarerklæringen skal oppgis. Eksempel på slik
            underlagsdokumentasjon er listet opp under:
          </p>
          <table className="reportTable">
            <tbody>
              <tr>
                <td>8.1 </td>
                <td>Dokumentplan: </td>
                <td>
                  <McInput
                    value={editData.underlagsdokumentasjon?.dokumentplan}
                    onChange={(e) => updateData("underlagsdokumentasjon.dokumentplan", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td>8.2 </td>
                <td>Fordelingsskjema: </td>
                <td>
                  <McInput
                    value={editData.underlagsdokumentasjon?.fordelingsskjema}
                    onChange={(e) => updateData("underlagsdokumentasjon.fordelingsskjema", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td>8.3 </td>
                <td>Beskrivelser: </td>
                <td>
                  <McInput
                    value={editData.underlagsdokumentasjon?.beskrivelser}
                    onChange={(e) => updateData("underlagsdokumentasjon.beskrivelser", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td>8.4 </td>
                <td>Resultat fra brukermøter: </td>
                <td>
                  <McInput
                    value={editData.underlagsdokumentasjon?.resultatFraBrukermoeter}
                    onChange={(e) => updateData("underlagsdokumentasjon.resultatFraBrukermøter", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td>8.5 </td>
                <td>Elektrotekniske beregninger: </td>
                <td>
                  <McInput
                    value={editData.underlagsdokumentasjon?.elektrotekniskeBeregninger}
                    onChange={(e) => updateData("underlagsdokumentasjon.elektrotekniskeBeregninger", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td>8.6 </td>
                <td>Risikovurdering: </td>
                <td>
                  <McInput
                    value={editData.underlagsdokumentasjon?.risikoburdering}
                    onChange={(e) => updateData("underlagsdokumentasjon.risikoburdering", e.target.value)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default RapportSamsvarserklaring;
