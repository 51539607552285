import { useEffect, useMemo, useState } from "react";
import { FlatHierarchy } from "../../../../types/global.types";
import { HierarchyNode } from "../../app/hierarchyNodeUtils";
import { VSpace } from "../index";
import "./HierarchyTreeSelector.css";

interface IHierarchyEditor<T, K, L> {
  nodeList?: FlatHierarchy<T>;
  selectedNodeIds?: string[];
  onSelectionChange?: (node: HierarchyNode<T>[]) => void;
  idProp: K;
  titleProp: L;
  isVisible?: boolean;
  barLabel?: string;
  hideLabelBar?: boolean;
  isCollapsed?: boolean;
  multiSelect?: boolean;
  localStorageCollapseKey?: string;
}

function HierarchyTreeSelector<T, K extends Extract<keyof T, string>, L extends Extract<keyof T, string>>({
  nodeList,
  selectedNodeIds,
  onSelectionChange,
  className,
  idProp,
  titleProp,
  isVisible = true,
  style,
  barLabel = "PNS",
  hideLabelBar = false,
  isCollapsed = false,
  multiSelect = false,
  localStorageCollapseKey = null,
  ...props
}: React.HtmlHTMLAttributes<HTMLDivElement> & IHierarchyEditor<T, K, L>) {
  const [collapsed, setCollapsed] = useState(() => {
    if (localStorageCollapseKey != null) {
      const storedValue = localStorage.getItem(localStorageCollapseKey);
      return storedValue === "true";
    }
    return isCollapsed;
  });

  useEffect(() => {
    if (localStorageCollapseKey != null) {
      localStorage.setItem(localStorageCollapseKey, collapsed.toString());
    }
  }, [collapsed, localStorageCollapseKey]);

  const rootNode = useMemo(() => HierarchyNode.createFromFlatList(nodeList ?? []), [nodeList]);
  const [selected, setSelected] = useState<HierarchyNode<T>[]>([]);

  useEffect(() => {
    const selectedNodes = [];

    for (const node of rootNode.forEach()) {
      if (selectedNodeIds?.some((id) => id === node.id)) {
        selectedNodes.push(node);
      }
    }

    setSelected(selectedNodes);
  }, [selectedNodeIds]);

  const nodeSelected = (node: HierarchyNode<T>) => {
    const toggleNodeIndex = selected.findIndex((n) => n.id === node.id);
    let selectedNodes = [...selected];

    if (multiSelect === true) {
      if (toggleNodeIndex === -1) {
        // Not found. Add to selected
        selectedNodes.push(node);
      } else {
        // Found. Remove from selected
        selectedNodes.splice(toggleNodeIndex, 1);
      }
    } else {
      if (toggleNodeIndex === -1) {
        // Not found. Return current
        selectedNodes = [node];
      } else {
        // Found. return empty
        selectedNodes = [];
      }
    }

    setSelected(selectedNodes);
    onSelectionChange && onSelectionChange(selectedNodes);
  };

  return (
    <div className="d-flex h-100">
      {collapsed === false && (
        <div
          style={{
            display: isVisible === true ? "block" : "none",
          }}
          {...props}
        >
          <TreeNode
            style={style}
            className={className}
            isExpanded
            node={rootNode}
            onNodeSelect={(n) => nodeSelected(n)}
            selectedNodeIds={selected.map((n) => n.id)}
            id={idProp}
            title={titleProp}
          />
        </div>
      )}
      {hideLabelBar === false && (
        <div
          className="h-100 mc-gray-200"
          style={{ width: "30px", cursor: "pointer" }}
          onClick={() => setCollapsed(!collapsed)}
        >
          <div className="d-flex h-100 justify-content-center align-items-center">
            <div>
              <i
                style={{ transform: "rotate(90deg)", color: selectedNodeIds?.length === 0 ? "" : "#f8992e" }}
                className="ml-2 fa fa-filter"
              />
              <VSpace />
              <h5 style={{ transform: "rotate(90deg)" }}>{barLabel}</h5>
              <div className={`ml-2 fa fa-chevron-${collapsed === true ? "right" : "left"}`} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function TreeNode<T>({
  node,
  isExpanded,
  onNodeSelect,
  selectedNodeIds,
  id,
  title,
  className,
  style,
  multiSelect = false,
}: {
  node: HierarchyNode<T>;
  isExpanded?: boolean;
  onNodeSelect: (node: HierarchyNode<T>) => void;
  selectedNodeIds: string[];
  id: string;
  title: string;
  className?: string;
  style?: React.CSSProperties;
  multiSelect?: boolean;
}) {
  const selected = useMemo(() => selectedNodeIds?.some((id) => id === node.id), [selectedNodeIds, node.id]);
  const [expanded, setExpanded] = useState<boolean>(isExpanded === true);
  const hasChildren = node?.children != null && node?.children?.length > 0;

  useEffect(() => {
    const checkIfShouldExpand = (node: HierarchyNode<T>) => {
      if (selected === true) {
        return true;
      }

      if (node.children) {
        return node.children.some(checkIfShouldExpand);
      }

      return false;
    };

    if (selectedNodeIds != null && checkIfShouldExpand(node)) {
      setExpanded(true);
    }
  }, [selectedNodeIds, node]);

  const toggleExpanded = () => {
    if (hasChildren === true) {
      setExpanded(expanded === false);
    }
  };

  return (
    <div
      className={`node-container ${node.parent == null && className != null ? className : ""}`}
      style={node.parent == null ? style : undefined}
    >
      <li className={`tree-node ${hasChildren ? "" : " no-children"} `}>
        <div
          className={`d-flex align-items-center ${hasChildren ? "has-children" : ""} title-container ${
            expanded ? "is-expanded" : ""
          } ${selected ? " selected" : ""}`}
          style={{ backgroundColor: selected ? "#ff9a28" : "" }}
        >
          <div
            onClick={hasChildren === true ? toggleExpanded : undefined}
            className={`fa tree-expand-icon ${expanded ? "fa-minus" : "fa-plus"}`}
            style={{
              margin: "0 4px 0 8px ",
              display: hasChildren === true ? "grid" : "none",
            }}
          />
          <div className={`tree-node-row flex-grow-1`} onClick={() => onNodeSelect(node)}>
            <div className={`node-id ${selected ? "selected" : ""}`}>{node?.props[id]}</div>
            <div className={`node-title ${selected ? "selected" : ""}`}>{node?.props[title]}</div>
          </div>
        </div>

        {expanded === true && hasChildren === true && (
          <ul>
            {node?.children.map((childNode) => (
              <div key={childNode.id}>
                <TreeNode
                  node={childNode}
                  selectedNodeIds={selectedNodeIds}
                  onNodeSelect={onNodeSelect}
                  id={id}
                  title={title}
                />
              </div>
            ))}
          </ul>
        )}
      </li>
    </div>
  );
}

export default HierarchyTreeSelector;
