import { IGKLufthastighetskontrollLAFTakResultater, IGKRapport } from "mc-shared/zod/report/GK/gkReportSchema";
import React, { useEffect } from "react";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../../../types/global.types";
import { HSpace, McInput } from "../../../../../../../components";
import ReportDocxImageContainer from "../../../../ReportBoard/ReportDocxImageContainer";
import {
  beregnetGjennomsnittligHastighet,
  beregnetLufthastighetUniformitet,
  beregnetMinimumAntallRuterIRutenett,
  beregnetStandardAvvik,
  beregnetVarians,
} from "../../gkGlobalUtils";

const LufthastighetskontrollLAFTak: React.FC<{
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
  data: IGKLufthastighetskontrollLAFTakResultater;
}> = ({ data, onUpdateEditData }) => {
  useEffect(() => {
    maaleResultaterArray();
  }, [data.inndata.maaleomraadeAreal]);

  useEffect(() => {
    utdataBeregninger();
  }, [data.inndata.andelFrittAreal, data.inndata.maaleresultater, data.inndata.maaleresultater.length]);

  const maaleResultaterArray = () => {
    const arrayLength = beregnetMinimumAntallRuterIRutenett(data.inndata.maaleomraadeAreal);
    const newMaaleresultaterArray = Array.from({ length: arrayLength }, () => null);
    onUpdateEditData(
      "testResultater.lufthastighetskontrollLAFTak.inndata.maaleresultater",
      newMaaleresultaterArray as number[],
    );
  };

  const addNewRow = () => {
    const maaleresultaterArray = data.inndata.maaleresultater;
    maaleresultaterArray.push(null);

    onUpdateEditData("testResultater.lufthastighetskontrollLAFTak.inndata.maaleresultater", maaleresultaterArray);

    return maaleresultaterArray;
  };

  const removeLastRow = () => {
    const updatedMaaleresultater = data.inndata.maaleresultater;

    if (updatedMaaleresultater.length > 0) {
      updatedMaaleresultater.pop(); // Remove the last element
      onUpdateEditData("testResultater.lufthastighetskontrollLAFTak.inndata.maaleresultater", updatedMaaleresultater);
    }
  };

  const updateMaaleresultater = (resultat, i) => {
    const maaleresultater = [...data.inndata.maaleresultater];
    maaleresultater[i] = resultat;
    onUpdateEditData("testResultater.lufthastighetskontrollLAFTak.inndata.maaleresultater", maaleresultater);
  };

  const utdataBeregninger = () => {
    const minimumAntallRuterIRutenett = beregnetMinimumAntallRuterIRutenett(data.inndata.maaleomraadeAreal);
    const gjennomsnittligHastighet = beregnetGjennomsnittligHastighet(
      data.inndata.maaleresultater.map((resultat) => resultat),
      data.inndata.maaleresultater.length,
    );
    const varians = beregnetVarians(
      data.inndata.maaleresultater.map((resultat) => resultat),
      gjennomsnittligHastighet,
    );
    const standardAvvik = beregnetStandardAvvik(varians);
    const lufthastighetUniformitet = beregnetLufthastighetUniformitet(gjennomsnittligHastighet, standardAvvik);
    const utdata = {
      minimumAntallRuterIRutenett: minimumAntallRuterIRutenett,
      gjennomsnittligHastighet: gjennomsnittligHastighet,
      varians: varians,
      standardAvvik: standardAvvik,
      lufthastighetUniformitet: lufthastighetUniformitet,
    };
    onUpdateEditData("testResultater.lufthastighetskontrollLAFTak.utdata", utdata);
  };

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Lufthastighetsmåling under filter</CardTitle>
        <div className="d-flex flex-column mt-0">
          <b>
            Fyll inn areal av måleområde &#40;m<sup>2</sup>&#41;:
          </b>
          <McInput
            value={data.inndata.maaleomraadeAreal}
            normalizeNumbers={true}
            type="text"
            onBlur={(e) =>
              onUpdateEditData(
                "testResultater.lufthastighetskontrollLAFTak.inndata.maaleomraadeAreal",
                Number(e.target.value),
              )
            }
            className="w-25"
            maxLength={4}
          />
        </div>
        <div className="d-flex justify-content-between">
          <p className="mb-0">
            Minimum antall ruter i rutenett ={" "}
            <b>{beregnetMinimumAntallRuterIRutenett(data.inndata.maaleomraadeAreal) ?? ""}</b>
          </p>
          <p className="mb-0">
            Formel: &#40;N = &#40;10 * A&#41;<sup>0,5</sup>&#41;
          </p>
        </div>
        <hr className="mt-0" />
        <div className="mt-4">
          <b>Legg til flere punkter ved behov:</b>
          <div className="d-flex">
            <Button color="success" onClick={addNewRow}>
              Legg til rad
            </Button>
            <HSpace />
            <Button
              color="danger"
              onClick={removeLastRow}
              disabled={data.inndata.maaleresultater.length <= data.utdata.minimumAntallRuterIRutenett}
            >
              Fjern siste rad
            </Button>
          </div>
        </div>
        {data.inndata.maaleresultater.length > 0 && (
          <>
            <div className="mt-4 d-flex flex-column">
              <b>Fyll inn måleresultater &#40;m/s&#41;:</b>
              {data.inndata.maaleresultater.map((a, i) => (
                <McInput
                  className="mb-1 w-25"
                  key={i}
                  value={a}
                  normalizeNumbers={true}
                  type="text"
                  onBlur={(e) => updateMaaleresultater(Number(e.target.value), i)}
                />
              ))}
            </div>
            <div className="d-flex justify-content-between">
              <p className="mb-0">
                Gjennomsnittlig hastighet &#40;m/s&#41; =
                <b>
                  {" "}
                  {beregnetGjennomsnittligHastighet(
                    data.inndata.maaleresultater.map((resultat) => resultat),
                    data.inndata.maaleresultater.length,
                  )?.toFixed(3) ?? ""}
                </b>
              </p>
              <p className="mb-0">Formel: &#40;Va = &#40;SUM Vn&#41; / N&#41;</p>
            </div>
            <hr className="mt-0" />
            <div className="d-flex justify-content-between">
              <p className="mb-0">
                Varians &#40;-&#41; =
                <b>
                  {" "}
                  {beregnetVarians(
                    data.inndata.maaleresultater.map((resultat) => resultat),
                    data.utdata.gjennomsnittligHastighet,
                  )?.toFixed(4) ?? ""}
                </b>
              </p>
              <p className="mb-0">
                Formel: &#40;VRS = &#40;&#40;SUM Vn<sup>2</sup>&#41; - N * &#40;Va<sup>2</sup>&#41;&#41; / &#40;N -
                1&#41;&#41;
              </p>
            </div>
            <hr className="mt-0" />
            <div className="d-flex justify-content-between">
              <p className="mb-0">
                Standardavvik &#40;-&#41; =<b> {beregnetStandardAvvik(data.utdata.varians)?.toFixed(4) ?? ""}</b>
              </p>
              <p className="mb-0">
                Formel: &#40;Sigma = VRS<sup>0,5</sup>&#41;
              </p>
            </div>
            <hr className="mt-0" />
            <div className="d-flex justify-content-between">
              <p className="mb-0">
                Lufthastighetens uniformitet &#40;-&#41; ={" "}
                <b>
                  {beregnetLufthastighetUniformitet(
                    data.utdata.gjennomsnittligHastighet,
                    data.utdata.standardAvvik,
                  )?.toFixed(2) ?? ""}
                </b>
              </p>
              <p className="mb-0">Formel: &#40;Uv = &#40;1 - &#40;Sigma / Va&#41;&#41; * 100&#41;</p>
            </div>
            <hr className="mt-0" />
          </>
        )}
        <div className="w-50 mt-4">
          <div>
            <ReportDocxImageContainer docxImageTag="lufthastighetskontrollLAFTak" title="Målepunkter i rommet" />
          </div>
          <b>Kommentar:</b>
          <McInput
            value={data.inndata.kommentar}
            onChange={(e) =>
              onUpdateEditData("testResultater.lufthastighetskontrollLAFTak.inndata.kommentar", e.target.value)
            }
            type="textarea"
            dynamicHeight={true}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default LufthastighetskontrollLAFTak;
