import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ZodIssue } from "zod/lib/ZodError";

export const ZodErrorModal: React.FC<{
  errors: ZodIssue[];
  onAbort: () => void;
}> = ({ errors, onAbort }) => {
  return (
    <Modal isOpen toggle={onAbort}>
      <ModalHeader toggle={onAbort}>Feil</ModalHeader>
      <ModalBody>
        <ZodErrorTable errors={errors} />
      </ModalBody>
      <ModalFooter>
        <Button onClick={onAbort}>Lukke</Button>
      </ModalFooter>
    </Modal>
  );
};

export const ZodErrorTable: React.FC<{ errors: ZodIssue[] }> = ({ errors }) => {
  const capitalizeFirstLetter = (str: string) => {
    if (typeof str === "string") {
      return str?.charAt(0).toUpperCase() + str?.slice(1);
    }
  };

  const columns = [
    {
      dataField: "path",
      text: "Hvor er det feil?",
      formatter: (cell, row) => {
        return cell.length > 0
          ? capitalizeFirstLetter(
              typeof cell[cell.length - 1] === "number" ? cell[cell.length - 2] : cell[cell.length - 1],
            )
          : "";
      },
    },
    {
      dataField: "message",
      text: "Feilmelding",
    },
  ];

  return (
    <>
      <BootstrapTable
        classes="table-sm"
        bootstrap4
        keyField="path"
        hover
        striped
        data={errors}
        columns={columns}
        bordered={false}
      />
    </>
  );
};
