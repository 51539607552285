import React from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { IColumnTemplate } from "../../../../../types/global.types";

export const ColumnTemplateSelector: React.FC<{
  columnsToShowTemplates: IColumnTemplate[];
  setColumnsByTemplate: (template: IColumnTemplate) => void;
  goToSettings: any;
}> = ({ columnsToShowTemplates, setColumnsByTemplate, goToSettings }) => {
  return (
    <UncontrolledDropdown>
      <DropdownToggle style={{ width: "132px" }} caret>
        Maler
      </DropdownToggle>
      <DropdownMenu>
        {columnsToShowTemplates.map((template) => (
          <DropdownItem onClick={() => setColumnsByTemplate(template)} key={template.name}>
            {template.name}
          </DropdownItem>
        ))}
        {columnsToShowTemplates && columnsToShowTemplates.length > 0 && <DropdownItem divider />}
        <DropdownItem onClick={() => goToSettings()}>
          <i className="fa fa-cog fa-fw" />
          Legg til
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
