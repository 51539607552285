export const MEETING_DOCX_DEMO_DATA = {
  project: {
    _id: "5f6085966d375136b9835f7a",
    maconomy: {
      projectNo: 10216575,
      name: "Nye Rikshospitalet",
    },
    hasLogoType: true,
  },
  generatedAt: "2024.06.24",
  meeting: {
    name: "Møteserie PG tverrfaglig ledermøte - 2024.06.22",
    dateOfMeeting: "2024.06.22",
    timeStart: "09:00",
    participants: [
      {
        user: {
          _id: "5c88d20edbb10a37c75aa7ae",
          name: "Per Ström",
        },
        isPresent: "X",
        _id: "66630e1c21cd00eaf71359b5",
        discipline: "Annet",
        isCopy: "",
      },
    ],
    agendaItems: [
      {
        id: 1,
        name: "en tittel",
        description: "en beskrivelse",
        conclusion: "en konklusjon\n",
        updatedAt: "2024-06-24T13:21:29.547Z",
        isDeleted: false,
        notRelevant: false,
        comments: [],
        connectedIssues: [
          {
            id: 23731,
            title: "Endre ID på Utsparinger FRA Mark TIL RevitID",
            description:
              "Autogenerert MARK verdi i Revit er et dårlig alternativ til ID da denne fort får duplikater. Revit ID eksporteres uansett og kan finnes under BATID i Solibri uten ekstra prosesser.  Foretrukket forholder vi oss til BATID, Alternativt overføre revitid til MARK, Dette virker helt meningsløst.\n\nKan vi bli enige om å kun forholde oss til REVITID/BATID?",
            assignedTo: "Olga Budarina",
            status: "Fullført",
            conclusion:
              "Endret til Revit id, prosessbeskrivelse og ifc eksport oppdatert. Bør vurderes og ta bort dette fra ifc NRH-hull pset da det uansett kommer som BATID. ",
            taskOrIssue: "ISSUE",
            dates: {
              due: "2023.12.19",
            },
          },
          {
            id: 42289,
            title: "en aksjon",
            description: "",
            assignedTo: "Ahmed Dadi",
            status: "Opprettet",
            conclusion: "",
            taskOrIssue: "TASK",
            dates: {
              due: "2024.06.27",
            },
          },
        ],
        order: 1,
        images: [],
      },
    ],
    owner: {
      _id: "5c88d20edbb10a37c75aa7ae",
      name: "Per Ström",
    },
    location: "",
    referent: "",
    meetingSerie: {
      _id: "608bd07232336d37177b29ca",
      name: "PG tverrfaglig ledermøte",
      description:
        "Møtereferater arkiveres på: https://livelink.multiconsult.no/ll/livelink.exe?func=ll&objId=78055072&objAction=browse&viewType=1",
      seriesEnd: "2024-12-28T00:00:00.000Z",
      seriesStart: "2023-01-02T00:00:00.000Z",
      hourStart: 9,
      hourEnd: 11,
      minuteStart: 30,
      minuteEnd: 30,
      interval: "1",
      color: "brown",
      createdBy: "5f4f96404511102e06f64fa8",
      project: "5f6085966d375136b9835f7a",
      updatedAt: "2024-06-07T13:41:48.680Z",
      createdAt: "2021-04-30T09:40:02.441Z",
      __v: 3,
      tagsToFilter: ["PG ledermøte"],
      status: "ACTIVE",
      connectedMeetingInstances: [
        {
          _id: "66630e1c21cd00eaf71359b4",
          name: "Møteserie PG tverrfaglig ledermøte - 2024.06.22",
          dateOfMeeting: "2024-06-22T00:00:00.000Z",
          isDeleted: false,
        },
      ],
      timeEnd: "11:00",
      timeStart: "09:00",
    },
    description: "",
    dates: {
      start: "2024.06.22",
      nextMeeting: "",
    },
  },
};
