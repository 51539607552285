import React from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

import { IValueLabel } from "../../../../types/global.types";

const ListWithArrowItems: React.FC<{ listArrowItems: IValueLabel[] }> = ({ listArrowItems }) => {
  return (
    <>
      <Breadcrumb className="p-0 m-0">
        {listArrowItems?.map((item, index) => (
          <BreadcrumbItem
            key={index}
            active={index + 1 === listArrowItems.length}
            className="text-truncate"
            style={{ maxWidth: "300px" }}
          >
            {index + 1 !== listArrowItems.length ? <a href={`#${item.value}`}>{item.label}</a> : item.label}
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
    </>
  );
};

export default ListWithArrowItems;
