import React, { useState } from "react";
import { Button } from "reactstrap";
import { IPNSProps } from "../../../../types/global.types";
import { HierarchyNode } from "../../app/hierarchyNodeUtils";
import { emptyValidator, pnsParentValidator } from "../ExcelImportValidator/ExcelImportValidator.utils";
import ExcelImportValidatorWithSpecTable from "../ExcelImportValidator/ExcelImportValidatorWithSpecTable";
import { createTree } from "./PNSImportUtils";

const PNSExcelImportWithSpecTable: React.FC<{
  onValidated: (pns: HierarchyNode<IPNSProps>) => void;
  onReset: () => void;
  hasImportedData?: boolean;
}> = ({ onValidated, onReset, hasImportedData }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <>
      {hasImportedData === true ? (
        <>
          <Button color="primary" onClick={onReset}>
            Nullstill
          </Button>
        </>
      ) : (
        // <PNSExcelImport
        //   onValidated={(res) => {
        //     onValidated(res);
        //     setModalOpen(false);
        //   }}
        //   onReset={onReset}
        // />
        <ExcelImportValidatorWithSpecTable
          onValidated={(res) => {
            const tree = createTree(res, 0, 1);
            onValidated(tree);
            setModalOpen(false);
          }}
          validators={[pnsParentValidator, emptyValidator]}
          sortColumn={0}
          specificationTable={{
            columns: [
              {
                dataField: "pnsId",
                text: "PNS Id",
              },
              {
                dataField: "title",
                text: "Tittel",
              },
            ],
            data: [
              {
                pnsId: "1",
                title: "PNRH",
              },
              {
                pnsId: "1.1",
                title: "Bygg J",
              },
              {
                pnsId: "1.1.1",
                title: "J1",
              },
              {
                pnsId: "1.2",
                title: "Bygg MNO",
              },
              {
                pnsId: "1.2.1",
                title: "Bygg M",
              },
            ],
          }}
        />
      )}

      {modalOpen === true && <></>}
    </>
  );
};

export default PNSExcelImportWithSpecTable;
