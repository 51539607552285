import Axios from "axios";
import { IBatchjob } from "../../../../../../../types/batchjob.types";

export const doUpdateBatchJobBim360ExportBim360Files = async (
  projectId: string,
  bim360RevitToIfcId: string,
  bim360RevitExportFiles: IBatchjob.IBim360RevitExportFile[],
) => {
  try {
    const resp = await Axios.put(`/api/project/${projectId}/bim360revittoifc/${bim360RevitToIfcId}/bim360files`, {
      bim360RevitExportFiles,
    });
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doUpdateBatchJobBim360ExportBim360RevitFileView = async (
  projectId: string,
  fileId: string,
  revitViewValue: string,
  bim360RevitToIfcId: string,
) => {
  try {
    const resp = await Axios.put(
      `/api/project/${projectId}/bim360revittoifc/${bim360RevitToIfcId}/bim360files/${fileId}`,
      {
        revitViewValue,
      },
    );
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};
