import { apiSlice } from "../../../../apiSlice";
import { INewsItem, IValueLabel } from "../../../../../../types/global.types";
import { IEditorMetadata } from "../../../../components/MultiComment/MultiComment";

interface IGetNewsParams {
  projectId: string;
  dashboardId: string;
}

export interface IUpdateNewsItem {
  projectId: string;
  newsItemId: string;
  newsItem: {
    dashboard: string;
    text: string;
    deleted: boolean;
    metadata: IEditorMetadata;
  };
}

export interface IPostNewsItem {
  projectId: string;
  newsItem: INewsItem;
}

export const newsRTK = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNews: builder.query<INewsItem[], IGetNewsParams>({
      query: (input) => ({
        url: `/project/${input.projectId}/projectdashboard/news/${input.dashboardId}`,
        method: "GET",
      }),
      providesTags: ["News"],
    }),
    searchNews: builder.query<INewsItem[], { projectId: string; layoutId: string; query: string; users: string[] }>({
      query: (input) => ({
        url: `/project/${input.projectId}/projectdashboard/news/search`,
        method: "POST",
        body: { query: input.query, dashboardId: input.layoutId, users: input.users },
      }),
      providesTags: ["News"],
    }),
    updateNewsItem: builder.mutation<INewsItem, IUpdateNewsItem>({
      query(input) {
        return {
          url: `/project/${input.projectId}/projectdashboard/news/${input.newsItemId}`,
          method: "PUT",
          body: { newsItem: input.newsItem },
        };
      },
      invalidatesTags: ["News"],
    }),
    addNewsItem: builder.mutation<INewsItem, IPostNewsItem>({
      query(input) {
        return {
          url: `/project/${input.projectId}/projectdashboard/news/`,
          method: "POST",
          body: { newsItem: input.newsItem },
        };
      },
      invalidatesTags: ["News"],
    }),
    getNewsUniqUsers: builder.query<IValueLabel[], IGetNewsParams>({
      query: (input) => ({
        url: `/project/${input.projectId}/projectdashboard/news/${input.dashboardId}/users`,
        method: "GET",
      }),
      providesTags: ["News"],
    }),
  }),
});

export const {
  useGetNewsQuery,
  useSearchNewsQuery,
  useUpdateNewsItemMutation,
  useAddNewsItemMutation,
  useGetNewsUniqUsersQuery,
} = newsRTK;
