import React, { useEffect, useState } from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { EditSaveAbort, HSpace } from "..";
import { IMultiOptionsColors } from "../../../../types/global.types";
import OptionBadge from "../OptionBadge";

const MultiOptionColorDropdownViewEdit: React.FC<{
  title: string;
  activeOptionKey: string;
  options: IMultiOptionsColors[];
  saveCb: (oldVal: string, newVal: string) => void;
}> = ({ title, activeOptionKey, options, saveCb }) => {
  const [editMode, setEditMode] = useState(false);
  const [editedState, setEditedOption] = useState<IMultiOptionsColors>(
    options.find((state) => state.key === activeOptionKey),
  );

  const editCb = (bool) => {
    setEditMode(bool);
  };

  const _save = () => {
    editCb(false);
    saveCb(activeOptionKey, editedState.key);
  };

  return (
    <div className="mb-2">
      <h5>{title}</h5>
      <div className="d-flex">
        <EditSaveAbort editMode={editMode} saveCb={_save} editModeCb={editCb} />
        <HSpace />
        {editMode === false && <OptionBadge activeOption={activeOptionKey} options={options} />}
        {editMode && (
          <Edit
            options={options}
            editedOptionKey={editedState != null ? editedState.key : null}
            setEditedOption={setEditedOption}
            editCb={editCb}
          />
        )}
      </div>
    </div>
  );
};

const Edit: React.FC<{
  editedOptionKey: string;
  options: IMultiOptionsColors[];
  setEditedOption: (option: IMultiOptionsColors) => void;
  editCb?: (enabled: boolean) => void;
}> = ({ editedOptionKey, options, setEditedOption, editCb }) => {
  const [state, setState] = useState(null);
  useEffect(() => {
    const _option = options.find((state) => state.key === editedOptionKey);
    setState(_option || { displayName: "-" });
  }, [editedOptionKey]);

  const handleEscape = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Escape") {
      e.stopPropagation();
      editCb(false);
    }
  };

  return (
    <>
      {state != null && (
        <UncontrolledDropdown onKeyUp={handleEscape}>
          <DropdownToggle style={{ minWidth: "100px", width: "100%" }} autoFocus caret>
            {state.displayName}
          </DropdownToggle>
          <DropdownMenu>
            {options.map((_option) => (
              <DropdownItem key={_option.key} onClick={() => setEditedOption(_option)}>
                {_option.displayName}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      )}
    </>
  );
};

export default MultiOptionColorDropdownViewEdit;
