import React from "react";

const CheckOrWarn: React.FC<{
  bool: boolean;
}> = ({ bool }) => {
  return bool === true ? (
    <i className="fa fa-check-circle fa-fw mc-green-600-text" />
  ) : (
    <i className="fa fa-warning fa-fw danger-text" />
  );
};

export default CheckOrWarn;
