import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { IRisiko } from "../../../../../types/global.types";
import { OptionBadge } from "../../../components/";
import { CellEditFormatter, headerSortAndFilterFormatter, sortCaret } from "../../../components/columnGlobal";
import { VIEW_EDIT_COMPS } from "../../globalConstants";
import { doGetRisikosStartinWithPns } from "../risiko/risikoAPI";
import { RISIKO_STATES } from "../risiko/risikoConstants";

const RisikoPnsTable: React.FC<{ pns: string; projectId: string }> = ({ pns, projectId }) => {
  const [risikos, setRisikos] = useState<IRisiko[]>();

  const miniColumns = [
    {
      dataField: "id",
      text: "Id",
      editable: true,
      classes: "editable-cell",
      filter: textFilter({ placeholder: "Tast inn..." }),
      section: "Generell",
      sort: true,
      sortCaret: sortCaret,
      headerFormatter: headerSortAndFilterFormatter,
      formatter: CellEditFormatter,
      headerSortingClasses: "sortedColumn",
      order: 1,
      viewEditComp: VIEW_EDIT_COMPS.TITLE_WITH_VALUE_EDIT,
      inputType: "text",
      headerStyle: () => {
        return { minWidth: "30px" };
      },
    },
    {
      dataField: "pnsCodes",
      text: "PNS",
      classes: "editable-cell",
      filter: textFilter({ placeholder: "Tast inn..." }),
      sort: true,
      sortCaret: sortCaret,
      headerFormatter: headerSortAndFilterFormatter,
      section: "Generell",
      headerStyle: () => {
        return { minWidth: "30px" };
      },
      headerSortingClasses: "sortedColumn",
      order: 5,
      viewEditComp: VIEW_EDIT_COMPS.PNS_EDITOR,
    },
    {
      dataField: "title",
      text: "Tittel",
      editable: false,
      classes: "editable-cell",
      filter: textFilter({ placeholder: "Tast inn..." }),
      sort: true,
      sortCaret: sortCaret,
      headerFormatter: headerSortAndFilterFormatter,
      section: "Risiko",
      headerSortingClasses: "sortedColumn",
      formatter: CellEditFormatter,
      order: 3,
      viewEditComp: VIEW_EDIT_COMPS.TITLE_WITH_VALUE_EDIT,
      inputType: "textarea",
    },
    {
      dataField: "state",
      text: "Status",
      editable: false,
      classes: "editable-cell",
      filter: textFilter({ placeholder: "Tast inn..." }),
      section: "Generell",
      sort: true,
      sortCaret: sortCaret,
      headerFormatter: headerSortAndFilterFormatter,
      formatter: (cell) => <OptionBadge activeOption={cell} options={RISIKO_STATES} />,
      headerSortingClasses: "sortedColumn",
      order: 1,
      viewEditComp: VIEW_EDIT_COMPS.TITLE_WITH_VALUE_EDIT,
      inputType: "text",
    },
  ];

  useEffect(() => {
    async function fetchData() {
      fetchIssuesStartinWithPns();
    }
    fetchData();
  }, []);

  const fetchIssuesStartinWithPns = async () => {
    if (pns == null || pns === "") {
      console.warn("PNS is null or empty");
      return;
    }

    const _riiskos = await doGetRisikosStartinWithPns(projectId, pns);
    setRisikos(_riiskos);
  };

  return (
    <>
      <h4>{`Risikoer med PNS som begynnner på ${pns}`}</h4>
      <div style={{ height: "65vh", overflow: "auto" }}>
        <BootstrapTable
          wrapperClasses="smalltable"
          bootstrap4
          keyField="_id"
          striped
          data={risikos || []}
          filter={filterFactory()}
          classes="table-sm table-responsive"
          bordered={false}
          columns={miniColumns}
        />
      </div>
    </>
  );
};

export default RisikoPnsTable;
