import React from "react";
import {
  IBootstrapTableColumnConfig,
  IDropdownFilterOption,
  ILabelAndIsActive,
  IMultipleFilterOptions,
} from "../../types";
import { Button, ButtonGroup, Card, CardBody, CardTitle } from "reactstrap";
import { CloseButton, ConfirmDialog, DropdownFilter, HSpace, McDropdown, McInput, VSpace } from "../index";
import SaveButton from "../SaveButton";
import MainFilterBar from "../MainFilterBar/MainFilterBar";
import * as R from "ramda";
import { IAllFilter } from "mc-shared/zod/commonSchema";
import { ISortColumn, ITableTemplate, ITableTemplateFilter } from "mc-shared/zod/issueSchema";
import { ISSUE_COLUMN_DATAFIELDS, ISSUE_COLUMN_LABELS } from "../../app/routes/issuesApp/issueConstants";
import { useSelector } from "react-redux";
import { selectSingleIssueBoard } from "../../app/routes/issuesApp/issueSlice";
import { useImmer } from "use-immer";

const TableTemplateEditMode: React.FC<{
  tableTemplate: ITableTemplate;
  allFilters: IAllFilter[];
  onSave: (tableTemplate: ITableTemplate) => void;
  onAbort: () => void;
  onDelete: () => void;
}> = ({ tableTemplate, allFilters, onSave, onAbort, onDelete }) => {
  const [copyOfTableTemplateFilters, setCopyOfTableTemplateFilters] = React.useState<ITableTemplateFilter[]>(null);
  const [editedTableColumns, setEditedTableColumns] = useImmer<string[]>([]);
  const [copyOfName, setCopyOfName] = React.useState<string>(null);
  const [selectedSortColumn, setSelectedSortColumn] = React.useState<ISortColumn>(tableTemplate.sortedCol);

  React.useEffect(() => {
    setCopyOfTableTemplateFilters([...tableTemplate.filters]);
    setCopyOfName(tableTemplate.name);
    setEditedTableColumns(tableTemplate.tableColumns);
  }, []);

  const updateMultiFilterValue = (allFilterDataField: string, filterValue: string) => {
    const clickedFilter = copyOfTableTemplateFilters.find(
      (item) => item.dataField === allFilterDataField.split(".")[0],
    );

    let _copyOfTableTemplateFilters = R.clone(copyOfTableTemplateFilters);
    if (clickedFilter == null) {
      _copyOfTableTemplateFilters.push({
        typeOfFilter: "MULTIFILTER",
        dataField: allFilterDataField,
        multiFilterValues: [filterValue],
      });
    } else {
      _copyOfTableTemplateFilters = _copyOfTableTemplateFilters.map((item) => {
        if (allFilterDataField.startsWith(item.dataField) === true) {
          const filterVal = item.multiFilterValues.find((_val) => _val === filterValue);
          if (filterVal == null) {
            item.multiFilterValues.push(filterValue);
          } else {
            item.multiFilterValues = item.multiFilterValues.filter((_val) => _val !== filterVal);
          }
          return item;
        } else {
          return item;
        }
      });
    }
    setCopyOfTableTemplateFilters(_copyOfTableTemplateFilters);
  };

  const updateBooleanFilter = (dataField: string) => {
    const clickedFilter = copyOfTableTemplateFilters.find((item) => item.dataField === dataField);
    let _copyOfTableTemplateFilters = R.clone(copyOfTableTemplateFilters);
    if (clickedFilter == null) {
      _copyOfTableTemplateFilters.push({
        typeOfFilter: "BOOLEANFILTER",
        dataField: dataField,
        isActive: true,
      });
    } else {
      _copyOfTableTemplateFilters = _copyOfTableTemplateFilters.map((item) => {
        if (item.dataField === dataField) {
          item.isActive = !item.isActive;
          return item;
        } else {
          return item;
        }
      });
    }
    setCopyOfTableTemplateFilters(_copyOfTableTemplateFilters);
  };

  const resetMultiFilter = (columnText: string) => {
    const dataField = ISSUE_COLUMN_DATAFIELDS[columnText];
    const filtertoReset = copyOfTableTemplateFilters.find((item) => item.dataField === dataField);
    let _copyOfTableTemplateFilters = [...copyOfTableTemplateFilters];
    _copyOfTableTemplateFilters.map((templateFilter) => {
      if (templateFilter === filtertoReset) {
        templateFilter.multiFilterValues = [];
      }
      return templateFilter;
    });
    setCopyOfTableTemplateFilters(_copyOfTableTemplateFilters);
  };

  const selectAllFilters = (columnText: string) => {
    const dataField = ISSUE_COLUMN_DATAFIELDS[columnText];
    const filterToSetToAll = copyOfTableTemplateFilters.find((item) => item.dataField === dataField);
    const allFilter = allFilters.find((item) => item.dataField === dataField);
    let _copyOfTableTemplateFilters = [...copyOfTableTemplateFilters];
    _copyOfTableTemplateFilters.map((templateFilter) => {
      if (templateFilter === filterToSetToAll) {
        templateFilter.multiFilterValues = allFilter.options.map((opt) => opt.label);
      }
      return templateFilter;
    });
    setCopyOfTableTemplateFilters(_copyOfTableTemplateFilters);
  };

  const updateSelectedColumn = (dataField: string) => {
    setEditedTableColumns((draft) => {
      const colIsAlreadySelected = draft.find((col) => col === dataField) != null;
      if (colIsAlreadySelected === true) {
        return draft.filter((col) => col !== dataField);
      } else {
        draft.push(dataField);
        return draft;
      }
    });
  };

  /* const getColumns = (): IBootstrapTableColumnConfig[] => {
    return allColumns.filter((col) => {
      if (col.dataField === "taskOrIssue") {
        return true;
      }
      return col.alwaysHidden !== true;
    });
  };*/

  const clearAllFilters = () => {
    let _copyOfTableTemplateFilters = R.clone([...copyOfTableTemplateFilters]);
    _copyOfTableTemplateFilters.map((templateFilterItem) => {
      if (templateFilterItem.typeOfFilter === "MULTIFILTER") {
        templateFilterItem.multiFilterValues = [];
      }
      if (templateFilterItem.typeOfFilter === "BOOLEANFILTER") {
        templateFilterItem.isActive = false;
      }
      return templateFilterItem;
    });
    setCopyOfTableTemplateFilters(_copyOfTableTemplateFilters);
  };

  return (
    <Card>
      {editedTableColumns != null && copyOfName != null && copyOfTableTemplateFilters != null && (
        <CardBody>
          <CardTitle tag="h4">
            <McInput
              placeholder="Ange navn.."
              value={copyOfName}
              onBlur={(e) => setCopyOfName(e.target.value)}
              type="text"
            />
          </CardTitle>
          <EditFilter
            allFilters={allFilters}
            filtersInTableTemplates={copyOfTableTemplateFilters}
            updateMultiFilterValue={updateMultiFilterValue}
            updateBooleanFilter={updateBooleanFilter}
            resetFilter={resetMultiFilter}
            clearAllFilters={clearAllFilters}
            selectAllFilters={selectAllFilters}
          />
          <VSpace />
          <EditIssueTableColumns selectedColumns={editedTableColumns} onSelectColumn={updateSelectedColumn} />
          <VSpace />
          <EditSortColumns onSelectColumn={setSelectedSortColumn} selectedColumnSort={selectedSortColumn} />
          <hr />
          <div className="d-flex">
            <SaveButton
              onClick={() =>
                onSave({
                  _id: tableTemplate._id,
                  filters: copyOfTableTemplateFilters,
                  tableColumns: editedTableColumns,
                  name: copyOfName,
                  sortedCol: selectedSortColumn,
                })
              }
            />
            <HSpace />
            <CloseButton onClick={onAbort} />
            <div className="flex-fill" />
            <ConfirmDialog
              color="danger"
              title="Slette mal"
              message="Er du sikker på at du vil slette denne mal?"
              confirmCb={() => onDelete()}
            >
              <Button color="danger">
                <i className="fa fa-trash-o" />
              </Button>
            </ConfirmDialog>
          </div>
          <VSpace />
        </CardBody>
      )}
    </Card>
  );
};

export default TableTemplateEditMode;

const EditSortColumns: React.FC<{
  selectedColumnSort: ISortColumn;
  onSelectColumn: (sortColumn: ISortColumn) => void;
}> = ({ selectedColumnSort, onSelectColumn }) => {
  const selectedCol = ISSUE_COLUMN_LABELS[selectedColumnSort?.column];

  const options = Object.entries(ISSUE_COLUMN_LABELS).map(([dataField, text]) => {
    return { dataField, text };
  });

  return (
    <div>
      <h5>
        <i className="fa fa-sort fa-fw" />
        Sorter
      </h5>
      <div className="gray-200 p-2 d-flex ">
        <div style={{ width: "200px" }}>
          <McDropdown
            defaultTitle={selectedCol == null ? "Velg kolonne" : selectedCol.text}
            color="white"
            currentValue={selectedCol}
            options={options}
            displayKey="text"
            onChange={(oldValue, newValue) =>
              onSelectColumn({
                column: newValue?.dataField,
                direction: selectedColumnSort?.direction,
              })
            }
          />
        </div>
        <HSpace />
        <ButtonGroup>
          <Button
            color="white"
            active={selectedColumnSort?.direction === "ASC"}
            onClick={() =>
              onSelectColumn({
                column: selectedColumnSort?.column,
                direction: "ASC",
              })
            }
          >
            <i className="fa fa-sort-amount-asc fa-fw" />
            Stigende
          </Button>
          <Button
            color="white"
            active={selectedColumnSort?.direction === "DESC"}
            onClick={() =>
              onSelectColumn({
                column: selectedColumnSort?.column,
                direction: "DESC",
              })
            }
          >
            <i className="fa fa-sort-amount-desc fa-fw" />
            Synkende
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

const EditFilter: React.FC<{
  filtersInTableTemplates: ITableTemplateFilter[];
  allFilters: IAllFilter[];
  updateMultiFilterValue: (dataField: string, filterValue: string) => void;
  updateBooleanFilter: (dataField: string) => void;
  resetFilter: (dataField) => void;
  clearAllFilters: () => void;
  selectAllFilters: (dataField: string) => void;
}> = ({
  filtersInTableTemplates,
  allFilters,
  updateMultiFilterValue,
  updateBooleanFilter,
  resetFilter,
  clearAllFilters,
  selectAllFilters,
}) => {
  const allColumns = Object.entries(ISSUE_COLUMN_LABELS).map(([dataField, text]) => {
    return { dataField, text };
  });
  return (
    <div>
      <h5>
        <i className="fa fa-filter fa-fw" />
        Filter
      </h5>
      <MainFilterBar
        multipleFilterItems={convertAllFiltersToMultipleFilterItems(allFilters, filtersInTableTemplates)}
        updateMultiFilterItemOption={(colTitle, value) =>
          updateMultiFilterValue(allColumns.find((col) => col.text === colTitle).dataField, value)
        }
        booleanFilterItems={convertAllFiltersToBooleanFilterItems(allFilters, allColumns, filtersInTableTemplates)}
        updateBooleanFilterItem={(colTitle) =>
          updateBooleanFilter(allColumns.find((col) => col.text === colTitle).dataField)
        }
        resetMultiFilter={resetFilter}
        clearAllFilters={clearAllFilters}
        selectAllMultiFilter={selectAllFilters}
      />
    </div>
  );
};

const convertAllFiltersToBooleanFilterItems = (
  allFilters: IAllFilter[],
  allColumns: IBootstrapTableColumnConfig[],
  filtersInTableTemplates: ITableTemplateFilter[],
): ILabelAndIsActive[] => {
  return allFilters.reduce((acc, filterItem) => {
    if (filterItem.bool != null) {
      const col = allColumns?.find((_col) => _col.dataField.startsWith(filterItem.dataField) === true);
      if (col != null) {
        const filterInFilterTemplate = filtersInTableTemplates?.find(
          (tmpl) => tmpl.dataField.startsWith(filterItem.dataField) === true,
        );

        acc.push({
          label: col.text,
          isActive: filterInFilterTemplate?.isActive || false,
        });
      }
    }
    return acc;
  }, []);
};

const convertAllFiltersToMultipleFilterItems = (
  allFilters: IAllFilter[],
  filtersInTableTemplates: ITableTemplateFilter[],
): IMultipleFilterOptions[] => {
  return allFilters.reduce((acc, allFilterItem) => {
    if (allFilterItem.options != null) {
      const filterInFilterTemplate = filtersInTableTemplates?.find((tmpl) =>
        allFilterItem.dataField.startsWith(tmpl.dataField),
      );

      acc.push({
        title: ISSUE_COLUMN_LABELS[allFilterItem.dataField],
        options: allFilterItem.options.map((option) => {
          return {
            label: option.label,
            value: option.value,
            isActive:
              filterInFilterTemplate == null
                ? false
                : filterInFilterTemplate.multiFilterValues.find(
                    (valueInTmplFilter) => option.value === valueInTmplFilter,
                  ) != null,
          };
        }),
      });
    }
    return acc;
  }, []);
};

const EditIssueTableColumns: React.FC<{
  selectedColumns: string[];
  onSelectColumn: (dataField: string) => void;
}> = ({ selectedColumns, onSelectColumn }) => {
  const issueBoard = useSelector(selectSingleIssueBoard);

  const filterItems: IDropdownFilterOption[] = Object.entries(ISSUE_COLUMN_LABELS).map(([dataField, label]) => {
    return {
      label: label,
      value: dataField,
      isActive: selectedColumns.find((_col) => _col === dataField) != null,
    };
  });

  const otherAttributesFilterItems: IDropdownFilterOption[] = Object.keys(
    issueBoard?.otherAttributesConfig ?? {},
  ).reduce((acc, key) => {
    const otherAttributeConfig = issueBoard?.otherAttributesConfig[key];
    if (otherAttributeConfig != null) {
      acc.push({
        label: otherAttributeConfig.label,
        value: key,
        isActive: selectedColumns.find((_col) => _col === `otherAttributesData.${key}`) != null,
      });
    }
    return acc;
  }, []);

  const allFilterItems = [...filterItems, ...otherAttributesFilterItems].filter((item) => item.value !== "taskOrIssue");

  const _onSelectColumn = (dataField: string) => {
    //Check if column is capital letters (other attributes)
    if (dataField === dataField.toUpperCase()) {
      onSelectColumn(`otherAttributesData.${dataField}`);
    } else {
      onSelectColumn(dataField);
    }
  };
  return (
    <div>
      <h5>
        <i className="fa fa-columns fa-fw" />
        Tabellkolonner
      </h5>
      <div className="gray-200 p-2 d-flex ">
        <DropdownFilter
          filterOptions={allFilterItems}
          onClick={(title, value) => _onSelectColumn(value)}
          title="Velg kolonner"
        />
      </div>
    </div>
  );
};
