import classnames from "classnames";
import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import { IBoard } from "../../../../../types/global.types";
import { HSpace } from "../../../components";
import { AccessView } from "../../../components/AccessComp/AccessComp";
import IssueBoardSelector from "./IssueBoardSelector";
import { getIssueRegisterNameOption } from "./issueUtils";

const IssueTabs: React.FC<{
  activeTab: string;
  setActiveTab: any;
  activeBoard: IBoard;
}> = ({ activeTab, setActiveTab, activeBoard }) => {
  return (
    <div>
      <Nav tabs className="pt-2 px-2">
        <NavItem className="custom">
          <NavLink
            className={classnames({ active: activeTab === "issues", custom: true })}
            onClick={() => {
              setActiveTab("issues");
            }}
          >
            {getIssueRegisterNameOption(activeBoard?.issueNameSetting, "register")}
          </NavLink>
        </NavItem>
        <NavItem className="custom">
          <NavLink
            className={classnames({ active: activeTab === "settings", custom: true })}
            onClick={() => {
              setActiveTab("settings");
            }}
          >
            <i className="fa fa-cog fa-fw text-black-50" />
            INNSTILLINGER
          </NavLink>
        </NavItem>
        <div className="flex-fill" />
        <div className="mt-1">
          <AccessView access={activeBoard.access as any} />
        </div>
        <HSpace />
        <IssueBoardSelector />
      </Nav>
    </div>
  );
};

export default IssueTabs;
