import React from "react";
import { IBIM360 } from "../../../../../types/bim360.types";
import Bim360UsersTable from "../../../components/Bim360UsersTable/Bim360UsersTable";
import { doGetBim360OProjectUsers } from "../admin/restQueries";
import { doGetBim360Companies } from "../../Bim360API";
import FailedAlert from "../../../components/FailedAlert";
import { MCLoader } from "../../../components";

const Bim360Members: React.FC<{ bim360AccountId: string; bim360ProjectId: string; projectId: string }> = ({
  bim360AccountId,
  bim360ProjectId,
  projectId,
}) => {
  const [bim360Users, setBim360Users] = React.useState<IBIM360.User[]>();
  const [bim360Companies, setBim360Companies] = React.useState<IBIM360.Company[]>();
  const [failed, setFailed] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      const _bim360Users = await doGetBim360OProjectUsers(projectId, bim360ProjectId);
      setBim360Users(_bim360Users);
      const _bim360Companies = await doGetBim360Companies(projectId, bim360AccountId);
      setBim360Companies(_bim360Companies);
    } catch (error) {
      setFailed(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <MCLoader loading={loading} />
      {failed === true && <FailedAlert errorMessage="Failed to fetch bim360 data" />}
      {bim360Users != null && bim360Companies != null && (
        <Bim360UsersTable bim360Users={bim360Users} bim360Companies={bim360Companies} />
      )}
    </>
  );
};

export default Bim360Members;
