import React, { useState } from "react";
import { IBoard, IIssue } from "../../../../../../../types/global.types";
import { FailedAlert } from "../../../../../components";
import { ConnectedIssues } from "../../../../../components/ConnectedIssues/ConnectedIssues";
import CreateItemWithBoardSelectorModal from "../../../../../components/CreateItemWithBoardSelectorModal/CreateItemWithBoardSelectorModal";
import { MCApplicationsUrlEnum } from "../../../../../frontendConstants";
import { useAppSelector } from "../../../../../hooks";
import ConnectExistingIssueToItemModalWrapper from "../../../../ConnectExistingIssueToItemModalWrapper";
import { useGetIssueBoardsQuery } from "../../IssueBoardRTK";
import { useConnectIssueOrTaskFromOtherBoardsToIssueMutation, useCreateIssueMutation } from "../../issueRTK";
import { getIssueActionNameOption, getIssueRegisterNameOption } from "../../issueUtils";

const ConnectedTasksAndIssuesMiniTable: React.FC<{
  issue: IIssue;
  issueId: string;
  projectId: string;
  issueBoardId: string;
  fullWidth: boolean;
  issueBoard?: IBoard;
}> = ({ issue, issueId, projectId, issueBoardId, issueBoard }) => {
  const [newIssueModalOpen, setNewIssueModalOpen] = useState<boolean>(false);
  const [connectExistingModalOpen, setConnectExistingModalOpen] = useState<boolean>(false);

  const titleLabel = `Koblede ${getIssueRegisterNameOption(
    issueBoard?.issueNameSetting,
    "plural",
  ).toLowerCase()} og ${getIssueActionNameOption(
    issueBoard?.taskNameSetting,
    "plural",
  ).toLowerCase()} fra andre registre`;

  const [connectIssueOrTask, { isLoading, isSuccess, error: connectIssueOrTaskError }] =
    useConnectIssueOrTaskFromOtherBoardsToIssueMutation();

  const [addNewIssue, { isLoading: addNewIssueIsLoading, isSuccess: addNewIssueIsSuccess, error: addNewIssueError }] =
    useCreateIssueMutation();

  const { data: issueBoards = [], error: boardFetchError } = useGetIssueBoardsQuery({
    projectId: projectId,
  });

  const isError = connectIssueOrTaskError || addNewIssueError || boardFetchError;

  const apps = useAppSelector((state) => state.globalReducer.apps);

  const onConnect = (issueOrTaskId: string) => {
    connectIssueOrTask({
      projectId: projectId,
      issueId: issueId,
      issueOrTaskId: issueOrTaskId,
      connectOrDisconnect: "CONNECT",
    });
    setConnectExistingModalOpen(false);
  };

  const onCreateIssue = async (boardId: string) => {
    const createdIssue = await addNewIssue({
      projectId: projectId,
      issueBoardId: boardId,
      issueTitle: "",
      taskOrIssue: "ISSUE",
    });
    await connectIssueOrTask({
      projectId: projectId,
      issueId: issueId,
      issueOrTaskId: createdIssue?.data?._id,
      connectOrDisconnect: "CONNECT",
    });
    setNewIssueModalOpen(false);
  };

  return (
    <>
      {isError && <FailedAlert />}
      <div className="bg-white">
        {connectExistingModalOpen === true && (
          <ConnectExistingIssueToItemModalWrapper
            onConnected={(boardId, issueOrTaskId) => onConnect(issueOrTaskId)}
            projectId={projectId}
            closeModal={() => setConnectExistingModalOpen(false)}
            blockedIssueIds={issue?.connectedTasksAndIssuesFromOtherBoards?.map((issue) => issue._id) || []}
          />
        )}
        {newIssueModalOpen === true && (
          <CreateItemWithBoardSelectorModal
            boards={(issueBoards || []).map((board) => {
              return {
                project: board.project,
                name: board.name,
                _id: board._id,
                status: board.status,
              };
            })}
            onCreate={onCreateIssue}
            modalTitle="Koble sak til sjekklistepunkt"
            toggle={() => setNewIssueModalOpen(false)}
            currentApp={MCApplicationsUrlEnum.ISSUE}
            apps={apps}
            projectId={projectId}
            createButtonText="Opprett sak"
          />
        )}
        <ConnectedIssues
          issues={issue.connectedTasksAndIssuesFromOtherBoards as IIssue[]}
          disconnectIssue={(issueOrTaskId) =>
            connectIssueOrTask({
              projectId: projectId,
              issueId: issueId,
              issueOrTaskId: issueOrTaskId,
              connectOrDisconnect: "DISCONNECT",
            })
          }
          connectExistingIssue={() => setConnectExistingModalOpen(true)}
          createNewIssue={() => setNewIssueModalOpen(true)}
          isPrintMode={false}
          disableCellEdit={true}
          titleLabel={titleLabel}
          hideRegisterColumn={false}
          projectId={projectId}
        />
      </div>
    </>
  );
};

export default ConnectedTasksAndIssuesMiniTable;
