import React from "react";
import { Card, CardBody, CardTitle, FormGroup, Input, Label } from "reactstrap";
import { IRapportOptionSection } from "../../app/routes/report/brannrapport/types";

const ReportOptionSection: React.FC<{
  optionSection: IRapportOptionSection;
  sectionKey: string;
  setOption: (sectionKey: string, option: string) => void;
}> = ({ optionSection, sectionKey, setOption }) => {
  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-1" tag="h4">
          {optionSection.title}
        </CardTitle>
        {optionSection.instructions != null && <b>{optionSection.instructions}</b>}
        <FormGroup check>
          {Object.keys(optionSection.options).map((option) => {
            return (
              <div className="d-flex flex-column" key={option}>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={optionSection.options[option].isSelected}
                    onChange={() => setOption(sectionKey, option)}
                  />
                  {optionSection.options[option].name}
                </Label>
              </div>
            );
          })}
        </FormGroup>
      </CardBody>
    </Card>
  );
};

export default ReportOptionSection;
