import { ISJArapport } from "mc-shared/zod/report/Elektro/sjaSchema";
import React from "react";
import { Card, CardBody, CardTitle, UncontrolledTooltip } from "reactstrap";
import { McInput } from "../../../../../components";

const SJAGenerellInfo: React.FC<{
  editData: ISJArapport;
  updateData: (key: string, value: any) => void;
}> = ({ editData, updateData }) => {
  return (
    <Card className="w-50">
      <CardBody>
        <CardTitle tag="h4">Generelt</CardTitle>
        <table className="reportTable">
          <tbody>
            <tr>
              <td>SJA-tittel: </td>
              <td>
                <McInput
                  value={editData.generellInfo?.sjaTittel}
                  onChange={(e) => updateData("generellInfo.sjaTittel", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>Oppdragsnavn: </td>
              <td>
                <McInput
                  value={editData.generellInfo?.oppdragsNavn}
                  onChange={(e) => updateData("generellInfo.oppdragsNavn", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>Oppdragsnummer: </td>
              <td>
                <McInput
                  value={editData.generellInfo?.oppdragsNummer}
                  onChange={(e) => updateData("generellInfo.oppdragsNummer", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>Beskrivelse av arbeidet: </td>
              <td>
                <McInput
                  value={editData.generellInfo?.beskrivelseAvArbeidet}
                  onChange={(e) => updateData("generellInfo.beskrivelseAvArbeidet", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>Firma/avdeling/seksjon: </td>
              <td>
                <McInput
                  value={editData.generellInfo?.firmaAvdelingSeksjon}
                  onChange={(e) => updateData("generellInfo.firmaAvdelingSeksjon", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>
                <UncontrolledTooltip placement="top" target="assumptions">
                  Hvilke forutsetninger ligger til grunn for befaringen? Hva er det som skal sjekkes?
                </UncontrolledTooltip>
                <p className="mr-2 mb-0">
                  Forutsetninger:
                  <i id="assumptions" className="fa fa-info-circle fa-fw text-info" />
                </p>
              </td>
              <td>
                <McInput
                  value={editData.generellInfo?.forutsetninger}
                  onChange={(e) => updateData("generellInfo.forutsetninger", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>
                <UncontrolledTooltip placement="top" target="coordinator">
                  Hvem er ansvarlig for denne SJA-rapporten?
                </UncontrolledTooltip>
                <p className="mr-2 mb-0">
                  SJA-koordinator:
                  <i id="coordinator" className="fa fa-info-circle fa-fw text-info" />
                </p>
              </td>
              <td>
                <McInput
                  value={editData.generellInfo?.sjaKoordinator}
                  onChange={(e) => updateData("generellInfo.sjaKoordinator", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>
                <UncontrolledTooltip placement="top" target="operation">
                  Hva går jobben som skal befares ut på?
                </UncontrolledTooltip>
                <p className="mr-2 mb-0">
                  Jobboperasjon:
                  <i id="operation" className="fa fa-info-circle fa-fw text-info" />
                </p>
              </td>
              <td>
                <McInput
                  value={editData.generellInfo?.jobbOperasjon}
                  onChange={(e) => updateData("generellInfo.jobbOperasjon", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>Dato for befaring:</td>
              <td>
                <McInput
                  value={editData.generellInfo?.dato}
                  onChange={(e) => updateData("generellInfo.dato", e.target.value)}
                  type="date"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </CardBody>
    </Card>
  );
};

export default SJAGenerellInfo;
