import React, { useEffect, useState } from "react";

const Checkbox: React.FC<{
  isChecked: boolean;
  setChecked: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  disabled?: boolean;
  text?: string;
  color?: string;
  mode?: "check" | "cross";
  label?: string;
  className?: string;
  size?: "small" | "medium";
  icon?: string;
}> = ({ isChecked, setChecked, disabled, text = null, mode = "check", color, label, className, size, icon }) => {
  const [primaryColor, setPrimaryColor] = useState<string>("#00a76d");

  const doCheck = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (disabled === true) {
      return null;
    } else {
      setChecked(e);
    }
  };

  useEffect(() => {
    if (color != null) {
      setPrimaryColor(color);
    } else if (mode === "cross") {
      setPrimaryColor("#dc3545");
    }
  }, [color]);

  return (
    <div
      style={{ gap: size === "small" ? ".25rem" : ".5rem" }}
      className={`d-flex align-items-center ${className != null ? className : ""}`}
    >
      {label != null && (
        <div>
          <span>{label}</span>
        </div>
      )}
      {isChecked === false && (
        <div
          onClick={(e) => doCheck(e)}
          style={{
            cursor: disabled ? "cursor" : "pointer",
            width: size === "small" ? "18px" : "25px",
            height: size === "small" ? "18px" : "25px",
            borderRadius: "50%",
            backgroundColor: "#fff",
            border: `1px solid ${disabled ? "#ccc" : primaryColor}`,
          }}
        />
      )}
      {isChecked && (
        <div
          onClick={(e) => doCheck(e)}
          className="d-flex align-items-center justify-content-center"
          style={{
            cursor: disabled ? "cursor" : "pointer",
            width: size === "small" ? "18px" : "25px",
            height: size === "small" ? "18px" : "25px",
            borderRadius: "50%",
            backgroundColor: `${disabled ? "#ccc" : primaryColor}`,
            border: `1px solid ${disabled ? "#ccc" : primaryColor}`,
          }}
        >
          <i
            className={`fa ${icon ? `fa-${icon}` : mode === "check" ? "fa-check" : "fa-close"}`}
            style={{ color: "#fff", fontSize: size === "small" ? "12px" : "1em" }}
          />
        </div>
      )}
      {text != null && (
        <span
          style={{
            fontSize: size === "small" ? "14px" : "1em",
            textDecoration: `${mode === "cross" && isChecked ? "line-through" : ""}`,
          }}
        >
          {text}
        </span>
      )}
    </div>
  );
};

export default Checkbox;
