import Basemap from "@arcgis/core/Basemap";
import Graphic from "@arcgis/core/Graphic";
import WebMap from "@arcgis/core/WebMap";
import esriConfig from "@arcgis/core/config";
import Circle from "@arcgis/core/geometry/Circle";
import Extent from "@arcgis/core/geometry/Extent";
import Point from "@arcgis/core/geometry/Point";
import IdentityManager from "@arcgis/core/identity/IdentityManager";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import MapView from "@arcgis/core/views/MapView";
import Compass from "@arcgis/core/widgets/Compass";
import classnames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { Alert, Button } from "reactstrap";
import { IArcgisMap, IGisPoint, IGisPointAndCamera } from "../../../../../../types/global.types";
import { VSpace } from "../../../../components";
import { useAppSelector } from "../../../../hooks";
import { doGetEsriToken, doGetSingleArcgisGroup } from "../../gis/GisAPI";
import ReportDocxImageContainer from "../ReportBoard/ReportDocxImageContainer";
import pinBlueIcon from "./marker_blue.png";
import northArrow from "./north_arrow.png";

let mapView: any;
let graphicsLayer: any;

const SingleGeoteknikReportGisMap: React.FC<{
  pinVisible: "YES" | "NO";
  circleVisible: "YES" | "NO";
  sirkelDiameter: number;
  mapId: string;
  groupId: string;
  label?: string;
  pin?: IGisPoint;
  clickedGis?: IGisPointAndCamera;
  onLoaded?: () => void;
  storeScreenShot: (image: string, docxImageTag: string) => void;
  docxImageTag: string;
  basemapId?: string;
  layerIds?: any[];
  onUpdateZoomLevel: (zoom) => void;
  zoomLevel?: number;
}> = ({
  pinVisible,
  circleVisible,
  sirkelDiameter,
  mapId,
  groupId,
  label,
  pin,
  clickedGis,
  onLoaded,
  storeScreenShot,
  docxImageTag,
  basemapId,
  layerIds,
  onUpdateZoomLevel,
  zoomLevel,
}) => {
  const [failed, setFailed] = useState<boolean>(false);
  const project = useAppSelector((state) => state.adminReducer.project);

  const [hideSavedImage, setHideSavedImage] = useState<boolean>(false);

  useEffect(() => {
    if (mapId == null) {
      setFailed(true);
      return;
    }
    setTimeout(() => {
      goToPin();
    }, 3000);
  }, [pin]);

  useEffect(() => {
    fetchAndSetMap();
  }, [mapId]);

  useEffect(() => {
    if (graphicsLayer != null) {
      updateCircle(pin, sirkelDiameter, circleVisible === "YES");
    }
  }, [sirkelDiameter, circleVisible, mapId, graphicsLayer]);

  useEffect(() => {
    if (graphicsLayer != null) {
      drawPin(pinVisible === "YES");
    }
  }, [pinVisible, mapId, graphicsLayer]);

  const turnOnLayers = () => {
    if (layerIds?.length > 0) {
      (mapView as any)?.map?.layers?.items.map((item) => {
        item.visible = layerIds.includes(item.id) === true;
      });
    }
  };

  const goToPin = () => {
    if (mapView != null && pin != null) {
      const point = new Point({
        x: pin.x,
        y: pin.y,
        spatialReference: { wkid: pin.wkid },
      });

      const extentWidth = 300; // Bredden på ytan i meter
      const extentHeight = 300; // Höjden på ytan i meter

      const extent = new Extent({
        xmin: point.x - extentWidth / 2,
        ymin: point.y - extentHeight / 2,
        xmax: point.x + extentWidth / 2,
        ymax: point.y + extentHeight / 2,
        spatialReference: { wkid: pin.wkid },
      });

      const zoomOptions = {
        target: extent,
      };
      if (zoomLevel !== 0 && zoomLevel !== -1) {
        zoomOptions["zoom"] = zoomLevel;
      }
      mapView.goTo(zoomOptions);
      setTimeout(() => {
        updateCircle(pin, sirkelDiameter, circleVisible === "YES");
      }, 2000);
    }
  };

  const pinGraphicRef = useRef(null);

  const drawPin = (visible: boolean) => {
    if (pinGraphicRef.current != null) {
      graphicsLayer.remove(pinGraphicRef.current);
    }
    const pinGraphic = new Graphic({
      geometry: new Point({
        x: pin?.x,
        y: pin?.y,
        spatialReference: { wkid: 25833 },
      }),
      symbol: {
        //@ts-ignore
        type: "picture-marker", // autocasts as new PictureMarkerSymbol()
        url: pinBlueIcon,
        width: "40px",
        height: "53px",
      },
      visible: visible,
    });
    graphicsLayer.add(pinGraphic);
    pinGraphicRef.current = pinGraphic;
  };

  const updateCircle = async (
    coordinates: { x: number; y: number; wkid: number },
    diameter: number,
    visible: boolean,
  ) => {
    // Find the specific layer in graphicslayer
    const circleLayer = graphicsLayer.graphics.items.find((layer) => layer.title === "my-circle-layer");

    const realWorldDiameterMeters = diameter; // Example diameter in meters
    const radiusMeters = realWorldDiameterMeters / 2;

    if (circleLayer == null) {
      const circleGraphic = new Graphic({
        geometry: new Circle({
          center: new Point({
            x: coordinates?.x,
            y: coordinates?.y,
            spatialReference: { wkid: coordinates?.wkid },
          }),
          radius: radiusMeters,
          spatialReference: { wkid: 25833 },
        }),
        symbol: {
          //@ts-ignore
          type: "simple-fill", // autocasts as new SimpleMarkerSymbol()
          style: "cross",
          color: [0, 0, 0, 0],
          outline: {
            color: "red",
            width: 2,
          },
        },
        visible: visible,
        title: "my-circle-layer",
      });
      graphicsLayer.add(circleGraphic);
    } else {
      // Update the existing circle layer
      circleLayer.geometry = new Circle({
        center: new Point({
          x: coordinates?.x,
          y: coordinates?.y,
          spatialReference: { wkid: coordinates?.wkid },
        }),
        radius: radiusMeters,
        spatialReference: { wkid: 25833 },
      });
      circleLayer.visible = visible;
    }
  };

  const fetchAndSetMap = async () => {
    try {
      const group = await doGetSingleArcgisGroup(project._id, groupId);
      const _map = group.maps.find((_map) => _map.id === mapId);
      if (_map == null) {
        setFailed(true);
      } else {
        initMap(_map);
      }
    } catch (error) {
      setFailed(true);
    }
  };

  let fetchedToken;

  const reqIntercept: __esri.BeforeInterceptorCallback = (ioArgs: any) => {
    if (ioArgs.url.indexOf("cloudgis.multiconsult.no") > -1) {
      ioArgs.requestOptions.query.token = fetchedToken;
    }
  };

  const initMap = (map: IArcgisMap) => {
    if (mapId) {
      doGetEsriToken(project._id).then((token) => {
        IdentityManager.registerToken({
          server: "https://cloudgis.multiconsult.no/portal/sharing/",
          token,
          expires: new Date().getTime() + 3600 * 1000,
        });
        esriConfig.portalUrl = "https://cloudgis.multiconsult.no/portal/";
        fetchedToken = token;
        esriConfig.request.interceptors.push({ before: reqIntercept });

        // Check if we will create a map or a scene
        if (map.type === "Web Map") {
          let webmapConf = {
            portalItem: {
              id: map.id,
            },
          };
          if (basemapId != null) {
            webmapConf["basemap"] = new Basemap({
              portalItem: {
                id: basemapId,
              },
            });
          }

          const webmap = new WebMap(webmapConf);
          mapView = new MapView({
            map: webmap,
            container: "mapContainer",
            zoom: zoomLevel,
          });
        }
        const compass = new Compass({
          view: mapView,
        });
        mapView.ui.add(compass, "top-left");
        graphicsLayer = new GraphicsLayer({
          title: "Aktive saker",
        });
        const zoomWatcher = mapView.watch("zoom", (newZoomLevel) => {
          onUpdateZoomLevel(newZoomLevel);
        });
        drawPin(pinVisible === "YES");
        updateCircle(pin, sirkelDiameter, circleVisible === "YES");
        mapView.when((mapView: MapView) => {
          if (onLoaded != null) {
            onLoaded();
          }
          // Add click-event to add marker symbol
          turnOnLayers();
          setTimeout(() => {
            mapView.map.add(graphicsLayer);
          }, 2000);
        });
      });
    }
  };

  const takeScreenShot = async () => {
    try {
      const northArrowGraphic = new Graphic({
        geometry: new Point({
          x: mapView.extent.xmax - mapView.extent.width * 0.05,
          y: mapView.extent.ymax - mapView.extent.height * 0.075,
          spatialReference: { wkid: 25833 },
        }),
        symbol: {
          //@ts-ignore
          type: "picture-marker", // autocasts as new PictureMarkerSymbol()
          url: northArrow,
          width: "40px",
          height: "53px",
        },
      });
      graphicsLayer.add(northArrowGraphic);
      // add 1 second delay to make sure the north arrow is rendered
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return await mapView.takeScreenshot({
        format: "jpg",
        quality: 100,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const takeScreenAndCreateItem = async () => {
    const screenShot = await takeScreenShot();
    storeScreenShot(screenShot.dataUrl, docxImageTag);
    const northArrowGraphic = graphicsLayer.graphics.find((graphic) => {
      return graphic.symbol.url === northArrow;
    });

    // Remove the north arrow graphic if it exists
    if (northArrowGraphic) {
      graphicsLayer.remove(northArrowGraphic);
    }
    setHideSavedImage(true);
    setTimeout(() => {
      setHideSavedImage(false);
    }, 50);
  };

  return (
    <>
      <div style={{ height: "500px", width: "500px" }} className="border">
        <div className="d-flex">
          <h3>{label}</h3>
          <div className="flex-fill" />
          <Button color="mc-blue" onClick={goToPin}>
            <i className="fa fa-map-marker fa-fw" />
            Gå til punkt
          </Button>
        </div>
        {failed === true && (
          <Alert color="danger">Kartet ble ikke funnet eller tilkobling til arcgis kunne ikke etableres</Alert>
        )}
        {failed !== true && (
          <div id="mapContainer" className="w-100 h-100 pb-6" style={{ position: "relative" }}>
            <div
              className={classnames({ "bg-primary": clickedGis != null, "p-2": true })}
              style={{ borderRadius: "5px", position: "absolute", right: "5px", top: "5px" }}
            />
          </div>
        )}
      </div>
      <Button block color="success mt-6" onClick={takeScreenAndCreateItem} style={{ width: "500px" }}>
        <i className="fa fa-download fa-fw" />
        Lagre bilde
      </Button>
      <VSpace />
      {hideSavedImage !== true && (
        <div style={{ height: "500px", width: "500px" }} className="border">
          <h4 className="text-center">Lagret bilde {label}</h4>
          <ReportDocxImageContainer docxImageTag={docxImageTag} title="" />
        </div>
      )}
    </>
  );
};

export default SingleGeoteknikReportGisMap;
