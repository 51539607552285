import React, { ErrorInfo } from "react";
import { createPortal } from "react-dom";
import { Alert, Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { doLogError } from "../app/routes/admin/restQueries";

interface IState {
  isGlobal: boolean;
  hasError: boolean;
  info: ErrorInfo;
  error: Error;
  promiseError: Error;
  expanded: boolean;
  errorMessage: string;
  errorStack: string;
}

interface IProps {
  isGlobal?: boolean;
  customComponent?: React.ReactNode;
}

class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      isGlobal: props.isGlobal || false,
      hasError: false,
      error: null,
      info: null,
      promiseError: null,
      expanded: false,
      errorMessage: "",
      errorStack: "",
    };
  }

  errorHappend = async (doLog: boolean, errorMessage: string, errorStack: string) => {
    localStorage.clear();

    if (doLog === true) {
      await doLogError(errorMessage, errorStack, window.location.href);
    }
  };

  resetState = () => {
    this.setState({
      ...this.state,
      hasError: false,
      error: null,
      info: null,
      promiseError: null,
      expanded: false,
      errorMessage: "",
    });
  };

  unhandledrejectionEventListener = (promiseError: PromiseRejectionEvent) => {
    console.log("### unhandledrejection");

    if (promiseError.reason?.type === "backend") {
      this.setState({ hasError: true, info: null, errorMessage: JSON.stringify(promiseError.reason.message) });
      // backend errors are logged in backend
      this.errorHappend(false, null, null);
    } else {
      this.setState({ hasError: true, info: null, errorMessage: JSON.stringify(promiseError.reason) });
      this.errorHappend(true, JSON.stringify(promiseError.reason), "");
    }
  };

  componentDidMount(): void {
    if (this.state.isGlobal === true) {
      window.onerror = (event: string, source: string, _lineNo: number, _columnNo: number, error: Error) => {
        console.log("### window.onerror");

        const errorMsg = `${error?.message ?? "Error is undefined"}\n${event}`;

        // Errors cought by boundaries in dev environment will be re-thrown and couught by window.onerror.
        // No need to show error modal.
        if (import.meta.env.DEV === true) {
          console.error(errorMsg);
          this.errorHappend(false, null, null);
        } else {
          if (error != null) {
            this.setState({
              hasError: true,
              info: null,
              errorMessage: errorMsg,
              errorStack: error.stack,
              promiseError: null,
            });

            this.errorHappend(true, errorMsg, error.stack);
          }

          this.errorHappend(true, errorMsg, "");
        }
      };

      window.addEventListener("unhandledrejection", this.unhandledrejectionEventListener);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("unhandledrejection", this.unhandledrejectionEventListener);
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    console.log("### componentDidCatch");

    this.setState({
      hasError: true,
      errorMessage: `${error.message}\n${info.componentStack}\n-\n${error.stack}`,
      info,
      error,
    });

    this.errorHappend(true, error.message, `${info.componentStack}\n\n${error.stack}`);
  }

  render() {
    if (this.state.hasError === true) {
      if (this.props.customComponent) {
        return this.props.customComponent;
      }
      return (
        <>
          {createPortal(
            <Modal
              style={{ maxWidth: "60vw", maxHeight: "90%" }}
              isOpen={this.state.hasError === true}
              toggle={() => this.resetState()}
            >
              <ModalHeader toggle={() => this.resetState()}>En uventet feil har oppstått!</ModalHeader>
              <ModalBody>
                <div className="app-wrapper d-flex flex-column">
                  <div className="jr-card jr-card-full-height">
                    <Alert color="primary">
                      <i className="fa fa-warning fa-fw" />
                      Teknisk personale er varslet.
                      <br />
                      Kontakt support på <b>mcapps@multiconsult.no</b> for ytterligere info.
                    </Alert>

                    <div
                      className={`bg-dark d-flex flex-column ${
                        this.state.expanded ? "error-expand" : "error-collapsed"
                      }`}
                    >
                      <pre className={`text-white p-2`}>{this.state.errorMessage}</pre>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                      {this.state.expanded === false && this.state.errorMessage != null && (
                        <Button color="link" onClick={() => this.setState({ ...this.state, expanded: true })}>
                          Se teknisk feilmelding
                          <i className="fa fa-caret-down ml-2" />
                        </Button>
                      )}
                      <div className="flex-grow-1"></div>
                      <Button
                        color="primary"
                        className="ml-2"
                        onClick={() => {
                          window.location.href = "/";
                        }}
                      >
                        Start programmet på nytt
                      </Button>
                      <Button className="ml-2" onClick={() => this.resetState()}>
                        Prøv igjen
                      </Button>
                    </div>
                  </div>
                </div>
              </ModalBody>
            </Modal>,
            document.body,
          )}
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
