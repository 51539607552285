import Axios from "axios";
import {
  FlatHierarchy,
  IMember,
  IMulticonsultProject,
  IPNSProps,
  IProjectMcBimSettings,
} from "../../../types/global.types";
import { IProjectMcCosSettings } from "mc-shared/zod/projectSchema";

export const doGetProjectMembers = async (projectId: string): Promise<IMember[]> => {
  try {
    const resp = await Axios.get(`/api/project/${projectId}/members`);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doCheckIfUserIsMemberOfProject = async (projectId: string) => {
  try {
    const projectFetch = await Axios.get(`/api/project/${projectId}/user/is-member`);
    return projectFetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doSyncMulticonsultMembers = async (projectId: string) => {
  try {
    const resp = await Axios.get(`/api/project/${projectId}/members/sync-multiconsultusers`);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doUpdateMemberDisciplinInProject = async (
  projectId: string,
  memberId: string,
  disciplin: string,
  removeDiscipline?: boolean,
) => {
  try {
    const resp = await Axios.put(
      `/api/project/${projectId}/member/${memberId}/disciplin/${disciplin}`,
      removeDiscipline === true ? { removeDiscipline } : {},
    );
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doUpdateMemberIsPassive = async (projectId: string, memberId: string, isPassive: boolean) => {
  try {
    const resp = await Axios.put(`/api/project/${projectId}/member/${memberId}/isPassive/${isPassive}`);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doUpdateMemberGroupInProject = async (projectId: string, memberId: string, group: string) => {
  try {
    const resp = await Axios.put(`/api/project/${projectId}/member/${memberId}/group/${group}`);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doUpdateMcBim = async (
  projectId: string,
  mcbim: IProjectMcBimSettings,
): Promise<IProjectMcBimSettings> => {
  try {
    const resp = await Axios.put(`/api/project/${projectId}/mcbim`, { mcbim });
    return Promise.resolve(resp.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const doUpdatePns = async (
  projectId: string,
  flatHierarchy: FlatHierarchy<IPNSProps>,
): Promise<FlatHierarchy<IPNSProps>> => {
  try {
    const resp = await Axios.put(`/api/project/${projectId}/pns`, flatHierarchy);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetProjectPnsItems = async (projectId: string): Promise<FlatHierarchy<IPNSProps>> => {
  try {
    const resp = await Axios.get(`/api/project/${projectId}/pnsItems`);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doAddAdminUserToProject = async (userId: string, projectId: string): Promise<void> => {
  try {
    const resp = await Axios.post(`/api/project/add-user`, { userId, projectId });
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetMProject = async (projectId: string, mProjectNo): Promise<IMulticonsultProject> => {
  try {
    const resp = await Axios.put(`/api/project/${projectId}/get-m-sub-project/${mProjectNo}`);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doUpdateMcCos = async (
  projectId: string,
  mccos: IProjectMcCosSettings,
): Promise<IProjectMcCosSettings> => {
  try {
    const resp = await Axios.put(`/api/project/${projectId}/mccos`, { mccos });
    return Promise.resolve(resp.data);
  } catch (error) {
    return Promise.reject(error);
  }
};
