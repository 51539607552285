import React, { useEffect, useState } from "react";
import { Alert, ButtonGroup, Modal, ModalBody, ModalHeader } from "reactstrap";
import {
  IMember,
  IOrgChart,
  IOrgChartItem,
  IParentChildItem,
  IUserActiveItem,
} from "../../../../../types/global.types";
import MemberCard from "../../../components/MemberCard/MemberCard";
import NumberOfLevelItemsSelector from "../../../components/NumberOfLevelItemsSelector/NumberOfLevelItemsSelector";
import OrgChartViewBar from "../../../components/OrgChartViewBar/OrgChartViewBar";
import { useAppSelector } from "../../../hooks";
import { getArrWithNumbers, sortArrayByDate } from "../../utils";
import { IMemberWithDomain } from "../admin/members/MembersTable";
import { getEmailDomain } from "../admin/selectors";
import { doGetUsersIssueStats } from "../issuesApp/issueAPIDepricated";
import { doGetActiveAssignedItemsDepricated } from "../projects/restQueries";
import D3OrganisationChart from "./D3OrganisationChart/D3OrganisationChart";
import {
  formatOrganisationItemsToParentChildItems,
  getNumberOfNodeLevels,
  hasNodeNumberOfLevels,
  isOrgChartLevelItemsValid,
} from "./orgChartUtils";

const OrgChart: React.FC<{
  orgChart: IOrgChart;
  members: IMember[];
  projectId: string;
}> = ({ orgChart, members, projectId }) => {
  const [nodes, setNodes] = useState<IParentChildItem[]>();
  const [selectedOrgItem, setSelectedOrgItem] = useState<IOrgChartItem>();
  const [selectedNode, setSelectedNode] = useState<IParentChildItem>();
  const [activeLevel, setActiveLevel] = React.useState<number>(null);
  const [activeView, setActiveView] = React.useState<"Standard" | "Komprimert">("Standard");

  const [notValid, setNotValid] = React.useState<boolean>(null);

  const [numberOfLevels, setNumberOfLevels] = React.useState<number>();

  useEffect(() => {
    if (isOrgChartLevelItemsValid(orgChart.items) === false) {
      setNotValid(true);
    } else {
      setNotValid(false);
      fetchUserIssueStatsFormatAndSetNodes();
    }
  }, []);

  const fetchUserIssueStatsFormatAndSetNodes = async () => {
    try {
      const issueStats = await doGetUsersIssueStats(projectId);
      const _nodes = formatOrganisationItemsToParentChildItems(orgChart.items, members, issueStats);
      setNodes(_nodes.length > 0 ? _nodes : null);
      const _numberOfLevels = getNumberOfNodeLevels(_nodes);
      setNumberOfLevels(_numberOfLevels);
    } catch (error) {
      return Promise.reject({ type: "backend", message: error.response?.data });
    }
  };

  const open = (level: string) => {
    const orgChartItem = orgChart.items.find((item) => item.level === level);
    if (orgChartItem.nameOfUser != null) {
      const _selectedNode = nodes.find((node) => node.id === level);
      setSelectedNode(_selectedNode);
      setSelectedOrgItem(orgChartItem);
    }
  };

  const closeModal = () => {
    setSelectedOrgItem(null);
  };

  const setNewActiveView = (view) => {
    setActiveView(null);
    setTimeout(() => {
      setActiveView(view);
    });
  };

  return (
    <>
      {notValid === true && <Alert color="danger">Inndata er ikke gyldig. Sjekk inndataene og prøv igjen.</Alert>}
      {notValid === false && (
        <div className="h-100">
          {selectedOrgItem != null && (
            <OrgItemModal orgItem={selectedOrgItem} toggle={closeModal} node={selectedNode} />
          )}
          <ActionBar
            arrWithLevels={getArrWithNumbers(numberOfLevels)}
            setActiveLevel={setActiveLevel}
            activeLevel={activeLevel}
            activeView={activeView}
            setActiveView={setNewActiveView}
          />
          <div className="p-3 h-100 d-flex flex-column">
            {nodes?.length > 0 && (
              <>
                {activeView != null && (
                  <D3OrganisationChart
                    onClick={open}
                    items={nodes?.filter((node) => hasNodeNumberOfLevels(activeLevel, node))}
                    size={activeView}
                  />
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default OrgChart;

const ActionBar: React.FC<{
  activeLevel: number;
  setActiveLevel: (level: number) => void;
  arrWithLevels: number[];
  activeView: string;
  setActiveView: (view: "Standard" | "Komprimert") => void;
}> = ({ activeLevel, setActiveLevel, arrWithLevels, activeView, setActiveView }) => {
  return (
    <ButtonGroup>
      <NumberOfLevelItemsSelector
        arrWithLevels={arrWithLevels}
        setActiveLevel={setActiveLevel}
        activeLevel={activeLevel}
      />
      <OrgChartViewBar
        activeView={activeView}
        arrWithViews={["Standard", "Komprimert"]}
        setActiveView={setActiveView}
      />
    </ButtonGroup>
  );
};

const OrgItemModal: React.FC<{ orgItem: IOrgChartItem; toggle: any; node: IParentChildItem }> = ({
  orgItem,
  toggle,
  node,
}) => {
  const [member, setMember] = useState<IMemberWithDomain>();
  const [assignedItems, setAssignedItems] = useState<IUserActiveItem[]>(null);

  const project = useAppSelector((state) => state.adminReducer.project);

  useEffect(() => {
    if (orgItem.nameOfUser != null) {
      const _member = project.members.find((mbr) => {
        return (
          mbr.user.name.trim().toUpperCase().replace(/\W/g, "") ===
          orgItem.nameOfUser.trim().toUpperCase().replace(/\W/g, "")
        );
      });
      const domain = getEmailDomain(_member?.user && _member.user.email);
      setMember({ ..._member, domain: domain });
      if (_member?.user) {
        fetchAndSetAssignedItems(_member);
      }
    }
  }, []);
  const fetchAndSetAssignedItems = async (member: IMember) => {
    const _assignedItems = await doGetActiveAssignedItemsDepricated(project._id, member.user._id);
    setAssignedItems(sortArrayByDate(_assignedItems, "createdAt"));
  };

  return (
    <Modal isOpen toggle={toggle} style={{ maxWidth: "550px" }}>
      <ModalHeader toggle={toggle}>{member && member.user?.name}</ModalHeader>
      <ModalBody>
        <MemberCard member={member} assignedItems={assignedItems} projectId={project._id} orgTitle={orgItem.text} />
      </ModalBody>
    </Modal>
  );
};
