import moment from "moment";
import * as R from "ramda";
import React, { useEffect, useRef, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { useHistory } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { Badge, Button, UncontrolledTooltip } from "reactstrap";
import {
  IColumnTemplate,
  IDocxTemplate,
  IRisiko,
  IRisikoEvaluationCategory,
  IRiskoBoard,
} from "../../../../../../../types/global.types";
import {
  ButtonToggler,
  ColumnSelector,
  CreateNewButton,
  DocxReport,
  DocxReportDropdown,
  ExcelExportDropdown,
  HSpace,
} from "../../../../../components";
import ToggleTextExpand from "../../../../../components/ToggleTextExpand";
import { expandColumnCaret, expandHeaderColumn, patchFilterFactory } from "../../../../../components/columnGlobal";
import { ToastMessagesEnums } from "../../../../../frontendConstants";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { IBootstrapTableColumnConfig } from "../../../../../types";
import { exportExcel } from "../../../../utils";
import { formatShortDate, isDateValidFrontend } from "../../../admin/selectors";
import { doDownloadIssueDocxTemplate } from "../../../issuesApp/issueAPIDepricated";
import { ISSUE_DATAFILEDS_ENUM } from "../../../issuesApp/issueConstants";
import { ColumnTemplateSelector } from "../../ColumnTemplateSelector";
import { RISIKO_COLUMN_LS_KEY } from "../../RisikoColumns";
import RisikoAcumlatedMatrixAndCharts from "../../Stats/RisikoAcumlatedMatrixAndCharts";
import RisikoCategoryMatrixAndChartMultipleItems from "../../Stats/RisikoCategoryMatrixAndChartMultipleItems";
import {
  addRisikoRedux,
  fetchAndSetRisikoDocxTemplates,
  fetchRisikoAndReplaceItInRedux,
  setActiveRiskoTabRedux,
  setEvaluationBarOpenInRedux,
  setFiltredByChartOrMatrixRisikoIdsInRedux,
  setRiskoColumnTemplateInRedux,
  setSelectedRisikoInRedux,
} from "../../actions";
import { RisikoItemTabEnum } from "../../reducer";
import { doDeleteRisikoDocxTemplate, doGenerateRisikoDocx, doUploadRisikoDocxTemplate } from "../../risikoAPI";
import { RISIKO_DOCX_DEMO_DATA } from "../../risikoConstants";
import { getRisikosFiltredInChart, getRisikosIdsFiltredOnGlobalSearch } from "../../selectors";
import { getRisikoTiltakSrr } from "../../util";
import AddRisikoModal from "../AddRisikoModal";
import RisikoRowExpandContent from "./RisikoRowExpandContent";
import { IDocxItemIndata } from "mc-shared/zod/commonSchema";
import { setPrintModeInRedux, setShowSuccessMessageThunk } from "../../../../../global/globalSlice";

const RisikoTable: React.FC<{
  columns: IBootstrapTableColumnConfig[];
  columnTemplates: IColumnTemplate[];
  saveCb: any;
}> = ({ columns, columnTemplates, saveCb }) => {
  const [addRisikoModalOpen, setAddRiskoModalOpen] = useState<boolean>(false);
  const [docxModalOpen, setDocxModalOpen] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const project = useAppSelector((state) => state.adminReducer.project);
  const activeBoard = useAppSelector((state) => state.risikoReducer.activeBoard);
  const categories = useAppSelector((state) => state.risikoReducer.categories);
  const evaluationBarOpen = useAppSelector((state) => state.risikoReducer.evaluationBarOpen);
  const filtredByChartOrMatrixRiskoIds = useAppSelector((state) => state.risikoReducer.filtredByChartOrMatrixRiskoIds);
  const docxTemplates = useAppSelector((state) => state.risikoReducer.docxTemplates);

  const risikos = useAppSelector(getRisikosFiltredInChart);

  //This is necassary since redux throws an error if bootstrap table tris to mutate the items outside of redux
  const [copyOfRisikos, setCopyOfRisikos] = useState(null);
  React.useEffect(() => {
    setCopyOfRisikos(R.clone(risikos));
  }, [risikos]);

  const risikoIdsFiltredOnGlobalSearch = useAppSelector(getRisikosIdsFiltredOnGlobalSearch);

  let refContainer = useRef(null);
  const [filtredTableRisikos, setFiltredTableRisikos] = useState(risikos);

  useEffect(() => {
    setFiltredTableRisikos(risikos);
  }, [risikos.length]);

  const resetFilter = () => {
    dispatch(setFiltredByChartOrMatrixRisikoIdsInRedux([]));
  };

  const factory = patchFilterFactory(filterFactory, (data) => {
    setFiltredTableRisikos((prevData) => {
      if (JSON.stringify(prevData) !== JSON.stringify(data)) {
        return data;
      }
      return prevData;
    });
  });

  const excelExportVisibleColumns = () => {
    const _columns = columns.filter((column) => column.hidden === false);
    exportExcelRisiko(_columns, filtredTableRisikos);
  };

  const exportExcelAllColumns = () => {
    exportExcelRisiko(columns, filtredTableRisikos);
  };

  const exportExcelRisiko = async (_columns: IBootstrapTableColumnConfig[], risikos: IRisiko[]) => {
    //Todo: Add actions somehow to export
    const formatted = risikos.map((risiko) => {
      return {
        ...risiko,
        connectedIssues: getRisikoTiltakSrr(risiko),
      };
    });
    const cols = [...columns, { dataField: "connectedIssues", text: "Tiltak" }];
    exportExcel(cols, formatted, "Risiko", ["_id", "_typeName", "statelogs", "tags"]);
  };

  const handleDataChange = () => {
    if (evaluationBarOpen) {
      if (evaluationBarOpen) {
        //   setRefreshStats(true);
      }
    }
  };

  const customColumnToggle = (column) => {
    const index = columns.findIndex((_column) => _column.dataField === column.dataField);
    const _columns = [...columns];
    _columns[index].hidden = !_columns[index].hidden;
    const hidden = _columns[index].hidden;
    try {
      const issueColumns = JSON.parse(localStorage.getItem(RISIKO_COLUMN_LS_KEY)) || {};
      issueColumns[column.dataField] = hidden;
      localStorage.setItem(RISIKO_COLUMN_LS_KEY, JSON.stringify(issueColumns));
    } catch (error) {
      console.log("failed to read colums in local storage");
      localStorage.removeItem(RISIKO_COLUMN_LS_KEY);
      console.error(error);
    }
  };

  const beforeSaveCell = async (oldValue, newValue, row, column) => {
    if (column.dataField === ISSUE_DATAFILEDS_ENUM.OWNER_NAME && newValue === "") {
      return;
    }
    if (oldValue === newValue) {
      return;
    }

    if (!oldValue && !newValue) {
      return;
    }

    if (isDateValidFrontend(newValue)) {
      if (moment(newValue).isBefore("2000-01-01")) {
        return "";
      }
    }

    if (
      (column.dataField === ISSUE_DATAFILEDS_ENUM.OWNER_NAME ||
        column.dataField === ISSUE_DATAFILEDS_ENUM.ASSIGNEDTO_NAME) &&
      newValue !== ""
    ) {
      const member = project.members.find((member) => member.user?.name === newValue);
      newValue = member.user?._id;
    }

    await saveCb(column.dataField, row._id, newValue, oldValue);
    await dispatch(fetchRisikoAndReplaceItInRedux(row._id));
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.UPDATED));
    return { async: true };
  };

  const expandRow = {
    renderer: (row) => <RisikoRowExpandContent risikoId={row._id} />,
    expandColumnRenderer: expandColumnCaret,
    showExpandColumn: true,
    expandHeaderColumnRenderer: expandHeaderColumn,
    onlyOneExpanding: true,
    expandByColumnOnly: true,
  };

  const history = useHistory();

  const addRisikoAndCloseModalAndOpenRisiko = async (risiko: IRisiko) => {
    dispatch(addRisikoRedux(risiko));
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.RISIKO_ADDED));
    dispatch(
      setSelectedRisikoInRedux({
        _id: risiko._id,
        activeItemTab: RisikoItemTabEnum.INFO,
      }),
    );
    history.push(`${history.location.pathname}?id=${risiko._id}`);
    setAddRiskoModalOpen(false);
  };

  const docxUploadComplete = () => {
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.UPLOAD_SUCCESFULL));
    dispatch(fetchAndSetRisikoDocxTemplates());
  };

  const generateRisikoDocx = async (docxTemplate: IDocxTemplate) => {
    const d = new Date().toISOString();
    const docxData: IDocxItemIndata = {
      generatedDate: formatShortDate(d),
      items: filtredTableRisikos,
    };
    await doGenerateRisikoDocx(project._id, docxTemplate, docxData);
  };
  const [printModePromise, setPrintModePromise] = useState({ resolve: undefined });
  useEffect(() => {
    if (printModePromise.resolve) {
      dispatch(setPrintModeInRedux(true));
      printModePromise.resolve();
    }
  }, [printModePromise]);
  return (
    <div
      id="a1"
      className="w-100 d-flex flex-column flex-child-container"
      style={{
        overflow: `${evaluationBarOpen === true ? "auto" : "hidden"}`,
      }}
    >
      {addRisikoModalOpen && (
        <AddRisikoModal
          closeAddRisikoModal={() => setAddRiskoModalOpen(false)}
          risikoBoardId={activeBoard._id}
          onCreateRisiko={addRisikoAndCloseModalAndOpenRisiko}
        />
      )}
      {docxModalOpen === true && (
        <DocxReport
          demoDocxData={RISIKO_DOCX_DEMO_DATA}
          docxTemplates={docxTemplates}
          uploadCompleedCb={docxUploadComplete}
          uploadFunc={(content) => doUploadRisikoDocxTemplate(project._id, content)}
          generateDocx={(docxTemplate: IDocxTemplate) => generateRisikoDocx(docxTemplate)}
          downloadTemplate={(docxTemplate: IDocxTemplate) => doDownloadIssueDocxTemplate(project._id, docxTemplate)}
          deleteDocx={(docxTemplate) => doDeleteRisikoDocxTemplate(project._id, docxTemplate)}
          fetchAndSet={() => dispatch(fetchAndSetRisikoDocxTemplates())}
          closeModal={() => setDocxModalOpen(false)}
        />
      )}
      {filtredTableRisikos != null && (
        <EvaluationBar
          isOpen={evaluationBarOpen}
          risikosInTable={filtredTableRisikos}
          risikoCategories={categories}
          risikoBoard={activeBoard}
        />
      )}
      <ToolkitProvider
        keyField="_id"
        data={(copyOfRisikos || []).filter((risiko) =>
          risikoIdsFiltredOnGlobalSearch != null ? risikoIdsFiltredOnGlobalSearch.indexOf(risiko._id) !== -1 : true,
        )}
        columns={columns}
        columnToggle
      >
        {(props) => {
          return (
            <>
              <div className="d-flex align-items-center p-1">
                <CreateNewButton onClick={() => setAddRiskoModalOpen(true)} tooltip="Ny risiko" />
                <HSpace />
                <ColumnSelector {...props.columnToggleProps} customColumnToggle={customColumnToggle} />
                <HSpace />
                <ColumnTemplateSelector
                  columnsToShowTemplates={columnTemplates}
                  setColumnsByTemplate={(template) => dispatch(setRiskoColumnTemplateInRedux(template))}
                  goToSettings={() => dispatch(setActiveRiskoTabRedux("SETTINGS"))}
                />
                <HSpace />
                <ToggleTextExpand currentApp={"risiko"} />
                <div className="flex-fill" />
                <div className="d-flex align-items-center">
                  <div>
                    <Button color="primary" onClick={() => dispatch(setEvaluationBarOpenInRedux(!evaluationBarOpen))}>
                      <i className={`fa fa-arrow-${evaluationBarOpen === false ? "down" : "up"} fa-fw`} />
                      {evaluationBarOpen ? "Skjul Evalueringer" : "Evalueringer"}
                      <i className={`fa fa-arrow-${evaluationBarOpen === false ? "down" : "up"} fa-fw`} />
                    </Button>
                  </div>
                  <HSpace />
                  {filtredByChartOrMatrixRiskoIds.length > 0 && (
                    <FilterBar nrOfFilterItems={filtredByChartOrMatrixRiskoIds.length} resetFilter={resetFilter} />
                  )}
                </div>
                <div className="flex-fill" />
                <ReactToPrint
                  onBeforeGetContent={() => {
                    return new Promise((resolve) => {
                      setPrintModePromise({ resolve: resolve });
                    });
                  }}
                  onAfterPrint={() => dispatch(setPrintModeInRedux(false))}
                  copyStyles={true}
                  trigger={() => (
                    <div>
                      <Button color="secondary" id="tabellutskrift">
                        <i className="fa fa-print fa-fw" />
                      </Button>
                      <UncontrolledTooltip placement="top" target="tabellutskrift">
                        Tabellutskrift
                      </UncontrolledTooltip>
                    </div>
                  )}
                  // @ts-ignore
                  content={() => refContainer}
                />
                <HSpace />
                <ExcelExportDropdown
                  excelExportVisibleColumns={excelExportVisibleColumns}
                  exportExcelAllColumns={exportExcelAllColumns}
                />
                <HSpace />
                {docxTemplates != null && (
                  <DocxReportDropdown
                    docxTemplates={docxTemplates}
                    generateDocx={generateRisikoDocx}
                    openDocxModal={() => setDocxModalOpen(true)}
                    buttonColor="secondary"
                    buttonText="Utskrift"
                  />
                )}
                <HSpace />
              </div>
              <div className="border flex-child-container overflow-auto">
                <BootstrapTable
                  expandRow={expandRow}
                  ref={(el) => (refContainer = el)}
                  classes="table-sm table-responsive"
                  {...props.baseProps}
                  bootstrap4
                  bordered={false}
                  striped
                  defaultSorted={[
                    {
                      dataField: "id",
                      order: "asc",
                    },
                  ]}
                  keyField="_id"
                  wrapperClasses="smalltable"
                  filter={factory()}
                  cellEdit={cellEditFactory({
                    mode: "dbclick",
                    blurToSave: true,
                    beforeSaveCell: beforeSaveCell,
                  })}
                  pagination={paginationFactory({
                    sizePerPage: 50,
                    sizePerPageList: [
                      {
                        text: "50",
                        value: 50,
                      },
                      {
                        text: "100",
                        value: 100,
                      },
                      {
                        text: "200",
                        value: 200,
                      },
                      {
                        text: "All",
                        value: risikos?.length || 1000,
                      },
                    ],
                  })}
                  onDataSizeChange={handleDataChange}
                />
              </div>
            </>
          );
        }}
      </ToolkitProvider>
    </div>
  );
};

export default RisikoTable;

const EvaluationBar: React.FC<{
  isOpen: boolean;
  risikoCategories: IRisikoEvaluationCategory[];
  risikosInTable: IRisiko[];
  risikoBoard: IRiskoBoard;
}> = ({ isOpen, risikosInTable }) => {
  const [activeView, setActiveView] = useState("Kategorier");
  const views = [
    {
      title: "Kategorier",
      value: "Kategorier",
    },
    {
      title: "Akkumulert",
      value: "Akkumulert",
    },
  ];

  return (
    <>
      {isOpen && (
        <div style={{ backgroundColor: "#f4f5fc" }} className="p-1">
          <div className="d-flex">
            <div className="flex-fill" />
            <ButtonToggler togglerItems={views} value={activeView} setValue={setActiveView} type="BUTTON" />
          </div>
          <div className="d-flex flex-wrap justify-content-around w-100" style={{ minHeight: isOpen ? "300px" : 0 }}>
            {activeView === "Kategorier" && (
              <RisikoCategoryMatrixAndChartMultipleItems risikosInTable={risikosInTable} />
            )}
            {activeView === "Akkumulert" && <RisikoAcumlatedMatrixAndCharts risikosInTable={risikosInTable} />}
          </div>
        </div>
      )}
    </>
  );
};

export const FilterBar = ({ nrOfFilterItems, resetFilter }) => (
  <div className="d-flex align-items-center bg-light p-1">
    <Badge pill color="teal">
      <i className="fa fa-list fa-fw" />
      <span>{nrOfFilterItems} st</span>
    </Badge>
    <HSpace />
    <div>
      <Button className="mc-blue text-white" onClick={() => resetFilter()}>
        <i className="fa fa-close fa-fw" />
        Lukk
      </Button>
    </div>
  </div>
);
