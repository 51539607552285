import Axios from "axios";
import { IMcBimMongo } from "../../../../../../types/mcbimMono.types";

export const doGetMCBimMongoFilesStats = async (projectId: string): Promise<IMcBimMongo.FileStats[]> => {
  try {
    const resp = await Axios.get(`/api/project/${projectId}/mcbimmongo/files`);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetMcBimMongoFileVersions = async (
  projectId: string,
  file: IMcBimMongo.FileIdAndName,
): Promise<IMcBimMongo.FileStats[]> => {
  try {
    const resp = await Axios.post(`/api/project/${projectId}/mcbimmongo/fileversions`, { file });
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetMcBimProjectInfo = async (projectId: string): Promise<IMcBimMongo.ProjectInfo> => {
  try {
    const resp = await Axios.get(`/api/project/${projectId}/mcbimmongo/projectinfo`);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetMcBimSpaceList = async (projectId: string): Promise<IMcBimMongo.SpaceBasicInfo[]> => {
  try {
    const resp = await Axios.get(`/api/project/${projectId}/mcbimmongo/spacelist`);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};
