import React from "react";
import { Badge } from "reactstrap";
import { sortArrayByNumber } from "../../../utils";
import { IMatrixData, IScoreMatrix } from "../types";

const ScoreMatrix: React.FC<{ scoreMatrix: IScoreMatrix }> = ({ scoreMatrix }) => {
  const getMatrixData = (colId: number, rowId: number) => {
    return scoreMatrix.data.find((item) => item.colId === colId && item.rowId === rowId);
  };

  return (
    <div className="d-flex flex-column">
      <table className="table table-bordered table-sm">
        <tbody>
          <tr />
          {sortArrayByNumber(scoreMatrix.rowHeaders, "value").map((rowHeader) => (
            <tr key={rowHeader.rowId} style={{ height: "30px" }}>
              {sortArrayByNumber(scoreMatrix.colHeaders, "value").map((colHeader) => (
                <Cell key={colHeader.colId} matrixData={getMatrixData(colHeader.colId, rowHeader.rowId)} />
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Cell: React.FC<{ matrixData: IMatrixData }> = ({ matrixData }) => {
  if (matrixData == null) {
    return <td />;
  }

  const id = `A${matrixData.rowId}${matrixData.colId}`;

  return (
    <>
      <td
        id={id}
        className="text-center"
        style={{ backgroundColor: matrixData && matrixData.color, borderColor: "#fff", width: "50px" }}
      >
        {matrixData.items.length !== 0 && (
          <Badge color="white" style={{ width: "20px" }}>
            {matrixData.items.length}
          </Badge>
        )}
      </td>
    </>
  );
};

export const trancuateStr = (str: string, nrofChars = 10) => {
  if (str == null) {
    return "";
  }
  return str.length > nrofChars ? str.substring(0, nrofChars) + "..." : str;
};

export default ScoreMatrix;
