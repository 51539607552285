import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import TabSidebar from "../../../components/TabSidebar/tabSidebar";
import { ISectionWithItems } from "../../../types";
import EnabledApps from "./AppAccessHandler";
import GeneralProjectSettingsPage from "./GeneralProjectSettingsPage";
import OrgChartSettingsPage from "./OrgChartSettingsPage";
import ProjectDisciplineSettingsPage from "./ProjectDisciplineSettingsPage";
import ProjectMilestoneSettingsPage from "./ProjectMilestoneSettingsPage";
import ArcGisSettingsPage from "./arcgisSettings/ArcGisSettingsPage";
import ConnectedBim360Project from "./bim360Settings/ConnectedBim360Project";
import MembersPage from "./members/MembersPage";
import MCBIMSettingsPage from "./mcbim/MCBIMSettingsPage";
import { useAppSelector } from "../../../hooks";
import { selectIsUserMutilconsult, selectUserMember } from "../../../global/globalSlice";
import PNS from "../pns/PNS";

const AppSettingsPage: React.FC<{ projectId: string }> = ({ projectId }) => {
  const activeApp = sessionStorage.getItem("activeApp");
  let history = useHistory();
  let location = useLocation();
  const arr = location.pathname.split("settings/")[1];
  const pathname = arr.split("/")[0];
  const [baseUrl, setBaseUrl] = useState<string>();

  const userMember = useAppSelector(selectUserMember);

  const userIsMulticonsult = useAppSelector(selectIsUserMutilconsult);

  const sections: ISectionWithItems[] = [
    {
      label: "Generell",
      items: [
        {
          label: "Aktiverte applikasjoner",
          value: "apps",
          showItem: userMember.group === "ADMIN" || userMember.group === "INTERNAL",
        },
        {
          label: "Prosjekt",
          value: "general",
        },
        {
          label: "Disipliner",
          value: "disciplines",
        },
        {
          label: "Milepæler",
          value: "milestones",
        },
        {
          label: "Organisasjon",
          value: "orgchart",
        },
        {
          label: "PNS",
          value: "pns",
        },
      ],
    },
    {
      label: "Integrasjoner",
      items: [
        {
          label: "ArcGIS",
          value: "arcgissettings",
          isEnabled: userIsMulticonsult,
        },
        {
          label: "Bim360",
          value: "bim360settings",
          isEnabled: userIsMulticonsult,
        },
        {
          label: "MCBIM",
          value: "mcbim",
          isEnabled: userIsMulticonsult,
        },
      ],
    },
  ];

  useEffect(() => {
    setBaseUrl(`/project/${projectId}/settings/`);
  }, [projectId]);

  const setUrl = (item: any) => {
    history.push({
      pathname: `${baseUrl}${item.value}`,
    });
  };

  const getSections = () => {
    return sections.map((sec) => {
      sec.items = sec.items.filter((sectionItem) => sectionItem.isEnabled !== false);
      return sec;
    });
  };

  return (
    <div className="d-flex h-100 w-100">
      <div style={{ background: "#eeeeee" }}>
        <TabSidebar
          sections={getSections()}
          activeTab={pathname}
          onClick={setUrl}
          collapsed={activeApp !== "settings"}
        />
      </div>

      <div className="h-100 w-100 overflow-auto">
        <div className="border p-3 h-100">
          <Switch>
            <Route path={`${baseUrl}general`}>
              <GeneralProjectSettingsPage />
            </Route>
            <Route path={`${baseUrl}members`}>
              <MembersPage projectId={projectId} />
            </Route>
            <Route path={`${baseUrl}apps`}>
              <EnabledApps />
            </Route>
            <Route path={`${baseUrl}pns`}>
              <PNS />
            </Route>
            <Route path={`${baseUrl}orgchart`}>
              <OrgChartSettingsPage />
            </Route>
            <Route path={`${baseUrl}bim360settings`}>
              <ConnectedBim360Project />
            </Route>
            {userIsMulticonsult === true && (
              <Route path={`${baseUrl}arcgissettings`}>
                <ArcGisSettingsPage />
              </Route>
            )}
            {userIsMulticonsult === true && (
              <Route path={`${baseUrl}mcbim`}>
                <MCBIMSettingsPage />
              </Route>
            )}
            <Route path={`${baseUrl}disciplines`}>
              <ProjectDisciplineSettingsPage />
            </Route>
            <Route path={`${baseUrl}milestones`}>
              <ProjectMilestoneSettingsPage />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default AppSettingsPage;
