import { createHeadlessEditor } from "@lexical/headless";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalClickableLinkPlugin from "@lexical/react/LexicalClickableLinkPlugin";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { $createParagraphNode, $createTextNode, $getRoot, $nodesOfType } from "lexical";
import { isMyTasksUrl } from "../../app/utils";
import "./RichTextEditorStyles.css";
import { MentionNode } from "./nodes/mentionNode";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import NewMentionsPlugin from "./plugins/MentionPlugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import ExampleTheme from "./theme";
import { IMember } from "../../../../types/global.types";
import InlineImagePlugin from "./plugins/InlineImagePlugin";
import { InlineImageNode } from "./nodes/InlineImageNode";
import DragDropPaste from "./plugins/DragDropPaste";

function Placeholder({ empty }) {
  return <div className="editor-placeholder">{empty ? "" : "Bruk @ for å tagge noen"}</div>;
}
const lexicalEditor = createHeadlessEditor({
  namespace: "Editor",
  nodes: [],
  onError: console.error,
});

export async function plainTextToLexicalState(text: string): Promise<string> {
  const isLexicalFormat = (text: string) => {
    const prefix = '{"root":{"children":[';
    return text.startsWith(prefix);
  };
  if (isLexicalFormat(text)) {
    return text;
  }
  const editorState = await new Promise((resolve) => {
    lexicalEditor.registerUpdateListener(({ editorState }) => {
      resolve(editorState);
    });

    lexicalEditor.update(() => {
      const paragraph = $createParagraphNode();
      const textNode = $createTextNode(text);

      paragraph.append(textNode);

      $getRoot().clear().append(paragraph);
    });
  });

  return JSON.stringify(editorState);
}

export async function lexicalStateToPlainText(state): Promise<string> {
  const editorStateTextString = state.read(() => $getRoot().getTextContent());

  return editorStateTextString;
}

const Editor: React.FC<{
  richTextTree?: string;
  onChange: any;
  showToolBar?: boolean;
  handleMentions: any;
  members?: IMember[];
}> = ({ richTextTree, onChange, showToolBar = true, handleMentions, members }) => {
  const initialState =
    '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}';

  const handleUpdateContent = (editorState) => {
    onChange(
      editorState,
      editorState.read(() => $getRoot().getTextContent()),
      editorState.read(() => {
        const imageNodes = $nodesOfType(InlineImageNode);
        return imageNodes?.length > 0;
      }),
    );
  };
  const editorConfig = {
    editable: true,
    namespace: "MyEditor",
    editorState: richTextTree ? richTextTree : initialState,
    theme: ExampleTheme,
    onError(error) {
      throw error;
    },
    nodes: [HeadingNode, ListNode, ListItemNode, QuoteNode, AutoLinkNode, LinkNode, MentionNode, InlineImageNode],
  };
  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div className=" w-100 commentCard">
        {showToolBar && <ToolbarPlugin />}
        <div className="editor-inner">
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input mcinput" />}
            placeholder={<Placeholder empty={isMyTasksUrl()} />}
            ErrorBoundary={LexicalErrorBoundary}
          />
          {members != null && <NewMentionsPlugin members={members} handleMentions={(e) => handleMentions(e)} />}
          <AutoFocusPlugin />
          <ListPlugin />
          <AutoLinkPlugin />
          <InlineImagePlugin />
          <DragDropPaste />
          {/* <LinkPlugin /> */}
        </div>
        <OnChangePlugin onChange={(editorState) => handleUpdateContent(editorState)} />
      </div>
    </LexicalComposer>
  );
};
function ParsePlainTextPlugin({ text }) {
  const [editor] = useLexicalComposerContext();
  const isLexicalFormat = (text: string) => {
    const prefix = '{"root":{"children":[';
    return text.startsWith(prefix);
  };

  if (isLexicalFormat(text)) {
    const editorState = editor.parseEditorState(text);
    queueMicrotask(() => {
      editor.setEditorState(editorState);
    });
  } else {
    editor.update(() => {
      const paragraph = $createParagraphNode();
      const textNode = $createTextNode(text);

      paragraph.append(textNode);

      $getRoot().clear().append(paragraph);
    });
  }

  return null;
}

export const RichTextReader = ({ richTextTree }) => {
  const initialState =
    '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}';
  const readerConfig = {
    editable: false,
    editorState: initialState,
    namespace: "MyEditor",
    theme: ExampleTheme,
    onError(error) {
      throw error;
    },
    nodes: [HeadingNode, ListNode, ListItemNode, QuoteNode, LinkNode, AutoLinkNode, MentionNode, InlineImageNode],
  };

  return (
    <LexicalComposer initialConfig={readerConfig}>
      <div className="w-100 commentCard">
        <ParsePlainTextPlugin text={richTextTree} />
        <AutoLinkPlugin />
        <RichTextPlugin
          contentEditable={<ContentEditable className="" />}
          placeholder={<Placeholder empty={isMyTasksUrl()} />}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <LexicalClickableLinkPlugin newTab />
        <LinkPlugin />
      </div>
    </LexicalComposer>
  );
};
export default Editor;
