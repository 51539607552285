import { IAccess, IAccessPopulated } from "mc-shared/zod/accessSchema";
import { IProjectDashboard } from "../../../../../types/global.types";
import { apiSlice } from "../../../apiSlice";

export const projectDashboardRTK = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    changeProjectDefault: builder.mutation({
      query: ({ newDefaultId, prevDefaultId, projectId }) => ({
        url: `/project/${projectId}/projectdashboard/setdefaultlayout`,
        method: "PUT",
        body: { newDefaultId, prevDefaultId },
      }),
      invalidatesTags: ["ProjectDashboard"],
    }),
    fetchLayouts: builder.query<IProjectDashboard[], string>({
      query: (projectId) => `/project/${projectId}/projectdashboard/`,
      providesTags: ["ProjectDashboard"],
    }),
    doSaveNewLayout: builder.mutation({
      query: ({ projectId, newLayoutAccessPopulated }) => {
        const accessPopulated = newLayoutAccessPopulated.access as IAccessPopulated;
        const access: IAccess = {
          level: accessPopulated.level,
          groups: accessPopulated.groups,
          users: accessPopulated.users.map((user) => user._id),
        };
        const newLayout: IProjectDashboard = { ...newLayoutAccessPopulated, access };
        delete newLayout._id;
        return {
          url: `/project/${projectId}/projectdashboard/`,
          method: "POST",
          body: { newLayout },
        };
      },
      invalidatesTags: ["ProjectDashboard"],
    }),
    doUpdateLayout: builder.mutation({
      query: ({ projectId, newLayout }) => ({
        url: `/project/${projectId}/projectdashboard/`,
        method: "PUT",
        body: { newLayout },
      }),
      invalidatesTags: ["ProjectDashboard"],
    }),

    doRemoveLayout: builder.mutation({
      query: ({ projectId, layoutId }) => ({
        url: `/project/${projectId}/projectdashboard/${layoutId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ProjectDashboard"],
    }),
    saveEmbeddedLinks: builder.mutation({
      query: ({ projectId, links, layoutId }) => ({
        url: `/project/${projectId}/projectdashboard/embeddedlink`,
        method: "POST",
        body: { links, layoutId },
      }),
      invalidatesTags: ["ProjectDashboard"],
    }),
    saveLinks: builder.mutation({
      query: ({ projectId, links, layoutId }) => ({
        url: `/project/${projectId}/projectdashboard/link`,
        method: "POST",
        body: { links, layoutId },
      }),
      invalidatesTags: ["ProjectDashboard"],
    }),
    updateDashboardAccess: builder.mutation({
      query: ({ projectId, layoutId, access }) => ({
        url: `/project/${projectId}/projectdashboard/access/${layoutId}`,
        method: "PUT",
        body: { access },
      }),
      invalidatesTags: ["ProjectDashboard"],
    }),
    uploadDashboardImage: builder.mutation<void, { image: File; projectId: string; dashboardId: string }>({
      query: ({ image, projectId, dashboardId }) => {
        const data = new FormData();
        data.append("fileToUpload", image);
        return {
          url: `/project/${projectId}/projectdashboard/slideshow/${dashboardId}/upload`,
          method: "POST",
          body: data,
          formData: true,
        };
      },
      invalidatesTags: ["ProjectDashboard"],
    }),
    deleteDashboardImage: builder.mutation<void, { projectId: string; dashboardId: string; imageId: string }>({
      query: ({ projectId, dashboardId, imageId }) => ({
        url: `/project/${projectId}/projectdashboard/slideshow/${dashboardId}/file/${imageId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ProjectDashboard"],
    }),
  }),
});

export const {
  useChangeProjectDefaultMutation,
  useFetchLayoutsQuery,
  useDoSaveNewLayoutMutation,
  useDoUpdateLayoutMutation,
  useDoRemoveLayoutMutation,
  useSaveEmbeddedLinksMutation,
  useSaveLinksMutation,
  useUpdateDashboardAccessMutation,
  useUploadDashboardImageMutation,
  useDeleteDashboardImageMutation,
} = projectDashboardRTK;
