import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FlatHierarchy, IMember, IPNSProps, IRisiko } from "../../../../../types/global.types";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { IBootstrapTableColumnConfig } from "../../../types";
import { doGetProjectMembers, doGetProjectPnsItems } from "../../projectApi";
import { sortArrayByNumber } from "../../utils";
import { getEvaluationColumns, getRisikoColumns } from "./RisikoColumns";
import RisikoItem from "./RisikoItem";
import { RisikoScoreFormatter } from "./Risikotab/RisikoTab";
import { fetchRisikoAndReplaceItInRedux, setRisikoCategoriesInRedux, setSelectedRisikoInRedux } from "./actions";
import { RisikoItemTabEnum } from "./reducer";
import { doGetRisikoEvaluationCategories, doGetSingleRisiko, doGetSingleRisikoBoard } from "./risikoAPI";
import { addRisikoEvaluationKeysToRisiko, createRisikoTagMultifilter } from "./util";
import { removeAppModalInRedux, setShowSuccessMessageThunk } from "../../../global/globalSlice";

const RisikoItemModalWrapper: React.FC<{
  projectId: string;
  boardId: string;
  risikoId: string;
}> = ({ projectId, boardId, risikoId }) => {
  const [columns, setColumns] = useState<IBootstrapTableColumnConfig[]>(null);
  const [members, setMembers] = React.useState<IMember[]>(null);
  const [pnsItems, setPnsItems] = useState<FlatHierarchy<IPNSProps>>(null);
  const [risiko, setRisiko] = useState<IRisiko>(null);

  const project = useAppSelector((state) => state.adminReducer.project);
  const user = useAppSelector((state) => state.globalReducer.user);
  const activeBoard = useAppSelector((state) => state.risikoReducer.activeBoard);
  const categories = useAppSelector((state) => state.risikoReducer.categories);
  const selectedRisiko = useAppSelector((state) => state.risikoReducer.selectedRisiko);
  const membersInRedux = project?.members;

  useEffect(() => {
    init();
  }, []);

  const setSelectedItemInMainTable = () => {};

  const dispatch = useAppDispatch();

  const init = async (tab?: RisikoItemTabEnum) => {
    let _members = membersInRedux;
    const board = await doGetSingleRisikoBoard(projectId, boardId);
    const tagsFilter = await createRisikoTagMultifilter(projectId, boardId);

    if (membersInRedux == null) {
      _members = await doGetProjectMembers(projectId);
      setMembers(_members);
    } else {
      setMembers(membersInRedux);
    }

    let _pnsItems = project?.pns;

    if (_pnsItems == null) {
      _pnsItems = await doGetProjectPnsItems(projectId);
    }

    setPnsItems(_pnsItems);

    const _columns = getRisikoColumns(
      setSelectedItemInMainTable,
      _members,
      projectId,
      user,
      setShowSuccessMessageThunk,
      (itemId) => dispatch(fetchRisikoAndReplaceItInRedux(itemId)),
      tagsFilter,
      pnsItems,
      board,
    );
    const _categories = await doGetRisikoEvaluationCategories(projectId, boardId);

    const evaluationColumns = getEvaluationColumns(_categories, RisikoScoreFormatter);
    const allColumns = sortArrayByNumber([..._columns, ...evaluationColumns], "order");
    setColumns(allColumns);

    dispatch(setRisikoCategoriesInRedux(_categories));
    const _risiko = await doGetSingleRisiko(projectId, risikoId);
    const _risikoWithEvalObj = addRisikoEvaluationKeysToRisiko(_risiko, _categories, board);
    setRisiko(_risikoWithEvalObj);
    dispatch(
      setSelectedRisikoInRedux({
        _id: risikoId,
        activeItemTab: tab || RisikoItemTabEnum.INFO,
      }),
    );
  };

  const forceRefetchOfData = async () => {
    const board = await doGetSingleRisikoBoard(projectId, boardId);
    const _categories = await doGetRisikoEvaluationCategories(projectId, boardId);
    const _risiko = await doGetSingleRisiko(projectId, risikoId);
    const _risikoWithEvalObj = addRisikoEvaluationKeysToRisiko(_risiko, _categories, board);
    setRisiko(_risikoWithEvalObj);
  };

  const history = useHistory();
  const closeModal = () => {
    history.replace({
      pathname: history.location.pathname,
      search: "",
    });
    dispatch(removeAppModalInRedux(selectedRisiko._id));
  };

  return (
    <>
      {columns != null && members != null && (
        <Modal fade={false} style={{ maxWidth: "1600px", width: "80%" }} isOpen toggle={closeModal}>
          {risiko != null && activeBoard != null && categories != null && selectedRisiko != null && (
            <>
              <ModalHeader toggle={closeModal}>
                {risiko.id} - {risiko.title}
              </ModalHeader>
              <ModalBody>
                <RisikoItem
                  columns={columns}
                  risiko={risiko}
                  onSaved={(tab) => init(tab)}
                  forceRefetchOfData={forceRefetchOfData}
                />
              </ModalBody>
            </>
          )}
        </Modal>
      )}
    </>
  );
};

export default RisikoItemModalWrapper;
