import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsGantt from "highcharts/modules/gantt";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { IDateRange, IIssue } from "../../../../../../../../types/global.types";
import { sortArrayByString } from "../../../../../utils";
import { selectSingleIssueBoard } from "../../../issueSlice";
import { getIssueRegisterNameOption } from "../../../issueUtils";
import { getGanttSeries } from "../gantUtils";

highchartsGantt(Highcharts);

const GanttChart: React.FC<{
  items: IIssue[];
  taskEnabled: boolean;
  dateRange: IDateRange;
  sortValue: string;
}> = ({ items, taskEnabled, dateRange, sortValue }) => {
  const [options, setOptions] = useState<any>(null);
  const issueBoard = useSelector(selectSingleIssueBoard);
  const prevItemsLength = usePrevious(items.length);
  const prevDateRange = usePrevious(dateRange);
  const prevSortValue = usePrevious(sortValue);

  function usePrevious(value: any) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  useEffect(() => {
    if (items.length === prevItemsLength && dateRange === prevDateRange && sortValue === prevSortValue) return;
    setOptions(null);
    const _seriesDataItems = getGanttSeries(items, taskEnabled);
    _options.series = [
      {
        name: null,
        data: [...sortArrayByString(_seriesDataItems, "title")],
      },
    ];
    //this allows highcharts to recalculate height before rendering
    setTimeout(() => {
      setOptions({ ..._options });
    }, 100);
  }, [items, dateRange, prevItemsLength, taskEnabled]);
  useEffect(() => {
    Highcharts.setOptions({
      lang: {
        months: [
          "januar",
          "februar",
          "mars",
          "april",
          "mai",
          "juni",
          "juli",
          "august",
          "september",
          "oktober",
          "november",
          "desember",
        ],
        weekdays: ["søndag", "mandag", "tirsdag", "onsdag", "torsdag", "fredag", "lørdag"],
      },
    });
  }, []);
  // Utility functions
  let dateFormat = Highcharts.dateFormat;
  let defined = Highcharts.defined;
  let isObject = Highcharts.isObject;
  const _options = {
    accessibility: { enabled: false }, //to remove warning
    series: [],
    tooltip: {
      outside: true,
      pointFormatter: function () {
        var point = this,
          format = "%e. %b",
          options = point.options,
          completed = options.completed,
          amount = isObject(completed) ? completed.amount : completed,
          status = (amount || 0) * 100 + "%",
          lines;

        lines = [
          {
            value: point.name,
            style: "font-weight: bold;",
          },
          {
            title: "Startdato",
            value: dateFormat(format, point.start),
          },
          {
            visible: !options.milestone,
            title: "Sluttdato",
            value: dateFormat(format, point.end),
          },
          {
            title: "Status",
            value: status,
          },
          {
            title: "Eier",
            value: options.owner || ".",
          },
          { title: "Ansvarlig", value: options.assignedTo || "-" },
        ];

        return lines.reduce(function (str, line) {
          var s = "",
            style = defined(line.style) ? line.style : "font-size: 0.8em;";
          if (line.visible !== false) {
            s =
              '<span style="' +
              style +
              '">' +
              (defined(line.title) ? line.title + ": " : "") +
              (defined(line.value) ? line.value : "") +
              "</span><br/>";
          }
          return str + s;
        }, "");
      },
    },
    yAxis: {
      min: 0,
      labels: {
        style: { overflow: "hidden", textOverflow: "ellipsis", maxWidth: "280px" },
        useHTML: true,
        events: {
          click: function () {
            console.log(this);
          },
        },
      },
    },
    xAxis: {
      currentDateIndicator: true,
      min: new Date(dateRange.startDate).getTime(),
      max: new Date(dateRange.endDate).getTime(),
      gridLineWidth: 1,
      gridLineColor: "#efefef",
    },
  };

  return (
    <>
      {items.length === 0 && (
        <div className="d-flex justify-content-center align-items-center h-100 w-100">
          <h3 className="text-muted">
            Ingenting å vise! Ingen {getIssueRegisterNameOption(issueBoard?.issueNameSetting, "plural")?.toLowerCase()}{" "}
            med frist tilgjengelig.
          </h3>
        </div>
      )}
      {options != null && items.length > 0 && dateRange.startDate?.length > 0 && (
        <div className="h-100 w-100" style={{ overflow: "auto" }}>
          <HighchartsReact
            // containerProps={{ style: { height: "100%" } }}
            highcharts={Highcharts}
            constructorType={"ganttChart"}
            options={options}
          />
        </div>
      )}
    </>
  );
};

export default GanttChart;
