import { IGKMaalingAvLufthastighetILukeaapningResultater, IGKRapport } from "mc-shared/zod/report/GK/gkReportSchema";
import { useEffect } from "react";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../../types/global.types";
import { HSpace, McInput } from "../../../../../../components";
import ReportDocxImageContainer from "../../../ReportBoard/ReportDocxImageContainer";
import {
  beregnetGjennomsnittligHastighet,
  beregnetLuftmengdeGjennomLukeaapning,
  beregnetProduktMedToVerdier,
  beregnetStandardAvvik,
  beregnetVarians,
} from "../gkGlobalUtils";

const MaalingAvLufthastighetOgLuftmengdeILukeaapning: React.FC<{
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
  data: IGKMaalingAvLufthastighetILukeaapningResultater;
}> = ({ data, onUpdateEditData }) => {
  useEffect(() => {
    utdataBeregninger();
  }, [
    data.inndata.maaleresultaterHoeyLukeaapning,
    data.inndata.maaleresultaterLavLukeaapning,
    data.inndata.maaleresultaterLavLukeaapning?.length,
    data.inndata.maaleresultaterHoeyLukeaapning?.length,
    data.inndata.dimensjoner.lukeaapninghoeydeHoey,
    data.inndata.dimensjoner.lukeaapninghoeydeLav,
    data.inndata.dimensjoner.skapbredde,
  ]);

  const addNewRow = (hoeyEllerLav: "høy" | "lav") => {
    if (hoeyEllerLav === "høy") {
      const maaleresultaterArray = data.inndata.maaleresultaterHoeyLukeaapning;
      maaleresultaterArray.push({
        maalepunktNummer: null,
        maaleresultat: null,
      });
      onUpdateEditData(
        "testResultater.maalingAvLufthastighetILukeaapningIgangkjoering.inndata.maaleresultaterHoeyLukeaapning",
        maaleresultaterArray,
      );
    }
    if (hoeyEllerLav === "lav") {
      const maaleresultaterArray = data.inndata.maaleresultaterLavLukeaapning;
      maaleresultaterArray.push({
        maalepunktNummer: null,
        maaleresultat: null,
      });
      onUpdateEditData(
        "testResultater.maalingAvLufthastighetILukeaapningIgangkjoering.inndata.maaleresultaterLavLukeaapning",
        maaleresultaterArray,
      );
    }
  };

  const removeLastRow = (hoeyEllerLav: "høy" | "lav") => {
    if (hoeyEllerLav === "høy") {
      const updatedMaaleresultater = data.inndata.maaleresultaterHoeyLukeaapning;

      if (updatedMaaleresultater?.length > 0) {
        updatedMaaleresultater.pop(); // Remove the last element
        onUpdateEditData(
          "testResultater.maalingAvLufthastighetILukeaapningIgangkjoering.inndata.maaleresultaterHoeyLukeaapning",
          updatedMaaleresultater,
        );
      }
    }
    if (hoeyEllerLav === "lav") {
      const updatedMaaleresultater = data.inndata.maaleresultaterLavLukeaapning;

      if (updatedMaaleresultater?.length > 0) {
        updatedMaaleresultater.pop(); // Remove the last element
        onUpdateEditData(
          "testResultater.maalingAvLufthastighetILukeaapningIgangkjoering.inndata.maaleresultaterLavLukeaapning",
          updatedMaaleresultater,
        );
      }
    }
  };

  const updateMaaleresultater = (resultat: number, i: number, parameter, hoeyEllerLav: "høy" | "lav") => {
    if (hoeyEllerLav === "høy") {
      const maaleresultater = [...data.inndata.maaleresultaterHoeyLukeaapning];
      maaleresultater[i][parameter] = resultat;
      onUpdateEditData(
        "testResultater.maalingAvLufthastighetILukeaapningIgangkjoering.inndata.maaleresultaterHoeyLukeaapning",
        maaleresultater,
      );
    }
    if (hoeyEllerLav === "lav") {
      const maaleresultater = [...data.inndata.maaleresultaterLavLukeaapning];
      maaleresultater[i][parameter] = resultat;
      onUpdateEditData(
        "testResultater.maalingAvLufthastighetILukeaapningIgangkjoering.inndata.maaleresultaterLavLukeaapning",
        maaleresultater,
      );
    }
  };

  const utdataBeregninger = () => {
    let lukeaapningArealHoey =
      beregnetProduktMedToVerdier(data.inndata.dimensjoner.skapbredde, data.inndata.dimensjoner.lukeaapninghoeydeHoey) /
      10000; // cm^2 til m^2
    let gjennomsnittligHastighetHoey = beregnetGjennomsnittligHastighet(
      data.inndata.maaleresultaterHoeyLukeaapning.map((resultat) => resultat.maaleresultat),
      data.inndata.maaleresultaterHoeyLukeaapning?.length,
    );
    let luftmengde = beregnetLuftmengdeGjennomLukeaapning(gjennomsnittligHastighetHoey, lukeaapningArealHoey);
    let gjennomsnittligHastighetLav = beregnetGjennomsnittligHastighet(
      data.inndata.maaleresultaterLavLukeaapning.map((resultat) => resultat.maaleresultat),
      data.inndata.maaleresultaterLavLukeaapning?.length,
    );
    let variansHoey = beregnetVarians(
      data.inndata.maaleresultaterHoeyLukeaapning.map((resultat) => resultat.maaleresultat),
      gjennomsnittligHastighetHoey,
    );
    let variansLav = beregnetVarians(
      data.inndata.maaleresultaterLavLukeaapning.map((resultat) => resultat.maaleresultat),
      gjennomsnittligHastighetLav,
    );
    let standardAvvikHoey = beregnetStandardAvvik(variansHoey);
    let standardAvvikLav = beregnetStandardAvvik(variansLav);
    const utdata = {
      beregningerHoeyLukeaapning: {
        luftmengde: luftmengde,
        gjennomsnittligHastighet: gjennomsnittligHastighetHoey,
        varians: variansHoey,
        standardAvvik: standardAvvikHoey,
        lukeaapningAreal: lukeaapningArealHoey,
      },
      beregningerLavLukeaapning: {
        gjennomsnittligHastighet: gjennomsnittligHastighetLav,
        varians: variansLav,
        standardAvvik: standardAvvikLav,
      },
    };
    onUpdateEditData("testResultater.maalingAvLufthastighetILukeaapningIgangkjoering.utdata", utdata);
  };

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Måling av lufthastighet i lukeåpning ved igangkjøring</CardTitle>
        <div className="d-flex flex-column mt-0 mb-4">
          <b>Fyll inn spesifisert lufthastighet fra fabrikant &#40;m/s&#41;:</b>
          <McInput
            value={data.inndata.lufthastighetFraFabrikant}
            onBlur={(e) =>
              onUpdateEditData(
                "testResultater.maalingAvLufthastighetILukeaapningIgangkjoering.inndata.lufthastighetFraFabrikant",
                Number(e.target.value),
              )
            }
            normalizeNumbers={true}
            type="text"
            className="w-25"
          />
        </div>
        <div className="d-flex flex-column mt-0 mb-4">
          <b>Fyll inn skapbredde &#40;cm&#41;:</b>
          <McInput
            value={data.inndata.dimensjoner.skapbredde}
            onBlur={(e) =>
              onUpdateEditData(
                "testResultater.maalingAvLufthastighetILukeaapningIgangkjoering.inndata.dimensjoner.skapbredde",
                Number(e.target.value),
              )
            }
            normalizeNumbers={true}
            type="text"
            className="w-25"
          />
        </div>
        <div className="d-flex flex-column mt-0 mb-4">
          <b>Fyll inn lukeåpningshøyde ved høy åpning &#40;cm&#41;:</b>
          <McInput
            value={data.inndata.dimensjoner.lukeaapninghoeydeHoey}
            onBlur={(e) =>
              onUpdateEditData(
                "testResultater.maalingAvLufthastighetILukeaapningIgangkjoering.inndata.dimensjoner.lukeaapninghoeydeHoey",
                Number(e.target.value),
              )
            }
            normalizeNumbers={true}
            type="text"
            className="w-25"
          />
        </div>
        <div className="d-flex flex-column mt-0 mb-4">
          <b>Fyll inn lukeåpningshøyde ved lav åpning &#40;cm&#41;:</b>
          <McInput
            value={data.inndata.dimensjoner.lukeaapninghoeydeLav}
            onBlur={(e) =>
              onUpdateEditData(
                "testResultater.maalingAvLufthastighetILukeaapningIgangkjoering.inndata.dimensjoner.lukeaapninghoeydeLav",
                Number(e.target.value),
              )
            }
            normalizeNumbers={true}
            type="text"
            className="w-25"
          />
        </div>
        <div className="d-flex flex-column mt-2">
          <b>Måletiden er 60 sekunder &#40;s&#41;:</b>
        </div>
        <div className="mt-4">
          <h4>Måleresultater ved høy åpning</h4>
          <div className="mt-0">
            <b>Legg til flere punkter ved behov:</b>
            <div className="d-flex">
              <Button color="success" onClick={() => addNewRow("høy")}>
                Legg til rad
              </Button>
              <HSpace />
              <Button
                color="danger"
                onClick={() => removeLastRow("høy")}
                disabled={data.inndata.maaleresultaterHoeyLukeaapning?.length < 10}
              >
                Fjern siste rad
              </Button>
            </div>
          </div>
          <div className="mt-2 d-flex flex-column">
            <b>Fyll inn måleresultater &#40;m/s&#41;:</b>
            {data.inndata.maaleresultaterHoeyLukeaapning.map((a, i) => (
              <div className="d-flex" key={i}>
                <McInput
                  className="mb-1 mr-1 w-25"
                  value={a.maalepunktNummer}
                  normalizeNumbers={true}
                  type="text"
                  onBlur={(e) => updateMaaleresultater(Number(e.target.value), i, "maalepunktNummer", "høy")}
                  placeholder="Målepunkt"
                />
                <McInput
                  className="mb-1 w-25"
                  value={a.maaleresultat}
                  normalizeNumbers={true}
                  type="text"
                  onBlur={(e) => updateMaaleresultater(Number(e.target.value), i, "maaleresultat", "høy")}
                  placeholder="Måleresultat"
                />
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-between">
            <p className="mb-0">
              Gjennomsnittlig hastighet &#40;m/s&#41; ={" "}
              <b>
                {beregnetGjennomsnittligHastighet(
                  data.inndata.maaleresultaterHoeyLukeaapning.map((resultat) => resultat.maaleresultat),
                  data.inndata.maaleresultaterHoeyLukeaapning?.length,
                )?.toFixed(2) ?? ""}
              </b>
            </p>
            <p className="mb-0">Formel: &#40;Va = &#40;SUM Vn&#41; / N&#41;</p>
          </div>
          <hr className="mt-0" />
          <div className="d-flex justify-content-between">
            <p className="mb-0">
              Varians &#40;-&#41; =
              <b>
                {" "}
                {beregnetVarians(
                  data.inndata.maaleresultaterHoeyLukeaapning.map((resultat) => resultat.maaleresultat),
                  data.utdata.beregningerHoeyLukeaapning.gjennomsnittligHastighet,
                )?.toFixed(4) ?? ""}
              </b>
            </p>
            <p className="mb-0">
              Formel: &#40;VRS = &#40;&#40;SUM Vn<sup>2</sup>&#41; - N * &#40;Va<sup>2</sup>&#41;&#41; / &#40;N -
              1&#41;&#41;
            </p>
          </div>
          <hr className="mt-0" />
          <div className="d-flex justify-content-between">
            <p className="mb-0">
              Standardavvik &#40;-&#41; =
              <b> {beregnetStandardAvvik(data.utdata.beregningerHoeyLukeaapning.varians)?.toFixed(4) ?? ""}</b>
            </p>
            <p className="mb-0">
              Formel: &#40;Sigma = VRS<sup>0,5</sup>&#41;
            </p>
          </div>
          <hr className="mt-0" />
          <div className="d-flex justify-content-between">
            <p className="mb-0">
              Luftmengde &#40;m<sup>2</sup>&#41; =
              <b>
                {" "}
                {beregnetLuftmengdeGjennomLukeaapning(
                  data.utdata.beregningerHoeyLukeaapning.gjennomsnittligHastighet,
                  data.utdata.beregningerHoeyLukeaapning.lukeaapningAreal,
                )?.toFixed(4) ?? ""}
              </b>
            </p>
            <p className="mb-0">Formel: &#40;Q = Va * Aluke&#41;</p>
          </div>
        </div>
        <div className="mt-4">
          <h4>Måleresultater ved lav åpning</h4>
          <div className="mt-0">
            <b>Legg til flere punkter ved behov:</b>
            <div className="d-flex">
              <Button color="success" onClick={() => addNewRow("lav")}>
                Legg til rad
              </Button>
              <HSpace />
              <Button
                color="danger"
                onClick={() => removeLastRow("lav")}
                disabled={data.inndata.maaleresultaterLavLukeaapning?.length < 4}
              >
                Fjern siste rad
              </Button>
            </div>
          </div>
          <div className="mt-4 d-flex flex-column">
            <b>Fyll inn måleresultater &#40;m/s&#41;:</b>
            {data.inndata.maaleresultaterLavLukeaapning.map((a, i) => (
              <div className="d-flex" key={i}>
                <McInput
                  className="mb-1 mr-1 w-25"
                  value={a.maalepunktNummer}
                  normalizeNumbers={true}
                  type="text"
                  onBlur={(e) => updateMaaleresultater(Number(e.target.value), i, "maalepunktNummer", "lav")}
                  placeholder="Målepunkt"
                />
                <McInput
                  className="mb-1 w-25"
                  value={a.maaleresultat}
                  normalizeNumbers={true}
                  type="text"
                  onBlur={(e) => updateMaaleresultater(Number(e.target.value), i, "maaleresultat", "lav")}
                  placeholder="Måleresultat"
                />
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-between">
            <p className="mb-0">
              Gjennomsnittlig hastighet &#40;m/s&#41; ={" "}
              <b>
                {beregnetGjennomsnittligHastighet(
                  data.inndata.maaleresultaterLavLukeaapning.map((resultat) => resultat.maaleresultat),
                  data.inndata.maaleresultaterLavLukeaapning?.length,
                )?.toFixed(2) ?? ""}
              </b>
            </p>
            <p className="mb-0">Formel: &#40;Va = &#40;SUM Vn&#41; / N&#41;</p>
          </div>
          <hr className="mt-0" />
          <div className="d-flex justify-content-between">
            <p className="mb-0">
              Varians &#40;-&#41; =
              <b>
                {" "}
                {beregnetVarians(
                  data.inndata.maaleresultaterLavLukeaapning.map((resultat) => resultat.maaleresultat),
                  data.utdata.beregningerLavLukeaapning.gjennomsnittligHastighet,
                )?.toFixed(4) ?? ""}
              </b>
            </p>
            <p className="mb-0">
              Formel: &#40;VRS = &#40;&#40;SUM Vn<sup>2</sup>&#41; - N * &#40;Va<sup>2</sup>&#41;&#41; / &#40;N -
              1&#41;&#41;
            </p>
          </div>
          <hr className="mt-0" />
          <div className="d-flex justify-content-between">
            <p className="mb-0">
              Standardavvik &#40;-&#41; =
              <b> {beregnetStandardAvvik(data.utdata.beregningerLavLukeaapning.varians)?.toFixed(4) ?? ""}</b>
            </p>
            <p className="mb-0">
              Formel: &#40;Sigma = VRS<sup>0,5</sup>&#41;
            </p>
          </div>
        </div>
        <hr className="mt-0" />
        <div className="mt-4">
          <ReportDocxImageContainer
            docxImageTag="maalingAvLufthastighetILukeaapningIgangkjoering"
            title="Målepunkter i rommet"
          />
          <div className="w-50">
            <b>Kommentar:</b>
            <McInput
              value={data.inndata.kommentar}
              onChange={(e) =>
                onUpdateEditData(
                  "testResultater.maalingAvLufthastighetILukeaapningIgangkjoering.inndata.kommentar",
                  e.target.value,
                )
              }
              type="textarea"
              dynamicHeight={true}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default MaalingAvLufthastighetOgLuftmengdeILukeaapning;
