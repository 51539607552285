import update from "immutability-helper";
import { IMinUser, IProject } from "../../../types/global.types";
import { IMcBimMongo } from "../../../types/mcbimMono.types";
import {
  RESET_PROJECT,
  SET_DISCIPLINES,
  SET_MCBIM,
  SET_MCBIM_PROJECT_SPACES,
  SET_MILESTONES,
  SET_ORG_CHART,
  SET_PNS,
  SET_PROJECT,
  SET_PROJECT_APPS,
  SET_PROJECT_ARC_GIS,
  SET_PROJECT_NAME,
  SET_PROJECT_USE_DASHBOARD_AS_DEFAULT,
  SET_RISIKOCOLUMN_TEMPLATES,
  SET_USE_DEFAULT_DISCIPLINES,
  UPDATE_MEMBER_GROUP,
} from "./constants";

export interface IAdminReducer {
  project: IProject;
  pathToRedirectTo: string;
  mcbimSpaces: IMcBimMongo.SpaceBasicInfo[];
  mccosAdmins: IMinUser[];
}

const initialState: IAdminReducer = {
  project: null,
  pathToRedirectTo: null,
  mcbimSpaces: null,
  mccosAdmins: null,
};

export const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROJECT:
      return { ...state, project: action.payload.project };
    case SET_PROJECT_APPS:
      return update(state, {
        project: { apps: { $set: action.payload.apps } },
      });
    case UPDATE_MEMBER_GROUP:
      return update(state, {
        project: {
          members: { [action.payload.memberIndex]: { group: { $set: action.payload.group } } },
        },
      });
    case SET_PROJECT_NAME:
      return update(state, {
        project: {
          maconomy: {
            name: {
              $set: action.payload.name,
            },
          },
        },
      });
    case SET_PROJECT_USE_DASHBOARD_AS_DEFAULT:
      return update(state, {
        project: {
          useDashboardAsDefaultStartPage: {
            $set: action.payload.useDashboardAsDefaultStartPage,
          },
        },
      });
    case SET_ORG_CHART:
      return update(state, {
        project: {
          orgChart: {
            $set: action.payload.orgChart,
          },
        },
      });
    case SET_PROJECT_ARC_GIS:
      return update(state, {
        project: {
          arcgis: {
            $set: action.payload.arcgis,
          },
        },
      });
    case SET_RISIKOCOLUMN_TEMPLATES:
      return update(state, {
        project: {
          risikoColumnTemplates: {
            $set: action.payload.risikoColumnTemplates,
          },
        },
      });
    case SET_USE_DEFAULT_DISCIPLINES:
      return update(state, {
        project: {
          useDefaultDisciplines: {
            $set: action.payload.useDefaultDisciplines,
          },
        },
      });
    case SET_DISCIPLINES:
      return update(state, {
        project: {
          disciplines: {
            $set: action.payload.disciplines,
          },
        },
      });
    case SET_MILESTONES:
      return update(state, {
        project: {
          milestones: {
            $set: action.payload.milestones,
          },
        },
      });
    case SET_MCBIM:
      return update(state, {
        project: {
          mcbim: {
            $set: action.payload.mcbim,
          },
        },
      });
    case SET_PNS:
      return update(state, {
        project: {
          pns: {
            $set: action.payload,
          },
        },
      });
    case SET_MCBIM_PROJECT_SPACES:
      return { ...state, mcbimSpaces: action.payload.mcbimSpaces };
    case RESET_PROJECT:
      return initialState;
    default:
      return state;
  }
};
