import { useState } from "react";
import { Button, Modal, ModalBody, ModalHeader, UncontrolledCarousel } from "reactstrap";
import GlobalFileUploaderTable from "../../../../components/FileUploader/GlobalFileUploaderTable";
import WidgetWrapper from "../../../../components/WidgetWrapper/WidgetWrapper";
import { useAppSelector } from "../../../../hooks";
import { useGetFilesFromAzureBlobStorageQuery } from "../../../azureBlobStorageRTK";

const ImageSlideWidget: React.FC = () => {
  const project = useAppSelector((state) => state.adminReducer.project);
  const layout = useAppSelector((state) => state.projectDashboardReducer.activeLayout);
  const globalEditMode = useAppSelector((state) => state.projectDashboardReducer.editMode);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const path = `${project._id}/projectdashboard/${layout._id}/files`;
  const projectId = project._id;
  const {
    data: images,
    isLoading: loadingFiles,
    isError: errorFetchingFiles,
  } = useGetFilesFromAzureBlobStorageQuery({ projectId, path });

  const SimpleSlider = ({ className = "" }) => {
    const imageSrcs = images.map((image) => ({
      src: `/api/image/fileTable/${image?.blobName}`,
    }));
    return (
      <div className="h-100">
        <UncontrolledCarousel items={imageSrcs} className={className} />
      </div>
    );
  };

  return (
    <WidgetWrapper
      widgetKey="ImageSlide"
      title="Bilder"
      onFullscreenClick={true ? () => setOpenModal(true) : null}
      onShowSettingsClick={() => setEditMode(!editMode)}
    >
      <>
        <div className="d-flex flex-column w-100" style={{ height: "calc(100% - 40px" }}>
          {images?.length > 0 ? (
            <>{editMode === false && <SimpleSlider />}</>
          ) : (
            <div
              className="d-flex justify-content-center text-center align-items-center h-100 flex-column "
              onMouseDown={(e) => e.stopPropagation()}
            >
              {globalEditMode === false ? (
                <>
                  <p>Ingen bilder er lagret! Klikk her for å håndtere bilder:</p>
                  <Button className="mc-blue text-white " style={{ zIndex: 3 }} onClick={() => setEditMode(true)}>
                    Håndtere Bilder
                  </Button>
                </>
              ) : (
                <Button disabled>Ingen bilder å vise</Button>
              )}
            </div>
          )}
        </div>
        {editMode && (
          <>
            <Modal style={{ maxWidth: "60vw" }} isOpen={editMode} toggle={() => setEditMode(false)}>
              <ModalHeader toggle={() => setEditMode(!editMode)}>Håndtere Bilder</ModalHeader>
              <ModalBody style={{ minHeight: "200px" }}>
                <>
                  <GlobalFileUploaderTable
                    path={`${project._id}/projectdashboard/${layout._id}/files`}
                    imageOnly={true}
                  />
                </>
              </ModalBody>
            </Modal>
          </>
        )}
        {openModal && (
          <Modal style={{ maxWidth: "80vw" }} isOpen={openModal} toggle={() => setOpenModal(false)}>
            <ModalHeader toggle={() => setOpenModal(false)}>
              <div className="d-flex justify-content-between w-100"></div>
            </ModalHeader>

            <ModalBody>
              <div className="gray-100" style={{ height: "80vh", borderRadius: "6px" }}>
                <SimpleSlider className="containImage" />
              </div>
            </ModalBody>
          </Modal>
        )}
      </>
    </WidgetWrapper>
  );
};

export default ImageSlideWidget;
