import React, { useState } from "react";
import { Button, Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";
import { ConfirmDialog, McInput } from "../../../../../components";
import { IMCBIMAPI } from "../../../../../../../types/mcbim.types";

const McBimVersionCount: React.FC<{
  mcBimProject: IMCBIMAPI.Project;
  disabled: boolean;
  onSave: (versionCount: number) => void;
}> = ({ mcBimProject, disabled, onSave }) => {
  const [saveEnabled, setSaveEnabled] = useState<boolean>(false);
  const [versionCount, setVersionCount] = useState<string>(
    mcBimProject?.fileVersionCount != null ? mcBimProject?.fileVersionCount.toString() : "",
  );

  const updateMaxVersionCount = async () => {
    onSave(Number(versionCount));
    validateNumber(mcBimProject?.fileVersionCount?.toString());
  };

  const validateNumber = (count: string) => {
    const num = Number(count);
    const current = Number(mcBimProject?.fileVersionCount);
    const enabled = current !== num && /^\d+$/.test(count) && num > 0 && num <= 10;
    setSaveEnabled(enabled);
    setVersionCount(count);
  };

  return (
    <Card className="mt-2">
      <CardBody>
        <CardTitle>Maksimalt antall versjoner</CardTitle>
        <CardSubtitle tag="h6" className="mb-2 text-muted">
          Hvis det finnes flere versjoner enn det maksimale antallet, vil de eldste versjonene bli fjernet
        </CardSubtitle>
        <div className="d-flex flex-column">
          <div className="d-flex flex-column">
            <div className="d-flex">
              <McInput
                disabled={disabled === true}
                type="number"
                min={1}
                max={10}
                style={{ width: "7rem" }}
                value={mcBimProject?.fileVersionCount}
                onChange={(e) => {
                  validateNumber(e.target.value);
                }}
              />
              <ConfirmDialog
                title="Maksimalt antall versjoner"
                message={`Er du sikker på at du vil oppdatere maksimalt antall versjoner? Dersom antall versjoner overskrider ${versionCount} vil eldre versjoner slettes daglig.`}
                confirmCb={() => updateMaxVersionCount()}
                color="success"
                confirmButtonText="Lagre"
              >
                <Button className="ml-2" color="success" disabled={disabled === true || saveEnabled === false}>
                  <i className="fa fa-save fa-fw" />
                </Button>
              </ConfirmDialog>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default McBimVersionCount;
