const PythonDoc = () => {
  return (
    <>
      <h3 className={"mb-5"}>Koble Python til MCBIM-database</h3>
      <h4 className="mb-3 mt-5">
        <div className="textCircle">1</div> Installer PyMongo biblioteket
      </h4>
      <div style={{ marginLeft: "3em" }}>
      <p>For eksempel gjennom å bruke pip (Package installer for Python)</p>
      <pre className="codeBlock"><code>pip install pymongo</code></pre>
      <p>Dokumentasjonen til pymongo kan du finne her <a href="https://pymongo.readthedocs.io/en/stable/">https://pymongo.readthedocs.io/en/stable/</a></p>
      </div>
      <h4 className="mb-3 mt-5">
        <div className="textCircle">2</div> Få URI for prosjektet
      </h4>
      <div style={{ marginLeft: "3em" }}>
      <p>URI er på formen </p>
      <pre className="codeBlock"><code>mongodb+srv://project_number:password@mcbim.fycix.mongodb.net/mcbim?retryWrites=true&w=majority</code></pre>

      {/* <pre className="codeBlock">{`mongodb+srv://project_number:password@mcbim.fycix.mongodb.net/mcbim?retryWrites=true&w=majority`}</pre> */}
      <p>Der prosjektnummeret er det kjente prosjektnummeret og passordet er unikt per prosjekt</p>
      </div>
      <h4 className="mb-3 mt-5">
        <div className="textCircle">3</div> Koble til databasen
      </h4>
      <div style={{ marginLeft: "3em" }}>
      {/* use curly brackets and `to get the text to extend over several lines */}
      <pre className="codeBlock"><code>{`uri = "mongodb+srv://project_number:password@mcbim.fycix.mongodb.net/mcbim?retryWrites=true&w=majority"

#your project number
project_number = "10217313" #example 10217313 = Sotrasambandet
     
#create a MongoClient
client = MongoClient(uri)
      
#Connect to the database for your project
db=client.mcbim[project_number]
      
#Check that you don't get any error messages to see that you have connected successfully by e.g. selecting a random document (row)
example = db.find_one()
print(example)`}
      </code>
      </pre>
      </div>
      <h4 className="mb-3 mt-5">
        <div className="textCircle">4</div> Kodeeksempler
      </h4>
      <div style={{ marginLeft: "3em" }}>
      <pre className="codeBlock"><code>{
`#Example 1: get all the key names (~Column names) from a document (row)
example = db.find_one()
for key in example:
    print(key)

#Example 2: get all disctinct file names
#get all disctinct file names
file_names=db.distinct('fileName')
number_of_files=len(file_names)

#run mongoDB queries in python
#Example 3: Get all ROA (Road) objects with an MMI above 350
#nested dictionaries values can be found by separating with a dot
#eg: finding all ROA (Road) objects with MMI equal or greater than 350 
#in this project the property set is SB_Data and the property for MMI is 'GEN-B02_MMI'
#'psets': {'SB_Data': {'GEN-B02_MMI': '350'}
# add a limit to e.g. get e.g. the first 100 rows

mmi_above_350 = db.find({
    "$and":[{"psets.SB_Data.GEN-B02_MMI": {"$gt" : "350"}},
            {"fileAttr2" : "ROA"},
            {"fileIsLatest" : True}
            ]
}).limit(100) #limit to get the first 100 rows
for i in mmi_above_350:
    print(i)

`}</code></pre>
      </div>
    </>
  );
};

export default PythonDoc;
