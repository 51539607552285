import React from "react";
import { IRisiko } from "../../../../../types/global.types";
import { IButtonWithBadge } from "../../../types";
import ButtonGroupWithBadge from "../../../components/ButtonGroupWithBadge/ButtonGroupWithBadge";
import { RisikoItemTabEnum } from "./reducer";

const RisikoItemButtonGroups: React.FC<{
  risiko: IRisiko;
  activeButtonName: RisikoItemTabEnum;
  setSelectedButtonName;
  projectIsGisActivated: boolean;
}> = ({ risiko, activeButtonName, setSelectedButtonName, projectIsGisActivated }) => {
  const buttons: IButtonWithBadge[] = [
    {
      name: RisikoItemTabEnum.INFO,
    },
    {
      name: RisikoItemTabEnum.ACTIONS,
      badgeCount: risiko.connectedIssues?.length,
    },
    {
      name: RisikoItemTabEnum.FILES,
      badgeCount: risiko.uploadedFiles?.length,
    },
  ];

  if (projectIsGisActivated) {
    buttons.push({
      name: RisikoItemTabEnum.GIS,
      badgeIcon: risiko.gis != null ? "fa-globe" : null,
    });
    buttons.push({
      name: RisikoItemTabEnum.LOG,
    });
  } else {
    buttons.push({ name: RisikoItemTabEnum.LOG });
  }

  return (
    <ButtonGroupWithBadge
      buttons={buttons}
      activeButtonName={activeButtonName}
      setSelectedButtonName={setSelectedButtonName}
    />
  );
};

export default RisikoItemButtonGroups;
