import React, { useEffect, useState } from "react";
import { InputType } from "reactstrap/lib/Input";
import { EditSaveAbort, HSpace, McInput } from "..";
import { formatInputDate } from "../../app/routes/admin/selectors";
import { CollapsableCell } from "../columnGlobal";

const TitleWithValue: React.FC<{
  title: string;
  value: any;
  saveCb?: (oldValue: any, newValue: any) => void;
  type?: InputType;
  pattern?: string;
  editable?: boolean;
  allowEmpty?: boolean;
  formatter?: any;
  item?: any;
  placeRight?: boolean;
  wrap?: boolean;
  hideIcon?: boolean;
  className?: string;
}> = ({
  title,
  value,
  saveCb,
  type,
  pattern,
  editable = false,
  allowEmpty = false,
  formatter,
  item,
  placeRight = false,
  wrap = false,
  hideIcon = false,
  className,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [editValue, setEditValue] = useState(value);
  const [viewValue, setViewValue] = useState("");

  const editCb = (isEditMode: boolean) => {
    if (isEditMode === false) {
      setEditValue(value);
    }

    setEditMode(isEditMode);
  };

  const _save = () => {
    if (editValue !== value) {
      saveCb(value, editValue);
    }

    editCb(false);
  };

  useEffect(() => {
    if (value instanceof Date) {
      const _value: string = JSON.parse(JSON.stringify(value));
      const inputDate = formatInputDate(_value);
      const formatted = formatter == null ? inputDate : formatter(_value);

      setViewValue(formatted);
      setEditValue(inputDate);
      return;
    } else {
      setEditValue(value);
    }

    try {
      if (formatter != null) {
        if (item != null) {
          setViewValue(formatter(value, item));
        }

        const formatted = formatter(value);
        setViewValue(formatted);
      } else {
        setViewValue(value);
      }
    } catch (error) {
      console.log(title);
      console.log(value);
      console.error(error);
    }
  }, [value]);

  return (
    <div className={className}>
      {title != null ? <h5 className="mb-0">{title}</h5> : <></>}
      <div
        className="d-flex"
        style={{
          ...(placeRight && { flexDirection: "row-reverse", justifyContent: "flex-end" }),
          ...(wrap && editMode === true && { flexWrap: "wrap-reverse", gap: "0.4em" }),
        }}
      >
        {editable && (
          <>
            <EditSaveAbort
              disabled={allowEmpty === false && editValue === ""}
              editMode={editMode}
              saveCb={_save}
              editModeCb={editCb}
              hidden={hideIcon}
            />
            <HSpace />
          </>
        )}
        {editMode === false && <CollapsableCell cell={viewValue || "-"} activeApp={"checklist"} />}
        {editMode && (
          <McInput
            pattern={pattern}
            type={type}
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
            autoFocus
            style={{ height: type === "textarea" ? "150px" : null, width: type === "date" ? "12em" : null }}
          />
        )}
      </div>
    </div>
  );
};
export default TitleWithValue;
