import { ColDef } from "ag-grid-community";
import { IRisikoSection } from "mc-shared/zod/issueBoardSchema";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { useHistory } from "react-router";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import { IBoard, IIssue } from "../../../../../../../types/global.types";
import { IScoreMatrixConfig } from "../../../../../../../types/issue.types";
import { HSpace, OptionBadge } from "../../../../../components";
import "../../../../../components/RisikoMatrix/RisikoMatrix.css";
import ViewEditTable from "../../../../../components/ViewEditTable/ViewEditTable";
import { useAppSelector } from "../../../../../hooks";
import { IStatbox } from "../../../../../types";
import LocalErrorBoundary from "../../../../../util/LocalErrorBoundary";
import { StatsBox } from "../../../risiko/Dashboard/StatsBox";
import { ISSUE_STATES_OPTIONS } from "../../issueConstants";
import { useDoGetAllIssuesQuery } from "../../issueRTK";
import {
  buildRisikoCountMatrix,
  convertValueAndColorToMultiDisplayColor,
  deduplicateMatrices,
  getIssueUrlPath,
} from "../../issueUtils";
import "./RisikoMatrix.css";

interface RisikoDashboardProps {
  issueBoard: IBoard;
  projectId: string;
}

const RisikoDashboard: React.FC<RisikoDashboardProps> = ({ issueBoard, projectId }) => {
  const [showAckumulated, setShowAckumulated] = useState(false);
  const [selectedIssues, setSelectedIssues] = useState<IIssue[]>([]);

  const { allFilters, freeTextSearch, selectedPnsFilterItem } = useAppSelector((state) => state.issueReducerNew);

  const { data: items = [] } = useDoGetAllIssuesQuery({
    projectId: projectId,
    issueBoardId: issueBoard._id,
    allFilters: allFilters,
    freeTextSearch: freeTextSearch,
    pns: selectedPnsFilterItem,
  });

  const boxes: IStatbox[] = [
    {
      icon: "fa-book",
      value: items.filter((x) => x.archived !== true).length,
      text: `Registrert`,
      classColor: "text-primary",
    },
    {
      icon: "fa-folder-open",
      value: items.filter((x) => x.status === "Opprettet" || x.status === "I prosess").length,
      text: `Åpne`,
      classColor: "text-primary",
    },
    {
      icon: "fa-check",
      value: items.filter((x) => {
        if (x.dates.completed == null) {
          return false;
        }

        const completedDate = new Date(x.dates.completed);
        const now = new Date();
        const twoWeeksAgo = new Date(now.setDate(now.getDate() - 14));
        return completedDate >= twoWeeksAgo;
      }).length,
      text: `Lukket siste 14 dager`,
      classColor: "text-success",
    },
    {
      icon: "fa-calendar",
      value: items.filter((x) => {
        if (x.dates.due == null) {
          return false;
        }

        const dueDate = new Date(x.dates.due);
        const now = new Date();
        const twoWeeksFromNow = new Date(now.setDate(now.getDate() + 14));
        return dueDate <= twoWeeksFromNow;
      }).length,
      text: `Tidsfrist neste 14 dager`,
      classColor: "text-danger",
    },
  ];

  const history = useHistory();

  const setIssueIdUrlParam = (issue: IIssue) => {
    if (issue != null) {
      history.push(getIssueUrlPath(history.location.pathname, issue._id));
    } else {
      history.push(`${history.location.pathname}`);
    }
  };

  return (
    <Card className="d-flex m-2 gray-200 h-100 overflow-auto">
      <CardBody className="d-flex flex-column h-100 overflow-auto">
        <div className="d-flex w-100">
          {boxes.map((stat, index) => (
            <React.Fragment key={index}>
              {index !== 0 && <HSpace />}
              <StatsBox stat={stat} />
            </React.Fragment>
          ))}
        </div>
        <div className="d-flex w-100 mt-4">
          <div className="d-flex flex-column flex-grow-1" style={{ flexBasis: "0" }}>
            <Card className="">
              <CardBody className="">
                <div className="d-flex justify-content-between align-items-center">
                  <CardTitle tag="h3">Kategorier</CardTitle>
                  <Button
                    color={showAckumulated === true ? "mc-blue" : "secondary"}
                    onClick={() => setShowAckumulated(!showAckumulated)}
                  >
                    Akkumulert
                  </Button>
                </div>
                <p className="mr-2">
                  <i className="fa fa-info-circle fa-fw text-info" />
                  <span className=""> Klikk på en celle for å se aktuelle risikoer</span>
                </p>
                <LocalErrorBoundary>
                  {issueBoard.risikoSection != null && (
                    <RisikoMatrices
                      items={items}
                      risikoSettings={issueBoard.risikoSection}
                      ackumulated={showAckumulated}
                      onCellClick={setSelectedIssues}
                    />
                  )}
                </LocalErrorBoundary>
              </CardBody>
            </Card>
          </div>
          <div className="mr-4"></div>
          <div className="d-flex flex-column flex-grow-1" style={{ flexBasis: "0" }}>
            <Card className="h-100">
              <CardBody>
                <CardTitle tag="h3">Risikoer</CardTitle>
                <RisikoSelectedIssuesTable
                  issues={selectedIssues.length > 0 ? selectedIssues : items}
                  onOpenIssue={setIssueIdUrlParam}
                />
              </CardBody>
            </Card>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default RisikoDashboard;

const RisikoMatrices: React.FC<{
  items: IIssue[];
  risikoSettings: IRisikoSection;
  ackumulated: boolean;
  onCellClick: (issues: IIssue[]) => void;
}> = ({ items, risikoSettings, ackumulated, onCellClick }) => {
  const [selectedCell, setSelectedCell] = useState<[number, number, number] | null>(null);

  const categoryMatrices: IScoreMatrixConfig[] = useMemo(() => {
    return buildRisikoCountMatrix(items, risikoSettings.evaluationCategoriesConfig, risikoSettings.probabilites);
  }, [items, risikoSettings]);

  const ackumulatedMatrices: IScoreMatrixConfig[] = useMemo(() => {
    if (ackumulated === false) {
      return [];
    }

    return deduplicateMatrices(categoryMatrices);
  }, [categoryMatrices, ackumulated]);

  const matrices = ackumulated === true && ackumulatedMatrices.length > 0 ? ackumulatedMatrices : categoryMatrices;

  return (
    // TODO: break out into separate component and use with buildRisikoCountMatrix in risiko modal as well
    <div>
      {matrices.map((categoryMatrix, index) => (
        <div key={index} style={{ marginBottom: "1rem" }}>
          <h5>{categoryMatrix.name}</h5>
          <div className="matrix-container">
            {categoryMatrix.matrix.map((row, rowIndex) => (
              <div className="matrix-row" key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <div
                    key={cellIndex}
                    className={`matrix-cell${cell.score > 0 ? " pointer" : ""}${
                      selectedCell != null &&
                      selectedCell[0] === index &&
                      selectedCell[1] === rowIndex &&
                      selectedCell[2] === cellIndex
                        ? " selected-matrix-cell"
                        : ""
                    }`}
                    style={{ backgroundColor: cell.color }}
                    onClick={() => {
                      if (cell.score === 0) {
                        return;
                      }

                      if (
                        selectedCell != null &&
                        selectedCell[0] === index &&
                        selectedCell[1] === rowIndex &&
                        selectedCell[2] === cellIndex
                      ) {
                        setSelectedCell(null);
                        onCellClick([]);
                        return;
                      }

                      setSelectedCell([index, rowIndex, cellIndex]);
                      onCellClick(cell.issues);
                    }}
                  >
                    {cell.score > 0 && <span className="score">{cell.score}</span>}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

const RisikoSelectedIssuesTable: React.FC<{ issues: IIssue[]; onOpenIssue: (issue: IIssue) => void }> = ({
  issues,
  onOpenIssue,
}) => {
  const colDef: ColDef<IIssue>[] = [
    {
      headerName: "ID",
      width: 80,
      field: "id",
      cellRenderer: (params) => {
        return (
          <Button onClick={() => onOpenIssue(params.data)} color="link">
            {params.value}
          </Button>
        );
      },
    },
    {
      minWidth: 120,
      flex: 1,
      headerName: "Tittel",
      field: "title",
    },
    {
      minWidth: 120,
      flex: 1,
      headerName: "Beskrivelse",
      field: "description",
    },
    {
      width: 120,
      headerName: "Opprettet",
      field: "createdAt",
      cellRenderer: (params) => {
        return moment(params.value).format("YYYY.MM.DD");
      },
    },
    {
      width: 120,
      headerName: "Status",
      field: "status",
      cellRenderer: (params) => {
        return (
          <OptionBadge
            asBadge={false}
            activeOption={params.value}
            options={convertValueAndColorToMultiDisplayColor(ISSUE_STATES_OPTIONS)}
          />
        );
      },
    },
  ];

  const deduplicatedIssues = issues.filter(
    (issue, index, self) => self.findIndex((t) => t._id === issue._id) === index,
  );

  return (
    <ViewEditTable
      gridOptions={{
        domLayout: "autoHeight",
        pagination: true,
        paginationPageSize: 20,
      }}
      data={deduplicatedIssues}
      columns={colDef}
      onSave={() => {}}
      newItemTemplateObject={null}
    />
  );
};
