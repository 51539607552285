import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Alert, Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";
import { JoinTeamsModal, MCLoader } from "../../../../components/";
import { resetProjectInRedux } from "../../../actions";
import MCNavbar from "../../../MCNavbar";
import { isLoggedinUserMulticonsultUser } from "../../admin/selectors";
import { ProjectTable } from "./ProjectTable";
import { IMaconomyProject } from "../../../../../../types/global.types";
import { doGetProjectsWhereUserIsAMemberInMaconomy, doGetProjectsWhereUserIsAMemberInMcapps } from "../../../userApi";
import { useAppDispatch } from "../../../../hooks";

export const MaconomyProjects = () => {
  const [projectsWhereUserIsAMemberInMCapps, setProjectsWhereUserIsAMemberInMCapps] = useState<IMaconomyProject[]>([]);
  const [projectsWhereUserIsAMaconomyMember, setProjectsWhereUserIsAMaconomyMember] = useState<IMaconomyProject[]>([]);
  const [loading, setLoading] = useState(true);
  const [fetchErrorWarning, setFetchErrorWarning] = useState(false);
  const [showJoinTeamsModal, setJoinTeamsModal] = useState(false);

  const dispatch = useAppDispatch();
  const isMulticonsultUser = useSelector(isLoggedinUserMulticonsultUser);

  useEffect(() => {
    if (isMulticonsultUser === false) {
      setLoading(false);
    }
    dispatch(resetProjectInRedux());
    const projectsInLocalStorage = localStorage.getItem("mcprojects");
    if (projectsInLocalStorage != null) {
      try {
        setProjectsWhereUserIsAMaconomyMember(JSON.parse(projectsInLocalStorage));
        setLoading(false);
      } catch (error) {
        localStorage.removeItem("mcprojects");
        console.error(error);
      }
    }
    fetchAndSetProjects();
  }, []);

  const fetchAndSetProjects = async () => {
    const _projectsWhereUserIsAMemberInMCapps = await doGetProjectsWhereUserIsAMemberInMcapps();
    setProjectsWhereUserIsAMemberInMCapps(_projectsWhereUserIsAMemberInMCapps);

    if (isMulticonsultUser === true) {
      try {
        setLoading(true);
        const _projectsWhereUserIsAMaconomyMember = await doGetProjectsWhereUserIsAMemberInMaconomy();
        setProjectsWhereUserIsAMaconomyMember(_projectsWhereUserIsAMaconomyMember);
        localStorage.setItem("mcprojects", JSON.stringify(_projectsWhereUserIsAMaconomyMember));
        setLoading(false);
      } catch (error) {
        setFetchErrorWarning(true);
        setLoading(false);
      }
    }
  };

  const closeJoinTeams = async () => {
    setJoinTeamsModal(false);
  };

  return (
    <div className="d-flex flex-column h-100">
      <MCNavbar isStartPage />
      {showJoinTeamsModal && <JoinTeamsModal toggle={closeJoinTeams} />}
      <div className="row bg-grid h-100" style={{ paddingTop: "75px", paddingBottom: "75px", overflowY: "auto" }}>
        <div className="col-sm-3" />
        <div className="col-sm-6">
          <Card className="m-30">
            <CardBody>
              <CardTitle tag="h3" className="d-flex">
                Prosjekter
                <div className="flex-fill" />
              </CardTitle>
              {isMulticonsultUser && (
                <CardSubtitle tag="h6" className="mb-2 text-muted">
                  Dine prosjekter i Maconomy
                </CardSubtitle>
              )}
              <ProjectTable
                projectsMaconomy={projectsWhereUserIsAMaconomyMember}
                projectsMCapps={projectsWhereUserIsAMemberInMCapps}
              />
              {loading && <MCLoader loading text="Henter prosjekt..." />}
              {fetchErrorWarning && (
                <Alert color="danger">
                  Failed to fetch projects. Check in again in a few minutes. Contact support if error still occurs.
                </Alert>
              )}
            </CardBody>
          </Card>
        </div>
        <div className="col-sm-3" />
      </div>
    </div>
  );
};
