import React from "react";
import { Button } from "reactstrap";
import { HSpace } from ".";

const EditSaveAbort: React.FC<{
  saveCb: () => void;
  editable?: boolean;
  editMode: boolean;
  editModeCb: (enabled: boolean) => void;
  disabled?: boolean;
  hidden?: boolean;
  big?: boolean;
}> = ({ saveCb, editMode, editModeCb, disabled = false, editable = true, hidden = false, big = false }) => {
  return (
    <div>
      {editMode === false && editable === true && hidden === false && (
        <>
          {big === true ? (
            <Button color="primary" onClick={() => editModeCb(true)}>
              <i className="fa fa-pencil fa-fw" />
              Rediger
            </Button>
          ) : (
            <i className="fa fa-pencil fa-fw editIcon" onClick={() => editModeCb(true)} />
          )}
        </>
      )}
      {editMode === true && (
        <div>
          <div className="d-flex">
            <Button color="success" onClick={() => saveCb()} disabled={disabled}>
              <i className="fa fa-floppy-o fa-fw mr-1" />
              Lagre
            </Button>
            <HSpace />
            <Button onClick={() => editModeCb(false)}>Avbryt</Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditSaveAbort;
