import classnames from "classnames";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Alert, Nav, NavItem, NavLink } from "reactstrap";
import { IMeeting } from "../../../../../../types/meeting.types";
import { FailedAlert, McTitle } from "../../../../components";
import { MCApplicationsUrlEnum } from "../../../../frontendConstants";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { getProjectIdFromUrl } from "../../../utils";
import { doLogUsage } from "../../admin/restQueries";
import { useGetSingleMeetingInstanceQuery } from "../meetingRTK";
import { MeetingTab, setMeetingActiveTab } from "../meetingSlice";
import Agenda from "./Agenda/Agenda";
import MeetingInfo from "./Info/MeetingInfo";
import { MeetingDocx } from "./MeetingDocx";
import MeetingSeriesInstanceSelector from "./MeetingSeriesInstanceSelector";
import Participants from "./Participants/Participants";

const SingleMeeting: React.FC<{ meetingId: string }> = ({ meetingId }) => {
  const dispatch = useAppDispatch();

  const history = useHistory();
  const projectId = getProjectIdFromUrl(history.location.pathname);
  const {
    data: singleMeeting,
    isLoading,
    isError,
  } = useGetSingleMeetingInstanceQuery({
    meetingId,
    projectId,
  });
  const singleMeetingActiveTab = useAppSelector((state) => state.meetingReducer.singleMeetingActiveTab);
  const project = useAppSelector((state) => state.adminReducer.project);

  const meetingSerie = singleMeeting?.meetingSerie as IMeeting.MeetingSerie;

  useEffect(() => {
    doLogUsage(project, MCApplicationsUrlEnum.MEETING, "open");

    const getActiveTabFromUrl = (): MeetingTab => {
      const queryParams = new URLSearchParams(history.location.search);
      const tab = queryParams.get("tab")?.toUpperCase() as MeetingTab | null;

      const validTabs: MeetingTab[] = ["AGENDA", "PARTICIPANTS", "INFO", "DOCX"];
      return tab && validTabs.includes(tab) ? tab : "AGENDA";
    };

    const activeTab = getActiveTabFromUrl();

    if (activeTab) {
      dispatch(setMeetingActiveTab(activeTab));
    }
  }, []);
  const handleSetActiveTab = (tab: MeetingTab) => {
    dispatch(setMeetingActiveTab(tab));
    history.push({
      search: `?tab=${tab.toLowerCase()}`,
    });
  };
  return (
    <div className="d-flex flex-column h-100 w-100 h-100 overflow-auto">
      {isError === true && <FailedAlert errorMessage="Failed to fetch meeting" />}
      {singleMeeting != null && (
        <>
          <Nav tabs className="w-100 pt-2 px-2">
            <NavItem className="custom">
              <NavLink
                className={classnames({ active: singleMeetingActiveTab === "INFO", custom: true })}
                onClick={() => {
                  handleSetActiveTab("INFO");
                }}
              >
                Info
              </NavLink>
            </NavItem>
            <NavItem className="custom">
              <NavLink
                className={classnames({ active: singleMeetingActiveTab === "AGENDA", custom: true })}
                onClick={() => {
                  handleSetActiveTab("AGENDA");
                }}
              >
                Møteinnhold
              </NavLink>
            </NavItem>
            <NavItem className="custom">
              <NavLink
                className={classnames({ active: singleMeetingActiveTab === "PARTICIPANTS", custom: true })}
                onClick={() => {
                  handleSetActiveTab("PARTICIPANTS");
                }}
              >
                Møtedeltagere
              </NavLink>
            </NavItem>
            <NavItem className="custom">
              <NavLink
                className={classnames({ active: singleMeetingActiveTab === "DOCX", custom: true })}
                onClick={() => {
                  handleSetActiveTab("DOCX");
                }}
              >
                Eksport
              </NavLink>
            </NavItem>
            <div className="flex-grow-1" />
            {meetingSerie != null && (
              <McTitle.InlineData positionInlineData="before">
                <h5 className="bold mr-2 pt-1">Møte i serie:</h5>
                <MeetingSeriesInstanceSelector singleMeeting={singleMeeting} />
              </McTitle.InlineData>
            )}
          </Nav>
          {singleMeetingActiveTab === "AGENDA" && <Agenda singleMeeting={singleMeeting} />}
          {singleMeetingActiveTab === "PARTICIPANTS" && <Participants singleMeeting={singleMeeting} />}
          {singleMeetingActiveTab === "INFO" && <MeetingInfo singleMeeting={singleMeeting} />}
          {singleMeetingActiveTab === "DOCX" && <MeetingDocx singleMeeting={singleMeeting} />}
        </>
      )}
    </div>
  );
};

export default SingleMeeting;

export const BetaWarningBanner: React.FC = () => {
  return (
    <Alert color="warning">
      <h5>Beta-utgave: Mindre endringer og feil kan forekomme. Rapporter til mcapps@multiconsult.no.</h5>
    </Alert>
  );
};
