import { ValideringEnum } from "mc-shared/zod/report/GK/gkReportConstants";
import {
  IGKPartikkelMaaleresultater,
  IGKPartikkelTellingEuGmpOptions,
  IGKPartikkelTellingIRomResultater,
  IGKPartikkelTellingIsoOptions,
  IGKRapport,
  IGKVerdierPerProevetakingResultater,
} from "mc-shared/zod/report/GK/gkReportSchema";
import * as R from "ramda";
import { useEffect } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../../../types/global.types";
import { DropdownFilterGeneric, McDropdown, McInput } from "../../../../../../../components";
import ReportDocxImageContainer from "../../../../ReportBoard/ReportDocxImageContainer";
import { EU_GMP_KLASSER, ISO_KLASSER } from "../../felles/partikkelTelling/partikkelTellingTabeller";
import {
  beregnetMinimumAntallProevetakingspunkter,
  beregnetMinimumsvolumPerProevetaking,
  beregnetVolumPerProevetaking,
  euGmpTabellverdi,
  isoTabellverdi,
  maalepunktTabellverdi,
  PARTIKKELSTOERRELSER_GMP,
  PARTIKKELSTOERRELSER_ISO,
  sammenlignetVerdiMellomKonsentrasjonOgKlassegrensenivaa,
} from "../../felles/partikkelTelling/partikkelTellingUtils";

const PartikkelTellingUnderLAFTak: React.FC<{
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
  data: IGKPartikkelTellingIRomResultater;
  romAktivitet: string;
  testValidering: string;
}> = ({ data, onUpdateEditData, romAktivitet, testValidering }) => {
  useEffect(() => {
    maaleResultaterArray();
  }, [data.inndata.romAreal]);

  useEffect(() => {
    utdataBeregninger();
  }, [
    data.inndata.maaleresultater,
    data.inndata.valgtePartikkelstoerrelser,
    data.inndata.euGmpKlasse,
    data.inndata.isoKlasse,
    data.utdata.klasseGrenseNivaaEUGMP_05,
    data.utdata.klasseGrenseNivaaEUGMP_5,
    data.inndata.maalehasighetTilPartikkelteller,
    data.inndata.maaletid,
  ]);

  const maaleResultaterArray = () => {
    let arrayLength = 0;
    if (data.inndata.romAreal > 1000) {
      arrayLength = beregnetMinimumAntallProevetakingspunkter(data.inndata.romAreal);
    }
    if (data.inndata.romAreal <= 1000 && data.inndata.romAreal > 0) {
      arrayLength = maalepunktTabellverdi(data.inndata.romAreal);
    }
    const newMaaleresultaterArray: IGKPartikkelMaaleresultater[] = Array.from({ length: arrayLength }, () => ({
      maalepunktNr: "",
      maaleresultat: null,
      sammenligningMellomKonsentrasjonOgKlassegrensenivaa: null,
    }));
    let maaleresultater = R.clone(data.inndata.maaleresultater);
    for (const key in data.inndata.valgtePartikkelstoerrelser) {
      if (data.inndata.valgtePartikkelstoerrelser[key] === false) {
        maaleresultater[key] = [];
      } else {
        const elementsToAdd = Math.max(0, newMaaleresultaterArray.length - maaleresultater[key].length);
        const elementsToRemove = Math.max(0, maaleresultater[key].length - newMaaleresultaterArray.length);
        maaleresultater[key] = maaleresultater[key].concat(newMaaleresultaterArray.slice(0, elementsToAdd));
        maaleresultater[key] = maaleresultater[key].slice(0, maaleresultater[key].length - elementsToRemove);
      }
    }
    onUpdateEditData("testResultater.partikkelTellingUnderLAFTak.inndata.maaleresultater", maaleresultater);
  };

  const addOrRemovePartikkelstoerrelse = (partikkelstoerrelse: string) => {
    data.inndata.valgtePartikkelstoerrelser[partikkelstoerrelse] =
      !data.inndata.valgtePartikkelstoerrelser[partikkelstoerrelse];
    onUpdateEditData(
      "testResultater.partikkelTellingUnderLAFTak.inndata.valgtePartikkelstoerrelser",
      data.inndata.valgtePartikkelstoerrelser,
    );
    maaleResultaterArray();
  };

  const updateMaaleresultater = (
    resultat: string | number,
    i: number,
    parameter: string,
    partikkelstoerrelse: string,
  ) => {
    const maaleresultater = R.clone(data.inndata.maaleresultater);
    maaleresultater[partikkelstoerrelse] = R.clone(data.inndata.maaleresultater[partikkelstoerrelse]);
    maaleresultater[partikkelstoerrelse][i][parameter] = resultat;
    if (testValidering === ValideringEnum.ISO_KVALIFISERING) {
      maaleresultater[partikkelstoerrelse][i].sammenligningMellomKonsentrasjonOgKlassegrensenivaa =
        sammenlignetVerdiMellomKonsentrasjonOgKlassegrensenivaa(
          maaleresultater[partikkelstoerrelse][i].maaleresultat,
          isoTabellverdi(partikkelstoerrelse, data.inndata.isoKlasse),
        );
    }
    if (testValidering === ValideringEnum.GMP_KVALIFISERING || testValidering === ValideringEnum.GMP_REKVALIFISERING) {
      if (partikkelstoerrelse === PARTIKKELSTOERRELSER_GMP[0].value) {
        maaleresultater[partikkelstoerrelse][i].sammenligningMellomKonsentrasjonOgKlassegrensenivaa =
          sammenlignetVerdiMellomKonsentrasjonOgKlassegrensenivaa(
            maaleresultater[partikkelstoerrelse][i].maaleresultat,
            euGmpTabellverdi(PARTIKKELSTOERRELSER_GMP[0].label, data.inndata.euGmpKlasse, romAktivitet),
          );
      }
      if (partikkelstoerrelse === PARTIKKELSTOERRELSER_GMP[1].value) {
        maaleresultater[partikkelstoerrelse][i].sammenligningMellomKonsentrasjonOgKlassegrensenivaa =
          sammenlignetVerdiMellomKonsentrasjonOgKlassegrensenivaa(
            maaleresultater[partikkelstoerrelse][i].maaleresultat,
            euGmpTabellverdi(PARTIKKELSTOERRELSER_GMP[1].label, data.inndata.euGmpKlasse, romAktivitet),
          );
      }
    }
    onUpdateEditData("testResultater.partikkelTellingUnderLAFTak.inndata.maaleresultater", maaleresultater);
  };

  const utdataBeregninger = () => {
    let minimumAntallProevetakingspunkter: number = null;
    if (data.inndata.romAreal <= 1000 && data.inndata.romAreal > 0) {
      minimumAntallProevetakingspunkter = maalepunktTabellverdi(data.inndata.romAreal);
    } else {
      minimumAntallProevetakingspunkter = beregnetMinimumAntallProevetakingspunkter(data.inndata.romAreal);
    }
    const volumPerProevetaking = beregnetVolumPerProevetaking(
      data.inndata.maalehasighetTilPartikkelteller,
      data.inndata.maaletid,
    );
    let klasseGrenseNivaaEUGMP_05 = null;
    let klasseGrenseNivaaEUGMP_5 = null;
    let minimumsvolumPerProevetakingEUGMP_05 = null;
    let minimumsvolumPerProevetakingEUGMP_5 = null;
    if (testValidering === ValideringEnum.GMP_KVALIFISERING || testValidering === ValideringEnum.GMP_REKVALIFISERING) {
      klasseGrenseNivaaEUGMP_05 = euGmpTabellverdi(
        PARTIKKELSTOERRELSER_GMP[0].label,
        data.inndata.euGmpKlasse,
        romAktivitet,
      );
      klasseGrenseNivaaEUGMP_5 = euGmpTabellverdi(
        PARTIKKELSTOERRELSER_GMP[1].label,
        data.inndata.euGmpKlasse,
        romAktivitet,
      );
      minimumsvolumPerProevetakingEUGMP_05 = beregnetMinimumsvolumPerProevetaking(klasseGrenseNivaaEUGMP_05);
      minimumsvolumPerProevetakingEUGMP_5 = beregnetMinimumsvolumPerProevetaking(klasseGrenseNivaaEUGMP_5);
    }
    const verdierPerProevetakingISO: IGKVerdierPerProevetakingResultater[] = [];
    if (testValidering === ValideringEnum.ISO_KVALIFISERING) {
      for (const key in data.inndata.valgtePartikkelstoerrelser) {
        if (data.inndata.valgtePartikkelstoerrelser[key] === true) {
          verdierPerProevetakingISO.push({
            partikkelstoerrelse: key,
            klasseGrenseNivaaISO: isoTabellverdi(key, data.inndata.isoKlasse),
            minimumsvolumPerProevetakingISO: beregnetMinimumsvolumPerProevetaking(
              isoTabellverdi(key, data.inndata.isoKlasse),
            ),
          });
        }
      }
    }
    const utdata = {
      minimumAntallProevetakingspunkter: minimumAntallProevetakingspunkter,
      volumPerProevetaking: volumPerProevetaking,
      minimumsvolumPerProevetakingEUGMP_05: minimumsvolumPerProevetakingEUGMP_05,
      minimumsvolumPerProevetakingEUGMP_5: minimumsvolumPerProevetakingEUGMP_5,
      verdierPerProevetakingISO: verdierPerProevetakingISO,
      klasseGrenseNivaaEUGMP_05: klasseGrenseNivaaEUGMP_05,
      klasseGrenseNivaaEUGMP_5: klasseGrenseNivaaEUGMP_5,
    };
    onUpdateEditData("testResultater.partikkelTellingUnderLAFTak.utdata", utdata);
  };

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Partikkeltelling under LAF-tak</CardTitle>
        {(testValidering === ValideringEnum.GMP_KVALIFISERING ||
          testValidering === ValideringEnum.GMP_REKVALIFISERING) && (
          <>
            <b>Velg EU-GMP-klasse &#40;-&#41;:</b>
            <div className="mb-4" style={{ width: "100px" }}>
              <McDropdown
                currentValue={data.inndata.euGmpKlasse}
                onChange={(_, item) =>
                  onUpdateEditData(
                    "testResultater.partikkelTellingUnderLAFTak.inndata.euGmpKlasse",
                    item as IGKPartikkelTellingEuGmpOptions,
                  )
                }
                defaultTitle="Velg EU-GMP-klasse"
                options={EU_GMP_KLASSER.map((klasse) => klasse.label)}
              />
            </div>
          </>
        )}
        {testValidering === ValideringEnum.ISO_KVALIFISERING && (
          <>
            <b>Velg ISO-klasse &#40;-&#41;:</b>
            <div style={{ width: "100px" }}>
              <McDropdown
                currentValue={data.inndata.isoKlasse}
                onChange={(_, item) =>
                  onUpdateEditData(
                    "testResultater.partikkelTellingUnderLAFTak.inndata.isoKlasse",
                    item as IGKPartikkelTellingIsoOptions,
                  )
                }
                defaultTitle="Velg ISO-klasse"
                options={ISO_KLASSER.map((klasse) => klasse.label)}
              />
            </div>
          </>
        )}
        <div className="d-flex flex-column mt-4">
          <b>
            Fyll inn romareal/filterareal &#40;m<sup>2</sup>&#41;:
          </b>
          <McInput
            value={data.inndata.romAreal}
            onBlur={(e) =>
              onUpdateEditData("testResultater.partikkelTellingUnderLAFTak.inndata.romAreal", Number(e.target.value))
            }
            normalizeNumbers={true}
            type="text"
            className="w-25"
            maxLength={5}
          />
        </div>
        {data.inndata.romAreal <= 1000 && data.inndata.romAreal > 0 && (
          <div className="d-flex flex-column mb-0">
            <p className="mb-0">
              Minimum antall målepunkter ut fra tabell = <b>{maalepunktTabellverdi(data.inndata.romAreal)}</b>
            </p>
          </div>
        )}
        {data.inndata.romAreal > 1000 && (
          <>
            <div className="d-flex justify-content-between ">
              <p className="mb-0">
                Minimum antall målepunkter ut fra formel ={" "}
                <b>{beregnetMinimumAntallProevetakingspunkter(data.inndata.romAreal) ?? ""}</b>
              </p>
              <p className="mb-0">Formel: &#40;NL = 27 * &#40;A / 1000&#41;&#41;</p>
            </div>
            <hr className="mt-0" />
          </>
        )}
        {testValidering === ValideringEnum.ISO_KVALIFISERING && (
          <div className="mt-4">
            <b>Velg partikkelstørrelse &#40;μm&#41;:</b>
            <div className="w-25 mb-4">
              <DropdownFilterGeneric
                filterOptions={PARTIKKELSTOERRELSER_ISO.map((partikkel) => {
                  return {
                    ...partikkel,
                    isActive: data.inndata.valgtePartikkelstoerrelser[partikkel.value],
                  };
                })}
                onClick={(value) => addOrRemovePartikkelstoerrelse(value)}
                title="Velg"
                color="secondary"
                textColor="text-black"
              />
            </div>
          </div>
        )}
        <div className="d-flex flex-column mt-2">
          <b>Fyll inn målehastigheten til partikkeltelleren &#40;l/s&#41;:</b>
          <McInput
            value={data.inndata.maalehasighetTilPartikkelteller}
            onBlur={(e) =>
              onUpdateEditData(
                "testResultater.partikkelTellingUnderLAFTak.inndata.maalehasighetTilPartikkelteller",
                Number(e.target.value),
              )
            }
            normalizeNumbers={true}
            type="text"
            className="w-25"
          />
        </div>
        <div className="d-flex flex-column mt-4">
          <b>Fyll inn måletiden &#40;s&#41;:</b>
          <McInput
            value={data.inndata.maaletid}
            onBlur={(e) =>
              onUpdateEditData("testResultater.partikkelTellingUnderLAFTak.inndata.maaletid", Number(e.target.value))
            }
            normalizeNumbers={true}
            type="text"
            className="w-25"
          />
        </div>
        <div className="d-flex justify-content-between">
          <p className="mb-0">
            Prøvetakingsvolum&#40;l&#41; ={" "}
            <b>
              {beregnetVolumPerProevetaking(
                data.inndata.maalehasighetTilPartikkelteller,
                data.inndata.maaletid,
              )?.toFixed(2) ?? ""}
            </b>
          </p>
          <p className="mb-0">Formel: &#40;Vs = Ps * tm&#41;</p>
        </div>
        <hr className="mt-0" />
        {(testValidering === ValideringEnum.GMP_KVALIFISERING ||
          testValidering === ValideringEnum.GMP_REKVALIFISERING) && (
          <>
            <b>Sjekk ISO 14644-1:2015 tabell 1 for bokstaver og nivåer lavere enn 100. </b>
            <div className="d-flex flex-column mt-2">
              <p className="mb-0">
                Klassegrensenivået i henhold til EU-GMP partikkelstørrelse 0,5μm &#40;antall/m<sup>3</sup>&#41;:
              </p>
              <b className="mb-2">
                {data.inndata.euGmpKlasse === ""
                  ? "-"
                  : euGmpTabellverdi(PARTIKKELSTOERRELSER_GMP[0].label, data.inndata.euGmpKlasse, romAktivitet)}
              </b>
            </div>
            <div className="d-flex justify-content-between">
              <p className="mb-0">
                Minimumvolumet for 0,5μm &#40;l&#41; ={" "}
                <b>
                  {beregnetMinimumsvolumPerProevetaking(data.utdata.klasseGrenseNivaaEUGMP_05 as number)?.toFixed(2) ??
                    ""}
                </b>
              </p>
              <p className="mb-0">Formel: &#40;VS = &#40;20 / Cn&#41; * 1000&#41;</p>
            </div>
            <hr className="mt-0" />
            <div className="mt-4">
              <b>Fyll inn måleresultater for partikkelstørrelse 0,5μm:</b>
            </div>
            {data.utdata.volumPerProevetaking > data.utdata.minimumsvolumPerProevetakingEUGMP_05 ? (
              <div className="mt-0 d-flex flex-column">
                <table>
                  <thead>
                    <tr>
                      <th>Målepunktnummer &#40;-&#41;</th>
                      <th>
                        Måleresultat &#40;antall/m<sup>3</sup>&#41;
                      </th>
                    </tr>
                  </thead>
                  {data.inndata.maaleresultater.size05.map((resultat, i) => (
                    <tbody key={i}>
                      <tr>
                        <td>
                          <McInput
                            value={resultat.maalepunktNr}
                            type="text"
                            onChange={(e) => updateMaaleresultater(e.target.value, i, "maalepunktNr", "size05")}
                            placeholder="Målepunktnummer"
                          />
                        </td>
                        <td>
                          <McInput
                            value={resultat.maaleresultat}
                            normalizeNumbers={true}
                            type="text"
                            onBlur={(e) => updateMaaleresultater(Number(e.target.value), i, "maaleresultat", "size05")}
                            placeholder="Måleresultat"
                          />
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            ) : (
              <b>Prøvetakingsvolumet må være høyere enn minimumskravet</b>
            )}
            <div className="d-flex flex-column mt-4">
              <p className="mb-0">
                Klassegrensenivået i henhold til EU-GMP partikkelstørrelse 5μm &#40;antall/m<sup>3</sup>&#41;:
              </p>
              <b className="mb-2">
                {data.inndata.euGmpKlasse === ""
                  ? "-"
                  : euGmpTabellverdi(PARTIKKELSTOERRELSER_GMP[1].label, data.inndata.euGmpKlasse, romAktivitet)}
              </b>
            </div>
            <div className="d-flex justify-content-between mb-0 mt-0">
              <p className="mb-0">
                Minimumvolumet for 5μm &#40;l&#41; ={" "}
                <b>
                  {beregnetMinimumsvolumPerProevetaking(data.utdata.klasseGrenseNivaaEUGMP_5 as number)?.toFixed(2) ??
                    ""}
                </b>
              </p>
              <p className="mb-0">Formel: &#40;VS = &#40;20 / Cn&#41; * 1000&#41;</p>
            </div>
            <hr className="mt-0 mb-2" />
            <div className="mt-0">
              <b>Fyll inn måleresultater for partikkelstørrelse 5μm:</b>
            </div>
            {data.utdata.volumPerProevetaking > data.utdata.minimumsvolumPerProevetakingEUGMP_5 ? (
              <div className="mt-0 d-flex flex-column">
                <table>
                  <thead>
                    <tr>
                      <th>Målepunktnummer &#40;-&#41;</th>
                      <th>
                        Måleresultat &#40;antall/m<sup>3</sup>&#41;
                      </th>
                    </tr>
                  </thead>
                  {data.inndata.maaleresultater.size5.map((resultat, i) => (
                    <tbody key={i}>
                      <tr>
                        <td>
                          <McInput
                            value={resultat.maalepunktNr}
                            type="text"
                            onChange={(e) => updateMaaleresultater(e.target.value, i, "maalepunktNr", "size5")}
                            placeholder="Målepunktnummer"
                          />
                        </td>
                        <td>
                          <McInput
                            value={resultat.maaleresultat}
                            normalizeNumbers={true}
                            type="text"
                            onBlur={(e) => updateMaaleresultater(Number(e.target.value), i, "maaleresultat", "size5")}
                            placeholder="Måleresultat"
                          />
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            ) : (
              <b>Prøvetakingsvolumet må være høyere enn minimumskravet</b>
            )}
          </>
        )}
        {testValidering === ValideringEnum.ISO_KVALIFISERING && data.utdata.verdierPerProevetakingISO.length > 0 && (
          <>
            <b className="mb-0">Sjekk ISO 14644-1:2015 tabell 1 for bokstaver og nivåer lavere enn 100. </b>
            {data.utdata.verdierPerProevetakingISO.map((verdi, i) => (
              <div key={i}>
                <div className={i === 0 ? "d-flex flex-column mt-2" : "d-flex flex-column mt-4"}>
                  <p className="mb-0">
                    Klassegrensenivået i henhold til ISO partikkelstørrelse{" "}
                    {
                      PARTIKKELSTOERRELSER_ISO.find((stoerrelse) => stoerrelse.value === verdi.partikkelstoerrelse)
                        .label
                    }
                    μm &#40;antall/m<sup>3</sup>&#41;:
                  </p>
                  <b className="mb-2">
                    {data.inndata.isoKlasse === ""
                      ? "-"
                      : isoTabellverdi(verdi.partikkelstoerrelse, data.inndata.isoKlasse)}
                  </b>
                </div>
                <div className="d-flex justify-content-between mb-0 mt-0">
                  <p className="mb-0">
                    Minimumvolumet for{" "}
                    {
                      PARTIKKELSTOERRELSER_ISO.find((stoerrelse) => stoerrelse.value === verdi.partikkelstoerrelse)
                        .label
                    }
                    μm &#40;l&#41; ={" "}
                    <b>
                      {beregnetMinimumsvolumPerProevetaking(verdi.klasseGrenseNivaaISO as number)?.toFixed(2) ?? ""}
                    </b>
                  </p>
                  <p className="mb-0">Formel: &#40;VS = &#40;20 / Cn&#41; * 1000&#41;</p>
                </div>
                <hr className="mt-0 mb-2" />
                <div className="mt-0">
                  <b>
                    Fyll inn måleresultater for partikkelstørrelse{" "}
                    {
                      PARTIKKELSTOERRELSER_ISO.find((stoerrelse) => stoerrelse.value === verdi.partikkelstoerrelse)
                        .label
                    }
                    μm:
                  </b>
                </div>
                {data.utdata.volumPerProevetaking > verdi.minimumsvolumPerProevetakingISO ? (
                  <div className="mt-0 d-flex flex-column">
                    <table>
                      <thead>
                        <tr>
                          <th>Målepunktnummer &#40;-&#41;</th>
                          <th>
                            Måleresultat &#40;antall/m<sup>3</sup>&#41;
                          </th>
                        </tr>
                      </thead>
                      {data.inndata.maaleresultater[verdi.partikkelstoerrelse].map((resultat, i: number) => (
                        <tbody key={i}>
                          <tr>
                            <td>
                              <McInput
                                value={resultat.maalepunktNr}
                                type="text"
                                onChange={(e) =>
                                  updateMaaleresultater(e.target.value, i, "maalepunktNr", verdi.partikkelstoerrelse)
                                }
                                placeholder="Målepunktnummer"
                              />
                            </td>
                            <td>
                              <McInput
                                value={resultat.maaleresultat}
                                normalizeNumbers={true}
                                type="text"
                                onBlur={(e) =>
                                  updateMaaleresultater(
                                    Number(e.target.value),
                                    i,
                                    "maaleresultat",
                                    verdi.partikkelstoerrelse,
                                  )
                                }
                                placeholder="Måleresultat"
                              />
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  </div>
                ) : (
                  <b>Prøvetakingsvolumet må være høyere enn minimumskravet</b>
                )}
              </div>
            ))}
          </>
        )}
        <div className="mt-2">
          <ReportDocxImageContainer docxImageTag="partikkelTellingUnderLAFTak" title="Målepunkter i rommet" />
          <div className="w-50">
            <b>Kommentar:</b>
            <McInput
              value={data.inndata.kommentar}
              onChange={(e) =>
                onUpdateEditData("testResultater.partikkelTellingUnderLAFTak.inndata.kommentar", e.target.value)
              }
              type="textarea"
              dynamicHeight={true}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default PartikkelTellingUnderLAFTak;
