import { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import MembersTable from "../../app/routes/admin/members/MembersTable";
import { useAppSelector } from "../../hooks";
import WidgetWrapper from "../WidgetWrapper/WidgetWrapper";

const OrgListWidget: React.FC<any> = () => {
  const [openOrgListModal, setOpenOrgListModal] = useState<boolean>(false);
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const project = useAppSelector((state) => state.adminReducer.project);

  return (
    <WidgetWrapper
      title="Medlemmer"
      widgetKey="orgList"
      onFullscreenClick={() => setOpenOrgListModal(true)}
      onShowSettingsClick={() => setShowSettings(!showSettings)}
    >
      {project.members?.length > 0 && (
        <>
          <MembersTable members={project.members} projectId={project._id} showSettings={showSettings} />
          <Modal
            style={{ maxWidth: "80vw", height: "88vh" }}
            isOpen={openOrgListModal}
            toggle={() => setOpenOrgListModal(false)}
          >
            <ModalHeader toggle={() => setOpenOrgListModal(false)}>Prosjektmedlemmer</ModalHeader>
            <ModalBody>
              <div className="gray-100" style={{ height: "86vh", borderRadius: "6px" }}>
                <MembersTable members={project.members} projectId={project._id} />
              </div>
            </ModalBody>
          </Modal>
        </>
      )}
    </WidgetWrapper>
  );
};
export default OrgListWidget;
