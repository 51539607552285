import Axios from "axios";
import { IBatchjob } from "../../../../../types/batchjob.types";
import { IBatchjobLog, IFolderAgGrid } from "../../../../../types/global.types";
import { BatchJobTemplateEnum } from "./batchJobConstants";

export const doGetBatchJobs = async (projectId: string): Promise<IBatchjob.IBatchJobBoardPopulated[]> => {
  try {
    const resp = await Axios.get(`/api/project/${projectId}/batchjob`);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetSingleBatchJob = async (
  projectId: string,
  batchJobId,
): Promise<IBatchjob.IBatchJobBoardPopulated> => {
  try {
    const resp = await Axios.get(`/api/project/${projectId}/batchjob/${batchJobId}`);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doCreateBatchjob = async (
  projectId: string,
  name: string,
  batchjobTemplate: BatchJobTemplateEnum,
): Promise<IBatchjob.IBatchJobBoardPopulated> => {
  try {
    const resp = await Axios.post(`/api/project/${projectId}/batchjob`, { name, batchjobTemplate });
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doUpdateBatchJobGeneralSettings = async (
  projectId: string,
  batchJob: IBatchjob.IBatchJobBoardPopulated,
): Promise<IBatchjob.IBatchJobBoardPopulated> => {
  try {
    const resp = await Axios.put(`/api/project/${projectId}/batchjob/${batchJob._id}/general`, { batchJob });
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doUpdateBim360ExportFolder = async (
  projectId: string,
  batchJob: IBatchjob.IBatchJobBoardPopulated,
  folder: IFolderAgGrid,
): Promise<IBatchjob.IBatchJobBoardPopulated> => {
  try {
    const resp = await Axios.put(`/api/project/${projectId}/batchjob/${batchJob._id}/exportfolder`, {
      batchJob,
      folder,
    });
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doUpdateRevitToIfcSettingsPropertyMapping = async (
  projectId: string,
  revitSettingsId: string,
  propertyMapping: IBatchjob.IPropertyMapping,
) => {
  try {
    const resp = await Axios.put(`/api/project/${projectId}/revittoifcsettings/${revitSettingsId}/propertymapping`, {
      propertyMapping,
    });
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doUpdateRevitToIfcSettingsJobParams = async (
  projectId: string,
  revitSettingsId: string,
  key: string,
  value: string,
) => {
  try {
    const resp = await Axios.put(`/api/project/${projectId}/revittoifcsettings/${revitSettingsId}/jobparams`, {
      key,
      value,
    });
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetBatchJobLogs = async (projectId: string, batchJobId: string): Promise<IBatchjobLog[]> => {
  try {
    const resp = await Axios.get(`/api/project/${projectId}/batchjob/logs/${batchJobId}`);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetBatchJobLogText = async (projectId: string, logId: string): Promise<string> => {
  try {
    const resp = await Axios.get(`/api/project/${projectId}/batchjob/logtext/${logId}`);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetBatchjobTemplates = async (projectId: string): Promise<string[]> => {
  try {
    const resp = await Axios.get(`/api/project/${projectId}/batchjob/templates`);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};
