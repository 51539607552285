import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import ChangePassword from "../../../components/ChangePassword/ChangePassword";
import { doGetUserByPassRequestCode, doUpdatePasswordFromReset } from "../../userApi";

export const PasswordReset = ({ match }) => {
  const [noUser, setNoUser] = useState(null);
  const [passwordHasBeenUpdated, setPasswordHasBeenUpdated] = useState(false);

  const { code, userId } = match.params;

  useEffect(() => {
    async function fetchData() {
      const user = await doGetUserByPassRequestCode(code, userId);
      setNoUser(user == null);
    }
    fetchData();
  }, []);

  const reset = async (password: string) => {
    await doUpdatePasswordFromReset(code, userId, password);
    setPasswordHasBeenUpdated(true);
  };

  const history = useHistory();

  const goToLogin = () => {
    history.push("/login");
  };

  return (
    <div style={{ width: "300x", height: "300px", borderRadius: "5px" }} className="login-box mt-5">
      {noUser === true && (
        <p>
          Det ser ut til at denne koblingen for tilbakestilling av passord ikke kan brukes lenger. Dette betyr
          sannsynligvis at du sendte oss en annen forespørsel om tilbakestilling av passord; i så fall må du bruke den
          nyere tilbakestillingslenken.
        </p>
      )}
      {passwordHasBeenUpdated && (
        <>
          <p>Passordet er oppdatert</p>
          <Button color="secondary" onClick={() => goToLogin()}>
            Gå til pålogging
          </Button>
        </>
      )}

      {noUser === false && passwordHasBeenUpdated === false && (
        <>
          <h2 style={{ color: "#5f6d83", fontWeight: 600 }}>Nytt passord</h2>
          <ChangePassword onChangePassword={reset} />
        </>
      )}
    </div>
  );
};
