import { AccessLevelsEnum } from "mc-shared/enums/enums";
import { getUniqValues } from "mc-shared/sharedUtils";
import { useEffect, useState } from "react";
import { McDropdownMultiUser, McDropdownSingleUser } from "..";
import { IIssue, IMinUser, onIssueChange } from "../../../../types/global.types";

const IssueUserSection: React.FC<{
  issue: IIssue;
  projectMembers: IMinUser[];
  onChange: onIssueChange;
  accessUsers: IMinUser[];
  accessLevel: AccessLevelsEnum;
}> = ({ projectMembers, issue, onChange, accessUsers, accessLevel }) => {
  const [usersListInDropdown, setUsersListInDropdown] = useState<IMinUser[]>([]);

  //TODO: Replace this with a common function between backend and frontend (userHasHasAccesToBoard)
  useEffect(() => {
    if (accessLevel === AccessLevelsEnum.PROJECT || accessLevel == null) {
      setUsersListInDropdown(projectMembers);
    }
    if (accessLevel === AccessLevelsEnum.USERS) {
      const createdBy: IMinUser = issue.createdBy as IMinUser;
      setUsersListInDropdown(getUniqValues([...accessUsers, createdBy], "_id"));
    }
    if (accessLevel === AccessLevelsEnum.GROUPS) {
      setUsersListInDropdown(accessUsers);
    }
  }, []);

  const issueMemberChange = (user: IMinUser) => {
    const members = issue.itemMembers == null ? [] : [...issue.itemMembers];
    const prevMembersLength = issue.itemMembers?.length ?? 0;
    const issueMemberIndex = issue.itemMembers.findIndex((i) => i._id === user._id);

    if (issueMemberIndex !== -1) {
      members.splice(issueMemberIndex, 1);
    } else {
      members.push(user);
    }

    onChange(prevMembersLength.toString(), members, "itemMembers");
  };

  return (
    <div className="even-columns">
      <div className="d-flex flex-column mr-4">
        <h5 className="truncateHeaderText">Eier</h5>
        <McDropdownSingleUser
          onClear={
            (issue.owner as IMinUser)?.name != null
              ? () => onChange(typeof issue.owner !== "string" ? issue.owner?.name : null, null, "owner")
              : null
          }
          filterActive={projectMembers.length > 10}
          options={usersListInDropdown}
          selected={issue.owner}
          onChange={(user) => onChange(issue.owner?.name, user, "owner")}
        />
      </div>
      <div className="d-flex flex-column mr-4">
        <h5 className="truncateHeaderText">Ansv. for utførelse</h5>
        <McDropdownSingleUser
          onClear={
            (issue.assignedTo as IMinUser)?.name != null
              ? () => onChange(typeof issue.assignedTo !== "string" ? issue.assignedTo?.name : null, null, "assignedTo")
              : null
          }
          filterActive={projectMembers.length > 10}
          options={usersListInDropdown}
          selected={typeof issue.assignedTo !== "string" && issue.assignedTo}
          onChange={(user) =>
            onChange(typeof issue.assignedTo !== "string" ? issue.assignedTo?.name : null, user._id, "assignedTo")
          }
        />
      </div>
      <div className="d-flex flex-column mr-4">
        <h5 className="truncateHeaderText">Medlemmer</h5>
        <McDropdownMultiUser
          filterActive={projectMembers.length > 10}
          allUsers={usersListInDropdown}
          selectedUsers={issue.itemMembers}
          onChange={(user) => issueMemberChange(user)}
        />
      </div>
    </div>
  );
};

export default IssueUserSection;
