import * as R from "ramda";
import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardSubtitle, CardTitle, FormGroup, Input, Label } from "reactstrap";
import { IColumnTemplate, IRiskoBoard } from "../../../../../../../types/global.types";
import { Checkbox, ConfirmDialog, HSpace, VSpace } from "../../../../../components";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { setRisikoColumnTemplatesinRedux } from "../../../../actions";
import {
  doAddRisikoColumnTemplate,
  doDeleteRisikoColumnTemplate,
  doGetRisikoColumnTemplates,
  doGetRisikoEvaluationCategories,
  doUpdateProjectRisikoColumnTemplate,
} from "../../risikoAPI";
import { getEvaluationColumns, getRisikoColumns } from "../../RisikoColumns";
import { IMiniColumn } from "../types";
import { setShowSuccessMessageThunk } from "../../../../../global/globalSlice";

const RisikoColumnTemplates: React.FC<{ risikoBoard: IRiskoBoard }> = ({ risikoBoard }) => {
  const dispatch = useAppDispatch();
  const [addNewMode, setAddNewMode] = useState(false);
  const [allColumns, setAllColumns] = useState<IMiniColumn[]>(null);

  const project = useAppSelector((state) => state.adminReducer.project);

  useEffect(() => {
    async function fetchData() {
      // Fetch all columns
      const _risikoCategories = await doGetRisikoEvaluationCategories(project._id, risikoBoard._id);
      const _categoryColumns = R.flatten(getEvaluationColumns(_risikoCategories, () => null));
      const _allColumns = getRisikoColumns(null, [], null, null, null, null, [], [], null);
      const formattedColumns = [..._allColumns, ..._categoryColumns].reduce((acc, c) => {
        if (c.text !== "") {
          acc.push({
            dataField: c.dataField,
            text: c.text,
            selected: false,
          });
        }
        return acc;
      }, []);
      setAllColumns(formattedColumns);
    }

    fetchData();
  }, []);

  const saveNewTemplate = async (columns: IMiniColumn[], name: string) => {
    const newRisikoColumnTemplate = {
      name,
      risikoBoard: risikoBoard._id,
      columns: R.pipe(
        // @ts-ignore
        R.filter((c) => c.selected === true),
        // @ts-ignore
        R.pluck("dataField"),
        //@ts-ignore
      )(columns),
    };

    //@ts-ignore
    await doAddRisikoColumnTemplate(project._id, newRisikoColumnTemplate);
    dispatch(setShowSuccessMessageThunk("Risikokolonnemal lagt til"));
    const columnTemplates = await doGetRisikoColumnTemplates(project._id);
    dispatch(setRisikoColumnTemplatesinRedux(columnTemplates));
    setAddNewMode(false);
  };

  const updateTemplate = async (risikoTemplate: IColumnTemplate) => {
    await doUpdateProjectRisikoColumnTemplate(project._id, risikoTemplate);
    dispatch(setShowSuccessMessageThunk("Risikokolonnemal oppdatert"));
    const columnTemplates = await doGetRisikoColumnTemplates(project._id);
    dispatch(setRisikoColumnTemplatesinRedux(columnTemplates));
  };

  const deleteTemplate = async (risikoColumnTemplate: IColumnTemplate) => {
    await doDeleteRisikoColumnTemplate(project._id, risikoColumnTemplate._id);
    dispatch(setShowSuccessMessageThunk("Risikokolonnemal slettet"));
    const columnTemplates = await doGetRisikoColumnTemplates(project._id);
    dispatch(setRisikoColumnTemplatesinRedux(columnTemplates));
  };

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h3">Kolonnemaler</CardTitle>
        <CardSubtitle tag="h6" className="mb-2 text-muted">
          Lag kolonnemaler for raskt å bytte mellom synlige kolonner
        </CardSubtitle>
        {allColumns != null && (
          <>
            {addNewMode === false && (
              <Button color="success" onClick={() => setAddNewMode(true)}>
                Opprett ny
              </Button>
            )}
            <hr />
            <VSpace />
            {addNewMode === true && (
              <>
                <Button color="secondary" onClick={() => setAddNewMode(false)}>
                  Avbryt
                </Button>
                <HSpace />
                <AddEditForm name="" columns={allColumns} saveFn={saveNewTemplate} />
              </>
            )}
            {project.risikoColumnTemplates
              .filter((tmpl) => tmpl.risikoBoard === risikoBoard._id)
              .map((template) => (
                <RisikoColumnTemplate
                  deleteTemplate={deleteTemplate}
                  updateTemplate={updateTemplate}
                  key={template._id}
                  risikoColumnTemplate={template}
                  allColumns={allColumns}
                />
              ))}
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default RisikoColumnTemplates;

const RisikoColumnTemplate: React.FC<{
  risikoColumnTemplate: IColumnTemplate;
  allColumns: IMiniColumn[];
  updateTemplate: any;
  deleteTemplate: any;
}> = ({ risikoColumnTemplate, allColumns, updateTemplate, deleteTemplate }) => {
  const [editMode, setEditMode] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState<IMiniColumn[]>();

  const setEdit = (bool: boolean) => {
    setEditMode(bool);
  };

  useEffect(() => {
    const selectedColumns = allColumns.map((c) => {
      return {
        dataField: c.dataField,
        text: c.text,
        selected: risikoColumnTemplate.columns.indexOf(c.dataField) !== -1,
      };
    });
    setSelectedColumns(selectedColumns);
  }, []);

  const _updateTemplate = (columns: IMiniColumn[], name) => {
    const template = {
      _id: risikoColumnTemplate._id,
      name: name,
      columns: columns.reduce((acc, c) => {
        if (c.selected) {
          acc.push(c.dataField);
        }
        return acc;
      }, []),
    };
    updateTemplate(template);
    setEditMode(false);
  };

  const _deleteTemplate = () => {
    deleteTemplate(risikoColumnTemplate);
  };

  return (
    <div>
      {editMode === false && (
        <div style={{ width: "400px" }}>
          <div className="d-flex">
            <h4>{risikoColumnTemplate.name.toUpperCase()}</h4>
            <div className="flex-fill" />
            <Button
              size="sm"
              id={`editSelectionButton-${risikoColumnTemplate._id}`}
              onClick={() => setEdit(true)}
              color="primary"
              outline
            >
              <i className="fa fa-pencil" />
            </Button>

            <HSpace />
            <ConfirmDialog
              title="Slett mal"
              message="Er du sikker på at du vil slette denne malen?"
              confirmCb={_deleteTemplate}
            >
              <Button size="sm" color="outline-danger">
                <i className="fa fa-trash" />
              </Button>
            </ConfirmDialog>
          </div>
          <ul>
            {risikoColumnTemplate.columns.map((column) => {
              const activeColumn = allColumns.find((c) => c.dataField === column);
              return (
                <li style={{ fontSize: "12px" }} key={column}>
                  {activeColumn != null ? activeColumn.text : "Unknwon"}
                </li>
              );
            })}
          </ul>
          <hr />
        </div>
      )}
      {editMode && selectedColumns != null && (
        <AddEditForm saveFn={_updateTemplate} columns={selectedColumns} name={risikoColumnTemplate.name} />
      )}
      <VSpace />
    </div>
  );
};

const AddEditForm: React.FC<{ columns: IMiniColumn[]; saveFn: any; name: string }> = ({ columns, saveFn, name }) => {
  const [localName, setName] = useState(name || "");
  const [selectedColumns, setSelectedColumns] = useState([...columns]);

  const setChecked = (dataField) => {
    const index = selectedColumns.findIndex((c) => c.dataField === dataField);
    let columnsToSet = [...selectedColumns];
    columnsToSet[index].selected = !columnsToSet[index].selected;
    setSelectedColumns(columnsToSet);
  };

  const isDisabled = localName === "" || selectedColumns.filter((c) => c.selected === true).length === 0;

  return (
    <div style={{ width: "400px" }}>
      <FormGroup>
        <Label for="name">Navnet på kolonnemalen</Label>
        <Input
          style={{ width: "300px" }}
          value={localName}
          type="text"
          name="email"
          id="name"
          onChange={(e) => setName(e.target.value)}
          placeholder="Weekly report, basic columns etc"
        />
      </FormGroup>
      {selectedColumns.map((c) => {
        return (
          <div key={c.dataField}>
            <VSpace />
            <Checkbox
              text={c.text}
              key={c.dataField}
              disabled={false}
              isChecked={c.selected}
              setChecked={() => setChecked(c.dataField)}
            />
          </div>
        );
      })}
      <VSpace />
      <Button block color="success" disabled={isDisabled} onClick={() => saveFn(selectedColumns, localName)}>
        <i className="fa fa-floppy-o fa-fw" />
      </Button>

      <VSpace />
    </div>
  );
};
