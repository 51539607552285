import React from "react";
import { Button } from "reactstrap";

const ClickableTableIdButton: React.FC<{
  id: any;
  _id: string;
  setItem: (_id: string) => void;
  prefix?: string;
  icon?: React.ReactNode;
  disabled?: boolean;
}> = ({ id, _id, setItem, prefix, icon, disabled }) => {
  return (
    <>
      {disabled !== true ? (
        <Button color="link" className="m-0 p-1" onClick={() => setItem(_id)}>
          <u>
            <b>
              {icon}
              {prefix}
              {id}
            </b>
          </u>
        </Button>
      ) : (
        <b>
          {icon}
          {prefix}
          {id}
        </b>
      )}
    </>
  );
};

export default ClickableTableIdButton;
