import { FlatHierarchy } from "../../../types/global.types";

export interface IHierarchyNode<T extends {}> {
  _id: string;
  props: T;
  parent: HierarchyNode<T>;
  children: HierarchyNode<T>[];
}

export class HierarchyNode<T extends {}> {
  private _levelId: string;
  private _id: string;
  private _parent: HierarchyNode<T> | null;
  private _children: HierarchyNode<T>[];
  private _props: T;

  get levelId(): string {
    return this._levelId;
  }

  get id(): string {
    return this._id;
  }

  get children(): HierarchyNode<T>[] {
    return this._children;
  }

  get parent(): HierarchyNode<T> | null {
    return this._parent;
  }

  get props(): T {
    return this._props;
  }

  constructor(node?: IHierarchyNode<T>) {
    this._id = node?._id != null && node._id !== "" ? node._id : this.generateObjectId();
    this._parent = node?.parent != null ? node.parent : null;
    this._children = node?.children != null ? node.children : [];
    this._props = node.props;

    if (this.parent == null) {
      this._levelId = "1";
    } else {
      this._parent.updateChildLevelIds();
    }
  }

  getRoot(): HierarchyNode<T> {
    let root: HierarchyNode<T> = this;

    while (root._parent !== null) {
      root = root._parent;
    }

    return root;
  }

  indent(): void {
    if (this._parent === null) {
      // Cannot indent root node
      return;
    }

    const siblingIndex = this._parent.children.indexOf(this);

    if (siblingIndex === 0) {
      // Cannot indent if there is no previous sibling
      return;
    }

    const previousSibling = this._parent.children[siblingIndex - 1];
    this._parent.removeChild(this);
    previousSibling.addChild(this);
    previousSibling._parent.updateChildLevelIds();
  }

  dedent(): void {
    if (this._parent == null || this._parent._parent === null) {
      // Cannot dedent root node or a direct child of the root node
      return;
    }

    const grandParent = this._parent._parent;
    const parentIndex = grandParent.children.indexOf(this._parent);

    this._parent.removeChild(this);
    grandParent.children.splice(parentIndex + 1, 0, this);
    this._parent = grandParent;
    this._parent.updateChildLevelIds();
  }

  addSibling(node: HierarchyNode<T>): void {
    const parent = this._parent;

    if (parent === null) {
      // Cannot add to same level as the root node
      return;
    }

    if (parent._children === null) {
      parent._children = [];
    }

    const index = parent._children.indexOf(this);
    parent._children.splice(index + 1, 0, node);

    node._parent = parent;
    this._parent.updateChildLevelIds();
  }

  addChild(node: HierarchyNode<T>): void {
    node._parent = this;
    this._children.push(node);
    this.updateChildLevelIds();
  }

  removeChild(node: HierarchyNode<T>): void {
    const childIndex = this._children.indexOf(node);

    if (childIndex !== -1) {
      node._parent = null;
      this._children.splice(childIndex, 1);
      this.updateChildLevelIds();
    }
  }

  moveUp(): void {
    if (this._parent === null) {
      // Cannot move up the root node
      return;
    }

    const siblingIndex = this._parent.children.indexOf(this);

    if (siblingIndex === 0) {
      // Cannot move up if it's the first child
      return;
    }

    // Swap positions with the previous sibling
    const previousSibling = this._parent.children[siblingIndex - 1];
    this._parent.children[siblingIndex - 1] = this;
    this._parent.children[siblingIndex] = previousSibling;
    this._parent.updateChildLevelIds();
  }

  moveDown(): void {
    if (this._parent === null) {
      // Cannot move down the root node
      return;
    }

    const siblingIndex = this._parent.children.indexOf(this);
    if (siblingIndex === this._parent.children.length - 1) {
      // Cannot move down if it's the last child
      return;
    }

    // Swap positions with the next sibling
    const nextSibling = this._parent.children[siblingIndex + 1];
    this._parent.children[siblingIndex + 1] = this;
    this._parent.children[siblingIndex] = nextSibling;
    this._parent.updateChildLevelIds();
  }

  flatten(): FlatHierarchy<T> {
    const result: FlatHierarchy<T> = [];

    const traverse = (node: HierarchyNode<T>) => {
      result.push({ _id: node._id, parentId: node._parent ? node._parent._id : null, props: node._props });

      for (const child of node.children) {
        traverse(child);
      }
    };

    traverse(this);

    return result;
  }

  *forEach(): IterableIterator<HierarchyNode<T>> {
    yield this;

    for (const child of this.children) {
      yield* child.forEach();
    }
  }

  getClosestSibling(): HierarchyNode<T> | null {
    if (this._parent == null) {
      return null;
    }

    const siblings = this.parent.children;
    const currentIndex = siblings.indexOf(this);

    if (currentIndex > 0) {
      return siblings[currentIndex - 1];
    } else if (currentIndex < siblings.length - 1) {
      return siblings[currentIndex + 1];
    } else {
      return this.parent;
    }
  }

  static createFromFlatList<T extends {}>(flatList: FlatHierarchy<T>): HierarchyNode<T> {
    if (flatList == null || flatList.length === 0) {
      return null;
    }

    const nodes: { [id: string]: HierarchyNode<T> } = {};
    let rootNode: HierarchyNode<T> | null = null;

    for (const item of flatList) {
      nodes[item._id] = new HierarchyNode({ _id: item._id, parent: null, children: [], props: { ...item.props } });
    }

    for (const item of flatList) {
      const currentNode = nodes[item._id];

      if (item.parentId == null || item.parentId === "") {
        rootNode = currentNode;
      } else {
        const parentNode = nodes[item.parentId];
        parentNode.addChild(currentNode);
      }
    }

    rootNode.setLevelId("1");

    return rootNode;
  }

  private setLevelId(newLevelId: string): void {
    this._levelId = newLevelId;
    this.children.forEach((child, index) => {
      child.setLevelId(`${newLevelId}.${index + 1}`);
    });
  }

  private updateChildLevelIds(): void {
    this.children.forEach((child, index) => {
      child._levelId = `${this.levelId}.${index + 1}`;
      child.updateChildLevelIds();
    });
  }

  private generateObjectId(): string {
    const timestamp = Math.floor(Date.now() / 1000).toString(16);
    const part1 = Math.floor(Math.random() * 0xffffff)
      .toString(16)
      .padStart(6, "0");
    const part2 = Math.floor(Math.random() * 0xffff)
      .toString(16)
      .padStart(4, "0");
    const part3 = Math.floor(Math.random() * 0xffffff)
      .toString(16)
      .padStart(6, "0");

    return timestamp + part1 + part2 + part3;
  }

  // For debugging
  logTree(logProp?: keyof T): void {
    const treeString = this.buildTreeString(0, logProp);
    console.log(treeString);
  }

  private buildTreeString(indentLevel: number, logProp?: keyof T): string {
    const indent = "  ".repeat(indentLevel);

    let treeString = logProp != null ? `${indent}${this.props[logProp]}\n` : `${indent}${this._id}\n`;

    for (const child of this.children) {
      treeString += child.buildTreeString(indentLevel + 1, logProp);
    }

    return treeString;
  }
}
