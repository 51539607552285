import React from "react";
import { useHistory } from "react-router-dom";
import { IApp, IMember, IProject } from "../../../../../types/global.types";
import AppIcon from "../../../components/AppIcon";
import { useAppSelector } from "../../../hooks";
import { getEnabledApps, selectUserMember } from "../../../global/globalSlice";

const AppsPage: React.FC = () => {
  const project = useAppSelector((state) => state.adminReducer.project);
  const appsToShow = useAppSelector(getEnabledApps);
  const member = useAppSelector(selectUserMember);

  return (
    <div className="d-flex h-100 w-100">
      <div className="sidebarOffsetWidth gray-200" />
      <div className="gray-200 h-100 overflow-auto p-5" style={{ flex: 1 }}>
        <div className="container lightContrastBackground">
          <div className="pb-2 mb-6 mr-5" style={{ borderBottom: "3px solid #d5d5d5" }}>
            <h2 style={{ color: "#404040", fontWeight: 500 }}>Appoversikt</h2>
          </div>
          <div className="mt-3 d-flex flex-wrap justify-content" style={{ gap: "2em" }}>
            {appsToShow.map((app) => (
              <AppCard key={app.name} app={app} project={project} member={member} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppsPage;

const AppCard: React.FC<{ app: IApp; project: IProject; member: IMember }> = ({ app, project, member }) => {
  const history = useHistory();

  const goToApp = () => {
    history.push(`/project/${project._id}/${app.name}`);
  };

  return (
    <div className="appCardBackground">
      <div className="d-flex align-items-center appCardHeader">
        <div
          className="appCardTint"
          style={{
            backgroundColor: app.iconColor,
          }}
        />
        <AppIcon app={app} variation="appcard" />

        <div
          style={{
            fontWeight: 500,
            fontSize: 22,
            margin: `${app.label.length < 13 ? "0 0.2em" : "0. 0em"}`,
            lineHeight: 1.1,
            letterSpacing: "0.03em",
            color: "#404040",
            transform: `${app.label.length < 13 ? "" : "scale(.95)"}`,
          }}
        >
          {app.label.replace("håndtering", "- håndtering")}
        </div>
      </div>

      <div
        className="d-flex"
        style={{
          flexGrow: 1,
          fontSize: 13,
          padding: "1em 2em",
          // fontWeight: 400,
          color: "#3E4245",
          letterSpacing: ".05em",
          lineHeight: 1.4,
          overflow: "hidden",
        }}
      >
        {app.description}
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            justifyContent: "space-between",
            alignItems: "flex-end",
            padding: ".8em",
            height: "50px",
            width: "100%",
          }}
        >
          <button className="appCardButton" onClick={() => goToApp()}>
            <div style={{ margin: "2px 6px" }}>START APPEN</div>
          </button>
        </div>
      </div>
    </div>
  );
};
