import "./IconToggle.css";

const IconToggle = ({
  activeColor,
  toggled,
  activeIcon,
  inactiveIcon,
  onClick,
  id,
  inActiveColor = "#999",
}: {
  activeColor: string;
  toggled: boolean;
  activeIcon: string;
  inactiveIcon: string;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  id?: string;
  inActiveColor?: string;
}) => {
  return (
    <i
      id={id}
      onClick={onClick}
      className={`mc-icon-toggle pointer fa fa-${toggled === true ? activeIcon : inactiveIcon}`}
      style={{ fontSize: "25px", height: "25px", width: "25px", color: toggled === true ? activeColor : inActiveColor }}
    />
  );
};

export default IconToggle;
