import { useEffect, useState } from "react";
import { Alert, Button, Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";
import { useAppSelector } from "../../../../hooks";
import { doGetBim360Project } from "../restQueries";
import ConnectBIM360ProjectModal from "./ConnectBIM360ProjectModal";

export const ConnectedBim360Project = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const project = useAppSelector((state) => state.adminReducer.project);

  const [bim360ConnectionSuccess, setBim360ConnectionSuccess] = useState(null);

  const bim360 = project.bim360;

  useEffect(() => {
    async function fetchData() {
      try {
        if (bim360.projectId) {
          const bim360Project = await doGetBim360Project(project?._id, bim360.projectId);
          //@ts-ignore
          setBim360ConnectionSuccess(bim360Project != null);
        }
      } catch (err) {
        //@ts-ignore
        setBim360ConnectionSuccess(false);
      }
    }
    fetchData();
  }, [bim360]);

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h3">Tilkoblet BIM360-konto</CardTitle>
        <CardSubtitle tag="h6" className="mb-2 text-muted" />
        <div>
          {bim360?.projectId != null && (
            <>
              <div className="d-flex">
                <span className="mr-2">
                  <b>Navn</b>
                </span>
                <p>{bim360.name || "?"}</p>
              </div>
              <div className="d-flex">
                <span className="mr-2">
                  <b>Status BIM360 Tilkobling:</b>
                </span>
                <Icon isValid={bim360ConnectionSuccess} />
              </div>
              {bim360ConnectionSuccess === false && (
                <Alert color="danger">
                  Kunne ikke koble til BIM 360-kontoen. Har den blitt arkivert? Kontakt support for mer informasjon.
                  Eller koble til et nytt bim360-prosjekt
                </Alert>
              )}
            </>
          )}
          <Button color="secondary" onClick={() => setModalOpen(true)}>
            Koble BIM360Account
          </Button>
          {modalOpen === true && <ConnectBIM360ProjectModal toggle={setModalOpen} />}
        </div>
      </CardBody>
    </Card>
  );
};

export default ConnectedBim360Project;

export const Icon = ({ isValid }) => {
  return isValid ? (
    <i style={{ color: "green" }} className="fa fa-check-circle fa-2x fa-fw" />
  ) : (
    <i style={{ color: "red" }} className="fa fa-close fa-fw" />
  );
};
