import React from "react";
import { CommentActions, IComment } from "../../../../../../../types/global.types";
import { FoldableSection, MultiComment } from "../../../../../components";
import { IEditorMetadata } from "../../../../../components/MultiComment/MultiComment";
import { ToastMessagesEnums } from "../../../../../frontendConstants";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { doCreateComment, doDeleteComment, doUpdatecomment } from "../../../projects/restQueries";
import { fetchSingleChecklistItemAndReplaceInReduxThunk } from "../../checklistSlice";
import { setShowSuccessMessageThunk } from "../../../../../global/globalSlice";

const ChecklistComments: React.FC<{ comments: IComment[]; checklistItemId: string }> = ({
  comments,
  checklistItemId,
}) => {
  const checklist = useAppSelector((state) => state.checklistReducer.checklist);

  const user = useAppSelector((state) => state.globalReducer.user);

  const dispatch = useAppDispatch();

  const project = useAppSelector((state) => state.adminReducer.project);

  const commentAction = async (
    commentId: string,
    boardId: string,
    action: CommentActions,
    value?: string,
    metadata?: IEditorMetadata,
  ) => {
    switch (action) {
      case "ADD":
        await doCreateComment(project._id, checklistItemId, "checklistItem", value, boardId, metadata);
        await dispatch(fetchSingleChecklistItemAndReplaceInReduxThunk(checklistItemId));
        break;
      case "DELETE":
        await doDeleteComment(project._id, commentId);
        await dispatch(fetchSingleChecklistItemAndReplaceInReduxThunk(checklistItemId));
        break;
      case "UPDATE":
        await doUpdatecomment(project._id, commentId, value);
        await dispatch(fetchSingleChecklistItemAndReplaceInReduxThunk(checklistItemId));
        break;
      default:
        return;
    }

    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.UPDATED));
  };

  return (
    <FoldableSection>
      <FoldableSection.Header>
        <div className="section-title">Diskusjon</div>
      </FoldableSection.Header>
      <FoldableSection.Body>
        <div className="d-flex flex-column w-100 mt-2 mb-2 mr-4">
          <MultiComment
            sortDesc
            title=""
            comments={comments}
            onCreate={(text, metadata) => commentAction(null, checklist._id, "ADD", text, metadata)}
            onDelete={(_id) => commentAction(_id, checklist._id, "DELETE")}
            onSave={(_id, text, metadata) => commentAction(_id, checklist._id, "UPDATE", text, metadata)}
            loggedInUser={user}
            members={project.members}
          />
        </div>
      </FoldableSection.Body>
    </FoldableSection>
  );
};

export default ChecklistComments;
