import React, { useCallback, useMemo, useRef, useState } from "react";
import { Badge, Button, Input, UncontrolledTooltip } from "reactstrap";
import { IMeeting } from "../../../../../../../types/meeting.types";
import { ITodo } from "../../../../../../../types/todo.types";
import {
  Avatar,
  Checkbox,
  CheckIcon,
  ClickableTableIdWithTaskCompletedCount,
  ConfirmDialog,
  FailedAlert,
  IconToggle,
  VSpace,
} from "../../../../../components";
import { CellEditFormatter, expandColumnCaret, expandHeaderColumn } from "../../../../../components/columnGlobal";
import { getIssuesCompletedCount, getProjectIdFromUrl, sortArrayByNumber } from "../../../../utils";
import { ISSUE_DATAFILEDS_ENUM } from "../../../issuesApp/issueConstants";

import { ColDef, GridApi, IRowNode } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-enterprise";
import { AgGridReact, CustomCellRendererProps } from "ag-grid-react";
import { useHistory } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { AG_GRID_LOCALE_NOR } from "../../../../../agGridLocale";
import { useAppSelector } from "../../../../../hooks";
import { STANDARD_COLORS } from "../../../../globalConstants";
import {
  useCreateMeetingAgendaItemMutation,
  useUpdateAgendaOrderMutation,
  useUpdateMeetingAgendaMutation,
} from "../../meetingAgendaRTK";
import { useUpdateAllAgendaIdsMutation } from "../../meetingRTK";
import AgendaItem from "./AgendaItem";
import SendMeetingAgendaByMailModal from "./SendMeetingAgendaByMailModal";

const Agenda: React.FC<{ singleMeeting: IMeeting.MeetingPopulated }> = ({ singleMeeting }) => {
  const [sendAgendaMailModalOpen, setSendAgendaMailModalOpen] = React.useState<boolean>(false);
  const [filterText, setFilterText] = useState<string>("");
  const [userFilterActive, setUserFilterActive] = useState<boolean>(false);
  const history = useHistory();
  const user = useAppSelector((state) => state.globalReducer.user);
  const projectId = getProjectIdFromUrl(history.location.pathname);
  const agendaItems = sortArrayByNumber(singleMeeting?.agendaItems || [], "order");
  let refContainer = useRef(null);

  const [updateAgendaItemOrder] = useUpdateAgendaOrderMutation();
  const gridRef = useRef<AgGridReact>(null);

  const setPrinterFriendly = (api: GridApi) => {
    api.sizeColumnsToFit();
    api.setGridOption("domLayout", "print");
  };

  const setNormal = (api: GridApi) => {
    api.setGridOption("domLayout", undefined);
  };

  const TodoList: React.FC<{ todos: ITodo.TodoPopulated[] }> = ({ todos }) => {
    const [showAll, setShowAll] = useState(false);
    const handleShowAll = (e) => {
      setShowAll(!showAll);
      e.preventDefault();
    };
    return (
      <div className="m-2">
        {todos.map((todo, index) => (
          <p key={todo._id} className={`pb-1 m-0 ${index > 4 && !showAll ? "d-none" : ""}`}>
            {todo.completedDate != null && <i className="fa fa-check-circle fa-fw success-text" />}
            {todo.completedDate == null && <i className="fa fa-circle fa-fw gray-text" />}
            {todo.text}
          </p>
        ))}
        {todos.length > 4 && (
          <Button
            size="sm"
            style={{ fontSize: ".8em", height: "auto", padding: "6px 10px" }}
            onClick={(e) => handleShowAll(e)}
          >
            {showAll ? "Vis mindre" : "Vis alle"}
            <i className={`fa fa-angle-down ml-1 ${showAll && "rotateIcon"}`}></i>
          </Button>
        )}
      </div>
    );
  };
  const onRowDragEnd = (event) => {
    const draggedRow = event.node.data;
    const newPosition = event.node.rowIndex + 1;
    if (draggedRow.order != newPosition) {
      updateAgendaItemOrder({
        projectId: projectId,
        agendaId: draggedRow._id,
        order: newPosition,
      });
    }
  };
  const doesExternalFilterPass = useCallback((node: IRowNode<any>): boolean => {
    const hasConnectedIssueWithUser = (node.data?.connectedIssues || []).some(
      (issue) => issue.assignedTo?._id === user._id,
    );
    return (
      node.data.assignedTo?._id === user._id ||
      node.data.itemParticipants?.some((p) => p?._id === user._id) ||
      hasConnectedIssueWithUser
    );
  }, []);
  const getRowId = useCallback((params) => params.data._id, []);
  const columns: ColDef<IMeeting.AgendaItem>[] = [
    {
      headerName: "",
      cellStyle: { padding: "0px" },
      cellRenderer: (props) => {
        const { node } = props;
        return (
          <div className={`expandIcon${node.expanded === true ? " rotateIcon" : ""}`}>
            <i className="fa fa-angle-down"></i>
          </div>
        );
      },
      width: 40,
      suppressSizeToFit: true,
      suppressHeaderMenuButton: true,
    },
    {
      headerName: "IR",
      field: "notRelevant",
      width: 70,
      maxWidth: 70,
      cellRenderer: (params) => {
        const row = params.data;
        return (
          <div id={"a" + row._id} className="d-flex w-100 align-items-center justify-content-center">
            <Checkbox
              mode="cross"
              isChecked={row?.notRelevant === true}
              setChecked={(e) => {
                e.preventDefault();
                updateAgendaItem({
                  projectId: projectId,
                  agendaId: row._id,
                  attribute: "notRelevant",
                  value: !row?.notRelevant,
                });
              }}
              disabled={false}
              color="gray"
            />
          </div>
        );
      },
    },
    {
      headerName: "Id",
      field: "id",
      rowDrag: true,
      cellRenderer: (params) => (
        <ClickableTableIdWithTaskCompletedCount
          id={params.data.id}
          _id={params.data._id}
          onClick={() => expandRow.renderer(params.data)}
          taskCompleteCount={getIssuesCompletedCount(params.data.connectedIssues)}
        />
      ),
      minWidth: 115,
      cellClass: "p-1",
      suppressSizeToFit: true,
    },
    {
      headerName: "Starttid",
      field: "startTimeCalculated",
      hide: singleMeeting.timeStart == null,
      width: 100,
      minWidth: 70,
      maxWidth: 110,
    },
    {
      headerName: "Tittel",
      field: "title",
      flex: 3,
      minWidth: 150,
      cellRenderer: (params) => (
        <div className="d-flex align-items-center truncateHeaderText">
          <b className="two-line-text-truncate">{params.value}</b>
        </div>
      ),
    },
    {
      headerName: "Beskrivelse",
      field: "description",
      minWidth: 150,
      maxWidth: 500,
      flex: 2,
      cellStyle: {
        alignItems: "start",
        padding: "8px",
      },
      cellRenderer: (params) => {
        return CellEditFormatter(params.value, "description");
      },
    },
    {
      headerName: "Ansvarlig",
      field: ISSUE_DATAFILEDS_ENUM.ASSIGNEDTO_NAME,
      flex: 1,
      minWidth: 180,
      cellRenderer: (params) => {
        const assignedTo = params.data.assignedTo;
        return assignedTo != null ? (
          <div className="d-flex align-items-center">
            <Avatar id={assignedTo._id} />
            <div className="ml-2">{assignedTo.name}</div>
          </div>
        ) : (
          <div></div>
        );
      },
    },
    {
      headerName: "Deltakere",
      field: "itemParticipants",
      hide: true,
    },
    { headerName: "Formål", field: "purpose", hide: true },
    { headerName: "Konklusjon", field: "conclusion", hide: true },
    {
      headerName: "Forberedelser",
      field: "todos",
      suppressHeaderMenuButton: true,
      autoHeight: true,
      flex: 1,
      width: 20,
      maxWidth: 120,
      cellStyle: { alignItems: "center" },
      valueGetter: (params) => {
        return (params.data.todos || []).map((todo) => todo.text);
      },
      cellRenderer: (params) => {
        const todos = params.data.todos as ITodo.TodoPopulated[];
        const completedCount = todos.filter((x) => x.completedDate != null).length;
        let ref = useRef(null);
        return (
          <>
            {todos?.length > 0 && (
              <Badge pill={true} className={`d-flex greybadge`}>
                <div
                  ref={ref}
                  style={{ padding: "2px 6px", fontSize: "12px" }}
                  className={`d-flex align-items-center `}
                >
                  <CheckIcon completedNr={completedCount} totalNr={todos.length} />
                  {completedCount} / {todos.length}
                </div>
              </Badge>
            )}
          </>
        );
      },
    },
    {
      headerName: "",
      width: 55,
      maxWidth: 55,
      suppressHeaderMenuButton: true,
      cellRenderer: (params) => (
        <ConfirmDialog
          title="Slette agendapunkt?"
          message="Er du sikker på at du vil slette agendapunkt?"
          confirmCb={() =>
            updateAgendaItem({
              projectId: projectId,
              agendaId: params.data._id,
              attribute: "isDeleted",
              value: true,
            })
          }
        >
          <div style={{ scale: ".7" }}>
            <IconToggle
              toggled
              activeIcon="trash"
              inactiveIcon="trash"
              activeColor={STANDARD_COLORS.red}
              onClick={(e) => e.preventDefault()}
            />
          </div>
        </ConfirmDialog>
      ),
    },
  ];

  const onRowClicked = (params) => {
    if (params.event.defaultPrevented || params.node?.detail == true) {
      return null;
    }
    params.api.forEachNode((node) => {
      if (node.expanded && node !== params.node) {
        node.setExpanded(false);
      }
    });
    params.node.setExpanded(!params.node.expanded);
    params.api.refreshCells();
    params.api.redrawRows();
  };
  const [updateAgendaItem] = useUpdateMeetingAgendaMutation();

  const expandRow = {
    renderer: (row) => (
      <AgendaItem
        agendaItem={row}
        onAgendaItemChange={(oldValue, newValue, param) =>
          updateAgendaItem({
            projectId: projectId,
            agendaId: row._id,
            attribute: param,
            value: newValue,
          })
        }
      />
    ),
    showExpandColumn: true,
    isAnyExpands: true,
    onlyOneExpanding: false,
    expandHeaderColumnRenderer: expandHeaderColumn,
    expandColumnRenderer: expandColumnCaret,
    expandByColumnOnly: false,
  };
  const defaultColDef = useMemo<ColDef>(
    () => ({
      // flex: 1,
      sortable: false,
      // filter: true,
      suppressHeaderMenuButton: true,
      suppressMovable: false,
      resizable: true,
      editable: false,
      wrapText: true,
      autoHeight: true,
      cellClassRules: {
        "not-relevant-cell": (params) => params.data.notRelevant === true,
      },
      cellClass: "py-0",
      cellStyle: {
        whiteSpace: "normal",
        lineHeight: "1.5",
        wordBreak: "break-word",
      },
    }),
    [],
  );
  const [createAgendaItem, { isError: addAgendaError }] = useCreateMeetingAgendaItemMutation();
  const [updateAllAgendaIds, { isError: updateAgendaIdError }] = useUpdateAllAgendaIdsMutation();
  const DetailCellRenderer = ({ data, node, api }: CustomCellRendererProps) => {
    return (
      <>
        <AgendaItem
          agendaItem={data}
          onAgendaItemChange={(oldValue, newValue, param) =>
            updateAgendaItem({
              projectId: projectId,
              agendaId: data._id,
              attribute: param,
              value: newValue,
            })
          }
        />
        <div style={{ height: "2px" }} />
      </>
    );
  };
  const rowData = useMemo(() => {
    return agendaItems?.filter((item) => item.isDeleted !== true) || [];
  }, [agendaItems]);
  const detailCellRenderer = useCallback(DetailCellRenderer, []);
  return (
    <div className="d-flex flex-column w-100 p-2 h-100 overflow-auto">
      {addAgendaError === true && <FailedAlert />}
      {updateAgendaIdError === true && <FailedAlert />}
      {sendAgendaMailModalOpen === true && (
        <SendMeetingAgendaByMailModal meeting={singleMeeting} close={() => setSendAgendaMailModalOpen(false)} />
      )}
      <div className="d-flex">
        <Button
          id="addAgendaItem"
          color="success"
          onClick={() =>
            createAgendaItem({
              projectId: projectId,
              meetingId: singleMeeting._id,
            })
          }
        >
          <i className="fa fa-plus "></i>
        </Button>
        <Button
          id="updateAgendaItemIds"
          color="primary"
          className="ml-2"
          onClick={() =>
            updateAllAgendaIds({
              projectId: projectId,
              meetingId: singleMeeting._id,
            })
          }
        >
          <i className="fa fa-sort mr-1 "></i>
          ID
        </Button>
        <Input
          type="text"
          placeholder="Søk"
          className="mx-2 h-100"
          onChange={(e) => setFilterText(e.target.value)}
          style={{ maxWidth: "180px" }}
        />
        <Button
          color="secondary"
          className="d-flex align-items-center"
          onClick={() => setUserFilterActive(!userFilterActive)}
        >
          {"Mine agendapunkter"}
          {userFilterActive === true && (
            <i
              style={{ marginRight: "-6px", marginLeft: "2px" }}
              className="fa fa-check-circle primary-text fa-fw fa-1x"
            />
          )}
        </Button>

        <UncontrolledTooltip delay={300} placement="right" target="addAgendaItem">
          Legg til agendapunkt
        </UncontrolledTooltip>
        <UncontrolledTooltip delay={300} placement="right" target="updateAgendaItemIds">
          Oppdater id
        </UncontrolledTooltip>
        <div className="flex-fill" />

        <ReactToPrint
          onBeforeGetContent={() => {
            setPrinterFriendly(gridRef.current!.api);
          }}
          onAfterPrint={() => setNormal(gridRef.current!.api)}
          trigger={() => (
            <div className="mr-2">
              <Button color="secondary" id="tabellutskrift">
                <i className="fa fa-print fa-fw" />
              </Button>
              <UncontrolledTooltip delay={300} placement="left" target="tabellutskrift">
                Tabellutskrift
              </UncontrolledTooltip>
            </div>
          )}
          // @ts-ignore
          content={() => refContainer.current}
        />
        <Button color="primary" onClick={() => setSendAgendaMailModalOpen(true)}>
          <i className="fa fa-send fa-fw" />
          Send agenda per mail
        </Button>
      </div>
      <VSpace />
      <div className="meetingTable ag-theme-quartz h-100" id="myGrid" ref={refContainer}>
        <AgGridReact
          rowHeight={56}
          ref={gridRef}
          rowData={rowData}
          getRowId={getRowId}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          rowSelection="single"
          animateRows={true}
          suppressContextMenu={true}
          suppressMoveWhenRowDragging={true}
          suppressCellFocus={true}
          rowDragManaged={true}
          onRowDragEnd={onRowDragEnd}
          onGridReady={(e) => {
            e.api.autoSizeColumns(["id", "name", "todos"]);
          }}
          getRowClass={(params) => {
            return params.node?.detail == true
              ? "expanded-outline-detail"
              : params.node.expanded
              ? "expanded-outline-row"
              : "default-outline-row";
          }}
          quickFilterText={filterText}
          includeHiddenColumnsInQuickFilter={true}
          masterDetail={true}
          detailRowAutoHeight={true}
          detailCellRenderer={detailCellRenderer}
          onRowClicked={onRowClicked}
          localeText={AG_GRID_LOCALE_NOR}
          doesExternalFilterPass={doesExternalFilterPass}
          isExternalFilterPresent={() => userFilterActive}
        />
      </div>
    </div>
  );
};

export default Agenda;
