import { ColDef, ColDefField } from "ag-grid-community";
import {
  IConsequenceArray,
  IEvaluationCategory,
  IGridConsequenceWithId,
  IMatrixConfig,
  IUpdateConsequence,
} from "mc-shared/zod/issueBoardSchema";
import React from "react";
import Switch from "react-switch";
import { Button, ButtonGroup, Card, CardBody, CardTitle, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ICapitalLetter } from "mc-shared/zod/otherAttributesSchema";
import { IBoard } from "../../../../../../../../types/global.types";
import {
  useAddRisikoEvaluationCategoryMutation,
  useUpdateRisikoEvaluationCategoryMutation,
} from "../../../IssueBoardRTK";
import {
  FailedAlert,
  FoldableSection,
  HSpace,
  McInput,
  MCLoader,
  McTitle,
  TitleWithValue,
  VSpace,
} from "../../../../../../components";
import ViewEditTable from "../../../../../../components/ViewEditTable/ViewEditTable";
import ColorMatrixSettings from "./ColorMatrixSettings";

const EvaluationCategories: React.FC<{ issueBoard: IBoard }> = ({ issueBoard }) => {
  const [addEvaluationCategory, { error: addEvaluationCategoryError, isLoading: isLoadingAdd }] =
    useAddRisikoEvaluationCategoryMutation();
  const [updateEvaluationCategory, { error: updateEvaluationCategoryError, isLoading: isLoadingUpdate }] =
    useUpdateRisikoEvaluationCategoryMutation();

  const evaluationCategoryTypeEnum = {
    RISK: "RISK",
    OPPORTUNITY: "OPPORTUNITY",
  } as const;

  const colSettings: ColDef<IGridConsequenceWithId>[] = [
    {
      field: "id",
      headerName: "ID",
      width: 70,
    },
    {
      field: "name",
      headerName: "Navn",
      editable: true,
    },
    {
      field: "description",
      headerName: "Beskrivelse",
      editable: true,
    },
    {
      field: "matrixValue",
      headerName: "Matriseverdi",
      editable: true,
      type: "numericColumn",
    },
    /*{
      field: "order",
      headerName: "Order",
      editable: false,
    },*/
    {
      field: "isActive",
      headerName: "Aktiv",
      editable: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: ["Ja", "Nei"],
      },
    },
  ];

  const template: IGridConsequenceWithId = {
    id: "",
    name: "",
    description: "",
    matrixValue: 1,
    isActive: "Ja",
    order: 0,
  };

  const saveCategory = async (
    prop: ColDefField<IEvaluationCategory>,
    newValue: string | boolean | IConsequenceArray | IMatrixConfig,
    id: string,
  ) => {
    const send: IUpdateConsequence = {
      projectId: issueBoard.project,
      issueBoardId: issueBoard._id,
      prop,
      newValue,
      id,
    };
    await updateEvaluationCategory(send);
  };

  const getData = (cat: IEvaluationCategory): IGridConsequenceWithId[] => {
    const d: IGridConsequenceWithId[] = Object.entries(cat.consequences).map(([key, value]) => ({
      ...value,
      isActive: value.isActive === true ? ("Ja" as "Ja" | "Nei") : "Nei",
      id: key,
    }));

    return d;
  };

  const _saveConsequences = (consequences: IConsequenceArray, categoryLetter: ICapitalLetter) => {
    const consequencesWithOrder = consequences.map((consequence, index) => ({
      ...consequence,
      order: index,
    }));
    saveCategory("consequences", consequencesWithOrder, categoryLetter);
  };

  const isError = addEvaluationCategoryError || updateEvaluationCategoryError;

  const [addEvalModalOpen, setAddEvalModalOpen] = React.useState<boolean>(false);

  const _add = (name: string) => {
    addEvaluationCategory({
      projectId: issueBoard.project,
      issueBoardId: issueBoard._id,
      name: name,
    });
    setAddEvalModalOpen(false);
  };
  return (
    <Card>
      {addEvalModalOpen && <AddEvalModal onCreate={_add} close={() => setAddEvalModalOpen(false)} />}
      <CardBody>
        <CardTitle tag="h3">
          <div className="d-flex align-items-center">
            <span className="mr-2">Evalueringskategorier</span>
            <MCLoader loading={isLoadingAdd === true || isLoadingUpdate === true} size={5} />
          </div>
        </CardTitle>
        {isError && <FailedAlert />}
        {Object.entries(issueBoard.risikoSection?.evaluationCategoriesConfig)?.map(([key, cat]) => {
          const _cat = cat as IEvaluationCategory;
          return (
            <Card className="mb-2" key={key}>
              <CardBody>
                <FoldableSection folded={_cat.isActive === false}>
                  <FoldableSection.Header>
                    <div className="d-flex align-items-center mb-2">
                      <h3 className="mr-2" style={{ margin: 0 }}>
                        #{key}
                      </h3>
                      <HSpace />
                      <Switch
                        onColor="#00a76d"
                        onChange={() => saveCategory("isActive", !_cat.isActive, key)}
                        checked={_cat.isActive}
                      />
                      <HSpace />
                      <h3 className="mt-1">
                        {cat.isActive === false && <s>{_cat.name}</s>}
                        {cat.isActive === true && _cat.name}
                      </h3>
                    </div>
                  </FoldableSection.Header>
                  <FoldableSection.Body>
                    <div className="d-flex flex-column w-100" id={"__points_table_" + key}>
                      <VSpace />
                      <TitleWithValue
                        allowEmpty={false}
                        title="Tittel"
                        value={_cat.name}
                        editable
                        saveCb={(oldValue, newValue) => saveCategory("name", newValue, key)}
                      />
                      <McTitle title="Risko eller muliget">
                        <div>
                          <ButtonGroup className="mr-2">
                            <Button
                              color={_cat.type === evaluationCategoryTypeEnum.RISK ? "primary" : "secondary"}
                              active={_cat.type === evaluationCategoryTypeEnum.RISK}
                              onClick={() => saveCategory("type", evaluationCategoryTypeEnum.RISK, key)}
                            >
                              Risiko
                            </Button>
                            <Button
                              color={_cat.type === evaluationCategoryTypeEnum.OPPORTUNITY ? "primary" : "secondary"}
                              active={_cat.type === evaluationCategoryTypeEnum.OPPORTUNITY}
                              onClick={() => saveCategory("type", evaluationCategoryTypeEnum.OPPORTUNITY, key)}
                            >
                              Mulighet
                            </Button>
                          </ButtonGroup>
                        </div>
                      </McTitle>
                      <VSpace />
                      <McTitle title="Konsekvens">
                        <ViewEditTable
                          columns={colSettings}
                          data={getData(_cat)}
                          newItemTemplateObject={template}
                          onSave={(data) => {
                            const newData = data.map((item) => ({
                              ...item,
                              isActive: item.isActive === "Ja",
                            }));
                            _saveConsequences(newData, key as ICapitalLetter);
                          }}
                          canEdit={issueBoard.risikoSection?.isActive === true}
                          canRemovePredicate={(item) => item.id === ""}
                          orderProp="order"
                        />
                      </McTitle>
                      <ColorMatrixSettings issueBoard={issueBoard} evaluationCategoryKey={key} />
                    </div>
                  </FoldableSection.Body>
                </FoldableSection>
              </CardBody>
            </Card>
          );
        })}

        <div className="d-flex">
          <Button color="success" onClick={() => setAddEvalModalOpen(true)}>
            <i className="fa fa-plus fa-fw" />
            Ny
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};

export default EvaluationCategories;

const AddEvalModal: React.FC<{ close: () => void; onCreate: (name: string) => void }> = ({ close, onCreate }) => {
  const [name, setName] = React.useState("");

  return (
    <Modal isOpen toggle={close}>
      <ModalHeader toggle={close}>Ny kategori</ModalHeader>
      <ModalBody>
        <McInput
          autoFocus
          placeholder={`Skriv inn navn på kategori (kost, fremdrift etc)`}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <VSpace />
      </ModalBody>
      <ModalFooter>
        <Button disabled={name === ""} color="success" onClick={() => onCreate(name)}>
          OPPRETT
        </Button>
        <Button color="secondary" onClick={close}>
          Avbryt
        </Button>
      </ModalFooter>
    </Modal>
  );
};
