import { AccessLevelsEnum } from "mc-shared/enums/enums";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { Progress, UncontrolledTooltip } from "reactstrap";
import { Checkbox, FoldableSection, McInput, MultiComment, TodoList } from "..";
import {
  CommentActions,
  FlatHierarchy,
  IBoard,
  IIssue,
  IMember,
  IMinProject,
  IMinUser,
  IPNSProps,
  IUser,
  onIssueChange,
  TodoActions,
} from "../../../../types/global.types";
import { IMcBimMongo } from "../../../../types/mcbimMono.types";
import { formatShortDate } from "../../app/routes/admin/selectors";
import { useGetSumsByFormulaQuery } from "../../app/routes/issuesApp/IssueBoardRTK";
import { getIssueBoardIdFromUrl } from "../../app/routes/issuesApp/issueUtils";
import { AppModalEnums } from "../../frontendConstants";
import CustomAttributeSection from "../CustomAttributes/CustomAttributeSection";
import { IEditorMetadata } from "../MultiComment/MultiComment";
import IssueConnectionsSection from "./IssueConnectionsSection";
import "./IssueDetails.css";
import IssueDetailsSection from "./IssueDetailsSection";
import IssueRisikoSection from "./IssueRisikoSection";
import IssueTimeSection from "./IssueTimeSection";
import IssueUserSection from "./IssueUserSection";

const IssueDetails: React.FC<{
  issue: IIssue;
  board: IBoard;
  members: IMember[];
  pnsItems: FlatHierarchy<IPNSProps>;
  onValueChange: onIssueChange;
  commentChange: (id: string, action: CommentActions, value?: string, metadata?: IEditorMetadata) => void;
  todoChange: (id: string, action: TodoActions, value?: string, isChecked?: boolean) => void;
  onTagsChange: (oldValue: string[], newValue: string[]) => void;
  tagOptions: string[];
  loggedInUser: IUser;
  onOpenConnectionModal: (issueId: string, issueBoardId: string, app: AppModalEnums) => void;
  mcbimSpaces: IMcBimMongo.SpaceBasicInfo[];
  onSpaceTypeAheadFocus: () => void;
  showSpaceInput: boolean;
}> = ({
  issue,
  board,
  members,
  onValueChange,
  commentChange,
  todoChange,
  onTagsChange,
  pnsItems,
  tagOptions,
  loggedInUser,
  onOpenConnectionModal,
  mcbimSpaces,
  onSpaceTypeAheadFocus,
  showSpaceInput,
}) => {
  const [_issue, setIssue] = useState<IIssue>(issue);
  const { showConclusion, buckets, types, access } = board ?? {};

  useEffect(() => {
    setIssue(issue);
  }, [issue.updatedAt]);

  const allTodosAreCompleted = () => {
    return _issue?.todos?.every((todo) => todo.completedBy != null);
  };

  const getAccessUsers = () => {
    if (access == null) {
      return [];
    }
    if (access.level === AccessLevelsEnum.USERS) {
      return access.users as IMinUser[];
    } else if (access.level === AccessLevelsEnum.GROUPS) {
      return members.reduce((acc, member) => {
        if (access.groups.includes(member.group) === true || member.group === "ADMIN") {
          acc.push(member.user);
        }
        return acc;
      }, []);
    }
  };

  const project = _issue.project as IMinProject;
  const projectId = project?._id;

  const location = useLocation();
  const issueBoardId = getIssueBoardIdFromUrl(location.pathname);
  const memoizedMultiComment = useMemo(
    () => (
      <MultiComment
        sortDesc
        comments={_issue.comments}
        onCreate={(text, metadata) => commentChange(null, "ADD", text, metadata)}
        onDelete={(_id) => commentChange(_id, "DELETE")}
        onSave={(_id, text, metadata) => commentChange(_id, "UPDATE", text, metadata)}
        loggedInUser={loggedInUser}
        members={members}
      />
    ),
    [_issue.comments],
  );

  const otherAttributeConfigContainsFormula = Object.keys(board?.otherAttributesConfig || {}).some(
    (attr) => board?.otherAttributesConfig[attr].typeOfValue === "FORMULA",
  );

  const {
    data: generatedFormulas = {},
    isError: getFormulaError,
    isLoading: getFormulaLoading,
  } = useGetSumsByFormulaQuery(
    {
      projectId: projectId,
      issueBoardId: board._id,
    },
    {
      skip: otherAttributeConfigContainsFormula === false,
    },
  );

  return (
    <div className="d-flex flex-column pr-4 pl-4">
      <FoldableSection>
        <FoldableSection.Header>
          <div className="d-flex flex-column">
            <div className="section-title mr-4">
              <McInput
                onBlur={(e) => onValueChange(_issue.title, e.target.value, "title")}
                value={_issue.title}
                placeholder="Skriv inn tittel"
                style={{ fontSize: "1.2rem" }}
              />
            </div>
            <div className="d-flex justify-content-between mr-4">
              {typeof _issue.lastUpdatedBy !== "string" && _issue.lastUpdatedBy?.name == null && (
                <div className="status-text truncateHeaderText gray-600-text">
                  Opprettet av {typeof _issue.createdBy !== "string" && _issue.createdBy?.name},{" "}
                  {formatShortDate(_issue.createdAt)}
                </div>
              )}
              {typeof _issue.lastUpdatedBy !== "string" &&
                _issue.lastUpdatedBy?.name != null &&
                _issue.status !== "Fullført" && (
                  <div className="status-text truncateHeaderText gray-600-text">
                    Oppdatert av {typeof _issue.lastUpdatedBy !== "string" && _issue.lastUpdatedBy?.name},{" "}
                    {formatShortDate(_issue.updatedAt)}
                  </div>
                )}
              {_issue.status === "Fullført" && (
                <div className="status-text truncateHeaderText text-success">
                  Fullført {formatShortDate(_issue?.dates?.completed)}
                </div>
              )}
              {(issueBoardId == null || issueBoardId !== board?._id) && (
                <>
                  <a id="goToRegister" href={`/#/project/${projectId}/issues/${board?._id}`} className="status-text">
                    {board?.name}
                    <i className="fa fa-external-link-square fa-fw" />
                  </a>
                  <UncontrolledTooltip target="goToRegister" placement="left">
                    Gå til {board.name}
                  </UncontrolledTooltip>
                </>
              )}
            </div>
          </div>
        </FoldableSection.Header>
        <FoldableSection.Body>
          <div className="d-flex flex-column w-100 mt-2 mb-2">
            <IssueUserSection
              issue={_issue}
              projectMembers={members.map((m) => m.user)}
              onChange={onValueChange}
              accessUsers={getAccessUsers()}
              accessLevel={access?.level}
            />
            <IssueDetailsSection
              issue={_issue}
              showConclusion={showConclusion ?? false}
              boardBuckets={buckets ?? []}
              boardTypes={types ?? []}
              onChange={onValueChange}
              onTagChange={(oldValue, newValue) => onTagsChange(oldValue, newValue)}
              tagOptions={tagOptions}
              pnsItems={pnsItems}
              mcbimSpaces={mcbimSpaces}
              onSpaceTypeAheadFocus={onSpaceTypeAheadFocus}
              showSpaceInput={showSpaceInput}
              disabledColumns={board.disabledColumns}
            />
          </div>
        </FoldableSection.Body>
      </FoldableSection>
      {board.risikoSection?.isActive && _issue.taskOrIssue === "ISSUE" && (
        <FoldableSection>
          <FoldableSection.Header>
            <div className="section-title">Risiko</div>
          </FoldableSection.Header>
          <FoldableSection.Body>
            <div className="d-flex flex-column w-100 mt-2 mb-2">
              <IssueRisikoSection issue={_issue} issueBoard={board} onChange={onValueChange} />
            </div>
          </FoldableSection.Body>
        </FoldableSection>
      )}
      <FoldableSection>
        <FoldableSection.Header>
          <div className="section-title">Tidsplan</div>
        </FoldableSection.Header>
        <FoldableSection.Body>
          <div className="d-flex flex-column w-100 mt-2 mb-2">
            <IssueTimeSection issue={_issue} onChange={onValueChange} disabledColumns={board.disabledColumns} />
          </div>
        </FoldableSection.Body>
      </FoldableSection>
      {issue.taskOrIssue === "ISSUE" && Object.keys(board?.otherAttributesConfig ?? {}).length > 0 && (
        <CustomAttributeSection
          issueLocalId={_issue.id}
          generatedFormulas={generatedFormulas}
          attributeConfig={board?.otherAttributesConfig}
          data={issue.otherAttributesData ?? {}}
          onSave={(oldValue, newValue, attributeName) => {
            if (oldValue !== newValue) {
              onValueChange(
                oldValue?.toString() ?? "",
                newValue,
                `otherAttributesData.${attributeName}` as keyof IIssue,
              );
            }
          }}
        />
      )}
      <FoldableSection>
        <FoldableSection.Header>
          <div className="d-flex">
            <span className="section-title">Sjekkliste</span>
            <div className="flex-fill" />
            <Checkbox
              color="#ccc"
              label="Vis i kanban"
              isChecked={issue.showTodosInKanban === true}
              setChecked={() => onValueChange(issue.showTodosInKanban, !issue.showTodosInKanban, "showTodosInKanban")}
            />
            <div className="ml-4" />
          </div>
          <div className="section-title d-flex align-items-center">
            {issue.todos?.length > 0 && (
              <>
                <div className="section-title-progress">
                  {issue.todos.filter((todo) => todo.completedBy?.name != null).length}/{issue.todos.length}
                </div>
                <Progress
                  className="w-100 thin-progress mr-4"
                  color={allTodosAreCompleted() === true ? "success" : "primary"}
                  value={issue.todos.filter((todo) => todo.completedBy?.name != null).length}
                  max={issue.todos.length}
                />
              </>
            )}
          </div>
        </FoldableSection.Header>
        <FoldableSection.Body>
          <div className="d-flex flex-column w-100 mt-2 mb-2 mr-4">
            <TodoList
              todos={issue.todos}
              addNewTodo={(text) => todoChange(null, "ADD", text)}
              updateTodo={(_id, text) => todoChange(_id, "UPDATE", text)}
              deleteTodo={(_id) => todoChange(_id, "DELETE")}
              toggleTodo={(_id, isChecked) => todoChange(_id, "TOGGLE", null, isChecked)}
              showComplete={false}
            />
          </div>
        </FoldableSection.Body>
      </FoldableSection>
      {(issue.connectedRisikos?.length > 0 ||
        issue.connectedChecklistItems?.length > 0 ||
        issue.connectedMeetingAgendas?.length > 0) && (
        <FoldableSection>
          <FoldableSection.Header>
            <div className="section-title">Tilkoblinger</div>
          </FoldableSection.Header>
          <FoldableSection.Body>
            <div className="d-flex flex-column w-100 mt-2 mb-2 mr-4">
              <IssueConnectionsSection
                projectId={projectId}
                connectedMeetingAgendas={issue.connectedMeetingAgendas}
                connectedRisikos={issue.connectedRisikos}
                connectedChecklistItems={issue.connectedChecklistItems}
                onOpenConnectionModal={onOpenConnectionModal}
              />
            </div>
          </FoldableSection.Body>
        </FoldableSection>
      )}
      <FoldableSection>
        <FoldableSection.Header>
          <div className="section-title">Diskusjon</div>
        </FoldableSection.Header>
        <FoldableSection.Body>
          <div className="d-flex flex-column w-100 mt-2 mb-2 mr-4">{memoizedMultiComment}</div>
        </FoldableSection.Body>
      </FoldableSection>
    </div>
  );
};

export default IssueDetails;
