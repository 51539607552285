import classnames from "classnames";
import React from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { Nav, NavItem, NavLink } from "reactstrap";
import BatchJobSettingsBanner from "../../../../../components/BatchJobSettingsBanner/BatchJobSettingsBanner";
import { BatchJobTemplateEnum } from "../../batchJobConstants";
import BatchJobLogPage from "../../logg/BatchJobLogPage";
import Bim360SheetExtractorSettingsPage from "./Bim360SheetExtractorSettingsPage";
import { useAppSelector } from "../../../../../hooks";

const Bim360SheetExtractor: React.FC = () => {
  const location = useLocation();

  const project = useAppSelector((state) => state.adminReducer.project);
  const activeBatchJob = useAppSelector((state) => state.batchJobReducer.activeBatchJob);

  const arr = location.pathname.split("/");
  const pathname = arr[arr.length - 1];

  const baseUrl = `/project/${project._id}/batchjob/${BatchJobTemplateEnum.BIM360SHEETEXTRACTOR.toLowerCase()}/${
    activeBatchJob?._id
  }`;

  const history = useHistory();

  const goToUrl = (tab: string) => {
    const path = `${baseUrl}/${tab}`;
    history.push(path);
  };

  return (
    <>
      <div style={{ position: "sticky", top: 0, zIndex: 1000 }}>
        <BatchJobSettingsBanner
          nrOfSettingsComplete={activeBatchJob.valid?.nrOfSettingsComplete}
          totalNumberOfSettings={activeBatchJob.valid?.totalNumberOfSettings}
          nextScheduledBatchJob={activeBatchJob.valid?.nextScheduledBatchJob}
        />
      </div>

      <Nav tabs>
        <NavItem className="custom">
          <NavLink className={classnames({ active: pathname === "log", custom: true })} onClick={() => goToUrl("log")}>
            Logg
          </NavLink>
        </NavItem>
        <NavItem className="custom">
          <NavLink
            className={classnames({ active: pathname === "settings", custom: true })}
            onClick={() => goToUrl("settings")}
          >
            Innstillinger
          </NavLink>
        </NavItem>
      </Nav>
      <Switch>
        <Route path={`${baseUrl}/settings`}>
          <Bim360SheetExtractorSettingsPage />
        </Route>
        <Route path={`${baseUrl}/log`}>
          <BatchJobLogPage />
        </Route>
      </Switch>
    </>
  );
};

export default Bim360SheetExtractor;
