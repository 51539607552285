import * as R from "ramda";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, ButtonGroup, ListGroup, ListGroupItem, UncontrolledTooltip } from "reactstrap";
import { useLocalStorage } from "usehooks-ts";
import { IMaconomyProject } from "../../../../../../types/global.types";
import { HSpace, McInput, MCLoader, VSpace } from "../../../../components";
import { SortAlphabeticIcon } from "../../../../components/CustomIcons/CustomIcons";
import { sortArrayByString, sortItemsByRecentIds } from "../../../utils";
import { doCreateOrJoinProject } from "../../admin/restQueries";

export const ProjectTable: React.FC<{
  projectsMCapps: IMaconomyProject[];
  projectsMaconomy: IMaconomyProject[];
}> = ({ projectsMCapps, projectsMaconomy }) => {
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState<string>("");
  const [sortByRecent, setSortByRecent] = useLocalStorage("sortByRecentProject", {
    isSortedByRecent: true,
    recentItemIds: [],
  });
  const goToProject = async (maconomy: IMaconomyProject) => {
    setLoading(true);
    const project = await doCreateOrJoinProject(maconomy);
    setLoading(false);
    if (project.useDashboardAsDefaultStartPage) {
      history.push(`/project/${project._id}/dashboard`);
    } else {
      history.push(`/project/${project._id}/apps`);
    }
  };

  const mergedProjects: IMaconomyProject[] = sortArrayByString(
    R.uniqBy(R.prop("projectNo"), [...projectsMCapps, ...projectsMaconomy]),
    "name",
  );

  const handleClick = (isSortedByRecent: boolean) => {
    setSortByRecent({ recentItemIds: sortByRecent.recentItemIds, isSortedByRecent: isSortedByRecent });
  };
  function sortItemsByRecent(items: IMaconomyProject[], recentIds: string[]) {
    if (sortByRecent.isSortedByRecent != true || searchText != "") {
      return items;
    }
    return sortItemsByRecentIds(items, recentIds, (item) => item.projectNo);
  }
  return (
    <div>
      {loading === false && (
        <>
          <div className="d-flex">
            <McInput placeholder="Søk..." value={searchText} onChange={(e) => setSearchText(e.target.value)} />
            <ButtonGroup className="ml-1">
              <Button
                id="sortByAlphabetical"
                style={{ fontSize: ".8em", padding: "0 10px" }}
                color={sortByRecent.isSortedByRecent === false ? "mc-blue" : "secondary"}
                onClick={() => handleClick(false)}
              >
                <SortAlphabeticIcon />
              </Button>

              <Button
                id="sortByRecent"
                style={{ fontSize: "1em", padding: "0 8px" }}
                color={sortByRecent.isSortedByRecent === true ? "mc-blue" : "secondary"}
                onClick={() => handleClick(true)}
              >
                <i className="fa fa-clock-o fa-fw" />
              </Button>
            </ButtonGroup>
            <UncontrolledTooltip delay={300} target="sortByRecent">
              Sorter etter nylig brukte
            </UncontrolledTooltip>
            <UncontrolledTooltip delay={300} target="sortByAlphabetical">
              Sorter alfabetisk
            </UncontrolledTooltip>
          </div>
          <VSpace />
          <ListGroup>
            {sortItemsByRecent(mergedProjects, sortByRecent.recentItemIds)
              .filter((project) => {
                if (searchText === "") {
                  return true;
                } else {
                  return (
                    project?.name?.toLowerCase().includes(searchText.toLowerCase()) ||
                    String(project?.projectNo)?.toLowerCase().includes(searchText.toLowerCase())
                  );
                }
              })
              .map((project) => (
                <ProjectListGroupItem key={project?.projectNo} maconomy={project} goToProject={goToProject} />
              ))}
          </ListGroup>
        </>
      )}
      {loading && <MCLoader loading={loading} />}
    </div>
  );
};

const ProjectListGroupItem: React.FC<{ maconomy: IMaconomyProject; goToProject: any }> = ({
  maconomy,
  goToProject,
}) => {
  return (
    <ListGroupItem
      href="#"
      action
      className="d-flex align-content-center"
      style={{ cursor: "pointer" }}
      onClick={() => goToProject(maconomy)}
    >
      <i className="fa fa-briefcase fa-3x fa-fw mt-2 gray-300-text" />
      <HSpace />
      <div className="d-flex flex-column">
        <h3 className="mb-0">{maconomy?.name}</h3>
        <p>{maconomy?.projectNo}</p>
      </div>
    </ListGroupItem>
  );
};
