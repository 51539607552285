import Axios from "axios";
import { IProject, IPsetAndProperty } from "../../../../../types/global.types";
import { IMCBIMAPI } from "../../../../../types/mcbim.types";

export const doGetActiveMcBimProjects = async (): Promise<IProject[]> => {
  try {
    const resp = await Axios.get("/api/systemadmin/activemcprojects");
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doEnableMcBim = async (
  maconomyProjectNo: string,
  adminEmail: string,
  fileAttributeExtractSettings: IMCBIMAPI.AllFourFileAttributeExtracSettings,
  psetsFilter: string[],
  pns: IPsetAndProperty,
  defaultProp: IPsetAndProperty,
): Promise<IProject[]> => {
  try {
    const fetchData = await Axios.post("/api/systemadmin/createmcbimproject", {
      maconomyProjectNo,
      adminEmail,
      fileAttributeExtractSettings,
      psetsFilter,
      pns,
      defaultProp,
    });
    return fetchData.data?.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};
