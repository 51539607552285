import * as R from "ramda";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { Button } from "reactstrap";
import { HSpace } from "../../";
import { IDocxTemplate } from "../../../../../types/global.types";
import { pathIsInIssueApp } from "../../../app/routes/issuesApp/issueUtils";
import UploadButton from "../../UploadButton";
import DocxDemodataModal from "./DocxDemodataModal";

const NewDocxUpload: React.FC<{
  demoData: any;
  uploadFunc: (event: React.ChangeEvent<HTMLInputElement>, level?: string) => void;
  uploadCompleedCb: () => void;
  docxTemplates: IDocxTemplate[];
}> = ({ demoData, uploadFunc, uploadCompleedCb, docxTemplates }) => {
  const [showDemoDataModal, setShowDemoDataModal] = useState<boolean>();
  const [dupWarning, setDupWarning] = useState<boolean>(false);
  const [errors, setErrors] = useState(null);

  const history = useHistory();
  const location = history.location;

  const urlIsIssueApp = pathIsInIssueApp(location.pathname);

  const uploadFile = async (event: React.ChangeEvent<HTMLInputElement>, level: string) => {
    if (docxTemplates?.find((tmpl) => event.target.files[0].name === tmpl.fileName) != null) {
      setDupWarning(true);
      return;
    }
    setDupWarning(false);

    setErrors(null);
    try {
      await uploadFunc(event, level);
      uploadCompleedCb();
    } catch (err) {
      const errorToSet = R.pathOr(err, ["response", "data", "error", "error", "properties"], err);
      setErrors(errorToSet);
    }
  };

  return (
    <div>
      {showDemoDataModal && <DocxDemodataModal docxDemoData={demoData} close={() => setShowDemoDataModal(false)} />}
      <div className="d-flex align-items-center">
        <UploadButton buttonText={"Prosjektmal"} onFileSelected={(event) => uploadFile(event, "PROJECT")} />
        {urlIsIssueApp === true && (
          <UploadButton
            className="ml-2"
            buttonText={"Registermal"}
            onFileSelected={(event) => uploadFile(event, "BOARD")}
          />
        )}
        <HSpace />
        <Button color="mc-blue" onClick={() => setShowDemoDataModal(true)}>
          Vis demodata
        </Button>
        <div className="flex-fill" />
        <a href="https://docxtemplater.com/demo/" target="_blank" rel="noreferrer">
          Se dokumentasjon
          <i className="fa fa-external-link   fa-fw" />
        </a>
      </div>
    </div>
  );
};

export default NewDocxUpload;
