import {
  CellEditFormatter,
  COL_WIDTHS,
  createHeaderDropDownFilter,
  createHeaderStyle,
  headerSortAndDropDownFilter,
  headerSortAndFilterFormatter,
  sortCaret,
} from "../../../components/columnGlobal";
import { ClickableTableIdButton, DisplayDueDate, OptionBadge } from "../../../components";
import {
  ISSUE_COMPLETED_VALUE,
  ISSUE_DATAFILEDS_ENUM,
  ISSUE_EXPIRED,
  ISSUE_SECTIONS,
  ISSUE_STATES_OPTIONS,
} from "../issuesApp/issueConstants";
import moment from "moment";
import React from "react";

import { Comparator, dateFilter, textFilter } from "react-bootstrap-table2-filter";
import { IMember, IRisiko } from "../../../../../types/global.types";
import { AppModalEnums } from "../../../frontendConstants";
import { formatShortDate } from "../admin/selectors";
import { useAppDispatch } from "../../../hooks";
import { convertValueAndColorToMultiDisplayColor } from "../issuesApp/issueUtils";
import { addAppModalThunk } from "../../../global/globalSlice";

export const getTiltakTableColumns = (
  hasOldId: boolean,
  projectId: string,
  members: IMember[],
  onOpenIssue: (_id: string, issueBoardId: string) => void,
) => {
  return [
    {
      dataField: "_id",
      text: "_id",
      hidden: true,
    },
    {
      dataField: "issueBoard",
      text: "issueBoard",
      hidden: true,
    },
    {
      dataField: "id",
      text: "Tiltak id",
      filter: textFilter({ placeholder: "Tast inn..." }),
      sort: true,
      sortCaret: sortCaret,
      headerFormatter: headerSortAndFilterFormatter,
      headerSortingClasses: "sortedColumn",
      headerStyle: createHeaderStyle(COL_WIDTHS.ID),
      formatter: (cell, row) => (
        <ClickableTableIdButton id={cell} _id={row._id} setItem={() => onOpenIssue(row._id, row.issueBoard)} />
      ),
    },
    {
      dataField: "previousIdInRisikoAction",
      text: "Forrige id",
      filter: textFilter({ placeholder: "Tast inn..." }),
      sort: true,
      hidden: hasOldId === false,
      sortCaret: sortCaret,
      headerFormatter: headerSortAndFilterFormatter,
      headerSortingClasses: "sortedColumn",
      headerStyle: createHeaderStyle(COL_WIDTHS.ID),
      formatter: (cell, row) => `T${cell}`,
    },
    {
      dataField: "title",
      text: "Navn",
      filter: textFilter({ placeholder: "Tast inn..." }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses: "sortedColumn",
      headerFormatter: headerSortAndFilterFormatter,
      headerStyle: createHeaderStyle(COL_WIDTHS.LONGTEXT),
      formatter: CellEditFormatter,
    },
    {
      dataField: "createdAt",
      text: "Opprettet",
      filter: dateFilter({
        defaultValue: { comparator: Comparator.GT },
      }),
      sort: true,
      sortCaret: sortCaret,
      headerFormatter: headerSortAndFilterFormatter,
      headerStyle: createHeaderStyle(COL_WIDTHS.DATE),
      formatter: (cell) => formatShortDate(cell),
      headerSortingClasses: "sortedColumn",
    },
    {
      dataField: ISSUE_DATAFILEDS_ENUM.STATUS,
      text: "Status",
      classes: "editable-cell",
      filter: createHeaderDropDownFilter(ISSUE_STATES_OPTIONS, "value"),
      sort: true,
      sortCaret: sortCaret,
      headerFormatter: headerSortAndDropDownFilter,
      headerStyle: createHeaderStyle(COL_WIDTHS.CHIP),
      section: ISSUE_SECTIONS.COMMON,
      headerSortingClasses: "sortedColumn",
      dropdownOptions: convertValueAndColorToMultiDisplayColor(ISSUE_STATES_OPTIONS),
      formatter: (cell) => (
        <OptionBadge activeOption={cell} options={convertValueAndColorToMultiDisplayColor(ISSUE_STATES_OPTIONS)} />
      ),
    },
    {
      dataField: ISSUE_DATAFILEDS_ENUM.OWNER_NAME,
      text: "Eier",
      filter: createHeaderDropDownFilter(members, "user.name"),
      sort: true,
      sortCaret: sortCaret,
      headerFormatter: headerSortAndDropDownFilter,
      headerStyle: createHeaderStyle(COL_WIDTHS.PERSON),
      section: ISSUE_SECTIONS.COMMON,
      headerSortingClasses: "sortedColumn",
      formatExtraData: {
        avatar: ["owner", "_id"],
      },
      formatter: CellEditFormatter,
    },
    {
      dataField: ISSUE_DATAFILEDS_ENUM.ASSIGNEDTO_NAME,
      text: "Ansv. for utførelse",
      filter: createHeaderDropDownFilter(members, "user.name"),
      sort: true,
      sortCaret: sortCaret,
      headerFormatter: headerSortAndDropDownFilter,
      headerStyle: createHeaderStyle(COL_WIDTHS.PERSON),
      section: ISSUE_SECTIONS.COMMON,
      headerSortingClasses: "sortedColumn",
      formatExtraData: {
        avatar: ["assignedTo", "_id"],
      },
      formatter: CellEditFormatter,
    },
    {
      dataField: ISSUE_DATAFILEDS_ENUM.DATES_DUE,
      text: "Tidsfrist dato",
      filter: dateFilter({
        defaultValue: { comparator: Comparator.GT },
      }),
      sort: true,
      sortCaret: sortCaret,
      headerFormatter: headerSortAndFilterFormatter,
      headerStyle: createHeaderStyle(COL_WIDTHS.DATE),
      headerSortingClasses: "sortedColumn",
      formatter: (cell, row) => {
        if (moment(cell).isValid() === false || cell == null || !cell) {
          return "-";
        }
        return (
          <DisplayDueDate
            dueDate={cell}
            isComplete={row != null && (row.status === ISSUE_COMPLETED_VALUE || row.status === ISSUE_EXPIRED)}
          />
        );
      },
    },
    {
      dataField: ISSUE_DATAFILEDS_ENUM.DATES_COMPLETED,
      text: "Lukket dato",
      filter: dateFilter({
        defaultValue: { comparator: Comparator.GT },
      }),
      sort: true,
      sortCaret: sortCaret,
      headerFormatter: headerSortAndFilterFormatter,
      headerStyle: createHeaderStyle(COL_WIDTHS.DATE),
      formatter: (cell) => formatShortDate(cell),
      headerSortingClasses: "sortedColumn",
    },
    {
      dataField: "connectedRisikos",
      text: "Tillknytte risiko",
      headerFormatter: headerSortAndFilterFormatter,
      headerStyle: createHeaderStyle(COL_WIDTHS.LONGTEXT),
      headerSortingClasses: "sortedColumn",
      formatter: (cell) => <ConnectedRisikos risikos={cell} projectId={projectId} />,
    },
  ];
};

const ConnectedRisikos: React.FC<{ risikos: IRisiko[]; projectId: string }> = ({ risikos, projectId }) => {
  const dispatch = useAppDispatch();
  return (
    <div className="d-flex flex-column">
      {risikos != null && (
        <>
          {risikos.map((risiko) => {
            return (
              <div className="d-flex p-0 m-0" key={risiko._id}>
                <ClickableTableIdButton
                  id={risiko.id}
                  _id={risiko._id}
                  setItem={(_id) =>
                    dispatch(
                      addAppModalThunk({
                        itemId: _id,
                        boardId: risiko.risikoBoard,
                        projectId: projectId,
                        app: AppModalEnums.RISIKO,
                      }),
                    )
                  }
                />
                <div className="mt-2">{risiko.title}</div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};
