import { IColumnValidator, IValidationResult } from "mc-shared/zod/excelImportSchema";

export const tableArrayToObjectArray = (validatedData: IValidationResult[][]): object[] => {
  if (validatedData == null || validatedData.length === 0) {
    return [];
  }

  const headers = validatedData[0].map((titleCell) => String(titleCell.value));
  const headerLength = headers.length;
  const longestRowLength = validatedData.reduce((acc, row) => (row.length > acc ? row.length : acc), 0);

  // Pad header if it is shorter than the longest row
  if (longestRowLength > headerLength) {
    for (let i = headerLength; i < longestRowLength; i++) {
      headers.push(`Column ${i + 1}`);
    }
  }

  // If header contains empty values, change them to [Column1] [Column2] etc.
  headers.forEach((value, index) => {
    if (value.trim() === "") {
      headers[index] = `Column ${index + 1}`;
    }
  });

  // Change duplicate header values to [header] [header(1)] etc.
  const headerMap = new Map<string, number>();
  const deduplicatedHEaders = headers.reduce((acc, value) => {
    const val = String(value);
    if (headerMap.has(val)) {
      const count = headerMap.get(val);
      headerMap.set(val, count + 1);
      acc.push(`${val}(${count})`);
    } else {
      headerMap.set(val, 1);
      acc.push(val);
    }

    return acc;
  }, []);

  // Create object array from grid data
  const objArr = validatedData.slice(1).map((row) => {
    const rowObject = {
      __validationResult: row,
    };

    for (let i = 0; i < deduplicatedHEaders.length; i++) {
      rowObject[deduplicatedHEaders[i]] = row[i] ?? "";
    }

    return rowObject;
  });

  return objArr;
};

export const validateExcelData = (
  data: (string | number)[][],
  validators: IColumnValidator[],
  skipValidateRows = 0,
): IValidationResult[][] => {
  const validatedData: IValidationResult[][] = [];

  for (let row = 0; row < data.length; row++) {
    const validatedRow: IValidationResult[] = [];
    const rowValues = data[row];

    if (rowValues.length === 0) {
      validatedRow.push({ isValid: false, value: "", col: 0, row });
      validatedData.push(validatedRow);
      continue;
    }

    for (let col = 0; col < rowValues.length; col++) {
      const value = rowValues[col];

      if (row < skipValidateRows) {
        validatedRow.push({ isValid: null, value, col, row });
      } else {
        const isValid = validators[col]?.validate(value, data, row, col) ?? null;
        validatedRow.push({ isValid, value, col, row });
      }
    }

    validatedData.push(validatedRow);
  }

  return validatedData;
};

export const pnsParentValidator: IColumnValidator = {
  validate: (value, dataSet, row, col) => {
    if (value == null) {
      return false;
    }

    const val = String(value);

    if (val === "") {
      return false;
    }

    const partCount = val.split(".").length;

    if (partCount === 1) {
      return true;
    }

    // find parent with one less part above current row by iterating backwards
    let parentValue = null;

    for (let i = row - 1; i >= 0; i--) {
      const rowValue = String(dataSet[i][col]);
      const rowPartCount = rowValue.split(".").length;
      if (rowPartCount === partCount - 1) {
        parentValue = rowValue;
        break;
      }
    }

    if (parentValue == null) {
      return false;
    }

    if (val?.startsWith(parentValue)) {
      return true;
    }

    return false;
  },
  errorMessage: "PNS må ha forelder med en del mindre",
};

export const emptyValidator: IColumnValidator = {
  validate: (value) => {
    return value != null && value !== "";
  },
  errorMessage: "Verdien skal ikke være tom",
};

const numberRegex = /^\d+([.,]\d+)?$/;

export const numberValidator: IColumnValidator = {
  validate: (value) => numberRegex.test(String(value)),
  errorMessage: "Verdien skal være et tall",
};

export const increasingNumberValidator: IColumnValidator = {
  validate: (currentVal, dataSet, row, col) => {
    // current value should exist
    if (currentVal == null) {
      return false;
    }

    const currentValTrim = currentVal.toString().trim();

    // current value should not be empty
    if (currentValTrim === "") {
      return false;
    }

    const currentNum = Number(currentValTrim);

    // current value should be a number and larger than 0
    if (isNaN(currentNum) || currentNum < 0) {
      return false;
    }

    // Accept any number if first row
    if (row === 0) {
      return true;
    }

    const previousRow = dataSet[row - 1];
    const previousVal = previousRow[col];

    // previous value should exist
    if (previousVal == null) {
      return false;
    }

    const previousValTrim = previousVal.toString().trim();

    // current value and previous value should not be the same
    if (currentValTrim === previousValTrim) {
      return false;
    }

    const previousNum = Number(previousValTrim);

    // previous value should be a number and larger than 0
    if (isNaN(previousNum) || currentNum < 0) {
      return false;
    }

    const valParts = currentValTrim.split(".");
    const previousValParts = previousValTrim.split(".");

    const nums = [valParts[0], valParts[1], previousValParts[0], previousValParts[1]].map((part) => {
      return part == null ? 0 : Number(part);
    });

    // decimal part should be larger than previous decimal part if integer part is the same
    if (nums[0] === nums[2]) {
      return nums[1] > nums[3];
    }

    // current value should be larger than previous value
    return currentNum > previousNum;
  },
  errorMessage: "Verdien skal være et psotivt tall i formatet 1 eller 1.1, og verdien må være større enn forrige rad",
};

export const geoReportValidation = [
  emptyValidator,
  numberValidator,
  numberValidator,
  numberValidator,
  emptyValidator,
  numberValidator,
  numberValidator,
];
