import React, { Children, ReactElement, ReactNode, useEffect, useState } from "react";

interface IFoldableSectionCompound {
  Header: typeof FoldableSectionHeader;
  Body: typeof FoldableSectionBody;
}

const FoldableSection: React.FC<{ folded?: boolean; headerClick?: boolean }> & IFoldableSectionCompound = ({
  children,
  folded = false,
  headerClick = false,
  ...props
}) => {
  const [collapsed, setCollapsed] = useState<boolean>(folded);
  const [removedOverflow, setRemovedOverflow] = useState<boolean>(false);

  const [headerElement, setHeaderElement] = useState<ReactNode>(children[0]);
  const [bodyElement, setBodyElement] = useState<ReactNode>(children[1]);

  useEffect(() => {
    Children.forEach(children, (child: ReactElement) => {
      if (child.type === FoldableSectionHeader) {
        setHeaderElement(child);
      }
      if (child.type === FoldableSectionBody) {
        setBodyElement(child);
      }
    });
  }, [children]);

  useEffect(() => {
    setCollapsed(folded);
  }, [folded]);

  useEffect(() => {
    if (!collapsed) {
      const delayedOverflow = setTimeout(() => setRemovedOverflow(true), 400);
      return () => clearTimeout(delayedOverflow);
    } else setRemovedOverflow(false);
  }, [collapsed]);

  const handleHeaderClick = () => {
    if (headerClick) {
      setCollapsed(!collapsed);
    }
  };

  return (
    <div className="w-100 d-flex" {...props}>
      <div
        onClick={() => setCollapsed(!collapsed)}
        className={`expandIcon${collapsed === true ? "" : " rotateIcon"}`}
        style={{ minWidth: "2rem", minHeight: "2rem", marginTop: "0.6rem" }}
      >
        <i className="fa fa-angle-down"></i>
      </div>
      <div className="d-flex flex-column w-100">
        <div className={`mt-2 ${headerClick ? "pointer" : ""}`} onClick={() => handleHeaderClick()}>
          {headerElement}
        </div>
        <div
          style={
            collapsed === true
              ? { height: 0, overflow: "hidden" /* transition: "height 0.4s ease-in-out" */ }
              : {
                  /*height  transition: "height 0.4s ease-in-out" */
                }
          }
          className={`d-flex w-100${removedOverflow === true ? "" : "overflow-hidden"}`}
        >
          {bodyElement}
        </div>
      </div>
    </div>
  );
};

const FoldableSectionHeader: React.FC = ({ children }) => {
  return <>{children}</>;
};

const FoldableSectionBody: React.FC = ({ children }) => {
  return <>{children}</>;
};

FoldableSection.Header = FoldableSectionHeader;
FoldableSection.Body = FoldableSectionBody;

export default FoldableSection;
