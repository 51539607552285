export enum AccessLevelsEnum {
  PROJECT = "PROJECT",
  USERS = "USERS",
  GROUPS = "GROUPS",
}

export const AccessNorwegianEnum = {
  PROJECT: "Prosjekt",
  USERS: "Brukere",
  GROUPS: "Grupper",
};

export enum AccessGroupsEnum {
  INTERNAL = "INTERNAL",
  CLIENT = "CLIENT",
  PARTNER = "PARTNER",
  ADMIN = "ADMIN",
}

export enum IssueViewEnum {
  TABLE = "TABLE",
  GIS = "GIS",
  KANBAN = "KANBAN",
  DASHBOARD = "DASHBOARD",
  PNS = "PNS",
  GANTT = "GANTT",
  BIM = "BIM",
  "NONE" = "NONE",
}

export enum IssueDisabledColumnsEnum {
  REFERENCEURL = "referenceUrl",
  SPACE = "space",
  PNS = "pns",
  TYPEOFISSUE = "typeOfIssue",
  BUCKET = "bucket",
  HOURSORIGINALESTIMATE = "hours.orignalEstimate",
  HOURSCOMPLETED = "hours.completed",
  HOURSREMAINING = "hours.remaining",
}
