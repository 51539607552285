import { IAllFilter } from "mc-shared/zod/commonSchema";
import {
  IConsequence,
  IConsquenceRecord,
  IEvaluationCategory,
  IMapOfEvaluationCategories,
  IMapOfRisikoProbabilities,
  IMatrixConfig,
  IMatrixItem,
  IRisikoProbability,
} from "mc-shared/zod/issueBoardSchema";
import { ITableTemplate, ITitleAndDescription } from "mc-shared/zod/issueSchema";
import { ICapitalLetter } from "mc-shared/zod/otherAttributesSchema";
import moment from "moment";
import * as R from "ramda";
import {
  IBoard,
  IBoardBucket,
  IIssue,
  IMultiOptionsColors,
  ITag,
  IValueAndColor,
  Paths,
  PathValue,
} from "../../../../../types/global.types";
import {
  IMatrixCell,
  IRisikoCountMatrix,
  IRisikoCountMatrixConfig,
  IScoreMatrixConfig,
} from "../../../../../types/issue.types";
import { IBoardByGroupKey, ITaskCompleteCount, IViewPoint } from "../../../types";
import { countArrByProp, formatTags, sortArrayByNumber } from "../../utils";
import {
  ISSUE_ACTION_NAME_OPTIONS,
  ISSUE_BOARD_STATUSES,
  ISSUE_COMPLETED_VALUE,
  ISSUE_DUEDATE_OPTIONS,
  ISSUE_EXPIRED,
  ISSUE_REGISTER_NAME_OPTIONS,
  ISSUE_STATES_OPTIONS,
  IssueKanbanGroupingViewEnum,
} from "./issueConstants";
import { IKanbanColumn } from "./types";

export const convertIssuesToKanbanBoardColumns = (
  issues: IIssue[],
  kanbanGroupingView: IssueKanbanGroupingViewEnum,
  buckets: IBoardBucket[],
): IKanbanColumn<IIssue>[] => {
  let arr;
  let groupKey: "status" | "bucket" | "tidsfrist";

  if (kanbanGroupingView === IssueKanbanGroupingViewEnum.STATUS) {
    arr = ISSUE_STATES_OPTIONS;
    groupKey = "status";
  }

  if (kanbanGroupingView === IssueKanbanGroupingViewEnum.BUCKET) {
    arr = sortArrayByNumber(buckets, "order");
    groupKey = "bucket";
  }

  if (kanbanGroupingView === IssueKanbanGroupingViewEnum.TIDSFRIST) {
    arr = ISSUE_DUEDATE_OPTIONS;
    groupKey = "tidsfrist";
  }

  let cardId = 0;

  return (arr || []).map((item, index) => {
    let issuesWithState = [];
    let sortedIssuesWithState = [];
    if (groupKey === "bucket" || groupKey === "status") {
      issuesWithState = issues.filter((issue) => issue[groupKey] === item.value);
      sortedIssuesWithState = sortArrayByNumber(issuesWithState, "kanbanOrder." + groupKey);
    }
    if (groupKey === "tidsfrist") {
      const today = moment().startOf("day");
      const tomorrow = moment().add(1, "days").startOf("day");
      const endOfWeek = moment().endOf("week");
      const startOfNextWeek = moment().add(1, "weeks").startOf("isoWeek");
      const endOfNextWeek = moment().add(1, "weeks").endOf("week");

      issuesWithState = issues.filter((issue) => {
        const dueDate = issue.dates?.due;
        if (dueDate === "" || dueDate == null) {
          return item.value === "Ingen dato";
        }

        const dueDateMoment = moment(dueDate).startOf("day");

        if (item.value === "Forsen") {
          return dueDateMoment.isBefore(today);
        } else if (item.value === "I dag") {
          return dueDateMoment.isSame(today, "day");
        } else if (item.value === "I morgen") {
          return dueDateMoment.isSame(tomorrow, "day");
        } else if (item.value === "Denne uken") {
          return dueDateMoment.isAfter(tomorrow) && dueDateMoment.isSameOrBefore(endOfWeek);
        } else if (item.value === "Neste uke") {
          return dueDateMoment.isSameOrAfter(startOfNextWeek) && dueDateMoment.isSameOrBefore(endOfNextWeek);
        } else if (item.value === "Fremtidig") {
          return dueDateMoment.isAfter(endOfNextWeek);
        } else {
          return false;
        }
      });

      sortedIssuesWithState = sortArrayByNumber(issuesWithState, "kanbanOrder." + groupKey);
    }

    const column: IKanbanColumn<IIssue> = {
      id: index,
      title: item.value,
      value: item.value,
      cards: sortedIssuesWithState?.map((issue) => {
        cardId++;
        return {
          _id: issue._id,
          id: cardId,
          title: String(issue.id),
          description: issue.title,
          content: issue,
        };
      }),
    };
    return column;
  });
};

export const getTaskCompleteCount = (issue: IIssue, darkText = false): ITaskCompleteCount => {
  const allConnectedIssuesAndTasks = [
    ...(issue.connectedTasks || []),
    ...(issue.connectedTasksAndIssuesFromOtherBoards || []),
  ];
  const nonArchivedTasks =
    allConnectedIssuesAndTasks == null
      ? []
      : (allConnectedIssuesAndTasks as IIssue[]).filter((task) => task.archived !== true);
  const count = countArrByProp(nonArchivedTasks, "status", ISSUE_COMPLETED_VALUE);
  const taskHasOverDueDates = nonArchivedTasks.some(
    (task) =>
      moment().isAfter(task.dates?.due) && task.status !== ISSUE_COMPLETED_VALUE && task.status !== ISSUE_EXPIRED,
  );
  return {
    darkText,
    totalNr: nonArchivedTasks.length,
    showWarning: hasUncompletedWithOverdueDates(taskHasOverDueDates, count, nonArchivedTasks.length),
    completedCount: count,
  };
};

const hasUncompletedWithOverdueDates = (hasOverdueDates: boolean, completedNr: number, totalNr: number) => {
  return hasOverdueDates && completedNr !== totalNr;
};

export const hasIssueGisConnection = (issue: IIssue) => {
  return issue.gis != null;
};

export const groupBoardsByStatus = (boards: IBoard[]): IBoardByGroupKey => {
  const statuses = new Map();

  ISSUE_BOARD_STATUSES.forEach((status) => {
    statuses.set(status.label, []);
  });

  boards
    .filter((b) => b.status.toLocaleLowerCase() !== "deleted")
    .forEach((board) => {
      const status = ISSUE_BOARD_STATUSES.find((status) => status.value === board.status);

      if (status != null) {
        statuses.get(status.label).push(board);
      }
    });

  return Object.fromEntries(statuses);
};

export const getAllFiltesWithIsActiveModifiedByTableTemplate = (
  allFilters: IAllFilter[],
  tableTemplate: ITableTemplate,
): IAllFilter[] => {
  const emptyAllFilters = R.clone(allFilters).map((filterItem) => {
    if (filterItem.options != null) {
      filterItem.options = filterItem.options.map((opt) => {
        return {
          label: opt.label,
          isActive: false,
          value: opt.value,
        };
      });
    }
    if (filterItem.bool != null) {
      filterItem.bool = false;
    }
    return filterItem;
  });

  const _allFilters = emptyAllFilters.map((allFilterItem) => {
    const filterItemInTableTemplate = tableTemplate.filters.find(
      (filterItem) => filterItem.dataField === allFilterItem.dataField,
    );
    if (filterItemInTableTemplate != null && filterItemInTableTemplate.typeOfFilter === "MULTIFILTER") {
      allFilterItem.options = allFilterItem.options.map((opt) => {
        return {
          isActive: filterItemInTableTemplate.multiFilterValues.indexOf(opt.value) !== -1,
          label: opt.label,
          value: opt.value,
        };
      });
    }
    if (filterItemInTableTemplate != null && filterItemInTableTemplate.typeOfFilter === "BOOLEANFILTER") {
      allFilterItem.bool = filterItemInTableTemplate.isActive;
    }
    return allFilterItem;
  });
  return _allFilters;
};

export const addMissingKeysIssue = (
  issue: IIssue,
  //@ts-ignore
  valueToReplaceWith: PathValue<IIssue, Paths<IIssue, 4>> = null,
  additionalParameters: string[] = [],
): IIssue => {
  const keys: { prop: Paths<IIssue, 4>; value: PathValue<IIssue, Paths<IIssue, 4>> }[] = [
    { prop: "taskOrIssue", value: valueToReplaceWith },
    { prop: "typeOfIssue", value: valueToReplaceWith },
    { prop: "id", value: valueToReplaceWith },
    { prop: "title", value: valueToReplaceWith },
    { prop: "referenceUrl", value: valueToReplaceWith },
    { prop: "pns", value: valueToReplaceWith },
    { prop: "owner", value: valueToReplaceWith },
    { prop: "description", value: valueToReplaceWith },
    { prop: "conclusion", value: valueToReplaceWith },
    { prop: "owner.name", value: valueToReplaceWith },
    { prop: "createdBy.name", value: valueToReplaceWith },
    { prop: "assignedTo.name", value: valueToReplaceWith },
    { prop: "owner.name", value: valueToReplaceWith },
    { prop: "dates.plannedStart", value: "" },
    { prop: "dates.due", value: "" },
    { prop: "dates.completed", value: "" },
    { prop: "lastUpdatedBy.name", value: valueToReplaceWith },
    { prop: "status", value: valueToReplaceWith },
    { prop: "priority", value: valueToReplaceWith },
    { prop: "bucket", value: valueToReplaceWith },
    { prop: "hours.orignalEstimate", value: valueToReplaceWith },
    { prop: "hours.remaining", value: valueToReplaceWith },
    { prop: "hours.completed", value: valueToReplaceWith },
    { prop: "itemMembers", value: valueToReplaceWith },
  ];

  keys.forEach((key) => {
    if (typeof key.prop !== "string") {
      return;
    }

    const splitKeys = key.prop.split(".");

    if (R.path(splitKeys, issue) == null) {
      issue = R.assocPath(splitKeys, key.value, issue);
    }
  });

  additionalParameters.forEach((param) => {
    const splitKeys = ("customAttributeData." + param).split(".");
    if (R.path(splitKeys, issue) == null) {
      issue = R.assocPath(splitKeys, valueToReplaceWith, issue);
    }
  });

  issue.issueIdTaskId = issue.taskOrIssue === "ISSUE" ? String(issue.id) : `${issue.connectedIssue?.id}-${issue.id}`;

  return issue;
};

export const getCustomAttributes = (board: IBoard) => {
  return Object.keys(board.customAttributesConfig ?? {}).reduce((acc, curr) => {
    acc.push(curr);

    Object.keys(board.customAttributesConfig[curr]).forEach((key) => {
      acc.push(`${curr}.${key}`);
    });

    return acc;
  }, []);
};

export const getIssueUrlPath = (historyLocationPathName: string, issueDbId: string) => {
  return `${historyLocationPathName}?id=${issueDbId}`;
};

export const getIssueBoardIdFromUrl = (historyLocationPathName: string) => {
  return historyLocationPathName.split("/")[4];
};

export const pathIsInIssueApp = (historyLocationPathName: string): boolean => {
  return historyLocationPathName.includes("/issues/");
};

export const convertIssuesToViewPoints = (issues: IIssue[]): IViewPoint[] => {
  const givenIssuesWithGisPoints = issues.filter((issue) => {
    return issue.gis?.gisPoint?.x != null;
  });

  return givenIssuesWithGisPoints.map((issue) => {
    const state = ISSUE_STATES_OPTIONS.find((opt) => opt.value === issue.status);
    return {
      _id: issue._id,
      gis: issue.gis,
      title: issue.title,
      status: {
        title: state?.value,
        color: state?.color,
      },
    };
  });
};

export const getFiltersWithActiveOption = (allFilters: IAllFilter[]): IAllFilter[] => {
  return (allFilters ?? []).reduce((acc, item) => {
    const hasActiveOption = item.options?.some((opt) => opt.isActive === true);
    if (hasActiveOption === true) {
      let copyOfItem = R.clone(item);
      copyOfItem.options = item.options.filter((opt) => opt.isActive === true);
      acc.push(copyOfItem);
    }
    return acc;
  }, []);
};

export const convertHistoryIssueVal = (val: any) => {
  try {
    const parsed = JSON.parse(val);
    if (parsed.name != null) {
      return parsed.name;
    }
    return val;
  } catch (e) {
    return val;
  }
};

export const createIssueTagFilter = (tags: ITag[]): [{ [key: string]: string }, string[]] => {
  const tagsFormated = formatTags(tags);

  const filterTags = tagsFormated.reduce((acc, tag) => {
    acc[tag] = tag;
    return acc;
  }, {});

  return [filterTags, tagsFormated];
};
export const getIssueRegisterNameOption = (value: string, mode: string) => {
  return ISSUE_REGISTER_NAME_OPTIONS.find((option) => option.type === value)?.[mode];
};

export const getIssueActionNameOption = (value: string, mode: string): string => {
  return ISSUE_ACTION_NAME_OPTIONS.find((option) => option.singular === value)?.[mode];
};

export const formatExcelIssueData = (excel: string[][]): ITitleAndDescription[] => {
  try {
    const titleAndDescriptions: ITitleAndDescription[] = [];
    for (let i = 1; i < excel.length; i++) {
      if (excel[i][0] != null) {
        const row = {
          title: excel[i][0].toString(),
          description: excel[i][1].toString(),
        };
        titleAndDescriptions.push(row);
      }
    }
    return titleAndDescriptions;
  } catch (error) {
    console.error(error);
    alert("Feil i formatering av excel");
  }
};

export const convertValueAndColorToMultiDisplayColor = (arr: IValueAndColor[]): IMultiOptionsColors[] => {
  return arr.map((item) => {
    return {
      displayName: item.value,
      color: item.color,
      key: item.value,
    };
  });
};

interface IGetMatrixArgs {
  issue: IIssue;
  issueBoard: IBoard;
  evaluationCategoryCapital: ICapitalLetter;
}

export interface IMatrixOutput {
  matrixItem: IMatrixItem | undefined;
  score: number;
}

export const getMatrixItemAndScore = (args: IGetMatrixArgs): IMatrixOutput => {
  let score;
  try {
    if (
      args.issue.risiko?.probability == null ||
      (args.issue.risiko?.consequence && args.issue.risiko?.consequence[args.evaluationCategoryCapital] == null)
    ) {
      return {
        matrixItem: undefined,
        score: 0,
      };
    }
    const probability: IRisikoProbability = args.issueBoard.risikoSection.probabilites[args.issue.risiko.probability];
    const probabilityOrder = probability.order;
    const probabilityMatrixValue = probability.matrixValue;
    const consequences: IConsquenceRecord =
      args.issueBoard.risikoSection.evaluationCategoriesConfig[args.evaluationCategoryCapital].consequences;
    const consequence = consequences[args.issue.risiko.consequence[args.evaluationCategoryCapital]];
    const consequenceOrder = consequence.order;
    const consequenceMatrixValue = consequence.matrixValue;

    score = probabilityMatrixValue * consequenceMatrixValue;
    try {
      const matrix: IMatrixConfig =
        args.issueBoard.risikoSection.evaluationCategoriesConfig[args.evaluationCategoryCapital].matrixConfig;
      return {
        matrixItem: matrix[consequenceOrder][probabilityOrder],
        score,
      };
    } catch (error) {
      return {
        matrixItem: undefined,
        score,
      };
    }
  } catch (error) {
    console.error("failed to get matrix item", error);
    return undefined;
  }
};

export const getMatrixConfigFiltred = (
  issueBoard: IBoard,
  evaluationCategoryCapital: ICapitalLetter,
): IMatrixConfig => {
  const consequences: IConsequence[] = getConsequencesArr(issueBoard, evaluationCategoryCapital);
  const probabilites: IRisikoProbability[] = getProbabilityArr(issueBoard);
  const matrxConfig: IMatrixConfig =
    issueBoard.risikoSection?.evaluationCategoriesConfig?.[evaluationCategoryCapital].matrixConfig;

  const matrixFiltredByConsequences = matrxConfig.reduce((acc, matrixConsequence, matrixConsequenceIndex) => {
    const consequence = consequences[matrixConsequenceIndex];
    if (consequence.isActive) {
      acc.push(matrixConsequence);
    }
    return acc;
  }, [] as IMatrixConfig);

  return matrixFiltredByConsequences.map((matrixConsequence) => {
    return matrixConsequence.reduce((acc, matrixProbability, matrixProbabilityIndex) => {
      const probability = probabilites[matrixProbabilityIndex];
      if (probability.isActive) {
        acc.push(matrixProbability);
      }
      return acc;
    }, [] as IMatrixItem[]);
  });
};

export const getConsequencesArr = (issueBoard: IBoard, evaluationCategoryCapital: ICapitalLetter): IConsequence[] => {
  const consequences: IConsequence[] = Object.entries(
    issueBoard.risikoSection?.evaluationCategoriesConfig?.[evaluationCategoryCapital]?.consequences ?? {},
  )
    .map(([key, value]) => {
      const consequence: IConsequence = value;
      return {
        ...consequence,
        id: key,
      };
    })
    .sort((a, b) => a.order - b.order);

  return consequences;
};

export const getProbabilityArr = (issueBoard: IBoard): IRisikoProbability[] => {
  const probabilities: IRisikoProbability[] = Object.entries(issueBoard.risikoSection?.probabilites ?? {})
    .map(([key, value]) => {
      const probability: IRisikoProbability = value;
      return {
        ...probability,
        id: key,
      };
    })
    .sort((a, b) => a.order - b.order);

  return probabilities;
};

export function buildRisikoCountMatrix(
  issues: IIssue[],
  evaluationCategoriesConfig: IMapOfEvaluationCategories,
  probabilites: IMapOfRisikoProbabilities,
): IScoreMatrixConfig[] {
  const scoreMatrix: IRisikoCountMatrix = {};
  const matrixConfig: IRisikoCountMatrixConfig = {};

  // Create matrices with 0 values from object keys

  Object.entries(evaluationCategoriesConfig).forEach(([catKey, category]: [string, IEvaluationCategory]) => {
    if (category.isActive === false) {
      return;
    }

    scoreMatrix[catKey] = {};
    matrixConfig[catKey] = {
      boardName: category.name,
    };

    Object.entries(category.consequences).forEach(([conseqKey, consequence], consIdex) => {
      if (consequence.isActive === false) {
        return;
      }

      scoreMatrix[catKey][conseqKey] = {};
      matrixConfig[catKey][conseqKey] = {};

      Object.keys(probabilites).forEach((probKey, probIdex) => {
        scoreMatrix[catKey][conseqKey][probKey] = { score: 0, issues: [] };
        matrixConfig[catKey][conseqKey][probKey] = getColor(probIdex, consIdex, category.matrixConfig);
      });
    });
  });

  // Count occurrences for each issue
  for (const issue of issues) {
    if (issue.risiko == null) {
      continue;
    }

    const probabilityKey = issue.risiko.probability;

    Object.entries(issue.risiko.consequence ?? {}).forEach(([catKey, conseqKey]: [string, string]) => {
      if (
        scoreMatrix[catKey] &&
        scoreMatrix[catKey][conseqKey] &&
        scoreMatrix[catKey][conseqKey][probabilityKey] !== undefined
      ) {
        scoreMatrix[catKey][conseqKey][probabilityKey].score++;
        // This can result in duplicate issues in the array since different
        // matrices can have the same issue. Make sure to halde accordingly.
        scoreMatrix[catKey][conseqKey][probabilityKey].issues.push(issue);
      }
    });
  }

  // Combine matrixConfig and scoreMatrix with issues and make them into arrays
  // Since the object keys are not ordered this happens in two steps

  const matrices: IScoreMatrixConfig[] = [];

  Object.keys(scoreMatrix).forEach((category) => {
    const matrix: IMatrixCell[][] = [];

    Object.entries(scoreMatrix[category]).forEach(([conseqKey, probMatrix]) => {
      const row: IMatrixCell[] = [];

      Object.entries(probMatrix).forEach(([probKey, cellCount]) => {
        row.push({
          score: cellCount.score,
          issues: cellCount.issues,
          color: matrixConfig[category][conseqKey][probKey],
        });
      });

      matrix.push(row);
    });

    matrices.push({ name: matrixConfig[category].boardName, matrix });
  });

  return matrices;
}

// Deduplicate matrices with same shape and colors
export const deduplicateMatrices = (matrices: IScoreMatrixConfig[]): IScoreMatrixConfig[] => {
  const matrixMap = new Map<string, IScoreMatrixConfig>();

  matrices.forEach((matrix) => {
    const key = JSON.stringify(matrix.matrix.map((row) => row.map((cell) => cell.color)));
    const existingMatrix = matrixMap.get(key);

    if (existingMatrix != null) {
      const newMatrix = matrix.matrix.map((row, rowIndex) =>
        row.map((cell, cellIndex) => {
          return {
            score: cell.score + existingMatrix.matrix[rowIndex][cellIndex].score,
            color: cell.color,
            issues: [...cell.issues, ...existingMatrix.matrix[rowIndex][cellIndex].issues],
          };
        }),
      );

      matrixMap.set(key, { name: existingMatrix.name, matrix: newMatrix });
    } else {
      matrixMap.set(key, { ...matrix, name: `Akkumulering ${matrixMap.size + 1}` });
    }
  });

  return Array.from(matrixMap.values());
};

export const getScore = (consequence: IConsequence, probability: IRisikoProbability): number | undefined => {
  return (consequence?.matrixValue ?? 0) * (probability?.matrixValue ?? 0);
};

export const getColor = (probabilityIndex: number, consequenceIndex: number, matrixConfig: IMatrixConfig): string => {
  return matrixConfig?.[consequenceIndex]?.[probabilityIndex]?.color ?? "#ffffff00";
};
