import {
  FUNKSJONSKONTROLL_ISOLATOR_TESTER_METODER,
  FunksjonskontrollIsolatorTesterEnum,
} from "mc-shared/zod/report/GK/gkReportConstants";
import { IGKFunksjonskontrollIsolatorResultater, IGKRapport } from "mc-shared/zod/report/GK/gkReportSchema";
import React from "react";
import { Card, CardBody, CardTitle, FormGroup, Input, Label } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../../../types/global.types";
import { DropdownFilterGeneric, McInput } from "../../../../../../../components";

const FunksjonskontrollIsolator: React.FC<{
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
  data: IGKFunksjonskontrollIsolatorResultater;
}> = ({ data, onUpdateEditData }) => {
  const addOrRemoveTest = (test: FunksjonskontrollIsolatorTesterEnum) => {
    data.inndata.valgtMetode[test] = !data.inndata.valgtMetode[test];
    onUpdateEditData("testResultater.funksjonskontrollIsolator.inndata.valgtMetode", data.inndata.valgtMetode);
  };

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Funkjonskontroll av isolator</CardTitle>
        <b>Velg hvilke tester som skal utføres?</b>
        <div className="w-25 mb-4">
          <DropdownFilterGeneric
            filterOptions={FUNKSJONSKONTROLL_ISOLATOR_TESTER_METODER.map((test) => {
              return {
                ...test,
                isActive: data.inndata.valgtMetode[test.value],
              };
            })}
            onClick={(value) => addOrRemoveTest(value)}
            title="Velg tester"
            color="secondary"
            textColor="text-black"
          />
        </div>
        {data.inndata.valgtMetode[FunksjonskontrollIsolatorTesterEnum.LYSPAERERFRONTPANEL] === true && (
          <>
            <h4>Lyspærer, frontpanel</h4>
            <div>
              <FormGroup className="d-flex flex-column" check>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.lyspaererFrontpanelResultater.godkjent === true}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollIsolator.inndata.lyspaererFrontpanelResultater.godkjent",
                        true,
                      )
                    }
                  />
                  Godkjent
                </Label>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.lyspaererFrontpanelResultater.godkjent === false}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollIsolator.inndata.lyspaererFrontpanelResultater.godkjent",
                        false,
                      )
                    }
                  />
                  Ikke godkjent
                </Label>
              </FormGroup>
            </div>
            <div className="w-50 mt-2 mb-4">
              <b>Kommentar:</b>
              <McInput
                value={data.inndata.lyspaererFrontpanelResultater.kommentar}
                onChange={(e) =>
                  onUpdateEditData(
                    "testResultater.funksjonskontrollIsolator.inndata.lyspaererFrontpanelResultater.kommentar",
                    e.target.value,
                  )
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
          </>
        )}
        {data.inndata.valgtMetode[FunksjonskontrollIsolatorTesterEnum.LYSPAERERINTERLOCK] === true && (
          <>
            <h4>Lyspærer, interlock i slusene</h4>
            <div>
              <FormGroup className="d-flex flex-column" check>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.lyspaererInterlockResultater.godkjent === true}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollIsolator.inndata.lyspaererInterlockResultater.godkjent",
                        true,
                      )
                    }
                  />
                  Godkjent
                </Label>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.lyspaererInterlockResultater.godkjent === false}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollIsolator.inndata.lyspaererInterlockResultater.godkjent",
                        false,
                      )
                    }
                  />
                  Ikke godkjent
                </Label>
              </FormGroup>
            </div>
            <div className="w-50 mt-2 mb-4">
              <b>Kommentar:</b>
              <McInput
                value={data.inndata.lyspaererInterlockResultater.kommentar}
                onChange={(e) =>
                  onUpdateEditData(
                    "testResultater.funksjonskontrollIsolator.inndata.lyspaererInterlockResultater.kommentar",
                    e.target.value,
                  )
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
          </>
        )}
        {data.inndata.valgtMetode[FunksjonskontrollIsolatorTesterEnum.INTERNVIFTER] === true && (
          <>
            <h4>Internvifter, kontroll av spenning</h4>
            <div className="d-flex flex-column mb-2">
              <b>Fyll inn måleresultat &#40;V&#41;:</b>
              <McInput
                value={data.inndata.internvifterResultater.maaleresultat}
                onBlur={(e) =>
                  onUpdateEditData(
                    "testResultater.funksjonskontrollIsolator.inndata.internvifterResultater.maaleresultat",
                    Number(e.target.value),
                  )
                }
                normalizeNumbers={true}
                type="text"
                className="w-25"
              />
            </div>
            <div>
              <FormGroup className="d-flex flex-column" check>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.internvifterResultater.godkjent === true}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollIsolator.inndata.internvifterResultater.godkjent",
                        true,
                      )
                    }
                  />
                  Godkjent
                </Label>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.internvifterResultater.godkjent === false}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollIsolator.inndata.internvifterResultater.godkjent",
                        false,
                      )
                    }
                  />
                  Ikke godkjent
                </Label>
              </FormGroup>
            </div>
            <div className="w-50 mt-2 mb-4">
              <b>Kommentar:</b>
              <McInput
                value={data.inndata.internvifterResultater.kommentar}
                onChange={(e) =>
                  onUpdateEditData(
                    "testResultater.funksjonskontrollIsolator.inndata.internvifterResultater.kommentar",
                    e.target.value,
                  )
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
          </>
        )}
        {data.inndata.valgtMetode[FunksjonskontrollIsolatorTesterEnum.EKSTERNVIFTE] === true && (
          <>
            <h4>Ekternvifte, kontroll av spenning</h4>
            <div className="d-flex flex-column mb-2">
              <b>Fyll inn måleresultat &#40;V&#41;:</b>
              <McInput
                value={data.inndata.eksternvifteResultater.maaleresultat}
                onBlur={(e) =>
                  onUpdateEditData(
                    "testResultater.funksjonskontrollIsolator.inndata.eksternvifteResultater.maaleresultat",
                    Number(e.target.value),
                  )
                }
                normalizeNumbers={true}
                type="text"
                className="w-25"
              />
            </div>
            <div>
              <FormGroup className="d-flex flex-column" check>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.eksternvifteResultater.godkjent === true}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollIsolator.inndata.eksternvifteResultater.godkjent",
                        true,
                      )
                    }
                  />
                  Godkjent
                </Label>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.eksternvifteResultater.godkjent === false}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollIsolator.inndata.eksternvifteResultater.godkjent",
                        false,
                      )
                    }
                  />
                  Ikke godkjent
                </Label>
              </FormGroup>
            </div>
            <div className="w-50 mt-2 mb-4">
              <b>Kommentar:</b>
              <McInput
                value={data.inndata.eksternvifteResultater.kommentar}
                onChange={(e) =>
                  onUpdateEditData(
                    "testResultater.funksjonskontrollIsolator.inndata.eksternvifteResultater.kommentar",
                    e.target.value,
                  )
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
          </>
        )}
        {data.inndata.valgtMetode[FunksjonskontrollIsolatorTesterEnum.TILBAKESLAGSVENTIL] === true && (
          <>
            <h4>Tilbakeslagsventil</h4>
            <div>
              <FormGroup className="d-flex flex-column" check>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.tilbakeslagsventilResultater.godkjent === true}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollIsolator.inndata.tilbakeslagsventilResultater.godkjent",
                        true,
                      )
                    }
                  />
                  Godkjent
                </Label>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.tilbakeslagsventilResultater.godkjent === false}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollIsolator.inndata.tilbakeslagsventilResultater.godkjent",
                        false,
                      )
                    }
                  />
                  Ikke godkjent
                </Label>
              </FormGroup>
            </div>
            <div className="w-50 mt-2 mb-4">
              <b>Kommentar:</b>
              <McInput
                value={data.inndata.tilbakeslagsventilResultater.kommentar}
                onChange={(e) =>
                  onUpdateEditData(
                    "testResultater.funksjonskontrollIsolator.inndata.tilbakeslagsventilResultater.kommentar",
                    e.target.value,
                  )
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
          </>
        )}
        {data.inndata.valgtMetode[FunksjonskontrollIsolatorTesterEnum.ALARMFORINTERNTRYKK] === true && (
          <>
            <h4>Alarm for interntrykk</h4>
            <div>
              <FormGroup className="d-flex flex-column" check>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.alarmForInterntrykkResultater.godkjent === true}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollIsolator.inndata.alarmForInterntrykkResultater.godkjent",
                        true,
                      )
                    }
                  />
                  Godkjent
                </Label>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.alarmForInterntrykkResultater.godkjent === false}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollIsolator.inndata.alarmForInterntrykkResultater.godkjent",
                        false,
                      )
                    }
                  />
                  Ikke godkjent
                </Label>
              </FormGroup>
            </div>
            <div className="w-50 mt-2 mb-4">
              <b>Kommentar:</b>
              <McInput
                value={data.inndata.alarmForInterntrykkResultater.kommentar}
                onChange={(e) =>
                  onUpdateEditData(
                    "testResultater.funksjonskontrollIsolator.inndata.alarmForInterntrykkResultater.kommentar",
                    e.target.value,
                  )
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
          </>
        )}
        {data.inndata.valgtMetode[FunksjonskontrollIsolatorTesterEnum.ALARMFORLUFTVEKSLINGER] === true && (
          <>
            <h4>Alarm for luftvekslinger</h4>
            <div>
              <FormGroup className="d-flex flex-column" check>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.alarmForLuftvekslingerResultater.godkjent === true}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollIsolator.inndata.alarmForLuftvekslingerResultater.godkjent",
                        true,
                      )
                    }
                  />
                  Godkjent
                </Label>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.alarmForLuftvekslingerResultater.godkjent === false}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollIsolator.inndata.alarmForLuftvekslingerResultater.godkjent",
                        false,
                      )
                    }
                  />
                  Ikke godkjent
                </Label>
              </FormGroup>
            </div>
            <div className="w-50 mt-2 mb-4">
              <b>Kommentar:</b>
              <McInput
                value={data.inndata.alarmForLuftvekslingerResultater.kommentar}
                onChange={(e) =>
                  onUpdateEditData(
                    "testResultater.funksjonskontrollIsolator.inndata.alarmForLuftvekslingerResultater.kommentar",
                    e.target.value,
                  )
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
          </>
        )}
        {data.inndata.valgtMetode[FunksjonskontrollIsolatorTesterEnum.SKIFTEFORFILTERISLUSE] === true && (
          <>
            <h4>Skifte forfilter i sluse</h4>
            <div>
              <FormGroup className="d-flex flex-column" check>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.skifteForfilterISluseResultater.godkjent === true}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollIsolator.inndata.skifteForfilterISluseResultater.godkjent",
                        true,
                      )
                    }
                  />
                  Godkjent
                </Label>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.skifteForfilterISluseResultater.godkjent === false}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollIsolator.inndata.skifteForfilterISluseResultater.godkjent",
                        false,
                      )
                    }
                  />
                  Ikke godkjent
                </Label>
              </FormGroup>
            </div>
            {data.inndata.skifteForfilterISluseResultater.godkjent === false && (
              <div className="mt-2">
                <b>Er filteret skiftet? &#40;-&#41;:</b>
                <FormGroup className="d-flex flex-column" check>
                  <Label check>
                    <Input
                      type="radio"
                      checked={data.inndata.skifteForfilterISluseResultater.filterSkiftet === true}
                      onChange={() =>
                        onUpdateEditData(
                          "testResultater.funksjonskontrollIsolator.inndata.skifteForfilterISluseResultater.filterSkiftet",
                          true,
                        )
                      }
                    />
                    Filteret er skiftet
                  </Label>
                  <Label check>
                    <Input
                      type="radio"
                      checked={data.inndata.skifteForfilterISluseResultater.filterSkiftet === false}
                      onChange={() =>
                        onUpdateEditData(
                          "testResultater.funksjonskontrollIsolator.inndata.skifteForfilterISluseResultater.filterSkiftet",
                          false,
                        )
                      }
                    />
                    Filteret er IKKE skiftet
                  </Label>
                </FormGroup>
              </div>
            )}
            <div className="w-50 mt-2 mb-4">
              <b>Kommentar:</b>
              <McInput
                value={data.inndata.skifteForfilterISluseResultater.kommentar}
                onChange={(e) =>
                  onUpdateEditData(
                    "testResultater.funksjonskontrollIsolator.inndata.skifteForfilterISluseResultater.kommentar",
                    e.target.value,
                  )
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
          </>
        )}
        {data.inndata.valgtMetode[FunksjonskontrollIsolatorTesterEnum.SKIFTEPAKNINGERSLUSERUTVENDIG] === true && (
          <>
            <h4>Skifte pakninger i sluses utvendig</h4>
            <div>
              <FormGroup className="d-flex flex-column" check>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.skiftePakningerSluserUtvendigResultater.godkjent === true}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollIsolator.inndata.skiftePakningerSluserUtvendigResultater.godkjent",
                        true,
                      )
                    }
                  />
                  Godkjent
                </Label>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.skiftePakningerSluserUtvendigResultater.godkjent === false}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollIsolator.inndata.skiftePakningerSluserUtvendigResultater.godkjent",
                        false,
                      )
                    }
                  />
                  Ikke godkjent
                </Label>
              </FormGroup>
            </div>
            {data.inndata.skiftePakningerSluserUtvendigResultater.godkjent === false && (
              <div className="mt-2">
                <b>Er pakninger skiftet? &#40;-&#41;:</b>
                <FormGroup className="d-flex flex-column" check>
                  <Label check>
                    <Input
                      type="radio"
                      checked={data.inndata.skiftePakningerSluserUtvendigResultater.pakningerSkiftet === true}
                      onChange={() =>
                        onUpdateEditData(
                          "testResultater.funksjonskontrollIsolator.inndata.skiftePakningerSluserUtvendigResultater.pakningerSkiftet",
                          true,
                        )
                      }
                    />
                    Pakninger er skiftet
                  </Label>
                  <Label check>
                    <Input
                      type="radio"
                      checked={data.inndata.skiftePakningerSluserUtvendigResultater.pakningerSkiftet === false}
                      onChange={() =>
                        onUpdateEditData(
                          "testResultater.funksjonskontrollIsolator.inndata.skiftePakningerSluserUtvendigResultater.pakningerSkiftet",
                          false,
                        )
                      }
                    />
                    Pakninger er IKKE skiftet
                  </Label>
                </FormGroup>
              </div>
            )}
            <div className="w-50 mt-2 mb-4">
              <b>Kommentar:</b>
              <McInput
                value={data.inndata.skiftePakningerSluserUtvendigResultater.kommentar}
                onChange={(e) =>
                  onUpdateEditData(
                    "testResultater.funksjonskontrollIsolator.inndata.skiftePakningerSluserUtvendigResultater.kommentar",
                    e.target.value,
                  )
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
          </>
        )}
        {data.inndata.valgtMetode[FunksjonskontrollIsolatorTesterEnum.ETTERSTRAMMEUTSATTEKOMPONENTER] === true && (
          <>
            <h4>Etterstramme utsatte komponenter</h4>
            <div>
              <FormGroup className="d-flex flex-column" check>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.etterstrammeUtsatteKomponenterResultater.godkjent === true}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollIsolator.inndata.etterstrammeUtsatteKomponenterResultater.godkjent",
                        true,
                      )
                    }
                  />
                  Godkjent
                </Label>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.etterstrammeUtsatteKomponenterResultater.godkjent === false}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollIsolator.inndata.etterstrammeUtsatteKomponenterResultater.godkjent",
                        false,
                      )
                    }
                  />
                  Ikke godkjent
                </Label>
              </FormGroup>
            </div>
            <div className="w-50 mt-2 mb-4">
              <b>Kommentar:</b>
              <McInput
                value={data.inndata.etterstrammeUtsatteKomponenterResultater.kommentar}
                onChange={(e) =>
                  onUpdateEditData(
                    "testResultater.funksjonskontrollIsolator.inndata.etterstrammeUtsatteKomponenterResultater.kommentar",
                    e.target.value,
                  )
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
          </>
        )}
        {data.inndata.valgtMetode[FunksjonskontrollIsolatorTesterEnum.SMOERESYLINDER] === true && (
          <>
            <h4>Smøre sylinder/dørskinner</h4>
            <div>
              <FormGroup className="d-flex flex-column" check>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.smoereSylinderResultater.godkjent === true}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollIsolator.inndata.smoereSylinderResultater.godkjent",
                        true,
                      )
                    }
                  />
                  Godkjent
                </Label>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.smoereSylinderResultater.godkjent === false}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollIsolator.inndata.smoereSylinderResultater.godkjent",
                        false,
                      )
                    }
                  />
                  Ikke godkjent
                </Label>
              </FormGroup>
            </div>
            <div className="w-50 mt-2 mb-4">
              <b>Kommentar:</b>
              <McInput
                value={data.inndata.smoereSylinderResultater.kommentar}
                onChange={(e) =>
                  onUpdateEditData(
                    "testResultater.funksjonskontrollIsolator.inndata.smoereSylinderResultater.kommentar",
                    e.target.value,
                  )
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
          </>
        )}
        {data.inndata.valgtMetode[FunksjonskontrollIsolatorTesterEnum.PUSSEMAGNETER] === true && (
          <>
            <h4>Pusse opp magneter ved dårlig kontakt</h4>
            <div>
              <FormGroup className="d-flex flex-column" check>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.pusseMagneterResultater.godkjent === true}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollIsolator.inndata.pusseMagneterResultater.godkjent",
                        true,
                      )
                    }
                  />
                  Godkjent
                </Label>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.pusseMagneterResultater.godkjent === false}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollIsolator.inndata.pusseMagneterResultater.godkjent",
                        false,
                      )
                    }
                  />
                  Ikke godkjent
                </Label>
              </FormGroup>
            </div>
            {data.inndata.pusseMagneterResultater.godkjent === false && (
              <div className="mt-2">
                <b>Er magneter pusset? &#40;-&#41;:</b>
                <FormGroup className="d-flex flex-column" check>
                  <Label check>
                    <Input
                      type="radio"
                      checked={data.inndata.pusseMagneterResultater.magneterPusset === true}
                      onChange={() =>
                        onUpdateEditData(
                          "testResultater.funksjonskontrollIsolator.inndata.pusseMagneterResultater.magneterPusset",
                          true,
                        )
                      }
                    />
                    Magneter er pusset
                  </Label>
                  <Label check>
                    <Input
                      type="radio"
                      checked={data.inndata.pusseMagneterResultater.magneterPusset === false}
                      onChange={() =>
                        onUpdateEditData(
                          "testResultater.funksjonskontrollIsolator.inndata.pusseMagneterResultater.magneterPusset",
                          false,
                        )
                      }
                    />
                    Magneter er IKKE skiftet
                  </Label>
                </FormGroup>
              </div>
            )}
            <div className="w-50 mt-2 mb-4">
              <b>Kommentar:</b>
              <McInput
                value={data.inndata.pusseMagneterResultater.kommentar}
                onChange={(e) =>
                  onUpdateEditData(
                    "testResultater.funksjonskontrollIsolator.inndata.pusseMagneterResultater.kommentar",
                    e.target.value,
                  )
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
          </>
        )}
        {data.inndata.valgtMetode[FunksjonskontrollIsolatorTesterEnum.DEKONTAMINERINGSARRANGEMENT] === true && (
          <>
            <h4>Dekontamineringsarragement</h4>
            <div>
              <FormGroup className="d-flex flex-column" check>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.dekontamineringsarrangement.godkjent === true}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollIsolator.inndata.dekontamineringsarrangement.godkjent",
                        true,
                      )
                    }
                  />
                  Godkjent
                </Label>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.dekontamineringsarrangement.godkjent === false}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollIsolator.inndata.dekontamineringsarrangement.godkjent",
                        false,
                      )
                    }
                  />
                  Ikke godkjent
                </Label>
              </FormGroup>
            </div>
            <div className="w-50 mt-2 mb-4">
              <b>Kommentar:</b>
              <McInput
                value={data.inndata.dekontamineringsarrangement.kommentar}
                onChange={(e) =>
                  onUpdateEditData(
                    "testResultater.funksjonskontrollIsolator.inndata.dekontamineringsarrangement.kommentar",
                    e.target.value,
                  )
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default FunksjonskontrollIsolator;
