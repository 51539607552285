import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Badge, Button, ButtonGroup } from "reactstrap";
import { ZodIssue } from "zod";
import { AG_GRID_LOCALE_NOR } from "../../../agGridLocale";
import { Checkbox, HSpace, McInput, VSpace } from "../../../components";
import TemplateSelector, { ITemplate } from "../../../components/TemplateSelector/TemplateSelector";
import { ZodErrorModal } from "../../../components/ZodErrorModal/ZodErrorModal";
import { MCApplicationsUrlEnum } from "../../../frontendConstants";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { formatShortDate } from "../admin/selectors";
import { doCreateReportBoard } from "./reportBoardAPI";
import {
  REPORT_BOARD_STATUS_COLOR,
  REPORT_BOARD_STATUS_CONSTANTS,
  REPORT_BOARD_STATUSES,
  ReportBoardStatusEnums,
} from "./reportBoardConstants";
import {
  fetchAndSetReportsThunk,
  fetchAndSetReportTemplatesThunk,
  getFilteredReportBoards,
  resetReportApp,
  setActiveReportInRedux,
  setActiveReportVersionInRedux,
  setFilterReportsByLoggedInUserCheckedInRedux,
} from "./reportSlice";

const ReportApp: React.FC = () => {
  const [modalIsOpen, setModalOpen] = useState<boolean>(false);
  const [activeStatus, setActiveStatus] = useState<ReportBoardStatusEnums>(ReportBoardStatusEnums.ACTIVE);

  const [zodErros, setZodErrors] = useState<ZodIssue[]>(null);
  const [selectedTitle, setSelectedTitle] = useState<string>("");

  const project = useAppSelector((state) => state.adminReducer.project);
  const reportTemplates = useAppSelector((state) => state.reportReducer.reportTemplates);
  const filterReportsByLoggedInUserChecked = useAppSelector(
    (state) => state.reportReducer.filterReportsByLoggedInUserChecked,
  );
  const reportBoardsFiltred = useAppSelector(getFilteredReportBoards);

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    init();
  }, []);

  const statuses = REPORT_BOARD_STATUSES;

  const convertedReportTemplates: ITemplate[] = reportTemplates?.map((template) => {
    return {
      _id: template.templateId.toString(),
      name: template.name,
      templateType: "MC",
      description: template.description,
      admin: template.admin,
      category: null,
    };
  });

  const init = async () => {
    dispatch(resetReportApp());
    dispatch(setActiveReportInRedux(null));
    dispatch(setActiveReportVersionInRedux(null));
    dispatch(fetchAndSetReportsThunk());
    dispatch(fetchAndSetReportTemplatesThunk());
  };

  const defaultColDef = {
    minWidth: 120,
    resizable: true,
    suppressHeaderMenuButton: true,
    autoHeight: true,
    cellStyle: {
      whiteSpace: "normal",
      lineHeight: "1.5",
      wordBreak: "break-word",
    },
  };

  const columns: ColDef<any>[] = useMemo(() => {
    const cols: ColDef<any>[] = [
      {
        field: "status",
        width: 120,
        headerName: "Status",
        filter: true,
        cellRenderer: (params) => (
          <Badge style={{ padding: "8px 12px" }} pill className="d-flex greybadge">
            <div className="statusdot" style={{ backgroundColor: REPORT_BOARD_STATUS_COLOR[params.data.status] }}></div>
            <div style={{ color: "#58595B", fontSize: "12px" }}>
              {REPORT_BOARD_STATUS_CONSTANTS[params.data.status]?.toUpperCase()}
            </div>
          </Badge>
        ),
      },
      {
        field: "name",
        headerName: "Tittel",
        flex: 1,
        filter: "agTextColumnFilter",
        cellRenderer: (params) => (
          <a className="font-weight-bold pointer" onClick={() => goToReportBoard(params.data._id)}>
            {params.value.toUpperCase()}
          </a>
        ),
      },
      {
        field: "createdAt",
        width: 130,
        headerName: "Opprettet",
        initialSort: "desc",
        cellRenderer: (params) => formatShortDate(params.value),
        filter: "agDateColumnFilter",
      },
      {
        field: "templateName",
        headerName: "Mal brukt",
        filter: "agTextColumnFilter",
        cellRenderer: (params) => <div className="d-flex align-items-center truncateHeaderText">{params.value}</div>,
      },
      {
        field: "createdBy.name",
        headerName: "Ansvarlig",
        filter: "agTextColumnFilter",
      },
      {
        field: "zodErrors",
        headerName: "Feil",
        width: 120,
        cellRenderer: (params) => {
          return (
            <>
              {params.value != null && (
                <>
                  <HSpace />
                  <Button onClick={() => setZodErrors(params.value)} color="primary">
                    {params.value?.length}
                    <i className="fa fa-warning fa-fw" />
                  </Button>
                </>
              )}
            </>
          );
        },
      },
    ];
    return cols;
  }, []);

  const history = useHistory();

  const goToReportBoard = async (boardId: string) => {
    history.push(`/project/${project._id}/reports/${boardId}`);
  };

  const createNew = async (templateId: number) => {
    const createdReportBoard = await doCreateReportBoard(project._id, selectedTitle, templateId);
    setModalOpen(false);
    goToReportBoard(createdReportBoard._id);
  };

  const toggle = () => {
    setSelectedTitle("");
    setModalOpen(!modalIsOpen);
  };

  return (
    <div className="d-flex flex-column w-100 h-100 pt-5 gray-100">
      {zodErros != null && <ZodErrorModal errors={zodErros} onAbort={() => setZodErrors(null)} />}
      <div className="d-flex flex-column w-100 h-100">
        <div className="d-flex m-2">
          <Button color="success" onClick={() => setModalOpen(true)} className="mr-3">
            <div className="d-flex align-items-center">
              <i className="fa fa-plus" />
            </div>
          </Button>
          <Checkbox
            isChecked={filterReportsByLoggedInUserChecked === true}
            setChecked={() =>
              dispatch(setFilterReportsByLoggedInUserCheckedInRedux(!filterReportsByLoggedInUserChecked))
            }
            disabled={false}
            color="#f8992e"
            text="Vis kun rapporter opprettet av meg"
          />
        </div>
        <ButtonGroup className="w-100 ml-2">
          {statuses.map((status, index) => (
            <Button
              className="d-flex justify-content-center align-items-center"
              key={index}
              style={{ maxWidth: "200px" }}
              active={activeStatus === status.value}
              onClick={() => setActiveStatus(status.value as ReportBoardStatusEnums)}
            >
              <div className="pr-1">{status.displayValue}</div>
              <div
                style={{
                  display: "grid",
                  placeItems: "center",
                  borderRadius: "50%",
                  width: "1.25rem",
                  height: "1.2rem",
                  backgroundColor: "#999999d9",
                }}
              >
                {reportBoardsFiltred?.filter((board) => board.status === status.value).length}
              </div>
            </Button>
          ))}
        </ButtonGroup>
        <VSpace />
        <div className="checklistTable ag-theme-quartz h-100 w-100">
          <AgGridReact
            rowData={reportBoardsFiltred?.filter((board) => board.status === activeStatus)}
            columnDefs={columns}
            defaultColDef={defaultColDef}
            suppressClickEdit={true}
            rowSelection="single"
            animateRows={true}
            suppressContextMenu={true}
            localeText={AG_GRID_LOCALE_NOR}
          />
        </div>
      </div>
      {reportTemplates != null && modalIsOpen === true && (
        <TemplateSelector
          templates={convertedReportTemplates}
          header={"Ny rapport"}
          app={{
            name: MCApplicationsUrlEnum.REPORT,
            icon: "appIcons/reportGenerator.svg",
            iconColor: "#6791a1",
          }}
          onCreate={(template) => {
            createNew(parseFloat(template._id));
          }}
          closeModal={toggle}
          isTitleEmpty={selectedTitle === ""}
          isTemplateProject={false}
          descriptionAndAdmin={true}
          showMCButton={false}
          showProjectButton={false}
          showInstanceButton={false}
          preSelectedTemplate={convertedReportTemplates[0]}
          disableOpprettButton={selectedTitle === ""}
          excelImport={{
            enabled: false,
          }}
        >
          <div className="d-flex flex-column flex-grow-1 pl-10 pr-10 pt-3" style={{ background: "FBFBFB" }}>
            <h4 className="m-2 d-flex justify-content-center align-items-center bold">NY RAPPORT </h4>
            <div className="grid-container">
              <h5 className="d-flex align-items-center mb-0">Navn*</h5>
              <McInput
                required
                autoFocus
                onBlur={(t) => setSelectedTitle(t.target.value)}
                value={selectedTitle}
                placeholder={"Angi navnet på rapporten"}
              />
            </div>
          </div>
        </TemplateSelector>
      )}
    </div>
  );
};

export default ReportApp;
