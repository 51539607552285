import React, { useEffect } from "react";
import { Alert, Button } from "reactstrap";

const FailedAlert: React.FC<{ errorMessage?: string | object }> = ({ errorMessage }) => {
  const [showDetail, setShowDetail] = React.useState(false);

  const [parseError, setParseError] = React.useState<string | null>(null);

  useEffect(() => {
    try {
      if (typeof errorMessage === "string") {
        setParseError(errorMessage);
      } else {
        const _parsedError = JSON.stringify(errorMessage as unknown as string);
        setParseError(_parsedError);
      }
    } catch (err) {}
  }, []);

  return (
    <Alert color="danger">
      <div>
        Datainnhenting mislyktes. Personalet er varslet. Prøv igjen senere. Ta kontakt med mcapps@multiconsult.no for
        mer informasjon.
      </div>

      <Button onClick={() => setShowDetail(true)} color="link">
        Se teknisk feilmelding
      </Button>
      {showDetail && <pre>{parseError}</pre>}
    </Alert>
  );
};

export default FailedAlert;
