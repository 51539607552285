import { useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { setProjectDashboardActiveLayoutInRedux } from "../../app/routes/projectdashboard/projectDashboardSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { EllipsisButton } from "../CustomIcons/CustomIcons";

const WidgetWrapper: React.FC<{
  title?: string;
  widgetKey?: string;
  customTitle?: React.ReactNode;
  customButtons?: React.ReactNode;
  allowFullscreen?: boolean;
  onShowSettingsClick?: (arg) => void;
  onFullscreenClick?: (arg) => void;
}> = ({
  title,
  widgetKey,
  customTitle,
  customButtons,
  allowFullscreen,
  onShowSettingsClick,
  onFullscreenClick,
  children,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const editDashboardMode = useAppSelector((state) => state.projectDashboardReducer.editMode);
  const activeLayout = useAppSelector((state) => state.projectDashboardReducer.activeLayout);
  const dispatch = useAppDispatch();

  const handleRemoveWidget = () => {
    const updateLayoutGrid = activeLayout.layoutGrid.filter((obj) => {
      return obj.i !== widgetKey;
    });
    const layout = { ...activeLayout, layoutGrid: updateLayoutGrid };
    dispatch(setProjectDashboardActiveLayoutInRedux(layout));
  };
  return (
    <div className="w-100 h-100 d-flex flex-column p-2 position-relative">
      <div className="d-flex align-items-center">
        <h4>{title}</h4>
        {customTitle}
        <div className="flex-fill" />
        {customButtons}
        {editDashboardMode === true && (
          <Button
            style={{ zIndex: 6 }}
            outline
            className="mb-1 btn-sm"
            color="danger"
            onClick={() => handleRemoveWidget()}
          >
            <i className="fa fa-close fa-fw" />
          </Button>
        )}
        {onFullscreenClick != null && editDashboardMode === false && (
          <Button className="mr-1 mb-1" color="secondary" onClick={onFullscreenClick}>
            <i className=" fa fa-expand fa-lg"></i>
          </Button>
        )}
        {allowFullscreen === true && (
          <Button className="mr-1 mb-1" color="secondary" onClick={() => setModalOpen(!modalOpen)}>
            <i className=" fa fa-expand fa-lg"></i>
          </Button>
        )}
        {onShowSettingsClick != null && editDashboardMode === false && <EllipsisButton onClick={onShowSettingsClick} />}
      </div>
      {children}
      {allowFullscreen === true && (
        <Modal
          isOpen={modalOpen}
          toggle={() => {
            setModalOpen(!modalOpen);
          }}
          style={{ maxWidth: "90vw" }}
        >
          <ModalHeader
            toggle={() => {
              setModalOpen(!modalOpen);
            }}
          >
            {title}
          </ModalHeader>
          <ModalBody>
            <div style={{ height: "80vh", overflow: "auto" }}>{children}</div>
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

export default WidgetWrapper;
