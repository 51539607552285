import React from "react";
import { CellEditFormatter } from "../columnGlobal";
import { formatDate, formatShortDate, isDateValidFrontend } from "../../app/routes/admin/selectors";
import { isEmpty } from "../../app/utils";
import SingleTag from "../SingleTag/SingleTag";
import HSpace from "../HSpace";
import { IHistoryLog } from "mc-shared/zod/commonSchema";
import { AG_GRID_LOCALE_NOR } from "../../agGridLocale";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-enterprise";

const HistoryLogsTable: React.FC<{
  logData: IHistoryLog[];
}> = ({ logData }) => {
  const _columns: ColDef<any>[] = [
    {
      field: "columnDisplayName",
      headerName: "Kolonne",
      filter: "agSetColumnFilter",
      width: 120,
    },
    {
      field: "_id",
      hide: true,
    },
    {
      field: "newValue",
      headerName: "Ny verdi",
      flex: 1,
      filter: "agTextColumnFilter",
      cellRenderer: (params) => CellEditFormatter(params.value),
    },
    {
      field: "oldValue",
      headerName: "Forrige verdi",
      flex: 1,
      filter: "agTextColumnFilter",
      cellRenderer: (params) => CellEditFormatter(params.value),
    },
    {
      field: "editedBy",
      headerName: "Redigert av",
      filter: "agTextColumnFilter",
    },
    {
      field: "updatedAt",
      headerName: "Endret dato",
      initialSort: "desc",
      cellRenderer: (params) => formatShortDate(params.value),
      width: 132,
    },
  ];

  return (
    <div className="ag-theme-quartz h-100 w-100">
      <AgGridReact
        domLayout={"autoHeight"}
        rowData={logData || []}
        defaultColDef={{
          suppressHeaderMenuButton: true,
          autoHeight: true,
          cellClass: "",
          minWidth: 120,
          maxWidth: 400,
          cellStyle: {
            overflow: "hidden",
            whiteSpace: "normal",
            lineHeight: "1.5",
            wordBreak: "break-word",
          },
        }}
        rowHeight={30}
        headerHeight={35}
        columnDefs={_columns}
        suppressClickEdit={true}
        animateRows={false}
        suppressContextMenu={true}
        localeText={AG_GRID_LOCALE_NOR}
      />
    </div>
  );
};

export default HistoryLogsTable;

const formatValue = (val: any) => {
  if (isEmpty(val)) {
    return "";
  }
  if (isDateValidFrontend(val)) {
    return formatDate(val);
  }
  try {
    if (val.startsWith("[")) {
      const valParsed = JSON.parse(val);
      if (valParsed.constructor === Array) {
        return (
          <div className="d-flex flex-wrap justify-content-start">
            {valParsed.map((i) => (
              <>
                <SingleTag displayValue={i} key={i} />
                <HSpace />
              </>
            ))}
          </div>
        );
      }
    }
  } catch (error) {
    console.warn(error);
  }
};
