import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import React from "react";
import { Button, Modal, ModalHeader } from "reactstrap";
import { IFile } from "../../../types/global.types";
import { AG_GRID_LOCALE_NOR } from "../agGridLocale";
import { formatShortDate } from "../app/routes/admin/selectors";
import { Checkbox, HSpace } from "./";

const FileTableDepricated: React.FC<{
  files: IFile[];
  downloadFile: (fileId: string, fileName: string) => void;
  deleteFile: (fileId: string) => void;
  showDocxImageTag?: boolean;
  folderPath?: string;
  allowToggleItems?: boolean;
  onToggleItem?: (fileId: string) => void;
}> = ({
  files,
  downloadFile,
  deleteFile,
  showDocxImageTag = false,
  folderPath,
  allowToggleItems = false,
  onToggleItem,
}) => {
  const [openImageModal, setOpenImageModal] = React.useState(false);
  const [activeImg, setActiveImg] = React.useState("");

  const onClickImg = (filename: string) => {
    setActiveImg(filename);
    setOpenImageModal(true);
  };

  const columns: ColDef<IFile>[] = [
    {
      headerName: "ID",
      field: "_id",
      hide: true,
    },
    {
      headerName: "",
      suppressHeaderMenuButton: true,
      sortable: false,
      width: 110,
      cellRenderer: (params) => {
        return (
          <div className="d-flex">
            <Button
              onClick={() => downloadFile(params.data._id, params.data.filename)}
              color="primary"
              outline
              size="sm"
            >
              <i className="fa fa-download fa-fw" />
            </Button>
            <HSpace />
            <Button onClick={() => deleteFile(params.data._id)} color="danger" outline size="sm">
              <i className="fa fa-trash fa-fw" />
            </Button>
          </div>
        );
      },
    },
    {
      headerName: "Filnavn",
      field: "filename",
      cellRenderer: (params) => {
        return <div className="ag-cell-text-truncate">{params.value}</div>;
      },
      flex: 1,
    },
    {
      headerName: "Tagg for docxtemplater",
      field: "docxImageTag",
      hide: showDocxImageTag === false,
      cellRenderer: (params) => (params.value != null ? `{%${params.value}}` : ""),
    },
    {
      headerName: "Opprettet",
      field: "createdDate",
      initialSort: "desc",
      cellRenderer: (params) => formatShortDate(params.value),
      width: 120,
    },
    {
      headerName: "Opplastet av",
      field: "createdBy.name",
    },
    {
      headerName: "",
      sortable: false,
      cellClass: "d-flex justify-content-center",
      suppressHeaderMenuButton: true,
      cellRenderer: (params) => {
        return /\.(gif|jpe?g|png|webp|bmp)$/i.test(params.data.filename) === true ? (
          <div className="d-flex">
            <img
              className="pointer"
              src={`${folderPath}/${params.data.filename}`}
              alt=""
              width="100px"
              onClick={() => onClickImg(params.data.filename)}
            />
          </div>
        ) : (
          <></>
        );
      },
    },
    {
      headerName: "",
      hide: allowToggleItems !== true,
      suppressHeaderMenuButton: true,
      cellRenderer: (params) => {
        return (
          <Checkbox
            isChecked={!!params}
            setChecked={() => {
              onToggleItem(params.data._id);
            }}
            disabled={false}
          />
        );
      },
    },
  ];

  return (
    <div className="ag-theme-quartz h-100 w-100">
      <AgGridReact
        domLayout={"autoHeight"}
        rowData={files}
        defaultColDef={{
          suppressHeaderMenuButton: true,
        }}
        rowHeight={70}
        columnDefs={columns}
        suppressClickEdit={true}
        rowSelection="single"
        animateRows={true}
        suppressContextMenu={true}
        onGridReady={(e) => {
          e.api.autoSizeColumns(["createdBy.name"]);
        }}
        localeText={AG_GRID_LOCALE_NOR}
      />
      {openImageModal === true && (
        <Modal
          isOpen={openImageModal}
          toggle={() => setOpenImageModal(false)}
          style={{ maxWidth: "1000px", width: "50%" }}
        >
          <ModalHeader>{activeImg}</ModalHeader>
          <div>
            <img src={`${folderPath}/${activeImg}`} alt="" width="100%" />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default FileTableDepricated;
