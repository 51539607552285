import React from "react";
import { Typeahead, TypeaheadComponentProps } from "react-bootstrap-typeahead";
import "./McTypeahead.css";

export const McTypeahead = <T,>({
  style,
  ...props
}: Omit<TypeaheadComponentProps, "defaultSelected" | "onChange" | "options" | "selected"> & {
  style?: React.CSSProperties;
  defaultSelected?: T[];
  onChange?: (selected: T[]) => void;
  options: T[];
  selected?: T[];
}): JSX.Element => {
  const handleEsc = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Escape") {
      e.stopPropagation();
    }
  };

  return (
    <div onKeyUp={handleEsc} style={style}>
      <Typeahead {...props} inputProps={{ className: "mcinput" }} />
    </div>
  );
};

export default McTypeahead;
