const ConnectorDoc = () => {
  return (
    <>
      <h3 className={"mb-2"}>Oprette connector til MCBIM-database</h3>
      <h4 className="mb-3 mt-5">
        <div className="textCircle">1</div> Laste ned og installere connector
      </h4>
      <div style={{ marginLeft: "3em" }}>
        <p>
          Last ned MongoDB BI Connector herfra:
          <br />
          <a href="https://github.com/mongodb/mongo-bi-connector-odbc-driver/releases/">
            https://github.com/mongodb/mongo-bi-connector-odbc-driver/releases/
          </a>
        </p>
        <p>
          Velg siste release (msi-fil) for windows 64-bit.
          <br />
          F.eks. denne:{" "}
          <a href="https://github.com/mongodb/mongo-bi-connector-odbc-driver/releases/download/v1.4.3/mongodb-connector-odbc-1.4.3-win-64-bit.msi">
            https://github.com/mongodb/mongo-bi-connector-odbc-driver/releases/download/v1.4.3/mongodb-connector-odbc-1.4.3-win-64-bit.msi
          </a>
        </p>
        <p>
          Installer filen på vanlig måte med standard innstillinger. Klikk <b>Next</b> og <b>Finish</b>, når den er
          klar.
        </p>
      </div>
      <h4 className="mb-3 mt-5">
        {" "}
        <div className="textCircle">2</div> Opprette kopling til datakilde
      </h4>
      <div style={{ marginLeft: "3em" }}>
        <p>
          Gå i Windows søkefeltet, skriv <b>odbc data sources</b>, start denne Appen.
        </p>
        <img
          src="images/PowerBI/image001.png"
          alt="PowerBI-1"
          className="mb-3"
          style={{ width: "400px", boxShadow: "9px 10px 15px -8px #363a5175" }}
        />
        <p>
          Velg fanen <b>System DNS</b> klikk <b>Add…</b>. Velg <b>MongoDB ODBC &lt;versjon&gt; Unicode Driver</b>. Klikk{" "}
          <b>Finish</b>.
        </p>
        <img
          src="images/PowerBI/image002.png"
          alt="PowerBI-2"
          className="mb-3"
          style={{ width: "400px", boxShadow: "9px 10px 15px -10px #363a5155" }}
        />
        <p>Fyll inn:</p>
        <ul>
          <li>
            <b>Data Source Name</b> = MongoDB Unicode &lt;Oppdragsnr&gt;
          </li>
          <li>
            <b>TCP/IP Server</b> = mcbim-biconnector.fycix.mongodb.net Port = 27015
          </li>
          <li>
            <b>User</b> = &lt;Oppdragsnummer&gt;?source=admin
          </li>
          <li>
            <b>Password</b> = <i>det du får tildelt av admin</i>
          </li>
          <li>
            <b>Database</b> = mcbim
          </li>
        </ul>
        <img
          src="images/PowerBI/image003.png"
          alt="PowerBI-3"
          className="mb-3"
          style={{ width: "400px", boxShadow: "9px 10px 15px -10px #363a5155" }}
        />

        <p>
          Avslutt med <b>OK</b>. Klikk også <b>OK</b> på skjermbildet <b>ODBC Data Source Administartor</b>, for å lagre
          og lukke.
        </p>
      </div>
    </>
  );
};

export default ConnectorDoc;
