import { ColDef } from "ag-grid-community";
import React from "react";
import { useHistory } from "react-router-dom";
import { IChecklist } from "../../../../../types/checklist.types";
import MainFilterBar from "../../../components/MainFilterBar/MainFilterBar";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useGetChecklistFiltersQuery } from "./checklistRTK";
import {
  clearAllChecklistFilterThunk,
  selectChecklistMultiFilters,
  setChecklistFreeTextSearchInRedux,
  toggleAllMultiChecklistFiltersInRedux,
  toggleChecklistBoardBoolFilterThunk,
  toggleOptionInChecklistFiltersInRedux,
} from "./checklistSlice";

const ChecklistDbFilterBar: React.FC<{ columns: ColDef<IChecklist>[] }> = ({ columns }) => {
  const project = useAppSelector((state) => state.adminReducer.project);
  const dispatch = useAppDispatch();

  useGetChecklistFiltersQuery({ projectId: project._id });

  const multipleFilterItems = useAppSelector((state) => selectChecklistMultiFilters(state as any, columns));

  const getDataField = (colTitle: string): string => {
    return columns.find((col) => col.headerName === colTitle)?.field.split(".")[0];
  };

  const history = useHistory();

  const reset = () => {
    history.push(`${history.location.pathname}`);
    dispatch(clearAllChecklistFilterThunk());
  };

  return (
    <>
      {multipleFilterItems != null && (
        <div className="border pt-2 px-2 d-flex w-100 mc-gray-200">
          <MainFilterBar
            freeTextSearchEnabled
            onUpdateSearch={(text) => dispatch(setChecklistFreeTextSearchInRedux(text))}
            multipleFilterItems={multipleFilterItems}
            updateMultiFilterItemOption={(title, value) =>
              dispatch(
                toggleOptionInChecklistFiltersInRedux({
                  dataField: getDataField(title),
                  optionValue: value,
                }),
              )
            }
            updateBooleanFilterItem={(title) =>
              dispatch(toggleChecklistBoardBoolFilterThunk(columns.find((col) => col.headerName === title)?.field))
            }
            clearAllFilters={reset}
            resetMultiFilter={(title) =>
              dispatch(
                toggleAllMultiChecklistFiltersInRedux({
                  dataField: getDataField(title),
                  isActive: false,
                }),
              )
            }
            selectAllMultiFilter={(title) =>
              dispatch(
                toggleAllMultiChecklistFiltersInRedux({
                  dataField: getDataField(title),
                  isActive: true,
                }),
              )
            }
          />
        </div>
      )}
    </>
  );
};

export default ChecklistDbFilterBar;
