import * as R from "ramda";
import React from "react";
import { Card, CardBody } from "reactstrap";
import { IIssueItemStat } from "../../../../../../../types/global.types";
import { HSpace, MCLoader, VSpace } from "../../../../../components";
import TimeSum from "../../../../../components/TimeSum/TimeSum";
import { IStatbox } from "../../../../../types";
import { StatsBox } from "../../../risiko/Dashboard/StatsBox";
import { useGetIssueStatsQuery } from "../../issueRTK";
import IssueMiniTable from "./IssueMiniTable";
import { StatusCircleChart } from "./StatusCircleChart";
import TrendChart from "./TrendChart/TrendChart";
import { useAppSelector } from "../../../../../hooks";

const IssueStandardDashboard: React.FC<{ projectId: string; issueBoardId: string }> = ({ projectId, issueBoardId }) => {
  const allFilters = useAppSelector((state) => state.issueReducerNew.allFilters);
  const freeTextSearch = useAppSelector((state) => state.issueReducerNew.freeTextSearch);
  const selectedPnsFilterItem = useAppSelector((state) => state.issueReducerNew.selectedPnsFilterItem);

  const { data: issueItemStat, isLoading } = useGetIssueStatsQuery({
    projectId: projectId,
    issueBoardId: issueBoardId,
    allFilters: allFilters,
    freeTextSearch: freeTextSearch,
    pns: selectedPnsFilterItem,
  });

  return (
    <div className="h-100 w-100" style={{ position: "relative", overflowX: "auto" }}>
      {isLoading && <MCLoader loading />}
      {issueItemStat != null && <StatsCard issueItemStat={issueItemStat.issueStats} />}
    </div>
  );
};

export default IssueStandardDashboard;

const StatsCard: React.FC<{ issueItemStat: IIssueItemStat }> = ({ issueItemStat }) => {
  const boxes: IStatbox[] = [
    {
      icon: "fa-book",
      value: issueItemStat.count,
      text: `Registrert`,
      classColor: "text-primary",
    },
    {
      icon: "fa-folder-open",
      value: issueItemStat.isOpen,
      text: `Åpne`,
      classColor: "text-primary",
    },
    {
      icon: "fa-check",
      value: issueItemStat.completedLast14Days,
      text: `Lukket siste 14 dager`,
      classColor: "text-success",
    },
    {
      icon: "fa-calendar",
      value: issueItemStat.dueNext14Days,
      text: `Tidsfrist neste 14 dager`,
      classColor: "text-danger",
    },
  ];

  return (
    <Card className="d-flex m-2 gray-200">
      <CardBody>
        <div className="d-flex w-100">
          {boxes.map((stat, index) => (
            <React.Fragment key={index}>
              {index !== 0 && <HSpace />}
              <StatsBox stat={stat} />
            </React.Fragment>
          ))}
        </div>
        <VSpace />
        <div className="d-flex w-100 p-0 m-0">
          <TimeSum sumHours={issueItemStat.hours} />
          <HSpace />
          <Card className="w-100">
            <CardBody>
              <StatusCircleChart circleChartDataItems={R.clone(issueItemStat.statusChartData)} />
            </CardBody>
          </Card>
        </div>
        {issueItemStat.trend != null && (
          <>
            <VSpace />
            <Card className="w-100 mt-2">
              <CardBody>
                <TrendChart issueTrend={R.clone(issueItemStat.trend)} />
              </CardBody>
            </Card>
          </>
        )}
        <div className="w-100 mt-2">
          <IssueMiniTable issues={R.clone(issueItemStat.last10Created)} />
        </div>
      </CardBody>
    </Card>
  );
};
