import React from "react";
import { ILevelItem } from "../../app/routes/orgChart/types";
import { DropdownSelectorDeprecated } from "../index";

const NumberOfLevelItemsSelector: React.FC<{
  arrWithLevels: number[];
  setActiveLevel: (count: number) => void;
  activeLevel: number;
}> = ({ arrWithLevels, setActiveLevel, activeLevel }) => {
  const [levelItems, setLevelItems] = React.useState<ILevelItem[]>();

  React.useEffect(() => {
    init();
  }, [arrWithLevels?.length]);

  const init = () => {
    const _levelItems = arrWithLevels.map((item) => {
      return {
        id: item,
        displayValue: String(item),
      };
    });
    setLevelItems(_levelItems);
  };

  return (
    <>
      {levelItems != null && (
        <DropdownSelectorDeprecated
          icon="filter"
          selectText={`Vise nivåer ${activeLevel == null ? "" : `(${activeLevel})`}`}
          widthInPx={150}
          color="secondary"
          activeItem={levelItems.find((item) => item.id === activeLevel)}
          setItem={(item) => setActiveLevel(item?.id)}
          items={levelItems}
          displayKey="displayValue"
        />
      )}
      {/* <Button>
        <i className="fa fa-download fa-fw" />
      </Button>
      <Button active>
        <i className="fa fa-eye fa-fw" />
      </Button>*/}
    </>
  );
};

export default NumberOfLevelItemsSelector;
