import React, { useEffect, useState } from "react";
import { Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";
import { IRiskoBoard } from "../../../../../../types/global.types";
import { TitleWithValue } from "../../../../components/";
import { ToastMessagesEnums } from "../../../../frontendConstants";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { doUpdateRisikBoardName } from "../risikoAPI";
import { getRisikoColumns } from "../RisikoColumns";
import DisabledColumns from "./DisabledColumns";
import { setShowSuccessMessageThunk } from "../../../../global/globalSlice";

const RisikoBoardSettings: React.FC<{ risikoBoard: IRiskoBoard; updateBoards: any }> = ({
  risikoBoard,
  updateBoards,
}) => {
  const [allColumns, setAllColumns] = useState(null);

  const dispatch = useAppDispatch();
  const project = useAppSelector((state) => state.adminReducer.project);

  useEffect(() => {
    const _allColumns = getRisikoColumns(null, [], null, null, null, null, [], [], null);
    setAllColumns(_allColumns);
  }, []);

  const save = async (prevName, newName) => {
    await doUpdateRisikBoardName(project._id, risikoBoard._id, newName);
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.UPDATED));
    updateBoards();
  };

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h3">Innstillinger</CardTitle>
        <CardSubtitle tag="h6" className="mb-2 text-muted" />
        <TitleWithValue editable title="Navn" value={risikoBoard.name} saveCb={save} />
        {allColumns != null && (
          <DisabledColumns
            updateBoards={updateBoards}
            allColumns={allColumns}
            projectId={project._id}
            risikoBoard={risikoBoard}
            disabledColumns={risikoBoard.disabledColumns}
          />
        )}
      </CardBody>
    </Card>
  );
};

export default RisikoBoardSettings;
