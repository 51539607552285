import { IAllFilter } from "mc-shared/zod/commonSchema";
import { ISortColumn, ITableTemplate, ITableTemplateFilter } from "mc-shared/zod/issueSchema";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useSelector } from "react-redux";
import { Button, Card, CardBody, CardTitle, ListGroup, ListGroupItem } from "reactstrap";
import { ISSUE_COLUMN_LABELS } from "../../app/routes/issuesApp/issueConstants";
import { selectSingleIssueBoard } from "../../app/routes/issuesApp/issueSlice";
import SimpleTag from "../SimpleTag";
import { COL_WIDTHS, createHeaderStyle, headerSortAndFilterFormatter, sortCaret } from "../columnGlobal";
import { FoldableSection, VSpace } from "../index";

const TableTemplateViewMode: React.FC<{
  tableTemplate: ITableTemplate;
  allFilters: IAllFilter[];
  onEdit: (bool: boolean) => void;
}> = ({ tableTemplate, allFilters, onEdit }) => {
  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">{tableTemplate.name}</CardTitle>
        <ViewFilter filters={tableTemplate.filters} allFilters={allFilters} />
        <ViewColumns selectedColumns={tableTemplate.tableColumns} />
        {tableTemplate.sortedCol != null && <SortColumns sortColumn={tableTemplate.sortedCol} />}
        <VSpace />
        <Button color="primary" onClick={() => onEdit(true)}>
          <i className="fa fa-pencil fa-fw" />
          Rediger
        </Button>
      </CardBody>
    </Card>
  );
};

export default TableTemplateViewMode;

const ViewFilter: React.FC<{
  filters: ITableTemplateFilter[];
  allFilters: IAllFilter[];
}> = ({ filters, allFilters }) => {
  const miniColumns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "dataField",
      text: "Kolonnn",
      formatter: (cell, row) => <span>{ISSUE_COLUMN_LABELS[cell]}</span>,
      headerStyle: createHeaderStyle(COL_WIDTHS.ID),
      headerFormatter: headerSortAndFilterFormatter,
      sortCaret: sortCaret,
    },
    {
      dataField: "multiFilterValues",
      text: "Verdier",
      formatter: (filterValues, row) => {
        const dataFieldFilter = allFilters.find((item) => item.dataField === row.dataField);
        if (dataFieldFilter == null) {
          console.warn(`missing field filter ${row.dataField}`);
          return "error";
        }
        if (filterValues != null && typeof filterValues !== "boolean" && filterValues?.length > 0) {
          const values = filterValues.reduce((acc, multiFilterValue) => {
            const allFiltersOption = dataFieldFilter.options.find((option) => option.value === multiFilterValue);
            if (allFiltersOption != null) {
              acc.push(allFiltersOption);
            }
            return acc;
          }, []);
          return (
            <div className="d-flex">
              {values.map((valueLabel) => (
                <SimpleTag key={valueLabel.label} displayValue={valueLabel.label} />
              ))}
            </div>
          );
        }
        return <i className="fa fa-check" />;
      },
      headerStyle: createHeaderStyle(COL_WIDTHS.TAGS),
      headerFormatter: headerSortAndFilterFormatter,
      sortCaret: sortCaret,
    },
  ];

  return (
    <FoldableSection folded>
      <FoldableSection.Header>
        <VSpace />
        <h5>
          <i className="fa fa-filter fa-fw" />
          Filter
        </h5>
      </FoldableSection.Header>
      <FoldableSection.Body>
        <div className="d-flex flex-column">
          <BootstrapTable
            bootstrap4
            keyField="_id"
            classes="table-sm table-responsive"
            wrapperClasses="smalltable"
            columns={miniColumns}
            data={filters.filter(
              (filterItem) => filterItem.multiFilterValues?.length > 0 || filterItem.isActive === true,
            )}
          />
        </div>
      </FoldableSection.Body>
    </FoldableSection>
  );
};

interface IDataFieldText {
  dataField: string;
  text: string;
}

const ViewColumns: React.FC<{ selectedColumns: string[] }> = ({ selectedColumns }) => {
  const issueBoard = useSelector(selectSingleIssueBoard);

  const otherAttributesConfig = issueBoard?.otherAttributesConfig;

  const tableColumns: IDataFieldText[] = selectedColumns.map((col) => {
    if (col.startsWith("otherAttributes")) {
      const colSplit = col.split(".");
      const otherAttributesKey = colSplit[colSplit.length - 1];
      return {
        dataField: col,
        text: otherAttributesConfig[otherAttributesKey]?.label,
      };
    }
    return {
      dataField: col,
      text: ISSUE_COLUMN_LABELS[col],
    };
  });

  return (
    <FoldableSection folded>
      <FoldableSection.Header>
        <VSpace />
        <h5>
          <i className="fa fa-columns fa-fw" />
          Tabellkolonner
        </h5>
      </FoldableSection.Header>
      <FoldableSection.Body>
        <ListGroup>
          {selectedColumns.map((scol) => {
            const col = tableColumns.find((col) => col.dataField === scol);
            return (
              <ListGroupItem key={scol}>
                <span
                  style={{
                    fontSize: "0.875rem",
                    lineHeight: "1.425",
                  }}
                >
                  {col?.text ?? "Ukjent kolonne"}
                </span>
              </ListGroupItem>
            );
          })}
        </ListGroup>
      </FoldableSection.Body>
    </FoldableSection>
  );
};

const SortColumns: React.FC<{ sortColumn: ISortColumn }> = ({ sortColumn }) => {
  return (
    <FoldableSection folded>
      <FoldableSection.Header>
        <VSpace />
        <h5>
          <i className="fa fa-sort fa-fw" />
          Sortering
        </h5>
      </FoldableSection.Header>
      <FoldableSection.Body>
        <ListGroup>
          <ListGroupItem>
            <span
              style={{
                fontSize: "0.875rem",
                lineHeight: "1.425",
              }}
            >
              {ISSUE_COLUMN_LABELS[sortColumn?.column]}
            </span>
          </ListGroupItem>
        </ListGroup>
      </FoldableSection.Body>
    </FoldableSection>
  );
};
