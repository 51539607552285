import * as _ from "lodash";
import React, { useState } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { AddButton, CloseButton } from "..";
import { FlatHierarchy, IPNSProps } from "../../../../types/global.types";
import SingleTag from "../SingleTag/SingleTag";
import PNSTypeAhead from "./PNSTypeAhead";

const PNSCodes: React.FC<{
  pnsCodes: any;
  pnsItems: FlatHierarchy<IPNSProps>;
  itemId: string;
  updateCb: (itemId: string, pnsCodes: string[]) => Promise<void>;
}> = ({ pnsCodes, itemId, updateCb, pnsItems }) => {
  const [addMode, setAddMode] = useState(false);
  const [addButtonHidden, setAddButtonHidden] = useState(true);

  const addPnsCode = async (_pnsCodeToAdd: string) => {
    if (_.isEmpty(_pnsCodeToAdd)) {
      return;
    }
    const uniqPnsCodes = _.uniq([_pnsCodeToAdd, ...pnsCodes]);
    setAddMode(false);
    await updateCb(itemId, uniqPnsCodes);
  };

  const deletePnsCode = async (pnsCodeToDelete: string) => {
    let pnsCodeToUpdate = pnsCodes.filter((pnsCode) => pnsCode !== pnsCodeToDelete);
    await updateCb(itemId, pnsCodeToUpdate);
  };

  const setVal = (str) => {
    if (str != null) {
      addPnsCode(str);
    }
  };

  return (
    <div
      className="d-flex align-items-center"
      onMouseEnter={() => setAddButtonHidden(false)}
      onMouseLeave={() => setAddButtonHidden(true)}
    >
      {addMode === false && (
        <div className={`${addButtonHidden ? "fadein" : "fadeout"}`}>
          <AddButton onClick={() => setAddMode(true)} />
        </div>
      )}
      <div>
        {pnsCodes.sort().map((pnsCode, index) => {
          const pnsItem = pnsItems.find((item) => item.props.pnsId === pnsCode);
          let displayValue;
          if (pnsItem != null) {
            displayValue = `${pnsItem.props.pnsId} - ${pnsItem.props.title}`;
          } else {
            displayValue = pnsCode;
          }

          return <SingleTag key={index} displayValue={displayValue} deleteTag={deletePnsCode} />;
        })}
        {addMode && (
          <div className="d-flex">
            <PNSTypeAhead pnsItems={pnsItems} setValue={(e) => setVal(e)} saveCb={addPnsCode} />
            <div className="mr-1" />
            <CloseButton onClick={() => setAddMode(false)} />
          </div>
        )}
      </div>
    </div>
  );
};

export default PNSCodes;
