import { useState } from "react";
import { Button } from "reactstrap";
import { McInput } from "../../../components";
import { useAppDispatch } from "../../../hooks";
import { doCreateProject } from "../admin/restQueries";
import { setShowSuccessMessageThunk } from "../../../global/globalSlice";

export const AddProjectToMcApps = () => {
  const dispatch = useAppDispatch();
  const [projectNo, setProjectNo] = useState<number>();
  const [projectName, setProjectName] = useState<string>("");

  const save = async () => {
    const thisProject = await doCreateProject({ projectNo: projectNo, name: projectName });
    if (thisProject != null) {
      dispatch(setShowSuccessMessageThunk("Project added!"));
    }
  };

  return (
    <>
      <div>
        <div>Project number</div>
        <McInput
          style={{ width: "100%" }}
          type="number"
          value={projectNo}
          onChange={(e) => setProjectNo(Number(e.target.value))}
          name="text"
        />
        <div>Project name</div>
        <McInput
          style={{ width: "100%" }}
          type="text"
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
          name="text"
        />
        <div className="d-flex align-items-center mt-2">
          <Button disabled={projectNo === null || projectName === ""} color="success" onClick={save}>
            Save
          </Button>
        </div>
      </div>
    </>
  );
};
