import { z } from "zod";

export const ZInternJuridiskUnderkonsulentRapportSchema = z.object({
  generellInfo: z.object({
    prosjektNavn: z.string(),
    oppdragsBeskrivelse: z.string(),
    underkonsulent: z.object({
      navn: z.string(),
      orgNummer: z.string(),
      adresse: z.string(),
      representant: z.object({
        navn: z.string(),
        tittel: z.string(),
        kontaktDetaljer: z.object({
          email: z.string(),
          tlf: z.string(),
        }),
      }),
    }),
    multiRepresentant: z.object({
      navn: z.string(),
      tittel: z.string(),
      kontaktDetaljer: z.object({
        email: z.string(),
        tlf: z.string(),
      }),
    }),
    dato: z.string(),
    sted: z.string(),
  }),
  avtaleDokumenter: z.object({
    avtaleC: z.boolean(),
    avtaleD: z.boolean(),
    avtaleE: z.string(),
  }),
  underkonsulentensNokkelpersoner: z.object({
    isActive: z.boolean(),
    nokkelpersoner: z.array(
      z.object({
        navn: z.string(),
        fagdisiplin: z.string(),
      }),
    ),
  }),
  dagmulktEllerErstatning: z.object({
    altEn: z.boolean(),
    altTo: z.boolean(),
    dagmulktBelagteFrister: z.array(
      z.object({
        frist: z.string(),
        sats: z.string(),
      }),
    ),
  }),
  ansvarsbegrensninger: z.object({
    forsinkelse: z.object({
      altEn: z.boolean(),
      altTo: z.boolean(),
      bestemmelser: z.string(),
    }),
    prosjekteringsfeil: z.object({
      altEn: z.boolean(),
      altTo: z.boolean(),
      bestemmelser: z.string(),
    }),
  }),
  ovrigeBestemmelser: z.boolean(),
  vedlegg: z.object({
    vedleggA: z.object({
      isActive: z.boolean(),
      beskrivelse: z.string(),
    }),
    vedleggB: z.object({
      isActive: z.boolean(),
      beskrivelse: z.string(),
    }),
    vedleggC: z.object({
      isActive: z.boolean(),
      beskrivelse: z.string(),
    }),
    vedleggD: z.object({
      isActive: z.boolean(),
      beskrivelse: z.string(),
    }),
    vedleggE: z.object({
      isActive: z.boolean(),
      beskrivelse: z.string(),
    }),
  }),
});

export type IInternJuridiskUnderkonsulentRapport = z.infer<typeof ZInternJuridiskUnderkonsulentRapportSchema>;
