import { CellEditRequestEvent, ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { ISJArapportOption } from "mc-shared/zod/report/Elektro/sjaSchema";
import React, { useMemo } from "react";
import { Button } from "reactstrap";
import { AG_GRID_LOCALE_NOR } from "../../../../../agGridLocale";
import { Checkbox, VSpace } from "../../../../../components";

export const SJAOptionsTable: React.FC<{
  category: string;
  editData: ISJArapportOption;
  updateData: (key: string, value: any) => void;
}> = ({ category, editData, updateData }) => {
  const rowData = useMemo(
    () => editData?.faremomenter?.map((item, i) => ({ ...item, _id: i })),
    [editData, editData?.isSelected],
  );
  const updateRowCheckbox = (index: number, value: boolean, parameter: string) => {
    let faremomenter = [...editData?.faremomenter];
    if (parameter === "isSelected") {
      faremomenter[index].isSelected = value;
    }
    if (parameter === "isApproved") {
      faremomenter[index].isApproved = value;
    }
    updateData(`options.${category}.faremomenter`, faremomenter);
  };

  const updateAllOptionsInCategory = (category: string, value: boolean) => {
    let dataToUpdate = editData;
    dataToUpdate.isSelected = value;
    dataToUpdate?.faremomenter?.forEach((_, index) => {
      dataToUpdate.faremomenter[index].isSelected = value;
    });
    updateData(`options.${category}`, dataToUpdate);
  };

  const onCellEditRequest = (event: CellEditRequestEvent<any>) => {
    let faremomenter = [...editData?.faremomenter];
    faremomenter[event.node.rowIndex][event.colDef.field] = event.newValue;

    updateData(`options.${category}.faremomenter`, faremomenter);
  };

  const addNewRow = () => {
    const newRow = {
      isSelected: true,
      deloppgave: "",
      faremoment: "",
      muligKonsekvens: "",
      tiltak: "",
      isApproved: false,
      ansvarlig: "",
      kommentar: "",
    };

    const updatedFaremomenter = [...editData.faremomenter, newRow];

    updateData(`options.${category}.faremomenter`, updatedFaremomenter);
  };

  const columns: ColDef<any>[] = useMemo(
    () => [
      {
        headerName: "",
        field: "isSelected",
        cellRenderer: (params) => {
          return (
            <Checkbox
              mode="check"
              disabled={false}
              isChecked={params?.value}
              setChecked={() => updateRowCheckbox(params.node.rowIndex, !params?.value, "isSelected")}
              color="#f8992e"
              className="ml-2"
            />
          );
        },
        suppressHeaderMenuButton: true,
        width: 50,
        cellClass: "px-1",
      },
      {
        headerName: "Deloppgave",
        field: "deloppgave",
        suppressHeaderMenuButton: true,
        flex: 1,
        cellClass: "d-block",
        editable: true,
      },
      {
        headerName: "Faremoment",
        field: "faremoment",
        suppressHeaderMenuButton: true,
        flex: 1,
        cellClass: "d-block",
        editable: true,
      },
      {
        headerName: "Mulig konsekvens",
        field: "muligKonsekvens",
        suppressHeaderMenuButton: true,
        cellClass: "d-block",
        flex: 1,
        editable: true,
      },
      {
        headerName: "Tiltak",
        field: "tiltak",
        suppressHeaderMenuButton: true,
        flex: 1.5,
        cellClass: "d-block",
        editable: true,
      },
      {
        headerName: "OK",
        field: "isApproved",
        cellRenderer: (params) => {
          return (
            <Checkbox
              mode="check"
              disabled={false}
              isChecked={params?.value}
              setChecked={() => updateRowCheckbox(params.node.rowIndex, !params?.value, "isApproved")}
              className="ml-2"
            />
          );
        },
        suppressHeaderMenuButton: true,
        width: 55,
        cellClass: "px-1",
      },
      {
        headerName: "Ansvarlig",
        field: "ansvarlig",
        suppressHeaderMenuButton: true,
        editable: true,
      },
      {
        headerName: "Kommentar",
        field: "kommentar",
        suppressHeaderMenuButton: true,
        editable: true,
        flex: 1,
      },
    ],
    [rowData?.length],
  );

  return (
    <div className="ag-theme-quartz h-100 w-100">
      <div className="d-flex">
        <Checkbox
          mode="check"
          disabled={false}
          isChecked={editData?.isSelected}
          setChecked={() => updateAllOptionsInCategory(`${category}`, !editData?.isSelected)}
          color="#f8992e"
          className="mb-2"
        />
        <h4 className="ml-2">
          <b>{editData.name}</b>
        </h4>
      </div>
      <Button color="success" onClick={addNewRow}>
        Legg til rad
      </Button>
      <VSpace />
      <AgGridReact
        rowHeight={40}
        domLayout={"autoHeight"}
        columnDefs={columns}
        getRowId={(p) => p.data._id}
        rowData={rowData}
        stopEditingWhenCellsLoseFocus={true}
        animateRows={false}
        readOnlyEdit={true}
        suppressContextMenu={true}
        maintainColumnOrder={true}
        onCellEditRequest={onCellEditRequest}
        localeText={AG_GRID_LOCALE_NOR}
      />
    </div>
  );
};
