import { IAzureBlobFile } from "mc-shared/zod/azureSchema";
import { IIssue, IMaconomyProject, IMember, IProject, IProjectMcBimSettings } from "../../../types/global.types";
import { apiSlice } from "../apiSlice";

export const projectRTK = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createOrJoinProject: builder.mutation<IProject, IMaconomyProject>({
      query: (input) => ({
        url: `/user/project`,
        body: {
          maconomy: input,
        },
        method: "PUT",
      }),
      invalidatesTags: ["Project"],
    }),
    getProjectMembers: builder.query<IMember[], string>({
      query: (projectId) => ({
        url: `/project/${projectId}/members`,
        method: "GET",
      }),
    }),
    getProject: builder.query<IProject, string>({
      query: (projectId) => ({
        url: `/project/${projectId}/single`,
        method: "GET",
      }),
      providesTags: ["Project"],
    }),
    enableMcBim: builder.mutation<IProjectMcBimSettings, string>({
      query: (projectId) => ({
        url: `/project/${projectId}/enable-mcbim`,
        method: "POST",
      }),
      invalidatesTags: ["Project"],
    }),
    getActiveAssignedItems: builder.query<IIssue[], { userId: string; projectId: string }>({
      query: (input) => ({
        url: `/project/${input.projectId}/active-asigned-items/${input.userId}`,
        method: "GET",
      }),
      providesTags: ["Issue"],
    }),
    getActiveUserOwnedItems: builder.query<IIssue[], { userId: string; projectId: string }>({
      query: (input) => ({
        url: `/project/${input.projectId}/user-owned-items/${input.userId}`,
        method: "GET",
      }),
      providesTags: ["Issue"],
    }),
    getActiveItemsWhereUserIsAMember: builder.query<IIssue[], { userId: string; projectId: string }>({
      query: (input) => ({
        url: `/project/${input.projectId}/user-as-member-items/${input.userId}`,
        method: "GET",
      }),
      providesTags: ["Issue"],
    }),
    uploadMeetingDocx: builder.mutation<void, { projectId: string; meetingId: string; event: any }>({
      query: ({ projectId, meetingId, event }) => {
        const formData = new FormData();
        formData.append("fileToUpload", event.target.files[0]);
        return {
          url: `/project/${projectId}/meeting-docx-template`,
          method: "POST",
          body: formData,
        };
      },
    }),
    getProjectMeetingDocxTemplates: builder.query<IAzureBlobFile[], { projectId: string }>({
      query: (input) => ({
        url: `/project/${input.projectId}/meeting-docx-templates`,
        method: "GET",
      }),
      providesTags: ["Meeting"],
    }),
    deleteMeetingDocxTemplate: builder.mutation<void, { projectId: string; fullBlobId: string }>({
      query: ({ projectId, fullBlobId }) => ({
        url: `/project/${projectId}/delete-meeting-template`,
        body: {
          fullBlobId: fullBlobId,
        },
        method: "DELETE",
      }),
      invalidatesTags: ["Meeting"],
    }),
    uploadIssueboardDocx: builder.mutation<void, { projectId: string; boardId: string; event: any }>({
      query: ({ projectId, boardId, event }) => {
        const formData = new FormData();
        formData.append("fileToUpload", event.target.files[0]);
        formData.append("boardId", boardId);
        return {
          url: `/project/${projectId}/issueboard-docx-template.add/${boardId}`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["IssueBoard"],
    }),
    getProjectIssueboardDocxTemplates: builder.query<IAzureBlobFile[], { projectId: string; issueBoardId: string }>({
      query: (input) => ({
        url: `/project/${input.projectId}/issueboard-docx-templates.get`,
        method: "POST",
        body: {
          boardId: input.issueBoardId,
        },
      }),
      providesTags: ["IssueBoard"],
    }),
    getProjectIssueDocxTemplates: builder.query<IAzureBlobFile[], { projectId: string }>({
      query: (input) => ({
        url: `/project/${input.projectId}/issue-docx-templates.get`,
        method: "POST",
      }),
      providesTags: ["IssueBoard"],
    }),
    deleteProjectIssueDocxTemplate: builder.mutation<void, { projectId: string; fullBlobId: string }>({
      query: ({ projectId, fullBlobId }) => ({
        url: `/project/${projectId}/issuedocx.delete`,
        body: {
          fullBlobId: fullBlobId,
        },
        method: "POST",
      }),
      invalidatesTags: ["IssueBoard"],
    }),
    getProjectLogoTypeAzureBlobStorageFile: builder.query<IAzureBlobFile | null, { projectId: string }>({
      query: ({ projectId }) => ({
        url: `/project/${projectId}/logotype.get`,
        method: "POST",
      }),
      providesTags: ["Project"],
    }),
    deleteProjectLogo: builder.mutation<string | null, { projectId: string }>({
      query: ({ projectId }) => ({
        url: `/project/${projectId}/logotype.delete`,
        method: "POST",
      }),
      invalidatesTags: ["Project"],
    }),
    uploadLogoType: builder.mutation<void, { projectId: string; image: File }>({
      query: ({ projectId, image }) => {
        const formData = new FormData();
        formData.append("fileToUpload", image);
        return {
          url: `/project/${projectId}/logotype.add`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["Project"],
    }),
    updateUserName: builder.mutation<{ firstName: string; lastName: string }, { firstName: string; lastName: string }>({
      query: (newUsername) => ({
        url: "/user/update-name",
        method: "POST",
        body: newUsername,
      }),
    }),
    pingMCappsAzureFunctions: builder.query<void, void>({
      query: () => ({
        url: "/mcapps-azure-function-ping",
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetProjectQuery,
  useEnableMcBimMutation,
  useGetActiveAssignedItemsQuery,
  useGetActiveUserOwnedItemsQuery,
  useGetActiveItemsWhereUserIsAMemberQuery,
  useGetProjectMeetingDocxTemplatesQuery,
  useDeleteMeetingDocxTemplateMutation,
  useGetProjectLogoTypeAzureBlobStorageFileQuery,
  useDeleteProjectLogoMutation,
  useUploadLogoTypeMutation,
  useUploadIssueboardDocxMutation,
  useGetProjectIssueboardDocxTemplatesQuery,
  useGetProjectIssueDocxTemplatesQuery,
  useDeleteProjectIssueDocxTemplateMutation,
  usePingMCappsAzureFunctionsQuery,
  useCreateOrJoinProjectMutation,
} = projectRTK;
