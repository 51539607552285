import { AccessLevelsEnum } from "mc-shared/enums/enums";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Alert, UncontrolledTooltip } from "reactstrap";
import {
  CommentActions,
  IBoard,
  IIssue,
  IMember,
  IUser,
  taskOrIssueENUM,
  TodoActions,
} from "../../../../../../../../types/global.types";
import {
  ChangeItemRegisterSelector,
  ConfirmDialog,
  CopyIssueButton,
  HSpace,
  IconToggle,
  IssueDetails,
  VSpace,
} from "../../../../../../components";
import GlobalFileUploaderTable from "../../../../../../components/FileUploader/GlobalFileUploaderTable";
import { IEditorMetadata } from "../../../../../../components/MultiComment/MultiComment";
import { AppModalEnums } from "../../../../../../frontendConstants";
import { addAppModalThunk, removeAppModalInRedux, selectUserMember } from "../../../../../../global/globalSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../hooks";
import { setMcBimSpacesThunk } from "../../../../../actions";
import { STANDARD_COLORS } from "../../../../../globalConstants";
import { formatTags } from "../../../../../utils";
import { doGetMcBimSpaceList } from "../../../../admin/mcbim/McBimMongoAPI";
import {
  fetchRisikosAndSetInRedux,
  setActiveRisikoBoard,
  setRisikoBoardsInRedux,
  setRisikoCategoriesInRedux,
} from "../../../../risiko/actions";
import {
  doGetRisikoBoards,
  doGetRisikoEvaluationCategories,
  doGetSingleRisikoBoard,
} from "../../../../risiko/risikoAPI";
import { IssueTabsEnum } from "../../../issueConstants";
import {
  useAddIssueCommentMutation,
  useAddIssueTodoMutation,
  useArchiveIssueMutation,
  useChangeBoardOfIssueItemMutation,
  useCopyIssueMutation,
  useDeleteIssueCommentMutation,
  useDeleteIssueTodoMutation,
  useDoUpdateIssueMutation,
  useGetUniqueTagsOnBoardQuery,
  useToggleClientAccessMutation,
  useToggleIssueTodoMutation,
  useToggleSubscriptionMutation,
  useUpdateIssueCommentMutation,
  useUpdateIssueTodoMutation,
} from "../../../issueRTK";
import { getIssueActionNameOption, getIssueRegisterNameOption } from "../../../issueUtils";
import ConnectedTasksAndIssuesMiniTable from "../../Table/ConnectedTasksAndIssuesMiniTable";
import TaskMiniTable from "../../Table/TaskMiniTable";
import IssueItemButtonGroups from "./IssueItemButtonGroups";
import IssueHistoryLogs from "./Tabs/IssueHistoryLogs";
import "./issueItem.css";

const IssueItem: React.FC<{
  issue: IIssue;
  issueOrTask: taskOrIssueENUM;
  user: IUser;
  issueBoard: IBoard;
  issueBoards: IBoard[];
  projectId: string;
  members: IMember[];
}> = ({ issue, issueOrTask, projectId, members, user, issueBoard, issueBoards }) => {
  const [activeIssueItemTab, setActiveIssueItemTab] = useState<IssueTabsEnum>(IssueTabsEnum.INFO);
  const project = useAppSelector((state) => state.adminReducer.project);
  const mcbimSpaces = useAppSelector((state) => state.adminReducer.mcbimSpaces);
  const arcgis = useAppSelector((state) => state.adminReducer.project?.arcgis);
  const userMember = useAppSelector(selectUserMember);
  const projectIsGisActivated = arcgis?.groupId != null;
  const [addComment, { isLoading: addCommentLoading, error: addCommentError }] = useAddIssueCommentMutation();
  const [deleteComment, { isLoading: deleteCommentLoading, error: deleteCommentError }] =
    useDeleteIssueCommentMutation();
  const [updateComment, { isLoading: updateCommentIsLoading, error: updateCommentError }] =
    useUpdateIssueCommentMutation();

  const [addIssueTodo, { isLoading: addIssueTodoLoading, error: addIssueTodoError }] = useAddIssueTodoMutation();
  const [deleteIssueTodo, { isLoading: deleteIssueTodoLoading, error: deleteIssueTodoError }] =
    useDeleteIssueTodoMutation();
  const [toggleIssueTodo, { isLoading: toggleIssueTodoLoading, error: toggleIssueTodoError }] =
    useToggleIssueTodoMutation();
  const [updateIssueTodo, { isLoading: updateIssueTodoLoading, error: updateIssueTodoError }] =
    useUpdateIssueTodoMutation();

  const { data: tags, isLoading: tagsLoading } = useGetUniqueTagsOnBoardQuery({
    projectId: projectId,
    boardId: issueBoard._id,
  });

  const issueTags = formatTags(tags);

  const [copyIssue, { isLoading: isCopying }] = useCopyIssueMutation();

  const [changeBoardOfIssue, { error: changeBoardError }] = useChangeBoardOfIssueItemMutation();

  const [updateIssue, { isLoading: isUpdating }] = useDoUpdateIssueMutation();

  const [archiveIssueMutation, { isLoading: isArchiving }] = useArchiveIssueMutation();

  const dispatch = useAppDispatch();

  const history = useHistory();

  const closeIssueAndGoToGis = () => {
    history.push(`${history.location.pathname}`);
    dispatch(removeAppModalInRedux(issue._id));
  };

  const openConnectionModal = async (itemId: string, boardId: string, app: AppModalEnums) => {
    if (app === AppModalEnums.RISIKO) {
      const _categories = await doGetRisikoEvaluationCategories(projectId, boardId);
      dispatch(setRisikoCategoriesInRedux(_categories));
      const _boards = await doGetRisikoBoards(projectId);
      dispatch(setRisikoBoardsInRedux(_boards));
      const _activeBoard = await doGetSingleRisikoBoard(projectId, boardId);
      dispatch(fetchRisikosAndSetInRedux(_activeBoard, _categories, projectId));
      dispatch(setActiveRisikoBoard(_activeBoard));
    }

    dispatch(
      addAppModalThunk({
        itemId,
        boardId,
        projectId,
        app,
      }),
    );
  };

  const onSpaceTypeAheadFocus = async () => {
    try {
      if (mcbimSpaces == null) {
        let _mcbimSpaces = await doGetMcBimSpaceList(project?._id);
        dispatch(setMcBimSpacesThunk(_mcbimSpaces));
      }
    } catch (err) {
      dispatch(setMcBimSpacesThunk([]));
    }
  };

  const commentError = addCommentError || deleteCommentError;

  const _changeBoardOfIssue = (board: IBoard) => {
    history.push(`${history.location.pathname}`);
    changeBoardOfIssue({
      issueId: issue._id,
      projectId,
      boardIdToChangeTo: board._id,
    });
  };

  const _archiveIssue = () => {
    history.push(`${history.location.pathname}`);
    archiveIssueMutation({
      issueId: issue._id,
      projectId,
    });
    dispatch(removeAppModalInRedux(issue._id));
  };

  const _copyIssue = (issueId: string, includeTasks: boolean) => {
    copyIssue({
      issueId: issue._id,
      projectId,
      includeTasks,
    });
  };

  const deleteMessageVariable =
    issue?.taskOrIssue === "ISSUE"
      ? getIssueRegisterNameOption(issueBoard?.issueNameSetting, "singular")
      : getIssueActionNameOption(issueBoard?.taskNameSetting, "singular");

  return (
    <>
      {commentError != null && <Alert color="danger">Comment mutation failed</Alert>}
      {updateIssueTodoError != null && <Alert color="danger">Update mutation failed</Alert>}
      <div className="d-flex pr-4 pl-9 mr-4">
        <IssueItemButtonGroups
          issue={issue}
          activeButtonName={activeIssueItemTab || IssueTabsEnum.INFO}
          setSelectedButtonName={(view) => setActiveIssueItemTab(view)}
          taskOrIssue={issueOrTask}
          projectIsGisActivated={projectIsGisActivated}
          issueBoard={issueBoard}
        />
        {issueBoards != null && issue.taskOrIssue === "ISSUE" && (
          <div className="change-register-selector">
            <ChangeItemRegisterSelector
              boards={issueBoards}
              activeBoard={issueBoards.find((board) => board._id === issue.issueBoard)}
              onChangeBoard={_changeBoardOfIssue}
            />
          </div>
        )}
        <div className="issue-buttons-goup">
          <HSpace />
          {userMember?.group !== "CLIENT" && issueBoard?.access?.level === AccessLevelsEnum.PROJECT && (
            <ClientAccess issueId={issue._id} projectId={projectId} hasClientAccess={issue.clientAccess} />
          )}
          <Subscribe
            projectId={projectId}
            issueId={issue._id}
            hasSubscription={issue.subscribers?.indexOf(user?._id) !== -1}
            isIssue={issue.taskOrIssue === "ISSUE"}
          />
          <HSpace />
          <CopyIssueButton issueId={issue._id} taskOrIssue={issue.taskOrIssue} copyAndSet={_copyIssue} />
          <HSpace />
          <ConfirmDialog
            title={`Slette ${deleteMessageVariable}?`}
            message={`Er du sikker på at du vil slette ${deleteMessageVariable?.toLowerCase()}?`}
            confirmCb={_archiveIssue}
          >
            <IconToggle
              activeIcon="trash"
              inactiveIcon="trash"
              activeColor={STANDARD_COLORS.orange}
              toggled={false}
              onClick={() => {}}
            />
          </ConfirmDialog>
        </div>
      </div>
      <div className="d-flex flex-column w-100 mt-4">
        {activeIssueItemTab === IssueTabsEnum.INFO && (
          <IssueDetails
            board={issueBoard}
            issue={issue}
            members={members}
            onValueChange={(oldValue, newValue, param) => {
              if (oldValue !== newValue) {
                updateIssue({
                  projectId,
                  issueId: issue._id,
                  attr: param,
                  value: newValue,
                  oldValue,
                  issueBoardId: issueBoard._id,
                });
              }
            }}
            todoChange={(id: string, action: TodoActions, value?: string, isChecked?: boolean) => {
              switch (action) {
                case "ADD":
                  addIssueTodo({
                    projectId,
                    issueId: issue._id,
                    text: value,
                  });
                  break;
                case "DELETE":
                  deleteIssueTodo({
                    projectId,
                    issueId: issue._id,
                    todoId: id,
                  });
                  break;
                case "TOGGLE":
                  toggleIssueTodo({
                    projectId,
                    issueId: issue._id,
                    todoId: id,
                    isChecked,
                  });
                  break;
                case "UPDATE":
                  updateIssueTodo({
                    projectId,
                    issueId: issue._id,
                    text: value,
                    todoId: id,
                  });
                  break;
                default:
                  return;
              }
            }}
            commentChange={(id: string, action: CommentActions, value?: string, metadata?: IEditorMetadata) => {
              switch (action) {
                case "ADD":
                  addComment({
                    projectId,
                    issueId: issue._id,
                    text: value,
                    metadata,
                  });
                  break;
                case "DELETE":
                  deleteComment({
                    projectId,
                    issueId: issue._id,
                    commentId: id,
                  });
                  break;
                case "UPDATE":
                  updateComment({
                    projectId,
                    issueId: issue._id,
                    text: value,
                    metadata,
                    commentId: id,
                  });
                  break;
                default:
                  return;
              }
            }}
            onTagsChange={(oldValue, newValue) =>
              updateIssue({
                projectId,
                issueId: issue._id,
                attr: "tags",
                value: newValue,
                oldValue,
                issueBoardId: issueBoard._id,
              })
            }
            tagOptions={issueTags}
            pnsItems={project?.pns || []}
            loggedInUser={user}
            onOpenConnectionModal={openConnectionModal}
            mcbimSpaces={mcbimSpaces}
            onSpaceTypeAheadFocus={onSpaceTypeAheadFocus}
            showSpaceInput={project?.mcbim?.activated === true}
          />
        )}
        {activeIssueItemTab === IssueTabsEnum.LOG && <IssueHistoryLogs issueId={issue._id} projectId={projectId} />}
        {activeIssueItemTab === getIssueActionNameOption(issueBoard?.taskNameSetting, "plural") && (
          <>
            <TaskMiniTable
              fullWidth
              issueId={issue._id}
              issueBoardId={issueBoard._id}
              projectId={projectId}
              members={members}
              issueLocalId={issue.id}
              issueBoard={issueBoard}
            />
            <VSpace />
            <VSpace />
            <ConnectedTasksAndIssuesMiniTable
              fullWidth
              issue={issue}
              issueId={issue._id}
              issueBoardId={issueBoard._id}
              projectId={projectId}
              issueBoard={issueBoard}
            />
          </>
        )}
        {activeIssueItemTab === IssueTabsEnum.FILES && (
          <GlobalFileUploaderTable path={`${projectId}/issue/${issue?._id}/files`} />
        )}
        {/*{activeIssueItemTab === IssueTabsEnum.GIS && (
          <GisItem
            imgPath={`/api/issue/${issue._id}/gis-screen-shot`}
            disconnectGisPoint={() =>
              updateIssue({
                projectId,
                issueId: issue._id,
                attr: "gis",
                value: null,
                oldValue: "",
                issueBoardId: issueBoard._id,
              })
            }
            goToViewer={closeIssueAndGoToGis}
            showStartGisViewerButon={activeIssueItemTab !== "GIS"}
            hasGis={issue?.gis?.gisPoint?.x != null}
          />
        )}*/}
      </div>
    </>
  );
};

export default IssueItem;

const Subscribe: React.FC<{
  issueId: string;
  projectId: string;
  hasSubscription: boolean;
  isIssue: boolean;
}> = ({ issueId, projectId, hasSubscription, isIssue }) => {
  const [toggleSubscription] = useToggleSubscriptionMutation();

  const helpText = `Hvis du abonnerer vil du motta en e-post hvis denne ${
    isIssue ? "saken" : "aksjone"
  } har endret seg det siste døgnet`;

  return (
    <>
      <UncontrolledTooltip delay={500} placement="top" target="subbell">
        {helpText}
      </UncontrolledTooltip>
      <IconToggle
        id="subbell"
        activeIcon="bell"
        inactiveIcon="bell-slash"
        toggled={hasSubscription}
        activeColor={STANDARD_COLORS.orange}
        onClick={() => toggleSubscription({ issueId, projectId })}
      />
    </>
  );
};

const ClientAccess: React.FC<{
  issueId: string;
  projectId: string;
  hasClientAccess: boolean;
}> = ({ issueId, projectId, hasClientAccess }) => {
  const [toggleClientAccess] = useToggleClientAccessMutation();

  const helpText = `Angi om kundene i prosjektet skal ha tilgang til denne saken`;

  return (
    <>
      <UncontrolledTooltip delay={500} placement="top" target="calock">
        {helpText}
      </UncontrolledTooltip>
      <IconToggle
        id="calock"
        activeIcon="unlock-alt"
        inactiveIcon="lock"
        toggled={hasClientAccess}
        activeColor={STANDARD_COLORS.green}
        onClick={() => toggleClientAccess({ issueId, projectId })}
        inActiveColor={STANDARD_COLORS.red}
      />
    </>
  );
};
