import * as R from "ramda";
import React from "react";
import { MultiOptionColorDropdownViewEdit, Tags, TitleWithValue } from "..";
import {
  FlatHierarchy,
  IMember,
  IPNSProps,
  IRisikoEvaluationCategory,
  IRiskoBoard,
  ITag,
} from "../../../../types/global.types";
import { VIEW_EDIT_COMPS } from "../../app/globalConstants";
import { RisikoScoreFormatter } from "../../app/routes/risiko/Risikotab/RisikoTab";
import { IBootstrapTableColumnConfig } from "../../types";
import DropdownEditDepricated from "../DropdownEditDepricated";
import TypeaheadViewEdit from "../TypeaheadViewEdit";

const ColumnsForm: React.FC<{
  item: any;
  columns: IBootstrapTableColumnConfig[];
  saveCb: (dataField: string, newValue: string, oldValue: string) => void;
  pnsItems?: FlatHierarchy<IPNSProps>;
  fetchUniqueTags?: (projectId: string, boardId: string) => Promise<ITag[]>;
  boardId: string;
  evaluationCategories?: IRisikoEvaluationCategory[];
  risikoBoard?: IRiskoBoard;
  projectId: string;
  members: IMember[];
}> = ({
  item,
  columns,
  saveCb,
  pnsItems,
  fetchUniqueTags,
  boardId,
  evaluationCategories,
  risikoBoard,
  projectId,
  members,
}) => {
  return (
    <>
      {columns
        .filter((col) => col.showInDetail !== false)
        .map((column, index) => {
          const save = async (oldVal, newVal) => {
            saveCb(column.dataField, newVal, oldVal || "");
          };

          const value: any = R.path(column.dataField.split("."), item);

          if (column.viewEditComp === VIEW_EDIT_COMPS.TITLE_WITH_VALUE_EDIT) {
            return (
              <TitleWithValue
                item={item}
                type={column.inputType}
                // formatter={column.formatter}
                editable={column.editable}
                saveCb={save}
                key={index}
                title={column.text}
                value={value}
              />
            );
          }

          if (column.viewEditComp === VIEW_EDIT_COMPS.DROPDOWN_EDIT && item?.taskOrIssue !== "TASK") {
            return (
              <DropdownEditDepricated
                title={column.text}
                saveCb={save}
                key={index}
                editable={column.editable}
                options={column.simpleDropdownOptions}
                value={value}
                disableReset={column.disableReset}
              />
            );
          }

          if (column.viewEditComp === VIEW_EDIT_COMPS.DROPDOWN_COLOR_EDIT) {
            return (
              <MultiOptionColorDropdownViewEdit
                title={column.text}
                saveCb={save}
                key={index}
                options={column.dropdownOptions}
                activeOptionKey={R.path([column.dataField], item)}
              />
            );
          }
          if (column.viewEditComp === VIEW_EDIT_COMPS.TYPEAHEAD_EDIT) {
            return (
              <TypeaheadViewEdit
                title={column.text}
                viewValueKey="name"
                saveCb={save}
                key={index}
                options={members}
                value={R.path([column.dataField.split(".")[0]], item)}
              />
            );
          }

          if (column.viewEditComp === VIEW_EDIT_COMPS.TAG_EDITOR) {
            return (
              <>
                <h5>Etiketter</h5>
                <Tags
                  tags={value}
                  updateCb={(newVal) => save(value, newVal)}
                  fetchUniqTags={fetchUniqueTags}
                  fetchArgs={[projectId, boardId]}
                  showAddButton
                />
              </>
            );
          }

          if (column.viewEditComp === VIEW_EDIT_COMPS.EVALUATION_PROBABILITY_EDIT) {
            const selections = risikoBoard.probabilitySelections;
            return (
              <DropdownEditDepricated
                key={index}
                title={column.text}
                editable
                value={value}
                saveCb={save}
                options={selections.map((sel) => sel.name)}
              />
            );
          }

          if (
            column.viewEditComp === VIEW_EDIT_COMPS.EVALUATION_CONS_EDIT ||
            column.viewEditComp === VIEW_EDIT_COMPS.EVALUATION_SCORE
          ) {
            const category = evaluationCategories.find((category) => category._id === column.evaluationCategoryId);
            if (category != null) {
              if (column.viewEditComp === VIEW_EDIT_COMPS.EVALUATION_CONS_EDIT) {
                const selections = category.consequenceSelections;
                return (
                  <DropdownEditDepricated
                    key={index}
                    title={column.text}
                    editable
                    value={value}
                    saveCb={save}
                    options={selections.map((sel) => sel.name)}
                  />
                );
              }
              if (column.viewEditComp === VIEW_EDIT_COMPS.EVALUATION_SCORE) {
                return (
                  <div key={index}>
                    <div className="mb-2">
                      <h5>Poeng</h5>
                      <div>{RisikoScoreFormatter(value, item, null, { columnName: category.name })}</div>
                      <hr />
                    </div>
                  </div>
                );
              }
            }
            return column.dataField;
          }

          return <></>;
        })}
    </>
  );
};

export default ColumnsForm;
