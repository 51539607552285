import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Card, CardBody, UncontrolledTooltip } from "reactstrap";
import { useLocalStorage } from "usehooks-ts";
import { IBoard, IBreeamStat } from "../../../../../../../types/global.types";
import { AG_GRID_LOCALE_NOR } from "../../../../../agGridLocale";
import { Checkbox, HSpace, McDropdown, MCLoader, VSpace } from "../../../../../components";
import { AppModalEnums } from "../../../../../frontendConstants";
import { addAppModalThunk } from "../../../../../global/globalSlice";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { BREEAM_DASHBOARD_PHASES } from "../../issueConstants";
import { useDoGetAllIssuesQuery } from "../../issueRTK";
import { getIssueUrlPath } from "../../issueUtils";
import BreeamBarChart, { BreeamStackedBarChart } from "./BreeamBarChart";
import GaugeChart from "./GaugeChart";

export const breeamAttributeTitles = [
  {
    attribute: "maxPoints",
    key: "D",
    titleAlternatives: ["Mulige poeng", "Antall oppnåelige BREEAM poeng", "Antall oppnåelige poeng"],
  },
  {
    attribute: "plannedPoints",
    key: "E",
    titleAlternatives: ["Planlagte poeng", "Antatt oppnådde poeng", "Antatt oppnåelige poeng"],
  },
  { attribute: "achievedPoints", key: "F", titleAlternatives: ["Oppnådde poeng", "Faktisk oppnådde poeng"] },
];
export enum OtherAttributeEnum {
  MaxPoints = "D",
  PlannedPoints = "E",
  AchievedPoints = "F",
}
const BreeamDashboard: React.FC<{ projectId: string; issueBoard: IBoard }> = ({ projectId, issueBoard }) => {
  const { allFilters, freeTextSearch, selectedPnsFilterItem } = useAppSelector((state) => state.issueReducerNew);

  const { data: items = [], isLoading } = useDoGetAllIssuesQuery({
    projectId: projectId,
    issueBoardId: issueBoard._id,
    allFilters: allFilters,
    freeTextSearch: freeTextSearch,
    pns: selectedPnsFilterItem,
  });

  const getBreeamValueCount: IBreeamStat = React.useMemo(() => {
    let primaryTotal = 0;
    let primaryAltTotal = 0;
    let secondaryTotal = 0;
    let maxTotal = 0;
    let optionalTotal = 0;
    items.forEach((item) => {
      const otherAttributesData = item.otherAttributesData || {};

      const primaryValue =
        item.bucket.replace(/\s+/g, "").toLowerCase() === "dokumentasjonmottatt"
          ? Number(otherAttributesData[OtherAttributeEnum.PlannedPoints]) || 0
          : 0;
      const primaryValueAlt = Number(otherAttributesData[OtherAttributeEnum.AchievedPoints]) || 0; //some projects use achieved points instead of 'documentation received'
      const secondaryValue = Number(otherAttributesData[OtherAttributeEnum.PlannedPoints]) || 0; //Antatt Oppnådde poeng
      const maxValue = Number(otherAttributesData[OtherAttributeEnum.MaxPoints]) || 0; //Mulige poeng
      const optionalValue = item.bucket.toLowerCase().includes("usikker")
        ? Number(otherAttributesData[OtherAttributeEnum.PlannedPoints] || 0) //Antatt oppnådde poeng
        : 0;
      // Update totals
      primaryTotal += primaryValue;
      primaryAltTotal += primaryValueAlt;
      secondaryTotal += secondaryValue;
      maxTotal += maxValue;
      optionalTotal += optionalValue;
    });
    return {
      valueArray: [
        primaryTotal > primaryAltTotal ? primaryTotal : primaryAltTotal,
        secondaryTotal,
        secondaryTotal - optionalTotal,
      ],
      maxTotal: maxTotal || 1,
    };
  }, [items]);

  const getPreanalyseValueCount: IBreeamStat = React.useMemo(() => {
    let total_1 = 0;
    let total_2 = 0;
    let total_3 = 0;
    let total_4 = 0;
    let maxTotal = 0;

    const useCustomAttribute = !!items[0]?.customAttributeData?.["BREEAM infrastructure"]?.["Vanskelighetsgrad"];

    items.forEach((item) => {
      const customAttributePreanalyseData =
        item?.customAttributeData?.["BREEAM infrastructure"]?.["Vanskelighetsgrad"] || "";
      const normalizedBucket =
        useCustomAttribute === true
          ? customAttributePreanalyseData.replace(/\s+/g, "")?.toLowerCase()
          : item.bucket.replace(/\s+/g, "")?.toLowerCase();
      const maxValue = Number(item?.otherAttributesData?.[OtherAttributeEnum.MaxPoints]) || 0;
      const plannedValue = Number(item?.otherAttributesData?.[OtherAttributeEnum.PlannedPoints]) || 0;
      maxTotal += maxValue;

      switch (normalizedBucket) {
        case "antasoppnås":
          total_1 += plannedValue;
          total_2 += plannedValue;
          total_3 += plannedValue;
          total_4 += plannedValue;
          break;
        case "krevernoe":
          total_2 += plannedValue;
          total_3 += plannedValue;
          total_4 += plannedValue;
          break;
        case "krevermer":
          total_3 += plannedValue;
          total_4 += plannedValue;
          break;
        case "krevermye":
          total_4 += plannedValue;
          break;
        default:
          break;
      }
    });
    return {
      valueArray: [total_1, total_2, total_3, total_4],
      maxTotal: maxTotal || 1,
    };
  }, [items]);

  return (
    <div className="h-100 w-100" style={{ position: "relative", overflowX: "auto" }}>
      {isLoading && <MCLoader loading />}
      {items != null && (
        <BreeamStatsCard
          breeamStat={getBreeamValueCount}
          preanalyseStat={getPreanalyseValueCount}
          items={items}
          issueBoard={issueBoard}
          projectId={projectId}
        />
      )}
    </div>
  );
};

export default BreeamDashboard;

const BreeamStatsCard: React.FC<{
  breeamStat?: IBreeamStat;
  preanalyseStat?: IBreeamStat;
  items: any[];
  issueBoard: IBoard;
  projectId: string;
}> = ({ breeamStat, preanalyseStat, items, issueBoard, projectId }) => {
  const [breemDashboardPhase, setBreeamDashboardPhase] = useState(BREEAM_DASHBOARD_PHASES.ANALYSE);
  const [showOptionalPoints, setShowOptionalPoints] = useLocalStorage("optionalBREEAMDial", false);

  const history = useHistory();
  const dispatch = useAppDispatch();

  const openIssue = (_id: string) => {
    history.push(getIssueUrlPath(history.location.pathname, _id));
    dispatch(
      addAppModalThunk({
        itemId: _id,
        boardId: issueBoard._id,
        projectId: projectId,
        app: AppModalEnums.ISSUE,
      }),
    );
  };

  useEffect(() => {
    if (issueBoard.name.toLowerCase().includes("preanalyse")) {
      setBreeamDashboardPhase(BREEAM_DASHBOARD_PHASES.PREANALYSE);
    } else {
      setBreeamDashboardPhase(BREEAM_DASHBOARD_PHASES.ANALYSE);
    }
  }, [issueBoard.name]);

  const generateCountColumns = (): any[] => {
    return breeamAttributeTitles.map((attribute) => {
      return {
        aggFunc: "sum",
        headerName: issueBoard.otherAttributesConfig?.[attribute.key]?.label,
        field: `otherAttributesData.${attribute.key}`,
        valueGetter: (params: any) => {
          try {
            const value = Number(params.data.otherAttributesData[attribute.key]);
            return isNaN(value) ? 0 : value;
          } catch (error) {
            return 0;
          }
        },
      };
    });
  };

  const columnDefs = [
    {
      headerName: "Kategori",
      field: "typeOfIssue",
      rowGroup: true,
      hide: true,
    },
    {
      headerName: "Tittel",
      field: "title",
      rowGroup: true,
      hide: true,
    },
    ...generateCountColumns(),
  ];
  return (
    <Card className="d-flex m-2 gray-200">
      <CardBody>
        <div style={{ width: "200px", position: "absolute", top: "0.6em", right: "1.3em", zIndex: 1 }}>
          <McDropdown
            color="mc-blue"
            currentValue={breemDashboardPhase}
            onChange={(_, newValue: BREEAM_DASHBOARD_PHASES) => {
              setBreeamDashboardPhase(newValue);
            }}
            displayKey="label"
            options={[BREEAM_DASHBOARD_PHASES.ANALYSE, BREEAM_DASHBOARD_PHASES.PREANALYSE]}
          />
        </div>
        <VSpace />
        <div className="d-flex w-100 p-0 m-0">
          <Card className="w-50">
            <CardBody className="px-3 pt-3 pb-2">
              {breemDashboardPhase === BREEAM_DASHBOARD_PHASES.ANALYSE ? (
                <>
                  <GaugeChart
                    valueArray={breeamStat.valueArray}
                    maxValue={breeamStat.maxTotal}
                    valueType="certification"
                  />
                  <div style={{ fontFamily: "helvetica", color: "#636363" }}>
                    <Checkbox
                      text="Vis uavklarte poeng"
                      isChecked={showOptionalPoints}
                      setChecked={() => setShowOptionalPoints(!showOptionalPoints)}
                      disabled={false}
                      size="small"
                    />
                  </div>
                </>
              ) : (
                <GaugeChart
                  valueArray={preanalyseStat.valueArray}
                  maxValue={preanalyseStat.maxTotal}
                  valueType="preanalyse"
                />
              )}
            </CardBody>
          </Card>
          <HSpace />
          <Card className="w-50">
            <CardBody className="px-3 pt-3 pb-2">
              {breemDashboardPhase === BREEAM_DASHBOARD_PHASES.ANALYSE ? (
                <BreeamBarChart
                  items={items}
                  dataFields={issueBoard.buckets.map((bucket) => bucket.value)}
                  issueBoard={issueBoard}
                />
              ) : (
                <BreeamStackedBarChart
                  items={items}
                  dataFields={issueBoard.types.map((type) => type.name)}
                  issueBoard={issueBoard}
                />
              )}
            </CardBody>
          </Card>
        </div>
        <VSpace />
        <div className="d-flex w-100 p-0 m-0">
          <Card className="w-100">
            <CardBody className="p-3">
              <div className="ag-theme-quartz h-100">
                <AgGridReact
                  defaultColDef={{
                    flex: 1,
                    minWidth: 100,
                    maxWidth: 400,
                    sortable: true,
                    resizable: true,
                    suppressHeaderMenuButton: true,
                    autoHeaderHeight: true,
                    wrapHeaderText: true,
                    cellClass: "d-block",
                  }}
                  cellSelection={true}
                  groupDisplayType={"singleColumn"}
                  localeText={AG_GRID_LOCALE_NOR}
                  columnDefs={columnDefs}
                  suppressAggFuncInHeader={true}
                  rowData={items}
                  domLayout="autoHeight"
                  rowHeight={32}
                  headerHeight={30}
                  autoGroupColumnDef={{
                    flex: 2,
                    headerName: "Kategori",
                    cellRendererParams: {
                      suppressCount: true,
                    },
                    cellRendererSelector: (params) => {
                      if (params.node.allChildrenCount < 2) {
                        return {
                          component: () => {
                            return (
                              <div className="d-flex align-items-center">
                                <div
                                  className="d-flex align-items-center"
                                  style={{
                                    width: "15px",
                                    height: "15px",
                                    marginRight: "5px",
                                    color: "#ccc",
                                    fontSize: "1.1em",
                                  }}
                                >
                                  <i
                                    className="fa fa-question-circle fa-fw pointer hoverText"
                                    id={"tooltip-" + params.node.rowIndex}
                                  />
                                </div>

                                <div
                                  className="pointer hoverText text-truncate"
                                  onClick={() => {
                                    openIssue(params.node.allLeafChildren[0]?.data._id);
                                  }}
                                >
                                  {params.node.key}
                                </div>
                                <UncontrolledTooltip
                                  offset="0, 10"
                                  style={{ fontFamily: "system-ui", textAlign: "left", minWidth: "450px" }}
                                  placement="bottom"
                                  target={"tooltip-" + params.node.rowIndex}
                                  container="body"
                                >
                                  {params.node.allLeafChildren[0]?.data.description}
                                </UncontrolledTooltip>
                              </div>
                            );
                          },
                        };
                      }
                      return { component: "agGroupCellRenderer" };
                    },
                  }}
                  grandTotalRow={"bottom"}
                />
              </div>
            </CardBody>
          </Card>
        </div>
      </CardBody>
    </Card>
  );
};
