import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { IChecklistStatus, ICheckTypes } from "mc-shared/zod/checklistSchema";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import { IChecklist, IChecklistPopulated } from "../../../../../types/checklist.types";
import { IMinUser, IPNSProps, IProject, IValueLabel } from "../../../../../types/global.types";
import { AG_GRID_LOCALE_NOR } from "../../../agGridLocale";
import { Avatar, FailedAlert, MCLoader, McTitle } from "../../../components";
import { McDropdown, McInput, McTypeahead } from "../../../components/";
import {
  emptyValidator,
  increasingNumberValidator,
} from "../../../components/ExcelImportValidator/ExcelImportValidator.utils";
import HierarchyTreeSelector from "../../../components/HierarchyTreeSelector/HierarchyTreeSelector";
import TemplateSelector, {
  ITemplate,
  TemplateSelectorType,
} from "../../../components/TemplateSelector/TemplateSelector";
import { MCApplicationsUrlEnum } from "../../../frontendConstants";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { ISpecificationTable } from "../../../types";
import CheckTypesSelector from "./CheckTypesSelector";
import { IDocumentManagers } from "./ChecklistAdmin";
import { getChecklistColumns } from "./ChecklistColums";
import { TypeOfChecklistEnum } from "./ChecklistConstants";
import ChecklistDbFilterBar from "./ChecklistDbFilterBar";
import "./ChecklistTable.css";
import { doAddChecklist, doGetChecklistTemplates } from "./checklistAPI";
import {
  useCreateChecklistFromImportedExcelMutation,
  useGetProjectChecklistsQuery,
  useGetUniqueChecklistTagsQuery,
} from "./checklistRTK";
import { setSelectedPnsFilterItemInRedux } from "./checklistSlice";
import { CHECKLIST_STATUS_DROPDOWN_VALUES, ChecklistTemplateStatusEnum } from "./constants";

const ChecklistBoardSelector: React.FC = () => {
  let history = useHistory();
  const [checklistTemplates, setChecklistTemplates] = useState<IChecklistPopulated[]>([]);

  const project: IProject = useAppSelector((state) => state.adminReducer.project);
  const { allFilters, selectedPnsFilterItem, freeTextSearch } = useAppSelector((state) => state.checklistReducer);
  const isTemplateProject = project.maconomy.projectNo === 100;

  const [modalIsOpen, setModalOpen] = useState<boolean>(false);
  const [selectedTitle, setSelectedTitle] = useState<string>("");
  const [pnsItems, setPnsItems] = useState<{ comboLabel: string; _id: string; parentId: string; props: IPNSProps }[]>(
    [],
  );
  const [selectedPns, setSelectedPns] = useState<string>("");
  const [selectedTemplate, setSelectedTemplate] = useState<ITemplate>(null);
  const [convertedTemplates, setConvertedTemplates] = useState<ITemplate[]>(null);
  const [selectedStatus, setSelectedStatus] = useState<IValueLabel>(null);

  const [checkTypes, setCheckTypes] = useState<ICheckTypes>(null);

  const dispatch = useAppDispatch();

  const [
    createChecklistFromExcelMutation,
    { isLoading: createChecklistFromExcelLoading, isError: createChecklistFromExcelError },
  ] = useCreateChecklistFromImportedExcelMutation();

  const {
    data: projectChecklists = [],
    isLoading: checklistsLoading,
    isError: checklistsError,
  } = useGetProjectChecklistsQuery(
    {
      projectId: project._id,
      allFilters: allFilters,
      pns: selectedPnsFilterItem,
      freeTextSearch,
    },
    {
      skip: allFilters == null,
    },
  );

  useEffect(() => {
    setPnsItems(
      project?.pns?.map((pns) => ({
        ...pns,
        comboLabel: `${pns.props.pnsId} - ${pns.props.title}`,
      })),
    );
  }, [project?.pns]);

  const { data: tags } = useGetUniqueChecklistTagsQuery({ projectId: project._id });
  const [columns, setColumns] = useState<ColDef<IChecklist>[]>(null);

  useEffect(() => {
    const _columns = getChecklistColumns(isTemplateProject, pnsItems);
    setColumns(_columns);
  }, [tags]);

  const goToChecklist = async (checklistId: string) => {
    history.push(`/project/${project._id}/checklist/${TypeOfChecklistEnum.CHECKLIST}/${checklistId}`);
  };

  const createNew = async (templateId: string) => {
    const isMcTemplate = project?.maconomy?.projectNo === 100;
    const checklist = await doAddChecklist(
      selectedTitle,
      selectedPns,
      project._id,
      templateId,
      selectedStatus.value as IChecklistStatus,
      checkTypes,
      isMcTemplate === true,
      isMcTemplate === true ? "MC" : "",
    );
    goToChecklist(checklist._id);
  };

  const toggle = () => {
    setSelectedTitle("");
    setModalOpen(!modalIsOpen);
  };

  useEffect(() => {
    init();
  }, []);

  const STATUSES = [CHECKLIST_STATUS_DROPDOWN_VALUES.active, CHECKLIST_STATUS_DROPDOWN_VALUES.in_development];

  const init = async () => {
    const _checklistTempaltes = await doGetChecklistTemplates(project._id);
    setChecklistTemplates(_checklistTempaltes);
    const activeTemplates = _checklistTempaltes.filter((cl) => cl.status === "ACTIVE");
    const newEmptyTemplate: IChecklistPopulated = {
      _id: "0",
      aboutText: "En helt tom mal om du vil sette opp en mal fra bunnen av.",
      checkTypes: null,
      createdAt: null,
      createdBy: null,
      templateType: "MC",
      isTemplate: true,
      project: null,
      name: "Tom mal",
      tags: [],
      status: ChecklistTemplateStatusEnum.IN_DEVELOPMENT,
      generatedFromTemplate: null,
      lastUpdatedBy: null,
      assignedTo: null,
      pns: "",
      stampSettings: {
        yes: { color: "#00975f", icon: "check", text: "JA" },
        no: { color: "#dc3545", icon: "close", text: "NEI" },
        ir: { color: "#f8992e", icon: "ban", text: "IR" },
        na: { color: "#0000004F", icon: "circle", text: "-" },
      },
      templateCategory: null,
      includeIrOnDefaultRoles: false,
    };

    activeTemplates.push(newEmptyTemplate);

    const convertedChecklistTemplates: ITemplate[] = activeTemplates.map((template) => {
      return {
        _id: template._id,
        name: template.name,
        templateType: template.templateType as TemplateSelectorType,
        description: template.aboutText,
        admin: template.admin,
        category: template.templateCategory,
        documentManagers: template.documentManagers,
      };
    });
    setConvertedTemplates(convertedChecklistTemplates);
    onTemplateSelected(convertedChecklistTemplates?.find((t) => t.name === "Tom mal"));
  };

  const onTemplateSelected = (template: ITemplate) => {
    setSelectedTemplate(template);

    if (template == null) {
      return;
    }

    if (template.name === "Tom mal") {
      setCheckTypes({
        check1: {
          active: true,
          name: "EK",
        },
        check2: {
          active: false,
          name: "SK",
        },
        check3: {
          active: false,
          name: "DK",
        },
      });
      return;
    }
    const selectedTemplate = checklistTemplates.find((t) => t._id === template._id);
    if (selectedTemplate != null) {
      setCheckTypes(selectedTemplate.checkTypes);
    }
  };

  const selectedPnsIds = useMemo(() => {
    const id = pnsItems.find((item) => item.props.pnsId === selectedPnsFilterItem)?._id;
    return id != null ? [id] : [];
  }, [selectedPnsFilterItem, pnsItems]);

  const PNS_COLLAPSE_LOCAL_STORAGE_KEY = `${project._id}_checklist_pnsCollapse`;

  const pnsIsOpen = useCallback(() => {
    const valInLocalStorage = localStorage.getItem(PNS_COLLAPSE_LOCAL_STORAGE_KEY);
    if (valInLocalStorage === null) {
      return true;
    }
    return valInLocalStorage === "true";
  }, [PNS_COLLAPSE_LOCAL_STORAGE_KEY]);

  const createChecklistFromExcel = async (data: string[][]) => {
    try {
      const checklistRows = formatImportedExcelDta(data);
      const isMcTemplate = project?.maconomy?.projectNo === 100;
      const resp = await createChecklistFromExcelMutation({
        params: {
          projectId: project._id,
        },
        body: {
          checklistImportRows: checklistRows,
          status: selectedStatus.value as IChecklistStatus,
          name: selectedTitle,
          pns: selectedPns,
          checkTypes,
          templateType: isMcTemplate === true ? "MC" : "",
        },
      });
      if ("data" in resp && resp.data != null) {
        goToChecklist(resp.data._id);
      }
    } catch (error) {
      alert("Feil ved konvertering av excel");
      console.error("Error creating checklist from excel", error);
    }
  };

  const isError = createChecklistFromExcelError === true || checklistsError === true;
  const isLoading = createChecklistFromExcelLoading === true || checklistsLoading === true;
  return (
    <>
      {isError && <FailedAlert />}
      {isLoading === true && <MCLoader loading />}
      {isError === false && isLoading === false && (
        <>
          <div className="d-flex flex-column w-100 h-100 overflow-auto">
            <>
              <div>
                <Button className="mb-1 mt-2 ml-2" color="success" onClick={() => setModalOpen(true)}>
                  <i className="fa fa-plus" />
                </Button>
                <ChecklistDbFilterBar columns={columns} />
                <div className="d-flex flex-grow-1"></div>
              </div>
              <div
                className="d-flex w-100"
                style={{
                  flex: 1,
                  minHeight: 0,
                }}
              >
                {pnsItems?.length > 0 && (
                  <HierarchyTreeSelector
                    style={{
                      minWidth: "200px",
                      maxWidth: "300px",
                      background: "#ededed",
                    }}
                    nodeList={pnsItems}
                    idProp={"pnsId"}
                    titleProp={"title"}
                    onSelectionChange={(node) => dispatch(setSelectedPnsFilterItemInRedux(node[0]?.props?.pnsId))}
                    selectedNodeIds={selectedPnsIds}
                    isCollapsed={!pnsIsOpen()}
                    localStorageCollapseKey={PNS_COLLAPSE_LOCAL_STORAGE_KEY}
                  />
                )}

                <div className="checklistTable ag-theme-quartz h-100 w-100">
                  <AgGridReact
                    rowData={projectChecklists}
                    defaultColDef={{
                      suppressHeaderMenuButton: true,
                      autoHeight: true,
                      minWidth: 100,
                      flex: 1,
                      cellStyle: {
                        whiteSpace: "normal",
                        lineHeight: "1.5",
                        wordBreak: "break-word",
                      },
                    }}
                    pagination={true}
                    paginationPageSize={100}
                    rowHeight={48}
                    columnDefs={getChecklistColumns(isTemplateProject, pnsItems)}
                    suppressClickEdit={true}
                    rowSelection="single"
                    animateRows={true}
                    suppressContextMenu={true}
                    onRowClicked={(e) => {
                      e.api.deselectAll();
                      goToChecklist(e.data._id);
                    }}
                    localeText={AG_GRID_LOCALE_NOR}
                  />
                </div>
              </div>
            </>
          </div>
          {modalIsOpen === true && (
            <TemplateSelector
              bottomDescriptionSection={
                <TemplateResponsibleSection documentManagers={selectedTemplate?.documentManagers} />
              }
              onTemplateSelected={onTemplateSelected}
              templates={convertedTemplates}
              header={"Ny sjekkliste"}
              app={{
                name: MCApplicationsUrlEnum.CHECKLIST,
                icon: "appIcons/reportGenerator.svg",
                iconColor: "#6791a1",
              }}
              onCreate={(template) => {
                createNew(template._id);
              }}
              closeModal={toggle}
              isTitleEmpty={selectedTitle === "" ? true : false}
              isTemplateProject={false}
              descriptionAndAdmin={true}
              showInstanceButton={false}
              preSelectedTemplate={convertedTemplates.find((template) => template.name === "Tom mal")}
              disableOpprettButton={selectedStatus == null || selectedTitle === ""}
              excelImport={{
                enabled: true,
                zodValidation: CHECKLIST_ZOD_EXCEL_VALIDATION,
                onCreateFromExcel: createChecklistFromExcel,
                specificationTable: CHECKLIST_EXCEL_SPECIFICATION_TABLE,
              }}
            >
              <div className="d-flex flex-column flex-grow-1 pl-10 pr-10 pt-3" style={{ background: "FBFBFB" }}>
                <h4 className="m-2 d-flex justify-content-center align-items-center bold">NY SJEKKLISTE </h4>
                <McTitle title="Navn*">
                  <McInput
                    required
                    autoFocus
                    onBlur={(t) => setSelectedTitle(t.target.value)}
                    value={selectedTitle}
                    placeholder={"Navn*"}
                  />
                </McTitle>
                <McTitle title="Status*">
                  <McDropdown
                    type="text"
                    options={STATUSES}
                    onChange={(_, status) => setSelectedStatus(status)}
                    currentValue={selectedStatus}
                    defaultTitle="Velg status"
                    displayKey="label"
                  />
                </McTitle>
                <McTitle title="PNS">
                  <McTypeahead
                    id={""}
                    clearButton
                    labelKey="comboLabel"
                    options={pnsItems}
                    onChange={(items) => {
                      if (items[0] == null || items[0]?.props?.pnsId == null) {
                        setSelectedPns("");
                        return;
                      }

                      setSelectedPns(items[0].props.pnsId);
                    }}
                    allowNew
                  />
                </McTitle>
                {checkTypes != null && (
                  <McTitle title="Roller">
                    <CheckTypesSelector inputIsEditable={false} checkTypes={checkTypes} onChange={setCheckTypes} />
                  </McTitle>
                )}
              </div>
            </TemplateSelector>
          )}
        </>
      )}
    </>
  );
};

export default ChecklistBoardSelector;

export const CustomSearchInput = (props) => {
  let input;

  const handleClick = () => {
    props.onSearch(input.value);
  };

  return (
    <input
      placeholder="Søk..."
      className="mcinput mr-2"
      style={{ maxWidth: "15rem" }}
      ref={(n) => (input = n)}
      type="text"
      onChange={handleClick}
    />
  );
};

export const CHECKLIST_EXCEL_SPECIFICATION_TABLE: ISpecificationTable = {
  columns: [
    {
      dataField: "nr",
      text: "Nr",
    },
    {
      dataField: "description",
      text: "Spørsmål",
    },
  ],
  data: [
    {
      nr: "1",
      description: "RIGG OG DRIFT",
    },
    {
      nr: "1.1",
      description: "Rigging av byggeplass?",
    },
    {
      nr: "1.2",
      description: "....",
    },
    {
      nr: "2",
      description: "FORBEREDELSE - TERRENGARBEIDER",
    },
    {
      nr: "2.1",
      description: "....",
    },
  ],
};

export const CHECKLIST_ZOD_EXCEL_VALIDATION = [increasingNumberValidator, emptyValidator];

export const formatImportedExcelDta = (data: string[][]): [number, string][] => {
  const converted = data
    .filter((row) => {
      return row.length > 0;
    })
    .map((row) => {
      return {
        nr: row[0].toString().trim(),
        description: row[1].toString().trim(),
      };
    });
  converted.shift();
  const checklistRows: [number, string][] = converted.map((row) => [Number(row.nr), String(row.description)]);
  return checklistRows;
};

const TemplateResponsibleSection = ({ documentManagers }: { documentManagers: IDocumentManagers }) => {
  if (
    documentManagers == null ||
    (documentManagers?.preparedBy == null &&
      documentManagers?.approvedBy == null &&
      documentManagers?.checkedBy == null)
  ) {
    return null;
  }

  return (
    <div className="d-flex flex-column">
      {documentManagers?.preparedBy && <DocManagerAvatar user={documentManagers.preparedBy} title="Utarbeidet av" />}
      {documentManagers?.approvedBy && <DocManagerAvatar user={documentManagers.approvedBy} title="Godkjent av" />}
      {documentManagers?.checkedBy && <DocManagerAvatar user={documentManagers.checkedBy} title="Kontrollert av" />}
    </div>
  );
};

const DocManagerAvatar = ({ user, title }: { user: IMinUser; title: string }) => {
  return (
    <div className="d-flex align-items-center mt-1">
      <Avatar height="25px" id={user._id} />
      <div className="d-flex flex-column">
        <div
          style={{
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          {title}
        </div>
        <div
          style={{
            fontWeight: "bold",
            fontSize: "12px",
            color: "#6c757d",
          }}
        >
          {user.name}
        </div>
      </div>
    </div>
  );
};
