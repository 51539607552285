export const beregnetVariansLufthastighetsMaaling = (
  maaleresultater: number[],
  gjennomsnittligHastighet: number,
): number => {
  if (isNaN(gjennomsnittligHastighet) || gjennomsnittligHastighet == null || gjennomsnittligHastighet === 0) {
    return null;
  } else {
    return (
      (maaleresultater?.map((resultat) => Math.pow(resultat, 2)).reduce((a, b) => a + b, 0) -
        maaleresultater?.length * Math.pow(gjennomsnittligHastighet, 2)) /
      (maaleresultater?.length - 1)
    );
  }
};
