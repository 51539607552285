import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { IExternalParticipant } from "mc-shared/zod/meetingSchema";
import React, { useState } from "react";
import { Button, Card, CardBody, Modal, ModalBody, ModalHeader } from "reactstrap";
import { IMeeting } from "../../../../../../../types/meeting.types";
import { AG_GRID_LOCALE_NOR } from "../../../../../agGridLocale";
import { Avatar, Checkbox, FailedAlert, VSpace } from "../../../../../components";
import MemberCard from "../../../../../components/MemberCard/MemberCard";
import TypeAheadUser from "../../../../../components/TypeAheadUser/TypeAheadUser";
import ViewEditTable from "../../../../../components/ViewEditTable/ViewEditTable";
import { useAppSelector } from "../../../../../hooks";
import { IMemberWithDomain } from "../../../admin/members/MembersTable";
import {
  useAddMeetingPartiipantMutation,
  useRemoveMeetingParticipantMutation,
  useUpdateExternalParticipantsMutation,
  useUpdateMeetingParticipantMutation,
} from "../../meetingRTK";

const Participants: React.FC<{ singleMeeting: IMeeting.MeetingPopulated }> = ({ singleMeeting }) => {
  const [selectedParticipant, setSelectedParticipant] = useState<IMeeting.ParticipantsPopulated>(null);

  const [addMeetingParticipant, { isError: addParticipantError }] = useAddMeetingPartiipantMutation();
  const [updateMeetingParticipant, { isError: updateParticipantError }] = useUpdateMeetingParticipantMutation();
  const [removeMeetingParticipant, { isError: removeParticipantError }] = useRemoveMeetingParticipantMutation();
  const [addExternalMeetingParticipant, { isError: addExternalParticipantError }] =
    useUpdateExternalParticipantsMutation();

  const isError =
    addParticipantError || updateParticipantError || removeParticipantError || addExternalParticipantError;

  const project = useAppSelector((state) => state.adminReducer.project);
  const participants = singleMeeting.participants;
  const externalParticipants = singleMeeting.externalParticipants;

  const newItemTemplateObject: {
    name: string;
    email: string;
    discipline: string;
    isPresent: boolean;
  } = {
    name: "",
    email: "",
    discipline: "",
    isPresent: false,
  };

  const columnsInternalParticipants: ColDef<any>[] = [
    {
      headerName: "",
      field: "user._id",
      maxWidth: 50,
      minWidth: 50,
      cellClass: "px-0",
      cellRenderer: (params) => (
        <Button
          size="sm"
          color="outline-danger"
          onClick={() =>
            removeMeetingParticipant({
              projectId: project._id,
              meetingId: singleMeeting._id,
              participantId: params.node.data._id,
            })
          }
        >
          <i className="fa fa-trash fa-fw" />
        </Button>
      ),
    },
    {
      field: "user.name",
      headerName: "Navn",
      flex: 1,
      cellRenderer: (params) => (
        <div className="d-flex align-items-center p-0 m-0">
          <div
            className="d-flex align-items-center pointer hoverText"
            onClick={() => setSelectedParticipant(params.node.data)}
          >
            {params.node.data.user && <Avatar id={params.node.data.user._id} />}
            {params.value}
          </div>
        </div>
      ),
    },
    {
      field: "discipline",
      headerName: "Disiplin",
      width: 100,
    },
    {
      width: 100,
      field: "isPresent",
      headerName: "Deltok",
      cellRenderer: (params) => (
        <Checkbox
          isChecked={params.value}
          setChecked={() =>
            updateMeetingParticipant({
              projectId: project._id,
              meetingId: singleMeeting._id,
              participantId: params.node.data._id,
              attribute: "isPresent",
              value: !params.value,
            })
          }
        />
      ),
    },
  ];

  const columnsExternalParticipants: ColDef<IExternalParticipant>[] = [
    {
      field: "name",
      headerName: "Navn",
      editable: true,
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      editable: true,
      flex: 1,
    },
    {
      field: "discipline",
      headerName: "Disiplin",
      editable: true,
      width: 100,
    },
    {
      width: 100,
      field: "isPresent",
      headerName: "Deltok",
      cellRenderer: (params) => {
        return (
          <Checkbox
            isChecked={params.value}
            setChecked={() => toggleIsPresentForExternalParticipant(params.node.data._id as string, !params.value)}
          />
        );
      },
      cellRendererParams: {
        editModeHidden: true,
      },
    },
  ];

  const filteredMembers = (members, addedUsers) => {
    const filtered = members.filter((el1) => {
      return addedUsers.every((el2) => el1.user._id !== el2.user?._id);
    });
    return filtered;
  };

  const toggleIsPresentForExternalParticipant = (participantId: string, value: boolean) => {
    const updatedExternalParticipants = externalParticipants.map((participant) => {
      if (participant._id === participantId) {
        return {
          ...participant,
          isPresent: value,
        };
      }

      return participant;
    });

    onSaveExternalUsers(updatedExternalParticipants);
  };

  const onSaveExternalUsers = (externalParticipantsConverted: IExternalParticipant[]): IExternalParticipant[] => {
    addExternalMeetingParticipant({
      projectId: project._id,
      meetingId: singleMeeting._id,
      externalParticipants: externalParticipantsConverted,
    });

    return externalParticipantsConverted;
  };

  const defaultColDef = {
    suppressHeaderMenuButton: true,
    autoHeight: true,
  };

  return (
    <div className="d-flex flex-column p-2 w-50">
      {isError && <FailedAlert />}
      {selectedParticipant != null && (
        <Modal isOpen toggle={() => setSelectedParticipant(null)} style={{ maxWidth: "550px" }}>
          <ModalHeader toggle={() => setSelectedParticipant(null)}>
            <div className="modal-title">Prosjektmedlem</div>
          </ModalHeader>
          <ModalBody>
            <MemberCard
              member={(project.members as IMemberWithDomain[])?.find(
                (member) => member.user._id === selectedParticipant?.user?._id,
              )}
              projectId={project._id}
            />
          </ModalBody>
        </Modal>
      )}
      <Card>
        <CardBody className="d-flex flex-column">
          <div className="w-50">
            <TypeAheadUser
              title={"Skriv inn bruker for å legge til..."}
              members={filteredMembers(project?.members, participants)}
              setAssignToUser={(userId: string) =>
                addMeetingParticipant({ projectId: project._id, meetingId: singleMeeting._id, userId })
              }
              clearAfterSelection
              focusOnOpen={false}
            />
          </div>
          <VSpace />
          <div className="ag-theme-alpine" id="myGrid">
            <AgGridReact
              domLayout="autoHeight"
              rowData={participants || []}
              getRowId={(p) => p.data._id}
              columnDefs={columnsInternalParticipants}
              defaultColDef={defaultColDef}
              animateRows={false}
              suppressContextMenu={true}
              localeText={AG_GRID_LOCALE_NOR}
              suppressRowVirtualisation={true}
            />
          </div>
          <VSpace />
          <h5 className="mt-5">Deltagere utenfor MCApps</h5>
          <ViewEditTable
            data={externalParticipants}
            columns={columnsExternalParticipants}
            onSave={onSaveExternalUsers}
            newItemTemplateObject={newItemTemplateObject}
            canEdit={true}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default Participants;
