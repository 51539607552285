import React, { useEffect, useState } from "react";
import { IRiskoBoard } from "../../../../../../types/global.types";
import { Checkbox, EditSaveAbort, HSpace, VSpace } from "../../../../components/";
import { ToastMessagesEnums } from "../../../../frontendConstants";
import { IBootstrapTableColumnConfig } from "../../../../types";
import { doUpdateRisikBoardDisabledColumns } from "../risikoAPI";
import { useAppDispatch } from "../../../../hooks";
import { setShowSuccessMessageThunk } from "../../../../global/globalSlice";

const DisabledColumns: React.FC<{
  allColumns: IBootstrapTableColumnConfig[];
  disabledColumns: string[];
  projectId: string;
  risikoBoard: IRiskoBoard;
  updateBoards: any;
}> = ({ allColumns, projectId, risikoBoard, updateBoards }) => {
  const [editMode, setEditMode] = useState(false);
  const dispatch = useAppDispatch();
  const [viewColumns, setViewColumns] = useState(getViewColumns(allColumns, risikoBoard.disabledColumns));
  const [editedColumns, setEditedColumns] = useState(risikoBoard.disabledColumns);

  useEffect(() => {
    setEditedColumns(risikoBoard.disabledColumns);
    setViewColumns(getViewColumns(allColumns, risikoBoard.disabledColumns));
  }, [risikoBoard.disabledColumns]);

  const save = async () => {
    await doUpdateRisikBoardDisabledColumns(projectId, risikoBoard._id, editedColumns);
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.UPDATED));
    setEditMode(false);
    updateBoards();
  };

  const editCb = (bool: boolean) => {
    setEditMode(bool);
    setViewColumns(getViewColumns(allColumns, risikoBoard.disabledColumns));
    if (bool === false) {
      setEditedColumns(risikoBoard.disabledColumns);
    }
  };

  return (
    <div className="mb-2">
      <h5>Deaktiver kolonner</h5>
      <div className="d-flex">
        <EditSaveAbort editMode={editMode} saveCb={save} editModeCb={editCb} />
        <HSpace />

        {editMode === false && <span>{viewColumns.map((col) => ` ${col}`)}</span>}
        {editMode && (
          <EditDisableColumns
            editedColumns={editedColumns}
            setEditedColumns={setEditedColumns}
            allColumns={allColumns}
          />
        )}
      </div>
    </div>
  );
};

export default DisabledColumns;

const EditDisableColumns: React.FC<{
  allColumns: IBootstrapTableColumnConfig[];
  editedColumns: string[];
  setEditedColumns: any;
}> = ({ allColumns, editedColumns, setEditedColumns }) => {
  const _setColumn = (dataField: string) => {
    const index = editedColumns.indexOf(dataField);
    let _editedColumns = [...editedColumns];
    if (index === -1) {
      _editedColumns.push(dataField);
    } else {
      _editedColumns.splice(index, 1);
    }
    setEditedColumns(_editedColumns);
  };

  return (
    <div>
      {allColumns
        .filter((col) => col.text !== "")
        .map((c) => {
          return (
            <div key={c.dataField}>
              <VSpace />
              <Checkbox
                mode="cross"
                text={c.text}
                key={c.dataField}
                disabled={false}
                isChecked={editedColumns.indexOf(c.dataField) !== -1}
                setChecked={() => _setColumn(c.dataField)}
              />
            </div>
          );
        })}
    </div>
  );
};

const getViewColumns = (fullColumns: IBootstrapTableColumnConfig[], columns: string[]): string[] => {
  return columns.reduce((acc, dataField) => {
    const col = fullColumns.find((fullCol) => fullCol.dataField === dataField);
    if (col != null) {
      acc.push(col.text);
    }
    return acc;
  }, []);
};
