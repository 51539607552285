import moment from "moment";
import React, { useMemo } from "react";
import { IIssue } from "../../../../../../../types/global.types";
import { useAppSelector } from "../../../../../hooks";
import { IssueSortValueEnum } from "../../issueConstants";
import GanttChart from "./GanttChart/GanttChart";

const IssueGant: React.FC<{ issues: IIssue[] }> = ({ issues }) => {
  const dateRange = useAppSelector((state) => state.issueReducerNew.gant.dateRange);
  const sortValue = useAppSelector((state) => state.issueReducerNew.gant.sortValue);
  const allFilters = useAppSelector((state) => state.issueReducerNew.allFilters);

  const sortedIssues = useMemo(
    () =>
      [...issues].sort((a, b) => {
        if (sortValue === IssueSortValueEnum.ID) {
          return a.id - b.id;
        } else {
          const aValue = sortValue.split(".").reduce((o, i) => o[i], a);
          const bValue = sortValue.split(".").reduce((o, i) => o[i], b);
          return moment(aValue).diff(moment(bValue));
        }
      }),
    [allFilters, sortValue, issues?.length],
  );

  const taskEnabled = allFilters?.find((filter) => filter.dataField === "taskOrIssue")?.bool ?? false;

  return <GanttChart items={sortedIssues} taskEnabled={taskEnabled} dateRange={dateRange} sortValue={sortValue} />;
};

export default IssueGant;
