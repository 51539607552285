import { IChecklistStatus, ICheckTypes } from "mc-shared/zod/checklistSchema";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Alert, Button, ButtonGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { IChecklistPopulated } from "../../../../../types/checklist.types";
import { IMinUser, IValueLabel } from "../../../../../types/global.types";
import { IRegister } from "../../../../../types/register.types";
import { FailedAlert } from "../../../components";
import { HSpace } from "../../../components/";
import ExcelImportValidatorWithSpecTable from "../../../components/ExcelImportValidator/ExcelImportValidatorWithSpecTable";
import McDropdownGrouped, { IMcDropdownGrouped } from "../../../components/McDropdownGrouped/McDropdownGrouped";
import RegisterBoards from "../../../components/RegisterBoards/RegisterBoards";
import { useAppSelector } from "../../../hooks";
import { getProjectIdFromUrl } from "../../utils";
import {
  CHECKLIST_EXCEL_SPECIFICATION_TABLE,
  CHECKLIST_ZOD_EXCEL_VALIDATION,
  formatImportedExcelDta,
} from "./ChecklistBoardSelector";
import { TypeOfChecklistEnum } from "./ChecklistConstants";
import { doAddChecklist } from "./checklistAPI";
import { useCreateChecklistFromImportedExcelMutation } from "./checklistRTK";
import { CHECKLIST_STATUS_DROPDOWN_VALUES, ChecklistStatusEnum } from "./constants";

const CHECK_TYPES: ICheckTypes = {
  check1: { active: true, name: "EK" },
  check2: { active: false, name: "SK" },
  check3: { active: false, name: "DK" },
  check4: { active: false, name: "" },
  check5: { active: false, name: "" },
  check6: { active: false, name: "" },
  check7: { active: false, name: "" },
  check8: { active: false, name: "" },
  check9: { active: false, name: "" },
  check10: { active: false, name: "" },
  check11: { active: false, name: "" },
  check12: { active: false, name: "" },
  check13: { active: false, name: "" },
  check14: { active: false, name: "" },
  check15: { active: false, name: "" },
};

const ChecklistTemplateBoardSelector: React.FC = () => {
  let history = useHistory();
  const project = useAppSelector((state) => state.adminReducer.project);
  const projectChecklistTemplates = useAppSelector((state) => state.checklistReducer.projectChecklistTemplates);
  const [modalIsOpen, setModalOpen] = useState<boolean>(false);
  const [checklistBoards, setchecklistBoards] = useState<IRegister.BoardPopulated[]>([]);
  const [checklistStatuses, setChecklistStatuses] = useState<IValueLabel[]>([]);
  const [activeStatus, setActiveStatus] = useState<string>(ChecklistStatusEnum.ACTIVE);

  const goToChecklist = async (checklistId: string) => {
    history.push(`/project/${project._id}/checklist/${TypeOfChecklistEnum.TEMPLATE}/${checklistId}`);
  };

  const createNewChecklist = async (
    name: string,
    pns: string,
    selectedTemplateId: string,
    selectedStatus: IChecklistStatus,
  ) => {
    //TODO: Replace with RTK
    const checklist = await doAddChecklist(
      name,
      pns,
      project._id,
      selectedTemplateId,
      selectedStatus,
      CHECK_TYPES,
      true,
      "PROJECT",
    );
    goToChecklist(checklist._id);
  };

  useEffect(() => {
    const statuses: IValueLabel[] = [
      CHECKLIST_STATUS_DROPDOWN_VALUES.active,
      CHECKLIST_STATUS_DROPDOWN_VALUES.in_development,
      CHECKLIST_STATUS_DROPDOWN_VALUES.removed,
    ];

    const boardItems: IRegister.BoardPopulated[] = projectChecklistTemplates
      .filter((checklist) => checklist.templateType !== "MC")
      .map((checklist) => {
        const boardItem: IRegister.BoardPopulated = {
          _id: checklist._id,
          access: null,
          name: checklist.name,
          project: null,
          status: checklist.status || ChecklistStatusEnum.ACTIVE,
          createdBy: checklist.createdBy as IMinUser,
          createdAt: checklist.createdAt,
        };
        return boardItem;
      });

    setChecklistStatuses(statuses);
    setchecklistBoards(boardItems);
  }, [projectChecklistTemplates]);

  return (
    <div className="w-100 p-5" style={{ maxWidth: "50%" }}>
      {modalIsOpen === true && (
        <CreateNewChecklistTemplateModal
          toggle={() => setModalOpen(false)}
          createNew={createNewChecklist}
          checklistTemplates={projectChecklistTemplates}
        />
      )}
      <RegisterBoards
        registerBoards={checklistBoards}
        onClickRegisterBoard={(checklist) => goToChecklist(checklist._id)}
        statuses={checklistStatuses}
        activeStatus={activeStatus}
        setActiveStatus={setActiveStatus}
        addNew={() => setModalOpen(true)}
      />
    </div>
  );
};

export default ChecklistTemplateBoardSelector;

const CreateNewChecklistTemplateModal: React.FC<{
  toggle: () => void;
  createNew: (name: string, pns: string, id: string, status: string) => void;
  checklistTemplates: IChecklistPopulated[];
}> = ({ toggle, createNew, checklistTemplates }) => {
  const [templateFrom, setTemplateFrom] = useState<"EXCEL" | "MC_TEMPLATE">("MC_TEMPLATE");
  const [name, setName] = useState<string>("");
  const [selectedTemplate, setSelectedTemplate] = useState<IChecklistPopulated>(null);
  const [groupedTemplates, setGroupedTemplates] = useState<IMcDropdownGrouped<IChecklistPopulated>>(null);
  const [importedFormattedExcelDataItems, setImportedFormattedExcelDataItems] = React.useState<string[][]>(null);

  const init = async () => {
    const checklistGroups: IMcDropdownGrouped<IChecklistPopulated> = {
      prosjektmaler: [],
      multiconsultmaler: [],
      annen: [],
    };

    const newEmptyTemplate: IChecklistPopulated = {
      _id: "0",
      checkTypes: null,
      createdAt: null,
      createdBy: null,
      templateType: "PROJECT",
      isTemplate: true,
      project: null,
      name: "Tom",
      tags: [],
      status: "IN_DEVELOPMENT",
      generatedFromTemplate: null,
      lastUpdatedBy: null,
      assignedTo: null,
      stampSettings: {
        yes: { color: "#00975f", icon: "check", text: "JA" },
        no: { color: "#dc3545", icon: "close", text: "NEI" },
        ir: { color: "#f8992e", icon: "ban", text: "IR" },
        na: { color: "#0000004F", icon: "circle", text: "-" },
      },
      templateCategory: "",
      includeIrOnDefaultRoles: false,
    };

    checklistGroups["annen"].push(newEmptyTemplate);

    checklistTemplates.forEach((template) => {
      if (template.status !== "ACTIVE") {
        return;
      }
      if (template.templateType === "PROJECT") {
        checklistGroups["prosjektmaler"].push(template);
      } else if (template.templateType === "MC") {
        checklistGroups["multiconsultmaler"].push(template);
      } else {
        checklistGroups["annen"].push(template);
      }
    });

    setGroupedTemplates(checklistGroups);
  };

  useEffect(() => {
    init();
  }, [checklistTemplates]);

  const [
    createChecklistFromExcelMutation,
    { isLoading: createChecklistFromExcelLoading, isError: createChecklistFromExcelError },
  ] = useCreateChecklistFromImportedExcelMutation();

  const isOppretteDisabled = () => {
    if (name === "") {
      return true;
    }
    if (createChecklistFromExcelLoading) {
      return true;
    }
    if (templateFrom === "MC_TEMPLATE" && selectedTemplate == null) {
      return true;
    }
    if (
      templateFrom === "EXCEL" &&
      (importedFormattedExcelDataItems == null || importedFormattedExcelDataItems?.length === 0)
    ) {
      return true;
    }
  };

  const history = useHistory();
  const projectId = getProjectIdFromUrl(history.location.pathname);

  const goToChecklist = async (checklistId: string) => {
    history.push(`/project/${projectId}/checklist/${TypeOfChecklistEnum.TEMPLATE}/${checklistId}`);
  };

  const opprett = async () => {
    if (templateFrom === "MC_TEMPLATE") {
      createNew(name, "", selectedTemplate._id, "ACTIVE");
    }
    if (templateFrom === "EXCEL") {
      const checklistRows = formatImportedExcelDta(importedFormattedExcelDataItems);
      const resp = await createChecklistFromExcelMutation({
        params: {
          projectId: projectId,
        },
        body: {
          checklistImportRows: checklistRows,
          status: "ACTIVE",
          name,
          pns: "",
          checkTypes: CHECK_TYPES,
          templateType: "PROJECT",
        },
      });
      if ("data" in resp && resp.data != null) {
        goToChecklist(resp.data._id);
      }
    }
  };

  return (
    <Modal isOpen toggle={toggle}>
      <ModalHeader className="gray-dark" modalclassname="mc-orange-text" toggle={toggle}>
        Ny prosjektmal
      </ModalHeader>
      <ModalBody>
        {createChecklistFromExcelError && <FailedAlert />}
        <div className="w-100">
          <div className="d-flex align-items-center mb-2">
            <div style={{ width: "20%" }}>Navn</div>
            <div style={{ width: "80%" }}>
              <Input placeholder="Angi navn på mal" value={name} onChange={(e) => setName(e.target.value)} />
            </div>
          </div>
          <div className="d-flex align-items-center mb-2">
            <div style={{ width: "20%" }}>Fra</div>
            <div style={{ width: "80%" }}>
              <ButtonGroup className="w-100">
                <Button
                  className="w-50"
                  active={templateFrom === "MC_TEMPLATE"}
                  onClick={() => setTemplateFrom("MC_TEMPLATE")}
                >
                  Multiconsult-mal
                </Button>
                <Button className="w-50" active={templateFrom === "EXCEL"} onClick={() => setTemplateFrom("EXCEL")}>
                  Excel
                </Button>
              </ButtonGroup>
            </div>
          </div>
          {templateFrom === "MC_TEMPLATE" && (
            <div className="d-flex align-items-center mb-2">
              <div style={{ width: "20%" }}>Start fra</div>
              <div style={{ width: "80%" }}>
                <McDropdownGrouped
                  className="w-100"
                  displayKey={"name"}
                  groups={groupedTemplates}
                  selectionChanged={(template) => setSelectedTemplate(template)}
                  enableSearch={true}
                  color="mc-blue"
                  showTooltip={true}
                  selectedItem={selectedTemplate}
                >
                  Velg sjekklistemal
                </McDropdownGrouped>
              </div>
            </div>
          )}
          {templateFrom === "EXCEL" && (
            <div className="d-flex align-items-center mb-2">
              <div style={{ width: "20%" }}>Start fra</div>
              <div style={{ width: "80%" }}>
                <ExcelImportValidatorWithSpecTable
                  onValidated={(res) => setImportedFormattedExcelDataItems(res.sourceData as string[][])}
                  validators={CHECKLIST_ZOD_EXCEL_VALIDATION}
                  specificationTable={CHECKLIST_EXCEL_SPECIFICATION_TABLE}
                  skipRows={1}
                  closeAfterSuccessfulImport
                />
              </div>
            </div>
          )}
          {importedFormattedExcelDataItems != null && (
            <Alert color="success">{importedFormattedExcelDataItems?.length} rader inleste</Alert>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button disabled={isOppretteDisabled()} color="success" onClick={() => opprett()}>
          Opprett
        </Button>
        <HSpace />
        <Button color="secondary" onClick={toggle}>
          Avbryt
        </Button>
      </ModalFooter>
    </Modal>
  );
};
