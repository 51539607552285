import React from "react";
import { IMinUser, IProject } from "../../../../../../../types/global.types";
import { IMCBIMAPI } from "../../../../../../../types/mcbim.types";
import { FailedAlert, MCLoader } from "../../../../../components";
import { useAppSelector } from "../../../../../hooks";
import { useUpdateBimJobIntervalMutation, useUpdateBimMaxVersionCountMutation } from "../McBimRTK";
import { isMcBimAdmin } from "../mcBimSlice";
import McBimScheduler from "./McBimScheduler";
import McBimVersionCount from "./McBimVersionCount";

const McBimConfigPage: React.FC<{ project: IProject; mcBimProject: IMCBIMAPI.Project }> = ({
  project,
  mcBimProject,
}) => {
  const user = useAppSelector((state) => state.globalReducer.user);
  const disabled = isMcBimAdmin(project.mcbim.admins as IMinUser[], user?._id) === false;

  const [updateBimJobInterval, { isLoading: updateBimJobIntervalLoading, error: updateBimJobIntervalError }] =
    useUpdateBimJobIntervalMutation();

  const [updateMcBimVersionCount, { isLoading: updateMcBimVersionCountLoading, error: updateMcBimVersionCountError }] =
    useUpdateBimMaxVersionCountMutation();

  const isLoading = updateBimJobIntervalLoading === true || updateMcBimVersionCountLoading === true;

  const isError = updateBimJobIntervalError != null || updateMcBimVersionCountError != null;

  return (
    <div className="w-100 h-100 p-2">
      {isLoading === true && <MCLoader loading />}
      {isError === true && <FailedAlert />}
      {isLoading !== true && isError === false && (
        <>
          <McBimScheduler
            mcBimProject={mcBimProject}
            disabled={disabled}
            onSave={(jobEnabled, jobInterval) =>
              updateBimJobInterval({ projectId: project._id, jobEnabled, jobInterval })
            }
          />
          <McBimVersionCount
            mcBimProject={mcBimProject}
            disabled={disabled}
            onSave={(versionCount) =>
              updateMcBimVersionCount({ projectId: project._id, maxVersionCount: versionCount })
            }
          />
        </>
      )}
    </div>
  );
};

export default McBimConfigPage;
