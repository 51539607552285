import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Alert, Button, Progress } from "reactstrap";
import { IChecklist } from "../../../../../../types/checklist.types";
import { AG_GRID_LOCALE_NOR } from "../../../../agGridLocale";
import { DisplayDueDate, FailedAlert } from "../../../../components";
import { useAppSelector } from "../../../../hooks";
import { StatusBadgeRenderer } from "../../checklist/ChecklistColums";
import { useGetActiveProjectChecklistsCreatedByLoggedInUserQuery } from "../../checklist/checklistRTK";
import { CHECKLIST_STATES_OPTIONS, CHECKLIST_STATES_OPTIONS_COLOR } from "../../checklist/constants";

const UserCreatedChecklistsInProject: React.FC<{}> = () => {
  const project = useAppSelector((state) => state.adminReducer.project);
  const user = useAppSelector((state) => state.globalReducer.user);

  const { data: checklistsCreatedByUser = [], isError } = useGetActiveProjectChecklistsCreatedByLoggedInUserQuery({
    projectId: project?._id,
    userId: user._id,
  });

  const history = useHistory();

  const goToChecklist = async (checklist: IChecklist) => {
    if (checklist != null) {
      history.push(`/project/${checklist.project}/checklist/instance/${checklist._id}`);
    }
  };

  return (
    <>
      {isError === true && <FailedAlert errorMessage="Kunne ikke laste inn dine sjekklister" />}
      {isError === false && checklistsCreatedByUser.length === 0 && (
        <div className="flex-fill overflow-auto px-1">
          <Alert color="success mt-5">
            <i className="fa fa-check fa-fw" />
            Du har ingen sjekklister i dette prosjektet.{" "}
            <Link to={`/project/${project?._id}/checklist/instance`}>Gå til sjekklisteappen </Link> for å se alle
            sjekklister.
          </Alert>
        </div>
      )}
      {checklistsCreatedByUser.length > 0 && (
        <div className="ag-theme-quartz h-100 w-100">
          <AgGridReact
            rowData={checklistsCreatedByUser}
            columnDefs={createActiveChecklistsColumns(goToChecklist)}
            domLayout={"autoHeight"}
            rowSelection="single"
            animateRows={true}
            suppressContextMenu={true}
            localeText={AG_GRID_LOCALE_NOR}
            defaultColDef={{ suppressHeaderMenuButton: true }}
          />
        </div>
      )}
    </>
  );
};

export default UserCreatedChecklistsInProject;

const createActiveChecklistsColumns = (goToChecklist: (item: IChecklist) => void) => {
  const columns: ColDef<IChecklist>[] = [
    {
      field: "status",
      headerName: "Status",
      valueGetter: (params) => {
        const status = CHECKLIST_STATES_OPTIONS_COLOR.find(
          (option) =>
            CHECKLIST_STATES_OPTIONS.find((opt) => opt.value === params.data.status).displayValue === option.value,
        );
        return status;
      },
      valueSetter: (params) => {
        return params.newValue.displayValue;
      },
      width: 150,
      cellRenderer: StatusBadgeRenderer,
    },
    {
      field: "name",
      headerName: "Sjekklister",
      cellRenderer: (params) => {
        return (
          <>
            <Button className="p-0 m-0" onClick={() => goToChecklist(params.data)} color="link">
              {params.value}
            </Button>
            <p>{params.data.checklistsCreatedByUser?.name}</p>
          </>
        );
      },
      filter: "agTextColumnFilter",
      flex: 1,
    },
    {
      field: "dueDate",
      headerName: "Frist",
      cellRenderer: (params) => {
        if (moment(params.value).isValid() === false || params.value == null || !params.value) {
          return "-";
        }
        return <DisplayDueDate dueDate={params.value} isComplete={params.data.status === "DELIVERED"} />;
      },
      filter: "agDateColumnFilter",
    },
    {
      field: "progress",
      headerName: "Fremskritt",
      cellRenderer: (params) => {
        return (
          <div className="w-100">
            <Progress multi className="thin-progress">
              {params?.value?.map((progress, index) => {
                return (
                  <Progress bar style={{ backgroundColor: progress.color }} value={progress.percentage} key={index} />
                );
              })}
            </Progress>
          </div>
        );
      },
    },
  ];
  return columns;
};
