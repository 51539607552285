import { AccessLevelsEnum } from "mc-shared/enums/enums";
import { ITitleAndDescription } from "mc-shared/zod/issueSchema";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Alert, ListGroupItem } from "reactstrap";
import { IRegister } from "../../../../../types/register.types";
import { apiSlice } from "../../../apiSlice";
import { FailedAlert, McInput, MCLoader } from "../../../components";
import TemplateSelector, { ITemplate } from "../../../components/TemplateSelector/TemplateSelector";
import { MCApplicationsUrlEnum } from "../../../frontendConstants";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  useCreateIssueBoardFromExcelMutation,
  useCreateIssueBoardMutation,
  useCreateIssueBoardTemplateMutation,
  useGetIssueBoardsQuery,
  useGetIssueBoardTemplatesQuery,
} from "./IssueBoardRTK";
import IssueBoardsTable from "./IssueBoardsTable";
import { ISSUE_BOARD_STATUSES, IssueBoardStatusEnums } from "./issueConstants";
import { ISpecificationTable } from "../../../types";
import { emptyValidator } from "../../../components/ExcelImportValidator/ExcelImportValidator.utils";

const IssueBoards: React.FC = () => {
  let history = useHistory();
  const [modalIsOpen, setModalOpen] = useState(false);
  const [activeStatus, setActiveStatus] = useState<IssueBoardStatusEnums>(IssueBoardStatusEnums.ACTIVE);
  const [selectedTitle, setSelectedTitle] = useState<string>("");

  const dispatch = useAppDispatch();

  const goToBoard = async (board: any) => {
    dispatch(apiSlice.util.invalidateTags(["IssueBoard"]));
    history.push(`/project/${project._id}/issues/${board._id}`);
  };

  const project = useAppSelector((state) => state.adminReducer.project);

  const {
    data: issueBoards = [],
    isLoading: issueBoardsLoading,
    error: boardError,
  } = useGetIssueBoardsQuery({
    projectId: project._id,
  });

  const {
    data: issueBoardTemplates = [],
    isLoading: templateLoading,
    error: templateError,
  } = useGetIssueBoardTemplatesQuery({
    projectId: project._id,
  });

  const convertedIssueBoardsToTemplates: ITemplate[] = issueBoards
    .filter((board) => board.status !== "DELETED")
    .map((board) => {
      return {
        _id: board._id,
        name: board.name,
        templateType: "INSTANCE",
      };
    });

  // TODO: Category is hardcoded to BREEAM, should be dynamic when we get more categories
  const convertedIssueBoardTemplates: ITemplate[] = issueBoardTemplates.map((template) => {
    return {
      _id: template._id,
      name: template.name,
      templateType: "MC",
      category: template.category,
      description: template.description,
      admin: template.template.admin,
    };
  });

  const combinedTemplates = [...convertedIssueBoardsToTemplates, ...convertedIssueBoardTemplates];

  const [createBoard, { isLoading: createBoardLoading, error: createBoardError }] = useCreateIssueBoardMutation();
  const [createTemplate, { isLoading: createTemplateLoading, error: createTemplateError }] =
    useCreateIssueBoardTemplateMutation();

  const [createBoardFromExcel, { isLoading: createBoardFromExcelLoading, error: createBoardFromExcelError }] =
    useCreateIssueBoardFromExcelMutation();

  const createNew = async (boardIdToCopySettingsFrom?: string) => {
    const createdBoard = await createBoard({
      projectId: project._id,
      name: selectedTitle,
      boardIdToCopySettingsFrom: boardIdToCopySettingsFrom,
    }).unwrap();
    setModalOpen(false);
    goToBoard(createdBoard);
  };

  const createNewIssueTemplate = async () => {
    const createdTemplate = await createTemplate({
      projectId: project._id,
      name: selectedTitle,
    }).unwrap();
    setModalOpen(false);
    goToBoard(createdTemplate);
  };

  const toggle = () => {
    setSelectedTitle("");
    setModalOpen(!modalIsOpen);
  };

  const createIssueBoardFromExcelImport = async (titleAndDescriptions: ITitleAndDescription[]) => {
    const createdBoard = await createBoardFromExcel({
      projectId: project._id,
      body: {
        nameOfBoard: selectedTitle,
        titleAndDescriptions: titleAndDescriptions,
      },
    }).unwrap();
    setModalOpen(false);
    goToBoard(createdBoard);
  };

  const isLoading =
    issueBoardsLoading || templateLoading || createBoardLoading || createTemplateLoading || createBoardFromExcelLoading;
  const error = boardError || templateError || createBoardError || createTemplateError || createBoardFromExcelError;

  const isTemplateProject = project.maconomy.projectNo === 100;

  const excelSpecificationTable: ISpecificationTable = {
    columns: [
      {
        dataField: "title",
        text: "Tittel",
      },
      {
        dataField: "description",
        text: "Beskrivelse",
      },
    ],
    data: [
      {
        title: "LCC beregning av valgt materiale..",
        description: "ARK må lage en LC....",
      },
      {
        title: "Dører til tekniske rom",
        description: "Se vedlagt oversikt over teknisk stand....",
      },
      {
        title: "...",
        description: "....",
      },
    ],
  };

  const excelImport = {
    enabled: true,
    specificationTable: excelSpecificationTable,
    onCreateFromExcel: (data) => {
      const titleAndDescriptions = data.map((row) => {
        return {
          title: row[0],
          description: row[1],
        };
      });
      createIssueBoardFromExcelImport(titleAndDescriptions);
    },
    zodValidation: [emptyValidator, emptyValidator],
  };

  return (
    <div className="d-flex flex-column w-100 h-100 pt-5 gray-100 overflow-auto">
      {isLoading === true && <MCLoader loading={isLoading} />}
      {error != null && <FailedAlert />}
      {isLoading === false && (
        <>
          {modalIsOpen === true && (
            <TemplateSelector
              templates={isTemplateProject === false ? combinedTemplates : convertedIssueBoardTemplates}
              header={isTemplateProject ? "Ny mal" : "Nytt register"}
              app={{
                name: MCApplicationsUrlEnum.ISSUE,
                icon: "appIcons/saks-sm.svg",
                iconColor: "#659180",
              }}
              onCreate={(template) => {
                createNew(template._id);
              }}
              createNewTemplate={createNewIssueTemplate}
              closeModal={toggle}
              isTemplateProject={isTemplateProject}
              isTitleEmpty={selectedTitle === "" ? true : false}
              descriptionAndAdmin={true}
              showInstanceButton={isTemplateProject === false ? true : false}
              showProjectButton={false}
              preSelectedTemplate={
                isTemplateProject === false
                  ? combinedTemplates.find((template) => template.name === "Standard")
                  : convertedIssueBoardTemplates.find((template) => template.name === "Standard")
              }
              disableOpprettButton={selectedTitle === ""}
              excelImport={excelImport}
            >
              <div className="d-flex flex-column flex-grow-1 pl-10 pr-10 pt-3" style={{ background: "FBFBFB" }}>
                <h4 className="m-2 d-flex justify-content-center align-items-center bold">NYTT REGISTER </h4>
                <div className="grid-container">
                  <h5 className="d-flex align-items-center mb-0">Navn*</h5>
                  <McInput
                    required
                    autoFocus
                    onBlur={(t) => setSelectedTitle(t.target.value)}
                    value={selectedTitle}
                    placeholder={isTemplateProject ? "Angi navnet på malen" : "Angi navnet på registeret"}
                  />
                </div>
              </div>
            </TemplateSelector>
          )}
          {project?.maconomy?.projectNo === 100 && (
            <Alert color="danger">
              Alle registre i dette prosjektet vil være tilgjengelige som maler i andre prosjekter!
            </Alert>
          )}
          <IssueBoardsTable
            loading={issueBoardsLoading}
            registerBoards={issueBoards as unknown as IRegister.BoardPopulated[]}
            onClickRegisterBoard={goToBoard}
            statuses={ISSUE_BOARD_STATUSES}
            activeStatus={activeStatus}
            setActiveStatus={(status: IssueBoardStatusEnums) => setActiveStatus(status)}
            addNew={toggle}
          />
        </>
      )}
    </div>
  );
};

export default IssueBoards;

export const IssueBoardItem: React.FC<{
  board: IRegister.BoardPopulated;
  goToBoard: (board: IRegister.BoardPopulated) => void;
}> = ({ board, goToBoard }) => {
  return (
    <ListGroupItem
      href="#"
      action
      className="d-flex align-content-center"
      style={{ cursor: "pointer", height: "100px" }}
      onClick={() => goToBoard(board)}
    >
      <i className="fa fa-briefcase fa-3x fa-fw mt-2 gray-300-text" />
      <div className="d-flex flex-column p-0 m-0">
        <h4 className="mt-2 mb-0">{board.name}</h4>
        <p className="p-0 m-0 d-flex">
          Tilgang:
          {board.access.level === AccessLevelsEnum.PROJECT ? (
            "Full"
          ) : (
            <p className="text-danger p-0 m-0 ml-1">
              <i className="fa fa-lock fa-fw" />
              Begrenset
            </p>
          )}
        </p>
      </div>
    </ListGroupItem>
  );
};
