import { LuftmengdeMaalingerEnum } from "mc-shared/zod/report/GK/gkReportConstants";
import { IGKLuftmengdemaalingResultater, IGKRapport } from "mc-shared/zod/report/GK/gkReportSchema";
import React, { useEffect } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../../../types/global.types";
import { McDropdown, McInput } from "../../../../../../../components";
import ReportDocxImageContainer from "../../../../ReportBoard/ReportDocxImageContainer";
import AnemometerTest from "./AnemometerTest";
import BalometerTest from "./BalometerTest";
import TilluftskanalTest from "./TilluftskanalTest";
import { beregnetLuftmengdeFraLuftskifter, beregnetVolum } from "./luftmengdeUtils";

const LuftmengdeTestIRom: React.FC<{
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
  data: IGKLuftmengdemaalingResultater;
}> = ({ data, onUpdateEditData }) => {
  const TILGJENGELIGELUFTMENGDEMAALINGER: LuftmengdeMaalingerEnum[] = [
    LuftmengdeMaalingerEnum.ANEMOMETERMAALING,
    LuftmengdeMaalingerEnum.TILLUFTSKANALMAALING,
    LuftmengdeMaalingerEnum.BALOMETERMAALING,
  ];

  useEffect(() => {
    utdataBeregninger();
  }, [data.inndata.romdimensjoner.romareal, data.inndata.romdimensjoner.romhoeyde]);

  const utdataBeregninger = () => {
    const romvolum = beregnetVolum(data.inndata.romdimensjoner.romareal, data.inndata.romdimensjoner.romhoeyde);
    const luftmengdeFraLuftskifter = beregnetLuftmengdeFraLuftskifter(romvolum, data.inndata.prosjektertLuftskifte);
    const utdata = {
      romvolum: romvolum,
      luftmengdeFraLuftskifter: luftmengdeFraLuftskifter,
    };
    onUpdateEditData("testResultater.luftmengdeMaalingIRom.utdata.felles", utdata);
  };

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Luftmengdemåling i rom</CardTitle>
        <b>Hvilken type luftmengdemåling skal utføres?</b>
        <div className="w-25">
          <McDropdown
            currentValue={data.inndata.valgtLuftmengdeMaalingsType}
            onChange={(_, item: LuftmengdeMaalingerEnum) =>
              onUpdateEditData("testResultater.luftmengdeMaalingIRom.inndata.valgtLuftmengdeMaalingsType", item)
            }
            defaultTitle="Hvilken type luftmengemåling skal utføres?"
            options={TILGJENGELIGELUFTMENGDEMAALINGER}
          />
        </div>
        {data.inndata.valgtLuftmengdeMaalingsType && (
          <>
            <div className="d-flex flex-column mt-4">
              <b>
                Fyll inn prosjektert luftmengde &#40;m<sup>3</sup>/t&#41;:
              </b>
              <McInput
                value={data.inndata.prosjektertLuftmengde}
                normalizeNumbers={true}
                type="text"
                onBlur={(e) =>
                  onUpdateEditData(
                    "testResultater.luftmengdeMaalingIRom.inndata.prosjektertLuftmengde",
                    Number(e.target.value),
                  )
                }
                className="w-25"
              />
            </div>
            <div className="d-flex flex-column mt-4">
              <b>Fyll inn prosjektert luftskifte i rommet &#40;ACH&#41;:</b>
              <McInput
                value={data.inndata.prosjektertLuftskifte}
                normalizeNumbers={true}
                type="text"
                onBlur={(e) =>
                  onUpdateEditData(
                    "testResultater.luftmengdeMaalingIRom.inndata.prosjektertLuftskifte",
                    Number(e.target.value),
                  )
                }
                className="w-25"
              />
            </div>
            <div className="d-flex flex-column mt-4">
              <b>
                Fyll inn romareal &#40;m<sup>2</sup>&#41; og romhøyde &#40;m&#41;:
              </b>
              <McInput
                placeholder="Romareal"
                value={data.inndata.romdimensjoner.romareal}
                normalizeNumbers={true}
                type="text"
                onBlur={(e) =>
                  onUpdateEditData(
                    "testResultater.luftmengdeMaalingIRom.inndata.romdimensjoner.romareal",
                    Number(e.target.value),
                  )
                }
                className="w-25"
              />
              <McInput
                placeholder="Romhøyde"
                value={data.inndata.romdimensjoner.romhoeyde}
                normalizeNumbers={true}
                type="text"
                onBlur={(e) =>
                  onUpdateEditData(
                    "testResultater.luftmengdeMaalingIRom.inndata.romdimensjoner.romhoeyde",
                    Number(e.target.value),
                  )
                }
                className="w-25 mt-1"
              />
            </div>
            <div className="d-flex justify-content-between mt-1">
              <p className="mb-0">
                Romvolum &#40;m<sup>3</sup>&#41; ={" "}
                <b>
                  {beregnetVolum(data.inndata.romdimensjoner.romareal, data.inndata.romdimensjoner.romhoeyde)?.toFixed(
                    2,
                  ) ?? ""}
                </b>
              </p>
              <p className="mb-0">Formel: &#40;V = A * H&#41;</p>
            </div>
            <hr className="mt-0" />
            <div className="d-flex justify-content-between mt-1">
              <p className="mb-0">
                Luftskifter til luftmengde &#40;m<sup>3</sup>/t&#41; ={" "}
                <b>
                  {beregnetLuftmengdeFraLuftskifter(
                    data.utdata.felles.romvolum,
                    data.inndata.prosjektertLuftskifte,
                  )?.toFixed(2) ?? ""}
                </b>
              </p>
              <p className="mb-0">Formel: &#40;Q = V * ACH&#41;</p>
            </div>
            <hr className="mt-0" />
          </>
        )}
        {data.inndata.valgtLuftmengdeMaalingsType === LuftmengdeMaalingerEnum.ANEMOMETERMAALING && (
          <AnemometerTest onUpdateEditData={onUpdateEditData} data={data} />
        )}
        {data.inndata.valgtLuftmengdeMaalingsType === LuftmengdeMaalingerEnum.TILLUFTSKANALMAALING && (
          <TilluftskanalTest onUpdateEditData={onUpdateEditData} data={data} />
        )}
        {data.inndata.valgtLuftmengdeMaalingsType === LuftmengdeMaalingerEnum.BALOMETERMAALING && (
          <BalometerTest onUpdateEditData={onUpdateEditData} data={data} />
        )}
        {data.inndata.valgtLuftmengdeMaalingsType && (
          <div className="mt-4">
            <ReportDocxImageContainer docxImageTag="luftmengdeMaalingIRom" title="Målepunkter i rommet" />
            <div className="w-50">
              <b>Kommentar:</b>
              <McInput
                value={data.inndata.kommentar}
                onChange={(e) =>
                  onUpdateEditData("testResultater.luftmengdeMaalingIRom.inndata.kommentar", e.target.value)
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default LuftmengdeTestIRom;
