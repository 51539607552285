import React from "react";
import { IViewItem } from "../../app/routes/orgChart/types";
import { DropdownSelectorDeprecated } from "..";

const OrgChartViewBar: React.FC<{
  activeView: string;
  arrWithViews: string[];
  setActiveView: (text: string) => void;
}> = ({ activeView, arrWithViews, setActiveView }) => {
  const [viewItems, setViewItems] = React.useState<IViewItem[]>();

  React.useEffect(() => {
    init();
  }, [arrWithViews?.length]);

  const init = () => {
    const _viewItems = arrWithViews.map((item) => {
      return {
        displayValue: item,
      };
    });
    setViewItems(_viewItems);
  };

  return (
    <>
      {viewItems != null && (
        <DropdownSelectorDeprecated
          icon="filter"
          selectText={` ${activeView == null ? "Vis detaljer" : `${activeView}`}`}
          widthInPx={150}
          color="secondary"
          activeItem={viewItems.find((item) => item.displayValue === activeView)}
          setItem={(item: IViewItem) => setActiveView(item?.displayValue)}
          items={viewItems}
          displayKey="displayValue"
        />
      )}
    </>
  );
};

export default OrgChartViewBar;
