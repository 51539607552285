import React, { useEffect } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { doSetJoinTeamsToTrue } from "../app/routes/admin/restQueries";

const JoinTeamsModal: React.FC<{ toggle: any }> = ({ toggle }) => {
  const joinChannel = async () => {
    await doSetJoinTeamsToTrue;
    window.open(
      "https://teams.microsoft.com/l/channel/19%3a160b1c8d39394b65b06f619a179f0599%40thread.skype/Allm%25C",
      "_blank",
    );
  };

  useEffect(() => {
    async function fetchData() {
      await doSetJoinTeamsToTrue();
    }
    fetchData();
  }, []);

  return (
    <Modal isOpen>
      <ModalHeader toggle={toggle}>Vil du bli med i MCApps Teams-kanalen? </ModalHeader>
      <ModalBody>
        Her kan du stille spørsmål, komme med ideer og få oppdaterte nyheter om utviklingen i MCApps.
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Lukk
        </Button>
        <Button className="btn btn-primary" color="primary" target="_blank" onClick={() => joinChannel()}>
          <i className="fa fa-windows fa-fw" />
          Bli med på kanalen
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default JoinTeamsModal;
