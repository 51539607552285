import React from "react";
import { Prompt } from "react-router-dom";
import { Alert, Button } from "reactstrap";
import McBimBim360FileHandler from "./McBimBim360FileHandler";
import { FailedAlert, MCLoader, VSpace } from "../../../../../components";
import { IMCBIMAPI } from "../../../../../../../types/mcbim.types";
import { IProject } from "../../../../../../../types/global.types";
import { selectedFilesAndFilesSavedInMcBimIsEqual } from "../mcBimSlice";
import { useUpdateMcBimIfcFilesMutation } from "../McBimRTK";
import { useAppSelector } from "../../../../../hooks";

const McBimFilePage: React.FC<{ mcbimProject: IMCBIMAPI.Project; project: IProject }> = ({ mcbimProject, project }) => {
  const user = useAppSelector((state) => state.globalReducer.user);

  const selectedFiles = useAppSelector((state) => state.mcBimReducer.selectedIfcFiles);

  const [updateFiles, { isLoading: updateFilesLoading, error: updateFilesError }] = useUpdateMcBimIfcFilesMutation();

  const filesAreEqual = selectedFilesAndFilesSavedInMcBimIsEqual(selectedFiles, mcbimProject.ifcFiles);

  React.useEffect(() => {
    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      if (filesAreEqual === false) {
        e.preventDefault();
        e.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", onBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, [filesAreEqual]);

  const onLocationChange = () => {
    return "You have unsaved changes, are you sure you want to leave?";
  };

  const _updateFiles = () => {
    const _files = selectedFiles.map((file) => {
      return {
        fileStorageId: file.fileStorageId,
        fileName: file.fileName,
        filePath: file.filePath,
      };
    });
    updateFiles({ projectId: project._id, ifcFiles: _files });
  };

  return (
    <div className="p-1" style={{ height: "calc(100% - 100px)" }}>
      {updateFilesLoading && <MCLoader loading />}
      {updateFilesError && <FailedAlert />}
      <Prompt when={filesAreEqual === false} message={onLocationChange} />
      {selectedFiles?.length === 0 && mcbimProject.ifcFiles?.length === 0 && (
        <Alert color="info">Ingen filer er lagret for innlasting i MCBIM ennå. Klikk nedenfor for å legge til</Alert>
      )}
      {(selectedFiles?.length > 0 || mcbimProject.ifcFiles?.length > 0) && (
        <>
          <div className="d-flex align-items-center">
            {filesAreEqual === false && (
              <Button color="success" onClick={_updateFiles}>
                <i className="fa fa-save fa-fw" />
                Lagre til MCBIM
              </Button>
            )}
            <div className="flex-fill" />
            {mcbimProject.ifcFiles?.length > 0 && (
              <h4 className="text-success">
                <i className="fa fa-check-circle fa-fw" />
                {mcbimProject.ifcFiles?.length} filer lagret for lasting i MCBIM
              </h4>
            )}
          </div>
          <VSpace />
          <hr />
        </>
      )}
      <div className="w-100 h-100">
        <McBimBim360FileHandler mcBimProject={mcbimProject} />
      </div>
    </div>
  );
};

export default McBimFilePage;
