import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { UncontrolledTooltip } from "reactstrap";
import { IDocxTemplate } from "../../../../types/global.types";
import { pathIsInIssueApp } from "../../app/routes/issuesApp/issueUtils";
import McDropdownBase from "../McDropdownBase/McDropdownBase";

const DocxReportDropdown: React.FC<{
  docxTemplates: IDocxTemplate[];
  generateDocx: (docxTemplate: IDocxTemplate) => void;
  openDocxModal: () => void;
  buttonColor?: string;
  buttonText?: string;
}> = ({ docxTemplates, generateDocx, openDocxModal, buttonColor, buttonText }) => {
  const [templates, setTemplates] = useState<IDocxTemplate[][]>([[], []]);

  const history = useHistory();
  const location = history.location;

  const urlIsIssueApp = pathIsInIssueApp(location.pathname);

  const init = () => {
    const checklistGroups: IDocxTemplate[][] = [[], [], []];

    docxTemplates.forEach((template) => {
      if (urlIsIssueApp === true) {
        switch (template.templateLevel) {
          case "PROJECT":
            checklistGroups[0].push(template);
            break;
          case "MULTICONSULT":
            checklistGroups[1].push(template);
            break;
          case "BOARD":
            checklistGroups[2].push(template);
            break;
          default:
            break;
        }
      } else {
        if (template.isMulticonsult !== true) {
          checklistGroups[0].push(template);
        } else {
          checklistGroups[1].push(template);
        }
      }
    });

    setTemplates(checklistGroups);
  };

  useEffect(() => {
    init();
  }, [docxTemplates]);

  return (
    <div className="d-flex">
      <McDropdownBase color={buttonColor == null ? "mc-blue" : buttonColor}>
        <McDropdownBase.Display>
          <i className="fa fa-file-word-o fa-fw" style={{ fontSize: "0.9rem" }} id="word" />
          {buttonText == null ? <span>Lag møtereferat</span> : <span>{buttonText}</span>}
        </McDropdownBase.Display>
        {templates[0]?.length > 0 && (
          <DropdownGroup templates={templates[0]} generateDocx={generateDocx} groupTitle={"Prosjekt"} />
        )}
        {templates[1]?.length > 0 && (
          <DropdownGroup templates={templates[1]} generateDocx={generateDocx} groupTitle={"Multiconsult"} />
        )}
        {templates[2]?.length > 0 && (
          <DropdownGroup templates={templates[2]} generateDocx={generateDocx} groupTitle={"Register"} />
        )}
        {(templates[0].length > 0 || templates[1].length > 0) && <McDropdownBase.Option divider key="__mc_divider" />}
        <McDropdownBase.Option
          key="__mc_settings"
          onClick={() => {
            openDocxModal();
          }}
        >
          <i className="fa fa-cog fa-fw" />
          Innstillinger
        </McDropdownBase.Option>
      </McDropdownBase>
      <UncontrolledTooltip placement="top" target="word">
        Eksporter til Word
      </UncontrolledTooltip>
    </div>
  );
};

export default DocxReportDropdown;

const DropdownGroup: React.FC<{ templates: IDocxTemplate[]; generateDocx: (t) => void; groupTitle: string }> = ({
  templates,
  generateDocx,
  groupTitle,
}) => {
  return (
    templates.length > 0 && (
      <>
        <McDropdownBase.Option header>
          <b>{groupTitle}</b>
        </McDropdownBase.Option>
        {templates.map((t) => (
          <McDropdownBase.Option
            key={t.fileName}
            selected={t?.fileName === "selectedTemplate?.fileName"}
            onClick={() => {
              generateDocx(t);
            }}
          >
            <div className="d-flex align-items-center">
              <i className="fa fa-file-word-o fa-fw mr-1" style={{ color: "#fff" }} />
              <div className="truncateHeaderText">{t.fileName}</div>
            </div>
          </McDropdownBase.Option>
        ))}
      </>
    )
  );
};
