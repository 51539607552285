import React from "react";
import { IBim360FolderContent, IFileAgGrid, IFolderAgGrid, IValueLabel } from "../../../../types/global.types";
import {
  doGetBim360FolderContent,
  doGetBim360RootFolders,
  doGetParentOfBim360Folder,
} from "../../app/routes/admin/restQueries";
import { formatShortDate } from "../../app/routes/admin/selectors";
import FileNavigatorWithSelectedFiles from "../FolderAndFiles/FileNavigatorWithSelectedFiles";
import FolderAndFileSelector from "../FolderAndFiles/FolderAndFileSelector";
import { Alert } from "reactstrap";

const BIM360AgFolderAndFileExplorer: React.FC<{
  projectId: string;
  bim360ProjectId: string;
  filterExtension?: IValueLabel;
  includeSelectedFilesHandler?: boolean;
  selectedAgFiles?: IFileAgGrid[];
  onSave: (files: IBim360FolderContent[]) => void;
  onAbort?: () => void;
  disableAbort?: boolean;
}> = ({
  projectId,
  bim360ProjectId,
  filterExtension,
  includeSelectedFilesHandler,
  selectedAgFiles,
  onSave,
  onAbort,
  disableAbort,
}) => {
  const [bim360Folders, setBim360Folders] = React.useState<IBim360FolderContent[]>(null);
  const [currentBim360Folder, setCurrentBim360Folder] = React.useState<IBim360FolderContent>();
  const [highlightedFolderBim360, setHighlighetedFolderBim360] = React.useState<IBim360FolderContent>();
  const [bim360Files, setBim360Files] = React.useState<IBim360FolderContent[]>([]);

  const [failed, setFailed] = React.useState<boolean>(false);

  React.useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      const rootFolders = await doGetBim360RootFolders(projectId, bim360ProjectId);
      const projectFilesFolder: IBim360FolderContent = rootFolders.find((folder) => folder.name === "Project Files");

      const _bim360Folders: IBim360FolderContent[] = rootFolders.map((folder) => {
        return {
          id: folder.id || folder.urn,
          attributes: {
            displayName: folder.name,
          },
        };
      });
      setBim360Folders(_bim360Folders);
      logicWhenHighlightedFolderIsChanged(formatBim360ToAgFolder(projectFilesFolder));
    } catch (error) {
      setFailed(true);
    }
  };

  const logicWhenHighlightedFolderIsChanged = async (folderAg: IFolderAgGrid) => {
    if (folderAg == null) {
      return;
    }
    setHighlighetedFolderBim360(formatAgFolderToBim360(folderAg));
    const folderContent = await doGetBim360FolderContent(projectId, bim360ProjectId, folderAg.id);
    const _files = folderContent.data.filter((content) => content.type === "items");
    setBim360Files(_files);
  };

  const goToFolder = async (folderAg: IFolderAgGrid) => {
    const isRoot = folderAg.name.toUpperCase().endsWith("ROOT-FOLDER");
    setCurrentBim360Folder(isRoot === false ? formatAgFolderToBim360(folderAg) : null);
    const folderContent = await doGetBim360FolderContent(projectId, bim360ProjectId, folderAg.id);
    const _bim360Folders = folderContent.data.filter((content) => content.type === "folders");
    setBim360Folders(_bim360Folders);
    const _files = folderContent.data.filter((content) => content.type === "items");
    setBim360Files(_files);
  };

  const goToPreviousFolder = async () => {
    const parentBim360Folder = await doGetParentOfBim360Folder(
      projectId,
      bim360ProjectId,
      currentBim360Folder.id || currentBim360Folder.urn,
    );
    setHighlighetedFolderBim360(null);
    setBim360Files(null);
    goToFolder({
      name: parentBim360Folder.attributes?.displayName,
      id: parentBim360Folder.id || parentBim360Folder.urn,
    });
  };

  const files: IFileAgGrid[] =
    bim360Files == null
      ? []
      : bim360Files.map((file) => {
          return {
            id: file.urn || file.id,
            name: file.attributes?.displayName,
            description: "",
            lastUpdated: formatShortDate(file.attributes?.lastModifiedTime),
          };
        });

  return (
    <>
      {failed === true && <Alert color="danger"></Alert>}
      {failed === false && bim360Folders == null && <div>Loading...</div>}
      {failed === false && bim360Folders != null && (
        <div className="d-flex flex-col h-100 w-100 overflow-auto">
          {includeSelectedFilesHandler !== true && (
            <FolderAndFileSelector
              folders={
                bim360Folders == null ? [] : bim360Folders?.map((folder) => formatBim360ToAgFolder(folder)) || []
              }
              files={files}
              goBack={() => goToPreviousFolder()}
              currentFolder={formatBim360ToAgFolder(currentBim360Folder)}
              getSelectedFilesCb={() => alert("")}
              onSelectActiveFolder={logicWhenHighlightedFolderIsChanged}
              onGoToNextFolder={goToFolder}
              filterExtension={filterExtension}
              highlightedFolder={formatBim360ToAgFolder(highlightedFolderBim360)}
              canGoBack={currentBim360Folder != null}
            />
          )}
          {includeSelectedFilesHandler === true && (
            <FileNavigatorWithSelectedFiles
              folders={
                bim360Folders == null ? [] : bim360Folders?.map((folder) => formatBim360ToAgFolder(folder)) || []
              }
              files={files}
              goBack={() => goToPreviousFolder()}
              currentFolder={formatBim360ToAgFolder(currentBim360Folder)}
              onSelectActiveFolder={logicWhenHighlightedFolderIsChanged}
              onGoToNextFolder={goToFolder}
              filterExtension={filterExtension}
              highlightedFolder={formatBim360ToAgFolder(highlightedFolderBim360)}
              filesAlreadySelected={selectedAgFiles}
              onAbort={onAbort}
              onSave={onSave}
              disableAbort={disableAbort}
            />
          )}
        </div>
      )}
    </>
  );
};

export default BIM360AgFolderAndFileExplorer;

const formatBim360ToAgFolder = (bim360Folder: IBim360FolderContent): IFolderAgGrid => {
  if (bim360Folder == null) {
    return null;
  }
  return {
    id: bim360Folder.urn || bim360Folder.id,
    name: bim360Folder.attributes?.name || bim360Folder.attributes?.displayName,
  };
};

const formatAgFolderToBim360 = (agFolder: IFolderAgGrid): IBim360FolderContent => {
  if (agFolder == null) {
    return null;
  }
  return {
    id: agFolder.id,
    attributes: {
      displayName: agFolder.name,
    },
  };
};
