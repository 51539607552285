import BootstrapTable from "react-bootstrap-table-next";
import React from "react";
import { IProject, IPsetAndProperty } from "../../../../../types/global.types";
import { Button, Input } from "reactstrap";
import { VSpace } from "../../../components";
import { doEnableMcBim, doGetActiveMcBimProjects } from "./systemAdminApi";
import { IMCBIMAPI } from "../../../../../types/mcbim.types";

const McBimMasterAdmin: React.FC = () => {
  const [projects, setProjects] = React.useState<IProject[]>(null);
  const [addNewFormOpen, setAddNewFormOpen] = React.useState<boolean>(false);
  const [adminEmail, setAdminEmail] = React.useState<string>("");
  const [newProjectNo, setNewProjectNo] = React.useState<string>("");
  const [psetsFilter, setPsetsFilter] = React.useState<string>("");

  const [fileAttributeExtractSettings, setFileAttributeExtractSettings] =
    React.useState<IMCBIMAPI.AllFourFileAttributeExtracSettings>();

  const [pns, setPns] = React.useState<IPsetAndProperty>({
    pset: "",
    property: "",
  });

  const [defaultProp, setDefaultProp] = React.useState<IPsetAndProperty>({
    pset: "",
    property: "",
  });

  React.useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const _projects = await doGetActiveMcBimProjects();
    setProjects(_projects);
    setAdminEmail("");
    setNewProjectNo("");
    setAddNewFormOpen(false);
  };

  const columns = [
    {
      dataField: "maconomy.projectNo",
      text: "Project nr",
    },
    {
      dataField: "maconomy.name",
      text: "Project name",
    },
    {
      dataField: "mcbim.admins",
      text: "Admins",
      formatter: (users) => {
        return (
          <div>
            {users?.map((user) => (
              <span>{user?.name}</span>
            ))}
          </div>
        );
      },
    },
  ];

  const submit = async () => {
    try {
      const _psetsFilter = psetsFilter === "" ? [] : psetsFilter.split(",");
      const resp = await doEnableMcBim(
        newProjectNo,
        adminEmail,
        fileAttributeExtractSettings,
        _psetsFilter,
        pns,
        defaultProp,
      );
      init();
    } catch (error) {
      console.log(error);
      alert("failed. Check console");
    }
  };

  return (
    <div>
      Active MCBIM projekt
      {projects != null && <BootstrapTable bootstrap4 keyField="levelId" data={projects || []} columns={columns} />}
      <Button onClick={() => setAddNewFormOpen(true)}>Add new</Button>
      {addNewFormOpen === true && (
        <div className="bg-light p-3">
          <Input
            placeholder="Enter project no"
            style={{ width: "150px" }}
            value={newProjectNo}
            type="number"
            onChange={(e) => setNewProjectNo(e.target.value)}
          />
          <VSpace />
          <Input
            placeholder="Enter admin email (short)"
            style={{ width: "150px" }}
            value={adminEmail}
            type="text"
            onChange={(e) => setAdminEmail(e.target.value)}
          />
          <VSpace />
          File attribute extract settings
          <Input
            placeholder="Enter file attribute extract settings"
            value={JSON.stringify(fileAttributeExtractSettings)}
            type="textarea"
            onChange={(e) => setFileAttributeExtractSettings(JSON.parse(e.target.value))}
          />
          <VSpace />
          PNS
          <Input
            placeholder="PNS pset)"
            value={pns.pset}
            type="text"
            onChange={(e) => setPns({ ...pns, pset: e.target.value })}
          />
          <VSpace />
          <Input
            placeholder="PNS prooperty)"
            value={pns.property}
            type="text"
            onChange={(e) => setPns({ ...pns, property: e.target.value })}
          />
          Defaut prop
          <Input
            placeholder="pset)"
            value={defaultProp.pset}
            type="text"
            onChange={(e) => setDefaultProp({ ...defaultProp, pset: e.target.value })}
          />
          <VSpace />
          <Input
            placeholder="property"
            value={defaultProp.property}
            type="text"
            onChange={(e) => setDefaultProp({ ...defaultProp, property: e.target.value })}
          />
          Psets
          <Input
            placeholder="psets (comma separated)"
            value={psetsFilter}
            type="text"
            onChange={(e) => setPsetsFilter(e.target.value)}
          />
          <Button color="success" onClick={submit}>
            Send
          </Button>
        </div>
      )}
    </div>
  );
};

export default McBimMasterAdmin;
