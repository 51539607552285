import { IGKLuftmengdemaalingResultater, IGKRapport } from "mc-shared/zod/report/GK/gkReportSchema";
import React, { useEffect } from "react";
import { Button } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../../../types/global.types";
import { HSpace, McInput } from "../../../../../../../components";
import { beregnetTotalLuftmengdeBalometer } from "./luftmengdeUtils";

const BalometerTest: React.FC<{
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
  data: IGKLuftmengdemaalingResultater;
}> = ({ data, onUpdateEditData }) => {
  useEffect(() => {
    utdataBeregninger();
  }, [data.inndata.balometerResultater.maaleresultater, data.inndata.balometerResultater.maaleresultater.length]);

  const addNewRow = () => {
    const maaleresultaterArray = data.inndata.balometerResultater.maaleresultater;
    maaleresultaterArray.push(null);

    onUpdateEditData(
      "testResultater.luftmengdeMaalingIRom.inndata.balometerResultater.maaleresultater",
      maaleresultaterArray,
    );

    return maaleresultaterArray;
  };

  const removeLastRow = () => {
    const updatedMaaleresultater = data.inndata.balometerResultater.maaleresultater;

    if (updatedMaaleresultater.length > 0) {
      updatedMaaleresultater.pop(); // Remove the last element
      onUpdateEditData(
        "testResultater.luftmengdeMaalingIRom.inndata.balometerResultater.maaleresultater",
        updatedMaaleresultater,
      );
    }
  };

  const updateMaaleresultater = (resultat, i) => {
    const maaleresultater = [...data.inndata.balometerResultater.maaleresultater];
    maaleresultater[i] = resultat;
    onUpdateEditData(
      "testResultater.luftmengdeMaalingIRom.inndata.balometerResultater.maaleresultater",
      maaleresultater,
    );
  };

  const utdataBeregninger = () => {
    const totalLuftmengde = beregnetTotalLuftmengdeBalometer(
      data.inndata.balometerResultater.maaleresultater.map((resultat) => resultat),
    );
    const utdata = {
      totalLuftmengde: totalLuftmengde,
    };
    onUpdateEditData("testResultater.luftmengdeMaalingIRom.utdata.balometerBeregninger", utdata);
  };

  return (
    <>
      <h4>Balometermåling</h4>
      <div className="mt-0 mb-4">
        <div className="d-flex flex-column mb-4">
          <b>Legg til og trekk fra ventiler:</b>
          <div className="d-flex">
            <Button color="success" onClick={addNewRow}>
              Legg til ventil
            </Button>
            <HSpace />
            <Button
              color="danger"
              onClick={removeLastRow}
              disabled={data.inndata?.balometerResultater?.maaleresultater?.length < 1}
            >
              Fjern siste ventil
            </Button>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column mt-4">
        <b>
          Totalareal tilluftsventiler &#40;m<sup>2</sup>&#41;:
        </b>
        <McInput
          value={data.inndata.balometerResultater.tilluftsventilAreal}
          normalizeNumbers={true}
          type="text"
          onBlur={(e) =>
            onUpdateEditData(
              "testResultater.luftmengdeMaalingIRom.inndata.balometerResultater.tilluftsventilAreal",
              Number(e.target.value),
            )
          }
          className="w-25"
        />
      </div>
      <div className="mt-4 d-flex flex-column">
        <b>Fyll inn filtertype &#40;-&#41;:</b>
        <McInput
          value={data.inndata.balometerResultater.filtertype}
          type="text"
          onChange={(e) =>
            onUpdateEditData(
              "testResultater.luftmengdeMaalingIRom.inndata.balometerResultater.filtertype",
              e.target.value,
            )
          }
          className="w-25"
        />
      </div>
      {data.inndata.balometerResultater?.maaleresultater?.length > 0 && (
        <>
          <div className="d-flex flex-column mt-4">
            <b>
              Fyll inn måleresultater &#40;m<sup>3</sup>/s&#41;:
            </b>
            {data.inndata.balometerResultater.maaleresultater.map((a, i) => (
              <McInput
                className="mb-1 w-25"
                key={i}
                value={a}
                normalizeNumbers={true}
                type="text"
                onBlur={(e) => updateMaaleresultater(Number(e.target.value), i)}
              />
            ))}
          </div>
          <div className="d-flex justify-content-between">
            <p className="mb-0">
              Total luftmengde &#40;m<sup>3</sup>/s&#41; ={" "}
              <b>
                {beregnetTotalLuftmengdeBalometer(
                  data.inndata.balometerResultater.maaleresultater.map((resultat) => resultat),
                )?.toFixed(2) ?? ""}
              </b>
            </p>
          </div>
          <hr className="mt-0" />
        </>
      )}
    </>
  );
};

export default BalometerTest;
