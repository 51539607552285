import { IGKResultaterPerVentil } from "mc-shared/zod/report/GK/gkReportSchema";
import React, { useEffect } from "react";
import { Button } from "reactstrap";
import { HSpace, McInput } from "../../../../../../components";
import {
  beregnetGjennomsnittligHastighet,
  beregnetLufthastighetUniformitet,
  beregnetMinimumAntallRuterIRutenett,
  beregnetStandardAvvik,
  beregnetVarians,
} from "../gkGlobalUtils";

const Luftventil: React.FC<{
  onUpdateVentilData: (updatedData: any, index: number) => void;
  data: IGKResultaterPerVentil;
  ventilIndex: number;
}> = ({ data, onUpdateVentilData, ventilIndex }) => {
  useEffect(() => {
    maaleResultaterArray();
  }, [data.beregninger.minimumAntallRuterIRutenett]);

  useEffect(() => {
    utdataBeregninger();
  }, [data.maaleresultater, data.maaleomraadeAreal]);

  const maaleResultaterArray = () => {
    const newMaaleresultaterArray: number[] = Array.from(
      { length: data.beregninger.minimumAntallRuterIRutenett },
      () => null,
    );
    const ventilnummer = ventilIndex + 1;
    const updatedData = {
      ...data,
      maaleresultater: newMaaleresultaterArray,
      ventilnummer: ventilnummer,
    };
    onUpdateVentilData(updatedData, ventilIndex);
  };

  const addNewRow = () => {
    const maaleresultaterArray = [...data.maaleresultater];

    maaleresultaterArray.push(null);

    const updatedData = {
      ...data,
      maaleresultater: maaleresultaterArray,
    };

    onUpdateVentilData(updatedData, ventilIndex);

    return maaleresultaterArray;
  };

  const removeLastRow = () => {
    const maaleresultaterArray: number[] = [...data.maaleresultater];

    if (maaleresultaterArray.length > 0) {
      maaleresultaterArray.pop(); // Remove the last element

      const updatedData = {
        ...data,
        maaleresultater: maaleresultaterArray,
      };
      onUpdateVentilData(updatedData, ventilIndex);
    }
  };

  const updateMaaleresultater = (resultat: number, i: number) => {
    const maaleresultaterArray = [...data?.maaleresultater];
    maaleresultaterArray[i] = resultat;

    const updatedData = {
      ...data,
      maaleresultater: maaleresultaterArray,
    };
    onUpdateVentilData(updatedData, ventilIndex);
  };

  const updateMaaleomraadeAreal = (resultat: number, i: number) => {
    const updatedData = {
      ...data,
      maaleomraadeAreal: resultat,
    };
    onUpdateVentilData(updatedData, ventilIndex);
  };

  const utdataBeregninger = () => {
    const minimumAntallRuterIRutenett = beregnetMinimumAntallRuterIRutenett(data?.maaleomraadeAreal);
    const gjennomsnittligHastighet = beregnetGjennomsnittligHastighet(
      data?.maaleresultater?.map((resultat) => resultat),
      data?.maaleresultater?.length,
    );
    const varians = beregnetVarians(
      data?.maaleresultater?.map((resultat) => resultat),
      gjennomsnittligHastighet,
    );
    const standardAvvik = beregnetStandardAvvik(varians);
    const lufthastighetUniformitet = beregnetLufthastighetUniformitet(gjennomsnittligHastighet, standardAvvik);
    const beregninger = {
      minimumAntallRuterIRutenett: minimumAntallRuterIRutenett,
      gjennomsnittligHastighet: gjennomsnittligHastighet,
      varians: varians,
      standardAvvik: standardAvvik,
      lufthastighetUniformitet: lufthastighetUniformitet,
    };

    const updatedData = {
      ...data,
      beregninger: beregninger,
    };
    onUpdateVentilData(updatedData, ventilIndex);
  };

  return (
    <>
      <div className="d-flex flex-column mt-0">
        <b>
          Fyll inn areal av måleområde &#40;m<sup>2</sup>&#41;:
        </b>
        <McInput
          value={data.maaleomraadeAreal}
          normalizeNumbers={true}
          type="text"
          onBlur={(e) => updateMaaleomraadeAreal(Number(e.target.value), ventilIndex)}
          className="w-25"
          maxLength={4}
        />
      </div>
      <div className="d-flex justify-content-between">
        <p className="mb-0">
          Minimum antall ruter i rutenett = <b>{beregnetMinimumAntallRuterIRutenett(data.maaleomraadeAreal) ?? ""}</b>
        </p>
        <p className="mb-0">
          Formel: &#40;N = &#40;10 * A&#41;<sup>0,5</sup>&#41;
        </p>
      </div>
      <hr className="mt-0" />
      <div className="mt-0">
        <b>Legg til flere punkter ved behov:</b>
        <div className="d-flex">
          <Button color="success" onClick={addNewRow}>
            Legg til rad
          </Button>
          <HSpace />
          <Button
            color="danger"
            onClick={removeLastRow}
            disabled={data.maaleresultater?.length <= data.beregninger?.minimumAntallRuterIRutenett}
          >
            Fjern siste rad
          </Button>
        </div>
      </div>
      {data.maaleresultater?.length > 0 && (
        <>
          <div className="mt-2 d-flex flex-column">
            <b>Fyll inn måleresultater &#40;m/s&#41;:</b>
            {data.maaleresultater.map((a, i) => (
              <McInput
                className="mb-1 w-25"
                key={i}
                value={a}
                normalizeNumbers={true}
                type="text"
                onBlur={(e) => updateMaaleresultater(Number(e.target.value), i)}
              />
            ))}
          </div>
          <div className="d-flex justify-content-between">
            <p className="mb-0">
              Gjennomsnittlig hastighet &#40;m/s&#41; =
              <b>
                {" "}
                {beregnetGjennomsnittligHastighet(
                  data.maaleresultater.map((resultat) => resultat),
                  data.maaleresultater?.length,
                )?.toFixed(3) ?? ""}
              </b>
            </p>
            <p className="mb-0">Formel: &#40;Va = &#40;SUM Vn&#41; / N&#41;</p>
          </div>
          <hr className="mt-0" />
          <div className="d-flex justify-content-between">
            <p className="mb-0">
              Varians &#40;-&#41; =
              <b>
                {" "}
                {beregnetVarians(
                  data.maaleresultater.map((resultat) => resultat),
                  data.beregninger.gjennomsnittligHastighet,
                )?.toFixed(4) ?? ""}
              </b>
            </p>
            <p className="mb-0">
              Formel: &#40;VRS = &#40;&#40;SUM Vn<sup>2</sup>&#41; - N * &#40;Va<sup>2</sup>&#41;&#41; / &#40;N -
              1&#41;&#41;
            </p>
          </div>
          <hr className="mt-0" />
          <div className="d-flex justify-content-between">
            <p className="mb-0">
              Standardavvik &#40;-&#41; =<b> {beregnetStandardAvvik(data.beregninger.varians)?.toFixed(4) ?? ""}</b>
            </p>
            <p className="mb-0">
              Formel: &#40;Sigma = VRS<sup>0,5</sup>&#41;
            </p>
          </div>
          <hr className="mt-0" />
          <div className="d-flex justify-content-between">
            <p className="mb-0">
              Lufthastighetens uniformitet &#40;-&#41; ={" "}
              <b>
                {beregnetLufthastighetUniformitet(
                  data.beregninger.gjennomsnittligHastighet,
                  data.beregninger.standardAvvik,
                )?.toFixed(2) ?? ""}
              </b>
            </p>
            <p className="mb-0">Formel: &#40;Uv = &#40;1 - &#40;Sigma / Va&#41;&#41; * 100&#41;</p>
          </div>
          <hr className="mt-0" />
        </>
      )}
    </>
  );
};

export default Luftventil;
