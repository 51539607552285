// Luftmengde i rom //
export const beregnetVolum = (romAreal: number, høyde: number): number => {
  if (romAreal == null || høyde == null || isNaN(høyde) === true || isNaN(romAreal) === true) {
    return null;
  } else {
    return romAreal * høyde;
  }
};

export const beregnetLuftmengdeFraLuftskifter = (volum: number, luftskifter: number): number => {
  if (volum == null || luftskifter == null || isNaN(volum) === true || isNaN(luftskifter) === true) {
    return null;
  } else {
    return volum * luftskifter;
  }
};

// Balometer //
export const beregnetTotalLuftmengdeBalometer = (maaleresultater: number[]): number => {
  if (
    maaleresultater.length === 0 ||
    maaleresultater.some((resultat) => isNaN(resultat)) ||
    maaleresultater.some((resultat) => resultat == null)
  ) {
    return null;
  } else {
    return maaleresultater?.map((resultat) => resultat).reduce((a, b) => a + b, 0);
  }
};

// Tilluftskanal //
export const beregnetArealPerMaalepunkt = (maaleomraadeAreal: number, antallMaalepunkter: number): number => {
  if (
    maaleomraadeAreal == null ||
    antallMaalepunkter == null ||
    isNaN(maaleomraadeAreal) === true ||
    isNaN(antallMaalepunkter) === true
  ) {
    return null;
  } else {
    return maaleomraadeAreal / antallMaalepunkter;
  }
};

export const beregnetTotalLuftmengdeTilluftskanal = (maaleresultater: number[], arealPerMaalepunkt: number): number => {
  if (
    maaleresultater.length === 0 ||
    arealPerMaalepunkt == null ||
    maaleresultater.some((resultat) => isNaN(resultat)) ||
    maaleresultater.some((resultat) => resultat == null)
  ) {
    return null;
  } else {
    return maaleresultater?.map((resultat) => resultat * arealPerMaalepunkt).reduce((a, b) => a + b, 0);
  }
};
