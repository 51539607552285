import React from "react";
import { Button } from "reactstrap";

const DeleteButton: React.FC<{ onClick: () => void; outline?: boolean }> = ({ onClick, outline }) => {
  return (
    <Button color="danger" outline={outline} onClick={() => onClick()}>
      <i className="fa fa-trash fa-fw" />
    </Button>
  );
};
export default DeleteButton;
