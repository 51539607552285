import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Modal,
  ModalBody,
  ModalHeader,
  Table,
} from "reactstrap";
import { read, utils } from "xlsx";
import { IMinUser, IOrgChart, IOrgChartItem } from "../../../../../types/global.types";
import { VSpace } from "../../../components/";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { setOrgChartInRedux } from "../../actions";
import OrgChart from "../orgChart/OrgChart";
import { convertExcelToOrgChartItems, isOrgChartLevelItemsValid } from "../orgChart/orgChartUtils";
import { doSaveProjectOrgChart } from "./restQueries";
import { formatShortDate } from "./selectors";
import { setShowSuccessMessageThunk } from "../../../global/globalSlice";

const OrgChartSettingsPage: React.FC = () => {
  const [mode, setMode] = useState<"TABLE" | "CHART">("TABLE");
  const [importModalOpen, setImportModalOpen] = useState<boolean>();
  const project = useAppSelector((state) => state.adminReducer.project);

  const [itemsNotValid, setItemsNotValid] = React.useState<boolean>(false);

  const items = project.orgChart?.items;

  React.useEffect(() => {
    if (items?.length > 0) {
      const _itemsNotValid = isOrgChartLevelItemsValid(items);

      setItemsNotValid(_itemsNotValid === false);
    }
  }, []);

  const columns = [
    {
      dataField: "_id",
      text: "Id",
      hidden: true,
    },
    {
      dataField: "level",
      text: "Level",
    },
    {
      dataField: "text",
      text: "Text",
    },

    {
      dataField: "nameOfUser",
      text: "Name of user",
    },
  ];

  return (
    <Card className="p-3 h-75 d-flex flex-column">
      {importModalOpen && <ImportOrgChartFromExcel toggle={() => setImportModalOpen(!importModalOpen)} />}
      <CardBody style={{ overflow: "auto" }} className="h-100">
        <CardTitle tag="h3">Organsisasjonskart</CardTitle>
        <CardSubtitle tag="h6" className="mb-2 text-muted">
          Innstillinger som brukes til å bygge kartet
        </CardSubtitle>
        <VSpace />
        <Button onClick={() => setImportModalOpen(true)} color="info">
          IMPORTER FRA EXCEL
        </Button>
        <VSpace />
        <span>
          {project.orgChart.lastUpdated && (project.orgChart.uploadedBy as IMinUser)?.name != null && (
            <p className="d-flex">
              Opplastet {formatShortDate(project.orgChart.lastUpdated)} av
              <span className="ml-1">{(project.orgChart.uploadedBy as IMinUser)?.name}</span>
            </p>
          )}
        </span>
        <hr />
        {items?.length > 0 && (
          <>
            {itemsNotValid === true && (
              <Alert color="danger">Inndata er ikke gyldig. Sjekk inndataene og prøv igjen.</Alert>
            )}
            <ButtonGroup>
              <Button active={mode === "CHART"} onClick={() => setMode("CHART")}>
                KART
              </Button>
              <Button active={mode === "TABLE"} onClick={() => setMode("TABLE")}>
                Tabell
              </Button>
            </ButtonGroup>
            <VSpace />
            {mode === "TABLE" && (
              <BootstrapTable keyField="_id" data={project.orgChart && project.orgChart.items} columns={columns} />
            )}
            {mode === "CHART" && (
              <OrgChart orgChart={project.orgChart} members={project.members} projectId={project._id} />
            )}
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default OrgChartSettingsPage;

const ImportOrgChartFromExcel: React.FC<{ toggle: () => void }> = ({ toggle }) => {
  const [failed, setFailed] = useState<boolean>();

  const user = useAppSelector((state) => state.globalReducer.user);
  const dispatch = useAppDispatch();

  const project = useAppSelector((state) => state.adminReducer.project);

  const handleUpload = (e) => {
    setFailed(false);
    e.preventDefault();

    const files = e.target.files,
      f = files[0];
    const reader = new FileReader();
    reader.onload = function (e) {
      const data = e.target.result;
      let readedData = read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      const dataParse = utils.sheet_to_json(ws, { header: 1 });
      const orgChartItems = convertExcelToOrgChartItems(dataParse);
      if (orgChartItems == null) {
        setFailed(true);
      } else {
        save(orgChartItems);
      }
    };
    reader.readAsBinaryString(f);
  };

  const save = async (orgChartItems: IOrgChartItem[]) => {
    const orgChart: IOrgChart = {
      items: orgChartItems,
      lastUpdated: new Date(),
      uploadedBy: user._id,
    };
    const updatedOrgChart = await doSaveProjectOrgChart(project._id, orgChart);
    dispatch(setOrgChartInRedux(updatedOrgChart));
    dispatch(setShowSuccessMessageThunk("Organisasjonsskjema importert"));
    toggle();
  };

  return (
    <Modal isOpen toggle={toggle}>
      <ModalHeader toggle={toggle}>Importer organisasjonskart fra excel</ModalHeader>
      <ModalBody>
        {failed && <Alert color="danger">Opplasting mislyktes. Filene er sannsynligvis ikke i formatet nedenfor</Alert>}
        <VSpace />
        <p>Excel-filen skal se slik ut:</p>
        <Table>
          <thead>
            <tr>
              <th>Level</th>
              <th>Text</th>
              <th>Name of user</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Prosjekteringsgruppeleder</td>
              <td>Hans Ole Haugen</td>
            </tr>
            <tr>
              <td>1.2</td>
              <td>Bygg J</td>
              <td>Bjørnar Foldøy Byberg</td>
            </tr>
            <tr>
              <td>1.2.1</td>
              <td>ARK (FK)</td>
              <td>Hallgeir Halland</td>
            </tr>
            <tr>
              <td>1.2.2</td>
              <td>RIBR</td>
              <td>Bjørn Lundby</td>
            </tr>
          </tbody>
        </Table>
        <VSpace />
        <input type="file" name="file" accept=".xls,.xlsx" onChange={(e) => handleUpload(e)} />
      </ModalBody>
    </Modal>
  );
};
