import React from "react";
import { ILabelAndCount } from "../../../../../../types/global.types";
import FlatErrorTable from "./FlatErrorTable";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import { AG_GRID_LOCALE_NOR } from "../../../../agGridLocale";

const GroupErrorTable: React.FC<{
  groupProp: string;
  labelAndCounts: ILabelAndCount[];
  deleteError: (_id: string) => void;
}> = ({ groupProp, labelAndCounts, deleteError }) => {
  const columns: ColDef[] = [
    {
      hide: true,
      field: "id",
    },
    {
      field: "label",
      flex: 1,
      headerName: groupProp,
      cellRenderer: "agGroupCellRenderer",
    },
    {
      field: "count",
      width: 100,
      headerName: "Count",
    },
    {
      hide: true,
      field: "items",
    },
  ];

  const DetailCellRenderer = (params) => {
    return (
      <div style={{ padding: "10px" }}>
        <FlatErrorTable errors={params.data.items} onDeleteError={deleteError} />
      </div>
    );
  };

  return (
    <div className="ag-theme-quartz h-100 w-100">
      <AgGridReact
        domLayout={"autoHeight"}
        rowData={labelAndCounts || []}
        defaultColDef={{
          suppressHeaderMenuButton: true,
          headerClass: "px-2 py-1",
        }}
        rowHeight={50}
        columnDefs={columns}
        suppressClickEdit={true}
        animateRows={false}
        suppressContextMenu={true}
        localeText={AG_GRID_LOCALE_NOR}
        pagination={true}
        paginationPageSize={50}
        masterDetail={true}
        detailRowAutoHeight={true}
        detailCellRenderer={DetailCellRenderer}
      />
    </div>
  );
};

export default GroupErrorTable;
