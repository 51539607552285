import { AgGridReact } from "ag-grid-react";
import { IssueViewEnum } from "mc-shared/enums/enums";
import { ITableTemplate } from "mc-shared/zod/issueSchema";
import * as R from "ramda";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { Button, ButtonGroup } from "reactstrap";
import { IBoard, IIssue, TDimension } from "../../../../../../types/global.types";
import {
  CreateNewButton,
  DimensionSelector,
  ExcelExportDropdown,
  HSpace,
  InfoCircle,
  McDropdown,
  TableTemplateSelector,
} from "../../../../components";
import ToggleTextExpand from "../../../../components/ToggleTextExpand";
import { DimensionEnums } from "../../../../frontendConstants";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { ISelectedGisMap } from "../../../../types";
import { exportExcel, getSelectedGisMapToLoadByDefault } from "../../../utils";
import { projectHasBotha2dAnd3dArcgisMap } from "../../admin/selectors";
import {
  ISSUE_DASHBOARD_LS_KEY,
  ISSUE_DASHBOARD_OPTIONS,
  ISSUE_KANBAN_GROUPING_LS_KEY,
  ISSUE_KANBAN_GROUPING_VIEWS,
  ISSUE_VIEWS,
  IssueKanbanGroupingViewEnum,
} from "../issueConstants";
import {
  loadIssueTableTemplateThunk,
  setActiveDashboardInRedux,
  setIssueActiveViewInRedux,
  setIssueSelectedGisMapInRedux,
  setKanbanGroupingViewInRedux,
} from "../issueSlice";
import { getIssueBoardIdFromUrl, getIssueRegisterNameOption, getIssueUrlPath } from "../issueUtils";
import AddIssueModal from "./AddIssueModal";
import GantDateRange from "./Gant/GantDateRange";
import GantSorterDropdown from "./Gant/GantSorterDropdown";
import IssueDocxTemplates from "./IssueDocxTemplates";
import { Column } from "ag-grid-community";

const IssueTabActionBar: React.FC<{
  items: IIssue[];
  setActiveTab: (tab: string) => void;
  issueBoard: IBoard;
  setTableByTableTemplateCb: (template: ITableTemplate) => void;
  gridRef: React.RefObject<AgGridReact>;
}> = ({ items, setActiveTab, issueBoard, setTableByTableTemplateCb, gridRef }) => {
  const [addIssueModalOpen, setAddIssueModalOpen] = useState(false);
  const history = useHistory();

  const issueBoardId = getIssueBoardIdFromUrl(history.location.pathname);

  const dispatch = useAppDispatch();

  const project = useAppSelector((state) => state.adminReducer.project);

  const showDimensionSelector = useSelector(projectHasBotha2dAnd3dArcgisMap);

  const activeView = useAppSelector((state) => state.issueReducerNew.activeView);
  const allFilters = useAppSelector((state) => state.issueReducerNew.allFilters);
  const activeTableTemplate = useAppSelector((state) => state.issueReducerNew.activeTableTemplate);
  const kanbanGroupingView = useAppSelector((state) => state.issueReducerNew.kanbanGroupingView);
  const selectedGisMap = useAppSelector((state) => state.issueReducerNew.selectedGisMap);
  const activeDashboard = useAppSelector((state) => state.issueReducerNew.activeDashboard);

  const isTaskOrIssueActive: boolean = allFilters.find((filter) => filter.dataField === "taskOrIssue")?.bool;

  React.useEffect(() => {
    if (selectedGisMap == null && project.arcgis?.groupId != null) {
      const _selectedGisMap = getSelectedGisMapToLoadByDefault(project.arcgis);

      dispatch(setIssueSelectedGisMapInRedux(_selectedGisMap));
    }
  }, []);

  const setGisDimension = (dimension: TDimension) => {
    let _selectedGisMap: ISelectedGisMap = {
      dimension: null,
      mapId: null,
    };
    if (dimension === DimensionEnums.TWODIMENSION) {
      _selectedGisMap = {
        dimension: DimensionEnums.TWODIMENSION,
        mapId: project.arcgis?.default2dMap,
      };
    }
    if (dimension === DimensionEnums.THEREEDIMENSION) {
      _selectedGisMap = {
        dimension: DimensionEnums.THEREEDIMENSION,
        mapId: project.arcgis?.default3dMap,
      };
    }
    dispatch(setIssueSelectedGisMapInRedux(_selectedGisMap));
  };

  const addIssueAndCloseModalAndOpenIssue = (issue: IIssue) => {
    setAddIssueModalOpen(false);
    history.push(getIssueUrlPath(history.location.pathname, issue._id));
  };

  const goToSettings = () => {
    setActiveTab("settings");
  };

  const resetTable = () => {
    dispatch(loadIssueTableTemplateThunk(null, issueBoardId));
    history.push(`${history.location.pathname}`);
  };

  useEffect(() => {
    if (issueBoard != null) {
      const kanbanViewSetting = localStorage.getItem(
        `${ISSUE_KANBAN_GROUPING_LS_KEY}_${issueBoardId}`,
      ) as IssueKanbanGroupingViewEnum;

      const viewMode = determineKanbanGroupingView(issueBoard, kanbanViewSetting);
      dispatch(setKanbanGroupingViewInRedux(viewMode));
    }
  }, [issueBoard]);

  const excelExportVisibleColumns = () => {
    const _columns = gridRef?.current?.api
      .getColumns()
      .filter((column) => column.isVisible() && column.getColDef().type !== "metaColumn")
      .map((col) => col.getColDef());
    let issuesFormatted = R.clone(items);
    issuesFormatted.map((issue) => {
      const tags = R.clone(issue.tags);
      // @ts-ignore
      issue.tags = tags && tags.join();
      return issue;
    });
    exportExcel(_columns, issuesFormatted, "issues", ["_id"], true);
  };

  const exportExcelAllColumns = () => {
    const _columns = gridRef?.current?.api
      .getColumns()
      .filter((column) => column.getColDef().type !== "alwaysHidden" && column.getColDef().type !== "metaColumn")
      .map((col) => col.getColDef());
    let issuesFormatted = R.clone(items);
    issuesFormatted.map((issue) => {
      const tags = R.clone(issue.tags);
      // @ts-ignore
      issue.tags = tags && tags.join();
      return issue;
    });
    const _cols = [{ field: "issueIdTaskId", headerName: "SakId-AksjonId" }, ..._columns];
    exportExcel(_cols, issuesFormatted, "issues", ["_id"], true);
  };

  const getAllColumnsWithFilter = () => {
    const filteredColumns = gridRef?.current?.api.getFilterModel();
    const filteredColumnIds = Object.keys(filteredColumns);
    const columnDefs: Column[] = gridRef?.current?.api.getColumns();

    const filteredColumnDefs = columnDefs.filter((col) => filteredColumnIds.includes(col.getColId()));

    const headerNames = filteredColumnDefs.map((col) => col.getColDef().headerName);
    return headerNames;
  };
  return (
    <div className="d-flex pt-2 px-2">
      {addIssueModalOpen && (
        <AddIssueModal
          toggle={() => setAddIssueModalOpen(false)}
          isOpen={addIssueModalOpen}
          issueBoardId={issueBoardId}
          onCreateItem={addIssueAndCloseModalAndOpenIssue}
        />
      )}
      <CreateNewButton
        onClick={() => setAddIssueModalOpen(true)}
        tooltip={`Opprett ${getIssueRegisterNameOption(issueBoard?.issueNameSetting, "singular")?.toLowerCase()}`}
        disabled={activeView === "GIS"}
      />
      {activeView === "GIS" && <InfoCircle text="Klikk på kartet for å registrere" />}
      <HSpace />
      <TableTemplateSelector
        reset={resetTable}
        goToSettings={goToSettings}
        setTableTemplate={setTableByTableTemplateCb}
        tableTemplates={issueBoard?.tableTemplates}
        selectedTemplate={activeTableTemplate}
      />
      <HSpace />
      <IssueDocxTemplates issues={items} gridRef={gridRef} />
      <div className="flex-fill" />
      {activeView === IssueViewEnum.TABLE && (
        <div className="d-flex">
          <ExcelExportDropdown
            excelExportVisibleColumns={excelExportVisibleColumns}
            exportExcelAllColumns={exportExcelAllColumns}
          />

          <ReactToPrint
            trigger={() => (
              <Button color="mc-blue" id="tabellutskrift" className="mx-2">
                <i className="fa fa-print fa-fw" />
              </Button>
            )}
            // @ts-ignore
            content={() => document.querySelector<HTMLElement>("#myGrid")}
            onBeforeGetContent={() => {
              gridRef?.current?.api.setSideBarVisible(false);
            }}
            onAfterPrint={() => {
              gridRef?.current?.api.setSideBarVisible(true);
            }}
          />
          <ToggleTextExpand currentApp={"issues"} />
        </div>
      )}
      {activeView === IssueViewEnum.KANBAN && (
        <div className="d-flex align-items-center">
          {kanbanGroupingView === IssueKanbanGroupingViewEnum.BUCKET && isTaskOrIssueActive === true && (
            <p
              className="mb-0 mr-2"
              style={{
                fontSize: "0.8rem",
                fontWeight: "bold",
                backgroundColor: "#fff3cd",
                color: "#856404",
                padding: "5px",
                border: "1px solid #ffeeba",
                borderRadius: "4px",
              }}
            >
              Aksjoner vises ikke for buckets
            </p>
          )}
          <McDropdown
            color="mc-blue"
            currentValue={ISSUE_KANBAN_GROUPING_VIEWS.find((item) => item.value === kanbanGroupingView)}
            onChange={(_, newValue) => {
              localStorage.setItem(`${ISSUE_KANBAN_GROUPING_LS_KEY}_${issueBoardId}`, newValue.value);
              dispatch(setKanbanGroupingViewInRedux(newValue.value));
            }}
            displayKey="label"
            options={ISSUE_KANBAN_GROUPING_VIEWS}
          />
        </div>
      )}
      {activeView === IssueViewEnum.GIS && selectedGisMap != null && showDimensionSelector === true && (
        <DimensionSelector
          activeDimension={selectedGisMap.dimension}
          setActiveDimension={(dimension) => setGisDimension(dimension)}
        />
      )}
      {activeView === IssueViewEnum.GANTT && (
        <>
          <GantSorterDropdown issues={items} />
          <GantDateRange issues={items} />
        </>
      )}
      {activeView === IssueViewEnum.DASHBOARD && (
        <>
          <McDropdown
            color="mc-blue"
            currentValue={activeDashboard != null ? activeDashboard : issueBoard?.dashboardSetting}
            options={ISSUE_DASHBOARD_OPTIONS}
            onChange={(_, newValue) => {
              localStorage.setItem(`${ISSUE_DASHBOARD_LS_KEY}_${issueBoardId}`, newValue);
              dispatch(setActiveDashboardInRedux(newValue));
            }}
          />
        </>
      )}
      <HSpace />
      <ViewToggler activeView={activeView} setActiveView={(view) => dispatch(setIssueActiveViewInRedux(view))} />
    </div>
  );
};

export default IssueTabActionBar;

const ViewToggler: React.FC<{ activeView: IssueViewEnum; setActiveView: (view: IssueViewEnum) => void }> = ({
  activeView,
  setActiveView,
}) => {
  return (
    <div className="d-flex">
      <ButtonGroup>
        {ISSUE_VIEWS.map((view) => (
          <Button
            className="d-flex align-items-center justify-content-center"
            style={{ minWidth: "60px" }}
            key={view.key}
            color={activeView === view.key ? "primary" : "secondary"}
            onClick={() => setActiveView(view.key)}
          >
            <i className={`fa fa-${view.icon} fa-fw`} />
            <div className="text-truncate">{view.label}</div>
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
};

const determineKanbanGroupingView = (
  board: IBoard,
  localStorageValue: IssueKanbanGroupingViewEnum,
): IssueKanbanGroupingViewEnum => {
  if (board?.useBucketsAsWorkflow === true) {
    return IssueKanbanGroupingViewEnum.BUCKET;
  }

  if (localStorageValue != null) {
    const enumValues = Object.values(IssueKanbanGroupingViewEnum);

    if (enumValues.includes(localStorageValue)) {
      return localStorageValue;
    }
  }

  if (board?.buckets.length > 1) {
    return IssueKanbanGroupingViewEnum.BUCKET;
  }

  return IssueKanbanGroupingViewEnum.STATUS;
};
