import Axios from "axios";
import * as R from "ramda";
import React, { useEffect, useState } from "react";
import { IBatchjob } from "../../../../../../../types/batchjob.types";
import { VSpace } from "../../../../../components";
import ExportSchedule from "../../../../../components/ExportSchedule/ExportSchedule";
import GeneralSettingsCard from "../../../../../components/GeneralSettingsCard/GeneralSettingsCard";
import { BATCHJOB_STATUSES } from "../../batchJobConstants";
import {
  updateBatchJobBim360ExportBim360ExportFilesThunk,
  updateBatchJobBim360ExportBim360RevitFileViewThunk,
  updateBatchJobBim360ExportFolderThunk,
  updateBatchJobGeneralThunk,
} from "../../batchJobSlice";
import Bim360OutputFolderSelector from "./Bim360OutputFolderSelector";
import Bim360RevitFileSelectorCard from "./Bim360RevitFileSelectorCard";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";

const Bim360SheetExtractorSettingsPage: React.FC = () => {
  const project = useAppSelector((state) => state.adminReducer.project);
  const activeBatchJob = useAppSelector((state) => state.batchJobReducer.activeBatchJob);
  const dispatch = useAppDispatch();
  const [generalSettingsValid, setGeneralSettingsValid] = useState<boolean>(false);
  const [fileSelectorValid, setFileSelectorValid] = useState<boolean>(false);
  const [exportScheduleValid, setExportScheduleValid] = useState<boolean>(false);
  const [folderSelectorValid, setFolderSelectorValid] = useState<boolean>(false);

  const saveBatchJob = (batchjob: IBatchjob.IBatchJobBoardPopulated, key: string, _oldValue, newValue) => {
    let batchJobToUpdate = R.assocPath(key.split("."), newValue, batchjob);
    dispatch(updateBatchJobGeneralThunk(batchJobToUpdate));
  };

  useEffect(() => {
    setGeneralSettingsValid(activeBatchJob.name !== "" && activeBatchJob.status === "ACTIVE");
  }, [activeBatchJob.name, activeBatchJob.status]);

  useEffect(() => {
    const folderId = activeBatchJob.bim360Settings.defaultBim360ExportFolder?.bim360Urn;
    const folderName = activeBatchJob.bim360Settings.defaultBim360ExportFolder?.displayName;

    setFolderSelectorValid(folderId !== "" && folderId != null && folderName != null && folderName !== "");
  }, [activeBatchJob.bim360Settings.defaultBim360ExportFolder?.bim360Urn]);

  useEffect(() => {
    const filesValid = activeBatchJob.bim360Settings.bim360RevitExportFiles.length > 0;
    setFileSelectorValid(filesValid);
  }, [activeBatchJob.bim360Settings.bim360RevitExportFiles]);

  useEffect(() => {
    if (activeBatchJob.schedule == null) {
      return;
    }

    const valid =
      activeBatchJob.schedule.jobEnabled === true &&
      activeBatchJob.schedule.jobInterval != null &&
      activeBatchJob.schedule.jobInterval !== "";

    setExportScheduleValid(valid);
  }, [activeBatchJob.schedule]);

  const startNow = async () => {
    await Axios.put(`/api/project/${project._id}/batchjob/${activeBatchJob._id}/startnow`);
  };

  return (
    <div className="w-100 p-3">
      <GeneralSettingsCard
        isValid={generalSettingsValid}
        hideAccess
        board={activeBatchJob}
        saveCb={saveBatchJob}
        allUsers={project.members.map((member) => member.user)}
        statuses={BATCHJOB_STATUSES}
        canStart={fileSelectorValid !== true || folderSelectorValid !== true}
        startNow={startNow}
        useIsValid
      />
      <VSpace />
      <ExportSchedule
        isValid={exportScheduleValid}
        scheduleOptions={activeBatchJob.schedule}
        onSave={(jobEnabled: boolean, jobInterval: string) =>
          saveBatchJob(activeBatchJob, "schedule", activeBatchJob.schedule, { jobEnabled, jobInterval })
        }
        disabled={false}
      />
      <VSpace />
      <Bim360RevitFileSelectorCard
        isValid={fileSelectorValid}
        bim360ProjectId={project.bim360.projectId}
        projectId={project._id}
        updateFiles={(files) => dispatch(updateBatchJobBim360ExportBim360ExportFilesThunk(files))}
        updateRevitViewValue={(fileId, revitViewValue) =>
          dispatch(updateBatchJobBim360ExportBim360RevitFileViewThunk(fileId, revitViewValue))
        }
      />
      <VSpace />
      <Bim360OutputFolderSelector
        isValid={folderSelectorValid}
        bim360ProjectId={project.bim360.projectId}
        projectId={project._id}
        currentFolder={{
          id: activeBatchJob.bim360Settings?.defaultBim360ExportFolder?.bim360Urn,
          name: activeBatchJob.bim360Settings?.defaultBim360ExportFolder?.displayName,
        }}
        onSave={(folder) => dispatch(updateBatchJobBim360ExportFolderThunk(folder))}
      />
      <VSpace />
      <div style={{ height: "50px" }}></div>
    </div>
  );
};

export default Bim360SheetExtractorSettingsPage;
