import React from "react";
import { IBasicItem } from "../../../types/global.types";
import { IRegister } from "../../../types/register.types";
import ConnectExistingItemModal from "../components/ConnectExistingItemModal/ConnectExistingItemModal";
import { MCApplicationsUrlEnum } from "../frontendConstants";
import { useAppSelector } from "../hooks";
import { doGetIssueBoardsDepricated } from "./routes/issuesApp/IssueBoardApiDepricated";
import { doGetBasicIssueInfo } from "./routes/issuesApp/issueAPIDepricated";

const ConnectExistingIssueToItemModalWrapper: React.FC<{
  projectId: string;
  defaultIssueBoard?: { name: string; _id: string };
  onConnected: (boardId: string, issueId: string) => void;
  closeModal: () => void;
  blockedIssueIds?: string[];
}> = ({ projectId, onConnected, closeModal, blockedIssueIds, defaultIssueBoard }) => {
  const [selectedIssueBoard, setSelectedIssuseBoard] = React.useState<IRegister.BoardPopulated>(null);
  const [issuesToSelectFrom, setIssuesToSelectFrom] = React.useState<IBasicItem[]>(null);
  const [issueBoards, setIssueBoards] = React.useState<IRegister.BoardPopulated[]>([]);
  const apps = useAppSelector((state) => state.globalReducer.apps);

  React.useEffect(() => {
    init();
  }, []);

  React.useEffect(() => {
    if (defaultIssueBoard != null && issueBoards.length > 0) {
      const board = issueBoards.find((board) => board._id === defaultIssueBoard._id);

      if (board != null) {
        issueBoardWasSelected(board);
      }
    }
  }, [defaultIssueBoard, issueBoards]);

  const init = async () => {
    const _issueBoard = await doGetIssueBoardsDepricated(projectId);
    setIssueBoards(_issueBoard.filter((board) => board.status === "ACTIVE"));
  };

  const issueBoardWasSelected = async (issueBoard: IRegister.BoardPopulated) => {
    setSelectedIssuseBoard(issueBoard);
    let _basicTypeAheadItems = await doGetBasicIssueInfo(projectId, issueBoard._id);
    _basicTypeAheadItems = _basicTypeAheadItems.reduce((acc, item) => {
      if (blockedIssueIds.indexOf(item._id) > -1) {
        return acc;
      }
      acc.push({
        displayValue: `${item.id}: ${item.title}`,
        ...item,
      });
      return acc;
    }, []);
    setIssuesToSelectFrom(_basicTypeAheadItems);
  };

  return (
    <ConnectExistingItemModal
      items={issuesToSelectFrom}
      onSelectBoard={issueBoardWasSelected}
      selectedBoard={selectedIssueBoard}
      boards={issueBoards || []}
      onConnect={onConnected}
      modalTitle="Koble sak"
      toggle={closeModal}
      currentApp={MCApplicationsUrlEnum.ISSUE}
      apps={apps}
      projectId={projectId}
    />
  );
};

export default ConnectExistingIssueToItemModalWrapper;
