import React from "react";
import { useHistory } from "react-router-dom";
import { IBatchjob } from "../../../../../types/batchjob.types";
import RegisterBoards from "../../../components/RegisterBoards/RegisterBoards";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import NewBatchJobModal from "./NewBatchJobModal";
import { doCreateBatchjob } from "./batchJobAPI";
import { BATCHJOB_STATUSES, BatchjobStatusEnum, BatchJobTemplateEnum } from "./batchJobConstants";
import { fetchAndSetBatchJobsThunk, fetchTemplatesThunk, resetBatchJobApp } from "./batchJobSlice";

const BatchJobApp: React.FC = () => {
  const [newBatchJobModalOpen, setNewBatchJobModalOpen] = React.useState<boolean>(false);
  const [activeStatus, setActiveStatus] = React.useState<BatchjobStatusEnum>(BatchjobStatusEnum.ACTIVE);

  const project = useAppSelector((state) => state.adminReducer.project);
  const batchJobs = useAppSelector((state) => state.batchJobReducer.batchJobs);
  const templates = useAppSelector((state) => state.batchJobReducer.templates);
  const convertedTemplates = templates.map((template) => {
    return {
      value: template,
      label: template,
    };
  });

  const history = useHistory();

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    init();
  }, []);

  const init = async () => {
    dispatch(resetBatchJobApp());
    await dispatch(fetchAndSetBatchJobsThunk());
    await dispatch(fetchTemplatesThunk());
  };

  const createBatchJob = async (name: string, template: BatchJobTemplateEnum) => {
    const createdBoard = await doCreateBatchjob(project?._id, name, template);
    goToTemplateBoard(createdBoard);
  };

  const goToTemplateBoard = (board: IBatchjob.IBatchJobBoardPopulated) => {
    const path = `/project/${project?._id}/batchjob/${board.template.toLowerCase()}/${board._id}/settings`;
    history.push(path);
  };

  return (
    <div className="d-flex align-items-center flex-column pt-5 h-100 w-100 gray-100 overflow-auto">
      {newBatchJobModalOpen === true && (
        <NewBatchJobModal
          templates={convertedTemplates}
          closeModal={() => setNewBatchJobModalOpen(false)}
          onCreate={createBatchJob}
        />
      )}
      <div className="w-100" style={{ maxWidth: "50%" }}>
        {batchJobs != null && (
          <RegisterBoards
            registerBoards={batchJobs}
            onClickRegisterBoard={(board: IBatchjob.IBatchJobBoardPopulated) => goToTemplateBoard(board)}
            statuses={BATCHJOB_STATUSES}
            activeStatus={activeStatus}
            setActiveStatus={(status: BatchjobStatusEnum) => setActiveStatus(status)}
            addNew={() => setNewBatchJobModalOpen(true)}
          />
        )}
      </div>
    </div>
  );
};

export default BatchJobApp;
