import { IGKRapport, IGKTemperaturmaalingResultater } from "mc-shared/zod/report/GK/gkReportSchema";
import React, { useEffect } from "react";
import { Card, CardBody, CardTitle, FormGroup, Input, Label } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../../../types/global.types";
import { McInput } from "../../../../../../../components";
import ReportDocxImageContainer from "../../../../ReportBoard/ReportDocxImageContainer";
import { beregnetDiffMellomToVerdier } from "../../gkGlobalUtils";

const Temperaturmaaling: React.FC<{
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
  data: IGKTemperaturmaalingResultater;
}> = ({ data, onUpdateEditData }) => {
  // const UTSTYRSOPTIONS = ["Termometer I", "Termometer II"];

  useEffect(() => {
    utdataBeregninger();
  }, [data.inndata.maaleresultat]);

  const utdataBeregninger = () => {
    const temperaturDiff = beregnetDiffMellomToVerdier(data.inndata.settpunktsTemp, data.inndata.maaleresultat);
    const utdata = {
      temperaturDiff: temperaturDiff,
    };
    onUpdateEditData("testResultater.temperaturmaaling.utdata", utdata);
  };

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Temperaturmåling</CardTitle>
        <div className="d-flex flex-column mt-4">
          <b>
            Fyll inn settpunktstemperaturen &#40;C<sup>o</sup>&#41;:
          </b>
          <McInput
            value={data.inndata.settpunktsTemp}
            normalizeNumbers={true}
            type="text"
            onBlur={(e) =>
              onUpdateEditData("testResultater.temperaturmaaling.inndata.settpunktsTemp", Number(e.target.value))
            }
            className="w-25"
          />
        </div>
        <div className="d-flex flex-column mt-4">
          <b>
            Fyll inn målt stabil temperatur &#40;C<sup>o</sup>&#41;:
          </b>
          <McInput
            value={data.inndata.maaleresultat}
            normalizeNumbers={true}
            type="text"
            onBlur={(e) =>
              onUpdateEditData("testResultater.temperaturmaaling.inndata.maaleresultat", Number(e.target.value))
            }
            className="w-25"
          />
        </div>
        <div className="d-flex justify-content-between">
          <p className="mb-0">
            Temperaturdifferanse &#40;C<sup>o</sup>&#41; =
            <b>
              {" "}
              {beregnetDiffMellomToVerdier(data.inndata.settpunktsTemp, data.inndata.maaleresultat)?.toFixed(2) ?? ""}
            </b>
          </p>
          <p className="mb-0">Formel: &#40;Diff T = Tm - Ts&#41;</p>
        </div>
        <hr className="mt-0" />
        <div className="d-flex flex-column">
          <b>Løpt tid til temperaturen er stabil &#40;s&#41;:</b>
          <McInput
            value={data.inndata.tidTilTempErStabil}
            normalizeNumbers={true}
            type="text"
            onBlur={(e) =>
              onUpdateEditData("testResultater.temperaturmaaling.inndata.tidTilTempErStabil", Number(e.target.value))
            }
            className="w-25"
          />
        </div>
        <div className="d-flex flex-column mt-4">
          <FormGroup className="d-flex flex-column" check>
            <Label check>
              <Input
                type="radio"
                checked={data.inndata.godkjent === true}
                onChange={() => onUpdateEditData("testResultater.temperaturmaaling.inndata.godkjent", true)}
              />
              Godkjent
            </Label>
            <Label check>
              <Input
                type="radio"
                checked={data.inndata.godkjent === false}
                onChange={() => onUpdateEditData("testResultater.temperaturmaaling.inndata.godkjent", false)}
              />
              Ikke godkjent
            </Label>
          </FormGroup>
        </div>
        <div className="w-50 mt-4">
          <div>
            <ReportDocxImageContainer docxImageTag="temperaturmaaling" title="Målepunkter i rommet" />
          </div>
          <b>Kommentar:</b>
          <McInput
            value={data.inndata.kommentar}
            onChange={(e) => onUpdateEditData("testResultater.temperaturmaaling.inndata.kommentar", e.target.value)}
            type="textarea"
            dynamicHeight={true}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default Temperaturmaaling;
