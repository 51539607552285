import React from "react";
import Switch from "react-switch";
import { Card, CardBody, CardTitle } from "reactstrap";
import { IBoard } from "../../../../../../../types/global.types";
import { useUpdateIssueBoardRisikoIsActiveMutation } from "../../IssueBoardRTK";
import { FailedAlert, VSpace } from "../../../../../components";
import RisikoProbabilitySettings from "./RisikoProbabilitySettings";
import EvaluationCategories from "./EvaluationCategories/EvaluationCategories";

const IssueRisiko: React.FC<{ issueBoard: IBoard }> = ({ issueBoard }) => {
  const [setRisikoActive, { error: activateRisikoError }] = useUpdateIssueBoardRisikoIsActiveMutation();

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h3">Risiko</CardTitle>
        {activateRisikoError != null && <FailedAlert />}
        <div className="d-flex">
          <p className="mr-2">
            <i className="fa fa-info-circle fa-fw text-info" />
            Aktiver risiko
          </p>
          <Switch
            onColor="#00a76d"
            onChange={(risikoActive) =>
              setRisikoActive({
                projectId: issueBoard.project,
                issueBoardId: issueBoard._id,
                isActive: risikoActive,
              })
            }
            checked={issueBoard.risikoSection?.isActive === true}
          />
        </div>

        {issueBoard.risikoSection?.isActive === true && (
          <>
            <VSpace />
            <RisikoProbabilitySettings issueBoard={issueBoard} />
            <VSpace />
            <EvaluationCategories issueBoard={issueBoard} />
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default IssueRisiko;
