import React, { useState } from "react";
import { Button } from "reactstrap";
import { FailedAlert } from "../../../../components";
import { useAppSelector } from "../../../../hooks";
import MeetingDbFilterBar from "../MeetingSeries/MeetingDbFilterBar";
import NewMeetingInSeriesModal from "../NewMeetingInSeriesModal";
import { useGetMeetingSeriesQuery } from "../meetingSerieRTK";
import MeetingBoardsTable, { meetingBoardColumnDefs } from "./MeetingBoardsTable";
import { sortArrayByString } from "../../../utils";

const MeetingBoards: React.FC<{}> = () => {
  const [modalIsOpen, setModalOpen] = useState<boolean>(false);
  const project = useAppSelector((state) => state.adminReducer.project);

  const { data: meetingSeries, isError: meetingSeriesError } = useGetMeetingSeriesQuery({
    projectId: project._id,
  });

  return (
    <div className="d-flex flex-column h-100 w-100 overflow-auto p-2">
      {meetingSeriesError === true && <FailedAlert errorMessage="Failed to fetch meeting series" />}
      <div>
        <Button color="success" onClick={() => setModalOpen(true)} className="mb-1">
          <i className="fa fa-plus fa-fw" />
          Nytt møte
        </Button>
        <MeetingDbFilterBar columns={meetingBoardColumnDefs} />
      </div>
      {modalIsOpen === true && (
        <NewMeetingInSeriesModal
          meetingSeries={sortArrayByString(meetingSeries, "name")}
          toggle={() => setModalOpen(false)}
        />
      )}
      <MeetingBoardsTable />
    </div>
  );
};

export default MeetingBoards;
