import React from "react";
import { IMeeting } from "../../../../../../../types/meeting.types";
import { FailedAlert } from "../../../../../components";
import MultiComment from "../../../../../components/MultiComment/MultiComment";
import { useAppSelector } from "../../../../../hooks";
import { doCreateComment } from "../../../projects/restQueries";
import {
  useAddAgendaCommentMutation,
  useDeleteAgendaCommentMutation,
  useUpdateAgendaCommentMutation,
} from "../../meetingAgendaRTK";

export const AgendaItemComments: React.FC<{
  agendaItem: IMeeting.AgendaItem;
  projectId: string;
  singleMeeting: IMeeting.MeetingPopulated;
}> = ({ agendaItem, projectId, singleMeeting }) => {
  const project = useAppSelector((state) => state.adminReducer.project);
  const [addComment, { isError: addError }] = useAddAgendaCommentMutation();
  const [deleteComment, { isError: deleteError }] = useDeleteAgendaCommentMutation();
  const [updateComment, { isError: updateError }] = useUpdateAgendaCommentMutation();

  const isError = addError || deleteError || updateError;

  const onCreateComment = async (text: string, metadata: any) => {
    const createdComment = await doCreateComment(
      project._id,
      agendaItem._id,
      "meetingAgendaItem",
      text,
      singleMeeting._id,
      metadata,
    );
    addComment({
      projectId: projectId,
      agendaId: agendaItem._id,
      commentId: createdComment._id,
    });
  };

  const user = useAppSelector((state) => state.globalReducer.user);

  return (
    <div>
      {isError === true && <FailedAlert />}
      <MultiComment
        members={project?.members ?? []}
        sortDesc
        title="Kommentarer"
        comments={agendaItem?.comments?.filter((comment) => comment.deleted !== true).reverse() ?? []}
        onCreate={(text, metadata) => onCreateComment(text, metadata)}
        onDelete={(_id) => deleteComment({ projectId, agendaId: agendaItem._id, commentId: _id })}
        onSave={(_id, text, metadata) => updateComment({ projectId, commentId: _id, text, agendaId: agendaItem._id })}
        loggedInUser={user}
        allowDelete={true}
      />
    </div>
  );
};
