import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Switch from "react-switch";
import { Button, Modal, ModalHeader } from "reactstrap";
import { IMeeting } from "../../../../../types/meeting.types";
import { FailedAlert, HSpace, McDropdown, McInput, McTitle, VSpace } from "../../../components";
import { ToastMessagesEnums } from "../../../frontendConstants";
import { useAppDispatch } from "../../../hooks";
import { getProjectIdFromUrl } from "../../utils";
import { useCopyMeetingInstanceMutation, useCreateMeetingInstanceMutation } from "./meetingRTK";
import { setShowSuccessMessageThunk } from "../../../global/globalSlice";

const NewMeetingInSeriesModal: React.FC<{
  toggle: () => void;
  meetingSerie?: IMeeting.MeetingSerie;
  meetingSeries?: IMeeting.MeetingSerie[];
}> = ({ toggle, meetingSerie, meetingSeries }) => {
  const [selectedStartDate, setSelectedStartDate] = useState<string>(new Date().toISOString());
  const [meetingTitle, setMeetingTitle] = useState<string>("");
  const [copyAgendaFromPreviousMeeting, setCopyAgendaFromPreviousMeeting] = useState<boolean>(true);
  const [selectedMeetingSerie, setSelectedMeetingSerie] = useState<IMeeting.MeetingSerie>(meetingSerie);

  const meetingSerieSelected = (meetingSerie: IMeeting.MeetingSerie) => {
    setSelectedMeetingSerie(meetingSerie);
    setMeetingTitle(meetingSerie.name + " - " + new Date().toISOString().split("T")[0].replace(/-/g, "."));
  };

  const history = useHistory();

  const projectId = getProjectIdFromUrl(history.location.pathname);

  const [createMeetingInstance, { isError: createMeetingError }] = useCreateMeetingInstanceMutation();

  const [copyMeeting, { isError: copyMeetingError }] = useCopyMeetingInstanceMutation();

  const dispatch = useAppDispatch();

  const createNewMeetingInSeries = async (
    title: string,
    dateOfMeeting: Date,
    meetingSerieId: string,
    copyAgendaFromPreviousMeeting: boolean,
  ) => {
    let resp;
    if (meetingSerieId == null) {
      resp = await createMeetingInstance({ projectId: projectId, startDate: dateOfMeeting, title });
    }

    if (meetingSerieId != null) {
      resp = await copyMeeting({
        projectId: projectId,
        meetingSerieId,
        dateOfMeeting,
        copyAgenda: copyAgendaFromPreviousMeeting,
      });
    }

    if (resp.error == null) {
      history.replace(`/project/${projectId}/meetings/${resp.data._id}`);

      dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.MEETING_ADDED));
    }

    toggle();
  };

  const isError = createMeetingError || copyMeetingError;

  return (
    <Modal isOpen={true} toggle={toggle}>
      {isError === true && <FailedAlert />}
      <ModalHeader toggle={toggle}>Nytt møte</ModalHeader>
      <div className="border">
        <div className="m-4">
          {meetingSerie != null && (
            <p>
              Nytt møte i møteserie <b>{meetingSerie.name}</b>
            </p>
          )}
          {selectedMeetingSerie == null && (
            <McTitle title="Møtetittel">
              <McInput
                autoFocus={true}
                onChange={(e) => setMeetingTitle(e.target.value.toString())}
                value={meetingTitle}
                placeholder="Tittel for enkeltmøte"
              />
            </McTitle>
          )}
          <VSpace />
          {meetingSerie == null && meetingSeries != null && (
            <>
              <McTitle title="Velg møteserie">
                <McDropdown
                  onClear={
                    selectedMeetingSerie != null
                      ? () => {
                          setSelectedMeetingSerie(null);
                          setMeetingTitle("");
                        }
                      : null
                  }
                  onChange={(_, meetingSerie) => {
                    meetingSerieSelected(meetingSerie);
                  }}
                  options={meetingSeries.filter((meetingSerie) => meetingSerie.status === "ACTIVE")}
                  displayKey="name"
                  defaultTitle="Møteserie"
                  currentValue={selectedMeetingSerie}
                />
              </McTitle>
              <VSpace />
            </>
          )}
          <McTitle title="Velg dato for møte">
            <McInput
              type="date"
              onChange={(t) => setSelectedStartDate(t.target.value.toString())}
              value={selectedStartDate}
              placeholder="Skriv inn dato for møte"
            />
          </McTitle>
          <VSpace />
          <McTitle title="Kopier agenda fra siste møte">
            <Switch
              disabled={selectedMeetingSerie == null}
              checked={selectedMeetingSerie != null && copyAgendaFromPreviousMeeting === true}
              onChange={() => setCopyAgendaFromPreviousMeeting(!copyAgendaFromPreviousMeeting)}
            />
          </McTitle>
          <div className="d-flex justify-content-end">
            <Button
              className="d-flex align-items-center"
              color="success"
              onClick={() =>
                createNewMeetingInSeries(
                  meetingTitle,
                  new Date(selectedStartDate),
                  selectedMeetingSerie?._id,
                  copyAgendaFromPreviousMeeting,
                )
              }
              disabled={
                (selectedMeetingSerie != null && selectedStartDate === "") ||
                (selectedMeetingSerie == null &&
                  (selectedStartDate === "" || meetingTitle == null || meetingTitle === ""))
              }
            >
              <i className="fa fa-floppy-o" />
              <HSpace />
              Opprett
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default NewMeetingInSeriesModal;
