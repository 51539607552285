import React from "react";
import { IScoreMatrix } from "../types";
import { CircleChart } from "./CircleChart";
import ScoreMatrix, { trancuateStr } from "./ScoreMatrix";

const RisikoCategoryMatrixAndChartSingleItem: React.FC<{
  scoreMatrix: IScoreMatrix;
  circleChart: any;
  setChartColorItem: any;
  categoryName: string;
}> = ({ scoreMatrix, circleChart, setChartColorItem, categoryName }) => {
  return (
    <div className="d-flex flex-column">
      {scoreMatrix != null && (
        <>
          <h4>{trancuateStr(scoreMatrix.name, 40).replaceAll("_", " ")}</h4>
          <div className="d-flex align-content-center p-0 m-0">
            <ScoreMatrix scoreMatrix={scoreMatrix} />
            <div style={{ width: "300px" }}>
              {circleChart != null && (
                <CircleChart
                  name={categoryName}
                  data={circleChart.chartData || []}
                  setSelectedColor={setChartColorItem}
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default RisikoCategoryMatrixAndChartSingleItem;
