import { IApp, IMember, INotification, IProject, IUser } from "../../../types/global.types";
import { IOpenedAppModal } from "../types";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, IRootState } from "../store";
import * as R from "ramda";
import { doMarkAllNotificationsAsRead, doToggleNotificationAsRead } from "../app/userApi";
import { apiSlice } from "../apiSlice";
import { resetIssueRedux } from "../app/routes/issuesApp/issueSlice";
import { logicWhenAppStarts } from "../app/actions";
import Axios from "axios";

interface ISuccessMessage {
  show: boolean;
  text: string;
}

export interface IGlobalReducer {
  user: IUser;
  successMessage: ISuccessMessage;
  failedMessage: {
    show: boolean;
    text: string;
  };
  projects: IProject[];
  isFullScreen: boolean;
  apps: IApp[];
  isProduction: boolean;
  openedAppModals: IOpenedAppModal[];
  isPrintMode: boolean;
  reloadAll: boolean;
}

const initialState: IGlobalReducer = {
  user: null,
  successMessage: {
    show: false,
    text: "",
  },
  failedMessage: {
    show: true,
    text: "",
  },
  projects: [],
  isFullScreen: false,
  apps: [],
  isProduction: null,
  openedAppModals: [],
  isPrintMode: false,
  reloadAll: false,
};

export const setShowSuccessMessageThunk =
  (text: string): AppThunk =>
  (dispatch) => {
    dispatch(
      setShowSuccessMessageInRedux({
        show: true,
        text,
      }),
    );

    setTimeout(() => {
      dispatch(setShowSuccessMessageInRedux({ show: false, text: "" }));
    }, 3000);
  };

export const addAppModalThunk =
  (openedAppModal: IOpenedAppModal): AppThunk =>
  (dispatch, getState) => {
    const { itemId } = openedAppModal;
    if (getState().globalReducer.openedAppModals.find((appModal) => appModal.itemId === itemId)) {
      console.warn("modal for id is already opened");
      return;
    }
    dispatch(addAppModalInRedux(openedAppModal));
  };

export const toggleNotificationAndReplaceNotificationThunk =
  (notificationId: string): AppThunk =>
  async (dispatch, getState) => {
    const copyOfNotifications = R.clone(getState().globalReducer.user.notifications);
    const updatedNotification = await doToggleNotificationAsRead(notificationId);
    const notificationIndex = copyOfNotifications?.findIndex((notification) => notification._id === notificationId);

    if (notificationIndex === -1) {
      console.warn("index is -1 notification");
      return;
    }
    copyOfNotifications[notificationIndex] = updatedNotification;

    console.log(copyOfNotifications[notificationIndex]);
    dispatch(setNotificationInRedux(copyOfNotifications));
  };

export const toggleAllNotificationsAsReadThunk = (): AppThunk => async (dispatch) => {
  const updatedNotifications = await doMarkAllNotificationsAsRead();
  dispatch(setNotificationInRedux(updatedNotifications));
};

export const thunkReloadAppThunk =
  (history): AppThunk =>
  async (dispatch) => {
    dispatch(apiSlice.util.resetApiState());
    dispatch(resetIssueRedux());

    dispatch(logicWhenAppStarts(history));
    dispatch(setReloadAllInRedux(true));
    setTimeout(() => {
      dispatch(setReloadAllInRedux(false));
    }, 20);
  };

export const loadProjectsThunk = (): AppThunk => async (dispatch) => {
  try {
    const projectsCall = await Axios.get("/api/user/projects");
    const projects = projectsCall.data;
    dispatch(setProjectsInRedux(projects));
  } catch (err) {
    await Axios.get("/api/logout");
    alert("Failed to load projects. Login and try again");
  }
};

export const logoutThunk = (): AppThunk => (dispatch) => {
  Axios.get(`/api/logout`);
  dispatch(setUserInRedux(null));
  window.location.href = "#/login";
};

export const globalSlice = createSlice({
  name: "checklist",
  initialState,
  reducers: {
    setUserInRedux: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    setProjectsInRedux: (state, action: PayloadAction<IProject[]>) => {
      state.projects = action.payload;
    },
    setShowSuccessMessageInRedux: (state, action: PayloadAction<ISuccessMessage>) => {
      state.successMessage = action.payload;
    },
    setAppsInRedux: (state, action: PayloadAction<IApp[]>) => {
      state.apps = action.payload;
    },
    setIsProductionInRedux: (state, action: PayloadAction<boolean>) => {
      state.isProduction = action.payload;
    },
    addAppModalInRedux: (state, action: PayloadAction<IOpenedAppModal>) => {
      state.openedAppModals.push(action.payload);
    },
    removeAppModalInRedux: (state, action: PayloadAction<string>) => {
      const index = state.openedAppModals.findIndex((modal) => modal.itemId === action.payload);
      if (index === -1) {
        return;
      }
      state.openedAppModals.splice(index, 1);
    },
    setNotificationInRedux: (state, action: PayloadAction<INotification[]>) => {
      state.user.notifications = action.payload;
    },
    setPrintModeInRedux: (state, action: PayloadAction<boolean>) => {
      state.isPrintMode = action.payload;
    },
    setReloadAllInRedux: (state, action: PayloadAction<boolean>) => {
      state.reloadAll = action.payload;
    },
  },
});

export const getEnabledApps = createSelector(
  (state: IRootState) => {
    return {
      project: state.adminReducer.project,
      allApps: state.globalReducer.apps,
      member: state.adminReducer.project.members.find((member) => member.user?._id === state.globalReducer.user._id),
    };
  },
  (projectAndApps: { project: IProject; allApps: IApp[]; member: IMember }) => {
    const project = projectAndApps.project;
    const allApps = projectAndApps.allApps;
    const member = projectAndApps.member;

    const memberGroup = member && member.group;
    if (project == null) {
      return [];
    }
    return allApps.filter((app) => {
      if (memberGroup == null) {
        return true;
      }
      const projectApp = project.apps.find((eApp) => eApp.app === app.name);

      if (projectApp == null) {
        return false;
      }
      if (projectApp.active === false) {
        return false;
      }

      if (app.enabledInProjects != null) {
        return app.enabledInProjects.indexOf(project.maconomy.projectNo) !== -1;
      }

      if (memberGroup === "INTERNAL" || memberGroup === "ADMIN") {
        return true;
      }
      if (memberGroup === "PARTNER") {
        return projectApp.access?.partner;
      }
      if (memberGroup === "CLIENT") {
        return projectApp.access?.client;
      }
      return false;
    });
  },
);

export const selectIsUserMutilconsult = createSelector(
  (state: IRootState) => state.globalReducer.user,
  (user: IUser) => user?.email.toLowerCase().endsWith("@multiconsult.no") === true,
);

export const selectUserMember = createSelector(
  (state: IRootState) => state.adminReducer.project,
  (state: IRootState) => state.globalReducer.user,
  (project: IProject, user: IUser): IMember | null => {
    if (project == null || user == null) {
      return null;
    }
    return project.members.find((member) => member.user?._id === user._id);
  },
);

export const {
  setUserInRedux,
  setShowSuccessMessageInRedux,
  setProjectsInRedux,
  setAppsInRedux,
  setIsProductionInRedux,
  addAppModalInRedux,
  removeAppModalInRedux,
  setNotificationInRedux,
  setPrintModeInRedux,
  setReloadAllInRedux,
} = globalSlice.actions;

export default globalSlice.reducer;
