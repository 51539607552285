import { IGKMaalingAvLufthastighetPunktavsugResultater, IGKRapport } from "mc-shared/zod/report/GK/gkReportSchema";
import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../../types/global.types";
import { McInput } from "../../../../../../components";
import ReportDocxImageContainer from "../../../ReportBoard/ReportDocxImageContainer";

const MaalingAvLufthastighetPunktavsug: React.FC<{
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
  data: IGKMaalingAvLufthastighetPunktavsugResultater;
}> = ({ data, onUpdateEditData }) => {
  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Måling av lufthastighet</CardTitle>
        <div className="d-flex flex-column">
          <b>Måleresultat &#40;m/s&#41;:</b>
          <McInput
            value={data.inndata.maaleresultat}
            normalizeNumbers={true}
            type="text"
            onBlur={(e) =>
              onUpdateEditData(
                "testResultater.maalingAvLufthastighetPunktavsug.inndata.maaleresultat",
                Number(e.target.value),
              )
            }
            className="w-25"
          />
        </div>
        <div className="mt-4">
          <ReportDocxImageContainer docxImageTag="maalingAvLufthastighetPunktavsug" title="Bilde med målepunkt" />
          <div className="w-50">
            <b>Kommentar:</b>
            <McInput
              value={data.inndata.kommentar}
              onChange={(e) =>
                onUpdateEditData("testResultater.maalingAvLufthastighetPunktavsug.inndata.kommentar", e.target.value)
              }
              type="textarea"
              dynamicHeight={true}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default MaalingAvLufthastighetPunktavsug;
