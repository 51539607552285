export const convertBim360UrlToFolderUrn = (path: string): string => {
  if (path.startsWith("urn")) {
    return path;
  } else {
    const data = /folders\/(.+)\/detail/.exec(path);
    return data != null ? data[1] : null;
  }
};

export const getProjectIdFromBim360Url = (path: string) => {
  if (path.startsWith("https://docs")) {
    return path.split("/")[4];
  }
  if (path.startsWith("https://acc")) {
    return path.split("/")[6];
  }
};

export const getBim360FolderFromBim360Url = (path: string) => {
  return path.split("/")[6];
};
