import { CellEditRequestEvent, ColDef, ICellRendererParams, SideBarDef, ValueFormatterParams } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-enterprise";
import { AgGridReact, CustomCellRendererProps } from "ag-grid-react";
import { debounce } from "lodash";
import { IMapOfEvaluationCategories, IRisikoProbability } from "mc-shared/zod/issueBoardSchema";
import { IOtherAttributeConfig } from "mc-shared/zod/otherAttributesSchema";
import moment from "moment";
import * as R from "ramda";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { IBoard, IBoardBucket, IIssue, IMember, IMinUser, IUser } from "../../../../../../../types/global.types";
import { AG_GRID_LOCALE_NOR } from "../../../../../agGridLocale";
import {
  Avatar,
  CopyIssueButton,
  CreateNewButton,
  DisplayDueDate,
  FailedAlert,
  McDropdownMultiUser,
  OptionBadge,
  Tags,
  VSpace,
} from "../../../../../components";
import { UserCellRenderer } from "../../../../../components/ConnectedIssues/ConnectedIssues";
import { CellEditFormatter, URLCellFormatter } from "../../../../../components/columnGlobal";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { formatShortDate } from "../../../admin/selectors";
import { useGetSumsByFormulaQuery } from "../../IssueBoardRTK";
import { IssueIdWithAksjonCompletedCount } from "../../IssueIdWithAksjonCompletedCount";
import { doFetchUniqIssueTagsOnBoardDepricated } from "../../issueAPIDepricated";
import {
  DEFAULT_VISIBLE_ISSUE_COLUMNS,
  ISSUE_COLUMN_LABELS,
  ISSUE_COMPLETED_VALUE,
  ISSUE_DATAFILEDS_ENUM,
  ISSUE_EXPIRED,
  ISSUE_PRIORTY_OPTIONS,
  ISSUE_SECTIONS,
  ISSUE_STATES_OPTIONS,
} from "../../issueConstants";
import {
  useCopyIssueMutation,
  useCreateIssueMutation,
  useDoUpdateIssueMutation,
  useGetConnectedTasksQuery,
} from "../../issueRTK";
import { selectSingleIssueBoard, updateColumnStateThunk } from "../../issueSlice";
import {
  convertValueAndColorToMultiDisplayColor,
  getIssueActionNameOption,
  getMatrixItemAndScore,
} from "../../issueUtils";
import ConnectedTasksAndIssuesMiniTable from "./ConnectedTasksAndIssuesMiniTable";

interface IIssueTableAgGridColumnProps {
  boardBuckets: IBoardBucket[];
  setSelectedIssue: (issue: IIssue) => void;
  types: string[];
  pnsItems: any[];
  members: IMember[];
  issueBoardId: string;
  projectId: string;
  updateItemMembers: (issueId: string, itemMembers: IMinUser[]) => void;
  otherAttributesConfig: IOtherAttributeConfig;
}

const IssueTableAgGrid: React.FC<{
  items: IIssue[];
  columnProps: IIssueTableAgGridColumnProps;
  loading: boolean;
  gridRef: React.RefObject<AgGridReact>;
}> = ({ items, columnProps, gridRef }) => {
  const {
    boardBuckets,
    setSelectedIssue,
    types,
    pnsItems,
    members,
    issueBoardId,
    projectId,
    updateItemMembers,
    otherAttributesConfig,
  } = columnProps;
  let refContainer = useRef(null);
  const allColumnState = useAppSelector((state) => state.issueReducerNew.allColumnState);
  const [updateIssue] = useDoUpdateIssueMutation();
  const [addNewIssue, { isLoading }] = useCreateIssueMutation();
  const [copyIssue] = useCopyIssueMutation();
  const issueBoard = useSelector(selectSingleIssueBoard);
  const dispatch = useAppDispatch();
  const columnsVisibility =
    (allColumnState[issueBoardId]?.length > 0
      ? allColumnState[issueBoardId].map((col) => col.colId)
      : DEFAULT_VISIBLE_ISSUE_COLUMNS) ?? DEFAULT_VISIBLE_ISSUE_COLUMNS; // use default cols if template is empty, or no state

  const getAgGridIssueColTextAndLabel = (
    dataField: ISSUE_DATAFILEDS_ENUM,
  ): { field: string; headerName: string; hide: boolean } => {
    return {
      field: dataField,
      headerName: ISSUE_COLUMN_LABELS[dataField],
      hide: !columnsVisibility.includes(dataField),
    };
  };
  const otherAttributeConfigContainsFormula = Object.keys(otherAttributesConfig || {}).some(
    (attr) => otherAttributesConfig[attr].typeOfValue === "FORMULA",
  );

  const {
    data: generatedFormulas = {},
    isError: getFormulaError,
    isLoading: getFormulaLoading,
  } = useGetSumsByFormulaQuery(
    {
      projectId: projectId,
      issueBoardId: issueBoardId,
    },
    {
      skip: otherAttributeConfigContainsFormula === false,
    },
  );
  const agDateCellEditor = (dateType: string) => {
    return {
      editable: true,
      context: ISSUE_SECTIONS.DATES,
      cellEditor: "agDateCellEditor",
      valueGetter: (params) => {
        if (params.data.dates && params.data.dates[dateType]) {
          return new Date(params.data.dates[dateType]);
        } else {
          return null;
        }
      },
    };
  };
  const agUserCellEditor = (userType: string) => {
    return {
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorPopup: true,
      valueGetter: (params) => {
        return params.data[userType] ?? null;
      },
      cellEditorParams: {
        values: members.map((m) => m.user),
        formatValue: (value) => value?.name ?? "",
        allowTyping: true,
        filterList: true,
        cellRenderer: (params) => <UserCellRenderer height="15px" user={params.value} />,
      },
    };
  };

  const InfoPanel = () => {
    return (
      <div className="p-2">
        <h4 className="mb-3">Tabelloversikt</h4>
        <h5>Justerbare kolonner:</h5>
        <p>
          Klikk på en kolonneoverskriftsdeler for å endre størrelsen på en kolonne. Dobbeltklikk for automatisk
          størrelse. Høyreklikk for å tilbakestille.
        </p>

        <h5>Omorganiser kolonner:</h5>
        <p>Klikk og dra en kolonneoverskrift for å omorganisere tabellen.</p>

        <h5>Kolonneverktøylinje:</h5>
        <p>
          På venstre side er det en meny med kolonneoversikt. Her kan du slå av og på enkeltkolonner eller grupper. Dra
          i listen for å omorganisere. <br /> Disse endringene vil kun påvirke din nåværende visning og vil ikke bli
          lagret.
        </p>

        <h5>Kolonnegruppering:</h5>
        <p>
          Kolonner har blitt gruppert med etiketter for enklere organisering. Tilpassede attributtkolonner vil
          automatisk bli gruppert etter seksjon.
        </p>

        <h5>Celleinteraksjon:</h5>
        <p>
          Dobbeltklikk på en celle for å redigere innholdet. Høyreklikk for å kopiere til utklippstavlen.
          <br /> Hvis teksten er for lang for en kolonne, klikk på pilen ned i høyre hjørne av cellen for å utvide. For
          å utvide alle kolonner, klikk på den blå knappen ved siden av fanene for tabellvisning.
        </p>
      </div>
    );
  };

  const ExpandAllDetailRowsButton = ({ api }) => {
    const [allRowsExpanded, setAllRowsExpanded] = useState<boolean>(false);
    const toggleAllRowsExpanded = () => {
      const expandAction = !allRowsExpanded;
      api.forEachNode((node) => node.setExpanded(expandAction));
      setAllRowsExpanded(expandAction);
      api.refreshCells();
    };

    return (
      <div>
        <div
          className={`expandIcon${allRowsExpanded === true ? " rotateIcon" : ""}`}
          onClick={() => toggleAllRowsExpanded()}
        >
          <div className="d-flex flex-column">
            <i className="fa fa-angle-down" style={{ marginBottom: "-5px" }}></i>
            <i className="fa fa-angle-down" style={{ marginTop: "-4px" }}></i>
          </div>
        </div>
      </div>
    );
  };
  const handleToolPanelVisibleChanged = (params) => {
    if (params.key === "columns" && params.visible === false) {
      localStorage.setItem("columnPanelSeen", "true");
    }
  };
  const icons = useMemo<{
    [key: string]: ((...args: any[]) => any) | string;
  }>(() => {
    return {
      "export-panel": '<span class="fa fa-file-excel-o fa-fw"></span>',
      info: '<span class="fa fa-question-circle"></span>',
    };
  }, []);
  const sideBar = useMemo(() => {
    const sb: SideBarDef = {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivotMode: true,
            // Other parameters...
          },
        },
        {
          id: "info",
          labelDefault: "Info",
          labelKey: "info",
          iconKey: "info",
          toolPanel: InfoPanel,
        },
      ],
      position: "left",
      defaultToolPanel: localStorage.getItem("columnPanelSeen") === "true" ? "" : "columns",
    };
    return sb;
  }, []);

  const AgGridUserCellRenderer: React.FC<{ user: IUser }> = ({ user }) => {
    return user != null ? (
      <div className="d-flex align-items-center">
        <Avatar id={user._id} />
        <div className="ml-2">{user.name}</div>
      </div>
    ) : (
      <div></div>
    );
  };

  const columnTypes = useMemo(
    () => ({
      alwaysHidden: {
        hide: true,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
      },
    }),
    [],
  );
  const columns: ColDef<any>[] = useMemo(
    () => [
      {
        headerName: "",
        cellClass: "p-0",
        cellRenderer: (props) => {
          const { node, api } = props;
          const handleClick = (e) => {
            e.preventDefault();
            node.setExpanded(!node.expanded);
            api.refreshCells();
            api.setFocusedCell(node.rowIndex, "id", null);
          };
          return (
            <div
              style={{ marginLeft: "3px" }}
              onClick={(e) => handleClick(e)}
              className={`expandIcon${node.expanded === true ? " rotateIcon" : ""}`}
            >
              <i className="fa fa-angle-down"></i>
            </div>
          );
        },
        width: 40,
        maxWidth: 40,
        suppressSizeToFit: true,
        suppressHeaderMenuButton: true,
        suppressColumnsToolPanel: true,
        suppressMovable: true,
        pinned: "left",
        context: ISSUE_SECTIONS.METACOLUMN,
        headerClass: "px-0 py-0",
        resizable: false,
        headerComponent: ExpandAllDetailRowsButton,
      },
      {
        field: "_id",
        headerName: "-",
        type: "alwaysHidden",
      },
      {
        field: "connecetedIssue",
        headerName: "-",
        type: "alwaysHidden",
      },
      {
        field: "connectedTasks",
        headerName: "-",
        type: "alwaysHidden",
      },
      {
        field: "taskOrIssue",
        headerName: "Vis aksjoner",
        type: "alwaysHidden",
      },
      {
        ...getAgGridIssueColTextAndLabel(ISSUE_DATAFILEDS_ENUM.ID),
        maxWidth: 165,
        cellClass: "grid-display-block px-1",
        // sort: "desc",
        cellRenderer: (params) => (
          <IssueIdWithAksjonCompletedCount id={params.value} issue={params.data} setSelectedIssue={setSelectedIssue} />
        ),
      },
      {
        ...getAgGridIssueColTextAndLabel(ISSUE_DATAFILEDS_ENUM.TITLE),
        minWidth: 200,
        filter: "agTextColumnFilter",
        // maxWidth: 400,
        flex: 2,
        editable: true,
      },
      {
        ...getAgGridIssueColTextAndLabel(ISSUE_DATAFILEDS_ENUM.DESCRIPTION),
        flex: 2,
        minWidth: 200,
        maxWidth: 600,
        cellClass: "overflow-hidden",
        cellRenderer: (params) => {
          return CellEditFormatter(params.value, ISSUE_DATAFILEDS_ENUM.DESCRIPTION);
        },
        editable: true,
      },
      {
        ...getAgGridIssueColTextAndLabel(ISSUE_DATAFILEDS_ENUM.CONCLUSION),
        flex: 2,
        minWidth: 200,
        maxWidth: 600,
        cellClass: "overflow-hidden",
        cellRenderer: (params) => {
          return CellEditFormatter(params.value, ISSUE_DATAFILEDS_ENUM.CONCLUSION);
        },
        editable: true,
      },
      {
        field: "assignedOrMember",
        headerName: "Mine saker",
        type: "alwaysHidden",
      },
      {
        field: "dueDate",
        headerName: "Tidsfrist",
        type: "alwaysHidden",
      },
      {
        ...getAgGridIssueColTextAndLabel(ISSUE_DATAFILEDS_ENUM.TYPEOFISSUE),
        minWidth: 80,
        cellEditor: "agRichSelectCellEditor", // Use the built-in SelectCellEditor
        editable: true,
        cellEditorParams: {
          values: types ?? [],
        },
      },
      {
        ...getAgGridIssueColTextAndLabel(ISSUE_DATAFILEDS_ENUM.PNS),
        cellRenderer: (params) => {
          const pnsItem = pnsItems?.find((pns) => pns.props?.pnsId === params.value);
          if (pnsItem != null) {
            return `${pnsItem.props?.pnsId} - ${pnsItem.props?.title}`;
          } else {
            return params.value;
          }
        },
      },
      {
        ...getAgGridIssueColTextAndLabel(ISSUE_DATAFILEDS_ENUM.CREATEDBY_NAME),
        cellRenderer: (params) => <AgGridUserCellRenderer user={params.data.createdBy} />,
        context: ISSUE_SECTIONS.HISTORY,
        editable: false,
        minWidth: 140,
      },
      {
        ...getAgGridIssueColTextAndLabel(ISSUE_DATAFILEDS_ENUM.ASSIGNEDTO_NAME),
        ...agUserCellEditor("assignedTo"),
        cellRenderer: (params) => <AgGridUserCellRenderer user={params.data.assignedTo} />,
        minWidth: 140,
      },
      {
        ...getAgGridIssueColTextAndLabel(ISSUE_DATAFILEDS_ENUM.CREATEDAT),
        width: 125,
        cellRenderer: (params) => formatShortDate(params.value, true),
        context: ISSUE_SECTIONS.HISTORY,
        editable: false,
      },
      {
        ...getAgGridIssueColTextAndLabel(ISSUE_DATAFILEDS_ENUM.LASTUPDATEDBY_NAME),
        cellRenderer: (params) => <AgGridUserCellRenderer user={params.data.lastUpdatedBy} />,
        context: ISSUE_SECTIONS.HISTORY,
        editable: false,
      },
      {
        ...getAgGridIssueColTextAndLabel(ISSUE_DATAFILEDS_ENUM.UPDATEDAT),
        width: 125,
        cellRenderer: (params) => formatShortDate(params.value, true),
        context: ISSUE_SECTIONS.HISTORY,
        editable: false,
      },
      {
        ...getAgGridIssueColTextAndLabel(ISSUE_DATAFILEDS_ENUM.OWNER_NAME),
        ...agUserCellEditor("owner"),
        filter: "agTextColumnFilter",
        valueGetter: (params) => {
          return params.data.owner?.name;
        },
        sortable: true,
        cellRenderer: (params) => <AgGridUserCellRenderer user={params.data.owner} />,
      },
      {
        ...getAgGridIssueColTextAndLabel(ISSUE_DATAFILEDS_ENUM.DATES_PLANNEDSTART),
        width: 125,
        ...agDateCellEditor("plannedStart"),
        cellRenderer: (params) => formatShortDate(params.value, true),
      },
      {
        ...getAgGridIssueColTextAndLabel(ISSUE_DATAFILEDS_ENUM.DATES_DUE),
        width: 127,
        ...agDateCellEditor("due"),

        cellRenderer: (params) => {
          if (moment(params.value).isValid() === false || params.value == null || !params.value) {
            return "-";
          }
          return (
            <DisplayDueDate
              dueDate={params.value}
              isComplete={
                params.data != null &&
                (params.data.status === ISSUE_COMPLETED_VALUE || params.data.status === ISSUE_EXPIRED)
              }
            />
          );
        },
      },
      {
        ...getAgGridIssueColTextAndLabel(ISSUE_DATAFILEDS_ENUM.DATES_COMPLETED),
        // minWidth: 125,
        ...agDateCellEditor("completed"),
        cellRenderer: (params) => formatShortDate(params.value, true),
      },
      {
        ...getAgGridIssueColTextAndLabel(ISSUE_DATAFILEDS_ENUM.TAGS),
        cellClass: "grid-display-block p-0",
        maxWidth: 200,
        cellRenderer: (params) => {
          const cb = async (tags: string[]) => {
            await updateIssue({
              projectId: projectId,
              issueBoardId: issueBoardId,
              issueId: params.data._id,
              attr: "tags",
              value: tags,
              oldValue: "",
            });
          };
          return (
            <Tags
              tags={params.value}
              updateCb={cb}
              fetchUniqTags={doFetchUniqIssueTagsOnBoardDepricated}
              fetchArgs={[projectId, issueBoardId]}
            />
          );
        },
      },
      {
        ...getAgGridIssueColTextAndLabel(ISSUE_DATAFILEDS_ENUM.STATUS),
        editable: true,
        valueSetter: (params) => {
          return params.newValue;
        },
        cellEditor: "agRichSelectCellEditor",
        cellEditorParams: {
          values: ISSUE_STATES_OPTIONS.map((opt) => opt.value),
          cellRenderer: (params) => {
            return (
              <OptionBadge
                asBadge={false}
                activeOption={params.value}
                options={convertValueAndColorToMultiDisplayColor(ISSUE_STATES_OPTIONS)}
                noWrap={true}
              />
            );
          },
          valueListMaxHeight: 220,
        },
        cellRenderer: (params) => {
          return (
            <OptionBadge
              asBadge={false}
              activeOption={params.value}
              options={convertValueAndColorToMultiDisplayColor(ISSUE_STATES_OPTIONS)}
            />
          );
        },
      },
      {
        ...getAgGridIssueColTextAndLabel(ISSUE_DATAFILEDS_ENUM.DISCIPLIN),
      },
      {
        ...getAgGridIssueColTextAndLabel(ISSUE_DATAFILEDS_ENUM.ITEMMEMBERS),
        cellClass: "grid-display-block",
        cellRenderer: (params) => {
          const issueMemberChange = async (user: IMinUser, issue) => {
            let itemMembers = issue.itemMembers ? [...issue.itemMembers] : [];

            const issueMemberIndex = itemMembers.findIndex((i) => i._id === user._id);

            if (issueMemberIndex !== -1) {
              itemMembers = itemMembers.filter((i) => i._id !== user._id);
            } else {
              itemMembers = [...itemMembers, user];
            }

            updateItemMembers(issue._id, itemMembers);
          };
          return (
            <McDropdownMultiUser
              filterActive={members.length > 10}
              allUsers={members.map((member) => member.user)}
              selectedUsers={params.data.itemMembers}
              onChange={(user) => issueMemberChange(user, params.data)}
            />
          );
        },
      },
      {
        ...getAgGridIssueColTextAndLabel(ISSUE_DATAFILEDS_ENUM.PRIORITY),
        editable: true,
        valueSetter: (params) => {
          return params.newValue.key;
        },
        cellEditor: "agRichSelectCellEditor",
        cellEditorParams: {
          values: ISSUE_PRIORTY_OPTIONS.map((opt) => opt.key),
          cellRenderer: (params) => (
            <OptionBadge asBadge={false} activeOption={params.value} options={ISSUE_PRIORTY_OPTIONS} noWrap={true} />
          ),
          valueListMaxHeight: 220,
        },
        cellRenderer: (params) => (
          <OptionBadge asBadge={false} activeOption={params.value} options={ISSUE_PRIORTY_OPTIONS} />
        ),
      },
      {
        ...getAgGridIssueColTextAndLabel(ISSUE_DATAFILEDS_ENUM.REFERENCEURL),
        minWidth: 200,
        maxWidth: 400,
        cellRenderer: (params) => URLCellFormatter(params.value),
      },
      {
        ...getAgGridIssueColTextAndLabel(ISSUE_DATAFILEDS_ENUM.GIS),
        cellRenderer: (params) => {
          let gisIcon;
          if (params.data.gis != null) {
            gisIcon = <i className="fa fa-globe"></i>;
          }

          return <div className="d-flex align-items-center justify-content-center">{gisIcon}</div>;
        },
      },
      {
        headerName: issueBoard?.bucketNameSetting,
        field: ISSUE_DATAFILEDS_ENUM.BUCKET,
        hide: !columnsVisibility.includes(ISSUE_DATAFILEDS_ENUM.BUCKET),
        cellClass: "overflow-hidden",
        cellRenderer: (params) => CellEditFormatter(params.value, ISSUE_DATAFILEDS_ENUM.BUCKET),
        maxWidth: 250,
        editable: true,
        cellEditor: "agRichSelectCellEditor",
        cellEditorParams: {
          values: boardBuckets.map((bucket) => bucket.value),
          valueListMaxHeight: 220,
        },
      },
      {
        ...getAgGridIssueColTextAndLabel(ISSUE_DATAFILEDS_ENUM.SPACE),
      },
      {
        ...getAgGridIssueColTextAndLabel(ISSUE_DATAFILEDS_ENUM.HOURS_ORIGNALESTIMATE),
        valueFormatter: (params) => {
          return params.value != null ? params.value : 0;
        },
        editable: true,
        context: ISSUE_SECTIONS.TIME,
      },
      {
        ...getAgGridIssueColTextAndLabel(ISSUE_DATAFILEDS_ENUM.HOURS_COMPLETED),
        editable: true,
        context: ISSUE_SECTIONS.TIME,
        valueFormatter: (params) => {
          return params.value != null ? params.value : 0;
        },
      },
      {
        ...getAgGridIssueColTextAndLabel(ISSUE_DATAFILEDS_ENUM.HOURS_REMAINING),
        editable: true,
        context: ISSUE_SECTIONS.TIME,
        valueFormatter: (params) => {
          return params.value != null ? params.value : 0;
        },
      },
    ],
    [],
  );
  const DetailCellRenderer = ({ data }: CustomCellRendererProps) => {
    const { data: tasks, isError } = useGetConnectedTasksQuery({
      issueId: data._id,
      projectId: projectId,
    });

    const gridRef = useRef<HTMLDivElement>(null);

    const copy = async (id: string, includeTasks: boolean): Promise<void> => {
      copyIssue({
        projectId: projectId,
        issueId: id,
        includeTasks: includeTasks,
      }).unwrap();
    };

    const filterColumnsByField = (columns: any[], allowedFields: string[]): any[] => {
      return columns.filter((col) => allowedFields.includes(col?.field));
    };

    const columnsWithCopy = [
      ...filterColumnsByField(columns, [
        "id",
        "title",
        "assignedTo.name",
        "status",
        "dates.due",
        "dates.started",
        "dates.completed",
      ]),
      {
        field: "_id",
        headerName: "Lag kopi",
        width: 90,
        minWidth: 90,
        suppressMovable: true,
        resizable: false,
        sortable: false,
        cellClass: "grid-display-block",
        cellRenderer: (params) => (
          <div className="">
            <CopyIssueButton issueId={params.data._id} taskOrIssue={"TASK"} copyAndSet={copy} />
          </div>
        ),
      },
    ];

    useEffect(() => {
      if (gridRef.current) {
        gridRef.current.style.visibility = "hidden";
      }
    }, []);

    return (
      <div className="p-2 bg-white">
        {isError === true && <FailedAlert />}
        <div className="d-flex align-items-center mb-1 ">
          <h5 style={{ marginTop: "6px", marginRight: "4px" }}>
            {getIssueActionNameOption(issueBoard?.taskNameSetting, "plural")}
          </h5>
          <CreateNewButton
            disabled={isLoading}
            outline
            onClick={() =>
              addNewIssue({
                projectId: projectId,
                issueBoardId: issueBoardId,
                issueTitle: "",
                taskOrIssue: "TASK",
                issueIdIfItIsATask: data._id,
              })
            }
            tooltip={getIssueActionNameOption(issueBoard?.taskNameSetting, "new")}
            size="sm"
          />
        </div>
        {data.connectedTasks?.length > 0 && (
          <div ref={gridRef}>
            <AgGridReact
              popupParent={document.querySelector("body") || undefined}
              rowHeight={45}
              defaultColDef={defaultColDef}
              domLayout={"autoHeight"}
              columnDefs={columnsWithCopy}
              getRowId={(p) => p.data._id}
              rowData={tasks as IIssue[]}
              stopEditingWhenCellsLoseFocus={true}
              animateRows={false}
              readOnlyEdit={true}
              onCellEditRequest={onCellEditRequest}
              suppressContextMenu={true}
              maintainColumnOrder={true}
              localeText={AG_GRID_LOCALE_NOR}
              onFirstDataRendered={() => {
                if (gridRef.current) {
                  gridRef.current.style.visibility = "visible";
                }
              }}
            />
          </div>
        )}
        {data.connectedTasksAndIssuesFromOtherBoards?.length > 0 && (
          <>
            <VSpace />
            <ConnectedTasksAndIssuesMiniTable
              fullWidth
              issue={data}
              issueId={data._id}
              issueBoardId={issueBoard._id}
              projectId={projectId}
              issueBoard={issueBoard}
            />
            <VSpace />
          </>
        )}
      </div>
    );
  };

  const getAgGridOtherAttributeColumns = (otherAttributesConfig: IOtherAttributeConfig): ColDef<any>[] => {
    const otherAttributeColumns: ColDef<any>[] = [];
    const otherAttributesEntries = Object.entries(otherAttributesConfig ?? {}).map(([key, value]) => ({
      key: key,
      ...value,
    }));

    // Group by the 'section' key
    const groupedBySection = R.groupBy((item) => item.section, otherAttributesEntries);
    Object.entries(groupedBySection).map(([sectionTitle, attributesInSection]) => {
      attributesInSection
        .sort((a, b) => a.order - b.order)
        .forEach((attribute) => {
          if (attribute.isActive !== true) {
            return;
          }
          let customParams: {};
          switch (attribute.typeOfValue) {
            case "MULTILINESTRING":
              customParams = {
                filter: "agTextColumnFilter",
                cellEditor: "agLargeTextCellEditor",
                cellEditorPopup: true,
                minWidth: 200,
                cellRenderer: (params) => CellEditFormatter(params.value),
              };
              break;
            case "NUMBER":
              customParams = { filter: "agNumberColumnFilter", cellEditor: "agNumberCellEditor" };
              break;
            case "BOOLEAN":
              customParams = {
                filter: "agFilter",
                cellEditor: "agRichSelectCellEditor",
                cellEditorParams: {
                  values: [true, false],
                  cellRenderer: (params) => {
                    return <div>{params.value === true ? "Ja" : "Nei"}</div>;
                  },
                },
                valueGetter: (params) =>
                  params.data.otherAttributesData?.[attribute.key] === true
                    ? "Ja"
                    : params.data.otherAttributesData?.[attribute.key] === false
                    ? "Nei"
                    : "",
              };
              break;
            case "DROPDOWN":
              const options = otherAttributesConfig?.[attribute.key]?.tableOptions ?? [];
              const OptionWithColor = (params: ValueFormatterParams) => {
                const foundOption = options.find((o) => o.value === params.value);
                return (
                  <div className="d-flex align-items-center">
                    {foundOption?.color && (
                      <div className="statusdot" style={{ backgroundColor: foundOption?.color }}></div>
                    )}
                    <div style={{ color: "#58595B", fontSize: "12px" }}>{params.value}</div>
                  </div>
                );
              };
              customParams = {
                filter: "agFilter",
                cellEditor: "agRichSelectCellEditor",
                editable:
                  attribute.locked !== true &&
                  otherAttributesConfig?.[attribute.key]?.allowMultipleSelectionsInDropdown !== true,
                valueSetter: (params) => {
                  return params.newValue;
                },
                cellEditorParams: {
                  values: options.map((opt) => opt.value),
                  cellRenderer: OptionWithColor,
                  valueListMaxHeight: 220,
                },
                cellRenderer: OptionWithColor,
              };
              break;
            case "DATE":
              customParams = {
                filter: "agDateColumnFilter",
                cellEditor: "agDateCellEditor",
                valueGetter: (params) => {
                  return formatShortDate(params.data.otherAttributesData?.[attribute?.key] ?? "", true);
                },
              };
              break;
            case "FORMULA":
              customParams = {
                filter: "agTextColumnFilter",
                editable: false,
                valueGetter: (params) => {
                  const key = attribute.key;
                  const issueId = params.data.id;
                  if (getFormulaError === true) {
                    return "Feil ved henting av data";
                  }
                  if (getFormulaLoading === true) {
                    return "Henter data...";
                  }
                  if (generatedFormulas[key] == null) {
                    return "Fant ikke formel";
                  }
                  return generatedFormulas[key]?.[issueId] ?? "";
                },
              };
              break;
            default:
              customParams = { filter: "agTextColumnFilter" };
              break;
          }

          const customAttributeHeaderTemplate = (color: string, isLocked: boolean) => {
            return `<div class="ag-cell-label-container" role="presentation">
                      <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
                      <span data-ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
                      <div data-ref="eLabel" class="ag-header-cell-label" role="presentation">
                        <span data-ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>
                        <span data-ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>
                        <span data-ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>
                        <span data-ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>
                        ${isLocked === true ? `<div class="mr-1"><i class="fa fa-lock"></i></div>` : ""}
                        ${
                          color != null && color.length > 0
                            ? `<div class="mmi-dot mr-1" style="background-color: ${color};"></div>`
                            : ""
                        }
                        <span data-ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                        <div class="d-flex align-items-baseline" style="flex-direction: row;"></div>
                        <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
                      </div>
                    </div>`;
          };

          const otherAttributeColumn: ColDef = {
            field: `otherAttributesData.${attribute.key}`,
            headerName: attribute.label,
            headerComponentParams: { template: customAttributeHeaderTemplate(attribute.labelColor, attribute.locked) },
            editable: attribute.locked !== true,
            ...customParams,
            maxWidth: 300,
            context: sectionTitle,
            cellClass: "overflow-hidden",
            hide: !columnsVisibility.includes(`otherAttributesData.${attribute.key}`),
          };
          otherAttributeColumns.push(otherAttributeColumn);
        });
    });
    return otherAttributeColumns;
  };

  const getRisikoEvaluationColumns = (issueBoard: IBoard): ColDef<any>[] => {
    const categoriesFromBoard: IMapOfEvaluationCategories = issueBoard?.risikoSection?.evaluationCategoriesConfig ?? {};
    const evaluationCategories = Object.entries(categoriesFromBoard)
      .filter(([key, value]) => value.isActive)
      .map(([key, value]) => ({
        key: key,
        ...value,
      }));
    const probabilityColumn: ColDef<any> = {
      ...getAgGridIssueColTextAndLabel(ISSUE_DATAFILEDS_ENUM.RISK_PROBABILITY),
      editable: true,
      context: ISSUE_SECTIONS.RISIKO,
      valueFormatter: (params) => {
        const risikoItem = issueBoard?.risikoSection?.probabilites[params.value];
        return risikoItem?.name || "";
      },
      filter: "agFilter",
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: Object.keys(issueBoard?.risikoSection?.probabilites || {}),
        valueFormatter: (params) => {
          const risikoItem = issueBoard?.risikoSection?.probabilites?.[params.value];
          return risikoItem?.name || "";
        },
      },
    };

    const evaluationColumns: ColDef<any>[] = evaluationCategories.flatMap((category) => {
      const consequenceColDef: ColDef<any> = {
        field: `risiko.consequence.${category.key}`,
        headerName: `${category?.name} konsekvens`,
        headerClass: "px-2 py-0 two-line-header",
        filter: "agFilter",
        editable: true,
        cellEditor: "agRichSelectCellEditor",
        valueFormatter: (params: ValueFormatterParams<IIssue>) => {
          const risikoItem: IRisikoProbability = categoriesFromBoard?.[category.key]?.consequences[params.value];
          return risikoItem?.name || "";
        },
        cellEditorParams: {
          values:
            Object.values(categoriesFromBoard[category.key]?.consequences ?? {})
              .filter((obj: { isActive: boolean }) => obj.isActive)
              .map((obj: { id: string }) => obj.id) ?? [],
          valueFormatter: (params) => {
            const risikoItem = categoriesFromBoard?.[category.key]?.consequences?.[params.value];
            return risikoItem?.name || "";
          },
        },
        context: ISSUE_SECTIONS.RISIKO,
        hide: !columnsVisibility.includes(`risiko.consequence.${category.key}`),
      };

      const matrixValueColDef: ColDef<any> = {
        field: `risikoMatrixValue.${category.key}`,
        headerName: `${category.name} poeng`,
        headerClass: "px-2 py-0 two-line-header",
        wrapHeaderText: true,
        initialWidth: 100,
        filter: "agNumberColumnFilter",

        valueGetter: (params) => {
          try {
            const matrixItemAndScore = getMatrixItemAndScore({
              issue: params.data as IIssue,
              issueBoard: issueBoard as IBoard,
              evaluationCategoryCapital: category.key,
            });
            return matrixItemAndScore?.score;
          } catch (error) {
            return 0;
          }
        },
        cellRenderer: (params: ICellRendererParams<IIssue>) => {
          try {
            const matrixItemAndScore = getMatrixItemAndScore({
              issue: params.data as IIssue,
              issueBoard: issueBoard as IBoard,
              evaluationCategoryCapital: category.key,
            });

            return (
              <div
                className="d-flex flex-items-center justify-content-center"
                style={{
                  color: matrixItemAndScore?.matrixItem?.color != null ? "white" : "black",
                  backgroundColor: matrixItemAndScore?.matrixItem?.color,
                  fontSize: "12px",
                  borderRadius: "5px",
                }}
              >
                {matrixItemAndScore?.score}
              </div>
            );
          } catch (error) {
            return <div>-</div>;
          }
        },
        context: ISSUE_SECTIONS.RISIKO,
        hide: !columnsVisibility.includes(`risikoMatrixValue.${category?.key}`),
      };

      return [consequenceColDef, matrixValueColDef];
    });

    const categoryCommentCol: ColDef<IIssue> = {
      field: `risiko.categoryComment`,
      headerName: "Risiko kommentar",
      headerClass: "px-2 py-0 two-line-header",
      wrapHeaderText: true,
      initialWidth: 180,
      minWidth: 120,
      filter: "agTextColumnFilter",
      editable: true,
      context: ISSUE_SECTIONS.RISIKO,
      hide: !columnsVisibility.includes(`risiko.categoryComment`),
    };
    const pointTotalCol: ColDef<any> = {
      field: "risiko.pointTotal",
      headerName: "Total poengsum",
      headerClass: "px-2 py-0 two-line-header",
      wrapHeaderText: true,
      filter: "agNumberColumnFilter",
      context: ISSUE_SECTIONS.RISIKO,
      hide: !columnsVisibility.includes(`risiko.pointTotal`),
      valueGetter: (params) => {
        try {
          const totalPoints = evaluationCategories.reduce((sum, category) => {
            const value = Number(params.getValue(`risikoMatrixValue.${category.key}`));
            return sum + (value ? Number(value) : 0);
          }, 0);
          return totalPoints;
        } catch (error) {
          return 0;
        }
      },
    };
    const lowestPointCol: ColDef<any> = {
      field: "risiko.worstScore",
      headerName: "Verste kategori",
      headerClass: "px-2 py-0 two-line-header",
      filter: "agNumberColumnFilter",
      wrapHeaderText: true,
      context: ISSUE_SECTIONS.RISIKO,
      hide: !columnsVisibility.includes(`risiko.worstScore`),
      valueGetter: (params) => {
        try {
          const highestPoint = evaluationCategories.reduce((max, category) => {
            const value = Number(params.getValue(`risikoMatrixValue.${category.key}`));
            return Math.max(max, value || 0);
          }, 0);
          return highestPoint;
        } catch (error) {
          return 0;
        }
      },
    };

    return [probabilityColumn, ...evaluationColumns, pointTotalCol, lowestPointCol, categoryCommentCol];
  };

  const allColumns = [
    ...columns,
    ...getAgGridOtherAttributeColumns(otherAttributesConfig),
    ...(issueBoard?.risikoSection?.isActive ? getRisikoEvaluationColumns(issueBoard) : []),
  ];

  const groupedColumns: ColDef<any>[] = useMemo(() => {
    const visibleColumns = allColumns.map((col) => {
      return {
        ...col,
        hide: col.context === ISSUE_SECTIONS.METACOLUMN ? col.hide : !columnsVisibility.includes(col.field),
      };
    });
    // Existing column definitions remain unchanged
    const initialColumns = [
      {
        headerName: "",
        children: [...visibleColumns.filter((col) => col.context === ISSUE_SECTIONS.METACOLUMN)],
        suppressColumnsToolPanel: true,
      },
      {
        headerName: ISSUE_SECTIONS.COMMON,
        children: [...visibleColumns.filter((col) => col?.context === undefined && col.headerName.length > 0)],
      },
      {
        headerName: ISSUE_SECTIONS.TIME,
        children: [...visibleColumns.filter((col) => col?.context === ISSUE_SECTIONS.TIME)],
      },
      {
        headerName: ISSUE_SECTIONS.DATES,
        children: [...visibleColumns.filter((col) => col?.context === ISSUE_SECTIONS.DATES)],
      },
      {
        headerName: ISSUE_SECTIONS.HISTORY,
        children: [...visibleColumns.filter((col) => col?.context === ISSUE_SECTIONS.HISTORY)],
      },
      ...(issueBoard?.risikoSection?.isActive === true
        ? [
            {
              headerName: ISSUE_SECTIONS.RISIKO,
              children: [...visibleColumns.filter((col) => col?.context === ISSUE_SECTIONS.RISIKO)],
            },
          ]
        : []),
    ];

    // Get custom attribute columns and group them by type
    const otherAttributeColumns = getAgGridOtherAttributeColumns(otherAttributesConfig);
    const groupedByType = R.groupBy((colDef) => colDef.context.toString(), otherAttributeColumns);
    const otherAttributeColumnGroups = Object.entries(groupedByType).map(([type, cols]) => ({
      headerName: type,
      children: cols,
    }));

    return [...initialColumns, ...otherAttributeColumnGroups];
  }, [generatedFormulas]);

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      minWidth: 100,
      suppressMovable: false,
      resizable: true,
      suppressHeaderMenuButton: true,
      editable: false,
      // wrapText: true,
      autoHeight: true,
      //autoHeaderHeight: true, // this causes crash in chrome update 2025-16-01
      cellClass: "p-1",
      headerClass: "px-2 py-1",
      cellStyle: {
        whiteSpace: "normal",
        lineHeight: "1.5",
        wordBreak: "break-word",
      },
    }),
    [],
  );
  const debouncedHandleToggleColumnVisibility = useCallback(
    debounce((e) => {
      const columnState =
        e.api.getAllDisplayedColumns()?.map((col) => ({
          colId: col.getColId(),
          // width: col.getActualWidth(),
        })) ?? [];

      dispatch(updateColumnStateThunk(columnState, issueBoardId));
    }, 1000), //lets animation finish, saves in background
    [dispatch],
  );
  const onCellEditRequest = (event: CellEditRequestEvent<IIssue>) => {
    const issue = event.data;
    let updateValue;

    if (event.colDef.field.startsWith("assignedTo")) {
      updateValue = typeof event.newValue !== "string" ? event.newValue?._id : event.newValue;
    } else if (event.colDef.field.startsWith("status")) {
      updateValue = event.newValue;
    } else if (event.colDef.field.startsWith("priority")) {
      updateValue = event.newValue;
    } else if (event.colDef.field.startsWith("dates")) {
      if (event.newValue == null) {
        updateValue = "";
      } else {
        updateValue = event.newValue;

        if (updateValue.toISOString() === event.oldValue?.toISOString()) {
          return;
        }
      }
    } else {
      updateValue = event.newValue;
    }

    updateIssue({
      projectId: projectId,
      issueId: issue._id,
      attr: event.colDef.field,
      value: updateValue,
      oldValue: event.oldValue?.name ?? "",
      issueBoardId: issueBoardId,
    });
  };
  return (
    <div className="d-flex flex-column w-100 p-2 h-100">
      <div className=" ag-theme-quartz h-100" id="myGrid" ref={refContainer}>
        <AgGridReact
          onColumnVisible={debouncedHandleToggleColumnVisibility}
          suppressHeaderFocus={true}
          cellSelection={true}
          rowHeight={55}
          headerHeight={40}
          columnTypes={columnTypes}
          maintainColumnOrder={true}
          ref={gridRef}
          getRowId={(p) => p.data._id}
          rowData={items}
          columnDefs={groupedColumns}
          stopEditingWhenCellsLoseFocus={true}
          readOnlyEdit={true}
          onCellEditRequest={onCellEditRequest}
          defaultColDef={defaultColDef}
          animateRows={false}
          icons={icons}
          onGridReady={(e) => {
            const autoSizeCols = e.api
              .getColumns()
              .filter((column) => {
                const colId = column.getColId();
                return colId !== "title" && colId !== "description" && colId !== "conclusion";
              })
              .map((column) => column.getColId());
            e.api.autoSizeColumns(autoSizeCols, true);
          }}
          sideBar={sideBar}
          pagination={true}
          paginationPageSize={50}
          suppressColumnVirtualisation={true}
          suppressRowVirtualisation={true}
          // rowBuffer={0}
          masterDetail={true}
          detailRowAutoHeight={true}
          detailCellRenderer={DetailCellRenderer}
          localeText={AG_GRID_LOCALE_NOR}
          onToolPanelVisibleChanged={handleToolPanelVisibleChanged}
        />
      </div>
    </div>
  );
};

export default IssueTableAgGrid;
