import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { IRisiko, IRisikoEvaluationCategory, IRiskoBoard } from "../../../../../../types/global.types";
import { sortArrayByDate } from "../../../utils";
import { nrOfItemsWithDatesNewerThan30Days } from "../util";
import EvaluationStatisticsCard from "./CategoryStats/EvaluationStatisticsCard";
import RisikoMiniTable from "./RisikoMiniTable";
import { StatsBox } from "./StatsBox";

const RisikoStats: React.FC<{
  risikos: IRisiko[];
  risikoCategories: IRisikoEvaluationCategory[];
  setSelectedChartColorItem: any;
  setSelectedRisiko: any;
  risikoBoard: IRiskoBoard;
}> = ({ risikos, risikoCategories, setSelectedChartColorItem, setSelectedRisiko, risikoBoard }) => {
  const [last10CreatedRisikos, setLast10CreatedRisiko] = useState(null);
  const [stats, setStats] = useState(null);

  useEffect(() => {
    const nrUpdatedLast30Days = nrOfItemsWithDatesNewerThan30Days("updatedAt")(risikos);
    const nrCreatedLast30Days = nrOfItemsWithDatesNewerThan30Days("createdAt")(risikos);
    const _stats = [
      {
        icon: "fa-book",
        value: risikos.length,
        text: "risikoer har blitt registrert",
      },
      {
        icon: "fa-pencil",
        value: nrUpdatedLast30Days,
        text: " risikoer er oppdatert siste 30 dager",
      },
      {
        icon: "fa-eye",
        value: nrCreatedLast30Days,
        text: "risikoer er opprettet siste 30 dager",
      },
      {
        icon: "fa-eye",
        value: nrCreatedLast30Days,
        text: "risikoer er opprettet siste 30 dager",
      },
    ];
    setStats(_stats);
  }, []);

  useEffect(() => {
    const _last10CreatedRisikos = sortArrayByDate(risikos, "createdAt").slice(0, 10);
    setLast10CreatedRisiko(_last10CreatedRisikos);
  }, []);

  return (
    <div id="b" className="w-100 p-3" style={{ background: "#f4f5fc", overflow: "auto" }}>
      {stats != null && (
        <Row>
          <Col xs="3">
            <StatsBox stat={stats[0]} />
          </Col>
          <Col xs="3">
            <StatsBox stat={stats[1]} />
          </Col>
          <Col xs="3">
            <StatsBox stat={stats[2]} />
          </Col>
          <Col xs="3">
            <StatsBox stat={stats[3]} />
          </Col>
        </Row>
      )}
      <div className="mt-5" />
      <Row>
        <Col xs="6">
          <EvaluationStatisticsCard risikos={risikos} />
        </Col>
        <Col xs="6">
          {last10CreatedRisikos != null && (
            <RisikoMiniTable setSelectedRisiko={setSelectedRisiko} risikos={last10CreatedRisikos} />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default RisikoStats;
