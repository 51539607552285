import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import React, { useRef, useState } from "react";
import { utils, writeFile } from "xlsx";

export interface IChartData {
  fields?: Record<string, string>; //got error when i did not have question mark
  documents?: Record<string, any>[];
}

//if sort is set to true, the columns will be sorted alphabetically according to field key names.
//since in some charts (tables?) the keys will be group, group_0, group_1, value, value_series_0 and then we want to sort them alphabetically in the export
//headers should be field.values so that the headers are descriptive (not group, group_0, group_1 etc.)
//OBS: if there are many groups and values the sorting will be wrong, since it will sort group_0, group_1, group_10, group_11, group_2, group_3 etc.
//fixed this by checking if group_series or value_series are in field keys also sort the numbers using regular expression
//this to make sure that group_10 is sorted after group_9 and not after group_1
export const exportChartToExcel = (data: IChartData, sort: boolean = false) => {
  try {
    let fieldKeys = Object.keys(data.fields);
    let rows = data.documents.map((document) => fieldKeys.map((fieldKey) => document[fieldKey]));
    let header = fieldKeys.map((fieldKey) => data.fields[fieldKey]);

    if (sort === true && fieldKeys.some((key) => key.includes("group_series") || key.includes("value_series"))) {
      const groupKeys = [];
      const valueKeys = [];

      fieldKeys.forEach((fieldKey) => {
        if (fieldKey.startsWith("group")) {
          groupKeys.push(fieldKey);
        } else if (fieldKey.startsWith("value")) {
          valueKeys.push(fieldKey);
        }
      });

      // Custom sorting function for numerical part
      const customSort = (a, b) => {
        const seriesRegex = /_(\d+)$/;
        const aSeriesMatch = seriesRegex.exec(a);
        const bSeriesMatch = seriesRegex.exec(b);

        if (aSeriesMatch && bSeriesMatch) {
          const aSeriesNumber = parseInt(aSeriesMatch[1]);
          const bSeriesNumber = parseInt(bSeriesMatch[1]);
          return aSeriesNumber - bSeriesNumber;
        }

        return a.localeCompare(b);
      };

      // Sort "group" keys
      groupKeys.sort((a, b) => customSort(a, b));

      // Sort "value" keys
      valueKeys.sort((a, b) => customSort(a, b));

      // Combine the sorted groups
      const sortedFieldKeys = [...groupKeys, ...valueKeys];

      // Sort header based on sorted field keys
      header = sortedFieldKeys.map((fieldKey) => data.fields[fieldKey]);

      // Sort rows based on sorted field keys
      rows = rows.map((row) => {
        const sortedRow = sortedFieldKeys.map((fieldKey) => row[fieldKeys.indexOf(fieldKey)]);
        return sortedRow;
      });
    } else if (sort === true) {
      //if sort is true but there are no series/group fields, do regular alphabetical sorting
      // Create a sorted copy of field keys alphabetically
      const sortedFieldKeys = [...fieldKeys].sort();
      // Sort header based on sorted field keys
      header = sortedFieldKeys.map((fieldKey) => data.fields[fieldKey]);
      // Sort rows based on sorted field keys
      rows = rows.map((row) => {
        const sortedRow = sortedFieldKeys.map((fieldKey) => row[fieldKeys.indexOf(fieldKey)]);
        return sortedRow;
      });
    }

    // Create a new workbook
    const workbook = utils.book_new();
    const worksheet = utils.aoa_to_sheet([header, ...rows]);

    utils.book_append_sheet(workbook, worksheet, "Sheet1");
    writeFile(workbook, "exported_chart_data.xlsx");
  } catch (err) {
    console.log("Error while exporting chart to excel.", err);
  }
};

const EmbeddedMongoDbAtlasChartWithGetData: React.FC<{
  chartId: string;
  filters: any;
  clickEnabled?: boolean;
  onChartClick?: (event, currentFilters: any) => void;
  getData?: (data: IChartData) => void;
}> = ({ chartId, filters, clickEnabled, onChartClick, getData }) => {
  const sdk = new ChartsEmbedSDK({ baseUrl: "https://charts.mongodb.com/charts-mcapps-ihimm" });
  const chartDiv1 = useRef(null);
  const [rendered1, setRendered1] = useState(false);
  const [chart1] = useState(
    sdk.createChart({
      chartId,
      showAttribution: false,
    }),
  );
  const [chartData, setChartData] = useState<IChartData>({ fields: {}, documents: [] });

  const updateChartData = async () => {
    try {
      const thisChartData: IChartData = (await chart1.getData()) || { fields: {}, documents: [] };
      setChartData(thisChartData);
      if (getData) {
        getData(thisChartData);
      }
    } catch (err) {
      console.log("Error while getting data.", err);
    }
  };

  React.useEffect(() => {
    chart1
      .render(chartDiv1.current)
      .then(() => setRendered1(true))
      .then(() => clickEnabled == true && chart1.addEventListener("click", (event) => onChartClick(event, filters)))
      .catch((err) => console.log("Error during Charts rendering.", err));
  }, [chart1]);

  React.useEffect(() => {
    const applyFilters = async () => {
      if (rendered1) {
        try {
          if (filters != null) {
            await chart1.setFilter(filters);
          } else {
            await chart1.setFilter({});
          }
          updateChartData();
        } catch (err) {
          console.log("Error while filtering.", err);
        }
      }
    };
    applyFilters();
  }, [chart1, rendered1, filters]);

  return (
    <>
      <div className="d-flex flex-column h-100 w-100" ref={chartDiv1} />
    </>
  );
};

export default EmbeddedMongoDbAtlasChartWithGetData;
