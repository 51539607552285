import nbLocale from "@fullcalendar/core/locales/nb";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { useElementSize } from "usehooks-ts";
import { DropdownSelectorDeprecated, FailedAlert } from "../../../../components";
import WidgetWrapper from "../../../../components/WidgetWrapper/WidgetWrapper";
import { AppModalEnums } from "../../../../frontendConstants";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { useGetMeetingSeriesQuery } from "../../meeting/meetingSerieRTK";
import { setActiveMeetingAppTabInRedux } from "../../meeting/meetingSlice";
import { MEETINGS_SERIES_STATUSES, MeetingSeriesStatusEnums } from "./meetingSeriesConstants";
import { ICalenderEvent } from "./types";
import { convertMeetingSeriesToCalenderEvent } from "./utils";
import { addAppModalThunk } from "../../../../global/globalSlice";

const MeetingSeries: React.FC = () => {
  const [calenderEvents, setCalenderEvents] = useState<ICalenderEvent[]>();
  const [showFailedAlert, setShowFailedAlert] = useState<boolean>(false);
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [openCalendarModal, setOpenCalendarModal] = useState<boolean>(false);

  const [activeStatus, setActiveStatus] = useState<MeetingSeriesStatusEnums>(MeetingSeriesStatusEnums.ACTIVE);

  const project = useAppSelector((state) => state.adminReducer.project);

  const openedAppModals = useAppSelector((state) => state.globalReducer.openedAppModals);
  const [containerRef, { height, width }] = useElementSize();

  const history = useHistory();

  const {
    data: meetingSeries,
    isError,
    isSuccess,
  } = useGetMeetingSeriesQuery({
    projectId: project._id,
  });

  useEffect(() => {
    init();
  }, [isSuccess]);

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (openedAppModals.length === 0) {
      init();
    }
  }, [openedAppModals.length]);

  const init = async () => {
    try {
      const _calenderEvents = (meetingSeries || []).reduce((acc, meetinSerie) => {
        const events = convertMeetingSeriesToCalenderEvent(meetinSerie);
        acc.push(...events);
        return acc;
      }, []);
      setCalenderEvents(_calenderEvents);
    } catch (error) {
      setShowFailedAlert(true);
    }
  };

  const onEventClick = (info) => {
    info.jsEvent.preventDefault();
    const meetingSerie = meetingSeries.find((ms) => ms.name === info.event.title);
    dispatch(
      addAppModalThunk({
        app: AppModalEnums.MEETING_SERIE,
        projectId: project._id,
        itemId: meetingSerie._id,
        startIsoString: new Date(info.event.start).toISOString(),
      }),
    );
  };

  const goToMeetingApp = () => {
    dispatch(setActiveMeetingAppTabInRedux("MEETING_SERIES"));
    history.push(`/project/${project._id}/meetings`);
  };

  return (
    <WidgetWrapper
      title="Prosjektkalender"
      widgetKey="meetingSeries"
      onFullscreenClick={() => setOpenCalendarModal(true)}
      onShowSettingsClick={() => setShowSettings(!showSettings)}
    >
      {showFailedAlert === true || (isError && <FailedAlert errorMessage="Failed to load meeting series" />)}
      {calenderEvents != null && showFailedAlert === false && (
        <>
          {showSettings && (
            <div className="d-flex flex-wrap justify-content-end w-100 mb-2">
              <DropdownSelectorDeprecated
                color="mc-blue"
                icon="filter"
                widthInPx={150}
                items={MEETINGS_SERIES_STATUSES}
                displayKey="label"
                setItem={(item) => setActiveStatus(item.value)}
                activeItem={MEETINGS_SERIES_STATUSES.find((item) => item.value === activeStatus)}
              />
              <Button onClick={goToMeetingApp} className="mc-blue text-white mx-1">
                <i className="fa fa-external-link fa-fw" />
                Gå till møteserier
              </Button>
            </div>
          )}
          <div ref={containerRef} className="flex-grow-1" style={{ fontSize: ".8em" }}>
            <FullCalendar
              allDaySlot={false}
              locale={nbLocale}
              slotMinTime={"08:00:00"}
              slotMaxTime={"17:00:00"}
              slotDuration={height > 400 ? "00:30:00" : "01:00:00"}
              slotLabelFormat={[
                {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: false,
                },
              ]}
              plugins={[timeGridPlugin]}
              initialView="timeGridWeek"
              weekends={false}
              height={"100%"}
              expandRows={true}
              // contentHeight="auto"
              events={calenderEvents.filter((event) => event.status === activeStatus)}
              eventClick={onEventClick}
              headerToolbar={
                width > 500
                  ? {
                      start: "title", // will normally be on the left. if RTL, will be on the right
                      center: "",
                      end: "today prev,next", // will normally be on the right. if RTL, will be on the left
                    }
                  : false
              }
            />
          </div>
          <Modal
            style={{ maxWidth: "80vw", maxHeight: "88vh" }}
            isOpen={openCalendarModal}
            toggle={() => setOpenCalendarModal(false)}
          >
            <ModalHeader toggle={() => setOpenCalendarModal(false)}>Prosjektkalender</ModalHeader>
            <ModalBody>
              <FullCalendar
                allDaySlot={false}
                locale={nbLocale}
                slotMinTime={"08:00:00"}
                slotMaxTime={"18:00:00"}
                slotLabelFormat={[
                  {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false,
                  },
                ]}
                plugins={[timeGridPlugin]}
                initialView="timeGridWeek"
                weekends={false}
                height={"86vh"}
                expandRows={true}
                // contentHeight="auto"
                events={calenderEvents.filter((event) => event.status === activeStatus)}
                eventClick={onEventClick}
              />
            </ModalBody>
          </Modal>
        </>
      )}
    </WidgetWrapper>
  );
};

export default MeetingSeries;
