import { IValueLabel } from "../../../../../../../../../types/global.types";
import {
  EU_GMP_KLASSER,
  EU_GMP_TABELL,
  ISO_KLASSER,
  ISO_TABELL,
  MAALEPUNKTER_TABELL,
} from "./partikkelTellingTabeller";

export const beregnetMinimumAntallProevetakingspunkter = (maaleomraadeAreal: number): number => {
  if (isNaN(maaleomraadeAreal) === true || maaleomraadeAreal == null) {
    return null;
  } else {
    return Math.ceil(27 * (maaleomraadeAreal / 1000));
  }
};

export const beregnetVolumPerProevetaking = (maalehastighet: number, maaletid: number): number => {
  if (isNaN(maalehastighet) || maalehastighet == null || isNaN(maaletid) === true || maaletid == null) {
    return null;
  } else {
    return maalehastighet * maaletid;
  }
};

export const beregnetMinimumsvolumPerProevetaking = (klassegrenseNivaa: number): number => {
  if (isNaN(klassegrenseNivaa) === true || klassegrenseNivaa == null) {
    return null;
  } else {
    return (20 / klassegrenseNivaa) * 1000;
  }
};

export const sammenlignetVerdiMellomKonsentrasjonOgKlassegrensenivaa = (
  konsentrasjon: number,
  klassegrensenivaa: number,
): boolean => {
  if (
    isNaN(konsentrasjon) === true ||
    konsentrasjon == null ||
    isNaN(klassegrensenivaa) === true ||
    klassegrensenivaa == null
  ) {
    return null;
  } else if (konsentrasjon <= klassegrensenivaa) {
    return true;
  } else {
    return false;
  }
};

export const PARTIKKELSTOERRELSER_GMP: IValueLabel[] = [
  {
    label: "0,5",
    value: "size05",
  },
  {
    label: "5",
    value: "size5",
  },
];

export const PARTIKKELSTOERRELSER_ISO: IValueLabel[] = [
  {
    label: "0,1",
    value: "size01",
  },
  {
    label: "0,2",
    value: "size02",
  },
  {
    label: "0,3",
    value: "size03",
  },
  {
    label: "0,5",
    value: "size05",
  },
  {
    label: "1",
    value: "size1",
  },
  {
    label: "5",
    value: "size5",
  },
];

export const maalepunktTabellverdi = (areal: number): number => {
  const maalepunktITabell = MAALEPUNKTER_TABELL.find((item) => item.areal >= areal);
  if (maalepunktITabell != null) {
    return maalepunktITabell.maalepunkter;
  } else {
    return null;
  }
};

export const euGmpTabellverdi = (partikkelstoerrelse: string, klasse: string, romAktivitet: string): number => {
  if (partikkelstoerrelse === "" || klasse === "") {
    return null;
  }
  const partikkelstoerrelseITabell = PARTIKKELSTOERRELSER_GMP.find((item) => item.label === partikkelstoerrelse).value;
  const klasseITabell = EU_GMP_KLASSER.find((item) => item.label === klasse).value;
  let romAktivitetITabell = "";
  if (romAktivitet === "At rest" || romAktivitet === "As built") {
    romAktivitetITabell = "atRest";
  }
  if (romAktivitet === "In operation") {
    romAktivitetITabell = "inOperation";
  }
  if (partikkelstoerrelseITabell === undefined || klasseITabell === undefined || romAktivitetITabell === undefined) {
    return null;
  } else {
    return EU_GMP_TABELL[partikkelstoerrelseITabell][romAktivitetITabell][klasseITabell];
  }
};

export const isoTabellverdi = (partikkelstoerrelse: string, klasse: string): number => {
  if (partikkelstoerrelse === "" || klasse === "") {
    return null;
  }
  const klasseITabell = ISO_KLASSER.find((item) => item.label === klasse).value;
  if (partikkelstoerrelse === undefined || klasseITabell === undefined) {
    return null;
  } else {
    return ISO_TABELL[klasseITabell][partikkelstoerrelse];
  }
};
