import { IGKBruddtestResultater, IGKRapport } from "mc-shared/zod/report/GK/gkReportSchema";
import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../../types/global.types";
import { McInput } from "../../../../../../components";

const Bruddtest: React.FC<{
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
  data: IGKBruddtestResultater;
}> = ({ data, onUpdateEditData }) => {
  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Bruddtest</CardTitle>
        <div className="d-flex flex-column mt-2">
          <b>Måletiden er 60 sekunder &#40;s&#41;:</b>
        </div>
        <div className="d-flex flex-column mt-2">
          <b>Fyll inn målt hastighet i venstre hanskeport &#40;m/s&#41;:</b>
          <McInput
            value={data.inndata.maaleresultatVenstre}
            normalizeNumbers={true}
            type="text"
            onBlur={(e) =>
              onUpdateEditData("testResultater.bruddtest.inndata.maaleresultatVenstre", Number(e.target.value))
            }
            className="w-25"
          />
        </div>
        <div className="d-flex flex-column mt-4">
          <b>Fyll inn målt hastighet i høyre hanskeport &#40;m/s&#41;:</b>
          <McInput
            value={data.inndata.maaleresultatHoeyre}
            normalizeNumbers={true}
            type="text"
            onBlur={(e) =>
              onUpdateEditData("testResultater.bruddtest.inndata.maaleresultatHoeyre", Number(e.target.value))
            }
            className="w-25"
          />
        </div>
        <div className="w-50 mt-4">
          <b>Kommentar:</b>
          <McInput
            value={data.inndata.kommentar}
            onChange={(e) => onUpdateEditData("testResultater.bruddtest.inndata.kommentar", e.target.value)}
            type="textarea"
            dynamicHeight={true}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default Bruddtest;
