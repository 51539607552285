import { AccessGroupsEnum, AccessLevelsEnum } from "mc-shared/enums/enums";
import { IAccessPopulated } from "mc-shared/zod/accessSchema";
import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DropdownFilterGeneric, EditSaveAbort, McDropdown, UserList, VSpace } from "..";
import { IMinUser } from "../../../../types/global.types";

const ACCESSLEVELS: IAccessPopulatedLevelLabelAndValue[] = [
  { label: "Prosjekt", value: AccessLevelsEnum.PROJECT },
  { label: "Grupper", value: AccessLevelsEnum.GROUPS },
  { label: "Brukere", value: AccessLevelsEnum.USERS },
];
const ACCESSGROUPS: IAccessPopulatedGroupLabelAndValue[] = [
  { label: "Interne", value: AccessGroupsEnum.INTERNAL },
  { label: "Kunde", value: AccessGroupsEnum.CLIENT },
  { label: "Partner", value: AccessGroupsEnum.PARTNER },
];
interface IAccessPopulatedLevelLabelAndValue {
  label: string;
  value: AccessLevelsEnum;
}

interface IAccessPopulatedGroupLabelAndValue {
  label: string;
  value: AccessGroupsEnum;
}
export const AccessComp: React.FC<{
  access: IAccessPopulated;
  updateAccess: (newAccessLevel: IAccessPopulated) => void;
  allUsers: IMinUser[];
  isEditable?: boolean;
  editMode?: boolean;
  editCb?: (bool: boolean) => void;
  variant?: "sm" | "md";
}> = ({ access, updateAccess, allUsers, isEditable = true, editMode = false, editCb, variant = "md" }) => {
  const [modifiedAccess, setModifiedAccess] = useState<IAccessPopulated>({
    level: AccessLevelsEnum.PROJECT,
    groups: [],
    users: [],
  });

  useEffect(() => {
    setModifiedAccess({ level: access.level, groups: access?.groups || [], users: access?.users || [] });
  }, [access]);

  const _save = async () => {
    if (modifiedAccess !== access) {
      await updateAccess(modifiedAccess);
      editCb(false);
    }
  };

  const activeAccessGroups = ACCESSGROUPS.map((item) => ({
    ...item,
    isActive: modifiedAccess?.groups.includes(item.value),
  }));

  const activeAccessUsers = allUsers.map((user) => ({
    ...user,
    value: user._id,
    label: user.name,
    isActive: modifiedAccess?.users?.findIndex((i) => i._id === user._id) !== -1,
  }));
  const toggleGroup = (value: AccessGroupsEnum) => {
    const _groups = [...modifiedAccess?.groups];
    const index = _groups.indexOf(value);
    if (index === -1) {
      _groups.push(value);
    } else {
      _groups.splice(index, 1);
    }
    setModifiedAccess({ ...modifiedAccess, groups: _groups });
  };

  const toggleMember = (userId: string) => {
    let _members = [...modifiedAccess?.users];
    const index = _members.findIndex((i) => i._id === userId);
    const user = allUsers.find((i) => i._id === userId);
    if (index === -1) {
      _members.push({ _id: user._id, name: user.name });
    } else {
      _members.splice(index, 1);
    }
    setModifiedAccess({ ...modifiedAccess, users: _members });
  };

  const toggleAllMembers = (action: string) => {
    if (action === "select") {
      setModifiedAccess({ ...modifiedAccess, users: allUsers });
    } else {
      setModifiedAccess({ ...modifiedAccess, users: [] });
    }
  };

  const saveIsDisabled =
    (modifiedAccess.level === AccessLevelsEnum.USERS && modifiedAccess.users.length === 0) ||
    (modifiedAccess.level === AccessLevelsEnum.GROUPS && modifiedAccess.groups.length === 0) ||
    (modifiedAccess.level === AccessLevelsEnum.GROUPS && modifiedAccess.groups.length === 3);

  return (
    <>
      <div style={{ maxWidth: "20em" }}>
        <div className="d-flex">{editMode === false && <AccessView access={access} />}</div>
        {editMode === true && (
          <div className="d-flex flex-wrap" style={{ gap: ".5em" }}>
            <div>
              <h5>Nivå</h5>
              <McDropdown
                currentValue={ACCESSLEVELS.find((b) => b.value === modifiedAccess.level)}
                options={ACCESSLEVELS}
                displayKey="label"
                onChange={(oldValue, newValue) => setModifiedAccess({ ...modifiedAccess, level: newValue.value })}
              />
            </div>
            {modifiedAccess.level === AccessLevelsEnum.GROUPS && (
              <div>
                <h5>Grupper</h5>
                <DropdownFilterGeneric
                  color="mc-blue"
                  filterOptions={activeAccessGroups}
                  title={"velg grupper"}
                  onClick={(value) => {
                    toggleGroup(value);
                  }}
                />
              </div>
            )}
            {modifiedAccess.level === AccessLevelsEnum.USERS && (
              <div>
                <h5>Brukere</h5>
                <DropdownFilterGeneric
                  color="mc-blue"
                  enableSearch={activeAccessUsers?.length > 10}
                  filterOptions={activeAccessUsers}
                  title={"velg brukere"}
                  onClick={(value) => toggleMember(value)}
                  unSelectAll={() => toggleAllMembers("deselect")}
                  selectAll={() => toggleAllMembers("select")}
                />
              </div>
            )}
          </div>
        )}
        <VSpace />
        {editMode === true && <AccessView access={access} />}
        {modifiedAccess.level === AccessLevelsEnum.USERS && (
          <>
            <h4>Brukere</h4>
            <div className="pr-4" style={{ maxHeight: "300px", overflow: "auto" }}>
              <UserList
                addedUsers={modifiedAccess.users.map((user) => {
                  return {
                    _id: user._id,
                    name: user.name,
                    canBeRemoved: true,
                  };
                })}
                condensed
              />
            </div>
          </>
        )}
        <VSpace />
        <EditSaveAbort
          disabled={saveIsDisabled}
          editable={isEditable}
          big
          saveCb={_save}
          editMode={editMode}
          editModeCb={editCb}
        />
      </div>
    </>
  );
};

export const AccessView: React.FC<{ access: IAccessPopulated; variant?: "sm" | "md" }> = ({
  access,
  variant = "md",
}) => {
  const generateAccessText = (access: IAccessPopulated) => {
    let accessText = "";

    const groupLabels = access?.groups?.map((value) => {
      const accessGroup = ACCESSGROUPS.find((group) => group.value === value);
      return accessGroup.label.toLowerCase();
    });

    if (access.level === AccessLevelsEnum.PROJECT) {
      accessText = "Alle i prosjektet";
    } else if (access.level === AccessLevelsEnum.GROUPS) {
      const resultString = `${groupLabels?.sort((a, b) => a.localeCompare(b)).join(" og ")}`;
      accessText = resultString.charAt(0).toUpperCase() + resultString.slice(1);
    } else if (access.level === AccessLevelsEnum.USERS) {
      accessText = "Spesifikke brukere";
    }

    if (variant !== "sm") {
      accessText += " har tilgang";
    }

    return accessText;
  };

  const generatedText = generateAccessText(access);
  return (
    <div
      className="d-flex align-items-center justify-content-between w-100"
      style={{ fontSize: variant === "sm" ? "0.8rem" : "1em" }}
    >
      {access.level === AccessLevelsEnum.PROJECT && (
        <div>
          <i className="fa fa-unlock mr-2 text-success" aria-hidden="true"></i>
          {generatedText}
        </div>
      )}
      {access.level === AccessLevelsEnum.USERS && (
        <div>
          <i className="fa fa-lock mr-2 text-danger" aria-hidden="true"></i>
          {generatedText}
        </div>
      )}
      {access.level === AccessLevelsEnum.GROUPS && (
        <div>
          <i className="fa fa-users mr-2 text-warning" aria-hidden="true"></i>
          {generatedText}
        </div>
      )}
    </div>
  );
};

interface AccessHandlerProps {
  isEditable: boolean;
  allUsers: IMinUser[];
  access: IAccessPopulated;
  updateAccess: (newAccessLevel: IAccessPopulated) => void;
}

interface AccessHandlerContainerProps extends AccessHandlerProps {
  editMode: boolean;
}

const AccessHandlerModal: React.FC<AccessHandlerContainerProps> = ({ access, updateAccess, allUsers, editMode }) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  return (
    <div>
      {editMode === false ? (
        <div className="gray-600-text">
          <AccessView access={access} />
        </div>
      ) : (
        <Button color="primary" onClick={() => setOpenModal(true)}>
          <AccessView access={access} />
        </Button>
      )}
      <Modal isOpen={openModal} toggle={() => setOpenModal(false)}>
        <ModalHeader toggle={() => setOpenModal(false)}>Endre tilgang</ModalHeader>
        <ModalBody>
          <Card>
            <CardBody>
              <div>
                <AccessComp
                  access={access}
                  updateAccess={updateAccess}
                  allUsers={allUsers}
                  editMode={true}
                  editCb={setOpenModal}
                />
              </div>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    </div>
  );
};
export default AccessHandlerModal;
