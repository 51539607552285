import { debounce, set } from "lodash";
import { AccessLevelsEnum } from "mc-shared/enums/enums";
import { IAccess, IAccessPopulated } from "mc-shared/zod/accessSchema";
import * as R from "ramda";
import React, { useEffect, useRef, useState } from "react";
import { Prompt, useHistory } from "react-router-dom";
import { Button, Modal, ModalBody, ModalHeader, UncontrolledTooltip } from "reactstrap";
import snarkdown from "snarkdown";
import { IProjectDashboard } from "../../../../../types/global.types";
import { apiSlice } from "../../../apiSlice";
import { ConfirmDialog, DropdownFilter, HSpace, McDropdown, McInput, MCLoader, VSpace } from "../../../components/";
import AccessHandlerModal from "../../../components/AccessComp/AccessComp";
import CustomGrid, { allWidgetsArray, IWidgetTemplate } from "../../../components/CustomGrid/CustomGrid";
import { MCApplicationsUrlEnum } from "../../../frontendConstants";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { sortArrayByString } from "../../utils";
import { doLogUsage } from "../admin/restQueries";
import {
  useDoRemoveLayoutMutation,
  useDoSaveNewLayoutMutation,
  useDoUpdateLayoutMutation,
  useFetchLayoutsQuery,
} from "./ProjectDashboardRTK";
import { dashboardInfo } from "./projectDashboardConstants";
import { setProjectDashboardActiveLayoutInRedux, setProjectDashBoardEditModeInRedux } from "./projectDashboardSlice";
import { selectUserMember } from "../../../global/globalSlice";

export const getLayoutById = (allLayouts: IProjectDashboard[], layoutId: string) => {
  return allLayouts && allLayouts.find((item) => item._id === layoutId);
};

const ProjectDashboard: React.FC = () => {
  const project = useAppSelector((state) => state.adminReducer.project);
  const editMode = useAppSelector((state) => state.projectDashboardReducer.editMode);
  const activeLayout = useAppSelector((state) => state.projectDashboardReducer.activeLayout);
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
  const [showNewModal, setShowNewModal] = useState<boolean>(false);
  const [allWidgets, setAllWidgets] = useState<IWidgetTemplateDropdown[]>([]);
  const [errorFlag, setErrorFlag] = useState<string>("");
  const [disablePrompt, setDisablePrompt] = useState<boolean>(false);
  const previousLayout = useRef(null);
  const dispatch = useAppDispatch();
  const userMember = useAppSelector(selectUserMember);
  const [doSaveNewLayout, { data: _newLayout }] = useDoSaveNewLayoutMutation();
  const [doUpdateLayout] = useDoUpdateLayoutMutation();
  const [doRemoveLayout] = useDoRemoveLayoutMutation();
  const { data: allLayouts, isFetching } = useFetchLayoutsQuery(project._id);
  const sortedLayouts: IProjectDashboard[] = sortArrayByString(allLayouts, "title");
  interface IWidgetTemplateDropdown extends IWidgetTemplate {
    isActive: boolean;
    value: string;
  }

  const history = useHistory();

  useEffect(() => {
    doLogUsage(project, MCApplicationsUrlEnum.DASHBOARD, "open");
    if (activeLayout?._id != null) {
      dispatch(setProjectDashBoardEditModeInRedux(false));
    }
  }, []);

  const getLayoutIdFromUrl = () => {
    const indexOfId = history.location.search.indexOf("layout");
    if (indexOfId === -1) {
      return null;
    }
    const indexOf = history.location.search.indexOf("=");
    const id = history.location.search.substring(indexOf + 1);
    return id;
  };

  useEffect(() => {
    if (allLayouts?.length > 0 && activeLayout == null) {
      const layoutIdFromUrl = getLayoutIdFromUrl();
      const layout = getLayoutById(allLayouts, layoutIdFromUrl);

      if (layout != null) {
        dispatch(setProjectDashboardActiveLayoutInRedux(layout));
        return;
      }

      const activeLayout = allLayouts.find((item) => item.isProjectDefault === true);
      dispatch(setProjectDashboardActiveLayoutInRedux(activeLayout));
    } else {
      if (activeLayout?._id) {
        dispatch(setProjectDashboardActiveLayoutInRedux(getLayoutById(allLayouts, activeLayout._id)));
      }
    }
  }, [allLayouts]);

  useEffect(() => {
    if (allWidgets.length === 0) {
      const filteredByProject = allWidgetsArray.filter((item) =>
        item.hasOwnProperty("limitToProject") ? item.limitToProject === project._id : true,
      );
      setAllWidgets(filteredByProject.map((item) => ({ ...item, value: item.i, isActive: false })));
    }
  }, [allWidgets]);
  useEffect(() => {
    if (activeLayout != null && editMode === true) {
      const layoutGrid = activeLayout.layoutGrid;
      const updateAllWidgets = allWidgets.map((obj) => {
        const exists = layoutGrid.some((item) => item.i === obj.i);
        exists ? (obj.isActive = true) : (obj.isActive = false);
        return obj;
      });
      setAllWidgets(updateAllWidgets);
    }
  }, [activeLayout, editMode]);

  const onLocationChange = () => {
    return "Du har ulagrede endringer, er du sikker på at du vil forlate siden?";
  };

  const setQueryStringWithoutPageReload = (queryParamKey: string, qsValue: string) => {
    const queryString = `${history.location.pathname}?${queryParamKey}=${qsValue}`;
    if (qsValue != null && history.location.pathname + history.location.search !== queryString) {
      history.push(queryString);
    }
  };

  const handleRemoveWidget = (key: string) => {
    const updateLayoutGrid = activeLayout.layoutGrid.filter((obj) => {
      return obj.i !== key;
    });
    const layout = { ...activeLayout, layoutGrid: updateLayoutGrid };
    dispatch(setProjectDashboardActiveLayoutInRedux(layout));
  };

  const handleAddWidget = (item) => {
    const updateLayoutGrid = [...activeLayout.layoutGrid];
    let copyOfItem = R.clone(item);
    copyOfItem.isActive = true;
    copyOfItem.i = item.value;
    updateLayoutGrid.push(copyOfItem);
    const layout = { ...activeLayout, layoutGrid: updateLayoutGrid };
    dispatch(setProjectDashboardActiveLayoutInRedux(layout));
  };

  const handleNewLayout = async (newLayout) => {
    setShowNewModal(false);
    previousLayout.current = activeLayout;
    dispatch(setProjectDashBoardEditModeInRedux(true));
    dispatch(setProjectDashboardActiveLayoutInRedux(newLayout));
  };

  const handleToggleEditMode = () => {
    previousLayout.current = activeLayout;
    dispatch(setProjectDashBoardEditModeInRedux(true));
  };

  const handleAddRemoveWidget = (value: string) => {
    const item = allWidgets.find((obj) => {
      return obj.value === value;
    });
    if (item.isActive === true) {
      handleRemoveWidget(item.value);
    } else {
      handleAddWidget(item);
    }
  };

  const handleCancel = () => {
    setErrorFlag(null);

    dispatch(setProjectDashboardActiveLayoutInRedux({ ...previousLayout.current, layoutGrid: [] }));
    //force layout grid rerender rerender
    setTimeout(() => {
      dispatch(setProjectDashboardActiveLayoutInRedux({ ...previousLayout.current }));
    }, 50);
    dispatch(apiSlice.util.invalidateTags(["ProjectDashboard"]));

    dispatch(setProjectDashBoardEditModeInRedux(false));
  };

  const saveLayout = async () => {
    try {
      if (allLayouts.some((item) => item.title === activeLayout.title && item._id !== activeLayout._id)) {
        return setErrorFlag("editModeTitle");
      }
      if (activeLayout?._id) {
        doUpdateLayout({ projectId: project._id, newLayout: activeLayout });
      } else {
        //save new layout
        const res = await doSaveNewLayout({ projectId: project._id, newLayoutAccessPopulated: activeLayout }).unwrap();
        if (res) {
          setDisablePrompt(true);
          setQueryStringWithoutPageReload("layout", res._id);
          dispatch(setProjectDashboardActiveLayoutInRedux(res));
        }
      }
    } catch (error) {
      console.log("error", error);
      dispatch(setProjectDashboardActiveLayoutInRedux(previousLayout.current));
    }
    return dispatch(setProjectDashBoardEditModeInRedux(false));
  };

  const goToLayout = (layoutId: string) => {
    history.replace(`/project/${project._id}/dashboard?layout=${layoutId}`);
  };

  const handleRemoveLayout = async () => {
    const resp = await doRemoveLayout({ projectId: project._id, layoutId: activeLayout._id });
    if (resp) {
      dispatch(setProjectDashBoardEditModeInRedux(false));
      set;
    }
  };

  const debounceValue = debounce((value) => {
    if (errorFlag === "editModeTitle") {
      setErrorFlag(null);
    }
    dispatch(setProjectDashboardActiveLayoutInRedux({ ...activeLayout, title: value }));
  }, 500);

  const onChange = (e) => {
    debounceValue(e.target.value);
  };
  const userIsCreatorOrAdmin = userMember?.group === "ADMIN" || userMember?.user._id === activeLayout?.userId;

  const handleUpdateAccess = async (updatedAccess: IAccessPopulated) => {
    if (activeLayout?._id) {
      const access: IAccess = {
        level: updatedAccess.level,
        groups: updatedAccess.groups,
        users: updatedAccess.users.map((user) => user._id),
      };
      dispatch(setProjectDashboardActiveLayoutInRedux({ ...activeLayout, access: access }));
    } else {
      dispatch(setProjectDashboardActiveLayoutInRedux({ ...activeLayout, access: updatedAccess }));
    }
  };

  const changeLayout = (layoutId: string) => {
    goToLayout(layoutId);
    dispatch(setProjectDashboardActiveLayoutInRedux(getLayoutById(allLayouts, layoutId)));
  };

  const CreateNewModal: React.FC<{
    isOpen: boolean;
    toggle: () => void;
    createNew: (newLayout: IProjectDashboard) => void;
  }> = ({ isOpen, toggle, createNew }) => {
    const [newTitle, setNewTitle] = useState<string>("");
    const [selectedLayout, setSelectedLayout] = useState<IProjectDashboard>(null);
    const [access, setAccess] = useState<IAccessPopulated>({
      level: AccessLevelsEnum.PROJECT,
      groups: [],
      users: [],
    });

    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>NY DASHBOARD</ModalHeader>
        <div className="border">
          <div className="m-4">
            <McInput
              autoFocus
              onChange={(t) => setNewTitle(t.target.value)}
              value={newTitle}
              placeholder="Skriv inn navn på dashboard"
            />
            <VSpace />
            <McDropdown
              onChange={(_, layout) => setSelectedLayout(layout)}
              options={sortedLayouts}
              displayKey="title"
              defaultTitle={allLayouts?.length > 0 ? "Kopier innstillinger fra..." : "Ingen dashboard å kopiere fra"}
              currentValue={selectedLayout}
            />
            <VSpace />
            <div className="d-flex justify-content-between">
              <AccessHandlerModal
                allUsers={project.members.map((m) => m.user)}
                isEditable={true}
                access={access}
                updateAccess={(updatedAccess) => setAccess(updatedAccess)}
                editMode={true}
              />
              <Button
                className="d-flex align-items-center"
                color="success"
                onClick={() =>
                  createNew({
                    ...selectedLayout,
                    title: newTitle,
                    _id: null,
                    isMCAppsPreset: false,
                    isProjectDefault: false,
                    layoutGrid: selectedLayout?.layoutGrid || [],
                    access: access,
                  })
                }
              >
                <i className="fa fa-floppy-o" />
                <HSpace />
                Opprett
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <>
      {<Prompt when={editMode && disablePrompt === false} message={onLocationChange} />}
      <div className="p-2 h-100 w-100 flex-column d-flex position-relative" style={{ background: "#f7f7f7" }}>
        <div
          className="border bg-white d-flex"
          style={{ borderRadius: "6px", margin: " 0 10px 0px 10px", padding: ".8rem" }}
        >
          <div>
            <h1 style={{ fontWeight: 400, fontSize: "1.65rem", marginBottom: "0.2rem" }}>
              Velkommen til {project.maconomy.name}
            </h1>
            <div className="gray-400-text">
              <i>{project.members.length} prosjektmedlemmer</i>
            </div>
          </div>
          <div className="flex-fill" />
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-end align-items-center h-50 mb-1">
              {activeLayout?.access && activeLayout?.isProjectDefault !== true && (
                <AccessHandlerModal
                  allUsers={project.members.map((m) => m.user)}
                  isEditable={true}
                  access={activeLayout?.access as IAccessPopulated}
                  updateAccess={(updatedAccess) => handleUpdateAccess(updatedAccess)}
                  editMode={editMode}
                />
              )}
              <HSpace />
              {editMode ? (
                <div className="d-flex">
                  <div>
                    <McInput placeholder="Tittel" id="editModeTitle" value={activeLayout?.title} onChange={onChange} />
                    {errorFlag === "editModeTitle" && (
                      <p className="text-danger" style={{ position: "absolute", fontSize: ".9em" }}>
                        Navn er allerede i bruk!
                      </p>
                    )}
                  </div>
                  <HSpace />

                  <DropdownFilter
                    color="mc-blue"
                    filterOptions={allWidgets}
                    title={"velg widgets"}
                    onClick={(title, value) => {
                      handleAddRemoveWidget(value);
                    }}
                  />
                  <HSpace />
                  <Button
                    style={{ height: "33px" }}
                    color="success"
                    size="sm"
                    onClick={() => saveLayout()}
                    disabled={activeLayout?.title === ""}
                  >
                    <i className="fa fa-floppy-o fa-fw" />
                  </Button>
                  <HSpace />
                  <Button
                    id="closeButton"
                    style={{ height: "33px" }}
                    color="secondary"
                    size="sm"
                    onClick={() => handleCancel()}
                  >
                    <i className="fa fa-close fa-fw" />
                  </Button>
                  <UncontrolledTooltip target="closeButton">Avbryt</UncontrolledTooltip>
                </div>
              ) : (
                <>
                  <McDropdown
                    color="primary"
                    currentValue={activeLayout}
                    options={sortedLayouts}
                    displayKey="title"
                    onChange={(oldValue, newValue) => changeLayout(newValue._id)}
                  />
                </>
              )}
              {editMode === false && (
                <>
                  <div id="editButton">
                    <Button
                      onClick={() => handleToggleEditMode()}
                      disabled={editMode}
                      color="secondary"
                      style={{ padding: ".9em 1.2em", marginLeft: ".5em" }}
                    >
                      <i className="fa fa-pencil fa-lg" />
                    </Button>
                  </div>
                </>
              )}
              <Modal
                style={{ maxWidth: "60vw", maxHeight: "90%" }}
                isOpen={showInfoModal}
                toggle={() => setShowInfoModal(!showInfoModal)}
              >
                <ModalHeader toggle={() => setShowInfoModal(!showInfoModal)}>Konfigurer Dashboard</ModalHeader>
                <ModalBody>
                  <div dangerouslySetInnerHTML={{ __html: snarkdown(dashboardInfo) }} />
                </ModalBody>
              </Modal>
              <CreateNewModal isOpen={showNewModal} toggle={() => setShowNewModal(false)} createNew={handleNewLayout} />
            </div>
            <div className="d-flex justify-content-end" style={{ marginBottom: "-20px", marginTop: "4px" }}>
              {editMode === false && (
                <>
                  <Button
                    id="addNewButton"
                    color="success"
                    outline
                    className=" btn-md"
                    style={{ padding: "0.2rem" }}
                    onClick={() => setShowNewModal(true)}
                  >
                    <i className="fa fa-plus fa-fw fa-lg" style={{ marginTop: "2px" }} />
                  </Button>
                  {userIsCreatorOrAdmin && (
                    <ConfirmDialog
                      title="Slette oppsett?"
                      message="Er du sikker på at du vil slette dette oppsettet?"
                      confirmCb={() => handleRemoveLayout()}
                    >
                      <Button id="deleteButton" outline color="secondary" style={{ padding: "0.4rem" }}>
                        <i className="fa fa-trash fa-lg" />
                      </Button>
                    </ConfirmDialog>
                  )}
                </>
              )}
              <Button
                id="navbarSupportButton"
                color="secondary"
                outline
                className=" btn-md"
                style={{ padding: "0.4rem" }}
                onClick={() => setShowInfoModal(true)}
              >
                <i className="fa fa-question-circle fa-lg" />
              </Button>
              {userMember?.group === "ADMIN" && (
                <>
                  <Button
                    id="settingsButton"
                    outline
                    onClick={() => history.push(`/project/${project?._id}/settings/general`)}
                    color="secondary"
                    style={{ padding: "0.4rem" }}
                  >
                    <i className="fa fa-gear fa-lg" />
                  </Button>

                  <UncontrolledTooltip target="settingsButton" placement="bottom" delay={300}>
                    Dashboard-innstillinger
                  </UncontrolledTooltip>
                </>
              )}
            </div>
          </div>
        </div>
        <div
          className="flex-fill overflow-auto"
          style={{
            outline: `${editMode ? "4px dashed #ec5c5c" : ""}`,
            borderRadius: "7px",
          }}
        >
          {isFetching && <MCLoader loading={isFetching} />}
          {activeLayout != null && isFetching === false && (
            <CustomGrid
              layoutGrid={activeLayout?.layoutGrid}
              setLayout={(grid) =>
                dispatch(setProjectDashboardActiveLayoutInRedux({ ...activeLayout, layoutGrid: grid }))
              }
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ProjectDashboard;
