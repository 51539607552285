import { IGKMaalingAvAvtrekksmengdeRutinekontrollResultater, IGKRapport } from "mc-shared/zod/report/GK/gkReportSchema";
import { useEffect } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../../types/global.types";
import { McInput } from "../../../../../../components";
import { beregnetLuftmengdeGjennomLukeaapning } from "../gkGlobalUtils";

const MaalingAvAvtrekksmengdeRutinekontroll: React.FC<{
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
  data: IGKMaalingAvAvtrekksmengdeRutinekontrollResultater;
}> = ({ data, onUpdateEditData }) => {
  useEffect(() => {
    utdataBeregninger();
  }, [data.inndata.maaleresultat, data.inndata.arealLukeaapning]);

  const utdataBeregninger = () => {
    const avtrekksLuftmengde = beregnetLuftmengdeGjennomLukeaapning(
      data.inndata.maaleresultat,
      data.inndata.arealLukeaapning,
    );
    const utdata = {
      avtrekksLuftmengde: avtrekksLuftmengde,
    };
    onUpdateEditData("testResultater.maalingAvAvtrekksmengdeRutinekontroll.utdata", utdata);
  };

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Måling av avtrekksmengde ved rutinekontroll</CardTitle>
        <div className="d-flex flex-column mt-0 mb-4">
          <b>
            Fyll inn lukeåpningsareal &#40;m<sup>2</sup>&#41;:
          </b>
          <McInput
            value={data.inndata.arealLukeaapning}
            onBlur={(e) =>
              onUpdateEditData(
                "testResultater.maalingAvAvtrekksmengdeRutinekontroll.inndata.arealLukeaapning",
                Number(e.target.value),
              )
            }
            normalizeNumbers={true}
            type="text"
            className="w-25"
          />
        </div>
        <div className="d-flex flex-column mt-2">
          <b>Måletiden er 60 sekunder &#40;s&#41;:</b>
        </div>
        <div className="mt-2 d-flex flex-column">
          <b>Fyll inn gjennomsnittlig hastighet &#40;m/s&#41;:</b>
          <McInput
            value={data.inndata.maaleresultat}
            onBlur={(e) =>
              onUpdateEditData(
                "testResultater.maalingAvAvtrekksmengdeRutinekontroll.inndata.maaleresultat",
                Number(e.target.value),
              )
            }
            normalizeNumbers={true}
            type="text"
            className="w-25"
          />
        </div>
        <div className="d-flex justify-content-between">
          <p className="mb-0">
            Avtrekksluftmengde &#40;m<sup>3</sup>/s&#41; ={" "}
            <b>
              {beregnetLuftmengdeGjennomLukeaapning(data.inndata.maaleresultat, data.inndata.arealLukeaapning)?.toFixed(
                3,
              ) ?? ""}
            </b>
          </p>
          <p className="mb-0">Formel: &#40;Q = Va * Aluke&#41;</p>
        </div>
        <hr className="mt-0" />
        <div className="w-50 mt-4">
          <b>Kommentar:</b>
          <McInput
            value={data.inndata.kommentar}
            onChange={(e) =>
              onUpdateEditData("testResultater.maalingAvAvtrekksmengdeRutinekontroll.inndata.kommentar", e.target.value)
            }
            type="textarea"
            dynamicHeight={true}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default MaalingAvAvtrekksmengdeRutinekontroll;
