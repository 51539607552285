import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { Button, ButtonGroup, Modal, ModalBody, ModalHeader, UncontrolledTooltip } from "reactstrap";
import { IEmbeddedLink } from "../../../../../../types/global.types";
import { AddButton, HSpace, McInput } from "../../../../components";
import WidgetWrapper from "../../../../components/WidgetWrapper/WidgetWrapper";
import { EmbedTypeEnum } from "../../../../frontendConstants";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { useSaveEmbeddedLinksMutation } from "../ProjectDashboardRTK";
import { setProjectDashboardActiveLayoutInRedux } from "../projectDashboardSlice";
import { setShowSuccessMessageThunk } from "../../../../global/globalSlice";

const EmbeddedLinkWidget: React.FC<{ embedType?: EmbedTypeEnum }> = ({ embedType }) => {
  const project = useAppSelector((state) => state.adminReducer.project);
  const layout = useAppSelector((state) => state.projectDashboardReducer.activeLayout);
  const dispatch = useAppDispatch();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [embeddedLinks, setEmbeddedLinks] = useState<IEmbeddedLink[]>([]);
  const [activeLink, setActiveLink] = useState<number>(0);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [editModeLinks, setEditModeLinks] = useState<IEmbeddedLink[]>([]);
  const [activePreview, setActivePreview] = useState<number>(0);
  const isVideoFeed = embedType === EmbedTypeEnum.VIDEOFEED ? true : false;
  const [saveEmbeddedLinks] = useSaveEmbeddedLinksMutation();
  //filteredByType allows for multiple embed widgets, using the same component
  const filteredByType = embeddedLinks.filter((link) => {
    if (embedType === EmbedTypeEnum.EMBEDDEDLINK) {
      return link?.embedType === EmbedTypeEnum.EMBEDDEDLINK || !link?.embedType;
    } else {
      return link?.embedType === embedType;
    }
  });
  const notFilteredByType = embeddedLinks.filter((link) => !filteredByType.includes(link));
  useEffect(() => {
    if (layout?.widgetData?.embeddedLinks) {
      setEmbeddedLinks(layout.widgetData.embeddedLinks);
    }
  }, [layout]);

  useEffect(() => {
    if (editMode === true) {
      return setEditModeLinks(filteredByType);
    }
    return setEditModeLinks([]);
  }, [editMode]);

  const save = async () => {
    const combinedLinks = [...notFilteredByType, ...editModeLinks];
    if (layout._id != null) {
      await saveEmbeddedLinks({ projectId: project._id, links: combinedLinks, layoutId: layout._id });
    }
    dispatch(setShowSuccessMessageThunk(isVideoFeed === true ? "Byggeplasskamera lagret" : "Appkobling lagret"));

    //updates the layout in redux in case user changes layout w/o refreshing
    const updatedLayout = { ...layout, widgetData: { ...layout.widgetData, embeddedLinks: combinedLinks } };
    dispatch(setProjectDashboardActiveLayoutInRedux(updatedLayout));
    setEditMode(false);
  };

  const handleCloseModal = async () => {
    await save();
    setEditMode(false);
  };

  const edit = debounce((key: string, index: number, value: any) => {
    const _links = [...editModeLinks];
    if (_links[index]) {
      const updatedLink = { ..._links[index], [key]: value };
      _links[index] = updatedLink;
    } else {
      _links.push({ name: "", url: "", embedType: embedType });
    }
    setEditModeLinks(_links);
  }, 200);
  const onChange = (key: string, index: number, value: any) => {
    edit(key, index, value);
  };

  const add = () => {
    const _links = [...editModeLinks];
    _links.push({
      name: "",
      url: "",
      embedType: embedType,
    });
    setEditModeLinks(_links);
  };

  const remove = (index: number) => {
    const _links = [...editModeLinks];
    _links.splice(index, 1);
    setEditModeLinks(_links);
  };

  const DisplayPreview: React.FC<{ src: string; fitWidth?: boolean }> = ({ src, fitWidth = false }) => {
    if (src && src.includes("byggekamera.no")) {
      return <img src={src} className={`${fitWidth ? "w-100" : "h-100"}`} />;
    } else {
      return (
        <iframe
          style={{ minHeight: `${fitWidth ? "70vh" : "100%"}` }}
          width="100%"
          src={src}
          frameBorder="0"
          scrolling="yes"
          allow="fullscreen; clipboard-read; clipboard-write"
          allowFullScreen
        ></iframe>
      );
    }
  };

  //removes anything that is not a url, useful for when embed links give the whole iframe code instead of just the url
  const regexp =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?!&//=]*)/gi;

  const filteredUrl = (url) => {
    const isValidUrl = url.match(regexp)?.toString();
    if (isValidUrl) {
      return isValidUrl.includes("miro.com") ? isValidUrl + "&embedAutoplay=true&autoplay=yep" : isValidUrl;
    } else return "";
  };

  const validLinks = editModeLinks.filter((link) => link.name.length > 0 && filteredUrl(link.url).length > 0);
  const linksMap = editModeLinks.map((link, index) => {
    return (
      <div key={index} className="d-flex mb-2 w-100">
        <McInput
          placeholder="Tittel"
          style={{ width: "40%" }}
          value={link.name}
          onChange={(e) => onChange("name", index, e.target.value)}
        />
        <HSpace />
        <McInput
          style={{ outline: `${filteredUrl(link.url).length < 1 ? "1px solid red" : ""}` }}
          placeholder="Lim embed-url:"
          value={link.url}
          onChange={(e) => onChange("url", index, filteredUrl(e.target.value))}
        />
        <Button color="danger" size="sm" outline className="ml-1" onClick={() => remove(index)}>
          <i className="fa fa-trash fa-fw" />
        </Button>
      </div>
    );
  });

  const AppButtonsHeader: React.FC<{ color?: string }> = ({ color }) => {
    return (
      <>
        {filteredByType.length > 0 ? (
          <>
            <ButtonGroup className="w-100 mb-1 mr-1">
              {filteredByType.map((link, index) => (
                <Button
                  className="d-flex justify-content-center"
                  key={index}
                  style={{ minWidth: "40px", maxWidth: "150px" }}
                  active={activeLink === index}
                  color={activeLink === index ? color : "secondary"}
                  onClick={() => setActiveLink(index)}
                >
                  <div className="text-truncate">{link.name}</div>
                </Button>
              ))}
            </ButtonGroup>
          </>
        ) : (
          <h4 className="m-1">{isVideoFeed === true ? "Byggeplasskamera" : "Eksterne Apper"}</h4>
        )}
      </>
    );
  };
  return (
    <WidgetWrapper
      widgetKey={embedType}
      customTitle={<AppButtonsHeader />}
      onFullscreenClick={filteredByType.length > 0 ? () => setOpenModal(true) : null}
      onShowSettingsClick={() => setEditMode(!editMode)}
    >
      <>
        <div className="h-100 w-100 d-flex justify-content-center overflow-auto">
          {filteredByType.length > 0 ? (
            <>{editMode === false && <DisplayPreview src={filteredByType[activeLink]?.url} />}</>
          ) : (
            <div
              className="d-flex justify-content-center text-center align-items-center h-100 flex-column "
              onMouseDown={(e) => e.stopPropagation()}
            >
              <p>Ingen nettsider er koblett! Klikk her for å håndtere visninger:</p>
              <Button className="mc-blue text-white " style={{ zIndex: 3 }} onClick={() => setEditMode(true)}>
                Håndtere {isVideoFeed === true ? "visninger" : "Eksterne Apper"}
              </Button>
            </div>
          )}
        </div>
        {editMode && (
          <>
            <Modal style={{ maxWidth: "60vw" }} isOpen={editMode} toggle={handleCloseModal}>
              <ModalHeader toggle={() => handleCloseModal()}>
                Håndtere {isVideoFeed === true ? "visninger" : "Eksterne Apper"}
              </ModalHeader>
              <ModalBody style={{ minHeight: "200px" }}>
                <div className="d-flex flex-column align-items-center">
                  {linksMap}
                  <div className=" w-100 d-flex align-items-baseline">
                    <div className="d-flex align-items-baseline">
                      <div>Legg til {isVideoFeed ? "visning" : "appkobling"}</div>
                      <HSpace />
                      <AddButton onClick={() => add()} />
                      <div className="flex-fill" />
                    </div>
                    <div className="flex-fill" />
                    <Button
                      className="ml-2"
                      disabled={validLinks.length != editModeLinks.length}
                      onClick={() => handleCloseModal()}
                      color="success"
                      size="md"
                    >
                      <i className="fa fa-floppy-o fa-fw" />
                    </Button>
                  </div>
                  {editModeLinks.length > 0 ? (
                    <div className="d-flex flex-column h-100 w-100 mt-3">
                      <ButtonGroup className="w-100 mb-2">
                        {validLinks.map((link, index) => (
                          <Button
                            className="d-flex justify-content-center"
                            key={index}
                            style={{ minWidth: "120px", maxWidth: "fit-content" }}
                            active={activePreview === index}
                            onClick={() => setActivePreview(index)}
                          >
                            <div className="text-truncate">{link.name}</div>
                          </Button>
                        ))}
                      </ButtonGroup>
                      <div className="flex-grow-1 w-100">
                        <DisplayPreview src={validLinks[activePreview]?.url} fitWidth={true} />
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center text-center align-items-center h-100 flex-column ">
                      {isVideoFeed === true ? (
                        <p className="w-75 m-4">
                          For å integrere et byggeplasskamera, klikk på plussknappen og fyll ut appens URL, en tittel og
                          en valgfri beskrivelse. URL for innebygde apper kan vanligvis finnes på nettstedet til
                          appleverandøren.
                        </p>
                      ) : (
                        <p className="w-75 m-4">
                          {
                            "Eksterne apper kan integreres for å ha enkel tilgang til tilleggsinformasjon uten å forlate MCApps, for eksempel "
                          }
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://learn.microsoft.com/en-us/power-bi/developer/embedded/embed-customer-app#workspace-id"
                          >
                            PowerBI-dashboard
                          </a>
                          {", "}
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://help.miro.com/hc/en-us/articles/360016335640-How-to-embed-Miro-boards-"
                          >
                            Miro-tavler
                          </a>
                          {" eller et "}
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://support.mural.co/en/articles/6976865-embed-view-only-murals"
                          >
                            Mural-workspace
                          </a>
                          {
                            ". For å integrere en ekstern app, klikk på plussknappen og fyll ut appens URL, en tittel og en valgfri beskrivelse. URL for innebygde apper kan vanligvis finnes på nettstedet til appleverandøren."
                          }
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </ModalBody>
            </Modal>
          </>
        )}
        {openModal === true && (
          <Modal style={{ maxWidth: "80vw", height: "auto" }} isOpen={openModal} toggle={() => setOpenModal(false)}>
            <ModalHeader toggle={() => setOpenModal(false)}>
              <div className="d-flex justify-content-between w-100">
                <AppButtonsHeader color="primary" />
                <Button
                  id="linkbtn"
                  color="secondary"
                  style={{ fontSize: ".9em" }}
                  onClick={() => {
                    window.open(filteredByType[activeLink]?.url, "_blank");
                  }}
                >
                  <i className="fa fa-external-link-square fa-fw" />
                </Button>
                <UncontrolledTooltip delay={300} target={"linkbtn"}>
                  Åpne i ny fane
                </UncontrolledTooltip>
              </div>
            </ModalHeader>

            <ModalBody>
              <div className="gray-100" style={{ height: "auto", borderRadius: "6px" }}>
                <DisplayPreview src={filteredByType[activeLink]?.url} fitWidth={true} />
              </div>
            </ModalBody>
          </Modal>
        )}
      </>
    </WidgetWrapper>
  );
};

export default EmbeddedLinkWidget;
