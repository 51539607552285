import React, { useRef } from "react";
import { Button, UncontrolledTooltip } from "reactstrap";

const CreateNewButton: React.FC<{
  onClick: () => void;
  label?: string;
  disabled?: boolean;
  tooltip?: string;
  size?: string;
  customIcon?: string;
  outline?: boolean;
}> = ({ onClick, label, disabled, tooltip, size = "md", customIcon, outline = false }) => {
  const tooltipTarget = useRef();

  return (
    <>
      <div className="d-flex">
        <Button
          innerRef={tooltipTarget}
          color="success"
          outline={outline}
          size={size}
          onClick={() => onClick()}
          disabled={disabled}
        >
          <i className={`fa fa-fw ${customIcon != null ? customIcon : "fa-plus"}`} />
          {label}
        </Button>
      </div>
      {tooltip != null && (
        <UncontrolledTooltip target={tooltipTarget} delay={200}>
          {tooltip}
        </UncontrolledTooltip>
      )}
    </>
  );
};

export default CreateNewButton;
