import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { IColumnTemplate, IRisikoEvaluationCategory, IRiskoBoard } from "../../../../../types/global.types";
import { AppModalEnums, MCApplicationsUrlEnum } from "../../../frontendConstants";
import { sortArrayByNumber } from "../../utils";
import { doLogUsage } from "../admin/restQueries";
import RisikoStats from "./Dashboard/RisikoStats";
import LastStatusEntries from "./LastStatusEntries";
import { getEvaluationColumns, getRisikoColumns } from "./RisikoColumns";
import RisikoTabs from "./RisikoTabs";
import RisikoTab, { RisikoScoreFormatter } from "./Risikotab/RisikoTab";
import {
  fetchAndSetRisikoDocxTemplates,
  fetchRisikoAndReplaceItInRedux,
  fetchRisikosAndSetInRedux,
  resetRisikoRedux,
  setActiveRisikoBoard,
  setRisikoBoardsInRedux,
  setRisikoCategoriesInRedux,
  setSelectedRisikoInRedux,
} from "./actions";
import { RisikoItemTabEnum } from "./reducer";
import { doGetRisikoBoards, doGetRisikoEvaluationCategories, doGetSingleRisikoBoard } from "./risikoAPI";
import RisikoAdmin from "./settings/Risikoadmin";
import { createRisikoTagMultifilter } from "./util";
import TiltakPageNew from "./TiltakPageNew";
import { Alert } from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { addAppModalThunk, setShowSuccessMessageThunk } from "../../../global/globalSlice";

const RisikoApp: React.FC<{ risikoBoardId: string }> = ({ risikoBoardId }) => {
  const [columns, setColumns] = useState(null);
  const user = useAppSelector((state) => state.globalReducer.user);
  const project = useAppSelector((state) => state.adminReducer.project);

  const boards = useAppSelector((state) => state.risikoReducer.boards);
  const activeBoard = useAppSelector((state) => state.risikoReducer.activeBoard);
  const activeTab = useAppSelector((state) => state.risikoReducer.activeTab);
  const categories = useAppSelector((state) => state.risikoReducer.categories);
  const items = useAppSelector((state) => state.risikoReducer.items);
  const activeColumnTemplate = useAppSelector((state) => state.risikoReducer.activeColumnTemplate);
  const isTemplateBoard = activeBoard?.template?.isTemplate;
  const dispatch = useAppDispatch();

  useEffect(() => {
    doLogUsage(project, MCApplicationsUrlEnum.RISIKO, "open");
    if (risikoBoardId == null) {
      dispatch(resetRisikoRedux());
    }
    if (risikoBoardId != null && project != null) {
      dispatch(resetRisikoRedux());
      setTimeout(async () => {
        await initRisiko();
      }, 50);
    }
  }, [risikoBoardId]);

  useEffect(() => {
    if (activeColumnTemplate != null && columns != null) {
      filterColumnsByTemplate(activeColumnTemplate);
    }
  }, [activeColumnTemplate]);

  const initRisiko = async () => {
    const _categories = await doGetRisikoEvaluationCategories(project._id, risikoBoardId);
    dispatch(setRisikoCategoriesInRedux(_categories));
    const _boards = await doGetRisikoBoards(project._id);
    dispatch(setRisikoBoardsInRedux(_boards));
    const _activeBoard = await doGetSingleRisikoBoard(project._id, risikoBoardId);
    dispatch(fetchRisikosAndSetInRedux(_activeBoard, _categories, project._id));
    dispatch(setActiveRisikoBoard(_activeBoard));
    await loadColumns(_activeBoard, _categories);
    dispatch(fetchAndSetRisikoDocxTemplates());
  };

  const history = useHistory();

  const setQueryStringWithoutPageReload = (queryParamKey: string, qsValue: string) => {
    history.push(`${history.location.pathname}?${queryParamKey}=${qsValue}`);
  };

  const setSelectedItemInMainTable = (risikoDbId: string, boardId: string, projectId: string) => {
    if (risikoDbId != null) {
      setQueryStringWithoutPageReload("id", risikoDbId);
    }
    if (risikoDbId == null) {
      history.push(`${history.location.pathname}`);
    }
    dispatch(
      addAppModalThunk({
        itemId: risikoDbId,
        boardId,
        projectId,
        app: AppModalEnums.RISIKO,
      }),
    );
    dispatch(
      setSelectedRisikoInRedux({
        _id: risikoDbId,
        activeItemTab: RisikoItemTabEnum.INFO,
      }),
    );
  };

  const loadColumns = async (_activeBoard: IRiskoBoard, _categories: IRisikoEvaluationCategory[]) => {
    const tagsFilter = await createRisikoTagMultifilter(project._id, _activeBoard._id);
    const _columns = getRisikoColumns(
      setSelectedItemInMainTable,
      project.members,
      project._id,
      user,
      setShowSuccessMessageThunk,
      (itemId) => dispatch(fetchRisikoAndReplaceItInRedux(itemId)),
      tagsFilter,
      project.pns,
      _activeBoard,
    );
    const evaluationColumns = getEvaluationColumns(_categories, RisikoScoreFormatter);
    const allColumns = sortArrayByNumber([..._columns, ...evaluationColumns], "order");
    setColumns(allColumns);
  };

  const filterColumnsByTemplate = (columnTemplate: IColumnTemplate) => {
    if (columnTemplate == null) {
      return columns;
    }
    const _columns = [...columns];
    _columns.map((col) => {
      col.hidden = columnTemplate.columns.indexOf(col.dataField) === -1 && col.text !== "";
      return col;
    });
    setColumns(_columns);
  };

  const updateBoards = async () => {
    const _boards = await doGetRisikoBoards(project._id);
    dispatch(setRisikoBoardsInRedux(_boards));
    const _activeBoard = await doGetSingleRisikoBoard(project._id, risikoBoardId);
    dispatch(setActiveRisikoBoard(_activeBoard));
  };

  return (
    <>
      <div className="p-2 w-100 h-100 d-flex flex-column">
        {activeBoard != null && categories != null && boards != null && items != null && columns != null && (
          <>
            <RisikoTabs />
            {activeTab === "RISIKOS" && (
              <RisikoTab columns={columns} setSelectedItemInMainTable={setSelectedItemInMainTable} />
            )}
            {activeTab === "DASHBOARD" && (
              <RisikoStats
                risikos={items}
                risikoCategories={categories}
                setSelectedChartColorItem={null}
                setSelectedRisiko={(id) =>
                  dispatch(
                    setSelectedRisikoInRedux({
                      _id: id,
                      activeItemTab: RisikoItemTabEnum.INFO,
                    }),
                  )
                }
                risikoBoard={activeBoard}
              />
            )}
            {activeTab === "TILTAK" && isTemplateBoard !== true && <TiltakPageNew />}
            {activeTab === "TILTAK" && isTemplateBoard === true && (
              <Alert color="info">Tiltak er ikke tilgjengelig i malar</Alert>
            )}
            {activeTab === "STATUS" && <LastStatusEntries risikoBoardId={activeBoard._id} project={project} />}
            {activeTab === "SETTINGS" && <RisikoAdmin risikoBoard={activeBoard} updateBoards={updateBoards} />}
          </>
        )}
      </div>
    </>
  );
};

export default RisikoApp;
