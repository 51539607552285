import {
  TestTypeLAFTakEnum,
  TestTypeRomEnum,
  TestTypeUtstyrEnum,
  UtstyrEnum,
} from "mc-shared/zod/report/GK/gkReportConstants";
import { GKRapportSchema, IGKRapport } from "mc-shared/zod/report/GK/gkReportSchema";
import * as R from "ramda";
import React, { useEffect, useState } from "react";
import { useDebounce } from "usehooks-ts";
import { SafeParseReturnType, ZodError } from "zod";
import { Paths, PathValue } from "../../../../../../types/global.types";
import { VSpace } from "../../../../components";
import "./GKReport.css";
import GKgenerellInfo from "./generellInfo/GKgenerellInfo";
import GKtestPicker from "./testPicker/GKtestPicker";
import DekontamineringAvRom from "./tester/felles/DekontamineringAvRom";
import DekontamineringAvSikkerhetskabinett from "./tester/felles/DekontamineringAvSikkerhetskabinett";
import LekkasjeskanningAvHepa from "./tester/felles/lekkasjeskanningAvHEPA/LekkasjeskanningAvHepa";
import PartikkelTellingForUtstyr from "./tester/felles/partikkelTelling/PartikkelTellingForUtstyr";
import PartikkelTellingIRom from "./tester/felles/partikkelTelling/PartikkelTellingIRom";
import FunksjonskontrollLAFTak from "./tester/rom/LAFtak/FunksjonskontrollLAFTak";
import LekkasjeskanningAvHepaLAFTak from "./tester/rom/LAFtak/LekkasjeskanningAvHepaLAFTak";
import LufthastighetskontrollLAFTak from "./tester/rom/LAFtak/LufthastighetskontrollLAFTak";
import MaalingAvLufthastighetVedOperasjonsseng from "./tester/rom/LAFtak/MaalingAvLufthastighetVedOperasjonsseng";
import MaalingAvOvertrykk from "./tester/rom/LAFtak/MaalingAvOvertrykk";
import PartikkelTellingUnderLAFTak from "./tester/rom/LAFtak/PartikkeltellingUnderLAFTak";
import InneslutningslekkasjetestMedFotometer from "./tester/rom/inneslutningslekkasjetestMedFotometer/InneslutningslekkasjetestMedFotometer";
import KontrollAvLuftstroem from "./tester/rom/kontrollAvLuftstroem/KontrollAvLuftstroem";
import KontrollAvRomtrykk from "./tester/rom/kontrollAvRomtrykk/KontrollAvRomtrykk";
import Lufthastighetskontroll from "./tester/rom/lufthastighetskontroll/Lufthastighetskontroll";
import LuftmengdeTestIRom from "./tester/rom/luftmengdemaalingIRom/LuftmengdeTestIRom";
import RecoveryTest from "./tester/rom/recoveryTest/RecoveryTest";
import RelativLuftfuktighetsMaaling from "./tester/rom/relativLuftfuktighet/RelativLuftfuktighetsMaaling";
import Temperaturmaaling from "./tester/rom/temperaturmaaling/Temperaturmaaling";
import BeregningAvLufthastighetGjennomArbeidsaapning from "./tester/utstyr/BeregningAvLufthastighetGjennomArbeidsaapning";
import Bruddtest from "./tester/utstyr/Bruddtest";
import EksternOgInternRoeykvisualisering from "./tester/utstyr/EksternOgInternRoeykvisualisering";
import KontrollAvAlarm from "./tester/utstyr/KontrollAvAlarm";
import KontrollAvLuftstroemVedArbeidsaapning from "./tester/utstyr/KontrollAvLuftmoensterVedArbeidsaapning";
import Lekkasjetest from "./tester/utstyr/Lekkasjetest";
import LufthastighetsmaalingIArbeidsomraade from "./tester/utstyr/LufthastighetsmaalingIArbeidsomraade";
import MaalingAvArbeidstrykkOgKontrollAvInternTrykkmaaler from "./tester/utstyr/MaalingAvArbeidstrykkOgKontrollAvInternTrykkmaaler";
import MaalingAvAvtrekksmengdeIgangkjoering from "./tester/utstyr/MaalingAvAvtrekksmengdeIgangkjoering";
import MaalingAvAvtrekksmengdeRutinekontroll from "./tester/utstyr/MaalingAvAvtrekksmengdeRutinekontroll";
import MaalingAvDriftstrykk from "./tester/utstyr/MaalingAvDriftstrykk";
import MaalingAvInnstroemningsHastighet from "./tester/utstyr/MaalingAvInnstroemningsHastighet";
import MaalingAvLufthastighetIRommet from "./tester/utstyr/MaalingAvLufthastighetIRommet";
import MaalingAvLufthastighetILukeaapningIgangkjoering from "./tester/utstyr/MaalingAvLufthastighetOgLuftmengdeILukeaapning";
import MaalingAvLufthastighetPunktavsug from "./tester/utstyr/MaalingAvLufthastighetPunktavsug";
import MaalingAvLuftmengdeGjennomInntaksfilter from "./tester/utstyr/MaalingAvLuftmengdeGjennomInntaksfilter/MaalingAvLuftmengdeGjennomInntaksfilter";
import MaalingAvLydtrykk from "./tester/utstyr/MaalingAvLydtrykk";
import MaalingAvTrykkPunktavsug from "./tester/utstyr/MaalingAvTrykkPunktavsug";
import MaalingAvTrykkfallOverHepaFilter from "./tester/utstyr/MaalingAvTrykkfallOverHepaFilter";
import TestAvAlarmsystemer from "./tester/utstyr/TestAvAlarmsystemer";
import TestAvSkapreguleringsResponstid from "./tester/utstyr/TestAvSkapreguleringsResponstid";
import TrykkfallIAvtrekksskap from "./tester/utstyr/TrykkfallIAvtrekksskap";
import VisualiseringAvLuftstroem from "./tester/utstyr/VisualiseringAvLuftstroem";
import VisualiseringMedRoeykPunktavsug from "./tester/utstyr/VisualiseringMedRoeykPunktavsug";
import FunksjonskontrollAvtrekksskap from "./tester/utstyr/funksjonskontroll/FunksjonskontrollAvtrekksskap";
import FunksjonskontrollIsolator from "./tester/utstyr/funksjonskontroll/FunksjonskontrollIsolator";
import FunksjonskontrollSikkerhetskabinett from "./tester/utstyr/funksjonskontroll/FunksjonskontrollSikkerhetskabinett";
import LufthastighetsmaalingOgDokuAvLaminaerLuftstroem from "./tester/utstyr/lufthastighetsmaalingOgDokuAvLaminaerLuftstroem/LufthastighetsmaalingOgDokuAvLaminaerLuftstroem";
import LuftmengdemaalingGjennomArbeidsaapning from "./tester/utstyr/luftmengdemaalingGjennomArbeidsaapning/LuftmengdemaalingGjennomArbeidsaapning";

function GKrapport({
  data,
  onUpdateEditData,
  onError,
  className = "",
  createdAt,
  ...props
}: React.PropsWithChildren<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    data: IGKRapport;
    onUpdateEditData: (data: IGKRapport, docxData: any) => void;
    onError: (errors: ZodError<IGKRapport>) => void;
    className?: string;
    createdAt: Date;
  }
>) {
  const [editData, setEditData] = useState<IGKRapport>(R.clone(data));
  const debouncedValue = useDebounce<IGKRapport>(editData, 1000);

  useEffect(() => {
    const parsedResult: SafeParseReturnType<IGKRapport, IGKRapport> = GKRapportSchema.safeParse(editData);

    if (parsedResult.success === false) {
      onError(parsedResult.error);
    } else {
      onUpdateEditData(editData, editData);
    }
  }, [debouncedValue]);

  React.useEffect(() => {
    setEditData(null);
    setTimeout(() => {
      setEditData(R.clone(data));
    }, 50);
  }, [createdAt]);

  function updateData<P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) {
    const updatedData = R.assocPath(path.split("."), value, editData);
    setEditData(updatedData);
  }

  return (
    <div className={`d-flex flex-column gk-rapport`} {...props}>
      {editData != null && (
        <>
          <GKgenerellInfo data={editData} onUpdateEditData={updateData} />
          <VSpace />
          <GKtestPicker testInnstillinger={editData.testInnstillinger} onUpdateEditData={updateData} />
          {editData.testInnstillinger.valgteTester[TestTypeRomEnum.LUFTMENGDEMAALINGIROM] === true && (
            <>
              <VSpace />
              <LuftmengdeTestIRom data={editData.testResultater.luftmengdeMaalingIRom} onUpdateEditData={updateData} />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeRomEnum.LUFTHASTIGHETSKONTROLL] === true && (
            <>
              <VSpace />
              <Lufthastighetskontroll
                data={editData.testResultater.lufthastighetskontroll}
                onUpdateEditData={updateData}
              />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeRomEnum.KONTROLLAVROMTRYKK] === true && (
            <>
              <VSpace />
              <KontrollAvRomtrykk data={editData.testResultater.kontrollAvRomtrykk} onUpdateEditData={updateData} />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeRomEnum.LEKKASJESKANNINGHAVHEPA] === true && (
            <>
              <VSpace />
              <LekkasjeskanningAvHepa
                data={editData.testResultater.lekkasjeskanningAvHepa}
                onUpdateEditData={updateData}
              />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeRomEnum.INNESLUTNINGSLEKKASJETEST] === true && (
            <>
              <VSpace />
              <InneslutningslekkasjetestMedFotometer
                data={editData.testResultater.inneslutningsLekkasjeTestMedFotometer}
                onUpdateEditData={updateData}
              />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeRomEnum.KONTROLLAVLUFTSTROEM] === true && (
            <>
              <VSpace />
              <KontrollAvLuftstroem data={editData.testResultater.kontrollAvLuftstroem} onUpdateEditData={updateData} />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeRomEnum.RECOVERYTEST] === true && (
            <>
              <VSpace />
              <RecoveryTest data={editData.testResultater.recoveryTest} onUpdateEditData={updateData} />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeRomEnum.PARTIKKELTELLINGROM] === true && (
            <>
              <VSpace />
              <PartikkelTellingIRom
                data={editData.testResultater.partikkelTellingIRom}
                onUpdateEditData={updateData}
                romAktivitet={editData.testInnstillinger.valgtRomAktivitet}
                testValidering={editData.testInnstillinger.valgtTestValidering}
              />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeRomEnum.TEMPERATURMAALING] === true && (
            <>
              <VSpace />
              <Temperaturmaaling data={editData.testResultater.temperaturmaaling} onUpdateEditData={updateData} />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeRomEnum.RELATIVLUFTFUKTIGHETSMAALING] === true && (
            <>
              <VSpace />
              <RelativLuftfuktighetsMaaling
                data={editData.testResultater.relativFuktighetsmaaling}
                onUpdateEditData={updateData}
              />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeUtstyrEnum.PARTIKKELTELLINGUTSTYR] === true && (
            <>
              <VSpace />
              <PartikkelTellingForUtstyr
                data={editData.testResultater.partikkelTellingForUtstyr}
                onUpdateEditData={updateData}
              />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeUtstyrEnum.KONTROLLAVLUFTSTROEMVEDARBEIDSAAPNING] ===
            true && (
            <>
              <VSpace />
              <KontrollAvLuftstroemVedArbeidsaapning
                data={editData.testResultater.kontrollAvLuftstroemVedArbeidsaapning}
                onUpdateEditData={updateData}
              />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeUtstyrEnum.LUFTMENGDEMAALINGGJENNOMARBEIDSAAPNING] ===
            true && (
            <>
              <VSpace />
              <LuftmengdemaalingGjennomArbeidsaapning
                data={editData.testResultater.luftmengdemaalingGjennomArbeidsaapning}
                onUpdateEditData={updateData}
              />
            </>
          )}
          {editData.testInnstillinger.valgteTester[
            TestTypeUtstyrEnum.LUFTHASTIGHETSMAALINGOGDOKUAVLAMINAERLUFTSTROEM
          ] === true && (
            <>
              <VSpace />
              <LufthastighetsmaalingOgDokuAvLaminaerLuftstroem
                data={editData.testResultater.lufthastighetsmaalingOgDokuAvLaminaerLuftstroem}
                onUpdateEditData={updateData}
              />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeUtstyrEnum.BEREGNINGAVLUFTHASTIGHETGJENNOMARBEIDSAAPNING] ===
            true && (
            <>
              <VSpace />
              <BeregningAvLufthastighetGjennomArbeidsaapning
                data={editData.testResultater.beregningAvLufthastighetGjennomArbeidsaapning}
                onUpdateEditData={updateData}
              />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeUtstyrEnum.MAALINGAVTRYKKFALLOVERHEPAFILTER] === true && (
            <>
              <VSpace />
              <MaalingAvTrykkfallOverHepaFilter
                data={editData.testResultater.maalingAvTrykkfallOverHepafilter}
                onUpdateEditData={updateData}
              />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeUtstyrEnum.KONTROLLAVALARM] === true && (
            <>
              <VSpace />
              <KontrollAvAlarm data={editData.testResultater.kontrollAvAlarm} onUpdateEditData={updateData} />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeUtstyrEnum.BRUDDTEST] === true && (
            <>
              <VSpace />
              <Bruddtest data={editData.testResultater.bruddtest} onUpdateEditData={updateData} />
            </>
          )}
          {editData.testInnstillinger.valgteTester[
            TestTypeUtstyrEnum.MAALINGAVARBEIDSTRYKKOGKONTROLLAVINTERNTRYKKMAALER
          ] === true && (
            <>
              <VSpace />
              <MaalingAvArbeidstrykkOgKontrollAvInternTrykkmaaler
                data={editData.testResultater.maalingAvArbeidstrykkOgKontrollAvInternTrykkmaaler}
                onUpdateEditData={updateData}
              />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeUtstyrEnum.MAALINGAVLUFTMENGDEGJENNOMINNTAKSFILTER] ===
            true && (
            <>
              <VSpace />
              <MaalingAvLuftmengdeGjennomInntaksfilter
                data={editData.testResultater.maalingAvLuftmengdeGjennomInntaksfilter}
                onUpdateEditData={updateData}
              />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeUtstyrEnum.LUFTHASTIGHETSMAALINGIARBEIDSOMRAADE] ===
            true && (
            <>
              <VSpace />
              <LufthastighetsmaalingIArbeidsomraade
                data={editData.testResultater.lufthastighetsMaalingIArbeidsomraade}
                onUpdateEditData={updateData}
              />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeUtstyrEnum.MAALINGAVINNSTROEMNINGSHASTIGHET] === true && (
            <>
              <VSpace />
              <MaalingAvInnstroemningsHastighet
                data={editData.testResultater.maalingAvInnstroemningsHastighet}
                onUpdateEditData={updateData}
              />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeUtstyrEnum.MAALINGAVDRIFTSTRYKK] === true && (
            <>
              <VSpace />
              <MaalingAvDriftstrykk data={editData.testResultater.maalingAvDriftstrykk} onUpdateEditData={updateData} />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeUtstyrEnum.LEKKASJETEST] === true && (
            <>
              <VSpace />
              <Lekkasjetest data={editData.testResultater.lekkasjetest} onUpdateEditData={updateData} />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeUtstyrEnum.MAALINGAVAVTREKKSMENGDERUTINEKONTROLL] ===
            true && (
            <>
              <VSpace />
              <MaalingAvAvtrekksmengdeRutinekontroll
                data={editData.testResultater.maalingAvAvtrekksmengdeRutinekontroll}
                onUpdateEditData={updateData}
              />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeUtstyrEnum.TRYKKFALLIAVTREKKSSKAPET] === true && (
            <>
              <VSpace />
              <TrykkfallIAvtrekksskap
                data={editData.testResultater.trykkfallIAvtrekksskap}
                onUpdateEditData={updateData}
              />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeUtstyrEnum.VISUALISERINGAVLUFTSTROEM] === true && (
            <>
              <VSpace />
              <VisualiseringAvLuftstroem
                data={editData.testResultater.visualiseringAvLuftstroem}
                onUpdateEditData={updateData}
              />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeUtstyrEnum.TESTAVALARMSYSTEMER] === true && (
            <>
              <VSpace />
              <TestAvAlarmsystemer data={editData.testResultater.testAvAlarmsystemer} onUpdateEditData={updateData} />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeUtstyrEnum.TESTAVSKAPREGULERINGENSRESPONSTID] === true && (
            <>
              <VSpace />
              <TestAvSkapreguleringsResponstid
                data={editData.testResultater.testAvSkapreguleringensResponstid}
                onUpdateEditData={updateData}
              />
            </>
          )}
          {editData.testInnstillinger.valgteTester[
            TestTypeUtstyrEnum.MAALINGAVLUFTHASTIGHETILUKEAAPNINGIGANGKJOERING
          ] === true && (
            <>
              <VSpace />
              <MaalingAvLufthastighetILukeaapningIgangkjoering
                data={editData.testResultater.maalingAvLufthastighetILukeaapningIgangkjoering}
                onUpdateEditData={updateData}
              />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeUtstyrEnum.MAALINGAVAVTREKKSMENGDEIGANGKJOERING] ===
            true && (
            <>
              <VSpace />
              <MaalingAvAvtrekksmengdeIgangkjoering
                data={editData.testResultater.maalingAvAvtrekksmengdeIgangkjoering}
                onUpdateEditData={updateData}
              />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeUtstyrEnum.MAALINGAVLUFTHASTIGHETIROMMET] === true && (
            <>
              <VSpace />
              <MaalingAvLufthastighetIRommet
                data={editData.testResultater.maalingAvLufthastighetIRommet}
                onUpdateEditData={updateData}
              />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeUtstyrEnum.MAALINGAVLYDTRYKK] === true && (
            <>
              <VSpace />
              <MaalingAvLydtrykk data={editData.testResultater.maalingAvLydtrykk} onUpdateEditData={updateData} />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeUtstyrEnum.MAALINGAVLUFTHASTIGHETPUNKTAVSUG] === true && (
            <>
              <VSpace />
              <MaalingAvLufthastighetPunktavsug
                data={editData.testResultater.maalingAvLufthastighetPunktavsug}
                onUpdateEditData={updateData}
              />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeUtstyrEnum.MAALINGAVTRYKKPUNKTAVSUG] === true && (
            <>
              <VSpace />
              <MaalingAvTrykkPunktavsug
                data={editData.testResultater.maalingAvTrykkPunktavsug}
                onUpdateEditData={updateData}
              />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeUtstyrEnum.VISUALISERINGMEDROEYKPUNKTAVSUG] === true && (
            <>
              <VSpace />
              <VisualiseringMedRoeykPunktavsug
                data={editData.testResultater.visualiseringMedRoeykPunktavsug}
                onUpdateEditData={updateData}
              />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeUtstyrEnum.EKSTERNOGINTERNROEYKVISUALISERING] === true && (
            <>
              <VSpace />
              <EksternOgInternRoeykvisualisering
                data={editData.testResultater.eksternOgInternRoeykVisualisering}
                onUpdateEditData={updateData}
              />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeRomEnum.DEKONTAMINERINGAVROM] === true && (
            <>
              <VSpace />
              <DekontamineringAvRom data={editData.testResultater.dekontamineringAvRom} onUpdateEditData={updateData} />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeUtstyrEnum.DEKONTAMINERINGAVSIKKERHETSKABINETT] === true && (
            <>
              <VSpace />
              <DekontamineringAvSikkerhetskabinett
                data={editData.testResultater.dekontamineringAvSikkerhetskabinett}
                onUpdateEditData={updateData}
              />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeUtstyrEnum.FUNKSJONSKONTROLLSIKKERHETSKABINETT] === true && (
            <>
              <VSpace />
              <FunksjonskontrollSikkerhetskabinett
                data={editData.testResultater.funksjonskontrollSikkerhetskabinett}
                onUpdateEditData={updateData}
                sikkerhetskabinettklasse={
                  editData.testInnstillinger.valgtUtstyr === UtstyrEnum.SIKKERHETSKABINETTI
                    ? UtstyrEnum.SIKKERHETSKABINETTI
                    : UtstyrEnum.SIKKERHETSKABINETTII
                }
              />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeUtstyrEnum.FUNKSJONSKONTROLLISOLATOR] === true && (
            <>
              <VSpace />
              <FunksjonskontrollIsolator
                data={editData.testResultater.funksjonskontrollIsolator}
                onUpdateEditData={updateData}
              />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeUtstyrEnum.FUNKSJONSKONTROLLAVTREKKSSKAP] === true && (
            <>
              <VSpace />
              <FunksjonskontrollAvtrekksskap
                data={editData.testResultater.funksjonskontrollAvtrekksskap}
                onUpdateEditData={updateData}
              />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeLAFTakEnum.MAALINGAVOVERTRYKK] === true && (
            <>
              <VSpace />
              <MaalingAvOvertrykk
                data={editData.testResultater.maalingAvOvertrykkLAFTak}
                onUpdateEditData={updateData}
              />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeLAFTakEnum.PARTIKKELTELLINGLAFTAK] === true && (
            <>
              <VSpace />
              <PartikkelTellingUnderLAFTak
                data={editData.testResultater.partikkelTellingUnderLAFTak}
                onUpdateEditData={updateData}
                romAktivitet={editData.testInnstillinger.valgtRomAktivitet}
                testValidering={editData.testInnstillinger.valgtTestValidering}
              />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeLAFTakEnum.LEKKASJESKANNINGHAVHEPA] === true && (
            <>
              <VSpace />
              <LekkasjeskanningAvHepaLAFTak
                data={editData.testResultater.lekkasjeskanningAvHepaLAFTak}
                onUpdateEditData={updateData}
              />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeLAFTakEnum.LUFTHASTIGHETSMAALINGUNDERFILTER] === true && (
            <>
              <VSpace />
              <LufthastighetskontrollLAFTak
                data={editData.testResultater.lufthastighetskontrollLAFTak}
                onUpdateEditData={updateData}
              />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeLAFTakEnum.MAALINGAVLUFTHASTIGHETVEDOPERASJONSSENG] ===
            true && (
            <>
              <VSpace />
              <MaalingAvLufthastighetVedOperasjonsseng
                data={editData.testResultater.maalingAvLufthastighetVedOperasjonssengLAFTak}
                onUpdateEditData={updateData}
              />
            </>
          )}
          {editData.testInnstillinger.valgteTester[TestTypeLAFTakEnum.FUNKSJONSKONTROLLLAFTAK] === true && (
            <>
              <VSpace />
              <FunksjonskontrollLAFTak
                data={editData.testResultater.funksjonskontrollLAFTak}
                onUpdateEditData={updateData}
              />
            </>
          )}
          <div style={{ height: "400px" }} />
        </>
      )}
    </div>
  );
}

export default GKrapport;
