import React, { useEffect, useState } from "react";
import { EditSaveAbort, HSpace } from "./";
import TypeAheadUser from "./TypeAheadUser/TypeAheadUser";

const TypeaheadViewEdit: React.FC<{
  title: string;
  value: object;
  viewValueKey: string;
  options: any[];
  saveCb: any;
}> = ({ title, value, viewValueKey, options, saveCb }) => {
  const [editMode, setEditMode] = useState(false);
  const [editedState, setEditedOption] = useState<string>();

  useEffect(() => {}, []);

  const editCb = (bool) => {
    setEditMode(bool);
  };

  const _save = () => {
    editCb(false);
    saveCb(value != null ? value[viewValueKey] : null, editedState);
  };

  return (
    <div className="mb-2">
      <h5>{title}</h5>
      <div className="d-flex">
        <EditSaveAbort disabled={editedState == null} editMode={editMode} saveCb={_save} editModeCb={editCb} />
        <HSpace />
        {editMode === false && <span>{value == null ? "-" : value[viewValueKey]}</span>}
        {editMode && <TypeAheadUser title="" members={options} setAssignToUser={setEditedOption} />}
      </div>
    </div>
  );
};

export default TypeaheadViewEdit;
//
