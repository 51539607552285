import {
  FUNKSJONSKONTROLL_LAFTAK_TESTER_METODER,
  FunksjonskontrollLAFTakTesterEnum,
} from "mc-shared/zod/report/GK/gkReportConstants";
import { IGKFunksjonskontrollLAFTakResultater, IGKRapport } from "mc-shared/zod/report/GK/gkReportSchema";
import React from "react";
import { Card, CardBody, CardTitle, FormGroup, Input, Label } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../../../types/global.types";
import { DropdownFilterGeneric, McInput } from "../../../../../../../components";

const FunksjonskontrollLAFTak: React.FC<{
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
  data: IGKFunksjonskontrollLAFTakResultater;
}> = ({ data, onUpdateEditData }) => {
  const addOrRemoveTest = (test: FunksjonskontrollLAFTakTesterEnum) => {
    data.inndata.valgtMetode[test] = !data.inndata.valgtMetode[test];
    onUpdateEditData("testResultater.funksjonskontrollLAFTak.inndata.valgtMetode", data.inndata.valgtMetode);
  };

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Funksjonskontroll av LAF-tak</CardTitle>
        <b>Velg hvilke tester som skal utføres?</b>
        <div className="w-25 mb-4">
          <DropdownFilterGeneric
            filterOptions={FUNKSJONSKONTROLL_LAFTAK_TESTER_METODER.map((test) => {
              return {
                ...test,
                isActive: data.inndata.valgtMetode[test.value],
              };
            })}
            onClick={(value) => addOrRemoveTest(value)}
            title="Velg tester"
            color="secondary"
            textColor="text-black"
          />
        </div>
        {data.inndata.valgtMetode[FunksjonskontrollLAFTakTesterEnum.KONTROLLAVBETJENINGSPANEL] === true && (
          <>
            <h4>Kontroll av betjeningspanel</h4>
            <div>
              <h5 className="mb-0 mt-2">På/av-knapp</h5>
              <FormGroup className="d-flex flex-column" check>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.kontrollAvBetjeningspanelResultater.paaAvKnappGodkjent === true}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollLAFTak.inndata.kontrollAvBetjeningspanelResultater.paaAvKnappGodkjent",
                        true,
                      )
                    }
                  />
                  Godkjent
                </Label>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.kontrollAvBetjeningspanelResultater.paaAvKnappGodkjent === false}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollLAFTak.inndata.kontrollAvBetjeningspanelResultater.paaAvKnappGodkjent",
                        false,
                      )
                    }
                  />
                  Ikke godkjent
                </Label>
              </FormGroup>
            </div>
            <div>
              <h5 className="mb-0 mt-2">Flow-knapp</h5>
              <FormGroup className="d-flex flex-column" check>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.kontrollAvBetjeningspanelResultater.flowKnappGodkjent === true}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollLAFTak.inndata.kontrollAvBetjeningspanelResultater.flowKnappGodkjent",
                        true,
                      )
                    }
                  />
                  Godkjent
                </Label>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.kontrollAvBetjeningspanelResultater.flowKnappGodkjent === false}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollLAFTak.inndata.kontrollAvBetjeningspanelResultater.flowKnappGodkjent",
                        false,
                      )
                    }
                  />
                  Ikke godkjent
                </Label>
              </FormGroup>
            </div>
            <div>
              <h5 className="mb-0 mt-2">Lys-knapp</h5>
              <FormGroup className="d-flex flex-column" check>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.kontrollAvBetjeningspanelResultater.lysKnappGodkjent === true}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollLAFTak.inndata.kontrollAvBetjeningspanelResultater.lysKnappGodkjent",
                        true,
                      )
                    }
                  />
                  Godkjent
                </Label>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.kontrollAvBetjeningspanelResultater.lysKnappGodkjent === false}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollLAFTak.inndata.kontrollAvBetjeningspanelResultater.lysKnappGodkjent",
                        false,
                      )
                    }
                  />
                  Ikke godkjent
                </Label>
              </FormGroup>
            </div>
            <div className="w-50 mt-2 mb-4">
              <b>Kommentar:</b>
              <McInput
                value={data.inndata.kontrollAvBetjeningspanelResultater.kommentar}
                onChange={(e) =>
                  onUpdateEditData(
                    "testResultater.funksjonskontrollLAFTak.inndata.kontrollAvBetjeningspanelResultater.kommentar",
                    e.target.value,
                  )
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
          </>
        )}
        {data.inndata.valgtMetode[FunksjonskontrollLAFTakTesterEnum.LYSPAERER] === true && (
          <>
            <h4>Visuell kontroll av lyspærer/lysrør frontpanel</h4>
            <div>
              <FormGroup className="d-flex flex-column" check>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.lyspaererResultater.godkjent === true}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollLAFTak.inndata.lyspaererResultater.godkjent",
                        true,
                      )
                    }
                  />
                  Godkjent
                </Label>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.lyspaererResultater.godkjent === false}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollLAFTak.inndata.lyspaererResultater.godkjent",
                        false,
                      )
                    }
                  />
                  Ikke godkjent
                </Label>
              </FormGroup>
            </div>
            <div className="w-50 mt-2 mb-4">
              <b>Kommentar:</b>
              <McInput
                value={data.inndata.lyspaererResultater.kommentar}
                onChange={(e) =>
                  onUpdateEditData(
                    "testResultater.funksjonskontrollLAFTak.inndata.lyspaererResultater.kommentar",
                    e.target.value,
                  )
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
          </>
        )}
        {data.inndata.valgtMetode[FunksjonskontrollLAFTakTesterEnum.DISPLAY] === true && (
          <>
            <h4>Visuell kontroll av display</h4>
            <div>
              <FormGroup className="d-flex flex-column" check>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.displayResultater.godkjent === true}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollLAFTak.inndata.displayResultater.godkjent",
                        true,
                      )
                    }
                  />
                  Godkjent
                </Label>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.displayResultater.godkjent === false}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollLAFTak.inndata.displayResultater.godkjent",
                        false,
                      )
                    }
                  />
                  Ikke godkjent
                </Label>
              </FormGroup>
            </div>
            <div className="w-50 mt-2 mb-4">
              <b>Kommentar:</b>
              <McInput
                value={data.inndata.displayResultater.kommentar}
                onChange={(e) =>
                  onUpdateEditData(
                    "testResultater.funksjonskontrollLAFTak.inndata.displayResultater.kommentar",
                    e.target.value,
                  )
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
          </>
        )}
        {data.inndata.valgtMetode[FunksjonskontrollLAFTakTesterEnum.SKADER] === true && (
          <>
            <h4>Visuell kontroll av skader</h4>
            <div>
              <FormGroup className="d-flex flex-column" check>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.skaderResultater.godkjent === true}
                    onChange={() =>
                      onUpdateEditData("testResultater.funksjonskontrollLAFTak.inndata.skaderResultater.godkjent", true)
                    }
                  />
                  Godkjent
                </Label>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.skaderResultater.godkjent === false}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollLAFTak.inndata.skaderResultater.godkjent",
                        false,
                      )
                    }
                  />
                  Ikke godkjent
                </Label>
              </FormGroup>
            </div>
            <div className="w-50 mt-2 mb-4">
              <b>Kommentar:</b>
              <McInput
                value={data.inndata.skaderResultater.kommentar}
                onChange={(e) =>
                  onUpdateEditData(
                    "testResultater.funksjonskontrollLAFTak.inndata.skaderResultater.kommentar",
                    e.target.value,
                  )
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
          </>
        )}
        {data.inndata.valgtMetode[FunksjonskontrollLAFTakTesterEnum.LEKKASJE] === true && (
          <>
            <h4>Visuell kontroll av lekkasje</h4>
            <div>
              <FormGroup className="d-flex flex-column" check>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.lekkasjeResultater.godkjent === true}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollLAFTak.inndata.lekkasjeResultater.godkjent",
                        true,
                      )
                    }
                  />
                  Godkjent
                </Label>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.lekkasjeResultater.godkjent === false}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollLAFTak.inndata.lekkasjeResultater.godkjent",
                        false,
                      )
                    }
                  />
                  Ikke godkjent
                </Label>
              </FormGroup>
            </div>
            <div className="w-50 mt-2 mb-4">
              <b>Kommentar:</b>
              <McInput
                value={data.inndata.lekkasjeResultater.kommentar}
                onChange={(e) =>
                  onUpdateEditData(
                    "testResultater.funksjonskontrollLAFTak.inndata.lekkasjeResultater.kommentar",
                    e.target.value,
                  )
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
          </>
        )}
        {data.inndata.valgtMetode[FunksjonskontrollLAFTakTesterEnum.POSISJON] === true && (
          <>
            <h4>Visuell kontroll av posisjon</h4>
            <div>
              <FormGroup className="d-flex flex-column" check>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.posisjonResultater.godkjent === true}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollLAFTak.inndata.posisjonResultater.godkjent",
                        true,
                      )
                    }
                  />
                  Godkjent
                </Label>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.posisjonResultater.godkjent === false}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollLAFTak.inndata.posisjonResultater.godkjent",
                        false,
                      )
                    }
                  />
                  Ikke godkjent
                </Label>
              </FormGroup>
            </div>
            <div className="w-50 mt-2 mb-4">
              <b>Kommentar:</b>
              <McInput
                value={data.inndata.posisjonResultater.kommentar}
                onChange={(e) =>
                  onUpdateEditData(
                    "testResultater.funksjonskontrollLAFTak.inndata.posisjonResultater.kommentar",
                    e.target.value,
                  )
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
          </>
        )}
        {data.inndata.valgtMetode[FunksjonskontrollLAFTakTesterEnum.LYD] === true && (
          <>
            <h4>Kontroll av lyd/støy</h4>
            <div>
              <FormGroup className="d-flex flex-column" check>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.lydResultater.godkjent === true}
                    onChange={() =>
                      onUpdateEditData("testResultater.funksjonskontrollLAFTak.inndata.lydResultater.godkjent", true)
                    }
                  />
                  Godkjent
                </Label>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.lydResultater.godkjent === false}
                    onChange={() =>
                      onUpdateEditData("testResultater.funksjonskontrollLAFTak.inndata.lydResultater.godkjent", false)
                    }
                  />
                  Ikke godkjent
                </Label>
              </FormGroup>
            </div>
            <div className="w-50 mt-2 mb-4">
              <b>Kommentar:</b>
              <McInput
                value={data.inndata.lydResultater.kommentar}
                onChange={(e) =>
                  onUpdateEditData(
                    "testResultater.funksjonskontrollLAFTak.inndata.lydResultater.kommentar",
                    e.target.value,
                  )
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
          </>
        )}
        {data.inndata.valgtMetode[FunksjonskontrollLAFTakTesterEnum.FORFILTER] === true && (
          <>
            <h4>Visuell kontroll av forfilter</h4>
            <div>
              <FormGroup className="d-flex flex-column" check>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.forfilterResultater.godkjent === true}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollLAFTak.inndata.forfilterResultater.godkjent",
                        true,
                      )
                    }
                  />
                  Godkjent
                </Label>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.forfilterResultater.godkjent === false}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollLAFTak.inndata.forfilterResultater.godkjent",
                        false,
                      )
                    }
                  />
                  Ikke godkjent
                </Label>
              </FormGroup>
            </div>
            <div className="w-50 mt-2 mb-4">
              <b>Kommentar:</b>
              <McInput
                value={data.inndata.forfilterResultater.kommentar}
                onChange={(e) =>
                  onUpdateEditData(
                    "testResultater.funksjonskontrollLAFTak.inndata.forfilterResultater.kommentar",
                    e.target.value,
                  )
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default FunksjonskontrollLAFTak;
