import React, { useEffect, useRef } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { Label } from "reactstrap";
import { IMember, IMinUser, IUser } from "../../../../types/global.types";

const TypeAheadUser: React.FC<{
  title?: string;
  members: IMember[];
  setAssignToUser: (userId: string) => void;
  assignedTo?: IMinUser | IUser;
  clearAfterSelection?: boolean;
  focusOnOpen?: boolean;
  usedInForm?: boolean;
}> = ({
  title = "",
  members,
  setAssignToUser,
  assignedTo,
  clearAfterSelection = false,
  focusOnOpen = true,
  usedInForm = false,
}) => {
  const typeAheadRef = useRef(null);
  const users = members.map((member) => member.user);
  const setSelectedUser = (users) => {
    if (users.length === 1) {
      setAssignToUser(users[0]._id);
      if (clearAfterSelection === true) {
        typeAheadRef?.current.clear();
      }
    }
  };

  useEffect(() => {
    if (focusOnOpen === true) {
      typeAheadRef.current?.focus();
    }
  }, []);

  return (
    <>
      {usedInForm === false && <Label for="id">{title}</Label>}
      <Typeahead
        ref={typeAheadRef}
        allowNew={false}
        //@ts-ignore
        selected={assignedTo != null ? [assignedTo.name] : null}
        id="1"
        onChange={(arrVal) => setSelectedUser(arrVal)}
        placeholder={usedInForm === false ? "Navn" : ""}
        labelKey="name"
        options={users}
        inputProps={
          usedInForm === true && {
            style: {
              backgroundColor: "#ebecec",
              border: "none",
              boxShadow: "none",
              fontSize: "0.85rem",
              fontWeight: 400,
            },
          }
        }
        //@ts-ignore
        // onKeyDown={(e) => stopPropagationOnEscape(e, () => editModeCb(false))}
      />
    </>
  );
};

export default TypeAheadUser;
