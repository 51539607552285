import React from "react";
import { Button, ButtonGroup } from "reactstrap";
import { TDimension } from "../../../../../../types/global.types";
import { HSpace, McInput } from "../../../../components";
import DimensionSelector from "../../../../components/DimensionSelector/DimensionSelector";
import { DimensionEnums } from "../../../../frontendConstants";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { ISelectedGisMap } from "../../../../types";
import {
  setActiveRisikoViewRedux,
  setGlobalSearchStringInRisikoRedux,
  setSelectedGisMapInRisikoRedux,
} from "../actions";
import { RisikoViewEnum } from "../reducer";
import { RISIKO_VIEWS } from "../risikoConstants";

const RisikoActionBar: React.FC = () => {
  const dispatch = useAppDispatch();

  const activeView = useAppSelector((state) => state.risikoReducer.activeView);
  const globalSearchString = useAppSelector((state) => state.risikoReducer.globalSearchString);
  const selectedGisMap = useAppSelector((state) => state.risikoReducer.selectedGisMap);
  const project = useAppSelector((state) => state.adminReducer.project);

  const setGisDimension = (dimension: TDimension) => {
    let _selectedGisMap: ISelectedGisMap = {
      dimension: null,
      mapId: null,
    };
    if (dimension === DimensionEnums.TWODIMENSION) {
      _selectedGisMap = {
        dimension: DimensionEnums.TWODIMENSION,
        mapId: project.arcgis?.default2dMap,
      };
    }
    if (dimension === DimensionEnums.THEREEDIMENSION) {
      _selectedGisMap = {
        dimension: DimensionEnums.THEREEDIMENSION,
        mapId: project.arcgis?.default3dMap,
      };
    }
    dispatch(setSelectedGisMapInRisikoRedux(_selectedGisMap));
  };

  return (
    <div className="d-flex pb-1 pt-1">
      <div style={{ width: "300px" }}>
        <McInput
          placeholder="Søk..."
          value={globalSearchString}
          onChange={(e) => dispatch(setGlobalSearchStringInRisikoRedux(e.target.value))}
        />
      </div>

      <HSpace />
      {activeView === RisikoViewEnum.GIS && selectedGisMap != null && (
        <DimensionSelector
          activeDimension={selectedGisMap.dimension}
          setActiveDimension={(dimension) => setGisDimension(dimension)}
        />
      )}
      <HSpace />
      <div className="flex-fill" />
      <HSpace />
      <div>
        <ButtonGroup className="ml-0">
          {RISIKO_VIEWS.map((view) => (
            <Button
              style={{ width: "120px" }}
              key={view.key}
              color={activeView === view.key ? "primary" : "secondary"}
              onClick={() => dispatch(setActiveRisikoViewRedux(view.key))}
            >
              <i className={`fa fa-${view.icon} fa-fw`} />
              {view.label}
            </Button>
          ))}
        </ButtonGroup>
      </div>
    </div>
  );
};

export default RisikoActionBar;
