import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { Comparator, dateFilter, textFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { IProject } from "../../../../../types/global.types";
import { Avatar, ClickableTableIdButton, VSpace } from "../../../components";
import {
  CellEditFormatter,
  COL_WIDTHS,
  createHeaderDropDownFilter,
  createHeaderStyle,
  headerSortAndDropDownFilter,
  headerSortAndFilterFormatter,
  patchFilterFactory,
  sortCaret,
} from "../../../components/columnGlobal";
import ExcelExport from "../../../components/ExcelExport/ExcelExport";
import { stringExistOnAnyProperty } from "../../utils";
import { formatShortDate } from "../admin/selectors";
import { setSelectedRisikoInRedux } from "./actions";
import { RisikoItemTabEnum } from "./reducer";
import { doGetLatestStatelogs } from "./risikoAPI";
import { useAppDispatch } from "../../../hooks";

const LastStatusEntries: React.FC<{ project: IProject; risikoBoardId: string }> = ({ project, risikoBoardId }) => {
  const [stateLogs, setStateLogs] = useState(null);
  const [filtredStateLogs, setFiltredStateLogs] = useState([]);
  const [filtredIdsOnGlobalSearch, setFiltredIdsOnGlobalSearch] = useState<string[]>(null);

  const { SearchBar } = Search;

  const dispatch = useAppDispatch();

  const [searchText, setSearchText] = React.useState<string>("");
  useEffect(() => {
    async function fetchData() {
      let latestStateLogs = await doGetLatestStatelogs(project._id, risikoBoardId);
      latestStateLogs.map((log) => {
        if (log.statelogs.createdBy != null) {
          const member = project.members.find((member) => member.user?._id === log.statelogs.createdBy);
          if (member != null) {
            log.statelogs.createdBy = member.user?.name;
          }
        }
        return log;
      });

      setStateLogs(latestStateLogs);
      setFiltredStateLogs(latestStateLogs);
    }

    fetchData();
  }, []);

  const factory = patchFilterFactory(filterFactory, (data) => {
    setFiltredStateLogs((prevData) => {
      if (JSON.stringify(prevData) !== JSON.stringify(data)) {
        return data;
      }
      return prevData;
    });
  });

  const columns = [
    {
      dataField: "statelogs._id",
      text: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      text: "_id",
      hidden: true,
    },
    {
      dataField: "id",
      text: "Risiko id",
      filter: textFilter({ placeholder: "Tast inn..." }),
      headerStyle: createHeaderStyle(COL_WIDTHS.ID),
      sort: true,
      sortCaret: sortCaret,
      headerFormatter: headerSortAndFilterFormatter,
      formatter: (cell, row) => (
        <ClickableTableIdButton
          id={cell}
          _id={row._id}
          setItem={() =>
            dispatch(
              setSelectedRisikoInRedux({
                _id: row._id,
                activeItemTab: RisikoItemTabEnum.INFO,
              }),
            )
          }
        />
      ),
    },
    {
      dataField: "statelogs.text",
      text: "Status kommentar",
      filter: textFilter({ placeholder: "Tast inn..." }),
      headerStyle: createHeaderStyle(COL_WIDTHS.LONGTEXT),
      sort: true,
      sortCaret: sortCaret,
      headerFormatter: headerSortAndFilterFormatter,
      formatter: CellEditFormatter,
    },
    {
      dataField: "statelogs.createdDate",
      text: "Dato",
      formatter: (cell) => formatShortDate(cell),
      filter: dateFilter({
        defaultValue: { comparator: Comparator.GT },
      }),
      sort: true,
      sortCaret: sortCaret,
      headerFormatter: headerSortAndFilterFormatter,
      headerStyle: createHeaderStyle(COL_WIDTHS.TIMESTAMP),
    },
    {
      dataField: "statelogs.createdBy",
      text: "Opprettet av",
      filter: createHeaderDropDownFilter(project.members, "user.name"),
      sort: true,
      sortCaret: sortCaret,
      headerFormatter: headerSortAndDropDownFilter,
      headerStyle: createHeaderStyle(COL_WIDTHS.PERSON),
      formatter: (cell) => {
        const member = project.members.find((member) => member.user?.name === cell);
        return member != null ? (
          <div className="d-flex">
            <Avatar id={member.user?._id} />
            {cell}
          </div>
        ) : (
          "Ukjent"
        );
      },
    },
  ];

  const onSearch = (searchString: string) => {
    setSearchText(searchString);
    if (searchString === "") {
      return setFiltredIdsOnGlobalSearch(null);
    }

    const filtredActionsOnSearchStrng = stateLogs.reduce((acc, action) => {
      if (stringExistOnAnyProperty(action, searchString)) {
        acc.push(action._id);
      }
      return acc;
    }, []);

    setFiltredIdsOnGlobalSearch(filtredActionsOnSearchStrng);
  };

  return (
    <div className="h-100 mb-2" style={{ overflow: "auto" }}>
      <VSpace />
      <VSpace />
      {stateLogs != null && (
        <ToolkitProvider keyField="_id" data={stateLogs} columns={columns || []} columnToggle search>
          {(props) => {
            return (
              <>
                <div className="d-flex align-items-center" style={{ height: "50px" }}>
                  <SearchBar {...props.searchProps} className="mt-2" />
                  <div className="flex-fill" />
                  <ExcelExport columns={columns} data={filtredStateLogs} filePrefix="risiko_status_logs" />
                </div>
                <BootstrapTable
                  bootstrap4
                  keyField="statelogs._id"
                  data={stateLogs}
                  filter={factory()}
                  striped
                  classes="table-sm table-responsive"
                  wrapperClasses="smalltable"
                  bordered={false}
                  columns={columns}
                  {...props.baseProps}
                  defaultSorted={[
                    {
                      dataField: "statelogs.createdDate",
                      order: "desc",
                    },
                  ]}
                />
              </>
            );
          }}
        </ToolkitProvider>
      )}
    </div>
  );
};

export default LastStatusEntries;
