import React from "react";
import { IIssue, IRisiko } from "../../../../../../../types/global.types";
import { ConnectedIssues } from "../../../../../components/ConnectedIssues/ConnectedIssues";
import { AppModalEnums, ToastMessagesEnums } from "../../../../../frontendConstants";
import { addAppModalThunk, setShowSuccessMessageThunk } from "../../../../../global/globalSlice";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import AddIssueModal from "../../../issuesApp/IssuesTab/AddIssueModal";
import { fetchRisikoAndReplaceItInRedux } from "../../actions";
import { DepricatedConnectExistingIssueToRisikoModal } from "../../DepricatedConnectExistingIssueToRisikoModal";
import { doConnectOrDisconnectIssueFromRisiko, doGetSingleRisiko } from "../../risikoAPI";

const RisikoRowExpandContent: React.FC<{ risikoId: string }> = ({ risikoId }) => {
  const [connectExistingModalOpen, setConnectExistingModalOpen] = React.useState<boolean>(false);
  const [singleRisiko, setSingleRisiko] = React.useState<IRisiko>(null);
  const [newIssueModalOpen, setNewIssueModalOpen] = React.useState<boolean>(false);

  const project = useAppSelector((state) => state.adminReducer.project);
  const activeBoard = useAppSelector((state) => state.risikoReducer.activeBoard);
  const isPrintMode = useAppSelector((state) => state.globalReducer.isPrintMode);
  const openedAppModals = useAppSelector((state) => state.globalReducer.openedAppModals);

  React.useEffect(() => {
    if (openedAppModals.length === 0) {
      init();
    }
  }, [openedAppModals]);

  const dispatch = useAppDispatch();
  React.useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const _singleRisiko = await doGetSingleRisiko(project._id, risikoId);
    setSingleRisiko(_singleRisiko);
  };

  const disconnectIssueFromRisiko = async (issueId: string) => {
    await doConnectOrDisconnectIssueFromRisiko(
      project._id,
      singleRisiko.risikoBoard,
      singleRisiko._id,
      issueId,
      "DISCONNECT",
    );
    init();
    dispatch(fetchRisikoAndReplaceItInRedux(risikoId));
  };

  const onIssueCreated = (issue: IIssue) => {
    dispatch(
      addAppModalThunk({
        itemId: issue._id,
        boardId: issue.issueBoard as string,
        projectId: project._id,
        app: AppModalEnums.ISSUE,
      }),
    );
    setNewIssueModalOpen(false);
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.UPDATED));
    init();
    dispatch(fetchRisikoAndReplaceItInRedux(risikoId));
  };

  const onConnecetExistingIssue = () => {
    setConnectExistingModalOpen(false);
    init();
    dispatch(fetchRisikoAndReplaceItInRedux(risikoId));
  };

  function selectedAppModals(state) {
    return state.globalReducer.openedAppModals;
  }

  const showOnPrintMode = () => {
    if (isPrintMode === true) {
      return singleRisiko.connectedIssues.length > 0;
    }
    return true;
  };

  return (
    <>
      {newIssueModalOpen && (
        <AddIssueModal
          issueBoardId={activeBoard.defaultIssueBoard}
          toggle={() => setNewIssueModalOpen(false)}
          isOpen
          onCreateItem={onIssueCreated}
          connectedRisikoId={risikoId}
        />
      )}
      {connectExistingModalOpen && (
        <DepricatedConnectExistingIssueToRisikoModal
          risiko={singleRisiko}
          projectId={project._id}
          onConnected={onConnecetExistingIssue}
          toggle={() => setConnectExistingModalOpen(false)}
        />
      )}
      {singleRisiko != null && showOnPrintMode() && (
        <ConnectedIssues
          issues={singleRisiko.connectedIssues as IIssue[]}
          disconnectIssue={disconnectIssueFromRisiko}
          createNewIssue={() => setNewIssueModalOpen(true)}
          connectExistingIssue={() => setConnectExistingModalOpen(true)}
          isPrintMode={isPrintMode}
          useClassContainer={true}
          titleLabel="Tiltak"
          projectId={project._id}
        />
      )}
    </>
  );
};

export default RisikoRowExpandContent;
