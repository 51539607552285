import { replaceMultipleValuesInString } from "mc-shared/sharedUtils";
import { IBoorHull } from "mc-shared/zod/report/Geoteknik/geoteknikSchema";

export const formatBorrhulDataFromExcel = (borrhulerExcel: string[][]): IBoorHull[] => {
  try {
    const borrhuller: IBoorHull[] = [];
    for (let i = 1; i < borrhulerExcel.length; i++) {
      const losm = Number(replaceCommaWithDot(borrhulerExcel[i][6]));
      const fjell = Number(replaceCommaWithDot(borrhulerExcel[i][7]));
      const borrhul = {
        id: borrhulerExcel[i][0].toString(),
        x: formatCoord(borrhulerExcel[i][1]) ?? "-",
        y: formatCoord(borrhulerExcel[i][2]) ?? "-",
        z: formatCoord(borrhulerExcel[i][3]) ?? "-",
        metode: replaceMultipleValuesInString(borrhulerExcel[i][4].toString(), {
          Total: "TOT",
          Dreie: "DTR",
          Pr: "PR",
          Tolk: "",
        }),
        stopp: borrhulerExcel[i][5] ?? "-",
        losm: formatDecimals(losm, 1) ?? "-",
        fjell: formatDecimals(Number(replaceCommaWithDot(borrhulerExcel[i][7])), 1) || "-",
        totalt: formatDecimals(losm + fjell, 1) ?? "-",
      };
      borrhuller.push(borrhul);
    }
    return borrhuller;
  } catch (error) {
    console.error(error);
    alert("Feil i formatering av borehull");
  }
};

const formatCoord = (value: string): string => {
  if (value == null) {
    return null;
  }
  const num = formatDecimals(Number(replaceCommaWithDot(value)), 1);
  return num?.toString().replace(".", ",");
};

const formatDecimals = (value: number, decimals: number): string => {
  if (value == null) {
    return null;
  }
  return String(value.toFixed(decimals)).replace(".", ",");
};

const replaceCommaWithDot = (value: string): string => {
  if (value == null) {
    return null;
  }
  return value.replace(",", ".");
};
