import { ReactElement, useState } from "react";
import { Input, UncontrolledTooltip } from "reactstrap";
import { HSpace, McDropdownBase } from "../";

export interface IMcDropdownGrouped<T> {
  [key: string]: T[];
}

export default function McDropdownGrouped<T>({
  groups,
  enableSearch = false,
  selectionChanged,
  children,
  displayKey,
  className,
  icon,
  color,
  showTooltip,
  selectedItem,
}: React.PropsWithChildren<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    groups: IMcDropdownGrouped<T>;
    enableSearch?: boolean;
    selectionChanged: (selected: T) => void;
    displayKey: keyof T;
    icon?: ReactElement;
    color?: string;
    showTooltip?: boolean;
    selectedItem: T;
  }
>) {
  const [searchTerm, setSearchTerm] = useState<string>("");

  const setSelected = (item: T) => {
    selectionChanged(item);
  };

  const generateList = () => {
    if (groups == null) {
      return [];
    }

    let counter = 0;

    const elements = Object.keys(groups).reduce((groupAcc, groupName, g) => {
      const items = groups[groupName];
      const filteredItems = items.reduce((itemsAcc, item, i) => {
        if (item[displayKey].toString()?.toLocaleLowerCase().includes(searchTerm?.toLowerCase())) {
          counter += 1;
          const id = `ddItem${counter}`;

          itemsAcc.push(
            <McDropdownBase.Option
              key={counter}
              className="truncateHeaderText"
              id={id}
              onClick={() => setSelected(item)}
              active={selectedItem != null && item[displayKey] === selectedItem[displayKey]}
            >
              {showTooltip === true ? <UncontrolledTooltip target={id}>{item[displayKey]}</UncontrolledTooltip> : <></>}
              {item[displayKey]}
            </McDropdownBase.Option>,
          );
        }

        return itemsAcc;
      }, [] as JSX.Element[]);

      if (filteredItems.length > 0) {
        counter += 1;
        const header = (
          <McDropdownBase.Option key={counter} header>
            <b>{groupName.toUpperCase()}</b>
          </McDropdownBase.Option>
        );

        groupAcc.push(header);
        groupAcc.push(...filteredItems);
      }

      return groupAcc;
    }, [] as JSX.Element[]);

    if (enableSearch === true) {
      counter += 1;
      const filterRow = (
        <McDropdownBase.Option toggle={false} key={counter}>
          <Input
            className="form-control"
            placeholder="Søk"
            value={searchTerm}
            autoFocus
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </McDropdownBase.Option>
      );

      return [filterRow, ...elements];
    }

    return elements;
  };

  return (
    <McDropdownBase color={color} className={className}>
      <McDropdownBase.Display>
        <div className="d-flex align-items-center">
          {icon != null && (
            <>
              {icon}
              <HSpace />
            </>
          )}
          <div className="truncateHeaderText" style={{ maxWidth: "200px" }}>
            {selectedItem == null ? children : selectedItem[displayKey]}
          </div>
        </div>
      </McDropdownBase.Display>
      {generateList()}
    </McDropdownBase>
  );
}
