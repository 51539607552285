import React, { useEffect, useState } from "react";
import { IBatchjobLog } from "../../../../../../types/global.types";
import BatchLogTable from "../../../../components/BatchLogTable/BatchLogTable";
import { useAppSelector } from "../../../../hooks";
import { doGetBatchJobLogs } from "../batchJobAPI";

const BatchJobLogPage: React.FC<{}> = () => {
  const [logs, setLogs] = useState<IBatchjobLog[]>([]);
  const project = useAppSelector((state) => state.adminReducer.project);
  const activeBatchJob = useAppSelector((state) => state.batchJobReducer.activeBatchJob);

  const init = async () => {
    const getLogs = await doGetBatchJobLogs(project?._id, activeBatchJob?._id);
    setLogs(getLogs);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className="d-flex flex-column">
      {logs.length > 0 ? (
        <BatchLogTable projectId={project?._id} logs={logs}></BatchLogTable>
      ) : (
        <div className="mt-2">Ingen logger ennå</div>
      )}
    </div>
  );
};

export default BatchJobLogPage;
