export const beregnetDiffMellomToVerdier = (settpunktVerdi: number, maaltVerdi: number): number => {
  if (isNaN(settpunktVerdi) === true || settpunktVerdi == null || isNaN(maaltVerdi) === true || maaltVerdi == null) {
    return null;
  } else {
    return Math.abs(maaltVerdi - settpunktVerdi);
  }
};

export const beregnetForholdMellomToVerdier = (teller: number, nevner: number): number => {
  if (isNaN(teller) === true || teller == null || isNaN(nevner) === true || nevner == null) {
    return null;
  } else {
    return teller / nevner;
  }
};

export const beregnetProduktMedToVerdier = (verdi1: number, verdi2: number): number => {
  if (isNaN(verdi1) === true || verdi1 == null || isNaN(verdi2) === true || verdi2 == null) {
    return null;
  } else {
    return verdi1 * verdi2;
  }
};

export const beregnetFrittArealIHverRuteIRutenettet = (
  maaleomraadeAreal: number,
  andelFrittAreal: number,
  antallMaalepunkter: number,
): number => {
  if (
    antallMaalepunkter == null ||
    isNaN(antallMaalepunkter) === true ||
    maaleomraadeAreal == null ||
    andelFrittAreal == null ||
    isNaN(andelFrittAreal) === true
  ) {
    return null;
  } else {
    return (maaleomraadeAreal * andelFrittAreal) / antallMaalepunkter;
  }
};

export const beregnetTotalLuftmengdeAnemometer = (
  maaleresultater: number[],
  frittArealIHverRuteIRutenettet: number,
): number => {
  if (
    maaleresultater.length === 0 ||
    frittArealIHverRuteIRutenettet == null ||
    maaleresultater.some((resultat) => isNaN(resultat)) ||
    maaleresultater.some((resultat) => resultat == null)
  ) {
    return null;
  } else {
    return Number(
      maaleresultater?.map((resultat) => resultat * frittArealIHverRuteIRutenettet).reduce((a, b) => a + b, 0),
    );
  }
};

export const beregnetMaksimalLekkasjeverdi = (maaleresultat: number): number => {
  if (isNaN(maaleresultat) === true || maaleresultat == null) {
    return null;
  } else {
    return Number(maaleresultat * 0.01);
  }
};

export const beregnetVarians = (maaleresultater: number[], gjennomsnittligHastighet: number): number => {
  if (
    isNaN(gjennomsnittligHastighet) ||
    gjennomsnittligHastighet == null ||
    maaleresultater.some((resultat) => isNaN(resultat)) ||
    maaleresultater.some((resultat) => resultat == null)
  ) {
    return null;
  } else {
    return (
      (maaleresultater?.map((resultat) => Math.pow(resultat, 2)).reduce((a, b) => a + b, 0) -
        maaleresultater?.length * Math.pow(gjennomsnittligHastighet, 2)) /
      (maaleresultater?.length - 1)
    );
  }
};

export const beregnetStandardAvvik = (varians: number): number => {
  if (isNaN(varians) || varians == null) {
    return null;
  } else {
    return Math.pow(varians, 0.5);
  }
};

export const beregnetLufthastighetUniformitet = (gjennomsnittligHastighet: number, standardAvvik: number): number => {
  if (
    isNaN(gjennomsnittligHastighet) ||
    gjennomsnittligHastighet == null ||
    isNaN(standardAvvik) ||
    standardAvvik == null
  ) {
    return null;
  } else {
    return Number((1 - standardAvvik / gjennomsnittligHastighet) * 100);
  }
};

export const beregnetLuftmengdeGjennomLukeaapning = (gjennomsnittligHastighet: number, areal: number): number => {
  if (
    gjennomsnittligHastighet == null ||
    areal == null ||
    isNaN(areal) ||
    gjennomsnittligHastighet === 0 ||
    isNaN(gjennomsnittligHastighet)
  ) {
    return null;
  } else {
    return gjennomsnittligHastighet * areal;
  }
};

export const beregnetTverrsnittareal = (diameter: number): number => {
  if (diameter == null || isNaN(diameter)) {
    return null;
  } else {
    return Math.PI * Math.pow(diameter / 2, 2);
  }
};

export const beregnetMinimumAntallRuterIRutenett = (maaleomraadeAreal: number): number => {
  if (isNaN(maaleomraadeAreal) === true || maaleomraadeAreal == null) {
    return null;
  } else {
    return Math.ceil(Math.pow(10 * maaleomraadeAreal, 0.5));
  }
};

export const beregnetArealForHvertPunkt = (tverrsnittareal: number, antallMaalepunkter: number): number => {
  if (tverrsnittareal == null || isNaN(tverrsnittareal) || antallMaalepunkter == null || isNaN(antallMaalepunkter)) {
    return null;
  } else {
    return tverrsnittareal / antallMaalepunkter;
  }
};

export const beregnetAvkastLuftmengde = (maaleresultater: number[], arealForHvertPunkt: number): number => {
  if (
    maaleresultater == null ||
    maaleresultater.length === 0 ||
    arealForHvertPunkt == null ||
    isNaN(arealForHvertPunkt) ||
    maaleresultater.some((resultat) => typeof resultat !== "number")
  ) {
    return null;
  } else {
    return maaleresultater?.map((resultat) => resultat * arealForHvertPunkt).reduce((a, b) => a + b, 0);
  }
};

export const beregnetGjennomsnittligHastighetAvtrekkskanal = (
  avkastLuftmengde: number,
  arealLukeaapning: number,
): number => {
  if (avkastLuftmengde == null || isNaN(avkastLuftmengde) || arealLukeaapning == null || isNaN(arealLukeaapning)) {
    return null;
  } else {
    return avkastLuftmengde / arealLukeaapning;
  }
};

export const beregnetGjennomsnittligHastighet = (maaleresultater: number[], antallMaalepunkter: number): number => {
  if (
    maaleresultater?.length === 0 ||
    antallMaalepunkter == null ||
    maaleresultater.some((resultat) => isNaN(resultat)) ||
    maaleresultater.some((resultat) => resultat == null)
  ) {
    return null;
  } else {
    return maaleresultater?.reduce((a, b) => a + b, 0) / antallMaalepunkter;
  }
};

export const beregnetGjennomsnittligLufthastighetsMaaling = (maaleresultater: number[]): number => {
  if (maaleresultater.some((resultat) => typeof resultat !== "number")) {
    return null;
  } else {
    return maaleresultater?.reduce((a, b) => a + b, 0) / maaleresultater.length;
  }
};
