import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProjectDashboard } from "../../../../../types/global.types";

export interface IProjectDashboardReducer {
  editMode: boolean;
  activeLayout?: IProjectDashboard;
  allLayouts?: IProjectDashboard[];
}

const initialState: IProjectDashboardReducer = {
  editMode: false,
  activeLayout: null,
  allLayouts: null,
};

export const projectDashboardSlice = createSlice({
  name: "projectdashboard",
  initialState,
  reducers: {
    resetProjectDashboardRedux: (state) => {
      state.activeLayout = null;
      state.allLayouts = null;
      state.activeLayout = null;
    },
    setProjectDashBoardEditModeInRedux: (state, action: PayloadAction<boolean>) => {
      state.editMode = action.payload;
    },
    setProjectDashboardActiveLayoutInRedux: (state, action: PayloadAction<IProjectDashboard>) => {
      state.activeLayout = action.payload;
    },
    setProjectDashboardAllLayoutsInRedux: (state, action: PayloadAction<IProjectDashboard[]>) => {
      state.allLayouts = action.payload;
    },
  },
});

export const {
  resetProjectDashboardRedux,
  setProjectDashBoardEditModeInRedux,
  setProjectDashboardActiveLayoutInRedux,
  setProjectDashboardAllLayoutsInRedux,
} = projectDashboardSlice.actions;
