import { Button, ListGroup, ListGroupItem } from "reactstrap";
import PowerBIDoc from "./PowerBIDocument";
import VSpace from "../VSpace";
import { useState } from "react";
import ExcelDoc from "./ExcelDocument";
import ConnectorDoc from "./ConnectorDocument";
import PythonDoc from "./PythonDocument";

interface IDocumentMenuProps {}

const McBimDocumentation: React.FC<IDocumentMenuProps> = () => {
  const [activeDocument, setActiveDocument] = useState<string>("");
  return (
    <div className="gray-100" style={{ minHeight: "500px", borderRadius: "6px" }}>
      {activeDocument === "" ? (
        <div className="p-3">
          <h3 className="mb-3"> Koble til MCBIM</h3>
          <ListGroup>
            <h4 className="mb-3">Power BI / Excel</h4>
            <p>For å få tilgang til MCBIM-Databasen må du første gang opprette en kobling til databasen</p>
            <ListGroupItem
              action
              className="d-flex align-items-center"
              style={{ cursor: "pointer" }}
              onClick={() => setActiveDocument("connector")}
            >
              <img src="/images/MongoDB-sm.png" alt="mongoDB" style={{ width: "30px" }} />
              <div className="d-flex flex-column w-100 ml-2">
                <h4 className="mb-0">MongoDB Connector</h4>
                <p className="mb-0" style={{ fontSize: "14px", color: "#ccc" }}>
                  Opprette kobling til datakilde
                </p>
              </div>
              <i className="fa fa-arrow-circle-o-right fa-lg gray-300-text" />
            </ListGroupItem>
            <VSpace />
            <p>Når dette er på plass, kan du lese inn data i Power BI og/eller Excel:</p>
            <ListGroupItem
              action
              className="d-flex align-items-center"
              style={{ cursor: "pointer" }}
              onClick={() => setActiveDocument("powerbi")}
            >
              <img src="/images/powerbi-sm.png" alt="powerbi" style={{ width: "30px" }} />
              <div className="d-flex flex-column w-100 ml-2">
                <h4 className="mb-0">Power BI</h4>
                <p className="mb-0" style={{ fontSize: "14px", color: "#ccc" }}>
                  Koble Power BI til MCBIM-database
                </p>
              </div>
              <i className="fa fa-arrow-circle-o-right fa-lg gray-300-text" />
            </ListGroupItem>
            <VSpace />
            <ListGroupItem
              action
              className="d-flex align-items-center"
              style={{ cursor: "pointer" }}
              onClick={() => setActiveDocument("excel")}
            >
              <img src="/images/excel-sm.png" alt="excel" style={{ width: "30px" }} />
              <div className="d-flex flex-column w-100 ml-2">
                <h4 className="mb-0">Excel</h4>
                <p className="mb-0" style={{ fontSize: "14px", color: "#ccc" }}>
                  Koble Excel til MCBIM-database
                </p>
              </div>
              <i className="fa fa-arrow-circle-o-right fa-lg gray-300-text" />
            </ListGroupItem>
            <VSpace />
            <VSpace />

            <h4 className="mb-3">Koble applikasjoner til MCBIM</h4>

            <ListGroupItem
              action
              className="d-flex align-items-center"
              style={{ cursor: "pointer" }}
              onClick={() => setActiveDocument("python")}
            >
              <img src="/images/python-sm.svg" alt="python" style={{ width: "30px" }} />
              <div className="d-flex flex-column w-100 ml-2">
                <h4 className="mb-0">Python</h4>
                <p className="mb-0" style={{ fontSize: "14px", color: "#ccc" }}>
                  Koble Python til MCBIM-database
                </p>
              </div>
              <i className="fa fa-arrow-circle-o-right fa-lg gray-300-text" />
            </ListGroupItem>
          </ListGroup>
        </div>
      ) : (
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-between p-1">
            <Button color="secondary" onClick={() => setActiveDocument("")}>
              Tilbake
            </Button>
          </div>
          <div style={{ height: "80vh", overflow: "auto" }} className="p-3">
            {activeDocument === "connector" && <ConnectorDoc />}
            {activeDocument === "powerbi" && <PowerBIDoc />}
            {activeDocument === "excel" && <ExcelDoc />}
            {activeDocument === "python" && <PythonDoc />}
          </div>
        </div>
      )}
    </div>
  );
};

export default McBimDocumentation;
