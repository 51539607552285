import { IComment } from "../../../../../types/global.types";
import { IMeeting } from "../../../../../types/meeting.types";
import { apiSlice, getCacheKey } from "../../../apiSlice";
import { ToastMessagesEnums } from "../../../frontendConstants";
import { meetingRTK } from "./meetingRTK";
import { setShowSuccessMessageThunk } from "../../../global/globalSlice";

export const meetingAgendaRTK = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createMeetingAgendaItem: builder.mutation<IMeeting.AgendaItem, { projectId: string; meetingId: string }>({
      query: (input) => ({
        url: `/project/${input.projectId}/meetingagenda/meeting/${input.meetingId}`,
        method: "POST",
      }),
      invalidatesTags: ["MeetingAgenda", "Meeting"],
    }),
    updateMeetingAgenda: builder.mutation<
      IMeeting.AgendaItem,
      { projectId: string; agendaId: string; attribute: string; value: any }
    >({
      query(input) {
        return {
          url: `/project/${input.projectId}/meetingagenda/${input.agendaId}/attribute`,
          method: "PUT",
          body: {
            attribute: input.attribute,
            value: input.value,
          },
        };
      },
      async onQueryStarted({ agendaId, value, attribute }, { dispatch, queryFulfilled, getState }) {
        patchAgendaItemAfterUpdateWithOptimisticUpdate(agendaId, value, attribute, {
          dispatch,
          queryFulfilled,
          getState,
        });
      },
    }),
    addAgendaPreperationItemTodo: builder.mutation<void, { projectId: string; agendaId: string; text: string }>({
      query(input) {
        return {
          url: `/project/${input.projectId}/meetingagenda/todo/${input.agendaId}/add`,
          method: "POST",
          body: { text: input.text },
        };
      },
      async onQueryStarted({ agendaId }, { dispatch, queryFulfilled, getState }) {
        replaceAgendaItemWithPessimisticUpdate(agendaId, {
          dispatch,
          queryFulfilled,
          getState,
        });
      },
    }),
    updateAgendaPreperationItemToDo: builder.mutation<
      void,
      {
        projectId: string;
        agendaId: string;
        todoId: string;
        text: string;
      }
    >({
      query(input) {
        return {
          url: `/project/${input.projectId}/meetingagenda/todo/${input.agendaId}/update`,
          method: "PUT",
          body: {
            text: input.text,
            todoId: input.todoId,
          },
        };
      },
      async onQueryStarted({ agendaId }, { dispatch, queryFulfilled, getState }) {
        replaceAgendaItemWithPessimisticUpdate(agendaId, {
          dispatch,
          queryFulfilled,
          getState,
        });
      },
    }),
    toggleAgendaPreperationItemTodo: builder.mutation<
      IMeeting.AgendaItem,
      {
        projectId: string;
        agendaId: string;
        todoId: string;
        isChecked: boolean;
      }
    >({
      query(input) {
        return {
          url: `/project/${input.projectId}/meetingagenda/todo/${input.agendaId}/toggle`,
          method: "PUT",
          body: {
            isChecked: input.isChecked,
            todoId: input.todoId,
          },
        };
      },
      async onQueryStarted({ agendaId }, { dispatch, queryFulfilled, getState }) {
        replaceAgendaItemWithPessimisticUpdate(agendaId, {
          dispatch,
          queryFulfilled,
          getState,
        });
      },
    }),
    deleteAgendaPreperationItemToDo: builder.mutation<
      IMeeting.AgendaItem,
      {
        projectId: string;
        agendaId: string;
        todoId: string;
      }
    >({
      query(input) {
        return {
          url: `/project/${input.projectId}/meetingagenda/todo/${input.agendaId}/delete/${input.todoId}`,
          method: "DELETE",
        };
      },
      async onQueryStarted({ agendaId }, { dispatch, queryFulfilled, getState }) {
        replaceAgendaItemWithPessimisticUpdate(agendaId, {
          dispatch,
          queryFulfilled,
          getState,
        });
      },
    }),
    connectOrDisconnectIssueFromAgendaItem: builder.mutation<
      IMeeting.AgendaItem,
      {
        projectId: string;
        agendaId: string;
        issueId: string;
        connectOrDisconnect: "CONNECT" | "DISCONNECT";
      }
    >({
      query(input) {
        return {
          url: `/project/${input.projectId}/meetingagenda/${input.agendaId}/connect-disconnect-issue/${input.issueId}`,
          method: "PUT",
          body: {
            connectOrDisconnect: input.connectOrDisconnect,
          },
        };
      },
      async onQueryStarted({ agendaId }, { dispatch, queryFulfilled, getState }) {
        replaceAgendaItemWithPessimisticUpdate(agendaId, {
          dispatch,
          queryFulfilled,
          getState,
        });
      },
    }),
    addAgendaComment: builder.mutation<
      IMeeting.AgendaItem,
      {
        projectId: string;
        agendaId: string;
        commentId: string;
      }
    >({
      query(input) {
        return {
          url: `/project/${input.projectId}/meetingagenda/${input.agendaId}/comment/${input.commentId}`,
          method: "POST",
        };
      },
      async onQueryStarted({ agendaId }, { dispatch, queryFulfilled, getState }) {
        replaceAgendaItemWithPessimisticUpdate(agendaId, {
          dispatch,
          queryFulfilled,
          getState,
        });
      },
    }),
    deleteAgendaComment: builder.mutation<
      IMeeting.AgendaItem,
      {
        projectId: string;
        agendaId: string;
        commentId: string;
      }
    >({
      query(input) {
        return {
          url: `/project/${input.projectId}/meetingagenda/${input.agendaId}/comment/${input.commentId}`,
          method: "DELETE",
        };
      },
      async onQueryStarted({ agendaId }, { dispatch, queryFulfilled, getState }) {
        replaceAgendaItemWithPessimisticUpdate(agendaId, {
          dispatch,
          queryFulfilled,
          getState,
        });
      },
    }),
    updateAgendaComment: builder.mutation<
      IMeeting.AgendaItem,
      {
        projectId: string;
        commentId: string;
        text: string;
        agendaId: string;
      }
    >({
      query(input) {
        return {
          url: `/project/${input.projectId}/comment/${input.commentId}`,
          method: "PUT",
          body: {
            text: input.text,
          },
        };
      },
      invalidatesTags: ["Meeting", "MeetingAgenda"],
    }),
    updateAgendaOrder: builder.mutation<
      IMeeting.AgendaItem,
      {
        projectId: string;
        agendaId: string;
        order: number;
      }
    >({
      query(input) {
        return {
          url: `/project/${input.projectId}/meetingagenda/${input.agendaId}/order`,
          method: "PUT",
          body: {
            order: input.order,
          },
        };
      },
      invalidatesTags: ["Meeting"],
    }),
  }),
});

export const {
  useUpdateMeetingAgendaMutation,
  useAddAgendaPreperationItemTodoMutation,
  useDeleteAgendaPreperationItemToDoMutation,
  useUpdateAgendaPreperationItemToDoMutation,
  useToggleAgendaPreperationItemTodoMutation,
  useAddAgendaCommentMutation,
  useUpdateAgendaCommentMutation,
  useDeleteAgendaCommentMutation,
  useConnectOrDisconnectIssueFromAgendaItemMutation,
  useCreateMeetingAgendaItemMutation,
  useUpdateAgendaOrderMutation,
} = meetingAgendaRTK;

const patchAgendaItemAfterUpdateWithOptimisticUpdate = async (
  agendaId,
  value,
  attribute,
  { dispatch, queryFulfilled, getState },
) => {
  if (attribute === "notRelevant" || attribute === "duration") {
    //invalidate tag since we need to update the list of agenda items
    dispatch(meetingRTK.util.invalidateTags(["Meeting"]));
    return;
  }

  const queries = getState().api.queries;
  const cacheKey = getCacheKey(queries, "getSingleMeetingInstance");
  const patchResult = dispatch(
    meetingRTK.util.updateQueryData("getSingleMeetingInstance", cacheKey, (draft) => {
      draft.agendaItems.map((agendaItem) => {
        if (agendaItem._id === agendaId) {
          agendaItem[attribute] = value;
          //Object.assign(agendaItem, args);
        }
        return agendaItem;
      });
    }),
  );
  try {
    await queryFulfilled;
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.UPDATED));
  } catch {
    alert("Kunne ikke oppdatere. Prøv igjen. Hvis problemene vedvarer, kontakt support på mcapps@multiconsult.no");
    // dispatch(meetingAgendaRTK.util.invalidateTags(["MeetingAgenda", "Meeting"]));
    patchResult.undo();
  }
};

const patchAgendaCommentAfterUpdate = async (agendaId: string, { dispatch, queryFulfilled, getState }) => {
  const resp = await queryFulfilled;
  const updatedComment: IComment = resp.data;
  const queries = getState().api.queries;
  const cacheKey = getCacheKey(queries, "getSingleMeetingInstance");
  const patchResult = dispatch(
    meetingRTK.util.updateQueryData("getSingleMeetingInstance", cacheKey, (draft) => {
      draft.agendaItems.map((agendaItem) => {
        if (agendaItem._id === agendaId) {
          const updatedComments = agendaItem.comments.map((comment) => {
            if (comment._id === updatedComment._id) {
              Object.assign(comment, updatedComment);
            }
            return comment;
          });

          const updatedAgenda = {
            ...agendaItem,
            comments: updatedComments,
          };

          Object.assign(agendaItem, updatedAgenda);
        }
        return agendaItem;
      });
    }),
  );
  dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.UPDATED));
};
/*

export const patchAgendaItemAfterAddingItem = async (meetingId: string, { dispatch, queryFulfilled, getState }) => {
  const resp = await queryFulfilled;
  const updatedAgendaItem: IMeeting.AgendaItem = resp.data;
  const queries = getState().api.queries;
  const cacheKey = getCacheKey(queries, "getSingleMeetingInstance");
  const patchResult = dispatch(
    meetingRTK.util.updateQueryData("getSingleMeetingInstance", cacheKey, (draft) => {
      draft.agendaItems.push(updatedAgendaItem);
    }),
  );
  dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.UPDATED));
};
*/

const replaceAgendaItemWithPessimisticUpdate = async (agendaId, { dispatch, queryFulfilled, getState }) => {
  const resp = await queryFulfilled;
  const data: IMeeting.AgendaItem = resp.data;
  const queries = getState().api.queries;
  const cacheKey = getCacheKey(queries, "getSingleMeetingInstance");
  const patchResult = dispatch(
    meetingRTK.util.updateQueryData("getSingleMeetingInstance", cacheKey, (draft) => {
      draft.agendaItems.map((agendaItem) => {
        if (agendaItem._id === agendaId) {
          Object.assign(agendaItem, data);
        }
        return agendaItem;
      });
    }),
  );
  dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.UPDATED));
};
