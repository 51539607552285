import { ColDef, ICellRendererParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import * as R from "ramda";
import React, { useRef, useState } from "react";
import { Button } from "reactstrap";
import { IMCBIMAPI } from "../../../../../../../types/mcbim.types";
import { AG_GRID_LOCALE_NOR } from "../../../../../agGridLocale";
import { sortArrayByString } from "../../../../utils";

const McBimAgFileTable: React.FC<{
  ifcFiles: IMCBIMAPI.IfcFile[];
  onChangeIfcFiles: (files: IMCBIMAPI.IfcFile[]) => void;
  fileAttributeExtractSettings: IMCBIMAPI.AllFourFileAttributeExtracSettings;
}> = ({ ifcFiles, onChangeIfcFiles, fileAttributeExtractSettings }) => {
  const gridRef = useRef<AgGridReact>(); // Optional - for
  const [dataToEdit, setDataToEdit] = useState<IMCBIMAPI.IfcFile[]>(null);

  const [columnDefs, setColumnDefs] = useState<ColDef[]>([
    {
      field: "fileStorageId",
      filter: true,
      headerName: "fileStorageId",
      hide: true,
      resizable: true,
    },
    {
      checkboxSelection: true,
      headerCheckboxSelection: true,
      field: "filePath",
      filter: true,
      headerName: "Sti til fil",
      resizable: true,
      sortable: true,
    },
    {
      field: "fileName",
      filter: true,
      headerName: "Fil",
      width: 300,
      resizable: true,
      sortable: true,
    },
  ]);

  const getFileAttrColumn = (
    fileAttr: IMCBIMAPI.FileAttributeExtractSetting,
    field: "fileAttr1" | "fileAttr2" | "fileAttr3" | "fileAttr4",
  ): ColDef => {
    return {
      field,
      filter: true,
      //TODO: Does not work to sort on fileAttr 1-4 or to filter with these attributes
      //sortable: true,
      headerName: fileAttr?.name,
      resizable: true,
      valueFormatter: (params) => {
        const fileNames = params?.data?.fileName;
        const value = getFileAttr(fileNames, fileAttr);
        return value;
      },
    };
  };

  React.useEffect(() => {
    if (ifcFiles?.length !== dataToEdit?.length) {
      setDataToEdit(R.clone(sortArrayByString(ifcFiles, "fileName")));
      const fileAttr1 = getFileAttrColumn(fileAttributeExtractSettings?.fileAttr1, "fileAttr1");
      const fileAttr2 = getFileAttrColumn(fileAttributeExtractSettings?.fileAttr2, "fileAttr2");
      const fileAttr3 = getFileAttrColumn(fileAttributeExtractSettings?.fileAttr3, "fileAttr3");
      const fileAttr4 = getFileAttrColumn(fileAttributeExtractSettings?.fileAttr4, "fileAttr4");
      const newColumnDefs = columnDefs
        .concat([fileAttr1, fileAttr2, fileAttr3, fileAttr4])
        .filter((col) => col.headerName !== undefined);
      setColumnDefs(newColumnDefs);
    }
  }, [ifcFiles?.length]);

  function onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  const deleteSelected = () => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    if (selectedRows.length === 0) {
      alert("No files selected");
      return;
    }

    const filesToRemove = gridRef.current.api.getSelectedRows();
    const updatedIfcFiles = dataToEdit.filter((ifcFile) => {
      return filesToRemove.some((file) => file.fileStorageId === ifcFile.fileStorageId) === false;
    });
    onChangeIfcFiles(R.clone(updatedIfcFiles));
    gridRef.current.api.deselectAll();
  };

  return (
    <>
      <Button onClick={deleteSelected} outline>
        <i className="fa fa-trash-o" />
      </Button>
      <div className="ag-theme-alpine h-100" style={{ overflow: "auto" }}>
        <AgGridReact
          ref={gridRef} // Ref
          onFirstDataRendered={onFirstDataRendered}
          rowData={dataToEdit}
          columnDefs={columnDefs}
          suppressClickEdit={true}
          animateRows={true}
          rowSelection="multiple" // Options - allows click selection of rows
          localeText={AG_GRID_LOCALE_NOR}
        />
      </div>
    </>
  );
};

export default McBimAgFileTable;

const CellRenderer = (props: ICellRendererParams) => {
  return (
    <>
      {props.value != null && <span style={{ paddingLeft: "4px" }}>{props.value}</span>}
      {props.value == null && <span className="text-danger">Verdi mangler</span>}
    </>
  );
};

const getFileAttr = (
  fileName: string,
  fileAttributeExtractSettings: IMCBIMAPI.FileAttributeExtractSetting,
): undefined | string => {
  const { regexp } = fileAttributeExtractSettings;
  const match = fileName.match(new RegExp(regexp));
  if (match == null) {
    return "NO_VALUE";
  }
  return match[1] || "NO_VALUE";
};
