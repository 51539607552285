import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink } from "reactstrap";
import { IParentChildItem } from "../../../../../types/global.types";
import RisikoPnsTable from "./RisikoPnsTable";

const PnsItemModal: React.FC<{
  isOpen: boolean;
  node: IParentChildItem;
  toggle: any;
  projectId: string;
}> = ({ isOpen, node, toggle, projectId }) => {
  const [activeTab, setActiveTab] = useState("ISSUE");
  useEffect(() => {}, []);

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="medium-modal" style={{ maxWidth: "90vw", maxHeight: "90vh" }}>
      <ModalHeader toggle={toggle}>
        PNS {node.subLabel} : {node.label}
      </ModalHeader>
      <ModalBody>
        <Nav tabs>
          {/* <NavItem className="custom" onClick={() => setActiveTab("ISSUE")}>
            <NavLink className={activeTab === "ISSUE" ? "active" : ""}>SAKSREGISTER</NavLink>
          </NavItem>*/}
          <NavItem className="custom" onClick={() => setActiveTab("RISK")}>
            <NavLink className={activeTab === "RISK" ? "active" : ""}>RISIKOREGISTER</NavLink>
          </NavItem>
        </Nav>
        <div className="p-3 border">
          {activeTab === "RISK" && <RisikoPnsTable projectId={projectId} pns={node.subLabel} />}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Lukk
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PnsItemModal;
