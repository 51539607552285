import React from "react";
import { useHistory } from "react-router-dom";
import { Card, CardBody, CardHeader } from "reactstrap";
import { IUser } from "../../../../../types/global.types";
import { logicWhenAppStarts } from "../../actions";
import { doAcceptInvite, doGetUserByPassRequestCode, doLoginLocal } from "../../userApi";
import CreateAccount from "./CreateAccount/CreateAccount";
import { useAppDispatch } from "../../../hooks";

const AcceptInvite: React.FC<{ code: string; userId: string }> = ({ code, userId }) => {
  const [notValid, setNotValid] = React.useState<boolean>(false);
  const [user, setUser] = React.useState<IUser>(null);
  React.useEffect(() => {
    init();
  }, []);

  const history = useHistory();
  const dispatch = useAppDispatch();

  const init = async () => {
    const _user = await doGetUserByPassRequestCode(code, userId);
    if (_user?.email == null) {
      setNotValid(true);
    } else {
      if (_user.acceptedInvite === true) {
        history.push("/projects");
      } else {
        setUser(_user);
      }
    }
  };

  const createAccount = async (name: string, password: string) => {
    await doAcceptInvite(code, userId, password, name);
    await doLoginLocal(user.email, password);
    history.push("/projects");
    setTimeout(() => {
      dispatch(logicWhenAppStarts(history));
    }, 500);
  };

  return (
    <div className="d-flex">
      <div className="flex-fill" />
      {notValid === true && (
        <Card className="m-5" style={{ width: "500px" }}>
          <CardHeader>Opprett konto</CardHeader>
          <CardBody>
            {notValid === true && (
              <>
                <p>Denne lenken er ikke lenger gyldig. Det kan være at du allerede har opprettet en konto.</p>
                <a href="/login">Gå til Login</a>
                <div className="mt-4">
                  Support: <br />
                  <a href="mailto:mcapps@multiconsult.no">mcapps@multiconsult.no</a>
                </div>
              </>
            )}
          </CardBody>
        </Card>
      )}
      {notValid === false && (
        <div className="mt-5" style={{ width: "300px" }}>
          <CreateAccount onCreateAccount={createAccount} />
        </div>
      )}
      <div className="flex-fill" />
    </div>
  );
};

export default AcceptInvite;
