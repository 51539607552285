import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Progress } from "reactstrap";
import { IMilestone } from "../../../../../../types/global.types";
import WidgetWrapper from "../../../../components/WidgetWrapper/WidgetWrapper";
import { useAppSelector } from "../../../../hooks";

interface IProgress extends IMilestone {
  complete: boolean;
}
const TimelineWidget: React.FC = () => {
  const project = useAppSelector((state) => state.adminReducer.project);
  const [progressArray, setProgressArray] = useState<IProgress[]>([]);
  const [completion, setCompletion] = useState(0);
  const timeLineRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  const num = (i) => {
    return (i / (progressArray.length - 1)) * 100;
  };

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      if (entries[0]) {
        setWidth(entries[0].contentRect.width);
      }
    });
    if (timeLineRef.current) {
      observer.observe(timeLineRef.current);
    }
    return () => {
      if (timeLineRef.current) {
        observer.unobserve(timeLineRef.current);
      }
    };
  }, []);
  useEffect(() => {
    renderMilestones(project.milestones);
  }, [project.milestones]);

  useEffect(() => {
    if (progressArray.length > 0) {
      renderDate();
    }
  }, [progressArray]);

  const renderMilestones = (milestones) => {
    //places milestones in order of due date, and sets completed to true if due date is before today
    const copy = [...milestones];
    const dateNow = moment().format("YYYY-MM-DD");
    const getTime = (date?: Date) => {
      return date != null ? new Date(date).getTime() : 0;
    };
    const sortedByDate = copy.sort((a, b) => getTime(a.dueDate) - getTime(b.dueDate));
    if (sortedByDate[0]?.dueDate > dateNow) {
      sortedByDate.unshift({
        dueDate: dateNow,
        name: "I dag",
      });
    }

    setProgressArray(
      sortedByDate.map((item) => {
        return {
          ...item,
          complete: item.dueDate <= dateNow,
        };
      }),
    );
  };

  const MilestoneLabel = ({ item, index, isLast }) => {
    return (
      <div
        style={{
          fontSize: `${isTextOverflow ? ".75em" : ".9em"}`,
          position: "absolute",
          top: `${isTextOverflow && index % 2 !== 0 ? "-35px" : "26px"}`,
          lineHeight: "1.2em",
          paddingRight: "10px",
          left: "2px",
          right: "0",
          width: `${isTextOverflow ? sectionWidth * 2 : sectionWidth}px`,
          maxWidth: `${isLast ? collapsedWidth : ""}px`,
        }}
      >
        <div className="d-flex flex-column align-items-start">
          <div className={`${isTextOverflow ? "text-truncate" : "two-line-text-truncate"}`} style={{ fontWeight: 500 }}>
            {item.name}
          </div>
          <div className="text-secondary" style={{ fontSize: ".8em" }}>
            {item.dueDate}
          </div>
        </div>
      </div>
    );
  };

  const renderDate = () => {
    //renders the progress bar between milestones, milestones are set equal distance apart
    const dateNow = moment().format("YYYY-MM-DD");
    //@ts-ignore
    let indexOfPrevious = progressArray.findLastIndex((item) => item.dueDate < dateNow);
    if (indexOfPrevious === -1) {
      indexOfPrevious = 0;
    }
    const PreviousDate = progressArray[indexOfPrevious];
    const $min = Date.parse(indexOfPrevious > -1 ? PreviousDate.dueDate : dateNow);
    const $max = Date.parse(
      progressArray[indexOfPrevious === progressArray.length - 1 ? indexOfPrevious : indexOfPrevious + 1].dueDate,
    );
    const $current = Date.parse(dateNow);
    const $percentage = (100 - (($max + $current * -1) * 100) / ($max - $min)) / 100;
    return setCompletion(num(indexOfPrevious + $percentage));
  };
  const isTextOverflow = width < 125 * progressArray.length - 1;
  const sectionWidth = width / progressArray.length - 1;
  const collapsedWidth = sectionWidth < 130 ? sectionWidth : 130;
  const timelineWidth = width - collapsedWidth;
  return (
    <WidgetWrapper title="" widgetKey="timeline">
      <div ref={timeLineRef} className="position-absolute" style={{ top: 10, width: "100%" }}>
        {progressArray.length > 0 ? (
          <div
            className="position-relative ml-2"
            style={{
              marginRight: "100px",
              marginTop: `${isTextOverflow ? "2.4em" : ".6em"}`,
              width: `${timelineWidth}px`,
            }}
          >
            <div className="w-100 d-flex justify-content-between position-absolute" style={{ bottom: "-6px" }}>
              {progressArray.map((item, index) => (
                <div key={index} style={{ position: "relative" }}>
                  <MilestoneLabel item={item} index={index} isLast={index === progressArray.length - 1} />
                  <div className="circle" style={{ backgroundColor: `${item.complete ? "#00975f" : "white"}` }}></div>
                </div>
              ))}
            </div>
            <Progress color="success" className="w-100 thin-progress" value={completion} />
          </div>
        ) : (
          <div style={{ display: "grid", placeItems: "center", height: "75%" }}>
            <p>
              Ingen milepæler opprettet! Klikk her for å håndtere milepæler:{" "}
              <a href={`#/project/${project?._id}/settings/milestones`}>instillinger</a>
            </p>
          </div>
        )}
      </div>
    </WidgetWrapper>
  );
};

export default TimelineWidget;
