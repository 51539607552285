import React, { useState } from "react";
import { useCopyToClipboard } from "usehooks-ts";
import { IBoard, IUserActiveItem } from "../../../../types/global.types";
import { STANDARD_COLORS } from "../../app/globalConstants";
import { IMemberWithDomain } from "../../app/routes/admin/members/MembersTable";
import IssueItemModal from "../../app/routes/issuesApp/IssuesTab/IssueItemModal/IssueItemModal";
import { createActiveAssignedItemsColumns } from "../../app/routes/projectdashboard/ActiveAssignedItems/UserAssignedIssuesInProject";
import Avatar from "../Avatar";
import FoldableSection from "../FoldableSection/FoldableSection";
import "./MemberCard.css";
import { AgGridReact } from "ag-grid-react";

interface IMemberCardProps {
  member: IMemberWithDomain;
  assignedItems?: IUserActiveItem[];
  projectId: string;
  orgTitle?: string;
}
const MemberCard: React.FC<IMemberCardProps> = ({ member, assignedItems, projectId, orgTitle }) => {
  const [, copy] = useCopyToClipboard();
  const [activeAssignedItem, setActiveAssignedItem] = useState<IUserActiveItem>(null);

  const infoRows = [
    { label: "tlf", value: member?.user?.phoneNumber ?? "-" },
    { label: "epost", value: member?.user?.email ?? "-" },
    { label: "disiplin", value: member?.disciplin ?? "-" },
    { label: "domene", value: member?.domain ?? "-" },
  ];
  const roleColors = {
    ADMIN: STANDARD_COLORS.orange,
    PARTNER: STANDARD_COLORS.green,
    INTERNAL: STANDARD_COLORS.grey,
    CLIENT: STANDARD_COLORS.blue,
  };

  return (
    <>
      <div className="memberCard">
        <div className="d-flex flex-column align-items-center">
          <div style={{ marginRight: "-.5em" }}>
            <Avatar id={member?.user?._id} height="100px" />
          </div>
          <h3 className="my-1">{member?.user?.name}</h3>{" "}
          {orgTitle && <p className="font-weight-bold mb-2">{orgTitle}</p>}
          <div
            className="memberGroupBadge"
            style={{ color: `${roleColors[member?.group]}`, border: `3px solid ${roleColors[member?.group]}` }}
          >
            {member?.group}
          </div>
        </div>
        <div className="infoContainer">
          {infoRows.map((row, i) => (
            <div key={i} className="d-flex  align-items-center rowContainer" style={{ fontSize: "1.1em" }}>
              <div className="rowLabel">{row.label}:</div>
              <div className="rowValue">{row.value}</div>
              <button className="addIcon" onClick={() => copy(row.value)}>
                <i className="fa fa-files-o"></i>
              </button>
            </div>
          ))}
        </div>
        <div className="foldableContainer">
          {assignedItems != null && (
            <FoldableSection folded={true} headerClick={true}>
              <FoldableSection.Header>
                <div className="d-flex align-items-center section-title">
                  Aktive Oppgaver
                  <div
                    className="textCircle ml-2"
                    style={{ fontSize: "0.7em", width: "1.7em", height: "1.7em", marginTop: ".2em" }}
                  >
                    {assignedItems?.length}
                  </div>
                </div>
              </FoldableSection.Header>
              <FoldableSection.Body>
                <div className="d-flex flex-column h-100 mt-2 mb-2 tableContainer ag-theme-quartz">
                  {assignedItems && assignedItems.length > 0 && (
                    <AgGridReact
                      rowData={assignedItems}
                      columnDefs={createActiveAssignedItemsColumns(setActiveAssignedItem)}
                      domLayout="autoHeight"
                      headerHeight={40}
                      defaultColDef={{
                        sortable: false,
                        filter: false,
                        suppressHeaderMenuButton: true,
                        cellStyle: {
                          whiteSpace: "normal",
                          lineHeight: "1.5",
                          wordBreak: "break-word",
                        },
                        autoHeight: true,
                      }}
                      onGridReady={(e) => {
                        e.api.autoSizeColumns(["id", "name", "dueDate", "status"]);
                      }}
                    />
                  )}
                </div>
              </FoldableSection.Body>
            </FoldableSection>
          )}
        </div>
      </div>
      {activeAssignedItem && activeAssignedItem.app === "issues" && (
        <IssueItemModal
          itemId={activeAssignedItem.itemId}
          issueBoardId={(activeAssignedItem.board as IBoard)._id}
          projectId={projectId}
        />
      )}
    </>
  );
};

export default MemberCard;
