import React from "react";
import { IBoard, IIssue, IMinProject } from "../../../../../../../../types/global.types";
import ButtonGroupWithBadge from "../../../../../../components/ButtonGroupWithBadge/ButtonGroupWithBadge";
import { IButtonWithBadge } from "../../../../../../types";
import { useGetFilesFromAzureBlobStorageQuery } from "../../../../../azureBlobStorageRTK";
import { getIssueActionNameOption, getTaskCompleteCount } from "../../../issueUtils";

const IssueItemButtonGroups: React.FC<{
  issue: IIssue;
  activeButtonName: string;
  setSelectedButtonName;
  taskOrIssue: string;
  projectIsGisActivated: boolean;
  issueBoard: IBoard;
}> = ({ issue, activeButtonName, setSelectedButtonName, taskOrIssue, projectIsGisActivated, issueBoard }) => {
  const project = issue?.project as IMinProject;
  const projectId = project?._id;
  const path = `${projectId}/issue/${issue?._id}/files`;

  const {
    data: files = [],
    isLoading: loadingFiles,
    isError: errorFetchingFiles,
  } = useGetFilesFromAzureBlobStorageQuery({ projectId, path });

  const buttons: IButtonWithBadge[] = [
    {
      name: "Info",
    },
  ];

  if (taskOrIssue === "ISSUE") {
    buttons.push({
      name: getIssueActionNameOption(issueBoard?.taskNameSetting, "plural"),
      taskCompleteCount: getTaskCompleteCount(issue),
    });
  }
  buttons.push({
    name: "Filer",
    badgeCount: files?.length,
  });

  if (projectIsGisActivated) {
    /* buttons.push({
      name: "GIS",
      badgeIcon: hasIssueGisConnection(issue) ? "fa-globe" : null,
    });*/
    buttons.push({
      name: "Logg",
    });
  } else {
    buttons.push({ name: "Logg" });
  }

  return (
    <ButtonGroupWithBadge
      buttons={buttons}
      activeButtonName={activeButtonName}
      setSelectedButtonName={setSelectedButtonName}
      className="w-100 mr-2"
      style={{ height: "2.2rem" }}
    />
  );
};

export default IssueItemButtonGroups;
