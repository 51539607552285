import { IGKMaalingAvInnstroemningsHastighetResultater, IGKRapport } from "mc-shared/zod/report/GK/gkReportSchema";
import React, { useEffect } from "react";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../../types/global.types";
import { HSpace, McInput } from "../../../../../../components";
import ReportDocxImageContainer from "../../../ReportBoard/ReportDocxImageContainer";
import {
  beregnetGjennomsnittligHastighet,
  beregnetLuftmengdeGjennomLukeaapning,
  beregnetProduktMedToVerdier,
} from "../gkGlobalUtils";

const MaalingAvInnstroemningsHastighet: React.FC<{
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
  data: IGKMaalingAvInnstroemningsHastighetResultater;
}> = ({ data, onUpdateEditData }) => {
  useEffect(() => {
    utdataBeregninger();
  }, [
    data.inndata.maaleresultaterVenstre.maaleresultater,
    data.inndata.maaleresultaterVenstre.maaleresultater.length,
    data.inndata.maaleresultaterHoeyre.maaleresultater,
    data.inndata.maaleresultaterHoeyre.maaleresultater.length,
    data.inndata.maaleresultaterVenstre.volum,
    data.inndata.maaleresultaterVenstre.luftskifter,
    data.inndata.maaleresultaterHoeyre.volum,
    data.inndata.maaleresultaterHoeyre.luftskifter,
  ]);

  const addNewRow = (hoeyreEllerVenstreSluse: "venstre" | "hoeyre") => {
    if (hoeyreEllerVenstreSluse === "venstre") {
      const maaleresultaterArray: number[] = data.inndata.maaleresultaterVenstre.maaleresultater;
      maaleresultaterArray.push(null);
      onUpdateEditData(
        `testResultater.maalingAvInnstroemningsHastighet.inndata.maaleresultaterVenstre.maaleresultater`,
        maaleresultaterArray,
      );
    }
    if (hoeyreEllerVenstreSluse === "hoeyre") {
      const maaleresultaterArray: number[] = data.inndata.maaleresultaterHoeyre.maaleresultater;
      maaleresultaterArray.push(null);
      onUpdateEditData(
        `testResultater.maalingAvInnstroemningsHastighet.inndata.maaleresultaterHoeyre.maaleresultater`,
        maaleresultaterArray,
      );
    }
  };

  const removeLastRow = (hoeyreEllerVenstreSluse: "venstre" | "hoeyre") => {
    if (hoeyreEllerVenstreSluse === "venstre") {
      const updatedMaaleresultater = data.inndata.maaleresultaterVenstre.maaleresultater;

      if (updatedMaaleresultater.length > 0) {
        updatedMaaleresultater.pop(); // Remove the last element
        onUpdateEditData(
          "testResultater.maalingAvInnstroemningsHastighet.inndata.maaleresultaterVenstre.maaleresultater",
          updatedMaaleresultater,
        );
      }
    }
    if (hoeyreEllerVenstreSluse === "hoeyre") {
      const updatedMaaleresultater = data.inndata.maaleresultaterHoeyre.maaleresultater;

      if (updatedMaaleresultater.length > 0) {
        updatedMaaleresultater.pop(); // Remove the last element
        onUpdateEditData(
          "testResultater.maalingAvInnstroemningsHastighet.inndata.maaleresultaterHoeyre.maaleresultater",
          updatedMaaleresultater,
        );
      }
    }
  };

  const updateMaaleresultater = (resultat: number, i: number, hoeyreEllerVenstreSluse: "venstre" | "hoeyre") => {
    if (hoeyreEllerVenstreSluse === "venstre") {
      const maaleresultater = [...data.inndata.maaleresultaterVenstre.maaleresultater];
      maaleresultater[i] = resultat;
      onUpdateEditData(
        "testResultater.maalingAvInnstroemningsHastighet.inndata.maaleresultaterVenstre.maaleresultater",
        maaleresultater,
      );
    }
    if (hoeyreEllerVenstreSluse === "hoeyre") {
      const maaleresultater = [...data.inndata.maaleresultaterHoeyre.maaleresultater];
      maaleresultater[i] = resultat;
      onUpdateEditData(
        "testResultater.maalingAvInnstroemningsHastighet.inndata.maaleresultaterHoeyre.maaleresultater",
        maaleresultater,
      );
    }
  };

  const utdataBeregninger = () => {
    const gjennomsnittligHastighetVenstre = beregnetGjennomsnittligHastighet(
      data.inndata.maaleresultaterVenstre.maaleresultater.map((resultat) => resultat),
      data.inndata.maaleresultaterVenstre.maaleresultater.length,
    );
    const luftmengdeGjennomFilterVenstre = beregnetLuftmengdeGjennomLukeaapning(
      gjennomsnittligHastighetVenstre,
      data.inndata.maaleresultaterVenstre.arealetTilFilterflaten,
    );
    const luftmengdeFraLuftskifterVenstre = beregnetProduktMedToVerdier(
      data.inndata.maaleresultaterVenstre.luftskifter,
      data.inndata.maaleresultaterVenstre.volum,
    );
    const gjennomsnittligHastighetHoeyre = beregnetGjennomsnittligHastighet(
      data.inndata.maaleresultaterHoeyre.maaleresultater.map((resultat) => resultat),
      data.inndata.maaleresultaterHoeyre.maaleresultater.length,
    );
    const luftmengdeGjennomFilterHoeyre = beregnetLuftmengdeGjennomLukeaapning(
      gjennomsnittligHastighetHoeyre,
      data.inndata.maaleresultaterHoeyre.arealetTilFilterflaten,
    );
    const luftmengdeFraLuftskifterHoeyre = beregnetProduktMedToVerdier(
      data.inndata.maaleresultaterHoeyre.luftskifter,
      data.inndata.maaleresultaterHoeyre.volum,
    );
    const utdata = {
      beregningerVenstre: {
        gjennomsnittligHastighet: gjennomsnittligHastighetVenstre,
        luftmengdeGjennomFilter: luftmengdeGjennomFilterVenstre,
        luftmengdeFraLuftskifter: luftmengdeFraLuftskifterVenstre,
      },
      beregningerHoeyre: {
        gjennomsnittligHastighet: gjennomsnittligHastighetHoeyre,
        luftmengdeGjennomFilter: luftmengdeGjennomFilterHoeyre,
        luftmengdeFraLuftskifter: luftmengdeFraLuftskifterHoeyre,
      },
    };
    onUpdateEditData("testResultater.maalingAvInnstroemningsHastighet.utdata", utdata);
  };

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Måling av hastighet over toppfilter isolatorsluse</CardTitle>
        <>
          <div className="d-flex flex-column mt-2">
            <b>Måletiden er 60 sekunder &#40;s&#41;:</b>
          </div>
          <div className="mt-2">
            <b>Resultater for venstre sluse:</b>
            <div className="d-flex flex-column mb-4">
              <b>
                Fyll inn arealet til filterflaten &#40;m<sup>2</sup>&#41;:
              </b>
              <McInput
                value={data.inndata.maaleresultaterVenstre.arealetTilFilterflaten}
                onBlur={(e) =>
                  onUpdateEditData(
                    "testResultater.maalingAvInnstroemningsHastighet.inndata.maaleresultaterVenstre.arealetTilFilterflaten",
                    Number(e.target.value),
                  )
                }
                normalizeNumbers={true}
                type="text"
                className="w-25"
              />
            </div>
            <div className="d-flex flex-column mt-4 mb-4">
              <b>
                Fyll inn volumet i slusen &#40;m<sup>3</sup>&#41;:
              </b>
              <McInput
                value={data.inndata.maaleresultaterVenstre.volum}
                onBlur={(e) =>
                  onUpdateEditData(
                    "testResultater.maalingAvInnstroemningsHastighet.inndata.maaleresultaterVenstre.volum",
                    Number(e.target.value),
                  )
                }
                normalizeNumbers={true}
                type="text"
                className="w-25"
              />
            </div>
            <div className="d-flex flex-column mt-4 mb-4">
              <b>Fyll inn antall luftskifter &#40;TAC/H&#41;:</b>
              <McInput
                value={data.inndata.maaleresultaterVenstre.luftskifter}
                onBlur={(e) =>
                  onUpdateEditData(
                    "testResultater.maalingAvInnstroemningsHastighet.inndata.maaleresultaterVenstre.luftskifter",
                    Number(e.target.value),
                  )
                }
                normalizeNumbers={true}
                type="text"
                className="w-25"
              />
            </div>
            <div className="d-flex flex-column mt-4 mb-4">
              <b>Fyll inn innstilt interlock-tid &#40;s&#41;:</b>
              <McInput
                value={data.inndata.maaleresultaterVenstre.innstiltInterlockTid}
                onBlur={(e) =>
                  onUpdateEditData(
                    "testResultater.maalingAvInnstroemningsHastighet.inndata.maaleresultaterVenstre.innstiltInterlockTid",
                    Number(e.target.value),
                  )
                }
                normalizeNumbers={true}
                type="text"
                className="w-25"
              />
            </div>
            <div className="mt-4">
              <b>Legg til flere punkter ved behov:</b>
              <div className="d-flex">
                <Button color="success" onClick={() => addNewRow("venstre")}>
                  Legg til rad
                </Button>
                <HSpace />
                <Button
                  color="danger"
                  onClick={() => removeLastRow("venstre")}
                  disabled={data.inndata.maaleresultaterVenstre.maaleresultater.length < 6}
                >
                  Fjern siste rad
                </Button>
              </div>
              <div className="mt-4 d-flex flex-column">
                <b>Fyll inn måleresultater &#40;m/s&#41;:</b>
                {data.inndata.maaleresultaterVenstre.maaleresultater.map((a, i) => (
                  <McInput
                    className="mb-1 w-25"
                    key={i}
                    value={a}
                    normalizeNumbers={true}
                    type="text"
                    onBlur={(e) => updateMaaleresultater(Number(e.target.value), i, "venstre")}
                  />
                ))}
              </div>
              <div className="d-flex justify-content-between">
                <p className="mb-0">
                  Gjennomsnittlig hastighet &#40;m/s&#41; =
                  <b>
                    {" "}
                    {beregnetGjennomsnittligHastighet(
                      data.inndata.maaleresultaterVenstre.maaleresultater.map((resultat) => resultat),
                      data.inndata.maaleresultaterVenstre.maaleresultater.length,
                    )?.toFixed(2) ?? ""}
                  </b>
                </p>
                <p className="mb-0">Formel: &#40;Va = &#40;SUM Vn&#41; / N&#41;</p>
              </div>
              <hr className="mt-0" />
              <div className="d-flex justify-content-between">
                <p className="mb-0">
                  Luftmengde gjennom filterflate&#40;m<sup>3</sup>/s&#41; ={" "}
                  <b>
                    {beregnetLuftmengdeGjennomLukeaapning(
                      data.utdata.beregningerVenstre.gjennomsnittligHastighet,
                      data.inndata.maaleresultaterVenstre.arealetTilFilterflaten,
                    )?.toFixed(3) ?? ""}
                  </b>
                </p>
                <p className="mb-0">Formel: &#40;Q = Va * Af&#41;</p>
              </div>
              <hr className="mt-0" />
            </div>
          </div>
          {/** HØYRE SLUSE STARTER HER*/}
          <div className="mt-4">
            <b>Resultater for høyre sluse:</b>
            <div className="d-flex flex-column mb-4">
              <b>
                Fyll inn arealet til filterflaten &#40;m<sup>2</sup>&#41;:
              </b>
              <McInput
                value={data.inndata.maaleresultaterHoeyre.arealetTilFilterflaten}
                onBlur={(e) =>
                  onUpdateEditData(
                    "testResultater.maalingAvInnstroemningsHastighet.inndata.maaleresultaterHoeyre.arealetTilFilterflaten",
                    Number(e.target.value),
                  )
                }
                normalizeNumbers={true}
                type="text"
                className="w-25"
              />
            </div>
            <div className="d-flex flex-column mt-4 mb-4">
              <b>
                Fyll inn volumet i slusen &#40;m<sup>3</sup>&#41;:
              </b>
              <McInput
                value={data.inndata.maaleresultaterHoeyre.volum}
                onBlur={(e) =>
                  onUpdateEditData(
                    "testResultater.maalingAvInnstroemningsHastighet.inndata.maaleresultaterHoeyre.volum",
                    Number(e.target.value),
                  )
                }
                normalizeNumbers={true}
                type="text"
                className="w-25"
              />
            </div>
            <div className="d-flex flex-column mt-4 mb-4">
              <b>Fyll inn antall luftskifter &#40;TAC/H&#41;:</b>
              <McInput
                value={data.inndata.maaleresultaterHoeyre.luftskifter}
                onBlur={(e) =>
                  onUpdateEditData(
                    "testResultater.maalingAvInnstroemningsHastighet.inndata.maaleresultaterHoeyre.luftskifter",
                    Number(e.target.value),
                  )
                }
                normalizeNumbers={true}
                type="text"
                className="w-25"
              />
            </div>
            <div className="d-flex flex-column mt-4 mb-4">
              <b>Fyll inn innstilt interlock-tid &#40;s&#41;:</b>
              <McInput
                value={data.inndata.maaleresultaterHoeyre.innstiltInterlockTid}
                onBlur={(e) =>
                  onUpdateEditData(
                    "testResultater.maalingAvInnstroemningsHastighet.inndata.maaleresultaterHoeyre.innstiltInterlockTid",
                    Number(e.target.value),
                  )
                }
                normalizeNumbers={true}
                type="text"
                className="w-25"
              />
            </div>
            <div className="mt-4">
              <b>Legg til flere punkter ved behov:</b>
              <div className="d-flex">
                <Button color="success" onClick={() => addNewRow("hoeyre")}>
                  Legg til rad
                </Button>
                <HSpace />
                <Button
                  color="danger"
                  onClick={() => removeLastRow("hoeyre")}
                  disabled={data.inndata.maaleresultaterHoeyre.maaleresultater.length < 6}
                >
                  Fjern siste rad
                </Button>
              </div>
              <div className="mt-4 d-flex flex-column">
                <b>Fyll inn måleresultater &#40;m/s&#41;:</b>
                {data.inndata.maaleresultaterHoeyre.maaleresultater.map((a, i) => (
                  <McInput
                    className="mb-1 w-25"
                    key={i}
                    value={a}
                    normalizeNumbers={true}
                    type="text"
                    onBlur={(e) => updateMaaleresultater(Number(e.target.value), i, "hoeyre")}
                  />
                ))}
              </div>
              <div className="d-flex justify-content-between">
                <p className="mb-0">
                  Gjennomsnittlig hastighet &#40;m/s&#41; =
                  <b>
                    {" "}
                    {beregnetGjennomsnittligHastighet(
                      data.inndata.maaleresultaterHoeyre.maaleresultater.map((resultat) => resultat),
                      data.inndata.maaleresultaterHoeyre.maaleresultater.length,
                    )?.toFixed(2) ?? ""}
                  </b>
                </p>
                <p className="mb-0">Formel: &#40;Va = &#40;SUM Vn&#41; / N&#41;</p>
              </div>
              <hr className="mt-0" />
              <div className="d-flex justify-content-between">
                <p className="mb-0">
                  Luftmengde gjennom filterflate&#40;m<sup>3</sup>/s&#41; ={" "}
                  <b>
                    {beregnetLuftmengdeGjennomLukeaapning(
                      data.utdata.beregningerHoeyre.gjennomsnittligHastighet,
                      data.inndata.maaleresultaterHoeyre.arealetTilFilterflaten,
                    )?.toFixed(3) ?? ""}
                  </b>
                </p>
                <p className="mb-0">Formel: &#40;Q = Va * Af&#41;</p>
              </div>
              <hr className="mt-0" />
            </div>
          </div>
          <div className="mt-4">
            <ReportDocxImageContainer
              docxImageTag="maalingAvInnstroemningsHastighet"
              title="Bilde av markerte målepunkter"
            />
            <div>
              <b>Kommentar:</b>
              <McInput
                value={data.inndata.kommentar}
                onChange={(e) =>
                  onUpdateEditData("testResultater.maalingAvInnstroemningsHastighet.inndata.kommentar", e.target.value)
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
          </div>
        </>
      </CardBody>
    </Card>
  );
};

export default MaalingAvInnstroemningsHastighet;
