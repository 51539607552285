import React from "react";
import { Button } from "reactstrap";

const CloseButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <Button onClick={() => onClick()}>
      <i className="fa fa-close fa-fw" />
    </Button>
  );
};
export default CloseButton;
