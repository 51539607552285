import React from "react";
import { Alert, Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import { getProjectIdFromUrl } from "../../utils";

const PnsApp: React.FC = () => {
  const history = useHistory();

  const projectId = getProjectIdFromUrl(history.location.pathname);

  const goToSettings = () => {
    history.replace(`/project/${projectId}/settings/pns`);
  };

  return (
    <div className="p-3 h-100 d-flex w-100 flex-column">
      <Alert color="info">
        <h3>PNS er flyttet til innstillinger.</h3>
        <Button color="link" onClick={() => goToSettings()}>
          Gå til innstillinger
        </Button>
      </Alert>
    </div>
  );
};

export default PnsApp;
