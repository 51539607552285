import React, { createContext, useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { MCApplicationsUrlEnum } from "../frontendConstants";
import { useAppSelector } from "../hooks";
import AppIcon from "./AppIcon";
import { getEnabledApps } from "../global/globalSlice";

export const SidebarContext = createContext(null);

const SidebarLink = ({ linkString, app }) => {
  const [isHovered, setIsHovered] = useState(false);
  const { activeApp, setActiveApp } = useContext(SidebarContext);

  return (
    <Link
      to={linkString}
      className="d-flex"
      style={{ padding: "5px 19px", textDecoration: "none" }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => setActiveApp(app.name)}
    >
      <div title={app.label}>
        <AppIcon app={app} hover={isHovered} active={activeApp === app.name} variation="sidebar" />
      </div>
      <div
        className="d-flex truncateHeaderText ml-2 align-items-center"
        style={{ color: `${isHovered ? "#858585" : "#3e4245"}` }}
      >
        {app.label}
      </div>
    </Link>
  );
};

const Sidebar: React.FC<{ isCollapsed: boolean }> = ({ isCollapsed }) => {
  const [activeApp, setActiveApp] = useState<string>(null);
  const history = useHistory();
  const enabledApps = useAppSelector(getEnabledApps);
  const project = useAppSelector((state) => state.adminReducer.project);
  const metaApps = {
    apps: {
      name: "apps",
      label: "Appoversikt",
      iconColor: "#f5a346",
      icon: "appIcons/apps.svg",
    },
    members: {
      name: "members",
      label: "Prosjektmedlemmer",
      iconColor: "#f5a346",
      icon: "appIcons/members.svg",
    },
    settings: {
      name: "settings",
      label: "Innstillinger",
      iconColor: "#f5a346",
      icon: "appIcons/settings.svg",
    },
  };
  const getLink = (appName: string) => {
    return `/project/${project._id}/${appName}`;
  };

  useEffect(() => {
    const urlPartsArr = history.location.pathname.split("/");
    const appInUrl = urlPartsArr[3] as MCApplicationsUrlEnum;
    const array = [
      { name: MCApplicationsUrlEnum.MEMBERS },
      { name: MCApplicationsUrlEnum.SETTINGS },
      { name: MCApplicationsUrlEnum.APPS },
    ];
    const apps = enabledApps.map((app) => {
      return {
        name: app.name as MCApplicationsUrlEnum,
      };
    });
    const combinedAppsArray = apps.concat(array);
    const _activeApp = combinedAppsArray.find((app) => app.name === appInUrl);
    if (_activeApp?.name !== activeApp) {
      if (_activeApp?.name != null) {
        setActiveApp(_activeApp.name);
      } else {
        console.error("No active app. Using default");
        setActiveApp("apps");
      }
    }
  }, [history.location.pathname]);

  useEffect(() => {
    if (activeApp) {
      sessionStorage.setItem("activeApp", activeApp);
    }
  }, [activeApp]);

  return (
    <SidebarContext.Provider value={{ isCollapsed, activeApp, setActiveApp }}>
      <div className="sidebarContainer">
        <div className={`d-flex flex-column overflow-auto sidebar${isCollapsed === true ? " sidebar-collapsed" : ""}`}>
          <SidebarLink app={metaApps.apps} linkString={getLink("apps")} />
          {enabledApps &&
            enabledApps.map((app, index) => {
              return <SidebarLink key={index} app={app} linkString={getLink(app.name)} />;
            })}
          <SidebarLink app={metaApps.members} linkString={getLink("members")} />
          <SidebarLink app={metaApps.settings} linkString={getLink("settings/apps")} />
        </div>
      </div>
    </SidebarContext.Provider>
  );
};

export default Sidebar;
