import * as R from "ramda";
import React from "react";
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";

interface IProps {
  getFilter: any;
  defaultValue: any;
  filterState: any;
  options: object;
  placeholder?: any;
  column: any;
  withoutEmptyOption: any;
  onFilter: any;
  id?: any;
  style?: any;
  className?: any;
  comparator?: any;
  caseSensitive?: any;
  disabled?: boolean;
  getSelectedOptions?: any;
  isNewFilter?: boolean;
  color?: string;
}

interface IState {
  isSelected: any;
  selectedOptions: any;
  nrOfOptions: number;
  searchTerm: string;
}

class DropdownMultiSelectFilter extends React.Component<IProps, IState> {
  private selectInput: any;
  constructor(props) {
    super(props);
    this.applyFilter = this.applyFilter.bind(this);
    this.state = {
      searchTerm: "",
      selectedOptions: [],
      isSelected: null,
      nrOfOptions:
        this.props.options != null
          ? Object.keys(this.props.options).reduce((acc) => {
              acc = acc + 1;
              return acc;
            }, 0)
          : 0,
    };
  }

  componentDidMount() {
    const { getFilter } = this.props;
    if (this.props.defaultValue.length > 0) {
      this.applyFilter(this.props.defaultValue);
      this.setState({ selectedOptions: this.props.defaultValue });
    }

    // export onFilter function to allow users to access
    if (getFilter) {
      getFilter((filterVal) => {
        this.selectInput.value = filterVal;
        this.applyFilter(filterVal);
      });
    }
  }

  componentDidUpdate(prevProps, newProps) {
    if (this.state.selectedOptions !== newProps.selectedOptions && this.props.getSelectedOptions != null) {
      this.props.getSelectedOptions(this.state.selectedOptions);
    }
  }

  getDefaultValue() {
    const { filterState, defaultValue } = this.props;
    if (filterState && typeof filterState.filterVal !== "undefined") {
      return filterState.filterVal;
    }
    return defaultValue;
  }

  getOptions() {
    const optionTags = [];
    const { options, placeholder, column, withoutEmptyOption } = this.props;
    if (!withoutEmptyOption) {
      optionTags.push(
        <option key="-1" value="">
          {placeholder || `Velg ${column.text}...`}
        </option>,
      );
    }
    Object.keys(options).forEach((key) =>
      optionTags.push(
        <option key={key} value={key}>
          {options[key]}
        </option>,
      ),
    );
    return optionTags;
  }

  applyFilter(value) {
    this.setState(() => ({ isSelected: value.length > 0, searchTerm: "" }));
    this.props.onFilter(this.props.column, "MULTISELECT")(value);
  }

  onDropdownClick = (option: string) => {
    let options = R.clone(this.state.selectedOptions);
    const index = options.findIndex((_option) => _option === option);

    if (index === -1) {
      let newOptions = [...this.state.selectedOptions, option];
      this.setState({ selectedOptions: newOptions }, () => {
        this.applyFilter(this.state.selectedOptions);
      });
    } else {
      this.setState(
        {
          selectedOptions: this.state.selectedOptions.filter((_, i) => i !== index),
        },
        () => {
          this.applyFilter(this.state.selectedOptions);
        },
      );
    }
  };

  isSelected = (option) => {
    return this.state.selectedOptions.findIndex((_option) => _option === option) !== -1;
  };

  resetFilter = () => {
    this.setState({ selectedOptions: [] }, () => {
      this.applyFilter([]);
    });
  };

  setSearchTerm = (value) => {
    this.setState({
      searchTerm: value,
    });
  };

  render() {
    const { options, isNewFilter } = this.props;

    return (
      <UncontrolledDropdown
        onClick={(e) => e.stopPropagation()}
        ref={(n) => (this.selectInput = n)}
        style={{ height: "100%" }}
      >
        {!isNewFilter ? (
          <DropdownToggle
            disabled={this.props.disabled}
            color={this.props.color || "secondary"}
            className="w-100 mb-1"
            caret
            onClick={(e) => e.stopPropagation()}
          >
            {this.state.selectedOptions.length === 0 && (
              <span>
                <i className="fa fa-filter fa-fw" />
                Filter
              </span>
            )}
            {this.state.selectedOptions.length > 0 && (
              <span>
                <i className="fa fa-filter fa-fw" />
                Filter
                <Badge color="primary" pill>
                  ({this.state.selectedOptions.length} / {this.state.nrOfOptions})
                </Badge>
              </span>
            )}
          </DropdownToggle>
        ) : (
          <DropdownToggle
            onClick={(e) => e.stopPropagation()}
            tag="div"
            className="d-flex align-items-center colheaderfilter"
          >
            <i
              className={`fa fa-filter fa-xs ${this.state.selectedOptions.length && "primary-text"}`}
              aria-hidden="true"
            ></i>
          </DropdownToggle>
        )}
        <DropdownMenu
          style={{ minWidth: "200px", width: "max-content", maxHeight: "300px" }}
          className="btn-block mt-2"
          right
        >
          <DropdownItem toggle={false} disabled={false} className={"nocolor"} tag="div">
            <input
              className="filter text-filter form-control"
              placeholder="Søk"
              value={this.state.searchTerm}
              ref={(element) =>
                element?.focus?.({
                  preventScroll: true,
                })
              }
              onChange={(e) => this.setSearchTerm(e.target.value)}
              style={{ width: "100%", margin: "0px" }}
            />
          </DropdownItem>
          <DropdownItem onClick={() => this.resetFilter()}>Vis alt</DropdownItem>
          <DropdownItem divider />
          {options != null &&
            Object.keys(options)
              .filter((key) => {
                try {
                  if (options[key] !== "") {
                    return options[key].toLowerCase().search(this.state.searchTerm.toLowerCase()) !== -1;
                  } else {
                    return false;
                  }
                } catch (error) {
                  console.error(error);
                  return false;
                }
              })
              .map((key) => (
                <DropdownItem
                  active={this.isSelected(key)}
                  onClick={() => this.onDropdownClick(key)}
                  toggle={false}
                  key={key}
                >
                  {options[key]}
                </DropdownItem>
              ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }
}

export default DropdownMultiSelectFilter;
