import { ColDef } from "ag-grid-community";
import { IGridProbabilityWithId, IRisikoProbability } from "mc-shared/zod/issueBoardSchema";
import React from "react";
import Switch from "react-switch";
import { Card, CardBody, CardTitle } from "reactstrap";
import { IBoard } from "../../../../../../../types/global.types";
import { FailedAlert } from "../../../../../components";
import ViewEditTable from "../../../../../components/ViewEditTable/ViewEditTable";
import {
  useUpdateRisikoProbabilitiesIsActiveAfterTiltakMutation,
  useUpdateRisikoProbabilitiesMutation,
} from "../../IssueBoardRTK";

const RisikoProbabilitySettings: React.FC<{ issueBoard: IBoard }> = ({ issueBoard }) => {
  const [setProbabilityActive, { error: activateProbabilityError }] =
    useUpdateRisikoProbabilitiesIsActiveAfterTiltakMutation();
  const [updateProbabilities, { error: updateProbabilitiesError }] = useUpdateRisikoProbabilitiesMutation();

  const colSettings: ColDef<IGridProbabilityWithId>[] = [
    {
      field: "id",
      headerName: "ID",
      width: 70,
    },
    {
      field: "name",
      headerName: "Navn",
      editable: true,
    },
    {
      field: "description",
      headerName: "Beskrivelse",
      editable: true,
    },
    {
      field: "matrixValue",
      headerName: "Matriseverdi",
      editable: true,
    },
    /*{
      field: "order",
      headerName: "Order",
      editable: false,
    },*/
    {
      field: "isActive",
      headerName: "Aktiv",
      editable: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: ["Ja", "Nei"],
      },
    },
  ];

  const template: IGridProbabilityWithId = {
    id: "",
    name: "",
    description: "",
    matrixValue: 1,
    isActive: "Ja",
    order: 0,
  };

  const data: IGridProbabilityWithId[] = Object.entries(issueBoard.risikoSection?.probabilites ?? {}).map(
    ([key, value]) => ({
      ...value,
      id: key,
      // Need to convert boolean to string for ag-grid to not use checkboxes which cannot be disabled when editMode is false
      // Also, since ViewEditTable sets values internally we cant use a custom valueFormatter
      isActive: value.isActive === true ? "Ja" : "Nei",
    }),
  );

  const formatProbabilites = (probabilities: IRisikoProbability[]) => {
    return probabilities.map((p, index) => ({
      ...p,
      order: index,
    }));
  };
  return (
    <Card>
      <CardBody>
        <CardTitle tag="h3">Sannsynlighet</CardTitle>
        {activateProbabilityError != null || (updateProbabilitiesError != null && <FailedAlert />)}
        <div className="d-flex flex-column">
          <div className="d-flex">
            <p className="mr-2">
              <i className="fa fa-info-circle fa-fw text-info" />
              Angi sannsynlighet før <b>og</b> etter tiltak
            </p>
            <Switch
              onColor="#00a76d"
              onChange={(risikoActive) =>
                setProbabilityActive({
                  projectId: issueBoard.project,
                  issueBoardId: issueBoard._id,
                  isActive: risikoActive,
                })
              }
              checked={issueBoard.risikoSection?.enableProbabilityAfterTiltak === true}
            />
          </div>
          <ViewEditTable
            columns={colSettings}
            data={data}
            newItemTemplateObject={template}
            onSave={(data) => {
              //convert isActive to boolean
              const newData = data.map((item) => ({
                ...item,
                isActive: item.isActive === "Ja",
              }));
              updateProbabilities({
                projectId: issueBoard.project,
                issueBoardId: issueBoard._id,
                probabilities: formatProbabilites(newData),
              });
            }}
            canEdit={issueBoard.risikoSection?.isActive === true}
            canRemovePredicate={(item) => item.id === ""}
            orderProp="order"
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default RisikoProbabilitySettings;
