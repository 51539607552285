import React, { useState } from "react";
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { IGisPointAndCamera, IRisiko } from "../../../../../../types/global.types";
import { ToastMessagesEnums } from "../../../../frontendConstants";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { doCreateRisiko } from "../risikoAPI";
import { setShowSuccessMessageThunk } from "../../../../global/globalSlice";

const AddRisikoModal: React.FC<{
  closeAddRisikoModal: () => void;
  risikoBoardId: string;
  onCreateRisiko: (risiko: IRisiko) => void;
  gis?: IGisPointAndCamera;
  gisImage?: string;
}> = ({ closeAddRisikoModal, risikoBoardId, onCreateRisiko, gis, gisImage }) => {
  const [title, setTitle] = useState<string>("");
  const project = useAppSelector((state) => state.adminReducer.project);

  const dispatch = useAppDispatch();
  const add = async () => {
    const createdRisiko = await doCreateRisiko(project._id, risikoBoardId, title, gis, gisImage);
    onCreateRisiko(createdRisiko);
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.RISIKO_ADDED));
  };

  return (
    <Modal size="sm" isOpen toggle={closeAddRisikoModal} className="height-auto">
      <ModalHeader toggle={closeAddRisikoModal}>Opprett risiko</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="id">Ny risiko</Label>
            <Input
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              name="title"
              id="title"
              placeholder="Skriv inn tittel på ny risiko"
              type="text"
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button disabled={title === ""} color="success" onClick={() => add()}>
          OPPRETT
        </Button>
        <Button color="secondary" onClick={closeAddRisikoModal}>
          Avbryt
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddRisikoModal;
