import jsyaml from "js-yaml";
import React, { useState } from "react";
import ReactDiffViewer from "react-diff-viewer";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { IReport } from "../../../../../../types/report.types";
import { HSpace, McDropdown } from "../../../../components";
import { sortArrayByDate } from "../../../utils";

interface DiffViewerProps {
  activeReport: IReport.IReportBoard;
  activeVersion: IReport.IReportVersion;
  toggle: () => void;
  showDiffModal: boolean;
}

const DiffViewer: React.FC<DiffViewerProps> = ({ activeReport, activeVersion, toggle, showDiffModal }) => {
  const prevVersion = activeVersion?.versionNumber - 2 < 0 ? 0 : activeVersion?.versionNumber - 2;
  const [oldObj, setOldObj] = useState(activeReport?.versions[prevVersion]);
  const [newObj, setNewObj] = useState(activeReport?.versions[activeVersion?.versionNumber - 1]);

  const oldYaml = jsyaml.dump(oldObj.data);
  const newYaml = jsyaml.dump(newObj.data);
  const versionsSorted = sortArrayByDate(activeReport?.versions, "createdAt", true);

  return (
    <Modal toggle={toggle} isOpen={showDiffModal} style={{ maxWidth: "70%" }} fade={false}>
      <ModalHeader toggle={toggle}>Endringer</ModalHeader>
      <ModalBody className="d-flex flex-column p-2" style={{ height: "80vh" }}>
        <div className="d-flex w-100 mb-2">
          <div className="w-50 d-flex">
            Versjon:
            <HSpace />
            <McDropdown
              type="text"
              displayKey="versionNumber"
              currentValue={oldObj}
              onChange={(oldVal, newVal) => setOldObj(newVal)}
              options={versionsSorted}
            />
          </div>

          <div className="w-50 d-flex">
            Versjon:
            <HSpace />
            <McDropdown
              type="text"
              displayKey="versionNumber"
              currentValue={newObj}
              onChange={(oldVal, newVal) => setNewObj(newVal)}
              options={versionsSorted}
            />
          </div>
        </div>
        <div className="flex-fill overflow-auto">
          <ReactDiffViewer oldValue={oldYaml} newValue={newYaml} splitView={true} />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DiffViewer;
