import React, { useEffect, useState } from "react";
import { UncontrolledTooltip } from "reactstrap";

const makeid = (length) => {
  var result = "";
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const InfoCircle: React.FC<{ text: string }> = ({ text }) => {
  const [id, setId] = useState<string>(null);
  useEffect(() => {
    const _id = makeid(5);
    setId(_id);
  }, []);
  //
  return (
    <>
      {id != null && (
        <>
          <i id={id} className="fa fa-info-circle fa-fw text-info fa-1x mt-1" />
          <UncontrolledTooltip placement="top" target={id}>
            {text}
          </UncontrolledTooltip>
        </>
      )}
    </>
  );
};
export default InfoCircle;
