import React from "react";
import { Badge } from "reactstrap";

const SimpleTag: React.FC<{
  onDelete?: (value: string) => void;
  onClick?: () => void;
  displayValue: string;
  editable?: boolean;
  tagSize?: "sm" | "lg";
  disabled?: boolean;
}> = ({ displayValue, onDelete, editable = true, tagSize, disabled, onClick, ...props }) => {
  return (
    <>
      <Badge
        onClick={onClick}
        {...props}
        style={{
          padding: tagSize === "sm" ? "5px 6px" : "5px .8em",
          fontSize: tagSize === "sm" ? "10px" : "14px",
          cursor: editable === true || onClick != null ? "pointer" : "default",
          height: tagSize === "sm" ? "1.2rem" : "2.2rem",
        }}
        className={"mc-tag d-flex align-items-center mr-2" + (disabled === true ? " disabled-greybadge" : " greybadge")}
      >
        {onDelete != null && editable === true && (
          <>
            <i className="pr-3" onClick={() => onDelete(displayValue)} />
          </>
        )}
        {displayValue}
      </Badge>
    </>
  );
};

export default SimpleTag;
