import React, { useEffect, useState } from "react";
import noname from "./noname.png";

const Avatar: React.FC<{ id: string; height?: string }> = ({ id, height = "30px" }) => {
  const [image, setImage] = useState(noname);

  useEffect(() => {
    setImage(id === null || id === "" ? noname : `/api/image/${id}`);
  }, [id]);

  return (
    <div className="mr-2" style={{ width: height }}>
      <img onError={() => setImage(noname)} alt={""} src={image} style={{ height }} className="rounded-circle" />
    </div>
  );
};

export default Avatar;
