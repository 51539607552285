import React from "react";
import * as R from "ramda";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { doGetBim360ItemInfo } from "../../../../forge/forgeAPI";
import { IMCBIMAPI } from "../../../../../../../types/mcbim.types";
import { MCLoader, VSpace } from "../../../../../components";
import McBimAgFileTable from "./McBimAgFileTable";
import { IBim360FolderContent } from "../../../../../../../types/global.types";
import BIM360AgFolderAndFileExplorer from "../../../../../components/Bim360AgFolderAndFileExplorer/BIM360AgFolderAndFileExplorer";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { setSelectIfcFilesInRedux } from "../mcBimSlice";
import { useLocation } from "react-router-dom";
import { getProjectIdFromUrl } from "../../../../utils";
import { useGetProjectQuery } from "../../../../projectRTK";

const McBimBim360FileHandler: React.FC<{ mcBimProject: IMCBIMAPI.Project }> = ({ mcBimProject }) => {
  const selectedIfcFiles = useAppSelector((state) => state.mcBimReducer.selectedIfcFiles);
  const [filesModalOpen, setFilesModalOpen] = React.useState<boolean>(false);
  const [loadingExtraFileInfoFromBim360, setLoadingExtraFileInfoFromBim360] = React.useState<boolean>(false);
  const [failedToLoadExtraFileInfoFromBim360, setFailedToLoadExtraFileInfoFromBim360] = React.useState<boolean>(false);

  React.useEffect(() => {
    init();
  }, []);

  const dispatch = useAppDispatch();

  const init = () => {
    dispatch(setSelectIfcFilesInRedux(R.clone(mcBimProject.ifcFiles)));
  };

  const location = useLocation();
  const projectId = getProjectIdFromUrl(location.pathname);

  const { data: project } = useGetProjectQuery(projectId);

  const addFilesFromFileSelector = async (filesToAdd: IMCBIMAPI.IfcFile[]) => {
    try {
      setFilesModalOpen(false);
      setLoadingExtraFileInfoFromBim360(true);
      for (const ifcFile of filesToAdd) {
        const fileInfo = await doGetBim360ItemInfo(project._id, project.bim360?.projectId, ifcFile.fileStorageId);
        ifcFile.filePath = fileInfo?.attributes?.pathInProject;
      }
      dispatch(setSelectIfcFilesInRedux(filesToAdd));
      setLoadingExtraFileInfoFromBim360(false);
    } catch (error) {
      setLoadingExtraFileInfoFromBim360(false);
      setFailedToLoadExtraFileInfoFromBim360(true);
    }
  };

  return (
    <div style={{ height: "calc(100% - 70px)" }}>
      {loadingExtraFileInfoFromBim360 === false && (
        <Button color="mc-blue" onClick={() => setFilesModalOpen(true)}>
          <i className="fa fa-plus fa-fw" />
          Legg til filer fra BIM 360
        </Button>
      )}
      <VSpace />
      {selectedIfcFiles?.length > 0 &&
        loadingExtraFileInfoFromBim360 !== true &&
        failedToLoadExtraFileInfoFromBim360 !== true && (
          <McBimAgFileTable
            ifcFiles={selectedIfcFiles}
            fileAttributeExtractSettings={mcBimProject.fileAttributeExtractSettings}
            onChangeIfcFiles={(updatedIfcFiles) => dispatch(setSelectIfcFilesInRedux(updatedIfcFiles))}
          />
        )}
      {loadingExtraFileInfoFromBim360 === true && <MCLoader loading text="Henter ytterligere filinformasjon ..." />}
      {failedToLoadExtraFileInfoFromBim360 === true && (
        <div>
          Kunne ikke laste inn filinformasjon fra BIM 360. Prøv igjen senere. Hvis problemet vedvarer. Kontakt support
        </div>
      )}
      {filesModalOpen === true && (
        <FileSelectorModal
          onSelectIfcFiles={addFilesFromFileSelector}
          ifcFiles={selectedIfcFiles}
          toggle={() => setFilesModalOpen(false)}
        />
      )}
    </div>
  );
};

export default McBimBim360FileHandler;

const FileSelectorModal: React.FC<{
  toggle: () => void;
  ifcFiles: IMCBIMAPI.IfcFile[];
  onSelectIfcFiles: (ifcFiles: IMCBIMAPI.IfcFile[]) => void;
}> = ({ toggle, ifcFiles, onSelectIfcFiles }) => {
  const location = useLocation();
  const projectId = getProjectIdFromUrl(location.pathname);

  const { data: project } = useGetProjectQuery(projectId);

  const convertBim360FileContentToIfcFileAndSetIt = (files: IBim360FolderContent[]) => {
    const ifcFiles: IMCBIMAPI.IfcFile[] = files.map((file) => {
      return {
        fileName: file.name,
        fileStorageId: file.id,
        fileDescription: "",
        fileDiscipline: "",
        filePath: "",
      };
    });
    onSelectIfcFiles(ifcFiles);
  };

  return (
    <>
      <Modal isOpen size="lg" style={{ width: "1550px" }} fade={false}>
        <ModalHeader toggle={toggle}>Velg filer</ModalHeader>
        <ModalBody className="h-100 w-100">
          <div style={{ height: "500px", width: "1500px" }} className="d-flex overflow-auto">
            <BIM360AgFolderAndFileExplorer
              disableAbort={true}
              filterExtension={{
                label: "IFC",
                value: ".ifc",
              }}
              projectId={project._id}
              bim360ProjectId={project.bim360?.projectId}
              includeSelectedFilesHandler
              onSave={convertBim360FileContentToIfcFileAndSetIt}
              selectedAgFiles={ifcFiles?.map((file) => {
                return {
                  id: file.fileStorageId,
                  name: file.fileName,
                };
              })}
            />
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
