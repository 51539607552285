import React from "react";
import { Card, CardBody, CardTitle, ListGroup, ListGroupItem } from "reactstrap";
import { usePingMCappsAzureFunctionsQuery } from "../../projectRTK";

export const IntegrationsHealthChecker: React.FC = () => {
  const {
    data: azureFunctionsAPIPing,
    error: azureFunctionsAPIPingError,
    isLoading: azureFunctionsAPIPingLoading,
    isSuccess: azureFunctionsAPIPingSuccess,
  } = usePingMCappsAzureFunctionsQuery();

  const listItems = [
    {
      name: "Azure Functions API",
      isSuccess: azureFunctionsAPIPingError == null && azureFunctionsAPIPingSuccess === true,
    },
  ];

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h3">Integrationer</CardTitle>
        <ListGroup>
          {listItems.map((item) => (
            <ListGroupItem color={item.isSuccess ? "success" : "danger"}>{item.name}</ListGroupItem>
          ))}
        </ListGroup>
      </CardBody>
    </Card>
  );
};
