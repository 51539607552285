import React, { useEffect } from "react";
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { IChecklist, IChecklistCategory, IChecklistItem, IChecklistPopulated } from "../../../../../../types/checklist.types";
import { usePublishDoksamChecklistMutation } from "../checklistRTK";
import { FailedAlert, MCLoader } from "../../../../components";
import { formatChecklistForDocxGeneration } from "../checklistUtils";
import { useHistory } from "react-router";
import { getProjectIdFromUrl } from "../../../utils";
import { useGetProjectQuery } from "../../../projectRTK";
import { useAppSelector } from "../../../../hooks";

const PublishDoskamChecklistModal: React.FC<{ close: () => void}> = ({ close }) => {

  const [publishChecklistToDoksam, { isError, error, isLoading, isUninitialized, isSuccess }] =
    usePublishDoksamChecklistMutation();

    const history = useHistory();
    const projectId = getProjectIdFromUrl(history.location.pathname);

    const {data: project, isSuccess: projectFetched} = useGetProjectQuery(projectId);

    const { checklist, checklistCategories, checklistItems, activeTab, hiddenColumns } = useAppSelector(
      (state) => state.checklistReducer,
    );

    const [formatedData, setFormatedData] = React.useState<any>(null);
  return (
    <Modal isOpen toggle={() => close()}>
      <ModalHeader toggle={() => close()}>Publiser til Doksam</ModalHeader>
      <ModalBody>
        {isUninitialized === true && <span>Ønsker du å publisere en ny revisjon av dokumenter til Doksam?</span>}
        {isError === true && <FailedAlert errorMessage={error} />}
        {isLoading && <MCLoader />}
        {isSuccess && (
          <Alert color="success">
            <i className="fa fa-check fa-fw" />
            Sjekkliste er sendt til publisering på Doksam!
          </Alert>
        )}
      </ModalBody>
      {isUninitialized === true && (
        <ModalFooter>
          <Button onClick={close}>Avbryt</Button>
          <Button
            onClick={() =>
              publishChecklistToDoksam({
                data: formatChecklistForDocxGeneration({
                  checklist,
                  project: project,
                  checklistCategories: checklistCategories,
                  checklistItems: checklistItems
                }),
                checklistId: checklist._id,
                projectId: checklist.project,
              })
            }
            color="success"
          >
            Publiser
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default PublishDoskamChecklistModal;
