import React, { Children, ReactElement, ReactNode, useEffect, useState } from "react";

type InlineDataPosition = "before" | "after" | "flex-end";

const McTitleInlineData: React.FC<{ positionInlineData?: InlineDataPosition }> = ({ children }) => {
  return <>{children}</>;
};

function McTitle({
  children,
  title,
  className = "",
  size = "m",
  ...props
}: React.PropsWithChildren<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    children: ReactNode;
    title: string;
    className?: string;
    size?: "s" | "m" | "l";
    inlineData?: ReactNode;
    positionInlineData?: InlineDataPosition;
  }
>) {
  const [bodyElements, setBodyElements] = useState<ReactNode>([]);
  const [inlineElementBefore, setInlineElementBefore] = useState<ReactNode[]>([]);
  const [inlineElementAfter, setInlineElementAfter] = useState<ReactNode[]>([]);
  const [inlineElementFlexEnd, setInlineElementFlexEnd] = useState<ReactNode[]>([]);

  useEffect(() => {
    const bodyElements: ReactNode[] = [];
    const inlineBefore: ReactNode[] = [];
    const inlineAfter: ReactNode[] = [];
    const inlineFlexEnd: ReactNode[] = [];

    Children.forEach(children, (child: ReactElement) => {
      if (child?.type !== McTitleInlineData) {
        bodyElements.push(child);
      } else {
        const position = child.props.positionInlineData ?? "before";

        switch (position) {
          case "before":
            inlineBefore.push(child);
            break;
          case "after":
            inlineAfter.push(child);
            break;
          case "flex-end":
            inlineFlexEnd.push(child);
            break;
          default:
            break;
        }
      }
    });

    setBodyElements(bodyElements);
    setInlineElementBefore(inlineBefore);
    setInlineElementAfter(inlineAfter);
    setInlineElementFlexEnd(inlineFlexEnd);
  }, [children]);

  return (
    <div className={`d-flex flex-column ${className}`} {...props}>
      <span className="d-flex align-items-center ">
        {inlineElementBefore}
        {size === "s" && <h6 className="truncateHeaderText">{title}</h6>}
        {size === "m" && <h5 className="truncateHeaderText">{title}</h5>}
        {size === "l" && <h3 className="truncateHeaderText">{title}</h3>}
        {inlineElementAfter}
        {inlineElementFlexEnd.length > 0 && (
          <>
            <div className="flex-grow-1" />
            {inlineElementFlexEnd}
          </>
        )}
      </span>
      {bodyElements}
    </div>
  );
}

McTitle.InlineData = McTitleInlineData;

export default McTitle;
