import {
  ColDef,
  EditableCallbackParams,
  NewValueParams,
  RowDragEndEvent,
  RowDragEnterEvent,
  SideBarDef,
} from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { IRowMoveInfo } from "mc-shared/zod/checklistSchema";
import * as R from "ramda";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Progress,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";
import {
  ICheckCategoryItem,
  ICheckItem,
  IChecklist,
  IChecklistCategory,
  IChecklistItem,
  IChecklistItemPopulated,
  IChecklistItemRow,
  IChecklistPopulated,
} from "../../../../../types/checklist.types";
import { IIssue, IMinUser, IProject, Paths } from "../../../../../types/global.types";
import { AG_GRID_LOCALE_NOR } from "../../../agGridLocale";
import {
  Checkbox,
  ConfirmDialog,
  CreateNewButton,
  ExcelExportDropdown,
  HSpace,
  McDropdownSingleUser,
  McInput,
  SimpleTag,
  TaskCompletedCount,
  TitleWithValue,
  VSpace,
} from "../../../components";
import { ToastMessagesEnums } from "../../../frontendConstants";
import { setShowSuccessMessageThunk } from "../../../global/globalSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { ITaskCompleteCount } from "../../../types";
import { sortArrayByNumber } from "../../utils";
import { formatShortDate } from "../admin/selectors";
import ChecklistModal from "./ChecklistModal/ChecklistModal";
import { useUpdateChecklistIdsMutation } from "./checklistRTK";
import {
  addChecklistCategoryThunk,
  addChecklistItemThunk,
  deleteChecklistCategoryThunk,
  deleteChecklistItemThunk,
  setChecklistCategoriesInRedux,
  setChecklistItemsInRedux,
  setCurrentChecklistInRedux,
  tempCollapseAllCategoriesThunk,
  toggleAllCategoryCollapseInRedux,
  toggleCategoryCollapseInRedux,
  toggleColVisibility,
  updateChecklistCategoryThunk,
  updateChecklistItemThunk,
  updateChecklistProgressInRedux,
  updateChecklistRowPositionThunk,
  updateChecklistThunk,
} from "./checklistSlice";
import { generateChecklistReportDocxAndDownload, getCategoryCount, getChecklistTableRows } from "./checklistUtils";
import InitialsAndDate from "./InitialsAndDate";
import StampCheck from "./StampCheck";

const ChecklistBoardAgGrid: React.FC<{
  checklist: IChecklistPopulated;
  checklistCategories: IChecklistCategory[];
  checklistItems: IChecklistItem[];
  hiddenColumns: string[];
}> = ({ checklist, checklistCategories, checklistItems, hiddenColumns }) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [selectedChecklistRow, setSelectedChecklistRow] = useState<IChecklistItemRow>(null);
  const [filterText, setFilterText] = useState<string>("");
  const gridRef = useRef<AgGridReact<IChecklistItemRow>>(null);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const allCollapsed = useMemo(
    () => checklistCategories.some((cat) => cat.collapsed === true) === true,
    [checklistCategories],
  );
  const project = useAppSelector((state) => state.adminReducer.project);
  const progress = useAppSelector((state) => state.checklistReducer.progress);
  const [tableRows, setTableRows] = useState<IChecklistItemRow[]>([]);

  useEffect(() => {
    const rows = getChecklistTableRows(editMode, checklist, checklistCategories, checklistItems);
    setTableRows(rows);
  }, [editMode, checklist, checklistCategories, checklistItems]);

  useEffect(() => {
    const indexOfId = history.location.search.indexOf("id");
    if (indexOfId !== -1 && tableRows != null) {
      const indexOf = history.location.search.indexOf("=");
      const id = history.location.search.substring(indexOf + 1);
      const row = tableRows.find((x) => x._id === id);
      if (row != null) {
        setSelectedRowAndAddIdToUrl(row);
      }
    }
  }, [history.location.search.indexOf("id") !== -1, tableRows]);

  function updateRowParameter<P extends Paths<IChecklistItemPopulated, 4>>(
    id: string,
    isItem: boolean,
    path: P,
    oldValue: string,
    newValue: string | string[] | boolean,
  ) {
    if (isItem === true) {
      dispatch(updateChecklistItemThunk(id, path, newValue, oldValue));
      dispatch(setShowSuccessMessageThunk("Sjekklisterad oppdatert"));
    } else {
      const p = path.replace(/^category\./, "");
      dispatch(updateChecklistCategoryThunk(id, p, newValue, oldValue));
      dispatch(setShowSuccessMessageThunk("Sjekklistekategori oppdatert"));
    }
  }

  const expandAllColumnHeader = ({ api }) => {
    return (
      <div className="w-100 d-flex justify-content-center">
        <div
          className={`expandIcon${allCollapsed === false ? " rotateIcon" : ""}`}
          onClick={() => {
            dispatch(toggleAllCategoryCollapseInRedux(!allCollapsed));
          }}
        >
          <div className="d-flex flex-column">
            <i className="fa fa-angle-down" style={{ marginBottom: "-5px" }}></i>
            <i className="fa fa-angle-down" style={{ marginTop: "-4px" }}></i>
          </div>
        </div>
      </div>
    );
  };

  const setSelectedRowAndAddIdToUrl = (row: IChecklistItemRow) => {
    setSelectedChecklistRow(row);
    history.push(`${history.location.pathname}?id=${row._id}`);
  };

  const handleToggleColumnVisibility = (e) => {
    dispatch(toggleColVisibility({ keys: e.columns.map((col) => col.colId), bool: e.visible }));
  };

  const excelExportColumns = (allColumns: boolean) => {
    const fileName = `${checklist.id}_${checklist.name}.xlsx`;
    const columns = allColumns ? gridRef.current?.api.getColumns() : gridRef.current?.api.getAllDisplayedColumns();
    return gridRef.current!.api.exportDataAsExcel({
      fileName,
      sheetName: fileName,
      columnKeys: columns.map((col) => col.getId()).slice(allColumns ? 5 : 1),
    });
  };

  const checkColumnsWithComment = useMemo(() => {
    const checkColumns = getCheckColumns(
      project,
      editMode,
      checklist,
      hiddenColumns,
      updateRowParameter,
      (categoryId, param, newValue, oldValue) =>
        dispatch(updateChecklistCategoryThunk(categoryId, param, newValue, oldValue)),
    );

    const commentColumn = {
      field: "comment",
      filter: "agTextColumnFilter",
      autoHeight: true,
      headerName: `Rapportkommentar`,
      cellClass: "p-0",
      minWidth: 200,
      flex: 1,
      hide: editMode === true || hiddenColumns.includes("comment"),
      cellRenderer: (params) => {
        const row = params.data as IChecklistItemRow;
        if (row.inactive == null || row.originalItem == null) {
          return <></>;
        }

        return (
          <div style={{ minHeight: "35px" }}>
            <TitleWithValue
              wrap={true}
              type="textarea"
              editable={true}
              allowEmpty
              title=""
              value={row.comment}
              saveCb={(oldValue: string, newValue: string) =>
                updateRowParameter(row._id, true, "comment", oldValue, newValue)
              }
            />
          </div>
        );
      },
    };
    const visibleColumnsCount = checkColumns.filter((column) => !column.hide).length;
    if (visibleColumnsCount > 3) {
      checkColumns.unshift(commentColumn);
    } else {
      checkColumns.push(commentColumn);
    }
    return checkColumns;
  }, [project?.members, editMode, hiddenColumns]);

  const columns: ColDef<IChecklistItemRow>[] = useMemo(() => {
    const _cols: ColDef<IChecklistItemRow>[] = [
      {
        suppressColumnsToolPanel: true,
        pinned: "left",
        suppressMovable: true,
        field: Math.random().toString(),
        headerName: "",
        maxWidth: 60,
        headerComponent: expandAllColumnHeader,
        headerClass: "p-0",
        cellClass: "justify-content-center p-0",
        cellRenderer: (params) => {
          const row = params.data as IChecklistItemRow;
          return (
            <>
              {row.originalItem == null ? (
                <div
                  onClick={() => dispatch(toggleCategoryCollapseInRedux(row._id))}
                  className={`expandIcon${row.collapsed === true ? " rotateIcon" : ""}`}
                >
                  <i className="fa fa-angle-up"></i>
                </div>
              ) : (
                <></>
              )}
            </>
          );
        },
      },
      {
        hide: editMode === false,
        suppressMovable: true,
        suppressColumnsToolPanel: true,
        field: "_id",
        headerName: "",
        width: 100,
        resizable: false,
        cellRenderer: (params) => {
          const row = params.data as IChecklistItemRow;
          return (
            <>
              {row.originalItem != null ? (
                <ConfirmDialog
                  confirmCb={() => dispatch(deleteChecklistItemThunk(row._id))}
                  title="Slett rad"
                  message="Er du sikker på at du vil slette denne raden?"
                >
                  <Button className="mr-2" size="sm" color="outline-danger">
                    <i className="fa fa-trash fa-fw" />
                  </Button>
                </ConfirmDialog>
              ) : (
                <div className="d-flex">
                  <ConfirmDialog
                    confirmCb={() => dispatch(deleteChecklistCategoryThunk(row._id))}
                    title="Slett kategori"
                    message="Er du sikker på at du vil slette denne kategorien og alle tilhørende rader?"
                  >
                    <Button className="mr-2" size="sm" color="outline-danger">
                      <i className="fa fa-trash fa-fw" />
                    </Button>
                  </ConfirmDialog>
                  <Button size="sm" color="outline-success" onClick={() => addItem(row.originalCategory)}>
                    <i className="fa fa-plus fa-fw" />
                  </Button>
                </div>
              )}
            </>
          );
        },
      },
      {
        field: "categoryId",
        headerName: "Kategori-ID",
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "itemId",
        headerName: "Rad-ID",
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "comments",
        headerName: "",
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "inactive.isChecked",
        headerName: "IR",
        hide: editMode === true || hiddenColumns.includes("inactive.isChecked"),
        width: 65,
        filter: true,
        filterParams: {
          suppressMiniFilter: true,
        },
        valueGetter: (params) => (params.data.inactive?.isChecked ? "Ikke relevant" : "Relevant"),
        cellRenderer: (params) => {
          const row = params.data as IChecklistItemRow;
          return (
            <>
              {row.inactive != null ? (
                <div id={"a" + row._id} className="d-flex w-100 align-items-center justify-content-center">
                  <Checkbox
                    icon="ban"
                    isChecked={row.inactive.isChecked === true}
                    setChecked={() =>
                      updateRowParameter(
                        row._id,
                        true,
                        "inactive.isChecked",
                        row.inactive?.isChecked.toString(),
                        !row.inactive?.isChecked,
                      )
                    }
                    disabled={false}
                    color="#f8992e"
                  />
                  {row.inactive.isChecked === true ? (
                    <>
                      <UncontrolledTooltip placement="bottom" target={"[id='a" + row._id + "']"} container={"body"}>
                        {`${(row.inactive.updatedBy as IMinUser)?.name || ""}`}
                        <br />
                        {`\n${formatShortDate((row.inactive.updatedDate || "").toString())}`}
                      </UncontrolledTooltip>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                ""
              )}
            </>
          );
        },
      },
      {
        field: "rowId",
        filter: "agTextColumnFilter",
        headerName: "Nr.",
        rowDrag: editMode === true,
        hide: editMode === false && hiddenColumns.includes("rowId"),
        width: 75,
        minWidth: 75,
        cellRenderer: (params) => {
          const row = params.data as IChecklistItemRow;
          return (
            <div className="d-flex p-0 m-0">
              <Button
                className="m-0 p-0 font-weight-bold"
                color="link"
                onClick={() => setSelectedRowAndAddIdToUrl(row)}
              >
                {row.inactive != null && row.originalItem?.checks == null ? (
                  <b className={`${row.inactive?.isChecked === true ? "text-inactive" : ""}`}>{row.rowId}</b>
                ) : (
                  <span className={`${row.inactive?.isChecked === true ? "text-inactive" : ""}`}>{row.rowId}</span>
                )}
              </Button>
              <div className="flex-fill" />
              {row.comments?.length > 0 && (
                <h5 className="mt-2 mc-green-text">
                  <i className="fa fa-comments fa-fw" />
                  {row.comments.length}
                </h5>
              )}
            </div>
          );
        },
      },
      {
        hide: editMode === true || hiddenColumns.includes("connectedIssues"),
        field: "connectedIssues",
        filter: "agTextColumnFilter",
        headerName: "Saker",
        width: 85,
        minWidth: 85,
        cellRenderer: (params) => {
          const row = params.data as IChecklistItemRow;
          if (row.checks == null) {
            return "";
          } else {
            let connectedIssues: IIssue[] = row.connectedIssues;
            connectedIssues = connectedIssues?.filter((issue) => issue.archived !== true);
            const completedCount = connectedIssues?.filter((issue) => issue.status === "Fullført")?.length;
            const taskCompleteCount: ITaskCompleteCount = {
              totalNr: connectedIssues?.length,
              completedCount,
              showWarning: false,
              darkText: true,
            };
            return (
              <div className="d-flex align-items-center p-0 m-0">
                {row?.connectedIssues != null && <TaskCompletedCount taskCompleteCount={taskCompleteCount} />}
              </div>
            );
          }
        },
      },
      {
        field: "title",
        filter: "agTextColumnFilter",
        headerName: "Kontrollspørsmål (krav)",
        hide: editMode === false && hiddenColumns.includes("title"),
        flex: 2,
        maxWidth: 800,
        editable: editMode,
        autoHeight: true,
        cellClass: (params) => {
          const row = params.data as IChecklistItemRow;
          return row?.originalItem == null ? "grid-display-block bold-cell" : "grid-display-block";
        },
        minWidth: 380,
        cellEditor: editMode === true ? "agLargeTextCellEditor" : undefined,
        cellEditorPopup: editMode,
        cellEditorParams: {
          maxLength: 500,
        },
        onCellValueChanged:
          editMode === true
            ? (event: NewValueParams<IChecklistItemRow, string>) => {
                const isItem = event.data.originalItem != null;
                const oldValue = (isItem ? event.data.originalItem?.title : event.data.originalCategory?.title) ?? "-";
                const newValue = event.newValue ?? "-";

                updateRowParameter(event.data._id, isItem, "title", oldValue, newValue);
              }
            : undefined,
      },
      {
        field: "reference",
        editable: (params: EditableCallbackParams<IChecklistItemRow, string>) => {
          return params.data.originalItem != null && editMode === true;
        },
        hide: editMode === false,
        filter: "agTextColumnFilter",
        headerName: "Referanse",
        onCellValueChanged:
          editMode === true
            ? (event: NewValueParams<IChecklistItemRow, string>) => {
                const isItem = event.data.originalItem != null;
                const oldValue = event.data.originalItem.reference ?? "";
                const newValue = event.newValue ?? "";

                if (isItem && event.data.originalItem.reference !== event.newValue) {
                  updateRowParameter(event.data._id, isItem, "reference", oldValue, newValue);
                }
              }
            : undefined,
      },
      {
        field: "dueDate",
        hide: editMode === true || hiddenColumns.includes("dueDate"),
        editable: (params: EditableCallbackParams<IChecklistItemRow, string>) => {
          return params.data.originalItem == null;
        },
        cellEditorPopup: false,
        cellEditor: "agDateStringCellEditor",
        valueGetter: (params) => {
          const date = params.data.dueDate != null ? params.data.dueDate.split("T")[0] : null;
          return date;
        },
        valueSetter: (params) => {
          updateRowParameter(params.data._id, false, "category.dueDate", params.oldValue, params.newValue);
          return true;
        },
        filter: "agDateColumnFilter",
        headerName: "Frist",
        cellClass: "px-0 grid-display-flex ",
        cellStyle: {
          display: "inline-block",
        },
        minWidth: 130,
        width: 130,
      },
      ...checkColumnsWithComment,
    ];

    if (checklist.itemTagsActive === true) {
      _cols.push({
        field: "itemTags",
        headerName: "Etiketter",
        hide: checklist.itemTagsActive !== true,
        cellClass: "grid-display-block p-0",
        maxWidth: 200,
        autoHeight: true,
        cellEditorPopup: true,
        cellRenderer: (params) => {
          const row = params.data as IChecklistItemRow;
          return row.originalItem != null ? (
            <div className="d-flex align-items-center flex-wrap h-100">
              <CellTags
                allTags={checklist.itemTags ?? []}
                currentTags={row.originalItem.itemTags ?? []}
                editMode={editMode}
                onChange={(tags) =>
                  updateRowParameter(
                    row._id,
                    row.originalItem != null,
                    "itemTags",
                    JSON.stringify(row.originalItem.itemTags),
                    tags,
                  )
                }
              />
            </div>
          ) : (
            <></>
          );
        },
      });
    }

    return _cols;
  }, [editMode, allCollapsed, tableRows]);

  const sideBar = useMemo(() => {
    const sb: SideBarDef = {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivotMode: true,
          },
        },
      ],
      position: "left",
    };
    return sb;
  }, []);

  useEffect(() => {
    if (checklist.isTemplate === true) {
      setEditMode(true);
    }
  }, []);

  useEffect(() => {
    if (selectedChecklistRow != null) {
      setSelectedChecklistRow(tableRows.find((x) => x._id === selectedChecklistRow._id));
    }
  }, [tableRows]);

  const addCategory = async () => {
    dispatch(addChecklistCategoryThunk(getCategoryCount(checklistCategories)));
  };

  const addItem = async (category: IChecklistCategory) => {
    dispatch(addChecklistItemThunk(category._id));
  };

  const closeModal = async () => {
    setSelectedChecklistRow(null);
    history.push(history.location.pathname);
  };

  const [updateChecklistIds, { data: checklistParts, isError: updateIdError }] = useUpdateChecklistIdsMutation();

  useEffect(() => {
    if (updateIdError) {
      dispatch(setShowSuccessMessageThunk("Feil ved tilbakestilling av ID-sekvenser"));
      return;
    }

    if (checklistParts == null) {
      return;
    }

    dispatch(setShowSuccessMessageThunk("ID-sekvenser tilbakestilt"));

    const { categories, checklist, items } = checklistParts;

    dispatch(updateChecklistProgressInRedux(checklist.progress));
    dispatch(setCurrentChecklistInRedux(checklist));
    dispatch(setChecklistItemsInRedux(sortArrayByNumber(items, "itemId")));
    dispatch(setChecklistCategoriesInRedux(sortArrayByNumber(categories, "categoryId")));
  }, [checklistParts]);

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: false,
      resizable: true,
      suppressHeaderMenuButton: true,
      editable: false,
      cellStyle: {
        whiteSpace: "normal",
        lineHeight: "1.5",
        wordBreak: "break-word",
      },
    }),
    [],
  );

  const onRowDragEnter = (event: RowDragEnterEvent<IChecklistItemRow>) => {
    if (event.node.data.originalItem === null) {
      dispatch(tempCollapseAllCategoriesThunk(true));
    }
  };

  const findNewCategoryIndex = (newPosition: number): number => {
    let foundIndex = -1;

    const displayRow = gridRef.current.api.getDisplayedRowAtIndex(newPosition - 1);
    const prevRowCategoryId = displayRow?.data?.categoryId ?? -1;

    gridRef.current.api.forEachNode((node) => {
      const data = node.data;

      if (data.originalItem === null && data.categoryId === prevRowCategoryId) {
        foundIndex = node.rowIndex;
        return;
      }
    });

    return foundIndex;
  };

  const onRowDragEnd = async (event: RowDragEndEvent<IChecklistItemRow>) => {
    const draggedRow = event.node.data;
    const isCat = draggedRow.originalItem == null;
    const isItem = draggedRow.originalItem != null;
    const newPosition = event.node.rowIndex;

    // reset grid data to "cancel" move if the dragged row is an item and is moved to the top of the grid
    if (isItem === true && newPosition === 0) {
      gridRef.current.api.setGridOption("rowData", filteredData);
      dispatch(tempCollapseAllCategoriesThunk(false));
      return;
    }

    const newCatIndex = findNewCategoryIndex(newPosition);
    const newCat = gridRef.current.api.getDisplayedRowAtIndex(newCatIndex)?.data;
    const newRelativePosition = newPosition - newCatIndex;
    const newIndex = event.node.rowIndex;
    const originalIndex = filteredData.findIndex((i) => i._id === draggedRow._id);

    if (originalIndex === event.node.rowIndex || event.overNode?.data == null) {
      dispatch(tempCollapseAllCategoriesThunk(false));
      return;
    }

    const requestInfo: IRowMoveInfo = {
      isCat,
      oldCategoryId: draggedRow.originalCategory._id,
      newCategoryId: newCat?.originalCategory._id ?? "xxxxxxxxxxxxxxxxxxxxxxxx",
      rowId: isCat ? draggedRow.originalCategory._id : draggedRow.originalItem._id,
      position: isCat ? newIndex : newRelativePosition - 1,
      checklistId: checklist._id,
    };

    if (isCat === true) {
      dispatch(tempCollapseAllCategoriesThunk(false));
    }

    await dispatch(updateChecklistRowPositionThunk(requestInfo));
    dispatch(setShowSuccessMessageThunk("Sjekklisterad oppdatert"));
  };

  const filteredData = useMemo(() => {
    return tableRows.filter((row) => row.originalItem == null || (row.originalItem != null && row.collapsed !== true));
  }, [tableRows]);

  const updateChecklist = async (prop: string, oldVal, newVal) => {
    await dispatch(updateChecklistThunk(prop, newVal, oldVal));
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.SETTINGS_UPDATED));
  };

  return (
    <>
      <div className="d-flex m-2">
        <h3 className="d-flex align-items-center">
          <div className="mx-2">{checklist.name}</div>
        </h3>
        <div className="d-flex flex-grow-1 justify-content-end align-items-center">
          {editMode === false && (
            <>
              <div className="w-100" style={{ maxWidth: "40%" }}>
                <Progress multi className="w-100">
                  {progress.map((p, i) => (
                    <Progress
                      bar
                      style={{ backgroundColor: p.color }}
                      value={p.percentage}
                      key={`progress__${i}`}
                    ></Progress>
                  ))}
                </Progress>
              </div>
              <div className=" d-flex ml-2">
                <Button
                  className="text-nowrap mr-2"
                  color="success"
                  onClick={() => generateChecklistReportDocxAndDownload(project, checklist, checklistCategories, checklistItems)}
                >
                  <i className="fa fa-file-word-o fa-fw" />
                  Generer utskrift
                </Button>
                <ExcelExportDropdown
                  excelExportVisibleColumns={() => excelExportColumns(false)}
                  exportExcelAllColumns={() => excelExportColumns(true)}
                />
              </div>
            </>
          )}
          <>
            {editMode === false && (
              <Button
                className="ml-2"
                onClick={() => {
                  dispatch(toggleAllCategoryCollapseInRedux(false));
                  setEditMode(true);
                }}
              >
                <i className="fa fa-pencil fa-fw" />
                Rediger sjekkliste
              </Button>
            )}
            {editMode === true && (
              <>
                <Button
                  id="updateChecklistIds"
                  color="primary"
                  className="mr-2"
                  onClick={() => updateChecklistIds({ projectId: project._id, checklistId: checklist._id })}
                >
                  <i className="fa fa-sort mr-1 "></i>
                  ID
                </Button>
                <UncontrolledTooltip delay={300} target="updateChecklistIds">
                  Tilbakestill ID-sekvenser
                </UncontrolledTooltip>
              </>
            )}
            {editMode === true && <CreateNewButton onClick={addCategory} tooltip="Legg til kategori" />}
            {editMode === true && checklist.isTemplate === false && (
              <div>
                <Button color="secondary" className="ml-2" onClick={() => setEditMode(false)}>
                  <i className="fa fa-check fa-fw mr-1" />
                  Lukk redigering
                </Button>
              </div>
            )}
          </>
        </div>
      </div>
      <McInput
        type="text"
        placeholder="Søk"
        className="mx-2"
        onChange={(e) => setFilterText(e.target.value)}
        style={{ maxWidth: "300px" }}
      />
      <VSpace />
      <div className="d-flex align-items-center">
        <HSpace />
        <TagToggle
          tags={checklist.itemTags}
          currentTags={checklist?.activeItemTags}
          onChange={async (currTags) => {
            try {
              await updateChecklist("activeItemTags", checklist?.activeItemTags, currTags);
              dispatch(setShowSuccessMessageThunk("Etiketter oppdatert"));
            } catch (error) {
              // dispatch(setShowFailedMessage("Etiketter ej oppdatert"));
            }
          }}
        />
        <HSpace />
        <InitialsAndDate
          updatedBy={checklist.activeItemTagChangedBy}
          updatedDate={checklist.activeItemTagChangedDate}
          singleLine={true}
        />
      </div>
      <VSpace />
      {tableRows != null && columns.length !== 0 && (
        <>
          <div className="d-flex flex-column border h-100 overflow-auto">
            <div className="ag-theme-quartz h-100">
              <AgGridReact
                onColumnVisible={handleToggleColumnVisibility}
                enableCellTextSelection={editMode === false}
                rowHeight={53}
                maintainColumnOrder={true}
                ref={gridRef}
                getRowId={(p) => p.data._id}
                rowData={filteredData}
                columnDefs={columns}
                stopEditingWhenCellsLoseFocus={true}
                defaultColDef={defaultColDef}
                animateRows={true}
                rowBuffer={50}
                localeText={AG_GRID_LOCALE_NOR}
                quickFilterText={filterText}
                sideBar={editMode !== true ? sideBar : null}
                suppressMoveWhenRowDragging={true}
                rowDragManaged={true}
                onRowDragEnter={onRowDragEnter}
                onRowDragEnd={onRowDragEnd}
                suppressColumnVirtualisation={true}
              />
            </div>

            {selectedChecklistRow != null && (
              <ChecklistModal
                stampSettings={checklist.stampSettings}
                editable={editMode === true}
                checklistRow={selectedChecklistRow}
                closeIssueItemModal={closeModal}
                updateRowAttribute={(rowId, path, oldValue, newValue) =>
                  updateRowParameter(rowId, true, path, oldValue, newValue)
                }
                columns={columns}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ChecklistBoardAgGrid;
const getCheckColumns = (
  project: IProject,
  editMode: boolean,
  checklist: IChecklist,
  hiddenColumns: string[],
  updateRowParameter: (id: string, boolean, status: string, oldValue, newValue) => void,
  updateChecklistCategory: (categoryId: string, param: string, newValue: string, oldValue: string) => void,
): ColDef<any>[] => {
  const projectUsers = project?.members.map((m) => m.user);

  const nrOfCheckColumns = 15;

  const columns = [];
  for (let i = 1; i <= nrOfCheckColumns; i++) {
    const checklistKey = `check${i}`;
    const isActive = R.path(["checkTypes", checklistKey, "active"], checklist);
    columns.push({
      field: `checks.check${i}.status`,
      headerName: R.path(["checkTypes", checklistKey, "name"], checklist),
      hide:
        hiddenColumns.includes(`checks.check${i}.status`) ||
        isActive !== true ||
        (editMode === true && checklist.includeIrOnDefaultRoles !== true),
      suppressColumnsToolPanel: isActive !== true,
      cellClass: "grid-display-block",
      cellStyle: {
        overflow: "visible",
        padding: "0px 4px",
        display: "inline-block",
      },
      minWidth: 180,
      filter: true,
      valueGetter: (params) => {
        const row = params.data as IChecklistItemRow;
        const checkItem: ICheckItem = R.path(["originalItem", "checks", checklistKey], row);
        const checkItemCategoryItem: ICheckCategoryItem = R.path(["originalCategory", "checks", checklistKey], row);
        return checkItem?.status === "YES"
          ? "Ja"
          : checkItem?.status === "N/A"
          ? "-"
          : checkItem?.status === "NO"
          ? "Nei"
          : checkItemCategoryItem?.assignedTo?.name ?? "Ingen ansvarlig";
      },
      cellRenderer: (params) => {
        const row = params.data as IChecklistItemRow;
        return (
          <>
            {row.inactive != null && row.originalItem?.checks ? (
              <div className="d-flex align-items-center h-100 grid-cell-center-fix">
                <StampCheck
                  templateMode={checklist.isTemplate}
                  includeIr={i > 3}
                  disabled={row.inactive.isChecked === true}
                  toggle={(id, oldValue, newValue) =>
                    updateRowParameter(id, true, `checks.check${i}.status`, oldValue, newValue)
                  }
                  updateCommentText={(text) =>
                    updateRowParameter(row._id, true, `checks.check${i}.comment.text`, "", text)
                  }
                  checkItem={R.path(["originalItem", "checks", checklistKey], row)}
                  row={row}
                  stampSettings={checklist.stampSettings}
                  includeIrOnDefaultRoles={checklist.includeIrOnDefaultRoles}
                />
              </div>
            ) : (
              <div style={{ marginTop: "2px" }} className=" grid-cell-center-fix">
                {checklist.isTemplate !== true && (
                  <McDropdownSingleUser
                    filterActive={projectUsers.length > 10}
                    color="transparent"
                    onClear={
                      R.path(["originalCategory", "checks", `check${i}`, "assignedTo"], row) != null
                        ? () =>
                            updateChecklistCategory(
                              row._id,
                              `checks.check${i}.assignedTo`,
                              null,
                              R.path(["originalCategory", "checks", `check${i}`, "assignedTo"], row),
                            )
                        : null
                    }
                    options={projectUsers}
                    selected={
                      R.path(["originalCategory", "checks", `check${i}`, "assignedTo"], row) || { _id: "", name: "" }
                    }
                    onChange={(user) =>
                      updateChecklistCategory(
                        row._id,
                        `checks.check${i}.assignedTo`,
                        user._id,
                        R.path(["originalCategory", "checks", `check${i}`, "assignedTo"], row),
                      )
                    }
                  />
                )}
              </div>
            )}
          </>
        );
      },
    });
  }
  return columns;
};

const CellTags: React.FC<{
  allTags: string[];
  currentTags: string[];
  editMode: boolean;
  onChange: (currentTags: string[]) => void;
}> = ({ allTags, currentTags, editMode, onChange }) => {
  const menuItems = allTags.filter((tag) => !currentTags.includes(tag));
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      {editMode === true && (
        <>
          <UncontrolledDropdown isOpen={menuOpen} toggle={() => setMenuOpen(!menuOpen)}>
            <DropdownToggle className="d-flex d-inline-block" tag="span" data-toggle="dropdown">
              <Button size="sm" color="outline-success" disabled={allTags.length === currentTags.length}>
                <i className="fa fa-plus fa-fw" />
              </Button>
            </DropdownToggle>
            <DropdownMenu>
              {menuItems.map((tag) => (
                <DropdownItem key={tag} onClick={() => onChange([...currentTags, tag])}>
                  {tag}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </>
      )}
      {[...currentTags].sort().map((tag, index) => (
        <div key={index}>
          <SimpleTag
            tagSize={"sm"}
            editable={editMode === true}
            displayValue={tag}
            onDelete={(value) => {
              const tags = currentTags.filter((e) => e !== value);
              onChange(tags);
            }}
          />
        </div>
      ))}
    </>
  );
};

const TagToggle: React.FC<{ tags: string[]; currentTags: string[]; onChange: (tags: string[]) => void }> = ({
  tags = [],
  currentTags = [],
  onChange,
}) => {
  return (
    <ButtonGroup>
      {[...tags].sort().map((tag) => (
        <Button
          key={tag}
          color={currentTags.includes(tag) ? "primary" : "secondary"}
          onClick={() =>
            onChange(currentTags.includes(tag) ? currentTags.filter((e) => e !== tag) : [...currentTags, tag])
          }
        >
          {tag}
        </Button>
      ))}
    </ButtonGroup>
  );
};
