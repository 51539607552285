import { IColumnValidator, IValidationResult } from "mc-shared/zod/excelImportSchema";
import { clone } from "ramda";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { read, utils } from "xlsx";
import { validateExcelData } from "./ExcelImportValidator.utils";

export type ValidationResult = {
  fileName: string;
  sourceData: (string | number)[][] | null;
  validatedData: IValidationResult[][];
  errors: string[] | null;
};

export type ValidatorRef = {
  selectFile: () => void;
};

export type ValidatorProps = {
  onValidated: (result: ValidationResult) => void;
  validators: IColumnValidator[];
  skipValidateRows?: number;
  skipRows?: number;
  sortColumn?: number;
  children?: React.ReactNode;
};

const ExcelImportValidator = forwardRef<ValidatorRef, ValidatorProps>(
  ({ validators, onValidated, skipValidateRows, skipRows, sortColumn, children }, ref) => {
    const fileInputRef = useRef(null);

    const readFile = (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();

      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onload = function (e) {
        const readedData = read(e.target.result, { type: "binary", raw: true });
        const wsname = readedData.SheetNames[0];

        const ws = readedData.Sheets[wsname];
        const sourceData = utils.sheet_to_json<(string | number)[]>(ws, { header: 1, raw: true });

        // remove empty rows from dataParse and find max column length
        const rowsWithoutEmpty = sourceData.reduce(
          (acc, row, index) => {
            if (index < skipRows) {
              return acc;
            }

            if (row.length > acc.maxColLength) {
              acc.maxColLength = row.length;
            }
            if (row.length > 0) {
              acc.arr.push(row);
            }
            return acc;
          },
          { maxColLength: 0, arr: [] } as { maxColLength: number; arr: (string | number)[][] },
        );

        // sort dataParse as hierarchy numbers by sortColumn if set
        if (sortColumn != null && sortColumn >= 0 && sortColumn < rowsWithoutEmpty.maxColLength) {
          rowsWithoutEmpty.arr.sort();
        }

        // pad rows with empty cells to max column length
        rowsWithoutEmpty.arr = rowsWithoutEmpty.arr.map((row) => {
          if (row.length < rowsWithoutEmpty.maxColLength) {
            return row.concat(Array(rowsWithoutEmpty.maxColLength - row.length).fill(null));
          }
          return row;
        });

        const validatedData = validateExcelData(clone(rowsWithoutEmpty.arr), validators ?? [], skipValidateRows);

        if (validatedData.some((row) => row.some((cell) => cell.isValid === false)) === false) {
          fileInputRef.current.value = "";
          onValidated({ fileName: file.name, sourceData, validatedData, errors: null });
          return;
        }

        const errors = validatedData.reduce((acc: string[], row) => {
          row.forEach((cell) => {
            if (cell.isValid === false) {
              acc.push(
                `Rad ${cell.row + 1 + skipRows}, kolonne ${cell.col} er ugyldig.\nVerdi: ${cell.value ?? ""}\nFel: ${
                  validators[cell.col].errorMessage
                }`,
              );
            }
          });
          fileInputRef.current.value = "";
          return acc;
        }, []);

        onValidated({ fileName: file.name, sourceData, validatedData, errors: errors });
      };

      reader.readAsBinaryString(file);
    };

    useImperativeHandle(
      ref,
      () =>
        ({
          selectFile: () => {
            fileInputRef.current.click();
          },
        } as ValidatorRef),
    );

    return (
      <div>
        <input
          ref={fileInputRef}
          type="file"
          name="file"
          accept=".xls,.xlsx"
          onChange={readFile}
          style={{ display: "none" }}
        />
        {children}
      </div>
    );
  },
);

export default ExcelImportValidator;
