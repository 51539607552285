import {
  IAllOtherAttributeFormulaWithGeneratedStrings,
  IOtherAttributeConfig,
  IOtherAttributeConfigItemProps,
} from "mc-shared/zod/otherAttributesSchema";
import { useEffect, useState } from "react";
import { FoldableSection, McDropdown, McInput, McTitle, SimpleTags } from "..";
import SimpleStampCheck from "../SimpleStampCheck/SimpleStampCheck";

type IConfigWithId = { fieldId: string } & IOtherAttributeConfigItemProps;
interface ISection {
  sectionName: string;
  sortedAttributeConfigs: ([IConfigWithId, IConfigWithId?, IConfigWithId?] | [IConfigWithId])[];
}

type OnSaveType = (
  oldValue: string | number | boolean,
  newValue: string | number | boolean | string[],
  attributePath: string,
) => void;

function CustomAttributeSection<T>({
  generatedFormulas,
  issueLocalId,
  onSave,
  attributeConfig = {},
  data = {} as T,
}: {
  generatedFormulas?: IAllOtherAttributeFormulaWithGeneratedStrings;
  issueLocalId: number;
  attributeConfig: IOtherAttributeConfig;
  data: T;
  onSave: OnSaveType;
}) {
  const [sections, setSections] = useState<JSX.Element[]>([]);
  const [unlockedFields, setUnlockedFields] = useState<string[]>([]);

  const toggleLockdeField = (fieldId: string) => {
    if (unlockedFields.includes(fieldId)) {
      setUnlockedFields(unlockedFields.filter((id) => id !== fieldId));
    } else {
      setUnlockedFields([...unlockedFields, fieldId]);
    }
  };

  useEffect(() => {
    const fields = Object.keys(attributeConfig);

    // sort attributes by order
    fields.sort((a, b) => attributeConfig[a].order - attributeConfig[b].order);

    // remove inactive attributes
    const activeFields = fields.filter((attribute) => attributeConfig[attribute].isActive === true);

    // create a sections array with attribute config arrays
    const sectionKeysMap: Map<string, IConfigWithId[]> = new Map();

    activeFields.forEach((fieldId) => {
      const sectionName = attributeConfig[fieldId].section;

      if (sectionKeysMap.has(sectionName) === false) {
        sectionKeysMap.set(sectionName, [] as IConfigWithId[]);
      }
      const sect = sectionKeysMap.get(sectionName);
      const configWithId = { ...attributeConfig[fieldId], fieldId };
      sect.push(configWithId);
    });

    // create a 3 columns array with attribute config arrays for each cell
    const sectionsArray: ISection[] = Array.from(sectionKeysMap).map(([sectionName, attributeConfigs]) => {
      const attributeConfigRows: ([IConfigWithId, IConfigWithId?, IConfigWithId?] | [IConfigWithId])[] = [];
      let rowOfThree: [IConfigWithId, IConfigWithId?, IConfigWithId?] = [null, null, null];
      let columnIndex = 0;

      for (let i = 0; i < attributeConfigs.length; i++) {
        const attributeConfig = attributeConfigs[i];
        if (attributeConfig.typeOfValue === "MULTILINESTRING") {
          if (rowOfThree[0] || rowOfThree[1] || rowOfThree[2]) {
            attributeConfigRows.push(rowOfThree);
            rowOfThree = [null, null, null];
          }

          attributeConfigRows.push([attributeConfig]);
          columnIndex = 0;
          continue;
        }

        rowOfThree[columnIndex] = attributeConfig;
        columnIndex += 1;

        if (columnIndex === 3) {
          attributeConfigRows.push(rowOfThree);
          rowOfThree = [null, null, null];
          columnIndex = 0;
        }

        if (i === attributeConfigs.length - 1 && (rowOfThree[0] || rowOfThree[1] || rowOfThree[2])) {
          attributeConfigRows.push(rowOfThree);
        }
      }

      return { sectionName, sortedAttributeConfigs: attributeConfigRows };
    });

    const _sectionElements = sectionsArray.map((section, index) => (
      <FoldableSection key={section.sectionName}>
        <FoldableSection.Header>
          <div className="section-title">{section.sectionName}</div>
        </FoldableSection.Header>
        <FoldableSection.Body>
          <div className="d-flex flex-column w-100 mt-2 mb-2">
            <div className="d-flex flex-column">
              {section.sortedAttributeConfigs.map((rows, iColumn) => (
                <div className="even-columns mt-2" key={`${iColumn}${index}`}>
                  {rows.map((cell, iField) => {
                    const cellKey = `${index}${iColumn}${iField}`;

                    if (cell == null) {
                      return <div key={cellKey}></div>;
                    }

                    const cellValue = data[cell.fieldId];

                    return (
                      <McTitle title={cell.label} className="mr-4" key={cellKey}>
                        <McTitle.InlineData positionInlineData="before">
                          <h5 className="d-flex">
                            {cell?.labelColor && (
                              <i
                                className="fa fa-fw fa-circle"
                                style={{ color: cell?.labelColor, marginLeft: "-0.2rem" }}
                              />
                            )}
                          </h5>
                        </McTitle.InlineData>
                        <McTitle.InlineData positionInlineData="flex-end">
                          <h5 className="d-flex">
                            {cell.locked === true && (
                              <i
                                className={`fa fa-fw pointer ${
                                  unlockedFields.includes(cellKey) ? "fa-unlock text-success" : "fa-lock text-muted"
                                }`}
                                onClick={() => toggleLockdeField(cellKey)}
                              />
                            )}
                            {cell.description && (
                              <i className="fa fa-info-circle fa-fw text-info" title={cell.description} />
                            )}
                          </h5>
                        </McTitle.InlineData>
                        {cell.typeOfValue === "BOOLEAN" && (
                          <>
                            <div className="d-flex align-items-center h-100">
                              <SimpleStampCheck
                                disabled={cell.locked === true && unlockedFields.includes(cellKey) === false}
                                toggled={cellValue == null ? null : Boolean(cellValue)}
                                toggle={(newValue) => {
                                  onSave(cellValue, newValue, cell.fieldId);
                                  if (cell.locked === true) {
                                    toggleLockdeField(cellKey);
                                  }
                                }}
                              />
                            </div>
                          </>
                        )}
                        {cell.typeOfValue === "DROPDOWN" && cell.allowMultipleSelectionsInDropdown !== true && (
                          <OtherAttributesDropdown
                            disabled={cell.locked === true && unlockedFields.includes(cellKey) === false}
                            cell={cell}
                            cellValue={cellValue}
                            onSave={(oldValue, newValue, attributePath) => {
                              onSave(oldValue, newValue, attributePath);
                              if (cell.locked === true) {
                                toggleLockdeField(cellKey);
                              }
                            }}
                          />
                        )}
                        {cell.typeOfValue === "DROPDOWN" &&
                          Array.isArray(cell.tableOptions) &&
                          cell.allowMultipleSelectionsInDropdown === true && (
                            <SimpleTags
                              editable={cell.locked !== true || unlockedFields.includes(cellKey) === true}
                              activeTags={convertValueToArray(cellValue)}
                              tagOptions={cell.tableOptions
                                ?.map((option) => option.value)
                                .filter((value) => convertValueToArray(cellValue).includes(value) === false)}
                              onChange={(value) => {
                                const currentSet = new Set(cellValue);
                                const newSet = new Set(value);

                                if (currentSet.size !== newSet.size) {
                                  onSave(cellValue, value, cell.fieldId);
                                }
                              }}
                            />
                          )}
                        {cell.typeOfValue === "STRING" && (
                          <McInput
                            disabled={cell.locked === true && unlockedFields.includes(cellKey) === false}
                            type="text"
                            onBlur={(e) => {
                              onSave(cellValue, e.target.value, cell.fieldId);
                              if (cell.locked === true) {
                                toggleLockdeField(cellKey);
                              }
                            }}
                            value={cellValue}
                          />
                        )}
                        {cell.typeOfValue === "MULTILINESTRING" && (
                          <McInput
                            disabled={cell.locked === true && unlockedFields.includes(cellKey) === false}
                            type={"textarea"}
                            dynamicHeight={true}
                            onBlur={(e) => {
                              onSave(cellValue, e.target.value, cell.fieldId);
                              if (cell.locked === true) {
                                toggleLockdeField(cellKey);
                              }
                            }}
                            value={cellValue}
                          />
                        )}
                        {cell.typeOfValue === "NUMBER" && (
                          <McInput
                            disabled={cell.locked === true && unlockedFields.includes(cellKey) === false}
                            type={"number"}
                            onBlur={(e) => {
                              onSave(cellValue, e.target.value === "" ? null : Number(e.target.value), cell.fieldId);
                              if (cell.locked === true) {
                                toggleLockdeField(cellKey);
                              }
                            }}
                            value={cellValue}
                          />
                        )}
                        {cell.typeOfValue === "DATE" && (
                          <McInput
                            disabled={cell.locked === true && unlockedFields.includes(cellKey) === false}
                            type="date"
                            onBlur={(e) => {
                              onSave(cellValue, e.target.value, cell.fieldId);
                              if (cell.locked === true) {
                                toggleLockdeField(cellKey);
                              }
                            }}
                            value={cellValue}
                          />
                        )}
                        {cell.typeOfValue === "FORMULA" && (
                          <span>{generatedFormulas?.[cell.fieldId]?.[issueLocalId]}</span>
                        )}
                      </McTitle>
                    );
                  })}
                </div>
              ))}
            </div>
          </div>
        </FoldableSection.Body>
      </FoldableSection>
    ));

    setSections(_sectionElements);
  }, [data, attributeConfig, unlockedFields]);

  return <>{sections}</>;
}

export default CustomAttributeSection;

const convertValueToArray = (value: any): string[] => {
  try {
    // Check if its an arary of strings
    if (Array.isArray(value) && value.every((v) => typeof v === "string")) {
      return value;
    }
    return [];
  } catch (error) {
    return [];
  }
};

const OtherAttributesDropdown: React.FC<{
  cell: IConfigWithId;
  cellValue: any;
  onSave: OnSaveType;
  disabled?: boolean;
}> = ({ cell, cellValue, onSave, disabled = false }) => {
  const _options = cell?.tableOptions?.map((option) => {
    return {
      value: option.value,
      label: option.value,
      color: option?.color,
      valueAndDescription: `${option.value}${
        option.description != null && option.description !== "" ? " - " + option.description : ""
      }`,
    };
  });
  const hasColor = _options?.some((opt) => opt?.color && opt.color?.length > 0);

  return (
    <McDropdown
      disabled={disabled}
      currentValue={_options?.find((option) => option.value === cellValue)}
      onClear={cellValue != null ? () => onSave(cellValue, null, cell.fieldId) : undefined}
      options={_options}
      displayKey="valueAndDescription"
      selectedDisplayKey="value"
      onChange={(_, newValue) => onSave(cellValue, newValue.value, cell.fieldId)}
      type={hasColor ? "colordot" : "text"}
    />
  );
};
