import React from "react";
import { Button, ButtonGroup } from "reactstrap";
import { TDimension } from "../../../../types/global.types";
import { DimensionEnums } from "../../frontendConstants";

const DimensionSelector: React.FC<{
  activeDimension: TDimension;
  setActiveDimension: (dimension: TDimension) => void;
}> = ({ activeDimension, setActiveDimension }) => {
  return (
    <ButtonGroup style={{ width: "300px" }}>
      <Button
        active={activeDimension === DimensionEnums.TWODIMENSION}
        onClick={() => setActiveDimension(DimensionEnums.TWODIMENSION)}
      >
        {DimensionEnums.TWODIMENSION}
      </Button>
      <Button
        active={activeDimension === DimensionEnums.THEREEDIMENSION}
        onClick={() => setActiveDimension(DimensionEnums.THEREEDIMENSION)}
      >
        {DimensionEnums.THEREEDIMENSION}
      </Button>
    </ButtonGroup>
  );
};

export default DimensionSelector;
