import { z } from "zod";

const ZEnergiDataSeries = z.object({
  name: z.string(),
  data: z.array(z.number()),
  yAxis: z.number(),
  type: z.enum(["spline", "area"]), //col.field === "utetemp" ? "spline" : "area",
});

export const ZEnergiBrukPerTimme = z.object({
  utetemp: z.number(),
  rommoppv: z.number(),
  ventverme: z.number(),
  romkyl: z.number(),
  ventkyl: z.number(),
  effektbelysning: z.number(),
  effektutstyr: z.number(),
  effektvifter: z.number(),
  produksjonSolcell: z.number(),
});

export type IEnergiBrukPerTimme = z.infer<typeof ZEnergiBrukPerTimme>;
export const ZEnergiSchema = z.object({
  generellInfo: z.object({
    deloppgavenummer: z.string(),
    datoGenerert: z.date().optional(),
    generertAv: z.string().optional(),
    oppdragsNavn: z.string().optional(),
    oppdragsNummer: z.string().optional(),
    oppdragsGiver: z.object({
      navn: z.string(),
      kontaktPerson: z.string(),
    }),
    oppdragsLeder: z.string(),
  }),
});

export type IEnergiDataSeries = z.infer<typeof ZEnergiDataSeries>;

export type IEnergiSchema = z.infer<typeof ZEnergiSchema>;
