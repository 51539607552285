import { IGKRapport, IGKRecoveryTestResultater } from "mc-shared/zod/report/GK/gkReportSchema";
import { useEffect } from "react";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../../../types/global.types";
import { HSpace, McInput } from "../../../../../../../components";
import ReportDocxImageContainer from "../../../../ReportBoard/ReportDocxImageContainer";
import { beregnetSluttkonsentrasjon } from "./recoveryTestUtils";

const RecoveryTest: React.FC<{
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
  data: IGKRecoveryTestResultater;
}> = ({ data, onUpdateEditData }) => {
  useEffect(() => {
    utdataBeregninger();
  }, [data.inndata.maaleresultater, data.inndata.maaleresultater.length]);

  const addNewRow = () => {
    const newRecoveryTestMaalepunkt = {
      maalepunktNr: "",
      maaleresultatStart: null,
      maaleresultatSlutt: null,
      maaletid: null,
    };

    const updatedMaaleresultater = [...data.inndata.maaleresultater, newRecoveryTestMaalepunkt];

    onUpdateEditData("testResultater.recoveryTest.inndata.maaleresultater", updatedMaaleresultater);

    return updatedMaaleresultater;
  };

  const removeLastRow = () => {
    const updatedMaaleresultater = [...data.inndata.maaleresultater];

    if (updatedMaaleresultater.length > 0) {
      updatedMaaleresultater.pop(); // Remove the last element
      onUpdateEditData("testResultater.recoveryTest.inndata.maaleresultater", updatedMaaleresultater);
    }
  };

  const updateMaaleresultater = (resultat: string | number, i: number, parameter: string) => {
    const maaleresultater = [...data.inndata.maaleresultater];
    maaleresultater[i][parameter] = resultat;
    onUpdateEditData("testResultater.recoveryTest.inndata.maaleresultater", maaleresultater);
  };

  const utdataBeregninger = () => {
    if (data.inndata.maaleresultater.length === 0) {
      return;
    } else {
      const teoretiskUttynningstid = beregnetSluttkonsentrasjon(
        data.inndata.luftskifterIRommet,
        data.inndata.bakgrunnskonsentrasjonFoerTest,
        data.inndata.startkonsentrasjon,
      );
      const utdata = {
        teoretiskUttynningstid: teoretiskUttynningstid,
      };
      onUpdateEditData("testResultater.recoveryTest.utdata", utdata);
    }
  };

  return (
    <Card className="w-75">
      <CardBody>
        <CardTitle tag="h4">Recovery-test</CardTitle>
        <div className="d-flex flex-column">
          <b>
            Fyll inn bakgrunnskonsentrasjon før test &#40;antall/m<sup>3</sup>&#41;:
          </b>
          <McInput
            value={data.inndata.bakgrunnskonsentrasjonFoerTest}
            onBlur={(e) =>
              onUpdateEditData(
                "testResultater.recoveryTest.inndata.bakgrunnskonsentrasjonFoerTest",
                Number(e.target.value),
              )
            }
            normalizeNumbers={true}
            type="text"
            className="w-25"
          />
        </div>
        <div className="d-flex flex-column mt-4">
          <b>Fyll inn luftskifter i rommet &#40;ACH&#41;:</b>
          <McInput
            value={data.inndata.luftskifterIRommet}
            onBlur={(e) =>
              onUpdateEditData("testResultater.recoveryTest.inndata.luftskifterIRommet", Number(e.target.value))
            }
            normalizeNumbers={true}
            type="text"
            className="w-25"
          />
        </div>
        <div className="d-flex flex-column mt-4">
          <b>
            Fyll inn konsentrasjon ved start av test &#40;antall/m<sup>3</sup>&#41;:
          </b>
          <McInput
            value={data.inndata.startkonsentrasjon}
            onBlur={(e) =>
              onUpdateEditData("testResultater.recoveryTest.inndata.startkonsentrasjon", Number(e.target.value))
            }
            normalizeNumbers={true}
            type="text"
            className="w-25"
          />
        </div>
        <div className="mt-4">
          <b>Hvor mange målinger skal utføres? &#40;-&#41;:</b>
          <div className="d-flex">
            <Button color="success" onClick={addNewRow}>
              Legg til rad
            </Button>
            <HSpace />
            <Button color="danger" onClick={removeLastRow}>
              Fjern siste rad
            </Button>
          </div>
        </div>
        {data.inndata.maaleresultater.length > 0 && (
          <div className="mt-4 d-flex flex-column">
            <b>Fyll inn måleresultater:</b>
            <table>
              <thead>
                <tr>
                  <th>Målepunktnummer &#40;-&#41;</th>
                  <th>
                    Måleresultat ved start &#40;antall/m<sup>3</sup>&#41;
                  </th>
                  <th>
                    Måleresultat ved slutt &#40;antall/m<sup>3</sup>&#41;
                  </th>
                  <th>Måletid &#40;s&#41;</th>
                </tr>
              </thead>
              {data.inndata.maaleresultater.map((resultat, i) => (
                <tbody key={i}>
                  <tr>
                    <td>
                      <McInput
                        value={resultat.maalepunktNr}
                        type="text"
                        onChange={(e) => updateMaaleresultater(e.target.value, i, "maalepunktNr")}
                        placeholder="Skriv inn målepunktnummer"
                      />
                    </td>
                    <td>
                      <McInput
                        value={resultat.maaleresultatStart}
                        normalizeNumbers={true}
                        type="text"
                        onBlur={(e) => updateMaaleresultater(Number(e.target.value), i, "maaleresultatStart")}
                        placeholder="Skriv inn startverdi"
                      />
                    </td>
                    <td>
                      <McInput
                        value={resultat.maaleresultatSlutt}
                        normalizeNumbers={true}
                        type="text"
                        onBlur={(e) => updateMaaleresultater(Number(e.target.value), i, "maaleresultatSlutt")}
                        placeholder="Skriv inn sluttverdi"
                      />
                    </td>
                    <td>
                      <McInput
                        value={resultat.maaletid}
                        normalizeNumbers={true}
                        type="text"
                        onBlur={(e) => updateMaaleresultater(Number(e.target.value), i, "maaletid")}
                        placeholder="Skriv in måletid"
                      />
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
            <div className="d-flex justify-content-between">
              <p className="mb-0">
                Teoretisk uttynningstid &#40;t&#41; ={" "}
                <b>
                  {" "}
                  {beregnetSluttkonsentrasjon(
                    data.inndata.luftskifterIRommet,
                    data.inndata.bakgrunnskonsentrasjonFoerTest,
                    data.inndata.startkonsentrasjon,
                  )?.toFixed(2) ?? ""}
                </b>
              </p>
            </div>
          </div>
        )}
        {data.inndata.maaleresultater.length > 0 && (
          <div className="mt-4">
            <ReportDocxImageContainer docxImageTag="recoveryTest" title="Målepunkter i rommet" />
            <div className="w-50">
              <b>Kommentar:</b>
              <McInput
                value={data.inndata.kommentar}
                onChange={(e) => onUpdateEditData("testResultater.recoveryTest.inndata.kommentar", e.target.value)}
                type="textarea"
                dynamicHeight={true}
              />
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default RecoveryTest;
