import React from "react";

const NoAccess: React.FC = () => {
  return (
    <div>
      Du har ikke tilgang til prosjektet i Maconomy. Kontakt oppdragsansvarlig for å bli lagt til. Spørsmål?
      mcapps@multiconsult.no
    </div>
  );
};

export default NoAccess;
