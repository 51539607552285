import * as R from "ramda";
import React, { useEffect, useState } from "react";
import { IRisiko } from "../../../../../../types/global.types";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { setFiltredByChartOrMatrixRisikoIdsInRedux } from "../actions";
import { ICategoriesWithSameScoreColors } from "../types";
import {
  convertScoreMatrixToColorAndRisikosIds,
  createCircleChartDataSeries,
  getAcumulatedScoreMatrixes,
} from "../util";
import RisikoCategoryMatrixAndChartSingleItem from "./RisikoCategoryMatrixAndChartSingleItem";

const RisikoAcumlatedMatrixAndCharts: React.FC<{
  risikosInTable: IRisiko[];
}> = ({ risikosInTable }) => {
  const [acumulatedScoreMatrixes, setAcumulatedScoreMatrixes] = useState<ICategoriesWithSameScoreColors[]>();
  const [circleCharts, setCircleCharts] = useState(null);

  const dispatch = useAppDispatch();
  const activeBoard = useAppSelector((state) => state.risikoReducer.activeBoard);
  const categories = useAppSelector((state) => state.risikoReducer.categories);

  useEffect(() => {
    const _acumulatedScoreMatrixes = getAcumulatedScoreMatrixes(categories, risikosInTable, activeBoard);
    setAcumulatedScoreMatrixes(_acumulatedScoreMatrixes);
    convertMatrixesToCircleChartAndSetData(_acumulatedScoreMatrixes);
  }, [risikosInTable.length]);

  const convertMatrixesToCircleChartAndSetData = (_acumulatedScoreMatrixes: ICategoriesWithSameScoreColors[]) => {
    const _circleCharts = _acumulatedScoreMatrixes.map((matrix) => {
      return {
        name: matrix.name,
        chartData: createCircleChartDataSeries(convertScoreMatrixToColorAndRisikosIds(matrix)),
      };
    });
    setCircleCharts(_circleCharts);
  };

  const setChartColorItem = (name: string, color: string) => {
    const category = acumulatedScoreMatrixes.find((q) => q.name === name);
    const arr = category.data.filter((q) => q.color === color);
    const risikoIds = R.flatten(R.pluck("items", arr));
    dispatch(setFiltredByChartOrMatrixRisikoIdsInRedux(risikoIds));
  };

  return (
    <>
      {acumulatedScoreMatrixes != null &&
        acumulatedScoreMatrixes.map((category) => {
          return (
            <RisikoCategoryMatrixAndChartSingleItem
              key={category.name}
              scoreMatrix={{
                name: category.name,
                colHeaders: category.colHeaders,
                rowHeaders: category.rowHeaders,
                data: category.data,
              }}
              circleChart={circleCharts != null ? circleCharts.find((chart) => chart.name === category.name) : []}
              categoryName={category.name}
              setChartColorItem={setChartColorItem}
            />
          );
        })}
    </>
  );
};

export default RisikoAcumlatedMatrixAndCharts;
