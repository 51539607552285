import Axios from "axios";
import { IMaconomyProject, INotification, IUser } from "../../../types/global.types";

export const sendResetPassword = async (email: string) => {
  try {
    await Axios.put(`/api/reset-password/${email}`);
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetUserByPassRequestCode = async (code: string, userId): Promise<IUser> => {
  try {
    const resp = await Axios.get(`/api/password-reset/${userId}/${code}`);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doUpdatePasswordFromReset = async (code: string, userId, password: string) => {
  try {
    const user = await Axios.put(`/api/password-reset/${userId}/${code}`, { password });
    return user;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doToggleNotificationAsRead = async (notificationId: string): Promise<INotification> => {
  try {
    const resp = await Axios.put(`/api/user/notification/${notificationId}/toggle-as-read`);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doMarkAllNotificationsAsRead = async (): Promise<INotification[]> => {
  try {
    const resp = await Axios.put(`/api/user/notification/mark-all-as-read`);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doAcceptInvite = async (code: string, userId: string, password: string, name: string) => {
  try {
    const user = await Axios.put(`/api/accept-invite/${userId}/${code}`, { password, name });
    return user;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doLoginLocal = async (email: string, password: string): Promise<string> => {
  try {
    await Axios.post("/api/login-local", { email, password });
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetProjectsWhereUserIsAMemberInMcapps = async (): Promise<IMaconomyProject[]> => {
  try {
    const project = await Axios.get(`/api/user/projects/member`);
    return project.data === "" ? null : project.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetProjectsWhereUserIsAMemberInMaconomy = async (): Promise<IMaconomyProject[]> => {
  try {
    const project = await Axios.get(`/api/user/projects/maconomy`);
    return project.data === "" ? null : project.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};
