import React from "react";
import Switch from "react-switch";
import { HSpace } from "..";

const SwitchWithTitle: React.FC<{ title: string; onSave: (bool: boolean) => void; checked: boolean }> = ({
  title,
  onSave,
  checked,
}) => {
  return (
    <div className="d-flex">
      <Switch checked={checked} onChange={onSave} />
      <HSpace />
      {title != null ? <h5 className="mb-0">{title}</h5> : <></>}
    </div>
  );
};

export default SwitchWithTitle;
