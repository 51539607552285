import { ISJArapport } from "mc-shared/zod/report/Elektro/sjaSchema";
import * as R from "ramda";
import React from "react";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import { HSpace, McInput, VSpace } from "../../../../../components";

const SJADeltagere: React.FC<{
  editData: ISJArapport;
  updateData: (key: string, value: any) => void;
}> = ({ editData, updateData }) => {
  const updateDeltagere = (index: number, key: string, value: string) => {
    let deltagere = R.clone(editData.deltagere);
    deltagere[index][key] = value;
    updateData("deltagere", deltagere);
  };

  const addNewRow = () => {
    const newDeltager = {
      navn: "",
      avdSeksjon: "",
    };

    const updatedDeltagere = [...editData.deltagere, newDeltager];

    updateData("deltagere", updatedDeltagere);

    return updatedDeltagere;
  };

  const removeRow = (index: number) => {
    const updatedDeltagere = [...editData.deltagere];

    updatedDeltagere.splice(index, 1);
    updateData("deltagere", updatedDeltagere);
  };

  return (
    <Card className="w-50">
      <CardBody>
        <CardTitle tag="h4">Deltagere</CardTitle>
        <div>
          <Button color="success" onClick={addNewRow}>
            Legg til deltager
          </Button>
        </div>
        <VSpace />
        <table className="reportTable">
          <thead>
            <tr>
              <th style={{ width: "50%" }}>Navn</th>
              <HSpace />
              <th style={{ width: "50%" }}>Avd/Seksjon</th>
              <th style={{ width: "20%" }}></th>
            </tr>
          </thead>
          <tbody>
            {editData.deltagere?.map((deltager, index) => (
              <tr key={index}>
                <td>
                  <McInput value={deltager?.navn} onChange={(e) => updateDeltagere(index, "navn", e.target.value)} />
                </td>
                <HSpace />
                <td>
                  <McInput
                    value={deltager?.avdSeksjon}
                    onChange={(e) => updateDeltagere(index, "avdSeksjon", e.target.value)}
                  />
                </td>
                <td>
                  <Button onClick={() => removeRow(index)} color="danger" outline size="sm">
                    <i className="fa fa-trash fa-fw" />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </CardBody>
    </Card>
  );
};

export default SJADeltagere;
