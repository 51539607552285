import React from "react";
import { apiSlice } from "../../../apiSlice";
import { FailedAlert, MCLoader } from "../../../components";
import NewDocxUpload from "../../../components/DocxReport/NewDocxUpload/NewDocxUpload";
import FileTable from "../../../components/FileUploader/FileTable";
import { setShowSuccessMessageThunk } from "../../../global/globalSlice";
import { useDeleteAzFileMutation, useGetOfficalMulticonsultMeetingTempaltesQuery } from "../../../globalRTK";
import { useAppDispatch } from "../../../hooks";
import { doUploadOfficialMeetingDocxTemplate, downloadAzureBlobFile } from "../admin/restQueries";

export const OfficialMeetingTemplateUpload: React.FC = () => {
  const dispatch = useAppDispatch();

  const upload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    await doUploadOfficialMeetingDocxTemplate(e);
    dispatch(apiSlice.util.invalidateTags(["Global"]));
    if (e.target != null) {
      e.target.value = null;
    }
    dispatch(setShowSuccessMessageThunk("Malen er lastet opp"));
  };

  const {
    data: meetingTemplates = [],
    isLoading,
    isError: getMeetingTemplatesError,
  } = useGetOfficalMulticonsultMeetingTempaltesQuery("");

  const [deleteOfficialTemplate, { isError: deleteOfficalTemplateError }] = useDeleteAzFileMutation();

  const isError = deleteOfficalTemplateError || getMeetingTemplatesError;

  return (
    <div>
      {isLoading === true && <MCLoader />}
      {isError === true && <FailedAlert />}
      <NewDocxUpload
        docxTemplates={[]}
        uploadCompleedCb={() => alert("File successfully uploaded")}
        uploadFunc={upload}
        demoData={{ name: "hello" }}
      />
      <hr />
      <FileTable
        downloadFile={(blobName, fileName) => downloadAzureBlobFile(blobName, fileName)}
        files={meetingTemplates}
        deleteFile={(blobName) => deleteOfficialTemplate(blobName)}
      />
    </div>
  );
};
