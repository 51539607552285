import React from "react";
import { IFileAgGrid, IFolderAgGrid, IValueLabel } from "../../../../types/global.types";
import HSpace from "../HSpace";
import FileTableAgGrid from "./FileTableAgGrid";
import FolderSelector from "./FolderSelector";

const FolderAndFileSelector: React.FC<{
  folders: IFolderAgGrid[];
  files: IFileAgGrid[];
  goBack: () => void;
  currentFolder?: IFolderAgGrid;
  getSelectedFilesCb: (files: IFileAgGrid[]) => void;
  onSelectActiveFolder: (folder: IFolderAgGrid) => void;
  onGoToNextFolder: (folder: IFolderAgGrid) => void;
  filterExtension?: IValueLabel;
  highlightedFolder?: IFolderAgGrid;
  canGoBack: boolean;
}> = ({
  folders,
  files,
  goBack,
  currentFolder,
  getSelectedFilesCb,
  onSelectActiveFolder,
  onGoToNextFolder,
  filterExtension,
  highlightedFolder,
  canGoBack,
}) => {
  return (
    <div className="d-flex h-100 w-100 overflow-auto">
      <FolderSelector
        currentFolder={currentFolder}
        folders={folders}
        onSelectActiveFolder={onSelectActiveFolder}
        onGoToNextFolder={onGoToNextFolder}
        highlightedFolder={highlightedFolder}
        goBack={goBack}
        canGoBack={canGoBack}
      />
      <HSpace />
      <FileTableAgGrid files={files} getSelectedFilesCb={getSelectedFilesCb} filterExtension={filterExtension} />
    </div>
  );
};

export default FolderAndFileSelector;
