const PowerBIDoc = () => {
  return (
    <>
      <h3 className={"mb-5"}>Koble Power BI til MCBIM-database</h3>
      <h4 className="mb-3 mt-5">
        <div className="textCircle">1</div> Laste data fra MongoDB til Power BI
      </h4>
      <div style={{ marginLeft: "3em" }}>
        <p>
          Start PowerBI, velg <b>Hent data</b> og <b>Mer…</b>. Søk opp <b>odbc</b>. Velg denne og trykk <b>Koble til</b>
          .
        </p>
        <img
          src="images/PowerBI/image004.png"
          alt="PowerBI-4"
          className="mb-3"
          style={{ height: "400px", boxShadow: "9px 10px 15px -10px #363a5155" }}
        />
        <img
          src="images/PowerBI/image005.png"
          alt="PowerBI-5"
          className="mb-3"
          style={{ height: "400px", boxShadow: "9px 10px 15px -10px #363a5135" }}
        />
        <img
          src="images/PowerBI/image006.png"
          alt="PowerBI-6"
          className="mb-3"
          style={{ width: "600px", boxShadow: "9px 10px 15px -10px #363a5135" }}
        />
        <p>
          Velg Datakilden (DNS) som er laget. Trykk <b>OK</b>.{" "}
        </p>
        <p>Legg inn brukernavn &lt;oppdragsnummer&gt; og passord.</p>
        <img
          src="images/PowerBI/image007.png"
          alt="PowerBI-7"
          className="mb-3"
          style={{ width: "600px", boxShadow: "9px 10px 15px -10px #363a5135" }}
        />
        <p>
          Klikk på <b>mcbim</b> velg tabellen (med checkbox) og trykk <b>Last inn</b>.
        </p>
        <img
          src="images/PowerBI/image008.png"
          alt="PowerBI-8"
          className="mb-3"
          style={{ width: "600px", boxShadow: "9px 10px 15px -10px #363a5135" }}
        />
        <p>Data lastes inn. Dette kan ta noe tid avhengig av antall IFC-filer, versjoner og egenskaper.</p>
        <img
          src="images/PowerBI/image009.png"
          alt="PowerBI-9"
          className="mb-3"
          style={{ width: "400px", boxShadow: "9px 10px 15px -10px #363a5125" }}
        />
        <p>Power BI er nå klar til bruk:</p>
        <img
          src="images/PowerBI/image010.png"
          alt="PowerBI-10"
          className="mb-3"
          style={{ width: "600px", boxShadow: "9px 10px 15px -10px #363a5155" }}
        />
      </div>
    </>
  );
};

export default PowerBIDoc;
