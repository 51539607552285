import React from "react";
import { useHistory } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { IMeeting } from "../../../../../../../types/meeting.types";
import {
  ConfirmDialog,
  FailedAlert,
  IconToggle,
  McDropdown,
  McDropdownSingleUser,
  McInput,
  McTitle,
  VSpace,
} from "../../../../../components";
import { useAppSelector } from "../../../../../hooks";
import { STANDARD_COLORS } from "../../../../globalConstants";
import { getProjectIdFromUrl } from "../../../../utils";
import { useGetIssueBoardsQuery } from "../../../issuesApp/IssueBoardRTK";
import { useUpdateMeetingMutation } from "../../meetingRTK";

const MeetingInfo: React.FC<{ singleMeeting: IMeeting.MeetingPopulated }> = ({ singleMeeting }) => {
  const history = useHistory();
  const projectId = getProjectIdFromUrl(history.location.pathname);
  const project = useAppSelector((state) => state.adminReducer.project);

  const { data: issueBoards = [] } = useGetIssueBoardsQuery({
    projectId: projectId,
  });

  const onDelete = () => {
    updateMeeting({
      meetingId: singleMeeting._id,
      projectId,
      attribute: "isDeleted",
      value: true,
    });
    history.push(`/project/${projectId}/meetings`);
  };

  const [updateMeeting, { isError: updateMeetingError }] = useUpdateMeetingMutation();

  const onChange = (attribute: keyof IMeeting.MeetingPopulated, value: string | boolean | Date) => {
    updateMeeting({
      meetingId: singleMeeting._id,
      projectId,
      attribute,
      value,
    });
  };

  return (
    <div className="d-flex flex-column p-5" style={{ width: "500px" }}>
      <Card>
        <CardBody>
          {updateMeetingError === true && <FailedAlert />}
          <McTitle title="Velg dato for møte">
            <McInput
              type="date"
              onChange={(t) => onChange("dateOfMeeting", t.target.value.toString() as any)}
              value={singleMeeting.dateOfMeeting.toString()}
              placeholder="Skriv inn dato for møte"
            />
          </McTitle>
          <VSpace />
          <McTitle title="Starttid for møte">
            <McInput
              type="time"
              onBlur={(e) => onChange("timeStart", e.target.value)}
              value={singleMeeting.timeStart}
            />
          </McTitle>
          <VSpace />
          <McTitle title="Møtelokasjon">
            <McInput
              type="text"
              onBlur={(t) => onChange("location", t.target.value as any)}
              value={singleMeeting.location}
              placeholder="Skriv inn lokasjon for møte"
            />
          </McTitle>
          <VSpace />
          <McTitle title="Eier for møte">
            <McDropdownSingleUser
              onChange={(user) => onChange("owner", user?._id)}
              filterActive={project.members?.length > 10}
              options={
                (singleMeeting?.participants as IMeeting.ParticipantsPopulated[]).map(
                  (participant) => participant.user,
                ) ?? []
              }
              selected={singleMeeting.owner}
            />
          </McTitle>
          <McTitle title="Referent">
            <McDropdownSingleUser
              onChange={(user) => onChange("referent", user?._id)}
              filterActive={project.members?.length > 10}
              options={
                (singleMeeting?.participants as IMeeting.ParticipantsPopulated[]).map(
                  (participant) => participant.user,
                ) ?? []
              }
              selected={singleMeeting.referent}
            />
          </McTitle>
          <VSpace />
          <McTitle title="Navn på møte">
            <McInput
              type="text"
              onBlur={(t) => onChange("name", t.target.value as any)}
              value={singleMeeting.name}
              placeholder="Skriv inn navn på møte"
            />
          </McTitle>
          <VSpace />
          <McTitle title="Hensikten med møte - Linjeskift - (Fylles ut ved behov)">
            <McInput
              dynamicHeight
              type="textarea"
              onBlur={(t) => onChange("description", t.target.value as any)}
              value={singleMeeting.description}
              placeholder="Skriv inn beskrivelse for møte"
            />
          </McTitle>
          <VSpace />
          <McTitle title="Standard saksregister">
            <McDropdown
              disabled={issueBoards.length === 0}
              onClear={singleMeeting.defaultIssueBoard != null ? () => onChange("defaultIssueBoard", null) : null}
              currentValue={singleMeeting.defaultIssueBoard}
              onChange={(oldVal, newVal) => onChange("defaultIssueBoard", newVal._id)}
              options={issueBoards.map((board) => ({ name: board.name, _id: board._id })) ?? []}
              displayKey="name"
            />
          </McTitle>
          <VSpace /> <hr />
          <ConfirmDialog title="Slette møtet?" message="Er du sikker på at du vil slette møtet?" confirmCb={onDelete}>
            <IconToggle
              toggled
              activeIcon="trash"
              inactiveIcon="trash"
              activeColor={STANDARD_COLORS.red}
              onClick={() => {}}
            />
          </ConfirmDialog>
        </CardBody>
      </Card>
    </div>
  );
};

export default MeetingInfo;
