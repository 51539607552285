import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Modal, ModalBody, ModalHeader } from "reactstrap";
import { IChecklistItemPopulated, IChecklistItemRow, IStampSettings } from "../../../../../../types/checklist.types";
import { IHistoryLogDepricated, Paths, PathValue } from "../../../../../../types/global.types";
import { ConfirmDialog, IconToggle } from "../../../../components";
import HistoryLogsDepricated from "../../../../components/HistoryLogsDepricated";
import { AppModalEnums } from "../../../../frontendConstants";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { STANDARD_COLORS } from "../../../globalConstants";

import { ColDef } from "ag-grid-community";
import { doGetChecklistRowLogs } from "../checklistAPI";
import {
  deleteChecklistCategoryThunk,
  deleteChecklistItemThunk,
  updateChecklistCategoryThunk,
} from "../checklistSlice";
import ChecklistDetails from "./ChecklistDetails/ChecklistDetails";
import { addAppModalThunk, setShowSuccessMessageThunk } from "../../../../global/globalSlice";

const ChecklistModal: React.FC<{
  checklistRow: IChecklistItemRow;
  editable: boolean;
  columns: ColDef[];
  stampSettings: IStampSettings;
  closeIssueItemModal: () => void;
  updateRowAttribute: <P extends Paths<IChecklistItemPopulated, 4>>(
    rowId: string,
    path: P,
    oldValue: PathValue<IChecklistItemPopulated, P>,
    newValue: PathValue<IChecklistItemPopulated, P>,
  ) => void;
}> = ({ checklistRow, editable, columns, closeIssueItemModal, updateRowAttribute, stampSettings }) => {
  const [selectedTab, setSelectedTab] = useState<string>("info");
  const [isItem, setIsRow] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const project = useAppSelector((state) => state.adminReducer.project);
  const checklist = useAppSelector((state) => state.checklistReducer.checklist);
  const isMcTemplate = project?.maconomy?.projectNo === 100;

  const removeItem = async () => {
    dispatch(deleteChecklistItemThunk(checklistRow._id));
    closeIssueItemModal();
  };

  const removeCategory = async () => {
    dispatch(deleteChecklistCategoryThunk(checklistRow._id));
    closeIssueItemModal();
  };

  const getLogs = async (projectId: string, itemId: string): Promise<IHistoryLogDepricated[]> => {
    return await doGetChecklistRowLogs(projectId, checklistRow.originalCategory.checklist.toString(), itemId);
  };

  const onAssignedToChange = async <T extends keyof IChecklistItemRow>(
    parameter: string,
    newValue: IChecklistItemRow[T],
    oldValue: string,
  ) => {
    dispatch(updateChecklistCategoryThunk(checklistRow._id, parameter, newValue, oldValue));
    dispatch(setShowSuccessMessageThunk("Sjekklistekategori oppdatert"));
  };

  const onOpenIssue = async (props: { itemId: string; boardId: string; app: AppModalEnums }) => {
    const { itemId, boardId, app } = props;

    await dispatch(
      addAppModalThunk({
        itemId,
        boardId,
        projectId: project._id,
        app,
      }),
    );
  };

  useEffect(() => {
    if (checklistRow.originalItem != null) {
      setIsRow(true);
    } else if (checklistRow.originalCategory != null) {
      setIsRow(false);
    }
  }, [checklistRow]);

  return (
    <Modal style={{ maxWidth: "1000px", width: "80%" }} isOpen toggle={closeIssueItemModal}>
      <ModalHeader toggle={() => closeIssueItemModal()}>
        <div className="truncateHeaderText">
          {isItem === true
            ? `${checklistRow.rowId}: ${checklistRow.title}`
            : `${checklistRow.rowId}: ${checklistRow.originalCategory.title}`}
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="d-flex flex-column pr-4 pl-9 mr-4">
          <div className="d-flex mb-4">
            <ButtonGroup style={{ maxWidth: "14rem" }} className="w-100 mr-2">
              <Button
                color={selectedTab === "info" ? "primary" : "secondary"}
                onClick={() => setSelectedTab("info")}
                active={selectedTab === "info"}
              >
                Info
              </Button>
              <Button
                color={selectedTab === "logs" ? "primary" : "secondary"}
                onClick={() => setSelectedTab("logs")}
                active={selectedTab === "logs"}
              >
                Logg
              </Button>
            </ButtonGroup>
            <div className="flex-fill" />
            {editable && isItem === true && (
              <ConfirmDialog confirmCb={removeItem} message={`Vil du virkelig slette rad??`} title={`Slett rad`}>
                <IconToggle
                  activeIcon="trash"
                  inactiveIcon="trash"
                  activeColor={STANDARD_COLORS.orange}
                  toggled={false}
                  onClick={() => {}}
                />
              </ConfirmDialog>
            )}
            {editable && isItem === false && (
              <ConfirmDialog
                confirmCb={removeCategory}
                message={`Vil du virkelig slette kategori og alle rader?`}
                title={`Fjern kategori`}
              >
                <IconToggle
                  activeIcon="trash"
                  inactiveIcon="trash"
                  activeColor={STANDARD_COLORS.orange}
                  toggled={false}
                  onClick={() => {}}
                />
              </ConfirmDialog>
            )}
          </div>
        </div>
        <div className="d-flex flex-column pr-4 pl-4">
          {selectedTab === "info" && (
            <ChecklistDetails
              updateRowAttribute={updateRowAttribute}
              onAssignedToChange={onAssignedToChange}
              checklistRow={checklistRow}
              isItem={isItem}
              isTemplate={checklist.isTemplate}
              editable={editable}
              onOpenIssue={onOpenIssue}
              stampSettings={stampSettings}
            />
          )}
          {selectedTab === "logs" && (
            <HistoryLogsDepricated
              projectId={isMcTemplate === true ? project._id : checklistRow.originalCategory.project.toString()}
              itemId={checklistRow._id}
              modelColumns={columns.map((column) => ({ dataField: column.field, text: column.headerName }))}
              getLogs={getLogs}
            />
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ChecklistModal;
