import { IValueLabelGeneric } from "../../../types/global";

export enum RomEllerUtstyrEnum {
  ROM = "Rom",
  UTSTYR = "Utstyr",
  LAFTAK = "Årskontroll LAF-tak",
}

export enum ValideringEnum {
  GMP_KVALIFISERING = "GMP-Kvalifisering",
  GMP_REKVALIFISERING = "GMP-Rekvalifisering",
  ISO_KVALIFISERING = "ISO-Kvalifisering",
}

export enum LuftmoensterEnum {
  LAMINAERT = "Laminært",
  TURBULENT = "Turbulent",
}

export enum RomAktivitetEnum {
  ATREST = "At rest",
  INOPERATION = "In operation",
  ASBUILT = "As built",
}

export enum TestTypeRomEnum {
  KONTROLLAVROMTRYKK = "kontrollAvRomtrykk",
  PARTIKKELTELLINGROM = "partikkelTellingIRom",
  LUFTMENGDEMAALINGIROM = "luftmengdeMaalingIRom",
  LEKKASJESKANNINGHAVHEPA = "lekkasjeskanningAvHepa",
  RECOVERYTEST = "recoveryTest",
  LUFTHASTIGHETSKONTROLL = "lufthastighetskontroll",
  INNESLUTNINGSLEKKASJETEST = "inneslutningsLekkasjeTestMedFotometer",
  KONTROLLAVLUFTSTROEM = "kontrollAvLuftstroem",
  TEMPERATURMAALING = "temperaturmaaling",
  RELATIVLUFTFUKTIGHETSMAALING = "relativFuktighetsmaaling",
  DEKONTAMINERINGAVROM = "dekontamineringAvRom",
}

export const GK_RAPPORT_TEST_TYPES_ROM: IValueLabelGeneric<TestTypeRomEnum>[] = [
  {
    label: "Kontroll av romtrykk",
    value: TestTypeRomEnum.KONTROLLAVROMTRYKK,
  },
  {
    label: "Kontroll av luftmønster",
    value: TestTypeRomEnum.KONTROLLAVLUFTSTROEM,
  },
  {
    label: "Partikkeltelling i rom",
    value: TestTypeRomEnum.PARTIKKELTELLINGROM,
  },
  {
    label: "Luftmengdemåling i rom",
    value: TestTypeRomEnum.LUFTMENGDEMAALINGIROM,
  },
  {
    label: "Lekkasjeskanning av HEPA-filter",
    value: TestTypeRomEnum.LEKKASJESKANNINGHAVHEPA,
  },
  {
    label: "Recovery-test",
    value: TestTypeRomEnum.RECOVERYTEST,
  },
  {
    label: "Lufthastighetskontroll/måling",
    value: TestTypeRomEnum.LUFTHASTIGHETSKONTROLL,
  },
  {
    label: "Inneslutningslekkasjetest",
    value: TestTypeRomEnum.INNESLUTNINGSLEKKASJETEST,
  },
  {
    label: "Temperaturmåling",
    value: TestTypeRomEnum.TEMPERATURMAALING,
  },
  {
    label: "Relativ luftfuktighetsmåling",
    value: TestTypeRomEnum.RELATIVLUFTFUKTIGHETSMAALING,
  },
  {
    label: "Dekontaminering av rom",
    value: TestTypeRomEnum.DEKONTAMINERINGAVROM,
  },
];

// TESTER TILGJENGELIG FOR GMP-KVALIFISERING AV ROM LAMINÆR LUFTSTRØM//
export const GK_RAPPORT_TEST_TYPES_GMP_KVALIFISERING_ROM_LAMINAER: IValueLabelGeneric<TestTypeRomEnum>[] = [
  {
    label: "Kontroll av romtrykk",
    value: TestTypeRomEnum.KONTROLLAVROMTRYKK,
  },
  {
    label: "Partikkeltelling i rom",
    value: TestTypeRomEnum.PARTIKKELTELLINGROM,
  },
  {
    label: "Luftmengdemåling i rom",
    value: TestTypeRomEnum.LUFTMENGDEMAALINGIROM,
  },
  {
    label: "Lekkasjeskanning av HEPA-filter",
    value: TestTypeRomEnum.LEKKASJESKANNINGHAVHEPA,
  },
  {
    label: "Lufthastighetskontroll/måling",
    value: TestTypeRomEnum.LUFTHASTIGHETSKONTROLL,
  },
  {
    label: "Kontroll av luftmønster",
    value: TestTypeRomEnum.KONTROLLAVLUFTSTROEM,
  },
  {
    label: "Temperaturmåling",
    value: TestTypeRomEnum.TEMPERATURMAALING,
  },
  {
    label: "Relativ luftfuktighetsmåling",
    value: TestTypeRomEnum.RELATIVLUFTFUKTIGHETSMAALING,
  },
  {
    label: "Inneslutningslekkasjetest",
    value: TestTypeRomEnum.INNESLUTNINGSLEKKASJETEST,
  },
  {
    label: "Dekontaminering av rom",
    value: TestTypeRomEnum.DEKONTAMINERINGAVROM,
  },
];

// TESTER TILGJENGELIG FOR GMP-KVALIFISERING AV ROM TURBULENT LUFTSTRØM//
export const GK_RAPPORT_TEST_TYPES_GMP_KVALIFISERING_ROM_TURBULENT: IValueLabelGeneric<TestTypeRomEnum>[] = [
  {
    label: "Kontroll av romtrykk",
    value: TestTypeRomEnum.KONTROLLAVROMTRYKK,
  },
  {
    label: "Partikkeltelling i rom",
    value: TestTypeRomEnum.PARTIKKELTELLINGROM,
  },
  {
    label: "Luftmengdemåling i rom",
    value: TestTypeRomEnum.LUFTMENGDEMAALINGIROM,
  },
  {
    label: "Lekkasjeskanning av HEPA-filter",
    value: TestTypeRomEnum.LEKKASJESKANNINGHAVHEPA,
  },
  {
    label: "Recovery-test",
    value: TestTypeRomEnum.RECOVERYTEST,
  },
  {
    label: "Lufthastighetskontroll",
    value: TestTypeRomEnum.LUFTHASTIGHETSKONTROLL,
  },
  {
    label: "Kontroll av luftmønster",
    value: TestTypeRomEnum.KONTROLLAVLUFTSTROEM,
  },
  {
    label: "Temperaturmåling",
    value: TestTypeRomEnum.TEMPERATURMAALING,
  },
  {
    label: "Relativ luftfuktighetsmåling",
    value: TestTypeRomEnum.RELATIVLUFTFUKTIGHETSMAALING,
  },
  {
    label: "Inneslutningslekkasjetest",
    value: TestTypeRomEnum.INNESLUTNINGSLEKKASJETEST,
  },
  {
    label: "Dekontaminering av rom",
    value: TestTypeRomEnum.DEKONTAMINERINGAVROM,
  },
];

// TESTER TILGJENGELIG FOR GMP-REKVALIFISERING AV ROM MED LAMINÆR LUFTSTRØM//
export const GK_RAPPORT_TEST_TYPES_GMP_REKVALIFISERING_ROM_LAMINAER: IValueLabelGeneric<TestTypeRomEnum>[] = [
  {
    label: "Kontroll av romtrykk",
    value: TestTypeRomEnum.KONTROLLAVROMTRYKK,
  },
  {
    label: "Partikkeltelling i rom",
    value: TestTypeRomEnum.PARTIKKELTELLINGROM,
  },
  {
    label: "Luftmengdemåling i rom",
    value: TestTypeRomEnum.LUFTMENGDEMAALINGIROM,
  },
  {
    label: "Lekkasjeskanning av HEPA-filter",
    value: TestTypeRomEnum.LEKKASJESKANNINGHAVHEPA,
  },
  {
    label: "Lufthastighetskontroll/måling",
    value: TestTypeRomEnum.LUFTHASTIGHETSKONTROLL,
  },
  {
    label: "Kontroll av luftmønster",
    value: TestTypeRomEnum.KONTROLLAVLUFTSTROEM,
  },
];

// TESTER TILGJENGELIG FOR GMP-REKVALIFISERING AV ROM TURBULENT LUFTSTRØM//
export const GK_RAPPORT_TEST_TYPES_GMP_REKVALIFISERING_ROM_TURBULENT: IValueLabelGeneric<TestTypeRomEnum>[] = [
  {
    label: "Kontroll av romtrykk",
    value: TestTypeRomEnum.KONTROLLAVROMTRYKK,
  },
  {
    label: "Partikkeltelling i rom",
    value: TestTypeRomEnum.PARTIKKELTELLINGROM,
  },
  {
    label: "Luftmengdemåling i rom",
    value: TestTypeRomEnum.LUFTMENGDEMAALINGIROM,
  },
  {
    label: "Lekkasjeskanning av HEPA-filter",
    value: TestTypeRomEnum.LEKKASJESKANNINGHAVHEPA,
  },
  {
    label: "Recovery-test",
    value: TestTypeRomEnum.RECOVERYTEST,
  },
  {
    label: "Kontroll av luftstrøm",
    value: TestTypeRomEnum.KONTROLLAVLUFTSTROEM,
  },
];

// TESTER TILGJENGELIG FOR ISO-KVALIFISERING AV ROM MED LAMINÆR LUFTSTRØM //
export const GK_RAPPORT_TEST_TYPES_ISO_KVALIFISERING_ROM_LAMINAER: IValueLabelGeneric<TestTypeRomEnum>[] = [
  {
    label: "Kontroll av romtrykk",
    value: TestTypeRomEnum.KONTROLLAVROMTRYKK,
  },
  {
    label: "Partikkeltelling i rom",
    value: TestTypeRomEnum.PARTIKKELTELLINGROM,
  },
  {
    label: "Lekkasjeskanning av HEPA-filter",
    value: TestTypeRomEnum.LEKKASJESKANNINGHAVHEPA,
  },
  {
    label: "Kontroll av luftmønster",
    value: TestTypeRomEnum.KONTROLLAVLUFTSTROEM, //Kontroll av luftmønster
  },
  {
    label: "Temperaturmåling",
    value: TestTypeRomEnum.TEMPERATURMAALING,
  },
  {
    label: "Relativ luftfuktighetsmåling",
    value: TestTypeRomEnum.RELATIVLUFTFUKTIGHETSMAALING,
  },
  {
    label: "Inneslutningslekkasjetest",
    value: TestTypeRomEnum.INNESLUTNINGSLEKKASJETEST,
  },
  {
    label: "Lufthastighetskontroll/måling",
    value: TestTypeRomEnum.LUFTHASTIGHETSKONTROLL,
  },
  {
    label: "Dekontaminering av rom",
    value: TestTypeRomEnum.DEKONTAMINERINGAVROM,
  },
];

// TESTER TILGJENGELIG FOR ISO-KVALIFISERING AV ROM MED TURBULENT LUFTSTRØM //
export const GK_RAPPORT_TEST_TYPES_ISO_KVALIFISERING_ROM_TURBULENT: IValueLabelGeneric<TestTypeRomEnum>[] = [
  {
    label: "Kontroll av romtrykk",
    value: TestTypeRomEnum.KONTROLLAVROMTRYKK,
  },
  {
    label: "Partikkeltelling i rom",
    value: TestTypeRomEnum.PARTIKKELTELLINGROM,
  },
  {
    label: "Luftmengdemåling i rom",
    value: TestTypeRomEnum.LUFTMENGDEMAALINGIROM,
  },
  {
    label: "Lekkasjeskanning av HEPA-filter",
    value: TestTypeRomEnum.LEKKASJESKANNINGHAVHEPA,
  },
  {
    label: "Recovery-test",
    value: TestTypeRomEnum.RECOVERYTEST,
  },
  {
    label: "Kontroll av luftstrøm",
    value: TestTypeRomEnum.KONTROLLAVLUFTSTROEM,
  },
  {
    label: "Temperaturmåling",
    value: TestTypeRomEnum.TEMPERATURMAALING,
  },
  {
    label: "Relativ luftfuktighetsmåling",
    value: TestTypeRomEnum.RELATIVLUFTFUKTIGHETSMAALING,
  },
  {
    label: "Inneslutningslekkasjetest",
    value: TestTypeRomEnum.INNESLUTNINGSLEKKASJETEST,
  },
  {
    label: "Dekontaminering av rom",
    value: TestTypeRomEnum.DEKONTAMINERINGAVROM,
  },
];

export enum TestTypeUtstyrEnum {
  PARTIKKELTELLINGUTSTYR = "partikkelTellingForUtstyr",
  KONTROLLAVLUFTSTROEMVEDARBEIDSAAPNING = "kontrollAvLuftstroemVedArbeidsaapning",
  LUFTMENGDEMAALINGGJENNOMARBEIDSAAPNING = "luftmengdemaalingGjennomArbeidsaapning",
  LUFTHASTIGHETSMAALINGOGDOKUAVLAMINAERLUFTSTROEM = "lufthastighetsmaalingOgDokuAvLaminaerLuftstroem",
  BEREGNINGAVLUFTHASTIGHETGJENNOMARBEIDSAAPNING = "beregningAvLufthastighetGjennomArbeidsaapning",
  MAALINGAVTRYKKFALLOVERHEPAFILTER = "maalingAvTrykkfallOverHepafilter",
  KONTROLLAVALARM = "kontrollAvAlarm",
  LEKKASJESKANNINGHAVHEPA = "lekkasjeskanningAvHepa",
  BRUDDTEST = "bruddtest",
  MAALINGAVLUFTMENGDEGJENNOMINNTAKSFILTER = "maalingAvLuftmengdeGjennomInntaksfilter",
  MAALINGAVARBEIDSTRYKKOGKONTROLLAVINTERNTRYKKMAALER = "maalingAvArbeidstrykkOgKontrollAvInternTrykkmaaler",
  LUFTHASTIGHETSMAALINGIARBEIDSOMRAADE = "lufthastighetsMaalingIArbeidsomraade",
  MAALINGAVINNSTROEMNINGSHASTIGHET = "maalingAvInnstroemningsHastighet",
  MAALINGAVDRIFTSTRYKK = "maalingAvDriftstrykk",
  LEKKASJETEST = "lekkasjetest",
  MAALINGAVLUFTHASTIGHETILUKEAAPNINGRUTINEKONTROLL = "maalingAvLufthastighetILukeaapningRutinekontroll",
  MAALINGAVAVTREKKSMENGDERUTINEKONTROLL = "maalingAvAvtrekksmengdeRutinekontroll",
  TRYKKFALLIAVTREKKSSKAPET = "trykkfallIAvtrekksskap",
  VISUALISERINGAVLUFTSTROEM = "visualiseringAvLuftstroem",
  TESTAVALARMSYSTEMER = "testAvAlarmsystemer",
  TESTAVSKAPREGULERINGENSRESPONSTID = "testAvSkapreguleringensResponstid",
  MAALINGAVLUFTHASTIGHETILUKEAAPNINGIGANGKJOERING = "maalingAvLufthastighetILukeaapningIgangkjoering",
  MAALINGAVAVTREKKSMENGDEIGANGKJOERING = "maalingAvAvtrekksmengdeIgangkjoering",
  MAALINGAVLUFTHASTIGHETIROMMET = "maalingAvLufthastighetIRommet",
  MAALINGAVLYDTRYKK = "maalingAvLydtrykk",
  MAALINGAVLUFTHASTIGHETPUNKTAVSUG = "maalingAvLufthastighetPunktavsug",
  MAALINGAVTRYKKPUNKTAVSUG = "maalingAvTrykkPunktavsug",
  VISUALISERINGMEDROEYKPUNKTAVSUG = "visualiseringMedRoeykPunktavsug",
  EKSTERNOGINTERNROEYKVISUALISERING = "eksternOgInternRoeykVisualisering",
  DEKONTAMINERINGAVSIKKERHETSKABINETT = "dekontamineringAvSikkerhetskabinett",
  FUNKSJONSKONTROLLSIKKERHETSKABINETT = "funksjonskontrollSikkerhetskabinett",
  FUNKSJONSKONTROLLISOLATOR = "funksjonskontrollIsolator",
  FUNKSJONSKONTROLLAVTREKKSSKAP = "funksjonskontrollAvtrekksskap",
}

export const GK_RAPPORT_TEST_TYPES_UTSTYR: IValueLabelGeneric<TestTypeUtstyrEnum>[] = [
  {
    label: "Lekkasjeskanning av HEPA-filter",
    value: TestTypeUtstyrEnum.LEKKASJESKANNINGHAVHEPA,
  },
  {
    label: "Partikkeltelling for utstyr",
    value: TestTypeUtstyrEnum.PARTIKKELTELLINGUTSTYR,
  },
  {
    label: "Kontroll av luftstrøm ved arbeidsåpning",
    value: TestTypeUtstyrEnum.KONTROLLAVLUFTSTROEMVEDARBEIDSAAPNING,
  },
  {
    label: "Luftmengdemåling gjennom arbeidsåpning",
    value: TestTypeUtstyrEnum.LUFTMENGDEMAALINGGJENNOMARBEIDSAAPNING,
  },
  {
    label: "Lufthastighetsmåling og dokumentasjon av laminær luftstrøm",
    value: TestTypeUtstyrEnum.LUFTHASTIGHETSMAALINGOGDOKUAVLAMINAERLUFTSTROEM,
  },
  {
    label: "Beregning av lufthastighet gjennom arbeidsåpning",
    value: TestTypeUtstyrEnum.BEREGNINGAVLUFTHASTIGHETGJENNOMARBEIDSAAPNING,
  },
  {
    label: "Måling av trykkfall over HEPA-filter",
    value: TestTypeUtstyrEnum.MAALINGAVTRYKKFALLOVERHEPAFILTER,
  },
  {
    label: "Kontroll av alarmer",
    value: TestTypeUtstyrEnum.KONTROLLAVALARM,
  },
  {
    label: "Bruddtest",
    value: TestTypeUtstyrEnum.BRUDDTEST,
  },
  {
    label: "Måling av luftmengde gjennom inntaksfilter",
    value: TestTypeUtstyrEnum.MAALINGAVLUFTMENGDEGJENNOMINNTAKSFILTER,
  },
  {
    label: "Måling av arbeidstrykk og kontroll av intern trykkmåler",
    value: TestTypeUtstyrEnum.MAALINGAVARBEIDSTRYKKOGKONTROLLAVINTERNTRYKKMAALER,
  },
  {
    label: "Lufthastighetsmåling i arbeidsområde",
    value: TestTypeUtstyrEnum.LUFTHASTIGHETSMAALINGIARBEIDSOMRAADE,
  },
  {
    label: "Måling av innstrømningshastighet",
    value: TestTypeUtstyrEnum.MAALINGAVINNSTROEMNINGSHASTIGHET,
  },
  {
    label: "Måling av driftstrykk",
    value: TestTypeUtstyrEnum.MAALINGAVDRIFTSTRYKK,
  },
  {
    label: "Lekkasjetest",
    value: TestTypeUtstyrEnum.LEKKASJETEST,
  },
  {
    label: "Trykkfall i avtrekkskapet",
    value: TestTypeUtstyrEnum.TRYKKFALLIAVTREKKSSKAPET,
  },
  {
    label: "Visualisering av luftstrøm",
    value: TestTypeUtstyrEnum.VISUALISERINGAVLUFTSTROEM,
  },
  {
    label: "Test av alarmsystemer",
    value: TestTypeUtstyrEnum.TESTAVALARMSYSTEMER,
  },
  {
    label: "Test av skapreguleringens responstid",
    value: TestTypeUtstyrEnum.TESTAVSKAPREGULERINGENSRESPONSTID,
  },
  {
    label: "Måling av lufthastighet og luftmengde i lukeåpning",
    value: TestTypeUtstyrEnum.MAALINGAVLUFTHASTIGHETILUKEAAPNINGIGANGKJOERING,
  },
  {
    label: "Måling av avtrekksmengde (igangkjøring)",
    value: TestTypeUtstyrEnum.MAALINGAVAVTREKKSMENGDEIGANGKJOERING,
  },
  {
    label: "Måling av lufthastighet i rommet",
    value: TestTypeUtstyrEnum.MAALINGAVLUFTHASTIGHETIROMMET,
  },
  {
    label: "Måling av lydtrykk",
    value: TestTypeUtstyrEnum.MAALINGAVLYDTRYKK,
  },
  {
    label: "Måling av lufthastighet",
    value: TestTypeUtstyrEnum.MAALINGAVLUFTHASTIGHETPUNKTAVSUG,
  },
  {
    label: "Måling av trykk",
    value: TestTypeUtstyrEnum.MAALINGAVTRYKKPUNKTAVSUG,
  },
  {
    label: "Visualisering med røyk",
    value: TestTypeUtstyrEnum.VISUALISERINGMEDROEYKPUNKTAVSUG,
  },
  {
    label: "Dekontaminering av sikkerhetskabinett",
    value: TestTypeUtstyrEnum.DEKONTAMINERINGAVSIKKERHETSKABINETT,
  },
  {
    label: "Funksjonskontroll sikkerhetskabinett",
    value: TestTypeUtstyrEnum.FUNKSJONSKONTROLLSIKKERHETSKABINETT,
  },
  {
    label: "Funksjonskontroll isolator",
    value: TestTypeUtstyrEnum.FUNKSJONSKONTROLLISOLATOR,
  },
  {
    label: "Funksjonskontroll avtrekksskap",
    value: TestTypeUtstyrEnum.FUNKSJONSKONTROLLAVTREKKSSKAP,
  },
];

export enum UtstyrEnum {
  SIKKERHETSKABINETTI = "Sikkerhetskabinett I",
  SIKKERHETSKABINETTII = "Sikkerhetskabinett II",
  SIKKERHETSKABINETTIII = "Sikkerhetskabinett III",
  ISOLATOR_POSITIV = "Isolator positiv",
  ISOLATOR_NEGATIV = "Isolator negativ",
  AVTREKKSSKAP_RUTINEKONTROLL = "Avtrekksskap rutinekontroll",
  AVTREKKSSKAP_IGANGKJOERING = "Avtrekksskap igangkjøring",
  PUNKTAVSUG_KJEMIKALIESKAP = "Punktavsug/Kjemikalieskap",
}

// TESTER TILGJENGELIG FOR SIKKERHETSKABINETT KLASSE I //
export const GK_RAPPORT_TEST_TYPES_SIKKERHETSKABINETTI: IValueLabelGeneric<TestTypeUtstyrEnum>[] = [
  {
    label: "Lekkasjeskanning av HEPA-filter",
    value: TestTypeUtstyrEnum.LEKKASJESKANNINGHAVHEPA,
  },
  {
    label: "Luftmengdemåling gjennom arbeidsåpning",
    value: TestTypeUtstyrEnum.LUFTMENGDEMAALINGGJENNOMARBEIDSAAPNING,
  },
  {
    label: "Kontroll av luftstrøm ved arbeidsåpning",
    value: TestTypeUtstyrEnum.KONTROLLAVLUFTSTROEMVEDARBEIDSAAPNING,
  },
  {
    label: "Funksjonskontroll sikkerhetskabinett",
    value: TestTypeUtstyrEnum.FUNKSJONSKONTROLLSIKKERHETSKABINETT,
  },
  {
    label: "Dekontaminering av sikkerhetskabinett",
    value: TestTypeUtstyrEnum.DEKONTAMINERINGAVSIKKERHETSKABINETT,
  },
];

// TESTER TILGJENGELIG FOR SIKKERHETSKABINETT KLASSE II //
export const GK_RAPPORT_TEST_TYPES_SIKKERHETSKABINETTII: IValueLabelGeneric<TestTypeUtstyrEnum>[] = [
  {
    label: "Lekkasjeskanning av HEPA-filter",
    value: TestTypeUtstyrEnum.LEKKASJESKANNINGHAVHEPA,
  },
  {
    label: "Kontroll av luftstrøm ved arbeidsåpning",
    value: TestTypeUtstyrEnum.KONTROLLAVLUFTSTROEMVEDARBEIDSAAPNING,
  },
  {
    label: "Funksjonskontroll sikkerhetskabinett",
    value: TestTypeUtstyrEnum.FUNKSJONSKONTROLLSIKKERHETSKABINETT,
  },
  {
    label: "Lufthastighetsmåling og dokumentasjon av laminær luftstrøm",
    value: TestTypeUtstyrEnum.LUFTHASTIGHETSMAALINGOGDOKUAVLAMINAERLUFTSTROEM,
  },
  {
    label: "Beregning av lufthastighet gjennom arbeidsåpning",
    value: TestTypeUtstyrEnum.BEREGNINGAVLUFTHASTIGHETGJENNOMARBEIDSAAPNING,
  },
  {
    label: "Partikkeltelling for utstyr",
    value: TestTypeUtstyrEnum.PARTIKKELTELLINGUTSTYR,
  },
  {
    label: "Måling av trykkfall over HEPA-filter",
    value: TestTypeUtstyrEnum.MAALINGAVTRYKKFALLOVERHEPAFILTER,
  },
  {
    label: "Kontroll av alarmer",
    value: TestTypeUtstyrEnum.KONTROLLAVALARM,
  },
  {
    label: "Dekontaminering av sikkerhetskabinett",
    value: TestTypeUtstyrEnum.DEKONTAMINERINGAVSIKKERHETSKABINETT,
  },
];

// TESTER TILGJENGELIG FOR SIKKERHETSKABINETT KLASSE III //
export const GK_RAPPORT_TEST_TYPES_SIKKERHETSKABINETTIII: IValueLabelGeneric<TestTypeUtstyrEnum>[] = [
  {
    label: "Lekkasjeskanning av HEPA-filter",
    value: TestTypeUtstyrEnum.LEKKASJESKANNINGHAVHEPA,
  },
  {
    label: "Funksjonskontroll sikkerhetskabinett",
    value: TestTypeUtstyrEnum.FUNKSJONSKONTROLLSIKKERHETSKABINETT,
  },
  {
    label: "Kontroll av alarmer",
    value: TestTypeUtstyrEnum.KONTROLLAVALARM,
  },
  {
    label: "Bruddtest",
    value: TestTypeUtstyrEnum.BRUDDTEST,
  },
  {
    label: "Måling av luftmengde gjennom inntaksfilter",
    value: TestTypeUtstyrEnum.MAALINGAVLUFTMENGDEGJENNOMINNTAKSFILTER,
  },
  {
    label: "Måling av arbeidstrykk og kontroll av intern trykkmåler",
    value: TestTypeUtstyrEnum.MAALINGAVARBEIDSTRYKKOGKONTROLLAVINTERNTRYKKMAALER,
  },
  {
    label: "Dekontaminering av sikkerhetskabinett",
    value: TestTypeUtstyrEnum.DEKONTAMINERINGAVSIKKERHETSKABINETT,
  },
];

// TESTER TILGJENGELIG FOR ISOLATOR //
export const GK_RAPPORT_TEST_TYPES_ISOLATOR: IValueLabelGeneric<TestTypeUtstyrEnum>[] = [
  {
    label: "Lekkasjeskanning av HEPA-filter",
    value: TestTypeUtstyrEnum.LEKKASJESKANNINGHAVHEPA,
  },
  {
    label: "Funksjonskontroll isolator",
    value: TestTypeUtstyrEnum.FUNKSJONSKONTROLLISOLATOR,
  },
  {
    label: "Partikkeltelling for utstyr",
    value: TestTypeUtstyrEnum.PARTIKKELTELLINGUTSTYR,
  },
  {
    label: "Bruddtest",
    value: TestTypeUtstyrEnum.BRUDDTEST,
  },
  {
    label: "Lufthastighetsmåling i arbeidsområde",
    value: TestTypeUtstyrEnum.LUFTHASTIGHETSMAALINGIARBEIDSOMRAADE,
  },
  {
    label: "Måling av hastighet over toppfilter isolatorsluse",
    value: TestTypeUtstyrEnum.MAALINGAVINNSTROEMNINGSHASTIGHET,
  },
  {
    label: "Måling av driftstrykk",
    value: TestTypeUtstyrEnum.MAALINGAVDRIFTSTRYKK,
  },
  {
    label: "Lekkasjetest",
    value: TestTypeUtstyrEnum.LEKKASJETEST,
  },
  {
    label: "Ekstern og intern røykvisualisering",
    value: TestTypeUtstyrEnum.EKSTERNOGINTERNROEYKVISUALISERING,
  },
  {
    label: "Dekontaminering av isolator",
    value: TestTypeUtstyrEnum.DEKONTAMINERINGAVSIKKERHETSKABINETT,
  },
];

// TESTER TILGJENGELIG FOR AVTREKKSSKAP RUTINEKONTROLL //
export const GK_RAPPORT_TEST_TYPES_AVTREKKSSKAP_RUTINEKONTROLL: IValueLabelGeneric<TestTypeUtstyrEnum>[] = [
  {
    label: "Funksjonskontroll avtrekksskap",
    value: TestTypeUtstyrEnum.FUNKSJONSKONTROLLAVTREKKSSKAP,
  },
  {
    label: "Måling av lufthastighet og luftmengde i lukeåpning",
    value: TestTypeUtstyrEnum.MAALINGAVLUFTHASTIGHETILUKEAAPNINGIGANGKJOERING,
  },
  {
    label: "Trykkfall i avtrekkskapet",
    value: TestTypeUtstyrEnum.TRYKKFALLIAVTREKKSSKAPET,
  },
  {
    label: "Visualisering av luftstrøm",
    value: TestTypeUtstyrEnum.VISUALISERINGAVLUFTSTROEM,
  },
  {
    label: "Test av alarmsystemer",
    value: TestTypeUtstyrEnum.TESTAVALARMSYSTEMER,
  },
  {
    label: "Test av skapreguleringens responstid",
    value: TestTypeUtstyrEnum.TESTAVSKAPREGULERINGENSRESPONSTID,
  },
  {
    label: "Dekontaminering av avtrekksskap",
    value: TestTypeUtstyrEnum.DEKONTAMINERINGAVSIKKERHETSKABINETT,
  },
];

// TESTER TILGJENGELIG FOR AVTREKKSSKAP IGANGKJØRING //
export const GK_RAPPORT_TEST_TYPES_AVTREKKSSKAP_IGANGKJOERING: IValueLabelGeneric<TestTypeUtstyrEnum>[] = [
  {
    label: "Funksjonskontroll avtrekksskap",
    value: TestTypeUtstyrEnum.FUNKSJONSKONTROLLAVTREKKSSKAP,
  },
  {
    label: "Måling av lufthastighet og luftmengde i lukeåpning",
    value: TestTypeUtstyrEnum.MAALINGAVLUFTHASTIGHETILUKEAAPNINGIGANGKJOERING,
  },
  // {
  //   label: "Måling av avtrekksmengde (igangkjøring)",
  //   value: TestTypeUtstyrEnum.MAALINGAVAVTREKKSMENGDEIGANGKJOERING,
  // },
  {
    label: "Trykkfall i avtrekkskapet",
    value: TestTypeUtstyrEnum.TRYKKFALLIAVTREKKSSKAPET,
  },
  {
    label: "Visualisering av luftstrøm",
    value: TestTypeUtstyrEnum.VISUALISERINGAVLUFTSTROEM,
  },
  {
    label: "Test av alarmsystemer",
    value: TestTypeUtstyrEnum.TESTAVALARMSYSTEMER,
  },
  {
    label: "Test av skapreguleringens responstid",
    value: TestTypeUtstyrEnum.TESTAVSKAPREGULERINGENSRESPONSTID,
  },
  {
    label: "Måling av lufthastighet i rommet",
    value: TestTypeUtstyrEnum.MAALINGAVLUFTHASTIGHETIROMMET,
  },
  {
    label: "Måling av lydtrykk",
    value: TestTypeUtstyrEnum.MAALINGAVLYDTRYKK,
  },
  {
    label: "Dekontaminering av avtrekksskap",
    value: TestTypeUtstyrEnum.DEKONTAMINERINGAVSIKKERHETSKABINETT,
  },
];

// TESTER TILGJENGELIG FOR PUNKTAVSUG OG KJEMIKALIESKAP //
export const GK_RAPPORT_TEST_TYPES_PUNKTAVSUG_OG_KJEMIKALIESKAP: IValueLabelGeneric<TestTypeUtstyrEnum>[] = [
  {
    label: "Måling av lufthastighet",
    value: TestTypeUtstyrEnum.MAALINGAVLUFTHASTIGHETPUNKTAVSUG,
  },
  {
    label: "Måling av trykkfall",
    value: TestTypeUtstyrEnum.MAALINGAVTRYKKPUNKTAVSUG,
  },
  {
    label: "Visualisering med røyk",
    value: TestTypeUtstyrEnum.VISUALISERINGMEDROEYKPUNKTAVSUG,
  },
];

export enum TestTypeLAFTakEnum {
  MAALINGAVOVERTRYKK = "maalingAvOvertrykk",
  PARTIKKELTELLINGLAFTAK = "partikkelTellingLAFTak",
  LEKKASJESKANNINGHAVHEPA = "lekkasjeskanningAvHepaLAFTak",
  LUFTHASTIGHETSMAALINGUNDERFILTER = "lufthastighetsmaalingUnderFilter",
  MAALINGAVLUFTHASTIGHETVEDOPERASJONSSENG = "maalingAvLufthastighetVedOperasjonsseng",
  FUNKSJONSKONTROLLLAFTAK = "funksjonskontrollLAFTak",
}

export const GK_RAPPORT_TEST_TYPES_LAFTAK: IValueLabelGeneric<TestTypeLAFTakEnum>[] = [
  {
    label: "Måling av overtrykk",
    value: TestTypeLAFTakEnum.MAALINGAVOVERTRYKK,
  },
  {
    label: "Partikkeltelling LAF-tak",
    value: TestTypeLAFTakEnum.PARTIKKELTELLINGLAFTAK,
  },
  {
    label: "Lekkasjeskanning av HEPA-filter",
    value: TestTypeLAFTakEnum.LEKKASJESKANNINGHAVHEPA,
  },
  {
    label: "Lufthastighetsmåling under filter",
    value: TestTypeLAFTakEnum.LUFTHASTIGHETSMAALINGUNDERFILTER,
  },
  {
    label: "Måling av lufthastighet ved operasjonsseng",
    value: TestTypeLAFTakEnum.MAALINGAVLUFTHASTIGHETVEDOPERASJONSSENG,
  },
  {
    label: "Funksjonskontroll LAF-tak",
    value: TestTypeLAFTakEnum.FUNKSJONSKONTROLLLAFTAK,
  },
];

export enum LuftmengdeMaalingerEnum {
  ANEMOMETERMAALING = "Anemometer-måling",
  TILLUFTSKANALMAALING = "Tilluftskanal-måling",
  BALOMETERMAALING = "Balometer-måling",
}

export enum KontrollAvLuftstroemEnum {
  STREAMER = "Streamer",
  ROEYKELLERPARTIKKEL = "Røyk/Partikkel",
  LUFTHASTIGHET = "Lufthastighet",
  ROEYKELLERTAAKE = "Røyk/Tåke",
}

export enum LuftmengdeGjennomLukeaapningEnum {
  ARBEIDSAAPNING = "Arbeidsåpning",
  AVTREKKSKANAL = "Avtrekkskanal",
  INNTAKSFILTER = "Inntaksfilter",
  AVKASTKANAL = "Avkastkanal",
}

export enum BeregningAvLufthastighetGjennomArbeidsaapningEnum {
  AVTREKKSKANAL = "Avtrekkskanal",
  LUKEAAPNING = "Lukeåpning",
}

export enum DekontamineringAvSikkerhetskabinettEnum {
  INNEISIKKERHETSKABINETT = "Inne i sikkerhetskabinett",
  UTENFORSIKKERHETSKABINETT = "Utenfor sikkerhetskabinett",
  TILKOBLETAVKAST = "Tilkoblet avkast",
}

export enum TestAvAlarmsystemerEnum {
  LUKEAAPNING = "lukeaapning",
  LUFTHASTIGHETSINDIKATOR = "lufthastighetsIndikator",
}

export const TEST_AV_ALARMSYSTEMER_METODER = [
  {
    label: "Lukeåpning",
    value: TestAvAlarmsystemerEnum.LUKEAAPNING,
  },
  {
    label: "Lufthastighetsindikator",
    value: TestAvAlarmsystemerEnum.LUFTHASTIGHETSINDIKATOR,
  },
];

export enum LufthastighetsmaalingOgDokuAvLaminaerLuftstroemEnum {
  LUFTHASTIGHETSMAALING = "lufthastighetsmaaling",
  DOKUMENTASJON = "dokumentasjon",
}

export const LUFTHASTIGHETSMAALING_OG_DOKU_AV_LAMINAER_LUFTSTROEM_METODER = [
  {
    label: "Lufthastighetsmåling",
    value: LufthastighetsmaalingOgDokuAvLaminaerLuftstroemEnum.LUFTHASTIGHETSMAALING,
  },
  {
    label: "Dokumentasjon",
    value: LufthastighetsmaalingOgDokuAvLaminaerLuftstroemEnum.DOKUMENTASJON,
  },
];

export enum MaalingAvAvtrekksmengdeEnum {
  LUFTHASTIGHETSMAALING = "lufthastighetsmaaling",
  DIFFERANSETRYKK = "differansetrykk",
}

export const MAALING_AV_AVTREKKSMENGDE_METODER = [
  {
    label: "Lufthastighetsmåling",
    value: MaalingAvAvtrekksmengdeEnum.LUFTHASTIGHETSMAALING,
  },
  {
    label: "Differansetrykkmåling",
    value: MaalingAvAvtrekksmengdeEnum.DIFFERANSETRYKK,
  },
];

export enum FunksjonskontrollSikkerhetskabinettTesterEnum {
  VISUELLKONTROLLAVOVERFLATER = "visuellKontrollAvOverflater",
  VISUELLKONTROLLAVAVTREKKSKANALER = "visuellKontrollAvAvtrekkskanaler",
  KONTROLLAVTILBAKESLAGSSPJELD = "kontrollAvTilbakeslagsspjeld",
  MAALINGAVBELYSNING = "maalingAvBelysning",
  MAALINGAVLYDNIVAAER = "maalingAvLydnivaaer",
  KONTROLLOGKALIBRERING = "kontrollOgKalibrering",
  DEKONTAMINERINGSARRANGEMENT = "dekontamineringsarrangement",
}

export const FUNKSJONSKONTROLL_SIKKERHETSKABINETT_TESTER_METODER = [
  {
    label: "Visuell kontroll av overflater",
    value: FunksjonskontrollSikkerhetskabinettTesterEnum.VISUELLKONTROLLAVOVERFLATER,
  },
  {
    label: "Visuell kontroll av avtrekkskanaler",
    value: FunksjonskontrollSikkerhetskabinettTesterEnum.VISUELLKONTROLLAVAVTREKKSKANALER,
  },
  {
    label: "Kontroll av tilbakeslagsspjeld",
    value: FunksjonskontrollSikkerhetskabinettTesterEnum.KONTROLLAVTILBAKESLAGSSPJELD,
  },
  {
    label: "Måling av belysning",
    value: FunksjonskontrollSikkerhetskabinettTesterEnum.MAALINGAVBELYSNING,
  },
  {
    label: "Måling av lydnivåer",
    value: FunksjonskontrollSikkerhetskabinettTesterEnum.MAALINGAVLYDNIVAAER,
  },
  {
    label: "Kontroll og kalibrering",
    value: FunksjonskontrollSikkerhetskabinettTesterEnum.KONTROLLOGKALIBRERING,
  },
  {
    label: "Dekontamineringsarrangement",
    value: FunksjonskontrollSikkerhetskabinettTesterEnum.DEKONTAMINERINGSARRANGEMENT,
  },
];

export enum FunksjonskontrollIsolatorTesterEnum {
  LYSPAERERFRONTPANEL = "lyspaererFrontpanel",
  LYSPAERERINTERLOCK = "lyspaererInterlock",
  INTERNVIFTER = "internvifter",
  EKSTERNVIFTE = "eksternvifte",
  TILBAKESLAGSVENTIL = "tilbakeslagsventil",
  ALARMFORINTERNTRYKK = "alarmForInterntrykk",
  ALARMFORLUFTVEKSLINGER = "alarmForLuftvekslinger",
  SKIFTEFORFILTERISLUSE = "skifteForfilterISluse",
  SKIFTEPAKNINGERSLUSERUTVENDIG = "skiftePakningerSluserUtvendig",
  ETTERSTRAMMEUTSATTEKOMPONENTER = "etterstrammeUtsatteKomponenter",
  SMOERESYLINDER = "smoereSylinder",
  PUSSEMAGNETER = "pusseMagneter",
  DEKONTAMINERINGSARRANGEMENT = "dekontamineringsarrangement",
}

export const FUNKSJONSKONTROLL_ISOLATOR_TESTER_METODER = [
  {
    label: "Lyspærer frontpanel",
    value: FunksjonskontrollIsolatorTesterEnum.LYSPAERERFRONTPANEL,
  },
  {
    label: "Lyspærer interlock i slusene",
    value: FunksjonskontrollIsolatorTesterEnum.LYSPAERERINTERLOCK,
  },
  {
    label: "Internvifter",
    value: FunksjonskontrollIsolatorTesterEnum.INTERNVIFTER,
  },
  {
    label: "Eksternvifte",
    value: FunksjonskontrollIsolatorTesterEnum.EKSTERNVIFTE,
  },
  {
    label: "Tilbakeslagsventil",
    value: FunksjonskontrollIsolatorTesterEnum.TILBAKESLAGSVENTIL,
  },
  {
    label: "Alarm for interntrykk",
    value: FunksjonskontrollIsolatorTesterEnum.ALARMFORINTERNTRYKK,
  },
  {
    label: "Alarm for luftvekslinger",
    value: FunksjonskontrollIsolatorTesterEnum.ALARMFORLUFTVEKSLINGER,
  },
  {
    label: "Skifte forfilter i sluse",
    value: FunksjonskontrollIsolatorTesterEnum.SKIFTEFORFILTERISLUSE,
  },
  {
    label: "Skifte pakninger sluser utvendig",
    value: FunksjonskontrollIsolatorTesterEnum.SKIFTEPAKNINGERSLUSERUTVENDIG,
  },
  {
    label: "Etterstramme utsatte komponenter",
    value: FunksjonskontrollIsolatorTesterEnum.ETTERSTRAMMEUTSATTEKOMPONENTER,
  },
  {
    label: "Smøre sylinder",
    value: FunksjonskontrollIsolatorTesterEnum.SMOERESYLINDER,
  },
  {
    label: "Pusse magneter",
    value: FunksjonskontrollIsolatorTesterEnum.PUSSEMAGNETER,
  },
  {
    label: "Dekontamineringsarrangement",
    value: FunksjonskontrollIsolatorTesterEnum.DEKONTAMINERINGSARRANGEMENT,
  },
];

export enum FunksjonskontrollAvtrekksskapTesterEnum {
  KONTROLLAVBETJENINGSPANEL = "kontrollAvBetjeningspanel",
  NOEDTEST = "noedtest",
  LYSNIVAA = "lysnivaa",
  LYDNIVAA = "lydnivaa",
  DEKONTAMINERINGSARRANGEMENT = "dekontamineringsarrangement",
}

export const FUNKSJONSKONTROLL_AVTREKKSSKAP_TESTER_METODER = [
  {
    label: "Kontroll av betjeningspanel",
    value: FunksjonskontrollAvtrekksskapTesterEnum.KONTROLLAVBETJENINGSPANEL,
  },
  {
    label: "Nødtest",
    value: FunksjonskontrollAvtrekksskapTesterEnum.NOEDTEST,
  },
  {
    label: "Lysnivå",
    value: FunksjonskontrollAvtrekksskapTesterEnum.LYSNIVAA,
  },
  {
    label: "Lydnivå",
    value: FunksjonskontrollAvtrekksskapTesterEnum.LYDNIVAA,
  },
  {
    label: "Dekontamineringsarrangement",
    value: FunksjonskontrollAvtrekksskapTesterEnum.DEKONTAMINERINGSARRANGEMENT,
  },
];

export enum FunksjonskontrollLAFTakTesterEnum {
  KONTROLLAVBETJENINGSPANEL = "kontrollAvBetjeningspanel",
  LYSPAERER = "visuellKontrollAvLyspaerer",
  DISPLAY = "visuellKontrollAvDisplay",
  SKADER = "visuellKontrollAvSkader",
  LEKKASJE = "visuellKontrollAvLekkasje",
  POSISJON = "visuellKontrollAvPosisjon",
  LYD = "kontrollAvLyd",
  FORFILTER = "visuellKontrollAvForfilter",
}

export const FUNKSJONSKONTROLL_LAFTAK_TESTER_METODER = [
  {
    label: "Kontroll av betjeningspanel",
    value: FunksjonskontrollLAFTakTesterEnum.KONTROLLAVBETJENINGSPANEL,
  },
  {
    label: "Visuell kontroll av lyspærer",
    value: FunksjonskontrollLAFTakTesterEnum.LYSPAERER,
  },
  {
    label: "Visuell kontroll av display",
    value: FunksjonskontrollLAFTakTesterEnum.DISPLAY,
  },
  {
    label: "Visuell kontroll av skader",
    value: FunksjonskontrollLAFTakTesterEnum.SKADER,
  },
  {
    label: "Visuell kontroll av lekkasje",
    value: FunksjonskontrollLAFTakTesterEnum.LEKKASJE,
  },
  {
    label: "Visuell kontroll av posisjon",
    value: FunksjonskontrollLAFTakTesterEnum.POSISJON,
  },
  {
    label: "Kontroll av lyd og støy",
    value: FunksjonskontrollLAFTakTesterEnum.LYD,
  },
  {
    label: "Visuell kontroll av forfilter",
    value: FunksjonskontrollLAFTakTesterEnum.FORFILTER,
  },
];
