import React from "react";
import { Badge } from "reactstrap";
import { CheckIcon } from "..";
import { ITaskCompleteCount } from "../../types";
import { ActionIcon } from "../CustomIcons/CustomIcons";

const TaskCompletedCount: React.FC<{
  taskCompleteCount: ITaskCompleteCount;
  darkVariant?: boolean;
  active?: boolean;
  useActionIcon?: boolean;
}> = ({ taskCompleteCount, darkVariant, active, useActionIcon }) => (
  <>
    {taskCompleteCount != null && taskCompleteCount.totalNr > 0 && (
      <Badge
        style={{ padding: "5px 8px" }}
        pill={darkVariant ? false : true}
        className={`d-flex ${darkVariant && !active ? "gray-400" : active ? "secondary" : "greybadge"}`}
      >
        <div
          className={`d-flex align-items-center ${taskCompleteCount.darkText ? "" : "text-white"}`}
          style={{ fontSize: "11px" }}
        >
          {taskCompleteCount.totalNr > 0 && (
            <>
              {taskCompleteCount.showWarning && <i className="fa fa-warning text-danger" />}
              {useActionIcon ? (
                <ActionIcon style={{ fontSize: "1.3em", margin: " -2px 1px" }} />
              ) : (
                <CheckIcon completedNr={taskCompleteCount.completedCount} totalNr={taskCompleteCount.totalNr} />
              )}
              {taskCompleteCount.completedCount}/{taskCompleteCount.totalNr}
            </>
          )}
        </div>
      </Badge>
    )}
  </>
);
export default TaskCompletedCount;
