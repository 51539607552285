import React from "react";
import CountUp from "react-countup";
import { Card, CardBody } from "reactstrap";
import { IStatbox } from "../../../../types";

export const StatsBox: React.FC<{ stat: IStatbox }> = ({ stat }) => (
  <Card
    style={{
      width: "100%",
      minWidth: "120px",
      height: "150px",
    }}
  >
    <CardBody className="d-flex flex-column align-items-center justify-content-center">
      <div className="d-flex" style={{ fontSize: "40px", fontWeight: 300, lineHeight: 1.2 }}>
        {stat.icon && <i style={{ fontSize: "34px" }} className={`mr-2 fa  ${stat.icon} mt-2 ${stat.classColor}`} />}
        <CountUp duration={3} end={stat?.value ?? 0} />
      </div>
      <span className="gray-400-text text-center">
        <b>{stat.text}</b>
      </span>
    </CardBody>
  </Card>
);
