import React from "react";
import TableTemplateViewMode from "./TableTemplateViewMode";
import TableTemplateEditMode from "./TableTemplateEditMode";
import { IAllFilter } from "mc-shared/zod/commonSchema";
import { ITableTemplate } from "mc-shared/zod/issueSchema";

const TableTemplate: React.FC<{
  tableTemplate: ITableTemplate;
  allFilters: IAllFilter[];
  onSave: (tableTemplate: ITableTemplate) => void;
  onDelete: () => void;
}> = ({ tableTemplate, allFilters, onSave, onDelete }) => {
  const [editMode, setEditMode] = React.useState<boolean>(false);

  const save = (tableTemplate: ITableTemplate) => {
    setEditMode(false);
    onSave(tableTemplate);
  };

  return (
    <>
      {editMode === false && (
        <TableTemplateViewMode allFilters={allFilters} tableTemplate={tableTemplate} onEdit={setEditMode} />
      )}
      {editMode === true && (
        <TableTemplateEditMode
          tableTemplate={tableTemplate}
          allFilters={allFilters}
          onSave={save}
          onAbort={() => setEditMode(false)}
          onDelete={onDelete}
        />
      )}
    </>
  );
};

export default TableTemplate;
