import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import classNames from "classnames";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Alert, Button, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { IBoard, IIssue, IUserActiveItem } from "../../../../../../types/global.types";
import { AG_GRID_LOCALE_NOR } from "../../../../agGridLocale";
import { DisplayDueDate, FailedAlert, HSpace, MCLoader } from "../../../../components";
import { StatusBadgeRenderer } from "../../../../components/ConnectedIssues/ConnectedIssues";
import { AppModalEnums } from "../../../../frontendConstants";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { loadProject } from "../../../actions";
import {
  useGetActiveAssignedItemsQuery,
  useGetActiveItemsWhereUserIsAMemberQuery,
  useGetActiveUserOwnedItemsQuery,
} from "../../../projectRTK";
import { ISSUE_COMPLETED_VALUE, ISSUE_EXPIRED, ISSUE_STATES_OPTIONS } from "../../issuesApp/issueConstants";
import { addAppModalThunk } from "../../../../global/globalSlice";

const UserAssignedIssuesInProject: React.FC<{ app: string }> = ({ app }) => {
  const [activeItems, setActiveItems] = useState<IIssue[]>([]);
  const [activeTab, setActiveTab] = useState<string>("assignedItems");
  const project = useAppSelector((state) => state.adminReducer.project);
  const user = useAppSelector((state) => state.globalReducer.user);
  let history = useHistory();

  const dispatch = useAppDispatch();

  const {
    data: assignedItems = [],
    isLoading,
    isError: isErrorAssignedItems,
  } = useGetActiveAssignedItemsQuery({
    projectId: project?._id,
    userId: user._id,
  });

  const { data: userOwnedItems = [], isError: isErrorUserOwnedItems } = useGetActiveUserOwnedItemsQuery({
    projectId: project?._id,
    userId: user._id,
  });

  const { data: itemsWhereUserIsAMember = [], isError: isErrorItemsWhereUserIsAMember } =
    useGetActiveItemsWhereUserIsAMemberQuery({
      projectId: project?._id,
      userId: user._id,
    });

  const isError = isErrorAssignedItems || isErrorUserOwnedItems || isErrorItemsWhereUserIsAMember;

  useEffect(() => {
    if (assignedItems.length > 0) {
      setActiveItems(assignedItems);
    }
  }, [assignedItems]);

  const toggleTab = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
    switch (tab) {
      case "assignedItems":
        setActiveItems(assignedItems);
        break;
      case "userOwnedItems":
        setActiveItems(userOwnedItems);
        break;
      case "itemsWhereUserIsAMember":
        setActiveItems(itemsWhereUserIsAMember);
        break;
      default:
        break;
    }
  };

  const isUserActiveItem = (item: any): item is IUserActiveItem => {
    return (item as IUserActiveItem).itemId !== undefined && (item as IUserActiveItem).board !== undefined;
  };

  const isIssue = (item: any): item is IIssue => {
    return (item as IIssue)._id !== undefined && (item as IIssue).issueBoard !== undefined;
  };

  const openModal = (item: IUserActiveItem | IIssue) => {
    let itemId, boardId, projectId;

    if (isUserActiveItem(item)) {
      itemId = item.itemId;
      boardId = (item.board as IBoard)?._id ?? item.board;
      projectId = typeof item.project === "string" ? item.project : item.project?._id;
    } else if (isIssue(item)) {
      itemId = item._id;
      boardId = (item.issueBoard as IBoard) ?? item.issueBoard;
      projectId = typeof item.project === "string" ? item.project : item.project?._id;
    } else {
      throw new Error("Unsupported item type");
    }
    dispatch(
      addAppModalThunk({
        itemId,
        boardId,
        projectId,
        app: AppModalEnums.ISSUE,
      }),
    );
  };

  const goToBoard = async (board: IBoard) => {
    history.push(`/project/${board.project}/issues/${board._id}`);
  };

  const goToProject = (id: string) => {
    dispatch(loadProject(id));
    history.push(`/project/${id}/apps`);
  };

  return (
    <>
      {isLoading === true && <MCLoader loading />}
      {isError === true && <FailedAlert errorMessage="Kunne ikke laste inn aktive tildelte oppgaver" />}
      {isLoading === false && isError === false && assignedItems.length === 0 && (
        <div className="px-1">
          <Alert color="success mb-1">
            <i className="fa fa-check fa-fw" />
            Du har ingen oppgaver i dette prosjektet
          </Alert>
        </div>
      )}
      {isLoading === false && isError === false && (
        <>
          <Nav tabs className="pt-2">
            <NavItem className="mr-1">
              <NavLink
                className={classNames({ active: activeTab === "assignedItems", custom: true })}
                onClick={() => toggleTab("assignedItems")}
              >
                Ansvarlig
              </NavLink>
            </NavItem>
            <NavItem className="mr-1">
              <NavLink
                className={classNames({ active: activeTab === "userOwnedItems", custom: true })}
                onClick={() => toggleTab("userOwnedItems")}
              >
                Eier
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames({ active: activeTab === "itemsWhereUserIsAMember", custom: true })}
                onClick={() => toggleTab("itemsWhereUserIsAMember")}
              >
                Medlem
              </NavLink>
            </NavItem>
          </Nav>
          <div className="h-100 overflow-auto">
            <TabContent activeTab={activeTab}>
              <TabPane tabId={activeTab}>
                <div className="ag-theme-quartz h-100 w-100">
                  <AgGridReact
                    rowData={activeItems}
                    columnDefs={createActiveAssignedItemsColumns(openModal, goToBoard, goToProject, app)}
                    domLayout={"autoHeight"}
                    rowSelection="single"
                    animateRows={true}
                    suppressContextMenu={true}
                    onGridReady={(e) => {
                      e.api.autoSizeColumns(["id", "name"]);
                    }}
                    defaultColDef={{
                      minWidth: 140,
                      suppressHeaderMenuButton: true,
                      autoHeight: true,
                      cellStyle: {
                        whiteSpace: "normal",
                        lineHeight: "1.5",
                        wordBreak: "break-word",
                      },
                    }}
                    localeText={AG_GRID_LOCALE_NOR}
                  />
                </div>
              </TabPane>
            </TabContent>
          </div>
        </>
      )}
    </>
  );
};

export default UserAssignedIssuesInProject;

export const createActiveAssignedItemsColumns = (
  onClickIssue: (item: IUserActiveItem) => void,
  onClickBoard?: (board: IBoard) => void,
  onClickProject?: (projectId: string) => void,
  app?: string,
) => {
  const columns: ColDef[] = [
    {
      field: "text",
      headerName: "Oppgaver",
      minWidth: 250,
      editable: false,
      cellRenderer: (params) => {
        return (
          <>
            <div className="d-flex align-items-center">
              {params.data.id.length < 5 ? <HSpace /> : null}
              <Button
                className="p-0 m-0"
                style={{ float: "left" }}
                onClick={() => onClickIssue(params.data)}
                color="link"
              >
                {params.data?.id}
              </Button>
              <HSpace />
              <p className="m-0 two-line-text-truncate">{params.value}</p>
            </div>
          </>
        );
      },
      flex: 2,
    },
    {
      field: "connectedIssue.title",
      headerName: "Tilhører sak",
      editable: false,
      minWidth: 200,
      hide: app !== "mytaskspage",
      cellRenderer: (params) => {
        return (
          <>
            <div className="d-flex align-items-center">
              <Button className="p-0 m-0" onClick={() => onClickIssue(params.data?.connectedIssue)} color="link">
                {params.data?.connectedIssue?.id}
              </Button>
              <HSpace />
              <p className="m-0 two-line-text-truncate">{params.value}</p>
            </div>
          </>
        );
      },
      flex: 1,
    },
    {
      field: "board.name",
      headerName: "Register",
      editable: false,
      hide: app !== "mytaskspage",
      cellRenderer: (params) => {
        return (
          <>
            <Button className="p-0 m-0" onClick={() => onClickBoard(params.data?.board)} color="link">
              {params.value}
            </Button>
          </>
        );
      },
    },
    {
      field: "project.maconomy.name",
      headerName: "Prosjekt",
      editable: false,
      hide: app !== "mytaskspage",
      cellRenderer: (params) => {
        return (
          <>
            <Button className="p-0 m-0" onClick={() => onClickProject(params.data?.project._id)} color="link">
              {params.value}
            </Button>
          </>
        );
      },
    },
    {
      field: "dueDate",
      headerName: "Frist",
      editable: false,
      cellRenderer: (params) => {
        if (moment(params.value).isValid() === false || params.value == null || !params.value) {
          return "-";
        }
        return (
          <DisplayDueDate
            dueDate={params.value}
            isComplete={params.data.status === ISSUE_COMPLETED_VALUE || params.data.status === ISSUE_EXPIRED}
          />
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      valueGetter: (params) => {
        const status = ISSUE_STATES_OPTIONS.find((opt) => opt.value === params.data.status);
        return status;
      },
      valueSetter: (params) => {
        return params.newValue.value;
      },
      width: 150,
      cellRenderer: StatusBadgeRenderer,
    },
  ];
  return columns;
};
