import { IRisikoEvaluationCategory, IRisikoEvaluationCategorySelection } from "../../../types/global.types";

const SCORE_COLORS = [
  {
    consequenceValue: 1,
    probabilityValue: 1,
    color: "green",
  },
  {
    consequenceValue: 2,
    probabilityValue: 1,
    color: "green",
  },
  {
    consequenceValue: 3,
    probabilityValue: 1,
    color: "orange",
  },
  {
    consequenceValue: 4,
    probabilityValue: 1,
    color: "orange",
  },
  {
    consequenceValue: 1,
    probabilityValue: 2,
    color: "green",
  },
  {
    consequenceValue: 1,
    probabilityValue: 3,
    color: "green",
  },
  {
    consequenceValue: 2,
    probabilityValue: 2,
    color: "orange",
  },
  {
    consequenceValue: 3,
    probabilityValue: 2,
    color: "orange",
  },
  {
    consequenceValue: 2,
    probabilityValue: 3,
    color: "orange",
  },
  {
    consequenceValue: 1,
    probabilityValue: 4,
    color: "orange",
  },
  {
    consequenceValue: 4,
    probabilityValue: 2,
    color: "red",
  },
  {
    consequenceValue: 3,
    probabilityValue: 3,
    color: "red",
  },
  {
    consequenceValue: 2,
    probabilityValue: 4,
    color: "red",
  },
  {
    consequenceValue: 4,
    probabilityValue: 3,
    color: "red",
  },
  {
    consequenceValue: 3,
    probabilityValue: 4,
    color: "red",
  },
  {
    consequenceValue: 4,
    probabilityValue: 4,
    color: "red",
  },
];

export const DEFAULT_PROBABILITY_SELECTIONS: IRisikoEvaluationCategorySelection[] = [
  {
    name: "0–25%",
    description: "",
    value: 1,
  },
  {
    name: "25-50%",
    description: "",
    value: 2,
  },
  {
    name: "50-75%,",
    description: "",
    value: 3,
  },
  {
    name: "75-100%",
    description: "",
    value: 4,
  },
];

export const DEFAULT_RISIKO_EVALUATION_CATEGORIES: IRisikoEvaluationCategory[] = [
  {
    name: "Kost",
    scoreColors: SCORE_COLORS,
    consequenceSelections: [
      {
        name: "Av budsjettsum: 0-10%",
        value: 1,
      },
      {
        name: "Av budsjettsum: 10-25%",
        value: 2,
      },
      {
        name: "Av budsjettsum: 25-50%",
        value: 3,
      },
      {
        name: "Av budsjettsum: > 50%",
        value: 4,
      },
    ],
  },
  {
    name: "Fremdrift",
    scoreColors: SCORE_COLORS,
    consequenceSelections: [
      {
        name: " 0-3 mnd",
        value: 1,
      },
      {
        name: "3 – 9 mnd",
        value: 2,
      },
      {
        name: "9 – 12 mnd",
        value: 3,
      },
      {
        name: ">12mnd",
        value: 4,
      },
    ],
  },
  {
    name: "Kvalitet",
    scoreColors: SCORE_COLORS,
    consequenceSelections: [
      {
        name: "Veldig lav",
        value: 1,
      },
      {
        name: "Lav",
        value: 2,
      },
      {
        name: "Middels",
        value: 3,
      },
      {
        name: "Høy",
        value: 4,
      },
    ],
  },
];

export const VIEW_EDIT_COMPS = {
  TITLE_WITH_VALUE_EDIT: "TITLE_WITH_VALUE_EDIT",
  DROPDOWN_COLOR_EDIT: "STATUS",
  DROPDOWN_EDIT: "DROPDOWN_EDITOR",
  EVALUATION_PROBABILITY_EDIT: "EVALUATION_PROBABILITY_EDIT",
  EVALUATION_CONS_EDIT: "EVALUATION_CONS_EDIT",
  EVALUATION_SCORE: "EVALUATION_SCORE",
  TYPEAHEAD_EDIT: "TYPEAHEAD_EDIT",
  PNS_EDITOR: "PNS_EDITOR",
  TAG_EDITOR: "TAG_EDITOR",
};

export const STANDARD_COLORS = {
  teal: "#20c997",
  green: "#2ba175",
  blue: "#007bff",
  purple: "#6f42c1",
  yellow: "#ffc107",
  orange: "#fd7e14",
  red: "#dc3545",
  brown: "#795548",
  pink: "#E83E8C",
  grey: "#58595B",
};

export const MEMBER_GROUPS = [
  {
    label: "Internal",
    value: "INTERNAL",
  },
  {
    label: "Admin",
    value: "ADMIN",
  },
  {
    label: "Partner",
    value: "PARTNER",
  },
  {
    label: "Kunde",
    value: "CLIENT",
  },
];
