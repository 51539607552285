import React, { useEffect } from "react";
import { IBoard } from "../../../../../../types/global.types";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import { FailedAlert, McInput, MCLoader, McTitle, VSpace } from "../../../../components";
import { useMoveIssueBoardToOtherProjectMutation } from "../IssueBoardRTK";
import { useHistory } from "react-router-dom";
import { getProjectIdFromUrl } from "../../../utils";

const MoveIssueBoardToOtherProject: React.FC<{ issueBoard: IBoard }> = ({ issueBoard }) => {
  const [projectNo, setProjectNo] = React.useState<number>();

  const history = useHistory();
  const projectId = getProjectIdFromUrl(history.location.pathname);

  const isProjectNoValid = howManyCharsIsNumber(projectNo) !== 8;

  const [moveIssueBoardToOtherProject, { isError, isLoading, isSuccess }] = useMoveIssueBoardToOtherProjectMutation();

  useEffect(() => {
    if (isSuccess === true) {
      history.push(`/project/${projectId}/issues`);
    }
  }, [isSuccess]);

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h3">Flytt register til et annet prosjekt</CardTitle>
        {isError && <FailedAlert />}
        {isLoading && <MCLoader />}
        <McTitle style={{ width: "200px" }} title="Oppdragsnummer">
          <McInput
            min={8}
            title="Oppdragsnummer"
            type="number"
            value={projectNo}
            onChange={(e) => setProjectNo(Number(e.target.value))}
          />
        </McTitle>
        <VSpace />
        <Button
          color="success"
          disabled={isProjectNoValid || isLoading}
          onClick={() =>
            moveIssueBoardToOtherProject({
              maconomyProjectNo: projectNo,
              issueBoardId: issueBoard._id,
              projectId: issueBoard.project,
            })
          }
        >
          <i className="fa fa-arrow-right fa-fw" />
          Flytt register
        </Button>
      </CardBody>
    </Card>
  );
};

export default MoveIssueBoardToOtherProject;

const howManyCharsIsNumber = (num: number | null): number => {
  return num?.toString().length ?? 0;
};
