import React from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
import { VSpace } from "..";
import { IFolderAgGrid } from "../../../../types/global.types";

const FolderSelector: React.FC<{
  currentFolder: IFolderAgGrid;
  folders: IFolderAgGrid[];
  onSelectActiveFolder: (folder: IFolderAgGrid) => void;
  onGoToNextFolder: (folder: IFolderAgGrid) => void;
  highlightedFolder?: IFolderAgGrid;
  goBack: () => void;
  canGoBack: boolean;
}> = ({ currentFolder, folders, onSelectActiveFolder, highlightedFolder, goBack, onGoToNextFolder, canGoBack }) => {
  return (
    <div className="d-flex flex-column h-100 overflow-auto" style={{ minWidth: "400px" }}>
      {currentFolder != null ? (
        <div className="d-flex align-items-center" style={{ height: "40px" }}>
          <span>{currentFolder?.name}</span>
        </div>
      ) : (
        <div className="d-flex align-items-center" style={{ height: "40px" }}>
          <span>Root</span>
        </div>
      )}
      <VSpace />
      <ListGroup className="d-flex h-100 overflow-auto" style={{ userSelect: "none" }}>
        {canGoBack === true && (
          <ListGroupItem
            className="d-flex align-items-center"
            onDoubleClick={() => goBack()}
            style={{ cursor: "pointer" }}
          >
            <i className="fa fa-folder fa-fw mr-2" />
            <span>...</span>
          </ListGroupItem>
        )}
        {canGoBack === false && (
          <ListGroupItem className="d-flex align-items-center" style={{ color: "lightGrey" }}>
            <i className="fa fa-folder fa-fw mr-2" />
            <span>...</span>
          </ListGroupItem>
        )}
        {folders.map((folder) => (
          <ListGroupItem
            onDoubleClick={() => onGoToNextFolder(folder)}
            onClick={() => onSelectActiveFolder(folder)}
            className="d-flex align-items-center"
            style={{ cursor: "pointer" }}
            color={highlightedFolder?.id === folder.id ? "info" : null}
            key={folder.id}
          >
            <i className="fa fa-folder fa-fw mr-2" />
            <span className="truncateHeaderText">{folder.name}</span>
          </ListGroupItem>
        ))}
      </ListGroup>
    </div>
  );
};

export default FolderSelector;
