import React from "react";
import FileUploaderDepricated from "../FileUploader/FileUploaderDepricated";
import { McInput } from "../index";

const InputUpload: React.FC<{ text: string; setText: (str: string) => void }> = ({ text, setText }) => {
  const onFileUpload = (event) => {
    if (event.path.endsWith(".txt") === false) {
      alert("Du kan kun laste opp .txt-filer");
      return;
    }

    const reader = new FileReader();

    reader.onload = () => {
      const fileContent = reader.result;
      if (typeof fileContent !== "string") {
        alert("Kunne ikke lese filen");
        return;
      } else {
        setText(fileContent);
      }
    };

    reader.readAsText(event, "ISO-8859-1"); // Lägg till teckenkodningen här
  };

  return (
    <div>
      <FileUploaderDepricated onFileUpload={onFileUpload} />
      <McInput
        dynamicHeight
        type="textarea"
        value={text}
        onChange={(event) => {
          setText(event.target.value);
        }}
      />
    </div>
  );
};

export default InputUpload;
