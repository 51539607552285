import { CellStyleFunc, ColDef, ColDefField } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import {
  IConsequenceArray,
  IConsequenceWithId,
  IEvaluationCategory,
  IMatrixConfig,
  IRisikoProbability,
  IUpdateConsequence,
} from "mc-shared/zod/issueBoardSchema";
import { ICapitalLetter } from "mc-shared/zod/otherAttributesSchema";
import React from "react";
import { IBoard } from "../../../../../../../../types/global.types";
import { FailedAlert, McTitle } from "../../../../../../components";
import ViewEditTable from "../../../../../../components/ViewEditTable/ViewEditTable";
import { STANDARD_COLORS } from "../../../../../globalConstants";
import { useUpdateRisikoEvaluationCategoryMutation } from "../../../IssueBoardRTK";

const ColorMatrixSettings: React.FC<{ issueBoard: IBoard; evaluationCategoryKey: ICapitalLetter }> = ({
  issueBoard,
  evaluationCategoryKey,
}) => {
  const [updateEvaluationCategory, { error: updateEvaluationCategoryError }] =
    useUpdateRisikoEvaluationCategoryMutation();

  const pointsColumns = getRisikoPointsColumns(issueBoard);

  const pointsData: Record<string, any[]> = getRisikoPointsData(issueBoard);

  const saveCategory = async (
    prop: ColDefField<IEvaluationCategory>,
    newValue: string | boolean | IConsequenceArray | IMatrixConfig,
    id: string,
  ) => {
    const send: IUpdateConsequence = {
      projectId: issueBoard.project,
      issueBoardId: issueBoard._id,
      prop,
      newValue,
      id,
    };
    await updateEvaluationCategory(send);
  };

  const saveColors = async (
    id: string,
    data: {
      id: string;
      [key: string]: { color: string } | string;
    }[],
  ) => {
    const matrixConfig: IMatrixConfig = data.map((item) =>
      Object.entries(item).reduce((acc, [key, value]) => {
        if (key === "id" || key === "isActive") {
          return acc;
        }

        acc.push(value as { color: string });
        return acc;
      }, [] as { color: string }[]),
    );

    saveCategory("matrixConfig", matrixConfig, id);
  };

  return (
    <div className="risiko-color-tables">
      {updateEvaluationCategoryError && <FailedAlert />}
      <McTitle title="Fargeinnstillinger for matrise">
        <ViewEditTable
          columns={pointsColumns}
          data={pointsData[evaluationCategoryKey] ?? []}
          newItemTemplateObject={{}}
          onSave={(data) => saveColors(evaluationCategoryKey, data)}
          canEdit={true}
          canAddRows={false}
        />
      </McTitle>
    </div>
  );
};

export default ColorMatrixSettings;

const ColorMenuRenderer = ({ value, data }: CustomCellRendererProps) => {
  return typeof value === "string" && data?.isActive !== false ? (
    <span
      className="d-flex align-items-center justify-content-center w-100"
      style={{
        display: "inline-block",
        minWidth: "120px",
        height: "20px",
        borderRadius: "5px",
        backgroundColor: value,
        marginRight: "5px",
      }}
    >
      <span>{value === "ffffff00" && "Velg farge"}</span>
    </span>
  ) : (
    <></>
  );
};

const isActiveCellStyle: CellStyleFunc<IConsequenceWithId> = (params) => {
  if (params.data.isActive === false) {
    return { backgroundColor: "#f0f0f0" };
  }

  return {};
};

export const getRisikoPointsData = (issueBoard: IBoard): Record<string, any[]> => {
  try {
    const _data: Record<string, any[]> = Object.entries(issueBoard.risikoSection.evaluationCategoriesConfig).reduce(
      (acc, [boardId, evaluationCat]: [key: string, prob: IEvaluationCategory], propIndex) => {
        if (evaluationCat.isActive === false) {
          return acc;
        }

        if (acc[boardId] == null) {
          acc[boardId] = [];
        }

        Object.entries(evaluationCat.consequences).forEach(([key, value], i) => {
          if (acc[boardId][i] == null) {
            acc[boardId][i] = { id: value.name, isActive: value.isActive };
          }

          Object.entries(issueBoard.risikoSection.probabilites).forEach(
            ([k, probability]: [string, IRisikoProbability], rowIndex) => {
              const matricConfig = (issueBoard.risikoSection.evaluationCategoriesConfig[boardId] as IEvaluationCategory)
                ?.matrixConfig;

              acc[boardId][i][probability.name] = matricConfig[i]?.[rowIndex] ?? {
                color: "#ccc",
                isActive: value.isActive ?? true,
              };
            },
          );
        });

        return acc;
      },
      {},
    );
    return _data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

export const getRisikoPointsColumns = (issueBoard: IBoard): ColDef<Record<string, any>>[] => {
  if (issueBoard.risikoSection.probabilites == null) {
    return [];
  }

  const _cols: ColDef[] = Object.entries(issueBoard.risikoSection.probabilites).reduce(
    (acc, [key, prob]: [string, IRisikoProbability]) => {
      if (prob.isActive === false) {
        return acc;
      }

      const _col: ColDef = {
        field: prob.name + ".color",
        headerName: prob.name,
        flex: 1,
        sortable: false,
        editable: (params) => params.data.isActive,
        cellRenderer: ColorMenuRenderer,
        cellEditor: "agRichSelectCellEditor",
        cellEditorParams: {
          cellRenderer: ColorMenuRenderer,
          values: Object.values(STANDARD_COLORS),
        },
        cellStyle: isActiveCellStyle,
      };

      acc.push(_col);

      return acc;
    },
    [
      {
        field: "id",
        headerName: "ID",
        flex: 1,
        cellStyle: isActiveCellStyle,
      } as ColDef,
    ],
  );

  return _cols;
};
