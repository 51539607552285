import moment from "moment";
import React from "react";
import { formatShortDate } from "../app/routes/admin/selectors";

const DisplayDueDate: React.FC<any> = ({ dueDate, isComplete, color = "black" }) => {
  const dueDateIsPassed = moment().isAfter(dueDate);
  return (
    <>
      {dueDate != null && dueDate !== "" && (
        <div className="d-flex p-0 m-0 align-items-center">
          {dueDateIsPassed && isComplete === false && <i className="fa fa-warning fa-fw text-danger" />}
          {dueDateIsPassed === false && <i className="fa fa-clock-o fa-fw" />}
          <span style={{ color: dueDateIsPassed && isComplete === false ? "red" : color || "#a3a3a3" }}>
            {formatShortDate(dueDate)}
          </span>
        </div>
      )}
    </>
  );
};

export default DisplayDueDate;
