import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const MissingRiskoModal: React.FC<{ close: () => void }> = ({ close }) => {
  return (
    <Modal isOpen toggle={close} className="height-auto">
      <ModalHeader>Risiko mangler</ModalHeader>
      <ModalBody>Vi fant ingen risiko med denne ID-en. Den er sannsynligvis fjernet.</ModalBody>
      <ModalFooter>
        <Button onClick={() => close()}>Ok</Button>
      </ModalFooter>
    </Modal>
  );
};

export default MissingRiskoModal;
