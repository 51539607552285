import React, { useState } from "react";
import { IBoard } from "../../../../../../types/global.types";
import { Checkbox, EditSaveAbort, VSpace } from "../../../../components/";
import { ToastMessagesEnums } from "../../../../frontendConstants";
import { useAppDispatch } from "../../../../hooks";
import { ISSUE_COLUMN_LABELS } from "../issueConstants";
import { setShowSuccessMessageThunk } from "../../../../global/globalSlice";

const DisabledIssueColumns: React.FC<{
  board: IBoard;
  updateBoards: (disabledColumns: string[]) => void;
  isEditable: boolean;
  editMode?: boolean;
  editCb?: (bool: boolean) => void;
}> = ({ board, updateBoards, isEditable, editMode, editCb }) => {
  const dispatch = useAppDispatch();

  const disabledColumnColTexts = board.disabledColumns.map((col) => {
    return ISSUE_COLUMN_LABELS[col];
  });

  const [editedColumns, setEditedColumns] = useState(board.disabledColumns);

  const save = async () => {
    if (editedColumns !== board.disabledColumns) {
      dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.UPDATED));
      await updateBoards(editedColumns);
      //setViewColumns(getViewColumns(allColumns, board.disabledColumns));
    }
    editCb(false);
  };

  return (
    <div className="mb-2">
      <div className="d-flex flex-column">
        {editMode === false && (
          <div>
            {disabledColumnColTexts?.map((col, index) => (
              <div key={index}>{col}</div>
            ))}
          </div>
        )}
        {editMode && <EditDisableColumns editedColumns={editedColumns} setEditedColumns={setEditedColumns} />}
        <VSpace />
        <EditSaveAbort editable={isEditable} big saveCb={save} editMode={editMode} editModeCb={editCb} />
      </div>
    </div>
  );
};

export default DisabledIssueColumns;

const EditDisableColumns: React.FC<{
  editedColumns: string[];
  setEditedColumns: any;
}> = ({ editedColumns, setEditedColumns }) => {
  const _setColumn = (dataField: string) => {
    const index = editedColumns?.indexOf(dataField);
    let _editedColumns = [...editedColumns];
    if (index === -1) {
      _editedColumns.push(dataField);
    } else {
      _editedColumns.splice(index, 1);
    }
    setEditedColumns(_editedColumns);
  };

  const columnsTobeDisabled = [
    "referenceUrl",
    "space",
    "pns",
    "typeOfIssue",
    "bucket",
    "hours.orignalEstimate",
    "hours.completed",
    "hours.remaining",
  ];
  return (
    <div>
      {Object.entries(ISSUE_COLUMN_LABELS)
        .map(([dataField, text]) => {
          return { dataField, text };
        })
        .filter((col) => columnsTobeDisabled.indexOf(col.dataField) !== -1)
        .map((c) => {
          return (
            <div key={c.dataField}>
              <VSpace />
              <Checkbox
                mode="cross"
                text={c.text}
                key={c.dataField}
                disabled={false}
                isChecked={editedColumns?.indexOf(c.dataField) !== -1}
                setChecked={() => _setColumn(c.dataField)}
              />
            </div>
          );
        })}
    </div>
  );
};
