import React, { useEffect, useState } from "react";
import { immediateInterval } from "../app/utils";

const DateCountDown: React.FC<{
  date: Date;
}> = ({ date }) => {
  const [dateText, setDateText] = useState<string>("");

  useEffect(() => {
    if (date == null) {
      setDateText("");
      return;
    }

    let timer;
    const deadline = date.getTime();

    timer = immediateInterval(() => {
      const now = new Date().getTime();
      const timeDiff = deadline - now;
      const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

      setDateText(days + "d " + hours + "h " + minutes + "m " + seconds + "s ");

      if (timeDiff < 0) {
        clearInterval(timer);
        setDateText("Jobben kjører!");
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [date]);

  return <>{dateText}</>;
};

export default DateCountDown;
