import * as validateEmail from "email-validator";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Alert, Button, Input, Label } from "reactstrap";
import { IMember, IValueLabel } from "../../../../../../types/global.types";
import { CreateNewButton } from "../../../../components";
import { DropdownSelectorDeprecated, HSpace, InfoCircle, MCLoader, VSpace } from "../../../../components/";
import { selectUserMember, setShowSuccessMessageThunk } from "../../../../global/globalSlice";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { loadProject } from "../../../actions";
import { MEMBER_GROUPS } from "../../../globalConstants";
import { doGetProjectMembers, doSyncMulticonsultMembers } from "../../../projectApi";
import { useGetProjectQuery } from "../../../projectRTK";
import { getProjectIdFromUrl, isEmpty } from "../../../utils";
import { doAddExternalMemberToProject } from "../restQueries";
import { isEmailDomainMulticonsult } from "../selectors";

export enum DuplicateWarningEnum {
  TRUE = "TRUE",
  FALSE = "FALSE",
  ISPASSIVE = "ISPASSIVE",
}
const AddNewMember = () => {
  const dispatch = useAppDispatch();

  const [group, setGroup] = useState(null);
  const history = useHistory();
  const projectId = getProjectIdFromUrl(history.location.pathname);
  const { data: project } = useGetProjectQuery(projectId);

  const [email, setEmail] = useState("");
  const [name, setName] = useState<string>();
  const [showAddMemberForm, setShowAddForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [duplicateWarning, setDuplicateWarning] = useState<DuplicateWarningEnum>(DuplicateWarningEnum.FALSE);
  const [emailIsMulticonsult, setEmailIsMulticonsult] = useState(false);
  const [members, setMembers] = useState([]);

  const isTemplateProject = project?.maconomy?.projectNo === 100;

  const emailIsLongEmailMulticonsult = getFirstPartOfEmail(email)?.split(".").length > 1;

  useEffect(() => {
    if (project == null) {
      return;
    }

    const fetchMembers = async () => {
      const allMembers = await doGetProjectMembers(project._id);
      setMembers(allMembers);
    };

    fetchMembers();
  }, [project]);

  const sendInvitationToExternalUser = async () => {
    setLoading(true);
    await doAddExternalMemberToProject(email, group?.value, project._id, name);
    setEmail("");
    setShowAddForm(false);
    setLoading(false);

    dispatch(setShowSuccessMessageThunk("Invitasjon sendt"));
    dispatch(loadProject(project._id));
    setLoading(false);
  };

  const checkMcEmail = (email: string): boolean => {
    return emailIsMulticonsult === true && isTemplateProject && emailIsLongEmailMulticonsult === false;
  };

  const emailIsValid = validateEmail.validate(email) === true;

  const sendIsEnabled =
    (duplicateWarning === DuplicateWarningEnum.FALSE &&
      validateEmail.validate(email) === true &&
      checkMcEmail(email) === true) ||
    (isEmpty(name) === false && isEmpty(group) === false);

  const toggleForm = () => {
    setShowAddForm(!showAddMemberForm);
  };

  const _setEmail = (val: string) => {
    setEmail(val);
    if (validateEmail.validate(val)) {
      setEmailIsMulticonsult(isEmailDomainMulticonsult(val));

      const member = members.find(
        (member) =>
          member.user?.email?.toLowerCase() === val.toLowerCase() ||
          member.user?.fullEmail?.toLowerCase() === val.toLowerCase(),
      );

      if (member != null) {
        if (member.user?.ispassive) {
          setDuplicateWarning(DuplicateWarningEnum.ISPASSIVE);
        } else {
          setDuplicateWarning(DuplicateWarningEnum.TRUE);
        }
      } else {
        setDuplicateWarning(DuplicateWarningEnum.FALSE);
      }
    } else {
      setEmailIsMulticonsult(false);
      setDuplicateWarning(DuplicateWarningEnum.FALSE);
    }
  };

  const syncMembersMulticonsult = async () => {
    setLoading(true);
    await doSyncMulticonsultMembers(project._id);
    setLoading(false);
    dispatch(loadProject(project._id));
    dispatch(setShowSuccessMessageThunk("Synkronisering vellykket"));
  };

  const userMember = useAppSelector(selectUserMember);

  return (
    <>
      {loading && <MCLoader loading />}
      {showAddMemberForm === false && loading === false && (
        <div className="d-flex">
          {userMember?.group !== "CLIENT" && (
            <CreateNewButton onClick={() => setShowAddForm(!showAddMemberForm)} tooltip="Legg til bruker" />
          )}
          <HSpace />
          {(userMember?.group === "ADMIN" || userMember?.group === "INTERNAL") && isTemplateProject === false && (
            <div className="d-flex">
              <Button color="secondary" onClick={() => syncMembersMulticonsult()}>
                Synkroniser Multiconsult ansatte fra Maconomy
                <i className="fa fa-refresh fa-fw" />
              </Button>
              <HSpace />
              <InfoCircle
                text={`Siste synkronisering: ${moment(project?.lastMaconomySync).format("YYYY.MM.DD-hh:mm")}`}
              />
            </div>
          )}
        </div>
      )}
      {showAddMemberForm && loading === false && (
        <AddForm
          projectNo={project?.maconomy?.projectNo}
          group={group}
          emailIsMulticonsult={emailIsMulticonsult}
          setShowAddForm={toggleForm}
          sendInvitation={sendInvitationToExternalUser}
          email={email}
          isDuplicate={duplicateWarning}
          sendIsEnabled={sendIsEnabled}
          setEmail={_setEmail}
          name={name}
          setName={setName}
          setGroup={setGroup}
        />
      )}
      {emailIsLongEmailMulticonsult && isTemplateProject && emailIsValid && (
        <Alert color="warning">Angi kort e-post</Alert>
      )}
    </>
  );
};

interface AddFormProps {
  emailIsMulticonsult: boolean;
  setShowAddForm: () => void;
  sendIsEnabled: boolean;
  setEmail: (val: string) => void;
  email: string;
  isDuplicate: DuplicateWarningEnum;
  sendInvitation: () => Promise<void>;
  name: string;
  setName: (val: string) => void;
  setGroup: (group: IValueLabel) => void;
  group: IValueLabel | null;
  projectNo: number;
}

const AddForm: React.FC<AddFormProps> = ({
  emailIsMulticonsult,
  setShowAddForm,
  sendIsEnabled,
  setEmail,
  email,
  isDuplicate,
  sendInvitation,
  name,
  setName,
  setGroup,
  group,
  projectNo,
}) => {
  const member = useSelector(selectUserMember);

  return (
    <div style={{ width: "350px" }}>
      <Button color="secondary" onClick={() => setShowAddForm()}>
        Avbryt
      </Button>
      <VSpace />
      <div className="d-flex flex-column border p-3">
        <Label>
          Email
          <Input
            style={{ width: "300px" }}
            type="text"
            name="email"
            placeholder="Skriv inn epost"
            value={email}
            onChange={(e) => setEmail(e.target.value?.toLowerCase())}
          />
        </Label>
        {emailIsMulticonsult === false && (
          <>
            <Label>
              Navn
              <Input
                style={{ width: "300px" }}
                type="text"
                name="name"
                placeholder="Skriv inn navn"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Label>
            <Label>Gruppe</Label>
            <DropdownSelectorDeprecated
              activeItem={group}
              color="mc-blue"
              setItem={(group) => setGroup(group)}
              items={getEnabledMemberGroups(MEMBER_GROUPS, member)}
              displayKey="label"
              selectText={group == null ? "Velg gruppe" : `Gruppe: ${group.label}`}
            />
            <VSpace />
          </>
        )}
        {sendIsEnabled && (
          <Button style={{ width: "300px" }} color="primary" onClick={() => sendInvitation()}>
            Sende
          </Button>
        )}
        <VSpace />
        {emailIsMulticonsult && projectNo !== 100 && (
          <Alert color="primary">
            Multiconsult-ansatte får tilgang via Maconomy. Hvis personen har tilgang i Maconomy, men ikke er oppført
            her, kan du kontakte mcapps@multiconsult.no
          </Alert>
        )}
        <VSpace />
        {isDuplicate !== DuplicateWarningEnum.FALSE && (
          <Alert color="primary">{`E-post er allerede invitert${
            isDuplicate === DuplicateWarningEnum.ISPASSIVE ? ", men er satt til 'passiv'" : ""
          }.`}</Alert>
        )}
      </div>
    </div>
  );
};

export default AddNewMember;

const getEnabledMemberGroups = (memberGroups: IValueLabel[], member: IMember): IValueLabel[] => {
  if (member.group === "ADMIN") {
    return memberGroups.filter((group) => group.value !== "INTERNAL");
  }
  return memberGroups.filter((group) => group.value !== "INTERNAL" && group.value !== "ADMIN");
};

const getFirstPartOfEmail = (email: string): string => {
  return email.split("@")[0];
};
