export const parseHex = (inputHex: string) => {
  const hex = inputHex.replace("#", "");

  const isShort = hex.length === 3 || hex.length === 4;

  const twoDigitHexR = isShort ? `${hex.slice(0, 1)}${hex.slice(0, 1)}` : hex.slice(0, 2);
  const twoDigitHexG = isShort ? `${hex.slice(1, 2)}${hex.slice(1, 2)}` : hex.slice(2, 4);
  const twoDigitHexB = isShort ? `${hex.slice(2, 3)}${hex.slice(2, 3)}` : hex.slice(4, 6);
  const twoDigitHexA = (isShort ? `${hex.slice(3, 4)}${hex.slice(3, 4)}` : hex.slice(6, 8)) || "ff";

  return {
    red: parseInt(twoDigitHexR, 16),
    green: parseInt(twoDigitHexG, 16),
    blue: parseInt(twoDigitHexB, 16),
    alpha: parseInt(twoDigitHexA, 16),
  };
};

export const debounce = (fn: () => void, delay: number) => {
  let timer: ReturnType<typeof setTimeout>;
  return () => {
    clearTimeout(timer);
    timer = setTimeout((go) => {
      fn();
    }, delay);
  };
};
