import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, CardTitle, Modal, ModalBody, ModalHeader } from "reactstrap";
import { IStats } from "../../../../../types/stats.types";
import { McDropdown, McInput } from "../../../components";
import { MCApplicationsUrlEnum } from "../../../frontendConstants";
import { doGetProjectStats, doGetSingleProjectStats } from "./stasAPI";
import { AgGridReact } from "ag-grid-react";
import { AG_GRID_LOCALE_NOR } from "../../../agGridLocale";
import { ColDef } from "ag-grid-community";

const ProjectUsageTable: React.FC = () => {
  const [data, setData] = useState<IStats.TotalAppStarts[]>();
  const [fromDate, setFromDate] = useState<Date>(null);
  const [toDate, setToDate] = useState<Date>(new Date());
  const [appName, setAppName] = useState<string>("");
  const [projectUsage, setProjectUsage] = useState<IStats.ProjectStats>(null);
  const [showModal, setShowModal] = useState<boolean>(false);

  const appOptions = useMemo(
    () => [
      { name: "Batchjobs", value: MCApplicationsUrlEnum.BATCHJOB },
      { name: "BIM360 Explorer", value: MCApplicationsUrlEnum.BIM360 },
      { name: "BIM planvisning", value: MCApplicationsUrlEnum.BIMFLOORPLAN },
      { name: "Dashboard", value: MCApplicationsUrlEnum.DASHBOARD },
      { name: "MCCos", value: MCApplicationsUrlEnum.MCCOS },
      { name: "Møter", value: MCApplicationsUrlEnum.MEETING },
      { name: "Rapporter", value: MCApplicationsUrlEnum.REPORT },
      { name: "PNS", value: MCApplicationsUrlEnum.PNS },
      { name: "Prosjektinnstillinger", value: MCApplicationsUrlEnum.SETTINGS },
      { name: "Prosjektmedlemmer", value: MCApplicationsUrlEnum.MEMBERS },
      { name: "Risiko", value: MCApplicationsUrlEnum.RISIKO },
      { name: "Register", value: MCApplicationsUrlEnum.ISSUE },
      { name: "Sjekkliste", value: MCApplicationsUrlEnum.CHECKLIST },
      { name: "Viewer", value: MCApplicationsUrlEnum.VIEWER },
    ],
    [],
  );

  const getData = async () => {
    const _data = await doGetProjectStats(fromDate, toDate, appName);
    _data.forEach((x, i) => (x.keyField = i));
    setData(_data);
  };

  useEffect(() => {
    if (toDate >= fromDate) {
      getData();
    }
  }, [fromDate, toDate, appName]);

  useEffect(() => {
    getData();
  }, []);

  const openModal = async (projectId: string) => {
    const stats = await doGetSingleProjectStats(fromDate, toDate, projectId);
    setProjectUsage(stats);
    setShowModal(true);
  };

  const columns: ColDef[] = [
    {
      field: "projectName",
      sortable: true,
      filter: "agTextColumnFilter",
      flex: 1,
      cellRenderer: (params) => (
        <Button color="link" size="sm" onClick={() => openModal(params.data.projectId)}>
          {params.value}
        </Button>
      ),
    },
    {
      field: "count",
      initialSort: "desc",
      valueGetter: (params) => params.data.count,
    },
  ];

  return (
    <>
      <Card className="m-3 p-3">
        <CardTitle tag="h3">Antall appstarter per prosjekt</CardTitle>
        <div className="d-flex mb-3">
          <div className="d-flex flex-column mr-4" style={{ maxWidth: "15rem", width: "100%" }}>
            <h5 className="truncateHeaderText">Fra</h5>
            <McInput
              type="date"
              onChange={(e) => setFromDate(e.target.value !== "" ? new Date(e.target.value) : null)}
              value={fromDate != null ? fromDate.toISOString() : null}
            />
          </div>
          <div className="d-flex flex-column mr-4" style={{ maxWidth: "15rem", width: "100%" }}>
            <h5 className="truncateHeaderText">Til</h5>
            <McInput
              type="date"
              onChange={(e) => setToDate(e.target.value !== "" ? new Date(e.target.value) : new Date())}
              value={toDate != null ? toDate.toISOString() : null}
            />
          </div>
          <div className="d-flex flex-column mr-4" style={{ maxWidth: "15rem", width: "100%" }}>
            <h5 className="truncateHeaderText">App</h5>
            <McDropdown
              onClear={appName !== "" ? () => setAppName("") : null}
              displayKey="name"
              options={appOptions}
              currentValue={appOptions.find((a) => a.value === appName) || { name: "", value: "" }}
              onChange={(_, v) => setAppName(v.value)}
            />
          </div>
        </div>
        <div style={{ maxHeight: "50vh", overflow: "auto" }}>
          {data != null && (
            <div className="ag-theme-quartz h-100 w-100">
              <AgGridReact
                domLayout={"autoHeight"}
                rowData={data || []}
                defaultColDef={{
                  suppressHeaderMenuButton: true,
                  cellClass: "d-block",
                }}
                headerHeight={35}
                columnDefs={columns}
                suppressClickEdit={true}
                animateRows={false}
                suppressContextMenu={true}
                localeText={AG_GRID_LOCALE_NOR}
                pagination={true}
                paginationPageSize={50}
              />
            </div>
          )}
        </div>
      </Card>
      <Modal fade={false} style={{ maxWidth: "40vw" }} isOpen={showModal === true} toggle={() => setShowModal(false)}>
        <ModalHeader toggle={() => setShowModal(false)}>Project stats</ModalHeader>
        <ModalBody>
          <div className="d-flex flex-column">
            <h4>Brukere (TOP 10)</h4>
            {projectUsage?.users.map((user) => (
              <div className="d-flex">
                <div className="w-100">{user.name}</div>
                <div>{user.count}</div>
              </div>
            ))}
            <h4 className="mt-2">Appstarter</h4>
            {projectUsage?.appStarts.map((app) => (
              <div className="d-flex">
                <div className="w-100">{app.name}</div>
                <div>{app.count}</div>
              </div>
            ))}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ProjectUsageTable;
