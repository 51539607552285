import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import React, { useMemo, useState } from "react";
import { IBIM360 } from "../../../../types/bim360.types";
import { AG_GRID_LOCALE_NOR } from "../../agGridLocale";
import Avatar from "../Avatar";
import McInput from "../McInput/McInput";
import ExcelExport from "../ExcelExport/ExcelExport";
import { HSpace } from "../index";

const Bim360UsersTable: React.FC<{ bim360Users: IBIM360.User[]; bim360Companies: IBIM360.Company[] }> = ({
  bim360Users,
  bim360Companies,
}) => {
  const usersPopulated: IBIM360.UserPopulated[] = useMemo(() => {
    const _userPopulated: IBIM360.UserPopulated[] = bim360Users.map((user) => {
      return {
        ...user,
        company: bim360Companies.find((company) => company.id === user.companyId),
      };
    });
    return _userPopulated;
  }, [bim360Users, bim360Companies]);

  const columns: ColDef<IBIM360.UserPopulated>[] = useMemo(() => {
    return [
      {
        field: "id",
        headerName: "memberId",
        hide: true,
      },
      {
        field: "name",
        flex: 2,
        filter: "agTextColumnFilter",
        minWidth: 160,
        menuTabs: ["filterMenuTab"],
        headerName: "Navn",
        cellRenderer: (params) => {
          return (
            <div className="d-flex pointer hoverText align-items-center">
              {params.data.id && <Avatar id={params.data.id} />}
              <div className="two-line-text-truncate">{params.value}</div>
            </div>
          );
        },
      },
      {
        field: "email",
        headerName: "Epost",
      },
      {
        field: "company.name",
        headerName: "Selskap",
      },
      {
        field: "status",
        headerName: "Status",
      },
      {
        field: "jobTitle",
        headerName: "Tittel",
      },
      {
        field: "addedOn",
        headerName: "Lagt til",
      },
    ];
  }, [usersPopulated]);

  const defaultColDef = {
    suppressHeaderMenuButton: true,
    minWidth: 120,
    flex: 1,
    resizable: true,
    cellStyle: {
      whiteSpace: "normal",
      lineHeight: "1.5",
      wordBreak: "break-word",
    },
  };
  const [filterText, setFilterText] = useState<string>("");

  return (
    <div className="d-flex flex-column w-100">
      <div className="d-flex">
        <ExcelExport
          columns={columns.filter((col, index) => index > 1)}
          data={usersPopulated}
          filePrefix="Prosjektmedlemmer"
          isAgGrid={true}
        />
        <HSpace />
        <div className="d-flex">
          <McInput
            type="text"
            placeholder="Søk"
            className="mb-2"
            onChange={(e) => setFilterText(e.target.value)}
            style={{ maxWidth: "300px" }}
          />
        </div>
      </div>
      <div className="ag-theme-quartz h-100 w-100" id="myGrid">
        <AgGridReact
          cellSelection={true}
          rowData={usersPopulated}
          getRowId={(p) => p.data.id}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          animateRows={true}
          suppressContextMenu={true}
          localeText={AG_GRID_LOCALE_NOR}
          pagination={true}
          paginationPageSize={50}
          rowBuffer={50}
          quickFilterText={filterText}
        />
      </div>
    </div>
  );
};

export default Bim360UsersTable;
