import React, { useEffect, useRef, useState } from "react";
import { formatHtmlInputDateString } from "../../app/routes/admin/selectors";
import "./McInput.css";

export type TMcInput = React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> & {
  dynamicHeight?: boolean;
  forceBlur?: boolean;
  normalizeNumbers?: boolean;
};

const McInput: React.FC<TMcInput> = ({
  value,
  dynamicHeight,
  onChange,
  onBlur,
  onKeyDown,
  onKeyUp,
  type = "text",
  pattern,
  className,
  forceBlur = false,
  normalizeNumbers = false,
  ...props
}) => {
  const [internalValue, setInternalValue] = useState<string>(String(value) || "");
  let propagateValueOnBlur = useRef(true);

  useEffect(() => {
    setInternalValue(value == null ? "" : String(value));
  }, [value]);

  const inputRef = useRef(null);
  const areaRef = useRef(null);

  useEffect(() => {
    updateTextAreaHeight();

    if (props.autoFocus != null && props.autoFocus !== false) {
      inputRef.current?.focus();
      areaRef.current?.focus();

      if (inputRef.current != null) {
        const v = inputRef.current.value;
        inputRef.current.value = "";
        inputRef.current.value = v;
      }

      if (areaRef.current != null) {
        const v = areaRef.current.value;
        areaRef.current.value = "";
        areaRef.current.value = v;
      }
    }
  }, []);

  const checkKey = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    propagateValueOnBlur.current = true;

    if (e.key === "Enter") {
      onKeyDown?.(e);
      if (type === "textarea") {
        if (e.shiftKey === true) {
          (e.target as HTMLElement).blur();
        }
        return;
      }

      (e.target as HTMLElement).blur();
      return;
    }

    if (e.key === "Escape") {
      e.stopPropagation();
      propagateValueOnBlur.current = forceBlur;
      setInternalValue(value != null ? value?.toString() : "");
      (e.target as HTMLElement).blur();
      return;
    }

    onKeyDown?.(e as React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>);
  };

  const stopEscOnKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === "Escape") {
      e.stopPropagation();
      return;
    }

    onKeyUp?.(e as React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>);
  };

  const valueChanged = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    if (normalizeNumbers === true) {
      e.target.value = e.target.value.replace(",", ".");
    }

    if (
      onChange != null &&
      e.target.value !== internalValue &&
      (pattern != null ? e.target.value.match(pattern) != null : true)
    ) {
      if (normalizeNumbers !== true) {
        onChange(e);
      }
      // do not run onchange if the value is not a number when normalizeNumbers is true
      else if (isNaN(Number(e.target.value)) === false) {
        onChange(e);
      }
    }

    updateTextAreaHeight();

    setInternalValue(e.target.value || "");
  };

  const updateTextAreaHeight = () => {
    if (dynamicHeight === true && areaRef.current != null) {
      (areaRef.current as HTMLTextAreaElement).style.height = "auto";
      (areaRef.current as HTMLTextAreaElement).style.height =
        (areaRef.current as HTMLTextAreaElement).scrollHeight + "px";
    }
  };

  const blurOverride = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (
      onBlur != null &&
      e.target.validity.valid === true &&
      propagateValueOnBlur.current === true &&
      (value !== internalValue || forceBlur === true) &&
      (pattern != null ? e.target.value.match(pattern) != null : true)
    ) {
      onBlur(e);
    }
  };

  return type === "textarea" ? (
    <textarea
      className={`mcinput${className != null ? " " + className : ""}`}
      {...props}
      ref={areaRef}
      onChange={valueChanged}
      onBlur={blurOverride}
      onKeyDown={checkKey}
      onKeyUp={stopEscOnKeyUp}
      value={internalValue}
    />
  ) : (
    <input
      className={`mcinput${className != null ? " " + className : ""}`}
      {...props}
      ref={inputRef}
      pattern={pattern}
      type={type}
      value={type === "date" ? formatHtmlInputDateString(internalValue) : internalValue}
      onChange={valueChanged}
      onBlur={blurOverride}
      onKeyDown={checkKey}
      onKeyUp={stopEscOnKeyUp}
      onWheel={(e) => {
        if (type === "number" && inputRef && inputRef?.current && inputRef?.current?.blur) {
          inputRef?.current?.blur();
        }
      }}
    />
  );
};

export default McInput;
