import "./CustomIconsStyles.css";

export const ActionIcon = (props) => {
  const { style, className } = props;
  return (
    <svg
      style={style}
      className={`${className} customIcon`}
      viewBox="0 0 200 200"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M56 85H126.5L95.5 54L133 44.5L189 105.5L133 165.5L95.5 156.5L126.5 125H38.5L13 101.5V66.5H53V82L56 85Z" />
      <rect x="13" y="35" width="41" height="20" />
    </svg>
  );
};

export const IssueIcon = (props) => {
  const { style, className } = props;
  return (
    <svg
      viewBox="0 0 130 113"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={`${className} customIcon`}
    >
      <rect width="27" height="27" />
      <rect x="40" y="4" width="93" height="18" fillOpacity="0.7" />
      <rect y="88" width="27" height="27" />
      <rect x="40" y="92" width="93" height="18" fillOpacity="0.7" />
      <rect y="44" width="27" height="27" />
      <rect x="40" y="48" width="93" height="18" fillOpacity="0.7" />
    </svg>
  );
};

export const RisikoIcon = (props) => {
  const { style, className } = props;
  return (
    <svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={`${className} customIcon`}
    >
      <path
        d="M161.522 36.0138C163.462 36.0171 165.031 37.5749 165.028 39.4932L164.972 68.8596C164.968 70.7779 163.392 72.3303 161.452 72.327L131.755 72.276C129.815 72.2726 128.245 70.7148 128.249 68.7965L128.305 39.4302C128.309 37.5118 129.885 35.9594 131.825 35.9627L161.522 36.0138Z"
        fillOpacity="0.5"
      />
      <path d="M115.39 35.966C117.33 35.9693 118.9 37.5271 118.896 39.4455L118.84 68.8118C118.836 70.7301 117.26 72.2825 115.32 72.2792L85.6231 72.2282C83.6832 72.2249 82.1135 70.6671 82.1172 68.7487L82.1734 39.3824C82.1771 37.4641 83.7527 35.9116 85.6926 35.915L115.39 35.966Z" />
      <path d="M69.2582 35.9181C71.1982 35.9215 72.7678 37.4793 72.7642 39.3976L72.708 68.7639C72.7043 70.6823 71.1287 72.2347 69.1888 72.2313L39.4915 72.1803C37.5515 72.177 35.9818 70.6192 35.9855 68.7009L36.0417 39.3345C36.0454 37.4162 37.621 35.8638 39.5609 35.8671L69.2582 35.9181Z" />
      <path d="M161.463 82.3643C163.403 82.3676 164.972 83.9255 164.969 85.8438L164.912 115.21C164.909 117.128 163.333 118.681 161.393 118.678L131.696 118.627C129.756 118.623 128.186 117.065 128.19 115.147L128.246 85.7807C128.25 83.8624 129.825 82.31 131.765 82.3133L161.463 82.3643Z" />
      <path
        d="M115.331 82.3167C117.271 82.32 118.841 83.8779 118.837 85.7962L118.781 115.163C118.777 117.081 117.201 118.633 115.261 118.63L85.5642 118.579C83.6242 118.576 82.0546 117.018 82.0582 115.099L82.1144 85.7331C82.1181 83.8148 83.6937 82.2624 85.6336 82.2657L115.331 82.3167Z"
        fillOpacity="0.5"
      />
      <path d="M69.1991 82.2688C71.1391 82.2722 72.7087 83.83 72.7051 85.7483L72.6489 115.115C72.6452 117.033 71.0696 118.585 69.1296 118.582L39.4323 118.531C37.4924 118.528 35.9227 116.97 35.9264 115.052L35.9826 85.6852C35.9863 83.7669 37.5619 82.2145 39.5018 82.2178L69.1991 82.2688Z" />
      <path d="M161.404 128.715C163.344 128.718 164.913 130.276 164.91 132.195L164.853 161.561C164.85 163.479 163.274 165.032 161.334 165.028L131.637 164.977C129.697 164.974 128.127 163.416 128.131 161.498L128.187 132.131C128.191 130.213 129.767 128.661 131.706 128.664L161.404 128.715Z" />
      <path d="M115.272 128.667C117.212 128.671 118.782 130.228 118.778 132.147L118.722 161.513C118.718 163.431 117.142 164.984 115.202 164.981L85.5052 164.93C83.5652 164.926 81.9956 163.368 81.9992 161.45L82.0554 132.084C82.0591 130.165 83.6347 128.613 85.5747 128.616L115.272 128.667Z" />
      <path
        d="M69.1403 128.62C71.0803 128.623 72.6499 130.181 72.6463 132.099L72.5901 161.466C72.5864 163.384 71.0108 164.936 69.0708 164.933L39.3735 164.882C37.4336 164.879 35.8639 163.321 35.8676 161.402L35.9238 132.036C35.9275 130.118 37.5031 128.565 39.443 128.569L69.1403 128.62Z"
        fillOpacity="0.5"
      />
    </svg>
  );
};

export const EllipsisButton = (props) => {
  const { style, className, onClick } = props;
  return (
    <div className="ellipsisButton" onClick={onClick}>
      <svg
        width="50"
        height="200"
        viewBox="0 0 50 200"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        style={style}
        className={`${className} customIcon`}
      >
        <path d="M50 25C50 38.8071 38.8071 50 25 50C11.1929 50 0 38.8071 0 25C0 11.1929 11.1929 0 25 0C38.8071 0 50 11.1929 50 25Z" />
        <path d="M50 175C50 188.807 38.8071 200 25 200C11.1929 200 0 188.807 0 175C0 161.193 11.1929 150 25 150C38.8071 150 50 161.193 50 175Z" />
        <path d="M50 100C50 113.807 38.8071 125 25 125C11.1929 125 0 113.807 0 100C0 86.1929 11.1929 75 25 75C38.8071 75 50 86.1929 50 100Z" />
      </svg>
    </div>
  );
};
export const MoveIssueIcon = (props) => {
  const { style, className } = props;
  return (
    <svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={`${className} customIcon`}
    >
      <path d="M23.7692 72.9111C20.0189 72.9111 17 75.9306 17 79.6808V114.335C17 118.085 20.0189 121.104 23.7692 121.104H74.5875C78.3378 121.104 81.3567 118.085 81.3567 114.335V79.6808C81.3567 75.9306 78.3378 72.9111 74.5875 72.9111H23.7692ZM32.8403 88.7156H65.5159V105.3H32.8403V88.7156Z" />
      <path d="M23.7692 139.822C20.0189 139.822 17 142.842 17 146.592V181.246C17 184.996 20.0189 188.015 23.7692 188.015H74.5875C78.3378 188.015 81.3567 184.996 81.3567 181.246V146.592C81.3567 142.842 78.3378 139.822 74.5875 139.822H23.7692ZM32.8403 155.627H65.5159V172.211H32.8403V155.627Z" />
      <path d="M74.5873 6H23.7694C20.0307 6 17 9.03075 17 12.7694V47.4231C17 51.1617 20.0307 54.1925 23.7694 54.1925H74.5873C78.326 54.1925 81.3567 51.1617 81.3567 47.4231V12.7694C81.3567 9.03075 78.326 6 74.5873 6Z" />
      <path d="M99.6696 21.7222V38.3367H152.471C152.471 38.3367 158.549 40.2704 161.829 43.55C165.605 47.326 166.385 54.3566 166.385 54.3566V137.853C166.385 142.198 164.078 146.575 161.161 149.795C158.75 152.456 155.004 154.381 151.414 154.381H136.532L147.203 143.872L135.328 132.361L104.84 162.769L135.262 193.167L146.983 181.965L136.284 171.242L150.311 170.996C160.412 170.818 166.277 169.548 173.421 162.404C181.073 154.752 183 149.354 183 138.532V51.7805C183 42.9082 178.491 37.9508 175.257 34.1922C171.215 29.4941 164.014 21.7222 150.717 21.7222H99.6696Z" />
    </svg>
  );
};

export const ToggleRTEIcon = (props) => {
  const { style, className } = props;
  return (
    <svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      style={style}
      className={`${className} customIcon`}
    >
      <path d="M0 152C0 149.791 1.79086 148 4 148H150C152.209 148 154 149.791 154 152V163C154 165.209 152.209 167 150 167H4C1.79086 167 0 165.209 0 163V152Z" />
      <path d="M0 113.5C0 111.291 1.79086 109.5 4 109.5H186.5C188.709 109.5 190.5 111.291 190.5 113.5V124.5C190.5 126.709 188.709 128.5 186.5 128.5H4.00001C1.79087 128.5 0 126.709 0 124.5V113.5Z" />
      <path d="M106.5 77C106.5 74.7909 108.291 73 110.5 73H174C176.209 73 178 74.7909 178 77V88C178 90.2091 176.209 92 174 92H110.5C108.291 92 106.5 90.2091 106.5 88V77Z" />
      <path d="M106.5 41.5C106.5 39.2909 108.291 37.5 110.5 37.5H146C148.209 37.5 150 39.2909 150 41.5V52.5C150 54.7091 148.209 56.5 146 56.5H110.5C108.291 56.5 106.5 54.7091 106.5 52.5V41.5Z" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25 95L32 73H66L73.5 95H95L62 0H36.5L3.5 95H25ZM36.5 58H61.5L49.5 21.5L36.5 58Z"
      />
    </svg>
  );
};

export const SendIcon = (props) => {
  const { style, className } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 20 16"
      style={{ ...style, width: "1.4em", height: "1.2em" }}
      className={`${className} customIcon`}
    >
      <path
        d="M15.964 0.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z"
        transform="rotate(45 8.5 8.5)"
      />
    </svg>
  );
};

export const SortAlphabeticIcon = (props) => {
  const { style, className } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      style={{ ...style, width: "1.4em", height: "1.2em" }}
      className={`${className} customIcon`}
      viewBox="0 0 448 512"
    >
      <path d="M176 352h-48V48a16 16 0 0 0 -16-16H80a16 16 0 0 0 -16 16v304H16c-14.2 0-21.4 17.2-11.3 27.3l80 96a16 16 0 0 0 22.6 0l80-96C197.4 369.3 190.2 352 176 352zm240-64H288a16 16 0 0 0 -16 16v32a16 16 0 0 0 16 16h56l-61.3 70.5A32 32 0 0 0 272 446.4V464a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16v-32a16 16 0 0 0 -16-16h-56l61.3-70.5A32 32 0 0 0 432 321.6V304a16 16 0 0 0 -16-16zm31.1-85.4l-59.3-160A16 16 0 0 0 372.7 32h-41.4a16 16 0 0 0 -15.1 10.6l-59.3 160A16 16 0 0 0 272 224h24.8a16 16 0 0 0 15.2-11.1l4.4-12.9h71l4.4 12.9A16 16 0 0 0 407.2 224H432a16 16 0 0 0 15.1-21.4zM335.6 144L352 96l16.4 48z" />
    </svg>
  );
};
