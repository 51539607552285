import { ReactElement, useEffect, useState } from "react";
import { McDropdownBase } from "..";
import { IMcDropdownBase } from "../McDropdownBase/McDropdownBase";

export type IMcDropdownGeneric<T extends Record<string, any>> = Omit<IMcDropdownBase, "onChange" | "children"> & {
  currentValue: T;
  onChange: (oldValue: T, newValue: T) => void;
  options: T[];
  displayKey: keyof T;
  selectedDisplayKey?: keyof T;
  type?: "text" | "colordot";
  defaultTitle?: string;
  formatter?: (option: T[keyof T]) => string;
};

export type IMcDropdownString = Omit<IMcDropdownBase, "onChange" | "children"> & {
  currentValue: string;
  onChange: (oldValue: string, newValue: string) => void;
  options: string[];
  type?: "text" | "colordot";
  defaultTitle?: string;
  formatter?: (option: string) => string;
};

export function McDropdown(props: IMcDropdownString): JSX.Element;
export function McDropdown<T extends Record<string, any>>(props: IMcDropdownGeneric<T>): JSX.Element;
export function McDropdown<T>(props: T extends string ? IMcDropdownString : IMcDropdownGeneric<T>): JSX.Element {
  const [triggerElement, setTriggerElement] = useState<ReactElement>(null);
  const [optionElements, setOptionElements] = useState<ReactElement[]>(null);

  const [displayCurrentValue, setDisplayCurrentValue] = useState<string>("");
  const [displayOptions, setDisplayOptions] = useState<string[]>([]);
  const [intlOptions, setIntlOptions] = useState<T[]>([]);
  const [filterValue, setFilterValue] = useState<string>("");

  const {
    currentValue,
    onChange,
    options,
    displayKey,
    selectedDisplayKey,
    type = "text",
    defaultTitle,
    formatter,
    ...restProps
  } = props as IMcDropdownGeneric<T>;

  useEffect(() => {
    try {
      if (
        displayKey != null &&
        displayKey !== "" &&
        options != null &&
        options.length > 0 &&
        typeof options[0] === "object"
      ) {
        const opts = options.reduce(
          (acc, o, index) => {
            const value: string = formatter != null ? formatter(o[displayKey]) : String(o[displayKey]);
            if (
              filterValue === "" ||
              value.trim()?.toLocaleLowerCase().includes(filterValue.trim()?.toLocaleLowerCase())
            ) {
              acc[0].push(o);
              acc[1].push(value);
            }

            return acc;
          },
          [[], []] as [T[], string[]],
        );
        setIntlOptions(opts[0]);
        setDisplayOptions(opts[1]);
      } else if (options != null && options.length > 0 && typeof options[0] === "string") {
        const opts = (options as unknown as string[]).reduce(
          (acc, o, index) => {
            if (filterValue === "" || o.trim()?.toLocaleLowerCase().includes(filterValue.trim()?.toLocaleLowerCase())) {
              acc[0].push(o as unknown as T);
              acc[1].push(o);
            }

            return acc;
          },
          [[], []] as [T[], string[]],
        );
        setIntlOptions(opts[0]);
        setDisplayOptions(opts[1]);
      }

      if (
        selectedDisplayKey != null &&
        selectedDisplayKey !== "" &&
        currentValue != null &&
        typeof currentValue === "object"
      ) {
        setDisplayCurrentValue(
          formatter == null
            ? String(currentValue[selectedDisplayKey]) || ""
            : formatter(currentValue[selectedDisplayKey]),
        );
      } else if (displayKey != null && displayKey !== "" && currentValue != null && typeof currentValue === "object") {
        setDisplayCurrentValue(
          formatter == null ? String(currentValue[displayKey]) || "" : formatter(currentValue[displayKey]),
        );
      } else if (typeof currentValue === "string") {
        setDisplayCurrentValue(formatter == null ? currentValue : formatter(currentValue[displayKey]));
      }
    } catch (error) {
      console.error(error);
    }
  }, [options, currentValue, filterValue]);

  useEffect(() => {
    let _optionElements: ReactElement[] = null;
    let _triggerElement: ReactElement = null;

    switch (type) {
      case "colordot":
        const _options = options as unknown as (T & { color: string })[];
        _triggerElement = (
          <div className="d-flex align-items-center">
            <div
              className="statusdot mr-2"
              style={{
                backgroundColor:
                  _options.find(
                    (c) =>
                      String(c[selectedDisplayKey ?? displayKey]).toLowerCase() === displayCurrentValue?.toLowerCase(),
                  )?.color || "transparent",
              }}
            ></div>
            <div className="truncateHeaderText">{currentValue == null ? defaultTitle : displayCurrentValue}</div>
          </div>
        );

        _optionElements = _options.map((option, index) => (
          <McDropdownBase.Option
            key={index}
            selected={option === currentValue}
            onClick={() => onChange(currentValue, intlOptions[index])}
          >
            <div className="d-flex align-items-center">
              <div
                className="statusdot mr-2"
                style={{
                  backgroundColor: _options.find(
                    (c) => String(c[displayKey] ?? "???").toLowerCase() === String(option[displayKey]).toLowerCase(),
                  ).color,
                }}
              ></div>
              <div className="truncateHeaderText">{option[displayKey]}</div>
            </div>
          </McDropdownBase.Option>
        ));
        break;
      default:
        _triggerElement = (
          <div className="truncateHeaderText">{currentValue == null ? defaultTitle : displayCurrentValue}</div>
        );
        _optionElements = displayOptions.map((option, index) => (
          <McDropdownBase.Option
            key={index}
            selected={option === displayCurrentValue}
            onClick={() => onChange(currentValue, intlOptions[index])}
          >
            <div className="truncateHeaderText">{option}</div>
          </McDropdownBase.Option>
        ));
        break;
    }

    setTriggerElement(_triggerElement);
    setOptionElements(_optionElements);
  }, [displayOptions, displayCurrentValue]);

  return (
    <McDropdownBase onFilter={(val) => setFilterValue(val)} onToggle={() => setFilterValue("")} {...restProps}>
      <McDropdownBase.Display>{triggerElement}</McDropdownBase.Display>
      {optionElements}
    </McDropdownBase>
  );
}

export default McDropdown;
