import {
  MAALING_AV_AVTREKKSMENGDE_METODER,
  MaalingAvAvtrekksmengdeEnum,
} from "mc-shared/zod/report/GK/gkReportConstants";
import { IGKMaalingAvAvtrekksmengdeIgangkjoeringResultater, IGKRapport } from "mc-shared/zod/report/GK/gkReportSchema";
import { useEffect } from "react";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../../types/global.types";
import { DropdownFilterGeneric, HSpace, McInput } from "../../../../../../components";
import ReportDocxImageContainer from "../../../ReportBoard/ReportDocxImageContainer";
import {
  beregnetGjennomsnittligHastighet,
  beregnetLuftmengdeGjennomLukeaapning,
  beregnetStandardAvvik,
  beregnetTverrsnittareal,
  beregnetVarians,
} from "../gkGlobalUtils";

const MaalingAvAvtrekksmengdeIgangkjoering: React.FC<{
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
  data: IGKMaalingAvAvtrekksmengdeIgangkjoeringResultater;
}> = ({ data, onUpdateEditData }) => {
  useEffect(() => {
    utdataBeregninger();
  }, [
    data.inndata.lukeaapningResultater.maaleresultater,
    data.inndata.lukeaapningResultater.arealLukeaapning,
    data.inndata.differanseTrykkResultater.diameterPaaAvtrekkskanal,
    data.inndata.lukeaapningResultater.maaleresultater.length,
    data.inndata.valgtMetode[MaalingAvAvtrekksmengdeEnum.LUFTHASTIGHETSMAALING],
    data.inndata.valgtMetode[MaalingAvAvtrekksmengdeEnum.DIFFERANSETRYKK],
  ]);

  const addOrRemoveTest = (test: MaalingAvAvtrekksmengdeEnum) => {
    data.inndata.valgtMetode[test] = !data.inndata.valgtMetode[test];
    onUpdateEditData(
      "testResultater.maalingAvAvtrekksmengdeIgangkjoering.inndata.valgtMetode",
      data.inndata.valgtMetode,
    );
  };

  const addNewRowLukeaapning = () => {
    const maaleresultaterArray = data.inndata.lukeaapningResultater.maaleresultater;
    maaleresultaterArray.push(null);

    onUpdateEditData(
      "testResultater.maalingAvAvtrekksmengdeIgangkjoering.inndata.lukeaapningResultater.maaleresultater",
      maaleresultaterArray,
    );

    return maaleresultaterArray;
  };

  const removeLastRowLukeaapning = () => {
    const updatedMaaleresultater = data.inndata.lukeaapningResultater.maaleresultater;

    if (updatedMaaleresultater.length > 0) {
      updatedMaaleresultater.pop(); // Remove the last element
      onUpdateEditData(
        "testResultater.maalingAvAvtrekksmengdeIgangkjoering.inndata.lukeaapningResultater.maaleresultater",
        updatedMaaleresultater,
      );
    }
  };

  const updateMaaleresultaterLukeaapning = (resultat, i) => {
    const maaleresultater = [...data.inndata.lukeaapningResultater.maaleresultater];
    maaleresultater[i] = resultat;
    onUpdateEditData(
      "testResultater.maalingAvAvtrekksmengdeIgangkjoering.inndata.lukeaapningResultater.maaleresultater",
      maaleresultater,
    );
  };

  const addNewRowDifferansetrykk = () => {
    const maaleresultaterArray = data.inndata.differanseTrykkResultater.maaleresultaterMaalenipler;
    maaleresultaterArray.push(null);

    onUpdateEditData(
      "testResultater.maalingAvAvtrekksmengdeIgangkjoering.inndata.differanseTrykkResultater.maaleresultaterMaalenipler",
      maaleresultaterArray,
    );

    return maaleresultaterArray;
  };

  const removeLastRowDifferansetrykk = () => {
    const updatedMaaleresultater = data.inndata.differanseTrykkResultater.maaleresultaterMaalenipler;

    if (updatedMaaleresultater.length > 0) {
      updatedMaaleresultater.pop(); // Remove the last element
      onUpdateEditData(
        "testResultater.maalingAvAvtrekksmengdeIgangkjoering.inndata.differanseTrykkResultater.maaleresultaterMaalenipler",
        updatedMaaleresultater,
      );
    }
  };

  const updateMaaleresultaterDifferansetrykk = (resultat, i) => {
    const maaleresultater = [...data.inndata.differanseTrykkResultater.maaleresultaterMaalenipler];
    maaleresultater[i] = resultat;
    onUpdateEditData(
      "testResultater.maalingAvAvtrekksmengdeIgangkjoering.inndata.differanseTrykkResultater.maaleresultaterMaalenipler",
      maaleresultater,
    );
  };

  const utdataBeregninger = () => {
    let gjennomsnittligHastighet: number = null;
    let varians: number = null;
    let standardAvvik: number = null;
    let avtrekksmengde: number = null;
    let tverrsnittareal: number = null;
    if (data.inndata.valgtMetode[MaalingAvAvtrekksmengdeEnum.LUFTHASTIGHETSMAALING] === true) {
      gjennomsnittligHastighet = beregnetGjennomsnittligHastighet(
        data.inndata.lukeaapningResultater.maaleresultater.map((resultat) => resultat),
        data.inndata.lukeaapningResultater.maaleresultater.length,
      );
      varians = beregnetVarians(
        data.inndata.lukeaapningResultater.maaleresultater.map((resultat) => resultat),
        gjennomsnittligHastighet,
      );
      standardAvvik = beregnetStandardAvvik(varians);
      avtrekksmengde = beregnetLuftmengdeGjennomLukeaapning(
        gjennomsnittligHastighet,
        data.inndata.lukeaapningResultater.arealLukeaapning,
      );
    }
    if (data.inndata.valgtMetode[MaalingAvAvtrekksmengdeEnum.DIFFERANSETRYKK] === true) {
      tverrsnittareal = beregnetTverrsnittareal(data.inndata.differanseTrykkResultater.diameterPaaAvtrekkskanal);
    }
    const utdata = {
      lukeaapningBeregninger: {
        gjennomsnittligHastighet: gjennomsnittligHastighet,
        varians: varians,
        standardAvvik: standardAvvik,
        avtrekksmengde: avtrekksmengde,
      },
      differanseTrykkBeregninger: {
        tverrsnittareal: tverrsnittareal,
      },
    };
    onUpdateEditData("testResultater.maalingAvAvtrekksmengdeIgangkjoering.utdata", utdata);
  };

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Måling av avtrekksmengde ved igangkjøring</CardTitle>
        <b>Velg hvilke metoder som skal brukes?</b>
        <div className="w-25 mb-4">
          <DropdownFilterGeneric
            filterOptions={MAALING_AV_AVTREKKSMENGDE_METODER.map((test) => {
              return {
                ...test,
                isActive: data.inndata.valgtMetode[test.value],
              };
            })}
            onClick={(value) => addOrRemoveTest(value)}
            title="Velg metoder"
            color="secondary"
            textColor="text-black"
          />
        </div>
        {data.inndata.valgtMetode[MaalingAvAvtrekksmengdeEnum.LUFTHASTIGHETSMAALING] === true && (
          <>
            <h4>Lufthastighetsmåling</h4>
            <div className="d-flex flex-column mb-4">
              <b>Fyll inn spesifisert lufthastighet fra fabrikant &#40;m/s&#41;:</b>
              <McInput
                value={data.inndata.lukeaapningResultater.lufthastighetFraFabrikant}
                onBlur={(e) =>
                  onUpdateEditData(
                    "testResultater.maalingAvAvtrekksmengdeIgangkjoering.inndata.lukeaapningResultater.lufthastighetFraFabrikant",
                    Number(e.target.value),
                  )
                }
                normalizeNumbers={true}
                type="text"
                className="w-25"
              />
            </div>
            <div className="d-flex flex-column mt-4 mb-4">
              <b>
                Fyll inn lukeåpningsareal &#40;m<sup>2</sup>&#41;:
              </b>
              <McInput
                value={data.inndata.lukeaapningResultater.arealLukeaapning}
                onBlur={(e) =>
                  onUpdateEditData(
                    "testResultater.maalingAvAvtrekksmengdeIgangkjoering.inndata.lukeaapningResultater.arealLukeaapning",
                    Number(e.target.value),
                  )
                }
                normalizeNumbers={true}
                type="text"
                className="w-25"
              />
            </div>
            <div className="d-flex flex-column mt-2">
              <b>Måletiden er 60 sekunder &#40;s&#41;:</b>
            </div>
            <div className="mt-2">
              <b>Legg til flere punkter ved behov:</b>
              <div className="d-flex">
                <Button color="success" onClick={addNewRowLukeaapning}>
                  Legg til rad
                </Button>
                <HSpace />
                <Button
                  color="danger"
                  onClick={removeLastRowLukeaapning}
                  disabled={data.inndata.lukeaapningResultater.maaleresultater.length < 10}
                >
                  Fjern siste rad
                </Button>
              </div>
            </div>
            <div className="mt-4 d-flex flex-column">
              <b>Fyll inn måleresultater &#40;m/s&#41;:</b>
              {data.inndata.lukeaapningResultater.maaleresultater.map((a, i) => (
                <McInput
                  className="mb-1 w-25"
                  key={i}
                  value={a}
                  normalizeNumbers={true}
                  type="text"
                  onBlur={(e) => updateMaaleresultaterLukeaapning(Number(e.target.value), i)}
                />
              ))}
            </div>
            <div className="d-flex justify-content-between">
              <p className="mb-0">
                Gjennomsnittlig hastighet &#40;m/s&#41; ={" "}
                <b>
                  {beregnetGjennomsnittligHastighet(
                    data.inndata.lukeaapningResultater.maaleresultater.map((resultat) => resultat),
                    data.inndata.lukeaapningResultater.maaleresultater.length,
                  )?.toFixed(3) ?? ""}
                </b>
              </p>
              <p className="mb-0">Formel: &#40;Va = &#40;SUM Vn&#41; / N&#41;</p>
            </div>
            <hr className="mt-0" />
            <div className="d-flex justify-content-between">
              <p className="mb-0">
                Varians &#40;-&#41; =
                <b>
                  {" "}
                  {beregnetVarians(
                    data.inndata.lukeaapningResultater.maaleresultater.map((resultat) => resultat),
                    data.utdata.lukeaapningBeregninger.gjennomsnittligHastighet,
                  )?.toFixed(4) ?? ""}
                </b>
              </p>
              <p className="mb-0">
                Formel: &#40;VRS = &#40;&#40;SUM Vn<sup>2</sup>&#41; - N * &#40;Va<sup>2</sup>&#41;&#41; / &#40;N -
                1&#41;&#41;
              </p>
            </div>
            <hr className="mt-0" />
            <div className="d-flex justify-content-between">
              <p className="mb-0">
                Standardavvik &#40;-&#41; =
                <b> {beregnetStandardAvvik(data.utdata.lukeaapningBeregninger.varians)?.toFixed(4) ?? ""}</b>
              </p>
              <p className="mb-0">
                Formel: &#40;Sigma = VRS<sup>0,5</sup>&#41;
              </p>
            </div>
            <hr className="mt-0" />
            <div className="d-flex justify-content-between">
              <p className="mb-0">
                Avtrekksluftmengde &#40;m<sup>3</sup>/s&#41; ={" "}
                <b>
                  {beregnetLuftmengdeGjennomLukeaapning(
                    data.utdata.lukeaapningBeregninger.gjennomsnittligHastighet,
                    data.inndata.lukeaapningResultater.arealLukeaapning,
                  )?.toFixed(3) ?? ""}
                </b>
              </p>
              <p className="mb-0">Formel: &#40;Q = Va * Aluke&#41;</p>
            </div>
            <hr className="mt-0" />
            <div className="w-50 mb-4">
              <b>Kommentar:</b>
              <McInput
                value={data.inndata.lukeaapningResultater.kommentar}
                onChange={(e) =>
                  onUpdateEditData(
                    "testResultater.maalingAvAvtrekksmengdeIgangkjoering.inndata.lukeaapningResultater.kommentar",
                    e.target.value,
                  )
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
          </>
        )}
        {data.inndata.valgtMetode[MaalingAvAvtrekksmengdeEnum.DIFFERANSETRYKK] === true && (
          <>
            <h4>Differansetrykkmåling</h4>
            <h5>Avtrekkskanal:</h5>
            <div className="d-flex flex-column mb-4">
              <b>Antall målepunkter &#40;Minimum 4&#41;:</b>
              <McInput
                value={data.inndata.differanseTrykkResultater.antallMaalepunkter}
                onBlur={(e) =>
                  onUpdateEditData(
                    "testResultater.maalingAvAvtrekksmengdeIgangkjoering.inndata.differanseTrykkResultater.antallMaalepunkter",
                    Number(e.target.value),
                  )
                }
                normalizeNumbers={true}
                type="text"
                className="w-25"
              />
            </div>
            <div className="d-flex flex-column mt-2">
              <b>Måletiden er 60 sekunder &#40;s&#41;:</b>
            </div>
            <div className="d-flex flex-column mt-2 w-50">
              <b>Beskrivelse av målepunkter:</b>
              <McInput
                value={data.inndata.differanseTrykkResultater.beskrivelseAvMaalepunkter}
                onChange={(e) =>
                  onUpdateEditData(
                    "testResultater.maalingAvAvtrekksmengdeIgangkjoering.inndata.differanseTrykkResultater.beskrivelseAvMaalepunkter",
                    e.target.value,
                  )
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
            <div className="mt-4 d-flex flex-column">
              <b>Diameter på avtrekkskanal hvor målinger ble utført &#40;m&#41;:</b>
              <McInput
                value={data.inndata.differanseTrykkResultater.diameterPaaAvtrekkskanal}
                onBlur={(e) =>
                  onUpdateEditData(
                    "testResultater.maalingAvAvtrekksmengdeIgangkjoering.inndata.differanseTrykkResultater.diameterPaaAvtrekkskanal",
                    Number(e.target.value),
                  )
                }
                normalizeNumbers={true}
                type="text"
                className="w-25"
              />
            </div>
            <div className="d-flex justify-content-between">
              <p className="mb-0">
                Tversnittsareal kanal &#40;m<sup>2</sup>&#41; ={" "}
                <b>
                  {beregnetTverrsnittareal(data.inndata.differanseTrykkResultater.diameterPaaAvtrekkskanal)?.toFixed(
                    3,
                  ) ?? ""}
                </b>
              </p>
              <p className="mb-0">
                Formel: &#40;Ak = PI * &#40;D / 2&#41;<sup>2</sup>&#41;
              </p>
            </div>
            <hr className="mt-0" />
            <div className="mt-4 d-flex flex-column">
              <b>Måleresultat tilkoblingspunkt &#40;Pa&#41;:</b>
              <McInput
                value={data.inndata.differanseTrykkResultater.maaleresultatTilkoblingspunkt}
                onBlur={(e) =>
                  onUpdateEditData(
                    "testResultater.maalingAvAvtrekksmengdeIgangkjoering.inndata.differanseTrykkResultater.maaleresultatTilkoblingspunkt",
                    Number(e.target.value),
                  )
                }
                normalizeNumbers={true}
                type="text"
                className="w-25"
              />
            </div>
            <div className="mt-4">
              <h5>Målenippel:</h5>
              <b>Legg til innebygd målenippel:</b>
              <div className="d-flex">
                <Button color="success" onClick={addNewRowDifferansetrykk}>
                  Legg til rad
                </Button>
                <HSpace />
                <Button color="danger" onClick={removeLastRowDifferansetrykk}>
                  Fjern siste rad
                </Button>
              </div>
            </div>
            {data.inndata.differanseTrykkResultater.maaleresultaterMaalenipler.length > 0 && (
              <div className="mt-4 d-flex flex-column">
                <b>Fyll inn måleresultater &#40;Pa&#41;:</b>
                {data.inndata.differanseTrykkResultater.maaleresultaterMaalenipler.map((a, i) => (
                  <McInput
                    className="mb-1 w-25"
                    key={i}
                    value={a}
                    normalizeNumbers={true}
                    type="text"
                    onBlur={(e) => updateMaaleresultaterDifferansetrykk(Number(e.target.value), i)}
                  />
                ))}
              </div>
            )}
            <div className="mt-4 d-flex flex-column mb-4">
              <b>
                Avtrekksluftmengde fra fabrikant &#40;m<sup>3</sup>/s&#41;:
              </b>
              <McInput
                value={data.inndata.differanseTrykkResultater.avtrekksmengdeFraFabrikant}
                onBlur={(e) =>
                  onUpdateEditData(
                    "testResultater.maalingAvAvtrekksmengdeIgangkjoering.inndata.differanseTrykkResultater.avtrekksmengdeFraFabrikant",
                    Number(e.target.value),
                  )
                }
                normalizeNumbers={true}
                type="text"
                className="w-25"
              />
            </div>
            <div className="w-50">
              <b>Kommentar:</b>
              <McInput
                value={data.inndata.differanseTrykkResultater.kommentar}
                onChange={(e) =>
                  onUpdateEditData(
                    "testResultater.maalingAvAvtrekksmengdeIgangkjoering.inndata.differanseTrykkResultater.kommentar",
                    e.target.value,
                  )
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
          </>
        )}
        <div className="mt-4">
          <ReportDocxImageContainer docxImageTag="maalingAvAvtrekksmengdeIgangkjoering" title="Trykkuttak" />
        </div>
      </CardBody>
    </Card>
  );
};

export default MaalingAvAvtrekksmengdeIgangkjoering;
