import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ColDef } from "ag-grid-community";
import { IRowMoveInfo } from "mc-shared/zod/checklistSchema";
import { IAllFilter } from "mc-shared/zod/commonSchema";
import * as R from "ramda";
import {
  IChecklist,
  IChecklistCategory,
  IChecklistItem,
  IChecklistItemRow,
  IChecklistPopulated,
} from "../../../../../types/checklist.types";
import { IProgressData } from "../../../../../types/global.types";
import { apiSlice } from "../../../apiSlice";
import { AppModalEnums, ToastMessagesEnums } from "../../../frontendConstants";
import { AppThunk, IRootState } from "../../../store";
import { IMultipleFilterOptions } from "../../../types";
import { sortArrayByNumber } from "../../utils";
import { doCreateIssueDepricated } from "../issuesApp/issueAPIDepricated";
import {
  doAddChecklistCategory,
  doAddChecklistItem,
  doConnectOrDisconnectIssueFromChecklistItem,
  doGetActiveProjectChecklistsCreatedByLoggedInUser,
  doGetSingleChecklistItem,
  doGetSingleProjectChecklistParts,
  doRemoveChecklistCategory,
  doRemoveChecklistItem,
  doUpdateChecklist,
  doUpdateChecklistCategory,
  doUpdateChecklistItem,
  doUpdateChecklistRowPosition,
} from "./checklistAPI";
import { CHECKLIST_FILTERS_LS_KEY } from "./ChecklistConstants";
import { getItemCount } from "./checklistUtils";
import { addAppModalThunk, setShowSuccessMessageThunk } from "../../../global/globalSlice";

export interface IChecklistReducer {
  projectChecklistTemplates: IChecklistPopulated[];
  checklist: IChecklistPopulated;
  checklistCategories: IChecklistCategory[];
  checklistItems: IChecklistItem[];
  progress: IProgressData[];
  activeTab: string;
  checklistIdToConnectToANewIssue: string;
  activeProjectChecklistsCreatedByLoggedInUser: IChecklistPopulated[];
  allFilters: IAllFilter[];
  freeTextSearch: string;
  selectedPnsFilterItem: string;
  hiddenColumns: string[];
  tempHiddenCategories: string[];
}

const initialState: IChecklistReducer = {
  projectChecklistTemplates: [],
  checklist: null,
  checklistItems: [],
  checklistCategories: [],
  activeTab: "CHECKLIST",
  progress: [],
  checklistIdToConnectToANewIssue: null,
  activeProjectChecklistsCreatedByLoggedInUser: [],
  allFilters: [],
  freeTextSearch: "",
  selectedPnsFilterItem: null,
  hiddenColumns: [],
  tempHiddenCategories: [],
};

export const clearAllChecklistFilterThunk = (): AppThunk => async (dispatch: any, getState) => {
  localStorage.removeItem(CHECKLIST_FILTERS_LS_KEY);
  let allFilters = getState().checklistReducer.allFilters;

  const _allFilters = R.clone(allFilters).map((allFilterItem) => {
    if (allFilterItem.options != null) {
      allFilterItem.options = allFilterItem.options.map((opt) => {
        opt.isActive = false;
        return opt;
      });
    }
    if (allFilterItem.bool != null) {
      allFilterItem.bool = false;
    }
    return allFilterItem;
  });
  dispatch(setChecklistAllFiltersInRedux(_allFilters));
};

export const getChecklistThunk =
  (checklistId: string): AppThunk =>
  async (dispatch: any, getState) => {
    const project = getState().adminReducer.project;
    const { categories, checklist, items } = await doGetSingleProjectChecklistParts(project._id, checklistId);

    dispatch(updateChecklistProgressInRedux(checklist.progress));
    dispatch(setCurrentChecklistInRedux(checklist));
    dispatch(setChecklistItemsInRedux(sortArrayByNumber(items, "itemId")));
    dispatch(setChecklistCategoriesInRedux(sortArrayByNumber(categories, "categoryId")));
  };

export const getChecklistsCreatedByLoggedInUserThunk = (): AppThunk => async (dispatch: any, getState) => {
  const project = getState().adminReducer.project;
  const userId = getState().globalReducer.user._id;
  const checklists = await doGetActiveProjectChecklistsCreatedByLoggedInUser(project._id, userId);
  dispatch(setActiveProjectChecklistsCreatedByLoggedInUserInRedux(checklists));
};

export const updateChecklistThunk =
  <T>(attribute: string, newValue: T, oldValue: T): AppThunk =>
  async (dispatch: any, getState) => {
    const project = getState().adminReducer.project;
    const checklist = getState().checklistReducer.checklist;

    const updatedChecklist = await doUpdateChecklist(project._id, checklist._id, attribute, newValue, oldValue);

    dispatch(updateChecklistProgressInRedux(updatedChecklist.progress));
    dispatch(updateChecklistInRedux(updatedChecklist));

    if (attribute === "title") {
      dispatch(apiSlice.util.invalidateTags(["Checklist"]));
    }
  };

export const updateChecklistCategoryThunk =
  <T extends keyof IChecklistItemRow>(
    categoryId: string,
    param: string,
    newValue: IChecklistItemRow[T],
    oldValue: string,
  ): AppThunk =>
  async (dispatch: any, getState) => {
    const project = getState().adminReducer.project;
    const checklist = getState().checklistReducer.checklist;
    const categories = getState().checklistReducer.checklistCategories;

    const updatedChecklistCategory = await doUpdateChecklistCategory(
      project._id,
      checklist._id,
      categoryId,
      param,
      newValue,
      oldValue,
    );

    const updatedChecklistCategories: IChecklistCategory[] = sortArrayByNumber(
      categories.map((category) =>
        category._id === updatedChecklistCategory._id ? updatedChecklistCategory : category,
      ),
      "categoryId",
    );

    dispatch(updateChecklistCategoryInRedux(updatedChecklistCategories));
  };

export const updateChecklistItemThunk =
  <T extends keyof IChecklistItemRow>(
    itemId: string,
    path: string,
    newValue: IChecklistItemRow[T],
    oldValue: string,
  ): AppThunk =>
  async (dispatch: any, getState) => {
    const project = getState().adminReducer.project;
    const checklist = getState().checklistReducer.checklist;
    const items = getState().checklistReducer.checklistItems;

    const updatedChecklistItem = await doUpdateChecklistItem(
      project._id,
      checklist._id,
      itemId,
      path,
      newValue,
      oldValue,
    );
    const updatedChecklistItems: IChecklistItem[] = sortArrayByNumber(
      items.map((category) => (category._id === updatedChecklistItem._id ? updatedChecklistItem : category)),
      "itemId",
    );

    dispatch(updateChecklistProgressInRedux(updatedChecklistItem.progress));
    dispatch(updateChecklistItemsInRedux(updatedChecklistItems));
  };

export const updateChecklistRowPositionThunk =
  (moveInfo: IRowMoveInfo): AppThunk =>
  async (dispatch: any, getState) => {
    const project = getState().adminReducer.project;
    const updatedChecklistRows = await doUpdateChecklistRowPosition(project._id, moveInfo);

    if (moveInfo.isCat) {
      dispatch(
        setChecklistCategoriesInRedux(sortArrayByNumber(updatedChecklistRows as IChecklistCategory[], "categoryId")),
      );
    } else {
      dispatch(setChecklistItemsInRedux(sortArrayByNumber(updatedChecklistRows as IChecklistItem[], "itemId")));
    }
  };

export const deleteChecklistCategoryThunk =
  (categoryId: string): AppThunk =>
  async (dispatch: any, getState) => {
    const project = getState().adminReducer.project;
    const checklist = getState().checklistReducer.checklist;

    await doRemoveChecklistCategory(project._id, checklist._id, categoryId);
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.UPDATED));
    dispatch(deleteChecklistCategoryInRedux(categoryId));
  };

export const deleteChecklistItemThunk =
  (itemId: string): AppThunk =>
  async (dispatch: any, getState) => {
    const project = getState().adminReducer.project;
    const checklist = getState().checklistReducer.checklist;

    await doRemoveChecklistItem(project._id, checklist._id, itemId);
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.UPDATED));
    dispatch(deleteChecklistItemsInRedux(itemId));
  };

export const addChecklistCategoryThunk =
  (categoryCount: number): AppThunk =>
  async (dispatch: any, getState) => {
    const project = getState().adminReducer.project;
    const checklist = getState().checklistReducer.checklist;

    const addedChecklistCategory = await doAddChecklistCategory(project._id, checklist._id, categoryCount);
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.UPDATED));

    dispatch(addChecklistCategoryInRedux(addedChecklistCategory));
  };

export const addChecklistItemThunk =
  (categoryId: string): AppThunk =>
  async (dispatch: any, getState) => {
    const project = getState().adminReducer.project;
    const { checklist, checklistItems } = getState().checklistReducer;

    const itemCount = getItemCount(categoryId, checklistItems);
    const addedChecklistItem = await doAddChecklistItem(project._id, checklist._id, categoryId, itemCount);

    dispatch(addChecklistItemInRedux(addedChecklistItem));
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.UPDATED));
  };

export const createIssueOnChecklistItemThunk =
  (issueBoardId: string, checklistId): AppThunk =>
  async (dispatch: any, getState) => {
    const project = getState().adminReducer.project;

    const createdIssue = await doCreateIssueDepricated(
      project._id,
      issueBoardId,
      `Angi tittel`,
      "ISSUE",
      null,
      null,
      null,
      false,
      null,
      null,
      checklistId,
    );

    dispatch(
      addAppModalThunk({
        itemId: createdIssue._id,
        boardId: issueBoardId,
        projectId: project._id,
        app: AppModalEnums.ISSUE,
      }),
    );
    dispatch(fetchSingleChecklistItemAndReplaceInReduxThunk(checklistId));
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.UPDATED));
  };

export const fetchSingleChecklistItemAndReplaceInReduxThunk =
  (checklistItemId: string): AppThunk =>
  async (dispatch: any, getState) => {
    const { project } = getState().adminReducer;
    const checklistItem = await doGetSingleChecklistItem(project._id, checklistItemId);
    dispatch(updateSingleChecklistItemInRedux(checklistItem));
  };

export const connectOrDisconnectIssueFromChecklistItemThunk =
  (checklistItemId: string, issueId: string, connectOrDisconnect: "CONNECT" | "DISCONNECT"): AppThunk =>
  async (dispatch: any, getState) => {
    await doConnectOrDisconnectIssueFromChecklistItem(getState().adminReducer.project._id, {
      checklistItemId,
      issueId,
      connectOrDisconnect,
    });
    dispatch(fetchSingleChecklistItemAndReplaceInReduxThunk(checklistItemId));
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.UPDATED));
  };

export const toggleChecklistBoardBoolFilterThunk = (dataField: string): AppThunk => {
  return (dispatch: any, getState) => {
    const allFilters = R.clone(getState().meetingReducer.allFilters);
    const filterIndex = allFilters?.findIndex((filter) => filter.dataField === dataField);
    if (filterIndex === -1) {
      console.warn("filterIndex === -1");
      return;
    }
    const bool = allFilters[filterIndex].bool;
    allFilters[filterIndex].bool = !bool;
    dispatch(setChecklistAllFiltersInRedux(allFilters));
  };
};

export const tempCollapseAllCategoriesThunk = (collapsed: boolean): AppThunk => {
  return (dispatch: any, getState) => {
    const { checklistCategories, tempHiddenCategories } = getState().checklistReducer;
    let updatedChecklistCategories: IChecklistCategory[];
    let updatedTempHiddenCategories: string[] = tempHiddenCategories;

    if (collapsed === true) {
      updatedTempHiddenCategories = checklistCategories.filter((cat) => cat.collapsed === true).map((cat) => cat._id);
      updatedChecklistCategories = checklistCategories.map((cat) => {
        const catTemp = { ...cat };
        catTemp.collapsed = true;
        return catTemp;
      });
    } else {
      updatedChecklistCategories = checklistCategories.map((cat) => {
        const catTemp = { ...cat };

        if (updatedTempHiddenCategories.includes(catTemp._id) === false) {
          catTemp.collapsed = false;
        }

        return catTemp;
      });
    }

    dispatch(
      toggleTempCollapseAllCategoriesInRedux({ cat: updatedChecklistCategories, hidden: updatedTempHiddenCategories }),
    );
  };
};

export const checklistSlice = createSlice({
  name: "checklist",
  initialState,
  reducers: {
    addChecklistCategoryInRedux: (state, action: PayloadAction<IChecklistCategory>) => {
      state.checklistCategories = [...state.checklistCategories, action.payload];
    },
    addChecklistItemInRedux: (state, action: PayloadAction<IChecklistItem>) => {
      state.checklistItems = [...state.checklistItems, action.payload];
    },
    setActiveChecklistTabInRedux: (state, action: PayloadAction<string>) => {
      state.activeTab = action.payload;
    },
    setActiveProjectChecklistsCreatedByLoggedInUserInRedux: (state, action: PayloadAction<IChecklistPopulated[]>) => {
      state.activeProjectChecklistsCreatedByLoggedInUser = action.payload;
    },
    setProjectChecklistTemplatesInRedux: (state, action: PayloadAction<IChecklistPopulated[]>) => {
      state.projectChecklistTemplates = action.payload;
    },
    setCurrentChecklistInRedux: (state, action: PayloadAction<IChecklistPopulated>) => {
      state.checklist = action.payload;
    },
    setChecklistCategoriesInRedux: (state, action: PayloadAction<IChecklistCategory[]>) => {
      state.checklistCategories = action.payload.map((cat) => {
        const prev = state.checklistCategories.find((prevCat) => prevCat._id === cat._id);
        const collapsed = prev?.collapsed ?? false;
        return { ...cat, collapsed };
      });
    },
    setChecklistItemsInRedux: (state, action: PayloadAction<IChecklistItem[]>) => {
      state.checklistItems = action.payload;
    },
    updateChecklistInRedux: (state, action: PayloadAction<IChecklistPopulated>) => {
      state.checklist = action.payload;
    },
    updateChecklistCategoryInRedux: (state, action: PayloadAction<IChecklistCategory[]>) => {
      state.checklistCategories = action.payload;
    },
    updateChecklistItemsInRedux: (state, action: PayloadAction<IChecklistItem[]>) => {
      state.checklistItems = action.payload;
    },
    updateChecklistProgressInRedux: (state, action: PayloadAction<IProgressData[]>) => {
      state.progress = action.payload || [];
    },
    deleteChecklistCategoryInRedux: (state, action: PayloadAction<string>) => {
      state.checklistCategories = state.checklistCategories.filter((category) => category._id !== action.payload);
    },
    deleteChecklistItemsInRedux: (state, action: PayloadAction<string>) => {
      state.checklistItems = state.checklistItems.filter((item) => item._id !== action.payload);
    },
    toggleAllCategoryCollapseInRedux: (state, action: PayloadAction<boolean>) => {
      state.checklistCategories = state.checklistCategories.map((cat) => {
        return { ...cat, collapsed: action.payload };
      });
    },
    toggleTempCollapseAllCategoriesInRedux: (
      state,
      action: PayloadAction<{ cat: IChecklistCategory[]; hidden: string[] }>,
    ) => {
      state.tempHiddenCategories = action.payload.hidden;
      state.checklistCategories = action.payload.cat;
    },
    toggleCategoryCollapseInRedux: (state, action: PayloadAction<string>) => {
      state.checklistCategories = state.checklistCategories.map((cat) => {
        if (cat._id === action.payload) {
          return { ...cat, collapsed: cat.collapsed !== true };
        }
        return cat;
      });
    },
    updateSingleChecklistItemInRedux: (state, action: PayloadAction<IChecklistItem>) => {
      state.checklistItems = state.checklistItems.map((item) =>
        item._id === action.payload?._id ? action.payload : item,
      );
    },
    setChecklistAllFiltersInRedux: (state, action: PayloadAction<IAllFilter[]>) => {
      state.allFilters = action.payload;
    },
    toggleOptionInChecklistFiltersInRedux: (
      state,
      action: PayloadAction<{ dataField: string; optionValue: string }>,
    ) => {
      const { dataField, optionValue } = action.payload;
      const filterIndex = state.allFilters.findIndex((filter) => filter.dataField === dataField);
      const optionIndex = state.allFilters[filterIndex].options.findIndex((option) => option.value === optionValue);
      state.allFilters[filterIndex].options[optionIndex].isActive =
        !state.allFilters[filterIndex].options[optionIndex].isActive;
    },
    toggleAllMultiChecklistFiltersInRedux: (state, action: PayloadAction<{ dataField: string; isActive: boolean }>) => {
      const { dataField, isActive } = action.payload;
      const filterIndex = state.allFilters.findIndex((filter) => filter.dataField === dataField);
      state.allFilters[filterIndex].options = state.allFilters[filterIndex].options.map((option) => {
        return { ...option, isActive: isActive };
      });
    },
    setSelectedPnsFilterItemInRedux: (state, action: PayloadAction<string>) => {
      state.selectedPnsFilterItem = action.payload;
    },
    setChecklistFreeTextSearchInRedux: (state, action: PayloadAction<string>) => {
      state.freeTextSearch = action.payload;
    },
    toggleColVisibility: (state, action: PayloadAction<{ keys: string[]; bool: boolean }>) => {
      action.payload.keys.forEach((key) => {
        if (action.payload.bool === false) {
          if (!state.hiddenColumns.includes(key)) {
            state.hiddenColumns.push(key);
          }
        } else {
          state.hiddenColumns = state.hiddenColumns.filter((column) => column !== key);
        }
      });
    },
  },
});

export const selectChecklistMultiFilters = (
  state: IRootState,
  columns: ColDef<IChecklist>[],
): IMultipleFilterOptions[] => {
  const allFilters = state.checklistReducer.allFilters;
  return (allFilters ?? []).reduce((acc, allFilterItem) => {
    if (allFilterItem.options != null) {
      const col = columns?.find((_col) => _col.field.startsWith(allFilterItem.dataField));
      if (col != null) {
        const filterItem = {
          title: col.headerName,
          options: allFilterItem.options,
        };
        acc.push(filterItem);
      }
    }
    return acc;
  }, []);
};

export const {
  setChecklistAllFiltersInRedux,
  toggleOptionInChecklistFiltersInRedux,
  toggleAllMultiChecklistFiltersInRedux,
  addChecklistCategoryInRedux,
  addChecklistItemInRedux,
  setActiveProjectChecklistsCreatedByLoggedInUserInRedux,
  setProjectChecklistTemplatesInRedux,
  setCurrentChecklistInRedux,
  updateChecklistProgressInRedux,
  setActiveChecklistTabInRedux,
  setChecklistItemsInRedux,
  setChecklistCategoriesInRedux,
  updateChecklistInRedux,
  updateChecklistCategoryInRedux,
  updateChecklistItemsInRedux,
  deleteChecklistCategoryInRedux,
  deleteChecklistItemsInRedux,
  toggleAllCategoryCollapseInRedux,
  toggleCategoryCollapseInRedux,
  updateSingleChecklistItemInRedux,
  setSelectedPnsFilterItemInRedux,
  setChecklistFreeTextSearchInRedux,
  toggleColVisibility,
  toggleTempCollapseAllCategoriesInRedux,
} = checklistSlice.actions;

export default checklistSlice.reducer;
