import { BeregningAvLufthastighetGjennomArbeidsaapningEnum } from "mc-shared/zod/report/GK/gkReportConstants";
import {
  IGKBeregningAvLufthastighetGjennomArbeidsaapningMaaleresultater,
  IGKBeregningAvLufthastighetGjennomArbeidsaapningResultater,
  IGKRapport,
} from "mc-shared/zod/report/GK/gkReportSchema";
import { useEffect } from "react";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../../types/global.types";
import { HSpace, McDropdown, McInput } from "../../../../../../components";
import ReportDocxImageContainer from "../../../ReportBoard/ReportDocxImageContainer";
import {
  beregnetArealForHvertPunkt,
  beregnetAvkastLuftmengde,
  beregnetForholdMellomToVerdier,
  beregnetGjennomsnittligHastighet,
  beregnetGjennomsnittligHastighetAvtrekkskanal,
  beregnetLuftmengdeGjennomLukeaapning,
  beregnetStandardAvvik,
  beregnetTverrsnittareal,
  beregnetVarians,
} from "../gkGlobalUtils";

const BeregningAvLufthastighetGjennomArbeidsaapning: React.FC<{
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
  data: IGKBeregningAvLufthastighetGjennomArbeidsaapningResultater;
}> = ({ data, onUpdateEditData }) => {
  const TESTMETODER: BeregningAvLufthastighetGjennomArbeidsaapningEnum[] = [
    BeregningAvLufthastighetGjennomArbeidsaapningEnum.AVTREKKSKANAL,
    BeregningAvLufthastighetGjennomArbeidsaapningEnum.LUKEAAPNING,
  ];

  useEffect(() => {
    maaleResultaterArray();
  }, [data.inndata.valgtMetode]);

  useEffect(() => {
    utdataBeregninger();
  }, [
    data.inndata.maaleresultater,
    data.inndata.avtrekkskanalResultater.diameter,
    data.inndata.maaleresultater.length,
  ]);

  useEffect(() => {
    updateMaaleresultaterForholdstall();
  }, [data.utdata.gjennomsnittligHastighet]);

  const maaleResultaterArray = () => {
    let arrayLength;
    if (data.inndata.valgtMetode === BeregningAvLufthastighetGjennomArbeidsaapningEnum.LUKEAAPNING) {
      arrayLength = 9;
    }
    const newMaaleresultaterArray = Array.from({ length: arrayLength }, () => ({
      maaleresultat: null,
      forholdMellomMaaleresultatOgGjennomsnittshastighet: null,
    }));
    onUpdateEditData(
      "testResultater.beregningAvLufthastighetGjennomArbeidsaapning.inndata.maaleresultater",
      newMaaleresultaterArray as IGKBeregningAvLufthastighetGjennomArbeidsaapningMaaleresultater[],
    );
  };

  const addNewRow = () => {
    const maaleresultaterArray = data.inndata.maaleresultater;
    maaleresultaterArray.push({
      maaleresultat: null,
      forholdMellomMaaleresultatOgGjennomsnittshastighet: null,
    });

    onUpdateEditData(
      "testResultater.beregningAvLufthastighetGjennomArbeidsaapning.inndata.maaleresultater",
      maaleresultaterArray,
    );

    return maaleresultaterArray;
  };

  const removeLastRow = () => {
    const updatedMaaleresultater = data.inndata.maaleresultater;

    if (updatedMaaleresultater.length > 0) {
      updatedMaaleresultater.pop(); // Remove the last element
      onUpdateEditData(
        "testResultater.beregningAvLufthastighetGjennomArbeidsaapning.inndata.maaleresultater",
        updatedMaaleresultater,
      );
    }
  };

  const updateMaaleresultater = (resultat: string | number, i: number, parameter: string) => {
    const maaleresultater = [...data.inndata.maaleresultater];
    maaleresultater[i][parameter] = resultat;
    maaleresultater[i].forholdMellomMaaleresultatOgGjennomsnittshastighet = beregnetForholdMellomToVerdier(
      maaleresultater[i].maaleresultat,
      data.utdata.gjennomsnittligHastighet,
    );
    onUpdateEditData(
      "testResultater.beregningAvLufthastighetGjennomArbeidsaapning.inndata.maaleresultater",
      maaleresultater,
    );
  };

  const updateMaaleresultaterForholdstall = () => {
    const maaleresultater = [...data.inndata.maaleresultater];
    maaleresultater.forEach((resultat) => {
      resultat.forholdMellomMaaleresultatOgGjennomsnittshastighet = beregnetForholdMellomToVerdier(
        resultat.maaleresultat,
        data.utdata.gjennomsnittligHastighet,
      );
    });
    onUpdateEditData(
      "testResultater.beregningAvLufthastighetGjennomArbeidsaapning.inndata.maaleresultater",
      maaleresultater,
    );
  };

  const utdataBeregninger = () => {
    let tverrsnittareal: number = null;
    let arealForHvertPunkt: number = null;
    let avkastLuftmengde: number = null;
    let gjennomsnittligHastighet: number = null;
    let luftmengdeGjennomLukeaapning: number = null;
    let varians: number = null;
    let standardAvvik: number = null;
    if (data.inndata.valgtMetode === BeregningAvLufthastighetGjennomArbeidsaapningEnum.AVTREKKSKANAL) {
      tverrsnittareal = beregnetTverrsnittareal(data.inndata.avtrekkskanalResultater.diameter);
      arealForHvertPunkt = beregnetArealForHvertPunkt(tverrsnittareal, data.inndata.maaleresultater.length);
      avkastLuftmengde = beregnetAvkastLuftmengde(
        data.inndata.maaleresultater.map((resultat) => resultat.maaleresultat),
        arealForHvertPunkt,
      );
      gjennomsnittligHastighet = beregnetGjennomsnittligHastighetAvtrekkskanal(
        avkastLuftmengde,
        data.inndata.arealLukeaapning,
      );
    }
    if (data.inndata.valgtMetode === BeregningAvLufthastighetGjennomArbeidsaapningEnum.LUKEAAPNING) {
      gjennomsnittligHastighet = beregnetGjennomsnittligHastighet(
        data.inndata.maaleresultater.map((resultat) => resultat.maaleresultat),
        data.inndata.maaleresultater.length,
      );
      luftmengdeGjennomLukeaapning = beregnetLuftmengdeGjennomLukeaapning(
        gjennomsnittligHastighet,
        data.inndata.arealLukeaapning,
      );
      varians = beregnetVarians(
        data.inndata.maaleresultater.map((resultat) => resultat.maaleresultat),
        gjennomsnittligHastighet,
      );
      standardAvvik = beregnetStandardAvvik(varians);
    }
    const utdata = {
      gjennomsnittligHastighet: gjennomsnittligHastighet,
      avtrekkskanalBeregninger: {
        tverrsnittareal: tverrsnittareal,
        arealForHvertPunkt: arealForHvertPunkt,
        avkastLuftmengde: avkastLuftmengde,
      },
      lukeaapningBeregninger: {
        luftmengdeGjennomLukeaapning: luftmengdeGjennomLukeaapning,
        varians: varians,
        standardAvvik: standardAvvik,
      },
    };
    onUpdateEditData("testResultater.beregningAvLufthastighetGjennomArbeidsaapning.utdata", utdata);
  };

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Beregning av lufthastighet gjennom arbeidsåpning</CardTitle>
        <div className="d-flex flex-column mt-4 mb-4">
          <b>
            Fyll inn lukeåpningsareal &#40;m<sup>2</sup>&#41;:
          </b>
          <McInput
            value={data.inndata.arealLukeaapning}
            onBlur={(e) =>
              onUpdateEditData(
                "testResultater.beregningAvLufthastighetGjennomArbeidsaapning.inndata.arealLukeaapning",
                Number(e.target.value),
              )
            }
            normalizeNumbers={true}
            type="text"
            className="w-25"
          />
        </div>
        <b>Hvilken metode skal brukes?</b>
        <div className="w-25">
          <McDropdown
            currentValue={data.inndata.valgtMetode}
            onChange={(_, item) =>
              onUpdateEditData(
                "testResultater.beregningAvLufthastighetGjennomArbeidsaapning.inndata.valgtMetode",
                item as string,
              )
            }
            defaultTitle="Hvilken testmetode skal brukes?"
            options={TESTMETODER}
          />
        </div>
        {data.inndata.valgtMetode === BeregningAvLufthastighetGjennomArbeidsaapningEnum.AVTREKKSKANAL &&
          data.inndata.arealLukeaapning != null && (
            <>
              <div className="d-flex flex-column mt-4">
                <b>Fyll inn diameter på avtrekkskanal &#40;m&#41;:</b>
                <McInput
                  value={data.inndata.avtrekkskanalResultater.diameter}
                  onBlur={(e) =>
                    onUpdateEditData(
                      "testResultater.beregningAvLufthastighetGjennomArbeidsaapning.inndata.avtrekkskanalResultater.diameter",
                      Number(e.target.value),
                    )
                  }
                  normalizeNumbers={true}
                  type="text"
                  className="w-25"
                />
              </div>
              <div className="d-flex justify-content-between">
                <p className="mb-0">
                  Tverrsnittsareal av kanal &#40;m<sup>2</sup>&#41; ={" "}
                  <b>{beregnetTverrsnittareal(data.inndata.avtrekkskanalResultater.diameter)?.toFixed(3) ?? ""}</b>
                </p>
                <p className="mb-0">
                  Formel: &#40;Ak = PI * &#40;D / 2&#41;<sup>2</sup>&#41;
                </p>
              </div>
              <hr className="mt-0" />
              <div className="d-flex flex-column mt-2">
                <b>Måletiden er 60 sekunder &#40;s&#41;:</b>
              </div>
              <div className="mt-2">
                <b>Legg til flere punkter ved behov:</b>
                <div className="d-flex">
                  <Button color="success" onClick={addNewRow}>
                    Legg til rad
                  </Button>
                  <HSpace />
                  <Button color="danger" onClick={removeLastRow}>
                    Fjern siste rad
                  </Button>
                </div>
              </div>
              <div className="mt-4 d-flex flex-column">
                <b>Fyll inn måleresultater &#40;m/s&#41;:</b>
                {data.inndata.maaleresultater.map((resultat, i) => (
                  <McInput
                    key={i}
                    value={resultat.maaleresultat}
                    normalizeNumbers={true}
                    type="text"
                    onBlur={(e) => updateMaaleresultater(Number(e.target.value), i, "maaleresultat")}
                    className="w-25 mb-1"
                  />
                ))}
              </div>
              <div className="d-flex justify-content-between">
                <p className="mb-0">
                  Areal for hvert målepunkt &#40;m<sup>2</sup>&#41; ={" "}
                  <b>
                    {beregnetArealForHvertPunkt(
                      data.utdata.avtrekkskanalBeregninger.tverrsnittareal,
                      data.inndata.maaleresultater.length,
                    )?.toFixed(3) ?? ""}
                  </b>
                </p>
                <p className="mb-0">Formel: &#40;Ac = Ak / N&#41;</p>
              </div>
              <hr className="mt-0" />
              <div className="d-flex justify-content-between">
                <p className="mb-0">
                  Avkastsluftmengde &#40;m<sup>3</sup>/s&#41; ={" "}
                  <b>
                    {beregnetAvkastLuftmengde(
                      data.inndata.maaleresultater.map((resultat) => resultat.maaleresultat),
                      data.utdata.avtrekkskanalBeregninger.arealForHvertPunkt,
                    )?.toFixed(3) ?? ""}
                  </b>
                </p>
                <p className="mb-0">Formel: &#40;Qa = SUM&#40;Vn * Ac&#41;&#41;</p>
              </div>
              <hr className="mt-0" />
              <div className="d-flex justify-content-between">
                <p className="mb-0">
                  Gjennomsnittlig hastighet &#40;m/s&#41; ={" "}
                  <b>
                    {beregnetGjennomsnittligHastighetAvtrekkskanal(
                      data.utdata.avtrekkskanalBeregninger.avkastLuftmengde,
                      data.inndata.arealLukeaapning,
                    )?.toFixed(3) ?? ""}
                  </b>
                </p>
                <p className="mb-0">Formel: &#40;Va = Qa / Aluke&#41;</p>
              </div>
              <hr className="mt-0" />
            </>
          )}
        {data.inndata.valgtMetode === BeregningAvLufthastighetGjennomArbeidsaapningEnum.LUKEAAPNING &&
          data.inndata.arealLukeaapning != null && (
            <>
              <div className="mt-4">
                <b>Legg til flere punkter ved behov:</b>
                <div className="d-flex">
                  <Button color="success" onClick={addNewRow}>
                    Legg til rad
                  </Button>
                  <HSpace />
                  <Button color="danger" onClick={removeLastRow} disabled={data.inndata.maaleresultater.length === 9}>
                    Fjern siste rad
                  </Button>
                </div>
              </div>
              <div className="mt-4 d-flex flex-column">
                <b>Fyll inn måleresultater &#40;m/s&#41;:</b>
                {data.inndata.maaleresultater.map((resultat, i) => (
                  <McInput
                    key={i}
                    value={resultat.maaleresultat}
                    normalizeNumbers={true}
                    type="text"
                    onBlur={(e) => updateMaaleresultater(Number(e.target.value), i, "maaleresultat")}
                    className="w-25 mb-1"
                  />
                ))}
              </div>
              <div className="d-flex justify-content-between">
                <p className="mb-0">
                  Gjennomsnittlig hastighet &#40;m/s&#41; ={" "}
                  <b>
                    {beregnetGjennomsnittligHastighet(
                      data.inndata.maaleresultater.map((resultat) => resultat.maaleresultat),
                      data.inndata.maaleresultater.length,
                    )?.toFixed(3) ?? ""}
                  </b>
                </p>
                <p className="mb-0">Formel: &#40;Va = &#40;SUM Vn&#41; / N&#41;</p>
              </div>
              <hr className="mt-0" />
              <div className="d-flex justify-content-between">
                <p className="mb-0">
                  Luftmengde gjennom lukeåpning &#40;m<sup>3</sup>/s&#41; ={" "}
                  <b>
                    {beregnetLuftmengdeGjennomLukeaapning(
                      data.utdata.gjennomsnittligHastighet,
                      data.inndata.arealLukeaapning,
                    )?.toFixed(3) ?? ""}
                  </b>
                </p>
                <p className="mb-0">Formel: &#40;Q = Va * Aluke&#41;</p>
              </div>
              <hr className="mt-0" />
              <div className="d-flex justify-content-between">
                <p className="mb-0">
                  Varians &#40;-&#41; =
                  <b>
                    {" "}
                    {beregnetVarians(
                      data.inndata.maaleresultater.map((resultat) => resultat.maaleresultat),
                      data.utdata.gjennomsnittligHastighet,
                    )?.toFixed(4) ?? ""}
                  </b>
                </p>
                <p className="mb-0">
                  Formel: &#40;VRS = &#40;&#40;SUM Vn<sup>2</sup>&#41; - N * &#40;Va<sup>2</sup>&#41;&#41; / &#40;N -
                  1&#41;&#41;
                </p>
              </div>
              <hr className="mt-0" />
              <div className="d-flex justify-content-between">
                <p className="mb-0">
                  Standardavvik &#40;-&#41; =
                  <b> {beregnetStandardAvvik(data.utdata.lukeaapningBeregninger.varians)?.toFixed(4) ?? ""}</b>
                </p>
                <p className="mb-0">
                  Formel: &#40;Sigma = VRS<sup>0,5</sup>&#41;
                </p>
              </div>
              <hr className="mt-0" />
            </>
          )}
        <div className="mt-4">
          <ReportDocxImageContainer
            docxImageTag="beregningAvLufthastighetGjennomArbeidsaapning"
            title="Målepunkter i rommet"
          />
          <div className="w-50">
            <b>Kommentar:</b>
            <McInput
              value={data.inndata.kommentar}
              onChange={(e) =>
                onUpdateEditData(
                  "testResultater.beregningAvLufthastighetGjennomArbeidsaapning.inndata.kommentar",
                  e.target.value,
                )
              }
              type="textarea"
              dynamicHeight={true}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default BeregningAvLufthastighetGjennomArbeidsaapning;
