import Axios from "axios";
import { IComment, IUserActiveItem } from "../../../../../types/global.types";
import { IEditorMetadata } from "../../../components/MultiComment/MultiComment";

export const doGetActiveAssignedItemsDepricated = async (
  projectId: string,
  userId?: string,
): Promise<IUserActiveItem[]> => {
  try {
    if (userId) {
      const resp = await Axios.get(`/api/project/${projectId}/active-asigned-items/${userId}`);
      return resp.data;
    }
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doDeleteComment = async (projectId: string, commentId: string): Promise<string> => {
  try {
    const resp = await Axios.delete(`/api/project/${projectId}/comment/${commentId}`);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doUpdatecomment = async (projectId: string, commentId: string, text: string): Promise<IComment> => {
  try {
    const resp = await Axios.put(`/api/project/${projectId}/comment/${commentId}`, { text });
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doCreateComment = async (
  projectId: string,
  itemId: string,
  model: string,
  text: string,
  boardId?: string,
  metadata?: IEditorMetadata,
): Promise<IComment> => {
  try {
    const resp = await Axios.post(`/api/project/${projectId}/comment`, {
      projectId,
      itemId,
      model,
      text,
      boardId,
      metadata,
    });
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};
