import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMeeting } from "../../../../../types/meeting.types";
import { IAllFilter } from "mc-shared/zod/commonSchema";
import { AppThunk } from "../../../store";
import * as R from "ramda";

export type MeetingTab = "AGENDA" | "PARTICIPANTS" | "INFO" | "DOCX";

export interface IMeetingReducer {
  meetingAppTab: "MEETING_SERIES" | "MEETINGS";
  singleMeetingActiveTab: MeetingTab;
  meetingsWhereUserIsAParticipant: IMeeting.Meeting[];
  hideDeletedMeetingSeries: boolean;
  clickedMeetingSerieId: string;
  issueIdsTableExpandeded: string[];
  allFilters: IAllFilter[];
  freeTextSearch: string;
}

const initialState: IMeetingReducer = {
  meetingAppTab: "MEETINGS",
  singleMeetingActiveTab: "AGENDA",
  meetingsWhereUserIsAParticipant: [],
  hideDeletedMeetingSeries: true,
  clickedMeetingSerieId: null,
  issueIdsTableExpandeded: [],
  freeTextSearch: "",
  allFilters: [],
};

export const clearAllMeetingFilterThunk = (): AppThunk => async (dispatch: any, getState) => {
  let allFilters = getState().meetingReducer.allFilters;
  const _allFilters = R.clone(allFilters).map((allFilterItem) => {
    if (allFilterItem.options != null) {
      allFilterItem.options = allFilterItem.options.map((opt) => {
        opt.isActive = false;
        return opt;
      });
    }
    if (allFilterItem.bool != null) {
      allFilterItem.bool = false;
    }
    return allFilterItem;
  });
  dispatch(setMeetingAllFiltersInRedux(_allFilters));
};

export const toggleMeetingBoolFilterThunk = (dataField: string): AppThunk => {
  return (dispatch: any, getState) => {
    const allFilters = R.clone(getState().meetingReducer.allFilters);
    const filterIndex = allFilters.findIndex((filter) => filter.dataField === dataField);
    if (filterIndex === -1) {
      console.warn("filterIndex === -1");
      return;
    }
    const bool = allFilters[filterIndex].bool;
    allFilters[filterIndex].bool = !bool;
    dispatch(setMeetingAllFiltersInRedux(allFilters));
  };
};

export const meetingsSlice = createSlice({
  name: "meeting",
  initialState,
  reducers: {
    setMeetingActiveTab: (state, action: PayloadAction<MeetingTab>) => {
      state.singleMeetingActiveTab = action.payload;
    },
    setActiveMeetingAppTabInRedux: (state, action: PayloadAction<"MEETING_SERIES" | "MEETINGS">) => {
      state.meetingAppTab = action.payload;
    },
    setHideDeletedMeetingSeriesInRedux: (state, action: PayloadAction<boolean>) => {
      state.hideDeletedMeetingSeries = action.payload;
    },
    setClickedMeetingSerieIdInRedux: (state, action: PayloadAction<string>) => {
      state.clickedMeetingSerieId = action.payload;
    },
    addIssueIdToTableExpandedInRedux: (state, action: PayloadAction<string>) => {
      state.issueIdsTableExpandeded.push(action.payload);
    },
    removeIssueIdFromTableExpandedInRedux: (state, action: PayloadAction<string>) => {
      state.issueIdsTableExpandeded = state.issueIdsTableExpandeded.filter((id) => id !== action.payload);
    },
    setMeetingAllFiltersInRedux: (state, action: PayloadAction<IAllFilter[]>) => {
      state.allFilters = action.payload;
    },
    toggleOptionInMeetingFiltersInRedux: (state, action: PayloadAction<{ dataField: string; optionValue: string }>) => {
      const { dataField, optionValue } = action.payload;
      const filterIndex = state.allFilters.findIndex((filter) => filter.dataField === dataField);
      const optionIndex = state.allFilters[filterIndex].options.findIndex((option) => option.value === optionValue);
      state.allFilters[filterIndex].options[optionIndex].isActive =
        !state.allFilters[filterIndex].options[optionIndex].isActive;
    },
    setMeetingFreeTextSearchInRedux: (state, action: PayloadAction<string>) => {
      state.freeTextSearch = action.payload;
    },
    toggleAllMultiMeetingsFilterInRedux: (state, action: PayloadAction<{ dataField: string; isActive: boolean }>) => {
      const { dataField, isActive } = action.payload;
      const filterIndex = state.allFilters.findIndex((filter) => filter.dataField === dataField);
      state.allFilters[filterIndex].options = state.allFilters[filterIndex].options.map((option) => {
        return { ...option, isActive: isActive };
      });
    },
  },
});

export const {
  setMeetingActiveTab,
  setActiveMeetingAppTabInRedux,
  setHideDeletedMeetingSeriesInRedux,
  setClickedMeetingSerieIdInRedux,
  addIssueIdToTableExpandedInRedux,
  removeIssueIdFromTableExpandedInRedux,
  setMeetingAllFiltersInRedux,
  toggleOptionInMeetingFiltersInRedux,
  setMeetingFreeTextSearchInRedux,
  toggleAllMultiMeetingsFilterInRedux,
} = meetingsSlice.actions;

export default meetingsSlice.reducer;
