import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { IMinUser, IProject } from "../../../../../../../types/global.types";
import { IMCBIMAPI } from "../../../../../../../types/mcbim.types";
import { FailedAlert, MCLoader } from "../../../../../components";
import { useAppSelector } from "../../../../../hooks";
import { useCreateMcBimJobMutation, useGetListOfMcBimJobsQuery } from "../McBimRTK";
import { isMcBimAdmin } from "../mcBimSlice";
import McBimSingleJob from "./McBimSingleJob";
import McJobTable from "./McJobTable";

const McBimJobbPage: React.FC<{ mcbimProject: IMCBIMAPI.Project; project: IProject }> = ({ mcbimProject, project }) => {
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);

  const user = useAppSelector((state) => state.globalReducer.user);
  const userIsMcBimAdmin = isMcBimAdmin(project.mcbim.admins as IMinUser[], user?._id);

  const {
    data: mcbimJobs,
    isLoading: mcbimJobsLoading,
    error: errorGettingBimJobs,
    refetch: fetchJobs,
  } = useGetListOfMcBimJobsQuery(project._id);

  const [createJob, { isLoading: createJobLoading, error: createJobError }] = useCreateMcBimJobMutation();

  const jobInQueOrInProgress = mcbimJobs?.find((job) => job.status === "queued" || job.status === "in-progress");

  const startJob = async () => {
    await createJob(project._id);
    fetchJobs();
    setModalOpen(false);
  };

  const isLoading = createJobLoading === true || mcbimJobsLoading === true;
  const isError = errorGettingBimJobs != null || createJobError != null;

  return (
    <div className="h-100 w-100 p-2">
      {isLoading === true && <MCLoader loading />}
      {isError === true && <FailedAlert />}
      {jobInQueOrInProgress != null && (
        <McBimSingleJob jobId={jobInQueOrInProgress.jobId} projectId={project._id} onComplete={() => fetchJobs()} />
      )}
      {isLoading === false && isError == false && jobInQueOrInProgress == null && (
        <>
          {userIsMcBimAdmin === true && (
            <Button color="success" onClick={() => setModalOpen(true)}>
              <i className="fa fa-play fa-fw" />
              Starte synkronisering til MCBIM
            </Button>
          )}

          {modalOpen === true && (
            <StartJobModal
              startJob={startJob}
              toggle={() => setModalOpen(false)}
              nrOfFiles={mcbimProject?.ifcFiles?.length}
            />
          )}
          <McJobTable jobs={mcbimJobs} projectId={project._id} />
        </>
      )}
    </div>
  );
};

export default McBimJobbPage;

const StartJobModal: React.FC<{ toggle: () => void; nrOfFiles: number; startJob: () => void }> = ({
  toggle,
  nrOfFiles,
  startJob,
}) => {
  const [isJobStarted, setIsJobStarted] = React.useState<boolean>(false);
  const initJob = () => {
    setIsJobStarted(true);
    startJob();
  };

  return (
    <Modal isOpen toggle={toggle}>
      {nrOfFiles === 0 ? (
        <>
          <ModalHeader toggle={toggle}>Ingen filer er valgt</ModalHeader>
          <ModalBody>
            <p>Det er ingen filer valgt for å starte utvinning.</p>
          </ModalBody>
        </>
      ) : (
        <>
          <ModalHeader toggle={toggle}>Vil du starte utvinning?</ModalHeader>
          <ModalBody>
            <p>{nrOfFiles} filer valgt for utvinning til MCBIM</p>
          </ModalBody>
        </>
      )}
      <ModalFooter>
        <Button disabled={nrOfFiles === 0 || isJobStarted === true} color="primary" onClick={initJob}>
          Starte
        </Button>
        <Button color="secondary" onClick={toggle}>
          Avbryt
        </Button>
      </ModalFooter>
    </Modal>
  );
};
