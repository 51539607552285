import React from "react";
import { IMinUser } from "../../../../../types/global.types";
import { formatDateWithoutYear } from "../admin/selectors";

interface InitialsAndDateProps {
  updatedBy: string | IMinUser;
  updatedDate: string | Date;
  singleLine?: boolean;
}

const InitialsAndDate: React.FC<InitialsAndDateProps> = ({ updatedBy, updatedDate, singleLine }) => {
  const getFirstCharInEachWordAndCapitalizeIt = (str: string) => {
    if (str == null) {
      return "";
    }

    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase())
      .join("");
  };

  const initials = getFirstCharInEachWordAndCapitalizeIt(typeof updatedBy === "string" ? null : updatedBy?.name);
  const date = formatDateWithoutYear(typeof updatedDate === "string" ? updatedDate : updatedDate?.toString());
  const fullDate =
    typeof updatedDate === "string" ? updatedDate.split("T")[0] : updatedDate?.toISOString().split("T")[0];

  return (
    <div
      className="d-flex flex-column"
      title={`${typeof updatedBy === "string" ? updatedBy : updatedBy?.name} ${fullDate}`}
    >
      {singleLine ? (
        <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
          {initials} {date}
        </span>
      ) : (
        <>
          <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{initials}</span>
          <span>{date}</span>
        </>
      )}
    </div>
  );
};

export default InitialsAndDate;
