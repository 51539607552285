import * as _ from "lodash";
import moment from "moment";
import { IBim360Project } from "../../../../../types/forge.types";
import { IFilterItem } from "../../../../../types/global.types";
import { IRootState } from "../../../store";

export const formatDate = (dateStr: string) => {
  if (isDateValidFrontend(dateStr) === false) {
    return dateStr;
  }
  return moment(dateStr).format("YYYY-MM-DD - HH:mm");
};

export const formatHtmlInputDateString = (isoString: string) => {
  if (isoString == null || isoString === "") {
    return "";
  }

  return moment(isoString).format("YYYY-MM-DD");
};

export const formatShortDate = (dateStr: string | Date, hideOldDate: boolean = false): string => {
  if (dateStr instanceof Date) {
    dateStr = dateStr.toISOString();
  }

  if (isDateValidFrontend(dateStr) === false) {
    return dateStr;
  }

  if (hideOldDate && moment(dateStr).isBefore("2000-01-01")) {
    return "";
  }
  return moment(dateStr).format("YYYY.MM.DD");
};

export const formatDateWithoutYear = (dateStr: string) => {
  if (isDateValidFrontend(dateStr) === false) {
    return dateStr;
  }
  //format DD/MM
  return moment(dateStr).format("DD/MM");
};

export const formatInputDate = (dateStr: string) => {
  return moment(dateStr).format("YYYY.MM.DD");
};

export const isDateValidFrontend = (str: string): boolean => {
  if (str == null) {
    return false;
  }
  if (typeof str !== "string") {
    return false;
  }
  if (!str.match(/^\d/)) {
    return false;
  }
  if (str.length < 10) {
    return false;
  }
  let date = moment(str);
  return date.isValid();
};

export const getFolderUrn = (bim360Project: IBim360Project, nameOfFolder = "Project Files"): string => {
  const projectFolder = bim360Project.rootFolders.filter((folder) => folder.name === nameOfFolder)[0];
  return projectFolder.urn;
};

export const isFilterItemsValid = (filterItems: IFilterItem[]): boolean => {
  let isValid = true;
  if (_.isEmpty(filterItems)) {
    return false;
  }
  filterItems.forEach((item) => {
    if (_.isEmpty(item.label) || _.isEmpty(item.searchWord)) {
      isValid = false;
    }
  });
  return isValid;
};
/*
export const isAdmin = (state: IRootReducer) => {
  const { user } = state.globalReducer;
  if (user == null) {
    return false;
  }
  try {
    const member: IMember = _.find(state.adminReducer.project.members, (member) => member.user._id === user._id);
    return member && member.role === "ADMIN";
  } catch (err) {
    return false;
  }
};*/

export const isLoggedinUserMulticonsultUser = (state: IRootState) => {
  const { email } = state.globalReducer.user;
  return isEmailDomainMulticonsult(email);
};

export const getEmailDomain = (email: string) => {
  if (email == null) {
    return null;
  }
  const index = email.indexOf("@");
  const emailDomain = email.substr(index);
  return emailDomain.toUpperCase();
};

export const isEmailDomainMulticonsult = (email: string) => {
  const index = email.indexOf("@");
  const emailDomain = email.substr(index);
  return emailDomain.toUpperCase() === "@MULTICONSULT.NO";
};

export const isEmailDomainCowi = (email: string) => {
  const index = email.indexOf("@");
  const emailDomain = email.substr(index);
  return emailDomain.toUpperCase() === "@COWI.COM";
};

export const validateEmail = (email) => {
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const emailShouldLoginWithOffice365 = (email: string) => {
  return isEmailDomainMulticonsult(email);
};

export const projectIsConnectedToBim360 = (state: IRootState) => {
  return state.adminReducer.project?.bim360.projectId != null;
};

export const projectIsConectedToArcGis = (state: IRootState) => {
  return state.adminReducer.project?.arcgis && state.adminReducer.project?.arcgis.groupId != null;
};

export const projectHasBotha2dAnd3dArcgisMap = (state: IRootState): boolean => {
  return (
    state.adminReducer.project?.arcgis?.default2dMap != null && state.adminReducer.project?.arcgis?.default3dMap != null
  );
};
