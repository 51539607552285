import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react"; // AG Grid Component
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { IMeeting } from "../../../../../../types/meeting.types";
import { AG_GRID_LOCALE_NOR } from "../../../../agGridLocale";
import { Avatar } from "../../../../components";
import { useAppSelector } from "../../../../hooks";
import { getProjectIdFromUrl } from "../../../utils";
import { formatShortDate } from "../../admin/selectors";
import { useGetMeetingInstancesQuery } from "../meetingRTK";
import "./MeetingBoardsTable.css";

export const meetingBoardColumnDefs: ColDef<IMeeting.Meeting>[] = [
  {
    headerName: "Kommende møter",
    field: "dateOfMeeting",
    hide: true,
  },
  {
    headerName: "ID",
    field: "id",
    sortable: true,
    // width: 75,
    cellRenderer: (params) => (
      <b className="mb-0 text-primary" style={{ textDecoration: "underline" }}>
        {params.value}
      </b>
    ),
  },
  {
    headerName: "Tittel",
    field: "name",
    sortable: true,
    filter: "agTextColumnFilter",
    flex: 3,
  },
  {
    headerName: "Møteserie",
    field: "meetingSerie.name",
    sortable: true,
    filter: "agTextColumnFilter",
    flex: 2,
  },
  {
    headerName: "Dato",
    field: "dateOfMeeting",
    sortable: true,
    sort: "desc",
    filter: "agDateColumnFilter",
    // width: 125,
    cellRenderer: (params) => formatShortDate(params.value),
  },
  {
    headerName: "Eier",
    field: "owner",
    sortable: true,
    flex: 1,
    cellRenderer: (params) => {
      const owner = params.value;
      return owner != null ? (
        <div className="d-flex align-items-center">
          <Avatar id={owner._id} />
          <div className="ml-2">{owner.name}</div>
        </div>
      ) : (
        <div></div>
      );
    },
  },
  { headerName: "Jeg er medlem", field: "isMember", hide: true },
  {
    headerName: "Jeg er ansvarlig",
    field: "owner.name",
    hide: true,
  },
];
const MeetingBoardsTable: React.FC<{
  meetingSerie?: IMeeting.MeetingSerie;
  singleMeetingId?: string;
  hideWhenEmpty?: boolean;
  autoHeight?: boolean;
  openMeetingInNewTab?: boolean;
}> = ({ meetingSerie, singleMeetingId, hideWhenEmpty = true, autoHeight = false, openMeetingInNewTab = false }) => {
  const project = useAppSelector((state) => state.adminReducer.project);
  const allMeetingFilters = useAppSelector((state) => state.meetingReducer.allFilters);
  const history = useHistory();
  const projectId = getProjectIdFromUrl(history.location.pathname);
  const { data: { meetings } = {} } = useGetMeetingInstancesQuery({
    projectId,
    allFilters: allMeetingFilters,
  });
  const currentMeetings: IMeeting.Meeting[] = useMemo(() => {
    if (meetings != null && singleMeetingId != null) {
      return meetings?.reduce((acc, meeting) => {
        if (meeting.isDeleted === true || meeting.meetingSerie == null) {
          return acc;
        }

        const id = (meeting.meetingSerie as IMeeting.MeetingSerie)._id;

        if (id != null && id === singleMeetingId) {
          acc.push(meeting);
        }

        return acc;
      }, []);
    } else if (meetings != null && meetingSerie != null) {
      return meetings?.filter((meeting) => (meeting.meetingSerie as IMeeting.MeetingSerie)?._id === meetingSerie?._id);
    } else if (meetings != null) {
      return meetings.filter((meeting) => meeting.isDeleted !== true);
    } else {
      return [];
    }
  }, [meetings, meetingSerie, singleMeetingId]);

  const goToMeeting = async (meetingId: string) => {
    if (openMeetingInNewTab === true) {
      window.open(`#/project/${project._id}/meetings/${meetingId}`, "_blank");
      return;
    } else {
      history.push(`/project/${project._id}/meetings/${meetingId}`);
    }
  };

  return hideWhenEmpty === true && (currentMeetings == null || currentMeetings.length === 0) ? (
    <>Ingen møter tilgjengelig</>
  ) : (
    <div className="meetingTable ag-theme-quartz h-100">
      <AgGridReact
        domLayout={autoHeight === true ? "autoHeight" : undefined}
        rowData={currentMeetings}
        defaultColDef={{ suppressHeaderMenuButton: true }}
        columnDefs={meetingBoardColumnDefs}
        suppressClickEdit={true}
        rowSelection="single"
        animateRows={true}
        suppressContextMenu={true}
        onGridReady={(e) => {
          e.api.autoSizeColumns(["id", "name"]);
        }}
        onRowClicked={(e) => {
          e.api.deselectAll();
          goToMeeting(e.data._id);
        }}
        localeText={AG_GRID_LOCALE_NOR}
      />
    </div>
  );
};

export default MeetingBoardsTable;
