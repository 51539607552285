import Axios from "axios";
import saveAs from "file-saver";
import { formatShortDate } from "../admin/selectors";

export const doGenerateMeetingDocx = async (
  projectId: string,
  meetingId: string,
  nameOfMeeting: string,
  templateBlobName: string,
  meetingSerieName?: string,
  meetingInstanceDate?: Date,
) => {
  try {
    const file = await Axios.put(
      `/api/project/${projectId}/meeting/${meetingId}/generate-docx`,
      {
        templateBlobName,
      },
      {
        responseType: "arraybuffer",
      },
    );
    const blob = new Blob([file.data], { type: "application/x-msdownload" });
    const name = `${meetingSerieName ?? nameOfMeeting}_${formatShortDate(meetingInstanceDate?.toString())}.docx`;
    saveAs(blob, name);
  } catch (error) {
    const decoder = new TextDecoder();
    const message = decoder.decode(error.response?.data as ArrayBuffer);
    return Promise.reject({ type: "backend", message });
  }
};
