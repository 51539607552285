import React from "react";
import { useSelector } from "react-redux";
import { useAppSelector } from "../../../../../hooks";
import { IssueDashboardOptionsEnum } from "../../issueConstants";
import { selectSingleIssueBoard } from "../../issueSlice";
import BreeamDashboard from "./BreeamDashboard";
import IssueStandardDashboard from "./IssueStandardDashboard";
import RisikoDashboard from "./RisikoDashboard";

const IssueDashboardMain: React.FC<{ projectId: string; issueBoardId: string }> = ({ projectId, issueBoardId }) => {
  const activeDashboardInRedux = useAppSelector((state) => state.issueReducerNew.activeDashboard);
  const issueBoard = useSelector(selectSingleIssueBoard);

  const activeDashboard = activeDashboardInRedux != null ? activeDashboardInRedux : issueBoard?.dashboardSetting;

  return (
    <>
      <div className="d-flex flex-column w-100 overflow-auto">
        {activeDashboard === IssueDashboardOptionsEnum.STANDARD && (
          <IssueStandardDashboard projectId={projectId} issueBoardId={issueBoardId} />
        )}
        {activeDashboard === IssueDashboardOptionsEnum.BREEAM && (
          <BreeamDashboard projectId={projectId} issueBoard={issueBoard} />
        )}
        {activeDashboard === IssueDashboardOptionsEnum.BREEAM_PREANALYSE && <p>Hello BREEAM preanalyse-dashboard</p>}
        {activeDashboard === IssueDashboardOptionsEnum.RISIKO && (
          <RisikoDashboard projectId={projectId} issueBoard={issueBoard} />
        )}
      </div>
    </>
  );
};

export default IssueDashboardMain;
