import React, { useEffect } from "react";
import { IChecklistItemRow } from "../../../../../../../types/checklist.types";
import { IIssue } from "../../../../../../../types/global.types";
import { FailedAlert, FoldableSection } from "../../../../../components";
import { ConnectedIssues } from "../../../../../components/ConnectedIssues/ConnectedIssues";
import CreateItemWithBoardSelectorModal from "../../../../../components/CreateItemWithBoardSelectorModal/CreateItemWithBoardSelectorModal";
import { MCApplicationsUrlEnum } from "../../../../../frontendConstants";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import ConnectExistingIssueToItemModalWrapper from "../../../../ConnectExistingIssueToItemModalWrapper";
import { useGetIssueBoardsQuery } from "../../../issuesApp/IssueBoardRTK";
import {
  connectOrDisconnectIssueFromChecklistItemThunk,
  createIssueOnChecklistItemThunk,
  fetchSingleChecklistItemAndReplaceInReduxThunk,
} from "../../checklistSlice";

const ChecklistConnecetedIssues: React.FC<{ checklistRow: IChecklistItemRow; projectId: string }> = ({
  checklistRow,
  projectId,
}) => {
  const [newIssueModalOpen, setNewIssueModalOpen] = React.useState<boolean>(false);
  const [connectExistingModalOpen, setConnectExistingModalOpen] = React.useState<boolean>(false);
  const [wasStarted, setWasStarted] = React.useState<boolean>(false);

  const dispatch = useAppDispatch();

  const openedAppModals = useAppSelector((state) => state.globalReducer.openedAppModals);

  useEffect(() => {
    if (wasStarted === true && openedAppModals.length === 0) {
      // an issue modal was closed. Update table
      dispatch(fetchSingleChecklistItemAndReplaceInReduxThunk(checklistRow._id));
    }
    setWasStarted(true);
  }, [openedAppModals?.length]);

  const onConnect = (issueId: string) => {
    dispatch(connectOrDisconnectIssueFromChecklistItemThunk(checklistRow._id, issueId, "CONNECT"));
    setConnectExistingModalOpen(false);
  };

  const { data: issueBoards = [], error: boardFetchError } = useGetIssueBoardsQuery({
    projectId: projectId,
  });

  const onCreateIssue = (boardId: string) => {
    dispatch(createIssueOnChecklistItemThunk(boardId, checklistRow._id));
    setNewIssueModalOpen(false);
  };

  const apps = useAppSelector((state) => state.globalReducer.apps);

  return (
    <FoldableSection>
      <FoldableSection.Header>
        <div className="section-title">Tilknyttede saker</div>
      </FoldableSection.Header>
      <FoldableSection.Body>
        <div className="d-flex flex-column w-100 mt-2 mb-2 mr-4">
          {boardFetchError && <FailedAlert />}
          {newIssueModalOpen === true && (
            <CreateItemWithBoardSelectorModal
              boards={(issueBoards || []).map((board) => {
                return {
                  project: board.project,
                  name: board.name,
                  _id: board._id,
                  status: board.status,
                };
              })}
              onCreate={onCreateIssue}
              modalTitle="Koble sak til sjekklistepunkt"
              toggle={() => setNewIssueModalOpen(false)}
              currentApp={MCApplicationsUrlEnum.ISSUE}
              apps={apps}
              projectId={projectId}
              createButtonText="Opprett sak"
            />
          )}
          {connectExistingModalOpen === true && (
            <ConnectExistingIssueToItemModalWrapper
              onConnected={(boardId, issueId) => onConnect(issueId)}
              projectId={projectId}
              closeModal={() => setConnectExistingModalOpen(false)}
              blockedIssueIds={checklistRow.connectedIssues.map((issue) => issue._id)}
            />
          )}
          <ConnectedIssues
            issues={checklistRow.connectedIssues as IIssue[]}
            disconnectIssue={(issueId) =>
              dispatch(connectOrDisconnectIssueFromChecklistItemThunk(checklistRow._id, issueId, "DISCONNECT"))
            }
            connectExistingIssue={() => setConnectExistingModalOpen(true)}
            createNewIssue={() => setNewIssueModalOpen(true)}
            isPrintMode={false}
            disableCellEdit={true}
            titleLabel=""
            projectId={projectId}
          />
        </div>
      </FoldableSection.Body>
    </FoldableSection>
  );
};

export default ChecklistConnecetedIssues;
