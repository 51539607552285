import * as R from "ramda";
import { IOtherAttributesFormula } from "./zod/otherAttributesSchema";
import { IIssue } from "../types/global.types";

//Example: Replace "Total -> TOT, Dreike -> DR" in string "Blabla Total Dreike" -> "Blabla TOT DR". valuesToReplace={TOTAL: "TOT", DREIKE: "DR"}
export const replaceMultipleValuesInString = (value: string, valuesToReplace: Record<string, string>): string => {
  if (value == null) {
    return "";
  }

  if (value.includes("Pr")) {
    value = value.replace(/Pr[^ ]*$/, "Pr");
  }

  // Loopa genom varje nyckel i valuesToReplace-objektet och ersätt varje förekomst
  Object.keys(valuesToReplace).forEach((searchValue) => {
    const replaceValue = valuesToReplace[searchValue];
    value = value.replace(new RegExp(searchValue, "g"), replaceValue);
  });

  return value;
};

export const getUniqValues = <T>(arr: T[], key: string): T[] => {
  if (arr == null) {
    return [];
  }

  return arr.reduce((acc: T[], item: T) => {
    if (acc.find((i) => R.path(key.split("."), i) === R.path(key.split("."), item)) == null) {
      acc.push(item);
    }
    return acc;
  }, []);
};

export const getDeeplyNestedValue = (obj: any, path: string): string | undefined => {
  const keys = path.split(".");
  const result = keys.reduce((acc, key) => {
    if (acc == null || typeof acc !== "object") {
      return undefined;
    }
    return acc[key];
  }, obj);
  return typeof result === "string" ? result : undefined;
};

interface IArgs {
  formulaConfig: IOtherAttributesFormula;
  issues: IIssue[];
  singleIssue: IIssue;
}

export const getFormulaValue = (args: IArgs): string => {
  const confArr = args.formulaConfig?.confArr ?? [];

  return confArr.reduce((acc, item) => {
    if (item.type === "DELIMITER") {
      return acc + item.value;
    }
    if (item.type === "ATTRIBUTE") {
      try {
        const val = args.singleIssue?.otherAttributesData[item.value];
        if (val == null || val === "") {
          return acc;
        }
        return acc + String(val);
      } catch (error) {
        return acc;
      }
    }
    return acc;
  }, "");
};
