import React, { useState } from "react";
import { Button, UncontrolledTooltip } from "reactstrap";
import { HSpace, MCLoader, SingleComment } from "..";
import { IComment, IMember, IUser } from "../../../../types/global.types";
import { sortArrayByDate } from "../../app/utils";
import { SendIcon } from "../CustomIcons/CustomIcons";
import Editor, { lexicalStateToPlainText } from "../RichTextEditor/RichTextEditor";
import NewsSearch from "../../app/routes/projectdashboard/News/NewsSearch";

const MultiComment: React.FC<{
  comments: IComment[];
  title?: string;
  onSave: (commentId: string, text: string, metadata: IEditorMetadata) => void;
  onDelete: (commentId: string) => void;
  onCreate: (text: string, metadata: IEditorMetadata) => void;
  showSearch?: boolean;
  subTitle?: string;
  sortDesc?: boolean;
  loggedInUser?: IUser;
  noCommentsText?: string;
  members?: IMember[];
  allowDelete?: boolean;
  loading?: boolean;
  visibleCommentsOnLoad?: number;
}> = ({
  comments,
  title,
  onSave,
  onDelete,
  onCreate,
  showSearch = false, //only useful for now in News, not for comments
  subTitle,
  sortDesc = true,
  loggedInUser,
  noCommentsText,
  members,
  allowDelete = false,
  loading = false,
  visibleCommentsOnLoad = 10,
}) => {
  const filteredComments = comments.filter((item) => item.deleted === false);
  const [addNewEditor, setAddNewEditor] = useState(false);
  const [doShowAll, setDoShowAll] = useState(false);
  const sortedComments = sortArrayByDate(filteredComments, "createdDate", sortDesc);
  const commentsToShow = doShowAll ? sortedComments : sortedComments.slice(0, 5);
  return (
    <div className="d-flex flex-column w-100 h-100">
      {title != null && <h5>{title}</h5>}
      {subTitle != null && <h6 className="gray-400-text">{subTitle}</h6>}
      {addNewEditor === false && (
        <div className="d-flex">
          <Button size="sm" color="success" outline onClick={() => setAddNewEditor(true)}>
            <i className="fa fa-plus fa-fw" />
          </Button>
          <div className="flex-fill d-flex justify-content-end">{showSearch && <NewsSearch />}</div>
        </div>
      )}
      <AddNewComment
        hideAddButton={true}
        members={members}
        onAddComment={(text: string, metadata: IEditorMetadata) => onCreate(text, metadata)}
        addNewEditor={addNewEditor}
        onToggleAddNewEditor={(state) => setAddNewEditor(state)}
      />
      <div className="overflow-auto flex-fill">
        <MCLoader loading={loading} />
        {filteredComments.length === 0 && loading !== true && filteredComments.length === 0 && (
          <div className="text-center mt-3">{noCommentsText}</div>
        )}
        {commentsToShow.map((comment: IComment) => (
          <SingleComment
            key={comment._id}
            comment={comment}
            editEnabled={loggedInUser?._id === comment.createdBy?._id}
            deleteEnabled={loggedInUser?._id === comment.createdBy?._id || allowDelete}
            onSave={(text, metadata) => onSave(comment._id, text, metadata)}
            onDelete={() => onDelete(comment._id)}
          />
        ))}
        {sortedComments.length > visibleCommentsOnLoad && doShowAll === false && (
          <Button color="secondary" onClick={() => setDoShowAll(true)}>
            <span className="mx-2">
              {"Vis alt"} <i className="ml-2 fa fa-angle-down"></i>
            </span>
          </Button>
        )}
      </div>
    </div>
  );
};

export default MultiComment;
export interface IEditorMetadata {
  mentionIdArray: string[];
  plainText: string;
}
export const AddNewComment: React.FC<{
  onAddComment: (text: string, IEditorMetadata) => void;
  positionRight?: boolean;
  hideAddButton?: boolean;
  members?: IMember[];
  addNewEditor: boolean;
  onToggleAddNewEditor: (state: boolean) => void; // Add this prop to allow parent to control addNew state
}> = ({ onAddComment, positionRight, hideAddButton = false, members, addNewEditor, onToggleAddNewEditor }) => {
  const [editorState, setEditorState] = useState("");
  const [plainText, setPlainText] = useState("");
  const [editorMetadata, setEditorMetadata] = useState<IEditorMetadata>(null);
  const [hasImages, setHasImages] = useState(false);
  const abort = () => {
    onToggleAddNewEditor(false);
    setEditorState("");
  };

  const add = async () => {
    onToggleAddNewEditor(false);
    const plainText = await lexicalStateToPlainText(editorState);
    onAddComment(JSON.stringify(editorState), { ...editorMetadata, plainText: plainText });
    setEditorState("");
    setEditorMetadata(null);
  };

  const handleChange = (editorState, plainText, hasImages) => {
    setEditorState(editorState);
    setPlainText(plainText);
    setHasImages(hasImages);
  };

  return (
    <div className="w-100 mb-1">
      {addNewEditor === false && hideAddButton === false && (
        <div className={`${positionRight ? "d-flex justify-content-end" : ""} `}>
          <Button size="sm" color="success" outline onClick={() => onToggleAddNewEditor(true)}>
            <i className="fa fa-plus fa-fw" />
          </Button>
        </div>
      )}
      {addNewEditor === true && (
        <>
          <div className="d-flex w-100">
            <Editor
              members={members}
              onChange={handleChange}
              handleMentions={(e) => setEditorMetadata({ ...editorMetadata, mentionIdArray: e })}
            />
          </div>
          <div className="d-flex mt-2">
            <Button
              className="d-flex align-items-center"
              id="saveButton"
              disabled={plainText.length < 1 && hasImages === false}
              onClick={() => add()}
              color="success"
              size="sm"
            >
              Send
              <SendIcon />
            </Button>
            <UncontrolledTooltip target="saveButton" delay={{ show: 350, hide: 100 }}>
              Send
            </UncontrolledTooltip>
            <HSpace />
            <Button id="closeButton" onClick={() => abort()} size="sm">
              <i className="fa fa-close fa-fw" />
            </Button>
            <UncontrolledTooltip target="closeButton" delay={{ show: 350, hide: 100 }}>
              Avbryt
            </UncontrolledTooltip>
          </div>
        </>
      )}
    </div>
  );
};
