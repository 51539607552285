import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { STANDARD_COLORS } from "../app/globalConstants";

export const ColorDropdown = ({ activeColor, setColor }) => {
  const colors = Object.keys(STANDARD_COLORS).map((color) => color);
  return (
    <>
      <UncontrolledDropdown>
        <DropdownToggle caret color={activeColor}>
          {activeColor ?? "Velg farge"}
        </DropdownToggle>
        <DropdownMenu>
          {colors.map((color) => (
            <DropdownItem className={`bg-${color}`} key={color} onClick={() => setColor(color)}>
              {color.toUpperCase()}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};
