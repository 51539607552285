import { IConsequenceWithId, IEvaluationCategory, IRisikoProbability } from "mc-shared/zod/issueBoardSchema";
import { useMemo } from "react";
import { McDropdown, McInput, McTitle, VSpace } from "../";
import { IBoard, IIssue } from "../../../../types/global.types";
import LocalErrorBoundary from "../../util/LocalErrorBoundary";
import IssueRisikoMatrix from "../RisikoMatrix/IssueRisikoMatrix";

type IEvaluationCategoryWithId = IEvaluationCategory & { id: string };

const IssueRisikoSection: React.FC<{
  issue: IIssue;
  issueBoard: IBoard;
  onChange: (oldValue: string | boolean, newValue: string, param: string) => void;
}> = ({ issue, onChange, issueBoard }) => {
  const probabilities: IRisikoProbability[] = Object.entries(issueBoard.risikoSection?.probabilites ?? {}).map(
    ([key, value]) => ({
      ...value,
      id: key,
    }),
  );

  const current: IRisikoProbability = probabilities.find((p: IRisikoProbability) => p.id === issue.risiko?.probability);

  const columns = useMemo(() => {
    if (issueBoard.risikoSection?.evaluationCategoriesConfig == null) {
      return [];
    }

    const _categories: [string, IEvaluationCategory][] = Object.entries(
      issueBoard.risikoSection.evaluationCategoriesConfig,
    );

    if (_categories.length === 0) {
      return [];
    }

    const _items = _categories.reduce((acc, [key, value]) => {
      if (value.isActive === false) {
        return acc;
      }

      acc.push({
        id: key,
        ...value,
      });

      return acc;
    }, [] as IEvaluationCategoryWithId[]);

    // split _categories into 3 columns
    const rows: IEvaluationCategoryWithId[][] = [];

    for (let i = 0; i < _items.length; i += 3) {
      rows.push(_items.slice(i, i + 3));
    }

    // pad with nulls
    const lastRow = rows[rows.length - 1];

    if (lastRow.length < 3) {
      for (let i = lastRow.length; i < 3; i++) {
        lastRow.push(null);
      }
    }

    return rows.map((row, index) => (
      <div key={index} className="even-columns mt-2">
        {row.map((category, i) => {
          if (category == null) {
            return <div key={i}></div>;
          }

          const consequences: IConsequenceWithId[] = Object.entries(category.consequences)
            .map(([key, value]) => ({
              ...value,
              id: key,
            }))
            .filter((c) => c.isActive);

          const catId = category.id;
          const currentConsequence: string = issue.risiko?.consequence?.[catId] ?? null;

          const currVal: IConsequenceWithId =
            currentConsequence != null ? category.consequences[currentConsequence] : null;

          return (
            <div className="d-flex flex-column mr-4" key={i}>
              <McTitle title={category.name || "-"}>
                <McDropdown
                  onClear={
                    currVal != null ? () => onChange(currVal?.id ?? "", null, `risiko.consequence.${catId}`) : undefined
                  }
                  currentValue={currVal}
                  options={consequences}
                  displayKey="name"
                  onChange={(_, newValue: IConsequenceWithId) => {
                    onChange(currVal?.id ?? "", newValue.id, `risiko.consequence.${catId}`);
                  }}
                />
                <VSpace />
                <LocalErrorBoundary>
                  <IssueRisikoMatrix issue={issue} issueBoard={issueBoard} evaluationCategoryCapital={catId} />
                </LocalErrorBoundary>
              </McTitle>
            </div>
          );
        })}
      </div>
    ));
  }, [issueBoard, issue.risiko]);

  return (
    <div className="d-flex flex-column">
      <div className="even-columns mt-2">
        <div className="d-flex flex-column mr-4">
          <McTitle title="Sannsynlighet">
            <McDropdown
              onClear={current != null ? () => onChange(current?.id ?? "", null, "risiko.probability") : undefined}
              currentValue={current}
              options={(probabilities ?? []).filter((p) => p.isActive)}
              displayKey="name"
              onChange={(_, newValue) => onChange(current?.name ?? "", newValue.id, "risiko.probability")}
            />
          </McTitle>
        </div>
        <div className="d-flex flex-column mr-4"></div>
        <div className="d-flex flex-column mr-4"></div>
      </div>
      {columns}
      <div className="d-flex flex-column mt-4 mr-4">
        <McTitle title="Kommentar">
          <McInput
            onBlur={(e) => onChange(issue.risiko?.categoryComment, e.target.value, "risiko.categoryComment")}
            value={issue.risiko?.categoryComment}
            type="textarea"
            dynamicHeight={true}
          />
        </McTitle>
      </div>
    </div>
  );
};

export default IssueRisikoSection;
