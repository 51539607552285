import { toNumber } from "lodash";
import { IGeoteknikKoordinater } from "mc-shared/zod/report/Geoteknik/geoteknikSchema";
import proj4 from "proj4";
import React from "react";
import { Button, ButtonGroup, Card, CardBody, CardTitle } from "reactstrap";
import { IGisPoint, IValueLabel } from "../../../../../../types/global.types";
import { IMaconomyCoordinates } from "../../../../../../types/report.types";
import { Checkbox, McInput, VSpace } from "../../../../components";
import { ToastMessagesEnums } from "../../../../frontendConstants";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { doReportImageUpload } from "../reportBoardAPI";
import { fetchReportAndUpdateUploadedFilesThunk } from "../reportSlice";
import "./GeoteknikCSS.css";
import SingleGeoteknikReportGisMap from "./SingleGeoteknikReportGisMap";
import { setShowSuccessMessageThunk } from "../../../../global/globalSlice";

const GeoteknikMaps: React.FC<{
  geoteknikKoordinater: IGeoteknikKoordinater;
  onUpdateGeoteknikKoordinater: (koordinater: IGeoteknikKoordinater) => void;
  sirkelDiameter: number;
  onUpdateSirkelDiameter: (key: string, value: number) => void;
  zoomLevel: number;
  onUpdateZoomLevel: (zoom: number) => void;
}> = ({
  geoteknikKoordinater,
  onUpdateGeoteknikKoordinater,
  sirkelDiameter,
  onUpdateSirkelDiameter,
  zoomLevel,
  onUpdateZoomLevel,
}) => {
  const [gisPoint, setGisPoint] = React.useState<IGisPoint>(null);
  const [pinVisible, setPinVisible] = React.useState<boolean>(false);
  const [circleVisible, setCircleVisible] = React.useState<boolean>(true);

  const [activeMap, setActiveMap] = React.useState<string>("kart1");

  const maps: IValueLabel[] = [
    { value: "kart1", label: "Gråtone" },
    { value: "kart2", label: "Flyfoto" },
    { value: "kart3", label: "NGU løsmassekart" },
    { value: "kart4", label: "NVE - Fare - Kvikkleiresoner" },
  ];

  const project = useAppSelector((state) => state.adminReducer.project);
  const activeReport = useAppSelector((state) => state.reportReducer.activeReport); //state.reportReducer.activeReport;
  const [gisMapWasLoaded, setGisMapWasLoaded] = React.useState<boolean>(false);

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    logicToHandlePinAndMoveTarget();
  }, [geoteknikKoordinater, gisMapWasLoaded]);

  const logicToHandlePinAndMoveTarget = () => {
    if (geoteknikKoordinater != null && gisMapWasLoaded === true) {
      const _gisPoint = convertGeoteknikKoordinaterToGisPoints(geoteknikKoordinater);
      setGisPoint(_gisPoint);
    } else {
      setGisPoint(null);
    }
  };

  const storeScreenShot = async (image: string, docxImageTag: string) => {
    await doReportImageUpload({
      projectId: project._id,
      reportBoardId: activeReport._id,
      docxImageTag: docxImageTag,
      imageData: image,
    });
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.FILE_UPLOADED));
    dispatch(fetchReportAndUpdateUploadedFilesThunk(activeReport._id));
  };

  const logicWhenMapIsLoaded = () => {
    setGisMapWasLoaded(true);
  };

  return (
    <Card>
      <CardBody style={{ width: "500px" }}>
        <CardTitle tag="h4">Kart</CardTitle>
        <VSpace />
        <GisPoint
          geoteknikKoordinater={geoteknikKoordinater}
          onUpdateGeoteknikKoordinater={onUpdateGeoteknikKoordinater}
        />
        <VSpace />
        <ButtonGroup>
          {maps?.map((map) => (
            <Button
              key={map.value}
              onClick={() => setActiveMap(map.value)}
              color={map.value === activeMap ? "mc-blue" : "secondary"}
            >
              {map?.label}
            </Button>
          ))}
        </ButtonGroup>
        <VSpace />

        <div className="pb-5">
          {gisPoint != null && (
            <>
              <div className="border p-2" style={{ width: "300px" }}>
                <Checkbox
                  color="#4498e2"
                  isChecked={pinVisible}
                  setChecked={() => setPinVisible(!pinVisible)}
                  label="Vis pin"
                />
                <VSpace />
                <Checkbox
                  color="#4498e2"
                  isChecked={circleVisible}
                  setChecked={() => setCircleVisible(!circleVisible)}
                  label="Vis sirkel"
                />
                <div className="d-flex flex-column">
                  <h5 className="truncateHeaderText mb-0 mt-2">Diameter sirkel &#40;m&#41;</h5>
                  <McInput
                    value={sirkelDiameter}
                    onChange={(e) =>
                      onUpdateSirkelDiameter("prosjektInformasjon.sirkelDiameter", toNumber(e.target.value))
                    }
                  />
                </div>
              </div>
              <VSpace />
            </>
          )}
          {activeMap === "kart1" && (
            <SingleGeoteknikReportGisMap
              pinVisible={pinVisible === true ? "YES" : "NO"}
              circleVisible={circleVisible === true ? "YES" : "NO"}
              sirkelDiameter={sirkelDiameter}
              mapId="39bf7f38ae82498b8427a3be4cb3a4e1"
              groupId="43b82823b8d34a438add6c6e88a79b0c"
              pin={gisPoint}
              storeScreenShot={storeScreenShot}
              onLoaded={logicWhenMapIsLoaded}
              docxImageTag="kart1"
              label="Kart 1"
              onUpdateZoomLevel={onUpdateZoomLevel}
              zoomLevel={zoomLevel}
            />
          )}
          {activeMap === "kart2" && (
            <SingleGeoteknikReportGisMap
              pinVisible={pinVisible === true ? "YES" : "NO"}
              circleVisible={circleVisible === true ? "YES" : "NO"}
              sirkelDiameter={sirkelDiameter}
              mapId="39bf7f38ae82498b8427a3be4cb3a4e1"
              groupId="43b82823b8d34a438add6c6e88a79b0c"
              pin={gisPoint}
              storeScreenShot={storeScreenShot}
              onLoaded={logicWhenMapIsLoaded}
              docxImageTag="kart2"
              label="Kart 2"
              basemapId="c6958eed33c944149a9e309f28c89ed5"
              onUpdateZoomLevel={onUpdateZoomLevel}
              zoomLevel={zoomLevel}
            />
          )}
          {activeMap === "kart3" && (
            <SingleGeoteknikReportGisMap
              pinVisible={pinVisible === true ? "YES" : "NO"}
              circleVisible={circleVisible === true ? "YES" : "NO"}
              sirkelDiameter={sirkelDiameter}
              mapId="39bf7f38ae82498b8427a3be4cb3a4e1"
              groupId="43b82823b8d34a438add6c6e88a79b0c"
              pin={gisPoint}
              storeScreenShot={storeScreenShot}
              onLoaded={logicWhenMapIsLoaded}
              docxImageTag="kart3"
              label="Kart 3"
              layerIds={["wms_7250"]}
              onUpdateZoomLevel={onUpdateZoomLevel}
              zoomLevel={zoomLevel}
            />
          )}
          {activeMap === "kart4" && (
            <SingleGeoteknikReportGisMap
              pinVisible={pinVisible === true ? "YES" : "NO"}
              circleVisible={circleVisible === true ? "YES" : "NO"}
              sirkelDiameter={sirkelDiameter}
              mapId="39bf7f38ae82498b8427a3be4cb3a4e1"
              groupId="43b82823b8d34a438add6c6e88a79b0c"
              pin={gisPoint}
              storeScreenShot={storeScreenShot}
              onLoaded={logicWhenMapIsLoaded}
              docxImageTag="kart4"
              label="Kart 4"
              layerIds={["18461deed91-layer-64", "wms_1084", "wms_5457"]}
              onUpdateZoomLevel={onUpdateZoomLevel}
              zoomLevel={zoomLevel}
            />
          )}
        </div>
        <div className="pb-5" />
      </CardBody>
    </Card>
  );
};

export default GeoteknikMaps;

const GisPoint: React.FC<{
  geoteknikKoordinater: IGeoteknikKoordinater;
  onUpdateGeoteknikKoordinater: (koordinater: IGeoteknikKoordinater) => void;
}> = ({ geoteknikKoordinater, onUpdateGeoteknikKoordinater }) => {
  const updateItem = (key: string, value: any) => {
    onUpdateGeoteknikKoordinater({
      ...geoteknikKoordinater,
      [key]: value,
    });
  };

  const isSoneValid = (sone: string | number): boolean => {
    return Number(sone) === 32 || Number(sone) === 33;
  };

  return (
    <div className="border p-2" style={{ width: "300px" }}>
      {geoteknikKoordinater != null && (
        <>
          <div className="d-flex flex-column">
            <h5 className="truncateHeaderText mb-0">Sone</h5>
            <McInput value={geoteknikKoordinater.sone} onChange={(e) => updateItem("sone", e.target.value)} />
          </div>
          {isSoneValid(geoteknikKoordinater?.sone) === false && <p className="text-danger mb-0">Sone ikke gyldig</p>}
          <VSpace />
          <div className="d-flex flex-column">
            <h5 className="truncateHeaderText mb-0">Nord</h5>
            <McInput value={geoteknikKoordinater.nord} onChange={(e) => updateItem("nord", e.target.value)} />
          </div>
          <VSpace />
          <div className="d-flex flex-column">
            <h5 className="truncateHeaderText mb-0">Øst</h5>
            <McInput value={geoteknikKoordinater.ost} onChange={(e) => updateItem("ost", e.target.value)} />
          </div>
        </>
      )}
      <VSpace />
    </div>
  );
};

export const convertMaconomyCoordinatesToGeoteknikCoordinates = (
  maconomyCoords: IMaconomyCoordinates,
): IGeoteknikKoordinater => {
  const { UTMN, UTME, UTMZ } = maconomyCoords;

  if (UTMN === null || UTME === null || UTMZ === null) {
    throw new Error("Ogiltiga koordinater. Vänligen ange värden för UTMN, UTME och UTMZ.");
  }

  const sone = UTMZ;
  const nord = UTMN;
  const ost = UTME;

  return { sone, nord, ost };
};

const convertGeoteknikKoordinaterToGisPoints = (koordinater: IGeoteknikKoordinater): IGisPoint => {
  const { sone, nord, ost } = koordinater;

  if (sone === null || nord === null || ost === null || sone === "" || nord === "" || ost === "") {
    return null;
  }

  let x = Number(ost);
  let y = Number(nord);
  const wkid = oversattZonToArcgisWkid(Number(sone));

  if (sone === 32 || sone === "32") {
    try {
      const firstProjection =
        'PROJCS["ETRS89 / UTM zone 32N",GEOGCS["ETRS89",DATUM["European_Terrestrial_Reference_System_1989",SPHEROID["GRS 1980",6378137,298.257222101,AUTHORITY["EPSG","7019"]],AUTHORITY["EPSG","6258"]],PRIMEM["Greenwich",0,AUTHORITY["EPSG","8901"]],UNIT["degree",0.01745329251994328,AUTHORITY["EPSG","9122"]],AUTHORITY["EPSG","4258"]],UNIT["metre",1,AUTHORITY["EPSG","9001"]],PROJECTION["Transverse_Mercator"],PARAMETER["latitude_of_origin",0],PARAMETER["central_meridian",9],PARAMETER["scale_factor",0.9996],PARAMETER["false_easting",500000],PARAMETER["false_northing",0],AUTHORITY["EPSG","25832"],AXIS["Easting",EAST],AXIS["Northing",NORTH]]';
      const secondProjection =
        'PROJCS["ETRS89 / UTM zone 33N",GEOGCS["ETRS89",DATUM["European_Terrestrial_Reference_System_1989",SPHEROID["GRS 1980",6378137,298.257222101,AUTHORITY["EPSG","7019"]],AUTHORITY["EPSG","6258"]],PRIMEM["Greenwich",0,AUTHORITY["EPSG","8901"]],UNIT["degree",0.01745329251994328,AUTHORITY["EPSG","9122"]],AUTHORITY["EPSG","4258"]],UNIT["metre",1,AUTHORITY["EPSG","9001"]],PROJECTION["Transverse_Mercator"],PARAMETER["latitude_of_origin",0],PARAMETER["central_meridian",15],PARAMETER["scale_factor",0.9996],PARAMETER["false_easting",500000],PARAMETER["false_northing",0],AUTHORITY["EPSG","25833"],AXIS["Easting",EAST],AXIS["Northing",NORTH]]';
      const resp = proj4(firstProjection, secondProjection, [x, y]);

      return {
        x: resp[0],
        y: resp[1],
        wkid: 25833,
      };
    } catch (error) {
      console.error(error);
    }
  }

  return { x, y, wkid };
};

const oversattZonToArcgisWkid = (sone: number): number => {
  // Översättningstabell för några vanliga zoner
  const zonOversattningstabell = {
    32: 25832,
    33: 25833,
    // Lägg till fler översättningar här vid behov
  };

  // Kolla om zonen finns i översättningstabellen
  if (zonOversattningstabell.hasOwnProperty(sone)) {
    return zonOversattningstabell[sone];
  } else {
    return null;
  }
};
