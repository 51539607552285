import { ITableTemplate } from "mc-shared/zod/issueSchema";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import { IBoard } from "../../../../../../types/global.types";
import { FailedAlert, MCLoader, VSpace } from "../../../../components";
import TableTemplate from "../../../../components/TableTemplate/TableTemplate";
import { getProjectIdFromUrl } from "../../../utils";
import {
  useCreateIssueBoardTableTemplatesMutation,
  useDeleteIssueBoardTableTemplateMutation,
  useUpdateIssueBoardTableTemplateMutation,
} from "../IssueBoardRTK";
import NewTableTemplate from "./NewTableTemplate";

const IssueMalerSettings: React.FC<{ issueBoard: IBoard }> = ({ issueBoard }) => {
  const history = useHistory();
  const projectId = getProjectIdFromUrl(history.location.pathname);

  const [deleteTemplate, { isLoading: deleteTableTemplateLoading, error: deleteTableTemplateError }] =
    useDeleteIssueBoardTableTemplateMutation();
  const [updateTableTemplate, { isLoading: updateTableTemplateLoading, error: updateTableTemplateError }] =
    useUpdateIssueBoardTableTemplateMutation();

  const [createTemplate, { isLoading: createTableTemplateLoading, error: createTableTemplateError }] =
    useCreateIssueBoardTableTemplatesMutation();

  const _deleteTemplate = async (tableTemplateId: string) => {
    deleteTemplate({
      projectId: projectId,
      issueBoardId: issueBoard._id,
      tableTemplateId: tableTemplateId,
    });
    setAddNewMode(false);
  };

  const updateTemplate = async (template: ITableTemplate) => {
    updateTableTemplate({
      params: {
        projectId: projectId,
        issueBoardId: issueBoard._id,
        tableTemplateId: template._id,
      },
      body: {
        name: template.name,
        tableColumns: template.tableColumns,
        filters: template.filters,
        sortedCol: template.sortedCol,
      },
    });
    setAddNewMode(false);
  };

  const [addNewMode, setAddNewMode] = useState<boolean>(false);

  const createNewTemplate = async (name: string) => {
    createTemplate({
      projectId: projectId,
      issueBoardId: issueBoard._id,
      name: name,
    });
    setAddNewMode(false);
  };

  const error = deleteTableTemplateError ?? updateTableTemplateError ?? createTableTemplateError;
  const isLoading = deleteTableTemplateLoading || updateTableTemplateLoading || createTableTemplateLoading;

  return (
    <>
      <Card>
        <CardBody>
          <CardTitle tag="h3">Maler</CardTitle>
          {isLoading === true && <MCLoader loading={isLoading} />}
          {error != null && <FailedAlert />}
          {addNewMode && <NewTableTemplate toggle={() => setAddNewMode(!addNewMode)} addNew={createNewTemplate} />}
          {isLoading === false && error == null && (
            <>
              {issueBoard.tableTemplates.map((tableTemplate) => (
                <div key={tableTemplate._id}>
                  <TableTemplate
                    tableTemplate={tableTemplate}
                    allFilters={issueBoard.allFilters.filter((filter) => filter.dataField !== "assignedTo")}
                    onDelete={() => _deleteTemplate(tableTemplate._id)}
                    onSave={updateTemplate}
                  />
                  <VSpace />
                </div>
              ))}
              <VSpace />
              <Button color="primary" onClick={() => setAddNewMode(true)}>
                <i className="fa fa-plus fa-fw" />
              </Button>
            </>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default IssueMalerSettings;
