import React from "react";
import { ClickableTableIdButton, TaskCompletedCount } from "..";
import { ITaskCompleteCount } from "../../types";

const ClickableTableIdWithTaskCompletedCount: React.FC<{
  id: number;
  _id: string;
  onClick: (_id: string) => void;
  taskCompleteCount: ITaskCompleteCount;
}> = ({ id, _id, onClick, taskCompleteCount }) => {
  return (
    <div className="d-flex p-0 m-0 align-items-center">
      <ClickableTableIdButton id={id} _id={_id} setItem={onClick} />
      <div className="flex-fill" />
      <div>
        <TaskCompletedCount taskCompleteCount={taskCompleteCount} />
      </div>
    </div>
  );
};

export default ClickableTableIdWithTaskCompletedCount;
