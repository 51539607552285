import { ColDef } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { IAllFilter } from "mc-shared/zod/commonSchema";
import { useState } from "react";
import { IIssueSearchResult } from "../../../../../types/issue.types";
import { AG_GRID_LOCALE_NOR } from "../../../agGridLocale";
import { FreeTextSearch } from "../../../components";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { IMultipleFilterOptions } from "../../../types";
import { doSearchIssueBoardsDepricated } from "./IssueBoardApiDepricated";
import { getIssueDataFieldFromColLabel, ISSUE_STATES_OPTIONS, IssueBoardStatusEnums } from "./issueConstants";
import { addAppModalThunk } from "../../../global/globalSlice";

const IssueSearch: React.FC<{
  projectId: string;
}> = ({ projectId }) => {
  const project = useAppSelector((state) => state.adminReducer.project);

  const [searchExecuted, setSearchExecuted] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<IIssueSearchResult[]>([]);
  const [multipleFilterItems, setMultipleFilterItems] = useState<IMultipleFilterOptions[]>([
    {
      title: "Status",
      options: ISSUE_STATES_OPTIONS.map((item) => {
        return {
          value: item.value,
          label: item.value,
          isActive: false,
        };
      }),
    },
    {
      title: "Ansv. for utførelse",
      options: project.members?.map((member) => {
        return {
          value: member?.user?._id,
          label: member.user?.name,
          isActive: false,
        };
      }),
    },
  ]);

  const dispatch = useAppDispatch();

  const statusMapper = new Map<IssueBoardStatusEnums, string>([
    [IssueBoardStatusEnums.ACTIVE, "Aktiv"],
    [IssueBoardStatusEnums.COMPLETED, "Lukket"],
    [IssueBoardStatusEnums.DELETED, "Slettet"],
  ]);

  const columnDefs: ColDef<IIssueSearchResult>[] = [
    {
      headerName: "Register",
      field: "issueBoardName",
      sortable: true,
      width: 150,
      cellRenderer: (params) => (
        <div className="d-flex flex-column">
          <div className="m-0 p-0 text-truncate">{params.data.issueBoardName}</div>
          <div className="m-0 p-0 text-muted">
            ({statusMapper.get(params.data.issueBoardStatus as IssueBoardStatusEnums)})
          </div>
        </div>
      ),
      headerClass: "sortedColumn",
    },
    {
      headerName: "Beskrivelse",
      field: "title",
      sortable: true,
      flex: 1,
      cellRenderer: (params) => (
        <div className="d-flex flex-column">
          <div className="m-0 p-0 text-truncate">{params.data.title}</div>
          <div className="m-0 p-0 text-muted">({params.data.issueStatus})</div>
        </div>
      ),
      headerClass: "sortedColumn",
    },
  ];

  const rowClick = (e, row: IIssueSearchResult) => {
    if (row._id == null || row.issueBoardId == null || row.projectId == null) {
      return;
    }
    dispatch(
      addAppModalThunk({
        itemId: row._id,
        boardId: row.issueBoardId,
        projectId: row.projectId,
        app: "issues",
      }),
    );
  };

  const convertMultipleFlterItemsToAllFilters = (multipleFilterItems: IMultipleFilterOptions[]): IAllFilter[] => {
    const allFilters: IAllFilter[] = [];
    multipleFilterItems.forEach((item) => {
      const allFilterItem: IAllFilter = {
        dataField: getIssueDataFieldFromColLabel(item.title as any),
        options: item.options.map((opt) => {
          return {
            value: opt.value,
            label: opt.label,
            isActive: opt.isActive,
          };
        }),
      };
      allFilters.push(allFilterItem);
    });
    return allFilters;
  };

  const search = async (query: string) => {
    setSearchExecuted(false);
    const results = await doSearchIssueBoardsDepricated(
      projectId,
      query,
      convertMultipleFlterItemsToAllFilters(multipleFilterItems),
    );
    setSearchExecuted(true);
    setSearchResults(results);
  };

  const onModalClose = () => {
    setSearchResults([]);
    setSearchExecuted(false);
  };

  const updateMultiFilterItemOption = (title: string, value: string) => {
    setMultipleFilterItems((prev) => {
      const newFilterItems = prev.map((item) => {
        if (item.title === title) {
          const newOptions = item.options.map((opt) => {
            if (opt.value === value) {
              opt.isActive = !opt.isActive;
            }
            return opt;
          });
          return {
            title: item.title,
            options: newOptions,
          };
        }
        return item;
      });
      return newFilterItems;
    });
  };

  const clearAllMultiFilters = () => {
    setMultipleFilterItems((prev) => {
      const newFilterItems = prev.map((item) => {
        const newOptions = item.options.map((opt) => {
          opt.isActive = false;
          return opt;
        });
        return {
          title: item.title,
          options: newOptions,
        };
      });
      return newFilterItems;
    });
  };

  return (
    <div className="mb-2">
      <FreeTextSearch
        search={search}
        placeholder="Søk i alle registre"
        onModalClose={onModalClose}
        displayNoResultsText={searchResults.length === 0 && searchExecuted === true}
        updateMultiFilterItemOption={updateMultiFilterItemOption}
        multipleFilterItems={multipleFilterItems}
        clearAllFilters={clearAllMultiFilters}
      >
        {searchResults.length > 0 && (
          <div className="checklistTable ag-theme-quartz h-100">
            <AgGridReact
              rowHeight={50}
              defaultColDef={{
                sortable: true,
                suppressHeaderMenuButton: true,
                editable: false,
                cellStyle: {
                  whiteSpace: "normal",
                  lineHeight: "1.5",
                  wordBreak: "break-word",
                },
                autoHeight: false,
              }}
              rowSelection="single"
              columnDefs={columnDefs}
              getRowId={(p) => p.data._id}
              rowData={searchResults}
              suppressContextMenu={true}
              onRowClicked={(e) => {
                // e.api.deselectAll();
                rowClick(null, e.data);
              }}
              // onGridReady={(e) => e.api.autoSizeColumns()}
              localeText={AG_GRID_LOCALE_NOR}
            />
          </div>
        )}
      </FreeTextSearch>
    </div>
  );
};

export default IssueSearch;
