import React from "react";
import { Button } from "reactstrap";

const SaveButton: React.FC<{ onClick: () => void; disabled?: boolean }> = ({ onClick, disabled = false }) => {
  return (
    <Button onClick={() => onClick()} disabled={disabled} color="success">
      <i className="fa fa-floppy-o fa-fw" />
      Lagre
    </Button>
  );
};

export default SaveButton;
