import { useEffect, useRef } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { MmiTab } from "../../app/routes/bimai/mccos/MMITab/MmiTab";
import PNS from "../../app/routes/pns/PNS";
import UserItemsInProject from "../../app/routes/projectdashboard/ActiveAssignedItems/UserItemsInProject";
import EmbeddedLinkWidget from "../../app/routes/projectdashboard/EmbeddedLinkWidget/EmbeddedLinkWidget";
import ImageSlideWidget from "../../app/routes/projectdashboard/ImageSlideWidget/ImageSlideWidget";
import LinksWidget from "../../app/routes/projectdashboard/LinksWidget/LinksWidget";
import MeetingSeries from "../../app/routes/projectdashboard/MeetingSeries/MeetingSeries";
import News from "../../app/routes/projectdashboard/News/News";
import TimelineWidget from "../../app/routes/projectdashboard/TimelineWidget/TimelineWidget";
import { EmbedTypeEnum } from "../../frontendConstants";
import { NRH_Spaces_ByggJ } from "../Custom_MCBIM/NRH/SpacesByggJ/NRH_Spaces_ByggJ";
import Sotra_Validering from "../Custom_MCBIM/Sotra/Sotra_Validering";
import YggdrasilValidation from "../Custom_MCBIM/Yggdrasil/YggdrasilValidation";
import OrgChartWidget from "../OrgChartWidget/OrgChartWidget";
import OrgListWidget from "../OrgListWidget/OrgListWidget";
import WidgetWrapper from "../WidgetWrapper/WidgetWrapper";
import "./CustomGrid.css";
import NRHRoomVal from "../../app/routes/apptest/NrhRoomVal/NRHRoomVal";
import { useAppSelector } from "../../hooks";

export interface IWidgetTemplate {
  w: number;
  h: number;
  i: string;
  label: string;
  limitToProject?: string;
  component: JSX.Element;
  useWrapper?: boolean;
  wrapperProps?: {
    allowFullscreen?: boolean;
  };
}

export const allWidgetsArray: IWidgetTemplate[] = [
  {
    w: 4,
    h: 8,
    i: "orgChart",
    label: "Organisjonskart",
    component: <OrgChartWidget />,
  },
  {
    w: 4,
    h: 8,
    i: "orgList",
    label: "Medlemmer",
    component: <OrgListWidget />,
  },
  {
    w: 6,
    h: 8,
    i: "news",
    label: "Oppslagstavle",
    component: <News />,
  },
  {
    w: 12,
    h: 8,
    i: "EmbeddedLinks",
    label: "Eksterne apper",
    component: <EmbeddedLinkWidget embedType={EmbedTypeEnum.EMBEDDEDLINK} />,
  },
  {
    w: 8,
    h: 8,
    i: "VideoFeed",
    label: "Byggeplasskamera",
    component: <EmbeddedLinkWidget embedType={EmbedTypeEnum.VIDEOFEED} />,
  },
  {
    w: 12,
    h: 8,
    i: "ImageSlide",
    label: "Bilder",
    component: <ImageSlideWidget />,
  },
  {
    w: 9,
    h: 3,
    i: "timeline",
    label: "Milepæler",
    component: <TimelineWidget />,
    limitToProject: "5f6085966d375136b9835f7a",
  },
  {
    w: 4,
    h: 10,
    i: "tasks",
    label: "Dine oppgaver",
    component: <UserItemsInProject app={"dashboard"} />,
  },
  {
    w: 7,
    h: 12,
    i: "meetingSeries",
    label: "Kalender",
    component: <MeetingSeries />,
  },
  {
    w: 7,
    h: 6,
    i: "links",
    label: "Lenker",
    component: <LinksWidget />,
  },
  {
    w: 5,
    h: 15,
    i: "nrh_mmi", //didnt work to change the value of i, got blank widget
    label: "MMI",
    component: <MmiTab />,
    useWrapper: true,
    wrapperProps: { allowFullscreen: true },
  },
  {
    w: 5,
    h: 15,
    i: "nrh_roomval", //didnt work to change the value of i, got blank widget
    limitToProject: "5f6085966d375136b9835f7a",
    label: "NRH Room Validation",
    component: <NRHRoomVal />,
    useWrapper: true,
    wrapperProps: { allowFullscreen: true },
  },
  {
    w: 5,
    h: 15,
    i: "sotra_validering",
    label: "Sotra Validering",
    component: <Sotra_Validering />,
    limitToProject: "5f96bfcb7b7a543a33fd1ae1",
    useWrapper: true,
    wrapperProps: { allowFullscreen: true },
  },
  {
    w: 5,
    h: 15,
    i: "spaces_byggj",
    label: "NRH Spaces Bygg J",
    component: <NRH_Spaces_ByggJ />,
    limitToProject: "5f6085966d375136b9835f7a",
    useWrapper: true,
    wrapperProps: { allowFullscreen: true },
  },
  {
    w: 5,
    h: 15,
    i: "yggdrasil_validation",
    label: "Yggdrasil Validation",
    component: <YggdrasilValidation />,
    limitToProject: "617259772e5afd2dfca1717c",
    useWrapper: true,
    wrapperProps: { allowFullscreen: true },
  },
  {
    w: 10,
    h: 8,
    i: "pns",
    label: "PNS",
    component: <PNS viewerMode={true} />,
    useWrapper: true,
    wrapperProps: { allowFullscreen: true },
  },
];
const ResponsiveReactGridLayout = WidthProvider(Responsive);
const ReactGridLayout = ({ layoutGrid, setLayout }) => {
  const editMode = useAppSelector((state) => state.projectDashboardReducer.editMode);
  const ref = useRef(true);
  useEffect(() => {
    const firstRender = ref.current;
    if (firstRender === true) {
      ref.current = false;
    }
  });

  const handleLayoutChange = (layout) => {
    const firstRender = ref.current;
    if (firstRender === true) {
      return null;
    }
    if (layout.length > 0 && !firstRender) {
      setLayout(layout);
    }
  };
  return (
    <div className="d-flex">
      <ResponsiveReactGridLayout
        className="w-100 h-100"
        measureBeforeMount={true}
        onLayoutChange={handleLayoutChange}
        verticalCompact={true}
        layout={layoutGrid}
        breakpoints={{ lg: 1200 }}
        isDraggable={editMode}
        draggableHandle={".reactGridItemHeader"}
        isResizable={editMode}
        preventCollision={false}
        useCSSTransforms={false}
        cols={{ lg: 20, md: 10, sm: 10, xs: 2, xxs: 2 }}
        rowHeight={30}
        margin={{
          lg: [10, 10],
          md: [10, 10],
          sm: [10, 10],
          xs: [20, 20],
          xxs: [20, 20],
        }}
      >
        {layoutGrid?.map((widget) => {
          const widgetRender: IWidgetTemplate = allWidgetsArray.find((item) => item.i === widget.i);

          return (
            <div
              className="reactGridItem"
              key={widget.i}
              data-grid={{
                i: widget.i,
                x: widget.x ?? 0,
                y: widget.y ?? Infinity,
                w: widget.w,
                h: widget.h,
                minW: 1,
                maxW: 20,
                minH: 2,
                maxH: 40,
                resizeHandles: ["s", "se", "e"],
              }}
            >
              <div className="w-100 h-100 position-relative">
                <div className={`${editMode === true ? "reactGridItemHeader" : ""}`}></div>
                {widgetRender === undefined && <WidgetWrapper title={""} widgetKey={widget.i}></WidgetWrapper>}
                {widgetRender?.useWrapper === true ? (
                  <WidgetWrapper
                    title={widgetRender.label}
                    widgetKey={widget.i}
                    allowFullscreen={widgetRender?.wrapperProps?.allowFullscreen}
                  >
                    {widgetRender?.component}
                  </WidgetWrapper>
                ) : (
                  <>{widgetRender?.component}</>
                )}
              </div>
            </div>
          );
        })}
      </ResponsiveReactGridLayout>
    </div>
  );
};

export default ReactGridLayout;
