import { IAzureBlobFile } from "mc-shared/zod/azureSchema";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

const FileUploader: React.FC<{ onFileUpload: (file: File) => void; files: IAzureBlobFile[]; imageOnly?: boolean }> = ({
  onFileUpload,
  files,
  imageOnly = false,
}) => {
  const maxSize = 20 * 1024 * 1024; // 20MB in bytes
  const minSize = 1; // 1 byte
  const acceptedFileTypes = imageOnly
    ? ["image/jpeg", "image/png", "image/gif", "image/jpg"]
    : [
        "image/jpeg",
        "image/png",
        "image/gif",
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "text/plain",
        "video/mp4",
        "application/vnd.ms-powerpoint", // MIME type for .ppt
        "application/vnd.ms-outlook", // MIME type for .msg
        "message/rfc822", // MIME type for .eml
        "application/vnd.ms-powerpoint", // MIME type for .ppt
        "application/vnd.openxmlformats-officedocument.presentationml.presentation", // MIME type for .pptx
      ];

  const checkFileExtension = (fileName: string) => {
    const extension = fileName.split(".").pop()?.toLowerCase();
    return extension === "msg"; // Check for .msg extension
  };

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      if (acceptedFiles?.length + files?.length >= 20) {
        alert(`For mange filer. Kan kun laste opp 20 filer totalt.`);
        return;
      }

      if (acceptedFiles?.length > 0) {
        acceptedFiles.forEach((file: File) => {
          const isValidType = acceptedFileTypes.includes(file.type) || checkFileExtension(file.name);
          if (isValidType) {
            onFileUpload(file);
          } else {
            alert(`Ugyldig filtype.`);
          }
        });
      }

      fileRejections.forEach((file) => {
        if (file.size > maxSize) {
          alert(`Filen er for stor. Maks tillatte størrelse er 20MB.`);
        } else if (file.size < minSize) {
          alert(`Filen er tom.`);
        }
      });
    },
    [onFileUpload, files],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxSize,
    minSize,
  });

  return (
    <div>
      <div {...getRootProps()} className="dropzone" style={{ margin: "0px 0px 15px 0px" }}>
        <input {...getInputProps()} />
        <p className="mb-0" style={{ cursor: "pointer" }}>
          Dra og slipp filer her, eller klikk for å velge filer (Maks størrelse: 20MB)
        </p>
      </div>
    </div>
  );
};

export default FileUploader;
