import moment from "moment";
import {
  IChecklistCategory,
  IChecklistItem,
  IChecklistItemRow,
  IChecklistPopulated,
  TChecklistItemStatus,
} from "../../../../../types/checklist.types";
import { IProject } from "../../../../../types/global.types";
import { doGenerateDocx } from "../admin/restQueries";

interface IChecklistReportData {
  id: number;
  checklistTitle: string;
  shortDate: string;
  projectNo: number;
  projectName: string;
  cOneNames: string;
  cTwoNames: string;
  cThreeNames: string;
  checks: string;
  cOne: string;
  cTwo: string;
  cThree: string;
  categories: any[];
  hasAboutText: boolean;
  aboutText: string;
  phase: string;
  discipline: string;
  activity: string;
  preparedBy: string;
  checkedBy: string;
  approvedBy: string;
  hasItemTags: boolean;
  activeItemTags?: string;
  activeItemTagChangedBy?: string;
  activeItemTagDate?: string;
  revNr: string;
  revDate: string;
}

export const getChecklistTableRows = (
  editMode: boolean,
  checklist: IChecklistPopulated,
  checklistCategories: IChecklistCategory[],
  checklistItems: IChecklistItem[],
): IChecklistItemRow[] => {
  if (checklist == null || checklistCategories == null) {
    return [];
  }

  const tableRows: [IChecklistItemRow, IChecklistItemRow[]][] = [];

  checklistCategories.forEach((cat) => {
    const catRow = {
      _id: cat._id,
      originalCategory: cat,
      dueDate: cat.dueDate,
      inactive: null,
      title: cat.title,
      reference: "",
      collapsed: cat.collapsed === true,
      originalItem: null,
      comment: null,
      checks: null,
      itemId: null,
      categoryId: cat.categoryId,
      rowId: cat.categoryId.toString(),
    };

    let catRows: IChecklistItemRow[] = [];

    checklistItems.forEach((item: IChecklistItem) => {
      if (item.category === cat._id) {
        catRows.push({
          _id: item._id,
          originalCategory: cat,
          inactive: item.inactive,
          title: item.title,
          reference: item.reference ?? "",
          collapsed: cat.collapsed === true,
          originalItem: item,
          comment: item.comment,
          checks: {
            check1: {
              status: item.checks?.check1?.status,
            },
            check2: {
              status: item.checks?.check2?.status,
            },
            check3: {
              status: item.checks?.check3?.status,
            },
            check4: {
              status: item.checks?.check4?.status,
            },
            check5: {
              status: item.checks?.check5?.status,
            },
            check6: {
              status: item.checks?.check6?.status,
            },
            check7: {
              status: item.checks?.check7?.status,
            },
            check8: {
              status: item.checks?.check8?.status,
            },
            check9: {
              status: item.checks?.check9?.status,
            },
            check10: {
              status: item.checks?.check10?.status,
            },
            check11: {
              status: item.checks?.check11?.status,
            },
            check12: {
              status: item.checks?.check12?.status,
            },
            check13: {
              status: item.checks?.check13?.status,
            },
            check14: {
              status: item.checks?.check14?.status,
            },
            check15: {
              status: item.checks?.check15?.status,
            },
          },
          itemId: item.itemId,
          categoryId: cat.categoryId,
          rowId: `${cat.categoryId}.${item.itemId}`,
          connectedIssues: item.connectedIssues,
          comments: item.comments,
          itemTags: item.itemTags ?? [],
        });
      }
    });

    if (
      editMode === false &&
      checklist.itemTagsActive === true &&
      checklist.activeItemTags.length > 0 &&
      catRows.length > 0
    ) {
      catRows = catRows.filter((row) => {
        // row should contain all active tags
        return checklist.activeItemTags.every((tag) => row.itemTags.includes(tag));
      });

      // if all rows are removed then remove category
      if (catRows.length === 0) {
        return;
      }
    }

    tableRows.push([catRow, catRows]);
  });

  return tableRows.reduce((acc, curr) => {
    acc.push(curr[0]);
    acc.push(...curr[1]);
    return acc;
  }, []);
};

export const getCategoryCount = (categories: IChecklistCategory[]): number => {
  if (categories == null || categories.length === 0) {
    return 0;
  }

  return categories.reduce((acc, category) => (acc > category.categoryId ? acc : category.categoryId), 0);
};

export const getItemCount = (categoryId: string, items: IChecklistItem[]): number => {
  if (items == null || items.length === 0) {
    return 0;
  }

  const categoryItems = items.filter((item) => item.category === categoryId);
  return categoryItems.reduce((acc, item) => (acc > item.itemId ? acc : item.itemId), 0);
};

const statusConverter = (v: TChecklistItemStatus, checklist: IChecklistPopulated) => {
  switch (v) {
    case "YES":
      return checklist.stampSettings.yes.text;
    case "NO":
      return checklist.stampSettings.no.text;
    case "IR":
      return checklist.stampSettings.ir.text;
    case "N/A":
      return checklist.stampSettings.na.text;
    default:
      return "-";
  }
};

export const generateChecklistReportDocxAndDownload = async (
  project: IProject,
  checklist: IChecklistPopulated,
  checklistCategories: IChecklistCategory[],
  checklistItems: IChecklistItem[],
): Promise<void> => {
  const data = formatChecklistForDocxGeneration({
    project,
    checklist,
    checklistCategories,
    checklistItems,
  });

  const types = [checklist.checkTypes.check1, checklist.checkTypes.check2, checklist.checkTypes.check3].filter((v) => v.active === true);
  const count = types.filter((v) => v.active === true).length;
  const templateName = `checklist_${count}col.docx`;
  await doGenerateDocx(data, templateName, `${project.maconomy.name} - ${checklist.name}`, false);
};

interface IArgsFormatChecklistDataForDocxGeneration {
  project: IProject,
  checklist: IChecklistPopulated,
  checklistCategories: IChecklistCategory[],
  checklistItems: IChecklistItem[]
}


export const formatChecklistForDocxGeneration = (args: IArgsFormatChecklistDataForDocxGeneration) => {
  const types = [args.checklist.checkTypes.check1, args.checklist.checkTypes.check2, args.checklist.checkTypes.check3];
  const typeTitles = [
    args.checklist.checkTypes.check1.name,
    args.checklist.checkTypes.check2.name,
    args.checklist.checkTypes.check3.name,
  ];

  const colTitles = types.reduce((acc, curr, i) => {
    if (curr) {
      acc.push(typeTitles[i]);
    }

    return acc;
  }, []);

  const hasAboutText = args.checklist.aboutText != null && args.checklist.aboutText !== "";

  const data: IChecklistReportData = {
    id: args.checklist.id,
    checklistTitle: args.checklist.name,
    shortDate: moment(new Date()).format("DD-MM-YYYY"),
    projectNo: args.project.maconomy.projectNo,
    projectName: args.project.maconomy.name,
    cOneNames: "",
    cTwoNames: "",
    cThreeNames: "",
    checks: "",
    cOne: colTitles[0],
    cTwo: colTitles[1],
    cThree: colTitles[2],
    categories: [],
    hasAboutText,
    aboutText: args.checklist.aboutText != null ? `\n${args.checklist.aboutText}` : "",
    phase: args.checklist.phase ?? "",
    discipline: args.checklist.discipline ?? "",
    activity: args.checklist.activity ?? "",
    preparedBy: args.checklist.documentManagers?.preparedBy?.name ?? "",
    checkedBy: args.checklist.documentManagers?.checkedBy?.name ?? "",
    approvedBy: args.checklist.documentManagers?.approvedBy?.name ?? "",
    hasItemTags:
    args.checklist.itemTagsActive === true && args.checklist.activeItemTags != null && args.checklist.activeItemTags.length > 0,
    revNr: args.checklist.revisionNr ?? "",
    revDate: args.checklist.revisionDate ?? "",
  };

  if (args.checklist.itemTagsActive === true && args.checklist.activeItemTags != null && args.checklist.activeItemTags.length > 0) {
    data.activeItemTags = `${hasAboutText ? "\n" : ""}Etikettfilter: ${args.checklist.activeItemTags.join(", ")}`;
    data.activeItemTagChangedBy =
      typeof args.checklist.activeItemTagChangedBy === "string" ? "" : args.checklist.activeItemTagChangedBy?.name ?? "";
    data.activeItemTagDate = moment(args.checklist.activeItemTagChangedDate).format("DD-MM-YYYY");
  }

  const check1NamesSet = new Set<string>();
  const check2NamesSet = new Set<string>();
  const check3NamesSet = new Set<string>();

  args.checklistCategories.forEach((cat) => {
    const categoryRow = {
      catId: cat.categoryId,
      categoryTitle: cat.title,
      rows: [],
    };

    categoryRow.rows = args.checklistItems.reduce((acc, checklistItem) => {
      if (
        args.checklist.itemTagsActive === true &&
        args.checklist.activeItemTags.every((tag) => checklistItem.itemTags.includes(tag)) === false
      ) {
        return acc;
      }

      if (checklistItem.category === cat._id) {
        const checks =
          checklistItem.inactive.isChecked === true
            ? [args.checklist.stampSettings.ir.text, args.checklist.stampSettings.ir.text, args.checklist.stampSettings.ir.text]
            : [
                statusConverter(checklistItem.checks.check1.status, args.checklist),
                statusConverter(checklistItem.checks.check2.status, args.checklist),
                statusConverter(checklistItem.checks.check3.status, args.checklist),
              ];

        const rowContent = types.reduce((typeAcc, curr, i) => {
          if (curr) {
            typeAcc.push(checks[i]);
          }
          return typeAcc;
        }, []);

        // Checks 4 - 15 is not exported to report
        let nameOne = "";
        let nameTwo = "";
        let nameThree = "";

        if (checklistItem.checks.check1.status !== "N/A" && typeof checklistItem.checks.check1.updatedBy !== "string") {
          check1NamesSet.add(checklistItem.checks.check1.updatedBy?.name ?? "");
          nameOne = checklistItem.checks.check1.updatedBy?.name ?? "";
        }

        if (checklistItem.checks.check2.status !== "N/A" && typeof checklistItem.checks.check2.updatedBy !== "string") {
          check2NamesSet.add(checklistItem.checks.check2.updatedBy?.name ?? "");
          nameTwo = checklistItem.checks.check2.updatedBy?.name ?? "";
        }

        if (checklistItem.checks.check3.status !== "N/A" && typeof checklistItem.checks.check3.updatedBy !== "string") {
          check3NamesSet.add(checklistItem.checks.check3.updatedBy?.name ?? "");
          nameThree = checklistItem.checks.check3.updatedBy?.name ?? "";
        }

        if (checklistItem.inactive.isChecked === true && typeof checklistItem.inactive.updatedBy !== "string") {
          check1NamesSet.add(checklistItem.inactive.updatedBy?.name ?? "");
          nameOne = checklistItem.inactive.updatedBy?.name ?? "";
        }

        acc.push({
          rowId: `${cat.categoryId}.${checklistItem.itemId}`,
          rowTitle: checklistItem.title,
          rowReference: checklistItem.reference?.length > 0 ? `\n${checklistItem.reference}` : "",
          rOne: rowContent[0],
          rTwo: rowContent[1],
          rThree: rowContent[2],
          comment: checklistItem.comment,
          nameOne,
          nameTwo,
          nameThree,
        });
      }

      return acc;
    }, []);

    data.categories.push(categoryRow);
  });

  const getNamesFromSet = (checkNames: Set<string>) => {
    let names = "";
    let count = 0;

    checkNames.forEach((name) => {
      names += count > 0 ? `, ${name}` : name;
      count += 1;
    });

    return names;
  };

  data.cOneNames = getNamesFromSet(check1NamesSet);
  data.cTwoNames = getNamesFromSet(check2NamesSet);
  data.cThreeNames = getNamesFromSet(check3NamesSet);

  data.checks = `${args.checklist.stampSettings.yes.text}/${args.checklist.stampSettings.no.text}/${args.checklist.stampSettings.na.text}`;

  return data;


};