import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Input, UncontrolledTooltip } from "reactstrap";
import { CreateNewButton, HSpace, McInput, VSpace } from ".";
import { IComment, IMember, IUser } from "../../../types/global.types";
import { formatShortDate } from "../app/routes/admin/selectors";
import { getMember } from "../app/routes/risiko/util";
import { blurOnEnter, sortArrayByDate, stopPropagationOnEscape } from "../app/utils";
import { ToastMessagesEnums } from "../frontendConstants";
import Avatar from "./Avatar";
import { setShowSuccessMessageThunk } from "../global/globalSlice";

//This comment is depricated. Use MultipleComments.tsx instad

const CommentsDepricated: React.FC<{
  members: IMember[];
  comments: IComment[];
  projectId: string;
  itemId: string;
  fetchAndSetItem?: (itemId: string) => void;
  user: IUser;
  setShowSuccessMessage: any;
  collapseEnabled?: boolean;
  serverAddComment: (projectId: string, itemId: string, text: string) => any;
  serverUpdateComment: (projectId: string, itemId: string, commentId: string, text: string) => any;
  serverDelete: (projectId: string, itemId: string, commentId: string) => void;
  title: string;
  sortDesc?: boolean;
  tooltip?: string;
}> = ({
  members,
  comments,
  projectId,
  fetchAndSetItem,
  user,
  setShowSuccessMessage,
  collapseEnabled = false,
  serverAddComment,
  itemId,
  serverUpdateComment,
  serverDelete,
  title,
  sortDesc = false,
  tooltip,
}) => {
  let [_comments, setComments] = useState<IComment[]>([]);
  let [kollaps, setCollaps] = useState(collapseEnabled === true);
  useEffect(() => {
    return function cleanup() {
      if (fetchAndSetItem) {
        fetchAndSetItem(null);
      }
    };
  }, []);

  const collapsAndFetchItem = async (bool: boolean) => {
    if (bool === false) {
      await fetchAndSetItem(itemId);
    }
    setCollaps(bool);
  };

  useEffect(() => {
    if (comments.length > _comments.length) {
      filterAndSetComments(comments);
    }
  }, []);

  const filterAndSetComments = (comments: IComment[]) => {
    const commentsSorted = sortArrayByDate(comments, "createdDate", sortDesc);
    setComments(commentsSorted.filter((comment) => comment.deleted == null || comment.deleted === false));
  };

  const addCommentAndFetch = async (text) => {
    const comments = await serverAddComment(projectId, itemId, text);
    filterAndSetComments(comments);
  };

  const updateCommentText = async (commentId, text) => {
    const comments = await serverUpdateComment(projectId, itemId, commentId, text);
    setComments(comments);
    setShowSuccessMessageThunk(ToastMessagesEnums.STATUS_LOG_UPDATED);
  };

  const showAllSateLogs = () => {
    if (collapseEnabled === false) {
      return true;
    }
    return collapseEnabled && kollaps === false;
  };

  return (
    <div className="d-flex flex-column w-100 h-100">
      {collapseEnabled === false && <h5>{title}</h5>}
      <AddComment addCommentAndFetch={addCommentAndFetch} tooltip={tooltip} />
      {
        <div className="overflow-auto">
          {showAllSateLogs() &&
            _comments?.map((comment) => (
              <CommentDepricated
                collapseEnabled={collapseEnabled}
                key={comment._id}
                user={user._id}
                comment={comment}
                updateCommentText={updateCommentText}
                itemId={itemId}
                projectId={projectId}
                setShowSuccessMessage={setShowSuccessMessage}
                members={members}
                serverDelete={serverDelete}
                filterAndSetComments={filterAndSetComments}
              />
            ))}
          {_comments?.length > 0 && kollaps === true && (
            <CommentDepricated
              collapseEnabled={collapseEnabled}
              key={_comments[0]._id}
              user={user._id}
              comment={_comments[0]}
              updateCommentText={updateCommentText}
              itemId={itemId}
              projectId={projectId}
              setShowSuccessMessage={setShowSuccessMessage}
              members={members}
              serverDelete={serverDelete}
              filterAndSetComments={filterAndSetComments}
            />
          )}
        </div>
      }
      {collapseEnabled && (
        <>
          {kollaps && _comments.length > 0 && (
            <Button className="m-0 p-0" size="sm" color="link" onClick={() => collapsAndFetchItem(false)}>
              Vis alt
            </Button>
          )}
          {kollaps === false && (
            <Button className="m-0 p-0" size="sm" color="link" onClick={() => setCollaps(true)}>
              Kollaps
            </Button>
          )}
        </>
      )}
    </div>
  );
};

const CommentDepricated = ({
  collapseEnabled,
  comment,
  members,
  updateCommentText = null,
  user = null,
  itemId = null,
  projectId = null,
  setShowSuccessMessage = null,
  serverDelete,
  filterAndSetComments,
}) => {
  let createdBy;
  if (comment.createdBy != null) {
    if (comment.createdBy._id != null) {
      createdBy = getMember(members, comment.createdBy._id);
    }
    if (typeof comment.createdBy === "string") {
      createdBy = getMember(members, comment.createdBy);
    }
  }

  const [deleteMode, setDeleteMode] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const updateAndCloseEdit = (editedText) => {
    updateCommentText(comment._id, editedText);
    setEditMode(false);
  };

  const deleteLog = async (commentId) => {
    const _comments = await serverDelete(projectId, itemId, commentId);
    filterAndSetComments(_comments);
    setShowSuccessMessageThunk("Kommentar slettet");
  };

  const commentIsCreatedByLoggedInUser = (comment: IComment, userId: string) => {
    //@ts-ignore
    return comment && (comment?.createdBy === userId || comment?.createdBy?._id === userId);
  };

  return (
    <>
      <div className="d-flex w-100">
        {createdBy && collapseEnabled === false && <Avatar id={createdBy._id} />}
        <div>
          <h5 style={{ margin: 0 }} className="gray-600-text">
            {createdBy && <b className="mr-2">{createdBy.name}</b>}
            <small className="text-muted">
              {comment.createdAt != null
                ? formatShortDate(comment.createdAt || comment.createdDate)
                : moment(comment.createdDate).format("YYYY.MM.DD")}
            </small>
          </h5>
          <div
            dangerouslySetInnerHTML={{ __html: comment.text.replaceAll("\n", "<br>").replaceAll(/<(?!br)/gm, "&lt;") }}
          />
          {user && commentIsCreatedByLoggedInUser(comment, user) === true && (
            <EditAndDeleteButtons setDeleteMode={setDeleteMode} log={comment} setEditMode={setEditMode} />
          )}
        </div>
      </div>
      <Edit editMode={editMode} setEditMode={setEditMode} comment={comment} updateAndCloseEdit={updateAndCloseEdit} />
      {deleteMode && <Delete setDeleteMode={setDeleteMode} comment={comment} deleteLog={deleteLog} />}
      <VSpace />
    </>
  );
};

const Delete = ({ setDeleteMode, comment, deleteLog }) => {
  return (
    <div>
      <p className="deleteCommentText">Er du sikker på at du vil slette denne handlingen? Dette kan ikke angres!</p>
      <div className="d-flex">
        <Button color="danger" onClick={() => deleteLog(comment._id)}>
          Fjern
        </Button>
        <HSpace />
        <Button color="secondary" onClick={() => setDeleteMode(false)}>
          Avbryt
        </Button>
      </div>
    </div>
  );
};

const Edit = ({ editMode, setEditMode, comment, updateAndCloseEdit }) => {
  const [editedText, setEditedText] = useState(comment.text);

  const abortUpdate = () => {
    setEditedText(comment.text);
    setEditMode(false);
  };

  return (
    <div className="d-flex p-1 w-100">
      {editMode === true && (
        <div className="d-flex flex-column w-100">
          <McInput
            value={editedText}
            onBlur={(e) => setEditedText(e.target.value)}
            type="textarea"
            dynamicHeight={true}
            autoFocus
          />
          <div className="d-flex mt-1">
            <div className="flex-fill" />
            <Button
              id="saveButton"
              color="success"
              size="sm"
              disabled={editedText.length < 1}
              onClick={() => updateAndCloseEdit(editedText)}
            >
              <i className="fa fa-floppy-o fa-fw" />
            </Button>
            <UncontrolledTooltip target="saveButton" delay={{ show: 350, hide: 100 }}>
              Lagre
            </UncontrolledTooltip>
            <HSpace />
            <Button id="closeButton" color="secondary" size="sm" onClick={() => abortUpdate()}>
              <i className="fa fa-close fa-fw" />
            </Button>
            <UncontrolledTooltip target="closeButton" delay={{ show: 350, hide: 100 }}>
              Avbryt
            </UncontrolledTooltip>
          </div>
        </div>
      )}
    </div>
  );
};

const EditAndDeleteButtons = ({ log, setDeleteMode, setEditMode }) => {
  return (
    <>
      <span style={{ cursor: "pointer" }} id={`A${log._id}`} onClick={() => setDeleteMode(true)}>
        <i className="fa fa-trash fa-fw" />
      </span>
      <span
        className="gray-600-text"
        style={{ cursor: "pointer" }}
        id={`C${log._id}`}
        onClick={() => setEditMode(true)}
      >
        <i className="fa fa-pencil fa-fw text-primary" />
      </span>
    </>
  );
};

const AddComment = ({ addCommentAndFetch, tooltip }) => {
  const [addNew, setAddNew] = useState<boolean>(false);
  const [stateLog, updateStateLog] = useState("");

  const addLog = () => {
    addCommentAndFetch(stateLog);
    updateStateLog("");
    setAddNew(false);
  };

  const handleEsc = () => {
    updateStateLog("");
    return setAddNew(false);
  };
  return (
    <div className="w-100 mb-2">
      {addNew === false && <CreateNewButton onClick={() => setAddNew(true)} tooltip={tooltip} />}
      <VSpace />
      {addNew && (
        <>
          <div className="d-flex w-100">
            <Input
              value={stateLog}
              autoFocus
              type="textarea"
              onChange={(e) => updateStateLog(e.target.value)}
              style={{ height: "75px", width: "100%", margin: "4px" }}
              placeholder="Skriv inn ny"
              onKeyDown={(e) => blurOnEnter(e, addLog)}
              onKeyUp={(e) => stopPropagationOnEscape(e, handleEsc)}
            />
          </div>
          <div className="d-flex mt-2">
            <div className="flex-fill" />
            <Button disabled={stateLog.length < 1} onClick={() => addLog()} color="success" size="sm">
              <i className="fa fa-floppy-o fa-fw" />
            </Button>
            <HSpace />
            <Button id="closeButton" color="secondary" size="sm" onClick={() => setAddNew(false)}>
              <i className="fa fa-close fa-fw" />
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default CommentsDepricated;
