import * as R from "ramda";
import React, { useEffect, useState } from "react";
import { IArcgisMap, TDimension } from "../../../../../../types/global.types";
import { apiSlice } from "../../../../apiSlice";
import { DropdownSelectorDeprecated } from "../../../../components/";
import { DimensionEnums, ToastMessagesEnums } from "../../../../frontendConstants";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { setProjectArcgisInRedux } from "../../../actions";
import { doUpdateDefaultMap } from "../../gis/GisAPI";
import { Icon } from "../bim360Settings/ConnectedBim360Project";
import { setShowSuccessMessageThunk } from "../../../../global/globalSlice";

const UpdateDefaultArcgisMap: React.FC<{
  projectId: string;
  defaultMapId: string;
  arcgisMaps: IArcgisMap[];
  dimension: TDimension;
}> = ({ projectId, defaultMapId, arcgisMaps, dimension }) => {
  const [map, setMap] = useState<IArcgisMap>(null);

  const dispatch = useAppDispatch();

  const project = useAppSelector((state) => state.adminReducer.project);

  const updateMap = async (_map: IArcgisMap) => {
    const defaultMap = await doUpdateDefaultMap(projectId, _map?.id, dimension);
    let arcgis = R.clone(project.arcgis);
    if (dimension === DimensionEnums.TWODIMENSION) {
      arcgis.default2dMap = defaultMap;
    }
    if (dimension === DimensionEnums.THEREEDIMENSION) {
      arcgis.default3dMap = defaultMap;
    }
    dispatch(setProjectArcgisInRedux(arcgis));
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.UPDATED));
    dispatch(apiSlice.util.invalidateTags(["Project"]));
    setMap(_map);
    dispatch(setShowSuccessMessageThunk("Standard kart oppdatert"));
  };

  useEffect(() => {
    const _map = arcgisMaps.find((mp) => mp.id === defaultMapId);
    setMap(_map);
  }, []);

  return (
    <div className="d-flex p-0 m-0">
      <DropdownSelectorDeprecated
        selectText={map == null ? "Velg" : null}
        color={map == null ? "danger" : "secondary"}
        activeItem={map}
        setItem={(item) => updateMap(item)}
        items={arcgisMaps || []}
        displayKey="title"
      />
      <Icon isValid={map != null} />
    </div>
  );
};

export default UpdateDefaultArcgisMap;
