import { NoAccess, SuccessMessage } from "../components";
import { Route, useHistory } from "react-router-dom";
import React, { useEffect } from "react";
import Login from "./routes/login/Login";
import "../styles/app.css";
import { MaconomyProjects } from "./routes/projects/MacProjects/Projects";
import { useAppDispatch, useAppSelector } from "../hooks";
import OpenedAppModals from "./OpenedAppModals";
import { PasswordReset } from "./routes/login/PasswordReset";
import FailedMessage from "../components/FailedMessage";
import ProjectApp from "./ProjectApp";
import { logicWhenAppStarts } from "./actions";
import AcceptInvite from "./routes/accept-invite/AcceptInvite";
import { Stats } from "./routes/stats/Stats";
import ErrorPage from "./routes/stats/Errors/ErrorPage";
import Apptest from "./routes/apptest/Apptest";
import Appadmin from "./routes/appadmin/Appadmin";
import { MyTasksPage } from "./routes/myTasks/MyTasksPage";
import ReportAdmin from "./routes/report/reportAdmin/ReportAdmin";
import ReportAdminPage from "./routes/report/reportAdmin/ReportAdminPage";
import { Alert, Button } from "reactstrap";
import { thunkReloadAppThunk } from "../global/globalSlice";
import DoksamChecklistPath from "./DoksamChecklistPath";

const App: React.FC = () => {
  const [showDevBanner, setShowDevBanner] = React.useState(true);

  const history = useHistory();
  const currrentPath = history.location.pathname;
  const dispatch = useAppDispatch();

  const isProduction = useAppSelector((state) => state.globalReducer.isProduction);

  const user = useAppSelector((state) => state.globalReducer.user);

  const goToLink = localStorage.getItem("gotolink");

  const isPasswordResetPage = currrentPath?.includes("password-reset");

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (user != null && (currrentPath === "/" || currrentPath === "")) {
      history.push("/projects");
    }
  }, [user]);

  const init = () => {
    if (isPasswordResetPage === true) {
      localStorage.removeItem("gotolink");
    }
    if (goToLink != null && isPasswordResetPage === false) {
      console.log(`redirecting to ${goToLink}`);
      localStorage.removeItem("gotolink");
      history.push(goToLink);
    }
    dispatch(logicWhenAppStarts(history));
  };
  return (
    <>
      <OpenedAppModals />
      <SuccessMessage />
      <FailedMessage />
      <Route
        path={`/accept-invite/:userId/:code`}
        exact
        render={(props) => <AcceptInvite code={props.match.params.code} userId={props.match.params.userId} />}
      />
      <Route path="/no-access" component={NoAccess} />
      <Route path="/login" component={Login} />
      <Route path={`/password-reset/:userId/:code`} render={(props) => <PasswordReset match={props.match} />} />
      <Route exact path="/terminated" component={Terminated} />
      <>
        {user != null && (
          <>
            <Route path="/projects">
              <MaconomyProjects />
            </Route>
            <Route path="/project/:projectId" component={ProjectApp} />
            <Route exact path="/stats" component={Stats} />
            <Route exact path="/errors" component={ErrorPage} />
            <Route exact path="/test" component={Apptest} />
            <Route path={`/appadmin`} component={Appadmin} />
            <Route exact path="/tasks" component={MyTasksPage} />
            <Route exact path="/reportadmin" component={ReportAdmin} />
            <Route
              path={`/reportadmin/:reportId`}
              render={(props) => <ReportAdminPage reportId={props.match.params.reportId} />}
            />

            <Route
              exact
              path={`/doksam/checklist`}
              render={(props) => <DoksamChecklistPath searchStr={props.location.search} />}
            />
          </>
        )}
      </>
      {isProduction === false && showDevBanner === true && (
        <div
          className="d-flex align-items-center justify-content-center bg-danger w-100"
          style={{ position: "absolute", bottom: 0, zIndex: 5000000 }}
        >
          <Button color="success" onClick={() => dispatch(thunkReloadAppThunk(history))}>
            RELOAD!
          </Button>
          <div className="d-flex flex-grow-1 justify-content-center">
            <h1 className="text-white">Dette er et testmiljø!!!</h1>
          </div>
          <h1 className="text-white">
            <i style={{ cursor: "pointer" }} className="fa fa-close fa-fw" onClick={() => setShowDevBanner(false)} />
          </h1>
        </div>
      )}
    </>
  );
};

export default App;

const Terminated: React.FC = () => {
  return (
    <div className="d-flex flex-column gray-200 w-100 h-100 pt-5 align-content-center" style={{ width: "300px" }}>
      <Alert color="danger">Brukeren din er suspendert. Vennligst prøv igjen senere.</Alert>
    </div>
  );
};
