import React, { useRef } from "react";
import { Button, ButtonProps } from "reactstrap";

type UploadButtonProps = {
  onFileSelected: (event: React.ChangeEvent<HTMLInputElement>) => void;
  buttonText: string;
} & ButtonProps;

const UploadButton: React.FC<UploadButtonProps> = ({ onFileSelected, buttonText, ...props }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      onFileSelected(event);
    }
  };

  return (
    <div>
      <Button color="success" {...props} onClick={handleButtonClick}>
        <i className="fa fa-upload fa-fw" />
        {buttonText}
      </Button>
      <input type="file" ref={fileInputRef} style={{ display: "none" }} onChange={handleFileChange} />
    </div>
  );
};

export default UploadButton;
