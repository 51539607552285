import React from "react";
import { Button, UncontrolledTooltip } from "reactstrap";

import { useHistory } from "react-router-dom";
import { IMeeting } from "../../../../../../types/meeting.types";
import { HSpace, McDropdown } from "../../../../components";
import { useAppSelector } from "../../../../hooks";
import { sortArrayByDate } from "../../../utils";
import { formatShortDate } from "../../admin/selectors";
import NewMeetingInSeriesModal from "../NewMeetingInSeriesModal";

const MeetingSeriesInstanceSelector: React.FC<{ singleMeeting: IMeeting.MeetingPopulated }> = ({ singleMeeting }) => {
  const meetingSerie = singleMeeting?.meetingSerie as IMeeting.MeetingSeriePopulated;
  const [newMeetingModalOpen, setNewMeetingModalOpen] = React.useState<boolean>(false);

  const project = useAppSelector((state) => state.adminReducer.project);

  const history = useHistory();
  const onChange = (oldDate: IMeeting.MeetingPopulated, meetingObj: IMeeting.MeetingPopulated) => {
    history.replace(`/project/${project._id}/meetings/${meetingObj._id}`);
  };

  const connectedMeetings: IMeeting.MeetingPopulated[] = (meetingSerie?.connectedMeetingInstances || []).reduce(
    (acc, meeting) => {
      if (meeting.isDeleted !== true) {
        acc.push({
          ...meeting,
          dateOfMeeting: formatShortDate(meeting.dateOfMeeting?.toString()),
        });
      }
      return acc;
    },
    [],
  );

  return (
    <div className="d-flex">
      {newMeetingModalOpen === true && (
        <NewMeetingInSeriesModal meetingSerie={meetingSerie} toggle={() => setNewMeetingModalOpen(false)} />
      )}
      <McDropdown
        color="mc-blue"
        onChange={onChange}
        currentValue={singleMeeting}
        options={sortArrayByDate(connectedMeetings, "dateOfMeeting")}
        formatter={(val) => formatShortDate(val as string)}
        displayKey="dateOfMeeting"
      />
      <HSpace />
      <Button id="addNewMeeting" color="success" onClick={() => setNewMeetingModalOpen(true)}>
        <i className="fa fa-plus" />
      </Button>
      <UncontrolledTooltip delay={300} placement="bottom" target="addNewMeeting">
        Legg til nytt møte
      </UncontrolledTooltip>
    </div>
  );
};

export default MeetingSeriesInstanceSelector;
