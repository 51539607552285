import React, { useState } from "react";
import { Button, ButtonGroup, UncontrolledTooltip } from "reactstrap";
import WidgetWrapper from "../../../../components/WidgetWrapper/WidgetWrapper";
import UserAssignedIssuesInProject from "./UserAssignedIssuesInProject";
import UserCreatedChecklistsInProject from "./UserCreatedChecklistsInProject";
import UserParticipantInMeeting from "./UserParticipantInMeeting";

const UserItemsInProject: React.FC<{ app: string }> = ({ app }) => {
  const [activeView, setActiveView] = useState<string>("Tasks");

  const Title = () => {
    let title;
    if (activeView === "Tasks") {
      title = "Dine oppgaver";
    } else if (activeView === "Meetings") {
      title = "Dine møter";
    } else if (activeView === "Checklists") {
      title = "Dine sjekklister";
    }
    return title;
  };

  const toggleList = (activeTable: string) => {
    if (activeTable === "tasks") {
      setActiveView("Tasks");
    }
    if (activeTable === "meetings") {
      setActiveView("Meetings");
    }
    if (activeTable === "checklists") {
      setActiveView("Checklists");
    }
  };

  const ShowYourTasksButton = () => {
    return (
      <>
        <Button
          className="px-2"
          id="activeAssignedItemsFilterButton"
          onClick={() => toggleList("tasks")}
          color="secondary"
          active={activeView === "Tasks"}
        >
          <span>
            <p className="m-0 text-truncate">oppgaver</p>
          </span>
        </Button>
        <UncontrolledTooltip placement="bottom" target="activeAssignedItemsFilterButton" delay={300}>
          Trykk for å vise dine oppgaver
        </UncontrolledTooltip>
      </>
    );
  };

  const ShowYourMeetingsButton = () => {
    return (
      <>
        <Button
          // className="px-3"
          id="activeAssignedMeetingsFilterButton"
          onClick={() => toggleList("meetings")}
          color="secondary"
          active={activeView === "Meetings"}
        >
          <span>
            <p className="m-0 text-truncate">møter </p>
          </span>
        </Button>
        <UncontrolledTooltip placement="bottom" target="activeAssignedMeetingsFilterButton" delay={300}>
          Trykk for å vise dine møter
        </UncontrolledTooltip>
      </>
    );
  };

  const ShowYourChecklistsButton = () => {
    return (
      <>
        <Button
          className="px-2"
          id="activeChecklistsFilterButton"
          onClick={() => toggleList("checklists")}
          color="secondary"
          active={activeView === "Checklists"}
        >
          <span>
            <p className="m-0 text-truncate">sjekklister</p>
          </span>
        </Button>
        <UncontrolledTooltip placement="bottom" target="activeChecklistsFilterButton" delay={300}>
          Trykk for å vise dine sjekklister
        </UncontrolledTooltip>
      </>
    );
  };

  return (
    <WidgetWrapper
      widgetKey="tasks"
      customButtons={
        <>
          <ButtonGroup className="mb-1 mr-1">
            <ShowYourTasksButton />
            <ShowYourMeetingsButton />
            <ShowYourChecklistsButton />
          </ButtonGroup>
        </>
      }
    >
      {activeView === "Tasks" && <UserAssignedIssuesInProject app={app} />}
      {activeView === "Meetings" && <UserParticipantInMeeting />}
      {activeView === "Checklists" && <UserCreatedChecklistsInProject />}
    </WidgetWrapper>
  );
};

export default UserItemsInProject;
