import { OrgChart } from "d3-org-chart";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { IParentChildItem } from "../../../../../../types/global.types";
import Styles from "./parentChildChart.module.css";
import { debounce } from "./utils";

const D3OrganisationChart: React.FC<{
  items: IParentChildItem[];
  onClick: (id: string) => void;
  size: "Standard" | "Komprimert";
}> = ({ items, onClick, size }) => {
  const chart = useMemo(() => new OrgChart(), []);
  const d3Ref = useRef<HTMLDivElement>(null);
  const holder = useRef<HTMLDivElement>(null);
  const [formattedItems, setFormattedItems] = useState<any>();

  useEffect(() => {
    setTimeout(() => {
      chart.expandAll();
    }, 500);
  }, [items.length]);

  if (holder.current && chart) {
    chart.svgHeight(holder.current.clientHeight);
  }

  const format = useCallback(() => {
    const formatted = items.map((item) => {
      const { parent = "", id } = item;
      return {
        ...item,
        connectorLineColor: {
          red: 0,
          green: 0,
          blue: 0,
          alpha: 0.6,
        },
        nodeId: id,
        parentNodeId: parent.length !== 0 ? parent : null,
      };
    });
    setFormattedItems(formatted);
  }, [items?.length]);

  useEffect(() => {
    const onResize = () => {
      if (holder.current) {
        chart.svgHeight(holder.current.clientHeight);
        chart.render();
      }
    };
    const debouncedFn = debounce(onResize, 100);
    window.addEventListener("resize", debouncedFn);

    return () => {
      window.removeEventListener("resize", debouncedFn);
    };
  }, [chart]);

  useEffect(() => {
    format();
  }, [format]);

  const SIZEPROPS = {
    WIDTH: size === "Standard" ? 250 : 200,
    HEIGHT: size === "Standard" ? 180 : 125,
    CHILDRENMARGIN: size === "Standard" ? 40 : 10,
    COMPACTMARGIN: size === "Standard" ? 15 : 7,
    COMPACTMARGINPAIR: size === "Standard" ? 80 : 40,
  };

  useLayoutEffect(() => {
    if (d3Ref.current && formattedItems) {
      try {
        chart
          .container(d3Ref.current)
          .data(formattedItems)
          .nodeWidth((d) => SIZEPROPS.WIDTH)
          .initialZoom(0.7)
          .nodeHeight((d) => SIZEPROPS.HEIGHT)
          .childrenMargin((d) => SIZEPROPS.CHILDRENMARGIN)
          .compactMarginBetween((d) => SIZEPROPS.COMPACTMARGIN)
          .compactMarginPair((d) => SIZEPROPS.COMPACTMARGINPAIR)
          .svgWidth(500)
          .initialZoom(0.8)
          .onNodeClick((d: any) => {
            onClick(d);
          })
          .nodeContent((d: any, i: any, arr: any, state: any) => {
            const { label, subLabel, imageUrl, nrOfOpenIssues, nrOfOverdueIssues } = d.data;

            if (size === "Komprimert") {
              return SmallBox(d, label, subLabel);
            }
            if (size === "Standard") {
              return StandardBox(d, imageUrl, label, subLabel, nrOfOpenIssues, nrOfOverdueIssues);
            }
          })
          .render();
      } catch (err) {
        console.error(err);
      }
    }
  }, [formattedItems, d3Ref, chart]);

  return (
    <div className={`d-flex flex-column ${Styles.holder}`}>
      <div ref={holder} className={Styles.holder}>
        <div id="parent-child-chart" ref={d3Ref} className={Styles.svgHolder} />
      </div>
    </div>
  );
};

export default D3OrganisationChart;

const StandardBox = (
  d,
  imageUrl: string,
  label: string,
  subLabel: string,
  nrOfOpenIssues: number,
  nrOfOverdueIssues: number,
) => {
  return `<div style="padding-top:30px;background-color:white;margin-left:1px;height:${
    d.height
  }px;border-radius:2px;overflow:visible">
              <div style="height:${
                d.height - 32
              }px;padding-top:0px;background-color:white;border:1px solid lightgray;position:relative">
                <img src=" ${imageUrl}" style="margin-top:-30px;margin-left:${
    d.width / 2 - 30
  }px;border-radius:100px;width:60px;height:60px;" />
  
               <div style="margin-top:-30px;background-color:#3d3d3d;height:10px;width:${
                 d.width - 2
               }px;border-radius:1px"></div>

               <div style="padding:20px; padding-top:35px;text-align:center">
                   <div style="color:#f8992e;font-size:16px;font-weight:bold"> ${label} </div>
                   <div style="color:#404040;font-size:16px;margin-top:4px"> ${subLabel || ""} </div>
               </div> 
               ${
                 nrOfOpenIssues != null
                   ? `<div style="position:absolute;bottom:10px;right:10px">
                 <div> <span style="color:#00975f">Åpne saker:  <b>${nrOfOpenIssues || 0}</b></span>
                 <br>
                 <span style="color:#dc3545">Passert tidsfrist:  <b>${nrOfOverdueIssues || 0}</b></span>
                 </div>  
               </div>`
                   : `
                   <span />
                 `
               }
              </div>     
      </div>
  `;
};

const SmallBox = (d: any, label, subLabel) => {
  return `<div style="padding-top:30px;background-color:white;margin-left:1px;height:${
    d.height
  }px;border-radius:2px;overflow:visible">
              <div style="height:${
                d.height - 32
              }px;padding-top:0px;background-color:white;border:1px solid lightgray;position:relative">
               <div style="margin-top:-15px;background-color:#3d3d3d;height:10px;width:${
                 d.width - 2
               }px;border-radius:1px"></div>

               <div style="padding:20px; padding-top:10px;text-align:center">
                   <div style="color:#f8992e;font-size:16px;font-weight:bold"> ${label} </div>
                   <div style="color:#404040;font-size:16px;margin-top:4px"> ${subLabel || ""} </div>
               </div> 
      </div>
  `;
};
