import React from "react";
import { IBim360FolderContent, IBim360FolderWithPermissionItems } from "../../../../../types/global.types";
import { FailedAlert } from "../../../components";
import Bim360DirectoryAccess from "../../../components/Bim360DirectoryAccess/Bim360DirectoryAccess";
import MCLoader from "../../../components/MCLoader/MCLoader";
import { getBim360Foldername, getBim360Permissions, getFormattedBim360Folders } from "./util";

const BIM360FolderPermissionReader: React.FC<{
  activeBim360Folder: IBim360FolderContent;
  projectId: string;
  bim360ProjectId: string;
  includeSubs: boolean;
}> = ({ activeBim360Folder, projectId, bim360ProjectId, includeSubs }) => {
  const [folderPermissions, setFolderPermissions] = React.useState<IBim360FolderWithPermissionItems[]>(null);
  const [failed, setFailed] = React.useState(false);

  React.useEffect(() => {
    fetchAndSetFolderPermissions(activeBim360Folder);
  }, []);

  const fetchAndSetFolderPermissions = async (folder: IBim360FolderContent) => {
    try {
      const folders = await getFormattedBim360Folders(projectId, bim360ProjectId, folder.urn);
      const permissions = await getBim360Permissions(projectId, bim360ProjectId, folders, includeSubs);
      setFolderPermissions(permissions);
    } catch (error) {
      setFailed(true);
    }
  };

  return (
    <div>
      <MCLoader loading={folderPermissions == null} />
      {folderPermissions != null && (
        <Bim360DirectoryAccess
          folderPermissions={folderPermissions}
          folderName={getBim360Foldername(activeBim360Folder)}
        />
      )}
      {failed && <FailedAlert errorMessage="Failed to load bim360 permissions" />}
    </div>
  );
};

export default BIM360FolderPermissionReader;
