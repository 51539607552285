import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

const FileUploaderDepricated: React.FC<{ onFileUpload: (file) => void }> = ({ onFileUpload }) => {
  const onDrop = useCallback((acceptedFiles) => {
    const arr = [];
    acceptedFiles.forEach((file) => {
      onFileUpload(file);
      if (arr.length === acceptedFiles.length) {
      }
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div>
      <>
        <div {...getRootProps()} className="dropzone" style={{ margin: "50px" }}>
          <input {...getInputProps()} />
          <p className="mb-0" style={{ cursor: "pointer" }}>
            Dra og slipp fil her, eller klikk for å velge fil
          </p>
        </div>
      </>
    </div>
  );
};

export default FileUploaderDepricated;
