import React, { useState } from "react";
import { Button, Card, CardBody, CardHeader, Form, FormGroup, Input } from "reactstrap";

const CreateAccount: React.FC<{ onCreateAccount: (name: string, password: string) => void }> = ({
  onCreateAccount,
}) => {
  const [name, setName] = useState<string>();
  const [newPassword, setNewPassword] = useState<string>("");
  const [newPasswordAgain, setNewPasswordAgain] = useState<string>("");
  const [passwordDontmatch, setPasswordDontMatch] = useState<boolean>(false);
  const [nameIsAnEmail, setNameIsAnEmail] = useState<boolean>(false);

  const createAccount = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isNameAnEmail = name.includes("@");
    const doPasswordsMatch = newPassword === newPasswordAgain;

    setNameIsAnEmail(isNameAnEmail);
    setPasswordDontMatch(!doPasswordsMatch);

    if (isNameAnEmail === false && doPasswordsMatch === true) {
      onCreateAccount(name, newPassword);
    }
  };

  return (
    <Card>
      <CardHeader>Opprett konto</CardHeader>
      <CardBody>
        <Form style={{ width: "100%" }} onSubmit={(e) => createAccount(e)}>
          <FormGroup>
            <Input
              required
              minLength={2}
              type="text"
              placeholder="Skriv inn navnet ditt"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Input
              required
              minLength={8}
              type="password"
              placeholder="Velg et passord"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Input
              required
              minLength={8}
              type="password"
              placeholder="Passord (igjen)"
              value={newPasswordAgain}
              onChange={(e) => setNewPasswordAgain(e.target.value)}
            />
          </FormGroup>
          {nameIsAnEmail === true && <p className="text-danger">Passordet kan ikke være en email.</p>}
          {passwordDontmatch === true && <p className="text-danger">Passordene er ikke like. Prøv igjen.</p>}
          <div className="d-flex">
            <div className="flex-fill" />
            <Button color="primary">Opprett konto</Button>
            <div className="flex-fill" />
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};

export default CreateAccount;
