import React from "react";
import { McDropdownBase } from "./";
import { ITableTemplate } from "mc-shared/zod/issueSchema";

const TableTemplateSelector: React.FC<{
  tableTemplates: ITableTemplate[];
  setTableTemplate: (template: ITableTemplate) => void;
  goToSettings: any;
  reset: () => void;
  selectedTemplate: ITableTemplate;
}> = ({ tableTemplates, setTableTemplate, goToSettings, reset, selectedTemplate }) => {
  const buttonLabel = selectedTemplate == null ? "Maler" : `Valgt mal: ${selectedTemplate.name}`;

  return (
    <McDropdownBase color={selectedTemplate == null ? "secondary" : "primary"}>
      <McDropdownBase.Display>
        <div>{buttonLabel}</div>
      </McDropdownBase.Display>
      {tableTemplates.map((template) => (
        <McDropdownBase.Option
          key={template.name}
          selected={template?.name === selectedTemplate?.name}
          onClick={() => setTableTemplate(template)}
        >
          <div className="truncateHeaderText">{template.name}</div>
        </McDropdownBase.Option>
      ))}
      {tableTemplates.length > 0 && <McDropdownBase.Option divider key="__mc_divider" />}
      {tableTemplates.length > 0 && (
        <McDropdownBase.Option key="__mc_reset" onClick={() => reset()}>
          <i className="fa fa-undo fa-fw" />
          Reset
        </McDropdownBase.Option>
      )}
      <McDropdownBase.Option key="__mc_settings" onClick={() => goToSettings()}>
        <i className="fa fa-cog fa-fw" />
        Gå til Innstillinger
      </McDropdownBase.Option>
    </McDropdownBase>
  );
};

export default TableTemplateSelector;
