import * as R from "ramda";
import React, { useEffect, useState } from "react";
import { RapportOptionSection, ReportProjectInfo } from "../../../../components";
import { IBrandrapportConfig } from "./types";
import { createSelects } from "./util";

const Brannrapport: React.FC<{
  onUpdateEditData: (data, dataToGenerateDocxWith) => void;
  data: IBrandrapportConfig;
}> = ({ onUpdateEditData, data }) => {
  const [editData, setEditData] = useState<IBrandrapportConfig>(R.clone(data));

  useEffect(() => {
    onUpdateEditData(data, data);
  }, []);

  useEffect(() => {
    if (editData != null) {
      const d = getDataToGenerateDocxWith(editData);
      onUpdateEditData(editData, d);
    }
  }, [editData]);

  React.useEffect(() => {
    setEditData(null);
    setTimeout(() => {
      setEditData(data);
    }, 50);
  }, [data]);

  const setOption = (sectionKey: string, checkboxKey: string) => {
    const newOption = {
      ...editData.optionSections[sectionKey].options[checkboxKey],
    };

    newOption.isSelected = !newOption.isSelected;

    const section = {
      ...editData.optionSections[sectionKey],
      options: {
        ...editData.optionSections[sectionKey].options,
        [checkboxKey]: newOption,
      },
    };

    setEditData({
      ...editData,
      optionSections: {
        ...editData.optionSections,
        [sectionKey]: section,
      },
    });
  };

  const updateInfoOmUppdrag = (value: string, key: string) => {
    const updatedInfoOmOppdraget = {
      ...editData.infoOmOppdraget,
      [key]: value,
    };

    setEditData({
      ...editData,
      infoOmOppdraget: updatedInfoOmOppdraget,
    });
  };

  const getDataToGenerateDocxWith = (dataToMod: IBrandrapportConfig) => {
    const selects = Object.keys(dataToMod.optionSections).reduce((acc, key) => {
      acc[key] = createSelects(dataToMod.optionSections[key]);
      return acc;
    }, {});
    return {
      infoOmOppdraget: dataToMod.infoOmOppdraget,
      ...dataToMod.optionSections,
      SELECTS: selects,
    };
  };

  return (
    <div className="p-3 h-100 overflow-auto">
      {editData != null && (
        <>
          <ReportProjectInfo infoOmUppdraget={editData.infoOmOppdraget} setInfOmOppdrag={updateInfoOmUppdrag} />
          {Object.keys(editData.optionSections).map((optionSection) => {
            return (
              <RapportOptionSection
                optionSection={editData.optionSections[optionSection]}
                setOption={setOption}
                sectionKey={optionSection}
              />
            );
          })}
          <hr />
        </>
      )}
    </div>
  );
};

export default Brannrapport;
