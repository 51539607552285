export enum DimensionEnums {
  TWODIMENSION = "2D",
  THEREEDIMENSION = "3D",
}

export enum ArcGisAcccessEnums {
  PUBLIC = "public",
  ORG = "org",
  PRIVATE = "private",
}

export enum IfcVersionEnums {
  IFC2X3 = "IFC2X3",
  IFC4 = "IFC4",
}

export enum RevitIfcParamsEnums {
  VersionClass = "VersionClass",
  ExportIFCCommonPropertySets = "ExportIFCCommonPropertySets",
  ExportUserDefinedPsets = "ExportUserDefinedPsets",
  ExportUserDefinedParameterMapping = "ExportUserDefinedParameterMapping",
}

export enum AppModalEnums {
  ISSUE = "issues",
  RISIKO = "risiko",
  RISIKO_ACTION = "risikoActions",
  MEETING_SERIE = "meetingSerie",
}

export enum ToastMessagesEnums {
  UPLOAD_SUCCESFULL = "Opplasting fullført",
  ISSUE_ARCHIVED = "Sak arkivert",
  NAME_SAVED = "Navn oppdatert",
  UPDATED = "Oppdatert",
  FILTER_UPDATED = "Filter oppdatert",
  SETTINGS_UPDATED = "Innstillinger oppdatert",
  ISSUE_ADDED = "Sak opprettet",
  ISSUE_UPDATED = "Sak oppdatert",
  RISIKO_ADDED = "Risiko opprettet",
  RISIKO_UPDATED = "Risiko oppdatert",
  TASK_ADDED = "Aksjon opprettet",
  TASK_UPDATED = "Aksjon oppdatert",
  STATUS_LOG_SAVED = "Statuslogg lagret",
  STATUS_LOG_UPDATED = "Statuslogg oppdatert",
  STATUS_LOG_ADDED = "Statuslogg opprettet",
  STATUS_LOG_DELETED = "Statuslogg slettet",
  FILE_UPLOADED = "Fil lastet opp",
  FILE_DELETED = "Fil fjernet",
  MEETING_ADDED = "Møte opprettet",
}

export enum MCApplicationsUrlEnum {
  APPS = "apps",
  BATCHJOB = "batchjob",
  BIM360 = "bim360explorer",
  BIMFLOORPLAN = "bimFloorplan",
  CHECKLIST = "checklist",
  DASHBOARD = "dashboard",
  FIRE = "brannrapport",
  IOT = "iot",
  ISSUE = "issues",
  MCCOS = "mccos",
  MCOFFICE = "mcoffice",
  MEETING = "meetings",
  MEMBERS = "members",
  PNS = "pns",
  REPORT = "reports",
  RISIKO = "risiko",
  VIEWER = "viewer",
  SETTINGS = "settings",
  BIMAI = "bimai",
}

export enum EmbedTypeEnum {
  EMBEDDEDLINK = "EmbeddedLinks",
  VIDEOFEED = "VideoFeed",
}

export enum GeneralMessageEnum {
  LOADING = "Laster inn...",
  CHARTLOADING = "Oppdaterer ... Straks klart!",
}
