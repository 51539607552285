import React from "react";
import { VSpace } from "../../../../components/";
import ConnectedArcgisProject from "./ConnectedArcgisProject";

const ArcGisSettingsPage = () => {
  return (
    <div>
      <ConnectedArcgisProject />
      <VSpace />
    </div>
  );
};

export default ArcGisSettingsPage;
