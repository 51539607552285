import { useSelector } from "react-redux";
import { useElementSize } from "usehooks-ts";
import { IIssue, IMinUser } from "../../../../../../../../types/global.types";
import { ITodo } from "../../../../../../../../types/todo.types";
import Avatar from "../../../../../../components/Avatar";
import { ActionIcon, IssueIcon } from "../../../../../../components/CustomIcons/CustomIcons";
import DisplayDueDate from "../../../../../../components/DisplayDueDate";
import SingleTag from "../../../../../../components/SingleTag/SingleTag";
import TaskCompletedCount from "../../../../../../components/TaskCompletedCount/TaskCompletedCount";
import VSpace from "../../../../../../components/VSpace";
import { ISSUE_COMPLETED_VALUE, ISSUE_EXPIRED, ISSUE_STATES_OPTIONS } from "../../../issueConstants";
import { selectSingleIssueBoard } from "../../../issueSlice";
import { getTaskCompleteCount } from "../../../issueUtils";
import "./KanbanCard.css";

export const KanbanCard: React.FC<{
  content: IIssue;
  openContent: (content: IIssue) => void;
}> = ({ content, openContent }) => {
  const [textElementRef, { height }] = useElementSize();
  const isComplete = content.status === ISSUE_COMPLETED_VALUE || content.status === ISSUE_EXPIRED;
  const matchStatusColor = (status: string) => {
    const color = ISSUE_STATES_OPTIONS.find((s) => s.value === status)?.color;
    return color;
  };
  const issueBoard = useSelector(selectSingleIssueBoard);

  let todos = content.todos as ITodo.TodoPopulated[];

  if (issueBoard.showCompletedTodosInKanban === false) {
    todos = todos?.filter((todo) => todo.completedDate == null);
  }

  return (
    <div className="mb-2 border" style={{ width: "250px" }} onClick={() => openContent(content)}>
      <div className="bg-dark p-1 d-flex align-items-center" style={{ height: "30px", borderRadius: "6px 6px 0 0" }}>
        {content.taskOrIssue === "ISSUE" && <IssueIcon className="mx-1 text-primary" style={{ fontSize: "0.8em" }} />}
        {content.taskOrIssue === "TASK" && (
          <ActionIcon className="mx-1" style={{ fontSize: "1em", color: "#d9d9d9" }} />
        )}

        <span className="text-primary">
          <b>{content.id}</b>
        </span>
        <div className="flex-fill" />
        {content.connectedTasks?.length > 0 && (
          <div className="mr-2">
            <TaskCompletedCount taskCompleteCount={getTaskCompleteCount(content, true)} useActionIcon />
          </div>
        )}
        {content?.priority === "HIGH" && <div className="statusdot mr-2" style={{ backgroundColor: "#dc3545" }}></div>}
      </div>
      <div
        className="bg-white p-1 d-flex flex-column shadowThin"
        style={{
          maxHeight: content.showTodosInKanban === true ? "none" : "250px",
          fontSize: "12px",
          borderRadius: "0 0 6px 6px",
        }}
      >
        <div
          className="contentTitle"
          style={{ boxShadow: ` inset 8px 0 0px -5px ${matchStatusColor(content.status)}a6` }}
        >
          <div>{content.title}</div>
        </div>
        <VSpace />
        {content.showTodosInKanban !== true && (
          <div
            ref={textElementRef}
            className={`body-text-truncate px-1`}
            style={{ WebkitLineClamp: Math.floor(height / 16) }}
          >
            {content.description}
          </div>
        )}
        {content.showTodosInKanban === true && (
          <div>
            {(todos as ITodo.TodoPopulated[])?.map((todo) => (
              <>
                <p key={todo._id} className="p-0 m-0">
                  {todo.completedDate != null && <i className="fa fa-check-circle fa-fw success-text" />}
                  {todo.completedDate == null && <i className="fa fa-circle fa-fw gray-text" />}
                  {todo.text}
                </p>
              </>
            ))}
          </div>
        )}
        {content.tags?.length > 0 && (
          <div className={"d-flex align-items-end flex-wrap my-1 masked-overflow"}>
            {content.tags.map((tag, index) => (
              <SingleTag key={index} displayValue={tag} />
            ))}
          </div>
        )}
        <div className="d-flex align-items-center" style={{ margin: ".2em" }}>
          <Avatar id={(content?.assignedTo as IMinUser)?._id} height="20px" />
          <div className="truncateHeaderText text-secondary font-weight-bold" style={{ marginLeft: "-0.3rem" }}>
            {(content?.assignedTo as IMinUser)?.name}
          </div>
          <div className="flex-fill" />
          <div className="mx-1" style={{ fontSize: "1.1em" }}>
            <DisplayDueDate dueDate={content.dates?.due} isComplete={isComplete} />
          </div>
        </div>
      </div>
    </div>
  );
};
