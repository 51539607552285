import { IRisikoEvaluationCategorySelection, IRiskoBoard } from "../../../../../types/global.types";
import Axios from "axios";

export const doUpdateRisikoBoardProbabilities = async (
  projectId: string,
  risikoBoardId: string,
  probabilitySelections: IRisikoEvaluationCategorySelection[],
): Promise<IRiskoBoard> => {
  try {
    const fetch = await Axios.put(`/api/project/${projectId}/risikoboard/${risikoBoardId}/probabilitySelections`, {
      probabilitySelections,
    });
    return fetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doCreateRisikoBoardTemplate = async (projectId: string, name: string): Promise<IRiskoBoard> => {
  try {
    const fetch = await Axios.post(`/api/project/${projectId}/risikoboard/template`, { name });
    return fetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetRisikoBoardTemplates = async (projectId: string): Promise<IRiskoBoard[]> => {
  try {
    const fetch = await Axios.get(`/api/project/${projectId}/risikoboard/template/all`);
    return fetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};
