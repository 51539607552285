import Axios from "axios";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import { IDocxTemplate } from "../../../../../../types/global.types";
import { IMeeting } from "../../../../../../types/meeting.types";
import { apiSlice } from "../../../../apiSlice";
import { DocxReport, FailedAlert, MCLoader, VSpace } from "../../../../components";
import DocxReportDropdown from "../../../../components/DocxReportDropdown/DocxReportDropdown";
import GlobalFileUploaderTable from "../../../../components/FileUploader/GlobalFileUploaderTable";
import { ToastMessagesEnums } from "../../../../frontendConstants";
import { useGetOfficalMulticonsultMeetingTempaltesQuery } from "../../../../globalRTK";
import { useAppDispatch } from "../../../../hooks";
import { useDeleteMeetingDocxTemplateMutation, useGetProjectMeetingDocxTemplatesQuery } from "../../../projectRTK";
import { getProjectIdFromUrl } from "../../../utils";
import { downloadAzureBlobFile } from "../../admin/restQueries";
import { doGenerateMeetingDocx } from "../meetingAPIDepricated";
import { MEETING_DOCX_DEMO_DATA } from "../meetingConstants";
import SendMeetingAgendaByMailModal from "./Agenda/SendMeetingAgendaByMailModal";
import { setShowSuccessMessageThunk } from "../../../../global/globalSlice";

export const MeetingDocx: React.FC<{ singleMeeting: IMeeting.MeetingPopulated }> = ({ singleMeeting }) => {
  const history = useHistory();

  const projectId = getProjectIdFromUrl(history.location.pathname);

  const meetingSerie = singleMeeting?.meetingSerie as IMeeting.MeetingSerie;

  const dispatch = useAppDispatch();

  const {
    data: officialMeetingTemplates = [],
    isLoading: isLoadingMeetingTemplates,
    isError: getMeetingTemplatesError,
  } = useGetOfficalMulticonsultMeetingTempaltesQuery("");

  const {
    data: projectMeetingDocxTemplates = [],
    isLoading: isLoadingProjectMeetingTemplates,
    isError: getProjectMeetingTemplatesError,
  } = useGetProjectMeetingDocxTemplatesQuery({ projectId });

  const officalDocxTemplatesFormatted: IDocxTemplate[] = officialMeetingTemplates.map((template) => {
    const docxTemplate: IDocxTemplate = {
      _id: template.blobName,
      fileName: template.fileName,
      isMulticonsult: true,
      uploadedDate: new Date(template.uploadedAt),
      uploadedBy: {
        name: template.uploadedBy,
      },
    };
    return docxTemplate;
  });

  const projectDocxTemplatesFormatted: IDocxTemplate[] = projectMeetingDocxTemplates.map((template) => {
    const docxTemplate: IDocxTemplate = {
      _id: template.blobName,
      fileName: template.fileName,
      isMulticonsult: false,
      uploadedDate: new Date(template.uploadedAt),
      uploadedBy: {
        name: template.uploadedBy,
      },
    };
    return docxTemplate;
  });

  const docxTemplates = [...officalDocxTemplatesFormatted, ...projectDocxTemplatesFormatted];

  const generateMeetingDocx = async (docxTemplate) => {
    const selectedDocxTemplate = [...officialMeetingTemplates, ...projectMeetingDocxTemplates].find(
      (tmpl) => tmpl.fileName === docxTemplate.fileName,
    );

    await doGenerateMeetingDocx(
      projectId,
      singleMeeting._id,
      singleMeeting.name,
      selectedDocxTemplate.blobName,
      meetingSerie?.name,
      singleMeeting.dateOfMeeting,
    );
    dispatch(apiSlice.util.invalidateTags(["Meeting", "AzureBlobStorage"]));
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.UPDATED));
  };

  const [docxModalOpen, setDocxModalOpen] = useState<boolean>(false);
  const [sendAgendaMailModalOpen, setSendAgendaMailModalOpen] = useState<boolean>(false);

  const [deleteTemplate, { isError: deleteTemplateError }] = useDeleteMeetingDocxTemplateMutation();

  const uploadDocxTempalte = async (event) => {
    await doUploadMeetingTemplateForProject(projectId, event);
    dispatch(apiSlice.util.invalidateTags(["Meeting"]));
  };

  const isLoading = isLoadingMeetingTemplates || isLoadingProjectMeetingTemplates;
  const isError = getMeetingTemplatesError || getProjectMeetingTemplatesError || deleteTemplateError;

  return (
    <div className="d-flex flex-column p-3">
      {isLoading === true && <MCLoader />}
      {isError === true && <FailedAlert />}
      <div className="d-flex justify-content-between">
        <DocxReportDropdown
          docxTemplates={docxTemplates}
          generateDocx={generateMeetingDocx}
          openDocxModal={() => setDocxModalOpen(true)}
        />
        {docxModalOpen === true && (
          <DocxReport
            demoDocxData={MEETING_DOCX_DEMO_DATA}
            docxTemplates={docxTemplates}
            uploadCompleedCb={() => dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.UPLOAD_SUCCESFULL))}
            uploadFunc={uploadDocxTempalte}
            generateDocx={generateMeetingDocx}
            downloadTemplate={(docxTemplate) => downloadAzureBlobFile(docxTemplate._id, docxTemplate.fileName)}
            deleteDocx={(docxTemplate: IDocxTemplate) =>
              deleteTemplate({
                projectId: projectId,
                fullBlobId: docxTemplate._id,
              })
            }
            closeModal={() => setDocxModalOpen(false)}
          />
        )}
        <Button color="primary" onClick={() => setSendAgendaMailModalOpen(true)}>
          <i className="fa fa-send fa-fw" />
          Send møtereferat per mail
        </Button>
        {sendAgendaMailModalOpen === true && (
          <SendMeetingAgendaByMailModal
            meeting={singleMeeting}
            altTitle={"møtereferat"}
            close={() => setSendAgendaMailModalOpen(false)}
          />
        )}
      </div>
      <VSpace />
      <h4>Møtereferater</h4>
      <GlobalFileUploaderTable path={`${projectId}/meeting/${singleMeeting._id}/referater`} disableUploader={true} />
      <h4 className="mt-6">Opplastede filer</h4>
      <GlobalFileUploaderTable path={`${projectId}/meeting/${singleMeeting._id}/files`} />
    </div>
  );
};

const doUploadMeetingTemplateForProject = async (projectId: string, event: React.ChangeEvent<HTMLInputElement>) => {
  try {
    const data = new FormData();
    data.append("fileToUpload", event.target.files[0]);
    const resp = await Axios.request({
      method: "POST",
      url: `/api/project/${projectId}/meetingdocxtemplate`,
      data,
      headers: {
        "content-type": `multipart/form-data`,
      },
    });
    return Promise.resolve(resp);
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};
