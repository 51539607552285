import { LuftmengdeGjennomLukeaapningEnum } from "mc-shared/zod/report/GK/gkReportConstants";
import {
  IGKLuftmengdemaalingGjennomArbeidsaapningResultater,
  IGKRapport,
} from "mc-shared/zod/report/GK/gkReportSchema";
import { useEffect } from "react";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../../../types/global.types";
import { HSpace, McDropdown, McInput } from "../../../../../../../components";
import ReportDocxImageContainer from "../../../../ReportBoard/ReportDocxImageContainer";
import {
  beregnetArealForHvertPunkt,
  beregnetAvkastLuftmengde,
  beregnetGjennomsnittligHastighet,
  beregnetGjennomsnittligHastighetAvtrekkskanal,
  beregnetLuftmengdeGjennomLukeaapning,
  beregnetTverrsnittareal,
} from "../../gkGlobalUtils";

const LuftmengdemaalingGjennomArbeidsaapning: React.FC<{
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
  data: IGKLuftmengdemaalingGjennomArbeidsaapningResultater;
}> = ({ data, onUpdateEditData }) => {
  const TESTMETODER: LuftmengdeGjennomLukeaapningEnum[] = [
    LuftmengdeGjennomLukeaapningEnum.ARBEIDSAAPNING,
    LuftmengdeGjennomLukeaapningEnum.AVTREKKSKANAL,
  ];

  useEffect(() => {
    maaleResultaterArray();
  }, [data.inndata.valgtMetode]);

  useEffect(() => {
    utdataBeregninger();
  }, [
    data.inndata.maaleresultater,
    data.inndata.avtrekkskanalResultater.diameter,
    data.inndata.maaleresultater.length,
  ]);

  const maaleResultaterArray = () => {
    let arrayLength;
    if (data.inndata.valgtMetode === LuftmengdeGjennomLukeaapningEnum.ARBEIDSAAPNING) {
      arrayLength = 5;
    }
    const newMaaleresultaterArray = Array.from({ length: arrayLength }, () => null);
    onUpdateEditData(
      "testResultater.luftmengdemaalingGjennomArbeidsaapning.inndata.maaleresultater",
      newMaaleresultaterArray as number[],
    );
  };

  const addNewRow = () => {
    const maaleresultaterArray = data.inndata.maaleresultater;
    maaleresultaterArray.push(null);

    onUpdateEditData(
      "testResultater.luftmengdemaalingGjennomArbeidsaapning.inndata.maaleresultater",
      maaleresultaterArray,
    );

    return maaleresultaterArray;
  };

  const removeLastRow = () => {
    const updatedMaaleresultater = data.inndata.maaleresultater;

    if (updatedMaaleresultater.length > 0) {
      updatedMaaleresultater.pop(); // Remove the last element
      onUpdateEditData(
        "testResultater.luftmengdemaalingGjennomArbeidsaapning.inndata.maaleresultater",
        updatedMaaleresultater,
      );
    }
  };

  const updateMaaleresultater = (resultat, i) => {
    const maaleresultater = [...data.inndata.maaleresultater];
    maaleresultater[i] = resultat;
    onUpdateEditData("testResultater.luftmengdemaalingGjennomArbeidsaapning.inndata.maaleresultater", maaleresultater);
  };

  const utdataBeregninger = () => {
    const tverrsnittareal = beregnetTverrsnittareal(data.inndata.avtrekkskanalResultater.diameter);
    const arealForHvertPunkt = beregnetArealForHvertPunkt(tverrsnittareal, data.inndata.maaleresultater.length);
    const avkastLuftmengde = beregnetAvkastLuftmengde(
      data.inndata.maaleresultater.map((resultat) => resultat),
      arealForHvertPunkt,
    );
    let gjennomsnittligHastighet: number = null;
    if (data.inndata.valgtMetode === "Arbeidsåpning") {
      gjennomsnittligHastighet = beregnetGjennomsnittligHastighet(
        data.inndata.maaleresultater.map((resultat) => resultat),
        data.inndata.maaleresultater.length,
      );
    }
    if (data.inndata.valgtMetode === "Avtrekkskanal") {
      gjennomsnittligHastighet = beregnetGjennomsnittligHastighetAvtrekkskanal(
        avkastLuftmengde,
        data.inndata.arealLukeaapning,
      );
    }
    let luftmengdeGjennomLukeaapning: number = null;
    if (data.inndata.valgtMetode === "Arbeidsåpning") {
      luftmengdeGjennomLukeaapning = beregnetLuftmengdeGjennomLukeaapning(
        gjennomsnittligHastighet,
        data.inndata.arealLukeaapning,
      );
    }
    const utdata = {
      gjennomsnittligHastighet: gjennomsnittligHastighet,
      arbeidsaapningBeregninger: {
        luftmengdeGjennomLukeaapning: luftmengdeGjennomLukeaapning,
      },
      avtrekkskanalBeregninger: {
        tverrsnittareal: tverrsnittareal,
        arealForHvertPunkt: arealForHvertPunkt,
        avkastLuftmengde: avkastLuftmengde,
      },
    };
    onUpdateEditData("testResultater.luftmengdemaalingGjennomArbeidsaapning.utdata", utdata);
  };

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Luftmengdemåling gjennom arbeidsåpning</CardTitle>
        <div className="d-flex flex-column mt-4 mb-4">
          <b>
            Fyll inn lukeåpningsareal &#40;m<sup>2</sup>&#41;:
          </b>
          <McInput
            value={data.inndata.arealLukeaapning}
            onBlur={(e) =>
              onUpdateEditData(
                "testResultater.luftmengdemaalingGjennomArbeidsaapning.inndata.arealLukeaapning",
                Number(e.target.value),
              )
            }
            normalizeNumbers={true}
            type="text"
            className="w-25"
          />
        </div>
        <b>Hvilken metode skal brukes?</b>
        <div className="w-25">
          <McDropdown
            currentValue={data.inndata.valgtMetode}
            onChange={(_, item) =>
              onUpdateEditData(
                "testResultater.luftmengdemaalingGjennomArbeidsaapning.inndata.valgtMetode",
                item as string,
              )
            }
            defaultTitle="Hvilken testmetode skal brukes?"
            options={TESTMETODER}
          />
        </div>
        {data.inndata.valgtMetode === LuftmengdeGjennomLukeaapningEnum.ARBEIDSAAPNING &&
          data.inndata.arealLukeaapning != null && (
            <>
              <div className="d-flex flex-column mt-2">
                <b>Måletiden er 60 sekunder &#40;s&#41;:</b>
              </div>
              <div className="mt-2">
                <b>Legg til flere punkter ved behov:</b>
                <div className="d-flex">
                  <Button color="success" onClick={addNewRow}>
                    Legg til rad
                  </Button>
                  <HSpace />
                  <Button color="danger" onClick={removeLastRow} disabled={data.inndata.maaleresultater.length === 5}>
                    Fjern siste rad
                  </Button>
                </div>
              </div>
              <div className="mt-4 d-flex flex-column">
                <b>Fyll inn måleresultater &#40;m/s&#41;:</b>
                {data.inndata.maaleresultater.map((a, i) => (
                  <McInput
                    className="mb-1 w-25"
                    key={i}
                    value={a}
                    normalizeNumbers={true}
                    type="text"
                    onBlur={(e) => updateMaaleresultater(Number(e.target.value), i)}
                  />
                ))}
              </div>
              <div className="d-flex justify-content-between">
                <p className="mb-0">
                  Gjennomsnittlig hastighet &#40;m/s&#41; ={" "}
                  <b>
                    {beregnetGjennomsnittligHastighet(
                      data.inndata.maaleresultater.map((resultat) => resultat),
                      data.inndata.maaleresultater.length,
                    )?.toFixed(3) ?? ""}
                  </b>
                </p>
                <p className="mb-0">Formel: &#40;Va = &#40;SUM Vn&#41; / N&#41;</p>
              </div>
              <hr className="mt-0" />
              <div className="d-flex justify-content-between">
                <p className="mb-0">
                  Luftmengde gjennom lukeåpning &#40;m<sup>3</sup>/s&#41; ={" "}
                  <b>
                    {beregnetLuftmengdeGjennomLukeaapning(
                      data.utdata.gjennomsnittligHastighet,
                      data.inndata.arealLukeaapning,
                    )?.toFixed(3) ?? ""}
                  </b>
                </p>
                <p className="mb-0">Formel: &#40;Q = Va * Aluke&#41;</p>
              </div>
              <hr className="mt-0" />
            </>
          )}
        {data.inndata.valgtMetode === LuftmengdeGjennomLukeaapningEnum.AVTREKKSKANAL &&
          data.inndata.arealLukeaapning != null && (
            <>
              <div className="d-flex flex-column mt-4">
                <b>Fyll inn diameter på avtrekkskanal &#40;m&#41;:</b>
                <McInput
                  value={data.inndata.avtrekkskanalResultater.diameter}
                  onBlur={(e) =>
                    onUpdateEditData(
                      "testResultater.luftmengdemaalingGjennomArbeidsaapning.inndata.avtrekkskanalResultater.diameter",
                      Number(e.target.value),
                    )
                  }
                  normalizeNumbers={true}
                  type="text"
                  className="w-25"
                />
              </div>
              <div className="d-flex justify-content-between">
                <p className="mb-0">
                  Tverrsnittsareal av kanal &#40;m<sup>2</sup>&#41; ={" "}
                  <b>{beregnetTverrsnittareal(data.inndata.avtrekkskanalResultater.diameter)?.toFixed(3) ?? ""}</b>
                </p>
                <p className="mb-0">
                  Formel: &#40;Ak = PI * &#40;D / 2&#41;<sup>2</sup>&#41;
                </p>
              </div>
              <hr className="mt-0" />
              <div className="mt-4">
                <b>Legg til flere punkter ved behov:</b>
                <div className="d-flex">
                  <Button color="success" onClick={addNewRow}>
                    Legg til rad
                  </Button>
                  <HSpace />
                  <Button color="danger" onClick={removeLastRow}>
                    Fjern siste rad
                  </Button>
                </div>
              </div>
              <div className="mt-4 d-flex flex-column">
                <b>Fyll inn måleresultater &#40;m/s&#41;:</b>
                {data.inndata.maaleresultater.map((a, i) => (
                  <McInput
                    className="mb-1 w-25"
                    key={i}
                    value={a}
                    normalizeNumbers={true}
                    type="text"
                    onBlur={(e) => updateMaaleresultater(Number(e.target.value), i)}
                  />
                ))}
              </div>
              <div className="d-flex justify-content-between">
                <p className="mb-0">
                  Areal for hvert målepunkt &#40;m<sup>2</sup>&#41; ={" "}
                  <b>
                    {beregnetArealForHvertPunkt(
                      data.utdata.avtrekkskanalBeregninger.tverrsnittareal,
                      data.inndata.maaleresultater.length,
                    )?.toFixed(3) ?? ""}
                  </b>
                </p>
                <p className="mb-0">Formel: &#40;Ac = Ak / N&#41;</p>
              </div>
              <hr className="mt-0" />
              <div className="d-flex justify-content-between">
                <p className="mb-0">
                  Avkastsluftmengde &#40;m<sup>3</sup>/s&#41; ={" "}
                  <b>
                    {beregnetAvkastLuftmengde(
                      data.inndata.maaleresultater.map((resultat) => resultat),
                      data.utdata.avtrekkskanalBeregninger.arealForHvertPunkt,
                    )?.toFixed(3) ?? ""}
                  </b>
                </p>
                <p className="mb-0">Formel: &#40;Qa = SUM&#40;Vn * Ac&#41;&#41;</p>
              </div>
              <hr className="mt-0" />
              <div className="d-flex justify-content-between">
                <p className="mb-0">
                  Gjennomsnittlig hastighet &#40;m/s&#41; ={" "}
                  <b>
                    {beregnetGjennomsnittligHastighetAvtrekkskanal(
                      data.utdata.avtrekkskanalBeregninger.avkastLuftmengde,
                      data.inndata.arealLukeaapning,
                    )?.toFixed(3) ?? ""}
                  </b>
                </p>
                <p className="mb-0">Formel: &#40;Va = Qa / Aluke&#41;</p>
              </div>
              <hr className="mt-0" />
            </>
          )}
        <div className="mt-2">
          <ReportDocxImageContainer
            docxImageTag="luftmengdemaalingGjennomArbeidsaapning"
            title="Målepunkter i rommet"
          />
          <div className="w-50">
            <b>Kommentar:</b>
            <McInput
              value={data.inndata.kommentar}
              onChange={(e) =>
                onUpdateEditData(
                  "testResultater.luftmengdemaalingGjennomArbeidsaapning.inndata.kommentar",
                  e.target.value,
                )
              }
              type="textarea"
              dynamicHeight={true}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default LuftmengdemaalingGjennomArbeidsaapning;
