import Axios from "axios";
import Highcharts, { Options } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react";
import { Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";

interface IChartPoint {
  x: number;
  y: number;
}

interface IStat {
  logDate: Date;
  nrOfInternalUsers: number;
  nrOfExternalUsers: number;
}

const UserChart = () => {
  const [highcartSeries, setHighchartSeries] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const res = await Axios.get("/api/stats");
      const statsFetch: IStat[] = res.data;

      const internalUsers: IChartPoint[] = statsFetch.map((logItem) => {
        return {
          x: new Date(logItem.logDate).getTime(),
          y: logItem.nrOfInternalUsers,
        };
      });

      const externalUsers = statsFetch.map((logItem) => {
        return {
          x: new Date(logItem.logDate).getTime(),
          y: logItem.nrOfExternalUsers,
        };
      });

      setHighchartSeries([
        {
          name: "Multiconsult employees",
          data: internalUsers,
          pointInterval: 24 * 3600 * 1000 * 7, // one week,
          color: "#ff9800",
        },
        {
          name: "External users",
          data: externalUsers,
          pointInterval: 24 * 3600 * 1000 * 7,
        },
      ]);
    }

    fetchData();
  }, []);

  const options: Options = {
    accessibility: { enabled: false }, //to remove warning
    title: {
      text: "Users logged in last 30 days",
    },
    chart: {
      type: "area",
    },
    subtitle: {
      text: "",
    },

    yAxis: {
      title: {
        text: "Number of users",
      },
    },
    xAxis: {
      type: "datetime",
      startOnTick: true,
      endOnTick: true,
      dateTimeLabelFormats: {
        week: "%e of %b",
      },
      units: [
        ["week", [1]],
        ["month", [1]],
      ],
    },
    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "middle",
    },

    plotOptions: {
      area: {
        stacking: "normal",
      },
      series: {
        label: {
          connectorAllowed: false,
        },
      },
    },
    series: highcartSeries || [],
  };

  return (
    <Card className="m-3">
      <CardBody>
        <CardTitle tag="h3">User Statstics</CardTitle>
        <CardSubtitle tag="h6" className="mb-2 text-muted" />
        {<HighchartsReact containerProps={{ style: { height: "100%" } }} highcharts={Highcharts} options={options} />}
      </CardBody>
    </Card>
  );
};

export default UserChart;
