import moment from "moment";
import React from "react";
import { Card, CardBody, CardText, CardTitle } from "reactstrap";
import { IMinUser, INotification } from "../../../../types/global.types";
import Avatar from "../Avatar";
import IconToggle from "../IconToggle/IconToggle";
import "./NotificationCard.css";

const NotificationCard: React.FC<{
  notification: INotification;
  onCardClick: (notification: INotification) => void;
  toggleMarkedAsRead: (notification: INotification) => void;
}> = ({ notification, onCardClick, toggleMarkedAsRead }) => {
  const date = moment(notification.createdAt).format("LLLL");
  const formattedDate = date.charAt(0).toUpperCase() + date.slice(1);

  const createdBy = notification.createdBy as IMinUser;

  const localMarkNotificationAsRead = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    toggleMarkedAsRead(notification);
  };

  let message;
  switch (notification.typeOfNotification) {
    case "ASSIGNED":
      message = `${createdBy?.name ?? "Noen"} tildelte deg en sak.`;
      break;
    case "SUBSCRIBED":
      message = `${createdBy?.name ?? "Noen"} har endret en sak du abonnerer på.`;
      break;
    case "MENTION":
      message = `${createdBy?.name ?? "Noen"} har tagget deg i en melding.`;
      break;
    case "MEMBER_ISSUE":
      message = `${createdBy?.name ?? "Noen"} har lagt deg til i en sak.`;
      break;
    case "MEMBER_MEETING":
      message = `${createdBy?.name ?? "Noen"} har lagt deg til i en møte.`;
      break;
    case "ASSIGNED_CHECKLIST":
      message = `${createdBy?.name ?? "Noen"} har lagt deg til som ansvarlig i en sjekkliste.`;
  }

  return (
    <>
      <Card className="onHover my-1">
        <CardBody className="pointer" style={{ padding: ".5rem .75rem" }} onClick={() => onCardClick(notification)}>
          <div className="d-flex">
            <CardTitle className="d-flex align-items-center mr-1">
              <Avatar id={createdBy?._id} />
              <h5 className="m-0 two-line-text-truncate">{message}</h5>
            </CardTitle>
            <div className="flex-fill" />
            <IconToggle
              activeColor="#fcac62"
              toggled={!notification.markedAsRead?.value}
              activeIcon="envelope"
              inactiveIcon="envelope-open"
              onClick={localMarkNotificationAsRead}
            />
          </div>
          <CardText className="two-line-text-truncate mb-2">{notification.text}</CardText>
          <p className="mb-0" style={{ fontSize: ".8em", color: "gray" }}>
            {formattedDate}
          </p>
        </CardBody>
      </Card>
    </>
  );
};

export default NotificationCard;
