import {
  IInternJuridiskUnderkonsulentRapport,
  ZInternJuridiskUnderkonsulentRapportSchema,
} from "mc-shared/zod/report/Internjuridisk/internJuridiskSchema";
import * as R from "ramda";
import React, { useEffect } from "react";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import { useDebounce } from "usehooks-ts";
import { SafeParseReturnType, ZodError } from "zod";
import { Paths, PathValue } from "../../../../../../types/global.types";
import { Checkbox, HSpace, McDropdown, McInput, VSpace } from "../../../../components";

const UnderkonsulentRapport: React.FC<{
  data: IInternJuridiskUnderkonsulentRapport;
  onError: (errors: ZodError<IInternJuridiskUnderkonsulentRapport>) => void;
  onUpdateEditData: (
    data: IInternJuridiskUnderkonsulentRapport,
    docxData: IInternJuridiskUnderkonsulentRapport,
  ) => void;
}> = ({ data, onUpdateEditData, onError }) => {
  const [editData, setEditData] = React.useState(R.clone(data));
  const debouncedValue = useDebounce<IInternJuridiskUnderkonsulentRapport>(editData, 1000);

  useEffect(() => {
    onUpdateEditData(data, data);
  }, []);

  useEffect(() => {
    const parsedResult: SafeParseReturnType<
      IInternJuridiskUnderkonsulentRapport,
      IInternJuridiskUnderkonsulentRapport
    > = ZInternJuridiskUnderkonsulentRapportSchema.safeParse(editData);
    if (parsedResult.success === false) {
      onError(parsedResult.error);
    } else {
      onUpdateEditData(editData, editData);
    }
  }, [debouncedValue]);

  React.useEffect(() => {
    setEditData(null);
    setTimeout(() => {
      setEditData(R.clone(data));
    }, 50);
  }, [data]);

  const updateData = <P extends Paths<IInternJuridiskUnderkonsulentRapport, 4>>(
    path: P,
    value: PathValue<IInternJuridiskUnderkonsulentRapport, P>,
  ) => {
    setEditData((prevState) => {
      const updatedData = R.assocPath(path.split("."), value, prevState);
      return updatedData;
    });
  };

  const updateRow = (path: string, index: number, key: string, value: string) => {
    if (path === "nokkelpersoner") {
      let nokkelpersoner = R.clone(editData.underkonsulentensNokkelpersoner.nokkelpersoner);
      nokkelpersoner[index][key] = value;
      updateData("underkonsulentensNokkelpersoner.nokkelpersoner", nokkelpersoner);
    }
    if (path === "dagmulktEllerErstatning") {
      let dagmulktFrister = R.clone(editData.dagmulktEllerErstatning.dagmulktBelagteFrister);
      dagmulktFrister[index][key] = value;
      updateData("dagmulktEllerErstatning.dagmulktBelagteFrister", dagmulktFrister);
    }
  };

  const addNewRow = (path: string) => {
    if (path === "nokkelpersoner") {
      const newDeltager = {
        navn: "",
        fagdisiplin: "",
      };

      const updatedDeltagere = [...editData.underkonsulentensNokkelpersoner.nokkelpersoner, newDeltager];

      updateData("underkonsulentensNokkelpersoner.nokkelpersoner", updatedDeltagere);
    }

    if (path === "dagmulktEllerErstatning") {
      const newDagmulkt = {
        frist: "",
        sats: "",
      };

      const updatedDagmulkter = [...editData.dagmulktEllerErstatning.dagmulktBelagteFrister, newDagmulkt];

      updateData("dagmulktEllerErstatning.dagmulktBelagteFrister", updatedDagmulkter);
    }
  };

  const removeRow = (path: string, index: number) => {
    if (path === "nokkelpersoner") {
      const updatedNokkelpersoner = [...editData.underkonsulentensNokkelpersoner.nokkelpersoner];

      updatedNokkelpersoner.splice(index, 1);
      updateData("underkonsulentensNokkelpersoner.nokkelpersoner", updatedNokkelpersoner);
    }
    if (path === "dagmulktEllerErstatning") {
      const updatedDagmulktFrister = [...editData.dagmulktEllerErstatning.dagmulktBelagteFrister];

      updatedDagmulktFrister.splice(index, 1);
      updateData("dagmulktEllerErstatning.dagmulktBelagteFrister", updatedDagmulktFrister);
    }
  };

  return (
    <div style={{ height: "100%", width: "1050px" }}>
      {editData != null && (
        <>
          <div>
            <Card>
              <CardBody>
                <CardTitle tag="h4">Generelt</CardTitle>
                <table className="reportTable">
                  <tbody>
                    <tr>
                      <td>Prosjektnavn: </td>
                      <td>
                        <McInput
                          value={editData.generellInfo?.prosjektNavn}
                          onChange={(e) => updateData("generellInfo.prosjektNavn", e.target.value)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Kort beskrivelse av oppdrag:</td>
                      <td>
                        <McInput
                          type="textarea"
                          value={editData.generellInfo?.oppdragsBeskrivelse}
                          onChange={(e) => updateData("generellInfo.oppdragsBeskrivelse", e.target.value)} // TEXTAREA
                          dynamicHeight={true}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Dato: </td>
                      <td>
                        <McInput
                          type="date"
                          value={editData.generellInfo?.dato}
                          onChange={(e) => updateData("generellInfo.dato", e.target.value)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Sted: </td>
                      <td>
                        <McInput
                          value={editData.generellInfo?.sted}
                          onChange={(e) => updateData("generellInfo.sted", e.target.value)}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </CardBody>
              <CardBody>
                <CardTitle tag="h4">Multiconsults representant</CardTitle>
                <table className="reportTable">
                  <tbody>
                    <tr>
                      <td>Navn: </td>
                      <td>
                        <McInput
                          value={editData.generellInfo?.multiRepresentant.navn}
                          onChange={(e) => updateData("generellInfo.multiRepresentant.navn", e.target.value)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Tittel: </td>
                      <td>
                        <McInput
                          value={editData.generellInfo?.multiRepresentant.tittel}
                          onChange={(e) => updateData("generellInfo.multiRepresentant.tittel", e.target.value)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Email: </td>
                      <td>
                        <McInput
                          value={editData.generellInfo?.multiRepresentant.kontaktDetaljer.email}
                          onChange={(e) =>
                            updateData("generellInfo.multiRepresentant.kontaktDetaljer.email", e.target.value)
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Tlf: </td>
                      <td>
                        <McInput
                          value={editData.generellInfo?.multiRepresentant.kontaktDetaljer.tlf}
                          onChange={(e) =>
                            updateData("generellInfo.multiRepresentant.kontaktDetaljer.tlf", e.target.value)
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle tag="h4">Underkonsulent</CardTitle>
                <table className="reportTable">
                  <tbody>
                    <tr>
                      <td>Navn: </td>
                      <td>
                        <McInput
                          value={editData.generellInfo?.underkonsulent.navn}
                          onChange={(e) => updateData("generellInfo.underkonsulent.navn", e.target.value)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Organisasjonsnummer: </td>
                      <td>
                        <McInput
                          value={editData.generellInfo?.underkonsulent.orgNummer}
                          onChange={(e) => updateData("generellInfo.underkonsulent.orgNummer", e.target.value)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Adresse: </td>
                      <td>
                        <McInput
                          value={editData.generellInfo?.underkonsulent.adresse}
                          onChange={(e) => updateData("generellInfo.underkonsulent.adresse", e.target.value)}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </CardBody>
              <CardBody>
                <CardTitle tag="h4">Underkonsulentens representant</CardTitle>
                <table className="reportTable">
                  <tbody>
                    <tr>
                      <td>Navn: </td>
                      <td>
                        <McInput
                          value={editData.generellInfo?.underkonsulent.representant.navn}
                          onChange={(e) => updateData("generellInfo.underkonsulent.representant.navn", e.target.value)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Tittel: </td>
                      <td>
                        <McInput
                          value={editData.generellInfo?.underkonsulent.representant.tittel}
                          onChange={(e) =>
                            updateData("generellInfo.underkonsulent.representant.tittel", e.target.value)
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Email: </td>
                      <td>
                        <McInput
                          value={editData.generellInfo?.underkonsulent.representant.kontaktDetaljer.email}
                          onChange={(e) =>
                            updateData("generellInfo.underkonsulent.representant.kontaktDetaljer.email", e.target.value)
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Tlf: </td>
                      <td>
                        <McInput
                          value={editData.generellInfo?.underkonsulent.representant.kontaktDetaljer.tlf}
                          onChange={(e) =>
                            updateData("generellInfo.underkonsulent.representant.kontaktDetaljer.tlf", e.target.value)
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </div>
          <Card>
            <CardBody>
              <CardTitle tag="h4">Avtaledokumenter</CardTitle>
              <table className="reportTable">
                <tbody>
                  <tr>
                    <td>(c) Underkonsulentens endelige tilbud av {editData?.generellInfo.underkonsulent.navn}:</td>
                    <td>
                      <Checkbox
                        mode="check"
                        isChecked={editData?.avtaleDokumenter?.avtaleC}
                        setChecked={() => updateData("avtaleDokumenter.avtaleC", !editData?.avtaleDokumenter?.avtaleC)}
                        color="#f8992e"
                        className="mb-2"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>(d) Konsulentens tilbudsgrunnlag av {editData?.generellInfo.underkonsulent.navn}:</td>
                    <td>
                      <Checkbox
                        mode="check"
                        isChecked={editData?.avtaleDokumenter?.avtaleD}
                        setChecked={() => updateData("avtaleDokumenter.avtaleD", !editData?.avtaleDokumenter?.avtaleD)}
                        color="#f8992e"
                        className="mb-2"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>(e) NS-standard:</td>
                    <td>
                      <McDropdown
                        currentValue={editData.avtaleDokumenter?.avtaleE}
                        onChange={(_, item) => updateData("avtaleDokumenter.avtaleE", item)}
                        options={["NS 8401:2010", "NS 8402:2010", "NS 8404:2013"]}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <CardTitle tag="h4">Underkonsulentens nøkkelpersoner</CardTitle>
              <tr>
                <td className="d-flex">
                  Skal nøkkelpersoner angis?
                  <Checkbox
                    mode="check"
                    isChecked={editData?.underkonsulentensNokkelpersoner?.isActive}
                    setChecked={() =>
                      updateData(
                        "underkonsulentensNokkelpersoner.isActive",
                        !editData?.underkonsulentensNokkelpersoner?.isActive,
                      )
                    }
                    color="#f8992e"
                    className="mb-2 ml-2"
                  />
                </td>
              </tr>
              {editData?.underkonsulentensNokkelpersoner.isActive === true && (
                <>
                  <div>
                    <Button color="success" onClick={() => addNewRow("nokkelpersoner")}>
                      Legg til deltager
                    </Button>
                  </div>
                  <VSpace />
                  <table className="reportTable">
                    <thead>
                      <tr>
                        <th style={{ width: "50%" }}>Navn</th>
                        <HSpace />
                        <th style={{ width: "50%" }}>Fagdisiplin</th>
                        <th style={{ width: "20%" }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {editData?.underkonsulentensNokkelpersoner.nokkelpersoner?.map((person, index) => (
                        <tr key={index}>
                          <td>
                            <McInput
                              value={person?.navn}
                              onChange={(e) => updateRow("nokkelpersoner", index, "navn", e.target.value)}
                            />
                          </td>
                          <HSpace />
                          <td>
                            <McInput
                              value={person?.fagdisiplin}
                              onChange={(e) => updateRow("nokkelpersoner", index, "fagdisiplin", e.target.value)}
                            />
                          </td>
                          <td>
                            <Button onClick={() => removeRow("nokkelpersoner", index)} color="danger" outline size="sm">
                              <i className="fa fa-trash fa-fw" />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <CardTitle tag="h4">Dagmulkt eller erstatning ved forsinkelse hos underkonsulenten</CardTitle>
              <tr>
                <td className="d-flex">
                  Dagmulkt skal ikke gjelde for underkonsulentoppdraget:
                  <Checkbox
                    mode="check"
                    isChecked={editData?.dagmulktEllerErstatning?.altEn}
                    setChecked={() =>
                      updateData("dagmulktEllerErstatning", {
                        ...editData?.dagmulktEllerErstatning,
                        altEn: !editData?.dagmulktEllerErstatning?.altEn,
                        altTo: false, // Ensure altTo is false when altEn is true
                      })
                    }
                    color="#f8992e"
                    className="mb-2 ml-2"
                  />
                </td>
              </tr>
              <tr>
                <td className="d-flex">
                  Dagmulkt skal gjelde for underkonsulentoppdraget med følgende frister:
                  <Checkbox
                    mode="check"
                    isChecked={editData?.dagmulktEllerErstatning?.altTo}
                    setChecked={() =>
                      updateData("dagmulktEllerErstatning", {
                        ...editData?.dagmulktEllerErstatning,
                        altTo: !editData?.dagmulktEllerErstatning?.altTo,
                        altEn: false, // Ensure altEn is false when altTo is true
                      })
                    }
                    color="#f8992e"
                    className="mb-2 ml-2"
                  />
                </td>
              </tr>
              {editData?.dagmulktEllerErstatning.altTo === true && (
                <>
                  <div>
                    <Button color="success" onClick={() => addNewRow("dagmulktEllerErstatning")}>
                      Legg til frist
                    </Button>
                  </div>
                  <VSpace />
                  <table className="reportTable">
                    <thead>
                      <tr>
                        <th style={{ width: "50%" }}>Frist</th>
                        <HSpace />
                        <th style={{ width: "50%" }}>Sats</th>
                        <th style={{ width: "20%" }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {editData?.dagmulktEllerErstatning.dagmulktBelagteFrister?.map((frist, index) => (
                        <tr key={index}>
                          <td>
                            <McInput
                              value={frist?.frist}
                              onChange={(e) => updateRow("dagmulktEllerErstatning", index, "frist", e.target.value)}
                            />
                          </td>
                          <HSpace />
                          <td>
                            <McInput
                              value={frist?.sats}
                              onChange={(e) => updateRow("dagmulktEllerErstatning", index, "sats", e.target.value)}
                            />
                          </td>
                          <td>
                            <Button
                              onClick={() => removeRow("dagmulktEllerErstatning", index)}
                              color="danger"
                              outline
                              size="sm"
                            >
                              <i className="fa fa-trash fa-fw" />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <CardTitle tag="h4">Ansvarsbegrensninger</CardTitle>
              <h5>Ansvarsbegrensning for forsinkelse:</h5>
              <tr>
                <td className="d-flex">
                  Standardvilkårenes ansvarsbegrensning for forsinkelse gjelder.
                  <Checkbox
                    mode="check"
                    isChecked={editData?.ansvarsbegrensninger?.forsinkelse.altEn}
                    setChecked={() =>
                      updateData("ansvarsbegrensninger.forsinkelse", {
                        ...editData?.ansvarsbegrensninger.forsinkelse,
                        altEn: !editData?.ansvarsbegrensninger?.forsinkelse.altEn,
                        altTo: false, // Ensure altTo is false when altEn is true
                      })
                    }
                    color="#f8992e"
                    className="mb-2 ml-2"
                  />
                </td>
              </tr>
              <tr>
                <td className="d-flex">
                  I stedet for den ansvarsbegrensning som følger av Standardvilkårene, skal følgende bestemmelser gjelde
                  for Underkonsulentoppdraget:
                  <Checkbox
                    mode="check"
                    isChecked={editData?.ansvarsbegrensninger.forsinkelse?.altTo}
                    setChecked={() =>
                      updateData("ansvarsbegrensninger.forsinkelse", {
                        ...editData?.ansvarsbegrensninger.forsinkelse,
                        altTo: !editData?.ansvarsbegrensninger?.forsinkelse?.altTo,
                        altEn: false, // Ensure altEn is false when altTo is true
                      })
                    }
                    color="#f8992e"
                    className="mb-2 ml-2"
                  />
                </td>
              </tr>
              {editData?.ansvarsbegrensninger.forsinkelse.altTo === true && (
                <>
                  <VSpace />
                  <McInput
                    type="textarea"
                    value={editData?.ansvarsbegrensninger?.forsinkelse.bestemmelser}
                    onChange={(e) => updateData("ansvarsbegrensninger.forsinkelse.bestemmelser", e.target.value)}
                    dynamicHeight={true}
                  />
                </>
              )}
            </CardBody>
            <CardBody>
              <h5>Ansvarsbegrensning for rådgivningsfeil/prosjekteringsfeil:</h5>
              <tr>
                <td className="d-flex">
                  Standardvilkårenes ansvarsbegrensning for prosjekteringsfeil gjelder.
                  <Checkbox
                    mode="check"
                    isChecked={editData?.ansvarsbegrensninger?.prosjekteringsfeil.altEn}
                    setChecked={() =>
                      updateData("ansvarsbegrensninger.prosjekteringsfeil", {
                        ...editData?.ansvarsbegrensninger.prosjekteringsfeil,
                        altEn: !editData?.ansvarsbegrensninger?.prosjekteringsfeil.altEn,
                        altTo: false, // Ensure altTo is false when altEn is true
                      })
                    }
                    color="#f8992e"
                    className="mb-2 ml-2"
                  />
                </td>
              </tr>
              <tr>
                <td className="d-flex">
                  I stedet for de ansvarsbegrensninger som følger Standardvilkårene, skal følgende bestemmelser gjelde
                  for Underkonsulentoppdraget:
                  <Checkbox
                    mode="check"
                    isChecked={editData?.ansvarsbegrensninger.prosjekteringsfeil?.altTo}
                    setChecked={() =>
                      updateData("ansvarsbegrensninger.prosjekteringsfeil", {
                        ...editData?.ansvarsbegrensninger.prosjekteringsfeil,
                        altTo: !editData?.ansvarsbegrensninger.prosjekteringsfeil?.altTo,
                        altEn: false, // Ensure altEn is false when altTo is true
                      })
                    }
                    color="#f8992e"
                    className="mb-2 ml-2"
                  />
                </td>
              </tr>
              {editData?.ansvarsbegrensninger.prosjekteringsfeil.altTo === true && (
                <>
                  <VSpace />
                  <McInput
                    type="textarea"
                    value={editData?.ansvarsbegrensninger?.prosjekteringsfeil.bestemmelser}
                    onChange={(e) => updateData("ansvarsbegrensninger.prosjekteringsfeil.bestemmelser", e.target.value)}
                    dynamicHeight={true}
                  />
                </>
              )}
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <CardTitle tag="h4">Bestemmelser fra Hovedkontrakten:</CardTitle>
              <tr>
                <td className="d-flex">
                  Skal bestemmelser fra Hovedkontrakten med?
                  <Checkbox
                    mode="check"
                    isChecked={editData?.ovrigeBestemmelser}
                    setChecked={() => updateData("ovrigeBestemmelser", !editData?.ovrigeBestemmelser)}
                    color="#f8992e"
                    className="mb-2 ml-2"
                  />
                </td>
              </tr>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <CardTitle tag="h4">Vedlegg:</CardTitle>
              <tr>
                <td className="d-flex">
                  Vedlegg A - Underkonsulentens ytelser:
                  <Checkbox
                    mode="check"
                    isChecked={editData?.vedlegg?.vedleggA.isActive}
                    setChecked={() => updateData("vedlegg.vedleggA.isActive", !editData?.vedlegg?.vedleggA.isActive)}
                    color="#f8992e"
                    className="mb-2 ml-2"
                  />
                </td>
              </tr>
              <p>
                Her angis Underkonsulentens arbeidsoppgaver. Fortrinnsvis med henvisning til fag eller aktiviteter
                angitt i Hovedkontrakten mellom Multiconsult og Kunden. Angi også evt. krav til sertifisering og/eller
                forutsetninger om prosess, organisasjon etc. som har betydning for Underkonsulentens arbeider. Normalt
                vil det være hensiktsmessig å benytte de samme formuleringer av oppgaver og forutsetninger som er
                benyttet i Hovedkontrakten mellom Multiconsult og Kunden. Det er viktig at det trekkes opp en mest mulig
                klar og entydig arbeidsfordeling mellom Multiconsult og Underkonsulenten, og at aktiviteter i
                grensesnittene som oppstår som følge av arbeidsfordelingen mellom disse reguleres mest mulig uttømmende.
              </p>
              {editData?.vedlegg?.vedleggA.isActive === true && (
                <>
                  <VSpace />
                  <McInput
                    type="textarea"
                    value={editData?.vedlegg?.vedleggA.beskrivelse}
                    onChange={(e) => updateData("vedlegg.vedleggA.beskrivelse", e.target.value)}
                    dynamicHeight={true}
                  />
                </>
              )}
            </CardBody>
            <CardBody>
              <tr>
                <td className="d-flex">
                  Vedlegg B - Underkonsulentens fremdrift:
                  <Checkbox
                    mode="check"
                    isChecked={editData?.vedlegg?.vedleggB.isActive}
                    setChecked={() => updateData("vedlegg.vedleggB.isActive", !editData?.vedlegg?.vedleggB.isActive)}
                    color="#f8992e"
                    className="mb-2 ml-2"
                  />
                </td>
              </tr>
              <p>
                Fremdriftsplan, liste med ytelser med tilhørende frister el.l. Innholdet i Vedlegg B må utformes slik at
                Multiconsult kan oppfylle Hovedkontrakten i tide.
              </p>
              {editData?.vedlegg?.vedleggB.isActive === true && (
                <>
                  <VSpace />
                  <McInput
                    type="textarea"
                    value={editData?.vedlegg?.vedleggB.beskrivelse}
                    onChange={(e) => updateData("vedlegg.vedleggB.beskrivelse", e.target.value)}
                    dynamicHeight={true}
                  />
                </>
              )}
            </CardBody>
            <CardBody>
              <tr>
                <td className="d-flex">
                  Vedlegg C - Underkonsulentens vederlag og utgiftsdekning:
                  <Checkbox
                    mode="check"
                    isChecked={editData?.vedlegg?.vedleggC.isActive}
                    setChecked={() => updateData("vedlegg.vedleggC.isActive", !editData?.vedlegg?.vedleggC.isActive)}
                    color="#f8992e"
                    className="mb-2 ml-2"
                  />
                </td>
              </tr>
              <p>
                Angi vederlagsformat (fastpris og/eller honorering etter medgått tid med eller uten bindende ramme) Angi
                timepriser (gjelder uavhengig av prisformat) Angi fastpris(er) eller honorarbudsjett(er) (avhengig av
                prisformat). Evt. bestemmelser om utgiftsdekning som supplerer eller endrer Standardvilkårene. Innholdet
                i Vedlegg C bør utarbeides i lys av hva som er avtalt i Hovedkontrakten, og gi Multiconsult en margin
                til dekning av risiko, administrasjon mv
              </p>
              {editData?.vedlegg?.vedleggC.isActive === true && (
                <>
                  <VSpace />
                  <McInput
                    type="textarea"
                    value={editData?.vedlegg?.vedleggC.beskrivelse}
                    onChange={(e) => updateData("vedlegg.vedleggC.beskrivelse", e.target.value)}
                    dynamicHeight={true}
                  />
                </>
              )}
            </CardBody>
            <CardBody>
              <tr>
                <td>Vedlegg D - Erklæring fra samarbeidspartner | Forretningsetikk og samfunnsansvar:</td>
              </tr>
              <p>
                <a
                  href={`https://livelink.multiconsult.no/ll/livelink.exe?func=ll&objaction=overview&objid=17785164`}
                  target="_blank" // Optional: opens the link in a new tab
                  rel="noopener noreferrer"
                >
                  Dokument ligger her
                </a>
              </p>
            </CardBody>
            <CardBody>
              <tr>
                <td className="d-flex">
                  Vedlegg E - Utdrag fra Multiconsults avtale med kunden, som skal gjelde som en del av denne
                  underkonsulentavtalen:
                  <Checkbox
                    mode="check"
                    isChecked={editData?.vedlegg?.vedleggE.isActive}
                    setChecked={() => updateData("vedlegg.vedleggE.isActive", !editData?.vedlegg?.vedleggE.isActive)}
                    color="#f8992e"
                    className="mb-2 ml-2"
                  />
                </td>
              </tr>
              <p>
                Dette vil typisk være aktuelt hvis Hovedkontrakten inneholder bestemmelser som avviker fra eller
                supplerer Standardvilkårene (NS 8401, NS 8402 eller NS 8404), og/eller hvis Hovedkontrakten krever at
                Multiconsult viderefører særskilte klausuler fra Hovedkontrakten inn i Multiconsults
                underleverandøravtaler. Hvis det ikke finnes klausuler i Hovedkontrakten som Multiconsult ønsker eller
                er forpliktet til å gjengi i underkonsulentavtalen, kan det skrives «Ikke i bruk» i dette vedlegget.
              </p>
              {editData?.vedlegg?.vedleggE.isActive === true && (
                <>
                  <VSpace />
                  <McInput
                    type="textarea"
                    value={editData?.vedlegg?.vedleggE.beskrivelse}
                    onChange={(e) => updateData("vedlegg.vedleggE.beskrivelse", e.target.value)}
                    dynamicHeight={true}
                  />
                </>
              )}
            </CardBody>
          </Card>
        </>
      )}
    </div>
  );
};

export default UnderkonsulentRapport;
