import { ISJArapport } from "mc-shared/zod/report/Elektro/sjaSchema";
import { IEnergiSchema } from "mc-shared/zod/report/Energi/Energi_601_schema";
import { IGKRapport } from "mc-shared/zod/report/GK/gkReportSchema";
import { IGeoTeknikkRapport } from "mc-shared/zod/report/Geoteknik/geoteknikSchema";
import { IInternJuridiskUnderkonsulentRapport } from "mc-shared/zod/report/Internjuridisk/internJuridiskSchema";
import { ISamsvarserklaring } from "mc-shared/zod/reportSchemas";
import * as R from "ramda";
import React, { useEffect, useState } from "react";
import { shallowEqual } from "react-redux";
import { Prompt } from "react-router-dom";
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ZodError } from "zod";
import { IDocxImage } from "../../../../../../types/global.types";
import { HSpace, McDropdown, McInput } from "../../../../components";
import { ZodErrorModal } from "../../../../components/ZodErrorModal/ZodErrorModal";
import { MCApplicationsUrlEnum } from "../../../../frontendConstants";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { sortArrayByDate, useNetworkStatus } from "../../../utils";
import { doLogUsage } from "../../admin/restQueries";
import { formatShortDate } from "../../admin/selectors";
import Brannrapport from "../brannrapport/Brannrapport";
import { IBrandrapportConfig } from "../brannrapport/types";
import SJARapport from "../elektro/SJA/SJArapport";
import RapportSamsvarserklaring from "../elektro/samsvarserklaring/RapportSamsvarserklaring";
import Energirapport from "../energirapport/Energirapport";
import GeoteknikOmraadestabilitetsRapport from "../geoteknik/GeoteknikOmraadestabilitetsRapport";
import GeoteknikRapport from "../geoteknik/Geoteknikrapport";
import GKrapport from "../gkRapport/GKrapport";
import UnderkonsulentRapport from "../internjuridisk/UnderkonsulentRapport";
import { doGenerateReportAppDocx } from "../reportBoardAPI";
import {
  setActiveReportVersionInRedux,
  setDataToGenerateDocxWithInRedux,
  setEditedDataInRedux,
  updateReportDataThunk,
} from "../reportSlice";
import DiffViewer from "./DiffViewer";

const SingleReport: React.FC = () => {
  const [reportErrorsModalOpen, setReportErrorsModalOpen] = useState<boolean>(false);
  const [commitModalOpen, setCommitModalOpen] = useState<boolean>(false);
  const [reportParseErrors, setReportParseErrors] = useState<ZodError>(null);
  const [showDiffModal, setShowDiffModal] = useState<boolean>(false);
  const { isOnline } = useNetworkStatus();

  const project = useAppSelector((state) => state.adminReducer.project);

  const { activeReport, activeVersion, editedData, dataToGenerateDocxWith } = useAppSelector(
    (state) => state.reportReducer,
    shallowEqual,
  );

  const user = useAppSelector((state) => state.globalReducer.user);

  const isEditedDataEqual = JSON.stringify(editedData) === JSON.stringify(activeVersion?.data);

  const versionsSorted: any[] = sortArrayByDate(activeReport?.versions, "createdAt", true);
  const isLatestVersion = versionsSorted != null ? versionsSorted[0]?.createdAt === activeVersion?.createdAt : false;

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    const onBeforeUnload = (e) => {
      if (isEditedDataEqual === false) {
        e.preventDefault();
        e.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", onBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, [isEditedDataEqual]);

  const onLocationChange = () => {
    return "You have unsaved changes, are you sure you want to leave?";
  };

  const init = () => {
    doLogUsage(project, MCApplicationsUrlEnum.REPORT, activeReport?.template.toString());
    const versionesSorted: any[] = sortArrayByDate(activeReport.versions, "createdAt", true);
    dispatch(setActiveReportVersionInRedux(versionesSorted[0]));
  };

  const dispatch = useAppDispatch();

  const onSaveConfirm = (message: string) => {
    dispatch(updateReportDataThunk(activeReport._id, editedData, message, activeReport.template));
    setCommitModalOpen(false);
  };

  const setData = (_editedData: object, _dataToGenerateDocxWith: object) => {
    dispatch(setEditedDataInRedux(R.clone(_editedData)));
    dispatch(setDataToGenerateDocxWithInRedux(R.clone(_dataToGenerateDocxWith)));
    setReportParseErrors(null);
  };

  const handleShowDiffModal = () => {
    setShowDiffModal(!showDiffModal);
  };

  const generateDocx = async () => {
    const images: IDocxImage[] = activeReport?.uploadedFiles
      ?.filter((file) => file.docxImageTag != null)
      .map((file) => {
        return {
          tag: file.docxImageTag,
          fileName: file.filename, // "Luftbehandling_test_blababla.jpg"
          path: `/reportapp/fileInstanceUploads/${activeReport._id}/`,
          width: file.width,
          height: file.height,
        };
      });
    await doGenerateReportAppDocx(
      activeReport.template,
      dataToGenerateDocxWith,
      images,
      project?.maconomy?.projectNo,
      activeReport.name,
    );
  };

  const handleError = (errors: ZodError) => {
    setReportParseErrors(errors);
  };

  return (
    <>
      {activeVersion != null && (
        <>
          {isOnline === false && (
            <Alert color="danger">Du er offline, koble til internett for å lagre endringer.</Alert>
          )}
          <Prompt when={isEditedDataEqual === false} message={onLocationChange} />
          {commitModalOpen === true && (
            <CommitMessageModal onSave={onSaveConfirm} onAbort={() => setCommitModalOpen(false)} />
          )}
          {reportParseErrors != null && reportErrorsModalOpen === true && (
            <ZodErrorModal errors={reportParseErrors.issues} onAbort={() => setReportErrorsModalOpen(false)} />
          )}
          <div className="d-flex sticky-top bg-white">
            <HSpace />
            <div className="d-flex">
              Versjon:
              <HSpace />
              <McDropdown
                type="text"
                displayKey="versionNumber"
                currentValue={activeVersion}
                onChange={(oldVal, newVal) => dispatch(setActiveReportVersionInRedux(newVal))}
                options={versionsSorted}
                disabled={isEditedDataEqual === false}
              />
            </div>
            <HSpace />
            {isLatestVersion === true && (
              <Button
                disabled={isEditedDataEqual === true || reportParseErrors != null || isOnline === false}
                onClick={() => setCommitModalOpen(true)}
                color="success"
              >
                <i className="fa fa-save" />
                Lagre
              </Button>
            )}
            {reportParseErrors != null && (
              <>
                <HSpace />
                <Button onClick={() => setReportErrorsModalOpen(true)} color="primary">
                  {reportParseErrors.errors.length}
                  <i className="fa fa-warning fa-fw" />
                </Button>
              </>
            )}
            <HSpace />
            <Button onClick={() => handleShowDiffModal()}>
              <i className="fa fa-eye mr-1" />
              Vis endringer
            </Button>
            <HSpace />
            <div className="flex-fill" />
            <Button color="mc-blue" onClick={generateDocx} disabled={reportParseErrors != null}>
              <i className="fa fa-file-word-o fa-fw" />
              Generer dokument
            </Button>
          </div>
          <hr />
          {isLatestVersion === false && (
            <Alert color="warning">
              <b>
                Viser lagret versjon {activeVersion?.versionNumber} lagret {formatShortDate(activeVersion?.createdAt)}
                av
                {typeof activeVersion.savedBy === "object" && "name" in activeVersion.savedBy
                  ? activeVersion.savedBy.name
                  : "Ukjent bruker"}
              </b>
            </Alert>
          )}
          <DiffViewer
            activeReport={activeReport}
            activeVersion={activeVersion}
            toggle={handleShowDiffModal}
            showDiffModal={showDiffModal}
          />
          <div className="overflow-auto h-100">
            {activeReport?.template === 101 && activeVersion != null && (
              <RapportSamsvarserklaring
                samsvarserklaring={activeVersion.data as ISamsvarserklaring}
                onUpdateEditData={(data, docxData) => setData(data, docxData)}
              />
            )}
            {activeReport?.template === 102 && activeVersion != null && (
              <Brannrapport
                data={activeVersion.data as IBrandrapportConfig}
                onUpdateEditData={(data, docxData) => setData(data, docxData)}
              />
            )}
            {activeReport?.template === 601 && activeVersion != null && (
              <Energirapport
                onError={(errors) => handleError(errors)}
                data={activeVersion.data as IEnergiSchema}
                onUpdateEditData={(data, docxData) => setData(data, docxData)}
              />
            )}
            {activeReport?.template === 103 && activeVersion != null && (
              <GeoteknikRapport
                onError={(errors) => handleError(errors)}
                data={activeVersion.data as IGeoTeknikkRapport}
                onUpdateEditData={(data, docxData) => setData(data, docxData)}
                projectId={project?._id}
                userName={user?.name}
              />
            )}
            {activeReport?.template === 104 && activeVersion != null && (
              <>
                <GKrapport
                  onError={(errors) => handleError(errors)}
                  data={activeVersion.data as IGKRapport}
                  onUpdateEditData={(data, docxData) => setData(data, docxData)}
                  className="w-50"
                  createdAt={activeVersion.createdAt as Date}
                />
              </>
            )}
            {activeReport?.template === 105 && activeVersion != null && (
              <>
                <SJARapport
                  onError={(errors) => handleError(errors)}
                  data={activeVersion.data as ISJArapport}
                  onUpdateEditData={(data, docxData) => setData(data, docxData)}
                  projectId={project?._id}
                />
              </>
            )}
            {activeReport?.template === 106 && activeVersion != null && (
              <GeoteknikOmraadestabilitetsRapport
                onError={(errors) => handleError(errors)}
                data={activeVersion.data as IGeoTeknikkRapport}
                onUpdateEditData={(data, docxData) => setData(data, docxData)}
                projectId={project?._id}
                userName={user?.name}
              />
            )}
            {activeReport?.template === 701 && activeVersion != null && (
              <>
                <UnderkonsulentRapport
                  onError={(errors) => handleError(errors)}
                  data={activeVersion.data as IInternJuridiskUnderkonsulentRapport}
                  onUpdateEditData={(data, docxData) => setData(data, docxData)}
                />
              </>
            )}
          </div>
          {activeReport == null && <Alert color="warning">Ingen rapport med detta ID</Alert>}
        </>
      )}
    </>
  );
};

export default SingleReport;

const CommitMessageModal: React.FC<{ onSave: (message) => void; onAbort: () => void }> = ({ onSave, onAbort }) => {
  const [message, setMessage] = React.useState<string>("");
  return (
    <Modal isOpen toggle={onAbort} fade={false}>
      <ModalHeader toggle={onAbort}>Lagre ny version</ModalHeader>
      <ModalBody>
        <McInput
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Skriv inn melding (valgfritt)"
        />
      </ModalBody>
      <ModalFooter>
        <Button color="success" onClick={() => onSave(message)}>
          Lagre
        </Button>
      </ModalFooter>
    </Modal>
  );
};
