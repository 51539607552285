import * as _ from "lodash";
import * as R from "ramda";
import { IBim360FolderContent, IBim360FolderWithPermissionItems } from "../../../../../types/global.types";
import { doGetBim360FolderContent, doGetBim360FolderPermission } from "../admin/restQueries";

export const formatRootFolders = (folderContent): IBim360FolderContent => {
  return {
    urn: folderContent.urn || folderContent.id,
    type: folderContent.type,
    attributes: {
      displayName: folderContent.name,
    },
    customAttributes: folderContent.customAttributes,
    extraData: folderContent.extraData,
  };
};

export const formatFolderContent = (folderContent, folderName = null): IBim360FolderContent => {
  let attributes = folderContent.attributes;
  attributes.displayName = folderContent.attributes.name || folderContent.attributes.displayName;
  const patt1 = /\.([0-9a-z]+)(?:[?#]|$)/i;

  const fileType = attributes.displayName.match(patt1) && attributes.displayName.match(patt1)[0];
  return {
    urn: folderContent.urn || folderContent.id,
    type: folderContent.type,
    folderName,
    fileType: fileType && fileType.toUpperCase(),
    parent: R.path(["relationships", "parent", "data", "id"], folderContent),
    attributes,
    extraData: folderContent.extraData,
    customAttributes: folderContent.customAttributes,
  };
};

export const getCustomAttributes = async (bimProjectId: string, urns: any[]) => {
  //BIM360 Api accept maximum 50urns per request
  return getChunks(urns, 40);
};

const getChunks = function (array, size) {
  var results = [];
  while (array.length) {
    results.push(array.splice(0, size));
  }
  return results;
};

export const getDistinctCustomAttributes = (files: IBim360FolderContent[]) => {
  return R.clone(files).reduce((acc, file) => {
    if (file.customAttributes != null) {
      Object.keys(file.customAttributes).forEach((key) => {
        if (acc.indexOf(key) === -1) {
          acc.push(key);
        }
      });
    }
    return _.sortBy(acc);
  }, []);
};

export const calcPercenFailed = (nrOfFailed: number, totalNr: number) => {
  return Math.round((nrOfFailed / totalNr) * 100);
};

export const getBim360Permissions = async (
  projectId: string,
  bim360ProjectId: string,
  folders: IBim360FolderContent[],
  includeSubdirectories: boolean,
): Promise<IBim360FolderWithPermissionItems[]> => {
  const bim36FolderPermissionsItems: IBim360FolderWithPermissionItems[] = [];

  const getFolders = async (filesOrFolders: IBim360FolderContent[]) => {
    for (let fileOrFolder of filesOrFolders) {
      if (fileOrFolder.type === "folders" && fileOrFolder.attributes?.displayName != null) {
        try {
          const folderId = fileOrFolder.urn || fileOrFolder.id;
          const bim360FolderPermissions = await doGetBim360FolderPermission(projectId, bim360ProjectId, folderId);
          let folderItemPermission: IBim360FolderWithPermissionItems = {
            folderName: fileOrFolder.attributes?.displayName,
            bim360FolderId: folderId,
            permissions: bim360FolderPermissions,
            parentId: fileOrFolder.parent || fileOrFolder.relationships?.parent?.data?.id,
            id: folderId,
          };
          bim36FolderPermissionsItems.push(folderItemPermission);
          if (includeSubdirectories) {
            const content = await doGetBim360FolderContent(
              projectId,
              bim360ProjectId,
              fileOrFolder.urn || fileOrFolder.id,
            );
            if (content.data[0] != null) {
              await getFolders(content.data);
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  await getFolders(folders);

  return bim36FolderPermissionsItems.map((item) => {
    return {
      ...item,
      path: getFullPath(bim36FolderPermissionsItems, item),
    };
  });
};

// @ts-ignore
export const getFullPath = (
  bim36FolderPermissionsItems: IBim360FolderWithPermissionItems[],
  topFolder: IBim360FolderWithPermissionItems,
): string => {
  let folderDisplayNames = [topFolder.folderName];

  const addFolderNames = (bim36FolderPermissionsItems: IBim360FolderWithPermissionItems[], folder) => {
    const parent = bim36FolderPermissionsItems.find((_item) => _item.id === folder.parentId);
    if (parent != null) {
      folderDisplayNames.push(parent.folderName);
      addFolderNames(bim36FolderPermissionsItems, parent);
    }
  };
  addFolderNames(bim36FolderPermissionsItems, topFolder);
  return "/" + folderDisplayNames.reverse().join("/");
};

export const getFormattedBim360Folders = async (
  projectId,
  bim360ProjectId,
  folderUrn: string,
): Promise<IBim360FolderContent[]> => {
  const folderContent = await doGetBim360FolderContent(projectId, bim360ProjectId, folderUrn);
  return folderContent.data.reduce((acc, fileOrFolder) => {
    const fileOrFolderFormatted = formatFolderContent(fileOrFolder);
    if (fileOrFolder.type === "folders") {
      acc.push(fileOrFolderFormatted);
    }
    return acc;
  }, []);
};

export const getFilesInFolder = (filesOrFolders: IBim360FolderContent[], folderName) => {
  const files = [];
  for (let fileOrFolder of filesOrFolders) {
    if (fileOrFolder.type !== "folders") {
      files.push(formatFolderContent(fileOrFolder, folderName));
    }
  }
  return files;
};

export const getBim360Foldername = (activeFolder: any): string => {
  if (activeFolder && activeFolder.name) {
    return activeFolder.name;
  }
  if (activeFolder && activeFolder.attributes && activeFolder.attributes.displayName) {
    return activeFolder.attributes.displayName;
  }
};
