import { Alert, Button, UncontrolledAlert } from "reactstrap";
import React, { useState } from "react";

const TaskBanner: React.FC<{ overdue: number; userName: string }> = ({ overdue, userName }) => {
  const [visible, setVisible] = useState(true);
  const today = new Date();
  const time = today.getHours();
  let greet;

  if (time > 0 && time <= 12) {
    greet = "God morgen";
  } else if (time > 12 && time <= 18) {
    greet = "God ettermiddag";
  } else if (time > 18 && time <= 24) {
    greet = "God kveld";
  } else {
    greet = "Hei";
  }

  return (
    <Alert color="warning" fade isOpen={visible} style={{ marginBottom: "0" }}>
      <div className="d-flex justify-content-center align-items-center">
        <a href="#/tasks" style={{ fontSize: ".9rem", color: "#857e6b" }}>
          {greet}, {userName}! Du har{" "}
          <span className="text-danger numberBordered">
            <b>{overdue}</b>
          </span>
          oppgave
          {overdue === 1 ? "" : "r"} som har passert tidsfrist.
        </a>
        <Button color="transparent" className=" closeBtnCustom" onClick={() => setVisible(false)}>
          <i className={`fa fa fa-close fa-fw `} />
        </Button>
      </div>
    </Alert>
  );
};

export default TaskBanner;
