import React, { useState } from "react";
import { useHistory } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { IProject, IRiskoBoard } from "../../../../../../types/global.types";
import { doSetRisikoRemoveStatus } from "../risikoAPI";
import { useAppSelector } from "../../../../hooks";

const RisikoRemove: React.FC<{ risikoBoard: IRiskoBoard }> = ({ risikoBoard }) => {
  const history = useHistory();
  const project: IProject = useAppSelector((state) => state.adminReducer.project);
  const [removeModalOpen, setRemoveModalOpen] = useState<boolean>(false);
  const [removeNameCheck, setRemoveNameCheck] = useState<string>("");

  const openModal = () => {
    setRemoveNameCheck("");
    setRemoveModalOpen(true);
  };

  const RemoveRegister = async () => {
    await doSetRisikoRemoveStatus(project._id, risikoBoard._id, true);

    setRemoveModalOpen(false);
    history.push(`/project/${project._id}/risiko`);
  };

  return (
    <>
      <Card>
        <CardBody>
          <CardTitle tag="h3">Slett risikoregister</CardTitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted" />
          <Button color="danger" onClick={openModal}>
            <i className="fa fa-remove fa-fw" />
            Slett
          </Button>
        </CardBody>
      </Card>
      <Modal isOpen={removeModalOpen} toggle={() => setRemoveModalOpen(!removeModalOpen)}>
        <ModalHeader toggle={() => setRemoveModalOpen(!removeModalOpen)}>Slett {risikoBoard.name}</ModalHeader>
        <ModalBody>
          <div>Bekreft navnet på risikoregister som skal slettes</div>
          <div>
            <Input value={removeNameCheck} onChange={(e) => setRemoveNameCheck(e.target.value)} />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="outline-danger"
            disabled={removeNameCheck?.toLocaleLowerCase() !== risikoBoard.name?.toLocaleLowerCase()}
            onClick={RemoveRegister}
          >
            <i className="fa fa-trash fa-fw" />
            Slett
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default RisikoRemove;
