import React from "react";
import { ConfirmDialog, HSpace, MCLoader, VSpace } from "../index";
import { Alert, Button } from "reactstrap";

const GisItem: React.FC<{
  disconnectGisPoint: () => void;
  imgPath: string;
  goToViewer: () => void;
  showStartGisViewerButon: boolean;
  hasGis: boolean;
}> = ({ disconnectGisPoint, imgPath, goToViewer, showStartGisViewerButon, hasGis }) => {
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const [failedToShowImage, setFailedToShowImage] = React.useState<boolean>(false);
  return (
    <>
      <MCLoader loading={loading} />
      {hasGis === true && loading === false && (
        <>
          <div className="d-flex">
            <ConfirmDialog
              confirmCb={disconnectGisPoint}
              title="Slett giskobbling"
              message="Er du sikker på at du vil slette kobbling mellan denne sak og GIS?"
            >
              <Button size="sm" outline>
                <i className="fa fa-trash fa-fw" />
                Fjern GIS
              </Button>
            </ConfirmDialog>
            <HSpace />
            <div className="flex-fill" />
            {showStartGisViewerButon && <StartGisButton goToViewer={goToViewer} />}
          </div>
          <VSpace />
          <hr />
          {failedToShowImage === false && (
            <img onError={() => setFailedToShowImage(true)} alt={imgPath} src={imgPath} className="h-100 w-100" />
          )}
          {failedToShowImage === true && <Alert color="warning">Finner ikke skjermbilde</Alert>}
        </>
      )}
      {hasGis === false && (
        <Alert color="info">
          <div className="d-flex align-items-center">
            <span>Denne tingen har ingen tilknytning til GIS. Start GIS-viewer for å koble til ting.</span>
            <div className="flex-fill" />
            <StartGisButton goToViewer={goToViewer} />
          </div>
        </Alert>
      )}
    </>
  );
};

export default GisItem;

const StartGisButton: React.FC<{ goToViewer: () => void }> = ({ goToViewer }) => (
  <Button style={{ whiteSpace: "nowrap" }} onClick={() => goToViewer()}>
    <i className="fa fa-external-link fa-fw" />
    Start gis-viewer
  </Button>
);
