import moment from "moment";
import { STANDARD_COLORS } from "../../../globalConstants";
import { ICalenderEvent } from "./types";
import { MeetingSeriesStatusEnums } from "./meetingSeriesConstants";
import { IMeeting } from "../../../../../../types/meeting.types";

export const convertMeetingSeriesToCalenderEvent = (meetingSerie: IMeeting.MeetingSerie): ICalenderEvent[] => {
  const startDate = moment(meetingSerie.seriesStart);
  const endDate = moment(meetingSerie.seriesEnd);

  const calenderEvents: ICalenderEvent[] = [];
  if (meetingSerie.seriesEnd == null) {
    const startTime = moment(startDate).set({
      hour: Number(meetingSerie.timeStart?.split(":")[0]),
      minute: Number(meetingSerie.timeEnd?.split(":")[1]),
      second: 0,
      millisecond: 0,
    });
    const endTime = moment(startDate).set({
      hour: Number(meetingSerie.timeEnd?.split(":")[0]),
      minute: Number(meetingSerie.timeEnd?.split(":")[1]),
      second: 0,
      millisecond: 0,
    });
    calenderEvents.push({
      title: `${meetingSerie.name}`,
      description: meetingSerie.description,
      start: startTime.toISOString(), // 2021-03-25T14:00:00
      end: endTime.toISOString(),
      color: "gray",
      status: meetingSerie.status as MeetingSeriesStatusEnums,
    });
  } else {
    const nrOfDays = Math.abs(startDate.diff(endDate, "days"));

    const weekDayOfStartDate = startDate.day();

    for (let i = 0; i < nrOfDays; i++) {
      const d1 = moment(startDate).add(i, "days");
      const d2 = moment(startDate).add(i, "days");
      const weekDay = d1.day();
      const weeknumber = moment(d1, "MM-DD-YYYY").week();
      if (weekDayOfStartDate === weekDay && isIntervalWeekValid(meetingSerie.interval, startDate, weeknumber)) {
        const startTime = d1.set({
          hour: Number(meetingSerie.timeStart?.split(":")[0]),
          minute: Number(meetingSerie.timeStart?.split(":")[1]),
          second: 0,
          millisecond: 0,
        });
        const endTime = d2.set({
          hour: Number(meetingSerie.timeEnd?.split(":")[0]),
          minute: Number(meetingSerie.timeEnd?.split(":")[1]),
          second: 0,
          millisecond: 0,
        });
        calenderEvents.push({
          title: `${meetingSerie.name}`,
          description: meetingSerie.description,
          start: startTime.toISOString(), // 2021-03-25T14:00:00
          end: endTime.toISOString(),
          color: STANDARD_COLORS[meetingSerie.color],
          status: meetingSerie.status as MeetingSeriesStatusEnums,
        });
      }
    }
  }

  return calenderEvents;
};

const isIntervalWeekValid = (interval: string, startDate: any, week: number): boolean => {
  if (interval === "1") {
    return true;
  }

  if (interval === "2") {
    const weeknumber = moment(startDate, "MM-DD-YYYY").week();

    const startWeekIsEven = weeknumber % 2 === 0;
    const weekIsEven = week % 2 === 0;
    return startWeekIsEven === weekIsEven;
  }
};
