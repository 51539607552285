import React from "react";
import { useHistory } from "react-router-dom";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { useAppSelector } from "../../../hooks";

const RisikoBoardSelector: React.FC = () => {
  const project = useAppSelector((state) => state.adminReducer.project);
  const boards = useAppSelector((state) => state.risikoReducer.boards);
  const activeBoard = useAppSelector((state) => state.risikoReducer.activeBoard);

  const history = useHistory();

  const goToBoard = (boardId: string) => {
    history.push(`/project/${project._id}/risiko/${boardId}`);
  };

  const goToBoardSelection = () => {
    history.push(`/project/${project._id}/risiko`);
  };
  return (
    <UncontrolledDropdown>
      <DropdownToggle color="secondary" style={{ width: "200px" }} caret>
        {activeBoard.name}
      </DropdownToggle>
      <DropdownMenu>
        {boards.map((board, index) => (
          <DropdownItem key={index} onClick={() => goToBoard(board._id)}>
            {board.name}
          </DropdownItem>
        ))}
        <DropdownItem divider />
        <DropdownItem onClick={() => goToBoardSelection()}>Gå til Utvalg</DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default RisikoBoardSelector;
