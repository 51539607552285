import moment from "moment";
import React from "react";
import { Button, Card, CardBody, CardTitle, Table } from "reactstrap";
import { IRisiko } from "../../../../../../types/global.types";
import { Avatar, OptionBadge } from "../../../../components/";
import { RISIKO_STATES } from "../risikoConstants";
import { trancuateStr } from "../Stats/ScoreMatrix";

const RisikoMiniTable: React.FC<{ risikos: IRisiko[]; setSelectedRisiko: any }> = ({ risikos, setSelectedRisiko }) => {
  return (
    <Card className="m-0 p-0">
      <CardBody>
        <CardTitle tag="h3">Sist opprettede risikoer</CardTitle>
        <Table>
          <thead>
            <tr>
              <th style={{ width: "60px" }} />
              <th style={{ width: "20px" }} />
              <th style={{ width: "40%" }}>Tittel</th>
              <th>Dato</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {risikos.map((risiko) => (
              <tr key={risiko._id}>
                <th scope="row">
                  <Avatar id={risiko.createdBy == null ? null : risiko.createdBy._id} />
                </th>
                <td>
                  <Button onClick={() => setSelectedRisiko(risiko._id)} color="link">
                    {risiko.id}
                  </Button>
                </td>
                <td>{trancuateStr(risiko.title)}</td>
                <td>{risiko.createdAt != null ? moment(risiko.createdAt).format("YYYY.MM.DD") : "-"}</td>
                <td className="align-middle">
                  <OptionBadge activeOption={risiko.state} options={RISIKO_STATES} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default RisikoMiniTable;
