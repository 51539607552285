export const SET_RISIKO_ITEMS = "risikoReducer/SET_RISIKO_ITEMS";
export const UPDATE_RISIKO_ITEM = "risikoReducer/UPDATE_RISIKO_ITEM";
export const ADD_RISIKO = "risikoReducer/ADD_RISIKO";
export const DELETE_RISIKO = "risikoReducer/DELETE_RISIKO";
export const SET_RISIKO_CATEGORIES = "risikoReducer/SET_RISIKO_CATEGORIES";
export const SET_RISIKO_BOARDS = "risikoReducer/SET_RISIKO_BOARDS";
export const SET_ACTIVE_RISIKO_BOARD = "risikoReducer/SET_ACTIVE_RISIKO_BOARD";
export const SET_ACTIVE_RISIKO_TAB = "risikoReducer/SET_ACTIVE_RISIKO_TAB";
export const SET_EVALUATIONBAR_OPEN = "risikoReducer/SET_EVALUATIONBAR_OPEN";
export const SET_TABLE_SCROLL_MODE = "risikoReducer/SET_TABLE_SCROLL_MODE";
export const RESET_RISIKO = "risikoReducer/RESET_RISIKO";
export const SET_RISKO_COLUMN_TEMPLATE = "risikoReducer/SET_RISKO_COLUMN_TEMPLATE";
export const SET_FILTRED_BY_CHART_OR_MATRIX_RISIKO_IDS = "risikoReducer/SET_FILTRED_BY_CHART_OR_MATRIX_RISIKO_IDS";
export const SET_SELECTED_RISIKO = "risikoReducer/SET_SELECTED_RISIKO";
export const SET_GLOBAL_SEARCH_STRING = "risikoReducer/SET_GLOBAL_SEARCH_STRING";
export const SET_ACTIVE_VIEW_RISIKO = "risikoReducer/SET_ACTIVE_VIEW_RISIKO";
export const SET_GIS_WAS_LOADED = "risikoReducer/SET_GIS_WAS_LOADED";
export const SET_ACTIVE_RISIKO_ACTION_ID = "risikoReducer/SET_ACTIVE_RISIKO_ACTION_ID";
export const SET_SELECTED_RISIKO_GIS_MAP = "risikoReducer/SET_SELECTED_RISIKO_GIS_MAP";
export const SET_RISIKO_DOCX_TEMPLATES = "risikoReducer/SET_RISIKO_DOCX_TEMPLATES";
export const SET_RISIO_BOARD_TEMPLATES = "risikoReducer/SET_RISIO_BOARD_TEMPLATES";
