import moment from "moment";
import "moment/dist/locale/nb";
import React, { useState } from "react";
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { IMeeting } from "../../../../../../../types/meeting.types";
import { FailedAlert, McInput, McTitle } from "../../../../../components";
import { useAppSelector } from "../../../../../hooks";
import { useSendMeetingAgendaByMailMutation } from "../../meetingRTK";

const SendMeetingAgendaByMailModal: React.FC<{
  close: () => void;
  meeting: IMeeting.MeetingPopulated;
  altTitle?: string;
}> = ({ close, meeting, altTitle }) => {
  const user = useAppSelector((state) => state.globalReducer.user);
  const day = moment(meeting.dateOfMeeting).date();
  const month = moment(meeting.dateOfMeeting).format("MMMM");
  const meetingSeries = meeting?.meetingSerie as IMeeting.MeetingSerie;
  const modalTitle = altTitle ? altTitle : "agenda";
  const capitalizeFirstLetter = (str: string): string => {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const capitalizedModalTitle = capitalizeFirstLetter(modalTitle);

  const [emailContent, setEmailContent] = useState<string>(
    "Hei [møtedeltakere].\n\n" +
      `Under finner du ${modalTitle} for møtet ` +
      day +
      " " +
      month +
      (meetingSeries ? ",\n" + "en del av møteserie: " + meetingSeries.name + ".\n" : ".\n") +
      "\nMvh " +
      user.name,
  );
  const [sendMeetingAgnedaByMail, { isLoading: sendMeetingAgendaMail, error: sendEmailError, isSuccess }] =
    useSendMeetingAgendaByMailMutation();

  const emailSubject = `${capitalizedModalTitle} for møtet ${meeting.name}`;

  const url = window.location.href;

  return (
    <Modal isOpen toggle={close} style={{ height: "300px" }}>
      <ModalHeader toggle={close}>Distribuer {modalTitle} på e-post</ModalHeader>
      <ModalBody>
        {sendEmailError != null && <FailedAlert />}
        {isSuccess === true && <Alert color="success">E-posten ble sendt</Alert>}
        {isSuccess !== true && sendEmailError == null && (
          <>
            <McTitle title="Emne:">
              <p>
                {capitalizedModalTitle} for møtet {meeting.name}
              </p>
            </McTitle>
            <McTitle title="Innhold:">
              <McInput
                onBlur={(e) => setEmailContent(e.target.value)}
                value={emailContent}
                type="textarea"
                dynamicHeight={true}
              />
              Link til møte:{" "}
              <a href={url} style={{ wordWrap: "break-word" }}>
                {url}
              </a>
              Denne e-posten ble sendt ut fra MCApps av {meeting.owner.name} og kan ikke besvares.
            </McTitle>
            <hr />
            <p>
              <i>
                Denne e-posten sendes til alle {meeting?.participants.length + meeting?.externalParticipants?.length}{" "}
                deltakere
              </i>
            </p>
          </>
        )}
      </ModalBody>
      {sendEmailError == null && isSuccess === false && (
        <ModalFooter className="d-flex justify-content-center">
          <Button
            color="success"
            onClick={() => {
              sendMeetingAgnedaByMail({
                body: {
                  emailContent: emailContent,
                  emailSubject: emailSubject,
                  urlToMeetingAgenda: url,
                  contextLabel: modalTitle,
                },
                params: { projectId: meeting.project, meetingId: meeting._id },
              });
            }}
            disabled={sendMeetingAgendaMail}
          >
            <i className="fa fa-send fa-fw" />
            {sendMeetingAgendaMail ? "Sender..." : "Send"}
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default SendMeetingAgendaByMailModal;
