import {
  IEnergiBrukPerTimme,
  IEnergiDataSeries,
  IEnergiSchema,
  ZEnergiSchema,
} from "mc-shared/zod/report/Energi/Energi_601_schema";
import { Alert, Button, Card, CardBody, CardTitle, ListGroup, ListGroupItem } from "reactstrap";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { read, utils } from "xlsx";
import { MCLoader, VSpace } from "../../../../components";
import { convertEnergiBrukPerTimmeFromExcel } from "./EnergiRapportUtils";
import { AgGridReact } from "ag-grid-react";
import { AG_GRID_LOCALE_NOR } from "../../../../agGridLocale";
import { ColDef } from "ag-grid-community";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsExport from "highcharts/modules/exporting";
import highchartsExportOffline from "highcharts/modules/offline-exporting";
import { doReportImageUpload } from "../reportBoardAPI";
import { useDebounce } from "usehooks-ts";
import { ISJArapport } from "mc-shared/zod/report/Elektro/sjaSchema";
import { SafeParseReturnType, ZodError } from "zod";
import { useHistory } from "react-router-dom";
import { getProjectIdFromUrl } from "../../../utils";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { useImmer } from "use-immer";
import { updateUpdatedFiles } from "../reportSlice";
import FileUploaderDepricated from "../../../../components/FileUploader/FileUploaderDepricated";

highchartsExport(Highcharts);
highchartsExportOffline(Highcharts);

const Energirapport: React.FC<{
  onUpdateEditData: (data: IEnergiSchema, dataToGenerateDocxWith: IEnergiSchema) => void;
  onError: (errors: ZodError<IEnergiSchema>) => void;
  data: IEnergiSchema;
}> = ({ data, onError, onUpdateEditData }) => {
  const [editData, setEditData] = useImmer(data);
  const [energiBruktPerTimme, setEnergiBruktPerTimme] = useState<IEnergiBrukPerTimme[]>([]);
  const debouncedValue = useDebounce<ISJArapport>(editData, 1000);

  useEffect(() => {
    onUpdateEditData(data, data);
  }, []);

  useEffect(() => {
    const parsedResult: SafeParseReturnType<ISJArapport, ISJArapport> = ZEnergiSchema.safeParse(editData);
    if (parsedResult.success === false) {
      onError(parsedResult.error);
    } else {
      onUpdateEditData(editData, editData);
    }
  }, [debouncedValue]);

  React.useEffect(() => {
    setEditData(null);
    setTimeout(() => {
      setEditData(data);
    }, 50);
  }, [data]);

  const [loading, setLoading] = useState<boolean>(false);

  const handleUpload = (file) => {
    setLoading(true);
    //e.preventDefault();

    const reader = new FileReader();
    reader.onload = function (e) {
      const data = e.target.result;
      let readedData = read(data, { type: "binary" });
      const indexOfSheetName = readedData.SheetNames.indexOf("Figur energibruk nåtilst.");
      if (indexOfSheetName === -1) {
        alert("Fant ikke sheet med navn 'Figur energibruk nåtilst.'");
        setLoading(false);
        return;
      }
      const wsname = readedData.SheetNames[indexOfSheetName];
      const ws = readedData.Sheets[wsname];
      /* Convert array to json*/
      const dataParse = utils.sheet_to_json(ws, { header: 1 });
      if (dataParse.length === 0) {
        alert("Fant ingen data i excel");
        setLoading(false);
        return;
      }

      const energiBrukperTimme = convertEnergiBrukPerTimmeFromExcel(dataParse);
      setEnergiBruktPerTimme(energiBrukperTimme);
      setLoading(false);
    };
    reader.readAsBinaryString(file);
  };

  return (
    <div>
      {loading && <MCLoader />}
      {loading === false && (
        <>
          <FileUploaderDepricated onFileUpload={(file) => handleUpload(file)} />
          <VSpace />
          {editData != null && <EnergiBrukPerTimme energiBruktPerTimme={energiBruktPerTimme} />}
        </>
      )}
    </div>
  );
};

export default Energirapport;

const energiAgColumns: ColDef<IEnergiBrukPerTimme>[] = [
  {
    field: "utetemp",
    headerName: "Utetemp. [°C]",
  },
  {
    field: "rommoppv",
    headerName: "Romoppv. [W]",
  },
  {
    field: "ventverme",
    headerName: "Vent.varme. [W]",
  },
  {
    field: "romkyl",
    headerName: "Romkyl. [W]",
  },
  {
    field: "ventkyl",
    headerName: "Vent.kyl. [W]",
  },
  {
    field: "effektbelysning",
    headerName: "Effekt belysning. [W]",
  },
  {
    field: "effektutstyr",
    headerName: "Effekt utstyr. [W]",
  },
  {
    field: "effektvifter",
    headerName: "Effekt vifter. [W]",
  },
  {
    field: "produksjonSolcell",
    headerName: "Produksjon solcell. [W]",
  },
];

const EnergiBrukPerTimme: React.FC<{ energiBruktPerTimme: IEnergiBrukPerTimme[] }> = ({ energiBruktPerTimme = [] }) => {
  const activeReport = useAppSelector((state) => state.reportReducer.activeReport); //state.reportReducer.activeReport;
  const series = getSeries(energiBruktPerTimme, energiAgColumns) ?? [];

  const options = useMemo(
    () => ({
      exporting: {
        fallbackToExportServer: false,
      },
      accessibility: { enabled: false }, //to remove warning
      chart: {
        type: "area",
      },
      title: {
        text: "Energibruk per timme [kWh]",
      },
      /*subtitle: {
      text: "Source: " + '<a href="https://www.ssb.no/en/statbank/table/09288/"' + 'target="_blank">SSB</a>',
    },*/
      yAxis: [
        {
          // Primary yAxis
          title: {
            text: "Effekt [W]",
            style: {
              color: Highcharts.getOptions().colors[1],
            },
          },
        },
        {
          // Secondary yAxis
          title: {
            text: "UteTemp [°C]",
            style: {
              color: Highcharts.getOptions().colors[0],
            },
          },
          opposite: true,
        },
      ],
      tooltip: {
        shared: true,
        headerFormat: '<span style="font-size:12px"><b>{point.key}</b></span>' + "<br>",
      },
      plotOptions: {
        series: {
          pointStart: 0,
        },
        area: {
          stacking: "normal" as any,
          lineColor: "#666666",
          lineWidth: 1,
          marker: {
            lineWidth: 1,
            lineColor: "#666666",
          },
        },
      },
      credits: {
        enabled: false,
      },
      series: series,
    }),
    [series],
  );

  const history = useHistory();
  const projectId = getProjectIdFromUrl(history.location.pathname);
  const dispatch = useAppDispatch();

  const getScreenShotOfChart = async () => {
    const chart = chartComponent.current.chart;
    const svg = chart.getSVG();
    const svgBlob = new Blob([svg], { type: "image/svg+xml;charset=utf-8" });
    const url = URL.createObjectURL(svgBlob);

    const img = new Image();
    img.src = url;
    img.onload = async () => {
      const canvas = document.createElement("canvas");
      canvas.width = chart.chartWidth;
      canvas.height = chart.chartHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      canvas.toBlob(async (blob) => {
        const base64 = await new Promise<string>((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result as string);
          };
          reader.readAsDataURL(blob);
        });
        const uploadedFiles = await doReportImageUpload({
          projectId: projectId,
          reportBoardId: activeReport._id,
          docxImageTag: "energiBrukPerTimme",
          imageData: base64,
          height: canvas.height,
          width: canvas.width,
        });
        dispatch(updateUpdatedFiles(uploadedFiles));
        setEnergibrukImageStored(true);
      }, "image/png");
    };
  };

  const [energiBrukImageStored, setEnergibrukImageStored] = useState<boolean>(false);

  const chartComponent = useRef(null);
  return (
    <Card>
      <CardBody>
        <CardTitle tag="h3">Energi bruk per timme</CardTitle>
        <h4>Krav excel</h4>
        <Alert color="info">
          <ListGroup>
            <ListGroupItem>Fanen skal hete "Figur energibruk nåtilst."</ListGroupItem>
            <ListGroupItem>
              Data med timeenergiforbruk for hele året skal stå i kolonne M til U med overskrifter som starter på linje
              13. Rekkefølgen skal være som følger: Utetemp. [°C],Roma oppv.[W],Vent.warm.[W],Romkjøling [W]
              Vent.kjøling [W],Effektiv belysning [W],Effektivt utstyr [W],Effektive vifter [W] Produkjon
              solcellepaneler [W].
            </ListGroupItem>
          </ListGroup>
        </Alert>
        {energiBruktPerTimme?.length > 0 && (
          <>
            <hr />
            <h4>Resultat</h4>
            <Button
              color={energiBrukImageStored === false ? "success" : "secondary"}
              onClick={() => getScreenShotOfChart()}
            >
              {energiBrukImageStored === false ? "Lagre bilde" : "Bilde lagret"}
            </Button>
            {
              //@ts-ignore
              <HighchartsReact highcharts={Highcharts} options={options} ref={chartComponent} />
            }
            <div className="checklistTable ag-theme-quartz h-100 w-100">
              <AgGridReact
                domLayout="autoHeight"
                rowData={energiBruktPerTimme}
                columnDefs={energiAgColumns}
                suppressClickEdit={true}
                rowSelection="single"
                animateRows={true}
                suppressContextMenu={true}
                localeText={AG_GRID_LOCALE_NOR}
                pagination={true}
                paginationPageSize={20}
              />
            </div>
          </>
        )}
      </CardBody>
    </Card>
  );
};

const getSeries = (
  energibrukPerTimme: IEnergiBrukPerTimme[],
  columns: ColDef<IEnergiBrukPerTimme>[],
): IEnergiDataSeries[] => {
  return columns.map((col) => {
    return {
      name: col.headerName,
      data: energibrukPerTimme.map((e) => e[col.field]),
      yAxis: col.field === "utetemp" ? 1 : 0,
      type: col.field === "utetemp" ? "spline" : "area",
    };
  });
};
