import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "moment/dist/locale/nb";
import React, { useState } from "react";
import { Button, ButtonGroup, Input, Progress } from "reactstrap";
import { IValueLabel } from "../../../../../types/global.types";
import { IRegister } from "../../../../../types/register.types";
import { AG_GRID_LOCALE_NOR } from "../../../agGridLocale";
import { HSpace, MCLoader } from "../../../components";
import { AccessView } from "../../../components/AccessComp/AccessComp";
import { useAppSelector } from "../../../hooks";
import { formatShortDate } from "../admin/selectors";
import IssueSearch from "./IssueSearch";

const IssueBoardsTable: React.FC<{
  registerBoards: IRegister.BoardPopulated[];
  onClickRegisterBoard: (board: IRegister.BoardPopulated) => void;
  statuses: IValueLabel[];
  activeStatus: string;
  setActiveStatus: (str: string) => void;
  addNew: () => void;
  loading?: boolean;
}> = ({ registerBoards, onClickRegisterBoard, statuses, activeStatus, setActiveStatus, addNew, loading }) => {
  const project = useAppSelector((state) => state.adminReducer.project);
  const [filterText, setFilterText] = useState<string>("");

  const issueBoardColumnDefs: ColDef<any>[] = [
    {
      headerName: "Tittel",
      field: "name",
      sortable: true,
      filter: "agTextColumnFilter",
      flex: 2,
      sort: "asc",
      maxWidth: 550,
      cellRenderer: (params) => (
        <b className="mb-0 text-primary" style={{ textDecoration: "underline", fontWeight: 600, fontSize: "1.05em" }}>
          {params.value}
        </b>
      ),
    },
    {
      headerName: "Type register",
      field: "category",
      sortable: true,
      filter: "agTextColumnFilter",
      cellClass: "px-1",
      minWidth: 135,
    },
    {
      headerName: "Tilgang",
      field: "access",
      sortable: true,
      filter: "agTextColumnFilter",
      cellClass: "px-1",
      minWidth: 135,
      cellRenderer: (params) => {
        return <div>{params.value != null && <AccessView access={params.value} variant="sm" />}</div>;
      },
    },
    {
      headerName: "Status",
      field: "progress",
      cellRenderer: (params) => {
        return (
          <div className="w-100">
            <Progress multi className="thin-progress">
              {params?.value?.map((progress, index) => {
                return (
                  <Progress bar style={{ backgroundColor: progress.color }} value={progress.percentage} key={index} />
                );
              })}
            </Progress>
          </div>
        );
      },
    },
    {
      headerName: "Opprettet av",
      field: "createdBy.name",
      sortable: true,
      filter: "agTextColumnFilter",
      flex: 2,
    },
    {
      headerName: "Opprettet",
      field: "createdAt",
      sortable: true,
      filter: "agDateColumnFilter",
      cellRenderer: (params) => formatShortDate(params.value),
    },
  ];

  return (
    <div className="d-flex flex-column flex-grow-1">
      {registerBoards != null && (
        <>
          <div className="d-flex mb-2">
            <HSpace />
            <Button color="success" onClick={() => addNew()}>
              <i className="fa fa-plus" />
            </Button>
            <Input
              type="text"
              placeholder="Søk"
              className="mx-2"
              onChange={(e) => setFilterText(e.target.value)}
              style={{ maxWidth: "140px", height: "2.2rem" }}
            />
            <ButtonGroup className="w-100 mr-2">
              {statuses.map((status, index) => (
                <Button
                  className="d-flex justify-content-center align-items-center"
                  key={index}
                  style={{ maxWidth: "200px" }}
                  active={activeStatus === status.value}
                  onClick={() => setActiveStatus(status.value)}
                >
                  <div className="pr-1">{status.label}</div>
                  <div
                    style={{
                      display: "grid",
                      placeItems: "center",
                      borderRadius: "50%",
                      width: "1.25rem",
                      height: "1.2rem",
                      backgroundColor: "#999999d9",
                    }}
                  >
                    {registerBoards.filter((board) => board.status === status.value).length}
                  </div>
                </Button>
              ))}
            </ButtonGroup>
            <div className="w-25">
              <IssueSearch projectId={project._id} />
            </div>
            <HSpace />
          </div>
          <div className="checklistTable ag-theme-quartz h-100 w-100">
            <AgGridReact
              rowData={registerBoards.filter((board) => board.status === activeStatus)}
              defaultColDef={{
                suppressHeaderMenuButton: true,
                autoHeight: false,
                minWidth: 100,
                flex: 1,
                cellStyle: {
                  whiteSpace: "normal",
                  lineHeight: "1.5",
                  wordBreak: "break-word",
                },
              }}
              columnDefs={issueBoardColumnDefs}
              rowHeight={50}
              suppressClickEdit={true}
              rowSelection="single"
              animateRows={true}
              suppressContextMenu={true}
              onRowClicked={(e) => {
                onClickRegisterBoard(e.data);
              }}
              localeText={AG_GRID_LOCALE_NOR}
              quickFilterText={filterText}
            />
          </div>
        </>
      )}
      {registerBoards.length === 0 && loading === false && (
        <p>Her var det tomt. Trykk på plussknappen for å opprette ny.</p>
      )}
      {loading === true && <MCLoader loading />}
    </div>
  );
};

export default IssueBoardsTable;
