import React, { useState } from "react";
import { IBasicItem, IGisCamera, IGisPoint, IGisPointAndCamera, IRisiko } from "../../../../../../types/global.types";
import { Viewpoints } from "../../../../components";
import ConnectItemToGisModal from "../../../../components/ConnectItemToGisModal/ConnectItemToGisModal";
import { AppModalEnums, ToastMessagesEnums } from "../../../../frontendConstants";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import ArcGisMap from "../../gis/ArcGisMap";
import { addRisikoRedux, fetchRisikoAndReplaceItInRedux, setSelectedRisikoInRedux } from "../actions";
import { RisikoItemTabEnum } from "../reducer";
import { doGetBasicRisikoInfo, doUpdateRisiko } from "../risikoAPI";
import { getRisikoWithGisViewPoints } from "../selectors";
import AddRisikoModal from "./AddRisikoModal";
import { addAppModalThunk, setShowSuccessMessageThunk } from "../../../../global/globalSlice";

const RisikoGIS: React.FC<{
  projectId: string;
  boardId: string;
  mapId: string;
  risikos: IRisiko[];
}> = ({ projectId, boardId, mapId, risikos }) => {
  const [addRisikoModalOpen, setAddRisikoModalOpen] = useState<boolean>(false);
  const [connectRisikoModalOpen, setConnectRisikoModalOpen] = useState<boolean>(false);
  const [clickedGis, setClickedGis] = useState<IGisPointAndCamera>(null);
  const [selectedVpId, setSelectedVpId] = React.useState<string>();
  const [basicTypeAheadItems, setBasicTypeAheadItems] = React.useState<IBasicItem[]>(null);
  const [zoomToGisPoint, setZoomToGisPoint] = useState<IGisPoint>(null);
  const [cameraPosition, setCameraPosition] = useState<IGisCamera>(null);
  const [gisImage, setGisImage] = useState<string>();

  const project = useAppSelector((state) => state.adminReducer.project);

  const dispatch = useAppDispatch();

  const viewPoints = useAppSelector(getRisikoWithGisViewPoints);

  React.useEffect(() => {
    fetchAndSetBasicTypeAheadItems();
  }, []);

  const fetchAndSetBasicTypeAheadItems = async () => {
    let _basicTypeAheadItems = await doGetBasicRisikoInfo(projectId, boardId);
    _basicTypeAheadItems = _basicTypeAheadItems.reduce((acc, item) => {
      if (viewPoints.find((vp) => vp._id === item._id) == null) {
        acc.push({
          displayValue: `${item.id}: ${item.title}`,
          ...item,
        });
      }

      return acc;
    }, []);
    setBasicTypeAheadItems(_basicTypeAheadItems);
  };

  const openAddRisikoModalWithGisPoint = (gisImage: string, gisObject: IGisPointAndCamera) => {
    setClickedGis(gisObject);
    setGisImage(gisImage);
    setAddRisikoModalOpen(true);
  };

  const openConnectRisikoModalWithGis = (gisImage: string, gisObject: IGisPointAndCamera) => {
    setClickedGis(gisObject);
    setGisImage(gisImage);
    setConnectRisikoModalOpen(true);
  };

  const deleteViewPoint = async (_id: string) => {
    const risiko = risikos.find((_risiko) => _risiko._id === _id);
    await doUpdateRisiko(project._id, risiko._id, "gis", null, JSON.stringify(risiko.gis));
    dispatch(fetchRisikoAndReplaceItInRedux(_id));
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.UPDATED));
  };

  const openRisikoModal = (_id: string) => {
    const risiko = risikos.find((risk) => risk._id === _id);
    dispatch(
      addAppModalThunk({
        itemId: _id,
        boardId: risiko.risikoBoard,
        projectId: projectId,
        app: AppModalEnums.RISIKO,
      }),
    );
  };

  const zoomToGisPointOrMoveCameraToPosition = (viewPointId: string) => {
    const viewpoint = viewPoints.find((vp) => vp._id === viewPointId);

    if (viewpoint?.gis.gisPoint != null) {
      setZoomToGisPoint(viewpoint.gis.gisPoint);
    }
    if (viewpoint?.gis.gisCamera != null) {
      setCameraPosition(viewpoint.gis.gisCamera);
    }
    setSelectedVpId(viewPointId);
    setTimeout(() => {
      setCameraPosition(null);
      setZoomToGisPoint(null);
    }, 100);
  };

  const closeAddRisikoModal = () => {
    setAddRisikoModalOpen(false);
    setClickedGis(null);
  };

  const onCreateRisiko = (risiko: IRisiko) => {
    setAddRisikoModalOpen(false);
    setClickedGis(null);
    dispatch(addRisikoRedux(risiko));
    dispatch(
      addAppModalThunk({
        itemId: risiko._id,
        boardId,
        projectId,
        app: AppModalEnums.RISIKO,
      }),
    );
  };

  const connectToGis = async (risikoId: string) => {
    const risiko = risikos.find((_risiko) => _risiko._id === risikoId);
    await doUpdateRisiko(project._id, risiko._id, "gis", clickedGis, "", null, gisImage);
    dispatch(fetchRisikoAndReplaceItInRedux(risikoId));
    setClickedGis(null);
    setConnectRisikoModalOpen(false);
    dispatch(
      setSelectedRisikoInRedux({
        _id: risiko._id,
        activeItemTab: RisikoItemTabEnum.GIS,
      }),
    );
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.UPDATED));
  };

  const handleSelectViewPoint = (viewPointId: string) => {
    setSelectedVpId(viewPointId);
  };

  return (
    <div className="d-flex flex-grow-1">
      <ArcGisMap
        clickedGis={clickedGis}
        label="Risiko"
        setclickedGis={setClickedGis}
        selectedVpId={selectedVpId}
        mapId={mapId}
        createItem={(gisImage, gisObject: IGisPointAndCamera) => openAddRisikoModalWithGisPoint(gisImage, gisObject)}
        connectItem={(gisImage, gisObject: IGisPointAndCamera) => openConnectRisikoModalWithGis(gisImage, gisObject)}
        viewPoints={viewPoints}
        onDoubleClickItem={(_id) =>
          dispatch(
            addAppModalThunk({
              itemId: _id,
              boardId,
              projectId,
              app: AppModalEnums.RISIKO,
            }),
          )
        }
        onSingleClickItem={handleSelectViewPoint}
        zoomToGisPoint={zoomToGisPoint}
        cameraPosition={cameraPosition}
        onLoaded={() => console.log("loaded")}
      />
      {addRisikoModalOpen && (
        <AddRisikoModal
          closeAddRisikoModal={closeAddRisikoModal}
          risikoBoardId={boardId}
          gis={clickedGis}
          gisImage={gisImage}
          onCreateRisiko={onCreateRisiko}
        />
      )}
      {connectRisikoModalOpen && basicTypeAheadItems != null && (
        <ConnectItemToGisModal
          items={basicTypeAheadItems}
          close={() => setConnectRisikoModalOpen(false)}
          connectItemToGis={(basicItem: IBasicItem) => connectToGis(basicItem._id)}
          placeholder="Skriv inn ID eller søk etter risikoer"
          typeOfItemToLink="Risiko"
          itemIdsToShow={risikos.map((risiko) => risiko._id)}
        />
      )}
      <Viewpoints
        viewPoints={viewPoints}
        selectedViewPointId={selectedVpId}
        onDeleteViewPoint={deleteViewPoint}
        onClickOnRow={(_id) => zoomToGisPointOrMoveCameraToPosition(_id)}
        onClickOnOpen={openRisikoModal}
      />
    </div>
  );
};

export default RisikoGIS;
