import classnames from "classnames";
import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import MeetingBoards from "./MeetingBoards/MeetingBoards";
import { MeetingSeries } from "./MeetingSeries/MeetingSeries";
import { setActiveMeetingAppTabInRedux } from "./meetingSlice";

export const MeetingApp: React.FC<{}> = () => {
  const meetingAppTab = useAppSelector((state) => state.meetingReducer.meetingAppTab);

  const dispatch = useAppDispatch();
  return (
    <div className="d-flex flex-column h-100 w-100 h-100 overflow-auto">
      <Nav tabs className="w-100 pt-2 px-2">
        <NavItem className="custom">
          <NavLink
            className={classnames({ active: meetingAppTab === "MEETINGS", custom: true })}
            onClick={() => dispatch(setActiveMeetingAppTabInRedux("MEETINGS"))}
          >
            Møter
          </NavLink>
        </NavItem>
        <NavItem className="custom">
          <NavLink
            className={classnames({ active: meetingAppTab === "MEETING_SERIES", custom: true })}
            onClick={() => dispatch(setActiveMeetingAppTabInRedux("MEETING_SERIES"))}
          >
            Møteserier
          </NavLink>
        </NavItem>
      </Nav>
      {meetingAppTab === "MEETINGS" && <MeetingBoards />}
      {meetingAppTab === "MEETING_SERIES" && <MeetingSeries />}
    </div>
  );
};
