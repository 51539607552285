import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Alert, Button, Card, CardBody, CardSubtitle, CardTitle, ListGroup, ListGroupItem } from "reactstrap";
import { IRiskoBoard } from "../../../../../types/global.types";
import { VSpace } from "../../../components";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { DEFAULT_PROBABILITY_SELECTIONS, DEFAULT_RISIKO_EVALUATION_CATEGORIES } from "../../globalConstants";
import { formatShortDate } from "../admin/selectors";
import CreateNewRisikoBoardModal from "./CreateNewRisikoBoardModal";
import { fetchAndSetRisikoBoardTemplatesThunk, resetRisikoRedux } from "./actions";
import {
  doCreateRisikoBoard,
  doCreateRisikoEvaluationCategory,
  doGetRisikoBoards,
  doSetRisikoRemoveStatus,
} from "./risikoAPI";
import { doCreateRisikoBoardTemplate } from "./riskoBoardAPI";

const RisikoBoards: React.FC = () => {
  let history = useHistory();
  const [boards, setBoards] = useState<IRiskoBoard[]>([]);
  const [modalIsOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const project = useAppSelector((state) => state.adminReducer.project);
  const risikoBoardTemplates = useAppSelector((state) => state.risikoReducer.risikoBoardTemplates);

  const goToBoard = async (boardId: string) => {
    history.push(`/project/${project._id}/risiko/${boardId}`);
  };

  const dispatch = useAppDispatch();

  const fetchData = async () => {
    dispatch(resetRisikoRedux());
    const _boards = await doGetRisikoBoards(project._id);
    setBoards(_boards);
    dispatch(fetchAndSetRisikoBoardTemplatesThunk());
  };

  useEffect(() => {
    fetchData();
  }, []);

  const createNew = async (name: string, selectedBoardTemplate?: IRiskoBoard) => {
    setLoading(true);
    const createdBoard = await doCreateRisikoBoard(
      project._id,
      name,
      selectedBoardTemplate == null ? DEFAULT_PROBABILITY_SELECTIONS : [],
      selectedBoardTemplate?._id,
      project.maconomy.projectNo === 100,
    );

    if (selectedBoardTemplate == null) {
      for (const category of DEFAULT_RISIKO_EVALUATION_CATEGORIES) {
        await doCreateRisikoEvaluationCategory(project._id, createdBoard._id, category);
      }
    }

    setModalOpen(false);
    goToBoard(createdBoard._id);
    setLoading(false);
  };

  const createNewTeplate = async (name: string) => {
    const createdTemplate = await doCreateRisikoBoardTemplate(project._id, name);

    setModalOpen(false);
    goToBoard(createdTemplate._id);
  };

  const toggle = () => {
    setModalOpen(!modalIsOpen);
  };

  const unRemoveBoard = async (id: string) => {
    await doSetRisikoRemoveStatus(project._id, id, false);
    fetchData();
  };

  const goToRegisterApp = () => {
    history.push(`/project/${project._id}/issues`);
  };

  return (
    <div className="d-flex flex-column w-100 h-100 pt-5 gray-100 overflow-auto">
      <div className="row">
        <div className="col-sm-3" />
        <div className="col-sm-6">
          {project.maconomy.projectNo === 100 && (
            <Alert color="danger">
              Alle risikoregistre i dette prosjektet vil være tilgjengelige som maler i andre prosjekter!
            </Alert>
          )}
          <Card className="m-3">
            <CardBody>
              <Alert color="warning">
                <b>
                  Risikofunksjonalitet er nå tilgjengelig i Register-appen. Nye risikoregistre i denne appen kan ikke
                  lenger opprettes. Du kan fortsette å bruke eksisterende registre. På sikt vil eksisterende
                  risikoregistre migreres til Register-appen. Spørsmål? mcapps@multiconsult.no
                </b>
                <VSpace />
                <Button color="primary" onClick={() => goToRegisterApp()}>
                  Gå til registerappen
                </Button>
              </Alert>
              <CardTitle tag="h3">Risikoregister</CardTitle>
              <CardSubtitle tag="h6" className="mb-2 text-muted" />
              <CreateNewRisikoBoardModal
                toggle={toggle}
                isOpen={modalIsOpen}
                createNew={createNew}
                risikoTemplates={risikoBoardTemplates || []}
                isTemplateProject={project.maconomy.projectNo === 100}
                createNewTemplate={createNewTeplate}
                loading={loading}
              />
              <VSpace />
              <ListGroup>
                {(boards || [])
                  .filter((board) => board.removed !== true)
                  .map((board, index) => (
                    <RisikoBoardItem
                      key={index}
                      board={board}
                      goToBoard={(board) => goToBoard(board._id)}
                      unRemoveBoard={unRemoveBoard}
                    />
                  ))}
              </ListGroup>
            </CardBody>
          </Card>
        </div>
        <div className="col-sm-3" />
      </div>
    </div>
  );
};

export default RisikoBoards;

const RisikoBoardItem: React.FC<{
  board: IRiskoBoard;
  goToBoard: (board: IRiskoBoard) => void;
  unRemoveBoard: (boardId: string) => void;
}> = ({ board, goToBoard, unRemoveBoard }) => {
  return (
    <ListGroupItem
      href="#"
      action={!board.removed}
      className="d-flex align-content-center"
      style={!board.removed ? { cursor: "pointer" } : {}}
      onClick={!board.removed ? () => goToBoard(board) : null}
    >
      <i className="fa fa-briefcase fa-3x fa-fw mt-2 gray-300-text mr-2" />
      <div className="d-flex flex-column w-100">
        <h4 className="mt-2">{board.name}</h4>
        <p style={{ fontSize: "12px", color: "#ccc" }}>{formatShortDate(board.createdDate)}</p>
      </div>
      <div className="d-flex justify-content-end align-items-center">
        {board.removed ? (
          <Button onClick={() => unRemoveBoard(board._id)} color="success" style={{ whiteSpace: "nowrap" }}>
            Oppheve fjerning
          </Button>
        ) : null}
      </div>
    </ListGroupItem>
  );
};
