import React, { useMemo } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { FlatHierarchy, IPNSProps } from "../../../../types/global.types";

interface IPNSWithComboLabel {
  _id: string;
  parentId: string | null;
  props: IPNSProps;
  comboLabel: string;
}

const PNSTypeAhead: React.FC<{
  pnsItems: FlatHierarchy<IPNSProps>;
  selected?: string;
  setValue: any;
  saveCb?: (value: string) => void;
}> = ({ pnsItems, selected = "", setValue, saveCb }) => {
  const typeAheadPNSItems: IPNSWithComboLabel[] = useMemo(() => {
    return pnsItems?.map((pns) => ({
      ...pns,
      comboLabel: `${pns.props.pnsId} - ${pns.props.title}`,
    }));
  }, [pnsItems]);

  const onTypeaheadChange = (arr: IPNSWithComboLabel[]) => {
    if (arr != null) {
      if (arr[0] != null) {
        if (typeof arr[0] === "string") {
          setValue(arr[0]);
        } else {
          if (arr[0]?.props?.pnsId != null) {
            setValue(arr[0]?.props?.pnsId);
          } else {
            setValue(arr[0]?.comboLabel);
          }
        }
      } else {
        setValue(null);
      }
    } else {
      setValue(null);
    }
  };

  const blur = () => {
    if (saveCb != null) {
      saveCb(selected);
    }
  };

  return (
    <Typeahead
      selected={selected != null ? [selected] : null}
      size="sm"
      className="w-100"
      clearButton
      id="selections-example"
      labelKey="comboLabel"
      options={typeAheadPNSItems}
      placeholder="Tast in PNS..."
      onChange={(arrVal: IPNSWithComboLabel[]) => onTypeaheadChange(arrVal)}
      allowNew
      onBlur={blur}
    />
  );
};

export default PNSTypeAhead;
