import { ColDef } from "ag-grid-community";
import React from "react";
import Switch from "react-switch";
import { Card, CardBody, CardTitle, UncontrolledTooltip } from "reactstrap";
import { IBoard, TIssueStatus } from "../../../../../../types/global.types";
import { FailedAlert, MCLoader } from "../../../../components";
import ViewEditTable, { VETEmptyValidator } from "../../../../components/ViewEditTable/ViewEditTable";
import { useAppSelector } from "../../../../hooks";
import { useUpdateIssueBoardBucketsMutation, useUpdateIssueBoardUseBucketsAsWorkflowMutation } from "../IssueBoardRTK";
import { ISSUE_STATES_OPTIONS } from "../issueConstants";

type IVETBoard = { value: string; status: TIssueStatus; order: number };

const IssueBoardBuckets: React.FC<{ issueBoard: IBoard }> = ({ issueBoard }) => {
  const project = useAppSelector((state) => state.adminReducer.project);
  const { buckets, useBucketsAsWorkflow } = issueBoard;

  const [updateBuckets, { isLoading: updateBucketsLoading, error: errorBuckets }] =
    useUpdateIssueBoardBucketsMutation();
  const [updateUseAsWorkflow, { isLoading: updateUseAsWorkflowLoading, error: errorWorkflow }] =
    useUpdateIssueBoardUseBucketsAsWorkflowMutation();

  const columns = React.useMemo<ColDef<IVETBoard>[]>(() => {
    const _columns: ColDef<IVETBoard>[] = [
      {
        field: "value",
        headerName: "Navn",
        editable: true,
      },
    ];

    if (useBucketsAsWorkflow === true) {
      _columns.push({
        field: "status",
        headerName: "Status",
        editable: true,
        cellEditor: "agSelectCellEditor",
        cellEditorParams: {
          values: ISSUE_STATES_OPTIONS.map((state) => state.value),
        },
      });
    }

    return _columns;
  }, [useBucketsAsWorkflow]);

  const tableData = React.useMemo<IVETBoard[]>(() => {
    return buckets?.map((bucket, index) => {
      return {
        order: bucket.order,
        value: bucket.value,
        status: bucket.status || "Opprettet",
      };
    });
  }, [buckets]);

  const newItemTemplateObject: IVETBoard = {
    value: "",
    status: "Opprettet",
    order: 0,
  };

  const saveBuckets = async (buckets: IVETBoard[]) => {
    if (buckets.length === 0) {
      alert("Du må ha minst 1 bucket");
      return;
    }

    updateBuckets({
      projectId: project._id,
      issueBoardId: issueBoard._id,
      buckets,
    });
  };

  const isLoading = updateBucketsLoading || updateUseAsWorkflowLoading;
  const error = errorBuckets != null || errorWorkflow != null;

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h3">{issueBoard?.bucketNameSetting}</CardTitle>
        {isLoading === true && <MCLoader loading={isLoading} />}
        {error === true && <FailedAlert />}
        <>
          <div className="d-flex">
            <UncontrolledTooltip placement="top" target="abo">
              Når {issueBoard?.bucketNameSetting?.toLowerCase()} endres, endres den tilsvarende statusen. Brukes
              hovedsakelig i kanban hvor du kan bygge en arbeidsflyt som beveger seg fra venstre til høyre
            </UncontrolledTooltip>
            <p className="mr-2">
              <i id="abo" className="fa fa-info-circle fa-fw text-info" />
              Bruk {issueBoard?.bucketNameSetting?.toLowerCase()} som arbeidsflyt:
            </p>
            <Switch
              onColor="#00a76d"
              onChange={(bool) =>
                updateUseAsWorkflow({
                  projectId: project._id,
                  issueBoardId: issueBoard._id,
                  useBucketsAsWorkflow: bool,
                })
              }
              checked={useBucketsAsWorkflow || false}
            />
          </div>
          <ViewEditTable
            validators={[VETEmptyValidator]}
            canEdit={true}
            orderProp={"order"}
            columns={columns}
            data={tableData}
            onSave={saveBuckets}
            newItemTemplateObject={newItemTemplateObject}
          />
        </>
      </CardBody>
    </Card>
  );
};

export default IssueBoardBuckets;
