import { IssueViewEnum } from "mc-shared/enums/enums";
import React from "react";
import { IIssue, IValueLabel } from "../../../../../../../types/global.types";
import { HSpace, McDropdown } from "../../../../../components";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { IssueSortValueEnum } from "../../issueConstants";
import { setSortValueInRedux } from "../../issueSlice";

const GantSorterDropdown: React.FC<{ issues: IIssue[] }> = ({ issues }) => {
  const dispatch = useAppDispatch();

  const activeView = useAppSelector((state) => state.issueReducerNew.activeView);
  const sortValue = useAppSelector((state) => state.issueReducerNew.gant?.sortValue);

  React.useEffect(() => {
    if (activeView === IssueViewEnum.GANTT && sortValue == null) {
      if (issues?.length > 0) {
        changeSortingFromPreset(IssueSortValueEnum.ID);
      }
    }
  }, [activeView, issues]);

  const sortOptions: IValueLabel[] = [
    { label: "ID", value: "id" },
    { label: "Startdato", value: "dates.plannedStart" },
    { label: "Sluttdato", value: "dates.due" },
  ];

  const changeSortingFromPreset = (preset: IssueSortValueEnum) => {
    dispatch(setSortValueInRedux(preset));
  };

  return (
    <div className="d-flex align-items-center">
      <HSpace />
      <McDropdown
        color="mc-blue"
        currentValue={sortOptions.find((option) => option.value === sortValue)}
        onChange={(oldVal, newValue) => changeSortingFromPreset(newValue.value as IssueSortValueEnum)}
        displayKey="label"
        defaultTitle="Sorter på"
        options={sortOptions}
      />
    </div>
  );
};

export default GantSorterDropdown;
