import { AgGridReact } from "ag-grid-react";
import { IssueViewEnum } from "mc-shared/enums/enums";
import { ITableTemplate } from "mc-shared/zod/issueSchema";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { FlatHierarchy, IIssue, IMinUser, IPNSProps } from "../../../../../../types/global.types";
import { FailedAlert, MCLoader, VSpace } from "../../../../components";
import HierarchyTreeSelector from "../../../../components/HierarchyTreeSelector/HierarchyTreeSelector";
import { AppModalEnums, MCApplicationsUrlEnum } from "../../../../frontendConstants";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import ErrorBoundary from "../../../../util/ErrorBoundary";
import { projectHasADefaultArcgisMap } from "../../../utils";
import { doLogUsage } from "../../admin/restQueries";
import { NoGisGroup, NoGisMap } from "../../gis/ArcGisMap";
import PnsApp from "../../pns/PnsApp";
import { useGetSingleIssueBoardQuery } from "../IssueBoardRTK";
import { useDoGetAllIssuesQuery, useDoUpdateIssueMutation, useGetUniqueTagsOnBoardQuery } from "../issueRTK";
import { loadIssueTableTemplateThunk, setSelectedPnsFilterItemInRedux, updateColumnStateThunk } from "../issueSlice";
import { getIssueBoardIdFromUrl, getIssueUrlPath } from "../issueUtils";
import IssueDashboardMain from "./Dashboard/IssueDashboardMain";
import IssuesGis from "./GIS/IssuesGis";
import IssueGant from "./Gant/IssueGant";
import IssueDbFilterBar from "./IssueDbFilterBar";
import IssueTabActionBar from "./IssueTabActionBar";
import IssueKanban from "./Kanban/IssueKanban";
import IssueTableAgGrid from "./Table/IssueTableAgGrid";
import { addAppModalThunk } from "../../../../global/globalSlice";

const IssueTab: React.FC<{ setActiveTab: (tab: string) => void }> = ({ setActiveTab }) => {
  const [tableTempalteWasSetOnStart, setTableTempalteWasSetOnStart] = useState<boolean>(false);

  const project = useAppSelector((state) => state.adminReducer.project);
  const allColumnState = useAppSelector((state) => state.issueReducerNew.allColumnState);
  const activeView = useAppSelector((state) => state.issueReducerNew.activeView);
  const allFilters = useAppSelector((state) => state.issueReducerNew.allFilters);
  const freeTextSearch = useAppSelector((state) => state.issueReducerNew.freeTextSearch);
  const gant = useAppSelector((state) => state.issueReducerNew.gant);
  const selectedPnsFilterItem = useAppSelector((state) => state.issueReducerNew.selectedPnsFilterItem);
  const openedModals = useAppSelector((state) => state.globalReducer.openedAppModals);

  const gridRef = useRef<AgGridReact>(null);
  const pnsItems = project?.pns as FlatHierarchy<IPNSProps>;

  const dateRange = gant.dateRange;

  useEffect(() => {
    doLogUsage(project, MCApplicationsUrlEnum.ISSUE, "open");
  }, []);

  const location = useLocation();
  const issueBoardId = getIssueBoardIdFromUrl(location.pathname);

  const {
    data: issueBoard,
    isLoading: boardLoading,
    error: boardError,
  } = useGetSingleIssueBoardQuery({
    projectId: project._id,
    issueBoardId: issueBoardId,
  });

  const [updateIssue, { error: updateIssueError }] = useDoUpdateIssueMutation();

  const {
    data: items = [],
    isLoading: issuesLoading,
    error: errorIssuesGet,
  } = useDoGetAllIssuesQuery(
    {
      projectId: project._id,
      issueBoardId: issueBoardId,
      allFilters: allFilters,
      freeTextSearch: freeTextSearch,
      pns: selectedPnsFilterItem,
    },
    {
      selectFromResult: ({ data, error, isLoading }) => ({
        data: data,
        error,
        isLoading,
      }),
      pollingInterval: 60000,
    },
  );
  const memoizedItems = useMemo(() => items, [items]);
  useGetUniqueTagsOnBoardQuery({
    projectId: project._id,
    boardId: issueBoardId,
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (issueBoard != null) {
      const tableTemplates = issueBoard.tableTemplates;
      if (issueBoard.defaultTemplate != null && tableTempalteWasSetOnStart === false) {
        const activeTableTemplate = tableTemplates.find((template) => template._id === issueBoard.defaultTemplate);
        if (activeTableTemplate != null) {
          setTableByTableTemplateCb(activeTableTemplate, true);
          setTableTempalteWasSetOnStart(true);
        }
      }
    }
  }, [issueBoard]);

  let history = useHistory();

  useEffect(() => {
    const indexOfId = history.location.search.indexOf("id");
    if (indexOfId !== -1) {
      const indexOf = history.location.search.indexOf("=");
      const id = history.location.search.substring(indexOf + 1);
      if (id?.length === 24 && openedModals?.find((item) => item.itemId === id) == null) {
        dispatch(
          addAppModalThunk({
            itemId: id,
            boardId: issueBoardId,
            projectId: project._id,
            app: AppModalEnums.ISSUE,
          }),
        );
      }
    }
  }, [history.location.search.indexOf("id") !== -1]);

  const setQueryStringWithoutPageReload = (qsValue: string) => {
    history.push(getIssueUrlPath(history.location.pathname, qsValue));
  };

  const setIssueIdUrlParam = (issue: IIssue) => {
    if (issue != null) {
      setQueryStringWithoutPageReload(issue._id);
    } else {
      history.push(`${history.location.pathname}`);
    }
  };

  const [hideTable, setHideTable] = useState<boolean>(false);
  const setTableByTableTemplateCb = async (tableTemplate: ITableTemplate, onLoad?: boolean) => {
    setHideTable(true);
    const searchParams = new URLSearchParams(history.location.search);
    if (!searchParams.has("id")) {
      searchParams.set("template", tableTemplate._id);
      history.push(`${history.location.pathname}?${searchParams.toString()}`);
    }
    if (onLoad !== true || (allColumnState[issueBoardId] == null && tableTemplate._id === issueBoard.defaultTemplate)) {
      //if template is clicked, or no column state is saved and default template is active, save the new column state
      const columnsToSave = tableTemplate.tableColumns.map((col) => ({ colId: col }));
      dispatch(updateColumnStateThunk(columnsToSave, issueBoardId));
    }
    dispatch(loadIssueTableTemplateThunk(tableTemplate, issueBoardId));
    setTimeout(() => {
      setHideTable(false);
    }, 500);
  };

  const isLoading = boardLoading || issuesLoading;

  const error = boardError || errorIssuesGet || updateIssueError;

  const selectedPnsIds = useMemo(() => {
    const id = pnsItems.find((item) => item.props.pnsId === selectedPnsFilterItem)?._id;
    return id != null ? [id] : [];
  }, [selectedPnsFilterItem, pnsItems]);
  const selectedGisMap = useAppSelector((state) => state.issueReducerNew.selectedGisMap);

  return (
    <>
      {isLoading === true && <MCLoader loading={isLoading} />}
      {error != null && <FailedAlert />}
      {isLoading === false && (
        <>
          <IssueTabActionBar
            issueBoard={issueBoard}
            items={items}
            setActiveTab={setActiveTab}
            setTableByTableTemplateCb={setTableByTableTemplateCb}
            gridRef={gridRef}
          />
          <VSpace />
          <IssueDbFilterBar issueBoard={issueBoard} />
          <div
            className="d-flex w-100"
            style={{
              flex: 1,
              minHeight: 0,
            }}
          >
            {pnsItems?.length > 0 && (
              <HierarchyTreeSelector
                isCollapsed
                style={{
                  minWidth: "200px",
                  maxWidth: "300px",
                  background: "#ededed",
                }}
                isVisible
                nodeList={pnsItems}
                idProp={"pnsId"}
                titleProp={"title"}
                onSelectionChange={(node) => dispatch(setSelectedPnsFilterItemInRedux(node[0]?.props?.pnsId))}
                selectedNodeIds={selectedPnsIds}
              />
            )}
            <>
              {activeView === "TABLE" && true && hideTable !== true && (
                <>
                  <div className="d-flex flex-column w-100">
                    <IssueTableAgGrid
                      loading={isLoading}
                      items={memoizedItems}
                      gridRef={gridRef}
                      columnProps={{
                        boardBuckets: issueBoard?.buckets ?? [],
                        setSelectedIssue: setIssueIdUrlParam,
                        types: issueBoard?.types.map((type) => type.name),
                        pnsItems: pnsItems,
                        members: project.members,
                        projectId: project._id,
                        issueBoardId: issueBoardId,
                        otherAttributesConfig: issueBoard?.otherAttributesConfig,
                        updateItemMembers: (itemId: string, members: IMinUser[]) => {
                          updateIssue({
                            projectId: project._id,
                            issueBoardId: issueBoardId,
                            issueId: itemId,
                            attr: "itemMembers",
                            value: members,
                            oldValue: "",
                          });
                        },
                      }}
                    />
                  </div>
                </>
              )}
              {activeView === "KANBAN" && <IssueKanban issues={items} issueBoard={issueBoard} project={project} />}
              {activeView === "GIS" && (
                <>
                  {selectedGisMap?.mapId != null && (
                    <IssuesGis
                      issues={items}
                      projectId={project._id}
                      mapId={selectedGisMap?.mapId}
                      boardId={issueBoardId}
                    />
                  )}
                  {project.arcgis?.groupId != null && projectHasADefaultArcgisMap(project) === false && <NoGisMap />}
                  {project.arcgis?.groupId == null && <NoGisGroup />}
                </>
              )}
              {activeView === "PNS" && <PnsApp />}
              {activeView === "GANTT" && (
                <ErrorBoundary>
                  {dateRange?.zoomLevel && (
                    <IssueGant issues={items.filter((item) => item.dates?.due > dateRange.startDate)} />
                  )}
                </ErrorBoundary>
              )}
              {activeView === "BIM" && (
                <div className="w-100 h-100 zstack">
                  <div className="customtextoverlay">
                    <div>
                      Trenger ditt prosjekt å koble saksbehandling til en BIM-modell?
                      <br />
                      Vi har nesten løsningen på plass! <br />
                      <div className="mt-4">
                        Kontakt oss på: <br />
                        <a href="mailto:mcapps@multiconsult.no">mcapps@multiconsult.no</a>
                      </div>
                    </div>
                  </div>
                  <div className="blurredoverlay"></div>
                  <div
                    className="h-100 w-100"
                    style={{
                      backgroundImage: 'url("/images/bimoverlay.png")',
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  ></div>
                </div>
              )}
              {activeView === IssueViewEnum.DASHBOARD && (
                <IssueDashboardMain projectId={project._id} issueBoardId={issueBoardId} />
              )}
            </>
          </div>
        </>
      )}
    </>
  );
};

export default IssueTab;
