import { ColDef, ICellRendererParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import {
  ICapitalLetter,
  IOtherAttributeConfig,
  IOtherAttributeConfigItemProps,
} from "mc-shared/zod/otherAttributesSchema";
import React, { useState } from "react";
import { Button } from "reactstrap";
import { IValueLabel } from "../../../../../types/global.types";
import { AG_GRID_LOCALE_NOR } from "../../../agGridLocale";
import { sortArrayByNumber } from "../../../app/utils";
import { VSpace } from "../../index";
import OtherAttributeItemConfigModal from "./OtherAttributesConfigFormModal";

export type IOtherAttributeConfigItemPropsWithId = { id: string } & IOtherAttributeConfigItemProps;

export const TYPE_OF_VALUE = {
  STRING: "Tekst",
  MULTILINESTRING: "Tekst (flerlinje)",
  NUMBER: "Tall",
  DATE: "Dato",
  BOOLEAN: "Ja/nei",
  DROPDOWN: "Velg fra listen",
  FORMULA: "Formel",
} as const;

const OtherAttributesConfig: React.FC<{
  otherAttributesConfig: IOtherAttributeConfig;
  onAdd: (otherAttributesConfigItemProps: IOtherAttributeConfigItemProps) => void;
  onEdit: (
    otherAttributeConfigItemProps: IOtherAttributeConfigItemProps,
    otherAttributeConfigKey: ICapitalLetter,
  ) => void;
  onUpdateOrder: (idsInOrder: ICapitalLetter[]) => void;
}> = ({ otherAttributesConfig, onAdd, onEdit, onUpdateOrder }) => {
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
  const [otherAttributesConfigItemPropsToEdit, setOtherAttributesConfigItemPropsToEdit] = useState<
    IOtherAttributeConfigItemPropsWithId | undefined
  >(undefined);
  const rows: IOtherAttributeConfigItemPropsWithId[] = Object.entries(otherAttributesConfig)?.map(([key, value]) => ({
    id: key,
    ...value,
  }));

  const columns: ColDef<IOtherAttributeConfigItemPropsWithId>[] = [
    { headerName: "Order", field: "order", hide: true },
    {
      headerName: "ID",
      width: 100,
      field: "id",
      rowDrag: true,
      sortable: false,
      cellRenderer: (params: ICellRendererParams<IOtherAttributeConfigItemPropsWithId>) => {
        return (
          <Button color="link" onClick={() => setOtherAttributesConfigItemPropsToEdit(params.data)}>
            {params.value}
          </Button>
        );
      },
    },
    {
      headerName: "Tittel",
      field: "label",
      sortable: false,
      cellRenderer: (params) => {
        return (
          <div className="d-flex align-items-center" style={{ marginLeft: "-15px" }}>
            <div
              className="mmi-dot mr-2"
              style={{
                backgroundColor: params.data?.labelColor,
              }}
            ></div>
            <span>{params.value}</span>
          </div>
        );
      },
    },
    {
      headerName: "Type verdi",
      field: "typeOfValue",
      sortable: false,
      cellRenderer: (params: ICellRendererParams<IOtherAttributeConfigItemPropsWithId>) => {
        return <span>{TYPE_OF_VALUE[params.value]}</span>;
      },
    },
    { headerName: "Seksjon", field: "section", sortable: false },
    { headerName: "Beskrivelse", field: "description", sortable: false, flex: 1 },
    {
      headerName: "Låst",
      field: "locked",
      width: 100,
      sortable: false,
      cellRenderer: (params: ICellRendererParams<IOtherAttributeConfigItemPropsWithId>) => {
        return (
          <span>
            {params.value ? (
              <i className="fa fa-check" style={{ color: "green" }} />
            ) : (
              <i className="fa fa-close" style={{ color: "red" }} />
            )}
          </span>
        );
      },
    },
    {
      headerName: "Er aktiv",
      field: "isActive",
      width: 100,
      sortable: false,
      cellRenderer: (params: ICellRendererParams<IOtherAttributeConfigItemPropsWithId>) => {
        return (
          <span>
            {params.value ? (
              <i className="fa fa-check" style={{ color: "green" }} />
            ) : (
              <i className="fa fa-close" style={{ color: "red" }} />
            )}
          </span>
        );
      },
    },
  ];

  const _onAdd = (otherAttributesConfigItemProps: IOtherAttributeConfigItemProps) => {
    onAdd(otherAttributesConfigItemProps);
    setAddModalOpen(false);
  };

  const _onEdit = (otherAttributesConfigItemProps: IOtherAttributeConfigItemProps) => {
    setOtherAttributesConfigItemPropsToEdit(null);
    onEdit(otherAttributesConfigItemProps, otherAttributesConfigItemPropsToEdit?.id as ICapitalLetter);
  };

  const onRowDragEnd = (event) => {
    const rows = event.api?.getRenderedNodes();
    const ids = rows?.map((row) => row.data.id);
    onUpdateOrder(ids as ICapitalLetter[]);
  };

  const existingSections = Array.from(new Set(Object.values(otherAttributesConfig ?? {}).map((item) => item.section)));
  return (
    <>
      {addModalOpen && (
        <OtherAttributeItemConfigModal
          orignalOtherAttributesConfigItemProps={otherAttributesConfigItemPropsToEdit}
          onClose={() => setAddModalOpen(false)}
          onSave={_onAdd}
          title="Leg til nytt attribut"
          addOrEdit="add"
          existingSections={existingSections}
        />
      )}
      {otherAttributesConfigItemPropsToEdit && (
        <OtherAttributeItemConfigModal
          orignalOtherAttributesConfigItemProps={otherAttributesConfigItemPropsToEdit}
          onClose={() => setOtherAttributesConfigItemPropsToEdit(null)}
          onSave={_onEdit}
          title={`Rediger attribut ${otherAttributesConfigItemPropsToEdit.id}`}
          addOrEdit="edit"
          existingSections={existingSections}
        />
      )}

      <div className="ag-theme-quartz h-100" id="myGrid">
        <AgGridReact
          domLayout="autoHeight"
          rowData={sortArrayByNumber(rows, "order")}
          columnDefs={columns}
          suppressClickEdit={true}
          animateRows={true}
          suppressContextMenu={true}
          localeText={AG_GRID_LOCALE_NOR}
          suppressMoveWhenRowDragging={true}
          suppressCellFocus={true}
          rowDragManaged={true}
          onRowDragEnd={onRowDragEnd}
          defaultColDef={{ suppressHeaderMenuButton: true }}
        />
      </div>
      <VSpace />
      <Button color="primary" onClick={() => setAddModalOpen(true)}>
        <i className="fa fa-plus fa-fw" />
      </Button>
    </>
  );
};

export default OtherAttributesConfig;

export const TYPE_OF_VALUE_OPTIONS: IValueLabel[] = Object.keys(TYPE_OF_VALUE).map((key) => ({
  value: key,
  label: TYPE_OF_VALUE[key],
}));
