import { IAccessPopulated } from "mc-shared/zod/accessSchema";
import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { IBoard } from "../../../../../../types/global.types";
import { FailedAlert } from "../../../../components";
import { AccessComp } from "../../../../components/AccessComp/AccessComp";
import { useUpdateIssueBoardAccessMutation } from "../IssueBoardRTK";
import { useAppSelector } from "../../../../hooks";

const IssueAccessSettings: React.FC<{ issueBoard: IBoard }> = ({ issueBoard }) => {
  const [updateAccess, { error: updateAccessError }] = useUpdateIssueBoardAccessMutation();

  const members = useAppSelector((state) => state.adminReducer.project.members);

  const users = members.reduce((acc, member) => {
    if (member.user != null) {
      acc.push(member.user);
    }
    return acc;
  }, []);

  const [editMode, setEditMode] = React.useState(false);
  return (
    <Card>
      <CardBody>
        <CardTitle tag="h3">Tilgangsstyring</CardTitle>
        {updateAccessError != null && <FailedAlert />}
        <AccessComp
          access={issueBoard.access as IAccessPopulated}
          isEditable
          updateAccess={(access) =>
            updateAccess({
              projectId: issueBoard.project,
              issueBoardId: issueBoard._id,
              access: {
                level: access.level,
                groups: access.groups,
                users: access.users.map((user) => user._id),
              },
            })
          }
          editMode={editMode}
          allUsers={users}
          editCb={(bool) => setEditMode(bool)}
        />
      </CardBody>
    </Card>
  );
};

export default IssueAccessSettings;
