export const createSelects = (stateItem) => {
  return Object.keys(stateItem.options).reduce((acc, item) => {
    if (stateItem.options[item].isSelected) {
      acc.push({
        name: stateItem.options[item].name,
      });
    }
    return acc;
  }, []);
};
