export const SET_EDIT_MODE = "projectDashboardReducer/SET_EDIT_MODE";
export const SET_ACTIVE_LAYOUT = "projectDashboardReducer/SET_ACTIVE_LAYOUT";
export const SET_ALL_LAYOUTS = "projectDashboardReducer/SET_ALL_LAYOUTS";

export const dashboardInfo = `

###Opprette og redigere oppsett

For å opprette et nytt oppsett, klikk på plussikonet. I vinduet som åpnes kan du velge å kopiere fra et annet oppsett eller la det stå tomt. Etter å ha fylt inn en tittel og lagret, kan du legge til eller fjerne widgets ved å bruke rullegardinmenyen.
For å endre oppsettet, klikk og dra en widget. For å justere størrelsen, klikk og dra markøren i nedre høyre hjørne, eller nederste eller høyre kant. Innholdet bør endre størrelse i henhold til endringene dine, hvis ikke, prøv å oppdatere siden etter at du har lagret oppsettet. Klikk på den grønne knappen for å lagre, eller avbryt ved å klikke på X-knappen.
For å redigere et oppsett, velg et oppsett, klikk på blyantknappen og følg samme prosedyre.
<pre>




</pre>

###Prosjektadministratorer og oppsett

Hvis du er prosjektadministrator, vil det lagrede oppsettet bli lagt til prosjektet. Ellers vil oppsettet ditt bare være synlig for deg.

Når et prosjekt er opprettet, gir MCApps noen grunnleggende oppsett. Prosjektadministratorer kan velge enten et generert eller tilpasset oppsett som standard for et prosjekt ved å følge koblingen "velg standardoppsett".
<pre>




</pre>

###Widgetinnstillinger

Widgets kan inneholde tilleggsinnstillinger i sine respektive vinduer. Klikk på pilknappen for å åpne innholdet i et større vindu, som kan lukkes ved å klikke utenfor vinduet.
Klikk på de tre prikkene for å åpne widgetspesifikke alternativer, for eksempel å legge til elementer eller endre filtre.
<pre>




</pre>

`;
