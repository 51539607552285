import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { IIssue, IRisiko } from "../../../../../types/global.types";
import ExcelExport from "../../../components/ExcelExport/ExcelExport";
import { patchFilterFactory } from "../../../components/columnGlobal";
import { AppModalEnums } from "../../../frontendConstants";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getDefaultBootstrapTablePaginationOptions } from "../../utils";
import { formatShortDate } from "../admin/selectors";
import { CustomSearchInput } from "../checklist/ChecklistBoardSelector";
import { useGetRisikoConnectedIssuesQuery } from "../issuesApp/issueRTK";
import { getTiltakTableColumns } from "./TiltakTableColumns";
import { addAppModalThunk } from "../../../global/globalSlice";

const TiltakPageNew: React.FC = () => {
  const [filtredIssues, setFiltredIssues] = useState<IIssue[]>([]);
  const [columns, setColumns] = useState(null);

  const project = useAppSelector((state) => state.adminReducer.project);
  const activeBoard = useAppSelector((state) => state.risikoReducer.activeBoard);

  const { data: issues, isLoading } = useGetRisikoConnectedIssuesQuery({
    projectId: project._id,
    boardId: activeBoard._id,
  });
  React.useEffect(() => {
    if (issues != null && isLoading === false) {
      init();
    }
  }, [issues]);

  const dispatch = useAppDispatch();

  const init = async () => {
    const columns = getTiltakTableColumns(false, project._id, project.members, (_id, issueBoardId) =>
      dispatch(
        addAppModalThunk({
          itemId: _id,
          boardId: issueBoardId,
          projectId: project._id,
          app: AppModalEnums.ISSUE,
        }),
      ),
    );
    setColumns(columns);
    setFiltredIssues(issues);
  };

  const factory = patchFilterFactory(filterFactory, (data) => {
    setFiltredIssues((prevData) => {
      if (JSON.stringify(prevData) !== JSON.stringify(data)) {
        return data;
      }
      return prevData;
    });
  });

  return (
    <>
      {issues != null && columns != null && (
        <div className="d-flex flex-column w-100 h-100 overflow-auto mb-5">
          <ToolkitProvider keyField="_id" data={issues} columns={columns || []} columnToggle search>
            {(props) => {
              return (
                <>
                  <div className="d-flex m-2">
                    <CustomSearchInput {...props.searchProps} />
                    <div className="d-flex flex-grow-1" />
                    <ExcelExport
                      columns={columns.filter((col, index) => index > 0)}
                      data={filtredIssues.map((issue) => {
                        return {
                          ...issue,
                          dates: {
                            plannedStart: formatShortDate(issue.dates?.plannedStart) || "",
                            due: formatShortDate(issue.dates?.due) || "",
                            completed: formatShortDate(issue.dates?.completed) || "",
                          },
                          connectedRisikos: (issue.connectedRisikos as IRisiko[])?.reduce((acc, curr) => {
                            if (typeof curr === "string") {
                              return acc + curr;
                            }

                            return acc + `${curr.id}: ${curr.title};`;
                          }, ""),
                        };
                      })}
                      filePrefix="risiko_tiltak"
                    />
                  </div>
                  <div className="d-lex h-100 overflow-auto">
                    <BootstrapTable
                      {...props.baseProps}
                      keyField="_id"
                      bootstrap4
                      classes="table-sm table-responsive"
                      wrapperClasses="smalltable"
                      striped
                      bordered={false}
                      pagination={getDefaultBootstrapTablePaginationOptions(issues?.length)}
                      columns={columns}
                      filter={factory()}
                      defaultSorted={[
                        {
                          dataField: "createdAt",
                          order: "desc",
                        },
                      ]}
                    />
                  </div>
                </>
              );
            }}
          </ToolkitProvider>
        </div>
      )}
    </>
  );
};

export default TiltakPageNew;
