import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip } from "reactstrap";
import { IMinUser, IProject } from "../../../../../../../types/global.types";
import { IMCBIMAPI } from "../../../../../../../types/mcbim.types";
import { IMcBimMongo } from "../../../../../../../types/mcbimMono.types";
import { FailedAlert, MCLoader } from "../../../../../components";
import ConfirmDialog from "../../../../../components/ConfirmDialog";
import {
  COL_WIDTHS,
  createHeaderStyle,
  expandColumnCaret,
  expandHeaderColumn,
  headerSortAndFilterFormatter,
  sortCaret,
} from "../../../../../components/columnGlobal";
import { useAppSelector } from "../../../../../hooks";
import { IBootstrapTableColumnConfig } from "../../../../../types";
import { doGetMCBimMongoFilesStats } from "../McBimMongoAPI";
import { useRemoveMcFileDataMutation } from "../McBimRTK";
import { isMcBimAdmin } from "../mcBimSlice";
import FileVersions from "./McBimFileVersions";

const McBimMongoExtractedFiles: React.FC<{ mcBimProject: IMCBIMAPI.Project; project: IProject }> = ({
  mcBimProject,
  project,
}) => {
  const user = useAppSelector((state) => state.globalReducer.user);
  const userIsMcBimAdmin = isMcBimAdmin(project.mcbim.admins as IMinUser[], user?._id);

  const [filesInMCBimMongo, setFilesInMCBimMongo] = useState<IMcBimMongo.FileStats[]>([]);
  const [nrOfDeletedDocuments, setNrOfDeletedDocuments] = useState<number>(null);
  const filesInMCBimProject = mcBimProject.ifcFiles;

  const [removeFiles, { isLoading: removeFilesLoading, error: removeFilesError }] = useRemoveMcFileDataMutation();

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const _files = await doGetMCBimMongoFilesStats(project._id);
    _files.forEach((file) => {
      file.isActiveInMCBimProject = false;
      const activeFile = filesInMCBimProject.find((activeFile) => {
        return file._id.fileName === activeFile.fileName && file._id.fileStorageId === activeFile.fileStorageId;
      });
      if (activeFile) {
        file.isActiveInMCBimProject = true;
      }
    });
    setFilesInMCBimMongo(_files);
  };

  const removeAllFileVersions = async (fileStorageId: string) => {
    const resp = await removeFiles({ projectId: project._id, fileStorageId }).unwrap();
    setFilesInMCBimMongo(filesInMCBimMongo.filter((f) => f._id.fileStorageId !== fileStorageId));
    setNrOfDeletedDocuments(resp.deletedDocuments);
    init();
  };

  const columns: IBootstrapTableColumnConfig[] = [
    {
      dataField: "",
      text: "",
      headerStyle: createHeaderStyle(COL_WIDTHS.BUTTON),
      formatter: (cell: string, row) => {
        return (
          <ConfirmDialog
            title="Slett fil?"
            message={`Er du sikker på at du vil slette alle versjoner av ${row._id.fileName}?`}
            confirmCb={() => removeAllFileVersions(row._id.fileStorageId)}
          >
            <Button disabled={userIsMcBimAdmin === false}>
              <i className="fa fa-trash" />
            </Button>
          </ConfirmDialog>
        );
      },
    },
    {
      dataField: "_id.fileStorageId",
      text: "fileStorageId",
      hidden: true,
    },
    {
      dataField: "_id.fileName",
      text: "Filnavn",
      sort: true,
      sortCaret: sortCaret,
      headerFormatter: headerSortAndFilterFormatter,
    },
    {
      dataField: "count",
      text: "Antall bimobjekter",
      sort: true,
      //sortCaret: sortCaret,
      //TODO: format same as filename, header not highlighted and sort caret now showing
      headerFormatter: (column) => {
        return (
          <div id={"Tooltip-" + column.dataField}>
            {column.text}
            <i className="fa fa-info-circle fa-fw text-info" />
            <UncontrolledTooltip target={"Tooltip-" + column.dataField}>
              Antall bimobjekter i siste versjon av filen
            </UncontrolledTooltip>
          </div>
        );
      },
    },
    {
      dataField: "isActiveInMCBimProject",
      text: "Aktiv",
      sort: true,
      //TODO: format same as filename, header not highlighted and sort caret now showing
      headerFormatter: (column) => {
        return (
          <div id={"Tooltip-" + column.dataField}>
            {column.text}
            <i className="fa fa-info-circle fa-fw text-info" />
            <UncontrolledTooltip target={"Tooltip-" + column.dataField}>
              Sjekk om fil i databasen ligger under aktive filer i prosjektet
            </UncontrolledTooltip>
          </div>
        );
      },

      formatter: (cell: string, row) => {
        const mode = row.isActiveInMCBimProject;
        return (
          <div
            style={{
              display: "grid",
              placeItems: "center",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              backgroundColor: `${row.isActiveInMCBimProject ? "#00a76d" : "#dc3545"}`,
            }}
          >
            <i className={`fa ${row.isActiveInMCBimProject ? "fa-check" : "fa-close"}`} style={{ color: "#fff" }} />
          </div>
        );
      },
    },
  ];

  const expandRow = {
    renderer: (row) => {
      return <FileVersions project={project} file={row._id} />;
    },
    expandColumnRenderer: expandColumnCaret,
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: expandHeaderColumn,
  };

  return (
    <div className="h-100 w-100 p-2" style={{ overflow: "auto" }}>
      {removeFilesLoading === true && <MCLoader loading={removeFilesLoading} />}
      {removeFilesError != null && <FailedAlert />}
      {nrOfDeletedDocuments && (
        <InfoDialog deletedDocuments={nrOfDeletedDocuments} toggle={() => setNrOfDeletedDocuments(null)} />
      )}
      {filesInMCBimMongo?.length > 0 && (
        <div className="border overflow-auto">
          <BootstrapTable
            expandRow={expandRow}
            defaultSorted={[
              {
                //consider changing to _id.fileName, order: 'asc'
                dataField: "_id",
                order: "desc",
              },
            ]}
            striped
            bootstrap4
            bordered={false}
            keyField="_id"
            data={filesInMCBimMongo || []}
            wrapperClasses="smalltable"
            columns={columns}
          />
        </div>
      )}
      {filesInMCBimMongo.length === 0 && removeFilesLoading === false && <div>Ingen filer</div>}
    </div>
  );
};

export default McBimMongoExtractedFiles;

export const InfoDialog: React.FC<{ deletedDocuments: number; toggle: () => void }> = ({
  deletedDocuments,
  toggle,
}) => {
  return (
    <Modal isOpen={true} fade={false}>
      <ModalHeader toggle={toggle}>Slettet</ModalHeader>
      <ModalBody>{deletedDocuments} dokumenter ble slettet</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle}>
          Ok
        </Button>
      </ModalFooter>
    </Modal>
  );
};
