import React, { useEffect, useState } from "react";
import { Badge } from "reactstrap";
import { IMultiOptionsColors } from "../../../types/global.types";

const OptionBadge: React.FC<{
  options: IMultiOptionsColors[];
  activeOption: string;
  asBadge?: boolean;
  noWrap?: boolean;
}> = ({ options, activeOption, asBadge = true, noWrap = false }) => {
  const [option, setOption] = useState<IMultiOptionsColors | null>({
    key: "NONE",
    displayName: "-",
    color: "white",
  });

  useEffect(() => {
    const opt = options?.find((_option) => _option.key === activeOption);

    if (opt != null) {
      setOption(opt);
    }
  }, [activeOption]);

  return asBadge === true ? (
    <Badge style={{ padding: "8px 12px" }} pill className="d-flex greybadge">
      <div className="statusdot" style={{ backgroundColor: option.color }}></div>
      <div style={{ color: "#58595B", fontSize: "12px" }}>{option.displayName.toUpperCase()}</div>
    </Badge>
  ) : (
    <div className="d-flex align-items-center">
      <div className="statusdot" style={{ backgroundColor: option.color }}></div>
      <div style={{ color: "#58595B", fontSize: "12px", whiteSpace: noWrap === true ? "nowrap" : "normal" }}>
        {option.displayName.toUpperCase()}
      </div>
    </div>
  );
};

export default OptionBadge;
