import { IconToggle, McDropdownBase } from "..";
import { taskOrIssueENUM } from "../../../../types/global.types";
import { STANDARD_COLORS } from "../../app/globalConstants";
import { IMcDropdownBase } from "../McDropdownBase/McDropdownBase";

const CopyIssueButton: React.FC<
  {
    issueId: string;
    taskOrIssue: taskOrIssueENUM;
    copyAndSet: (id: string, includeTasks: boolean) => void;
  } & Omit<IMcDropdownBase, "children">
> = ({ issueId, taskOrIssue, copyAndSet, ...props }) => {
  const copyIssue = async (includeTasks: boolean) => {
    await copyAndSet(issueId, includeTasks);
  };

  return (
    <McDropdownBase
      customTrigger={
        <IconToggle
          activeIcon="files-o"
          inactiveIcon="files-o"
          toggled={false}
          activeColor={STANDARD_COLORS.orange}
          onClick={() => {}}
        />
      }
      className={"dropdown-row-end"}
      {...props}
    >
      {taskOrIssue === "ISSUE" ? (
        <>
          <McDropdownBase.Option key={1} onClick={() => copyIssue(true)}>
            Lag kopi med aksjoner
          </McDropdownBase.Option>
          <McDropdownBase.Option key={0} onClick={() => copyIssue(false)}>
            Lag kopi uten aksjoner
          </McDropdownBase.Option>
        </>
      ) : (
        <McDropdownBase.Option key={0} onClick={() => copyIssue(false)}>
          Lag kopi
        </McDropdownBase.Option>
      )}
    </McDropdownBase>
  );
};
export default CopyIssueButton;
