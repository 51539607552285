import { IGKEUGMPTabell, IGKISOTabell, IGKMaalepunkterTabell } from "mc-shared/zod/report/GK/gkReportSchema";
import { IValueLabel } from "../../../../../../../../../types/global.types";

export const EU_GMP_KLASSER: IValueLabel[] = [
  {
    label: "A",
    value: "gradeA",
  },
  {
    label: "B",
    value: "gradeB",
  },
  {
    label: "C",
    value: "gradeC",
  },
  {
    label: "D",
    value: "gradeD",
  },
];

export const ISO_KLASSER: IValueLabel[] = [
  {
    label: "1",
    value: "ISO_1",
  },
  {
    label: "2",
    value: "ISO_2",
  },
  {
    label: "3",
    value: "ISO_3",
  },
  {
    label: "4",
    value: "ISO_4",
  },
  {
    label: "5",
    value: "ISO_5",
  },
  {
    label: "6",
    value: "ISO_6",
  },
  {
    label: "7",
    value: "ISO_7",
  },
  {
    label: "8",
    value: "ISO_8",
  },
  {
    label: "9g",
    value: "ISO_9g",
  },
];

export const EU_GMP_TABELL: IGKEUGMPTabell = {
  size05: {
    atRest: {
      gradeA: 3520,
      gradeB: 3520,
      gradeC: 352000,
      gradeD: 3520000,
    },
    inOperation: {
      gradeA: 3520,
      gradeB: 352000,
      gradeC: 3520000,
      gradeD: "Ikke forhåndsbestemt",
    },
  },
  size5: {
    atRest: {
      gradeA: "Ikke presisert",
      gradeB: "Ikke presisert",
      gradeC: 2930,
      gradeD: 29300,
    },
    inOperation: {
      gradeA: "Ikke presisert",
      gradeB: 2930,
      gradeC: 29300,
      gradeD: "Ikke forhåndsbestemt",
    },
  },
};

export const ISO_TABELL: IGKISOTabell = {
  ISO_1: {
    size01: 10,
    size02: "d",
    size03: "d",
    size05: "d",
    size1: "d",
    size5: "e",
  },
  ISO_2: {
    size01: 100,
    size02: 24,
    size03: 10,
    size05: "d",
    size1: "d",
    size5: "e",
  },
  ISO_3: {
    size01: 1000,
    size02: 237,
    size03: 102,
    size05: 35,
    size1: "d",
    size5: "e",
  },
  ISO_4: {
    size01: 10000,
    size02: 2370,
    size03: 1020,
    size05: 352,
    size1: 83,
    size5: "e",
  },
  ISO_5: {
    size01: 100000,
    size02: 23700,
    size03: 10200,
    size05: 3520,
    size1: 832,
    size5: "d,e,f",
  },
  ISO_6: {
    size01: 1000000,
    size02: 237000,
    size03: 102000,
    size05: 35200,
    size1: 8320,
    size5: 293,
  },
  ISO_7: {
    size01: "c",
    size02: "c",
    size03: "c",
    size05: 352000,
    size1: 83200,
    size5: 2930,
  },
  ISO_8: {
    size01: "c",
    size02: "c",
    size03: "c",
    size05: 3520000,
    size1: 832000,
    size5: 29300,
  },
  ISO_9g: {
    size01: "c",
    size02: "c",
    size03: "c",
    size05: 35200000,
    size1: 8320000,
    size5: 293000,
  },
};

export const MAALEPUNKTER_TABELL: IGKMaalepunkterTabell[] = [
  {
    areal: 2,
    maalepunkter: 1,
  },
  {
    areal: 4,
    maalepunkter: 2,
  },
  {
    areal: 6,
    maalepunkter: 3,
  },
  {
    areal: 8,
    maalepunkter: 4,
  },
  {
    areal: 10,
    maalepunkter: 5,
  },
  {
    areal: 24,
    maalepunkter: 6,
  },
  {
    areal: 28,
    maalepunkter: 7,
  },
  {
    areal: 32,
    maalepunkter: 8,
  },
  {
    areal: 36,
    maalepunkter: 9,
  },
  {
    areal: 52,
    maalepunkter: 10,
  },
  {
    areal: 56,
    maalepunkter: 11,
  },
  {
    areal: 64,
    maalepunkter: 12,
  },
  {
    areal: 68,
    maalepunkter: 13,
  },
  {
    areal: 72,
    maalepunkter: 14,
  },
  {
    areal: 76,
    maalepunkter: 15,
  },
  {
    areal: 104,
    maalepunkter: 16,
  },
  {
    areal: 108,
    maalepunkter: 17,
  },
  {
    areal: 116,
    maalepunkter: 18,
  },
  {
    areal: 148,
    maalepunkter: 19,
  },
  {
    areal: 156,
    maalepunkter: 20,
  },
  {
    areal: 192,
    maalepunkter: 21,
  },
  {
    areal: 232,
    maalepunkter: 22,
  },
  {
    areal: 276,
    maalepunkter: 23,
  },
  {
    areal: 352,
    maalepunkter: 24,
  },
  {
    areal: 436,
    maalepunkter: 25,
  },
  {
    areal: 636,
    maalepunkter: 26,
  },
  {
    areal: 1000,
    maalepunkter: 27,
  },
];
