import React from "react";
import { DropdownSelectorDeprecated } from "../";
import VSpace from "../VSpace";
import { ISubjectTypesWithOptions } from "./types";

const SubjectTypeFilters: React.FC<{
  subjectTypesWithOptions: ISubjectTypesWithOptions[];
  activeSubjectType: string;
  activeOption: string;
  setActiveSubjectType: (subjetType: string) => void;
  setActiveOption: (option: string) => void;
}> = ({ subjectTypesWithOptions, activeSubjectType, activeOption, setActiveSubjectType, setActiveOption }) => {
  const setSubjectTypeAndResetActiveOption = (subjectType: string) => {
    setActiveSubjectType(subjectType);
    setActiveOption(null);
  };

  return (
    <>
      <DropdownSelectorDeprecated
        outline={activeSubjectType != null}
        size="sm"
        color={activeSubjectType == null ? "primary" : "secondary"}
        setItem={(val) => setSubjectTypeAndResetActiveOption(val.label)}
        selectText={activeSubjectType == null ? "Velg typ" : activeSubjectType}
        displayKey="label"
        activeItem={setActiveSubjectType}
        items={subjectTypesWithOptions?.map((subjectTypeAndOptions, index) => {
          return {
            value: index,
            label: subjectTypeAndOptions.subjectType,
          };
        })}
      />
      <VSpace />
      {activeSubjectType != null && (
        <DropdownSelectorDeprecated
          outline={activeOption != null}
          size="sm"
          color={activeOption == null ? "primary" : "secondary"}
          setItem={(val) => setActiveOption(val.label)}
          selectText={activeOption == null ? "Velg alternativ" : activeOption}
          displayKey="label"
          activeItem={setActiveOption}
          items={subjectTypesWithOptions
            .find((item) => item.subjectType === activeSubjectType)
            .options.map((option, index) => {
              return {
                value: `o${index}`,
                label: option,
              };
            })}
        />
      )}
    </>
  );
};

export default SubjectTypeFilters;
