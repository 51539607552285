import { IGKMaalingAvLufthastighetIRommetResultater, IGKRapport } from "mc-shared/zod/report/GK/gkReportSchema";
import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../../types/global.types";
import { McInput } from "../../../../../../components";

const MaalingAvLufthastighetIRommet: React.FC<{
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
  data: IGKMaalingAvLufthastighetIRommetResultater;
}> = ({ data, onUpdateEditData }) => {
  const updateMaaleresultater = (resultat, i) => {
    const maaleresultater = [...data.inndata.maaleresultater];
    maaleresultater[i] = resultat;
    onUpdateEditData("testResultater.maalingAvLufthastighetIRommet.inndata.maaleresultater", maaleresultater);
  };

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Måling av lufthastighet i rommet</CardTitle>
        <div className="d-flex flex-column">
          <b>Fyll inn måleresultater &#40;m/s&#41;:</b>
          {data.inndata.maaleresultater.map((a, i) => (
            <McInput
              className="mb-1 w-25"
              key={i}
              value={a}
              normalizeNumbers={true}
              type="text"
              onBlur={(e) => updateMaaleresultater(Number(e.target.value), i)}
            />
          ))}
        </div>
        <div className="w-50 mt-2">
          <b>Kommentar:</b>
          <McInput
            value={data.inndata.kommentar}
            onChange={(e) =>
              onUpdateEditData("testResultater.maalingAvLufthastighetIRommet.inndata.kommentar", e.target.value)
            }
            type="textarea"
            dynamicHeight={true}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default MaalingAvLufthastighetIRommet;
