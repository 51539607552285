import React from "react";
import { Button, ButtonGroup, Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";
import { IBatchjob } from "../../../../../../../types/batchjob.types";
import { IBim360FolderContent } from "../../../../../../../types/global.types";
import { VSpace } from "../../../../../components";
import BIM360AgFolderAndFileExplorer from "../../../../../components/Bim360AgFolderAndFileExplorer/BIM360AgFolderAndFileExplorer";
import CheckOrWarn from "../../../../../components/CheckOrWarn";
import RevitFilesExportTable from "../../../../../components/RevitFilesExportTable/RevitFilesExportTable";
import { useAppSelector } from "../../../../../hooks";

const Bim360RevitFileSelectorCard: React.FC<{
  projectId: string;
  bim360ProjectId: string;
  updateFiles: (files: IBatchjob.IBim360RevitExportFile[]) => void;
  updateRevitViewValue: (fileId: string, revitViewValue: string) => void;
  isValid: boolean;
}> = ({ projectId, bim360ProjectId, updateFiles, updateRevitViewValue, isValid }) => {
  enum TabsEnum {
    SELECTED_FILES = "Valgte filer",
    SELECT_FILES_FROM_BIM360 = "Velg filer fra BIM360",
  }
  const [selectedTab, setSelectedTab] = React.useState<TabsEnum>(TabsEnum.SELECTED_FILES);

  let bim360RevitExportFiles = useAppSelector(
    (state) => state.batchJobReducer.activeBatchJob?.bim360Settings?.bim360RevitExportFiles,
  );

  const updateFilesFromBim360 = (filesFromBim360Editor: IBim360FolderContent[]) => {
    const filesToKeep = bim360RevitExportFiles.filter(
      (revitFile) =>
        filesFromBim360Editor.findIndex((_f) => _f.urn === revitFile.bim360Urn || _f.id === revitFile.bim360Urn) !== -1,
    );
    const newFiles = filesFromBim360Editor.filter(
      (fileFromBim360) =>
        bim360RevitExportFiles.findIndex(
          (revitFile) => revitFile.bim360Urn === fileFromBim360.urn || revitFile.bim360Urn === fileFromBim360.id,
        ) === -1,
    );

    const newFilesFormatted: IBatchjob.IBim360RevitExportFile[] = newFiles.map((bim360File) => {
      return {
        bim360Urn: bim360File.urn || bim360File.id,
        name: bim360File.name,
        revitView: {
          value: "",
          match: "EXACT",
        },
        useMultipleFilesPerViewExport: false,
      };
    });

    updateFiles([...filesToKeep, ...newFilesFormatted]);

    setTimeout(() => {
      setSelectedTab(TabsEnum.SELECTED_FILES);
    }, 100);
  };

  return (
    <Card className="d-flex" style={{ height: "600px" }}>
      <CardBody className="d-flex flex-column">
        <CardTitle tag="h3">
          <CheckOrWarn bool={isValid} />
          Revit-filer
        </CardTitle>
        <CardSubtitle tag="h6" className="mb-2 text-muted">
          Velg revit-filer fra BIM360 for å eksportere til IFC
          <div className="d-flex">
            <h5 style={{ background: "#fff3cd", color: "#857e6b" }}>
              (Advarsel! IFC-eksport bør kun anvendes med filer lagret i Revit 2024 eller nyere versjoner)
            </h5>
          </div>
        </CardSubtitle>
        <div className="d-flex flex-column h-100 overflow-auto w-100">
          <ButtonGroup className="w-25">
            <Button
              onClick={() => setSelectedTab(TabsEnum.SELECTED_FILES)}
              active={TabsEnum.SELECTED_FILES === selectedTab}
            >
              Valgte filer
            </Button>
            <Button
              onClick={() => setSelectedTab(TabsEnum.SELECT_FILES_FROM_BIM360)}
              active={TabsEnum.SELECT_FILES_FROM_BIM360 === selectedTab}
            >
              Velg filer fra BIM360
            </Button>
          </ButtonGroup>
          <VSpace />
          <div className="h-100 overflow-auto">
            {selectedTab === TabsEnum.SELECT_FILES_FROM_BIM360 && (
              <BIM360AgFolderAndFileExplorer
                filterExtension={{
                  label: "Revit",
                  value: ".rvt",
                }}
                projectId={projectId}
                bim360ProjectId={bim360ProjectId}
                includeSelectedFilesHandler
                onSave={(files) => updateFilesFromBim360(files)}
                selectedAgFiles={bim360RevitExportFiles?.map((file) => {
                  return {
                    id: file.bim360Urn,
                    name: file.name,
                  };
                })}
                onAbort={() => setSelectedTab(TabsEnum.SELECTED_FILES)}
              />
            )}
            {selectedTab === TabsEnum.SELECTED_FILES && (
              <RevitFilesExportTable
                includeRevitView={true}
                revitFilesToExport={bim360RevitExportFiles}
                updateFile={(fileId, revitViewValue) => updateRevitViewValue(fileId, revitViewValue)}
              />
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default Bim360RevitFileSelectorCard;
