import React from "react";
import { useHistory } from "react-router-dom";
import { FailedAlert, MCLoader, VSpace } from "../../../../components";
import { useAppSelector } from "../../../../hooks";
import { useGetSingleIssueBoardQuery } from "../IssueBoardRTK";
import { getIssueBoardIdFromUrl } from "../issueUtils";
import IssueAccessSettings from "./IssueAccessSettings";
import IssueBoardBuckets from "./IssueBoardBuckets";
import IssueBoardTypes from "./IssueBoardTypes";
import IssueColumnSettings from "./IssueColumnSettings";
import IssueCommonSettings from "./IssueCommonSettings";
import IssueMalerSettings from "./IssueMalerSettings";
import IssueOtherAttributesConfig from "./IssueOtherAttributesConfig";
import IssuePredefinedTags from "./IssuePredefinedTags";
import MoveIssueBoardToOtherProject from "./MoveIssueBoardToOtherProject";
import IssueRisiko from "./Risiko/IssueRisiko";

const IssueSettingsTab: React.FC = () => {
  const history = useHistory();
  const issueBoardId = getIssueBoardIdFromUrl(history.location.pathname);

  const project = useAppSelector((state) => state.adminReducer.project);

  const {
    data: issueBoard,
    isLoading: boardLoading,
    //isFetching: boardFetching,
    error,
  } = useGetSingleIssueBoardQuery({
    projectId: project._id,
    issueBoardId: issueBoardId,
  });

  const isDev = process.env.NODE_ENV === "development";

  return (
    <div className="bg-light p-3 flex-child-container overflow-auto">
      {error != null && <FailedAlert />}
      {boardLoading === true && <MCLoader loading={boardLoading} />}
      {issueBoard != null && (
        <>
          <IssueAccessSettings issueBoard={issueBoard} />
          <VSpace />
          <IssueCommonSettings issueBoard={issueBoard} />
          <VSpace />
          <IssueBoardBuckets issueBoard={issueBoard} />
          <VSpace />
          <IssueBoardTypes issueBoard={issueBoard} />
          <VSpace />
          <IssueOtherAttributesConfig issueBoard={issueBoard} />
          <VSpace />
          <IssueMalerSettings issueBoard={issueBoard} />
          <VSpace />
          <IssueColumnSettings issueBoard={issueBoard} />
          <VSpace />
          <IssuePredefinedTags issueBoard={issueBoard} />
          <VSpace />
          <MoveIssueBoardToOtherProject issueBoard={issueBoard} />
          <VSpace />
          <IssueRisiko issueBoard={issueBoard} />
          <div style={{ height: "100px" }} />
        </>
      )}
    </div>
  );
};

export default IssueSettingsTab;
