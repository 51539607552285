import { IGKLufthastighetskontrollResultater, IGKRapport } from "mc-shared/zod/report/GK/gkReportSchema";
import React from "react";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../../../types/global.types";
import { HSpace, McInput } from "../../../../../../../components";
import ReportDocxImageContainer from "../../../../ReportBoard/ReportDocxImageContainer";
import Luftventil from "../Luftventil";

const Lufthastighetskontroll: React.FC<{
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
  data: IGKLufthastighetskontrollResultater;
}> = ({ data, onUpdateEditData }) => {
  const addNewRow = () => {
    const newMaaleresultaterArray = {
      ventilnummer: null,
      maaleomraadeAreal: null,
      maaleresultater: [],
      beregninger: {
        minimumAntallRuterIRutenett: null,
        gjennomsnittligHastighet: null,
        varians: null,
        standardAvvik: null,
        lufthastighetUniformitet: null,
      },
    };

    const updatedMaaleresultaterArray = [...data.inndata.resultaterPerVentil, newMaaleresultaterArray];

    onUpdateEditData("testResultater.lufthastighetskontroll.inndata.resultaterPerVentil", updatedMaaleresultaterArray);

    return updatedMaaleresultaterArray;
  };

  const removeLastRow = () => {
    const updatedMaaleresultaterArray = [...data.inndata.resultaterPerVentil];

    if (updatedMaaleresultaterArray.length > 0) {
      updatedMaaleresultaterArray.pop(); // Remove the last element
      onUpdateEditData(
        "testResultater.lufthastighetskontroll.inndata.resultaterPerVentil",
        updatedMaaleresultaterArray,
      );
    }
  };

  const onUpdateVentilData = (updatedData: any, ventilIndex: number) => {
    const resultaterPerVentil = [...data.inndata.resultaterPerVentil];
    resultaterPerVentil[ventilIndex] = updatedData;
    onUpdateEditData("testResultater.lufthastighetskontroll.inndata.resultaterPerVentil", resultaterPerVentil);
  };

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Lufthastighetskontroll</CardTitle>
        <div className="d-flex flex-column mb-4">
          <b>Legg til og trekk fra ventiler:</b>
          <div className="d-flex">
            <Button color="success" onClick={addNewRow}>
              Legg til ventil
            </Button>
            <HSpace />
            <Button color="danger" onClick={removeLastRow} disabled={data.inndata?.resultaterPerVentil?.length < 1}>
              Fjern siste ventil
            </Button>
          </div>
        </div>
        {data.inndata.resultaterPerVentil != null && data.inndata.resultaterPerVentil?.length > 0 && (
          <>
            {data.inndata.resultaterPerVentil.map((resultat, index) => (
              <div className="mb-4" key={index}>
                <b>Luftventil {index + 1}</b>
                <Luftventil onUpdateVentilData={onUpdateVentilData} data={resultat} ventilIndex={index} />
              </div>
            ))}
          </>
        )}
        <div className="w-50 mt-4">
          <div>
            <ReportDocxImageContainer docxImageTag="lufthastighetskontroll" title="Målepunkter i rommet" />
          </div>
          <b>Kommentar:</b>
          <McInput
            value={data.inndata.kommentar}
            onChange={(e) =>
              onUpdateEditData("testResultater.lufthastighetskontroll.inndata.kommentar", e.target.value)
            }
            type="textarea"
            dynamicHeight={true}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default Lufthastighetskontroll;
