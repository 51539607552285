import { IGKLuftmengdemaalingResultater, IGKRapport } from "mc-shared/zod/report/GK/gkReportSchema";
import React, { useEffect } from "react";
import { Button } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../../../types/global.types";
import { HSpace, McInput } from "../../../../../../../components";
import { beregnetArealPerMaalepunkt, beregnetTotalLuftmengdeTilluftskanal } from "./luftmengdeUtils";

const TilluftskanalTest: React.FC<{
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
  data: IGKLuftmengdemaalingResultater;
}> = ({ data, onUpdateEditData }) => {
  useEffect(() => {
    utdataBeregninger();
  }, [
    data.inndata.tilluftsKanalResultater.maaleomraadeAreal,
    data.inndata.tilluftsKanalResultater.maaleresultater,
    data.inndata.tilluftsKanalResultater.maaleresultater.length,
  ]);

  const addNewRow = () => {
    const maaleresultaterArray = data.inndata.tilluftsKanalResultater.maaleresultater;
    maaleresultaterArray.push(null);

    onUpdateEditData(
      "testResultater.luftmengdeMaalingIRom.inndata.tilluftsKanalResultater.maaleresultater",
      maaleresultaterArray,
    );

    return maaleresultaterArray;
  };

  const removeLastRow = () => {
    const updatedMaaleresultater = data.inndata.tilluftsKanalResultater.maaleresultater;

    if (updatedMaaleresultater.length > 0) {
      updatedMaaleresultater.pop(); // Remove the last element
      onUpdateEditData(
        "testResultater.luftmengdeMaalingIRom.inndata.tilluftsKanalResultater.maaleresultater",
        updatedMaaleresultater,
      );
    }
  };

  const updateMaaleresultater = (resultat, i) => {
    const maaleresultater = [...data.inndata.tilluftsKanalResultater.maaleresultater];
    maaleresultater[i] = resultat;
    onUpdateEditData(
      "testResultater.luftmengdeMaalingIRom.inndata.tilluftsKanalResultater.maaleresultater",
      maaleresultater,
    );
  };

  const utdataBeregninger = () => {
    const arealetPerMaalepunkt = beregnetArealPerMaalepunkt(
      data.inndata.tilluftsKanalResultater.maaleomraadeAreal,
      data.inndata.tilluftsKanalResultater.maaleresultater.length,
    );

    const totalLuftmengde = beregnetTotalLuftmengdeTilluftskanal(
      data.inndata.tilluftsKanalResultater.maaleresultater.map((resultat) => resultat),
      arealetPerMaalepunkt,
    );
    const utdata = {
      arealPerMaalepunkt: arealetPerMaalepunkt,
      totalLuftmengde: totalLuftmengde,
    };
    onUpdateEditData("testResultater.luftmengdeMaalingIRom.utdata.tilluftsKanalBeregninger", utdata);
  };

  return (
    <>
      <div className="mt-0">
        <h4>Tilluftskanalmåling</h4>
      </div>
      <div className="d-flex flex-column mt-0">
        <b>
          Fyll inn areal av måleområde &#40;m<sup>2</sup>&#41;:
        </b>
        <McInput
          value={data.inndata.tilluftsKanalResultater.maaleomraadeAreal}
          normalizeNumbers={true}
          type="text"
          onBlur={(e) =>
            onUpdateEditData(
              "testResultater.luftmengdeMaalingIRom.inndata.tilluftsKanalResultater.maaleomraadeAreal",
              Number(e.target.value),
            )
          }
          className="w-25"
        />
      </div>
      <div className="d-flex flex-column mt-4">
        <b>Legg til og trekk fra målepunkt:</b>
        <div className="d-flex mb-2">
          <Button color="success" onClick={addNewRow}>
            Legg til målepunkt
          </Button>
          <HSpace />
          <Button
            color="danger"
            onClick={removeLastRow}
            disabled={data.inndata?.tilluftsKanalResultater?.maaleresultater?.length < 1}
          >
            Fjern siste målepunkt
          </Button>
        </div>
        <div className="d-flex justify-content-between">
          <p className="mb-0">
            Areal per målepunkt&#40;m<sup>2</sup>&#41; ={" "}
            <b>
              {beregnetArealPerMaalepunkt(
                data.inndata.tilluftsKanalResultater.maaleomraadeAreal,
                data.inndata.tilluftsKanalResultater.maaleresultater.length,
              )?.toFixed(2) ?? ""}
            </b>
          </p>
          <p className="mb-0">Formel: &#40;Ar = A / N&#41;</p>
        </div>
      </div>
      <hr className="mt-0" />
      {data.inndata.tilluftsKanalResultater.maaleresultater.length > 0 && (
        <>
          <div className="d-flex flex-column mt-4">
            <b>Fyll inn måleresultater &#40;m/s&#41;:</b>
            {data.inndata.tilluftsKanalResultater.maaleresultater.map((a, i) => (
              <McInput
                className="mb-1 w-25"
                key={i}
                value={a}
                normalizeNumbers={true}
                type="text"
                onBlur={(e) => updateMaaleresultater(Number(e.target.value), i)}
              />
            ))}
          </div>
          <div className="d-flex justify-content-between">
            <p className="mb-0">
              Total luftmengde &#40;m<sup>3</sup>/s&#41; ={" "}
              <b>
                {beregnetTotalLuftmengdeTilluftskanal(
                  data.inndata.tilluftsKanalResultater.maaleresultater.map((resultat) => resultat),
                  data.utdata.tilluftsKanalBeregninger.arealPerMaalepunkt,
                )?.toFixed(2) ?? ""}
              </b>
            </p>
            <p className="mb-0">Formel: &#40;Q = SUM&#40;Vn * Ac&#41;&#41;</p>
          </div>
          <hr className="mt-0" />
        </>
      )}
    </>
  );
};

export default TilluftskanalTest;
