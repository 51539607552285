import { IConsequence, IEvaluationCategory, IMatrixConfig, IRisikoProbability } from "mc-shared/zod/issueBoardSchema";
import { ICapitalLetter } from "mc-shared/zod/otherAttributesSchema";
import { useEffect, useState } from "react";
import { IBoard, IIssue } from "../../../../types/global.types";
import { getColor, getMatrixConfigFiltred, getScore } from "../../app/routes/issuesApp/issueUtils";
import "./RisikoMatrix.css";

interface IIssueRisikoMatrixProps {
  issue: IIssue;
  issueBoard: IBoard;
  evaluationCategoryCapital: ICapitalLetter;
}

const IssueRisikoMatrix: React.FC<IIssueRisikoMatrixProps> = ({ issue, issueBoard, evaluationCategoryCapital }) => {
  const [consequences, setConsequences] = useState<IConsequence[]>([]);
  const [probabilities, setProbabilities] = useState<IRisikoProbability[]>([]);
  const [matrixConfig, setMatrixConfig] = useState<IMatrixConfig>([]);
  const [issueProbability, setIssueProbability] = useState<ICapitalLetter | undefined>(undefined);
  const [issueConsequence, setIssueConsequence] = useState<ICapitalLetter | undefined>(undefined);

  useEffect(() => {
    const _consequences: IConsequence[] = Object.entries(
      (issueBoard.risikoSection?.evaluationCategoriesConfig?.[evaluationCategoryCapital] as IEvaluationCategory)
        ?.consequences ?? {},
    )
      .map(([key, value]) => {
        return value;
      })
      .filter((c) => c.isActive);

    const _probabilities: IRisikoProbability[] = Object.entries(issueBoard.risikoSection?.probabilites ?? {}).map(
      ([key, value]) => {
        return {
          ...value,
          id: key,
        };
      },
    );

    setConsequences(_consequences);
    setProbabilities(_probabilities.filter((p) => p.isActive));
    setMatrixConfig(getMatrixConfigFiltred(issueBoard, evaluationCategoryCapital));
    setIssueProbability(issue.risiko?.probability);
    setIssueConsequence(issue.risiko?.consequence && issue.risiko?.consequence[evaluationCategoryCapital]);
  }, [issue, issueBoard, evaluationCategoryCapital]);

  return (
    <div className="matrix-container">
      {consequences.map((consequence, consequenceIndex) => (
        <div className="matrix-row" key={consequenceIndex}>
          {probabilities.map((probability, probabilityIndex) => {
            const isIssueProbability = issueProbability === probability.id;
            const isIssueConsequnce = issueConsequence === consequence.id;
            const isScoreCell = isIssueProbability && isIssueConsequnce;

            return (
              <div
                key={probabilityIndex}
                className={`matrix-cell`}
                style={{ backgroundColor: getColor(probabilityIndex, consequenceIndex, matrixConfig) }}
              >
                {isScoreCell === true && <span className="score">{getScore(consequence, probability)}</span>}
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default IssueRisikoMatrix;
