import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import React, { useCallback, useMemo, useRef } from "react";
import { Button } from "reactstrap";
import { HSpace } from "..";
import { IFileAgGrid } from "../../../../types/global.types";
import { AG_GRID_LOCALE_NOR } from "../../agGridLocale";
import { sortArrayByString } from "../../app/utils";

const HandleSelectedFilesTable: React.FC<{
  files: IFileAgGrid[];
  onSave: () => void;
  onAbort?: () => void;
  deleteFilesCb: (files: IFileAgGrid[]) => void;
  disableAbort?: boolean;
}> = ({ files, onSave, onAbort, deleteFilesCb, disableAbort }) => {
  const gridRef = useRef<AgGridReact>(); // Optional - for accessing Grid's API

  // Each Column Definition results in one Column.
  const columnDefs = useMemo<ColDef[]>(
    () => [
      {
        field: "name",
        filter: true,
        headerName: "Navn",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        width: 300,
      },
    ],
    [],
  );

  //@ts-ignore
  const defaultColDef = useMemo(() => ({
    sortable: true,
  }));

  const deleteSelected = useCallback(() => {
    deleteFilesCb(gridRef.current.api.getSelectedRows());
    gridRef.current.api.deselectAll();
  }, []);

  return (
    <div className="d-flex flex-column h-100 w-100 overflow-auto m-0 p-0">
      <div className="d-flex mt-1 mb-1 justify-content-center align-items-center" style={{ height: "40px" }}>
        <Button onClick={deleteSelected} outline>
          <i className="fa fa-trash-o" />
        </Button>
        <div className="flex-fill" />
        <HSpace />
        {disableAbort !== true && (
          <Button onClick={onAbort}>
            <i className="fa fa-close" />
          </Button>
        )}
        <HSpace />
        <Button color="success" onClick={() => onSave()}>
          <i className="fa fa-save fa-fw" />
        </Button>
      </div>
      <div className="ag-theme-alpine h-100 overflow-auto" style={{ minWidth: "400px" }}>
        <AgGridReact
          ref={gridRef} // Ref for accessing Grid's API
          rowData={sortArrayByString(files, "name")} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          rowSelection="multiple" // Options - allows click selection of rows
          localeText={AG_GRID_LOCALE_NOR}
        />
      </div>
    </div>
  );
};

export default HandleSelectedFilesTable;
