import Axios from "axios";
import { IArcGisGroup, IProjectArcGis, TDimension } from "../../../../../types/global.types";

export const doGetEsriToken = async (projectId): Promise<string> => {
  try {
    const resp = await Axios.get(`/api/project/${projectId}/gis/getToken`);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetSingleArcgisGroup = async (projectId: string, arcgisGroupId: string): Promise<IArcGisGroup> => {
  try {
    const resp = await Axios.get(`/api/project/${projectId}/gis/group/${arcgisGroupId}`);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doConnectArcgisGroupToProject = async (
  projectId: string,
  arcgisGroupId: string,
): Promise<IProjectArcGis> => {
  try {
    const resp = await Axios.put(`/api/project/${projectId}/gis/connectgroup/${arcgisGroupId}`);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetMulticonsultGroups = async (projectId: string): Promise<IArcGisGroup[]> => {
  try {
    const resp = await Axios.get(`/api/project/${projectId}/gis/group`);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doUpdateDefaultMap = async (projectId: string, mapId: string, dimension: TDimension): Promise<string> => {
  try {
    const resp = await Axios.put(`/api/project/${projectId}/gis/defaultmap/${mapId}`, { dimension });
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};
