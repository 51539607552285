import * as R from "ramda";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";
import { useElementSize, useLocalStorage } from "usehooks-ts";
import { createDistinctDropdownFilter } from "../app/utils";
import Avatar from "./Avatar";
import DropdownMultiSelectFilter from "./DropdownMultiSelectFilter";

export const headerSortAndDropDownFilter = (column, colIndex, { sortElement, filterElement }) => {
  return headerSortAndFilterFormatter(column, colIndex, { sortElement, filterElement }, true);
};
const CustomFilterElement = ({ filterElement }) => {
  const isActiveFilter = () => {
    try {
      const isDateFilter = filterElement.props.filterState && filterElement.props.filterState?.filterType === "DATE";
      if (isDateFilter) {
        return filterElement.props.filterState.filterVal.date != null;
      }
      const isNumberFilter =
        filterElement.props.filterState && filterElement.props.filterState?.filterType === "NUMBER";
      if (isNumberFilter) {
        return !!filterElement.props.filterState.filterVal.number;
      }
      return filterElement.props.filterState && Object.keys(filterElement.props.filterState).length > 0;
    } catch (error) {
      console.error(error);
    }
  };

  const clickHandle = (event) => {
    event.stopPropagation();
  };
  return (
    <UncontrolledDropdown style={{ height: "100%" }}>
      <DropdownToggle onClick={(e) => clickHandle(e)} tag="div" className="d-flex align-items-center colheaderfilter">
        <i className={`fa fa-filter fa-xs ${isActiveFilter() && "primary-text"}`} aria-hidden="true"></i>
      </DropdownToggle>
      <DropdownMenu end style={{ marginTop: "10px", padding: "0" }}>
        <DropdownItem
          toggle={false}
          disabled={false}
          style={{ paddingBottom: "0px" }}
          className={"nocolor"}
          tag="div"
          onClick={(e) => clickHandle(e)}
        >
          {filterElement && React.cloneElement(filterElement, { autoFocus: true })}
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
export const headerSortAndFilterFormatter = (column, colIndex, { sortElement, filterElement }, isDropDownSelect) => {
  return (
    <div className="d-flex colheadercontainer">
      <div className="d-flex p-1 m-0 align-items-center colheadertitle">
        <div
          style={{
            marginRight: "3px",
            whiteSpace: "nowrap",
          }}
        >
          {column.text}
        </div>
        <div style={{ paddingLeft: "2px" }}>{sortElement}</div>
      </div>
      {isDropDownSelect
        ? filterElement
        : filterElement != null && <CustomFilterElement filterElement={filterElement} />}
    </div>
  );
};
interface IHeaderSortAndFilterProps {
  children: React.ReactNode;
  sortElement: JSX.Element;
  filterElement: JSX.Element;
}

export const HeaderSortAndFilterWrapper: React.FC<IHeaderSortAndFilterProps> = ({
  children,
  sortElement,
  filterElement,
}) => {
  return (
    <div className="d-flex colheadercontainer">
      <div className="d-flex p-1 m-0 align-items-center colheadertitle">
        <div
          style={{
            marginRight: "3px",
            whiteSpace: "nowrap",
          }}
        >
          {children}
        </div>
        <div style={{ paddingLeft: "2px" }}>{sortElement}</div>
      </div>
      <CustomFilterElement filterElement={filterElement} />
    </div>
  );
};
export const CustomHeaderTextFormatter = (column, colIndex, { sortElement, filterElement }) => {
  const OverflowCheck = ({ text }) => {
    const [isOverflowed, setIsOverflow] = useState(false);
    const [containElementRef, { width }] = useElementSize();
    const textElementRef = useRef();
    const tooltipTarget = useRef();

    useEffect(() => {
      //@ts-ignore
      setIsOverflow(textElementRef.current.scrollWidth > width);
    }, [width]);
    return (
      <>
        <div
          className="d-flex p-1 m-0 align-items-center colheadertitle"
          style={{ minWidth: "120px" }}
          ref={containElementRef}
        >
          <div
            className="d-flex flex-column"
            style={{ fontSize: "0.85rem", lineHeight: "1.2", width: width, position: "absolute" }}
            ref={tooltipTarget}
          >
            <span ref={textElementRef} className="truncateHeaderText" style={{ marginRight: ".5em" }}>
              {column.text.replace(`- ${text}`, "")}
            </span>
            <div className="d-flex text-nowrap">
              <span>{`- ${text}`}</span>
              <div style={{ paddingLeft: ".5em" }}>{sortElement}</div>
            </div>
          </div>
        </div>

        {isOverflowed && (
          <UncontrolledTooltip placement="bottom" target={tooltipTarget} delay={{ show: 500, hide: 100 }}>
            {column.text}
          </UncontrolledTooltip>
        )}
      </>
    );
  };

  if (column.text.includes("- Konsekvens")) {
    return (
      <div className="d-flex colheadercontainer">
        <OverflowCheck text={"Konsekvens"} />
        <div>{filterElement}</div>
      </div>
    );
  }
  if (column.text.includes("- Poeng")) {
    return (
      <div className="d-flex colheadercontainer">
        <OverflowCheck text={"Poeng"} />
        <CustomFilterElement filterElement={filterElement} />
      </div>
    );
  }
};

export const headerAndDateFilterFormatter = (column, colIndex, { sortElement, filterElement }) => {
  return (
    <div className="d-flex flex-column">
      <div className="d-flex p-1" style={{ height: "35px" }}>
        {column.text}
        {sortElement}
      </div>
      {filterElement}
    </div>
  );
};

export const patchFilterFactory = (filterFactory, onFilteredData) => {
  return (...args) => {
    const { createContext, options } = filterFactory(...args);
    return {
      createContext: (...args) => {
        const { Provider: BaseProvider, Consumer } = createContext(...args);
        const Provider = class FilterProvider extends BaseProvider {
          componentDidUpdate() {
            onFilteredData(this.data);
          }
        };
        return { Provider, Consumer };
      },
      options,
    };
  };
};

export const detailClickColumn = (setItem) => {
  return {
    dataField: "N/A",
    text: "",
    hidden: false,
    headerAlign: "center",
    summarize: false,
    order: 0,
    headerStyle: () => {
      return { width: "40px" };
    },
    formatter: (cell, row) => {
      return (
        <div className="d-flex justify-content-center align-items-center">
          <Button className="gray-700 text-white" size="sm" onClick={() => setItem(row._id)}>
            <i style={{ cursor: "pointer" }} className="fa fa-info-circle" />
          </Button>
        </div>
      );
    },
    editable: false,
  };
};
export const CollapsableCell = ({ cell, activeApp }) => {
  const [globalHidden, setGlobalHidden] = useLocalStorage(`collapseCell${activeApp}`, true);
  const [hidden, setHidden] = useState(true);
  const [showButton, setShowButton] = useState(false);
  const [containElementRef, { height }] = useElementSize();
  const textElementRef = useRef();
  //@ts-ignore
  const textHeight = textElementRef?.current?.scrollHeight ?? 0;

  function createMarkup() {
    return { __html: cell && String(cell).replace(/(?:\r\n|\r|\n)/g, "<br/>") };
  }
  const handleClick = (event) => {
    // Check if text is selected
    const selection = window.getSelection();
    if (selection.toString().length > 0) {
      event.preventDefault();
      return;
    }

    event.preventDefault();
    if (event.detail === 1 && (textHeight > 50 || height > 50)) {
      setHidden(!hidden);
      setShowButton(!showButton);
    }
  };

  useEffect(() => {
    setHidden(globalHidden);
  }, [globalHidden]);
  const globalShowButton = globalHidden && (textHeight > 50 || height > 50);
  return (
    <div
      className={"d-flex align-items-end showExpandOnHover w-100"}
      style={{ margin: ".2em 0" }}
      onClick={(e) => handleClick(e)}
    >
      <div ref={containElementRef} style={{ flexGrow: 1 }}>
        <span
          style={{ cursor: globalShowButton ? "pointer" : "default" }}
          dangerouslySetInnerHTML={createMarkup()}
          className={`${hidden ? "two-line-text-truncate" : ""}`}
          ref={textElementRef}
        />
      </div>
      {(showButton || globalShowButton) && (
        <div style={{ width: "30px", position: "relative" }}>
          <button
            className={`expandText addIcon ${hidden === true ? "" : " rotateIcon"}`}
            onClick={(e) => handleClick(e)}
          >
            <i className="fa fa-angle-down"></i>
          </button>
        </div>
      )}
    </div>
  );
};

export const CellEditFormatter = (cell, row?, param3?, extraData?) => {
  const activeApp = sessionStorage.getItem("activeApp");
  return (
    <span className="expandable-cell" style={{ overflow: "hidden", cursor: "pointer" }}>
      <i style={{ color: "#dddddd", cursor: "pointer" }} />
      <div className="d-flex p-0 m-0 align-items-center">
        {extraData && extraData?.avatar && <Avatar id={R.path(extraData.avatar, row)} />}
        {extraData && extraData?.icon && <div style={{ marginRight: "2px" }}>{extraData.icon}</div>}
        <CollapsableCell cell={cell} activeApp={activeApp} />
      </div>
    </span>
  );
};

export const URLCellFormatter = (cell) => {
  if (cell != null && (cell.startsWith("http") || cell.startsWith("www"))) {
    return (
      <div className="text-truncate">
        <a target="_href" href={cell.startsWith("http") ? cell : `http://${cell}`}>
          {cell}
        </a>
      </div>
    );
  } else {
    return <div className="text-truncate">{cell}</div>;
  }
};

export const isColumnHidden = (dataField: string, defaultHidden: boolean, key: string) => {
  const risikoColumns = JSON.parse(localStorage.getItem(key));
  if (risikoColumns != null) {
    if (risikoColumns[dataField] !== undefined) {
      return risikoColumns[dataField];
    } else {
      return defaultHidden;
    }
  } else {
    return defaultHidden;
  }
};

export const COMMON_COLUMN_SECTIONS = {
  HISTORY: "HISTORIKK",
};

export const COL_WIDTHS = {
  TIMESTAMP: "TIMESTAMP",
  DATE: "DATE",
  LONGTEXT: "LONGTEXT",
  PERSON: "PERSON",
  CHIP: "CHIP",
  FILL: "FILL",
  ID: "ID",
  ID_EXPAND: "ID_EXPAND",
  SWITCH: "SWITCH",
  TAGS: "TAGS",
  BUTTON: "BUTTON",
};

export const createHeaderDropDownFilter = (options: any[], propName?: string, useDisplayValue = false) => {
  return {
    Filter: (props) => <DropdownMultiSelectFilter {...props} isNewFilter={true} />,
    props: {
      options: createDistinctDropdownFilter(options, propName, useDisplayValue),
      defaultValue: [],
    },
  };
};

export const createCustomHeaderDropDownFilter = (options: any) => {
  return {
    Filter: (props) => <DropdownMultiSelectFilter {...props} isNewFilter={true} />,
    props: {
      options: options,
      defaultValue: [],
    },
  };
};

export const createHeaderStyle = (width) => {
  switch (width) {
    case "DATE":
      return {
        width: "120px",
      };
    case "TIMESTAMP":
      return {
        width: "160px",
      };
    case "PERSON":
      return {
        minWidth: "170px",
      };
    case "LONGTEXT":
      return {
        minWidth: "250px",
      };
    case "ID_EXPAND":
      return {
        width: "125px",
      };
    case "ID":
      return {
        width: "70px",
      };

    case "CHIP":
      return {
        width: "140px",
      };
    case "SWITCH":
      return {
        minWidth: "150px",
        // width: "140px",
      };
    case "TAGS":
      return {
        width: "100px",
      };
    case "BUTTON":
      return {
        width: "50px",
      };
    default: {
    }
  }
  return {};
};

export const createColumnStyle = (width) => {
  switch (width) {
    case "DATE":
      return {
        width: "110px",
      };
    case "PERSON":
      return {
        minWidth: "150px",
      };
    case "LONGTEXT":
      return {
        minWidth: "250px",
      };
    case "ID_EXPAND":
      return {
        width: "125px",
      };
    default: {
    }
  }
  return {};
};

export const sortCaret = (order, column) => {
  const iconDirection = order === "asc" ? "fa-caret-down" : order === "desc" ? "fa-caret-up" : "";
  return (
    <div style={{ width: "8px" }}>
      <i className={`fa ${iconDirection} p-0 m-0`}></i>
    </div>
  );
};

export const expandHeaderColumn = ({ isAnyExpands }) => {
  return (
    <div className="colheadercontainer" style={{ visibility: "visible" }}>
      <div className={`expandIcon${isAnyExpands === true ? " rotateIcon" : ""}`}>
        <div className="d-flex flex-column">
          <i className="fa fa-angle-down" style={{ marginBottom: "-5px" }}></i>
          <i className="fa fa-angle-down" style={{ marginTop: "-4px" }}></i>
        </div>
      </div>
    </div>
  );
};

export const expandColumnCaret = ({ expanded }) => {
  return (
    <div className={`expandIcon${expanded === true ? " rotateIcon" : ""}`}>
      <i className="fa fa-angle-down"></i>
    </div>
  );
};
