import {
  IGKMaalingAvLufthastighetVedOperasjonssengResultater,
  IGKRapport,
} from "mc-shared/zod/report/GK/gkReportSchema";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../../../types/global.types";
import { HSpace, McInput } from "../../../../../../../components";
import ReportDocxImageContainer from "../../../../ReportBoard/ReportDocxImageContainer";

const MaalingAvLufthastighetVedOperasjonsseng: React.FC<{
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
  data: IGKMaalingAvLufthastighetVedOperasjonssengResultater;
}> = ({ data, onUpdateEditData }) => {
  const addNewRow = () => {
    const maaleresultaterArray = data.inndata.maaleresultater;
    maaleresultaterArray.push(null);

    onUpdateEditData(
      "testResultater.maalingAvLufthastighetVedOperasjonssengLAFTak.inndata.maaleresultater",
      maaleresultaterArray,
    );

    return maaleresultaterArray;
  };

  const removeLastRow = () => {
    const updatedMaaleresultater = data.inndata.maaleresultater;

    if (updatedMaaleresultater.length > 0) {
      updatedMaaleresultater.pop(); // Remove the last element
      onUpdateEditData(
        "testResultater.maalingAvLufthastighetVedOperasjonssengLAFTak.inndata.maaleresultater",
        updatedMaaleresultater,
      );
    }
  };

  const updateMaaleresultater = (resultat, i) => {
    const maaleresultater = [...data.inndata.maaleresultater];
    maaleresultater[i] = resultat;
    onUpdateEditData(
      "testResultater.maalingAvLufthastighetVedOperasjonssengLAFTak.inndata.maaleresultater",
      maaleresultater,
    );
  };

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Måling av lufthastighet ved operasjonsseng</CardTitle>
        <div className="d-flex flex-column mt-2">
          <b>Måletiden er fem sekunder &#40;s&#41;:</b>
        </div>
        <div className="mt-2">
          <b>Legg til flere punkter ved behov:</b>
          <div className="d-flex">
            <Button color="success" onClick={addNewRow}>
              Legg til rad
            </Button>
            <HSpace />
            <Button color="danger" onClick={removeLastRow} disabled={data.inndata.maaleresultater.length < 1}>
              Fjern siste rad
            </Button>
          </div>
        </div>
        <div className="mt-4 d-flex flex-column">
          <b>Fyll inn måleresultater &#40;m/s&#41;:</b>
          {data.inndata.maaleresultater.map((a, i) => (
            <McInput
              className="mb-1 w-25"
              key={i}
              value={a}
              normalizeNumbers={true}
              type="text"
              onBlur={(e) => updateMaaleresultater(Number(e.target.value), i)}
            />
          ))}
        </div>
        <div className="mt-4">
          <ReportDocxImageContainer
            docxImageTag="maalingAvLufthastighetVedOperasjonssengLAFTak"
            title="Målepunkter i rommet"
          />
          <div className="w-50">
            <b>Kommentar:</b>
            <McInput
              value={data.inndata.kommentar}
              onChange={(e) =>
                onUpdateEditData(
                  "testResultater.maalingAvLufthastighetVedOperasjonssengLAFTak.inndata.kommentar",
                  e.target.value,
                )
              }
              type="textarea"
              dynamicHeight={true}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default MaalingAvLufthastighetVedOperasjonsseng;
