import { IssueViewEnum } from "mc-shared/enums/enums";
import { IAccess } from "mc-shared/zod/accessSchema";
import { TCustomAttributeConfig } from "mc-shared/zod/commonSchema";
import {
  IAddRisikoEvalCategory,
  IMoveIssueBoardToOtherProject,
  IUpdateConsequence,
  IUpdateIsActive,
  IUpdateIssueBoardPredefinedTags,
  IUpdateIssueBoardUsePredefinedTags,
  IUpdateProbability,
} from "mc-shared/zod/issueBoardSchema";
import { IDisabledColumnsBody, IIssueExcelImportPOSTBody, IUpdateTableTemplateBody } from "mc-shared/zod/issueSchema";
import {
  IAllOtherAttributeFormulaWithGeneratedStrings,
  IIssueOtherAttributesUpdateOrderBody,
  IOtherAttributesAddPostBody,
  IOtherAttributesUpdateUpdateBody,
} from "mc-shared/zod/otherAttributesSchema";
import { IBoard, IBoardBucket, IBoardType } from "../../../../../types/global.types";
import { apiSlice } from "../../../apiSlice";
import { setIssueAllFiltersInRedux } from "./issueSlice";
import { setShowSuccessMessageThunk } from "../../../global/globalSlice";

interface IIssueBoardParams {
  projectId: string;
  issueBoardId: string;
}

interface IIssueBoardBucketsParams extends IIssueBoardParams {
  buckets: IBoardBucket[];
}

interface IIssueBoardTableTemplateParams extends IIssueBoardParams {
  tableTemplateId: string;
}

interface IIssueBoardTypesParams extends IIssueBoardParams {
  types: IBoardType[];
}

interface IIssueBoardCustomAttributeConfig extends IIssueBoardParams {
  attributeConfig: TCustomAttributeConfig;
}
interface IIssueBoardBucketsWorkflowParams extends IIssueBoardParams {
  useBucketsAsWorkflow: boolean;
}

interface IUpdateIssueBoardCommonSettings {
  projectId: string;
  issueBoardId: string;
  name: string;
  status: string;
  showConclusion: boolean;
  defaultView: IssueViewEnum;
  defaultTemplateId: string;
  issueNameSetting?: string;
  taskNameSetting?: string;
  dashboardSetting?: string;
  category?: string;
  description: string;
  bucketNameSetting?: string;
  showCompletedTodosInKanban?: boolean;
}

interface IUpdateIssueBoardAccess {
  projectId: string;
  issueBoardId: string;
  access: IAccess;
}

interface IUpdateIssueBoardAttributeParams extends IIssueBoardParams {
  attribute: string;
  newValue: string | boolean;
}

interface IUpdateIssueBoardDisabledColumnsPut {
  body: IDisabledColumnsBody;
  params: IIssueBoardParams;
}

export const issueBoardRTK = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSingleIssueBoard: builder.query<IBoard, IIssueBoardParams>({
      query(input) {
        return {
          url: `/project/${input.projectId}/issueboard/${input.issueBoardId}`,
          method: "GET",
        };
      },
      async onQueryStarted({ issueBoardId, ...createIssueParams }, { dispatch, queryFulfilled }) {
        try {
          const resp = await queryFulfilled;
          dispatch(setIssueAllFiltersInRedux(resp.data.allFilters));
        } catch (error) {
          console.error(error);
        }
      },
      providesTags: ["IssueBoard"],
    }),
    getIssueBoards: builder.query<IBoard[], { projectId: string }>({
      query(input) {
        return {
          url: `/project/${input.projectId}/issueboard`,
          method: "GET",
        };
      },
      providesTags: ["IssueBoard"],
    }),
    updateIssueBoardSettings: builder.mutation<IBoard, IUpdateIssueBoardCommonSettings>({
      query(input) {
        return {
          url: `/project/${input.projectId}/issueboard/${input.issueBoardId}`,
          method: "PUT",
          body: {
            name: input.name,
            status: input.status,
            showConclusion: input.showConclusion,
            defaultView: input.defaultView,
            defaultTemplateId: input.defaultTemplateId,
            issueNameSetting: input.issueNameSetting,
            taskNameSetting: input.taskNameSetting,
            dashboardSetting: input.dashboardSetting,
            category: input.category,
            description: input.description,
            bucketNameSetting: input.bucketNameSetting,
            showCompletedTodosInKanban: input.showCompletedTodosInKanban,
          },
        };
      },
      invalidatesTags: ["IssueBoard"],
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setShowSuccessMessageThunk("Register er oppdatert"));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    updateIssueBoardAttribute: builder.mutation<IBoard, IUpdateIssueBoardAttributeParams>({
      query(input) {
        return {
          url: `/project/${input.projectId}/issueboard/${input.issueBoardId}/attribute`,
          method: "PUT",
          body: {
            attribute: input.attribute,
            newValue: input.newValue,
          },
        };
      },
      invalidatesTags: ["IssueBoard"],
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setShowSuccessMessageThunk("Attributt er oppdatert"));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    updateIssueBoardBuckets: builder.mutation<IBoardBucket[], IIssueBoardBucketsParams>({
      query(input) {
        return {
          url: `/project/${input.projectId}/issueboard/${input.issueBoardId}/buckets`,
          method: "PUT",
          body: {
            buckets: input.buckets,
          },
        };
      },
      invalidatesTags: ["IssueBoard"],
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setShowSuccessMessageThunk("Buckets er oppdatert"));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    updateIssueBoardUseBucketsAsWorkflow: builder.mutation<IBoard, IIssueBoardBucketsWorkflowParams>({
      query(input) {
        return {
          url: `/project/${input.projectId}/issueboard/${input.issueBoardId}/usebucketsasworkflow`,
          method: "PUT",
          body: {
            useBucketsAsWorkflow: input.useBucketsAsWorkflow,
          },
        };
      },
      invalidatesTags: ["IssueBoard"],
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setShowSuccessMessageThunk("Buckets er oppdatert"));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    updateIssueBoardTypes: builder.mutation<IBoardType[], IIssueBoardTypesParams>({
      query(input) {
        return {
          url: `/project/${input.projectId}/issueboard/${input.issueBoardId}/types`,
          method: "PUT",
          body: {
            types: input.types,
          },
        };
      },
      invalidatesTags: ["IssueBoard"],
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setShowSuccessMessageThunk("Typer er oppdatert"));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    updateIssueBoardCustomAttributes: builder.mutation<IBoard, IIssueBoardCustomAttributeConfig>({
      query(input) {
        return {
          url: `/project/${input.projectId}/issueboard/${input.issueBoardId}/attributeconfig`,
          method: "PUT",
          body: { attributeConfig: input.attributeConfig },
        };
      },
      invalidatesTags: ["IssueBoard"],
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setShowSuccessMessageThunk("Egendefinerte attributter er oppdatert"));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    deleteIssueBoardTableTemplate: builder.mutation<void, IIssueBoardTableTemplateParams>({
      query(input) {
        return {
          url: `/project/${input.projectId}/issueboard/${input.issueBoardId}/tableTemplate/${input.tableTemplateId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["IssueBoard"],
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setShowSuccessMessageThunk("Register mal er slettet"));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    updateIssueBoardTableTemplate: builder.mutation<
      void,
      { params: IIssueBoardTableTemplateParams; body: IUpdateTableTemplateBody }
    >({
      query(input) {
        return {
          url: `/project/${input.params.projectId}/issueboard/${input.params.issueBoardId}/tableTemplate/${input.params.tableTemplateId}`,
          method: "PUT",
          body: input.body,
        };
      },
      invalidatesTags: ["IssueBoard"],
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setShowSuccessMessageThunk("Register mal er oppdatert"));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    createIssueBoardTableTemplates: builder.mutation<IBoard, { projectId: string; name: string; issueBoardId: string }>(
      {
        query(input) {
          return {
            url: `/project/${input.projectId}/issueboard/${input.issueBoardId}/tableTemplate`,
            method: "POST",
            body: {
              name: input.name,
            },
          };
        },
        invalidatesTags: ["IssueBoard"],
        async onQueryStarted({}, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;
            dispatch(setShowSuccessMessageThunk("Register mal er opprettet"));
          } catch (error) {
            console.error(error);
          }
        },
      },
    ),
    getIssueBoardTemplates: builder.query<IBoard[], { projectId: string }>({
      query(input) {
        return {
          url: `/project/${input.projectId}/issueboard/template/all`,
          method: "GET",
        };
      },
      providesTags: ["IssueBoard"],
    }),
    createIssueBoard: builder.mutation<IBoard, { projectId: string; name: string; boardIdToCopySettingsFrom?: string }>(
      {
        query(input) {
          return {
            url: `/project/${input.projectId}/issueboard`,
            method: "POST",
            body: {
              name: input.name,
              boardIdToCopySettingsFrom: input.boardIdToCopySettingsFrom,
            },
          };
        },
        invalidatesTags: ["IssueBoard"],
        async onQueryStarted({}, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;
            dispatch(setShowSuccessMessageThunk("Register er opprettet"));
          } catch (error) {
            console.error(error);
          }
        },
      },
    ),
    createIssueBoardTemplate: builder.mutation<IBoard, { projectId: string; name: string }>({
      query(input) {
        return {
          url: `/project/${input.projectId}/issueboard/template`,
          method: "POST",
          body: {
            name: input.name,
          },
        };
      },
      invalidatesTags: ["IssueBoard"],
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setShowSuccessMessageThunk("Register mal er opprettet"));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    createIssueBoardFromExcel: builder.mutation<void, { projectId: string; body: IIssueExcelImportPOSTBody }>({
      query(input) {
        return {
          url: `/project/${input.projectId}/issueboard/import-excel`,
          method: "POST",
          body: input.body,
        };
      },
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setShowSuccessMessageThunk("Board er opprettet"));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    updateIssueBoardAccess: builder.mutation<IBoard, IUpdateIssueBoardAccess>({
      query(input) {
        return {
          url: `/project/${input.projectId}/issueboard/${input.issueBoardId}/access`,
          method: "PUT",
          body: {
            access: input.access,
          },
        };
      },
      invalidatesTags: ["IssueBoard"],
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setShowSuccessMessageThunk("Tilgang er oppdatert"));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    updateIssueBoardPredefinedTags: builder.mutation<IBoard, IUpdateIssueBoardPredefinedTags>({
      query(input) {
        return {
          url: `/project/${input.projectId}/issueboard/predefinedtags.update`,
          method: "POST",
          body: input,
        };
      },
      invalidatesTags: ["IssueBoard"],
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setShowSuccessMessageThunk("Forhåndsdefinerte tags er oppdatert"));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    updateIssueBoardUsePredefinedTags: builder.mutation<IBoard, IUpdateIssueBoardUsePredefinedTags>({
      query(input) {
        return {
          url: `/project/${input.projectId}/issueboard/usepredefinedtags.update`,
          method: "POST",
          body: input,
        };
      },
      invalidatesTags: ["IssueBoard"],
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setShowSuccessMessageThunk("Bruk av forhåndsdefinerte tags er oppdatert"));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    updateIssueBoardRisikoIsActive: builder.mutation<IBoard, IUpdateIsActive>({
      query(input) {
        return {
          url: `/project/${input.projectId}/issueboard/risiko.isactive`,
          method: "POST",
          body: input,
        };
      },
      invalidatesTags: ["IssueBoard"],
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setShowSuccessMessageThunk("Risiko er aktivet"));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    updateRisikoProbabilitiesIsActiveAfterTiltak: builder.mutation<IBoard, IUpdateIsActive>({
      query(input) {
        return {
          url: `/project/${input.projectId}/issueboard/risiko.probability.isactiveaftertiltak`,
          method: "POST",
          body: input,
        };
      },
      invalidatesTags: ["IssueBoard"],
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setShowSuccessMessageThunk("Sannsynligheter er oppdatert"));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    updateRisikoProbabilities: builder.mutation<IBoard, IUpdateProbability>({
      query(input) {
        return {
          url: `/project/${input.projectId}/issueboard/risiko.probability.update`,
          method: "POST",
          body: input,
        };
      },
      invalidatesTags: ["IssueBoard"],
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setShowSuccessMessageThunk("Sannsynligheter er oppdatert"));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    updateIssueBoardDisabledColumns: builder.mutation<IBoard, IUpdateIssueBoardDisabledColumnsPut>({
      query(input) {
        return {
          url: `/project/${input.params.projectId}/issueboard/${input.params.issueBoardId}/disabledColumns`,
          method: "PUT",
          body: input.body,
        };
      },
      invalidatesTags: ["IssueBoard"],
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setShowSuccessMessageThunk("Kolonner er oppdatert"));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    addIssueBoardOtherAttribute: builder.mutation<void, { projectId: string; body: IOtherAttributesAddPostBody }>({
      query(input) {
        return {
          url: `/project/${input.projectId}/issueboard/otherAttributesConfig.add`,
          method: "POST",
          body: input.body,
        };
      },
      invalidatesTags: ["IssueBoard"],
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setShowSuccessMessageThunk("Egendefinert attributt er lagt til"));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    updateIssueBoardOtherAttribute: builder.mutation<
      void,
      { projectId: string; body: IOtherAttributesUpdateUpdateBody }
    >({
      query(input) {
        return {
          url: `/project/${input.projectId}/issueboard/otherAttributesConfig.update`,
          method: "POST",
          body: input.body,
        };
      },
      invalidatesTags: ["IssueBoard"],
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setShowSuccessMessageThunk("Egendefinert attributt er oppdatert"));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    updateIssueBoardOtherAttributeOrder: builder.mutation<
      void,
      { projectId: string; body: IIssueOtherAttributesUpdateOrderBody }
    >({
      query(input) {
        return {
          url: `/project/${input.projectId}/issueboard/otherAttributesConfig.updateorder`,
          method: "POST",
          body: input.body,
        };
      },
      invalidatesTags: ["IssueBoard"],
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setShowSuccessMessageThunk("Rekkefølgen på egendefinerte attributter er oppdatert"));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    getSumsByFormula: builder.query<
      IAllOtherAttributeFormulaWithGeneratedStrings,
      { projectId: string; issueBoardId: string }
    >({
      query(input) {
        return {
          url: `/project/${input.projectId}/issueboard/otherAttributesConfig.getSumsByFormula`,
          method: "POST",
          body: {
            issueBoardId: input.issueBoardId,
          },
        };
      },
      providesTags: ["IssueFormulas"],
    }),
    addRisikoEvaluationCategory: builder.mutation<IBoard, IAddRisikoEvalCategory>({
      query(input) {
        return {
          url: `/project/${input.projectId}/issueboard/risiko.evaluationcategory.add`,
          method: "POST",
          body: input,
        };
      },
      invalidatesTags: ["IssueBoard"],
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setShowSuccessMessageThunk("Evalueringskategori lagt til"));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    updateRisikoEvaluationCategory: builder.mutation<IBoard, IUpdateConsequence>({
      query(input) {
        return {
          url: `/project/${input.projectId}/issueboard/risiko.evaluationcategory.update`,
          method: "POST",
          body: input,
        };
      },
      invalidatesTags: ["IssueBoard"],
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setShowSuccessMessageThunk("Evaluering oppdatert"));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    moveIssueBoardToOtherProject: builder.mutation<IBoard, IMoveIssueBoardToOtherProject & { projectId: string }>({
      query(input) {
        return {
          url: `/project/${input.projectId}/issueboard/move.issueboard.to.project`,
          method: "POST",
          body: input,
        };
      },
      invalidatesTags: ["IssueBoard"],
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setShowSuccessMessageThunk("Register flyttet"));
        } catch (error) {
          console.error(error);
        }
      },
    }),
  }),
});

export const {
  useGetSingleIssueBoardQuery,
  useGetIssueBoardsQuery,
  useUpdateIssueBoardSettingsMutation,
  useUpdateIssueBoardBucketsMutation,
  useUpdateIssueBoardUseBucketsAsWorkflowMutation,
  useUpdateIssueBoardTypesMutation,
  useDeleteIssueBoardTableTemplateMutation,
  useUpdateIssueBoardTableTemplateMutation,
  useCreateIssueBoardTableTemplatesMutation,
  useGetIssueBoardTemplatesQuery,
  useCreateIssueBoardMutation,
  useCreateIssueBoardTemplateMutation,
  useCreateIssueBoardFromExcelMutation,
  useUpdateIssueBoardAccessMutation,
  useUpdateIssueBoardDisabledColumnsMutation,
  useAddIssueBoardOtherAttributeMutation,
  useUpdateIssueBoardOtherAttributeMutation,
  useUpdateIssueBoardOtherAttributeOrderMutation,
  useGetSumsByFormulaQuery,
  useUpdateIssueBoardPredefinedTagsMutation,
  useUpdateIssueBoardUsePredefinedTagsMutation,
  useUpdateIssueBoardRisikoIsActiveMutation,
  useUpdateRisikoProbabilitiesIsActiveAfterTiltakMutation,
  useUpdateRisikoProbabilitiesMutation,
  useAddRisikoEvaluationCategoryMutation,
  useUpdateRisikoEvaluationCategoryMutation,
  useMoveIssueBoardToOtherProjectMutation,
} = issueBoardRTK;
