import { z } from "zod";

export const ZSJArapportOptionSchema = z.object({
  name: z.string(),
  isSelected: z.boolean(),
  faremomenter: z.array(
    z.object({
      isSelected: z.boolean(),
      deloppgave: z.string(),
      faremoment: z.string(),
      muligKonsekvens: z.string(),
      tiltak: z.string(),
      ansvarlig: z.string().nullable(),
      isApproved: z.boolean(),
      kommentar: z.string().nullable(),
    }),
  ),
});

export type ISJArapportOption = z.infer<typeof ZSJArapportOptionSchema>;

export const ZSJArapportSchema = z.object({
  generellInfo: z.object({
    sjaTittel: z.string(),
    oppdragsNavn: z.string(),
    oppdragsNummer: z.string(),
    firmaAvdelingSeksjon: z.string(),
    beskrivelseAvArbeidet: z.string(),
    forutsetninger: z.string(),
    sjaKoordinator: z.string(),
    jobbOperasjon: z.string(),
    dato: z.string(),
  }),
  deltagere: z.array(
    z.object({
      navn: z.string(),
      avdSeksjon: z.string(),
    }),
  ),
  options: z.object({
    generell: ZSJArapportOptionSchema,
    tekniskRom: ZSJArapportOptionSchema,
    ute: ZSJArapportOptionSchema,
    spesial: ZSJArapportOptionSchema,
  }),
});

export type ISJArapport = z.infer<typeof ZSJArapportSchema>;
