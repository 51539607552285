import React, { useEffect, useState } from "react";
import { IApp } from "../../../types/global.types";

interface IAppIconProps {
  app: Pick<IApp, "icon" | "iconColor" | "name">;
  active?: boolean;
  hover?: boolean;
  hoverEffect?: boolean;
  variation: "sidebar" | "appcard" | "list";
}

const AppIcon: React.FC<IAppIconProps> = ({ app, active, hover, variation, hoverEffect }) => {
  const [isHovered, setIsHovered] = useState(false);
  let appColor = app.iconColor ? app.iconColor : "#3e4245";
  let scale = 0.5;
  let backgroundColor = "";
  let iconColor = "";
  useEffect(() => {
    setIsHovered(hover);
  }, [hover]);
  switch (variation) {
    case "list":
      scale = 0.3;
      backgroundColor = appColor;
      iconColor = isHovered || active ? appColor : "#3e4245";
      break;
    case "sidebar":
      scale = 0.4;
      backgroundColor = appColor;
      iconColor = isHovered || active ? appColor : "#3e4245";
      break;
    case "appcard":
      backgroundColor = "";
      scale = 0.55;
      iconColor = appColor;
      break;
  }

  return (
    <div
      style={{
        width: `${scale * 100}px`,
        height: `${scale * 100}px`,
      }}
      onMouseEnter={() => hoverEffect === true && setIsHovered(true)}
      onMouseLeave={() => hoverEffect === true && setIsHovered(false)}
    >
      <div
        style={{
          width: `${100}px`,
          height: `${100}px`,
          transform: `scale(${scale})`,
          transformOrigin: "left top",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className={`appIconbackground ${active ? "activeApp" : ""}`}
          style={{
            background: backgroundColor,
          }}
        />
        <div
          className="appIconInnerBackground"
          style={{
            background: `${variation === "appcard" || isHovered ? "white" : active ? "#F3F3F3" : "#D3D3D3"}`,
          }}
        />
        <div
          style={{
            position: "absolute",
            width: "80%",
            height: "80%",
          }}
        >
          <div
            className="appIcon"
            style={{
              backgroundColor: iconColor,
              maskImage: `url(${app.icon})`,
              WebkitMask: `url(${app.icon})`,
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default AppIcon;
