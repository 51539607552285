import { IMCBIMAPI } from "../../../../../../types/mcbim.types";
import { apiSlice } from "../../../../apiSlice";
import { setShowSuccessMessageThunk } from "../../../../global/globalSlice";

export const mcbimRTK = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMcBimProject: builder.query<IMCBIMAPI.Project, string>({
      query: (projectId) => ({
        url: `/project/${projectId}/mcbim/project`,
        method: "GET",
      }),
      providesTags: ["MCBIM"],
    }),
    createMcBimJob: builder.mutation<IMCBIMAPI.Project, string>({
      query: (projectId) => ({
        url: `/project/${projectId}/mcbim/job`,
        method: "POST",
      }),
    }),
    updateMcBimIfcFiles: builder.mutation<
      IMCBIMAPI.Project,
      { projectId: string; ifcFiles: IMCBIMAPI.IIfcFileUpdateInput[] }
    >({
      query: ({ projectId, ifcFiles }) => ({
        url: `/project/${projectId}/mcbim/updatefiles`,
        method: "PUT",
        body: { ifcFiles },
      }),
      onQueryStarted: async ({ projectId, ifcFiles }, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(setShowSuccessMessageThunk(`Endring av IFC-filer lagret til MCBIM`));
      },
      invalidatesTags: ["MCBIM"],
    }),
    updateBimJobInterval: builder.mutation<
      IMCBIMAPI.Project,
      { projectId: string; jobInterval: string; jobEnabled: boolean }
    >({
      query: ({ projectId, jobInterval, jobEnabled }) => ({
        url: `/project/${projectId}/mcbim/updateinterval`,
        method: "PUT",
        body: { jobInterval, jobEnabled },
      }),
      onQueryStarted: async ({ projectId, jobInterval, jobEnabled }, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(setShowSuccessMessageThunk("Endring av intervall for MCBIM-jobb lagret til MCBIM"));
      },
      invalidatesTags: ["MCBIM"],
    }),
    updateBimMaxVersionCount: builder.mutation<IMCBIMAPI.Project, { projectId: string; maxVersionCount: number }>({
      query: ({ projectId, maxVersionCount }) => ({
        url: `/project/${projectId}/mcbim/updateversioncount`,
        method: "PUT",
        body: { maxVersionCount },
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(setShowSuccessMessageThunk(`Endring av maksimalt antall versjoner lagret til MCBIM`));
      },
      invalidatesTags: ["MCBIM"],
    }),
    getListOfMcBimJobs: builder.query<IMCBIMAPI.IJob[], string>({
      query: (projectId) => ({
        url: `/project/${projectId}/mcbim/job`,
        method: "GET",
      }),
    }),
    getSingleMcBimJob: builder.query<IMCBIMAPI.IJob[], { projectId: string; jobId: string }>({
      query: ({ projectId, jobId }) => ({
        url: `/project/${projectId}/mcbim/job/${jobId}`,
        method: "GET",
      }),
    }),
    removeMcBimJob: builder.mutation<IMCBIMAPI.Project, { projectId: string; jobId: string }>({
      query: ({ projectId, jobId }) => ({
        url: `/project/${projectId}/mcbim/job/${jobId}`,
        method: "POST",
      }),
    }),
    removeMcFileData: builder.mutation<
      { deletedDocuments: number },
      { projectId: string; fileStorageId: string; fileUploadedAt?: string }
    >({
      query: ({ projectId, fileStorageId, fileUploadedAt }) => ({
        url: `/project/${projectId}/mcbim/cleardata`,
        method: "POST",
        body: { fileStorageId, fileUploadedAt },
      }),
    }),
  }),
});

export const {
  useGetMcBimProjectQuery,
  useCreateMcBimJobMutation,
  useGetListOfMcBimJobsQuery,
  useGetSingleMcBimJobQuery,
  useRemoveMcBimJobMutation,
  useUpdateBimJobIntervalMutation,
  useUpdateBimMaxVersionCountMutation,
  useUpdateMcBimIfcFilesMutation,
  useRemoveMcFileDataMutation,
} = mcbimRTK;
