import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { IRapportOppdrag } from "../../app/routes/report/brannrapport/types";
import McInput from "../McInput/McInput";

const ReportProjectInfo: React.FC<{
  infoOmUppdraget: IRapportOppdrag;
  setInfOmOppdrag: (value: string, key: string) => void;
}> = ({ infoOmUppdraget, setInfOmOppdrag }) => {
  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Info om oppdraget</CardTitle>
        <div className="d-flex flex-column">
          <b>Oppdragsgiver</b>
          <McInput
            value={infoOmUppdraget.oppdragsgiver}
            type="text"
            onChange={(e) => setInfOmOppdrag(e.target.value, "oppdragsgiver")}
            id="Oppdragsgiver"
            placeholder="Oppdragsgiver"
            className="w-25"
          />
        </div>
        <div className="d-flex flex-column mt-2">
          <b>Oppdragsnavn</b>
          <McInput
            value={infoOmUppdraget.oppdragsnavn}
            type="text"
            onChange={(e) => setInfOmOppdrag(e.target.value, "oppdragsnavn")}
            id="oppdragsnavn"
            placeholder="Oppdragsnavn"
            className="w-25"
          />
        </div>
        <div className="d-flex flex-column mt-2">
          <b>Oppdragsnummer</b>
          <McInput
            value={infoOmUppdraget.oppdragsnummer}
            type="text"
            onChange={(e) => setInfOmOppdrag(e.target.value, "oppdragsnummer")}
            id="oppdragsnummer"
            placeholder="Oppdragsnummer"
            className="w-25"
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default ReportProjectInfo;
