import { IGKKontrollAvAlarmResultater, IGKRapport } from "mc-shared/zod/report/GK/gkReportSchema";
import React from "react";
import { Card, CardBody, CardTitle, FormGroup, Input, Label } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../../types/global.types";
import { McInput } from "../../../../../../components";

const KontrollAvAlarm: React.FC<{
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
  data: IGKKontrollAvAlarmResultater;
}> = ({ data, onUpdateEditData }) => {
  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Kontroll av alarmer</CardTitle>
        <div className="w-50">
          <div>
            <b>Beskrivelse av fremgangsmåte:</b>
            <McInput
              value={data.inndata.beskrivelseAvHvordanAlarmFremprovoseres}
              onChange={(e) =>
                onUpdateEditData(
                  "testResultater.kontrollAvAlarm.inndata.beskrivelseAvHvordanAlarmFremprovoseres",
                  e.target.value,
                )
              }
              type="textarea"
              dynamicHeight={true}
            />
          </div>
          <div>
            <FormGroup className="d-flex flex-column" check>
              <Label check>
                <Input
                  type="radio"
                  checked={data.inndata.godkjent === true}
                  onChange={() => onUpdateEditData("testResultater.kontrollAvAlarm.inndata.godkjent", true)}
                />
                Godkjent
              </Label>
              <Label check>
                <Input
                  type="radio"
                  checked={data.inndata.godkjent === false}
                  onChange={() => onUpdateEditData("testResultater.kontrollAvAlarm.inndata.godkjent", false)}
                />
                Ikke godkjent
              </Label>
            </FormGroup>
          </div>
        </div>
        <div className="w-50 mt-4">
          <b>Kommentar:</b>
          <McInput
            value={data.inndata.kommentar}
            onChange={(e) => onUpdateEditData("testResultater.kontrollAvAlarm.inndata.kommentar", e.target.value)}
            type="textarea"
            dynamicHeight={true}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default KontrollAvAlarm;
