import { IGKLekkasjetestResultater, IGKRapport } from "mc-shared/zod/report/GK/gkReportSchema";
import React from "react";
import { Card, CardBody, CardTitle, FormGroup, Input, Label } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../../types/global.types";
import { McInput } from "../../../../../../components";

const Lekkasjetest: React.FC<{
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
  data: IGKLekkasjetestResultater;
}> = ({ data, onUpdateEditData }) => {
  const updateMaaleresultater = (resultat: string | number, i: number, parameter: string) => {
    const maaleresultater = [...data.inndata.maaleresultater];
    maaleresultater[i][parameter] = resultat;
    onUpdateEditData("testResultater.lekkasjetest.inndata.maaleresultater", maaleresultater);
  };

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Lekkasjetest</CardTitle>
        <div className="mt-0 d-flex flex-column w-50">
          <b>Fyll inn måleresultater:</b>
          <table>
            <thead>
              <tr>
                <th>Starttrykk &#40;Pa&#41;</th>
                <th>Sluttrykk &#40;Pa&#41;</th>
              </tr>
            </thead>
            {data.inndata.maaleresultater.map((resultat, i) => (
              <tbody key={i}>
                <tr>
                  <td>
                    <McInput
                      value={resultat.starttrykk}
                      normalizeNumbers={true}
                      type="text"
                      onBlur={(e) => updateMaaleresultater(Number(e.target.value), i, "starttrykk")}
                      placeholder="Starttrykk"
                    />
                  </td>
                  <td>
                    <McInput
                      value={resultat.sluttrykk}
                      normalizeNumbers={true}
                      type="text"
                      onBlur={(e) => updateMaaleresultater(Number(e.target.value), i, "sluttrykk")}
                      placeholder="Sluttrykk"
                    />
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
        <div>
          <FormGroup className="d-flex flex-column mt-4" check>
            <Label check>
              <Input
                type="radio"
                checked={data.inndata.godkjent === true}
                onChange={() => onUpdateEditData("testResultater.lekkasjetest.inndata.godkjent", true)}
              />
              Godkjent
            </Label>
            <Label check>
              <Input
                type="radio"
                checked={data.inndata.godkjent === false}
                onChange={() => onUpdateEditData("testResultater.lekkasjetest.inndata.godkjent", false)}
              />
              Ikke godkjent
            </Label>
          </FormGroup>
        </div>
        <div className="w-50 mt-4">
          <b>Kommentar:</b>
          <McInput
            value={data.inndata.kommentar}
            onChange={(e) => onUpdateEditData("testResultater.lekkasjetest.inndata.kommentar", e.target.value)}
            type="textarea"
            dynamicHeight={true}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default Lekkasjetest;
