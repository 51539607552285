import React, { useCallback, useMemo, useState } from "react";
import { ColDef } from "ag-grid-community";
import { AgGridReact, CustomCellRendererProps } from "ag-grid-react";
import { FailedAlert, HSpace, McInput, MCLoader, TitleWithValue, VSpace } from "../../../../components";
import { Button, ButtonGroup, Progress, UncontrolledTooltip } from "reactstrap";
import { AG_GRID_LOCALE_NOR } from "../../../../agGridLocale";
import {
  useGetNrhRoomValidationCommentsQuery,
  useGetNrhRoomValidationsQuery,
  useUpsertNrhRoomValidationCommentMutation,
} from "../../admin/mcbim/McBimMongoRTK";
import { useAppSelector } from "../../../../hooks";
import { IUser } from "../../../../../../types/global.types";
import RIESingleRoomValidationTable from "./RIESingleRoomValidationTable";
import { INrhRoomValidationRow } from "./nrhRoomValTypes";
import ArkSingleRoomValidationTable from "./ArkSingleRoomValidationTable";
import Switch from "react-switch";

const NRHRoomVal = () => {
  const [onlyFunctionRooms, setOnlyFunctionRooms] = useState(true);

  const LOCAL_STORAGE_DISCIPLINE_KEY = "nrhroom_valdiscipline";

  const getInitialDiscipline = () => {
    const savedDiscipline = localStorage.getItem(LOCAL_STORAGE_DISCIPLINE_KEY);
    return savedDiscipline === "ARK" || savedDiscipline === "RIE" ? savedDiscipline : "RIE";
  };

  const [discipline, setDiscipline] = React.useState<"ARK" | "RIE">(getInitialDiscipline);

  const {
    data: roomValidations = [],
    isLoading: loadingValidations,
    isError: errorLoadingValidations,
  } = useGetNrhRoomValidationsQuery({ projectId: "5f6085966d375136b9835f7a" });

  const {
    data: comments = [],
    isLoading: isLoadingComments,
    isError: isErrorComments,
  } = useGetNrhRoomValidationCommentsQuery({ projectId: "5f6085966d375136b9835f7a" });

  const [upsertComment, { isLoading: isUpsertingComment, isError: errorUpsertingComment }] =
    useUpsertNrhRoomValidationCommentMutation();

  const isFunctionRoom = (roomNr: string): boolean => {
    if (roomNr.startsWith("8")) {
      return false;
    }
    if (roomNr.startsWith("9")) {
      return false;
    }
    return true;
  };

  const rows = useMemo(() => {
    return roomValidations
      .filter((room) => {
        if (onlyFunctionRooms === false) {
          return true;
        }
        if (onlyFunctionRooms && isFunctionRoom(room.roomNr)) {
          return true;
        }
        return false;
      })
      .map((row) => {
        const comment = comments.find((comment) => comment.roomNr === row.roomNr)?.comment || "";
        return {
          ...row,
          RIE: {
            ...row.RIE,
            comment,
          },
        };
      });
  }, [roomValidations, comments, onlyFunctionRooms]);

  const user: IUser = useAppSelector((state) => state.globalReducer.user);

  const columns: ColDef<INrhRoomValidationRow>[] = [
    {
      headerName: "",
      cellRenderer: (params) => {
        return params.node.expanded ? <i className="fa fa-caret-up" /> : <i className="fa fa-caret-down" />;
      },
      width: 50,
    },
    {
      field: "roomNr",
      headerName: "Romnr",
      filter: "agTextColumnFilter",
      sort: "asc",
    },
    {
      field: "roomName",
      headerName: "Romnavn",
      filter: true,
    },
    {
      field: "floor",
      headerName: "Etasje",
      filter: true,
    },
    {
      field: "wing",
      headerName: "Lamell",
      filter: true,
    },
    {
      field: "mmi",
      headerName: "MMI",
      filter: true,
    },
    {
      field: "standardRoomNr",
      headerName: "Std romnr",
      hide: discipline !== "RIE",
      filter: true,
    },
    {
      field: "mmiStandardRum",
      headerName: "MMI Std rom",
      hide: discipline !== "RIE",
      filter: true,
    },
    {
      field: "RIE.nrOfCorrectTypes",
      headerName: "nrOfCorrectTypes",
      hide: true,
    },
    {
      field: "RIE.nrOfTypes",
      headerName: "nrOfTypes",
      hide: true,
    },
    {
      field: "RIE.percentageCorrect",
      hide: discipline !== "RIE",
      headerName: "Matchende artikler %",
      cellRenderer: (params) => {
        const displayVal = `${Math.round(params.value)}%`;
        return (
          <div className="w-100">
            <Progress
              color={params.data.nrOfCorrectTypes === params.data.nrOfTypes ? "success" : "warning"}
              value={params.value}
            >
              {displayVal}
            </Progress>
          </div>
        );
      },
    },
    {
      hide: discipline !== "RIE",
      field: "RIE.comment",
      filter: "agTextColumnFilter",
      autoHeight: true,
      headerName: `Kommentar RIE`,
      cellClass: "p-0",
      minWidth: 200,
      flex: 1,
      cellRenderer: (params) => {
        const row = params.data;
        return (
          <div style={{ minHeight: "35px" }}>
            <TitleWithValue
              wrap={true}
              type="textarea"
              editable={true}
              allowEmpty
              title=""
              value={row.RIE?.comment}
              saveCb={(oldValue: string, newValue: string) =>
                upsertComment({
                  projectId: "5f6085966d375136b9835f7a",
                  roomNr: row.roomNr,
                  comment: newValue,
                  userName: user?.name || "Unknown",
                  createdAt: new Date(),
                })
              }
            />
          </div>
        );
      },
    },
    {
      field: "ARK.percentageCorrect",
      hide: discipline !== "ARK",
      headerName: "Matchende artikler %",
      cellRenderer: (params) => {
        const displayVal = `${Math.round(params.value)}%`;
        return (
          <div className="w-100">
            <Progress
              color={params.data.nrOfCorrectTypes === params.data.nrOfTypes ? "success" : "warning"}
              value={params.value}
            >
              {displayVal}
            </Progress>
          </div>
        );
      },
    },
  ];

  const DetailCellRendererRIE = ({ data, node, api }: CustomCellRendererProps) => {
    return <RIESingleRoomValidationTable devices={data.RIE.devicesRIECompare} />;
  };

  const DetailCellRendererARK = ({ data, node, api }: CustomCellRendererProps) => {
    return <ArkSingleRoomValidationTable devices={data.ARK.devicesARKCompare} />;
  };

  const onRowClicked = (params) => {
    if (params.event.defaultPrevented) {
      return null;
    }
    params.node.setExpanded(!params.node.expanded);
    params.api.refreshCells();
  };

  const isLoading = loadingValidations || isLoadingComments || isUpsertingComment;
  const isError = errorLoadingValidations || isErrorComments || errorUpsertingComment;
  const detailCellRenderer =
    discipline === "RIE"
      ? useCallback(DetailCellRendererRIE, [discipline])
      : useCallback(DetailCellRendererARK, [discipline]);

  const _setDisciplineAndLocalStorage = (discipline: "ARK" | "RIE") => {
    setDiscipline(discipline);
    localStorage.setItem(LOCAL_STORAGE_DISCIPLINE_KEY, discipline);
  };

  const [searchText, setSearchText] = useState("");

  return (
    <div className="d-flex flex-column h-100 w-100">
      <div>
        <ButtonGroup>
          <Button
            color={discipline === "ARK" ? "mc-blue" : "secondary"}
            onClick={() => _setDisciplineAndLocalStorage("ARK")}
          >
            ARK
          </Button>
          <Button
            color={discipline === "RIE" ? "mc-blue" : "secondary"}
            onClick={() => _setDisciplineAndLocalStorage("RIE")}
          >
            RIE
          </Button>
        </ButtonGroup>
      </div>
      <div className="d-flex border p-1">
        <div style={{ width: "300px" }}>
          <McInput
            type="text"
            placeholder="Søk..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="my-2"
          />
        </div>
        <div className="d-flex p-0 mt-2">
          <UncontrolledTooltip placement="top" target="abo">
            Vis kun funktionsrom (rom som ikke startar på 8 eller 9)
          </UncontrolledTooltip>
          <p className="mr-2">
            <i id="abo" className="fa fa-info-circle fa-fw text-info" />
            Funksjonsrom
          </p>
          <HSpace />
          <Switch checked={onlyFunctionRooms} onChange={() => setOnlyFunctionRooms(!onlyFunctionRooms)} />
        </div>
        <HSpace />
      </div>

      {isLoading && <MCLoader />}
      {isError && (
        <div>
          <FailedAlert />
        </div>
      )}
      <div className="border w-100 h-100 ag-theme-alpine overflow-auto p-2">
        <VSpace />
        <AgGridReact
          detailCellRenderer={detailCellRenderer}
          rowData={rows}
          columnDefs={columns}
          masterDetail={true}
          onRowClicked={onRowClicked}
          localeText={AG_GRID_LOCALE_NOR}
          quickFilterText={searchText}
        />
      </div>
    </div>
  );
};

export default NRHRoomVal;
