import { CellEditRequestEvent, ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import React from "react";
import { IIssue } from "../../../../types/global.types";
import { ISSUE_COMPLETED_VALUE, ISSUE_EXPIRED, ISSUE_STATES_OPTIONS } from "../../app/routes/issuesApp/issueConstants";
import { useCreateIssueMutation, useDoUpdateIssueMutation } from "../../app/routes/issuesApp/issueRTK";
import { AppModalEnums } from "../../frontendConstants";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { ClickableTableIdButton, CreateNewButton, DisplayDueDate, FailedAlert } from "../index";

import moment from "moment/moment";
import { AG_GRID_LOCALE_NOR } from "../../agGridLocale";
import { ActionIcon } from "../CustomIcons/CustomIcons";
import { StatusBadgeRenderer, UserCellRenderer } from "./ConnectedIssues";
import { addAppModalThunk } from "../../global/globalSlice";

export const TaskForAIssueTable: React.FC<{ issue: IIssue }> = ({ issue }) => {
  const dispatch = useAppDispatch();

  const project = useAppSelector((state) => state.adminReducer.project);

  const [updateIssue, { isError }] = useDoUpdateIssueMutation();

  const [addNewIssue, { isLoading, isSuccess }] = useCreateIssueMutation();

  const columnDefs: ColDef<IIssue, any>[] = [
    {
      field: "id",
      width: 120,
      headerName: "Id",
      cellRenderer: (props) => {
        const { node, api, data } = props;
        return (
          <>
            <ActionIcon
              className="text-secondary"
              style={{ marginRight: "3px", fontSize: "1.1em", marginBottom: "3px" }}
            />
            <ClickableTableIdButton
              id={data?.id}
              _id={data?._id}
              setItem={() =>
                dispatch(
                  addAppModalThunk({
                    itemId: data?._id,
                    boardId: data?.issueBoard,
                    projectId: data?.project,
                    app: AppModalEnums.ISSUE,
                  }),
                )
              }
            />
          </>
        );
      },
    },
    {
      field: "title",
      headerName: "Tittel",
      flex: 1,
      minWidth: 150,
      editable: true,
    },
    {
      field: "assignedTo",
      headerName: "Ansv. for utførelse",
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: project.members.map((m) => m.user),
        cellRenderer: (params) => <UserCellRenderer height="15px" user={params} />,
      },
      cellRenderer: (params) => (
        <UserCellRenderer
          user={params}
          onClear={() => {
            updateIssue({
              projectId: params.data.project as string,
              issueId: params.data._id,
              attr: "assignedTo",
              value: null,
              oldValue: params.value?.name ?? "",
              issueBoardId: params.data.issueBoard,
            });
          }}
        />
      ),
    },
    {
      field: "dates.due",
      cellEditor: "agDateCellEditor",
      width: 150,
      headerName: "Tidsfrist",
      editable: true,
      filter: "agDateColumnFilter",
      valueGetter: (params) => {
        return params?.data?.dates?.due === null ? null : new Date(params.data?.dates?.due);
      },
      valueSetter: (params) => {
        return params.newValue.toISOString();
      },
      cellRenderer: (params) => {
        if (moment(params?.value).isValid() === false || params?.value == null || !params?.value) {
          return "-";
        }
        return (
          <DisplayDueDate
            dueDate={params?.value}
            isComplete={
              params.data != null &&
              (params.data.status === ISSUE_COMPLETED_VALUE || params.data.status === ISSUE_EXPIRED)
            }
          />
        );
      },
    },
    {
      field: "status",
      valueGetter: (params) => {
        const status = ISSUE_STATES_OPTIONS.find((opt) => opt.value === params.data.status);
        return status;
      },
      valueSetter: (params) => {
        return params.newValue.value;
      },
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: ISSUE_STATES_OPTIONS,
        cellRenderer: StatusBadgeRenderer,
        valueListMaxHeight: 220,
      },
      headerName: "Status",
      editable: true,
      width: 150,
      cellRenderer: StatusBadgeRenderer,
    },
  ];

  const onCellEditRequest = (event: CellEditRequestEvent<IIssue>) => {
    const issue = event.data;
    let updateValue;

    if (event.colDef.field.startsWith("assignedTo")) {
      updateValue = typeof event.newValue !== "string" ? event.newValue?._id : event.newValue;
    } else if (event.colDef.field.startsWith("status")) {
      updateValue = event.newValue.value;
    } else if (event.colDef.field.startsWith("dates")) {
      if (event.newValue == null) {
        updateValue = "";
      } else {
        updateValue = event.newValue;

        if (updateValue.toISOString() === event.oldValue?.toISOString()) {
          return;
        }
      }
    } else {
      updateValue = event.newValue;
    }

    updateIssue({
      projectId: issue.project as string,
      issueId: issue._id,
      attr: event.colDef.field,
      value: updateValue,
      oldValue: event.oldValue?.name ?? "",
      issueBoardId: issue.issueBoard as string,
    });
  };

  return (
    <div className="p-2">
      {isError === true && <FailedAlert />}
      <div className="d-flex align-items-center ">
        <h5 className="mt-1">Aksjoner</h5>
        <CreateNewButton
          disabled={isLoading}
          outline
          onClick={() =>
            addNewIssue({
              projectId: project?._id,
              issueBoardId: issue.issueBoard as string,
              issueTitle: "",
              taskOrIssue: "TASK",
              issueIdIfItIsATask: issue._id,
            })
          }
          tooltip="Ny aksjon"
          size="sm"
        />
      </div>
      {issue.connectedTasks?.length > 0 && (
        <AgGridReact
          defaultColDef={{ sortable: false, suppressHeaderMenuButton: false }}
          domLayout={"autoHeight"}
          columnDefs={columnDefs}
          getRowId={(p) => p.data._id}
          rowData={issue.connectedTasks as IIssue[]}
          stopEditingWhenCellsLoseFocus={true}
          animateRows={true}
          readOnlyEdit={true}
          onCellEditRequest={onCellEditRequest}
          suppressContextMenu={true}
          detailRowAutoHeight={true}
          localeText={AG_GRID_LOCALE_NOR}
        />
      )}
    </div>
  );
};
