import React, { useEffect, useMemo, useState } from "react";
import { Prompt } from "react-router";
import { IParentChildItem, IPNSProps } from "../../../../../types/global.types";
import { FailedAlert, ParentChildChart } from "../../../components/";
import HierarchyEditor from "../../../components/HierarchyEditor/HierarchyEditor";
import PNSExcelImportWithSpecTable from "../../../components/PNSExcelImport/PNSExcelImportWithSpecTable";
import { MCApplicationsUrlEnum } from "../../../frontendConstants";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { updateProjectPnsAndSetInRedux } from "../../actions";
import { HierarchyNode } from "../../hierarchyNodeUtils";
import { doLogUsage } from "../admin/restQueries";
import PnsItemModal from "./PnsItemModal";
import { formatHierarchyNodesToParentChildItems } from "./pnsUtils";
import { setShowSuccessMessageThunk } from "../../../global/globalSlice";

const PNS: React.FC<{ viewerMode?: boolean }> = ({ viewerMode = false }) => {
  const [nodes, setNodes] = useState<IParentChildItem[]>();
  const [selectedNode, setSelectedNode] = useState<IParentChildItem>();
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [showFailedAlert, setShowFailedAlert] = useState<boolean>(false);
  const [displayFullScreen, setDisplayFullScreen] = useState<boolean>(false);
  const project = useAppSelector((state) => state.adminReducer.project);
  const dispatch = useAppDispatch();
  const [importedData, setImportedData] = useState<HierarchyNode<IPNSProps>>(null);

  const pnsRootNode = useMemo<HierarchyNode<IPNSProps>>(() => {
    if (importedData != null) {
      return importedData;
    }

    if (project.pns == null || project.pns.length === 0) {
      return new HierarchyNode<IPNSProps>({
        _id: null,
        props: {
          pnsId: "root",
          title: "root",
        },
        parent: null,
        children: [],
      });
    }

    return HierarchyNode.createFromFlatList(project.pns);
  }, [project.pns, importedData]);

  useEffect(() => {
    try {
      if (pnsRootNode == null) {
        setNodes([]);
        return;
      }

      const _nodes = formatHierarchyNodesToParentChildItems(pnsRootNode);
      setNodes(_nodes.length > 0 ? _nodes : null);
    } catch (error) {
      setShowFailedAlert(true);
    }
  }, [pnsRootNode]);

  useEffect(() => {
    doLogUsage(project, MCApplicationsUrlEnum.PNS, "open");
  }, []);

  const onClickItem = (id: string) => {
    const node = nodes.find((node) => node.id === id);
    setSelectedNode(node);
  };

  const toggle = () => {
    setSelectedNode(null);
  };

  const saveStructure = async (rootNode: HierarchyNode<IPNSProps>) => {
    try {
      await dispatch(updateProjectPnsAndSetInRedux(rootNode.flatten()));
      dispatch(setShowSuccessMessageThunk("PNS lagret"));
      setIsDirty(false);
    } catch (error) {
      setShowFailedAlert(true);
    }
  };

  return (
    <>
      {showFailedAlert === true && <FailedAlert errorMessage="Failed to start PNS app" />}
      {selectedNode != null && <PnsItemModal toggle={toggle} projectId={project._id} isOpen node={selectedNode} />}
      <Prompt when={isDirty === true} message={"Du har ulagrede endringer, er du sikker på at du vil forlate siden?"} />
      <div className="d-flex h-100">
        <div
          hidden={displayFullScreen === true || viewerMode === true}
          className="pr-2 w-50"
          style={{ borderRight: "1px solid #d3d3d3" }}
        >
          <HierarchyEditor
            onNodeChange={() => setIsDirty(true)}
            rootNode={pnsRootNode}
            onSave={(rootNode) => {
              saveStructure(rootNode);
              setIsDirty(false);
            }}
            importButton={
              // <PNSExcelImport
              //   hasImportedData={importedData != null}
              //   onReset={() => {
              //     setImportedData(null);
              //     setIsDirty(false);
              //   }}
              //   onValidated={(pnsTree) => {
              //     setIsDirty(true);
              //     setImportedData(pnsTree);
              //   }}
              // />
              <PNSExcelImportWithSpecTable
                hasImportedData={importedData != null}
                onReset={() => {
                  setImportedData(null);
                  setIsDirty(false);
                }}
                onValidated={(pnsTree) => {
                  setIsDirty(true);
                  setImportedData(pnsTree);
                }}
              />
            }
          />
        </div>
        <div className={`d-flex ${displayFullScreen === true || viewerMode === true ? "w-100" : "w-50"} pl-2`}>
          {nodes?.length > 0 && (
            <ParentChildChart
              fullScreen={displayFullScreen}
              onToggleFullScreen={() => setDisplayFullScreen(displayFullScreen === false)}
              items={nodes}
              onClick={onClickItem}
              viewerMode={viewerMode}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default PNS;
