import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useState } from "react";
import { IChecklistItem } from "../../../../types/checklist.types";
import { IRisiko } from "../../../../types/global.types";
import { IMeeting } from "../../../../types/meeting.types";
import { AG_GRID_LOCALE_NOR } from "../../agGridLocale";
import { AppModalEnums } from "../../frontendConstants";
import ClickableTableIdButton from "../ClickableTableIdButton/ClickableTableIdButton";

interface IConnection {
  _id: string;
  id: string;
  board: string;
  useLink: boolean;
  icon: "risiko-sm" | "check-sm" | "meeting";
  title: string;
}

const IssueConnectionsSection: React.FC<{
  projectId: string;
  connectedRisikos: string[] | IRisiko[];
  connectedChecklistItems: string[] | IChecklistItem[];
  connectedMeetingAgendas: string[] | IMeeting.AgendaItem[];
  onOpenConnectionModal: (issueId: string, issueBoardId: string, app: AppModalEnums) => void;
}> = ({ projectId, connectedRisikos, connectedChecklistItems, connectedMeetingAgendas, onOpenConnectionModal }) => {
  // connectedIssue
  // connectedMeeting
  // connectedTasks

  const [rows, setRows] = useState<IConnection[]>([]);
  const [columns, setColumns] = useState<ColDef<IConnection>[]>(null);

  useEffect(() => {
    const connectionRows: IConnection[] = [];

    if (connectedRisikos != null && connectedRisikos.length > 0 && typeof connectedRisikos[0] !== "string") {
      (connectedRisikos as IRisiko[])?.forEach((risiko) => {
        connectionRows.push({
          _id: risiko._id,
          id: risiko.id,
          board: risiko.risikoBoard,
          icon: "risiko-sm",
          title: risiko.title,
          useLink: true,
        });
      });
    }

    if (
      connectedChecklistItems != null &&
      connectedChecklistItems.length > 0 &&
      typeof connectedChecklistItems[0] !== "string"
    ) {
      (connectedChecklistItems as IChecklistItem[])?.forEach((item) => {
        connectionRows.push({
          _id: item._id,
          id: null,
          board: null,
          icon: "check-sm",
          title: item.title,
          useLink: false,
        });
      });
    }

    if (
      connectedMeetingAgendas != null &&
      connectedMeetingAgendas.length > 0 &&
      typeof connectedMeetingAgendas[0] !== "string"
    ) {
      const latestAgendaItems = new Map<string, IMeeting.AgendaItem>();

      (connectedMeetingAgendas as IMeeting.AgendaItem[])?.forEach((agenda) => {
        const key = `${agenda.meetingSerie ?? agenda._id}-${agenda.title}`;
        const existing = latestAgendaItems.get(key);

        if (existing == null || new Date(agenda.createdAt) > new Date(existing.createdAt)) {
          latestAgendaItems.set(key, agenda);
        }
      });

      latestAgendaItems.forEach((agenda) => {
        connectionRows.push({
          _id: agenda.meeting,
          id: agenda.id.toString(),
          board: null,
          icon: "meeting",
          title: agenda.title,
          useLink: true,
        });
      });
    }

    setRows(connectionRows);
  }, [connectedRisikos, connectedChecklistItems, connectedMeetingAgendas]);

  useEffect(() => {
    setColumns([
      {
        field: "_id",
        headerName: "_id",
        hide: true,
      },
      {
        field: "icon",
        headerName: "Type",
        cellRenderer: (params) => <TypeIcon appName={params.value} />,
        cellClass: "justify-content-center",
        width: 65,
        resizable: false,
      },
      {
        field: "id",
        headerName: "Id",
        width: 100,
        cellRenderer: (params) => {
          const row: IConnection = params.data;

          if (row.useLink && row.id !== null) {
            let cb;

            switch (row.icon) {
              case "risiko-sm":
                cb = () => onOpenConnectionModal(row._id, row.board, AppModalEnums.RISIKO);
                break;
              case "meeting":
                cb = () => {
                  const currentDomain = window.location.origin;
                  window.open(`${currentDomain}/#/project/${projectId}/meetings/${row._id}`, "_blank");
                };
                break;
              default:
                throw new Error("Cannot create callback for unknown connection type");
            }

            return <ClickableTableIdButton id={row.id} _id={row._id} setItem={cb} />;
          } else if (row.id !== null) {
            return <span>{row.id}</span>;
          } else {
            return <></>;
          }
        },
      },
      {
        field: "title",
        flex: 1,
        headerName: "Tittel",
      },
    ]);
  }, []);

  return (
    columns != null &&
    rows.length > 0 && (
      <div className="ag-theme-quartz h-100 w-100 overflow-auto" style={{ maxHeight: "300px" }}>
        <AgGridReact
          domLayout={"autoHeight"}
          rowData={rows}
          defaultColDef={{
            suppressHeaderMenuButton: true,
          }}
          headerHeight={35}
          columnDefs={columns}
          suppressClickEdit={true}
          animateRows={false}
          suppressContextMenu={true}
          localeText={AG_GRID_LOCALE_NOR}
        />
      </div>
    )
  );
};

export default IssueConnectionsSection;

const TypeIcon: React.FC<{
  appName: string;
}> = ({ appName }) => {
  return (
    appName != null && (
      <div
        style={{
          width: "30px",
          height: "30px",
        }}
      >
        <div
          style={{
            width: "100px",
            height: "100px",
            transform: "scale(0.3)",
            transformOrigin: "left top",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="appIconInnerBackground"
            style={{
              background: "#D3D3D3",
            }}
          />
          <div
            style={{
              position: "absolute",
              width: "80%",
              height: "80%",
            }}
          >
            <div
              className="appIcon"
              style={{
                backgroundColor: "#3e4245",
                maskImage: `url(appIcons/${appName}.svg)`,
                WebkitMask: `url(appIcons/${appName}.svg)`,
              }}
            />
          </div>
        </div>
      </div>
    )
  );
};
