import { CellEditRequestEvent, ColDef, ColDefField, GridOptions, RowDragEndEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Draft, WritableDraft } from "immer";
import { useEffect, useRef } from "react";
import { Button } from "reactstrap";
import { useImmer } from "use-immer";
import { AG_GRID_LOCALE_NOR } from "../../agGridLocale";

type ValidatorValue = string | number | boolean;
type TDataWithInnerId<TData> = TData & { ___id: number };
export type VETColValidator<TData, TValue = ValidatorValue> = {
  validate: (value: TValue, data: TData[], row: number, col: number) => boolean;
  errorMessage: string;
};

const Ids = {
  ___id: "___id",
  ___deleteRow: "___deleteRow",
  ___dragOrder: "___dragOrder",
} as const;

export const VETEmptyValidator = {
  validate: (value: string) => {
    return value != null && value !== "";
  },
  errorMessage: "Verdien skal ikke være tom",
};

function ViewEditTable<TData>({
  gridOptions,
  validators,
  columns,
  data,
  onSave,
  newItemTemplateObject,
  orderProp,
  canEdit = false,
  canAddRows = true,
  canRemovePredicate,
  dropdownContainer = "",
}: {
  gridOptions?: GridOptions<TData>;
  validators?: VETColValidator<TData, ValidatorValue>[];
  columns: ColDef<TData>[];
  data: TData[];
  onSave: (data: TData[]) => void;
  newItemTemplateObject: TData;
  orderProp?: ColDefField<TData>;
  canEdit?: boolean;
  canAddRows?: boolean;
  canRemovePredicate?: (item: TData) => boolean;
  dropdownContainer?: string;
}) {
  const gridRef = useRef<AgGridReact<TDataWithInnerId<TData>>>(null);
  const [editMode, setEditMode] = useImmer<boolean>(false);
  const [columnsInner, setColumnsInner] = useImmer<ColDef<TDataWithInnerId<TData>>[]>(
    columns as ColDef<TDataWithInnerId<TData>>[],
  );
  const [dataInner, setDataInner] = useImmer<TDataWithInnerId<TData>[]>([]);

  useEffect(() => {
    const _data = data.map((item, index) => ({ ...item, ___id: index }));
    setDataInner(_data);
  }, [data]);

  useEffect(() => {
    setColumnsInner(columns as ColDef<TDataWithInnerId<TData>>[]);
  }, [columns]);

  const deleteRowCol: ColDef<TData> = {
    colId: Ids.___deleteRow,
    headerName: "",
    cellRenderer: (params) => {
      let showRemoveButton = true;

      if (canRemovePredicate != null) {
        showRemoveButton = canRemovePredicate(params.node.data);
      }

      return (
        showRemoveButton && (
          <Button
            color="danger"
            outline={true}
            size="sm"
            onClick={() => params.node?.rowIndex != null && params.onDelete(params.node.rowIndex)}
          >
            <i className="fa fa-trash fa-fw" />
          </Button>
        )
      );
    },
    width: 80,
    suppressHeaderMenuButton: true,
    suppressSizeToFit: true,
    cellRendererParams: {
      onDelete: (rowIndex: number) => {
        setDataInner((draft) => {
          draft.splice(rowIndex, 1);
        });
      },
    },
  };

  const dragOrderCol: ColDef<TData> = {
    colId: Ids.___dragOrder,
    headerName: "",
    width: 50,
    suppressHeaderMenuButton: true,
    suppressSizeToFit: true,
    rowDrag: true,
  };

  useEffect(() => {
    const hasDeleteRowCol = columnsInner.find((col) => col.colId === Ids.___deleteRow) != null;
    const hasDragOrderCol = columnsInner.find((col) => col.colId === Ids.___dragOrder) != null;

    setColumnsInner((draft) => {
      if (canEdit === true && editMode === true) {
        if (canAddRows === true && hasDeleteRowCol === false) {
          draft.splice(0, 0, deleteRowCol as WritableDraft<ColDef<TDataWithInnerId<TData>, any>>);
        }

        if (orderProp != null && hasDragOrderCol === false) {
          draft.splice(0, 0, dragOrderCol as WritableDraft<ColDef<TDataWithInnerId<TData>, any>>);
        }
      }

      // remove any column with cellRendererParams.editModeHidden !== true
      draft.forEach((col) => {
        col.hide = col.cellRendererParams?.editModeHidden === true && editMode === true;
      });
    });
  }, [orderProp, canEdit, editMode, columns]);

  useEffect(() => {
    if (gridRef.current?.api == null) {
      return;
    }

    gridRef.current.api.setGridOption("suppressCellFocus", canEdit === false || editMode === false);
    gridRef.current.api.setGridOption("suppressClickEdit", canEdit === false || editMode === false);
  }, [canEdit, editMode]);

  const onCellEditRequest = (event: CellEditRequestEvent<TDataWithInnerId<TData>>) => {
    setDataInner((draft) => {
      const draftRow = draft[event.rowIndex];
      // handle when event.colDef.field is multiple levels deep
      const fieldParts = event.colDef.field.split(".");
      const lastField = fieldParts.pop();
      const field = fieldParts.reduce((acc, curr) => acc[curr], draftRow);
      field[lastField as string] = event.newValue;
    });
  };

  const abort = () => {
    setDataInner(data.map((item, index) => ({ ...item, ___id: index })));
    setColumnsInner(columns as ColDef<TDataWithInnerId<TData>>[]);
    setEditMode(false);
  };

  const onSaveChanges = () => {
    const rows = dataInner.map((item) => {
      const i = { ...item };
      delete i["___id"];
      return i;
    });

    if (validators?.length > 0) {
      const errors = columns.reduce((acc, col, colIndex) => {
        if (col.field != null) {
          const validator = validators[colIndex];

          if (validator != null) {
            rows.forEach((row, rowIndex) => {
              const value = row[col.field as string];

              if (validator.validate(value, rows, rowIndex, colIndex) === false) {
                acc.push(
                  `Rad ${rowIndex + 1}, kolonne ${colIndex + 1} er ugyldig.\nVerdi: ${value ?? ""}\nFel: ${
                    validator.errorMessage
                  }`,
                );
              }
            });
          }
        }

        return acc;
      }, []);

      if (errors.length > 0) {
        alert(errors.join("\n"));
        return;
      }
    }

    onSave(rows);
    setColumnsInner(columns as ColDef<TDataWithInnerId<TData>>[]);
    setEditMode(false);
  };

  const rowDragEnd = (event: RowDragEndEvent<TDataWithInnerId<TData>>) => {
    const updatedData: TDataWithInnerId<TData>[] = [];

    event.api.forEachNode((node, index) => {
      updatedData.push({ ...node.data, [orderProp as string]: index });
    });

    setDataInner(updatedData);
  };

  const addRow = () => {
    setDataInner((draft) => {
      const randomId = Math.floor(Math.random() * 1000000);
      draft.push({ ...newItemTemplateObject, ___id: randomId } as Draft<TDataWithInnerId<TData>>);
    });
  };

  return (
    <div className="d-flex flex-column ag-theme-alpine w-100">
      <AgGridReact
        suppressMovableColumns={true}
        domLayout="autoHeight"
        localeText={AG_GRID_LOCALE_NOR}
        ref={gridRef}
        getRowId={(e) => String(e.data.___id)}
        defaultColDef={{ suppressHeaderMenuButton: true }}
        onCellEditRequest={onCellEditRequest}
        readOnlyEdit={true}
        stopEditingWhenCellsLoseFocus={true}
        suppressContextMenu={true}
        suppressCellFocus={true}
        suppressClickEdit={true}
        enterNavigatesVerticallyAfterEdit={true}
        columnDefs={columnsInner}
        rowData={dataInner}
        animateRows={true}
        rowDragManaged={orderProp != null && canEdit === true && editMode === true}
        onRowDragEnd={(event) => rowDragEnd(event)}
        popupParent={document.getElementById(dropdownContainer) || undefined}
        {...(gridOptions as GridOptions<TDataWithInnerId<TData>>)}
      />
      {canEdit === true ? (
        <div className="d-flex mt-2">
          {editMode === true ? (
            <>
              {canAddRows === true && (
                <Button className="mr-2" color="primary" onClick={addRow}>
                  <i className="fa fa-plus fa-fw" />
                </Button>
              )}
              <Button className="mr-2" color="secondary" onClick={abort}>
                <i className="fa fa-times fa-fw" /> Avbryt
              </Button>
              <Button color="success" onClick={onSaveChanges}>
                <i className="fa fa-save fa-fw" /> Lagre
              </Button>
            </>
          ) : (
            <Button color="primary" onClick={() => setEditMode(true)}>
              <i className="fa fa-pencil fa-fw" /> Rediger
            </Button>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default ViewEditTable;
