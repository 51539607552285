import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Alert, Button, Card, CardBody, CardHeader } from "reactstrap";
import { FailedAlert, MCLoader, VSpace } from "../../../../components";
import { useEnableMcBimMutation, useGetProjectQuery } from "../../../projectRTK";
import { getProjectIdFromUrl } from "../../../utils";
import { useGetMcBimProjectQuery } from "./McBimRTK";
import ConnectBIM360ProjectModal from "../bim360Settings/ConnectBIM360ProjectModal";

const McBimActivate: React.FC = () => {
  const location = useLocation();

  const history = useHistory();
  const projectId = getProjectIdFromUrl(location.pathname);

  const [connectBim360ModalOpen, setConnectBim360ModalOpen] = React.useState(false);

  const { data: project, isLoading: projectLoading } = useGetProjectQuery(projectId);

  const mcbimIsActivated = project?.mcbim?.activated;

  const bim360Activated = project?.bim360?.projectId != null;

  const {
    data: mcbimProject,
    isLoading: mcbimProjectLoading,
    error: mcbimProjectError,
  } = useGetMcBimProjectQuery(projectId, {
    skip: project?.mcbim?.activated !== true,
  });

  React.useEffect(() => {
    const url = history.location.pathname;
    const urlToRedirectTo = `/project/${projectId}/bimai/mcbimsettings/activefiles`;
    if (mcbimProject != null && url !== urlToRedirectTo) {
      history.push(`/project/${projectId}/bimai/mcbimsettings/activefiles`);
    }
  }, [mcbimProject]);

  const [enableMcBim, { isLoading: enableMcBimLoading, error: createMcBimFailed }] = useEnableMcBimMutation();

  const isLoading = enableMcBimLoading || projectLoading || mcbimProjectLoading;

  const isError = createMcBimFailed != null || mcbimProjectError != null;
  return (
    <>
      {connectBim360ModalOpen === true && <ConnectBIM360ProjectModal toggle={() => setConnectBim360ModalOpen(false)} />}
      {mcbimProjectError != null && <Alert color="danger">Failed to fetch mcbim project</Alert>}
      {mcbimIsActivated !== true && isLoading !== true && isError !== true && (
        <Card style={{ width: "500px" }}>
          <CardHeader>MCBIM</CardHeader>
          <CardBody>
            MCBIM tilbyr en omfattende tjeneste for effektiv lagring og håndtering av BIM-data fra IFC-filer. Ved å
            bruke våre avanserte funksjoner blir denne dataen tilgjengelig på ulike apper innenfor MCApps og kan også
            integreres sømløst med eksterne verktøy som Excel, PowerBI og Python-baserte applikasjoner. Ved å dra nytte
            av vår tjeneste kan brukere utføre ulike avanserte oppgaver, som validering, opprettelse av mengder og få
            statusrapporter for objekter. MCBIM streber etter å levere en kraftig og allsidig løsning for håndtering av
            BIM-data, som muliggjør effektiv bruk og analyse av bygningsinformasjon på en profesjonell måte.
            <VSpace />
            {bim360Activated !== true && (
              <Alert color="info">
                For å bruke MCBIM kreves det at en integrasjon er satt opp mot BIM360.
                <VSpace />
                <Button color="primary" block onClick={() => setConnectBim360ModalOpen(true)}>
                  <i className="fa fa-plug fa-fw" />
                  Koble ditt MCApps-prosjekt til BIM360.
                </Button>
              </Alert>
            )}
            {bim360Activated === true && (
              <Button block color="success" onClick={() => enableMcBim(projectId)}>
                <i className="fa fa-rocket fa-fw" />
                Aktiver MCBIM
              </Button>
            )}
          </CardBody>
        </Card>
      )}
      {createMcBimFailed != null && <FailedAlert />}
      {mcbimProjectError != null && <span>Failed to fetch mcbim project</span>}
      {isLoading === true && <MCLoader loading={true} />}
    </>
  );
};

export default McBimActivate;
