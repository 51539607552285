import * as R from "ramda";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { IProject, IRisikoEvaluationCategorySelection } from "../../../../../../../types/global.types";
import { IBootstrapTableColumnConfig } from "../../../../../types";
import { EditCategorySelections } from "./EditCategorySelections";
import { IRootState } from "../../../../../store";
import { setShowSuccessMessageThunk } from "../../../../../global/globalSlice";

interface IProps {
  project: IProject;
  name: string;
  selections: IRisikoEvaluationCategorySelection[];
  saveCb: (selections: IRisikoEvaluationCategorySelection[]) => void;
}

interface IState {
  editMode: boolean;
  selections: IRisikoEvaluationCategorySelection[];
}

class CategorySelections extends React.Component<IProps, IState> {
  private columns: IBootstrapTableColumnConfig[];
  private selectionTemplate: IRisikoEvaluationCategorySelection;

  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      selections: R.clone(this.props.selections),
    };

    this.columns = [
      {
        dataField: "name",
        text: "Navn",
      },
      {
        dataField: "description",
        text: "Beskrivelse",
      },
      {
        dataField: "value",
        text: "Verdi",
      },
    ];

    this.selectionTemplate = {
      name: "",
      description: "",
      value: null,
    };
  }

  addSelectionItem = () => {
    const _selections = [...this.state.selections, R.clone(this.selectionTemplate)];
    this.setState({ selections: _selections });
  };

  setEditSelection = (bool: boolean) => {
    this.setState({ selections: R.clone(this.props.selections) }, () => {
      this.setState({ editMode: bool });
    });
  };

  save = (selections: IRisikoEvaluationCategorySelection[]) => {
    this.setState({ editMode: false });
    this.props.saveCb(selections);
  };

  render() {
    return (
      <div>
        <div className="d-flex">
          <h5 className="align-self-center" style={{ marginTop: "3px" }}>
            {this.props.name}
          </h5>
          <div className="flex-fill" />
          {this.state.editMode === false && (
            <Button onClick={() => this.setEditSelection(true)} color="primary" outline>
              Rediger
              <i className="fa fa-pencil fa-fw" />
            </Button>
          )}
        </div>

        {this.state.editMode === false && (
          <BootstrapTable
            keyField="_id"
            data={this.props.selections.sort((a, b) => {
              return parseFloat(String(a.value)) - parseFloat(String(b.value));
            })}
            columns={this.columns}
          />
        )}
        {this.state.editMode && (
          <EditCategorySelections
            save={this.save}
            cancel={this.setEditSelection}
            addSelectionItem={this.addSelectionItem}
            selections={this.state.selections}
          />
        )}
      </div>
    );
  }
}
//
const mapStateToProps = (state: IRootState) => ({
  project: state.adminReducer.project,
});
// @ts-ignore
export default connect(mapStateToProps, {
  setShowSuccessMessageThunk,
})(CategorySelections);
