import React from "react";
import { AppModalEnums } from "../frontendConstants";
import { useAppDispatch, useAppSelector } from "../hooks";
import IssueItemModal from "./routes/issuesApp/IssuesTab/IssueItemModal/IssueItemModal";
import MeetingSerieModal from "./routes/projectdashboard/MeetingSeries/MeetingSerieModal";
import RisikoItemModalWrapper from "./routes/risiko/RisikoItemModalWrapper";
import { removeAppModalInRedux } from "../global/globalSlice";

const OpenedAppModals: React.FC = () => {
  const openedAppModals = useAppSelector((state) => state.globalReducer.openedAppModals);

  const dispatch = useAppDispatch();

  return (
    <>
      {openedAppModals.map((modal) => (
        <div key={modal.itemId}>
          {modal.app === AppModalEnums.ISSUE && modal.boardId != null && modal.projectId != null && (
            <>
              <IssueItemModal itemId={modal.itemId} issueBoardId={modal.boardId} projectId={modal.projectId} />
            </>
          )}
          {modal.app === AppModalEnums.RISIKO && (
            <RisikoItemModalWrapper projectId={modal.projectId} risikoId={modal.itemId} boardId={modal.boardId} />
          )}
          {modal.app === AppModalEnums.MEETING_SERIE && (
            <MeetingSerieModal
              projectId={modal.projectId}
              meetingSerieId={modal.itemId}
              toggle={() => dispatch(removeAppModalInRedux(modal.itemId))}
              startIsoString={modal.startIsoString}
            />
          )}
        </div>
      ))}
    </>
  );
};

export default OpenedAppModals;
