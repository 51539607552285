import React, { useState } from "react";
import { Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { IRiskoBoard } from "../../../../../types/global.types";
import { HSpace, McDropdown, McInput, MCLoader, VSpace } from "../../../components";

const CreateNewRisikoBoardModal: React.FC<{
  isOpen: boolean;
  toggle: () => void;
  createNew: (name: string, selectedTemplate?: IRiskoBoard) => void;
  createNewTemplate: (name) => void;
  risikoTemplates: IRiskoBoard[];
  isTemplateProject: boolean;
  loading: boolean;
}> = ({ isOpen, toggle, createNew, createNewTemplate, risikoTemplates, isTemplateProject, loading }) => {
  const [name, setName] = useState<string>("");
  const [selectedTemplate, setSelectedTemplate] = useState<IRiskoBoard>(null);

  const [templateMode, setTemplateMode] = useState<"STANDARD" | "FROM_TEMPLATE">("STANDARD");
  const clearAndToggleModal = () => {
    toggle();
    setName("");
    setSelectedTemplate(null);
  };

  React.useEffect(() => {
    if (templateMode === "STANDARD") {
      setSelectedTemplate(null);
    }
  }, [templateMode]);

  return (
    <Modal isOpen={isOpen} toggle={clearAndToggleModal}>
      <ModalHeader className="gray-dark" toggle={clearAndToggleModal}>
        Nytt register
      </ModalHeader>
      <ModalBody>
        <MCLoader loading={loading} />
        {loading !== true && (
          <>
            <McInput
              autoFocus={true}
              className="mb-2"
              placeholder={isTemplateProject === false ? "Angi navnet på registeret" : "Ange navnet på malen"}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {isTemplateProject === false && risikoTemplates?.length > 0 && (
              <div>
                <ButtonGroup>
                  <Button onClick={() => setTemplateMode("STANDARD")} active={templateMode === "STANDARD"}>
                    Standard
                  </Button>
                  <Button onClick={() => setTemplateMode("FROM_TEMPLATE")} active={templateMode === "FROM_TEMPLATE"}>
                    Fra mal
                  </Button>
                </ButtonGroup>
                <VSpace />
                {templateMode === "FROM_TEMPLATE" && (
                  <McDropdown
                    onChange={(_, template) => {
                      if (template == null) {
                        setSelectedTemplate(null);
                      } else {
                        setSelectedTemplate(template);
                      }
                    }}
                    onClear={selectedTemplate !== null ? () => setSelectedTemplate(null) : null}
                    currentValue={selectedTemplate}
                    options={risikoTemplates}
                    displayKey="name"
                    defaultTitle="Velg mal"
                    color="mc-blue"
                  />
                )}
              </div>
            )}
          </>
        )}
      </ModalBody>
      {loading !== true && (
        <ModalFooter>
          {isTemplateProject === false && (
            <Button disabled={name === ""} color="success" onClick={() => createNew(name, selectedTemplate)}>
              Opprett
            </Button>
          )}
          {isTemplateProject === true && (
            <Button disabled={name === ""} color="success" onClick={() => createNewTemplate(name)}>
              Opprett ny mal
            </Button>
          )}
          <HSpace />
          <Button color="secondary" onClick={toggle}>
            Avbryt
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default CreateNewRisikoBoardModal;
