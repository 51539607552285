import classnames from "classnames";
import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import ChecklistSelector from "./ChecklistSelector";
import { setActiveChecklistTabInRedux } from "./checklistSlice";

const ChecklistTabs: React.FC<{
  activeTab: string;
}> = ({ activeTab }) => {
  const dispatch = useAppDispatch();
  const checklist = useAppSelector((state) => state.checklistReducer.checklist);

  return (
    <Nav tabs className="w-100 pt-2 px-2">
      <NavItem className="custom">
        <NavLink
          className={classnames({ active: activeTab === "CHECKLIST", custom: true })}
          onClick={() => {
            dispatch(setActiveChecklistTabInRedux("CHECKLIST"));
          }}
        >
          {checklist.isTemplate === true ? "Sjekklistemal" : "Sjekkliste"}
        </NavLink>
      </NavItem>
      <NavItem className="custom">
        <NavLink
          className={classnames({ active: activeTab === "LOGS", custom: true })}
          onClick={() => {
            dispatch(setActiveChecklistTabInRedux("LOGS"));
          }}
        >
          Logg
        </NavLink>
      </NavItem>
      <NavItem className="custom">
        <NavLink
          className={classnames({ active: activeTab === "SETTINGS", custom: true })}
          onClick={() => {
            dispatch(setActiveChecklistTabInRedux("SETTINGS"));
          }}
        >
          Innstillinger
        </NavLink>
      </NavItem>
      <div className="flex-fill" />
      <ChecklistSelector />
    </Nav>
  );
};

export default ChecklistTabs;
