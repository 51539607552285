import React, { useEffect, useRef, useState } from "react";

import type { ColDef, GetRowIdParams, GridApi, GridReadyEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";

import "./styles.css";
import { HSpace, McDropdown, McInput, McTitle, VSpace } from "../../index";
import { useImmer } from "use-immer";
import { IOtherAttributesFormula, IOtherAttributesFormulaConfItem } from "mc-shared/zod/otherAttributesSchema";
import { ISSUE_COLUMN_LABELS, ISSUE_DATAFILEDS_ENUM } from "../../../app/routes/issuesApp/issueConstants";
import { useGetSingleIssueBoardQuery } from "../../../app/routes/issuesApp/IssueBoardRTK";
import { getProjectIdFromUrl } from "../../../app/utils";
import { getIssueBoardIdFromUrl } from "../../../app/routes/issuesApp/issueUtils";
import { useHistory } from "react-router-dom";
import { Button, Card, CardBody, CardTitle } from "reactstrap";

const rowClassRules = {
  "green-row": 'data.type == "DELIMITER"',
  "blue-row": 'data.type == "ATTRIBUTE"',
  "red-row": 'data.type == "COUNTER"',
};

const defaultColDef: ColDef = {
  flex: 1,
  minWidth: 100,
  filter: true,
};

const FormulaConfig: React.FC<{
  formula: IOtherAttributesFormula;
  onFormulaConfUpdate: (formula: IOtherAttributesFormula) => void;
}> = ({ formula, onFormulaConfUpdate }) => {
  const [leftApi, setLeftApi] = useState<GridApi | null>(null);
  const [rightApi, setRightApi] = useState<GridApi | null>(null);
  const [leftRowData, setLeftRowData] = useImmer<IOtherAttributesFormulaConfItem[]>([]);
  const [rightRowData, setRightRowData] = useImmer<IOtherAttributesFormulaConfItem[]>([]);

  const history = useHistory();
  const projectId = getProjectIdFromUrl(history.location.pathname);
  const issueBoardId = getIssueBoardIdFromUrl(history.location.pathname);

  const { data: issueBoard } = useGetSingleIssueBoardQuery({
    projectId: projectId,
    issueBoardId: issueBoardId,
  });

  const otherAttributesConfig = issueBoard.otherAttributesConfig;

  const leftColumns: ColDef<IOtherAttributesFormulaConfItem>[] = [
    { field: "id", hide: true },
    {
      rowDrag: true,
      field: "value",
      headerName: "Attribut",
      width: 200,
      cellRenderer: (params) => {
        return getAttributeLabel(params.value);
      },
    },
  ];

  const RightColumns: ColDef<IOtherAttributesFormulaConfItem>[] = [
    { field: "id", hide: true },
    { field: "type", hide: true },
    {
      rowDrag: true,
      field: "value",
      filter: false,
      headerName: "Attribut",
      cellRenderer: (params) => {
        const rowId = params.data.id;
        const label =
          params.data.type == "COUNTER"
            ? "Enumerator"
            : params.data.type === "DELIMITER"
            ? params.value
            : getAttributeLabel(params.value);
        return (
          <span>
            <i
              style={{ cursor: "pointer" }}
              onClick={() =>
                onFormulaConfUpdate({
                  ...formula,
                  confArr: rightRowData.filter((item) => item.id !== rowId),
                })
              }
              className="fa fa-trash fa-fw"
            />
            {label}
          </span>
        );
      },
    },
  ];

  useEffect(() => {
    const items = [
      {
        id: ISSUE_DATAFILEDS_ENUM.BUCKET as string,
        type: "ATTRIBUTE" as "ATTRIBUTE",
        value: ISSUE_DATAFILEDS_ENUM.BUCKET,
      },
      {
        id: ISSUE_DATAFILEDS_ENUM.STATUS,
        type: "ATTRIBUTE" as "ATTRIBUTE",
        value: ISSUE_DATAFILEDS_ENUM.STATUS,
      },
      {
        id: ISSUE_DATAFILEDS_ENUM.TITLE,
        type: "ATTRIBUTE" as "ATTRIBUTE",
        value: ISSUE_DATAFILEDS_ENUM.TITLE,
      },
    ];
    setLeftRowData(items);
    const attributeItems = Object.entries(otherAttributesConfig || {}).reduce<IOtherAttributesFormulaConfItem[]>(
      (acc, [key, value]) => {
        if (value.typeOfValue !== "FORMULA") {
          const item: IOtherAttributesFormulaConfItem = {
            id: key,
            type: "ATTRIBUTE",
            value: key,
          };
          acc.push(item);
        }
        return acc;
      },
      [],
    );
    setLeftRowData((draft) => {
      draft.push(...attributeItems);
    });
  }, []);

  useEffect(() => {
    let _formula: IOtherAttributesFormula = null;
    if (formula == null) {
      _formula = {
        nrOfCharsInCounter: 3,
        confArr: [],
      };
    } else {
      _formula = formula;
    }
    const rows = (_formula.confArr ?? []).map((item) => {
      return {
        id: item.id ?? Math.random().toString(36).substring(7),
        type: item.type,
        value: item.value,
      };
    });
    setRightRowData(rows);
  }, [formula]);

  const eLeftGrid = useRef(null);
  const eRightGrid = useRef(null);

  const getRowId = (params: GetRowIdParams) => String(params.data.id);

  const addGridDropZone = (side: string, api: GridApi) => {
    const dropApi = side === "Left" ? rightApi : leftApi;
    const dropZone = dropApi!.getRowDropZoneParams();

    api.addRowDropZone(dropZone);
  };

  useEffect(() => {
    if (rightApi && leftApi) {
      addGridDropZone("Right", rightApi);
      addGridDropZone("Left", leftApi);
    }
  });

  const onGridReady = (side: string, params: GridReadyEvent) => {
    if (side === "Left") {
      setLeftApi(params.api);
    } else {
      setRightApi(params.api);
    }
  };

  const onRowDragEnd = (grid: string, event: any) => {
    if (grid === "Left") {
      return;
    }
    const rows = event.api?.getRenderedNodes();
    onFormulaConfUpdate({
      ...formula,
      confArr: rows.map((row) => {
        return {
          id: row.data.id,
          type: row.data.type,
          value: row.data.value,
        };
      }),
    });
  };

  const getInnerGridCol = (side: string) => (
    <div className="inner-col">
      <div style={{ height: "100%" }} className="inner-col" ref={side === "Left" ? eLeftGrid : eRightGrid}>
        <AgGridReact
          defaultColDef={defaultColDef}
          getRowId={getRowId}
          rowClassRules={rowClassRules}
          rowDragManaged={true}
          suppressMoveWhenRowDragging={true}
          readOnlyEdit={true}
          onRowDragEnd={(event) => onRowDragEnd(side, event)}
          rowData={side === "Left" ? leftRowData ?? [] : rightRowData ?? []}
          columnDefs={side === "Left" ? leftColumns : RightColumns}
          onGridReady={(params) => onGridReady(side, params)}
        />
      </div>
    </div>
  );

  const addOwnDelimiter = (str: string) => {
    onFormulaConfUpdate({
      ...formula,
      confArr: [
        ...formula.confArr,
        {
          id: Math.random().toString(36).substring(7),
          type: "DELIMITER",
          value: str,
        },
      ],
    });
    setInputValue("");
  };

  const blurOnEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      addOwnDelimiter(inputValue);
      setInputValue("");
      return;
    }
  };

  const [inputValue, setInputValue] = useState("");

  const addCounter = () => {
    if (rightRowData.find((row) => row.type === "COUNTER")) {
      return;
    }
    onFormulaConfUpdate({
      ...formula,
      confArr: [
        ...formula.confArr,
        {
          id: Math.random().toString(36).substring(7),
          type: "COUNTER",
          value: "Counter",
        },
      ],
    });
  };

  const counterIsAdded = rightRowData.find((row) => row.type === "COUNTER") != null;

  const generatedSting = rightRowData
    .map((row) => {
      if (row.type === "ATTRIBUTE") {
        return ISSUE_COLUMN_LABELS[row.value as ISSUE_DATAFILEDS_ENUM]?.toUpperCase() || row.value;
      }
      if (row.type === "COUNTER") {
        return getLabelForCounter(formula.nrOfCharsInCounter);
      }
      return row.value;
    })
    .join("");

  const getAttributeLabel = (value: string) => {
    const label = ISSUE_COLUMN_LABELS[value as ISSUE_DATAFILEDS_ENUM];
    if (label) {
      return label;
    }
    const otherAttribute = otherAttributesConfig[value];
    if (otherAttribute) {
      return `(${value}) ${otherAttribute.label}`;
    }
  };

  const nrOfCharsOptions = [
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
  ];

  return (
    <>
      <VSpace />
      <Card className="bg-light h-100 w-100">
        <CardBody>
          <CardTitle tag="h5">Konfigurasjon for formell</CardTitle>
          <div className="ag-theme-alpine" style={{ height: 500, width: "100%" }}>
            <div className="d-flex flex-column h-100">
              <VSpace />
              <div className="bg-warning p-2 border-radius">
                <VSpace />
                <h6>{generatedSting}</h6>
              </div>
              <VSpace />
              <div className="d-flex h-100 w-100">
                {getInnerGridCol("Left")}
                <HSpace />
                <div className="d-flex flex-column h-100">
                  <McTitle title="Skriv inn tegn">
                    <McInput
                      autoFocus
                      type="text"
                      value={inputValue}
                      placeholder="Legg til valg"
                      className="mcinput"
                      style={{ maxWidth: "200px", backgroundColor: "#c7e8c5" }}
                      onChange={(e) => setInputValue(e.target.value)}
                      onKeyDown={blurOnEnter}
                      onBlur={(e) => {
                        if (e.target.value.length > 0) {
                          setInputValue("");
                        }
                      }}
                    />
                    <VSpace />
                  </McTitle>
                  <Button color="danger" disabled={counterIsAdded} onClick={() => addCounter()}>
                    <i className="fa fa-plus fa-fw" />
                    {counterIsAdded ? "Enumerator lagt til" : "Legg til Enumerator"}
                  </Button>
                  <VSpace />
                  <McTitle title="Antall tegn i enumerator">
                    <McDropdown
                      currentValue={nrOfCharsOptions.find((o) => o.value === formula.nrOfCharsInCounter)}
                      onChange={(_, valueAndLabel) =>
                        onFormulaConfUpdate({
                          ...formula,
                          nrOfCharsInCounter: valueAndLabel.value,
                        })
                      }
                      options={nrOfCharsOptions}
                      displayKey="label"
                    />
                  </McTitle>
                </div>
                <HSpace />
                {getInnerGridCol("Right")}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default FormulaConfig;

const getLabelForCounter = (nrOfChars: number): string => {
  // nr of chars = 3 => "001", nr of chars = 4 => "0001", nr of chars = 5 => "00001", etc
  if (nrOfChars === 3) {
    return "001";
  }
  if (nrOfChars === 4) {
    return "0001";
  }
  if (nrOfChars === 2) {
    return "01";
  }
};
