import React from "react";
import { Button } from "reactstrap";
import { HSpace } from "../../index";
import MainFilterBar from "../../MainFilterBar/MainFilterBar";
import { IMultipleFilterOptions } from "../../../types";
import * as R from "ramda";
import EmbeddedMongoDbAtlasChart from "../../EmbeddedMongoDBAtlasChart/EmbeddedMongoDbAtlasChart";
//import EmbeddedMongoDbAtlasChart from "../../EmbeddedMongoDBAtlasChart/EmbeddedMongoDbAtlasChart";

//test

interface IAtlasMultiFilter extends IMultipleFilterOptions {
  filterKey: string;
}

const FILTERS: IAtlasMultiFilter[] = [
  {
    options: [
      {
        label: "Structure (STR)",
        value: "Structure (STR)",
        isActive: false,
      },
      {
        label: "Geotechnics (GEO)",
        value: "Geotechnics (GEO)",
        isActive: false,
      },
      {
        label: "Landscape (LAN)",
        value: "Landscape (LAN)",
        isActive: false,
      },
      {
        label: "Road (ROA)",
        value: "Road (ROA)",
        isActive: false,
      },
      {
        label: "Water and sewage (WSS)",
        value: "Water and sewage (WSS)",
        isActive: false,
      },
      {
        label: "Other",
        value: "Other",
        isActive: false,
      },
    ],
    title: "Disiplin",
    filterKey: "Discipline",
  },
];
//
const SotraValidering: React.FC = () => {
  const [activeFiltersInChart, setActiveFiltersInChart] = React.useState<IAtlasMultiFilter[]>(FILTERS);
  const [selectedFilters, setSelectedFilters] = React.useState<IAtlasMultiFilter[]>(FILTERS);
  //const chartsFilterObject={Discipline: "Structure (STR)"}

  const [chartsFilterObject, setChartsFilterObject] = React.useState<any>(null);

  const filtersAreEqual = R.equals(activeFiltersInChart, selectedFilters);

  const toggleIssueBoardMultiFilterItem = (filterTitle: string, value: string) => {
    const _selectedFilters = R.clone(selectedFilters);
    let filterIndex = _selectedFilters.findIndex((filterItem) => filterItem.title === filterTitle);
    if (filterIndex === -1) {
      console.warn("filter index is -1");
      return;
    }
    const valueInFilterIndex = _selectedFilters[filterIndex].options.findIndex((opt) => opt.value === value);
    if (valueInFilterIndex === -1) {
      console.warn("valueInFilterIndex is -1");
      return;
    }
    _selectedFilters[filterIndex].options[valueInFilterIndex].isActive =
      !_selectedFilters[filterIndex].options[valueInFilterIndex].isActive;
    setSelectedFilters(_selectedFilters);
  };

  const clearAllFilters = () => {
    setSelectedFilters(FILTERS);
    setActiveFiltersInChart(FILTERS);
    setChartsFilterObject(null);
  };
  const runFilter = () => {
    const mongoQuery = convertAllFiltersToMongoFindQuery(selectedFilters);
    setChartsFilterObject(mongoQuery);
    setActiveFiltersInChart(selectedFilters);
  };

  return (
    <div className="d-flex flex-column h-100">
      <div className="border pt-2 px-2 d-flex w-100 mc-gray-200">
        <Button color="success" disabled={filtersAreEqual} onClick={runFilter}>
          Kjør filter!
          <i className="fa fa-play ml-2" />
        </Button>
        <HSpace />
        <MainFilterBar
          clearAllFilters={clearAllFilters}
          multipleFilterItems={selectedFilters}
          resetMultiFilter={function noRefCheck() {}}
          selectAllMultiFilter={function noRefCheck() {}}
          updateMultiFilterItemOption={toggleIssueBoardMultiFilterItem}
        />
      </div>
      <EmbeddedMongoDbAtlasChart chartId="6487e592-e3ca-4ef3-8e94-9ed1b95cdf6b" filters={chartsFilterObject} />
    </div>
  );
};

export default SotraValidering;

export const convertAllFiltersToMongoFindQuery = (atlasMultiFilters: IAtlasMultiFilter[]) => {
  const allFilters = atlasMultiFilters.reduce((acc, filtrItem) => {
    if (filtrItem.options?.length > 0) {
      const activeOptions = filtrItem.options.filter((option) => option.isActive === true);
      const orQuery = activeOptions.map((option) => {
        return {
          [filtrItem.filterKey]: option.value,
        };
      });
      if (orQuery.length > 0) {
        acc.push({
          ["$or"]: orQuery,
        });
      }
    }

    return acc;
  }, []);
  return {
    $and: [...allFilters],
  };
};
