import {
  FUNKSJONSKONTROLL_SIKKERHETSKABINETT_TESTER_METODER,
  FunksjonskontrollSikkerhetskabinettTesterEnum,
  UtstyrEnum,
} from "mc-shared/zod/report/GK/gkReportConstants";
import { IGKFunksjonskontrollSikkerhetskabinettResultater, IGKRapport } from "mc-shared/zod/report/GK/gkReportSchema";
import React from "react";
import { Card, CardBody, CardTitle, FormGroup, Input, Label } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../../../types/global.types";
import { DropdownFilterGeneric, McInput } from "../../../../../../../components";
import { beregnetDiffMellomToVerdier } from "../../gkGlobalUtils";

const FunksjonskontrollSikkerhetskabinett: React.FC<{
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
  data: IGKFunksjonskontrollSikkerhetskabinettResultater;
  sikkerhetskabinettklasse: string;
}> = ({ data, onUpdateEditData, sikkerhetskabinettklasse }) => {
  const addOrRemoveTest = (test: FunksjonskontrollSikkerhetskabinettTesterEnum) => {
    data.inndata.valgtMetode[test] = !data.inndata.valgtMetode[test];
    onUpdateEditData(
      "testResultater.funksjonskontrollSikkerhetskabinett.inndata.valgtMetode",
      data.inndata.valgtMetode,
    );
  };

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Funksjonskontroll av sikkerhetskabinett</CardTitle>
        <b>Velg hvilke tester som skal utføres?</b>
        <div className="w-25 mb-4">
          <DropdownFilterGeneric
            filterOptions={FUNKSJONSKONTROLL_SIKKERHETSKABINETT_TESTER_METODER.map((test) => {
              return {
                ...test,
                isActive: data.inndata.valgtMetode[test.value],
              };
            })}
            onClick={(value) => addOrRemoveTest(value)}
            title="Velg tester"
            color="secondary"
            textColor="text-black"
          />
        </div>
        {data.inndata.valgtMetode[FunksjonskontrollSikkerhetskabinettTesterEnum.VISUELLKONTROLLAVOVERFLATER] ===
          true && (
          <>
            <h4>Visuell kontroll av overflater</h4>
            <div>
              <FormGroup className="d-flex flex-column" check>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.visuellKontrollAvOverflaterResultater.godkjent === true}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollSikkerhetskabinett.inndata.visuellKontrollAvOverflaterResultater.godkjent",
                        true,
                      )
                    }
                  />
                  Godkjent
                </Label>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.visuellKontrollAvOverflaterResultater.godkjent === false}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollSikkerhetskabinett.inndata.visuellKontrollAvOverflaterResultater.godkjent",
                        false,
                      )
                    }
                  />
                  Ikke godkjent
                </Label>
              </FormGroup>
            </div>
            <div className="w-50 mt-2 mb-4">
              <b>Kommentar:</b>
              <McInput
                value={data.inndata.visuellKontrollAvOverflaterResultater.kommentar}
                onChange={(e) =>
                  onUpdateEditData(
                    "testResultater.funksjonskontrollSikkerhetskabinett.inndata.visuellKontrollAvOverflaterResultater.kommentar",
                    e.target.value,
                  )
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
          </>
        )}
        {data.inndata.valgtMetode[FunksjonskontrollSikkerhetskabinettTesterEnum.VISUELLKONTROLLAVAVTREKKSKANALER] ===
          true && (
          <>
            <h4>Visuell kontroll av avtrekkskanaler</h4>
            <div>
              <FormGroup className="d-flex flex-column" check>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.visuellKontrollAvAvtrekkskanalerResultater.godkjent === true}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollSikkerhetskabinett.inndata.visuellKontrollAvAvtrekkskanalerResultater.godkjent",
                        true,
                      )
                    }
                  />
                  Godkjent
                </Label>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.visuellKontrollAvAvtrekkskanalerResultater.godkjent === false}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollSikkerhetskabinett.inndata.visuellKontrollAvAvtrekkskanalerResultater.godkjent",
                        false,
                      )
                    }
                  />
                  Ikke godkjent
                </Label>
              </FormGroup>
            </div>
            <div className="w-50 mt-2 mb-4">
              <b>Kommentar:</b>
              <McInput
                value={data.inndata.visuellKontrollAvAvtrekkskanalerResultater.kommentar}
                onChange={(e) =>
                  onUpdateEditData(
                    "testResultater.funksjonskontrollSikkerhetskabinett.inndata.visuellKontrollAvAvtrekkskanalerResultater.kommentar",
                    e.target.value,
                  )
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
          </>
        )}
        {data.inndata.valgtMetode[FunksjonskontrollSikkerhetskabinettTesterEnum.KONTROLLAVTILBAKESLAGSSPJELD] ===
          true && (
          <>
            <h4>Kontroll av tilbakeslagsspjeld</h4>
            <div>
              <FormGroup className="d-flex flex-column" check>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.kontrollAvTilbakeslagsspjeldResultater.godkjent === true}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollSikkerhetskabinett.inndata.kontrollAvTilbakeslagsspjeldResultater.godkjent",
                        true,
                      )
                    }
                  />
                  Godkjent
                </Label>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.kontrollAvTilbakeslagsspjeldResultater.godkjent === false}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollSikkerhetskabinett.inndata.kontrollAvTilbakeslagsspjeldResultater.godkjent",
                        false,
                      )
                    }
                  />
                  Ikke godkjent
                </Label>
              </FormGroup>
            </div>
            <div className="w-50 mt-2 mb-4">
              <b>Kommentar:</b>
              <McInput
                value={data.inndata.kontrollAvTilbakeslagsspjeldResultater.kommentar}
                onChange={(e) =>
                  onUpdateEditData(
                    "testResultater.funksjonskontrollSikkerhetskabinett.inndata.kontrollAvTilbakeslagsspjeldResultater.kommentar",
                    e.target.value,
                  )
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
          </>
        )}
        {data.inndata.valgtMetode[FunksjonskontrollSikkerhetskabinettTesterEnum.MAALINGAVBELYSNING] === true && (
          <>
            <h4>Måling av belysning</h4>
            <div className="d-flex flex-column mb-2">
              <b>Fyll inn måleresultat &#40;lux&#41;:</b>
              <McInput
                value={data.inndata.maalingAvBelysningResultater.maaleresultat}
                onBlur={(e) =>
                  onUpdateEditData(
                    "testResultater.funksjonskontrollSikkerhetskabinett.inndata.maalingAvBelysningResultater.maaleresultat",
                    Number(e.target.value),
                  )
                }
                normalizeNumbers={true}
                type="text"
                className="w-25"
              />
            </div>
            <div>
              <FormGroup className="d-flex flex-column" check>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.maalingAvBelysningResultater.godkjent === true}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollSikkerhetskabinett.inndata.maalingAvBelysningResultater.godkjent",
                        true,
                      )
                    }
                  />
                  Godkjent
                </Label>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.maalingAvBelysningResultater.godkjent === false}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollSikkerhetskabinett.inndata.maalingAvBelysningResultater.godkjent",
                        false,
                      )
                    }
                  />
                  Ikke godkjent
                </Label>
              </FormGroup>
            </div>
            <div className="w-50 mt-2 mb-4">
              <b>Kommentar:</b>
              <McInput
                value={data.inndata.maalingAvBelysningResultater.kommentar}
                onChange={(e) =>
                  onUpdateEditData(
                    "testResultater.funksjonskontrollSikkerhetskabinett.inndata.maalingAvBelysningResultater.kommentar",
                    e.target.value,
                  )
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
          </>
        )}
        {data.inndata.valgtMetode[FunksjonskontrollSikkerhetskabinettTesterEnum.MAALINGAVLYDNIVAAER] === true && (
          <>
            <h4>Måling av lydnivåer</h4>
            <div className="d-flex flex-column mb-2">
              <b>Fyll inn måleresultat &#40;dB&#41;:</b>
              <McInput
                value={data.inndata.maalingAvLydnivaaerResultater.maaleresultat}
                onBlur={(e) =>
                  onUpdateEditData(
                    "testResultater.funksjonskontrollSikkerhetskabinett.inndata.maalingAvLydnivaaerResultater.maaleresultat",
                    Number(e.target.value),
                  )
                }
                normalizeNumbers={true}
                type="text"
                className="w-25"
              />
            </div>
            <div>
              <FormGroup className="d-flex flex-column" check>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.maalingAvLydnivaaerResultater.godkjent === true}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollSikkerhetskabinett.inndata.maalingAvLydnivaaerResultater.godkjent",
                        true,
                      )
                    }
                  />
                  Godkjent
                </Label>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.maalingAvLydnivaaerResultater.godkjent === false}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollSikkerhetskabinett.inndata.maalingAvLydnivaaerResultater.godkjent",
                        false,
                      )
                    }
                  />
                  Ikke godkjent
                </Label>
              </FormGroup>
            </div>
            <div className="w-50 mt-2 mb-4">
              <b>Kommentar:</b>
              <McInput
                value={data.inndata.maalingAvLydnivaaerResultater.kommentar}
                onChange={(e) =>
                  onUpdateEditData(
                    "testResultater.funksjonskontrollSikkerhetskabinett.inndata.maalingAvLydnivaaerResultater.kommentar",
                    e.target.value,
                  )
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
          </>
        )}
        {data.inndata.valgtMetode[FunksjonskontrollSikkerhetskabinettTesterEnum.KONTROLLOGKALIBRERING] === true && (
          <>
            <h4>Kontroll og kalibrering</h4>
            <div className="d-flex flex-column mb-2">
              <b>
                Fyll inn målt luftmengde &#40;m<sup>3</sup>/s&#41;:
              </b>
              <McInput
                value={data.inndata.kontrollOgKalibreringResultater.maaltLuftmengde}
                onBlur={(e) =>
                  onUpdateEditData(
                    "testResultater.funksjonskontrollSikkerhetskabinett.inndata.kontrollOgKalibreringResultater.maaltLuftmengde",
                    Number(e.target.value),
                  )
                }
                normalizeNumbers={true}
                type="text"
                className="w-25"
              />
            </div>
            <div className="d-flex flex-column mb-2">
              <b>
                Fyll inn avlest luftmengde &#40;m<sup>3</sup>/s&#41;:
              </b>
              <McInput
                value={data.inndata.kontrollOgKalibreringResultater.avlestLuftmengde}
                onBlur={(e) =>
                  onUpdateEditData(
                    "testResultater.funksjonskontrollSikkerhetskabinett.inndata.kontrollOgKalibreringResultater.avlestLuftmengde",
                    Number(e.target.value),
                  )
                }
                normalizeNumbers={true}
                type="text"
                className="w-25"
              />
            </div>
            <div className="d-flex justify-content-between">
              <p className="mb-0">
                Luftmengdedifferanse &#40;m<sup>3</sup>/s&#41; =
                <b>
                  {" "}
                  {beregnetDiffMellomToVerdier(
                    data.inndata.kontrollOgKalibreringResultater.avlestLuftmengde,
                    data.inndata.kontrollOgKalibreringResultater.maaltLuftmengde,
                  )?.toFixed(2) ?? ""}
                </b>
              </p>
              <p className="mb-0">Formel: &#40;Qd = Qm - Qa&#41;</p>
            </div>
            <hr className="mt-0" />
            {sikkerhetskabinettklasse === UtstyrEnum.SIKKERHETSKABINETTII && (
              <>
                <div className="d-flex flex-column mb-2">
                  <b>Fyll inn målt trykk &#40;Pa&#41;:</b>
                  <McInput
                    value={data.inndata.kontrollOgKalibreringResultater.maaltTrykk}
                    onBlur={(e) =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollSikkerhetskabinett.inndata.kontrollOgKalibreringResultater.maaltTrykk",
                        Number(e.target.value),
                      )
                    }
                    normalizeNumbers={true}
                    type="text"
                    className="w-25"
                  />
                </div>
                <div className="d-flex flex-column mb-2">
                  <b>Fyll inn avlest trykk &#40;Pa&#41;:</b>
                  <McInput
                    value={data.inndata.kontrollOgKalibreringResultater.avlestTrykk}
                    onBlur={(e) =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollSikkerhetskabinett.inndata.kontrollOgKalibreringResultater.avlestTrykk",
                        Number(e.target.value),
                      )
                    }
                    normalizeNumbers={true}
                    type="text"
                    className="w-25"
                  />
                </div>
                <div className="d-flex justify-content-between">
                  <p className="mb-0">
                    Trykkdifferanse &#40;Pa&#41; =
                    <b>
                      {" "}
                      {beregnetDiffMellomToVerdier(
                        data.inndata.kontrollOgKalibreringResultater.avlestTrykk,
                        data.inndata.kontrollOgKalibreringResultater.maaltTrykk,
                      )?.toFixed(2) ?? ""}
                    </b>
                  </p>
                  <p className="mb-0">Formel: &#40;Pd = Pm - Pa&#41;</p>
                </div>
                <hr className="mt-0" />
              </>
            )}
            <div className="w-50 mt-2 mb-4">
              <b>Kommentar:</b>
              <McInput
                value={data.inndata.kontrollOgKalibreringResultater.kommentar}
                onChange={(e) =>
                  onUpdateEditData(
                    "testResultater.funksjonskontrollSikkerhetskabinett.inndata.kontrollOgKalibreringResultater.kommentar",
                    e.target.value,
                  )
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
          </>
        )}
        {data.inndata.valgtMetode[FunksjonskontrollSikkerhetskabinettTesterEnum.DEKONTAMINERINGSARRANGEMENT] ===
          true && (
          <>
            <h4>Dekontamineringsarragement</h4>
            <div>
              <FormGroup className="d-flex flex-column" check>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.dekontamineringsarrangement.godkjent === true}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollSikkerhetskabinett.inndata.dekontamineringsarrangement.godkjent",
                        true,
                      )
                    }
                  />
                  Godkjent
                </Label>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.dekontamineringsarrangement.godkjent === false}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.funksjonskontrollSikkerhetskabinett.inndata.dekontamineringsarrangement.godkjent",
                        false,
                      )
                    }
                  />
                  Ikke godkjent
                </Label>
              </FormGroup>
            </div>
            <div className="w-50 mt-2 mb-4">
              <b>Kommentar:</b>
              <McInput
                value={data.inndata.dekontamineringsarrangement.kommentar}
                onChange={(e) =>
                  onUpdateEditData(
                    "testResultater.funksjonskontrollSikkerhetskabinett.inndata.dekontamineringsarrangement.kommentar",
                    e.target.value,
                  )
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default FunksjonskontrollSikkerhetskabinett;
