import { IGKEksternOgInternRoeykvisualiseringResultater, IGKRapport } from "mc-shared/zod/report/GK/gkReportSchema";
import React from "react";
import { Card, CardBody, CardTitle, FormGroup, Input, Label } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../../types/global.types";
import { McInput } from "../../../../../../components";
import ReportDocxImageContainer from "../../../ReportBoard/ReportDocxImageContainer";

const EksternOgInternRoeykvisualisering: React.FC<{
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
  data: IGKEksternOgInternRoeykvisualiseringResultater;
}> = ({ data, onUpdateEditData }) => {
  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Ekstern og intern røykvisualisering</CardTitle>
        <div className="w-50 mt-0">
          <b>Intern visualisering:</b>
          <div>
            <b>Beskrivelse av luftstrøm:</b>
            <McInput
              value={data.inndata.intern.beskrivelseAvLuftstroem}
              onChange={(e) =>
                onUpdateEditData(
                  "testResultater.eksternOgInternRoeykVisualisering.inndata.intern.beskrivelseAvLuftstroem",
                  e.target.value,
                )
              }
              type="textarea"
              dynamicHeight={true}
            />
          </div>
          <div>
            <FormGroup className="d-flex flex-column" check>
              <Label check>
                <Input
                  type="radio"
                  checked={data.inndata.intern.godkjent === true}
                  onChange={() =>
                    onUpdateEditData("testResultater.eksternOgInternRoeykVisualisering.inndata.intern.godkjent", true)
                  }
                />
                Godkjent
              </Label>
              <Label check>
                <Input
                  type="radio"
                  checked={data.inndata.intern.godkjent === false}
                  onChange={() =>
                    onUpdateEditData("testResultater.eksternOgInternRoeykVisualisering.inndata.intern.godkjent", false)
                  }
                />
                Ikke godkjent
              </Label>
            </FormGroup>
          </div>
        </div>
        <div className="mt-4">
          <ReportDocxImageContainer
            docxImageTag="internRoeykVisualisering"
            title="Bilde av intern luftstrøm med forstyrrelser"
          />
          <div className="w-50">
            <b>Kommentar:</b>
            <McInput
              value={data.inndata.intern.kommentar}
              onChange={(e) =>
                onUpdateEditData(
                  "testResultater.eksternOgInternRoeykVisualisering.inndata.intern.kommentar",
                  e.target.value,
                )
              }
              type="textarea"
              dynamicHeight={true}
            />
          </div>
        </div>
        <div className="w-50 mt-4">
          <b>Ekstern visualisering:</b>
          <div>
            <b>Beskrivelse av luftstrøm:</b>
            <McInput
              value={data.inndata.ekstern.beskrivelseAvLuftstroem}
              onChange={(e) =>
                onUpdateEditData(
                  "testResultater.eksternOgInternRoeykVisualisering.inndata.ekstern.beskrivelseAvLuftstroem",
                  e.target.value,
                )
              }
              type="textarea"
              dynamicHeight={true}
            />
          </div>
          <div>
            <FormGroup className="d-flex flex-column" check>
              <Label check>
                <Input
                  type="radio"
                  checked={data.inndata.ekstern.godkjent === true}
                  onChange={() =>
                    onUpdateEditData("testResultater.eksternOgInternRoeykVisualisering.inndata.ekstern.godkjent", true)
                  }
                />
                Godkjent
              </Label>
              <Label check>
                <Input
                  type="radio"
                  checked={data.inndata.ekstern.godkjent === false}
                  onChange={() =>
                    onUpdateEditData("testResultater.eksternOgInternRoeykVisualisering.inndata.ekstern.godkjent", false)
                  }
                />
                Ikke godkjent
              </Label>
            </FormGroup>
          </div>
        </div>
        <div className="mt-4">
          <ReportDocxImageContainer
            docxImageTag="eksternRoeykVisualisering"
            title="Bilde av ekstern luftstrøm med forstyrrelser"
          />
          <div className="w-50">
            <b>Kommentar:</b>
            <McInput
              value={data.inndata.ekstern.kommentar}
              onChange={(e) =>
                onUpdateEditData(
                  "testResultater.eksternOgInternRoeykVisualisering.inndata.ekstern.kommentar",
                  e.target.value,
                )
              }
              type="textarea"
              dynamicHeight={true}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default EksternOgInternRoeykvisualisering;
