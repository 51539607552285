import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { Comparator, dateFilter, textFilter } from "react-bootstrap-table2-filter";
import { IHistoryLogDepricated } from "../../../types/global.types";
import { formatDate, formatShortDate, isDateValidFrontend } from "../app/routes/admin/selectors";
import { isEmpty } from "../app/utils";
import { IBootstrapTableColumnConfig } from "../types";
import HSpace from "./HSpace";
import SingleTag from "./SingleTag/SingleTag";
import {
  createHeaderDropDownFilter,
  headerSortAndDropDownFilter,
  headerSortAndFilterFormatter,
  sortCaret,
} from "./columnGlobal";

const HistoryLogsDepricated: React.FC<{
  itemId: string;
  modelColumns: IBootstrapTableColumnConfig[];
  projectId: string;
  getLogs: (projectId: string, itemId: string) => Promise<IHistoryLogDepricated[]>;
}> = ({ itemId, modelColumns, projectId, getLogs }) => {
  const [logs, setLogs] = useState(null);
  const [columns, setColumns] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const _logs = await getLogs(projectId, itemId);
      _logs.map((log) => {
        const column = modelColumns.find((column) => column.dataField === log.column);
        log.columnDisplayName = column == null ? log.column : column.text;
        return log;
      });
      setLogs(_logs);
      const _hasUsername = _logs.some((log) => log.userName != null);
      const _hasUserDotName = _logs.some((log) => log.user?.name != null);
      _setColumns(_logs, _hasUserDotName, _hasUsername);
    }
    fetchData();
  }, []);

  const formatValue = (val: any) => {
    if (isEmpty(val)) {
      return "";
    }
    if (isDateValidFrontend(val)) {
      return formatDate(val);
    }
    try {
      if (val.startsWith("[")) {
        const valParsed = JSON.parse(val);
        if (valParsed.constructor === Array) {
          return (
            <div className="d-flex flex-wrap justify-content-start">
              {valParsed.map((i) => (
                <>
                  <SingleTag displayValue={i} key={i} />
                  <HSpace />
                </>
              ))}
            </div>
          );
        }
      }
    } catch (error) {
      console.warn(error);
    }

    return val;
  };

  const _setColumns = (logs, hasUserDotName, hasUsername) => {
    const _columns = [
      {
        dataField: "columnDisplayName",
        text: "Kolonne",
        filter: createHeaderDropDownFilter(logs, "columnDisplayName"),
        sort: true,
        sortCaret: sortCaret,
        headerFormatter: headerSortAndDropDownFilter,
      },
      {
        dataField: "_id",
        text: "_id",
        hidden: true,
      },
      {
        dataField: "newValue",
        text: "Ny verdi",
        filter: textFilter({ placeholder: "Tast inn..." }),
        sort: true,
        sortCaret: sortCaret,
        formatter: (cell) => formatValue(cell),
        headerFormatter: headerSortAndFilterFormatter,
      },
      {
        dataField: "oldValue",
        text: "Forrige verdi",
        filter: textFilter({ placeholder: "Tast inn..." }),
        sort: true,
        sortCaret: sortCaret,
        formatter: (cell) => formatValue(cell),
        headerFormatter: headerSortAndFilterFormatter,
      },
      {
        dataField: "userName",
        text: "Redigert av",
        filter: textFilter({ placeholder: "Tast inn..." }),
        sort: true,
        sortCaret: sortCaret,
        hidden: hasUsername === false,
        headerFormatter: headerSortAndFilterFormatter,
      },
      {
        dataField: "user.name",
        text: "Redigert av",
        filter: textFilter({ placeholder: "Tast inn..." }),
        sort: true,
        sortCaret: sortCaret,
        hidden: hasUserDotName === false,
        headerFormatter: headerSortAndFilterFormatter,
      },
      {
        dataField: "updatedAt",
        text: "Endret dato",
        formatter: (cell) => formatShortDate(cell),
        filter: dateFilter({
          dateClassName: "custom-date-class",
          comparatorClassName: "custom-compare-class",
          defaultValue: { comparator: Comparator.GT },
        }),
        sort: true,
        sortCaret: sortCaret,
        headerFormatter: headerSortAndFilterFormatter,
      },
    ];

    setColumns(_columns);
  };

  return (
    <>
      {logs != null && columns != null && (
        <BootstrapTable
          bootstrap4
          keyField="_id"
          classes={"table-sm wrap-anywhere"}
          striped
          bordered={false}
          wrapperClasses="smalltable"
          data={logs}
          filter={filterFactory()}
          defaultSorted={[
            {
              dataField: "updatedAt",
              order: "desc",
            },
          ]}
          columns={columns}
        />
      )}
    </>
  );
};

export default HistoryLogsDepricated;
