import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip } from "reactstrap";
import { HSpace } from "../../index";
import MainFilterBar from "../../MainFilterBar/MainFilterBar";
import { IMultipleFilterOptions } from "../../../types";
import * as R from "ramda";
import EmbeddedMongoDbAtlasChartWithGetData, {
  exportChartToExcel,
  IChartData,
} from "../../EmbeddedMongoDBAtlasChart/EmbeddedMongoDbAtlasChartWithGetData";

interface IAtlasMultiFilter extends IMultipleFilterOptions {
  filterKey: string;
  regex: boolean; //if value is a regular expression
}

const FILTERS: IAtlasMultiFilter[] = [
  {
    options: [
      {
        label: "Årskog: AAR",
        value: "AAR",
        isActive: false,
      },
      {
        label: "Børdalen: BOR",
        value: "^BOR",
        isActive: false,
      },
      {
        label: "Statnett: (K)BOR",
        value: "\\(K\\)BOR",
        isActive: false,
      },
    ],
    title: "Location",
    filterKey: "fileName",
    regex: true,
  },
  {
    options: [
      {
        label: "Architect: C",
        value: "-C-",
        isActive: false,
      },
      {
        label: "Electro: E",
        value: "-E-",
        isActive: false,
      },
      {
        label: "HVAC: H",
        value: "-H-",
        isActive: false,
      },
      {
        label: "Concrete: Q-BLD/Q-STA/Q-TCS/Q-OUT",
        value: "Q-BLD|Q-STA|Q-TCS|Q-OUT",
        isActive: false,
      },
      {
        label: "Structural Steel: Q-SST",
        value: "Q-SST",
        isActive: false,
      },
      {
        label: "Engineering Geology: Q-GEO",
        value: "Q-GEO",
        isActive: false,
      },
      {
        label: "Geotechnics: Q-GTE",
        value: "Q-GTE",
        isActive: false,
      },
      {
        label: "Landscaping: Q-LAN",
        value: "Q-LAN",
        isActive: false,
      },
      {
        label: "Road: Q-ROA",
        value: "Q-ROA",
        isActive: false,
      },
      {
        label: "Site Prep and Earth Works: Q-SPR",
        value: "Q-SPR",
        isActive: false,
      },
      {
        label: "Water and Sewerage: Q-WSS",
        value: "Q-WSS",
        isActive: false,
      },
    ],
    title: "Discipline",
    filterKey: "fileName",
    regex: true,
  },
];

const validationRules = [
  { fieldName: "Yggdrasil PfS.MUC_OBS", condition: "AA.BB.* - ^\\d{2}\\.\\d{2}\\.*/" },
  { fieldName: "Yggdrasil PfS.opeTask", condition: "beginning with 'AAR' or 'BOR" },
  { fieldName: "Yggdrasil PfS.opeStatus", condition: "beginning with S" },
  { fieldName: "Yggdrasil PfS.opeTagNo", condition: "beginning with P,Q,U or X" },
  { fieldName: "Yggdrasil PfS.opeproject", condition: "equal to 'Yggdrasil PfS'" },
  { fieldName: "Yggdrasil PfS.opeprojectID", condition: "equal to 'AAR' or 'BOR'" },
  { fieldName: "Yggdrasil PfS.opecontr", condition: "equal to 'MUC'" },
  { fieldName: "Yggdrasil PfS.opeDrawingNo", condition: "beginning with 'AAR' or 'BOR" },
  { fieldName: "Yggdrasil PfS.opePidNo ", condition: "not empty (only for HVAC, EL, WSS)" },
  { fieldName: "Yggdrasil PfS.opeLineNo", condition: "not empty (only for HVAC, EL, WSS)" },
];
//
const YggdrasilValidation: React.FC = () => {
  const [activeFiltersInChart, setActiveFiltersInChart] = React.useState<IAtlasMultiFilter[]>(FILTERS);
  const [selectedFilters, setSelectedFilters] = React.useState<IAtlasMultiFilter[]>(FILTERS);

  const [isPopupVisible, setIsPopupVisible] = React.useState<boolean>(false);
  const [popupChartFilter, setPopupChartFilter] = React.useState<{ fileName: string } | null>(null);
  const [isInfoVisible, setInfoVisible] = React.useState<boolean>(false);

  const [exportedChartData, setExportedChartData] = React.useState<IChartData>({ fields: {}, documents: [] });

  const receiveChartData = (data: IChartData) => {
    setExportedChartData(data);
  };

  const [exportedChartDataPerObject, setExportedChartDataPerObject] = React.useState<IChartData>({
    fields: {},
    documents: [],
  });

  const receiveChartDataPerObject = (data: IChartData) => {
    setExportedChartDataPerObject(data);
  };

  // Function to handle the chart click event
  const handleChartClick = (event: any, currentFilters: any) => {
    const selectedFileName = event.selectionFilter.fileName;
    // Set the filter for the modal window and show the other chart with filter
    if (selectedFileName) {
      setPopupChartFilter({ fileName: selectedFileName });
      setIsPopupVisible(true);
    }
  };

  const handleInfoClick = () => {
    setInfoVisible((prevState) => !prevState);
  };

  const [chartsFilterObject, setChartsFilterObject] = React.useState<any>(null);

  const filtersAreEqual = R.equals(activeFiltersInChart, selectedFilters);

  const toggleIssueBoardMultiFilterItem = (filterTitle: string, value: string) => {
    const _selectedFilters = R.clone(selectedFilters);
    let filterIndex = _selectedFilters.findIndex((filterItem) => filterItem.title === filterTitle);
    if (filterIndex === -1) {
      console.warn("filter index is -1");
      return;
    }
    const valueInFilterIndex = _selectedFilters[filterIndex].options.findIndex((opt) => opt.value === value);
    if (valueInFilterIndex === -1) {
      console.warn("valueInFilterIndex is -1");
      return;
    }
    _selectedFilters[filterIndex].options[valueInFilterIndex].isActive =
      !_selectedFilters[filterIndex].options[valueInFilterIndex].isActive;
    setSelectedFilters(_selectedFilters);
  };

  const clearAllFilters = () => {
    setSelectedFilters(FILTERS);
    setActiveFiltersInChart(FILTERS);
    setChartsFilterObject(null);
  };
  const runFilter = () => {
    const mongoQuery = convertAllFiltersToMongoFindQuery(selectedFilters);
    setChartsFilterObject(mongoQuery);
    setActiveFiltersInChart(selectedFilters);
  };

  React.useEffect(() => {
    // This code block will be executed whenever exportedChartData changes
    console.log("exportedChartData changed:", exportedChartData);
    console.log("exportedChartDataPerObject changed:", exportedChartDataPerObject);
  }, [exportedChartData, exportedChartDataPerObject]);

  return (
    <div className="d-flex flex-column h-100">
      <div className="border pt-2 px-2 d-flex w-100 mc-gray-200">
        <MainFilterBar
          clearAllFilters={clearAllFilters}
          multipleFilterItems={selectedFilters}
          resetMultiFilter={function noRefCheck() {}}
          selectAllMultiFilter={function noRefCheck() {}}
          updateMultiFilterItemOption={toggleIssueBoardMultiFilterItem}
        />
        <HSpace />
        <Button color="success" disabled={filtersAreEqual} onClick={runFilter}>
          Run filter!
          <i className="fa fa-play ml-2" />
        </Button>
        <HSpace />
        <Button color="primary" onClick={handleInfoClick}>
          Validation rules
        </Button>
        <HSpace />
        <UncontrolledTooltip placement="top" target="excel">
          Eksporter til Excel
        </UncontrolledTooltip>
        <Button id="excel" onClick={() => exportChartToExcel(exportedChartData, true)}>
          <i className="fa fa-file-excel-o" />
        </Button>
      </div>
      <EmbeddedMongoDbAtlasChartWithGetData
        chartId="6501e4b7-90a4-4986-889e-e514f8ffd678"
        filters={chartsFilterObject}
        clickEnabled={true}
        onChartClick={handleChartClick}
        getData={receiveChartData}
      />

      <Modal isOpen={isPopupVisible} size="lg">
        <ModalHeader> Per object of selected file </ModalHeader>
        <ModalBody style={{ height: "750px" }}>
          <UncontrolledTooltip placement="top" target="excel">
            Eksporter til Excel
          </UncontrolledTooltip>
          <Button id="excel" onClick={() => exportChartToExcel(exportedChartDataPerObject, true)}>
            <i className="fa fa-file-excel-o" />
          </Button>
          <EmbeddedMongoDbAtlasChartWithGetData
            chartId="6502ea53-1cca-402e-8198-8f11e136d549"
            filters={popupChartFilter}
            getData={receiveChartDataPerObject}
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setIsPopupVisible(false)}>Close</Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={isInfoVisible}>
        <ModalHeader> Validation rules </ModalHeader>
        <ModalBody style={{ height: "750px" }}>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Property</th>
                <th>Rule</th>
              </tr>
            </thead>
            <tbody>
              {validationRules.map((item, index) => (
                <tr key={index}>
                  <td>{item.fieldName}</td>
                  <td>{item.condition}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setInfoVisible(false)}>Close</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default YggdrasilValidation;

export const convertAllFiltersToMongoFindQuery = (atlasMultiFilters: IAtlasMultiFilter[]) => {
  const allFilters = atlasMultiFilters.reduce((acc, filtrItem) => {
    if (filtrItem.options?.length > 0) {
      const activeOptions = filtrItem.options.filter((option) => option.isActive === true);
      const orQuery = activeOptions.map((option) => {
        if (filtrItem.regex == true) {
          //if its regular expressions add regex to query
          return {
            [filtrItem.filterKey]: { $regex: option.value, $options: "i" }, // i: case-insensitive search
          };
        }
        return {
          [filtrItem.filterKey]: option.value,
        };
      });
      if (orQuery.length > 0) {
        acc.push({
          ["$or"]: orQuery,
        });
      }
    }

    return acc;
  }, []);
  return {
    $and: [...allFilters],
  };
};
