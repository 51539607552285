import React from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, UncontrolledTooltip } from "reactstrap";

const ExcelExportDropdown: React.FC<{ excelExportVisibleColumns: any; exportExcelAllColumns: any }> = ({
  excelExportVisibleColumns,
  exportExcelAllColumns,
}) => (
  <>
    <UncontrolledTooltip placement="top" target="excel">
      Eksporter til Excel
    </UncontrolledTooltip>
    <UncontrolledDropdown>
      <DropdownToggle color="mc-blue" caret id="excel">
        <i className="fa fa-file-excel-o fa-fw" />
      </DropdownToggle>
      <DropdownMenu className="moveLeft">
        <DropdownItem onClick={() => exportExcelAllColumns()}>Eksporter alle kolonner</DropdownItem>
        <DropdownItem onClick={() => excelExportVisibleColumns()}>Eksporter utvalgte kolonner</DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  </>
);

export default ExcelExportDropdown;
