import React, { useState } from "react";

import { Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";
import { IFolderAgGrid } from "../../../../../../../types/global.types";
import { EditSaveAbort, HSpace } from "../../../../../components";
import BIM360FolderSelector from "../../../../../components/Bim360FolderSelector/BIM360FolderSelector";
import CheckOrWarn from "../../../../../components/CheckOrWarn";

const Bim360OutputFolderSelector: React.FC<{
  projectId: string;
  bim360ProjectId: string;
  isValid: boolean;
  currentFolder: IFolderAgGrid;
  onSave: (folderAg: IFolderAgGrid) => void;
}> = ({ projectId, bim360ProjectId, isValid, onSave, currentFolder }) => {
  const [selectedFolder, setSelectedFolder] = useState<IFolderAgGrid>(null);
  const [editMode, setEditMode] = useState(false);

  const folderSelected = (folderAg: IFolderAgGrid) => {
    setSelectedFolder(folderAg);
  };

  const saveAndExit = () => {
    if (selectedFolder.id != null && selectedFolder.name != null) {
      onSave(selectedFolder);
    }
    setEditMode(false);
  };

  return (
    <Card className="d-flex">
      <CardBody className="d-flex flex-column">
        <CardTitle tag="h3">
          <CheckOrWarn bool={isValid} />
          Eksportmappe: {currentFolder.name}
        </CardTitle>
        <CardSubtitle tag="h6" className="mb-2 text-muted">
          Velg mappe hvor eksporten skal plasseres
        </CardSubtitle>
        {editMode === false && <i className="fa fa-pencil fa-fw editIcon" onClick={() => setEditMode(true)} />}
        {editMode === true && (
          <div className="d-flex flex-column" style={{ height: "600px" }}>
            <BIM360FolderSelector
              onSelectedAgFolderUrn={folderSelected}
              bim360ProjectId={bim360ProjectId}
              projectId={projectId}
            />
            <HSpace />
            <EditSaveAbort editMode={editMode} saveCb={saveAndExit} editModeCb={() => setEditMode(false)} />
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default Bim360OutputFolderSelector;
