import React from "react";
import { IBasicItem, IIssue, IRisiko } from "../../../../../types/global.types";
import { IRegister } from "../../../../../types/register.types";
import ConnectExistingItemModal from "../../../components/ConnectExistingItemModal/ConnectExistingItemModal";
import { MCApplicationsUrlEnum, ToastMessagesEnums } from "../../../frontendConstants";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { doGetBasicIssueInfo } from "../issuesApp/issueAPIDepricated";
import { doGetIssueBoardsDepricated } from "../issuesApp/IssueBoardApiDepricated";
import { doConnectOrDisconnectIssueFromRisiko } from "./risikoAPI";
import { setShowSuccessMessageThunk } from "../../../global/globalSlice";

export const DepricatedConnectExistingIssueToRisikoModal: React.FC<{
  projectId: string;
  risiko: IRisiko;
  onConnected: (issueId: string) => void;
  toggle: () => void;
}> = ({ projectId, risiko, onConnected, toggle }) => {
  const [selectedIssueBoard, setSelectedIssuseBoard] = React.useState<IRegister.BoardPopulated>(null);
  const [issuesToSelectFrom, setIssuesToSelectFrom] = React.useState<IBasicItem[]>(null);
  const [issueBoards, setIssueBoards] = React.useState<IRegister.BoardPopulated[]>([]);
  const apps = useAppSelector((state) => state.globalReducer.apps);
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const _issueBoard = await doGetIssueBoardsDepricated(projectId);
    setIssueBoards(_issueBoard.filter((board) => board.status === "ACTIVE"));
  };

  const issueBoardWasSelected = async (issueBoard: IRegister.BoardPopulated) => {
    setSelectedIssuseBoard(issueBoard);
    let _basicTypeAheadItems = await doGetBasicIssueInfo(projectId, issueBoard._id);
    _basicTypeAheadItems = _basicTypeAheadItems.reduce((acc, item) => {
      if ((risiko.connectedIssues as IIssue[]).find((issue) => issue.id === item.id)) {
        return acc;
      }
      acc.push({
        displayValue: `${item.id}: ${item.title}`,
        ...item,
      });
      return acc;
    }, []);
    setIssuesToSelectFrom(_basicTypeAheadItems);
  };

  const connectIssueToRisiko = async (boardId: string, itemId: string) => {
    await doConnectOrDisconnectIssueFromRisiko(projectId, boardId, risiko._id, itemId, "CONNECT");
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.UPDATED));
    onConnected(itemId);
  };

  return (
    <ConnectExistingItemModal
      items={issuesToSelectFrom}
      onSelectBoard={issueBoardWasSelected}
      selectedBoard={selectedIssueBoard}
      boards={issueBoards || []}
      onConnect={connectIssueToRisiko}
      modalTitle="Koble sak"
      toggle={toggle}
      currentApp={MCApplicationsUrlEnum.ISSUE}
      apps={apps}
      projectId={projectId}
    />
  );
};
