import React, { useState } from "react";
import { Alert, Button, Input, InputGroup, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { apiSlice } from "../../../../apiSlice";
import { VSpace } from "../../../../components/";
import { setShowSuccessMessageThunk } from "../../../../global/globalSlice";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { loadProject } from "../../../actions";
import { doConnectBim360ProjectToMCAppsProject, doGetBim360Project } from "../restQueries";
import { getProjectIdFromBim360Url } from "../utils";

const ConnectBIM360ProjectModal: React.FC<{ toggle: any }> = ({ toggle }) => {
  const dispatch = useAppDispatch();
  const project = useAppSelector((state) => state.adminReducer.project);

  const [noHit, setNoHit] = useState(false);
  const [url, setBim360Url] = useState("");
  const [noBim360Project, setNoBim360Project] = useState(false);

  const [failedWarning, setFailedWarning] = useState<boolean>(false);

  const connectToBim360 = async () => {
    const bim360ProjectId = getProjectIdFromBim360Url(url);
    if (bim360ProjectId == null) {
      return setNoHit(true);
    }
    let _bim360Project;
    try {
      _bim360Project = await doGetBim360Project(project?._id, bim360ProjectId);
    } catch (error) {
      return setFailedWarning(true);
    }
    if (_bim360Project == null) {
      return setNoBim360Project(true);
    }
    await doConnectBim360ProjectToMCAppsProject(project._id, bim360ProjectId);
    dispatch(setShowSuccessMessageThunk("BIM360 prosjekt tilkoblet"));
    dispatch(setShowSuccessMessageThunk("Innstillinger lagret"));
    dispatch(loadProject(project._id));
    dispatch(apiSlice.util.invalidateTags(["MCBIM"]));
    dispatch(apiSlice.util.invalidateTags(["Project"]));
    setTimeout(() => {
      toggle(true);
    }, 50);
  };

  return (
    <Modal isOpen toggle={toggle} style={{ height: "300px" }}>
      <ModalHeader>Koble til BIM360/ACC-prosjektet</ModalHeader>
      <ModalBody>
        <p>Lim inn lenken til BIM360s dokumenthåndtering</p>
        <InputGroup>
          <Input
            type="text"
            placeholder="https://docs.b360.com/projects/..."
            value={url}
            onChange={(e) => setBim360Url(e.target.value)}
          />
          <Button onClick={() => connectToBim360()} color="primary">
            Koble
          </Button>
        </InputGroup>
        <VSpace />
        {noHit && (
          <Alert>
            Kunne ikke trekke ut prosjekt-ID fra lenken. Er du sikker på at du har limt inn en Bim360-lenke?
          </Alert>
        )}
        {noBim360Project ||
          (failedWarning && (
            <Alert color="danger">
              Tilkoblingen kunne ikke opprettes. Er det et Multiconsult-prosjekt i BIM360 Docs?
            </Alert>
          ))}
      </ModalBody>
      <ModalFooter>
        <Button outline onClick={() => toggle(false)}>
          Lukk
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConnectBIM360ProjectModal;
