import { useState } from "react";
import { FreeTextSearch, SingleComment } from "../../../../components";
import { IComment, INewsItem } from "../../../../../../types/global.types";
import { newsRTK, useGetNewsUniqUsersQuery } from "./newsRTK";
import { useAppSelector } from "../../../../hooks";

const NewsSearch: React.FC<{}> = () => {
  const projectId = useAppSelector((state) => state.adminReducer.project._id);
  const layoutId = useAppSelector((state) => state.projectDashboardReducer.activeLayout?._id);
  const [storedData, setStoredData] = useState<INewsItem[]>(null);
  const [searchNews, { isLoading }] = newsRTK.endpoints.searchNews.useLazyQuery();

  const [selecetedUsers, setSelectedUsers] = useState<string[]>([]);

  const search = async (query: string) => {
    const result = await searchNews({ projectId, layoutId, query, users: selecetedUsers });
    setStoredData(result.data ?? []);
  };

  const onModalClose = () => {
    setStoredData(null);
    setSelectedUsers([]);
  };

  const { data: uniqUsers } = useGetNewsUniqUsersQuery({
    projectId: projectId,
    dashboardId: layoutId,
  });

  const updateMultiFilterItemOption = (title: string, value: string) => {
    setSelectedUsers((prev) => {
      if (prev.includes(value)) {
        return prev.filter((item) => item !== value);
      } else {
        return [...prev, value];
      }
    });
  };

  return (
    <div className="mb-1">
      {uniqUsers != null && (
        <FreeTextSearch
          placeholder="Søk"
          manualIsLoading={isLoading}
          search={search}
          onModalClose={onModalClose}
          displayNoResultsText={storedData != null && storedData?.length === 0 && isLoading === false}
          updateMultiFilterItemOption={updateMultiFilterItemOption}
          multipleFilterItems={[
            {
              title: "Opprettet av",
              options: uniqUsers.map((user) => {
                return {
                  value: user.value,
                  label: user.label,
                  isActive: selecetedUsers.includes(user.value),
                };
              }),
            },
          ]}
          clearAllFilters={() => setSelectedUsers([])}
          selectAllMultiFilter={() => setSelectedUsers(uniqUsers.map((user) => user.value))}
          resetMultiFilter={() => setSelectedUsers([])}
        >
          {storedData?.map((comment: IComment) => {
            return (
              <SingleComment
                key={comment._id}
                comment={comment}
                editEnabled={false}
                deleteEnabled={false}
                onSave={() => {}}
                onDelete={() => {}}
              />
            );
          })}
        </FreeTextSearch>
      )}
    </div>
  );
};

export default NewsSearch;
