import React from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, Input, UncontrolledDropdown } from "reactstrap";
import { sortArrayByString } from "../../app/utils";
import { IDropdownFilterOptionGeneric } from "../../types";
import { HSpace } from "../index";

function DropdownFilterGeneric<T>({
  filterOptions,
  title,
  onClick,
  enableSearch = false,
  unSelectAll,
  selectAll,
  color = "white",
  textColor = "text-white",
}: {
  filterOptions: IDropdownFilterOptionGeneric<T>[];
  title: string;
  onClick?: (value: T) => void;
  enableSearch?: boolean;
  unSelectAll?: () => void;
  selectAll?: () => void;
  color?: string;
  textColor?: string;
}) {
  const numberOfActiveFilters = filterOptions.filter((option) => option.isActive === true).length;
  const [searchValue, setSearchValue] = React.useState<string>("");

  return (
    <>
      <div className="text-nowrap">
        <UncontrolledDropdown>
          <DropdownToggle
            color={color}
            className={`d-flex align-items-center ${color === "white" ? "text-dark" : textColor}`}
            style={{ cursor: "pointer", height: "2.2rem" }}
          >
            {title}
            <HSpace />
            <CircleWithNumber count={numberOfActiveFilters} hide={numberOfActiveFilters < 1} />
            <HSpace />
            <div className="flex-fill" />
            <i className="fa fa-caret-down" />
          </DropdownToggle>
          <DropdownMenu style={{ maxHeight: "300px", overflow: "auto" }}>
            {enableSearch !== false && (
              <>
                <DropdownItem toggle={false}>
                  <Input
                    className="form-control"
                    autoFocus
                    placeholder="Søk"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                </DropdownItem>
                <DropdownItem divider />
              </>
            )}
            {sortArrayByString(filterOptions || [], "label")
              .filter((item) => item.label != null && item.value != null)
              .filter((item) =>
                enableSearch !== true || searchValue === ""
                  ? true
                  : item.label?.toLowerCase().search(searchValue?.toLowerCase()) !== -1,
              )
              .map((option, i) => (
                <DropdownItem
                  toggle={false}
                  className="d-flex align-items-center"
                  onClick={() => onClick(option.value)}
                  key={i}
                >
                  {option.label}
                  <div className="flex-fill" />
                  {option.isActive && <i className="fa fa-check fa-fw" />}
                </DropdownItem>
              ))}
            {unSelectAll != null && (
              <>
                <DropdownItem divider />
                <DropdownItem onClick={() => unSelectAll()}>
                  <i className="fa fa-times-rectangle fa-fw" />
                  Avmarker alle
                </DropdownItem>
              </>
            )}

            {typeof selectAll == "function" && (
              <>
                <DropdownItem divider />
                <DropdownItem onClick={() => selectAll()}>
                  <i className="fa fa-check fa-fw" />
                  Marker alle
                </DropdownItem>
              </>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </>
  );
}

export default DropdownFilterGeneric;

export const CircleWithNumber: React.FC<{ count: number; hide: boolean }> = ({ count, hide }) => {
  return (
    <div style={{ opacity: hide === true ? 0 : 1 }} className="circle-singleline">
      {count}
    </div>
  );
};
