import { RomEllerUtstyrEnum } from "mc-shared/zod/report/GK/gkReportConstants";
import { IGKRapport } from "mc-shared/zod/report/GK/gkReportSchema";
import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../types/global.types";
import { McInput, VSpace } from "../../../../../components";

const GKgenerellInfo: React.FC<{
  data: IGKRapport;
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
}> = ({ data, onUpdateEditData }) => {
  return (
    <div>
      {data != null && (
        <>
          <Card>
            <CardBody>
              <CardTitle tag="h3">GK-Rapport</CardTitle>
              <CardTitle tag="h4">Generell info:</CardTitle>
              <div className="d-flex justify-content-between">
                <div style={{ width: "40%" }}>
                  <b>Oppdrag:</b>
                  <McInput
                    value={data.generellInfo?.oppdragsnavn}
                    onChange={(e) => onUpdateEditData("generellInfo.oppdragsnavn", e.target.value)}
                  />
                  <VSpace />
                  <b>Emne:</b>
                  <McInput
                    value={data.generellInfo?.emne}
                    onChange={(e) => onUpdateEditData("generellInfo.emne", e.target.value)}
                  />
                  <VSpace />
                  <b>Oppdragsgiver:</b>
                  <McInput
                    value={data.generellInfo?.oppdragsgiver}
                    onChange={(e) => onUpdateEditData("generellInfo.oppdragsgiver", e.target.value)}
                  />
                  <VSpace />
                  <b>Kontaktperson:</b>
                  <McInput
                    value={data.generellInfo?.kontaktperson}
                    onChange={(e) => onUpdateEditData("generellInfo.kontaktperson", e.target.value)}
                  />
                  <VSpace />
                  <b>Kopi:</b>
                  <McInput
                    value={data.generellInfo?.kopi}
                    onChange={(e) => onUpdateEditData("generellInfo.kopi", e.target.value)}
                  />
                  <VSpace />
                  <b>Etasje:</b>
                  <McInput
                    value={data.generellInfo?.etasje}
                    onChange={(e) => onUpdateEditData("generellInfo.etasje", e.target.value)}
                  />
                  <VSpace />
                  <b>Rom Nr.:</b>
                  <McInput
                    value={data.generellInfo?.romNr}
                    onChange={(e) => onUpdateEditData("generellInfo.romNr", e.target.value)}
                  />
                  {data.testInnstillinger.valgtRomEllerUtstyr === RomEllerUtstyrEnum.UTSTYR && (
                    <>
                      <VSpace />
                      <b>Utstyr:</b>
                      <McInput
                        value={data.generellInfo?.utstyr}
                        onChange={(e) => onUpdateEditData("generellInfo.utstyr", e.target.value)}
                      />
                    </>
                  )}
                </div>
                <div style={{ width: "40%" }}>
                  <b>Dokumentkode:</b>
                  <McInput
                    value={data.generellInfo?.dokumentkode}
                    onChange={(e) => onUpdateEditData("generellInfo.dokumentkode", e.target.value)}
                  />
                  <VSpace />
                  <b>Tilgjengelighet:</b>
                  <McInput
                    value={data.generellInfo?.tilgjengelighet}
                    onChange={(e) => onUpdateEditData("generellInfo.tilgjengelighet", e.target.value)}
                  />
                  <VSpace />
                  <b>Oppdragsleder:</b>
                  <McInput
                    value={data.generellInfo?.oppdragsleder}
                    onChange={(e) => onUpdateEditData("generellInfo.oppdragsleder", e.target.value)}
                  />
                  <VSpace />
                  <b>Utarbeidet av:</b>
                  <McInput
                    value={data.generellInfo?.utarbeidetAv}
                    onChange={(e) => onUpdateEditData("generellInfo.utarbeidetAv", e.target.value)}
                  />
                  <VSpace />
                  <b>Ansvarlig enhet:</b>
                  <McInput
                    value={data.generellInfo?.ansvarligEnhet}
                    onChange={(e) => onUpdateEditData("generellInfo.ansvarligEnhet", e.target.value)}
                  />
                  <VSpace />
                  <b>Sted:</b>
                  <McInput
                    value={data.generellInfo?.sted}
                    onChange={(e) => onUpdateEditData("generellInfo.sted", e.target.value)}
                  />
                  <VSpace />
                  <b>Dato:</b>
                  <McInput
                    value={data.generellInfo?.dato}
                    onChange={(e) => onUpdateEditData("generellInfo.dato", e.target.value)}
                    type="date"
                  />
                  {data.testInnstillinger.valgtRomEllerUtstyr === RomEllerUtstyrEnum.UTSTYR && (
                    <>
                      <VSpace />
                      <b>Serienummer utstyr:</b>
                      <McInput
                        value={data.generellInfo?.serienummerUtstyr}
                        onChange={(e) => onUpdateEditData("generellInfo.serienummerUtstyr", e.target.value)}
                      />
                    </>
                  )}
                </div>
              </div>
              <div>
                <VSpace />
                <b>Generelt:</b>
                <McInput
                  value={data.generellInfo?.generelt}
                  onChange={(e) => onUpdateEditData("generellInfo.generelt", e.target.value)}
                  type="textarea"
                  dynamicHeight={true}
                />
              </div>
              <div>
                <VSpace />
                <b>Sammendrag:</b>
                <McInput
                  value={data.generellInfo?.sammendrag}
                  onChange={(e) => onUpdateEditData("generellInfo.sammendrag", e.target.value)}
                  type="textarea"
                  dynamicHeight={true}
                />
              </div>
            </CardBody>
          </Card>
        </>
      )}
    </div>
  );
};

export default GKgenerellInfo;
