import React from "react";
import { FailedAlert } from "../../../../components/";
import MultiComment, { IEditorMetadata } from "../../../../components/MultiComment/MultiComment";
import WidgetWrapper from "../../../../components/WidgetWrapper/WidgetWrapper";
import { useAppSelector } from "../../../../hooks";
import { useAddNewsItemMutation, useGetNewsQuery, useUpdateNewsItemMutation } from "./newsRTK";

const News: React.FC = () => {
  const projId = useAppSelector((state) => state.adminReducer.project._id);
  const user = useAppSelector((state) => state.globalReducer.user);
  const layoutId = useAppSelector((state) => state.projectDashboardReducer.activeLayout._id);
  const members = useAppSelector((state) => state.adminReducer.project.members);
  const { data, isLoading, isError } = useGetNewsQuery(
    {
      projectId: projId,
      dashboardId: layoutId,
    },
    {
      skip: layoutId == null,
    },
  );

  const [updateNewsItem, { isLoading: isLoadingEdit, isError: isErrorUpdatingNewsItem }] = useUpdateNewsItemMutation();
  const [addNewsItem, { isLoading: isLoadingAddNewsItem, isError: isErrorAddNewsItem }] = useAddNewsItemMutation();
  const failed = isError || isErrorUpdatingNewsItem || isErrorAddNewsItem;
  const loading = isLoading || isLoadingEdit || isLoadingAddNewsItem;

  return (
    <WidgetWrapper title="Oppslagstavle" widgetKey="news" allowFullscreen>
      {failed && <FailedAlert errorMessage="Failed to start news" />}
      {data != null && user != null && failed !== true && layoutId != null && (
        <>
          <div className="flex-fill overflow-auto p-1">
            <MultiComment
              members={members}
              sortDesc={false}
              comments={data}
              showSearch={true}
              loading={loading}
              visibleCommentsOnLoad={5}
              onCreate={(text: string, metadata: IEditorMetadata) => {
                const newsItem = {
                  projectId: projId,
                  newsItem: {
                    dashboard: layoutId,
                    text,
                    deleted: false,
                    metadata,
                  },
                };
                addNewsItem(newsItem);
              }}
              onDelete={(_id) => {
                const newsItem = {
                  projectId: projId,
                  newsItemId: _id,
                  newsItem: {
                    dashboard: layoutId,
                    text: data.find((n) => n._id === _id)?.text ?? "",
                    deleted: true,
                    metadata: data.find((n) => n._id === _id)?.metadata ?? {},
                  },
                };
                updateNewsItem(newsItem);
              }}
              onSave={(_id, text, metadata) => {
                const newsItem = {
                  projectId: projId,
                  newsItemId: _id,
                  newsItem: {
                    dashboard: layoutId,
                    text,
                    deleted: false,
                    metadata,
                  },
                };
                updateNewsItem(newsItem);
              }}
              loggedInUser={user}
              noCommentsText="Ingen nyheter å vise. Klikk på knappen i venstre hjørne for å lage et innlegg."
            />
          </div>
        </>
      )}
    </WidgetWrapper>
  );
};

export default News;
