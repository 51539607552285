import React, { useEffect, useState } from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { IMember, IValueLabel } from "../../../../../../types/global.types";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { setProjectMemberGroupInRedux } from "../../../actions";
import { MEMBER_GROUPS } from "../../../globalConstants";
import { doUpdateMemberGroupInProject } from "../../../projectApi";
import { setShowSuccessMessageThunk } from "../../../../global/globalSlice";

const ProjectGroupSelector: React.FC<{ group: IValueLabel; memberId: string }> = ({ group, memberId }) => {
  const [member, setMember] = useState<IMember>();
  const [memberIsMulticonsult, setMemberIsMulticonsult] = useState<boolean>();

  useEffect(() => {
    const _member = project.members.find((mbr) => mbr._id === memberId);

    if (_member != null) {
      const _memberIsMulticonsult = _member.user.email && _member.user.email.toLowerCase().endsWith("@multiconsult.no");
      setMemberIsMulticonsult(_memberIsMulticonsult);
    }
    setMember(_member);
  }, []);

  const project = useAppSelector((state) => state.adminReducer.project);

  const dispatch = useAppDispatch();
  const onGroupChange = async (group: IValueLabel) => {
    await doUpdateMemberGroupInProject(project._id, memberId, group.value);
    const memberIndex = project.members.findIndex((member) => member._id === memberId);
    dispatch(setProjectMemberGroupInRedux(group.value, memberIndex));
    dispatch(setShowSuccessMessageThunk("Gruppe satt"));
  };

  const getItemsInMemberGroup = () => {
    if (memberIsMulticonsult && member.group === "INTERNAL") {
      return MEMBER_GROUPS.filter((group) => {
        return group.value === "ADMIN" || group.value === "INTERNAL";
      });
    }

    if (memberIsMulticonsult && member.group === "ADMIN") {
      return MEMBER_GROUPS.filter((group) => {
        return group.value === "ADMIN" || group.value === "INTERNAL";
      });
    }

    if (memberIsMulticonsult === false && member.group !== "INTERNAL") {
      return MEMBER_GROUPS.filter((group) => {
        return group.value !== "INTERNAL";
      });
    }
    return MEMBER_GROUPS;
  };

  return (
    <UncontrolledDropdown>
      {member != null && memberIsMulticonsult != null && (
        <>
          <DropdownToggle className="w-100 d-flex align-items-center justify-content-between" caret outline>
            {group == null ? "Ikke satt" : group}
          </DropdownToggle>
          <DropdownMenu>
            {getItemsInMemberGroup().map((group, index) => (
              <DropdownItem key={index} onClick={() => onGroupChange(group)}>
                {group.label}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </>
      )}
    </UncontrolledDropdown>
  );
};

export default ProjectGroupSelector;
