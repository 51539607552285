import Axios from "axios";
import { IBIM360 } from "../../../types/bim360.types";
import { IBim360FolderContent } from "../../../types/global.types";

export const doGetBim360Companies = async (projectId: string, bim360AccountId: string): Promise<IBIM360.Company[]> => {
  try {
    const fetch = await Axios.get(`/api/project/${projectId}/forge/account/${bim360AccountId}/companies`);
    return fetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetBim360FolderInfo = async (
  projectId: string,
  bim360ProjectId: string,
  folderId: string,
): Promise<IBim360FolderContent> => {
  try {
    const fetchData = await Axios.get(
      `/api/project/${projectId}/forge/bim360project/${bim360ProjectId}/folder/${folderId}/folderinfo`,
    );
    return fetchData.data?.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};
