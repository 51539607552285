import React, { useState } from "react";
import { Badge, Modal, ModalBody, ModalHeader } from "reactstrap";
import { BatchJobStatus, IBatchjobLog } from "../../../../types/global.types";
import { STANDARD_COLORS } from "../../app/globalConstants";
import { doGetBatchJobLogText } from "../../app/routes/batchjob/batchJobAPI";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { AG_GRID_LOCALE_NOR } from "../../agGridLocale";
import { formatShortDate } from "../../app/routes/admin/selectors";

const BatchLogTable: React.FC<{
  logs: IBatchjobLog[];
  projectId: string;
}> = ({ logs, projectId }) => {
  const [currentLogText, setCurrentLogText] = useState<string>("");
  const [currentLog, setCurrentLog] = useState<IBatchjobLog>(null);

  const openModal = async (log: IBatchjobLog) => {
    const logText = await doGetBatchJobLogText(projectId, log._id);

    if (typeof logText === "string") {
      setCurrentLogText(logText);
    } else {
      setCurrentLogText(JSON.stringify(logText));
    }

    setCurrentLog(log);
  };

  const getColor = (status: BatchJobStatus): string => {
    switch (status) {
      case "FAILED":
        return STANDARD_COLORS.red;
      case "PENDING":
        return STANDARD_COLORS.orange;
      case "SUCCEEDED":
        return STANDARD_COLORS.green;
      default:
        return STANDARD_COLORS.red;
    }
  };

  const columns: ColDef[] = [
    {
      field: "_id",
      headerName: "",
      width: 50,
      cellRenderer: (params) => {
        return (
          <div className="d-flex justify-content-center align-items-center h-100">
            <i style={{ cursor: "pointer" }} className="fa fa-info-circle" onClick={() => openModal(params.data)} />
          </div>
        );
      },
    },
    {
      field: "title",
      headerName: `Logg`,
      filter: "agTextColumnFilter",
      flex: 1,
    },
    {
      field: "updatedAt",
      headerName: `Dato`,
      initialSort: "desc",
      cellRenderer: (params) => formatShortDate(params.value),
    },
    {
      field: "status",
      filter: "agSetColumnFilter",
      cellClass: "d-flex",
      headerName: `Status`,
      cellRenderer: (params) => {
        return (
          <Badge style={{ padding: "8px 12px" }} pill className="d-flex greybadge ">
            <div className="statusdot" style={{ backgroundColor: getColor(params.value) }}></div>
            <div style={{ color: STANDARD_COLORS.grey, fontSize: "12px" }}>{params.value.toUpperCase()}</div>
          </Badge>
        );
      },
    },
  ];

  return (
    <div
      className="d-flex justify-content-center mt-2 w-100"
      style={{ height: "calc(100% - 150px)", overflow: "auto" }}
    >
      <div className="ag-theme-quartz h-100 w-100">
        <AgGridReact
          domLayout={"autoHeight"}
          rowData={logs}
          defaultColDef={{
            suppressHeaderMenuButton: true,
            cellClass: "d-block",
          }}
          headerHeight={35}
          columnDefs={columns}
          suppressClickEdit={true}
          rowSelection="single"
          animateRows={false}
          suppressContextMenu={true}
          localeText={AG_GRID_LOCALE_NOR}
        />
      </div>
      {currentLog != null && (
        <LogModal
          logTitle={currentLog.title}
          logText={currentLogText}
          closeIssueItemModal={() => setCurrentLog(null)}
        />
      )}
    </div>
  );
};

const LogModal: React.FC<{
  logTitle: string;
  logText: string;
  closeIssueItemModal: () => void;
}> = ({ logText, closeIssueItemModal, logTitle }) => {
  return (
    <Modal style={{ width: "80vw", maxWidth: "80vw" }} isOpen toggle={closeIssueItemModal}>
      <ModalHeader toggle={() => closeIssueItemModal()}>{logTitle}</ModalHeader>
      <ModalBody>
        <div className={`bg-dark d-flex flex-column}`}>
          <pre style={{ whiteSpace: "break-spaces" }} className={`text-white h-100 m-0 p-1`}>
            {logText}
          </pre>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default BatchLogTable;
