import { ColDef, ICellRendererParams, ValueGetterParams, ValueSetterParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import React, { useMemo } from "react";
import { Button } from "reactstrap";
import { IBatchjob } from "../../../../types/batchjob.types";
import { AG_GRID_LOCALE_NOR } from "../../agGridLocale";

const RevitFilesExportTable: React.FC<{
  revitFilesToExport: IBatchjob.IBim360RevitExportFile[];
  updateFile: (fileId: string, revitViewValue: string) => void;
  includeRevitView: boolean;
}> = ({ revitFilesToExport, updateFile, includeRevitView }) => {
  const columnDefs = useMemo<ColDef[]>(() => {
    const columnDefs: ColDef[] = [
      {
        field: "name",
        filter: true,
        headerName: "Fil",
        width: 300,
        sortable: true,
      },
    ];

    if (includeRevitView === true) {
      columnDefs.push({
        field: "revitView.value",
        filter: true,
        headerName: "Revit-vy",
        width: 300,
        editable: true,
        sortable: true,
        cellRenderer: CellRenderer,
        valueGetter: (params: ValueGetterParams) => {
          return (params.data as IBatchjob.IBim360RevitExportFile).revitView?.value;
        },
        valueSetter: (params: ValueSetterParams) => {
          updateFile(params.data._id, params.newValue);
          return true;
        },
      });
    }

    return columnDefs;
  }, [includeRevitView]);

  return (
    <div className="ag-theme-alpine h-100" style={{ overflow: "auto" }}>
      <AgGridReact
        rowData={revitFilesToExport}
        columnDefs={columnDefs}
        suppressClickEdit={true}
        animateRows={true}
        localeText={AG_GRID_LOCALE_NOR}
      />
    </div>
  );
};

export default RevitFilesExportTable;

const CellRenderer = (props: ICellRendererParams) => {
  const handleClick = () => {
    props.api.startEditingCell({
      rowIndex: props.node.rowIndex,
      colKey: props.colDef.colId,
    });
  };
  return (
    <span>
      <Button outline onClick={handleClick} color="primary">
        <i className="fa fa-pencil" />
      </Button>
      <span style={{ paddingLeft: "4px" }}>{props.value}</span>
    </span>
  );
};
