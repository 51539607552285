import { useEffect } from "react";
import { doGetMsGraphUserByEmail } from "../admin/restQueries";

const Apptest = () => {
  useEffect(() => {
    async function fetch() {
      await doGetMsGraphUserByEmail("tobiasnorum.andersson@multiconsult.no");
      //   await doGetBimObjectsByFloor("5c88d216dbb10a37c75aa7af", "60c1db8e8605fe14e94a89b1", "1YMMGxlDD9DgoKadlajFce");
    }
    fetch();
  }, []);

  return <div>hello test</div>;
};

export default Apptest;
