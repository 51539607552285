import React from "react";
import { Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";
import { IRisiko } from "../../../../../../../types/global.types";
import RisikoCategoryMatrixAndChartMultipleItems from "../../Stats/RisikoCategoryMatrixAndChartMultipleItems";

const EvaluationStatisticsCard: React.FC<{
  risikos: IRisiko[];
}> = ({ risikos }) => {
  return (
    <Card>
      <CardBody>
        <CardTitle tag="h3">Kategorier</CardTitle>
        <CardSubtitle tag="h6" className="mb-2 text-muted">
          <RisikoCategoryMatrixAndChartMultipleItems risikosInTable={risikos} />
        </CardSubtitle>
      </CardBody>
    </Card>
  );
};

export default EvaluationStatisticsCard;
