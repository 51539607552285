import { VSpace } from "../../../components/";
import ProjectUsageTable from "./ProjectUsageTable";
import UserChart from "./UserChart";
import WeeklyUserChart from "./WeeklyUserChart";

export const Stats = () => {
  return (
    <div style={{ overflow: "auto", height: "100%" }}>
      <VSpace />
      <WeeklyUserChart />
      <VSpace />
      <UserChart />
      <VSpace />
      <ProjectUsageTable />
    </div>
  );
};
