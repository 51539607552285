import * as R from "ramda";
import React from "react";
import { Alert, Card, CardBody, CardSubtitle, CardTitle, Table } from "reactstrap";
import { IApp, IProjectApp } from "../../../../../types/global.types";
import { Checkbox } from "../../../components/";
import { ToastMessagesEnums } from "../../../frontendConstants";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { setProjectAppsInRedux } from "../../actions";
import { doSaveProjectApps } from "./restQueries";
import { selectUserMember, setShowSuccessMessageThunk } from "../../../global/globalSlice";

const EnabledApps = () => {
  const apps = useAppSelector((state) => state.globalReducer.apps);
  const project = useAppSelector((state) => state.adminReducer.project);
  const userMember = useAppSelector(selectUserMember);
  const userIsAdmin = userMember?.group === "ADMIN";

  const dispatch = useAppDispatch();

  const updateProjectApp = async (projectApp: IProjectApp, path: string[], bool: boolean) => {
    const index = project.apps.findIndex((_prjApp) => _prjApp.app === projectApp.app);
    const copyOfProjectApps = [...project.apps];
    copyOfProjectApps[index] = R.assocPath(path, bool, copyOfProjectApps[index]);
    const savedProjectApps = await doSaveProjectApps(project._id, copyOfProjectApps);
    dispatch(setProjectAppsInRedux(savedProjectApps));
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.UPDATED));
  };

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h3">Aktiverte applikasjoner</CardTitle>
        <CardSubtitle tag="h6" className="mb-2 text-muted">
          Velg hvilke applikasjoner og hvilke som skal ha tilgang
        </CardSubtitle>
        {userIsAdmin === false && <Alert color="info">Administreres av prosjektadministratorer</Alert>}
        <Table>
          <thead>
            <tr>
              <th className="w-25">App</th>
              <th className="w-25">Intern</th>
              <th className="w-25">Partner</th>
              <th className="w-25">Kunde</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {apps
              .filter((app) => {
                if (app.enabledInProjects == null) {
                  return true;
                } else {
                  return app.enabledInProjects.indexOf(project.maconomy.projectNo) !== -1;
                }
              })
              .map((app, index) => (
                <AppRow
                  key={index}
                  isAdmin={userIsAdmin}
                  app={app}
                  projectApp={project.apps.find((prjApp) => prjApp.app === app.name)}
                  updateProjectApp={updateProjectApp}
                />
              ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default EnabledApps;

const AppRow: React.FC<{
  isAdmin: boolean;
  projectApp: IProjectApp;
  app: IApp;
  updateProjectApp: (projectApp: IProjectApp, path: string[], bool: boolean) => void;
}> = ({ isAdmin, projectApp, app, updateProjectApp }) => {
  return (
    <>
      {projectApp != null && (
        <tr>
          <td>{app.label}</td>
          <td className="text-center">
            <Checkbox
              isChecked={projectApp.active}
              setChecked={() => updateProjectApp(projectApp, ["active"], !projectApp.active)}
              disabled={isAdmin === false}
            />
          </td>
          <td className="text-center">
            <Checkbox
              isChecked={projectApp.access.partner}
              setChecked={() => updateProjectApp(projectApp, ["access", "partner"], !projectApp.access.partner)}
              disabled={isAdmin === false || projectApp.active === false}
            />
          </td>
          <td className="text-center">
            <Checkbox
              isChecked={projectApp.access.client}
              setChecked={() => updateProjectApp(projectApp, ["access", "client"], !projectApp.access.client)}
              disabled={isAdmin === false || projectApp.active === false}
            />
          </td>
        </tr>
      )}
    </>
  );
};
