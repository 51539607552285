import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMcBimFileAttributes } from "mc-shared/zod/mcbimSchema";
import { IMinUser } from "../../../../../../types/global.types";
import { IMCBIMAPI } from "../../../../../../types/mcbim.types";
import { arraysEqual, sortArrayByString } from "../../../utils";

export interface IMCBimReducer {
  mcBimProject: IMCBIMAPI.Project;
  selectedIfcFiles: IMCBIMAPI.IfcFile[];
}

const initialState: IMCBimReducer = {
  mcBimProject: null,
  selectedIfcFiles: null,
};

export const mcbimSlice = createSlice({
  name: "mcbim",
  initialState,
  reducers: {
    resetMcBimInRedux: (state) => {
      state.mcBimProject = null;
      state.selectedIfcFiles = null;
    },
    setSelectIfcFilesInRedux: (state, action: PayloadAction<IMCBIMAPI.IfcFile[]>) => {
      state.selectedIfcFiles = action.payload;
    },
    setMcBimProjectRedux: (state, action: PayloadAction<IMCBIMAPI.Project>) => {
      state.mcBimProject = action.payload;
    },
  },
});
/*export const loadMcCosAndMcBimThunk = (): AppThunk => async (dispatch: any, getState) => {
  const project = getState().adminReducer.project;
  dispatch(setMcCosMcBimLoadingInRedux(true));
  dispatch(setTableEditedInRedux(false));
  try {
    const mcbimProjectResponse = await dispatch(mcbimRTK.endpoints.getMcBimProject.initiate(project._id));
    if (mcbimProjectResponse.status === "fulfilled") {
      dispatch(setMcBimProjectRedux(mcbimProjectResponse.data));
    } else {
      console.log("McBimProject request not fulfilled", mcbimProjectResponse);
      dispatch(setMcBimFailedToLoadInRedux(true));
    }
    //load McCos
    dispatch(loadMcCosThunk());
  } catch (error) {
    console.log("ERROR", error);
    dispatch(setMcBimFailedToLoadInRedux(true));
  } finally {
    dispatch(setMcCosMcBimLoadingInRedux(false));
  }
};*/

export const selectedFilesAndFilesSavedInMcBimIsEqual = (
  selectedFiles: IMCBIMAPI.IfcFile[],
  filesSavedInMcBimProject: IMCBIMAPI.IfcFile[],
) => {
  return arraysEqual(
    sortArrayByString(
      filesSavedInMcBimProject.map((file) => {
        return {
          fileStorageId: file.fileStorageId,
          filePath: file.filePath,
          fileName: file.fileName,
        };
      }),
      "fileStorageId",
    ),
    sortArrayByString(
      (selectedFiles || []).map((file) => {
        return {
          fileStorageId: file.fileStorageId,
          filePath: file.filePath,
          fileName: file.fileName,
        };
      }),
      "fileStorageId",
    ),
  );
};

export const isMcBimAdmin = (mcBimAdmins: IMinUser[], userId: string) => {
  return mcBimAdmins.some((admin) => admin._id === userId) === true;
};

export const getMcBimFileAttributes = (mcBimProject: IMCBIMAPI.Project): IMcBimFileAttributes => {
  return {
    fileAttributes: {
      fileAttr1: mcBimProject?.fileAttributeExtractSettings?.fileAttr1?.name,
      fileAttr2: mcBimProject?.fileAttributeExtractSettings?.fileAttr2?.name,
      fileAttr3: mcBimProject?.fileAttributeExtractSettings?.fileAttr3?.name,
      fileAttr4: mcBimProject?.fileAttributeExtractSettings?.fileAttr4?.name,
    },
  };
};

export const { resetMcBimInRedux, setMcBimProjectRedux, setSelectIfcFilesInRedux } = mcbimSlice.actions;
