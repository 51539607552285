import moment from "moment/moment";
import * as R from "ramda";
import React, { useEffect, useState } from "react";
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
  ICheckItem,
  IChecklistItemRow,
  IStampSettings,
  TChecklistItemStatus,
} from "../../../../../types/checklist.types";
import { IMinUser } from "../../../../../types/global.types";
import { HSpace, VSpace } from "../../../components";
import Avatar from "../../../components/Avatar";
import { useAppSelector } from "../../../hooks";
import InitialsAndDate from "./InitialsAndDate";

const StampCheck: React.FC<{
  disabled?: boolean;
  toggle: (rowId: string, oldValue: TChecklistItemStatus, newValue: TChecklistItemStatus) => void;
  updateCommentText?: (text: string) => void;
  checkItem: ICheckItem;
  row: IChecklistItemRow;
  stampSettings: IStampSettings;
  includeIr?: boolean;
  includeIrOnDefaultRoles?: boolean;
  templateMode?: boolean;
}> = ({
  disabled,
  toggle,
  updateCommentText,
  checkItem,
  row,
  stampSettings,
  includeIr = false,
  includeIrOnDefaultRoles,
  templateMode,
}) => {
  const handleOnChange = (status: TChecklistItemStatus) => {
    toggle(row._id, checkItem.status, status);
  };

  const [commentModalOpen, setCommentModalOpen] = useState<boolean>(false);
  const hideComment = checkItem.status === "N/A";

  const onSaveComment = (text: string) => {
    updateCommentText(text);
    setCommentModalOpen(false);
  };

  return (
    <>
      {commentModalOpen === true && (
        <StampCheckModal
          onSaveCommentText={onSaveComment}
          checkItem={checkItem}
          toggle={() => setCommentModalOpen(false)}
        />
      )}
      {checkItem ? (
        row.originalItem != null && (
          <div className="align-items-center" style={{ display: disabled === true ? "none" : "flex" }}>
            {templateMode !== true && (
              <>
                <div
                  className="d-flex align-items-center justify-content-center"
                  onClick={() => disabled === false && handleOnChange(checkItem.status === "NO" ? "N/A" : "NO")}
                  style={{
                    color: "#fff",
                    cursor: disabled ? "cursor" : "pointer",
                    width: "25px",
                    height: "25px",
                    borderRadius: "50%",
                    backgroundColor: checkItem.status === "NO" ? stampSettings.no?.color : "#ccc",
                  }}
                >
                  <i className={`fa fa-${stampSettings.no.icon}`} />
                </div>
                <HSpace />
                <div
                  className="d-flex align-items-center justify-content-center"
                  onClick={() => disabled === false && handleOnChange(checkItem.status === "YES" ? "N/A" : "YES")}
                  style={{
                    color: "#fff",
                    cursor: disabled ? "cursor" : "pointer",
                    width: "25px",
                    height: "25px",
                    borderRadius: "50%",
                    backgroundColor: checkItem.status === "YES" ? stampSettings.yes?.color : "#ccc",
                  }}
                >
                  <i className={`fa fa-${stampSettings.yes.icon}`} />
                </div>
              </>
            )}
            {(includeIr === true || includeIrOnDefaultRoles === true) && (
              <>
                <HSpace />
                <div
                  className="d-flex align-items-center justify-content-center"
                  onClick={() => disabled === false && handleOnChange(checkItem.status === "IR" ? "N/A" : "IR")}
                  style={{
                    color: "#fff",
                    cursor: disabled ? "cursor" : "pointer",
                    width: "25px",
                    height: "25px",
                    borderRadius: "50%",
                    backgroundColor: checkItem.status === "IR" ? stampSettings.ir?.color : "#ccc",
                  }}
                >
                  <i className={`fa fa-${stampSettings.ir?.icon}`} />
                </div>
              </>
            )}
            <HSpace />
            <div className="d-flex flex-column small-text overflow-hidden" style={{ height: "30px" }}>
              {checkItem.status !== "N/A" && (
                <InitialsAndDate updatedBy={checkItem.updatedBy} updatedDate={checkItem.updatedDate} />
              )}
            </div>

            {hideComment === false && (
              <>
                <HSpace />
                <div
                  className="d-flex align-items-center justify-content-center"
                  onClick={() => setCommentModalOpen(true)}
                  style={{
                    color: "#fff",
                    cursor: disabled ? "cursor" : "pointer",
                    width: "25px",
                    height: "25px",
                    borderRadius: "50%",
                    backgroundColor: checkItem.comment?.text?.length > 0 ? "#007bff" : "#ccc",
                  }}
                >
                  <i className={`fa fa-comment`} />
                </div>
              </>
            )}
          </div>
        )
      ) : (
        <div>{JSON.stringify(checkItem)}</div>
      )}
    </>
  );
};

export default StampCheck;

const StampCheckModal: React.FC<{
  toggle: () => void;
  checkItem: ICheckItem;
  onSaveCommentText: (text: string) => void;
}> = ({ toggle, checkItem, onSaveCommentText }) => {
  const [text, setText] = useState<string | null>(null);

  const user = useAppSelector((state) => state.globalReducer.user);

  const userIsSameWhoUpdatedCheckItem = user._id === (checkItem.updatedBy as IMinUser)?._id;

  useEffect(() => {
    setText(R.clone(checkItem.comment?.text) || "");
  }, [checkItem.comment?.text]);

  const comment = checkItem.comment;

  const saveDisabled = text === checkItem.comment?.text;

  return (
    <Modal isOpen toggle={toggle}>
      <ModalHeader toggle={() => toggle()}>Angi kommentar</ModalHeader>
      <ModalBody>
        {comment?.text?.length > 0 && (
          <div className="d-flex align-content-center">
            <Avatar id={(comment.createdBy as IMinUser)?._id} />
            <h5 className="gray-800-text mt-1">
              <b className="mr-2">{(comment.createdBy as IMinUser)?.name}</b>
            </h5>
            <div className="flex-fill" />
            <span>{moment(comment.createdDate).format("YYYY.MM.DD - HH:mm")}</span>
          </div>
        )}
        <VSpace />
        {userIsSameWhoUpdatedCheckItem && (
          <Input type="textarea" value={text} onChange={(e) => setText(e.target.value)} />
        )}
        {userIsSameWhoUpdatedCheckItem === false && <div>{checkItem.comment?.text}</div>}
      </ModalBody>
      <ModalFooter>
        {userIsSameWhoUpdatedCheckItem === true && (
          <Button color="success" disabled={saveDisabled} onClick={() => onSaveCommentText(text)}>
            <i className="fa fa-save fa-fw" />
            Lagre
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};
