import * as _ from "lodash";
import moment from "moment";
import * as R from "ramda";
import {
  IEvaluationObject,
  IIssue,
  IMember,
  IRisiko,
  IRisikoEvaluationCategory,
  IRiskoBoard,
  IScoreColor,
} from "../../../../../types/global.types";
import { STANDARD_COLORS } from "../../globalConstants";
import { formatTags, sortArrayByNumber } from "../../utils";
import { doFetchUniqRisikoTags } from "./risikoAPI";
import {
  ICategoriesWithSameScoreColors,
  IHigchartData,
  IMatrixColHeader,
  IMatrixData,
  IMatrixRowHeader,
  IRisikoColor,
  IScoreMatrix,
} from "./types";
import { formatShortDate } from "../admin/selectors";

export const addRisikoEvaluationKeysToRisiko = (
  risiko: IRisiko,
  evaluationCategories: IRisikoEvaluationCategory[],
  risikoBoard: IRiskoBoard,
) => {
  // @ts-ignore
  risiko.evaluationsObj = {};
  const probabilitySelection = risikoBoard.probabilitySelections.find(
    (probabilitySelection) => probabilitySelection.name === risiko.probability,
  );

  evaluationCategories.forEach((category) => {
    const consequence = getSingleConsequence(risiko, category._id);
    const consequenceSelection = category.consequenceSelections.find(
      (consequenceSelection) => consequenceSelection.name === consequence,
    );

    const probabilityValue = probabilitySelection && probabilitySelection.value;
    const consequenceValue = consequenceSelection && consequenceSelection.value;
    const colorItem = getEvaluationColor(category.scoreColors, consequenceValue, probabilityValue);
    risiko.evaluationsObj[category.name] = {
      consequence,
      _id: category._id,
      consequenceValue,
      score: calculateEvaluationScore(category._id, consequence, risiko.probability, evaluationCategories, risikoBoard),
      color: colorItem && colorItem.color,
    };
  });
  risiko.evaluationsObj["_SUM_"] = Object.keys(risiko.evaluationsObj).reduce((acc, evKEY) => {
    acc = acc + risiko.evaluationsObj[evKEY].score;
    return acc;
  }, 0);
  return risiko;
};

const getSingleConsequence = (risiko: IRisiko, evaluationId: String): string => {
  const evaluation = _.find(risiko.evaluations, (evaluation) => evaluation.evaluation === evaluationId);
  return evaluation == null ? null : evaluation.consequence;
};

export const getConsequenceSelections = (categoryEvaluations: IRisikoEvaluationCategory[], categoryName: string) => {
  const category = _.find(categoryEvaluations, (category) => category.name === categoryName);
  return category.consequenceSelections.map((selection) => {
    return {
      value: selection.name,
      label: selection.name,
    };
  });
};

export const convertEvaluationsToOrginal = (tableRisikoObj, newValue, column) => {
  const evaluationToUpdate = column.dataField.split(".")[1];
  const probabilityOrConsequence = column.dataField.split(".")[2];
  let evaluations = [];
  Object.keys(tableRisikoObj.evaluationsObj).forEach((evaluationKey) => {
    if (evaluationKey === evaluationToUpdate) {
      evaluations.push({
        evaluation: tableRisikoObj.evaluationsObj[evaluationKey]._id,
        _id: tableRisikoObj.evaluationsObj[evaluationKey]._id,
        probability:
          probabilityOrConsequence === "probability"
            ? newValue
            : tableRisikoObj.evaluationsObj[evaluationKey].probability,
        consequence:
          probabilityOrConsequence === "consequence"
            ? newValue
            : tableRisikoObj.evaluationsObj[evaluationKey].consequence,
      });
    } else {
      evaluations.push({
        evaluation: tableRisikoObj.evaluationsObj[evaluationKey]._id,
        _id: tableRisikoObj.evaluationsObj[evaluationKey]._id,
        probability: tableRisikoObj.evaluationsObj[evaluationKey].probability,
        consequence: tableRisikoObj.evaluationsObj[evaluationKey].consequence,
      });
    }
  });
  return evaluations;
};

export const calculateEvaluationScore = (
  evaluationId: string,
  consequence: string,
  probability: string,
  risikoEvaluationCategories: IRisikoEvaluationCategory[],
  risikoBoard: IRiskoBoard,
): number => {
  let value = 0;
  const risikoCategory = _.find(risikoEvaluationCategories, (category) => category._id === evaluationId);
  if (risikoCategory != null) {
    const consequenceSelection = risikoCategory.consequenceSelections.find(
      (selection) => selection.name === consequence,
    );

    const probabilitySelection = risikoBoard.probabilitySelections.find((selection) => selection.name === probability);
    if (consequenceSelection != null && probabilitySelection != null) {
      value = consequenceSelection.value * probabilitySelection.value;
    }
  }
  return value;
};

export const isWithin30Days = (date) => {
  const thirtyDaysAgo = moment().subtract(30, "days");
  const mDate = moment(date);
  return moment(thirtyDaysAgo).isBefore(mDate);
};

export const nrOfItemsWithDatesNewerThan30Days = (datePropertyPath: string) =>
  R.pipe(R.map(R.pathOr([], datePropertyPath.split("."))), R.filter(isWithin30Days), R.length);

export const createCircleChartDataSeries = (evaluationCategoryByColor: IRisikoColor[]): IHigchartData[] => {
  const totalNrOfRisikos = R.flatten(R.pluck("risikoIds", evaluationCategoryByColor)).length;
  return evaluationCategoryByColor.map((item) => {
    return {
      name: item.color,
      color: STANDARD_COLORS[item.color] || item.color,
      y: (item.risikoIds.length / totalNrOfRisikos) * 100,
    };
  });
};

export const risikoHaveScore = (score: number, categoryName: string) =>
  R.pipe(R.path(["evaluationsObj", categoryName, "score"]), R.equals(score));

export const getRisikoWithCategoryScores = (scores: number[], categoryName: string) => {
  const preds = scores.map((score) => {
    return risikoHaveScore(score, categoryName);
  });
  return R.filter(R.anyPass(preds));
};

export const createScoreMatrix = (
  risikoEvaluationCategory: IRisikoEvaluationCategory,
  risikos: IRisiko[],
  risikoBoard: IRiskoBoard,
): IScoreMatrix => {
  const colHeaders = createMatrixColHeaders(risikoBoard);
  const rowHeaders = createMatrixRowHeaders(risikoEvaluationCategory);

  return {
    name: risikoEvaluationCategory.name,
    colHeaders,
    rowHeaders,
    // @ts-ignore
    data: createMatrixScoreData(risikoEvaluationCategory, colHeaders, rowHeaders, risikos, risikoBoard),
  };
};

export const getScoreColorFromNames = (
  risikoEvaluationCategory: IRisikoEvaluationCategory,
  consequenceName: string,
  risikoProbability: string,
  risikoBoard: IRiskoBoard,
) => {
  const consequence = risikoEvaluationCategory.consequenceSelections.find((consq) => consq.name === consequenceName);
  const probability = risikoBoard.probabilitySelections.find((prob) => prob.name === risikoProbability);

  if (consequence != null && probability != null) {
    const scoreColor = risikoEvaluationCategory.scoreColors.find((_score) => {
      return consequence.value === _score.consequenceValue && probability.value === _score.probabilityValue;
    });
    return scoreColor != null ? STANDARD_COLORS[scoreColor.color] : "gray";
  } else {
    return 0;
  }
};

export const createMatrixScoreData = (
  risikoEvaluationCategory: IRisikoEvaluationCategory,
  colHeaders: IMatrixColHeader[],
  rowHeaders: IMatrixRowHeader[],
  risikos: IRisiko[],
  risikoBoard: IRiskoBoard,
) => {
  let grid = colHeaders.reduce((acc, col) => {
    rowHeaders.forEach((row) => {
      acc.push({
        rowId: row.rowId,
        colId: col.colId,
        rowName: row.name,
        colName: col.name,
        color: getScoreColorFromNames(risikoEvaluationCategory, row.name, col.name, risikoBoard),
        items: [],
      });
    });
    return acc;
  }, []);

  (risikos || []).forEach((risiko) => {
    try {
      const evaluationCategory: IEvaluationObject = risiko.evaluationsObj[risikoEvaluationCategory.name];
      const gridCellIndex = grid.findIndex(
        (cell) => cell.rowName === evaluationCategory.consequence && cell.colName === risiko.probability,
      );
      if (gridCellIndex !== -1) {
        grid[gridCellIndex].items.push(String(risiko._id));
      }
    } catch (err) {
      console.log(err);
    }
  });

  return grid;
};

export const createMatrixColHeaders = (risikoBoard: IRiskoBoard): IMatrixColHeader[] => {
  return sortArrayByNumber(risikoBoard.probabilitySelections, "value").map((item, index) => {
    return {
      colId: index,
      name: item.name,
      subname: "",
      value: item.value,
    };
  });
};

export const createMatrixRowHeaders = (risikoEvaluationCategory: IRisikoEvaluationCategory): IMatrixRowHeader[] => {
  return sortArrayByNumber(risikoEvaluationCategory.consequenceSelections, "value").map((item, index) => {
    return {
      rowId: index,
      name: item.name,
      subname: "",
      value: item.value,
    };
  });
};

export const getEvaluationColor = (scoreColors: IScoreColor[], consequenceValue, probabilityValue): IScoreColor => {
  return scoreColors.find((scoreColor) => {
    return (
      scoreColor.consequenceValue === Number(consequenceValue) &&
      scoreColor.probabilityValue === Number(probabilityValue)
    );
  });
};

export const groupEvaluationCategoryByColor = (risikos: IRisiko[]) => {
  return risikos.reduce((acc, risiko) => {
    Object.keys(risiko.evaluationsObj).forEach((category) => {
      const color = risiko.evaluationsObj[category].color;
      if (color != null) {
        if (acc[category] == null) {
          acc[category] = [
            {
              color,
              risikoIds: [risiko._id],
            },
          ];
        } else {
          const colorIndex = acc[category].findIndex((item) => item.color === color);
          if (colorIndex === -1) {
            acc[category].push({
              color,
              risikoIds: [risiko._id],
            });
          } else {
            acc[category][colorIndex].risikoIds.push(risiko._id);
          }
        }
      }
    });
    return acc;
  }, {});
};

export const getMember = (members: IMember[], userId) => {
  const member = members?.find((member) => member.user?._id === userId);
  return member == null ? null : member.user;
};

export const createRisikoTagMultifilter = async (projectId: string, risikoBoardId: string) => {
  const allUniqTags = await doFetchUniqRisikoTags(projectId, risikoBoardId);
  const tagsFormated = formatTags(allUniqTags);

  return tagsFormated.reduce((acc, tag) => {
    acc[tag] = tag;
    // return acc[tag] = tag;
    return acc;
  }, {});
};

export const groupCategoriesBySizeAndColor = (
  evaluationCategories: IRisikoEvaluationCategory[],
): ICategoriesWithSameScoreColors[] => {
  return evaluationCategories.reduce((acc, category) => {
    const ackIndex = acc.findIndex((acumulatedMatrix) => {
      return isScoreColorTheSame(acumulatedMatrix.scoreColors, category.scoreColors);
    });

    if (ackIndex === -1) {
      acc.push({
        name: `Akkumulering ${acc.length + 1}`,
        categoryNames: [category.name],
        scoreColors: category.scoreColors,
      });
    } else {
      acc[ackIndex].categoryNames.push(category.name);
    }
    return acc;
  }, []);
};

export const isScoreColorTheSame = (scoreColors1: IScoreColor[], scoreColors2: IScoreColor[]): boolean => {
  if (scoreColors2 == null || scoreColors1 == null) {
    return false;
  }
  if (scoreColors1.length !== scoreColors2.length) {
    return false;
  }
  let isSame = true;
  for (let i = 0; i < scoreColors1.length; i++) {
    const { color, consequenceValue, probabilityValue } = scoreColors1[i];
    if (
      scoreColors2.find(
        (item) =>
          item.probabilityValue === probabilityValue &&
          item.consequenceValue === consequenceValue &&
          item.color === color,
      ) == null
    ) {
      isSame = false;
      break;
    }
  }
  return isSame;
};

export const combineMatrixDataItems = (matrixDataItems: IMatrixData[][]): IMatrixData[] => {
  return matrixDataItems.reduce((acc, matrixData) => {
    if (acc.length === 0) {
      acc = removeValueFromMatrixData(matrixData);
    } else {
      matrixData.forEach((matrixDataItem) => {
        const index = acc.findIndex(
          (_item) => _item.colId === matrixDataItem.colId && _item.rowId === matrixDataItem.rowId,
        );
        if (index === -1) {
          console.log("INDEX IS -1!!");
        } else {
          const uniqueItems = R.uniq([...acc[index].items, ...matrixDataItem.items]);
          acc[index].items = uniqueItems.sort();
        }
      });
    }
    return acc;
  }, []);
};

const removeValueFromMatrixData = (matrixData: IMatrixData[]) => {
  return matrixData.map((item) => {
    return {
      rowId: item.rowId,
      colId: item.colId,
      color: item.color,
      items: item.items,
    };
  });
};

export const getAcumulatedScoreMatrixes = (
  evaluationCategories: IRisikoEvaluationCategory[],
  risikos: IRisiko[],
  risikoBoard: IRiskoBoard,
): ICategoriesWithSameScoreColors[] => {
  const _scoreMatrixes = evaluationCategories.map((category) => {
    return createScoreMatrix(category, risikos, risikoBoard);
  });
  const categoriesGroupedByScoreAndColor = groupCategoriesBySizeAndColor(evaluationCategories);
  return categoriesGroupedByScoreAndColor.map((category) => {
    const includedScoreMatrixes = _scoreMatrixes.filter((matrix) => category.categoryNames.indexOf(matrix.name) !== -1);
    const matrixDataItems = includedScoreMatrixes.map((scoreMatrix) => {
      return scoreMatrix.data;
    });
    category.rowHeaders = includedScoreMatrixes[0].rowHeaders;
    category.colHeaders = includedScoreMatrixes[0].colHeaders;
    category.data = combineMatrixDataItems([...matrixDataItems]);
    return category;
  });
};

export const convertScoreMatrixToColorAndRisikosIds = (
  scoreMatrix: IScoreMatrix | ICategoriesWithSameScoreColors,
): IRisikoColor[] => {
  const groupByColor = R.groupBy((item) => item.color, scoreMatrix.data);
  //@ts-ignore
  return Object.keys(groupByColor).map((color) => {
    const risikoIds = R.flatten(groupByColor[color].map((q) => q.items));
    return {
      color,
      risikoIds: risikoIds,
    };
  });
};

export const getRisikoTiltakSrr = (risiko: IRisiko) => {
  return (risiko.connectedIssues as IIssue[])?.reduce((acc, issue) => {
    const _issue = issue as IIssue;
    if (_issue.archived !== true) {
      const str = `${_issue}: ${_issue.title}, ${_issue.status}, ${formatShortDate(_issue.dates?.due)};`;
      acc = acc + str;
    }
    return acc;
  }, "");
};
