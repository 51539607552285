import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { IRisikoEvaluationCategorySelection, IRiskoBoard } from "../../../../../../types/global.types";
import { ToastMessagesEnums } from "../../../../frontendConstants";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { doUpdateRisikoBoardProbabilities } from "../riskoBoardAPI";
import CategorySelections from "./CategorySelectionsEditor/CategorySelections";
import { setShowSuccessMessageThunk } from "../../../../global/globalSlice";

const RisikoBoardProbabilites: React.FC<{ risikoBoard: IRiskoBoard; updateBoards: any }> = ({
  risikoBoard,
  updateBoards,
}) => {
  const dispatch = useAppDispatch();
  const project = useAppSelector((state) => state.adminReducer.project);

  const save = async (selections: IRisikoEvaluationCategorySelection[]) => {
    await doUpdateRisikoBoardProbabilities(project._id, risikoBoard._id, selections);
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.UPDATED));
    updateBoards();
  };

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h3">Sannsynlighet</CardTitle>
        <CategorySelections saveCb={save} name="Sannsynlighet" selections={risikoBoard.probabilitySelections} />
      </CardBody>
    </Card>
  );
};

export default RisikoBoardProbabilites;
