import { IValueAndColor } from "../../../../../types/global.types";
import { IDropDownOption } from "../../../components/EditLabelValueDropdownSelector";
import { STANDARD_COLORS } from "../../globalConstants";

export const CHECKLIST_STATES_OPTIONS: { value: string; displayValue: string }[] = [
  {
    value: "ACTIVE",
    displayValue: "Aktiv",
  },
  {
    value: "IN_DEVELOPMENT",
    displayValue: "Under utvikling",
  },
  {
    value: "DELIVERED",
    displayValue: "Levert",
  },
  {
    value: "REMOVED",
    displayValue: "Slettet",
  },
];

export const CHECKLIST_STATES_OPTIONS_COLOR: IValueAndColor[] = [
  {
    value: "Aktiv",
    color: STANDARD_COLORS.green,
  },
  {
    value: "Under utvikling",
    color: STANDARD_COLORS.yellow,
  },
  {
    value: "Levert",
    color: STANDARD_COLORS.blue,
  },
  {
    value: "Slettet",
    color: STANDARD_COLORS.red,
  },
];

export enum ChecklistStatusEnum {
  ACTIVE = "ACTIVE",
  IN_DEVELOPMENT = "IN_DEVELOPMENT",
  DELIVERED = "DELIVERED",
  REMOVED = "REMOVED",
}

export const ChecklistStatus = {
  ACTIVE: "Aktiv",
  IN_DEVELOPMENT: "Under utvikling",
  DELIVERED: "Levert",
  REMOVED: "Slettet",
};

export const ChecklistStatusColor = {
  ACTIVE: STANDARD_COLORS.green,
  IN_DEVELOPMENT: STANDARD_COLORS.yellow,
  DELIVERED: STANDARD_COLORS.blue,
  REMOVED: STANDARD_COLORS.red,
};

export const CHECKLIST_STATUS_DROPDOWN_VALUES: {
  active: IDropDownOption;
  in_development: IDropDownOption;
  delivered: IDropDownOption;
  removed: IDropDownOption;
} = {
  active: { label: "Aktiv", value: ChecklistStatusEnum.ACTIVE, visible: true },
  in_development: {
    label: "Under Utvikling",
    value: ChecklistStatusEnum.IN_DEVELOPMENT,
    visible: true,
  },
  delivered: { label: "Levert", value: ChecklistStatusEnum.DELIVERED, visible: true },
  removed: { label: "Slettet", value: ChecklistStatusEnum.REMOVED, visible: false },
};

export enum ChecklistTemplateStatusEnum {
  ACTIVE = "ACTIVE",
  IN_DEVELOPMENT = "IN_DEVELOPMENT",
  REMOVED = "REMOVED",
}

export const CHECKLISTSTATUSES: IDropDownOption[] = [
  CHECKLIST_STATUS_DROPDOWN_VALUES.active,
  CHECKLIST_STATUS_DROPDOWN_VALUES.in_development,
  CHECKLIST_STATUS_DROPDOWN_VALUES.delivered,
  CHECKLIST_STATUS_DROPDOWN_VALUES.removed,
];

export const TEMPLATESTATUSES: IDropDownOption[] = [
  CHECKLIST_STATUS_DROPDOWN_VALUES.active,
  CHECKLIST_STATUS_DROPDOWN_VALUES.in_development,
  CHECKLIST_STATUS_DROPDOWN_VALUES.removed,
];

export enum TemplateLevelEnum {
  MULTICONSULT = "MULTICONSULT",
  PROJECT = "PROJECT",
  BOARD = "BOARD",
}
