import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";
import MainApp from "./MainApp";

Sentry.init({
  dsn: "https://6c9c6ec9428bf746d05280225d1b9733@o4509049683116032.ingest.de.sentry.io/4509049914261584",
  enabled: import.meta.env.DEV !== true,
});

const rootEl = document.getElementById("app-site");
const root = createRoot(rootEl);

root.render(<MainApp />);
