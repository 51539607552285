import { apiSlice, getCacheKey } from "../../../../apiSlice";
import { IMcBimMongo } from "../../../../../../types/mcbimMono.types";

import { sortArrayByString } from "../../../utils";
import { IMcBimItem } from "mc-shared/types/mcbim.types";
import { trancuateStr } from "../../risiko/Stats/ScoreMatrix";
import { INrhRoomValidationCommentRIE, INrhRoomValidationRow } from "../../apptest/NrhRoomVal/nrhRoomValTypes";

export const mcBimMongoRTK = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMcBimSpaceList: builder.query<IMcBimMongo.SpaceBasicInfo[], { projectId: string }>({
      query(input) {
        return {
          url: `/project/${input.projectId}/mcbimmongo/spacelist`,
          method: "GET",
        };
      },
      transformResponse: (resp: IMcBimMongo.SpaceBasicInfo[]) => {
        const fomatted = resp.map((space) => {
          space.name_longName = `${space.name} - ${trancuateStr(space.longName, 20)}`;
          return space;
        });
        return sortArrayByString(fomatted, "name");
      },
    }),
    getDevicesInSpace: builder.query<IMcBimItem[], { projectId: string; spaceId: string }>({
      query: (input) => ({
        url: `/project/${input.projectId}/mcbimmongo/devices-inside-space/${input.spaceId}`,
        method: "GET",
      }),
    }),
    getNrhRoomValidations: builder.query<INrhRoomValidationRow[], { projectId }>({
      query: (input) => ({
        url: `/project/${input.projectId}/mcbimmongo/nrh-room-validations`,
        method: "GET",
      }),
    }),
    getNrhRoomValidationComments: builder.query<INrhRoomValidationCommentRIE[], { projectId }>({
      query: (input) => ({
        url: `/project/${input.projectId}/mcbimmongo/nrh-room-validations.comments`,
        method: "POST",
      }),
    }),
    upsertNrhRoomValidationComment: builder.mutation<INrhRoomValidationCommentRIE[], INrhRoomValidationCommentRIE>({
      query: (input) => ({
        url: `/project/${input.projectId}/mcbimmongo/nrh-room-validations.upsert-comment`,
        method: "POST",
        body: input,
      }),
      async onQueryStarted({ roomNr }, { dispatch, queryFulfilled, getState }) {
        const resp = await queryFulfilled;
        const data: any = resp.data;
        const updatedComment = data.value;
        const queries = getState().api.queries;
        const cacheKey = getCacheKey(queries, "getNrhRoomValidationComments");
        const patchResult = dispatch(
          mcBimMongoRTK.util.updateQueryData("getNrhRoomValidationComments", cacheKey, (draft) => {
            draft.map((commentRow) => {
              if (commentRow.roomNr === roomNr) {
                Object.assign(commentRow, updatedComment);
              }
              return commentRow;
            });
          }),
        );
        if (patchResult.patches?.length === 0) {
          dispatch(
            mcBimMongoRTK.util.updateQueryData("getNrhRoomValidationComments", cacheKey, (draft) => {
              draft.push(updatedComment);
            }),
          );
        }
      },
    }),
  }),
});

export const {
  useGetNrhRoomValidationsQuery,
  useGetNrhRoomValidationCommentsQuery,
  useUpsertNrhRoomValidationCommentMutation,
} = mcBimMongoRTK;
