import { ColDef } from "ag-grid-community";
import { ICapitalLetter, IOtherAttributeConfigItemProps } from "mc-shared/zod/otherAttributesSchema";
import React, { useRef, useState } from "react";
import { HexColorInput, HexColorPicker } from "react-colorful";
import { useHistory } from "react-router-dom";
import Switch from "react-switch";
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Popover,
  UncontrolledDropdown,
} from "reactstrap";
import { useImmer } from "use-immer";
import { useOnClickOutside } from "usehooks-ts";
import { useGetSingleIssueBoardQuery } from "../../../app/routes/issuesApp/IssueBoardRTK";
import { getIssueBoardIdFromUrl } from "../../../app/routes/issuesApp/issueUtils";
import { getProjectIdFromUrl } from "../../../app/utils";
import { McDropdown, McDropdownBase, McInput, McTitle, VSpace } from "../../index";
import McTypeahead from "../../McTypeahead/McTypeahead";
import ViewEditTable from "../../ViewEditTable/ViewEditTable";
import FormulaConfig from "./FormulaConfig";
import { IOtherAttributeConfigItemPropsWithId, TYPE_OF_VALUE_OPTIONS } from "./OtherAttributesConfig";
import { CustomCellEditorProps, CustomCellRendererProps } from "ag-grid-react";
import { STANDARD_COLORS } from "../../../app/globalConstants";

const OtherAttributeItemConfigModal: React.FC<{
  onClose: () => void;
  onSave: (otherAttributesConfigItemProps: IOtherAttributeConfigItemProps) => void;
  title: string;
  orignalOtherAttributesConfigItemProps?: IOtherAttributeConfigItemPropsWithId;
  addOrEdit: "add" | "edit";
  existingSections: string[];
}> = ({ onClose, onSave, title, orignalOtherAttributesConfigItemProps, addOrEdit, existingSections }) => {
  const template: IOtherAttributeConfigItemProps = {
    typeOfValue: "STRING",
    label: "",
    labelColor: "",
    tableOptions: [],
    allowMultipleSelectionsInDropdown: false,
    description: "",
    section: "",
    order: 0,
    isActive: true,
    formula: {
      nrOfCharsInCounter: 3,
      confArr: [],
    },
  };

  const [editedOtherAttributesConfigItemProps, setConfigItem] = useImmer<IOtherAttributeConfigItemProps>(
    addOrEdit === "add" ? template : orignalOtherAttributesConfigItemProps,
  );

  const updateAttribute = (key: keyof IOtherAttributeConfigItemProps, value: any) => {
    setConfigItem((draft) => {
      (draft[key] as any) = value;
    });
  };

  const noEditsMade =
    JSON.stringify(orignalOtherAttributesConfigItemProps) === JSON.stringify(editedOtherAttributesConfigItemProps);

  const isDisabled =
    editedOtherAttributesConfigItemProps.label === "" ||
    editedOtherAttributesConfigItemProps.section === "" ||
    noEditsMade;

  const _existingSectionOptions = existingSections.map((section, index) => {
    return {
      id: index,
      displayValue: section,
    };
  });

  const getDefaultSelected = () => {
    const existing = _existingSectionOptions.find(
      (section) => section.displayValue === editedOtherAttributesConfigItemProps.section,
    );
    if (existing != null) {
      return [existing];
    } else {
      return [];
    }
  };
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);

  const toolTipRef = useRef(null);

  const handleClickOutside = () => {
    setTooltipVisible(false);
  };

  useOnClickOutside(toolTipRef, handleClickOutside);
  return (
    <Modal isOpen style={{ maxWidth: "850px", width: "80%" }}>
      <ModalHeader toggle={onClose}>{title}</ModalHeader>
      <ModalBody id="dropdownContainer">
        <McTitle title="Tittel">
          <McInput
            value={editedOtherAttributesConfigItemProps.label}
            onChange={(e) => updateAttribute("label", e.target.value)}
          />
        </McTitle>
        <McTitle title="Beskrivelse">
          <McInput
            value={editedOtherAttributesConfigItemProps.description}
            onChange={(e) => updateAttribute("description", e.target.value)}
          />
        </McTitle>
        <McTitle title="Seksjon">
          <McTypeahead
            defaultSelected={getDefaultSelected()}
            allowNew
            id="1"
            onBlur={(e) => {
              const target = e.target as HTMLInputElement;
              updateAttribute("section", target.value ?? "");
            }}
            placeholder="Angi seksjon"
            labelKey="displayValue"
            options={_existingSectionOptions}
          />
        </McTitle>
        <McTitle title="Typ av verdi">
          <McDropdown
            currentValue={TYPE_OF_VALUE_OPTIONS.find(
              (o) => o.value === editedOtherAttributesConfigItemProps.typeOfValue,
            )}
            onChange={(_, valueAndLabel) =>
              updateAttribute("typeOfValue", TYPE_OF_VALUE_OPTIONS.find((o) => o.value === valueAndLabel.value)?.value)
            }
            options={TYPE_OF_VALUE_OPTIONS}
            displayKey="label"
          />
        </McTitle>
        {editedOtherAttributesConfigItemProps.typeOfValue === "DROPDOWN" && (
          <TableOptions
            editedOtherAttributesConfigItemProps={editedOtherAttributesConfigItemProps}
            updateAttribute={updateAttribute}
          />
        )}
        {editedOtherAttributesConfigItemProps.typeOfValue === "FORMULA" && (
          <FormulaConfig
            formula={
              editedOtherAttributesConfigItemProps.formula || {
                nrOfCharsInCounter: 3,
                confArr: [],
              }
            }
            onFormulaConfUpdate={(formula) => updateAttribute("formula", formula)}
          />
        )}
        <h5 className="mt-2">Farge på tittel</h5>
        <Button id="colorTooltip" className="px-3 mr-2" onClick={() => setTooltipVisible(true)}>
          <div className="d-flex align-items-center">
            <div
              className="mmi-dot mr-2"
              style={{
                marginBottom: "0px",
                backgroundColor: editedOtherAttributesConfigItemProps?.labelColor,
                outline: editedOtherAttributesConfigItemProps?.labelColor ? "" : "2px solid #aeaeae",
              }}
            ></div>
            <span>velg farge</span>
          </div>
        </Button>
        <Popover
          className="colorPickerPopup"
          target="colorTooltip"
          placement="right"
          trigger="focus"
          isOpen={tooltipVisible}
        >
          <div ref={toolTipRef} style={{ padding: "10px" }}>
            <HexColorPicker
              color={editedOtherAttributesConfigItemProps?.labelColor}
              onChange={(newColor) => updateAttribute("labelColor", newColor ?? "")}
            />
            <div style={{ marginTop: "5px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <HexColorInput
                color={editedOtherAttributesConfigItemProps?.labelColor}
                onChange={(newColor) => updateAttribute("labelColor", newColor ?? "")}
                style={{ width: "140px" }}
                className="mcinput"
                autoFocus={true}
              />
              <Button color="success" onClick={() => setTooltipVisible(false)}>
                <i className={`fa fa-check`} />
              </Button>
            </div>
          </div>
        </Popover>
        <Button onClick={() => updateAttribute("labelColor", "")}>
          <i className={`fa fa-times`} /> Nullstill
        </Button>
        <McTitle className="mt-2" title="Låst">
          <McTitle.InlineData positionInlineData="after">
            <h5>
              <i className="fa fa-info-circle fa-fw text-info" title="Låste attributter kan ikke endres i tabellen"></i>
            </h5>
          </McTitle.InlineData>

          <Switch
            checked={editedOtherAttributesConfigItemProps.locked}
            onChange={(bool) => updateAttribute("locked", bool)}
          />
        </McTitle>
        <McTitle className="mt-2" title="Aktiv">
          <McTitle.InlineData positionInlineData="after">
            <h5>
              <i className="fa fa-info-circle fa-fw text-info" title="Inaktive attributter vises ikke"></i>
            </h5>
          </McTitle.InlineData>

          <Switch
            checked={editedOtherAttributesConfigItemProps.isActive}
            onChange={(bool) => updateAttribute("isActive", bool)}
          />
        </McTitle>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>Lukk</Button>
        <Button disabled={isDisabled} color="success" onClick={() => onSave(editedOtherAttributesConfigItemProps)}>
          Lagre
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default OtherAttributeItemConfigModal;

const ColorMenuRenderer = ({ value, data }: CustomCellRendererProps) => {
  return (
    <div
      className="w-100"
      style={{
        minWidth: "60px",
        height: "20px",
        borderRadius: "5px",
        backgroundColor: value,
        outline: value ? "" : "1px solid #e1e1e1",
      }}
    />
  );
};

const ColorMenuEditor: React.FC<CustomCellEditorProps> = ({ value, onValueChange, stopEditing }) => {
  return (
    <UncontrolledDropdown isOpen={true} className="dropdown-menu dropdown-light" style={{ minWidth: "80px" }}>
      {Object.values(STANDARD_COLORS).map((color) => (
        <McDropdownBase.Option
          style={{ padding: ".3em .6em" }}
          key={color}
          onClick={() => {
            onValueChange(color);
            stopEditing();
          }}
        >
          <div
            style={{
              width: "100%",
              height: "20px",
              borderRadius: "5px",
              backgroundColor: color,
            }}
          />
        </McDropdownBase.Option>
      ))}
      <McDropdownBase.Option
        style={{ padding: ".3em .6em" }}
        key={"clear"}
        onClick={() => {
          onValueChange("");
          stopEditing();
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "20px",
            borderRadius: "5px",
            color: "black",
            fontSize: "14px",
          }}
        >
          <span className="mr-1" style={{ marginBottom: "3px" }}>
            NULLSTILL
          </span>
          <i className="fa fa-refresh"></i>
        </div>
      </McDropdownBase.Option>
    </UncontrolledDropdown>
  );
};
const TableOptions: React.FC<{
  updateAttribute: (key: keyof IOtherAttributeConfigItemProps, value: any) => void;
  editedOtherAttributesConfigItemProps: IOtherAttributeConfigItemProps;
}> = ({ updateAttribute, editedOtherAttributesConfigItemProps }) => {
  const history = useHistory();
  const location = history.location;
  const projectId = getProjectIdFromUrl(location.pathname);
  const issueBoardId = getIssueBoardIdFromUrl(location.pathname);

  const { data: issueBoard } = useGetSingleIssueBoardQuery({
    projectId: projectId,
    issueBoardId: issueBoardId,
  });

  const otherAttirbutesConfigItemPropsWithDropdown = Object.entries(issueBoard?.otherAttributesConfig || {})
    .filter(([_propKey, content]) => {
      return content.typeOfValue === "DROPDOWN";
    })
    .map(([propKey, content]) => propKey);

  const colDefs: ColDef<{ value: string; description: string; color?: string }>[] = [
    {
      field: "value",
      headerName: "Navn",
      editable: true,
    },
    {
      field: "description",
      headerName: "Beskrivelse",
      editable: true,
      flex: 1,
    },
    {
      field: "color",
      headerName: "Farge",
      editable: true,
      width: 100,
      cellRenderer: ColorMenuRenderer,
      cellEditor: ColorMenuEditor,
      cellEditorPopup: true,
    },
  ];

  const newItemTemplateObject: { value: string; description: string } = {
    value: "",
    description: "",
  };

  const onCopyValuesFromOtherAttribute = (propKey: ICapitalLetter) => {
    const otherAttribute = issueBoard?.otherAttributesConfig[propKey];
    if (otherAttribute) {
      updateAttribute("tableOptions", otherAttribute.tableOptions);
    }
  };

  return (
    <div className="d-flex flex-column border p-3 mt-2">
      <h5>Alternativ</h5>
      {otherAttirbutesConfigItemPropsWithDropdown?.length > 0 && (
        <div style={{ width: "200px" }}>
          <McDropdown
            currentValue={null}
            onChange={(_, item) => onCopyValuesFromOtherAttribute(item)}
            defaultTitle="Kopier verdier fra?"
            options={otherAttirbutesConfigItemPropsWithDropdown}
          />
          <VSpace />
        </div>
      )}
      <ViewEditTable
        columns={colDefs}
        data={(editedOtherAttributesConfigItemProps.tableOptions as { value: string; description: string }[]) || []}
        onSave={(data) => updateAttribute("tableOptions", data)}
        newItemTemplateObject={newItemTemplateObject}
        canEdit={true}
        dropdownContainer={"dropdownContainer"}
      />
      <hr />
      <h6>Et eller flera val?</h6>
      <span style={{ fontSize: "10px" }}>
        <i>Angi om du vil kunne velge ett eller flere valg fra listen</i>
      </span>
      <VSpace />
      <div>
        <ButtonGroup>
          <Button
            onClick={() => updateAttribute("allowMultipleSelectionsInDropdown", false)}
            active={editedOtherAttributesConfigItemProps.allowMultipleSelectionsInDropdown === false}
          >
            Ett valg
          </Button>
          <Button
            onClick={() => updateAttribute("allowMultipleSelectionsInDropdown", true)}
            active={editedOtherAttributesConfigItemProps.allowMultipleSelectionsInDropdown}
          >
            Fler valg
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};
