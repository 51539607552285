import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { IAzureBlobFile } from "mc-shared/zod/azureSchema";
import React from "react";
import { Button, Modal, ModalHeader } from "reactstrap";
import { AG_GRID_LOCALE_NOR } from "../../agGridLocale";
import { formatShortDate } from "../../app/routes/admin/selectors";
import { Checkbox, ConfirmDialog, HSpace } from ".././";

const FileTable: React.FC<{
  files: IAzureBlobFile[];
  downloadFile: (fileId: string, fileName: string) => void;
  deleteFile: (fileId: string) => void;
  showDocxImageTag?: boolean;
  folderPath?: string;
  allowToggleItems?: boolean;
  onToggleItem?: (fileId: string) => void;
}> = ({
  files,
  downloadFile,
  deleteFile,
  showDocxImageTag = false,
  folderPath,
  allowToggleItems = false,
  onToggleItem,
}) => {
  const [openImageModal, setOpenImageModal] = React.useState(false);
  const [activeImg, setActiveImg] = React.useState("");

  const onClickImg = (filename: string) => {
    setActiveImg(filename);
    setOpenImageModal(true);
  };

  function formatFileSize(bytes: number): string {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(1)) + " " + sizes[i];
  }

  const columns: ColDef<IAzureBlobFile>[] = [
    {
      headerName: "ID",
      field: "blobName",
      hide: true,
    },
    {
      headerName: "",
      suppressHeaderMenuButton: true,
      sortable: false,
      width: 110,
      cellRenderer: (params) => {
        return (
          <div className="d-flex">
            <Button
              onClick={() => downloadFile(params.data?.blobName, params.data?.fileName)}
              color="primary"
              outline
              size="sm"
            >
              <i className="fa fa-download fa-fw" />
            </Button>
            <HSpace />
            <ConfirmDialog
              title="Slette fil?"
              message="Er du sikker på at du vil slette filen?"
              confirmCb={() => deleteFile(params.data?.blobName)}
            >
              <Button onClick={() => {}} color="danger" outline>
                <i className="fa fa-trash fa-fw" />
              </Button>
            </ConfirmDialog>
          </div>
        );
      },
    },
    {
      headerName: "Filnavn",
      field: "fileName",
      cellRenderer: (params) => {
        return <div className="ag-cell-text-truncate">{params.value}</div>;
      },
      flex: 1,
    },
    {
      headerName: "Tagg for docxtemplater",
      field: showDocxImageTag === false ? "blobName" : "fileName", // MÅ ENDRES
      hide: showDocxImageTag === false,
      cellRenderer: (params) => (params.value != null ? `{%${params.value}}` : ""),
    },
    {
      headerName: "Opprettet",
      field: "uploadedAt",
      initialSort: "desc",
      cellRenderer: (params) => formatShortDate(params.value),
      width: 120,
    },
    {
      headerName: "Opplastet av",
      field: "uploadedBy",
    },
    {
      headerName: "Størrelse",
      field: "size",
      cellRenderer: (params) => {
        const formattedSize = formatFileSize(params.value);
        return <div className="ag-cell-text-truncate">{formattedSize}</div>;
      },
      width: 90,
    },
    {
      headerName: "",
      sortable: false,
      cellClass: "d-flex justify-content-center",
      suppressHeaderMenuButton: true,
      cellRenderer: (params) => {
        return (
          <div className="d-flex">
            <img
              className="pointer"
              src={`/api/image/fileTable/${params.data?.blobName}`}
              alt=""
              width="100px"
              onClick={() => onClickImg(params.data?.blobName)}
            />
          </div>
        );
      },
    },
    {
      headerName: "",
      hide: allowToggleItems !== true,
      suppressHeaderMenuButton: true,
      cellRenderer: (params) => {
        return (
          <Checkbox
            isChecked={!!params}
            setChecked={() => {
              onToggleItem(params.data?._id);
            }}
            disabled={false}
          />
        );
      },
    },
  ];

  return (
    <div className="ag-theme-quartz h-100 w-100">
      <AgGridReact
        domLayout={"autoHeight"}
        rowData={files}
        defaultColDef={{
          suppressHeaderMenuButton: true,
        }}
        rowHeight={70}
        columnDefs={columns}
        suppressClickEdit={true}
        rowSelection="single"
        animateRows={true}
        suppressContextMenu={true}
        onGridReady={(e) => {
          e.api.autoSizeColumns(["createdBy.name"]);
        }}
        localeText={AG_GRID_LOCALE_NOR}
      />
      {openImageModal === true && (
        <Modal
          isOpen={openImageModal}
          toggle={() => setOpenImageModal(false)}
          style={{ maxWidth: "1000px", width: "50%" }}
        >
          <ModalHeader>{files?.find((file) => file.blobName === activeImg)?.fileName}</ModalHeader>
          <div>
            <img src={`/api/image/fileTable/${activeImg}`} alt="" width="100%" />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default FileTable;
