import * as R from "ramda";
import React, { useEffect, useState } from "react";
import { IRisiko } from "../../../../../../types/global.types";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { setFiltredByChartOrMatrixRisikoIdsInRedux } from "../actions";
import { IScoreMatrix } from "../types";
import { convertScoreMatrixToColorAndRisikosIds, createCircleChartDataSeries, createScoreMatrix } from "../util";
import RisikoCategoryMatrixAndChartSingleItem from "./RisikoCategoryMatrixAndChartSingleItem";

const RisikoCategoryMatrixAndChartMultipleItems: React.FC<{
  risikosInTable: IRisiko[];
}> = ({ risikosInTable }) => {
  const [scoreMatrixes, setScoreMatrixes] = useState<IScoreMatrix[]>();
  const [circleCharts, setCircleCharts] = useState(null);

  const dispatch = useAppDispatch();
  const activeBoard = useAppSelector((state) => state.risikoReducer.activeBoard);
  const categories = useAppSelector((state) => state.risikoReducer.categories);

  useEffect(() => {
    const _scoreMatrixes = categories.map((category) => {
      return createScoreMatrix(category, risikosInTable, activeBoard);
    });
    setScoreMatrixes(_scoreMatrixes);
    convertMatrixesToCircleChartAndSetData(_scoreMatrixes);
  }, [risikosInTable.length]);

  const convertMatrixesToCircleChartAndSetData = (_scoreMatrixes: IScoreMatrix[]) => {
    const _circleCharts = _scoreMatrixes.map((matrix) => {
      return {
        name: matrix.name,
        chartData: createCircleChartDataSeries(convertScoreMatrixToColorAndRisikosIds(matrix)),
      };
    });
    setCircleCharts(_circleCharts);
  };

  const setChartColorItem = (name: string, color: string) => {
    const category = scoreMatrixes.find((q) => q.name === name);
    const arr = category.data.filter((q) => q.color === color);
    const risikoIds = R.flatten(R.pluck("items", arr));
    dispatch(setFiltredByChartOrMatrixRisikoIdsInRedux(risikoIds));
  };

  return (
    <>
      {scoreMatrixes != null &&
        categories.map((category) => {
          return (
            <RisikoCategoryMatrixAndChartSingleItem
              key={category.name}
              scoreMatrix={scoreMatrixes.find((matrix) => matrix.name === category.name)}
              circleChart={circleCharts != null ? circleCharts.find((chart) => chart.name === category.name) : []}
              categoryName={category.name}
              setChartColorItem={setChartColorItem}
            />
          );
        })}
    </>
  );
};

export default RisikoCategoryMatrixAndChartMultipleItems;
