import * as R from "ramda";
import { IError, ILabelAndCount } from "../../../../../../types/global.types";

export const groupAndCountErrors = (errors: IError[], property: string): ILabelAndCount[] => {
  const grouped = R.groupBy((error) => error[property], errors);
  const groupedErrors: ILabelAndCount[] = Object.keys(grouped).map((key, index) => {
    return {
      label: key,
      count: grouped[key].length,
      items: grouped[key],
      id: index,
    };
  });

  return groupedErrors;
};
