import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import React, { useRef, useState } from "react";
import { IMcBimMongo } from "../../../../types/mcbimMono.types";
import { formatShortDate } from "../../app/routes/admin/selectors";

const MCBimOverview: React.FC<{
  data: IMcBimMongo.ProjectInfo;
  projectNo: string;
}> = ({ data, projectNo }) => {
  const dataList = [
    { title: "Automatisk synkronisering", value: data?.scheduled === true ? "Ja" : "Nei" },
    { title: "Filer lagret for synkronisering", value: data?.nrOfFilesSavedForSync },
    { title: "Siste synkronisering", value: formatShortDate(data?.lastSyncDate) },
    { title: "Antall bimobjekt i databasen", value: data?.totalBimObjectCount?.toLocaleString() },
  ];

  const sdk = new ChartsEmbedSDK({ baseUrl: "https://charts.mongodb.com/charts-mcapps-ihimm" });
  const chartDiv1 = useRef(null);
  const [rendered1, setRendered1] = useState(false);
  const [chart1] = useState(
    sdk.createChart({
      chartId: "643ef05c-18a3-4c19-86ac-c3f051b9f1a1",
      showAttribution: false,
      //     theme: "dark",
    }),
  );

  React.useEffect(() => {
    chart1
      .render(chartDiv1.current)
      .then(() => setRendered1(true))
      .catch((err) => console.log("Error during Charts rendering.", err));
  }, [chart1]);

  React.useEffect(() => {
    if (rendered1) {
      chart1.setFilter({ projectId: projectNo }).catch((err) => console.log("Error while filtering.", err));
    }
  }, [chart1, rendered1]);

  const chartDiv2 = useRef(null);
  const [rendered2, setRendered2] = useState(false);
  const [chart2] = useState(
    sdk.createChart({
      chartId: "643ef1f8-80fa-48cf-8739-5f4e802511e1",
      showAttribution: false,
      //  theme: "dark",
    }),
  );

  React.useEffect(() => {
    chart2
      .render(chartDiv2.current)
      .then(() => setRendered2(true))
      .catch((err) => console.log("Error during Charts rendering.", err));
  }, [chart2]);

  React.useEffect(() => {
    if (rendered2) {
      chart2.setFilter({ projectId: projectNo }).catch((err) => console.log("Error while filtering.", err));
    }
  }, [chart2, rendered2]);

  return (
    <div className="border" style={{ height: "300px" }}>
      <div
        className="d-flex align-items-center w-100 gray-100 p-2 border"
        style={{ borderRadius: "6px", border: "2px solid #f4f4f4" }}
      >
        <div
          className=" mx-2"
          style={{
            backgroundColor: `#4caf50`,
            width: "13px",
            height: "13px",
            borderRadius: "50%",
          }}
        ></div>
        <div className="mr-3">Aktiv</div>
      </div>
      <div className="d-flex" style={{ height: "calc(100% - 50px)" }}>
        <table>
          <tbody className="d-flex flex-column align-items-center m-3">
            {dataList.map((item, index) => {
              return (
                <tr
                  key={index}
                  className={`d-flex justify-content-between w-100 ${index % 2 ? "gray-100" : ""} py-1 px-3`}
                  style={{ borderBottom: "2px solid  #f0f0f0" }}
                >
                  <td className="text-secondary mr-3">{item.title}:</td>
                  <td className="text-secondary">
                    <b>{item.value}</b>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="flex-fill p-1 d-flex flex-column h-100">
          <div className="d-flex w-100 h-100">
            <div ref={chartDiv1} className="bg-dark w-50 h-100 mr-1"></div>
            <div ref={chartDiv2} className="bg-dark w-50 h-100"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MCBimOverview;
