import { ISJArapport } from "mc-shared/zod/report/Elektro/sjaSchema";
import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { VSpace } from "../../../../../components";
import { SJAOptionsTable } from "./SJAOptionsTable";

const SJAOptions: React.FC<{
  editData: ISJArapport;
  updateData: (key: string, value: any) => void;
}> = ({ editData, updateData }) => {
  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Aktuelle punkter</CardTitle>
        {Object.entries(editData.options)?.map(([key, option]) => {
          return (
            <React.Fragment key={key}>
              <Card>
                <CardBody>
                  <SJAOptionsTable category={key} editData={option} updateData={updateData} />
                </CardBody>
              </Card>
              <VSpace />
            </React.Fragment>
          );
        })}
      </CardBody>
    </Card>
  );
};

export default SJAOptions;
