import React, { cloneElement, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const ConfirmDialog: React.FC<{
  title: string;
  message: string;
  confirmCb: () => void;
  children: JSX.Element;
  color?: string;
  confirmButtonText?: string;
}> = ({ title, message, confirmCb, children, confirmButtonText, color }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const confirmClick = () => {
    setIsOpen(false);
    confirmCb();
  };
  return (
    <>
      <>{cloneElement(children, { onClick: () => setIsOpen(true) })}</>
      <Modal isOpen={isOpen} toggle={() => setIsOpen(false)}>
        <ModalHeader toggle={() => setIsOpen(false)}>{title}</ModalHeader>
        <ModalBody>{message}</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setIsOpen(false)}>
            Avbryt
          </Button>
          <Button color={color != null ? color : "danger"} onClick={confirmClick}>
            <i className="fa fa-exclamation-triangle fa-fw" />
            {confirmButtonText != null ? confirmButtonText : "Slett"}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ConfirmDialog;
