import React from "react";

import { useHistory } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { IMeeting } from "../../../../../../types/meeting.types";
import { FailedAlert, McDropdown, McTitle } from "../../../../components";
import { ColorDropdown } from "../../../../components/ColorDropDown";
import McInput from "../../../../components/McInput/McInput";
import { getProjectIdFromUrl } from "../../../utils";
import { useGetIssueBoardsQuery } from "../../issuesApp/IssueBoardRTK";
import {
  MEETING_INTERVALS,
  MEETINGS_SERIES_STATUSES,
} from "../../projectdashboard/MeetingSeries/meetingSeriesConstants";
import { useUpdateMeetingSerieItemMutation } from "../meetingSerieRTK";

export const MeetingSerieItemModal: React.FC<{
  meetingSerie: IMeeting.MeetingSerie;
  toggle: () => void;
}> = ({ meetingSerie, toggle }) => {
  const [updateMeetingSerie, { isError: updateError }] = useUpdateMeetingSerieItemMutation();
  const history = useHistory();
  const projectId = getProjectIdFromUrl(history.location.pathname);

  const { data: issueBoards = [] } = useGetIssueBoardsQuery({
    projectId: projectId,
  });

  const onChange: IMeeting.onMeetingSerieItemChange = (oldValue, newValue, param) => {
    updateMeetingSerie({
      projectId: projectId,
      meetingSerieId: meetingSerie._id,
      attribute: param,
      value: newValue,
    });
  };

  return (
    <Modal toggle={toggle} isOpen={true}>
      {updateError === true && <FailedAlert />}
      {meetingSerie != null && (
        <>
          <ModalHeader toggle={toggle}>{meetingSerie.name}</ModalHeader>
          <ModalBody>
            <McTitle title="Status">
              <McDropdown
                currentValue={MEETINGS_SERIES_STATUSES.find((item) => item.value === meetingSerie.status)}
                displayKey="label"
                options={MEETINGS_SERIES_STATUSES}
                onChange={(oldVal, newObj) => onChange(null, newObj.value as any, "status")}
              />
            </McTitle>
            <McTitle title="Tittel">
              <McInput onBlur={(e) => onChange(meetingSerie.name, e.target.value, "name")} value={meetingSerie.name} />
            </McTitle>
            <McTitle title="Beskrivelse">
              <McInput
                type="textarea"
                onBlur={(e) => onChange(meetingSerie.description, e.target.value, "description")}
                value={meetingSerie.description}
              />
            </McTitle>
            <McTitle title="Interval">
              <McDropdown
                currentValue={MEETING_INTERVALS.find((item) => item.id === String(meetingSerie.interval))}
                displayKey="name"
                options={MEETING_INTERVALS}
                onChange={(oldVal, newObj) => onChange(null, newObj.id, "interval")}
              />
            </McTitle>
            <McTitle title="Startdato">
              <McInput
                type="date"
                onBlur={(e) => onChange(meetingSerie.seriesStart, e.target.value, "seriesStart")}
                value={meetingSerie.seriesStart}
              />
            </McTitle>
            <McTitle title="Til dato">
              <McInput
                type="date"
                onBlur={(e) => onChange(meetingSerie.seriesEnd, e.target.value, "seriesEnd")}
                value={meetingSerie.seriesEnd}
              />
            </McTitle>
            <McTitle title="Starttid">
              <McInput
                type="time"
                onBlur={(e) => onChange(meetingSerie.timeStart, e.target.value, "timeStart")}
                value={meetingSerie.timeStart}
              />
            </McTitle>
            <McTitle title="Sluttid">
              <McInput
                type="time"
                onBlur={(e) => onChange(meetingSerie.timeEnd, e.target.value, "timeEnd")}
                value={meetingSerie.timeEnd}
              />
            </McTitle>
            <McTitle title="Farge">
              <ColorDropdown
                activeColor={meetingSerie.color}
                setColor={(color) => onChange(meetingSerie.color, color, "color")}
              />
            </McTitle>
            <McTitle title="Standard saksregister">
              <McDropdown
                disabled={issueBoards.length === 0}
                onClear={
                  meetingSerie.defaultIssueBoard != null ? () => onChange(null, "defaultIssueBoard", null) : null
                }
                currentValue={meetingSerie.defaultIssueBoard}
                onChange={(oldVal, newVal) => onChange(null, newVal, "defaultIssueBoard")}
                options={issueBoards.map((board) => ({ name: board.name, _id: board._id })) ?? []}
                displayKey="name"
              />
            </McTitle>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </>
      )}
    </Modal>
  );
};

export default MeetingSerieItemModal;
