import Axios from "axios";
import saveAs from "file-saver";
import fileDownload from "js-file-download";
import { IDocxImage, IFile } from "../../../../../types/global.types";
import { IReport } from "../../../../../types/report.types";

export const doCreateReportBoard = async (
  projectId: string,
  name: string,
  templateId: number,
): Promise<IReport.IReportBoardPopulated> => {
  try {
    const fetch = await Axios.post(`/api/project/${projectId}/reportboard`, { name, templateId });
    return Promise.resolve(fetch.data);
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetReportBoards = async (projectId: string): Promise<IReport.IReportBoardPopulated[]> => {
  try {
    const logFetch = await Axios.get(`/api/project/${projectId}/reportboard`);
    return Promise.resolve(logFetch.data);
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetSingleReportBoard = async (
  projectId: string,
  reportBoardId: string,
): Promise<IReport.IReportBoardPopulated> => {
  try {
    const fetch = await Axios.get(`/api/project/${projectId}/reportboard/${reportBoardId}`);
    return Promise.resolve(fetch.data);
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doUpdateReportBoardGeneralSettings = async <T extends keyof IReport.IReportBoardPopulated>(
  projectId: string,
  reportBoardId: string,
  attribute: T,
  newValue: IReport.IReportBoardPopulated[T],
): Promise<IReport.IReportBoardPopulated> => {
  try {
    const fetch = await Axios.put(`/api/project/${projectId}/reportboard/${reportBoardId}/settings`, {
      attribute,
      newValue,
    });
    return Promise.resolve(fetch.data);
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doUpdateReportData = async (
  projectId: string,
  reportBoardId: string,
  reportData: object,
  message: string,
  templateId: number,
): Promise<IReport.IReportBoardPopulated> => {
  try {
    const fetch = await Axios.put(`/api/project/${projectId}/reportboard/${reportBoardId}/reportdata`, {
      reportData,
      message,
      templateId,
    });
    return Promise.resolve(fetch.data);
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGenerateReportAppDocx = async (
  templateId: number,
  data: object,
  images: IDocxImage[],
  projectNr: number,
  reportName: string,
) => {
  try {
    const file = await Axios.put(
      `/api/reportapptemplate/template/${templateId}/generate-docx`,
      { data, images },
      {
        responseType: "arraybuffer",
      },
    );
    const fileName = templateId === 103 ? `${projectNr}-RIG-RAP-001.docx` : `${reportName}.docx`;
    const blob = new Blob([file.data], { type: "application/x-msdownload" });
    saveAs(blob, `${fileName}`);
    return Promise.resolve("OK");
  } catch (error) {
    console.error(error);
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doReportFileUpload = async (file, projectId: string, reportBoardId: string, docxImageTag?: string) => {
  try {
    const data = new FormData();
    data.append("fileToUpload", file);
    if (docxImageTag != null) {
      data.append("docxImageTag", docxImageTag);
    }
    await Axios.request({
      method: "POST",
      url: `/api/project/${projectId}/reportboard/${reportBoardId}/upload`,
      data,
      headers: {
        "content-type": `multipart/form-data`,
      },
    });
  } catch (error) {
    // Keep this!
    return Promise.reject(error);
  }
};

interface ImageArgs {
  projectId: string;
  reportBoardId: string;
  docxImageTag: string;
  imageData: string;
  height?: number;
  width?: number;
}

export const doReportImageUpload = async (args: ImageArgs): Promise<IFile[]> => {
  try {
    const resp = await Axios.post(`/api/project/${args.projectId}/reportboard/${args.reportBoardId}/uploadimage`, {
      docxImageTag: args.docxImageTag,
      imageData: args.imageData,
      height: args.height,
      width: args.width,
    });
    return Promise.resolve(resp.data);
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doReportFileDownload = async (projectId: string, fileId: string, reportId: string, filename: string) => {
  try {
    const response = await Axios({
      url: `/api/project/${projectId}/reportboard/${reportId}/file/${fileId}`,
      method: "GET",
      responseType: "blob", // Important
    });
    fileDownload(response.data, filename);
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doDeleteReportFile = async (projectId: string, risikoId: string, fileId: string) => {
  try {
    const response = await Axios.delete(`/api/project/${projectId}/reportboard/${risikoId}/file/${fileId}`);
    return response.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetSingleMaconomyProject = async (projectId: string) => {
  try {
    const response = await Axios.get(`/api/project/${projectId}/maconomy-project`);
    return response.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};
