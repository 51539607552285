import {
  GK_RAPPORT_TEST_TYPES_AVTREKKSSKAP_IGANGKJOERING,
  GK_RAPPORT_TEST_TYPES_AVTREKKSSKAP_RUTINEKONTROLL,
  GK_RAPPORT_TEST_TYPES_GMP_KVALIFISERING_ROM_LAMINAER,
  GK_RAPPORT_TEST_TYPES_GMP_KVALIFISERING_ROM_TURBULENT,
  GK_RAPPORT_TEST_TYPES_GMP_REKVALIFISERING_ROM_LAMINAER,
  GK_RAPPORT_TEST_TYPES_GMP_REKVALIFISERING_ROM_TURBULENT,
  GK_RAPPORT_TEST_TYPES_ISO_KVALIFISERING_ROM_LAMINAER,
  GK_RAPPORT_TEST_TYPES_ISO_KVALIFISERING_ROM_TURBULENT,
  GK_RAPPORT_TEST_TYPES_ISOLATOR,
  GK_RAPPORT_TEST_TYPES_LAFTAK,
  GK_RAPPORT_TEST_TYPES_PUNKTAVSUG_OG_KJEMIKALIESKAP,
  GK_RAPPORT_TEST_TYPES_SIKKERHETSKABINETTI,
  GK_RAPPORT_TEST_TYPES_SIKKERHETSKABINETTII,
  GK_RAPPORT_TEST_TYPES_SIKKERHETSKABINETTIII,
  LuftmoensterEnum,
  RomAktivitetEnum,
  RomEllerUtstyrEnum,
  TestTypeLAFTakEnum,
  TestTypeRomEnum,
  TestTypeUtstyrEnum,
  UtstyrEnum,
  ValideringEnum,
} from "mc-shared/zod/report/GK/gkReportConstants";
import { IGKRapport, IGKTestInnstillinger } from "mc-shared/zod/report/GK/gkReportSchema";
import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../types/global.types";
import { DropdownFilterGeneric, HSpace, McDropdown, VSpace } from "../../../../../components";

const GKtestPicker: React.FC<{
  testInnstillinger: IGKTestInnstillinger;
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
}> = ({ testInnstillinger, onUpdateEditData }) => {
  const ROMELLERUTSTYR: RomEllerUtstyrEnum[] = [
    RomEllerUtstyrEnum.ROM,
    RomEllerUtstyrEnum.UTSTYR,
    RomEllerUtstyrEnum.LAFTAK,
  ];
  const TESTVALIDERINGER: ValideringEnum[] = [
    ValideringEnum.GMP_KVALIFISERING,
    ValideringEnum.GMP_REKVALIFISERING,
    ValideringEnum.ISO_KVALIFISERING,
  ];
  const LUFTMOENSTER: LuftmoensterEnum[] = [LuftmoensterEnum.LAMINAERT, LuftmoensterEnum.TURBULENT];

  const ROMAKTIVITET: RomAktivitetEnum[] = [
    RomAktivitetEnum.ATREST,
    RomAktivitetEnum.INOPERATION,
    RomAktivitetEnum.ASBUILT,
  ];

  const UTSTYRSLISTE: UtstyrEnum[] = [
    UtstyrEnum.SIKKERHETSKABINETTI,
    UtstyrEnum.SIKKERHETSKABINETTII,
    UtstyrEnum.SIKKERHETSKABINETTIII,
    UtstyrEnum.ISOLATOR_POSITIV,
    UtstyrEnum.ISOLATOR_NEGATIV,
    UtstyrEnum.AVTREKKSSKAP_RUTINEKONTROLL,
    UtstyrEnum.AVTREKKSSKAP_IGANGKJOERING,
    UtstyrEnum.PUNKTAVSUG_KJEMIKALIESKAP,
  ];

  const visTilgjengeligeTesterForRom =
    testInnstillinger.valgtRomEllerUtstyr != null &&
    testInnstillinger.valgtTestValidering != null &&
    testInnstillinger.valgtLuftmoenster != null &&
    testInnstillinger.valgtRomAktivitet != null;

  const visTilgjengeligeTesterForUtstyr =
    testInnstillinger.valgtRomEllerUtstyr != null && testInnstillinger.valgtUtstyr != null;

  const visTilgjengeligeTesterForLAFTak =
    testInnstillinger.valgtRomEllerUtstyr === "Årskontroll LAF-tak" && testInnstillinger.valgtRomAktivitet != null;

  const addOrRemoveTest = (test: TestTypeRomEnum | TestTypeUtstyrEnum | TestTypeLAFTakEnum) => {
    testInnstillinger.valgteTester[test] = !testInnstillinger.valgteTester[test];
    onUpdateEditData("testInnstillinger.valgteTester", testInnstillinger.valgteTester);
  };

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Valg av tester for rapport:</CardTitle>
        <b>Hvilke tester skal utføres?</b>
        <div className="d-flex flex-column">
          <div className="d-flex">
            <McDropdown
              currentValue={testInnstillinger.valgtRomEllerUtstyr}
              onChange={(_, item) =>
                testInnstillinger.valgtRomEllerUtstyr === item
                  ? onUpdateEditData("testInnstillinger.valgtRomEllerUtstyr", item)
                  : resetAndSetValgtRomEllerUtstyr(
                      testInnstillinger,
                      onUpdateEditData,
                      "testInnstillinger.valgtRomEllerUtstyr",
                      item as RomEllerUtstyrEnum,
                    )
              }
              defaultTitle="Er det rom eller utstyr?"
              options={ROMELLERUTSTYR}
            />
          </div>
          <VSpace />
          {testInnstillinger.valgtRomEllerUtstyr === RomEllerUtstyrEnum.ROM && (
            <div className="d-flex">
              <McDropdown
                currentValue={testInnstillinger.valgtTestValidering}
                onChange={(_, item: ValideringEnum) =>
                  testInnstillinger.valgtTestValidering === item
                    ? onUpdateEditData("testInnstillinger.valgtTestValidering", item)
                    : resetAndSetValgtRomEllerUtstyr(
                        testInnstillinger,
                        onUpdateEditData,
                        "testInnstillinger.valgtTestValidering",
                        item,
                      )
                }
                defaultTitle="Velg testvalidering"
                options={TESTVALIDERINGER}
              />
              <HSpace />
              <McDropdown
                currentValue={testInnstillinger.valgtLuftmoenster}
                onChange={(_, item: LuftmoensterEnum) =>
                  testInnstillinger.valgtLuftmoenster === item
                    ? onUpdateEditData("testInnstillinger.valgtLuftmoenster", item)
                    : resetAndSetValgtRomEllerUtstyr(
                        testInnstillinger,
                        onUpdateEditData,
                        "testInnstillinger.valgtLuftmoenster",
                        item,
                      )
                }
                defaultTitle="Velg luftmønster"
                options={LUFTMOENSTER}
              />
              <HSpace />
              <McDropdown
                currentValue={testInnstillinger.valgtRomAktivitet}
                onChange={(_, item: RomAktivitetEnum) => onUpdateEditData("testInnstillinger.valgtRomAktivitet", item)}
                defaultTitle="Velg romaktivitet"
                options={ROMAKTIVITET}
              />
            </div>
          )}
          {testInnstillinger.valgtRomEllerUtstyr === RomEllerUtstyrEnum.UTSTYR && (
            <div className="d-flex">
              <McDropdown
                currentValue={testInnstillinger.valgtUtstyr}
                onChange={(_, item) =>
                  testInnstillinger.valgtUtstyr === item
                    ? onUpdateEditData("testInnstillinger.valgtUtstyr", item)
                    : resetAndSetValgtRomEllerUtstyr(
                        testInnstillinger,
                        onUpdateEditData,
                        "testInnstillinger.valgtUtstyr",
                        item as UtstyrEnum,
                      )
                }
                defaultTitle="Hvilket utstyr skal testes?"
                options={UTSTYRSLISTE}
              />
            </div>
          )}
          {testInnstillinger.valgtRomEllerUtstyr === RomEllerUtstyrEnum.LAFTAK && (
            <div className="d-flex">
              <McDropdown
                currentValue={testInnstillinger.valgtTestValidering}
                onChange={(_, item: ValideringEnum) =>
                  testInnstillinger.valgtTestValidering === item
                    ? onUpdateEditData("testInnstillinger.valgtTestValidering", item)
                    : resetAndSetValgtRomEllerUtstyr(
                        testInnstillinger,
                        onUpdateEditData,
                        "testInnstillinger.valgtTestValidering",
                        item,
                      )
                }
                defaultTitle="Velg testvalidering"
                options={TESTVALIDERINGER}
              />
              <HSpace />
              <McDropdown
                currentValue={testInnstillinger.valgtRomAktivitet}
                onChange={(_, item: RomAktivitetEnum) => onUpdateEditData("testInnstillinger.valgtRomAktivitet", item)}
                defaultTitle="Velg romaktivitet"
                options={ROMAKTIVITET}
              />
            </div>
          )}
        </div>
        <VSpace />
        {visTilgjengeligeTesterForRom === true &&
          testInnstillinger.valgtRomEllerUtstyr === RomEllerUtstyrEnum.ROM &&
          testInnstillinger.valgtTestValidering === ValideringEnum.GMP_KVALIFISERING &&
          testInnstillinger.valgtLuftmoenster === LuftmoensterEnum.LAMINAERT && (
            <DropdownFilterGeneric
              filterOptions={GK_RAPPORT_TEST_TYPES_GMP_KVALIFISERING_ROM_LAMINAER.map((test) => {
                return {
                  ...test,
                  isActive: testInnstillinger.valgteTester[test.value],
                };
              })}
              onClick={(value) => addOrRemoveTest(value)}
              title="Velg tester"
              color="secondary"
              textColor="text-black"
            />
          )}
        {visTilgjengeligeTesterForRom === true &&
          testInnstillinger.valgtRomEllerUtstyr === RomEllerUtstyrEnum.ROM &&
          testInnstillinger.valgtTestValidering === ValideringEnum.GMP_KVALIFISERING &&
          testInnstillinger.valgtLuftmoenster === LuftmoensterEnum.TURBULENT && (
            <DropdownFilterGeneric
              filterOptions={GK_RAPPORT_TEST_TYPES_GMP_KVALIFISERING_ROM_TURBULENT.map((test) => {
                return {
                  ...test,
                  isActive: testInnstillinger.valgteTester[test.value],
                };
              })}
              onClick={(value) => addOrRemoveTest(value)}
              title="Velg tester"
              color="secondary"
              textColor="text-black"
            />
          )}
        {visTilgjengeligeTesterForRom === true &&
          testInnstillinger.valgtRomEllerUtstyr === RomEllerUtstyrEnum.ROM &&
          testInnstillinger.valgtTestValidering === ValideringEnum.GMP_REKVALIFISERING &&
          testInnstillinger.valgtLuftmoenster === LuftmoensterEnum.LAMINAERT && (
            <DropdownFilterGeneric
              filterOptions={GK_RAPPORT_TEST_TYPES_GMP_REKVALIFISERING_ROM_LAMINAER.map((test) => {
                return {
                  ...test,
                  isActive: testInnstillinger.valgteTester[test.value],
                };
              })}
              onClick={(value) => addOrRemoveTest(value)}
              title="Velg tester"
              color="secondary"
              textColor="text-black"
            />
          )}
        {visTilgjengeligeTesterForRom === true &&
          testInnstillinger.valgtRomEllerUtstyr === RomEllerUtstyrEnum.ROM &&
          testInnstillinger.valgtTestValidering === ValideringEnum.GMP_REKVALIFISERING &&
          testInnstillinger.valgtLuftmoenster === LuftmoensterEnum.TURBULENT && (
            <DropdownFilterGeneric
              filterOptions={GK_RAPPORT_TEST_TYPES_GMP_REKVALIFISERING_ROM_TURBULENT.map((test) => {
                return {
                  ...test,
                  isActive: testInnstillinger.valgteTester[test.value],
                };
              })}
              onClick={(value) => addOrRemoveTest(value)}
              title="Velg tester"
              color="secondary"
              textColor="text-black"
            />
          )}
        {visTilgjengeligeTesterForRom === true &&
          testInnstillinger.valgtRomEllerUtstyr === RomEllerUtstyrEnum.ROM &&
          testInnstillinger.valgtTestValidering === ValideringEnum.ISO_KVALIFISERING &&
          testInnstillinger.valgtLuftmoenster === LuftmoensterEnum.LAMINAERT && (
            <DropdownFilterGeneric
              filterOptions={GK_RAPPORT_TEST_TYPES_ISO_KVALIFISERING_ROM_LAMINAER.map((test) => {
                return {
                  ...test,
                  isActive: testInnstillinger.valgteTester[test.value],
                };
              })}
              onClick={(value) => addOrRemoveTest(value)}
              title="Velg tester"
              color="secondary"
              textColor="text-black"
            />
          )}
        {visTilgjengeligeTesterForRom === true &&
          testInnstillinger.valgtRomEllerUtstyr === RomEllerUtstyrEnum.ROM &&
          testInnstillinger.valgtTestValidering === ValideringEnum.ISO_KVALIFISERING &&
          testInnstillinger.valgtLuftmoenster === LuftmoensterEnum.TURBULENT && (
            <DropdownFilterGeneric
              filterOptions={GK_RAPPORT_TEST_TYPES_ISO_KVALIFISERING_ROM_TURBULENT.map((test) => {
                return {
                  ...test,
                  isActive: testInnstillinger.valgteTester[test.value],
                };
              })}
              onClick={(value) => addOrRemoveTest(value)}
              title="Velg tester"
              color="secondary"
              textColor="text-black"
            />
          )}
        {visTilgjengeligeTesterForUtstyr === true &&
          testInnstillinger.valgtRomEllerUtstyr === RomEllerUtstyrEnum.UTSTYR &&
          testInnstillinger.valgtUtstyr === UtstyrEnum.SIKKERHETSKABINETTI && (
            <DropdownFilterGeneric
              filterOptions={GK_RAPPORT_TEST_TYPES_SIKKERHETSKABINETTI.map((test) => {
                return {
                  ...test,
                  isActive: testInnstillinger.valgteTester[test.value],
                };
              })}
              onClick={(value) => addOrRemoveTest(value)}
              title="Velg tester"
              color="secondary"
              textColor="text-black"
            />
          )}
        {visTilgjengeligeTesterForUtstyr === true &&
          testInnstillinger.valgtRomEllerUtstyr === RomEllerUtstyrEnum.UTSTYR &&
          testInnstillinger.valgtUtstyr === UtstyrEnum.SIKKERHETSKABINETTII && (
            <DropdownFilterGeneric
              filterOptions={GK_RAPPORT_TEST_TYPES_SIKKERHETSKABINETTII.map((test) => {
                return {
                  ...test,
                  isActive: testInnstillinger.valgteTester[test.value],
                };
              })}
              onClick={(value) => addOrRemoveTest(value)}
              title="Velg tester"
              color="secondary"
              textColor="text-black"
            />
          )}
        {visTilgjengeligeTesterForUtstyr === true &&
          testInnstillinger.valgtRomEllerUtstyr === RomEllerUtstyrEnum.UTSTYR &&
          testInnstillinger.valgtUtstyr === UtstyrEnum.SIKKERHETSKABINETTIII && (
            <DropdownFilterGeneric
              filterOptions={GK_RAPPORT_TEST_TYPES_SIKKERHETSKABINETTIII.map((test) => {
                return {
                  ...test,
                  isActive: testInnstillinger.valgteTester[test.value],
                };
              })}
              onClick={(value) => addOrRemoveTest(value)}
              title="Velg tester"
              color="secondary"
              textColor="text-black"
            />
          )}
        {visTilgjengeligeTesterForUtstyr === true &&
          testInnstillinger.valgtRomEllerUtstyr === RomEllerUtstyrEnum.UTSTYR &&
          (testInnstillinger.valgtUtstyr === UtstyrEnum.ISOLATOR_POSITIV ||
            testInnstillinger.valgtUtstyr === UtstyrEnum.ISOLATOR_NEGATIV) && (
            <DropdownFilterGeneric
              filterOptions={GK_RAPPORT_TEST_TYPES_ISOLATOR.map((test) => {
                return {
                  ...test,
                  isActive: testInnstillinger.valgteTester[test.value],
                };
              })}
              onClick={(value) => addOrRemoveTest(value)}
              title="Velg tester"
              color="secondary"
              textColor="text-black"
            />
          )}
        {visTilgjengeligeTesterForUtstyr === true &&
          testInnstillinger.valgtRomEllerUtstyr === RomEllerUtstyrEnum.UTSTYR &&
          testInnstillinger.valgtUtstyr === UtstyrEnum.AVTREKKSSKAP_RUTINEKONTROLL && (
            <DropdownFilterGeneric
              filterOptions={GK_RAPPORT_TEST_TYPES_AVTREKKSSKAP_RUTINEKONTROLL.map((test) => {
                return {
                  ...test,
                  isActive: testInnstillinger.valgteTester[test.value],
                };
              })}
              onClick={(value) => addOrRemoveTest(value)}
              title="Velg tester"
              color="secondary"
              textColor="text-black"
            />
          )}
        {visTilgjengeligeTesterForUtstyr === true &&
          testInnstillinger.valgtRomEllerUtstyr === RomEllerUtstyrEnum.UTSTYR &&
          testInnstillinger.valgtUtstyr === UtstyrEnum.AVTREKKSSKAP_IGANGKJOERING && (
            <DropdownFilterGeneric
              filterOptions={GK_RAPPORT_TEST_TYPES_AVTREKKSSKAP_IGANGKJOERING.map((test) => {
                return {
                  ...test,
                  isActive: testInnstillinger.valgteTester[test.value],
                };
              })}
              onClick={(value) => addOrRemoveTest(value)}
              title="Velg tester"
              color="secondary"
              textColor="text-black"
            />
          )}
        {visTilgjengeligeTesterForUtstyr === true &&
          testInnstillinger.valgtRomEllerUtstyr === RomEllerUtstyrEnum.UTSTYR &&
          testInnstillinger.valgtUtstyr === UtstyrEnum.PUNKTAVSUG_KJEMIKALIESKAP && (
            <DropdownFilterGeneric
              filterOptions={GK_RAPPORT_TEST_TYPES_PUNKTAVSUG_OG_KJEMIKALIESKAP.map((test) => {
                return {
                  ...test,
                  isActive: testInnstillinger.valgteTester[test.value],
                };
              })}
              onClick={(value) => addOrRemoveTest(value)}
              title="Velg tester"
              color="secondary"
              textColor="text-black"
            />
          )}
        {visTilgjengeligeTesterForLAFTak === true && (
          <DropdownFilterGeneric
            filterOptions={GK_RAPPORT_TEST_TYPES_LAFTAK.map((test) => {
              return {
                ...test,
                isActive: testInnstillinger.valgteTester[test.value],
              };
            })}
            onClick={(value) => addOrRemoveTest(value)}
            title="Velg tester"
            color="secondary"
            textColor="text-black"
          />
        )}
      </CardBody>
    </Card>
  );
};

export default GKtestPicker;

export const resetAndSetValgtRomEllerUtstyr = (
  testInnstillinger: IGKTestInnstillinger,
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void,
  path: string,
  testInnstilling: RomEllerUtstyrEnum | ValideringEnum | UtstyrEnum | LuftmoensterEnum,
) => {
  for (const prop in testInnstillinger.valgteTester) {
    if (Object.prototype.hasOwnProperty.call(testInnstillinger.valgteTester, prop)) {
      testInnstillinger.valgteTester[prop] = false;
    }
  }
  if (path === "testInnstillinger.valgtLuftmoenster") {
    let resetedTestInnstillinger: IGKTestInnstillinger = {
      valgteTester: testInnstillinger.valgteTester,
      valgtUtstyr: null,
      valgtRomEllerUtstyr: testInnstillinger.valgtRomEllerUtstyr,
      valgtTestValidering: testInnstillinger.valgtTestValidering,
      valgtLuftmoenster: testInnstilling as LuftmoensterEnum,
      valgtRomAktivitet: testInnstillinger.valgtRomAktivitet,
    };
    onUpdateEditData("testInnstillinger", resetedTestInnstillinger);
  } else if (path === "testInnstillinger.valgtTestValidering") {
    let resetedTestInnstillinger: IGKTestInnstillinger = {
      valgteTester: testInnstillinger.valgteTester,
      valgtUtstyr: null,
      valgtRomEllerUtstyr: testInnstillinger.valgtRomEllerUtstyr,
      valgtTestValidering: testInnstilling as ValideringEnum,
      valgtLuftmoenster: testInnstillinger.valgtLuftmoenster,
      valgtRomAktivitet: testInnstillinger.valgtRomAktivitet,
    };
    onUpdateEditData("testInnstillinger", resetedTestInnstillinger);
  } else {
    let resetedTestInnstillinger: IGKTestInnstillinger = {
      valgteTester: testInnstillinger.valgteTester,
      valgtUtstyr: path === "testInnstillinger.valgtUtstyr" ? (testInnstilling as UtstyrEnum) : null,
      valgtRomEllerUtstyr:
        path === "testInnstillinger.valgtRomEllerUtstyr"
          ? (testInnstilling as RomEllerUtstyrEnum)
          : testInnstillinger.valgtRomEllerUtstyr,
      valgtTestValidering: null,
      valgtLuftmoenster: null,
      valgtRomAktivitet: null,
    };
    onUpdateEditData("testInnstillinger", resetedTestInnstillinger);
  }
};
