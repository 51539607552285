import React from "react";
import { Button, ButtonGroup } from "reactstrap";
import { IToggleItem } from "../types";
import { Checkbox, HSpace } from "./";

const ButtonToggler: React.FC<{
  togglerItems: IToggleItem[];
  setValue: any;
  value: string | boolean;
  width?: number;
  type: "RADIO" | "BUTTON";
  disabled?: boolean;
}> = ({ togglerItems, setValue, value, width, type, disabled }) => {
  return (
    <>
      {type === "BUTTON" && (
        <ButtonGroup>
          {togglerItems.map((item, index) => (
            <div key={index}>
              <Button
                size="sm"
                style={{ width: width != null ? `${width}px` : "auto" }}
                key={index}
                disabled={disabled === true}
                color={value === item.value ? "mc-blue" : "secondary"}
                active={value === item.value}
                onClick={() => setValue(item.value)}
              >
                {item.value === value && <i className="fa fa-check fa-fw" />}
                {item.title}
              </Button>
            </div>
          ))}
        </ButtonGroup>
      )}
      {type === "RADIO" && (
        <div className="d-flex">
          {togglerItems.map((item, index) => (
            <div key={index}>
              <div className="d-flex mr-2">
                <Checkbox
                  disabled={disabled === true}
                  isChecked={value === item.value}
                  setChecked={() => setValue(item.value)}
                />
                <span className="ml-2">{item.title}</span>
              </div>
              {index !== togglerItems.length - 1 && <HSpace />}
            </div>
          ))}
        </div>
      )}
    </>
  );
};
export default ButtonToggler;
