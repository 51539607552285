import { IParentChildItem, IPNSProps } from "../../../../../types/global.types";
import { HierarchyNode } from "../../hierarchyNodeUtils";

interface Tree {
  id: string;
  parent: string;
  label: string;
  labelColor: string;
  subLabel: string;
  subLabelColor: string;
  bgColor: string;
  bgOpacity: number;
  textsFontSize: string;
  textColor: string;
}

export const formatHierarchyNodesToParentChildItems = (
  hierarchyNodes: HierarchyNode<IPNSProps>,
): IParentChildItem[] => {
  const pnsHierarchy: Tree[] = [];

  for (const node of hierarchyNodes.forEach()) {
    let props = node.props;

    pnsHierarchy.push({
      id: node.levelId,
      parent: node.parent == null ? "" : node.parent.levelId,
      label: props.title,
      labelColor: "#f8992e",
      subLabel: props.pnsId,
      subLabelColor: "#fff",
      bgColor: "#262626",
      bgOpacity: 1,
      textsFontSize: "12px",
      textColor: "#fff",
    });
  }

  return pnsHierarchy;
};
