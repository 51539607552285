import { IBatchjob } from "../../../../types/batchjob.types";
import { IChecklistPopulated } from "../../../../types/checklist.types";
import { IBoard, IRiskoBoard, IValueLabel } from "../../../../types/global.types";
import { IMeeting } from "../../../../types/meeting.types";
import { IReport } from "../../../../types/report.types";
import { TypeOfChecklistEnum } from "../routes/checklist/ChecklistConstants";

export const convertProjectUrlToValueAndLabels = (
  url: string,
  projectName,
  issueBoard: IBoard,
  risikoBoards: IRiskoBoard[],
  checklist: IChecklistPopulated,
  batchjobs: IBatchjob.IBatchJobBoardPopulated[],
  useDashboardAsDefaultStartPage: boolean,
  reports: IReport.IReportBoardPopulated[],
  activeMeeting: IMeeting.MeetingPopulated,
): IValueLabel[] => {
  const arr = url.split("/");

  const lastString = arr[arr.length - 1];
  const projectId = arr[2];

  const appUrlName = arr[3];

  const endsWithAMongoId = lastString.length === 24;

  const startValueAndLabel = {
    label: projectName,
    value: useDashboardAsDefaultStartPage === false ? `/project/${projectId}/apps` : `/project/${projectId}/dashboard`,
  };

  if (appUrlName === "apps") {
    return [startValueAndLabel];
  }

  if (appUrlName === "bimai") {
    return [
      startValueAndLabel,
      {
        label: "BIM AI",
        value: url,
      },
    ];
  }

  if (appUrlName === "dashboard") {
    return [
      startValueAndLabel,
      {
        label: "Dashboard",
        value: url,
      },
    ];
  }

  if (appUrlName === "mccos") {
    return [
      startValueAndLabel,
      {
        label: "COS & MMI",
        value: url,
      },
    ];
  }

  if (appUrlName === "bimFloorplan") {
    return [
      startValueAndLabel,
      {
        label: "BIM planvisning",
        value: url,
      },
    ];
  }

  if (appUrlName === "pns") {
    return [
      startValueAndLabel,
      {
        label: "PNS",
        value: url,
      },
    ];
  }

  if (appUrlName === "brannrapport") {
    return [
      startValueAndLabel,
      {
        label: "Brannrapport",
        value: url,
      },
    ];
  }

  if (appUrlName === "viewer") {
    return [
      startValueAndLabel,
      {
        label: "BIM Viewer",
        value: url,
      },
    ];
  }

  if (appUrlName === "bim360explorer") {
    return [
      startValueAndLabel,
      {
        label: "BIM360 explorer",
        value: url,
      },
    ];
  }

  if (appUrlName === "members") {
    return [
      startValueAndLabel,
      {
        label: "Medlemmer",
        value: url,
      },
    ];
  }

  if (appUrlName === "settings") {
    return [
      startValueAndLabel,
      {
        label: "Prosjektinnstillinger",
        value: url,
      },
    ];
  }

  //***Issues**//

  if (lastString === "issues") {
    return [
      startValueAndLabel,
      {
        label: "Register",
        value: `/project/${projectId}/${appUrlName}`,
      },
    ];
  }

  if (appUrlName === "issues" && endsWithAMongoId === true) {
    return [
      startValueAndLabel,
      {
        label: "Register",
        value: `/project/${projectId}/${appUrlName}`,
      },
      {
        label: issueBoard?.name,
        value: url,
      },
    ];
  }

  //***Risikos**//

  if (lastString === "risiko") {
    return [
      startValueAndLabel,
      {
        label: "Risiko",
        value: `/project/${projectId}/${appUrlName}`,
      },
    ];
  }

  if (appUrlName === "risiko" && endsWithAMongoId === true) {
    return [
      startValueAndLabel,
      {
        label: "Risiko",
        value: `/project/${projectId}/${appUrlName}`,
      },
      {
        label: risikoBoards.find((board) => board._id === lastString)?.name,
        value: url,
      },
    ];
  }

  //***Checklist**//

  if (appUrlName === "checklist") {
    const typeofChecklist = arr[4];

    const baseChecklist = {
      label: "Sjekkliste",
      value: `/project/${projectId}/${appUrlName}`,
    };

    if (
      (typeofChecklist === TypeOfChecklistEnum.CHECKLIST || typeofChecklist === TypeOfChecklistEnum.TEMPLATE) &&
      endsWithAMongoId === false
    ) {
      return [startValueAndLabel, baseChecklist];
    }

    if (typeofChecklist === TypeOfChecklistEnum.CHECKLIST && endsWithAMongoId === true) {
      return [
        startValueAndLabel,
        baseChecklist,
        {
          label: "Instans",
          value: `/project/${projectId}/${appUrlName}/${TypeOfChecklistEnum.CHECKLIST}`,
        },
        {
          label: checklist?.name,
          value: url,
        },
      ];
    }

    if (typeofChecklist === TypeOfChecklistEnum.TEMPLATE && endsWithAMongoId === true) {
      return [
        startValueAndLabel,
        baseChecklist,
        {
          label: "Prosjektmaler",
          value: `/project/${projectId}/${appUrlName}/${TypeOfChecklistEnum.TEMPLATE}`,
        },
        {
          label: checklist?.name,
          value: url,
        },
      ];
    }
  }

  //***Batchjob**//

  if (appUrlName === "batchjob") {
    const base = {
      label: "Batch Job",
      value: `/project/${projectId}/${appUrlName}`,
    };

    if (lastString === appUrlName) {
      return [startValueAndLabel, base];
    }

    const boardId = arr[5];
    const board = {
      label: batchjobs?.find((item) => item._id === boardId)?.name,
      value: url,
    };

    if (lastString === "settings") {
      return [startValueAndLabel, base, board];
    }

    if (lastString === "log") {
      return [startValueAndLabel, base, board];
    }

    /* if (endsWithAMongoId === false) {
      return [startValueAndLabel, base];
    }

    if (endsWithAMongoId === true) {
      return [
        startValueAndLabel,
        base,
        {
          label: issueBoards.find((board) => board._id === lastString)?.name,
          value: url,
        },
      ];
    }*/
  }

  //***Reports**//

  if (appUrlName === "reports") {
    const base = {
      label: "Rapporter",
      value: `/project/${projectId}/${appUrlName}`,
    };

    if (lastString === appUrlName) {
      return [startValueAndLabel, base];
    }

    if (endsWithAMongoId === true) {
      const report = {
        label: reports?.find((report) => report._id === lastString)?.name,
        value: url,
      };
      return [startValueAndLabel, base, report];
    }
  }

  if (appUrlName === "meetings") {
    const base = {
      label: "Møter",
      value: `/project/${projectId}/${appUrlName}`,
    };

    if (lastString === appUrlName) {
      return [startValueAndLabel, base];
    }

    /*if (endsWithAMongoId === true) {
      const report = {
        label: activeMeeting?.name,
        value: url,
      };
      return [startValueAndLabel, base, report];
    }*/
  }

  if (appUrlName === "meetings" && endsWithAMongoId === true) {
    return [
      startValueAndLabel,
      {
        label: "Møter",
        value: `/project/${projectId}/${appUrlName}`,
      },
      {
        label: activeMeeting?.name,
        value: url,
      },
    ];
  }

  return [];
};
