import { IGKRapport, IGKVisualiseringAvLuftstroemResultater } from "mc-shared/zod/report/GK/gkReportSchema";
import React from "react";
import { Card, CardBody, CardTitle, FormGroup, Input, Label } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../../types/global.types";
import { McInput } from "../../../../../../components";
import ReportDocxImageContainer from "../../../ReportBoard/ReportDocxImageContainer";

const VisualiseringAvLuftstroem: React.FC<{
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
  data: IGKVisualiseringAvLuftstroemResultater;
}> = ({ data, onUpdateEditData }) => {
  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Visualisering av luftstrøm</CardTitle>
        <div className="w-50 mt-0">
          <div>
            <b>Beskrivelse av luftstrøm:</b>
            <McInput
              value={data.inndata.beskrivelseAvLuftstroem}
              onChange={(e) =>
                onUpdateEditData(
                  "testResultater.visualiseringAvLuftstroem.inndata.beskrivelseAvLuftstroem",
                  e.target.value,
                )
              }
              type="textarea"
              dynamicHeight={true}
            />
          </div>
          <div>
            <FormGroup className="d-flex flex-column mt-2" check>
              <Label check>
                <Input
                  type="radio"
                  checked={data.inndata.godkjent === true}
                  onChange={() => onUpdateEditData("testResultater.visualiseringAvLuftstroem.inndata.godkjent", true)}
                />
                Godkjent
              </Label>
              <Label check>
                <Input
                  type="radio"
                  checked={data.inndata.godkjent === false}
                  onChange={() => onUpdateEditData("testResultater.visualiseringAvLuftstroem.inndata.godkjent", false)}
                />
                Ikke godkjent
              </Label>
            </FormGroup>
          </div>
        </div>
        <div className="mt-4">
          <ReportDocxImageContainer docxImageTag="visualiseringAvLuftstroem" title="Bilde av luftstrøm" />
          <div className="w-50">
            <b>Kommentar:</b>
            <McInput
              value={data.inndata.kommentar}
              onChange={(e) =>
                onUpdateEditData("testResultater.visualiseringAvLuftstroem.inndata.kommentar", e.target.value)
              }
              type="textarea"
              dynamicHeight={true}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default VisualiseringAvLuftstroem;
