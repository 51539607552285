import React from "react";
import { IBoard } from "../../../../../../types/global.types";
import OtherAttributesConfig from "../../../../components/OtherAttributes/OtherAttributesConfig/OtherAttributesConfig";
import { ICapitalLetter, IOtherAttributeConfigItemProps } from "mc-shared/zod/otherAttributesSchema";
import { Card, CardBody, CardTitle } from "reactstrap";
import {
  useAddIssueBoardOtherAttributeMutation,
  useUpdateIssueBoardOtherAttributeMutation,
  useUpdateIssueBoardOtherAttributeOrderMutation,
} from "../IssueBoardRTK";
import { FailedAlert, MCLoader } from "../../../../components";

const IssueOtherAttributesConfig: React.FC<{ issueBoard: IBoard }> = ({ issueBoard }) => {
  const [addOtherAttribute, { isLoading: addLoading, isError: addError }] = useAddIssueBoardOtherAttributeMutation();
  const [editOtherAttribute, { isLoading: editLoading, isError: editError }] =
    useUpdateIssueBoardOtherAttributeMutation();
  const [updateOtherAttributeOrder, { isLoading: updateOrderLoading, isError: updateOrderError }] =
    useUpdateIssueBoardOtherAttributeOrderMutation();

  const onAdd = (otherAttributesConfigItemProps: IOtherAttributeConfigItemProps) => {
    addOtherAttribute({
      projectId: issueBoard.project,
      body: {
        issueBoardId: issueBoard._id,
        otherAttributesConfigItemProps,
      },
    });
  };

  const onEdit = (
    otherAttributeConfigItemProps: IOtherAttributeConfigItemProps,
    otherAttributeConfigKey: ICapitalLetter,
  ) => {
    editOtherAttribute({
      projectId: issueBoard.project,
      body: {
        issueBoardId: issueBoard._id,
        otherAttributeConfigItemProps,
        otherAttributeConfigKey,
      },
    });
  };

  const onUpdateOrder = (idsInOrder: ICapitalLetter[]) => {
    updateOtherAttributeOrder({
      projectId: issueBoard.project,
      body: {
        issueBoardId: issueBoard._id,
        idsInOrder: idsInOrder,
      },
    });
  };

  const isLoading = addLoading || editLoading || updateOrderLoading;
  const isError = addError || editError || updateOrderError;
  return (
    <Card>
      <CardBody className="h-100">
        {isError && <FailedAlert />}
        <CardTitle tag="h3">Egne attributter</CardTitle>
        <OtherAttributesConfig
          otherAttributesConfig={issueBoard.otherAttributesConfig ?? {}}
          onAdd={onAdd}
          onEdit={onEdit}
          onUpdateOrder={onUpdateOrder}
        />
        {isLoading && <MCLoader />}
      </CardBody>
    </Card>
  );
};

export default IssueOtherAttributesConfig;
