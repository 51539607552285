import * as R from "ramda";
import React, { useState } from "react";
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Input } from "reactstrap";
import { IBootstrapTableColumnConfig } from "../types";
import ColumnsSection from "./ColumnsSection";

const ColumnSelector: React.FC<{
  columns: IBootstrapTableColumnConfig[];
  toggles: any;
  customColumnToggle: any;
  onColumnToggle;
}> = ({ columns, toggles, customColumnToggle, onColumnToggle }) => {
  const [isOpen, setIsOpen] = useState(false);
  const columnsGroupedBySection = R.pipe(
    // @ts-ignore
    R.filter((c) => c.section != null),
    // @ts-ignore
    R.groupBy((c) => c.section),
    // @ts-ignore
  )(columns);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const [searchTerm, setSearchTerm] = useState("");

  return (
    <>
      <ButtonDropdown isOpen={isOpen} toggle={toggleDropdown}>
        <DropdownToggle caret className="w-100" color="secondary" id="columndropdown">
          <span>
            <i className="fa fa-columns fa-fw" />
            Kolonner
          </span>
        </DropdownToggle>
        <DropdownMenu className="btn-block" style={{ top: "5px", width: "300px" }}>
          <DropdownItem toggle={false}>
            <Input
              className="form-control"
              placeholder="Søk"
              value={searchTerm}
              autoFocus
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </DropdownItem>
          <div style={{ overflow: "auto", height: "500px" }}>
            {Object.keys(columnsGroupedBySection).map((section) => (
              <ColumnsSection
                searchTerm={searchTerm}
                toggles={toggles}
                customColumnToggle={customColumnToggle}
                onColumnToggle={onColumnToggle}
                key={section}
                //@ts-ignore
                columns={columnsGroupedBySection[section].filter((col) => col.dataField !== "id")}
                section={section}
              />
            ))}
          </div>
        </DropdownMenu>
      </ButtonDropdown>
    </>
  );
};

export default ColumnSelector;
