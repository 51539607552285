import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "moment/dist/locale/nb";
import React from "react";
import { Button } from "reactstrap";
import { IIssue } from "../../../../../types/global.types";
import { TaskCompletedCount } from "../../../components";
import { getTaskCompleteCount } from "./issueUtils";
import { ActionIcon, IssueIcon } from "../../../components/CustomIcons/CustomIcons";

export const IssueIdWithAksjonCompletedCount: React.FC<{
  issue: IIssue;
  setSelectedIssue: (issue: IIssue) => void;
  id: string;
}> = ({ issue, setSelectedIssue, id }) => {
  return (
    <div className="d-flex align-items-center">
      {issue._id != null && <IssueIdButton issue={issue} setSelectedIssue={setSelectedIssue} id={id} />}
      <div className="flex-fill" />
      <TaskCompletedCount taskCompleteCount={getTaskCompleteCount(issue, true)} />
    </div>
  );
};

export const IssueIdButton: React.FC<{ issue: IIssue; setSelectedIssue: (issue: IIssue) => void; id: any }> = ({
  issue,
  setSelectedIssue,
  id,
}) => {
  return (
    <Button color="link" style={{ padding: "4px" }} onClick={() => setSelectedIssue(issue)}>
      {issue.taskOrIssue === "ISSUE" && (
        <span>
          <b>
            <u>
              <IssueIcon style={{ marginRight: "4px", fontSize: ".9em", marginBottom: "3px" }} />
              {id}
            </u>
          </b>
        </span>
      )}
      {issue.taskOrIssue === "TASK" && (
        <span>
          <ActionIcon className="mr-1 text-secondary" style={{ marginBottom: "3px", fontSize: "1.1em" }} />
          {id}
        </span>
      )}
    </Button>
  );
};
