import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FailedAlert, MCLoader } from "../../../components";
import { MCApplicationsUrlEnum } from "../../../frontendConstants";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useGetProjectQuery } from "../../projectRTK";
import { getProjectIdFromUrl } from "../../utils";
import { doLogUsage } from "../admin/restQueries";
import { useGetSingleIssueBoardQuery } from "./IssueBoardRTK";
import IssueTabs from "./IssueTabs";
import IssueTab from "./IssuesTab/IssueTab";
import IssueSettingsTab from "./SettingsTab/IssueSettingsTab";
import { resetIssueRedux, setIssueActiveViewInRedux } from "./issueSlice";

const IssueApp: React.FC<{ issueBoardId: string }> = ({ issueBoardId }) => {
  const [activeTab, setActiveTab] = useState("issues");

  const allFilters = useAppSelector((state) => state.issueReducerNew.allFilters);

  const history = useHistory();
  const projectId = getProjectIdFromUrl(history.location.pathname);

  const { data: project, isLoading: projectloading } = useGetProjectQuery(projectId);

  const dispatch = useAppDispatch();

  const {
    data: singleIssueBoard,
    error,
    isLoading,
    refetch: refetchIssueBoard,
  } = useGetSingleIssueBoardQuery(
    {
      projectId: project?._id,
      issueBoardId: issueBoardId,
    },
    {
      skip: projectloading === true || project?._id == null,
    },
  );
  useEffect(() => {
    if (issueBoardId != null && projectloading === false && project != null) {
      doLogUsage(project, MCApplicationsUrlEnum.ISSUE, "open");
      refetchIssueBoard();
      dispatch(resetIssueRedux());
    }
  }, [issueBoardId]);

  useEffect(() => {
    if (singleIssueBoard != null) {
      if (singleIssueBoard.defaultView != null && singleIssueBoard.defaultView !== "NONE") {
        dispatch(setIssueActiveViewInRedux(singleIssueBoard.defaultView));
      }
    }
  }, [singleIssueBoard]);

  return (
    <div className="d-flex flex-column overflow-auto h-100 w-100">
      {isLoading && <MCLoader loading={isLoading} />}
      {error != null && <FailedAlert />}
      {isLoading === false && error == null && singleIssueBoard != null && (
        <>
          <IssueTabs activeTab={activeTab} setActiveTab={setActiveTab} activeBoard={singleIssueBoard} />
          {activeTab === "issues" && allFilters != null && <IssueTab setActiveTab={setActiveTab} />}
          {activeTab === "settings" && <IssueSettingsTab />}
        </>
      )}
    </div>
  );
};

export default IssueApp;
