import { IIssueTrend } from "../../../../../../../../types/global.types";

export const getIssueTrendHighchartOptions = (issueTrendArr: IIssueTrend): Highcharts.Options => {
  return {
    accessibility: { enabled: false }, //to remove warning
    credits: {
      enabled: false,
    },
    chart: {
      type: "column",
    },
    title: {
      text: "Trend",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      type: "category",
      categories: issueTrendArr.categories,
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: "Antal",
      },
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: issueTrendArr.series as Highcharts.SeriesOptionsType[],
  };
};
