import React, { useState } from "react";
import { Button, Input, InputGroup, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const NewTableTemplate: React.FC<{ toggle: () => void; addNew: (string) => void }> = ({ toggle, addNew }) => {
  const [templateName, seTemplateName] = useState("");

  return (
    <Modal isOpen toggle={toggle} style={{ height: "300px" }}>
      <ModalHeader>Ny mal</ModalHeader>
      <ModalBody>
        <InputGroup>
          <Input
            type="text"
            placeholder="Skriv inn navn på mal"
            value={templateName}
            onChange={(e) => seTemplateName(e.target.value)}
          />
          <Button disabled={templateName === ""} onClick={() => addNew(templateName)} color="primary">
            Opprett
          </Button>
        </InputGroup>
      </ModalBody>
      <ModalFooter>
        <Button outline onClick={() => toggle()}>
          Lukk
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default NewTableTemplate;
