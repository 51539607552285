import React from "react";
import { useGetIssueHistoryLogsQuery } from "../../../../issueRTK";
import { FailedAlert } from "../../../../../../../components";
import HistoryLogsTable from "../../../../../../../components/HistoryLogsTable/HistoryLogsTable";

const IssueHistoryLogs: React.FC<{ projectId: string; issueId: string }> = ({ projectId, issueId }) => {
  const {
    data: logs = [],
    isLoading: logsLoading,
    isError,
  } = useGetIssueHistoryLogsQuery({
    projectId: projectId,
    issueId: issueId,
  });

  return (
    <div>
      {isError === true && <FailedAlert />}
      <HistoryLogsTable logData={logs} />
    </div>
  );
};

export default IssueHistoryLogs;
