import { STANDARD_COLORS } from "../../globalConstants";

export enum ReportBoardStatusEnums {
  ACTIVE = "ACTIVE",
  COMPLETED = "COMPLETED",
  DELETED = "DELETED",
}

export const REPORT_BOARD_STATUSES: { value: string; displayValue: string }[] = [
  {
    value: ReportBoardStatusEnums.ACTIVE,
    displayValue: "Aktiv",
  },
  {
    value: ReportBoardStatusEnums.COMPLETED,
    displayValue: "Lukket",
  },
  {
    value: ReportBoardStatusEnums.DELETED,
    displayValue: "Slettet",
  },
];

export const REPORT_BOARD_STATUS_CONSTANTS = {
  ACTIVE: "Aktiv",
  COMPLETED: "Lukket",
  DELETED: "Slettet",
};

export const REPORT_BOARD_STATUS_COLOR = {
  ACTIVE: STANDARD_COLORS.green,
  COMPLETED: STANDARD_COLORS.blue,
  DELETED: STANDARD_COLORS.red,
};
