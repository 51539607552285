import Axios from "axios";
import React from "react";
import "./nrh_spaces.css";
import { MCLoader, VSpace } from "../../../index";
import { Alert } from "reactstrap";

interface ISpace {
  mmi: string;
  dRofus: string;
  section: string;
  floor: string;
}

interface IMMIAndDrofus {
  dr0: number;
  dr1: number;
  dr2: number;
  mmi100: number;
  mmi200: number;
  mmi300: number;
}

interface IFloorData {
  floorName: string;
  sections: {
    J1: IMMIAndDrofus;
    J2: IMMIAndDrofus;
    J3: IMMIAndDrofus;
    J4: IMMIAndDrofus;
  };
}

export const NRH_Spaces_ByggJ: React.FC = () => {
  const [floorData, setFloorData] = React.useState<IFloorData[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<boolean>(false);

  React.useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      const _spaces =
        process.env.NODE_ENV === "production"
          ? await Axios.get("https://westeurope.azure.data.mongodb-api.com/app/nrh-lbrgq/endpoint/spaces/ito1")
          : {
              data: {
                result: [
                  { mmi: "200", dRofus: "dR1", section: "J2", floor: "02" },
                  { mmi: "200", dRofus: "dR1", section: "J2", floor: "02" },
                ],
              },
            };
      setFloorData(convertData(_spaces.data.result));
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ height: "1000px", overflow: "auto" }}>
      <h4>Rum Bygg J</h4>
      <table className="custom-table">
        <tr>
          <th colSpan={2}></th>
          <th colSpan={2}>Ferdig konsept</th>
          <th colSpan={2}>Underlag for detaljering</th>
          <th colSpan={2}>Tvärfaglig kontrollert</th>
        </tr>
        <tr>
          <td style={{ width: "100px" }}>Etasje</td>
          <td>Flöy</td>
          <td>dR0</td>
          <td>MMI100</td>
          <td>dR1</td>
          <td>MMI200</td>
          <td>dR2</td>
          <td>MMI300</td>
        </tr>
        {(floorData || []).map((floor) => {
          return <FloorRow floorData={floor} />;
        })}
      </table>
      <VSpace />
      <MCLoader loading={loading} />
      {error === true && <Alert color="danger">Failed to fetch spaces</Alert>}
    </div>
  );
};

const FloorRow: React.FC<{ floorData: IFloorData }> = ({ floorData }) => {
  return (
    <>
      <tr>
        <td rowSpan={4}>{floorData.floorName}</td>
        {floorData.sections.J1 && <SectionRow sectionName="J1" floorData={floorData} />}
      </tr>
      <tr>{floorData.sections.J2 && <SectionRow sectionName="J2" floorData={floorData} />}</tr>
      <tr>{floorData.sections.J3 && <SectionRow sectionName="J3" floorData={floorData} />}</tr>
      <tr>{floorData.sections.J4 && <SectionRow sectionName="J4" floorData={floorData} />}</tr>
    </>
  );
};

const SectionRow: React.FC<{ sectionName: string; floorData: IFloorData }> = ({ sectionName, floorData }) => {
  return (
    <>
      <td style={{ width: "100px" }}>{sectionName}</td>

      {Object.keys(floorData.sections[sectionName]).map((key) => (
        <td style={{ width: "75px" }}>{floorData.sections[sectionName][key]}</td>
      ))}
    </>
  );
};

const convertData = (spaces: ISpace[]): IFloorData[] => {
  const floors = ["U1", "U2", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "12", "13", "14"];

  return floors.reduce((acc, floor) => {
    const spacesOnFloor = spaces.filter((space) => space.floor === floor);
    const floorData: IFloorData = {
      floorName: floor,
      sections: {
        J1: createFloorData(floor, spacesOnFloor, "J1"),
        J2: createFloorData(floor, spacesOnFloor, "J2"),
        J3: createFloorData(floor, spacesOnFloor, "J3"),
        J4: createFloorData(floor, spacesOnFloor, "J4"),
      },
    };
    acc.push(floorData);
    return acc;
  }, []);
};

const createFloorData = (floor: string, spaces: ISpace[], section: string) => {
  return {
    dr0: summarizeProp(floor, section, spaces, "dRofus", "dR0"),
    dr1: summarizeProp(floor, section, spaces, "dRofus", "dR1"),
    dr2: summarizeProp(floor, section, spaces, "dRofus", "dR2"),
    mmi100: summarizeProp(floor, section, spaces, "mmi", "100"),
    mmi200: summarizeProp(floor, section, spaces, "mmi", "200"),
    mmi300: summarizeProp(floor, section, spaces, "mmi", "300"),
  };
};

const summarizeProp = (
  floor: string,
  section: string,
  spaces: ISpace[],
  dRofusOrMmi: "dRofus" | "mmi",
  value: string,
) => {
  return spaces.reduce((acc, space) => {
    if (space.floor === floor && space.section === section && space[dRofusOrMmi] === value) {
      acc++;
    }
    return acc;
  }, 0);
};
