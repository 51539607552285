import { KontrollAvLuftstroemEnum } from "mc-shared/zod/report/GK/gkReportConstants";
import { IGKKontrollAvLuftStroemVedArbeidsaapningResultater, IGKRapport } from "mc-shared/zod/report/GK/gkReportSchema";
import React from "react";
import { Card, CardBody, CardTitle, FormGroup, Input, Label } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../../types/global.types";
import { McDropdown, McInput } from "../../../../../../components";
import ReportDocxImageContainer from "../../../ReportBoard/ReportDocxImageContainer";

const KontrollAvLuftstroemVedArbeidsaapning: React.FC<{
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
  data: IGKKontrollAvLuftStroemVedArbeidsaapningResultater;
}> = ({ data, onUpdateEditData }) => {
  const TESTMETODER: KontrollAvLuftstroemEnum[] = [
    KontrollAvLuftstroemEnum.STREAMER,
    KontrollAvLuftstroemEnum.ROEYKELLERTAAKE,
  ];

  const valgtMetode = data.inndata.valgtMetode;

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Kontroll av luftstrøm</CardTitle>
        <b>Hvilken metode skal brukes?</b>
        <div className="w-25">
          <McDropdown
            currentValue={data.inndata.valgtMetode}
            onChange={(_, item) =>
              onUpdateEditData(
                "testResultater.kontrollAvLuftstroemVedArbeidsaapning.inndata.valgtMetode",
                item as string,
              )
            }
            defaultTitle="Hvilken testmetode skal brukes?"
            options={TESTMETODER}
          />
        </div>
        {(valgtMetode === KontrollAvLuftstroemEnum.STREAMER ||
          valgtMetode === KontrollAvLuftstroemEnum.ROEYKELLERTAAKE) && (
          <div className="w-50 mt-4">
            <div>
              <b>Beskrivelse av luftstrøm:</b>
              <McInput
                value={data.inndata.resultater.beskrivelseAvLuftstroem}
                onChange={(e) =>
                  onUpdateEditData(
                    "testResultater.kontrollAvLuftstroemVedArbeidsaapning.inndata.resultater.beskrivelseAvLuftstroem",
                    e.target.value,
                  )
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
            <div>
              <FormGroup className="d-flex flex-column" check>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.resultater.godkjent === true}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.kontrollAvLuftstroemVedArbeidsaapning.inndata.resultater.godkjent",
                        true,
                      )
                    }
                  />
                  Godkjent
                </Label>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.resultater.godkjent === false}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.kontrollAvLuftstroemVedArbeidsaapning.inndata.resultater.godkjent",
                        false,
                      )
                    }
                  />
                  Ikke godkjent
                </Label>
              </FormGroup>
            </div>
          </div>
        )}
        <div className="w-50 mt-4">
          <div>
            <ReportDocxImageContainer docxImageTag="kontrollAvLuftstroemVedArbeidsaapning" title="Målepunkter" />
          </div>
          <b>Kommentar:</b>
          <McInput
            value={data.inndata.kommentar}
            onChange={(e) =>
              onUpdateEditData("testResultater.kontrollAvLuftstroemVedArbeidsaapning.inndata.kommentar", e.target.value)
            }
            type="textarea"
            dynamicHeight={true}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default KontrollAvLuftstroemVedArbeidsaapning;
