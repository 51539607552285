import React, { useEffect, useState } from "react";
import { Alert, Button, Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";
import { IArcGisGroup } from "../../../../../../types/global.types";
import { MCLoader, VSpace } from "../../../../components";
import { TitleWithValue } from "../../../../components/";
import { DimensionEnums } from "../../../../frontendConstants";
import { useAppSelector } from "../../../../hooks";
import { doGetSingleArcgisGroup } from "../../gis/GisAPI";
import { Icon } from "../bim360Settings/ConnectedBim360Project";
import ConnectArcgisModal from "./ConnectArcgisModal";
import UpdateDefaultArcgisMap from "./UpdateDefaultArcgisMap";

export const ConnectedArcgisProject = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [arcgisGroup, setArcGisGroup] = useState<IArcGisGroup>(null);
  const [connectionFailed, setConnectionFailed] = useState<boolean>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const project = useAppSelector((state) => state.adminReducer.project);
  const arcgis = project?.arcgis;

  useEffect(() => {
    init();
  }, [arcgis]);

  const init = async () => {
    try {
      if (arcgis.groupId != null) {
        setLoading(true);
        const arcgisGroup = await doGetSingleArcgisGroup(project?._id, arcgis?.groupId);
        setArcGisGroup(arcgisGroup);
        setConnectionFailed(false);
      }
    } catch (err) {
      setConnectionFailed(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h3">Tilkoblet Multiconsult Cloud-GIS gruppe</CardTitle>
        <CardSubtitle tag="h6" className="mb-2 text-muted" />
        <MCLoader loading={loading} />

        {loading === false && (
          <div>
            {arcgis?.groupId != null && (
              <>
                <div className="d-flex">
                  <span className="mr-2">
                    <b>Status ArcGIS Tilkobling:</b>
                  </span>
                  <Icon isValid={connectionFailed === false} />
                </div>
                {connectionFailed && (
                  <Alert color="danger">
                    Kunne ikke koble til gruppe på Multiconsult Cloud GIS konto. Sikker på at du har angitt riktig
                    gruppetid? Kontakt support for mer informasjon.
                  </Alert>
                )}
              </>
            )}
            <Button color="secondary" onClick={() => setModalOpen(true)}>
              Koble Multiconsult Cloud GIS Gruppe
            </Button>
            {modalOpen && <ConnectArcgisModal toggle={setModalOpen} />}
            <hr />
            {arcgisGroup != null && (
              <>
                <ArcGisGroup arcgisGroup={arcgisGroup} />
                <div>
                  <h5 className="mb-0">Default 2d-kart</h5>
                  {arcgisGroup.maps.find((map) => map.type === "Web Map") != null ? (
                    <UpdateDefaultArcgisMap
                      defaultMapId={arcgis.default2dMap}
                      arcgisMaps={arcgisGroup.maps.filter((map) => map.type === "Web Map")}
                      projectId={project._id}
                      dimension={DimensionEnums.TWODIMENSION}
                    />
                  ) : (
                    <p>
                      <i>Denne gruppen inneholder ingen 2d-kart</i>
                    </p>
                  )}
                </div>
                <VSpace />
                <div>
                  <h5 className="mb-0">Default 3d-kart</h5>
                  {arcgisGroup.maps.find((map) => map.type === "Web Scene") != null ? (
                    <UpdateDefaultArcgisMap
                      defaultMapId={arcgis.default3dMap}
                      arcgisMaps={arcgisGroup.maps.filter((map) => map.type === "Web Scene")}
                      projectId={project._id}
                      dimension={DimensionEnums.THEREEDIMENSION}
                    />
                  ) : (
                    <p>
                      <i>Denne gruppen inneholder ingen 3d-kart</i>
                    </p>
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default ConnectedArcgisProject;

const ArcGisGroup: React.FC<{ arcgisGroup: IArcGisGroup }> = ({ arcgisGroup }) => {
  return (
    <div className="d-flex flex-column">
      <TitleWithValue title="Tilkoblet gruppe" value={arcgisGroup.title} />
      <h5 className="mb-0">Maps</h5>
      <ul>{arcgisGroup && arcgisGroup.maps.map((map) => <li key={map.id}>{map.title}</li>)}</ul>
      {arcgisGroup?.maps?.length === 0 && <Alert color="info">Ingen kart ble funnet på denne gruppen</Alert>}
    </div>
  );
};
