import React from "react";
import { Link } from "react-router-dom";
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { IMeeting } from "../../../../../../types/meeting.types";
import { FailedAlert } from "../../../../components";
import { ConfirmDialog, TitleWithValue } from "../../../../components/";
import { removeAppModalInRedux, setShowSuccessMessageThunk } from "../../../../global/globalSlice";
import { useAppDispatch } from "../../../../hooks";
import { formatShortDate } from "../../admin/selectors";
import MeetingBoardsTable from "../../meeting/MeetingBoards/MeetingBoardsTable";
import { useGetSingleMeetingSerieQuery, useUpdateMeetingSerieItemMutation } from "../../meeting/meetingSerieRTK";
import { MEETINGS_SERIES_STATUSES } from "./meetingSeriesConstants";

const MeetingSerieModal: React.FC<{
  toggle: () => void;
  meetingSerieId: string;
  projectId: string;
  startIsoString: string;
}> = ({ toggle, meetingSerieId, projectId, startIsoString }) => {
  const { data: meetingSerie, isError: meetingSerieError } = useGetSingleMeetingSerieQuery({
    projectId: projectId,
    meetingSerieId: meetingSerieId,
  });

  const [updateMeetingSerie, { isError: updateError }] = useUpdateMeetingSerieItemMutation();
  const isError = meetingSerieError || updateError;

  const dispatch = useAppDispatch();

  const changeStatusToSlettetOnMeeting = async () => {
    await updateMeetingSerie({
      projectId: projectId,
      meetingSerieId: meetingSerieId,
      attribute: "status",
      value: MEETINGS_SERIES_STATUSES.find((stat) => stat.value === "Slettet").value,
    });
    dispatch(setShowSuccessMessageThunk("Møteserie fjernet"));
    dispatch(removeAppModalInRedux(meetingSerieId));
  };

  return (
    <>
      {isError === true && <FailedAlert />}
      {meetingSerie != null && (
        <Modal isOpen className="medium-modal" toggle={toggle}>
          <ModalHeader toggle={toggle}>
            {formatShortDate(meetingSerie.instanceDate)}: {meetingSerie.name}
          </ModalHeader>
          <ModalBody>
            <div className="d-flex">
              <div className="d-flex flex-column">
                {meetingSerie.seriesEnd != null && <TitleWithValue title="Møteserie:" value={meetingSerie.name} />}
                <TitleWithValue title="Møtebeskrivelse:" value={meetingSerie.description} />
                {meetingSerie.seriesEnd != null && (
                  <TitleWithValue title="Gjenta:" value={getIntervaltext(meetingSerie)} />
                )}
                <TitleWithValue
                  title="Status:"
                  value={MEETINGS_SERIES_STATUSES.find((stat) => stat.value === meetingSerie.status)?.label}
                />
              </div>
              <div className="flex-fill" />
              <div>
                <ConfirmDialog
                  title="Slette møte?"
                  message="Er du sikker på at du vil slette møte?"
                  confirmCb={() => changeStatusToSlettetOnMeeting()}
                >
                  <Button size="sm" outline>
                    <i className="fa fa-trash fa-fw" />
                  </Button>
                </ConfirmDialog>
              </div>
            </div>
            <hr />
            {meetingSerie.connectedMeetingInstances.length === 0 && (
              <Alert color="success mt-5">
                <i className="fa fa-check fa-fw" />
                Det finnes ingen møter i denne møteserien. <br />
                <Link to={`/project/${projectId}/meetings`}>Gå til møteappen for å opprette et møte.</Link>
              </Alert>
            )}
            {meetingSerie.connectedMeetingInstances.length > 0 && (
              <MeetingBoardsTable autoHeight={true} meetingSerie={meetingSerie} openMeetingInNewTab={true} />
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle}>
              Lukk
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default MeetingSerieModal;

const getIntervaltext = (meetingSerie: IMeeting.MeetingSerie): string => {
  const d = new Date(meetingSerie.seriesStart);
  const weekDay = d.getDay();

  const weekdays = ["Söndag", "mandag", "tirsdag", "onsdag", "torsdag", "fredag"];
  if (Number(meetingSerie.interval) === 1) {
    return `Hver ${weekdays[weekDay]}`;
  } else {
    return `Annenhver ${weekdays[weekDay]}`;
  }
};
