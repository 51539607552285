import { IssueViewEnum } from "mc-shared/enums/enums";
import moment from "moment";
import React from "react";
import { IDateRange, IIssue, IValueLabel } from "../../../../../../../types/global.types";
import { HSpace, McDropdown, McInput } from "../../../../../components";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { setDateRangeInRedux } from "../../issueSlice";

const DateRangePicker: React.FC<{ issues: IIssue[] }> = ({ issues }) => {
  const dispatch = useAppDispatch();

  const activeView = useAppSelector((state) => state.issueReducerNew.activeView);
  const dateRange = useAppSelector((state) => state.issueReducerNew.gant.dateRange);

  React.useEffect(() => {
    if (activeView === IssueViewEnum.GANTT && dateRange.zoomLevel == null) {
      if (issues?.length > 0) {
        changeDateRangeFromPreset("all");
      }
    }
  }, [activeView, issues]);

  const zoomLevelOptions: IValueLabel[] = [
    { label: "Uke", value: "week" },
    { label: "Måned", value: "month" },
    { label: "År", value: "year" },
    { label: "I år", value: "YTD" },
    { label: "Vis alt", value: "all" },
    { label: "Egendefinert", value: "custom" },
  ];

  const changeDateRangeFromPreset = (preset: string) => {
    const dateFormat = "YYYY-MM-DD";
    let _dateRange: IDateRange = {
      startDate: moment().format(dateFormat),
      endDate: moment().format(dateFormat),
      zoomLevel: "",
    };
    switch (preset) {
      case "week":
        const oneWeek = moment(_dateRange.startDate, dateFormat).add(1, "weeks").format(dateFormat);
        _dateRange.endDate = oneWeek;
        _dateRange.zoomLevel = "week";
        break;
      case "month":
        const oneMonth = moment(_dateRange.startDate, dateFormat).add(1, "months").format(dateFormat);
        _dateRange.endDate = oneMonth;
        _dateRange.zoomLevel = "month";
        break;
      case "year":
        const oneYear = moment(_dateRange.startDate, dateFormat).add(1, "years").format(dateFormat);
        _dateRange.endDate = oneYear;
        _dateRange.zoomLevel = "year";
        break;
      case "YTD":
        const YTD = `${moment().year()}-01-01`;
        _dateRange.startDate = YTD;
        _dateRange.zoomLevel = "YTD";
        break;
      case "all":
        const earliestDate = issues
          .map((item) => item.dates.plannedStart)
          .filter((date) => date !== "")
          .reduce((earliest, current) => {
            return moment(earliest).isBefore(moment(current)) ? earliest : current;
          }, "");

        const latestDate = issues.reduce((latest, current) => {
          return latest.dates.due > current.dates.due ? latest : current;
        }, issues[0]).dates.due;
        _dateRange.startDate = earliestDate;
        _dateRange.endDate = latestDate;
        _dateRange.zoomLevel = "all";
        break;
      case "custom":
        _dateRange.startDate = dateRange.startDate;
        _dateRange.endDate = dateRange.endDate;
        _dateRange.zoomLevel = "custom";
        break;
      default:
        break;
    }
    return dispatch(setDateRangeInRedux(_dateRange));
  };

  const changeDateRange = (startOrEndDate: "startDate" | "endDate", htmlDate: string) => {
    const _dateRange: IDateRange = {
      startDate: startOrEndDate === "startDate" ? htmlDate : dateRange.startDate,
      endDate: startOrEndDate === "endDate" ? htmlDate : dateRange.endDate,
      zoomLevel: "custom",
    };
    dispatch(setDateRangeInRedux(_dateRange));
  };

  return (
    <div className="d-flex align-items-center">
      {dateRange.zoomLevel === "custom" && (
        <>
          <div className="ml-1">Fra:</div>
          <McInput
            style={{ marginLeft: ".3em" }}
            type="date"
            onChange={(e) => changeDateRange("startDate", e.target.value)}
            value={dateRange.startDate}
          />
          <HSpace />
          <div>Til:</div>
          <McInput
            style={{ marginLeft: ".3em" }}
            type="date"
            onChange={(e) => changeDateRange("endDate", e.target.value)}
            value={dateRange.endDate}
          />
        </>
      )}
      <HSpace />
      <McDropdown
        color="mc-blue"
        currentValue={zoomLevelOptions.find((item) => item.value === dateRange?.zoomLevel)}
        onChange={(oldVal, newValue) => changeDateRangeFromPreset(newValue.value)}
        displayKey="label"
        defaultTitle="datointervall"
        options={zoomLevelOptions}
      />
    </div>
  );
};

export default DateRangePicker;
