import { ColDef } from "ag-grid-community";
import { AgGridReact, CustomCellRendererProps } from "ag-grid-react";
import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { IMeeting } from "../../../../../../types/meeting.types";
import { AG_GRID_LOCALE_NOR } from "../../../../agGridLocale";
import { Avatar, FailedAlert, McTitle } from "../../../../components";
import { CellEditFormatter } from "../../../../components/columnGlobal";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { getProjectIdFromUrl } from "../../../utils";
import { formatShortDate } from "../../admin/selectors";
import { MEETINGS_SERIES_STATUSES } from "../../projectdashboard/MeetingSeries/meetingSeriesConstants";
import MeetingBoardsTable from "../MeetingBoards/MeetingBoardsTable";
import { useGetMeetingSeriesQuery } from "../meetingSerieRTK";
import { setClickedMeetingSerieIdInRedux } from "../meetingSlice";
import MeetingSerieItemModal from "./MeetingSerieItemModal";

export const MeetingSeriesTable: React.FC = () => {
  const hideDeletedMeetingSeries = useAppSelector((state) => state.meetingReducer.hideDeletedMeetingSeries);
  const clickedMeetingSerieId = useAppSelector((state) => state.meetingReducer.clickedMeetingSerieId);

  const dispatch = useAppDispatch();
  const history = useHistory();

  const projectId = getProjectIdFromUrl(history.location.pathname);

  const { data: meetingSeries, isError } = useGetMeetingSeriesQuery({
    projectId: projectId,
  });

  const columns: ColDef<IMeeting.MeetingSeriePopulated>[] = useMemo(() => {
    const cols: ColDef<IMeeting.MeetingSeriePopulated>[] = [
      {
        headerName: "",
        cellStyle: { padding: "0px" },
        cellRenderer: (props) => {
          const { node, api } = props;
          const handleClick = (e) => {
            e.preventDefault();
            node.setExpanded(!node.expanded);
            api.refreshCells();
            api.setFocusedCell(node.rowIndex, "id", null);
          };
          return (
            <div onClick={(e) => handleClick(e)} className={`expandIcon${node.expanded === true ? " rotateIcon" : ""}`}>
              <i className="fa fa-angle-down"></i>
            </div>
          );
        },
        width: 40,
        maxWidth: 40,
        suppressSizeToFit: true,
        suppressHeaderMenuButton: true,
        suppressColumnsToolPanel: true,
        suppressMovable: true,
        pinned: "left",
        type: "metaColumn",
        resizable: false,
      },
      {
        field: "_id",
        headerName: "_id",
        hide: true,
      },
      {
        field: "name",
        flex: 1,
        headerName: "Tittel",
        filter: true,
        cellRenderer: (params) => (
          <a
            className="font-weight-bold pointer"
            onClick={() => dispatch(setClickedMeetingSerieIdInRedux(params.data._id))}
          >
            {params.value.toUpperCase()}
          </a>
        ),
      },
      {
        field: "description",
        headerName: "Beskrivelse",
        minWidth: 250,
        flex: 1,
        filter: true,
        cellRenderer: (params) => CellEditFormatter(params.value),
      },
      {
        field: "createdBy.name",
        headerName: "Opprettet av",
        filter: true,
        cellRenderer: (params) => {
          const owner = params.value;
          return owner != null ? (
            <div className="d-flex align-items-center">
              <Avatar id={owner._id} />
              <div className="ml-2">{owner}</div>
            </div>
          ) : (
            <div></div>
          );
        },
      },
      {
        field: "createdAt",
        headerName: "Opprettet",
        initialSort: "desc",
        filter: "agDateColumnFilter",
        cellRenderer: (params) => formatShortDate(params.value),
      },
      {
        field: "status",
        headerName: "Status",
        filter: true,
        cellRenderer: (params) => MEETINGS_SERIES_STATUSES.find((status) => status.value === params.value).label,
      },
    ];
    return cols;
  }, []);

  const defaultColDef = {
    suppressHeaderMenuButton: true,
    minWidth: 100,
    resizable: true,
    editable: true,
    autoHeight: true,
    cellStyle: {
      whiteSpace: "normal",
      lineHeight: "1.5",
      wordBreak: "break-word",
      padding: "0.6em 0.8em",
    },
  };

  const currentSeries = useMemo(() => {
    if (meetingSeries == null) {
      return [];
    }
    if (hideDeletedMeetingSeries === true) {
      return meetingSeries.filter((item) => item.status !== "ARCHIVED" && item.status !== "DELETED");
    } else {
      return meetingSeries;
    }
  }, [meetingSeries, hideDeletedMeetingSeries]);

  const DetailCellRenderer = ({ data, node, api }: CustomCellRendererProps) => {
    return (
      <McTitle className="p-2" title="Møteforekomster i serie">
        <MeetingBoardsTable autoHeight={true} singleMeetingId={data._id} />
      </McTitle>
    );
  };
  const detailCellRenderer = useCallback(DetailCellRenderer, []);

  return (
    <div className="d-flex flex-column border h-100 w-100">
      {clickedMeetingSerieId != null && (
        <MeetingSerieItemModal
          toggle={() => dispatch(setClickedMeetingSerieIdInRedux(null))}
          meetingSerie={meetingSeries?.find((item) => item._id === clickedMeetingSerieId)}
        />
      )}
      {isError === true && <FailedAlert />}
      <div className="ag-theme-quartz h-100 w-100">
        <AgGridReact
          domLayout={"autoHeight"}
          rowData={currentSeries}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          suppressClickEdit={true}
          rowSelection="single"
          animateRows={true}
          suppressContextMenu={true}
          onGridReady={(e) => {
            e.api.autoSizeColumns(["name", "status", "createdAt"]);
          }}
          localeText={AG_GRID_LOCALE_NOR}
          masterDetail={true}
          detailRowAutoHeight={true}
          detailCellRenderer={detailCellRenderer}
        />
      </div>
    </div>
  );
};
