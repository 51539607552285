import React from "react";
import { Card, CardBody, CardTitle, Table } from "reactstrap";
import { IIssue } from "../../../../../../../types/global.types";
import { Avatar, OptionBadge } from "../../../../../components";
import { getUserInitials, sortArrayByDate } from "../../../../utils";
import { formatShortDate } from "../../../admin/selectors";
import { trancuateStr } from "../../../risiko/Stats/ScoreMatrix";
import { ISSUE_STATES_OPTIONS } from "../../issueConstants";
import { convertValueAndColorToMultiDisplayColor } from "../../issueUtils";

const IssueMiniTable: React.FC<{ issues: IIssue[] }> = ({ issues }) => {
  return (
    <Card>
      <CardBody>
        <CardTitle>Sist opprettede</CardTitle>
        <Table>
          <thead>
            <tr>
              <th style={{ width: "120px" }}>Id</th>
              <th style={{ width: "320px" }}>Opprettet av</th>
              <th>Tittel</th>
              <th style={{ width: "120px" }}>Dato</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {sortArrayByDate(issues, "createdAt").map((issue: IIssue) => (
              <tr key={issue._id}>
                <td>
                  <span>{issue.id}</span>
                </td>
                <td>
                  <div className="d-flex">
                    <Avatar
                      id={issue.createdBy == null ? null : typeof issue.createdBy !== "string" && issue.createdBy?._id}
                    />
                    {
                      <span className="mt-2">
                        {getUserInitials(typeof issue?.createdBy !== "string" ? issue?.createdBy?.name : null)}
                      </span>
                    }
                  </div>
                </td>
                <td>{trancuateStr(issue.title, 40)}</td>
                <td>{formatShortDate(issue.createdAt)}</td>
                <td className="align-middle">
                  <OptionBadge
                    activeOption={issue.status}
                    options={convertValueAndColorToMultiDisplayColor(ISSUE_STATES_OPTIONS)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default IssueMiniTable;
