import React, { useState } from "react";
import { IBim360FolderContent } from "../../../../../types/global.types";
import { FailedAlert } from "../../../components";
import { doGetBim360FolderContent } from "../admin/restQueries";
import { Bim360FileTable } from "./Bim360FileTable";
import { formatFolderContent, getFilesInFolder, getFormattedBim360Folders } from "./util";

const BIM360FileMetaDataReader: React.FC<{
  activeBim360Folder: IBim360FolderContent;
  projectId: string;
  bim360ProjectId: string;
}> = ({ activeBim360Folder, projectId, bim360ProjectId }) => {
  const [loadingFiles, setLoadingFiles] = React.useState<boolean>(false);
  const [files, setFiles] = React.useState<IBim360FolderContent[]>([]);
  const [showFailedAlert, setShowFailedAlert] = useState<boolean>(false);

  React.useEffect(() => {
    extractFiles(activeBim360Folder);
  }, []);

  const getFiles = async (filesOrFolders: IBim360FolderContent[], folderName = null) => {
    for (let fileOrFolder of filesOrFolders) {
      if (fileOrFolder.type === "folders") {
        const content = await doGetBim360FolderContent(projectId, bim360ProjectId, fileOrFolder.urn || fileOrFolder.id);
        if (content.data[0] != null) {
          await getFiles(content.data, fileOrFolder.attributes.displayName);
        }
      } else {
        const file = formatFolderContent(fileOrFolder, folderName);
        setFiles((files) => [...files, file]);
      }
    }
  };

  const extractFiles = async (folder: IBim360FolderContent) => {
    try {
      const folders = await getFormattedBim360Folders(projectId, bim360ProjectId, folder.urn);
      setLoadingFiles(true);
      const contentFetch = await doGetBim360FolderContent(projectId, bim360ProjectId, activeBim360Folder.urn);
      if (contentFetch.data != null) {
        const _files = getFilesInFolder(contentFetch?.data, activeBim360Folder?.attributes?.displayName);
        setFiles(_files);
      }
      await getFiles(folders);
      setLoadingFiles(false);
    } catch (error) {
      setShowFailedAlert(true);
    }
  };

  return (
    <>
      {showFailedAlert === true && <FailedAlert errorMessage="Kunne ikke hente data fra BIM360. Prøv igjen senere" />}
      <Bim360FileTable folder={activeBim360Folder} files={files} loading={loadingFiles} />
    </>
  );
};

export default BIM360FileMetaDataReader;
