import classnames from "classnames";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Alert, Nav, NavItem, NavLink } from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import ChecklistBoardSelector from "./ChecklistBoardSelector";
import { TypeOfChecklistEnum } from "./ChecklistConstants";
import ChecklistTemplateBoardSelector from "./ChecklistTemplateBoardSelector";
import { doGetChecklistTemplates } from "./checklistAPI";
import { setProjectChecklistTemplatesInRedux } from "./checklistSlice";

const ChecklistBoards: React.FC<{ typeOfChecklist: TypeOfChecklistEnum }> = ({ typeOfChecklist }) => {
  const dispatch = useAppDispatch();
  const project = useAppSelector((state) => state.adminReducer.project);
  const isTemplateProject = useAppSelector((state) => state.adminReducer.project?.maconomy?.projectNo === 100);

  const history = useHistory();

  const changeTypeOfChecklistInUrl = (_typeOfChecklist: TypeOfChecklistEnum) => {
    history.push(`/project/${project._id}/checklist/${_typeOfChecklist}`);
  };

  const init = async () => {
    const allChecklistTemplates = await doGetChecklistTemplates(project._id);
    dispatch(setProjectChecklistTemplatesInRedux(allChecklistTemplates));
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className="d-flex align-items-center flex-column h-100 w-100 gray-100 overflow-auto">
      {isTemplateProject === true && (
        <Alert className="m-2" color="danger">
          Alle sjekklister i dette prosjektet vil være tilgjengelige som maler i andre prosjekter!
        </Alert>
      )}
      <Nav tabs className="w-100 pt-2 px-2">
        <NavItem className="custom">
          <NavLink
            className={classnames({ active: typeOfChecklist === TypeOfChecklistEnum.CHECKLIST, custom: true })}
            onClick={() => {
              changeTypeOfChecklistInUrl(TypeOfChecklistEnum.CHECKLIST);
            }}
          >
            Sjekklister
          </NavLink>
        </NavItem>
        {project.maconomy.projectNo !== 100 && (
          <NavItem className="custom">
            <NavLink
              className={classnames({ active: typeOfChecklist === TypeOfChecklistEnum.TEMPLATE, custom: true })}
              onClick={() => {
                changeTypeOfChecklistInUrl(TypeOfChecklistEnum.TEMPLATE);
              }}
            >
              Prosjektmaler
            </NavLink>
          </NavItem>
        )}
        <div className="flex-fill" />
      </Nav>
      {typeOfChecklist === TypeOfChecklistEnum.CHECKLIST && <ChecklistBoardSelector />}
      {typeOfChecklist === TypeOfChecklistEnum.TEMPLATE && <ChecklistTemplateBoardSelector />}
    </div>
  );
};

export default ChecklistBoards;
