import React, { useMemo, useState } from "react";
import { Avatar, McDropdownBase } from "..";
import { IMinUser } from "../../../../types/global.types";
import { filterPattern } from "../../app/utils";

const McDropdownMultiUser: React.FC<{
  allUsers: IMinUser[];
  selectedUsers: IMinUser[];
  onChange: (userId: IMinUser) => void;
  filterActive: boolean;
}> = ({ allUsers, selectedUsers, onChange, ...props }) => {
  const [filterValue, setFilterValue] = useState<string>("");
  const userExistInSelectedUser = (userId: string) => selectedUsers?.find((user) => user._id === userId) != null;

  const unSelectedUsers = useMemo(
    () => allUsers.filter((user) => userExistInSelectedUser(user?._id) === false),
    [selectedUsers, allUsers],
  );

  return (
    <McDropdownBase
      {...props}
      multiSelect={true}
      onToggle={() => setFilterValue("")}
      onFilter={(val) => setFilterValue(val)}
      style={{ width: "16em", maxHeight: "40vh", overflow: "auto" }}
      color="white"
    >
      <McDropdownBase.Display>
        <AvatarList users={selectedUsers ?? []} />
      </McDropdownBase.Display>
      {selectedUsers?.reduce((acc, user, index) => {
        if (
          filterValue === "" ||
          user.name.trim()?.toLocaleLowerCase().includes(filterValue.trim()?.toLocaleLowerCase())
        ) {
          acc.push(
            <McDropdownBase.Option
              className="d-flex flex-fill align-items-center"
              key={index + user._id}
              onClick={() => onChange && onChange(user)}
            >
              <Avatar id={user._id} height="20px" />
              <div className="truncateHeaderText">{user.name}</div>
              <div className="flex-fill" />
              <i className="fa fa-close" />
            </McDropdownBase.Option>,
          );
        }
        return acc;
      }, [])}
      {selectedUsers?.length > 0 && unSelectedUsers?.length > 0 && (
        <McDropdownBase.Option key="mc__divider" divider={true} />
      )}
      {unSelectedUsers?.reduce((acc, user, index) => {
        if (filterValue === "" || filterPattern(filterValue).test(user?.name)) {
          acc.push(
            <McDropdownBase.Option
              className="d-flex align-items-center"
              key={user?._id + index}
              onClick={() => onChange && onChange(user)}
            >
              <Avatar id={user?._id} height="20px" />
              <div className="truncateHeaderText">{user?.name}</div>
            </McDropdownBase.Option>,
          );
        }
        return acc;
      }, [])}
    </McDropdownBase>
  );
};

export default McDropdownMultiUser;

const AvatarList: React.FC<{ users: IMinUser[] }> = ({ users }) => {
  return (
    <div className="d-flex w-100 truncateHeaderText">
      {users?.map((user) => (
        <div key={user._id} title={user.name}>
          <Avatar id={user._id} height="20px" />
        </div>
      ))}
    </div>
  );
};
