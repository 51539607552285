import React, { useEffect, useState } from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { EditSaveAbort, HSpace } from "./";

const DropdownEditDepricated: React.FC<{
  title: string;
  value: string;
  options: string[];
  saveCb?: (oldValue: string, newValue: string) => void;
  editable?: boolean;
  formatter?: any;
  item?: any;
  disableReset?: boolean;
}> = ({ title, value, options, saveCb, editable = false, formatter, item, disableReset = false }) => {
  const [editMode, setEditMode] = useState(false);
  const [editValue, setEditValue] = useState<string>(value);
  const [viewValue, setViewValue] = useState<string>(value);

  React.useEffect(() => {
    setViewValue(value);
  }, [value]);

  const editCb = (bool) => {
    setEditMode(bool);
  };

  const _save = () => {
    if (editValue !== value) {
      saveCb(value, editValue);
      editCb(false);
    }
  };

  useEffect(() => {
    try {
      if (formatter != null) {
        return setViewValue(formatter(value, item));
      }
      setViewValue(value);
    } catch (error) {
      console.log(title);
      console.log(value);
      console.error(error);
    }
  }, [value]);

  return (
    <div className="mb-2">
      <h5 className="mb-0">{title}</h5>
      <div className="d-flex">
        {editable && (
          <>
            <EditSaveAbort editMode={editMode} saveCb={_save} editModeCb={editCb} disabled={value === editValue} />
            <HSpace />
          </>
        )}
        {editMode === false && <span>{viewValue || "-"}</span>}

        {editMode && (
          <Edit
            options={options}
            selectedOption={editValue}
            setEditedOption={(option) => setEditValue(option)}
            editModeCb={editCb}
            disableReset={disableReset}
          />
        )}
      </div>
    </div>
  );
};

export default DropdownEditDepricated;

const Edit: React.FC<{
  options: string[];
  selectedOption?: string;
  setEditedOption: (option: string) => void;
  editModeCb?: (enabled: boolean) => void;
  disableReset: boolean;
}> = ({ options, selectedOption, setEditedOption, editModeCb, disableReset }) => {
  const handleEscape = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Escape") {
      e.stopPropagation();
      editModeCb(false);
    }
  };
  return (
    <>
      <UncontrolledDropdown onKeyUp={handleEscape}>
        <DropdownToggle style={{ minWidth: "100px", width: "100%" }} autoFocus caret>
          {selectedOption == null ? "Velg" : selectedOption}
        </DropdownToggle>
        <DropdownMenu>
          {options.map((_option) => (
            <DropdownItem active={_option === selectedOption} key={_option} onClick={() => setEditedOption(_option)}>
              {_option}
            </DropdownItem>
          ))}
          <DropdownItem divider />
          {disableReset !== true && (
            <DropdownItem onClick={() => setEditedOption(null)}>
              <i className="fa fa-trash fa-fw" />
              Reset
            </DropdownItem>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};
