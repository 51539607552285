import React from "react";
import { ColDef } from "ag-grid-community";
import { VSpace } from "../../../../components";
import { AgGridReact } from "ag-grid-react";
import { IRIEDeviceCompare } from "./nrhRoomValTypes";

const RIESingleRoomValidationTable: React.FC<{ devices: IRIEDeviceCompare[] }> = ({ devices }) => {
  const columns: ColDef<any>[] = [
    {
      field: "compCode",
      headerName: "CompCode",
    },
    {
      field: "typeName",
      headerName: "Beskrivelse",
      width: 600,
    },
    {
      field: "countInStandard",
      headerName: "Antall i standardroom",
    },
    {
      field: "countInInstance",
      headerName: "Antall i modell (rom)",
      cellRenderer: (params) => {
        return (
          <span>
            {params.data.countInStandard !== params.value && (
              <i className="fa fa-exclamation-triangle fa-fw text-warning" />
            )}
            {params.data.countInStandard === params.value && <i className="fa fa-check fa-fw text-success" />}
            {params.value}
          </span>
        );
      },
    },
  ];

  return (
    <div className="d-flex h-100 w-100">
      <div className="border w-100 h-100 ag-theme-alpine overflow-auto p-2">
        <VSpace />
        <AgGridReact rowData={devices} columnDefs={columns} masterDetail={true} />
      </div>
    </div>
  );
};

export default RIESingleRoomValidationTable;
