import React, { useEffect } from "react";
import { Alert } from "reactstrap";
import { VSpace } from "../../../components";
import { MCApplicationsUrlEnum } from "../../../frontendConstants";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { doLogUsage } from "../admin/restQueries";
import ChecklistAdmin from "./ChecklistAdmin";
import ChecklistBoardAgGrid from "./ChecklistBoardAgGrid";
import ChecklistLogs from "./ChecklistLogs";
import ChecklistTabs from "./ChecklistTabs";
import { getChecklistThunk, setActiveChecklistTabInRedux, setCurrentChecklistInRedux } from "./checklistSlice";
import { ChecklistStatusEnum } from "./constants";
import DoksamChecklistBanner from "./doksam/DoksamChecklistBanner";

const ChecklistApp: React.FC<{ checklistId: string }> = ({ checklistId }) => {
  const dispatch = useAppDispatch();
  const { checklist, checklistCategories, checklistItems, activeTab, hiddenColumns } = useAppSelector(
    (state) => state.checklistReducer,
  );
  const project = useAppSelector((state) => state.adminReducer.project);

  useEffect(() => {
    doLogUsage(project, MCApplicationsUrlEnum.CHECKLIST, "open");
  }, []);

  useEffect(() => {
    dispatch(getChecklistThunk(checklistId));

    return () => {
      dispatch(setActiveChecklistTabInRedux("CHECKLIST"));
      dispatch(setCurrentChecklistInRedux(null));
    };
  }, [checklistId]);

  return (
    <>
      {checklist?.status !== ChecklistStatusEnum.REMOVED
        ? checklist != null && (
            <div className="d-flex flex-column w-100 overflow-hidden">
              {project.maconomy.projectNo === 100 && (
                <Alert className="mt-2" color="danger">
                  Denne sjekklisten vil være tilgjengelig som mal for alle prosjekter!
                </Alert>
              )}
              {checklist.doksam?.docId != null && <DoksamChecklistBanner checklist={checklist} />}
              <ChecklistTabs activeTab={activeTab} />
              <VSpace />
              {activeTab === "CHECKLIST" && (
                <>
                  <ChecklistBoardAgGrid
                    checklist={checklist}
                    checklistCategories={checklistCategories}
                    checklistItems={checklistItems}
                    hiddenColumns={hiddenColumns}
                  />
                </>
              )}
              {activeTab === "LOGS" && <ChecklistLogs />} {activeTab === "SETTINGS" && <ChecklistAdmin />}
            </div>
          )
        : checklist?.isTemplate === true
        ? "Mal slettet!"
        : "Sjekkliste slettet!"}
    </>
  );
};

export default ChecklistApp;
