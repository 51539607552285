import { ColDef } from "ag-grid-community";
import { Progress } from "reactstrap";
import { IChecklist, IChecklistPopulated } from "../../../../../types/checklist.types";
import { FlatHierarchy, IPNSProps, IValueAndColor } from "../../../../../types/global.types";
import { Avatar, OptionBadge, SimpleTag } from "../../../components";
import { UserCellRenderer } from "../../../components/ConnectedIssues/ConnectedIssues";
import { formatShortDate } from "../admin/selectors";
import { convertValueAndColorToMultiDisplayColor } from "../issuesApp/issueUtils";
import { CHECKLIST_STATES_OPTIONS, CHECKLIST_STATES_OPTIONS_COLOR } from "./constants";

export const StatusBadgeRenderer = (params) => {
  return (
    <OptionBadge
      asBadge={false}
      activeOption={(params?.value as IValueAndColor)?.value}
      options={convertValueAndColorToMultiDisplayColor(CHECKLIST_STATES_OPTIONS_COLOR)}
    />
  );
};

export const getChecklistColumns = (isTemplateProject: boolean, pnsItems: FlatHierarchy<IPNSProps>) => {
  const checklistColumnDefs: ColDef<IChecklist>[] = [
    {
      headerName: "ID",
      field: "id",
      resizable: false,
      maxWidth: 90,
      minWidth: 90,
      cellRenderer: (params) => (
        <b className="mb-0 text-primary" style={{ textDecoration: "underline" }}>
          {params.value}
        </b>
      ),
    },
    {
      headerName: "Status",
      field: "status",
      minWidth: 120,
      cellClass: "px-0",
      valueGetter: (params) => {
        const status = CHECKLIST_STATES_OPTIONS_COLOR.find(
          (option) =>
            CHECKLIST_STATES_OPTIONS.find((opt) => opt.value === params.data.status).displayValue === option.value,
        );
        return status;
      },
      valueSetter: (params) => {
        return params.newValue.displayValue;
      },
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: CHECKLIST_STATES_OPTIONS_COLOR,
        cellRenderer: StatusBadgeRenderer,
        valueListMaxHeight: 220,
      },
      width: 150,
      cellRenderer: StatusBadgeRenderer,
    },
    {
      headerName: "Tittel",
      field: "name",
      filter: "agTextColumnFilter",
      flex: 3,
      minWidth: 200,
    },
    {
      headerName: "Utarbeidet av",
      field: "documentManagers.preparedBy",
      hide: isTemplateProject === false,
      filter: "agTextColumnFilter",
      cellRenderer: (params) => <UserCellRenderer height="15px" user={params.value} />,
      filterValueGetter: (params) => (params.data as IChecklistPopulated).documentManagers?.preparedBy?.name,
    },
    {
      headerName: "Kontrollert av",
      field: "documentManagers.checkedBy",
      hide: isTemplateProject === false,
      filter: "agTextColumnFilter",
      cellRenderer: (params) => <UserCellRenderer height="15px" user={params.value} />,
      filterValueGetter: (params) => (params.data as IChecklistPopulated).documentManagers?.checkedBy?.name,
    },
    {
      headerName: "Godkjent av",
      field: "documentManagers.approvedBy",
      hide: isTemplateProject === false,
      filter: "agTextColumnFilter",
      cellRenderer: (params) => <UserCellRenderer height="15px" user={params.value} />,
      filterValueGetter: (params) => (params.data as IChecklistPopulated).documentManagers?.approvedBy?.name,
    },
    {
      headerName: "Fremdrift",
      field: "progress",
      hide: isTemplateProject,
      cellRenderer: (params) => {
        return (
          <div className="w-100">
            {params.value != null && (
              <Progress multi className="thin-progress">
                {params?.value?.map((progress, index) => {
                  return (
                    <Progress bar style={{ backgroundColor: progress.color }} value={progress.percentage} key={index} />
                  );
                })}
              </Progress>
            )}
          </div>
        );
      },
    },
    {
      headerName: "PNS",
      field: "pns",
      filter: "agTextColumnFilter",
      hide: isTemplateProject,
      cellRenderer: (params) => {
        if (params.value != null) {
          return (
            <div className="truncateHeaderText">
              {params.value} - {pnsItems.find((pns) => pns.props.pnsId === params.value)?.props?.title}
            </div>
          );
        } else {
          return "";
        }
      },
    },
    {
      headerName: "Opprettet",
      field: "createdAt",
      filter: "agDateColumnFilter",
      maxWidth: 140,
      initialSort: "desc",
      cellRenderer: (params) => formatShortDate(params.value),
    },
    {
      headerName: "Frist",
      field: "dueDate",
      filter: "agDateColumnFilter",
      maxWidth: 140,
      hide: isTemplateProject,
      cellRenderer: (params) => {
        if (params.value != null) {
          return params.data.status === "ACTIVE" && new Date(params.value) < new Date() ? (
            <div className="text-danger">{formatShortDate(params.value)}</div>
          ) : (
            formatShortDate(params.value)
          );
        } else {
          return "";
        }
      },
    },
    {
      headerName: "Ansvarlig",
      field: "assignedTo",
      hide: isTemplateProject,
      minWidth: 140,
      cellClass: "p-0",
      valueGetter: (params) => {
        return (params as any)?.data?.assignedTo?.name;
      },
      cellRenderer: (params) => {
        const owner = params?.data?.assignedTo;
        return owner != null ? (
          <div className="d-flex align-items-center">
            <Avatar id={owner._id} />
            <div className="ml-2">{owner.name}</div>
          </div>
        ) : (
          <div></div>
        );
      },
    },
    {
      headerName: "Etiketter",
      field: "tags",
      hide: isTemplateProject,
      cellRenderer: (params) => {
        return (
          <div className="d-flex overflow-hidden">
            {(params.value || []).map((tag, index) => (
              <div key={index}>
                <SimpleTag displayValue={tag} />
              </div>
            ))}
          </div>
        );
      },
    },
  ];
  return checklistColumnDefs;
};
