import React, { useState } from "react";
import { Button, Card, CardBody, CardSubtitle, CardTitle, Input } from "reactstrap";
import { IChecklistTemplate, IChecklistTemplateCategory } from "../../../../../types/checklist.types";
import { Checkbox, VSpace } from "../../../components";
import { useAppDispatch } from "../../../hooks";
import { doImportRowsMcCosNRH, doTestEmail, doTestReceiveEmail } from "../admin/restQueries";
import { doUploadChecklistJsonTemplate } from "../checklist/checklistAPI";
import { ActiveBatchJob } from "./ActiveBatchJob";
import { AddProjectToMcApps } from "./AddProjectToMcApps";
import { AddUserToProject } from "./AddUserToProject";
import McBimMasterAdmin from "./McBimMasterAdmin";
import { OfficialMeetingTemplateUpload } from "./OfficialMeetingTemplateUpload";
import { OfficialIssueTemplateUpload } from "./OfficialIssueTemplateUpload";
import { IntegrationsHealthChecker } from "./IntegrationsHealthChecker";
import { setShowSuccessMessageThunk } from "../../../global/globalSlice";
import { ServiceAccounts } from "./ServiceAccounts";

const Appadmin: React.FC = () => {
  const [isLoadingImportMcCos, setIsLoadingImportMcCos] = React.useState(false);
  const [messageImportMcCos, setMessageImportMcCos] = React.useState("");

  const sendEmail = async (template: string) => {
    try {
      if (template === "receive-email") {
        return await doTestReceiveEmail();
      }
      await doTestEmail(template);
    } catch (error) {
      alert(JSON.stringify(error));
    }
  };

  const handleMcCosImport = async () => {
    setIsLoadingImportMcCos(true);
    setMessageImportMcCos("Import påbegynt");
    try {
      const result = await doImportRowsMcCosNRH();
      setMessageImportMcCos(`Import successful. ${result.rowCount} rows imported.`);
    } catch (error) {
      setMessageImportMcCos(`Import failed. ${error.message}`);
    } finally {
      setIsLoadingImportMcCos(false);
    }
  };

  const emailtemplates = ["reset-password", "receive-email", "task-notify", "project-invite", "meeting-agenda"];

  return (
    <div className="h-100 overflow-auto gray-300 p-2">
      <IntegrationsHealthChecker />
      <VSpace />
      <Card>
        <CardBody>
          <CardTitle tag="h3">Officiel mal för mötesapp</CardTitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted" />
          <OfficialMeetingTemplateUpload />
        </CardBody>
      </Card>
      <VSpace />
      <Card>
        <CardBody>
          <CardTitle tag="h3">Officiel mal för sakshantering</CardTitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted" />
          <OfficialIssueTemplateUpload />
        </CardBody>
      </Card>
      <VSpace />
      <Card>
        <CardBody>
          <CardTitle tag="h3">Batchjob feature toggle</CardTitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted" />
          <ActiveBatchJob />
        </CardBody>
      </Card>
      <VSpace />
      <Card>
        <CardBody>
          <CardTitle tag="h3">McBIM</CardTitle>
          <McBimMasterAdmin />
        </CardBody>
      </Card>
      <VSpace />
      <Card>
        <CardBody>
          <CardTitle tag="h3">Legg til mal for sjekkliste</CardTitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted" />
          <UploadChecklistTemplate />
        </CardBody>
      </Card>
      <VSpace />
      <Card>
        <CardBody>
          <CardTitle tag="h3">Mail tester</CardTitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted" />
          {emailtemplates.map((template) => (
            <Button className="m-1" onClick={() => sendEmail(template)}>
              {template}
            </Button>
          ))}
        </CardBody>
      </Card>
      <VSpace />
      <Card>
        <CardBody>
          <CardTitle tag="h3">Add user to project</CardTitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted" />
          <AddUserToProject />
        </CardBody>
      </Card>
      <VSpace />
      <Card>
        <CardBody>
          <CardTitle tag="h3">Add project to MCApps</CardTitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted" />
          <AddProjectToMcApps />
        </CardBody>
      </Card>
      <VSpace />
      <Card>
        <CardBody>
          <CardTitle tag="h3">Add service account</CardTitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted" />
          <ServiceAccounts />
        </CardBody>
      </Card>
    </div>
  );
};

export default Appadmin;

const UploadChecklistTemplate = () => {
  const [autoDownload, setAutoDownload] = useState<boolean>(true);
  const [aboutText, setAboutText] = useState<string>("");
  const [jsonText, setJsonText] = useState<string>("");
  const [csvText, setCsvText] = useState<string>("");
  const [tagsText, setTagsText] = useState<string>("");
  const [titleText, setTitleText] = useState<string>("");
  const [statusMessage, setStatusMessage] = useState<string>("");
  const dispatch = useAppDispatch();

  const saveNew = async () => {
    try {
      let template: IChecklistTemplate = JSON.parse(jsonText);
      console.log(template);
      await doUploadChecklistJsonTemplate(JSON.stringify(template));
      dispatch(setShowSuccessMessageThunk("Sjekklistemalen lagret!"));
    } catch (error) {
      setStatusMessage(error.message || error);
      return;
    }
  };

  const parseCsvText = () => {
    let template: IChecklistTemplate = {
      aboutText: aboutText.trim(),
      categories: [],
      tags: tagsText
        .split(",")
        .filter((x) => x.trim() !== "")
        .map((x) => x.trim()),
      templateType: "MC",
      name: titleText,
    };

    const lines = csvText.trim().split("\n");
    const templateRows = new Map<string, [string, string][]>();

    let currentLine: string = "";

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];

      const nextLineHasId = i + 1 < lines.length ? /^(\d+\.\d+|\d+)\t/.test(lines[i + 1]) : false;

      currentLine += line;

      if (nextLineHasId === false && i + 1 < lines.length) {
        currentLine += "\n";
        continue;
      }

      const rowParts = currentLine.trim().match(/(\d+\.\d+|\d+)\t((?:.|\n)*)/);

      currentLine = "";

      if (rowParts == null || rowParts.length !== 3) {
        console.error(`Unknown row format on row ${i}: "${lines[i]}"`);
        return;
      }

      const id = rowParts[1].trim();
      const text = rowParts[2].trim();
      const groupId = id.split(".")[0];

      const catRows = templateRows.get(groupId);

      if (catRows == null) {
        templateRows.set(groupId, [[id, text]]);
      } else {
        catRows.push([id, text]);
      }
    }

    templateRows.forEach((rows) => {
      for (let i = 0; i < rows.length; i++) {
        const cat: IChecklistTemplateCategory = {
          title: rows[i][1],
          items: [],
        };

        while (i < rows.length - 1) {
          i += 1;

          if (/^\d+$/.test(rows[i][0]) === false) {
            cat.items.push({ title: rows[i][1] });
          } else {
            i -= 1;
            break;
          }
        }

        template.categories.push(cat);
      }
    });

    const _json = JSON.stringify(template, null, 2);

    setJsonText(_json);

    if (autoDownload === true) {
      download(titleText + ".json", _json);
    }
  };

  const download = (file: string, text: string) => {
    var element = document.createElement("a");
    element.setAttribute("href", "data:application/json;charset=utf-8, " + encodeURIComponent(text));
    element.setAttribute("download", file);
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <>
      <div>
        <div>Title</div>
        <Input
          style={{ width: "100%" }}
          type="text"
          value={titleText}
          onChange={(e) => setTitleText(e.target.value)}
          name="text"
        />
        <div>Etiketter (comma separated)</div>
        <Input
          style={{ width: "100%" }}
          type="text"
          value={tagsText}
          onChange={(e) => setTagsText(e.target.value)}
          name="text"
        />
        <div>About text</div>
        <Input
          style={{ height: "150px", width: "100%" }}
          type="textarea"
          value={aboutText}
          onChange={(e) => setAboutText(e.target.value)}
          name="text"
        />
        <div>CSV</div>
        <Input
          style={{ height: "150px", width: "100%" }}
          type="textarea"
          value={csvText}
          onChange={(e) => setCsvText(e.target.value)}
          name="text"
        />
        <div className="d-flex align-items-center mt-2">
          <Button disabled={csvText === ""} color="success" onClick={parseCsvText}>
            Convert to JSON
          </Button>
          <div className="ml-2">
            <Checkbox
              disabled={false}
              isChecked={autoDownload === true}
              setChecked={() => setAutoDownload(!autoDownload)}
            />
          </div>
          <div>Save JSON to disk</div>
        </div>
        <div>JSON</div>
        <Input
          style={{ height: "150px", width: "100%" }}
          type="textarea"
          value={jsonText}
          onChange={(e) => setJsonText(e.target.value)}
          name="text"
        />
        <div>{statusMessage}</div>
      </div>
      <Button className="mt-2" disabled={jsonText === ""} color="success" onClick={() => saveNew()}>
        Store template
      </Button>
    </>
  );
};
