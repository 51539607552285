import React from "react";
import { Button, Card, CardBody } from "reactstrap";
import { HSpace, McInput, McTitle } from "../../../../components";

const SubMcProject: React.FC<{ onFetchFromMaconomy: (subProjectNo: string) => void; failed: boolean }> = ({
  onFetchFromMaconomy,
  failed,
}) => {
  const [subProjectNo, setSubProjectNo] = React.useState<string>("");

  return (
    <Card>
      <CardBody>
        <div className="d-flex">
          <div>
            <McTitle title="Deloppgave">
              <div className="d-flex">
                <McInput
                  placeholder="Angi to tall (02,03..)"
                  type="text"
                  value={subProjectNo}
                  onChange={(e) => setSubProjectNo(e.target.value)}
                />
                <Button
                  disabled={subProjectNo?.length !== 2}
                  onClick={() => onFetchFromMaconomy(subProjectNo)}
                  color="primary"
                >
                  <i className="fa fa-download fa-fw" />
                  Last ned data fra maconomy
                </Button>
              </div>
            </McTitle>
            {failed === true && (
              <div className="text-danger" style={{ width: "300px" }}>
                <i className="fa fa-warning fa-fw" />
                Kunne ikke hente data fra maconomy. Är du säker på att du har angitt riktig deloppgave?
              </div>
            )}
          </div>
          <HSpace />
        </div>
      </CardBody>
    </Card>
  );
};

export default SubMcProject;
