import React, { useState } from "react";
import { Button, Form, FormGroup, Input } from "reactstrap";

const ChangePassword: React.FC<{ onChangePassword: (password: string) => void }> = ({ onChangePassword }) => {
  const [newPassword, setNewPassword] = useState("");
  const [newPassswordAgain, setNewPasswordAgain] = useState("");
  const [passwordDontmatch, setPasswordDontMatch] = useState(false);

  const changePasssword = (e) => {
    e.preventDefault();
    if (newPassword !== newPassswordAgain) {
      return setPasswordDontMatch(true);
    }
    setPasswordDontMatch(false);
    onChangePassword(newPassword);
  };

  return (
    <>
      <Form style={{ width: "100%" }} onSubmit={(e) => changePasssword(e)}>
        <FormGroup>
          <Input
            required
            minLength={8}
            type="password"
            placeholder="Password"
            bsSize="lg"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Input
            required
            minLength={8}
            type="password"
            placeholder="Passord (igjen)"
            bsSize="lg"
            value={newPassswordAgain}
            onChange={(e) => setNewPasswordAgain(e.target.value)}
          />
        </FormGroup>
        <Button type="submit" color="secondary" className="mt-2" block>
          Bekrefte
        </Button>

        {passwordDontmatch === true && <p className="text-danger">Passordene er ikke like. Prøv igjen.</p>}
      </Form>
    </>
  );
};

export default ChangePassword;
