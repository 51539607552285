import React from "react";
import { IRiskoBoard } from "../../../../../../types/global.types";
import { VSpace } from "../../../../components/";
import RisikoBoardProbabilites from "./RisikoBoardProbabilites";
import RisikoBoardSettings from "./RisikoBoardSetting";
import RisikoColumnTemplates from "./RisikoColumnTemplates/RisikoColumnTemplates";
import RisikoRemove from "./RisikoRemove";
import RisikoEvaluationCategoriesDepricated from "./EvaluationCategories/EvaluationCategoriesDepricated";

const RisikoAdmin: React.FC<{ risikoBoard: IRiskoBoard; updateBoards: any }> = ({ risikoBoard, updateBoards }) => {
  return (
    <div id="a" className="w-100 p-3 overflow-auto" style={{ background: "#f4f5fc" }}>
      <RisikoBoardSettings risikoBoard={risikoBoard} updateBoards={updateBoards} />
      <VSpace />
      <RisikoColumnTemplates risikoBoard={risikoBoard} />
      <VSpace />
      <RisikoBoardProbabilites risikoBoard={risikoBoard} updateBoards={updateBoards} />
      <VSpace />
      <RisikoEvaluationCategoriesDepricated risikoBoard={risikoBoard} />
      <VSpace />
      <RisikoRemove risikoBoard={risikoBoard} />
    </div>
  );
};

export default RisikoAdmin;
