import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more";
import { useLocalStorage } from "usehooks-ts";

highchartsMore(Highcharts);
interface GaugeChartProps {
  valueArray: number[];
  maxValue: number;
  valueType: string;
}
const GaugeChart: React.FC<GaugeChartProps> = ({ valueArray, maxValue, valueType }) => {
  const [showOptionalPoints, setShowOptionalPoints] = useLocalStorage("optionalBREEAMDial", false);
  const certificationSeries = [
    {
      showInLegend: true,
      color: "#f59426",
      name: "Status sertifiseringsgrad",
      data: [Math.round((valueArray[0] / maxValue) * 100)],

      tooltip: {
        valueSuffix: "%",
      },
      dataLabels: {
        format: "{y}%",
        y: 30,
        x: 6,
        borderWidth: 0,
        color: "#f59426",
        style: {
          fontSize: "28px",
          textOutline: "none",
        },
      },
      dial: {
        radius: "80%",
        backgroundColor: "#f59426",
        baseWidth: 12,
        baseLength: "20%",
        rearLength: "-20%",
      },
      pivot: {
        backgroundColor: "white",
        radius: 25,
        borderWidth: 6,
        borderColor: "#484747",
      },
    },
    {
      showInLegend: true,
      color: "black",
      name: "Antatt oppnådde poeng",
      data: [Math.round((valueArray[1] / maxValue) * 100)],

      tooltip: {
        valueSuffix: "%",
      },
      dataLabels: {
        format: "{y}%",
        borderWidth: 0,
        y: 65,
        x: 4,
        color: "black",
        style: {
          fontSize: "18px",
        },
      },
      dial: {
        radius: "80%",
        backgroundColor: "black",
        baseWidth: 12,
        baseLength: "20%",
        rearLength: "-20%",
      },
      pivot: {
        backgroundColor: "white",
        radius: 25,
        borderWidth: 6,
        borderColor: "#484747",
      },
    },
    {
      visible: showOptionalPoints,
      showInLegend: showOptionalPoints,
      color: "#7a7981",
      name: "Uavklarte poeng",
      data: [Math.round((valueArray[2] / maxValue) * 100)],

      tooltip: {
        valueSuffix: "%",
      },
      dataLabels: {
        format: "{y}%",
        borderWidth: 0,

        y: 65,
        x: 4,
        color: "black",
        style: {
          fontSize: "18px",
        },
      },
      dial: {
        radius: "80%",
        backgroundColor: "#7a7981",
        baseWidth: 12,
        baseLength: "20%",
        rearLength: "-20%",
      },
      pivot: {
        backgroundColor: "white",
        radius: 25,
        borderWidth: 6,
        borderColor: "#484747",
      },
    },
  ];
  const createPreanalyseDial = (color: string, name: string, data: number) => {
    return {
      showInLegend: true,
      color,
      name,
      data: [Math.round((data / maxValue) * 100)],
      visible: true,
      tooltip: {
        valueSuffix: "%",
      },
      dataLabels: {
        style: {
          display: "none",
        },
      },
      dial: {
        radius: "80%",
        backgroundColor: color,
        baseWidth: 12,
        baseLength: "20%",
        rearLength: "-20%",
      },
      pivot: {
        backgroundColor: color,
        radius: 6,
      },
    };
  };
  const preanalyseSeries = [
    createPreanalyseDial("green", "Antatt oppnås", valueArray[0]),
    createPreanalyseDial("#FFEA00", "Krever noe", valueArray[1]),
    createPreanalyseDial("orange", "Krever mer", valueArray[2]),
    createPreanalyseDial("red", "Krever mye", valueArray[3]),
    {
      pivot: {
        backgroundColor: "white",
        radius: 25,
        borderWidth: 6,
        borderColor: "#484747",
      },
    },
  ];

  const options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "gauge",
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false,
    },
    title: {
      text: `BREEAM ${valueType === "certification" ? "Infrastructure sertifiseringsgrad" : "Preanalyse"}`,
      style: {
        color: "#464646",
      },
    },
    pane: {
      startAngle: -110,
      endAngle: 110,
      background: null,
    },
    yAxis: {
      min: 0,
      max: 100,
      tickPositions: [0, 30, 45, 60, 75, 90, 100],
      tickPosition: "inside",
      tickColor: Highcharts.defaultOptions.chart.backgroundColor || "#FFFFFF",
      tickLength: 20,
      tickWidth: 0,
      minorTickInterval: null,
      labels: {
        distance: 15,
        format: "{value}%",
        style: {
          fontSize: "12px",
          color: "#b3b3b3",
        },
      },
      lineWidth: 0,
      plotBands: [
        {
          from: 0,
          to: 30,
          color: "#e6e6e6", // light gray
          thickness: 80,
          label: { text: "Ingen <br/>sertifisering", align: "right", x: 70, style: { color: "#757575" } },
        },
        {
          from: 30,
          to: 45,
          color: "#ccc", // gray
          thickness: 80,
          label: { text: "Pass", align: "right", x: 30, y: 45, style: { color: "#494949" } },
        },
        {
          from: 45,
          to: 60,
          color: "#b3b3b3", // dark gray
          thickness: 80,
          label: { text: "Good", align: "left", x: -18, y: 55, style: { color: "#494949" } },
        },
        {
          from: 60,
          to: 75,
          color: "#808080", // dim gray
          thickness: 80,
          label: { text: "Very <br/>good", align: "left", x: -35, y: 35, style: { color: "#e3e3e3" } },
        },
        {
          from: 75,
          to: 90,
          color: "#666", // dark slate gray
          thickness: 80,
          label: { text: "Excellent", align: "left", x: -60, y: 30, style: { color: "#e3e3e3" } },
        },
        {
          from: 90,
          to: 100,
          color: "#484747", // black
          thickness: 80,
          label: { text: "Outstanding", align: "left", x: -73, y: -15, style: { color: "#e3e3e3" } },
        },
      ],
    },
    legend: {
      enabled: true,

      y: 150,
      verticalAlign: "middle",
      symbolRadius: 0,
      itemStyle: {
        fontSize: "15px",
        color: "#636363",
      },
    },
    series: valueType === "certification" ? certificationSeries : preanalyseSeries,
  };

  // @ts-ignore
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default GaugeChart;
