import React from "react";
import { IMinUser } from "../../../../types/global.types";
import Avatar from "../Avatar";

const UserWithAvatar: React.FC<{
  user: IMinUser;
  height?: string;
}> = ({ user, height }) => {
  return (
    <div className="d-flex">
      <Avatar id={user._id} height={height} />
      <p>{user.name}</p>
    </div>
  );
};

export default UserWithAvatar;
