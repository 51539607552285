import { IReportTemplate } from "mc-shared/types/report.types";
import React from "react";
import { useHistory } from "react-router-dom";
import { Alert, Col, Row } from "reactstrap";
import RepItem from "../../../../components/RepItem/RepItem";
import { useAppSelector } from "../../../../hooks";
import { isDeveloper } from "../../../utils";
import { doGetReportTemplates } from "../reportTemplateAPI";

const ReportAdmin: React.FC = () => {
  const [reportTemplates, setReportTemplates] = React.useState<IReportTemplate[]>(null);
  const history = useHistory();

  const user = useAppSelector((state) => state.globalReducer.user);

  React.useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const _reportTemplates = await doGetReportTemplates();
    setReportTemplates(
      _reportTemplates.filter(
        (template) =>
          template.admin?._id === user._id || process.env.NODE_ENV === "development" || isDeveloper(user._id),
      ),
    );
  };

  const goToReport = (reportId: number) => {
    history.push(`/reportadmin/${reportId}`);
  };

  return (
    <div className="h-100">
      <Row>
        <Col xs={3} />
        <Col xs={6} className="mt-5">
          {reportTemplates?.length === 0 && <Alert color="warning">Du er ikke administrator for noen rapport</Alert>}
          {reportTemplates?.length > 0 && (
            <>
              <h3>Velg rapport som skal administreres</h3>
              {reportTemplates?.map((report) => (
                <RepItem key={report.name} report={report} onClick={() => goToReport(report.templateId)} />
              ))}
            </>
          )}
        </Col>
        <Col xs={3} />
      </Row>
    </div>
  );
};

export default ReportAdmin;
