import { IGKLekkasjeskanningAvHepaResultater, IGKRapport } from "mc-shared/zod/report/GK/gkReportSchema";
import { Button, Card, CardBody, CardTitle, FormGroup, Input, Label } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../../../types/global.types";
import { HSpace, McInput } from "../../../../../../../components";
import ReportDocxImageContainer from "../../../../ReportBoard/ReportDocxImageContainer";
import { beregnetMaksimalLekkasjeverdi } from "../../gkGlobalUtils";

const LekkasjeskanningAvHepaLAFTak: React.FC<{
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
  data: IGKLekkasjeskanningAvHepaResultater;
}> = ({ data, onUpdateEditData }) => {
  const addNewRow = () => {
    const newFilter = {
      filterNr: "",
      maaleresultaterOppstroems: null,
      godkjent: null,
      maaleresultaterNedstroems: null,
      maksimalLekkasjeverdi: null,
      beskrivelseAvLekkasjeLokasjon: "",
    };

    const updatedMaaleresultater = [...data.inndata.maaleresultater, newFilter];

    onUpdateEditData("testResultater.lekkasjeskanningAvHepaLAFTak.inndata.maaleresultater", updatedMaaleresultater);

    return updatedMaaleresultater;
  };

  const removeLastRow = () => {
    const updatedMaaleresultater = [...data.inndata.maaleresultater];

    if (updatedMaaleresultater.length > 0) {
      updatedMaaleresultater.pop(); // Remove the last element
      onUpdateEditData("testResultater.lekkasjeskanningAvHepaLAFTak.inndata.maaleresultater", updatedMaaleresultater);
    }
  };

  const updateMaaleresultater = (resultat: string | number | boolean, i: number, parameter: string) => {
    const maaleresultater = [...data.inndata.maaleresultater];
    maaleresultater[i][parameter] = resultat;
    maaleresultater[i].maksimalLekkasjeverdi = beregnetMaksimalLekkasjeverdi(
      maaleresultater[i].maaleresultaterOppstroems,
    );
    onUpdateEditData("testResultater.lekkasjeskanningAvHepaLAFTak.inndata.maaleresultater", maaleresultater);
  };

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Lekkasjeskanning av HEPA-filter</CardTitle>
        <div className="d-flex flex-column">
          <b>Hvor mange målinger skal utføres? &#40;-&#41;:</b>
          <div className="d-flex">
            <Button color="success" onClick={addNewRow}>
              Legg til rad
            </Button>
            <HSpace />
            <Button color="danger" onClick={removeLastRow}>
              Fjern siste rad
            </Button>
          </div>
        </div>
        {Number(data.inndata.maaleresultater.length) > 0 && (
          <div className="mt-4 d-flex flex-column">
            <b>Fyll inn måleresultater:</b>
            <table>
              <thead>
                <tr>
                  <th>Filternummer &#40;-&#41;</th>
                  <th>
                    Måleresultat oppstrøms &#40;mg/m<sup>3</sup>&#41;
                  </th>
                  <th className="d-flex justify-content-center">Verdi høyere enn 0,01 &#40;%&#41;?</th>
                  <th>Belastning etter test &#40;%&#41;</th>
                  {data.inndata.maaleresultater.some((resultat) => resultat.godkjent === true) && (
                    <>
                      <th>Resultat nedstrøms &#40;%&#41;</th>
                      <th>Lekkasjelokasjon</th>
                    </>
                  )}
                </tr>
              </thead>
              {data.inndata.maaleresultater.map((filter, i) => (
                <tbody key={i}>
                  <tr>
                    <td>
                      <McInput
                        value={filter.filterNr}
                        type="text"
                        onChange={(e) => updateMaaleresultater(e.target.value, i, "filterNr")}
                        placeholder="Filternummer"
                      />
                    </td>
                    <td>
                      <McInput
                        value={filter.maaleresultaterOppstroems}
                        normalizeNumbers={true}
                        type="text"
                        onBlur={(e) => updateMaaleresultater(Number(e.target.value), i, "maaleresultaterOppstroems")}
                        placeholder="Måleresultat oppstrøms"
                      />
                    </td>
                    <td>
                      <FormGroup
                        className="d-flex flex-row justify-content-around align-items-center"
                        check
                        style={{ backgroundColor: "#ebecec", borderRadius: "6px", height: "2.2rem" }}
                      >
                        <Label check>
                          <Input
                            type="radio"
                            checked={filter.godkjent === true}
                            onChange={() => updateMaaleresultater(true, i, "godkjent")}
                          />
                          Ja
                        </Label>
                        <Label check>
                          <Input
                            type="radio"
                            checked={filter.godkjent === false}
                            onChange={() => updateMaaleresultater(false, i, "godkjent")}
                          />
                          Nei
                        </Label>
                      </FormGroup>
                    </td>
                    <td>
                      <McInput
                        value={filter.konsentrasjonFoerFilterEtterTest}
                        normalizeNumbers={true}
                        type="text"
                        onBlur={(e) =>
                          updateMaaleresultater(Number(e.target.value), i, "konsentrasjonFoerFilterEtterTest")
                        }
                      />
                    </td>
                    {filter.godkjent === true && (
                      <>
                        <td>
                          <McInput
                            value={filter.maaleresultaterNedstroems}
                            normalizeNumbers={true}
                            type="text"
                            onBlur={(e) =>
                              updateMaaleresultater(Number(e.target.value), i, "maaleresultaterNedstroems")
                            }
                            placeholder="Måleresultat nedstrøms"
                          />
                        </td>
                        <td>
                          <McInput
                            value={filter.beskrivelseAvLekkasjeLokasjon}
                            type="text"
                            onChange={(e) => updateMaaleresultater(e.target.value, i, "beskrivelseAvLekkasjeLokasjon")}
                            placeholder="Beskrivelse av lekkasjelokasjon"
                          />
                        </td>
                      </>
                    )}
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
        )}
        {Number(data.inndata.maaleresultater.length) > 0 && (
          <div className="mt-4">
            <ReportDocxImageContainer docxImageTag="lekkasjeskanningAvHepaLAFTak" title="Målepunkter" />
            <div className="w-50">
              <b>Kommentar:</b>
              <McInput
                value={data.inndata.kommentar}
                onChange={(e) =>
                  onUpdateEditData("testResultater.lekkasjeskanningAvHepaLAFTak.inndata.kommentar", e.target.value)
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default LekkasjeskanningAvHepaLAFTak;
