import React from "react";
import { CardBody, CardText, CardTitle, ListGroupItem } from "reactstrap";
import { IReportTemplate } from "mc-shared/types/report.types";

const RepItem: React.FC<{ report: IReportTemplate; onClick: () => void }> = ({ report, onClick }) => {
  return (
    <ListGroupItem action style={{ padding: "0", cursor: "pointer" }} onClick={() => onClick()}>
      <CardBody style={{ padding: "0.4rem" }}>
        <CardTitle>
          <b>{report.name}</b>
        </CardTitle>
        <CardText>{report.description}</CardText>
        <CardText style={{ fontSize: "0.8rem", margin: "0px" }}>Admin: {report.admin?.name}</CardText>
      </CardBody>
    </ListGroupItem>
  );
};

export default RepItem;
