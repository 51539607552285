import React from "react";
import { useHistory } from "react-router-dom";
import { IMinUser } from "../../../../../../../types/global.types";
import { IMeeting } from "../../../../../../../types/meeting.types";
import { FailedAlert, McDropdownMultiUser, McDropdownSingleUser, VSpace } from "../../../../../components";
import GlobalFileUploaderTable from "../../../../../components/FileUploader/GlobalFileUploaderTable";
import McInput from "../../../../../components/McInput/McInput";
import { useAppSelector } from "../../../../../hooks";
import { getProjectIdFromUrl } from "../../../../utils";
import { useUpdateMeetingAgendaMutation } from "../../meetingAgendaRTK";
import { useGetSingleMeetingInstanceQuery } from "../../meetingRTK";
import AgendaConnectedIssues from "./AgendaConnectedIssues";
import { AgendaItemComments } from "./AgendaItemComments";
import AgendaPrepTodoItems from "./AgendaPrepTodoItems";

const AgendaItem: React.FC<{ onAgendaItemChange: IMeeting.onAgendaItemChange; agendaItem: IMeeting.AgendaItem }> = ({
  onAgendaItemChange,
  agendaItem,
}) => {
  const project = useAppSelector((state) => state.adminReducer.project);
  const history = useHistory();
  const projectId = getProjectIdFromUrl(history.location.pathname);
  const meetingId = history.location.pathname.split("/")[4];

  const { data: singleMeeting, isError: singleMeetingError } = useGetSingleMeetingInstanceQuery({
    meetingId,
    projectId,
  });

  const [updateAgendaItem, { isError: updateAgendaItemError }] = useUpdateMeetingAgendaMutation();

  const agendaItemParticipantChange = (user: IMinUser) => {
    const participants = agendaItem?.itemParticipants == null ? [] : [...agendaItem.itemParticipants];
    const participantIndex = participants.findIndex((p) => p._id === user._id);

    if (participantIndex !== -1) {
      participants.splice(participantIndex, 1);
    } else {
      participants.push(user);
    }
    onAgendaItemChange(agendaItem?.itemParticipants, participants, "itemParticipants");
  };

  const isError = singleMeetingError || updateAgendaItemError;
  return (
    <>
      <div className="responsive-flex-container">
        <div className="d-flex flex-column flex-grow-1 p-2">
          {isError === true && <FailedAlert />}
          <VSpace />
          <McInput
            onBlur={(e) => onAgendaItemChange(agendaItem.title, e.target.value, "title")}
            value={agendaItem.title}
            style={{ fontSize: "1.4rem" }}
            placeholder="Skriv inn tittel på agendapunkt"
          />
          <VSpace />
          <h5>Beskrivelse</h5>
          <McInput
            onBlur={(e) => onAgendaItemChange(agendaItem.description, e.target.value, "description")}
            value={agendaItem.description}
            type="textarea"
            placeholder="Skriv inn beskrivelse"
            dynamicHeight={true}
          />
          <VSpace />
          <h5>Formål</h5>
          <McInput
            onBlur={(e) => onAgendaItemChange(agendaItem.purpose, e.target.value, "purpose")}
            value={agendaItem.purpose}
            type="textarea"
            placeholder="Skriv inn formål"
            dynamicHeight={true}
          />
          <VSpace />
          <h5>Ansvarlig deltager</h5>
          <div style={{ width: "300px" }}>
            <McDropdownSingleUser
              onClear={
                agendaItem.assignedTo != null
                  ? () => onAgendaItemChange(agendaItem.assignedTo, null, "assignedTo")
                  : null
              }
              filterActive={project.members?.length > 10}
              options={
                (singleMeeting?.participants as IMeeting.ParticipantsPopulated[]).map(
                  (participant) => participant.user,
                ) ?? []
              }
              selected={agendaItem.assignedTo}
              onChange={(user) => onAgendaItemChange(agendaItem.assignedTo, user, "assignedTo")}
            />
          </div>
          <VSpace />
          <h5>Ansvarlig, annen</h5>
          <div style={{ width: "300px" }}>
            <McInput
              onBlur={(e) => onAgendaItemChange(agendaItem.assignedToFreeText, e.target.value, "assignedToFreeText")}
              value={agendaItem.assignedToFreeText}
              type="text"
            />
          </div>
          <VSpace />
          <h5>Deltakere</h5>
          <div style={{ width: "300px" }}>
            <McDropdownMultiUser
              filterActive={project.members?.length > 10}
              allUsers={
                (singleMeeting?.participants as IMeeting.ParticipantsPopulated[]).map(
                  (participant) => participant.user,
                ) ?? []
              }
              selectedUsers={agendaItem?.itemParticipants}
              onChange={(user) => agendaItemParticipantChange(user)}
            />
          </div>
          <VSpace />
          <AgendaPrepTodoItems agendaItem={agendaItem} />
          <VSpace />
          <h5>Varighet (min)</h5>
          <div style={{ width: "100px" }}>
            <McInput
              onBlur={(e) => onAgendaItemChange(agendaItem.duration, Number(e.target.value), "duration")}
              value={agendaItem.duration}
              type="number"
              min={1}
              max={180}
              dynamicHeight={true}
            />
          </div>
          <VSpace />
          <h5>Vedlegg</h5>
          <GlobalFileUploaderTable path={`${projectId}/meetingagenda/${agendaItem._id}/files`} />
        </div>
        <div className="d-flex flex-column flex-grow-1 p-2">
          <h5>Konklusjon</h5>
          <McInput
            onBlur={(e) => onAgendaItemChange(agendaItem.conclusion, e.target.value, "conclusion")}
            value={agendaItem.conclusion}
            type="textarea"
            dynamicHeight={true}
          />
          <VSpace />
          <AgendaConnectedIssues agendaItem={agendaItem} defaultIssueBoard={singleMeeting.defaultIssueBoard} />
          <VSpace />
          <AgendaItemComments agendaItem={agendaItem} projectId={projectId} singleMeeting={singleMeeting} />
        </div>
      </div>
    </>
  );
};

export default AgendaItem;
