import React from "react";
import { IReport } from "../../../../../../types/report.types";
import GeneralSettingsCard from "../../../../components/GeneralSettingsCard/GeneralSettingsCard";
import { ISSUE_BOARD_STATUSES } from "../../issuesApp/issueConstants";
import { updateReportSettingsThunk } from "../reportSlice";
import { useAppDispatch } from "../../../../hooks";

const SettingsReport: React.FC<{ reportBoard: IReport.IReportBoardPopulated }> = ({ reportBoard }) => {
  const dispatch = useAppDispatch();
  const save = (board, prop, oldVal, newVal) => {
    dispatch(updateReportSettingsThunk(board._id, prop, newVal));
  };

  return (
    <GeneralSettingsCard
      hideAccess
      board={reportBoard as any}
      saveCb={save}
      allUsers={[]}
      statuses={ISSUE_BOARD_STATUSES}
      useIsValid={false}
    />
  );
};

export default SettingsReport;
