import React, { useEffect, useState } from "react";
import { IBim360FolderContent, IFolderAgGrid } from "../../../../types/global.types";
import {
  doGetBim360FolderContent,
  doGetBim360RootFolders,
  doGetParentOfBim360Folder,
} from "../../app/routes/admin/restQueries";
import FolderSelector from "../FolderAndFiles/FolderSelector";

const BIM360FolderSelector: React.FC<{
  projectId: string;
  bim360ProjectId: string;
  onSelectedAgFolderUrn: (folderAg: IFolderAgGrid) => void;
}> = ({ projectId, bim360ProjectId, onSelectedAgFolderUrn }) => {
  const [bim360Folders, setBim360Folders] = useState<IBim360FolderContent[]>(null);
  const [currentBim360Folder, setCurrentBim360Folder] = useState<IBim360FolderContent>();
  const [highlightedFolderBim360, setHighlighetedFolderBim360] = useState<IBim360FolderContent>();

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      const rootFolders = await doGetBim360RootFolders(projectId, bim360ProjectId);
      const projectFilesFolder = rootFolders.find((folder) => folder.name === "Project Files");

      const _bim360Folders = rootFolders.map((folder) => {
        return {
          id: folder.id || folder.urn,
          attributes: {
            displayName: folder.name,
          },
        };
      });
      setBim360Folders(_bim360Folders);
      folderSelection(formatBim360ToAgFolder(projectFilesFolder));
    } catch (error) {
      console.log("failed to connect to bim360");
      console.warn(error);
    }
  };

  const folderSelection = async (folderAg: IFolderAgGrid) => {
    setHighlighetedFolderBim360(formatAgFolderToBim360(folderAg));
    onSelectedAgFolderUrn(folderAg);
  };

  const goToFolder = async (folderAg: IFolderAgGrid) => {
    const isRoot = folderAg.name.toUpperCase().endsWith("ROOT-FOLDER");
    setCurrentBim360Folder(isRoot === false ? formatAgFolderToBim360(folderAg) : null);
    const folderContent = await doGetBim360FolderContent(projectId, bim360ProjectId, folderAg.id);
    const _bim360Folders = folderContent.data.filter((content) => content.type === "folders");
    setBim360Folders(_bim360Folders);
  };

  const goToPreviousFolder = async () => {
    const parentBim360Folder = await doGetParentOfBim360Folder(
      projectId,
      bim360ProjectId,
      currentBim360Folder.id || currentBim360Folder.urn,
    );
    setHighlighetedFolderBim360(null);
    goToFolder({
      name: parentBim360Folder.attributes?.displayName,
      id: parentBim360Folder.id || parentBim360Folder.urn,
    });
  };

  return (
    <FolderSelector
      currentFolder={formatBim360ToAgFolder(currentBim360Folder)}
      folders={bim360Folders == null ? [] : bim360Folders?.map((folder) => formatBim360ToAgFolder(folder)) || []}
      onSelectActiveFolder={folderSelection}
      onGoToNextFolder={goToFolder}
      highlightedFolder={formatBim360ToAgFolder(highlightedFolderBim360)}
      goBack={() => goToPreviousFolder()}
      canGoBack={currentBim360Folder != null}
    />
  );
};

export default BIM360FolderSelector;

const formatBim360ToAgFolder = (bim360Folder: IBim360FolderContent): IFolderAgGrid => {
  if (bim360Folder == null) {
    return null;
  }
  return {
    id: bim360Folder.urn || bim360Folder.id,
    name: bim360Folder.attributes?.name || bim360Folder.attributes?.displayName,
  };
};

const formatAgFolderToBim360 = (agFolder: IFolderAgGrid): IBim360FolderContent => {
  if (agFolder == null) {
    return null;
  }
  return {
    id: agFolder.id,
    attributes: {
      displayName: agFolder.name,
    },
  };
};
