import { apiSlice } from "../../../apiSlice";
import { IMeeting } from "../../../../../types/meeting.types";
import { setShowSuccessMessageThunk } from "../../../global/globalSlice";

export const meetingSerieRTK = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMeetingSeries: builder.query<IMeeting.MeetingSeriePopulated[], { projectId: string }>({
      query: (input) => ({
        url: `/project/${input.projectId}/meetingserie`,
        method: "GET",
      }),
      providesTags: ["MeetingSerie"],
    }),
    getSingleMeetingSerie: builder.query<IMeeting.MeetingSeriePopulated, { projectId: string; meetingSerieId: string }>(
      {
        query: (input) => ({
          url: `/project/${input.projectId}/meetingserie/${input.meetingSerieId}`,
          method: "GET",
        }),
        providesTags: ["MeetingSerie"],
      },
    ),
    createMeetingSerie: builder.mutation<
      IMeeting.MeetingSerie,
      { projectId: string; seriesStart: string; name: string }
    >({
      query: (input) => ({
        url: `/project/${input.projectId}/meetingserie`,
        method: "POST",
        body: {
          seriesStart: input.seriesStart,
          name: input.name,
        },
      }),
      invalidatesTags: ["MeetingSerie"],
    }),
    updateMeetingSerieItem: builder.mutation<
      IMeeting.MeetingSerie,
      { projectId: string; meetingSerieId: string; attribute: string; value: any }
    >({
      query: (input) => ({
        url: `/project/${input.projectId}/meetingserie/${input.meetingSerieId}/attribute`,
        method: "PUT",
        body: {
          attribute: input.attribute,
          value: input.value,
        },
      }),
      async onQueryStarted({ meetingSerieId }, { dispatch, queryFulfilled, getState }) {
        dispatch(setShowSuccessMessageThunk("Møteserie er oppdatert"));
      },
      invalidatesTags: ["MeetingSerie"],
    }),
  }),
});

export const {
  useGetMeetingSeriesQuery,
  useCreateMeetingSerieMutation,
  useUpdateMeetingSerieItemMutation,
  useGetSingleMeetingSerieQuery,
} = meetingSerieRTK;
