import Axios from "axios";
import fileDownload from "js-file-download";
import { IReportTemplate } from "mc-shared/types/report.types";

export const doGetReportTemplates = async (): Promise<IReportTemplate[]> => {
  try {
    const resp = await Axios.get(`/api/reportapptemplate`);
    return Promise.resolve(resp.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const doUploadReportTemplates = async (reportId: number, event: any) => {
  try {
    const data = new FormData();
    data.append("fileToUpload", event.target.files[0]);
    await Axios.request({
      method: "POST",
      url: `/api/reportapptemplate/${reportId}`,
      data,
      headers: {
        "content-type": `multipart/form-data`,
      },
    });
    return Promise.resolve("OK");
  } catch (error) {
    // Keep this!
    return Promise.reject(error);
  }
};

export const doDownloadReportTemplate = async (templateId: string) => {
  try {
    const response = await Axios({
      url: `/api/reportapptemplate/download-docx-template/${templateId}`,
      method: "PUT",
      responseType: "blob",
    });
    fileDownload(response.data, `${templateId}.docx`);
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};
