import * as R from "ramda";
import React from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, Input, UncontrolledDropdown } from "reactstrap";
import { sortArrayByString } from "../app/utils";
import { HSpace } from "./index";

// DropdownSelector has been deprecated and replaced by
// McDropdown (or McDropdownBase for more flexibility)

const DropdownSelectorDeprecated: React.FC<{
  setItem: (any) => void;
  items: any[];
  activeItem?: any;
  displayKey: string;
  color?: string;
  selectText?: string;
  fullWidth?: boolean;
  icon?: string;
  size?: string;
  widthInPx?: number;
  outline?: boolean;
  horizontalLabel?: string;
  reset?: () => void;
  enableSearch?: boolean;
}> = ({
  setItem,
  items,
  activeItem,
  displayKey,
  color,
  selectText,
  fullWidth,
  icon,
  size,
  widthInPx,
  outline,
  horizontalLabel,
  reset,
  enableSearch = false,
}) => {
  const display = (item) => {
    return R.path(displayKey.split("."), item);
  };

  const getWidth = () => {
    if (fullWidth) {
      return "100%";
    }
    if (widthInPx != null) {
      return `${widthInPx}px`;
    }
    return "300px";
  };

  const [searchValue, setSearchValue] = React.useState<string>("");
  return (
    <div className={`${horizontalLabel != null ? "d-flex align-items-center" : null}`}>
      {horizontalLabel != null && <span style={{ fontSize: "14px" }}>{horizontalLabel.toUpperCase()}</span>}
      <HSpace />
      <UncontrolledDropdown>
        <DropdownToggle
          outline={outline}
          size={size}
          caret
          color={color || "secondary"}
          className="d-flex align-items-center justify-content-center"
          style={{ width: getWidth() }}
        >
          <div className="text-truncate">
            <i className={`fa fa-${icon} fa-fw`} />
            {selectText == null ? activeItem && activeItem[displayKey] : selectText}
          </div>
        </DropdownToggle>
        <DropdownMenu style={{ maxHeight: "300px", overflow: "auto" }}>
          {enableSearch !== false && (
            <>
              <DropdownItem toggle={false}>
                <Input
                  className="form-control"
                  autoFocus
                  placeholder="Søk"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </DropdownItem>
              <DropdownItem divider />
            </>
          )}
          {sortArrayByString(items || [], displayKey)
            .filter((item) =>
              enableSearch !== true || searchValue === ""
                ? true
                : item[displayKey].toLowerCase().search(searchValue.toLowerCase()) !== -1,
            )
            .map((item, index) => (
              <DropdownItem
                key={index}
                onClick={() => setItem(item === activeItem ? null : item)}
                active={activeItem && activeItem[displayKey] === item[displayKey]}
              >
                {display(item)}
              </DropdownItem>
            ))}
          {reset != null && (
            <>
              <DropdownItem divider />
              <DropdownItem onClick={() => reset()}>Vis alle</DropdownItem>
            </>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};

export default DropdownSelectorDeprecated;
