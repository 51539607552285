import { TEST_AV_ALARMSYSTEMER_METODER, TestAvAlarmsystemerEnum } from "mc-shared/zod/report/GK/gkReportConstants";
import { IGKRapport, IGKTestAvAlarmsystemerResultater } from "mc-shared/zod/report/GK/gkReportSchema";
import React from "react";
import { Card, CardBody, CardTitle, FormGroup, Input, Label } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../../types/global.types";
import { DropdownFilterGeneric, McInput } from "../../../../../../components";

const TestAvAlarmsystemer: React.FC<{
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
  data: IGKTestAvAlarmsystemerResultater;
}> = ({ data, onUpdateEditData }) => {
  const addOrRemoveTest = (test: TestAvAlarmsystemerEnum) => {
    data.inndata.valgtMetode[test] = !data.inndata.valgtMetode[test];
    onUpdateEditData("testResultater.testAvAlarmsystemer.inndata.valgtMetode", data.inndata.valgtMetode);
  };

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Test av alarmsystemer</CardTitle>
        <b>Velg hvilke tester som skal utføres?</b>
        <div className="w-25 mb-4">
          <DropdownFilterGeneric
            filterOptions={TEST_AV_ALARMSYSTEMER_METODER.map((test) => {
              return {
                ...test,
                isActive: data.inndata.valgtMetode[test.value],
              };
            })}
            onClick={(value) => addOrRemoveTest(value)}
            title="Velg tester"
            color="secondary"
            textColor="text-black"
          />
        </div>
        {data.inndata.valgtMetode[TestAvAlarmsystemerEnum.LUKEAAPNING] === true && (
          <>
            <h4>Lukeåpning</h4>
            <div>
              <FormGroup className="d-flex flex-column" check>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.lukeaapningResultater.godkjent === true}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.testAvAlarmsystemer.inndata.lukeaapningResultater.godkjent",
                        true,
                      )
                    }
                  />
                  Godkjent
                </Label>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.lukeaapningResultater.godkjent === false}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.testAvAlarmsystemer.inndata.lukeaapningResultater.godkjent",
                        false,
                      )
                    }
                  />
                  Ikke godkjent
                </Label>
              </FormGroup>
            </div>
            <div className="w-50 mt-2 mb-4">
              <b>Kommentar:</b>
              <McInput
                value={data.inndata.lukeaapningResultater.kommentar}
                onChange={(e) =>
                  onUpdateEditData(
                    "testResultater.testAvAlarmsystemer.inndata.lukeaapningResultater.kommentar",
                    e.target.value,
                  )
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
          </>
        )}
        {data.inndata.valgtMetode[TestAvAlarmsystemerEnum.LUFTHASTIGHETSINDIKATOR] === true && (
          <>
            <h4>Lufthastighetsindikator</h4>
            <div>
              <FormGroup className="d-flex flex-column" check>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.lufthastighetsIndikatorResultater.godkjent === true}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.testAvAlarmsystemer.inndata.lufthastighetsIndikatorResultater.godkjent",
                        true,
                      )
                    }
                  />
                  Godkjent
                </Label>
                <Label check>
                  <Input
                    type="radio"
                    checked={data.inndata.lufthastighetsIndikatorResultater.godkjent === false}
                    onChange={() =>
                      onUpdateEditData(
                        "testResultater.testAvAlarmsystemer.inndata.lufthastighetsIndikatorResultater.godkjent",
                        false,
                      )
                    }
                  />
                  Ikke godkjent
                </Label>
              </FormGroup>
            </div>
            <div className="w-50 mt-2">
              <b>Kommentar:</b>
              <McInput
                value={data.inndata.lufthastighetsIndikatorResultater.kommentar}
                onChange={(e) =>
                  onUpdateEditData(
                    "testResultater.testAvAlarmsystemer.inndata.lufthastighetsIndikatorResultater.kommentar",
                    e.target.value,
                  )
                }
                type="textarea"
                dynamicHeight={true}
              />
            </div>
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default TestAvAlarmsystemer;
