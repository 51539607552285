import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import { IIssue, IRisiko } from "../../../../../types/global.types";
import { CommentsDepricated, ConfirmDialog, GisItem, MainItem } from "../../../components/";
import { AppModalEnums, ToastMessagesEnums } from "../../../frontendConstants";
import { IBootstrapTableColumnConfig } from "../../../types";
import RisikoFiles from "./RisikoFiles";
import RisikoItemButtonGroups from "./RisikoTabsButtonGroups";
import {
  archiveRisikoAndRemoveInRedux,
  fetchRisikoAndReplaceItInRedux,
  setActiveRisikoViewRedux,
  setSelectedRisikoInRedux,
} from "./actions";

import AddIssueModal from "../issuesApp/IssuesTab/AddIssueModal";
import { RisikoItemTabEnum, RisikoViewEnum } from "./reducer";
import {
  doAddRisikoStateLog,
  doConnectOrDisconnectIssueFromRisiko,
  doDeleteRisikoStatelog,
  doFetchUniqRisikoTags,
  doGetRisikoLogs,
  doUpdateRisiko,
  doUpdateRisikoStatelogText,
} from "./risikoAPI";

import { ConnectedIssues } from "../../../components/ConnectedIssues/ConnectedIssues";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { DepricatedConnectExistingIssueToRisikoModal } from "./DepricatedConnectExistingIssueToRisikoModal";

import HistoryLogsDepricated from "../../../components/HistoryLogsDepricated";
import { addAppModalThunk, setShowSuccessMessageThunk } from "../../../global/globalSlice";

const RisikoItem: React.FC<{
  columns: IBootstrapTableColumnConfig[];
  risiko: IRisiko;
  onSaved: (tab?: RisikoItemTabEnum) => void;
  forceRefetchOfData: () => void;
}> = ({ columns, risiko, onSaved, forceRefetchOfData }) => {
  const tableElementRef = useRef(null);
  const [newIssueModalOpen, setNewIssueModalOpen] = React.useState<boolean>(false);
  const [connectExistingModalOpen, setConnectExistingModalOpen] = React.useState<boolean>(false);
  const [issueModalOpen, setIssueModalOpen] = React.useState<boolean>(null);

  const dispatch = useAppDispatch();

  const project = useAppSelector((state) => state.adminReducer.project);
  const projectIsGisActivated = project?.arcgis?.groupId != null;
  const isPrintMode = useAppSelector((state) => state.globalReducer.isPrintMode);
  const user = useAppSelector((state) => state.globalReducer.user);
  const activeBoard = useAppSelector((state) => state.risikoReducer.activeBoard);
  const categories = useAppSelector((state) => state.risikoReducer.categories);
  const selectedRisiko = useAppSelector((state) => state.risikoReducer.selectedRisiko);
  const activeView = useAppSelector((state) => state.risikoReducer.activeView);

  const openedAppModals = useAppSelector((state) => state.globalReducer.openedAppModals);

  React.useEffect(() => {
    if (openedAppModals.length === 2) {
      setIssueModalOpen(true);
    }
    if (issueModalOpen === true && openedAppModals.length === 1) {
      onSaved(RisikoItemTabEnum.ACTIONS);
      setIssueModalOpen(null);
    }
  }, [openedAppModals]);

  const saveRisiko = async (dataField: string, newVal: string, oldVal: string) => {
    if (dataField.startsWith("evaluation")) {
      const column = columns.find((col) => col.dataField === dataField);
      const evaluation = categories.find((category) => category._id === column.evaluationCategoryId);
      await doUpdateRisiko(project._id, risiko._id, dataField, newVal, oldVal, evaluation._id);
    } else {
      await doUpdateRisiko(project._id, risiko._id, dataField, newVal, oldVal);
    }
    dispatch(fetchRisikoAndReplaceItInRedux(risiko._id));
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.UPDATED));
    onSaved();
  };

  const history = useHistory();
  const deleteRisiko = (riskoId: string) => {
    dispatch(archiveRisikoAndRemoveInRedux(riskoId));
    history.push(`${history.location.pathname}`);
  };

  const disconnectGisPoint = () => {
    saveRisiko("gis", null, "GIS-tilkobling opprettet");
    dispatch(setSelectedRisikoInRedux(null));
  };

  const closeIssueAndGoToGis = () => {
    dispatch(setActiveRisikoViewRedux(RisikoViewEnum.GIS));
    dispatch(setSelectedRisikoInRedux(null));
  };

  const disconnectIssueFromRisiko = async (issueId: string) => {
    await doConnectOrDisconnectIssueFromRisiko(project._id, risiko.risikoBoard, risiko._id, issueId, "DISCONNECT");
    onSaved(RisikoItemTabEnum.ACTIONS);
  };

  const onIssueCreated = (issue: IIssue) => {
    dispatch(
      addAppModalThunk({
        itemId: issue._id,
        boardId: issue.issueBoard as string,
        projectId: project._id,
        app: AppModalEnums.ISSUE,
      }),
    );
    setNewIssueModalOpen(false);
    onSaved(RisikoItemTabEnum.ACTIONS);
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.UPDATED));
  };

  const onConnecetExistingIssue = (issueId: string) => {
    onSaved(RisikoItemTabEnum.ACTIONS);
    setConnectExistingModalOpen(false);
  };

  return (
    <>
      {newIssueModalOpen && (
        <AddIssueModal
          issueBoardId={activeBoard.defaultIssueBoard}
          toggle={() => setNewIssueModalOpen(false)}
          isOpen
          onCreateItem={onIssueCreated}
          connectedRisikoId={risiko._id}
        />
      )}
      {connectExistingModalOpen && (
        <DepricatedConnectExistingIssueToRisikoModal
          risiko={risiko}
          projectId={project._id}
          onConnected={onConnecetExistingIssue}
          toggle={() => setConnectExistingModalOpen(false)}
        />
      )}
      {risiko != null && (
        <>
          <div className="d-flex">
            <RisikoItemButtonGroups
              risiko={risiko}
              projectIsGisActivated={projectIsGisActivated}
              activeButtonName={selectedRisiko?.activeItemTab || RisikoItemTabEnum.INFO}
              setSelectedButtonName={(tab) =>
                dispatch(setSelectedRisikoInRedux({ _id: selectedRisiko._id, activeItemTab: tab }))
              }
            />
            <div className="flex-fill" />
            <ConfirmDialog
              title="Slette risiko?"
              message="Er du sikker på at du vil slette risiko?"
              confirmCb={() => deleteRisiko(risiko._id)}
            >
              <Button size="sm" color="outline-danger">
                <i className="fa fa-trash fa-fw" />
              </Button>
            </ConfirmDialog>
          </div>
          <hr />
          <div ref={tableElementRef} style={{ maxHeight: "70vh", height: "100%", overflow: "auto" }}>
            {selectedRisiko?.activeItemTab === RisikoItemTabEnum.INFO && (
              <MainItem
                item={risiko}
                displayBySections
                columns={columns}
                saveCb={(dataField, newValue, oldValue) => saveRisiko(dataField, newValue, oldValue)}
                pnsItems={project?.pns}
                projectId={project?._id}
                members={project?.members}
                fetchUniqueTags={doFetchUniqRisikoTags}
                boardId={risiko.risikoBoard}
                evaluationCategories={categories}
                risikoBoard={activeBoard}
              />
            )}
            {selectedRisiko?.activeItemTab === RisikoItemTabEnum.LOG && (
              <HistoryLogsDepricated
                projectId={project._id}
                itemId={risiko._id}
                modelColumns={columns}
                getLogs={doGetRisikoLogs}
              />
            )}
            {selectedRisiko?.activeItemTab === RisikoItemTabEnum.FILES && (
              <RisikoFiles
                projectId={project._id}
                risiko={risiko}
                fetchAndSetRisiko={() => onSaved(RisikoItemTabEnum.FILES)}
              />
            )}
            {selectedRisiko?.activeItemTab === RisikoItemTabEnum.GIS && (
              <GisItem
                imgPath={`/api/risiko/${risiko._id}/gis-screen-shot`}
                disconnectGisPoint={disconnectGisPoint}
                goToViewer={closeIssueAndGoToGis}
                showStartGisViewerButon={activeView !== "GIS"}
                hasGis={risiko?.gis?.gisPoint?.x != null}
              />
            )}
            {selectedRisiko?.activeItemTab === RisikoItemTabEnum.ACTIONS && (
              <>
                <ConnectedIssues
                  issues={risiko.connectedIssues as IIssue[]}
                  disconnectIssue={disconnectIssueFromRisiko}
                  connectExistingIssue={() => setConnectExistingModalOpen(true)}
                  createNewIssue={() => setNewIssueModalOpen(true)}
                  isPrintMode={isPrintMode}
                  titleLabel="Tiltak"
                  forceRefetchOfData={() => forceRefetchOfData()}
                  projectId={project._id}
                />
                <hr />
                <CommentsDepricated
                  sortDesc
                  title="Risiko statuslogg"
                  members={project?.members}
                  comments={risiko.statelogs}
                  projectId={project?._id}
                  itemId={risiko._id}
                  fetchAndSetItem={() => dispatch(fetchRisikoAndReplaceItInRedux(risiko._id))}
                  user={user}
                  setShowSuccessMessage={setShowSuccessMessageThunk}
                  serverAddComment={doAddRisikoStateLog}
                  serverUpdateComment={doUpdateRisikoStatelogText}
                  serverDelete={doDeleteRisikoStatelog}
                  tooltip="Ny kommentar"
                />
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default RisikoItem;
