import {
  Action,
  combineSlices,
  configureStore,
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
  ThunkAction,
} from "@reduxjs/toolkit";
import { createBrowserHistory } from "history";
import { apiSlice } from "./apiSlice";
import { adminReducer } from "./app/reducer";
import risikoReducer from "./app/routes/risiko/reducer";
import checklistReducer from "./app/routes/checklist/checklistSlice";
import { batchJobSlice } from "./app/routes/batchjob/batchJobSlice";
import { reportSlice } from "./app/routes/report/reportSlice";
import { mcbimSlice } from "./app/routes/admin/mcbim/mcBimSlice";
import { projectDashboardSlice } from "./app/routes/projectdashboard/projectDashboardSlice";
import { meetingsSlice } from "./app/routes/meeting/meetingSlice";
import { issueSlice } from "./app/routes/issuesApp/issueSlice";
import { setupListeners } from "@reduxjs/toolkit/query";
import { globalSlice } from "./global/globalSlice";

export const history = createBrowserHistory();

const rootReducer = combineSlices({
  adminReducer: adminReducer,
  globalReducer: globalSlice.reducer,
  risikoReducer: risikoReducer,
  checklistReducer: checklistReducer,
  batchJobReducer: batchJobSlice.reducer,
  reportReducer: reportSlice.reducer,
  mcBimReducer: mcbimSlice.reducer,
  projectDashboardReducer: projectDashboardSlice.reducer,
  meetingReducer: meetingsSlice.reducer,
  issueReducerNew: issueSlice.reducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

// Infer the `RootState` type from the root reducer

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    console.warn("We got a rejected action!");
  }

  return next(action);
};

// The store setup is wrapped in `makeStore` to allow reuse
// when setting up tests that need the same store config
export const makeStore = (preloadedState?: Partial<IRootState>) => {
  const store = configureStore({
    reducer: rootReducer,
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware().concat(apiSlice.middleware);
    },
    preloadedState,
  });
  // configure listeners using the provided defaults
  // optional, but required for `refetchOnFocus`/`refetchOnReconnect` behaviors
  setupListeners(store.dispatch);
  return store;
};

export const store = makeStore();
export type AppStore = typeof store;
export type AppDispatch = AppStore["dispatch"];
export type IRootState = ReturnType<typeof rootReducer>;

/*
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, UnknownAsyncThunkAction>;
*/

export type AppThunk<ThunkReturnType = void> = ThunkAction<ThunkReturnType, IRootState, unknown, Action>;
