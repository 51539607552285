import React from "react";
import { IHistoryLogDepricated } from "../../../../../types/global.types";
import HistoryLogsDepricated from "../../../components/HistoryLogsDepricated";
import { useAppSelector } from "../../../hooks";
import { IBootstrapTableColumnConfig } from "../../../types";
import { doGetChecklistRowLogs } from "./checklistAPI";

const ChecklistLogs: React.FC = () => {
  const checklist = useAppSelector((state) => state.checklistReducer.checklist);
  const isMcTemplate = useAppSelector((state) => state.adminReducer.project?.maconomy?.projectNo === 100);
  const project = useAppSelector((state) => state.adminReducer.project);

  const getLogs = async (projectId: string, itemId: string): Promise<IHistoryLogDepricated[]> => {
    return await doGetChecklistRowLogs(projectId, checklist._id, itemId);
  };

  const columns: IBootstrapTableColumnConfig[] = [
    {
      dataField: "assignedTo",
      text: "Ansvarlig",
    },
    {
      dataField: "checkTypes",
      text: "Roller",
    },
    {
      dataField: "tags",
      text: "Etiketter",
    },
    {
      dataField: "title",
      text: "Kontrollspørsmål (krav)",
    },
    {
      dataField: "dueDate",
      text: "Frist",
    },
    {
      dataField: "status",
      text: "Status",
    },
    {
      dataField: "pns",
      text: "PNS",
    },
    {
      dataField: "stampSettings",
      text: "Rapporttekst",
    },
    {
      dataField: "aboutText",
      text: "Beskrivelse",
    },
    {
      dataField: "phase",
      text: "Fase",
    },
    {
      dataField: "discipline",
      text: "Disiplin",
    },
    {
      dataField: "controlledBy",
      text: "Kontrollert av",
    },
    {
      dataField: "documentManagers",
      text: "Dokumentforvaltere",
    },
  ];

  return (
    <div className="w-100 overflow-auto p-3">
      <HistoryLogsDepricated
        projectId={isMcTemplate === true ? project._id : checklist.project}
        itemId={checklist._id}
        modelColumns={columns}
        getLogs={getLogs}
      />
    </div>
  );
};

export default ChecklistLogs;
