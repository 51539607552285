import { IReportTemplate } from "mc-shared/types/report.types";
import { ISJArapport } from "mc-shared/zod/report/Elektro/sjaSchema";
import { IGKRapport } from "mc-shared/zod/report/GK/gkReportSchema";
import { IInternJuridiskUnderkonsulentRapport } from "mc-shared/zod/report/Internjuridisk/internJuridiskSchema";
import { ISamsvarserklaring } from "mc-shared/zod/reportSchemas";
import React from "react";
import { Alert, Button, Col, Navbar, Row } from "reactstrap";
import { ZodError } from "zod";
import { HSpace } from "../../../../components";
import DocxUpload from "../../../../components/DocxReport/NewDocxUpload/DocxUpload";
import { ZodErrorModal } from "../../../../components/ZodErrorModal/ZodErrorModal";
import Brannrapport from "../brannrapport/Brannrapport";
import { IBrandrapportConfig } from "../brannrapport/types";
import RapportSamsvarserklaring from "../elektro/samsvarserklaring/RapportSamsvarserklaring";
import GeoteknikOmraadestabilitetsRapport from "../geoteknik/GeoteknikOmraadestabilitetsRapport";
import Geoteknikrapport from "../geoteknik/Geoteknikrapport";
import GKrapport from "../gkRapport/GKrapport";
import UnderkonsulentRapport from "../internjuridisk/UnderkonsulentRapport";
import { doGenerateReportAppDocx } from "../reportBoardAPI";
import { doDownloadReportTemplate, doGetReportTemplates, doUploadReportTemplates } from "../reportTemplateAPI";
import SJARapport from "../elektro/SJA/SJArapport";

const ReportAdminPage: React.FC<{ reportId: string }> = ({ reportId }) => {
  const [reportErrorsModalOpen, setReportErrorsModalOpen] = React.useState<boolean>(false);
  const [reportTemplate, setReportTemplate] = React.useState<IReportTemplate>(null);
  const [reportParseErrors, setReportParseErrors] = React.useState<ZodError>(null);
  const [editData, setEditData] = React.useState<object>(null);
  const [dataToGenerateDocxWith, setDataToGenerateDocxWith] = React.useState<object>(null);

  React.useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const _reportTemplates = await doGetReportTemplates();
    const _reportTemplate = _reportTemplates.find((tmpl) => tmpl.templateId === Number(reportId));
    if (_reportTemplate != null || process.env.NODE_ENV === "development") {
      setReportTemplate(_reportTemplate);
    } else {
      alert("no access");
    }
  };

  const uploadComplete = () => {
    alert("File successfully uploaded");
  };

  const downloadTemplate = async () => {
    try {
      await doDownloadReportTemplate(reportId);
    } catch (error) {
      alert("Feil under nedlasting. Det er sannsynligvis ingen mal lastet opp ennå.");
    }
  };

  const upload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    await doUploadReportTemplates(reportTemplate.templateId, e);
    if (e.target != null) {
      e.target.value = null;
    }
  };

  const setData = (_editedData: object, _dataToGenerateDocxWith: object) => {
    setEditData(_editedData);
    setDataToGenerateDocxWith(_dataToGenerateDocxWith);
    setReportParseErrors(null);
  };

  const handleError = (errors: ZodError) => {
    setReportParseErrors(errors);
    console.log(errors);
  };

  return (
    <div className="d-flex flex-column overflow-auto h-100">
      {reportTemplate != null && (
        <>
          <Navbar color="dark" light expand="md">
            <h4 className="text-white">Admin: {reportTemplate.name}</h4>
          </Navbar>
          {reportTemplate.enabledForUser === false && (
            <Alert color="warning">
              <i className="fa fa-warning fa-fw" />
              Denne malen er foreløpig ikke tilgjengelig for brukere. Kontakt support når du ønsker å gjøre malen
              tilgjengelig.
            </Alert>
          )}
          {reportParseErrors != null && reportErrorsModalOpen === true && (
            <ZodErrorModal errors={reportParseErrors.issues} onAbort={() => setReportErrorsModalOpen(false)} />
          )}
          <div className="d-flex align-items-center p-3">
            {reportParseErrors != null && (
              <>
                <Button onClick={() => setReportErrorsModalOpen(true)} color="primary">
                  {reportParseErrors.errors.length}
                  <i className="fa fa-warning fa-fw" />
                </Button>
                <HSpace />
              </>
            )}
            <Button color="mc-blue" onClick={downloadTemplate}>
              Laste ned mal
            </Button>
            <DocxUpload uploadCompleedCb={uploadComplete} uploadFunc={upload} />
            <HSpace />
            <a href="https://docxtemplater.com/demo/" target="_blank" rel="noreferrer">
              Se eksempler og dokumentasjon for rapportgenerator
              <i className="fa fa-external-link   fa-fw" />
            </a>
            <div className="flex-fill" />
            <div>
              <Button
                color="mc-blue"
                onClick={() =>
                  doGenerateReportAppDocx(
                    reportTemplate.templateId,
                    dataToGenerateDocxWith,
                    [],
                    0,
                    reportTemplate.templateId.toString(),
                  )
                }
              >
                <i className="fa fa-file-word-o fa-fw" />
                Generer dokument
              </Button>
            </div>
          </div>
          <hr />
          <Row className="d-flex overflow-auto h-100">
            <Col xs="6" className="d-flex h-100 overflow-auto">
              {reportTemplate.templateId === 101 && (
                <RapportSamsvarserklaring
                  samsvarserklaring={reportTemplate.data as ISamsvarserklaring}
                  onUpdateEditData={(data, docxData) => setData(data, docxData)}
                />
              )}
              {reportTemplate.templateId === 102 && (
                <Brannrapport
                  onUpdateEditData={(data, docxData) => setData(data, docxData)}
                  data={reportTemplate.data as IBrandrapportConfig}
                />
              )}
              {reportTemplate.templateId === 103 && (
                <Geoteknikrapport
                  onError={(zodError) => handleError(zodError)}
                  onUpdateEditData={(data, docxData) => setData(data, docxData)}
                  data={reportTemplate.data}
                  projectId={null}
                  userName=""
                />
              )}
              {reportTemplate.templateId === 104 && (
                <GKrapport
                  onError={(zodError) => handleError(zodError)}
                  onUpdateEditData={(data, docxData) => setData(data, docxData)}
                  data={reportTemplate.data as IGKRapport}
                  createdAt={null}
                />
              )}
              {reportTemplate?.templateId === 105 && (
                <>
                  <SJARapport
                    onError={(errors) => handleError(errors)}
                    data={reportTemplate.data as ISJArapport}
                    onUpdateEditData={(data, docxData) => setData(data, docxData)}
                  />
                </>
              )}
              {reportTemplate.templateId === 106 && (
                <GeoteknikOmraadestabilitetsRapport
                  onError={(zodError) => handleError(zodError)}
                  onUpdateEditData={(data, docxData) => setData(data, docxData)}
                  data={reportTemplate.data}
                  projectId={null}
                  userName=""
                />
              )}
              {reportTemplate?.templateId === 701 && (
                <>
                  <UnderkonsulentRapport
                    onError={(errors) => handleError(errors)}
                    data={reportTemplate.data as IInternJuridiskUnderkonsulentRapport}
                    onUpdateEditData={(data, docxData) => setData(data, docxData)}
                  />
                </>
              )}
            </Col>
            <Col xs="6" className="h-100" style={{ overflow: "auto" }}>
              <h4>Indata</h4>
              <div className="bg-dark d-flex flex-column" style={{ height: "500px", overflow: "auto" }}>
                <pre className="text-white">{JSON.stringify(editData, null, 2)}</pre>
              </div>
              <h4>Data som skickas för rapportgenerering:</h4>
              <div className="bg-dark d-flex flex-column" style={{ height: "500px", overflow: "auto" }}>
                <pre className="text-white">{JSON.stringify(dataToGenerateDocxWith, null, 2)}</pre>
              </div>
            </Col>
          </Row>
        </>
      )}
      {reportTemplate == null && <Alert color="warning">Ingen rapport med detta ID</Alert>}
    </div>
  );
};

export default ReportAdminPage;
