import { IWeeklyAppStarts } from "../../../../../mc-shared/zod/statsSchema";
import { IStats } from "../../../../../types/stats.types";
import { apiSlice } from "../../../apiSlice";
import { IStat } from "./WeeklyUserChart";

export const statsRTK = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getWeeklyStats: builder.query<IStat[], IWeeklyAppStarts>({
      query: (input) => {
        return {
          url: `/stats/weekly`,
          method: "POST",
          body: {
            fromDate: input.fromDate,
            toDate: input.toDate,
            projectNo: input.projectNo,
            toggleUniqueUsers: input.toggleUniqueUsers,
          },
        };
      },
      providesTags: ["Stats"],
    }),
    getAllProjectsForStatsPage: builder.query<IStats.AllProjects[], string>({
      query: () => {
        return {
          url: `/stats/projects/all`,
          method: "POST",
        };
      },
      providesTags: ["Stats"],
    }),
  }),
});

export const { useGetWeeklyStatsQuery, useGetAllProjectsForStatsPageQuery } = statsRTK;
