import { IBoorHull } from "mc-shared/zod/report/Geoteknik/geoteknikSchema";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { IBootstrapTableColumnConfig } from "../../../../../types";

export const BORRING_TABLE_COLUMN: IBootstrapTableColumnConfig[] = [
  {
    dataField: "id",
    text: "Borrhull ID",
  },
  {
    dataField: "x",
    text: "X",
  },
  {
    dataField: "y",
    text: "Y",
  },
  {
    dataField: "z",
    text: "Z",
  },
  {
    dataField: "metode",
    text: "Metode",
  },
  {
    dataField: "stopp",
    text: "Stopp",
  },
  {
    dataField: "losm",
    text: "Løsmasse",
  },
  {
    dataField: "fjell",
    text: "Fjell",
  },
  {
    dataField: "totalt",
    text: "Totalt",
  },
];

const BorringTable: React.FC<{ borrhuler: IBoorHull[] }> = ({ borrhuler }) => {
  return (
    <BootstrapTable
      wrapperClasses="smalltable h-100 overflow-auto px-1"
      bootstrap4
      bordered={false}
      hover
      keyField="id"
      data={borrhuler || []}
      columns={BORRING_TABLE_COLUMN}
    />
  );
};

export default BorringTable;
