import React from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from "reactstrap";

const ConnectToGisDropdownSelector: React.FC<{
  label: string;
  onConnectExisting: () => void;
  onCreateNew: () => void;
}> = ({ label, onConnectExisting, onCreateNew }) => {
  return (
    <UncontrolledButtonDropdown>
      <DropdownToggle caret color="success">
        <i className="fa fa-plus fa-fw" />
        Lag lenke
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => onConnectExisting()}>
          <i className="fa fa-link fa-fw" />
          Eksisterende {label}
        </DropdownItem>
        <DropdownItem onClick={() => onCreateNew()}>
          <i className="fa fa-file-text-o fa-fw" />
          Ny {label}
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

export default ConnectToGisDropdownSelector;
