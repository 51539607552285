import React, { useRef, useState } from "react";
import { Badge } from "reactstrap";

const SingleTag: React.FC<{ deleteTag?: any; displayValue: string; hideicon?: boolean }> = ({
  deleteTag,
  displayValue,
  hideicon = false,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const timer = useRef<any>(null);

  const handleMouseOver = () => {
    timer.current = setTimeout(() => {
      setIsHovered(true);
    }, 300);
  };

  const handleMouseExit = () => {
    clearTimeout(timer.current);
    setIsHovered(false);
  };

  return (
    <Badge
      key={displayValue}
      style={{ padding: "5px 6px" }}
      // pill
      className="d-flex greybadge"
      onMouseEnter={() => handleMouseOver()}
      onMouseLeave={() => handleMouseExit()}
    >
      {hideicon === false && deleteTag != null && (
        <div style={{ fontSize: "10px", cursor: "pointer", marginRight: "2px", width: "12px" }}>
          <i
            onClick={() => deleteTag(displayValue)}
            className={`fa fw fa-close text-danger ${isHovered ? "flipOut" : "flipIn"}`}
            style={{ position: "absolute" }}
          />
          <i className={`fa fw fa-tag ${isHovered ? " flipIn" : " flipOut"}`} />
        </div>
      )}
      {displayValue}
      <div className="mr-1" />
    </Badge>
  );
};
export default SingleTag;
