import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { IBoard } from "../../../../../../types/global.types";
import MainFilterBar from "../../../../components/MainFilterBar/MainFilterBar";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { ISSUE_COLUMN_LABELS } from "../issueConstants";
import {
  clearAllIssueFilterThunk,
  setIssueFreeTextSearchInRedux,
  toggleAllMultiIssuesFilterInRedux,
  toggleIssueBoardBoolFilterThunk,
  toggleOptionInAllFiltersInRedux,
} from "../issueSlice";

const IssueDbFilterBar: React.FC<{ issueBoard: IBoard }> = ({ issueBoard }) => {
  const allFilters = useAppSelector((state) => state.issueReducerNew.allFilters);
  const kanbanGroupingView = useAppSelector((state) => state.issueReducerNew.kanbanGroupingView);

  const dispatch = useAppDispatch();

  const getFormattedMultifilterItems = () => {
    return (allFilters ?? []).reduce((acc, allFilterItem) => {
      if (allFilterItem.options != null) {
        const colText: string = ISSUE_COLUMN_LABELS[allFilterItem.dataField];
        if (colText != null) {
          const filterItem = {
            title: colText === "Bucket" ? issueBoard.bucketNameSetting : colText,
            options: allFilterItem.options,
          };
          acc.push(filterItem);
        }
      }
      return acc;
    }, []);
  };

  const multipleFilterItems = useMemo(() => getFormattedMultifilterItems(), [JSON.stringify(allFilters)]);
  const boolFilterItems = useMemo(() => {
    return allFilters
      ?.filter((filterItem) => filterItem.bool != null)
      ?.map((filterItem) => {
        const colText: string = ISSUE_COLUMN_LABELS[filterItem.dataField];
        return {
          label: colText,
          isActive: filterItem.bool,
        };
      });

    /*    if (kanbanGroupingView === IssueKanbanGroupingViewEnum.BUCKET) {
      return filters.filter(
        (filterItem) => filterItem.label !== `Vis ${getIssueActionNameOption(issueBoard?.taskNameSetting, "plural")}`,
      );
    }*/
  }, [allFilters, kanbanGroupingView]);

  const getDataField = (colTitle: string): string => {
    if (colTitle?.startsWith("Mine")) {
      return "assignedOrMember";
    }
    const dataField = Object.entries(ISSUE_COLUMN_LABELS).find(([dataField, title]) => title === colTitle)?.[0] ?? "";
    if (dataField === "") {
      console.warn(`Could not find dataField for title: ${colTitle}`);
    }
    return dataField;
  };

  const history = useHistory();

  const reset = () => {
    history.push(`${history.location.pathname}`);
    dispatch(clearAllIssueFilterThunk());
  };

  return (
    <>
      {multipleFilterItems != null && issueBoard != null && (
        <div className="border pt-2 px-2 d-flex w-100 mc-gray-200">
          <MainFilterBar
            freeTextSearchEnabled
            onUpdateSearch={(str) => dispatch(setIssueFreeTextSearchInRedux(str))}
            multipleFilterItems={multipleFilterItems}
            booleanFilterItems={boolFilterItems}
            updateMultiFilterItemOption={(title, value) =>
              dispatch(
                toggleOptionInAllFiltersInRedux({
                  dataField: getDataField(title),
                  optionValue: value,
                }),
              )
            }
            updateBooleanFilterItem={(title) => dispatch(toggleIssueBoardBoolFilterThunk(getDataField(title)))}
            clearAllFilters={reset}
            resetMultiFilter={(title) =>
              dispatch(
                toggleAllMultiIssuesFilterInRedux({
                  dataField: getDataField(title),
                  isActive: false,
                }),
              )
            }
            selectAllMultiFilter={(title) =>
              dispatch(
                toggleAllMultiIssuesFilterInRedux({
                  dataField: getDataField(title),
                  isActive: true,
                }),
              )
            }
          />
        </div>
      )}
    </>
  );
};

export default IssueDbFilterBar;

/*
const getColTextFromDataField = (dataField: ISSUE_DATAFILEDS_ENUM): string => {
  return ISSUE_COLUMN_LABELS[dataField];
}*/
