import { z } from "zod";

export const geoteknikKoordinaterSchema = z.object({
  sone: z.union([z.number(), z.string()]),
  nord: z.union([z.number(), z.string()]),
  ost: z.union([z.number(), z.string()]),
});

export type IGeoteknikKoordinater = z.infer<typeof geoteknikKoordinaterSchema>;

const ZBorrhul = z.object({
  id: z.string(),
  x: z.string(),
  y: z.string(),
  z: z.string(),
  metode: z.string(),
  stopp: z.string(),
  losm: z.string(),
  fjell: z.string(),
  totalt: z.string(),
});

export const ZGeoTeknikkRapportSchema = z.object({
  generellInfo: z.object({
    deloppgavenummer: z.string(),
    datoGenerert: z.date().optional(),
    generertAv: z.string().optional(),
    oppdragsNavn: z.string().min(1, "Oppdragsnavn må fylles ut"),
    oppdragsNummer: z.string().min(4, "Oppdragsnummer må fylles ut. F.eks. 12345-01"),
    oppdragsGiver: z.object({
      navn: z.string(),
      kontaktPerson: z.string(),
    }),
    oppdragsLeder: z.string(),
  }),
  innstillinger: z.object({
    veiledningsTekst: z.boolean(),
    eksempelTekst: z.boolean(),
  }),
  prosjektInformasjon: z.object({
    hoydeSystem: z.string(),
    koordinatSystem: z.string(),
    koordinater: geoteknikKoordinaterSchema,
    sirkelDiameter: z.number().optional().default(300),
    zoomLevel: z.number().optional(),
    eiendomsInformasjon: z.object({
      gnr: z.number().nullable(),
      bnr: z.number().nullable(),
      snr: z.number().nullable(),
      by: z.string().nullable(),
    }),
    tilgjengelighet: z.string(),
    saksbehandler: z.string(),
    ansvarligEnhet: z.string(),
  }),
  laboratorieresultater: z.string().optional(),
  borrhuler: z.array(ZBorrhul).optional().nullable(),
});

export type IBoorHull = z.infer<typeof ZBorrhul>;
export type IGeoTeknikkRapport = z.infer<typeof ZGeoTeknikkRapportSchema>;
