import _ from "lodash";
import * as R from "ramda";
import React, { useEffect, useRef, useState } from "react";
import { Button, Input, Modal, ModalBody, ModalHeader, Table, UncontrolledTooltip } from "reactstrap";
import { ILink } from "../../../../../../types/global.types";
import { HSpace, McDropdown, VSpace } from "../../../../components";
import WidgetWrapper from "../../../../components/WidgetWrapper/WidgetWrapper";
import { setShowSuccessMessageThunk } from "../../../../global/globalSlice";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { sortArrayByString } from "../../../utils";
import { useSaveLinksMutation } from "../ProjectDashboardRTK";

const Links: React.FC = () => {
  const project = useAppSelector((state) => state.adminReducer.project);
  const activeLayout = useAppSelector((state) => state.projectDashboardReducer.activeLayout);
  const dispatch = useAppDispatch();

  const [editMode, setEditMode] = useState<boolean>(false);
  const [links, setLinks] = useState<ILink[]>([]);
  const [saveLinks] = useSaveLinksMutation();

  useEffect(() => {
    if (activeLayout?.widgetData?.links != null) {
      setLinks(R.clone(activeLayout.widgetData.links));
    }
  }, [activeLayout]);

  const save = async (links: ILink[]) => {
    await saveLinks({ projectId: project._id, links: links, layoutId: activeLayout._id });
    setLinks(links);
    dispatch(setShowSuccessMessageThunk("Lenker lagret"));
    setEditMode(false);
  };
  const close = () => {
    setEditMode(false);
  };

  const goToLink = (url) => {
    if (url.startsWith("www")) {
      window.open(`https://${url}`);
    } else {
      window.open(url);
    }
  };
  const LinkContainer = ({ link }) => {
    const [hover, setHover] = useState(false);
    const linkRef = useRef(null);
    if (link.icon === "") {
      link.icon = "#a1a2a2";
    }
    return (
      <>
        <div
          onClick={() => goToLink(link.url)}
          ref={linkRef}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          className="newlink d-flex justify-content-between py-1"
          style={{
            boxShadow: `inset 16px 0px 0px -12px ${link.icon}`,
            background: `${link.icon}${hover ? "AA" : "50"}`,
            transition: ".2s ease-in-out background",
          }}
          key={link._id}
        >
          <div className="mc-gray-text text-truncate" style={{ paddingLeft: "4px", userSelect: "none" }}>
            {link.name}
          </div>
          {/* <i className="fa fa-external-link-square fa-sm fa-fw" /> */}
        </div>
        {link.description.length > 0 && (
          <UncontrolledTooltip delay={{ show: 150, hide: 0 }} target={linkRef} placement="right">
            {link.description}
          </UncontrolledTooltip>
        )}
      </>
    );
  };
  return (
    <WidgetWrapper title="Lenker" widgetKey="links" onShowSettingsClick={() => setEditMode(!editMode)}>
      <div className="testflex">
        {sortArrayByString(links, "icon").map((link, index) => {
          return <LinkContainer link={link} key={index} />;
        })}
      </div>
      <EditLinksModal links={links} save={save} toggle={() => setEditMode(false)} isOpen={editMode} />
    </WidgetWrapper>
  );
};

export default Links;

const EditLinksModal: React.FC<{
  save: (links: ILink[]) => void;
  links: ILink[];
  toggle: () => void;
  isOpen: boolean;
}> = ({ save, links, toggle, isOpen }) => {
  const [editedLinks, setEditedLinks] = useState<ILink[]>(null);

  useEffect(() => {
    setEditedLinks(R.clone(links));
  }, [links]);

  const edit = (key: string, index: number, value: any) => {
    const _links = [...editedLinks];
    _links[index][key] = value;
    setEditedLinks(_links);
  };

  const add = () => {
    const _links = [...editedLinks];
    _links.push({
      icon: "#a1a2a2",
      name: "",
      description: "",
      url: "",
    });
    setEditedLinks(_links);
  };

  const removeRow = (index) => {
    const _links = [...editedLinks];
    _links.splice(index, 1);
    setEditedLinks(_links);
  };

  const updateIndex = (index, moveBy) => {
    const _links = [...editedLinks];
    const newIndex = index + moveBy;
    if (newIndex < 0 || newIndex >= _links.length) {
      return _links;
    }
    const item = _links.splice(index, 1)[0];
    _links.splice(newIndex, 0, item);
    setEditedLinks(_links);
  };

  const isDisabled = () => {
    const isValid = editedLinks.every((link) => {
      return (link.url.startsWith("http") || link.url.startsWith("www")) && link.name !== "";
    });
    return isValid === false;
  };
  const handleCloseModal = () => {
    const areEqual = _.isEqual(links, editedLinks);
    toggle();

    if (areEqual === false && isDisabled() === false) {
      save(editedLinks);
    }
  };

  const linkColorOptions = [
    { color: "#e15353", label: "rød" },
    { color: "#ff9800", label: "oransje" },
    { color: "#94b0f3", label: "blå" },
    { color: "#80b180", label: "grønn" },
    { color: "#ffe499", label: "gul" },
    { color: "#a1a2a2", label: "grå" },
  ];

  return (
    <>
      {editedLinks != null && (
        <Modal style={{ maxWidth: "60vw" }} isOpen={isOpen} toggle={handleCloseModal}>
          <ModalHeader toggle={handleCloseModal}>Håndtere Lenker</ModalHeader>
          <ModalBody>
            <div className="d-flex flex-column">
              <Table>
                <thead>
                  <tr>
                    <th></th>
                    <th className="w-25">Navn</th>
                    <th className="w-25">Beskrivelse</th>
                    <th className="w-25">URL</th>
                    <th className="w-25">Color</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {editedLinks.map((link, index) => (
                    <tr key={index}>
                      <td className="p-0">
                        {index > 0 && (
                          <Button size="sm" outline className="ml-1" onClick={() => updateIndex(index, -1)}>
                            <i className="fa fa-caret-up fa-fw" />
                          </Button>
                        )}
                        {index < editedLinks.length - 1 && (
                          <Button size="sm" outline className="ml-1" onClick={() => updateIndex(index, 1)}>
                            <i className="fa fa-caret-down fa-fw" />
                          </Button>
                        )}
                      </td>
                      <td>
                        <Input onChange={(e) => edit("name", index, e.target.value)} value={link.name} />
                      </td>
                      <td>
                        <Input
                          type="textarea"
                          onChange={(e) => edit("description", index, e.target.value)}
                          value={link.description}
                        />
                      </td>
                      <td>
                        <Input onChange={(e) => edit("url", index, e.target.value)} value={link.url} />
                      </td>
                      <td>
                        <McDropdown
                          type="colordot"
                          currentValue={linkColorOptions.find((item) => item.color === link.icon)}
                          onChange={(oldValue, newValue) => edit("icon", index, newValue.color)}
                          displayKey="label"
                          options={linkColorOptions}
                        />
                      </td>
                      <td>
                        <Button color="danger" size="sm" outline className="ml-1" onClick={() => removeRow(index)}>
                          <i className="fa fa-trash fa-fw" />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className="d-flex align-items-baseline">
                <Button color="success" onClick={() => add()}>
                  <i className="fa fa-plus" />
                </Button>
                <div className="flex-fill" />
              </div>
              <VSpace />
              <div className="d-flex">
                <div className="flex-fill" />
                <Button disabled={isDisabled()} color="success" onClick={() => save(editedLinks)}>
                  <i className="fa fa-floppy-o fa-fw" />
                </Button>
                <HSpace />
                <Button color="secondary" onClick={() => toggle()}>
                  Lukk
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};
