import React, { useEffect } from "react";
import { Button, ButtonGroup } from "reactstrap";
import { IError, ILabelAndCount } from "../../../../../../types/global.types";
import { VSpace } from "../../../../components";
import { useDeleteAllErrorsMutation } from "../../admin/errorRTK";
import { doDeleteError, doGetErrors } from "../../admin/restQueries";
import FlatErrorTable from "./FlatErrorTable";
import GroupErrorTable from "./GroupErrorTable";
import { groupAndCountErrors } from "./errorUtils";

const ErrorPage: React.FC = () => {
  const [errors, setErrors] = React.useState<IError[]>(null);
  const [groupedByRouterErrorMessage, setGroupedByRouterErrorMessage] = React.useState<ILabelAndCount[]>(null);

  const [isGroupMode, setIsGroupedMode] = React.useState<boolean>(false);

  useEffect(() => {
    fetchAndSetErrors();
  }, []);

  const fetchAndSetErrors = async () => {
    const _errors = await doGetErrors();
    const groupedByRouterErrorMessage = groupAndCountErrors(_errors, "routeMessage");
    setGroupedByRouterErrorMessage(groupedByRouterErrorMessage);
    setErrors(_errors);
  };

  const deleteError = async (_id: string) => {
    await doDeleteError(_id);
    await fetchAndSetErrors();
  };

  const [deleteAllErrors, { isError: deleteAllErrorsError }] = useDeleteAllErrorsMutation();

  return (
    <div className="p-4 h-100">
      <ButtonGroup>
        <Button active={isGroupMode === false} onClick={() => setIsGroupedMode(false)}>
          Alle errors
        </Button>
        <Button active={isGroupMode} onClick={() => setIsGroupedMode(true)}>
          Grupperat
        </Button>
        <Button color="danger" active={isGroupMode} onClick={() => deleteAllErrors()}>
          Slett alle errors
        </Button>
      </ButtonGroup>
      <VSpace />
      {errors != null && isGroupMode === false && <FlatErrorTable errors={errors} onDeleteError={deleteError} />}
      {errors != null && isGroupMode && (
        <GroupErrorTable
          labelAndCounts={groupedByRouterErrorMessage}
          groupProp="routeMessage"
          deleteError={deleteError}
        />
      )}
    </div>
  );
};

export default ErrorPage;
