import { useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { SimpleTag } from "../";

const SimpleTags: React.FC<{
  tagOptions: string[];
  activeTags: string[];
  onChange: (value: string[]) => void;
  editable?: boolean;
  allowNew?: boolean;
  tagSize?: "sm" | "lg";
}> = ({ tagOptions, activeTags, onChange, editable = true, allowNew = true, tagSize = "lg" }) => {
  const [showNew, setShowNew] = useState<boolean>(false);

  const handleEsc = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Escape") {
      e.stopPropagation();
      setShowNew(false);
    }
  };

  return (
    <div className="d-flex align-items-center flex-wrap" style={{ minHeight: "2.4rem" }}>
      {(activeTags || []).map((tag, index) => (
        <div key={index}>
          <SimpleTag
            tagSize={tagSize}
            editable={editable}
            displayValue={tag}
            onDelete={(value) => onChange(activeTags.filter((e) => e !== value))}
          />
        </div>
      ))}
      {showNew === false && editable === true && (
        <i className="fa fa-plus fa-fw gray-700-text pointer" onClick={() => setShowNew(true)} />
      )}
      {tagOptions != null && showNew === true && (
        <div
          onKeyDown={handleEsc}
          onKeyUp={handleEsc}
          onBlur={(v) => {
            setShowNew(false);
          }}
        >
          <Typeahead
            inputProps={{ className: "mcinput" }}
            clearButton
            id="selections-example"
            options={tagOptions || []}
            placeholder="Skriv inn en tag ..."
            onChange={(v: { customOption: boolean; id: string; label: string }[] | string[]) => {
              if (typeof v[0] === "string") {
                onChange([...activeTags, v[0]]);
              } else {
                onChange([...activeTags, v[0].label]);
              }

              setShowNew(false);
            }}
            allowNew={allowNew}
            autoFocus
          />
        </div>
      )}
    </div>
  );
};

export default SimpleTags;
