import { IGeoTeknikkRapport, ZGeoTeknikkRapportSchema } from "mc-shared/zod/report/Geoteknik/geoteknikSchema";
import * as R from "ramda";
import React, { useEffect } from "react";
import { Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";
import { useDebounce } from "usehooks-ts";
import { SafeParseReturnType, ZodError } from "zod";
import { IMulticonsultProject, Paths, PathValue } from "../../../../../../types/global.types";
import { IMaconomyCoordinates } from "../../../../../../types/report.types";
import { DeleteButton, VSpace } from "../../../../components";
import { geoReportValidation } from "../../../../components/ExcelImportValidator/ExcelImportValidator.utils";
import ExcelImportValidatorWithSpecTable from "../../../../components/ExcelImportValidator/ExcelImportValidatorWithSpecTable";
import InputUpload from "../../../../components/InputUpload/InputUpload";
import { useAppDispatch } from "../../../../hooks";
import { doGetMProject } from "../../../projectApi";
import BorringTable, { BORRING_TABLE_COLUMN } from "./BorrhulTable/BorrhulTable";
import GeoteknikGenrellInfo, { GEOTEKNIK_DEPARTMENTS } from "./GeoteknikGenerellInfo";
import GeoteknikMaps, { convertMaconomyCoordinatesToGeoteknikCoordinates } from "./GeoteknikMaps";
import GeoteknikSettings from "./GeoteknikSettings";
import SubMcProject from "./SubMcProject";
import { formatBorrhulDataFromExcel } from "./geoteknikUtils";
import { setShowSuccessMessageThunk } from "../../../../global/globalSlice";

const GeoteknikRapport: React.FC<{
  data: IGeoTeknikkRapport;
  onError: (errors: ZodError<IGeoTeknikkRapport>) => void;
  onUpdateEditData: (data: IGeoTeknikkRapport, docxData: IGeoTeknikkRapport) => void;
  projectId: string;
  userName: string;
}> = ({ data, onUpdateEditData, projectId, userName, onError }) => {
  const [editData, setEditData] = React.useState(R.clone(data));
  const debouncedValue = useDebounce<IGeoTeknikkRapport>(editData, 1000);

  useEffect(() => {
    onUpdateEditData(data, data);
  }, []);

  useEffect(() => {
    const parsedResult: SafeParseReturnType<IGeoTeknikkRapport, IGeoTeknikkRapport> =
      ZGeoTeknikkRapportSchema.safeParse(editData);

    if (parsedResult.success === false) {
      onError(parsedResult.error);
    } else {
      onUpdateEditData(editData, editData);
    }
  }, [debouncedValue]);

  const [failedToFetchFromMaconomy, setFailedToFetchFromMaconomy] = React.useState<boolean>(false);

  React.useEffect(() => {
    setEditData(null);
    setTimeout(() => {
      setEditData(data);
    }, 50);
  }, [data]);

  const dispatch = useAppDispatch();

  const fetchFromMaconomyAndSetData = async (subProjectNo: string) => {
    try {
      setFailedToFetchFromMaconomy(false);
      const multiconsultProject: IMulticonsultProject = await doGetMProject(projectId, subProjectNo);
      if (multiconsultProject == null) {
        setFailedToFetchFromMaconomy(true);
        return;
      }
      dispatch(setShowSuccessMessageThunk("Hentet data fra maconomy"));

      const maconomyCoordinates: IMaconomyCoordinates = {
        UTMN:
          typeof multiconsultProject.UTMN === "string"
            ? Number(multiconsultProject.UTMN.replace(",", "."))
            : multiconsultProject.UTMN,
        GNR: Number(multiconsultProject.GNR),
        BNR: Number(multiconsultProject.BNR),
        UTME:
          typeof multiconsultProject.UTME === "string"
            ? Number(multiconsultProject.UTME.replace(",", "."))
            : multiconsultProject.UTME,
        UTMZ: Number(multiconsultProject.UTMZ),
      };

      const koordinater = convertMaconomyCoordinatesToGeoteknikCoordinates(maconomyCoordinates);

      let updatedData = R.clone(editData);
      updatedData.generellInfo.oppdragsNummer = String(multiconsultProject.ID);
      updatedData.generellInfo.oppdragsNavn = multiconsultProject.DisplayName;
      updatedData.prosjektInformasjon.koordinater = koordinater;
      updatedData.prosjektInformasjon.saksbehandler = userName;
      updatedData.generellInfo.oppdragsLeder = (multiconsultProject.ProjectManager as any)?.FullName;
      updatedData.prosjektInformasjon.eiendomsInformasjon.bnr = Number(multiconsultProject.BNR);
      updatedData.prosjektInformasjon.eiendomsInformasjon.gnr = Number(multiconsultProject.GNR);
      updatedData.generellInfo.oppdragsGiver.navn = multiconsultProject.CustomerName;
      updatedData.generellInfo.oppdragsGiver.kontaktPerson = multiconsultProject.InvoiceName;

      const geoteknikDepartment = GEOTEKNIK_DEPARTMENTS.find((d) => d.startsWith(String(multiconsultProject.UnitId)));

      updatedData.prosjektInformasjon.ansvarligEnhet = geoteknikDepartment || "";

      setEditData({
        ...editData,
        ...updatedData,
      });
    } catch (error) {
      setFailedToFetchFromMaconomy(true);
    }
  };

  const setLabText = (text: string) => {
    updateData("laboratorieresultater", text);
  };

  const updateData = <P extends Paths<IGeoTeknikkRapport, 4>>(path: P, value: PathValue<IGeoTeknikkRapport, P>) => {
    setEditData((prevState) => {
      const updatedData = R.assocPath(path.split("."), value, prevState);
      return updatedData;
    });
  };

  return (
    <div style={{ height: "100%", width: "800px" }}>
      {editData != null && (
        <>
          <GeoteknikSettings
            veiledningsTekst={editData.innstillinger?.veiledningsTekst}
            eksempelTekst={editData.innstillinger?.eksempelTekst}
            updateVeiledningsTekst={(bool) => updateData("innstillinger.veiledningsTekst", bool)}
            updateEksempelTekst={(bool) => updateData("innstillinger.eksempelTekst", bool)}
          />
          <VSpace />
          <SubMcProject onFetchFromMaconomy={fetchFromMaconomyAndSetData} failed={failedToFetchFromMaconomy} />
          <VSpace />
          <GeoteknikGenrellInfo editData={editData} updateData={updateData} />
        </>
      )}
      <VSpace />
      <GeoteknikMaps
        geoteknikKoordinater={editData?.prosjektInformasjon?.koordinater}
        onUpdateGeoteknikKoordinater={(koordinater) => updateData("prosjektInformasjon.koordinater", koordinater)}
        sirkelDiameter={editData?.prosjektInformasjon?.sirkelDiameter}
        onUpdateSirkelDiameter={updateData}
        zoomLevel={editData?.prosjektInformasjon?.zoomLevel}
        onUpdateZoomLevel={(zoomLevel) => updateData("prosjektInformasjon.zoomLevel", zoomLevel)}
      />
      <VSpace />
      <Card>
        <CardBody>
          <CardTitle tag="h4">Laboratorieresultater</CardTitle>
          <InputUpload setText={setLabText} text={editData?.laboratorieresultater} />
        </CardBody>
      </Card>
      <VSpace />
      <Card>
        <CardBody>
          <CardTitle tag="h4">Borehull</CardTitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted">
            Excel fra GeoSuite
          </CardSubtitle>
          <div className="d-flex">
            <ExcelImportValidatorWithSpecTable
              validators={geoReportValidation}
              onValidated={(result) =>
                updateData("borrhuler", formatBorrhulDataFromExcel(result.sourceData as string[][]))
              }
              skipRows={1}
              specificationTable={{
                columns: BORRING_TABLE_COLUMN,
                data: [
                  {
                    id: "1",
                    x: "1",
                    y: "1",
                    z: "1",
                    metode: "1",
                    stopp: "1",
                    losm: "1",
                    fjell: "1",
                  },
                ],
              }}
            />
            <div className="flex-fill" />
            {editData?.borrhuler?.length > 0 && <DeleteButton outline onClick={() => updateData("borrhuler", null)} />}
          </div>
          <VSpace />
          {editData?.borrhuler?.length > 0 && <BorringTable borrhuler={editData.borrhuler} />}
        </CardBody>
      </Card>
      <div style={{ height: "400px" }} />
    </div>
  );
};

export default GeoteknikRapport;
