import * as R from "ramda";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import { IBim360Info } from "../../../../../types/forge.types";
import { IBim360FolderContent } from "../../../../../types/global.types";
import { DropdownSelectorDeprecated, HSpace } from "../../../components";
import { MCLoader, VSpace } from "../../../components/";
import { MCApplicationsUrlEnum } from "../../../frontendConstants";
import { useAppSelector } from "../../../hooks";
import {
  doGetBim360FolderContent,
  doGetBim360RootFolders,
  doGetParentOfBim360Folder,
  doLogUsage,
} from "../admin/restQueries";
import { projectIsConnectedToBim360 } from "../admin/selectors";
import BIM360FileMetaDataReader from "./BIM360FileMetaDataReader";
import BIM360FolderPermissionReader from "./BIM360FolderPermissionReader";
import { formatFolderContent, formatRootFolders, getBim360Foldername } from "./util";

export const BIM360DocManagement: React.FC<{
  bim360: IBim360Info;
  initActiveFolder?: any;
  projectId: string;
}> = ({ bim360, initActiveFolder = null, projectId }) => {
  const project = useAppSelector((state) => state.adminReducer.project);

  const [folders, setFolders] = useState([]);
  const [activeFolder, setActiveFolder] = useState(initActiveFolder);
  const [parentFolder, setParentFolder] = useState(null);
  const [loadingFolders, setLoadingFolders] = useState(true);
  const [showBim360FileReader, setShowBim360FileReader] = React.useState<boolean>(false);
  const [showBIM360PermissionReader, setShowBIM360PermissionReader] = React.useState<boolean>(false);
  const [showFailedAlert, setShowFailedAlert] = useState<boolean>(false);
  const bim360Connected = useSelector(projectIsConnectedToBim360);

  useEffect(() => {
    doLogUsage(project, MCApplicationsUrlEnum.BIM360, "open");
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        if (bim360Connected === false) {
          return;
        }
        const rootFolderFetch = await doGetBim360RootFolders(projectId, bim360.projectId);

        const folders = rootFolderFetch.map((item) => formatRootFolders(item));
        setFolders(R.clone(folders));
        setLoadingFolders(false);
        if (initActiveFolder == null) {
          const projectFilesFolder = rootFolderFetch.find((folder) => folder.name === "Project Files");
          setFolder(projectFilesFolder);
        }
      } catch (error) {
        setShowFailedAlert(true);
      }
    }

    fetchData();
    if (initActiveFolder != null) {
      setFolder(initActiveFolder);
    }
  }, [bim360Connected]);

  const setFolder = async (folder) => {
    try {
      const parentFolderContent = await doGetParentOfBim360Folder(projectId, bim360.projectId, folder.urn);
      setParentFolder(formatFolderContent(parentFolderContent));
      setLoadingFolders(true);
      const folderContent = await doGetBim360FolderContent(projectId, bim360.projectId, folder.urn);

      setActiveFolder(folder);
      const folders = folderContent.data.reduce((acc, fileOrFolder) => {
        const fileOrFolderFormatted = formatFolderContent(fileOrFolder);
        if (fileOrFolder.type === "folders") {
          acc.push(fileOrFolderFormatted);
        }
        return acc;
      }, []);
      setFolders(folders);
      setLoadingFolders(false);
    } catch (error) {
      setShowFailedAlert(true);
    }
  };

  const goBack = async () => {
    await setFolder(parentFolder);
  };

  const backToFolderNavigator = () => {
    setShowBIM360PermissionReader(false);
    setShowBim360FileReader(false);
  };

  const [includeSubs, setIncludeSubs] = React.useState<boolean>(false);

  const readPermissions = (includeSubs: boolean) => {
    setIncludeSubs(includeSubs);
    setTimeout(() => {
      setShowBIM360PermissionReader(true);
    }, 50);
  };

  return (
    <div className="d-flex flex-column h-100 w-100">
      {(showBIM360PermissionReader !== false || showBim360FileReader !== false) && (
        <div>
          <Button color="secondary" size="sm" onClick={() => backToFolderNavigator()}>
            <i className="fa fa-arrow-left" />
          </Button>
        </div>
      )}
      {showFailedAlert === false &&
        bim360Connected === true &&
        showBim360FileReader === false &&
        showBIM360PermissionReader === false && (
          <>
            {loadingFolders === false ? (
              <div className="table-responsive-material w-100">
                <FolderTable
                  activeFolder={activeFolder}
                  folders={folders.filter((item) => item.type === "folders")}
                  setFolder={setFolder}
                  goBack={goBack}
                  parentFolder={parentFolder}
                />
                <VSpace />
                <Bim360Actions
                  readFiles={() => setShowBim360FileReader(true)}
                  readPermissions={(includeSubs) => readPermissions(includeSubs)}
                />
              </div>
            ) : (
              <MCLoader loading text="Henter mapper..." />
            )}
          </>
        )}
      {showBim360FileReader && (
        <BIM360FileMetaDataReader
          activeBim360Folder={activeFolder}
          projectId={project._id}
          bim360ProjectId={bim360.projectId}
        />
      )}
      {showBIM360PermissionReader && (
        <BIM360FolderPermissionReader
          activeBim360Folder={activeFolder}
          projectId={project._id}
          bim360ProjectId={bim360.projectId}
          includeSubs={includeSubs}
        />
      )}
    </div>
  );
};

const Bim360Actions: React.FC<{ readFiles: () => void; readPermissions: (includeSubs: boolean) => void }> = ({
  readFiles,
  readPermissions,
}) => {
  return (
    <div className="d-flex">
      <Button style={{ width: "300px" }} onClick={() => readFiles()}>
        <i className="fa fa-file-text-o fa-fw" />
        Les filer
      </Button>
      <HSpace />
      <ReadPermissionDropdown readPermissions={readPermissions} />
    </div>
  );
};

const ReadPermissionDropdown: React.FC<{ readPermissions: (includeSubs: boolean) => void }> = ({ readPermissions }) => {
  const options = [
    {
      label: "Inkluderer ikke undermapper",
      value: 1,
    },
    {
      label: "Inkluder undermapper",
      value: 0,
    },
  ];

  return (
    <DropdownSelectorDeprecated
      selectText="Les rettigheter"
      setItem={(option) => readPermissions(option.value === 0)}
      items={options}
      displayKey="label"
    />
  );
};

const FolderTable: React.FC<{
  activeFolder: IBim360FolderContent;
  folders: IBim360FolderContent[];
  setFolder: any;
  goBack: any;
  parentFolder?: IBim360FolderContent;
}> = ({ activeFolder, folders, setFolder, goBack, parentFolder }) => {
  const isRootFolder = parentFolder && parentFolder.attributes.displayName.indexOf("account-root-folder") !== -1;

  return (
    <div className="border p-2 w-100">
      <div className="d-flex align-items-center w-100">
        {isRootFolder === false && (
          <Button color="link" style={{ padding: "4px" }} onClick={goBack}>
            <b>{getBim360Foldername(parentFolder)}</b>
          </Button>
        )}
        <b>/&nbsp;</b>
        {activeFolder && (
          <div color="">
            <b>{getBim360Foldername(activeFolder)}</b>
          </div>
        )}
      </div>
      <VSpace />
      <table className="table default-table table-sm remove-table-border table-hover mb-0 border-0">
        <tbody>
          {folders.map((folder) => {
            return (
              <tr key={folder.urn}>
                <td>
                  <div
                    className="user-profile py-1 d-flex flex-row align-items-center"
                    style={{ cursor: "pointer" }}
                    onClick={() => setFolder(folder)}
                  >
                    <i className="fa fa-folder fa-3x fa-fw" style={{ color: "#9aa1a3" }} />
                    <div className="user-detail">
                      <h4 className="user-name">{folder.attributes.displayName} </h4>
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
