import * as R from "ramda";
import React, { useState } from "react";
import { Type } from "react-bootstrap-table2-editor";
import { Comparator, dateFilter, numberFilter, textFilter } from "react-bootstrap-table2-filter";
import {
  FlatHierarchy,
  IMember,
  IPNSProps,
  IRisikoEvaluationCategory,
  IRiskoBoard,
  IUser,
} from "../../../../../types/global.types";
import {
  ClickableTableIdWithTaskCompletedCount,
  CommentsDepricated,
  OptionBadge,
  PNSCodes,
  PNSTypeAhead,
  Tags,
} from "../../../components";
import {
  CellEditFormatter,
  COL_WIDTHS,
  COMMON_COLUMN_SECTIONS,
  createColumnStyle,
  createCustomHeaderDropDownFilter,
  createHeaderDropDownFilter,
  createHeaderStyle,
  CustomHeaderTextFormatter,
  headerSortAndDropDownFilter,
  headerSortAndFilterFormatter,
  isColumnHidden,
  sortCaret,
  URLCellFormatter,
} from "../../../components/columnGlobal";
import { ToastMessagesEnums } from "../../../frontendConstants";
import { IBootstrapTableColumnConfig } from "../../../types";
import { VIEW_EDIT_COMPS } from "../../globalConstants";
import { createMultiFilterOptions, getIssuesCompletedCount, sortArrayByString } from "../../utils";
import { formatShortDate } from "../admin/selectors";
import { ISSUE_DATAFILEDS_ENUM } from "../issuesApp/issueConstants";
import {
  doAddRisikoStateLog,
  doDeleteRisikoStatelog,
  doFetchUniqRisikoTags,
  doUpdateRisikoPnsCodes,
  doUpdateRisikoStatelogText,
  doUpdateRisikoTags,
} from "./risikoAPI";
import { RISIKO_LAV_MODERAT_HOY, RISIKO_STATES } from "./risikoConstants";
import { getConsequenceSelections } from "./util";
import { setShowSuccessMessageThunk } from "../../../global/globalSlice";

export const RISIKO_COLUMN_LS_KEY = "risiko_columns";

export const DEFAULT_VISIBLE_RISIKO_COLUMNS = ["id", "title", "state", "description", "createdAt"];

export const getRisikoColumns = (
  setSelectedRisikoId: (_id: string, boardId: string, projectId: string) => void,
  members: IMember[],
  projectId: string,
  user: IUser,
  setShowSuccessMessage,
  fetchAndSetRisiko,
  tagsFilter,
  pnsItems: FlatHierarchy<IPNSProps>,
  risikoBoard?: IRiskoBoard,
): IBootstrapTableColumnConfig[] => {
  const columns: IBootstrapTableColumnConfig[] = [
    {
      dataField: "id",
      text: "Id",
      editable: false,
      classes: "editable-cell",
      headerStyle: createHeaderStyle(COL_WIDTHS.ID_EXPAND),
      style: createColumnStyle(COL_WIDTHS.ID_EXPAND),
      showInDetail: false,
      filter: textFilter({ placeholder: "Tast inn..." }),
      section: "Generell",
      sort: true,
      sortCaret: sortCaret,
      headerFormatter: headerSortAndFilterFormatter,
      formatter: (cell, row) => (
        <ClickableTableIdWithTaskCompletedCount
          id={row.id}
          _id={row._id}
          onClick={() => setSelectedRisikoId(row._id, risikoBoard._id, projectId)}
          taskCompleteCount={getIssuesCompletedCount(row.connectedIssues)}
        />
      ),
      hidden: isColumnHidden("id", DEFAULT_VISIBLE_RISIKO_COLUMNS.indexOf("id") === -1, RISIKO_COLUMN_LS_KEY),
      headerSortingClasses: "sortedColumn",
      order: 1,
      viewEditComp: VIEW_EDIT_COMPS.TITLE_WITH_VALUE_EDIT,
      inputType: "text",
    },

    {
      dataField: "object",
      text: "Objekt",
      editable: true,
      classes: "editable-cell",
      filter: textFilter({ placeholder: "Tast inn..." }),
      sort: true,
      sortCaret: sortCaret,
      headerStyle: createHeaderStyle(COL_WIDTHS.LONGTEXT),
      headerFormatter: headerSortAndFilterFormatter,
      section: "Generell",
      hidden: isColumnHidden("object", DEFAULT_VISIBLE_RISIKO_COLUMNS.indexOf("object") === -1, RISIKO_COLUMN_LS_KEY),
      headerSortingClasses: "sortedColumn",
      formatter: CellEditFormatter,
      editor: {
        type: Type.TEXTAREA,
      },
      order: 2,
      viewEditComp: VIEW_EDIT_COMPS.TITLE_WITH_VALUE_EDIT,
      inputType: "text",
    },
    {
      dataField: "state",
      text: "Status",
      editable: true,
      classes: "editable-cell",
      filter: createCustomHeaderDropDownFilter(createMultiFilterOptions(RISIKO_STATES)),
      sort: true,
      sortCaret: sortCaret,
      headerFormatter: headerSortAndDropDownFilter,
      section: "Generell",
      hidden: isColumnHidden("state", DEFAULT_VISIBLE_RISIKO_COLUMNS.indexOf("state") === -1, RISIKO_COLUMN_LS_KEY),
      headerSortingClasses: "sortedColumn",
      dropdownOptions: RISIKO_STATES,
      formatter: (cell) => <OptionBadge activeOption={cell} options={RISIKO_STATES} />,
      editor: {
        type: Type.SELECT,
        options: RISIKO_STATES.map((state) => {
          return {
            label: state.key,
            value: state.key,
          };
        }),
      },
      order: 2,
      headerStyle: createHeaderStyle(COL_WIDTHS.CHIP),
      viewEditComp: VIEW_EDIT_COMPS.DROPDOWN_COLOR_EDIT,
    },
    {
      dataField: "title",
      text: "Tittel",
      editable: true,
      classes: "editable-cell",
      filter: textFilter({ placeholder: "Tast inn..." }),
      sort: true,
      sortCaret: sortCaret,
      headerFormatter: headerSortAndFilterFormatter,
      section: "Risiko",
      hidden: isColumnHidden("title", DEFAULT_VISIBLE_RISIKO_COLUMNS.indexOf("title") === -1, RISIKO_COLUMN_LS_KEY),
      headerSortingClasses: "sortedColumn",
      formatter: CellEditFormatter,
      editor: {
        type: Type.TEXTAREA,
      },
      headerStyle: createHeaderStyle(COL_WIDTHS.LONGTEXT),

      order: 3,
      viewEditComp: VIEW_EDIT_COMPS.TITLE_WITH_VALUE_EDIT,
      inputType: "textarea",
    },
    {
      dataField: "description",
      text: "Beskrivelse",
      editable: true,
      classes: "editable-cell",
      filter: textFilter({ placeholder: "Tast inn..." }),
      sort: true,
      sortCaret: sortCaret,
      headerStyle: createHeaderStyle(COL_WIDTHS.LONGTEXT),
      headerFormatter: headerSortAndFilterFormatter,
      section: "Risiko",
      hidden: isColumnHidden(
        "description",
        DEFAULT_VISIBLE_RISIKO_COLUMNS.indexOf("description") === -1,
        RISIKO_COLUMN_LS_KEY,
      ),
      headerSortingClasses: "sortedColumn",
      formatter: CellEditFormatter,
      editor: {
        type: Type.TEXTAREA,
      },
      order: 4,
      viewEditComp: VIEW_EDIT_COMPS.TITLE_WITH_VALUE_EDIT,
      inputType: "textarea",
    },
    {
      dataField: "pnsCodes",
      text: "PNS",
      classes: "editable-cell",
      filter: textFilter({ placeholder: "Tast inn..." }),
      editable: false,
      sort: true,
      sortCaret: sortCaret,
      hidden: isColumnHidden(
        "pnsCodes",
        DEFAULT_VISIBLE_RISIKO_COLUMNS.indexOf("pnsCodes") === -1,
        RISIKO_COLUMN_LS_KEY,
      ),
      headerFormatter: headerSortAndFilterFormatter,
      section: "Generell",
      formatter: (cell, risiko) => {
        const cb = async (itemId: string, pnsCodes) => {
          await doUpdateRisikoPnsCodes(projectId, risiko._id, pnsCodes);
          fetchAndSetRisiko(itemId);
          setShowSuccessMessageThunk(ToastMessagesEnums.UPDATED);
        };
        return <PNSCodes pnsCodes={cell} itemId={risiko._id} pnsItems={pnsItems} updateCb={cb} />;
      },
      headerStyle: createHeaderStyle(COL_WIDTHS.CHIP),
      headerSortingClasses: "sortedColumn",
      order: 5,
      viewEditComp: VIEW_EDIT_COMPS.PNS_EDITOR,
      editorRenderer: (editorProps, value) => <PNSEditor {...editorProps} value={value} pnsItems={pnsItems} />,
    },
    {
      dataField: "cause",
      text: "Årsak",
      editable: true,
      classes: "editable-cell",
      filter: textFilter({ placeholder: "Tast inn..." }),
      sort: true,
      sortCaret: sortCaret,
      headerFormatter: headerSortAndFilterFormatter,
      headerStyle: createHeaderStyle(COL_WIDTHS.LONGTEXT),
      section: "Risiko",
      hidden: isColumnHidden("cause", DEFAULT_VISIBLE_RISIKO_COLUMNS.indexOf("cause") === -1, RISIKO_COLUMN_LS_KEY),
      headerSortingClasses: "sortedColumn",
      formatter: CellEditFormatter,
      editor: {
        type: Type.TEXTAREA,
      },
      order: 6,
      viewEditComp: VIEW_EDIT_COMPS.TITLE_WITH_VALUE_EDIT,
      inputType: "textarea",
    },
    {
      dataField: "consequenceDescription",
      text: "Konsekvenser",
      editable: true,
      classes: "editable-cell",
      filter: textFilter({ placeholder: "Tast inn..." }),
      sort: true,
      sortCaret: sortCaret,
      headerFormatter: headerSortAndFilterFormatter,
      headerStyle: createHeaderStyle(COL_WIDTHS.LONGTEXT),
      section: "Risiko",
      hidden: isColumnHidden(
        "consequenceDescription",
        DEFAULT_VISIBLE_RISIKO_COLUMNS.indexOf("consequenceDescription") === -1,
        RISIKO_COLUMN_LS_KEY,
      ),
      headerSortingClasses: "sortedColumn",
      formatter: CellEditFormatter,
      editor: {
        type: Type.TEXTAREA,
      },
      order: 7,
      viewEditComp: VIEW_EDIT_COMPS.TITLE_WITH_VALUE_EDIT,
      inputType: "textarea",
    },
    {
      dataField: "uncertainty.value",
      text: "Usikkerhet",
      editable: true,
      classes: "editable-cell",
      filter: createHeaderDropDownFilter(RISIKO_LAV_MODERAT_HOY),
      sort: true,
      sortCaret: sortCaret,
      headerFormatter: headerSortAndDropDownFilter,
      headerStyle: createHeaderStyle(COL_WIDTHS.CHIP),
      section: "Risiko",
      hidden: isColumnHidden(
        "uncertainty.value",
        DEFAULT_VISIBLE_RISIKO_COLUMNS.indexOf("uncertainty.value") === -1,
        RISIKO_COLUMN_LS_KEY,
      ),
      headerSortingClasses: "sortedColumn",
      formatter: CellEditFormatter,
      order: 7,
      viewEditComp: VIEW_EDIT_COMPS.DROPDOWN_EDIT,
      simpleDropdownOptions: RISIKO_LAV_MODERAT_HOY,
      editor: {
        type: Type.SELECT,
        options: RISIKO_LAV_MODERAT_HOY.map((typ) => {
          return {
            label: typ,
            value: typ,
          };
        }),
      },
    },
    {
      dataField: "vulnerability.value",
      text: "Sårbarhet",
      editable: true,
      classes: "editable-cell",
      filter: createHeaderDropDownFilter(RISIKO_LAV_MODERAT_HOY),
      sort: true,
      sortCaret: sortCaret,
      headerFormatter: headerSortAndDropDownFilter,
      headerStyle: createHeaderStyle(COL_WIDTHS.CHIP),
      section: "Risiko",
      hidden: isColumnHidden(
        "vulnerability.value",
        DEFAULT_VISIBLE_RISIKO_COLUMNS.indexOf("vulnerability.value") === -1,
        RISIKO_COLUMN_LS_KEY,
      ),
      headerSortingClasses: "sortedColumn",
      formatter: CellEditFormatter,
      order: 7,
      viewEditComp: VIEW_EDIT_COMPS.DROPDOWN_EDIT,
      simpleDropdownOptions: RISIKO_LAV_MODERAT_HOY,
      editor: {
        type: Type.SELECT,
        options: RISIKO_LAV_MODERAT_HOY.map((typ) => {
          return {
            label: typ,
            value: typ,
          };
        }),
      },
    },
    {
      dataField: "owner.name",
      showInDetail: false,
      editable: true,
      classes: "editable-cell",
      text: "Eier",
      filter: createHeaderDropDownFilter(members, "user.name"),
      sort: true,
      sortCaret: sortCaret,
      headerFormatter: headerSortAndDropDownFilter,
      section: "Generell",
      hidden: isColumnHidden(
        "owner",
        DEFAULT_VISIBLE_RISIKO_COLUMNS.indexOf("owner.name") === -1,
        RISIKO_COLUMN_LS_KEY,
      ),
      headerSortingClasses: "sortedColumn",
      formatExtraData: {
        avatar: ["owner", "_id"],
      },
      headerStyle: createHeaderStyle(COL_WIDTHS.PERSON),
      formatter: CellEditFormatter,
      editor: {
        type: Type.SELECT,
        options: sortArrayByString(members, "user.name").map((member) => {
          return {
            label: member.user?.name,
            value: member.user?.name,
          };
        }),
      },
      order: 8,
    },
    {
      dataField: "identified",
      text: "Identifisert",
      editable: true,
      classes: "editable-cell",
      filter: textFilter({ placeholder: "Tast inn..." }),
      sort: true,
      sortCaret: sortCaret,
      headerFormatter: headerSortAndFilterFormatter,
      section: "Generell",
      hidden: isColumnHidden(
        "identified",
        DEFAULT_VISIBLE_RISIKO_COLUMNS.indexOf("identified") === -1,
        RISIKO_COLUMN_LS_KEY,
      ),
      headerSortingClasses: "sortedColumn",
      formatter: CellEditFormatter,
      editor: {
        type: Type.TEXTAREA,
      },
      order: 9,
      headerStyle: createHeaderStyle(COL_WIDTHS.LONGTEXT),
      viewEditComp: VIEW_EDIT_COMPS.TITLE_WITH_VALUE_EDIT,
      inputType: "text",
    },
    {
      dataField: "comment",
      text: "Kommentar",
      editable: true,
      classes: "editable-cell",
      filter: textFilter({ placeholder: "Tast inn..." }),
      sort: true,
      sortCaret: sortCaret,
      headerFormatter: headerSortAndFilterFormatter,
      section: "Generell",
      hidden: isColumnHidden("comment", DEFAULT_VISIBLE_RISIKO_COLUMNS.indexOf("comment") === -1, RISIKO_COLUMN_LS_KEY),
      headerSortingClasses: "sortedColumn",
      formatter: CellEditFormatter,
      editor: {
        type: Type.TEXTAREA,
      },
      order: 10,
      headerStyle: createHeaderStyle(COL_WIDTHS.LONGTEXT),
      viewEditComp: VIEW_EDIT_COMPS.TITLE_WITH_VALUE_EDIT,
      inputType: "textarea",
    },
    {
      dataField: "createdAt",
      text: "Opprettet",
      filter: dateFilter({
        defaultValue: { comparator: Comparator.GT },
      }),
      sort: true,
      sortCaret: sortCaret,
      headerFormatter: headerSortAndFilterFormatter,
      formatter: (cell) => formatShortDate(cell),
      section: COMMON_COLUMN_SECTIONS.HISTORY,
      editable: false,
      hidden: isColumnHidden(
        "createdAt",
        DEFAULT_VISIBLE_RISIKO_COLUMNS.indexOf("createdAt") === -1,
        RISIKO_COLUMN_LS_KEY,
      ),
      headerSortingClasses: "sortedColumn",
      editor: {
        type: Type.TEXTAREA,
      },
      order: 11,
      headerStyle: createHeaderStyle(COL_WIDTHS.DATE),
      viewEditComp: VIEW_EDIT_COMPS.TITLE_WITH_VALUE_EDIT,
    },
    {
      dataField: "createdBy.name",
      text: "Opprettet av",
      sort: true,
      sortCaret: sortCaret,
      headerFormatter: headerSortAndDropDownFilter,
      section: COMMON_COLUMN_SECTIONS.HISTORY,
      editable: false,
      hidden: isColumnHidden(
        "createdBy.name",
        DEFAULT_VISIBLE_RISIKO_COLUMNS.indexOf("createdBy.name") === -1,
        RISIKO_COLUMN_LS_KEY,
      ),
      headerSortingClasses: "sortedColumn",
      order: 12,
      headerStyle: createHeaderStyle(COL_WIDTHS.PERSON),
      filter: createHeaderDropDownFilter(members, "user.name"),
      viewEditComp: VIEW_EDIT_COMPS.TITLE_WITH_VALUE_EDIT,
    },
    {
      dataField: "updatedAt",
      text: "Sist oppdatert",
      filter: dateFilter({
        defaultValue: { comparator: Comparator.GT },
      }),
      sort: true,
      sortCaret: sortCaret,
      headerFormatter: headerSortAndFilterFormatter,
      formatter: (cell) => formatShortDate(cell),
      section: COMMON_COLUMN_SECTIONS.HISTORY,
      editable: false,
      hidden: isColumnHidden(
        "updatedAt",
        DEFAULT_VISIBLE_RISIKO_COLUMNS.indexOf("updatedAt") === -1,
        RISIKO_COLUMN_LS_KEY,
      ),
      headerSortingClasses: "sortedColumn",
      order: 13,
      headerStyle: createHeaderStyle(COL_WIDTHS.DATE),
      viewEditComp: VIEW_EDIT_COMPS.TITLE_WITH_VALUE_EDIT,
    },
    {
      dataField: "lastUpdatedBy.name",
      text: "Sist oppdatert av",
      filter: createHeaderDropDownFilter(members, "user.name"),
      sort: true,
      sortCaret: sortCaret,
      headerFormatter: headerSortAndDropDownFilter,
      section: COMMON_COLUMN_SECTIONS.HISTORY,
      editable: false,
      hidden: isColumnHidden(
        "lastUpdatedBy.name",
        DEFAULT_VISIBLE_RISIKO_COLUMNS.indexOf("lastUpdatedBy.name") === -1,
        RISIKO_COLUMN_LS_KEY,
      ),
      headerSortingClasses: "sortedColumn",
      order: 14,
      headerStyle: createHeaderStyle(COL_WIDTHS.PERSON),
      viewEditComp: VIEW_EDIT_COMPS.TITLE_WITH_VALUE_EDIT,
    },
    {
      dataField: "bookmark",
      text: "Bokmerke",
      editable: true,
      classes: "editable-cell",
      filter: textFilter({ placeholder: "Tast inn..." }),
      sort: true,
      sortCaret: sortCaret,
      section: "Generell",
      headerStyle: createHeaderStyle(COL_WIDTHS.CHIP),
      headerSortingClasses: "sortedColumn",
      headerFormatter: headerSortAndFilterFormatter,
      formatter: (cell) => {
        if (cell == null || cell === "") {
          return null;
        }
        return isNaN(cell) ? cell : Number(cell);
      },
      sortFunc: (a, b, order) => {
        // equal items sort equally
        if (a === b) {
          return 0;
        }
        // nulls sort after anything else
        else if (R.isEmpty(a)) {
          return 1;
        } else if (R.isEmpty(b)) {
          return -1;
        }
        // otherwise, if we're ascending, lowest sorts first
        else if (order === "asc") {
          return b - a;
        }
        // if descending, highest sorts first
        else {
          return a - b;
        }
      },
      hidden: isColumnHidden(
        "bookmark",
        DEFAULT_VISIBLE_RISIKO_COLUMNS.indexOf("bookmark") === -1,
        RISIKO_COLUMN_LS_KEY,
      ),
      order: 15,
      viewEditComp: VIEW_EDIT_COMPS.TITLE_WITH_VALUE_EDIT,
      inputType: "text",
    },
    {
      dataField: "statelogs",
      text: "Statuslogger",
      filter: textFilter({ placeholder: "Tast inn..." }),
      sort: true,
      sortCaret: sortCaret,
      showInDetail: false,
      headerFormatter: headerSortAndFilterFormatter,
      section: "Risiko",
      editable: false,
      formatter: (cell, risiko) => {
        return (
          <CommentsDepricated
            title="Status logs"
            members={members}
            comments={cell}
            projectId={projectId}
            itemId={risiko._id}
            fetchAndSetItem={fetchAndSetRisiko}
            user={user}
            setShowSuccessMessage={setShowSuccessMessage}
            collapseEnabled
            serverAddComment={doAddRisikoStateLog}
            serverDelete={doDeleteRisikoStatelog}
            serverUpdateComment={doUpdateRisikoStatelogText}
            tooltip="Legg til kommentar"
          />
        );
      },
      hidden: isColumnHidden(
        "statelogs",
        DEFAULT_VISIBLE_RISIKO_COLUMNS.indexOf("statelogs") === -1,
        RISIKO_COLUMN_LS_KEY,
      ),
      headerSortingClasses: "sortedColumn",
      order: 16,
      headerStyle: createHeaderStyle(COL_WIDTHS.LONGTEXT),
    },
    {
      dataField: "tags",
      text: "Etiketter",
      sort: true,
      sortCaret: sortCaret,
      showInDetail: false,
      editable: false,
      section: "Generell",
      headerStyle: createHeaderStyle(COL_WIDTHS.TAGS),
      filter: createCustomHeaderDropDownFilter(tagsFilter),
      headerSortingClasses: "sortedColumn",
      headerFormatter: headerSortAndDropDownFilter,
      formatter: (cell, risiko) => {
        const cb = async (tags) => {
          await doUpdateRisikoTags(projectId, risiko._id, tags);
          fetchAndSetRisiko(risiko._id);
          setShowSuccessMessageThunk(ToastMessagesEnums.UPDATED);
        };
        return (
          <Tags
            key={risiko._id}
            tags={cell}
            fetchUniqTags={doFetchUniqRisikoTags}
            updateCb={cb}
            fetchArgs={[projectId, risikoBoard != null ? risikoBoard._id : ""]}
          />
        );
      },
      order: 18,
    },
    {
      dataField: "probability",
      text: "Sannsynlighet",
      editable: true,
      classes: "editable-cell",
      filter: textFilter({ placeholder: "Tast inn..." }),
      sort: true,
      sortCaret: sortCaret,
      headerFormatter: headerSortAndFilterFormatter,
      section: "Evaluering",
      hidden: isColumnHidden(
        "probability",
        DEFAULT_VISIBLE_RISIKO_COLUMNS.indexOf("probability") === -1,
        RISIKO_COLUMN_LS_KEY,
      ),
      headerSortingClasses: "sortedColumn",
      formatter: CellEditFormatter,
      headerStyle: createHeaderStyle(COL_WIDTHS.CHIP),
      editor: {
        type: Type.SELECT,
        options:
          risikoBoard != null
            ? risikoBoard.probabilitySelections.map((selection) => {
                return {
                  value: selection.name,
                  label: selection.name,
                };
              })
            : {},
      },
      order: 19,
      viewEditComp: VIEW_EDIT_COMPS.EVALUATION_PROBABILITY_EDIT,
    },
    {
      dataField: ISSUE_DATAFILEDS_ENUM.REFERENCEURL,
      text: "Referanse URL",
      editable: true,
      classes: "editable-cell",
      filter: textFilter({ placeholder: "Tast inn..." }),
      section: "Generell",
      sort: true,
      sortCaret: sortCaret,
      headerFormatter: headerSortAndFilterFormatter,
      hidden: isColumnHidden(
        ISSUE_DATAFILEDS_ENUM.REFERENCEURL,
        DEFAULT_VISIBLE_RISIKO_COLUMNS.indexOf(ISSUE_DATAFILEDS_ENUM.REFERENCEURL) === -1,
        RISIKO_COLUMN_LS_KEY,
      ),
      headerSortingClasses: "sortedColumn",
      formatter: URLCellFormatter,
      editor: {
        type: Type.TEXTAREA,
      },
      headerStyle: createHeaderStyle(COL_WIDTHS.LONGTEXT),
      viewEditComp: VIEW_EDIT_COMPS.TITLE_WITH_VALUE_EDIT,
      inputType: "text",
    },
    {
      dataField: "constructionContract",
      text: "Enterprise",
      editable: true,
      classes: "editable-cell",
      filter: textFilter({ placeholder: "Tast inn..." }),
      section: "Generell",
      sort: true,
      sortCaret: sortCaret,
      headerFormatter: headerSortAndFilterFormatter,
      hidden: isColumnHidden(
        "constructionContract",
        DEFAULT_VISIBLE_RISIKO_COLUMNS.indexOf("constructionContract") === -1,
        RISIKO_COLUMN_LS_KEY,
      ),
      headerSortingClasses: "sortedColumn",
      formatter: URLCellFormatter,
      editor: {
        type: Type.TEXTAREA,
      },
      headerStyle: createHeaderStyle(COL_WIDTHS.LONGTEXT),
      viewEditComp: VIEW_EDIT_COMPS.TITLE_WITH_VALUE_EDIT,
      inputType: "text",
    },
  ];

  if (projectId === "5cc6e4d8b78033404b892d30" || projectId === "611b5dcb13582243627ed21a")
    columns.splice(1, 0, {
      dataField: "oldId",
      text: "Old id",
      editable: true,
      classes: "editable-cell",
      headerStyle: createHeaderStyle(COL_WIDTHS.ID),
      filter: textFilter({ placeholder: "Tast inn..." }),
      section: "Generell",
      sort: true,
      sortCaret: sortCaret,
      headerFormatter: headerSortAndFilterFormatter,
      formatter: CellEditFormatter,
      hidden: isColumnHidden("comment", false, RISIKO_COLUMN_LS_KEY),
      headerSortingClasses: "sortedColumn",
      order: 1,
      viewEditComp: VIEW_EDIT_COMPS.TITLE_WITH_VALUE_EDIT,
      inputType: "text",
    });

  if (risikoBoard != null) {
    return columns.filter((col) => risikoBoard.disabledColumns.indexOf(col.dataField) === -1);
  } else {
    return columns;
  }
};

export const getEvaluationColumns = (risikoCategories: IRisikoEvaluationCategory[], calculateRisikoScore: any) => {
  const risikoCategoriesColumns = risikoCategories.reduce((acc, category) => {
    const columns = [
      {
        dataField: `evaluationsObj.${category.name}.consequence`,
        text: `${category.name.replaceAll("_", " ")} - Konsekvens`,
        evaluationCategoryId: category._id,
        filter: createHeaderDropDownFilter(category.consequenceSelections, "name"),
        headerFormatter: CustomHeaderTextFormatter,
        editable: true,
        classes: "editable-cell",
        headerSortingClasses: "sortedColumn",
        sort: true,
        sortCaret: sortCaret,
        section: "Evaluering",
        editor: {
          type: Type.SELECT,
          options: getConsequenceSelections(risikoCategories, category.name),
        },
        formatter: CellEditFormatter,
        hidden: isColumnHidden(`evaluationsObj.${category.name}.consequence`, true, RISIKO_COLUMN_LS_KEY),
        headerStyle: createHeaderStyle(COL_WIDTHS.CHIP),
        viewEditComp: VIEW_EDIT_COMPS.EVALUATION_CONS_EDIT,
      },
      {
        dataField: `evaluationsObj.${category.name}.score`,
        text: `${category.name.replaceAll("_", " ")} - Poeng`,
        evaluationCategoryId: category._id,
        filter: numberFilter({
          defaultValue: { number: 0, comparator: Comparator.GT },
        }),
        headerFormatter: CustomHeaderTextFormatter,
        headerSortingClasses: "sortedColumn",
        formatter: calculateRisikoScore,
        formatExtraData: {
          columnName: category.name.replaceAll("_", " "),
        },
        sort: true,
        sortCaret: sortCaret,
        section: "Evaluering",
        editable: false,
        hidden: isColumnHidden(`evaluationsObj.${category.name}.score`, true, RISIKO_COLUMN_LS_KEY),
        headerStyle: createHeaderStyle(COL_WIDTHS.CHIP),
        viewEditComp: VIEW_EDIT_COMPS.EVALUATION_SCORE,
      },
    ];
    acc.push(...columns);
    return acc;
  }, []);
  risikoCategoriesColumns.push({
    dataField: `evaluationsObj._SUM_`,
    text: `Total poengsum`,
    evaluationCategoryId: "Oppsummert poeng",
    filter: numberFilter({
      defaultValue: { number: 0, comparator: Comparator.GT },
    }),
    headerFormatter: headerSortAndFilterFormatter,
    headerSortingClasses: "sortedColumn",
    sort: true,
    sortCaret: sortCaret,
    section: "Evaluering",
    editable: false,
    hidden: isColumnHidden(`evaluationsObj._SUM_`, true, RISIKO_COLUMN_LS_KEY),
    headerStyle: createHeaderStyle(COL_WIDTHS.CHIP),
    viewEditComp: VIEW_EDIT_COMPS.TITLE_WITH_VALUE_EDIT,
  });
  return risikoCategoriesColumns;
};

export const PNSEditor: React.FC<{
  onUpdate: (value: string) => void;
  value: string;
  pnsItems: FlatHierarchy<IPNSProps>;
}> = ({ onUpdate, pnsItems, value }) => {
  const [val, setVal] = useState<string>(value);

  return <PNSTypeAhead selected={val} pnsItems={pnsItems} setValue={setVal} saveCb={onUpdate} />;
};
