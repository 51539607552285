import { IStampSettings } from "../../../../../../types/checklist.types";
import { McInput } from "../../../../components";

const StampConfiguration: React.FC<{
  currentConfig: IStampSettings;
  onChange: (newSettings: IStampSettings) => void;
}> = ({ currentConfig, onChange }) => {
  const updateSettings = (key: keyof IStampSettings, value: string) => {
    onChange({
      ...currentConfig,
      [key]: {
        ...currentConfig[key],
        text: value,
      },
    });
  };

  return (
    currentConfig != null && (
      <table className="w-100">
        <tbody>
          <tr>
            <td>
              <SingleStamp config={currentConfig.yes} />
            </td>
            <td>
              <McInput
                required={true}
                value={currentConfig.yes.text}
                onBlur={(e) => updateSettings("yes", e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>
              <SingleStamp config={currentConfig.no} />
            </td>
            <td>
              <McInput
                required={true}
                value={currentConfig.no.text}
                onBlur={(e) => updateSettings("no", e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>
              <SingleStamp config={currentConfig.ir} />
            </td>
            <td>
              <McInput
                required={true}
                value={currentConfig.ir.text}
                onBlur={(e) => updateSettings("ir", e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>
              <SingleStamp config={currentConfig.na} />
            </td>
            <td>
              <McInput
                required={false} // This should be able to be empty in reports
                value={currentConfig.na.text}
                onBlur={(e) => updateSettings("na", e.target.value)}
              />
            </td>
          </tr>
        </tbody>
      </table>
    )
  );
};

export default StampConfiguration;

const SingleStamp: React.FC<{
  config: {
    text: string;
    color: string;
    icon: string;
  };
}> = ({ config }) => {
  return (
    config != null && (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{
          color: "#fff",
          width: "25px",
          height: "25px",
          borderRadius: "50%",
          backgroundColor: config.color,
        }}
      >
        <i className={`fa fa-${config.icon}`} />
      </div>
    )
  );
};
