import { IssueDisabledColumnsEnum } from "mc-shared/enums/enums";
import { McInput } from "..";
import { IIssue, onIssueChange } from "../../../../types/global.types";

const IssueTimeSection: React.FC<{
  issue: IIssue;
  onChange: onIssueChange;
  disabledColumns?: string[];
}> = ({ issue, onChange, disabledColumns }) => {
  return (
    <div className="d-flex flex-column">
      <div className="even-columns mt-2">
        <div className="d-flex flex-column mr-4">
          <h5 className="truncateHeaderText">Startdato</h5>
          <McInput
            type="date"
            onChange={(e) =>
              onChange(issue.dates?.plannedStart, { ...issue.dates, plannedStart: e.target.value }, "dates")
            }
            value={issue.dates?.plannedStart}
          />
        </div>
        <div className="d-flex flex-column mr-4">
          <h5 className="truncateHeaderText">Tidsfrist</h5>
          <McInput
            type="date"
            onChange={(e) => onChange(issue.dates?.due, { ...issue.dates, due: e.target.value }, "dates")}
            value={issue.dates?.due}
            className={
              (issue.status === "I prosess" || issue.status === "Opprettet") &&
              issue.dates?.due != null &&
              new Date(issue.dates?.due) < new Date()
                ? "border border-danger text-danger"
                : ""
            }
          />
        </div>
        <div className="d-flex flex-column mr-4"></div>
      </div>
      <div className="even-columns mt-2">
        {disabledColumns?.includes(IssueDisabledColumnsEnum.HOURSORIGINALESTIMATE) === false && (
          <div className="d-flex flex-column mr-4">
            <h5 className="truncateHeaderText">Estimert</h5>
            <McInput
              placeholder="Angi timer"
              pattern="^$|[0-9]+"
              onBlur={(e) =>
                (e?.target?.value === "" && issue.hours?.orignalEstimate == null) === false &&
                onChange(
                  issue.hours?.orignalEstimate?.toString(),
                  { ...issue.hours, orignalEstimate: e?.target?.value === "" ? null : Number(e.target.value) },
                  "hours",
                )
              }
              value={issue.hours?.orignalEstimate?.toString()}
            />
          </div>
        )}
        {disabledColumns?.includes(IssueDisabledColumnsEnum.HOURSCOMPLETED) === false && (
          <div className="d-flex flex-column mr-4">
            <h5 className="truncateHeaderText">Brukt</h5>
            <McInput
              placeholder="Angi timer"
              pattern="^$|[0-9]+"
              onBlur={(e) =>
                (e?.target?.value === "" && issue.hours?.completed == null) === false &&
                onChange(
                  issue.hours?.completed?.toString(),
                  { ...issue.hours, completed: e?.target?.value === "" ? null : Number(e.target.value) },
                  "hours",
                )
              }
              value={issue.hours?.completed?.toString()}
            />
          </div>
        )}
        {disabledColumns?.includes(IssueDisabledColumnsEnum.HOURSREMAINING) === false && (
          <div className="d-flex flex-column mr-4">
            <h5 className="truncateHeaderText">Gjenstående</h5>
            <McInput
              placeholder="Angi timer"
              pattern="^$|[0-9]+"
              onBlur={(e) =>
                (e?.target?.value === "" && issue.hours?.remaining == null) === false &&
                onChange(
                  issue.hours?.remaining?.toString(),
                  { ...issue.hours, remaining: e?.target?.value === "" ? null : Number(e.target.value) },
                  "hours",
                )
              }
              value={issue.hours?.remaining?.toString()}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default IssueTimeSection;
