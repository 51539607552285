import React, { useEffect } from "react";
import { MCApplicationsUrlEnum } from "../../../../frontendConstants";
import { useAppSelector } from "../../../../hooks";
import { doLogUsage } from "../restQueries";
import AddNewMember from "./AddNewMember";
import MembersTable from "./MembersTable";

const MembersPage: React.FC<{ projectId: string }> = ({ projectId }) => {
  const project = useAppSelector((state) => state.adminReducer.project);

  useEffect(() => {
    doLogUsage(project, MCApplicationsUrlEnum.MEMBERS, "open");
  }, []);

  return (
    <div className="d-flex flex-column h-100 w-100 p-2">
      <h3>Prosjektmedlemmer</h3>
      <AddNewMember />
      <hr />
      <MembersTable members={project.members} projectId={projectId} />
    </div>
  );
};

export default MembersPage;
