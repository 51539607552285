import React from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { SwitchWithTitle, VSpace } from "..";
import { IBatchjob } from "../../../../types/batchjob.types";
import { IfcVersionEnums, RevitIfcParamsEnums } from "../../frontendConstants";
import CheckOrWarn from "../CheckOrWarn";

const RevitToIfcSettings: React.FC<{
  revitIfcSettings: IBatchjob.IRevitToIfcSettings;
  saveJobParams: (value, key: string) => void;
  savePropertyMapping: (mapping: IBatchjob.IPropertyMapping) => void;
  isValid: boolean;
}> = ({ revitIfcSettings, saveJobParams, savePropertyMapping, isValid }) => {
  const [activeTab, setActiveTab] = React.useState<"GENERAL" | "PROPERTYMAPPING">("GENERAL");
  return (
    <Card>
      <CardBody>
        <CardTitle tag="h3">
          <CheckOrWarn bool={isValid} />
          Revit til IFC-innstillinger
        </CardTitle>
        <CardSubtitle tag="h6" className="mb-2 text-muted">
          Spesifiser hvilke innstillinger i Revit som skal brukes for IFC-eksporten
        </CardSubtitle>
        <ButtonGroup>
          <Button active={activeTab === "GENERAL"} onClick={() => setActiveTab("GENERAL")}>
            Genrelt
          </Button>
          <Button active={activeTab === "PROPERTYMAPPING"} onClick={() => setActiveTab("PROPERTYMAPPING")}>
            Property mapping
          </Button>
        </ButtonGroup>
        <VSpace />
        {activeTab === "GENERAL" && revitIfcSettings?.jobParams != null && (
          <JobParamsSettings jobParams={revitIfcSettings.jobParams} save={saveJobParams} />
        )}
        {activeTab === "PROPERTYMAPPING" && (
          <PropertyMappingSettings
            propertyMapping={revitIfcSettings.propertyMapping}
            saveProperyMapping={savePropertyMapping}
          />
        )}
      </CardBody>
    </Card>
  );
};

export default RevitToIfcSettings;

const PropertyMappingSettings: React.FC<{
  propertyMapping: IBatchjob.IPropertyMapping;
  saveProperyMapping: (propetyMapping: IBatchjob.IPropertyMapping) => void;
}> = ({ propertyMapping, saveProperyMapping }) => {
  const [warningFile, setWarningFile] = React.useState<boolean>(false);
  const uploadFile = (event) => {
    setWarningFile(false);
    const files = event.target.files,
      f = files[0];

    if (f.size > 20000) {
      return setWarningFile(true);
    }
    const reader = new FileReader();
    reader.onload = function (e) {
      let data = e.target.result;
      if (isUploadValid(data) === false) {
        setWarningFile(true);
      } else {
        saveProperyMapping({
          fileName: f.fileName,
          content: data as string,
        });
      }
    };
    reader.readAsText(f);
  };

  const isUploadValid = (content: any): boolean => {
    if (typeof content !== "string") {
      return false;
    }
    return content.includes("PropertySet:") !== false;
  };
  return (
    <>
      {warningFile && (
        <Alert color="warning">
          Filens innhold ser ikke ut til å være en korrekt egenskapskartleggingsfil fra Revit
        </Alert>
      )}
      <div className="d-flex flex-column">
        <span>Laste opp ny (max 20kb)</span>
        <input accept=".txt" type="file" onChange={(event) => uploadFile(event)} />
      </div>
      <VSpace />
      {propertyMapping != null && (
        <>
          Fil: {propertyMapping.fileName}
          <pre className="bg-light p-2">{propertyMapping.content}</pre>
        </>
      )}
    </>
  );
};

const JobParamsSettings: React.FC<{ jobParams: IBatchjob.IIFCExportJobParams; save: (value, key: string) => void }> = ({
  jobParams,
  save,
}) => {
  const [showAdvanced, setShowAdvanced] = React.useState<boolean>(false);
  const IFC_VERSIONS = [IfcVersionEnums.IFC2X3, IfcVersionEnums.IFC4];

  return (
    <div>
      <UncontrolledDropdown>
        <DropdownToggle color="secondary" style={{ minWidth: "200px" }} caret>
          {jobParams != null ? jobParams[RevitIfcParamsEnums.VersionClass] : "-"}
        </DropdownToggle>
        <DropdownMenu>
          {IFC_VERSIONS.map((ifcVersion, index) => (
            <DropdownItem key={index} onClick={() => save(ifcVersion, `jobParams.${RevitIfcParamsEnums.VersionClass}`)}>
              {ifcVersion}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
      <Button color="link" onClick={() => setShowAdvanced(!showAdvanced)}>
        {showAdvanced === false ? (
          <span>Vise avanserte innstillinger</span>
        ) : (
          <span>Skjul avanserte innstillinger</span>
        )}
      </Button>
      {showAdvanced === true && (
        <>
          {Object.keys(jobParams.Options)
            .filter(
              (settingKey) =>
                settingKey !== RevitIfcParamsEnums.ExportIFCCommonPropertySets &&
                settingKey !== RevitIfcParamsEnums.ExportUserDefinedParameterMapping,
            )
            .filter((settingKey) => typeof jobParams.Options[settingKey] === "boolean")
            .map((settingKey, index) => (
              <div key={index} className="mb-2">
                <SwitchWithTitle
                  title={settingKey}
                  onSave={(bool) => save(bool, `jobParams.Options.${settingKey}`)}
                  checked={jobParams.Options[settingKey]}
                />
              </div>
            ))}
        </>
      )}
    </div>
  );
};
