import Graphic from "@arcgis/core/Graphic";
import WebMap from "@arcgis/core/WebMap";
import WebScene from "@arcgis/core/WebScene";
import esriConfig from "@arcgis/core/config";
import Point from "@arcgis/core/geometry/Point";
import IdentityManager from "@arcgis/core/identity/IdentityManager";
import BuildingSceneLayer from "@arcgis/core/layers/BuildingSceneLayer.js";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import MapView from "@arcgis/core/views/MapView";
import SceneView from "@arcgis/core/views/SceneView";
import BasemapGallery from "@arcgis/core/widgets/BasemapGallery.js";
import BuildingExplorer from "@arcgis/core/widgets/BuildingExplorer.js";
import Expand from "@arcgis/core/widgets/Expand";
import LayerList from "@arcgis/core/widgets/LayerList";
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Alert, Button } from "reactstrap";
import { IArcgisMap, IGisCamera, IGisPoint, IGisPointAndCamera } from "../../../../../types/global.types";
import { HSpace } from "../../../components/";
import ConnectToGisDropdownSelector from "../../../components/ConnectToGisDropdownSelector/ConnectToGisDropdownSelector";
import { useAppSelector } from "../../../hooks";
import { IViewPoint } from "../../../types";
import { doGetEsriToken, doGetSingleArcgisGroup } from "./GisAPI";
import pinBlueIcon from "./marker_blue.png";
import pinRedIcon from "./marker_red.png";

let mapView: any;
let graphicsLayer: any;

const ArcGisMap: React.FC<{
  mapId: string;
  label: string;
  createItem?: (gisImage: string, gisObject: IGisPointAndCamera) => void;
  connectItem?: (gisImage: string, gisObject: IGisPointAndCamera) => void;
  viewPoints?: IViewPoint[];
  selectedVpId?: string;
  onDoubleClickItem?: (_id: string) => void;
  onSingleClickItem?: (vpId: string) => void;
  clickedGis?: IGisPointAndCamera;
  setclickedGis?: (gis: IGisPointAndCamera) => void;
  zoomToGisPoint?: IGisPoint;
  cameraPosition?: IGisCamera;
  onLoaded?: () => void;
}> = ({
  mapId,
  label,
  createItem,
  connectItem,
  viewPoints,
  onDoubleClickItem,
  onSingleClickItem,
  clickedGis,
  setclickedGis,
  zoomToGisPoint,
  cameraPosition,
  onLoaded,
  selectedVpId,
}) => {
  const [failed, setFailed] = useState<boolean>(false);
  const [basemapGalleryOpen, setBasemapGalleryOpen] = useState<boolean>(false);
  const project = useAppSelector((state) => state.adminReducer.project);

  useEffect(() => {
    if (mapId == null) {
      setFailed(true);
      return;
    }
    if (mapView != null && zoomToGisPoint != null) {
      if (mapView.type === "2d") {
        const point = new Point({
          x: zoomToGisPoint.x,
          y: zoomToGisPoint.y,
          z: zoomToGisPoint.z ?? 0,
          spatialReference: { wkid: zoomToGisPoint.wkid },
        });
        mapView.goTo(point);
      }
      if (mapView.type === "3d" && cameraPosition == null) {
        const point = new Point({
          x: zoomToGisPoint.x,
          y: zoomToGisPoint.y,
          z: zoomToGisPoint.z ?? 0,
          spatialReference: { wkid: zoomToGisPoint.wkid },
        });
        mapView.goTo(point);
      }
    }
  }, [zoomToGisPoint]);

  useEffect(() => {
    if (cameraPosition != null && mapView.type === "3d") {
      mapView.goTo(cameraPosition);
    }
  }, [cameraPosition]);

  useEffect(() => {
    fetchAndSetMap();
  }, [mapId]);

  useEffect(() => {
    if (clickedGis == null) {
      mapView?.graphics?.removeAll();
    }
  }, [clickedGis]);

  useEffect(() => {
    if (graphicsLayer != null) {
      graphicsLayer.removeAll();
    }
    drawItems();
  }, [viewPoints, graphicsLayer, selectedVpId]);

  const drawItems = () => {
    if (graphicsLayer != null) {
      viewPoints?.forEach((vp) => {
        const graphic = new Graphic({
          geometry: new Point({
            x: vp.gis.gisPoint?.x,
            y: vp.gis.gisPoint?.y,
            z: vp.gis.gisPoint?.z,
            spatialReference: { wkid: vp.gis.gisPoint?.wkid },
          }),
          symbol: getSymbol(vp._id) as any,
          attributes: {
            _id: vp._id,
            message: vp.title,
            status: vp.status,
          },
          popupTemplate: {
            title: "[title]",
            content: `
            <b>Status:</b> {status}<br>
            <b>ViewPoint ID:</b> {_id}<br>
          `,
          },
        });
        graphicsLayer.add(graphic);
      });
    }
  };

  // Legg til ulikt symbol basert på feltverdi
  const getSymbol = (vpId) => {
    const isSelectedVp = selectedVpId === vpId;
    return {
      type: "picture-marker", // autocasts as new PictureMarkerSymbol()
      url: isSelectedVp ? pinBlueIcon : pinRedIcon,
      width: isSelectedVp ? "40px" : "33px",
      height: isSelectedVp ? "53px" : "44px",
    };
  };

  const onClickCancel = () => {
    setclickedGis(null);
    mapView.graphics.removeAll();
    drawItems();
  };

  const fetchAndSetMap = async () => {
    try {
      const group = await doGetSingleArcgisGroup(project._id, project.arcgis.groupId);
      const _map = group.maps.find((_map) => _map.id === mapId);
      if (_map == null) {
        setFailed(true);
      } else {
        initMap(_map);
      }
    } catch (error) {
      console.log(error);
      setFailed(true);
    }
  };

  let graphicClickedPoint;
  let fetchedToken;

  const reqIntercept: __esri.BeforeInterceptorCallback = (ioArgs: any) => {
    if (ioArgs.url.indexOf("cloudgis.multiconsult.no") > -1) {
      ioArgs.requestOptions.query.token = fetchedToken;
    }
  };

  const initMap = (map: IArcgisMap) => {
    if (mapId) {
      doGetEsriToken(project._id)
        ?.then((token) => {
          IdentityManager.registerToken({
            server: "https://cloudgis.multiconsult.no/portal/sharing/",
            token,
            expires: new Date().getTime() + 3600 * 1000,
          });
          esriConfig.portalUrl = "https://cloudgis.multiconsult.no/portal/";
          fetchedToken = token;
          esriConfig.request.interceptors.push({ before: reqIntercept });

          // Check if we will create a map or a scene
          if (map.type === "Web Map") {
            const webmap = new WebMap({
              portalItem: {
                // autocasts as new PortalItem()
                id: map.id,
              },
            });
            mapView = new MapView({
              map: webmap,
              container: "mapContainer",
              popup: {
                dockEnabled: true,
                dockOptions: {
                  buttonEnabled: false,
                  breakpoint: false,
                  position: "top-left",
                },
              },
            });
          } else {
            const webmap = new WebScene({
              portalItem: {
                // autocasts as new PortalItem()
                id: map.id,
              },
            });

            mapView = new SceneView({
              map: webmap,
              container: "mapContainer",
            });
          }
          graphicsLayer = new GraphicsLayer({
            title: "Aktive saker",
          });
          drawItems();
          const layerList = new LayerList({
            view: mapView,
          });
          const expandLayerList = new Expand({
            expandIconClass: "esri-icon-layer-list",
            expandTooltip: "Kartlag",
            view: mapView,
            content: layerList,
            expanded: false,
            group: "widget",
          });

          mapView.ui.add(expandLayerList, "top-left");
          /* if (map.type !== "Web Map") {
          if (onLoaded != null) {
            onLoaded();
            }
            return;
            }
            
            */
          if (mapView.type === "3d") {
            const buildingExplorer = new BuildingExplorer({
              view: mapView,
              layers: [
                mapView.map.allLayers.filter((l) => l.type === "building-scene") as unknown as BuildingSceneLayer,
              ],
            });
            const bELayerList = new Expand({
              expandTooltip: "BIM-Explorer",
              view: mapView,
              content: buildingExplorer,
              expanded: false,
              group: "widget",
            });
            mapView.ui.add(bELayerList, "top-left");
          }
          mapView.when((mapView: MapView) => {
            if (onLoaded != null) {
              onLoaded();
            }

            mapView.map.add(graphicsLayer);
            mapView.on("click", (event) => {
              event.stopPropagation();
              const screenPoint = {
                x: event.x,
                y: event.y,
              };

              mapView.hitTest(screenPoint).then(function (response) {
                if (response.results.length > 0 && response.results[0].graphic?.attributes) {
                  const graphic = response.results[0].graphic;
                  if ("objectid" in graphic.attributes || graphic?.attributes?._id) {
                    onSingleClickItem(graphic.attributes._id);
                    mapView.popup.open({
                      features: [graphic],
                      location: event.mapPoint,
                    });
                  } else {
                    mapView.popup.close();
                  }
                } else {
                  mapView.popup.close();
                }
              });
            });

            mapView.on(["double-click"], (event) => {
              event.stopPropagation();
              var screenPoint = {
                x: event.x,
                y: event.y,
              };

              // Search for graphics at the clicked location
              mapView.hitTest(screenPoint).then(function (response) {
                // Check if we clicked on a pin
                if (response.results[0]?.graphic?.attributes?._id != null) {
                  let graphic = response.results[0].graphic;
                  if (graphic == null) {
                    // user clicked on previous point
                    setclickedGis(null);
                    drawItems();
                    return;
                  }

                  if (onDoubleClickItem != null) {
                    onDoubleClickItem(graphic.attributes._id);
                  }
                }

                // User clicked on terrain or some other layer
                if (response.results[0]?.graphic?.attributes?._id == null) {
                  console.log("no point found");
                  graphicsLayer.removeAll();
                  mapView.graphics.removeAll();

                  const symbol = {
                    type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
                    style: "circle",
                    color: "#f8992e",
                    size: "20px", // pixels
                    outline: {
                      // autocasts as new SimpleLineSymbol()
                      color: [88, 89, 91],
                      width: 3, // points
                    },
                  };

                  graphicClickedPoint = new Graphic({
                    geometry: event.mapPoint,
                    attributes: { type: "clickedPoint" },
                    symbol: symbol,
                  });
                  mapView.graphics.add(graphicClickedPoint);
                  const gis = formatGisObject({
                    x: event.mapPoint.x,
                    y: event.mapPoint.y,
                    z: event.mapPoint.z,
                    wkid: event.mapPoint.spatialReference.wkid,
                  });
                  setclickedGis(gis);
                }
              });
            });

            /*        if (gisPoint != null) {
            const point = new Point({
              x: gisPoint.x,
              y: gisPoint.y,
              spatialReference: { wkid: gisPoint.wkid },
              });
              mapView.goTo(point);
              }*/

            /*  // Add widgets
             // Layerlist
             var layerList = new LayerList({
              view: mapView,
              });
              const expandLayerList = new Expand({
                expandIconClass: "esri-icon-layer-list",
                expandTooltip: "Kartlag",
                view: mapView,
                content: layerList,
                expanded: false,
                group: "top-left",
                mode: "floating",
                });
                
                // Add Legend
                var legend = new Legend({
                  view: mapView,
                  });
                  const expandLegend = new Expand({
                    expandIconClass: "esri-icon-legend",
                    expandTooltip: "Tegnforklaring",
                    view: mapView,
                    content: legend,
                    expanded: false,
                    group: "top-left",
                    mode: "floating",
                    });
                    
                    mapView.ui.add(expandLayerList, {
                      position: "top-left",
                      });
                      mapView.ui.add(expandLegend, {
                        position: "top-left",
                        });*/
          });
        })
        .catch((error) => {
          console.log(error);
          setFailed(true);
        });
    }
  };

  const takeScreenShot = async () => {
    try {
      return await mapView.takeScreenshot({
        format: "jpg",
        quality: 100,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getGisCamera = () => {
    const isWebMap = mapView.camera == null;

    if (isWebMap) {
      return null;
    } else {
      const newCam = mapView?.camera?.clone();
      return newCam?.toJSON();
    }
  };

  const formatGisObject = (gisPoint: IGisPoint) => {
    const gis: IGisPointAndCamera = {
      gisPoint,
      gisCamera: getGisCamera(),
    };
    return gis;
  };

  const takeScreenAndCreateItem = async (gis: IGisPointAndCamera) => {
    const screenShot = await takeScreenShot();
    createItem(screenShot.dataUrl, gis);
  };

  const takeScreenshotAndConnectItem = async (gis: IGisPointAndCamera) => {
    const screenShot = await takeScreenShot();
    connectItem(screenShot.dataUrl, gis);
  };

  let basemapGallery = null;

  const onOpenBasemapGallery = (toggle: boolean) => {
    if (basemapGallery == null) {
      const container = document.createElement("div");
      container.style.position = "absolute";
      container.style.bottom = "30px";
      container.style.right = "-5px";

      basemapGallery = new BasemapGallery({
        view: mapView,
        container: container,
        id: "basemapGallery",
      });
    }

    if (toggle === true && !mapView.ui.find("basemapGallery")) {
      mapView.ui.add(basemapGallery, "bottom-right");
    }

    if (toggle === false && mapView.ui.find("basemapGallery")) {
      mapView.ui.remove("basemapGallery");
      basemapGallery.destroy();
      basemapGallery = null;
    }

    setBasemapGalleryOpen(toggle);
  };

  return (
    <div className="w-100 h-100">
      {failed === true && (
        <Alert color="danger">Kartet ble ikke funnet eller tilkobling til arcgis kunne ikke etableres</Alert>
      )}
      {failed !== true && (
        <div id="mapContainer" className="w-100 h-100" style={{ position: "relative" }}>
          <div
            className={classnames({ "bg-primary": clickedGis != null, "p-2": true })}
            style={{ borderRadius: "5px", position: "absolute", right: "5px", top: "5px" }}
          >
            {clickedGis == null && (
              <>
                <Alert color="success">
                  <b>Dobbeltklikk i kartet for å kople/registrere {label.toLowerCase()}</b>
                </Alert>
              </>
            )}

            {clickedGis != null && (
              <div className="d-flex">
                <ConnectToGisDropdownSelector
                  label={label}
                  onConnectExisting={() => takeScreenshotAndConnectItem(clickedGis)}
                  onCreateNew={() => takeScreenAndCreateItem(clickedGis)}
                />
                <HSpace />
                <Button
                  type="button"
                  onClick={() => {
                    onClickCancel();
                  }}
                >
                  Avbryt
                </Button>
              </div>
            )}
          </div>
          <Button
            onClick={() => onOpenBasemapGallery(!basemapGalleryOpen)}
            style={{ position: "absolute", right: "9px", bottom: "20px" }}
            className="primary text-white d-flex justify-content-center align-items-center"
          >
            Åpne galleri
          </Button>
        </div>
      )}
    </div>
  );
};

export default ArcGisMap;

export const NoGisGroup = () => {
  const project = useAppSelector((state) => state.adminReducer.project);

  const history = useHistory();

  const goToSettings = () => {
    history.replace(`/project/${project._id}/settings/arcgissettings`);
  };

  return (
    <Alert color="warning">
      <h5>Gå til innstillinger for å koble GIS till prosjektet og velge kart</h5>
      <Button className="p-0 m-0" color="link" onClick={() => goToSettings()}>
        Innstillninger
      </Button>
    </Alert>
  );
};

export const NoGisMap = () => {
  const project = useAppSelector((state) => state.adminReducer.project);

  const history = useHistory();

  const goToSettings = () => {
    history.replace(`/project/${project._id}/settings/arcgissettings`);
  };

  return (
    <Alert color="warning">
      <h5>Gå til innstillinger og velge default kart</h5>
      <Button className="p-0 m-0" color="link" onClick={() => goToSettings()}>
        Innstillninger
      </Button>
    </Alert>
  );
};
