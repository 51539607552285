import React, { useState } from "react";
import { Button, Modal, ModalHeader } from "reactstrap";
import { FailedAlert, HSpace, McInput, McTitle, VSpace } from "../../../../components";
import { useCreateMeetingSerieMutation } from "../meetingSerieRTK";
import { getProjectIdFromUrl } from "../../../utils";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "../../../../hooks";
import { setClickedMeetingSerieIdInRedux } from "../meetingSlice";

const NewMeetingSeriesModal: React.FC<{
  toggle: () => void;
}> = ({ toggle }) => {
  const [seriesStart, setSeriesStart] = useState<string>("");
  const [name, setName] = useState<string>("");

  const history = useHistory();
  const projectId = getProjectIdFromUrl(history.location.pathname);

  const dispatch = useAppDispatch();
  const [createMeetingSerie, { isError: createMeetingError }] = useCreateMeetingSerieMutation();

  const onCreate = async () => {
    const resp: any = await createMeetingSerie({
      projectId: projectId,
      name: name,
      seriesStart: seriesStart,
    });
    dispatch(setClickedMeetingSerieIdInRedux(resp.data._id));
    toggle();
  };

  return (
    <Modal isOpen={true} toggle={toggle}>
      <ModalHeader toggle={toggle}>Ny møteserie</ModalHeader>
      {createMeetingError === true && <FailedAlert />}
      <div className="border">
        <div className="m-4">
          <McTitle title="Velg startdato for møteserie">
            <McInput
              type="date"
              autoFocus
              onChange={(t) => setSeriesStart(t.target.value.toString() as any)}
              value={seriesStart as string}
              placeholder="Skriv inn dato for møteserie"
            />
          </McTitle>
          <VSpace />
          <McTitle title="Navn på møteserie">
            <McInput
              type="text"
              autoFocus
              onChange={(t) => setName(t.target.value.toString())}
              value={name as string}
              placeholder="Skriv inn navn for møteserie"
            />
          </McTitle>
          <VSpace />
          <div className="d-flex justify-content-end">
            <Button
              className="d-flex align-items-center"
              color="success"
              onClick={onCreate}
              disabled={seriesStart === ""}
            >
              <i className="fa fa-floppy-o" />
              <HSpace />
              Opprett
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default NewMeetingSeriesModal;
