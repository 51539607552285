import React from "react";
import { Button, UncontrolledTooltip } from "reactstrap";
import { exportExcel } from "../../app/utils";

const ExcelExport: React.FC<{ columns: any[]; data: any[]; filePrefix: string; isAgGrid?: boolean }> = ({
  columns,
  data,
  filePrefix,
  isAgGrid = false,
}) => {
  return (
    <div>
      <UncontrolledTooltip placement="top" target="excel">
        Eksporter til Excel
      </UncontrolledTooltip>
      <Button id="excel" onClick={() => exportExcel(columns, data, filePrefix, [], isAgGrid)}>
        <i className="fa fa-file-excel-o" />
      </Button>
    </div>
  );
};

export default ExcelExport;
