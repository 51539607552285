import React from "react";
import { IIssueSumHours } from "mc-shared/zod/issueSchema";
import { Card, CardBody } from "reactstrap";
import { StatsBox } from "../../app/routes/risiko/Dashboard/StatsBox";
import { IStatbox } from "../../types";
import { VSpace } from "../index";

const TimeSum: React.FC<{ sumHours: IIssueSumHours }> = ({ sumHours }) => {
  const statsboxes: IStatbox[] = [
    {
      icon: "fa-clock-o",
      value: sumHours?.totalOrignalEstimate || 0,
      text: "Timer estimert",
      classColor: "text-succss",
    },
    {
      icon: "fa-clock-o",
      value: sumHours?.totalCompleted || 0,
      text: "Brukte timer",
      classColor: "text-succss",
    },
    {
      icon: "fa-clock-o",
      value: sumHours?.totalRemaining || 0,
      text: "Gjenstående timer",
      classColor: "text-succss",
    },
  ];

  return (
    <Card>
      <CardBody>
        {statsboxes.map((stat, index) => (
          <div key={index}>
            <StatsBox key={index} stat={stat} />
            <VSpace />
          </div>
        ))}
      </CardBody>
    </Card>
  );
};

export default TimeSum;
