import Axios from "axios";
import saveAs from "file-saver";
import fileDownload from "js-file-download";
import {
  IBasicItem,
  IColumnTemplate,
  IComment,
  IDocxTemplate,
  IGisPointAndCamera,
  IHistoryLogDepricated,
  IIssue,
  IRisiko,
  IRisikoEvaluationCategory,
  IRisikoEvaluationCategorySelection,
  IRiskoBoard,
  IScoreColor,
  ITag,
} from "../../../../../types/global.types";
import { IDocxItemIndata } from "mc-shared/zod/commonSchema";

export const doUpdateRisikBoardName = async (
  projectId: string,
  risikoBoardId: string,
  name: string,
): Promise<IRiskoBoard> => {
  try {
    const fetch = await Axios.put(`/api/project/${projectId}/risikoboard/${risikoBoardId}/name`, { name: name });
    return fetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doUpdateRisikBoardDisabledColumns = async (
  projectId: string,
  risikoBoardId: string,
  disabledColumns: string[],
): Promise<IRiskoBoard> => {
  try {
    const fetch = await Axios.put(`/api/project/${projectId}/risikoboard/${risikoBoardId}/disabledcolumns`, {
      disabledColumns,
    });
    return fetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doCreateRisikoBoard = async (
  projectId: string,
  name: string,
  probabilitySelections: IRisikoEvaluationCategorySelection[],
  templateId: string,
  isATemplate: boolean,
): Promise<IRiskoBoard> => {
  try {
    const fetch = await Axios.post(`/api/project/${projectId}/risikoboard`, {
      name,
      probabilitySelections,
      templateId,
      isATemplate,
    });
    return fetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetRisikoBoards = async (projectId: string): Promise<IRiskoBoard[]> => {
  try {
    const logFetch = await Axios.get(`/api/project/${projectId}/risikoboard`);
    return logFetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetSingleRisikoBoard = async (projectId: string, risikoBoardId: string): Promise<IRiskoBoard> => {
  try {
    const fetch = await Axios.get(`/api/project/${projectId}/risikoboard/${risikoBoardId}`);
    return fetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doCreateRisiko = async (
  projectId: string,
  risikoBoardId: string,
  title: string,
  gis?: IGisPointAndCamera,
  gisImage?: string,
): Promise<IRisiko> => {
  try {
    const fetch = await Axios.post(`/api/project/${projectId}/risiko/board/${risikoBoardId}`, { title, gis, gisImage });
    return fetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doUpdateRisiko = async (
  projectId: string,
  risikoId: string,
  attr: string,
  value: any,
  oldValue: string,
  evaluationId?: string,
  gisImage?: string,
): Promise<IRisiko> => {
  try {
    const fetch = await Axios.put(`/api/project/${projectId}/risiko/item/${risikoId}`, {
      attr,
      value,
      oldValue,
      evaluationId,
      gisImage,
    });
    return fetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetRisikoEvaluationCategories = async (
  projectId: string,
  risikoBoardId: string,
): Promise<IRisikoEvaluationCategory[]> => {
  try {
    const fetch = await Axios.get(`/api/project/${projectId}/risiko/board/${risikoBoardId}/evaluationcategories`);
    return fetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doCreateRisikoEvaluationCategory = async (
  projectId: string,
  risikoBoardId: string,
  evaluationCategory: IRisikoEvaluationCategory,
) => {
  try {
    const createdRisiko = await Axios.post(
      `/api/project/${projectId}/risiko/board/${risikoBoardId}/evaluationcategory`,
      { evaluationCategory },
    );
    return createdRisiko;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doUpdateRisikoEvaluationCategory = async (
  projectId: string,
  evaluationCategory: IRisikoEvaluationCategory,
) => {
  try {
    const createdRisiko = await Axios.put(
      `/api/project/${projectId}/risiko/evaluationcategory/${evaluationCategory._id}`,
      { evaluationCategory },
    );
    return createdRisiko;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doDeleteRisikoEvaluationCategory = async (
  projectId: string,
  evaluationCategory: IRisikoEvaluationCategory,
) => {
  try {
    await Axios.delete(`/api/project/${projectId}/risiko/evaluationcategory/${evaluationCategory._id}`);
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doUpdateScoreColors = async (
  projectId: string,
  evaluationcategoryId: string,
  scoreColors: IScoreColor,
) => {
  try {
    const createdScoreColor = await Axios.put(
      `/api/project/${projectId}/risiko/evaluationcategory/${evaluationcategoryId}/scoreColors`,
      { scoreColors },
    );
    return createdScoreColor;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetRisikoLogs = async (projectId: string, risikoId: string): Promise<IHistoryLogDepricated[]> => {
  try {
    const logFetch = await Axios.get(`/api/project/${projectId}/risiko/item/${risikoId}/log`);
    return logFetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doAddRisikoStateLog = async (projectId: string, risikoId: string, text: string): Promise<IComment[]> => {
  try {
    const createRisikoStateLog = await Axios.post(`/api/project/${projectId}/risiko/item/${risikoId}/risikostatelog/`, {
      text,
    });
    return createRisikoStateLog.data.statelogs;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetSingleRisiko = async (projectId: string, risikoId: string): Promise<IRisiko> => {
  try {
    if (risikoId == null) {
      return null;
    }
    const fetch = await Axios.get(`/api/project/${projectId}/risiko/item/${risikoId}`);
    return fetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetRisikos = async (projectId: string, risikoBoardId: string): Promise<IRisiko[]> => {
  try {
    const risikoFetch = await Axios.get(`/api/project/${projectId}/risiko/board/${risikoBoardId}`);
    return risikoFetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetLatestStatelogs = async (projectId: string, risikoBoardId: string) => {
  try {
    const statelogsFetch = await Axios.get(`/api/project/${projectId}/risiko/board/${risikoBoardId}/statelogs/latest`);
    return statelogsFetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doDeleteRisikoStatelog = async (
  projectId: string,
  risikoId: string,
  statelogId: string,
): Promise<IComment[]> => {
  try {
    const updateRisikoFetch = await Axios.delete(
      `/api/project/${projectId}/risiko/item/${risikoId}/risikostatelog/${statelogId}`,
    );
    return updateRisikoFetch.data.statelogs;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doUpdateRisikoStatelogText = async (
  projectId: string,
  risikoId: string,
  statelogId: string,
  text: string,
): Promise<IComment[]> => {
  try {
    const q = await Axios.put(`/api/project/${projectId}/risiko/item/${risikoId}/risikostatelog/${statelogId}`, {
      text,
    });
    return q.data.statelogs;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doDeleteRisiko = async (projectId: string, risikoId: string) => {
  try {
    await Axios.put(`/api/project/${projectId}/risiko/item/${risikoId}/delete`);
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doUpdateRisikoTags = async (projectId: string, risikoId: string, tags: string[]): Promise<IRisiko> => {
  try {
    const updatedRisikoFetch = await Axios.put(`/api/project/${projectId}/risiko/item/${risikoId}/tags`, { tags });
    return updatedRisikoFetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doUpdateRisikoPnsCodes = async (
  projectId: string,
  risikoId: string,
  pnsCodes: string[],
): Promise<IRisiko> => {
  try {
    const updatedRisikoFetch = await Axios.put(`/api/project/${projectId}/risiko/item/${risikoId}/pnscodes`, {
      pnsCodes,
    });
    return updatedRisikoFetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doFetchUniqRisikoTags = async (projectId: string, risikoBoardId: string): Promise<ITag[]> => {
  try {
    const fetch = await Axios.get(`/api/project/${projectId}/risiko/board/${risikoBoardId}/tags`);
    return fetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetRisikoColumnTemplates = async (projectId: string): Promise<IColumnTemplate[]> => {
  try {
    const resp = await Axios.get(`/api/project/${projectId}/risikoColumnTemplate`);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doAddRisikoColumnTemplate = async (projectId: string, risikoColumnTemplate: IColumnTemplate) => {
  try {
    const resp = await Axios.post(`/api/project/${projectId}/risikoColumnTemplate`, risikoColumnTemplate);
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doUpdateProjectRisikoColumnTemplate = async (projectId: string, risikoColumnTemplate: IColumnTemplate) => {
  try {
    const resp = await Axios.put(
      `/api/project/${projectId}/risikoColumnTemplate/${risikoColumnTemplate._id}`,
      risikoColumnTemplate,
    );
    return resp.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doDeleteRisikoColumnTemplate = async (projectId: string, risikoTemplateId: string) => {
  try {
    await Axios.delete(`/api/project/${projectId}/risikoColumnTemplate/${risikoTemplateId}`);
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doRisikoFileUpload = async (event, projectId: string, risikoId: string) => {
  try {
    const data = new FormData();
    data.append("fileToUpload", event);
    await Axios.request({
      method: "POST",
      url: `/api/project/${projectId}/risiko/item/${risikoId}/upload`,
      data,
      headers: {
        "content-type": `multipart/form-data`,
      },
    });
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doDownloadRisikoFile = async (projectId: string, fileId: string, risikoId: string, filename: string) => {
  try {
    const response = await Axios({
      url: `/api/project/${projectId}/risiko/item/${risikoId}/file/${fileId}`,
      method: "GET",
      responseType: "blob", // Important
    });
    fileDownload(response.data, filename);
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doDeleteRisikoFile = async (projectId: string, risikoId: string, fileId: string) => {
  try {
    const response = await Axios.delete(`/api/project/${projectId}/risiko/item/${risikoId}/file/${fileId}`);
    return response.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetRisikosStartinWithPns = async (projectId: string, pns: string): Promise<IRisiko[]> => {
  try {
    const fetch = await Axios.get(`/api/project/${projectId}/risiko/start-with-pns/${pns}`);
    return fetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doSetRisikoRemoveStatus = async (
  projectId: string,
  risikoBoardId: string,
  removed: boolean,
): Promise<void> => {
  try {
    await Axios.put(`/api/project/${projectId}/risikoboard/${risikoBoardId}/remove`, { removed });
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetBasicRisikoInfo = async (projectId: string, boardId: string): Promise<IBasicItem[]> => {
  try {
    const fetch = await Axios.get(`/api/project/${projectId}/risiko/board/${boardId}/basic`);
    return fetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGetRisikoDocxTemplates = async (projectId: string): Promise<IDocxTemplate[]> => {
  try {
    const fetch = await Axios.get(`/api/project/${projectId}/risiko/docxtemplates`);
    return fetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doDownloadRisikoDocxTemplate = async (projectId: string, docxTemplate: IDocxTemplate) => {
  try {
    const response = await Axios({
      url: `/api/project/${projectId}/risiko/download-docx-template`,
      method: "PUT",
      responseType: "blob",
      data: { docxTemplate },
    });
    fileDownload(response.data, docxTemplate.fileName);
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doGenerateRisikoDocx = async (projectId: string, docxTemplate: IDocxTemplate, data?: IDocxItemIndata) => {
  try {
    const file = await Axios.put(
      `/api/project/${projectId}/risiko/generate-docx`,
      { data, docxTemplate },
      {
        responseType: "arraybuffer",
      },
    );
    const blob = new Blob([file.data], { type: "application/x-msdownload" });
    saveAs(blob, `${docxTemplate.fileName}`);
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doUploadRisikoDocxTemplate = async (projectId: string, event: any) => {
  try {
    const data = new FormData();
    data.append("fileToUpload", event.target.files[0]);
    await Axios.request({
      method: "POST",
      url: `/api/project/${projectId}/risiko-docx-template`,
      data,
      headers: {
        "content-type": `multipart/form-data`,
      },
    });
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doDeleteRisikoDocxTemplate = async (projectId: string, docxTemplate: IDocxTemplate): Promise<object> => {
  try {
    const fetch = await Axios.delete(`/api/project/${projectId}/risiko-docx-template`, { data: { docxTemplate } });
    return fetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

export const doConnectOrDisconnectIssueFromRisiko = async (
  projectId: string,
  risikoBoardId: string,
  riskoId: string,
  issueId: string,
  connectOrDisconnect: "CONNECT" | "DISCONNECT",
): Promise<IIssue[]> => {
  try {
    const fetch = await Axios.put(
      `/api/project/${projectId}/risiko/board/${risikoBoardId}/risko/${riskoId}/connect-disconnect-issue/${issueId}`,
      { connectOrDisconnect },
    );
    return fetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};
