import React, { useState } from "react";
import McInput from "../McInput/McInput";
import VSpace from "../VSpace";
import EditSaveAbort from "../EditSaveAbort";
import { Card, CardBody, CardTitle } from "reactstrap";
import McTitle from "../McTitle/McTitle";
import { IUser } from "../../../../types/global.types";
import { useAppDispatch } from "../../hooks";
import { useUpdateUserNameMutation } from "../../globalRTK";
import { setShowSuccessMessageThunk } from "../../global/globalSlice";

const UpdateUserNameCard: React.FC<{ user: IUser }> = ({ user }) => {
  const [userName, setUserName] = useState<string>(user.name);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [updateUserName] = useUpdateUserNameMutation();
  const dispatch = useAppDispatch();

  const doUpdateUserName = async () => {
    await updateUserName({ userName: userName });
    dispatch(setShowSuccessMessageThunk("Navn oppdatert"));
    setEditMode(false);
  };

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h3">Innstillinger</CardTitle>
        <McTitle title="Navn" className="mr-2" style={{ width: "300px" }}>
          <div>
            <span>
              Nåværende: <b>{user.name}</b>{" "}
            </span>
            <VSpace />
            {editMode && (
              <>
                <McInput
                  name="Navn"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  placeholder="Navn"
                />
                <VSpace />
              </>
            )}
          </div>
        </McTitle>
        <VSpace />
        <EditSaveAbort
          disabled={userName === ""}
          editable
          big
          saveCb={doUpdateUserName}
          editMode={editMode}
          editModeCb={(bool) => setEditMode(bool)}
        />
      </CardBody>
    </Card>
  );
};

export default UpdateUserNameCard;
