import { IGKRapport, IGKTestAvSkapreguleringensResponstidResultater } from "mc-shared/zod/report/GK/gkReportSchema";
import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../../types/global.types";
import { McInput } from "../../../../../../components";

const TestAvSkapreguleringsResponstid: React.FC<{
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
  data: IGKTestAvSkapreguleringensResponstidResultater;
}> = ({ data, onUpdateEditData }) => {
  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Test av skapreguleringens responstid</CardTitle>
        <div className="d-flex flex-column mt-4">
          <b>Målt trykkfall før test &#40;Pa&#41;:</b>
          <McInput
            value={data.inndata.trykkfallFoerTest}
            normalizeNumbers={true}
            type="text"
            onBlur={(e) =>
              onUpdateEditData(
                "testResultater.testAvSkapreguleringensResponstid.inndata.trykkfallFoerTest",
                Number(e.target.value),
              )
            }
            className="w-25"
          />
        </div>
        <div className="d-flex flex-column mt-4">
          <b>Målt trykk etter test &#40;Pa&#41;:</b>
          <McInput
            value={data.inndata.trykkfallEtterTest}
            normalizeNumbers={true}
            type="text"
            onBlur={(e) =>
              onUpdateEditData(
                "testResultater.testAvSkapreguleringensResponstid.inndata.trykkfallEtterTest",
                Number(e.target.value),
              )
            }
            className="w-25"
          />
        </div>
        <div className="d-flex flex-column mt-4">
          <b>Målt tid før trykkfall har returnert &#40;s&#41;:</b>
          <McInput
            value={data.inndata.tidFoerTrykkfallHarRetunert}
            normalizeNumbers={true}
            type="text"
            onBlur={(e) =>
              onUpdateEditData(
                "testResultater.testAvSkapreguleringensResponstid.inndata.tidFoerTrykkfallHarRetunert",
                Number(e.target.value),
              )
            }
            className="w-25"
          />
        </div>
        <div className="w-50 mt-4">
          <b>Kommentar:</b>
          <McInput
            value={data.inndata.kommentar}
            onChange={(e) =>
              onUpdateEditData("testResultater.testAvSkapreguleringensResponstid.inndata.kommentar", e.target.value)
            }
            type="textarea"
            dynamicHeight={true}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default TestAvSkapreguleringsResponstid;
