import { IAllFilter } from "mc-shared/zod/commonSchema";
import { IExternalParticipant, IMeetingAgendaByMailPostBody } from "mc-shared/zod/meetingSchema";
import { IMeeting } from "../../../../../types/meeting.types";
import { apiSlice } from "../../../apiSlice";
import { ToastMessagesEnums } from "../../../frontendConstants";
import { setMeetingAllFiltersInRedux } from "./meetingSlice";
import { setShowSuccessMessageThunk } from "../../../global/globalSlice";

export const meetingRTK = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMeetingInstances: builder.query<
      { meetings: IMeeting.Meeting[]; allFilters: IAllFilter[] },
      { projectId: string; allFilters: IAllFilter[] }
    >({
      query: (input) => ({
        url: `/project/${input.projectId}/meeting`,
        method: "POST",
        body: { allFilters: input.allFilters },
      }),
      async onQueryStarted({}, { dispatch, queryFulfilled, requestId, extra, getState }) {
        const resp = await queryFulfilled;
        dispatch(setMeetingAllFiltersInRedux(resp.data.allFilters));
      },
      providesTags: ["Meeting"],
    }),
    getSingleMeetingInstance: builder.query<IMeeting.MeetingPopulated, { projectId: string; meetingId: string }>({
      query: (input) => ({
        url: `/project/${input.projectId}/meeting/${input.meetingId}`,
        method: "GET",
      }),
      providesTags: ["Meeting"],
    }),
    updateMeeting: builder.mutation<
      IMeeting.Meeting,
      { projectId: string; meetingId: string; attribute: string; value: string | boolean | Date }
    >({
      query: (input) => ({
        url: `/project/${input.projectId}/meeting/${input.meetingId}/attribute`,
        method: "PUT",
        body: {
          attribute: input.attribute,
          value: input.value,
        },
      }),
      async onQueryStarted({ meetingId }, { dispatch, queryFulfilled, getState }) {
        dispatch(setShowSuccessMessageThunk("Møtet er oppdatert"));
      },
      invalidatesTags: ["Meeting"],
    }),
    getMeetingInstancesWhereUserIsAParticipant: builder.query<
      IMeeting.Meeting[],
      { userId: string; projectId: string }
    >({
      query: (input) => ({
        url: `/project/${input.projectId}/meeting/participant/${input.userId}`,
        method: "GET",
      }),
      providesTags: ["Meeting"],
    }),
    deleteMeeting: builder.mutation<IMeeting.Meeting, { projectId: string; meetingId: string }>({
      query(input) {
        return {
          url: `/project/${input.projectId}/meeting/${input.meetingId}/`,
          method: "PUT",
        };
      },
      async onQueryStarted({ meetingId }, { dispatch, queryFulfilled, getState }) {
        dispatch(setShowSuccessMessageThunk("Møtet er slettet"));
      },
      invalidatesTags: ["Meeting"],
    }),
    createMeetingInstance: builder.mutation<IMeeting.Meeting, { projectId: string; startDate: Date; title: string }>({
      query(input) {
        return {
          url: `/project/${input.projectId}/meeting/new-single-meeting`,
          method: "POST",
          body: {
            startDate: input.startDate,
            title: input.title,
          },
        };
      },
      async onQueryStarted({}, { dispatch, queryFulfilled, getState }) {
        dispatch(setShowSuccessMessageThunk("Møtet er lagret"));
      },
      invalidatesTags: ["Meeting"],
    }),
    copyMeetingInstance: builder.mutation<
      IMeeting.Meeting,
      { projectId: string; meetingSerieId: string; dateOfMeeting: Date; copyAgenda: boolean }
    >({
      query(input) {
        return {
          url: `/project/${input.projectId}/meeting/new-meeting-in-series`,
          method: "PUT",
          body: {
            dateOfMeeting: input.dateOfMeeting,
            copyAgenda: input.copyAgenda,
            meetingSerieId: input.meetingSerieId,
          },
        };
      },
      async onQueryStarted({}, { dispatch, queryFulfilled, getState }) {
        dispatch(setShowSuccessMessageThunk("Møtet er kopiert"));
      },
      invalidatesTags: ["Meeting"],
    }),
    sendMeetingAgendaByMail: builder.mutation<
      string,
      {
        body: IMeetingAgendaByMailPostBody;
        params: { projectId: string; meetingId: string };
      }
    >({
      query: (input) => ({
        url: `/project/${input.params.projectId}/meeting/${input.params.meetingId}/sendagenda`,
        method: "PUT",
        body: input.body,
      }),
      async onQueryStarted({}, { dispatch, queryFulfilled, getState }) {
        dispatch(setShowSuccessMessageThunk("Agenda er sendt"));
      },
      invalidatesTags: ["Meeting"],
    }),
    addMeetingPartiipant: builder.mutation<
      IMeeting.Meeting,
      {
        projectId: string;
        meetingId: string;
        userId: string;
      }
    >({
      query: (input) => ({
        url: `/project/${input.projectId}/meeting/${input.meetingId}/participant/${input.userId}`,
        method: "POST",
      }),
      async onQueryStarted({}, { dispatch, queryFulfilled, getState }) {
        dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.UPDATED));
      },
      invalidatesTags: ["Meeting"],
    }),
    updateMeetingParticipant: builder.mutation<
      IMeeting.Meeting,
      {
        projectId: string;
        meetingId: string;
        participantId: string;
        attribute: string;
        value: string | boolean | Date;
      }
    >({
      query: (input) => ({
        url: `/project/${input.projectId}/meeting/${input.meetingId}/participant/${input.participantId}`,
        method: "PUT",
        body: {
          attribute: input.attribute,
          value: input.value,
        },
      }),
      async onQueryStarted({}, { dispatch, queryFulfilled, getState }) {
        dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.UPDATED));
      },
      invalidatesTags: ["Meeting"],
    }),
    removeMeetingParticipant: builder.mutation<
      IMeeting.Meeting,
      {
        projectId: string;
        meetingId: string;
        participantId: string;
      }
    >({
      query: (input) => ({
        url: `/project/${input.projectId}/meeting/${input.meetingId}/participant/${input.participantId}`,
        method: "DELETE",
      }),
      async onQueryStarted({}, { dispatch, queryFulfilled, getState }) {
        dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.UPDATED));
      },
      invalidatesTags: ["Meeting"],
    }),
    updateExternalParticipants: builder.mutation<
      IMeeting.Meeting,
      {
        projectId: string;
        meetingId: string;
        externalParticipants: IExternalParticipant[];
      }
    >({
      query: (input) => ({
        url: `/project/${input.projectId}/meeting/${input.meetingId}/externalParticipants`,
        body: input.externalParticipants,
        method: "POST",
      }),
      async onQueryStarted({}, { dispatch, queryFulfilled, getState }) {
        dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.UPDATED));
      },
      invalidatesTags: ["Meeting"],
    }),
    updateAllAgendaIds: builder.mutation<
      IMeeting.Meeting,
      {
        projectId: string;
        meetingId: string;
      }
    >({
      query(input) {
        return {
          url: `/project/${input.projectId}/meeting/${input.meetingId}/updateAgendaIds`,
          method: "PUT",
        };
      },
      async onQueryStarted({}, { dispatch, queryFulfilled, getState }) {
        dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.UPDATED));
      },
      invalidatesTags: ["Meeting"],
    }),
  }),
});

export const {
  useGetMeetingInstancesQuery,
  useGetSingleMeetingInstanceQuery,
  useUpdateMeetingMutation,
  useGetMeetingInstancesWhereUserIsAParticipantQuery,
  useCreateMeetingInstanceMutation,
  useCopyMeetingInstanceMutation,
  useSendMeetingAgendaByMailMutation,
  useAddMeetingPartiipantMutation,
  useUpdateMeetingParticipantMutation,
  useRemoveMeetingParticipantMutation,
  useUpdateExternalParticipantsMutation,
  useUpdateAllAgendaIdsMutation,
} = meetingRTK;
