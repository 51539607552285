import moment from "moment";
import React, { useState } from "react";
import { Button, UncontrolledTooltip } from "reactstrap";
import { Checkbox, TaskCompletedCount } from "..";
import { ITodo } from "../../../../types/todo.types";
import "./todolist.css";

const TodoList: React.FC<{
  todos: ITodo.TodoPopulated[];
  showComplete?: boolean;
  updateTodo: (_id: string, value: string) => void;
  addNewTodo: (text: string) => void;
  deleteTodo: (todoId: string) => void;
  toggleTodo: (todoId: string, isChecked: boolean) => void;
}> = ({ todos, updateTodo, addNewTodo, deleteTodo, toggleTodo, showComplete = true }) => {
  return (
    <div>
      {showComplete === true && todos != null && (
        <TaskCompletedCount
          taskCompleteCount={{
            totalNr: todos.length,
            completedCount: todos.filter((obj) => obj.completedDate !== null).length,
            showWarning: false,
            darkText: true,
          }}
        />
      )}
      {todos?.map((todo) => (
        <TodoItem key={todo._id} todo={todo} updateTodo={updateTodo} deleteTodo={deleteTodo} toggleTodo={toggleTodo} />
      ))}
      <AddTodoItem addTodo={addNewTodo} />
    </div>
  );
};

const TodoItem: React.FC<{
  todo: ITodo.TodoPopulated;
  updateTodo: (_id: string, value: any) => void;
  deleteTodo: (todoId: string) => void;
  toggleTodo: (todoId: string, isChecked: boolean) => void;
}> = ({ todo, updateTodo, deleteTodo, toggleTodo }) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [value, setValue] = useState<string>(todo.text);
  const [isHovered, setIsHovered] = useState(false);

  const blurInput = (e: React.FocusEvent<HTMLInputElement>) => {
    saveChange();
  };

  const blurOnEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      saveChange();
      return;
    }

    if (e.key === "Escape") {
      e.stopPropagation();
      setValue(todo.text);
      setEditMode(false);
    }
  };

  const stopPropagationOnEscape = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Escape") {
      e.stopPropagation();
    }
  };

  const saveChange = () => {
    if (value === "" || value === todo.text) {
      setValue(todo.text);
    } else {
      updateTodo(todo._id, value);
    }

    setEditMode(false);
  };

  return (
    <div
      className="d-flex align-items-center todo-item border-radius"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {todo.completedDate != null && (
        <UncontrolledTooltip target={`check${todo._id}`}>
          {`${todo.completedBy?.name}`}
          <br />
          {moment(todo.completedDate).format("YYYY.MM.DD")}
        </UncontrolledTooltip>
      )}
      <div id={`check${todo._id}`}>
        <Checkbox
          isChecked={todo.completedDate != null}
          setChecked={() => toggleTodo(todo._id, todo.completedDate != null)}
          disabled={false}
          color="#f8992e"
        />
      </div>

      {editMode === false ? (
        <div
          className="todo-value ml-2"
          style={{ textDecoration: `${todo.completedDate != null ? "line-through" : ""}` }}
          onClick={() => setEditMode(true)}
        >
          {value}
        </div>
      ) : (
        <input
          className="todo-input w-100 ml-2"
          autoFocus={true}
          type="text"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={blurOnEnter}
          onKeyUp={stopPropagationOnEscape}
          onBlur={blurInput}
          placeholder={todo.text}
        />
      )}
      <Button
        size="sm"
        color="outline-danger"
        onClick={() => deleteTodo(todo._id)}
        style={{ opacity: `${isHovered ? 1 : 0}` }}
      >
        <i className="fa fa-trash fa-fw" />
      </Button>
    </div>
  );
};

const AddTodoItem: React.FC<{
  addTodo: (value: string) => void;
}> = ({ addTodo }) => {
  const [value, setValue] = useState<string>("");

  const blurInput = (e: React.FocusEvent<HTMLInputElement>) => {
    saveChange();
  };

  const blurOnEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      saveChange();
      return;
    }

    if (e.key === "Escape") {
      e.stopPropagation();
      setValue("");
    }
  };

  const stopPropagationOnEscape = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Escape") {
      e.stopPropagation();
    }
  };

  const saveChange = () => {
    if (value !== "") {
      addTodo(value);
      setValue("");
    } else {
    }
  };

  return (
    <div className="d-flex align-items-center todo-item border-radius">
      <Checkbox isChecked={false} setChecked={() => {}} disabled={false} color="#f8992e" />

      <input
        className="todo-input w-100 ml-2"
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={blurOnEnter}
        onKeyUp={stopPropagationOnEscape}
        onBlur={blurInput}
        placeholder="Legg til punkt"
      />
    </div>
  );
};

export default TodoList;
