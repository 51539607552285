import React from "react";

import { Card, CardBody, CardTitle } from "reactstrap";

import { SwitchWithTitle, VSpace } from "../../../../components";

const GeoteknikSettings: React.FC<{
  veiledningsTekst: boolean;
  eksempelTekst: boolean;
  updateVeiledningsTekst: (boolean: boolean) => void;
  updateEksempelTekst: (bool: boolean) => void;
}> = ({ veiledningsTekst, eksempelTekst, updateVeiledningsTekst, updateEksempelTekst }) => {
  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Innstillinger</CardTitle>
        <SwitchWithTitle
          title={"Veiledningstekst"}
          onSave={() => updateVeiledningsTekst(!veiledningsTekst)}
          checked={veiledningsTekst}
        />
        <VSpace />
        <SwitchWithTitle
          title={"Eksempeltekst"}
          onSave={() => updateEksempelTekst(!eksempelTekst)}
          checked={eksempelTekst}
        />
      </CardBody>
    </Card>
  );
};

export default GeoteknikSettings;
