import "@fullcalendar/react/dist/vdom";
import { Provider } from "react-redux";
import { HashRouter, Route } from "react-router-dom";
import ErrorBoundary from "./util/ErrorBoundary";
import { store } from "./store";

import App from "./app/App";

const MainApp = () => {
  return (
    <Provider store={store}>
      <ErrorBoundary isGlobal={true}>
        <HashRouter>
          <Route exact path="/">
            <App />
          </Route>
          <Route exact path="">
            <App />
          </Route>
        </HashRouter>
      </ErrorBoundary>
    </Provider>
  );
};
export default MainApp;
