import Highcharts, { Options } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useState } from "react";
import { Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";
import { Checkbox, McInput, McTypeahead } from "../../../components";
import { useGetAllProjectsForStatsPageQuery, useGetWeeklyStatsQuery } from "./statsRTK";

interface IChartPoint {
  x: number;
  y: number;
}

export interface IStat {
  logDate: Date;
  count: number;
}

const WeeklyUserChart = () => {
  const [fromDate, setFromDate] = useState<Date>(null);
  const [toDate, setToDate] = useState<Date>(new Date());
  const [projectNo, setProjectNo] = useState<number>(null);
  const [toggleUniqueUsers, setToggleUniqueUsers] = useState<boolean>(true);

  const { data: res } = useGetWeeklyStatsQuery({
    fromDate: fromDate?.toISOString(),
    toDate: toDate?.toISOString(),
    projectNo: projectNo,
    toggleUniqueUsers: toggleUniqueUsers === true ? "YES" : "NO",
  });

  const { data: projects } = useGetAllProjectsForStatsPageQuery("");

  const totalUsers: IChartPoint[] = res?.map((logItem) => {
    return {
      x: new Date(logItem.logDate).getTime(),
      y: logItem.count,
    };
  });

  const highcartSeries: any[] = [
    {
      name: toggleUniqueUsers === false ? "Total appstarter" : "Total unike brukere",
      data: totalUsers,
      pointInterval: 24 * 3600 * 1000 * 7, // one week,
      color: "orange",
    },
  ];

  const options: Options = {
    accessibility: { enabled: false }, //to remove warning
    title: {
      text: toggleUniqueUsers === false ? "Appstarter hver uke" : "Unike brukere hver uke",
    },
    chart: {
      type: "area",
    },
    subtitle: {
      text: "",
    },
    yAxis: {
      title: {
        text: toggleUniqueUsers === false ? "Antall bruker" : " Antall appstarter ",
      },
    },
    xAxis: {
      type: "datetime", // Show dates
      labels: {
        autoRotation: [-90],
        format: "{value:%d-%m-%Y}", // Format for the weeks
      },
      tickInterval: 7 * 24 * 3600 * 1000,
    },
    plotOptions: {
      column: {
        pointWidth: 15, // Width of the column
        pointPlacement: "on",
      },
    },
    series: highcartSeries || [],
  };

  return (
    <Card className="m-3">
      <div className="d-flex m-2">
        <div className="d-flex flex-column mr-4" style={{ maxWidth: "15rem", width: "100%" }}>
          <h5 className="truncateHeaderText mb-0">Fra</h5>
          <McInput
            type="date"
            onChange={(e) => setFromDate(e.target.value !== "" ? new Date(e.target.value) : null)}
            value={fromDate != null ? fromDate.toISOString() : null}
          />
        </div>
        <div className="d-flex flex-column mr-4" style={{ maxWidth: "15rem", width: "100%" }}>
          <h5 className="truncateHeaderText mb-0">Til</h5>
          <McInput
            type="date"
            onChange={(e) => setToDate(e.target.value !== "" ? new Date(e.target.value) : new Date())}
            value={toDate != null ? toDate.toISOString() : null}
          />
        </div>
        <div className="d-flex flex-column mr-4" style={{ maxWidth: "15rem", width: "100%" }}>
          <h5 className="truncateHeaderText mb-0">Prosjekt</h5>
          <McTypeahead
            id="issuepnstypeahead"
            clearButton
            labelKey={"projectName"}
            options={projects || []}
            onChange={(project) => {
              setProjectNo(project[0]?.projectNo !== null && project.length !== 0 ? project[0].projectNo : null);
            }}
            allowNew
          />
        </div>
        <div className="d-flex flex-column mr-4" style={{ maxWidth: "15rem", width: "100%" }}>
          <h5 className="truncateHeaderText mb-1">Unike brukere</h5>
          <Checkbox isChecked={toggleUniqueUsers} setChecked={() => setToggleUniqueUsers(!toggleUniqueUsers)} />
        </div>
      </div>
      <CardBody>
        <CardTitle tag="h3">Oversikt appstarter</CardTitle>
        <CardSubtitle tag="h6" className="mb-2 text-muted" />
        {<HighchartsReact containerProps={{ style: { height: "100%" } }} highcharts={Highcharts} options={options} />}
      </CardBody>
    </Card>
  );
};

export default WeeklyUserChart;
