import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReportTemplate } from "mc-shared/types/report.types";
import { IFile } from "../../../../../types/global.types";
import { IReport } from "../../../../../types/report.types";
import { ToastMessagesEnums } from "../../../frontendConstants";
import { AppThunk, IRootState } from "../../../store";
import {
  doGetReportBoards,
  doGetSingleReportBoard,
  doUpdateReportBoardGeneralSettings,
  doUpdateReportData,
} from "./reportBoardAPI";
import { doGetReportTemplates } from "./reportTemplateAPI";
import { setShowSuccessMessageThunk } from "../../../global/globalSlice";

export interface IReportReducer {
  activeReport: IReport.IReportBoardPopulated;
  activeVersion: IReport.IReportVersion;
  reportBoards: IReport.IReportBoardPopulated[];
  reportTemplates: IReportTemplate[];
  filterReportsByLoggedInUserChecked: boolean;
  editedData: object;
  dataToGenerateDocxWith: object;
}

const initialState: IReportReducer = {
  activeReport: null,
  activeVersion: null,
  reportBoards: null,
  reportTemplates: null,
  filterReportsByLoggedInUserChecked: false,
  editedData: null,
  dataToGenerateDocxWith: null,
};

export const fetchAndSetReportsThunk = (): AppThunk => async (dispatch: any, getState) => {
  const project = getState().adminReducer.project;
  const reports = await doGetReportBoards(project._id);
  dispatch(setReportsInRedux(reports));
};

export const fetchAndSetSingleReportThunk =
  (reportBoardId: string): AppThunk =>
  async (dispatch: any, getState) => {
    const project = getState().adminReducer.project;
    const report = await doGetSingleReportBoard(project._id, reportBoardId);
    dispatch(setActiveReportInRedux(report));
  };

export const fetchAndSetReportTemplatesThunk = () => async (dispatch) => {
  const reportTemplates = await doGetReportTemplates();
  dispatch(setReportTemplatesInRedux(reportTemplates));
};

export const updateReportSettingsThunk =
  (reportId: string, attr, value): AppThunk =>
  async (dispatch: any, getState) => {
    const project = getState().adminReducer.project;
    await doUpdateReportBoardGeneralSettings(project._id, reportId, attr, value);
    dispatch(fetchAndSetSingleReportThunk(reportId));
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.SETTINGS_UPDATED));
  };

export const updateReportDataThunk =
  (reportId: string, reportData: object, message: string, templateId: number): AppThunk =>
  async (dispatch: any, getState) => {
    const project = getState().adminReducer.project;
    const updatedReport = await doUpdateReportData(project._id, reportId, reportData, message, templateId);
    dispatch(fetchAndSetSingleReportThunk(reportId));
    dispatch(setActiveReportVersionInRedux(updatedReport.versions[updatedReport.versions.length - 1]));
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.SETTINGS_UPDATED));
  };

export const fetchReportAndUpdateUploadedFilesThunk =
  (reportId: string): AppThunk =>
  async (dispatch: any, getState) => {
    const project = getState().adminReducer.project;
    const report = await doGetSingleReportBoard(project._id, reportId);
    dispatch(updateUpdatedFiles(report.uploadedFiles));
  };

export const reportSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    resetReportApp: (state) => {
      return initialState;
    },
    setReportsInRedux: (state, action: PayloadAction<IReport.IReportBoardPopulated[]>) => {
      state.reportBoards = action.payload;
    },
    setActiveReportInRedux: (state, action: PayloadAction<IReport.IReportBoardPopulated>) => {
      state.activeReport = action.payload;
    },
    setReportTemplatesInRedux: (state, action: PayloadAction<IReportTemplate[]>) => {
      state.reportTemplates = action.payload;
    },
    setActiveReportVersionInRedux: (state, action: PayloadAction<IReport.IReportVersion>) => {
      state.activeVersion = action.payload;
    },
    updateUpdatedFiles: (state, action: PayloadAction<IFile[]>) => {
      state.activeReport.uploadedFiles = action.payload;
    },
    setFilterReportsByLoggedInUserCheckedInRedux: (state, action: PayloadAction<boolean>) => {
      state.filterReportsByLoggedInUserChecked = action.payload;
    },
    setEditedDataInRedux: (state, action: PayloadAction<object>) => {
      state.editedData = action.payload;
    },
    setDataToGenerateDocxWithInRedux: (state, action: PayloadAction<object>) => {
      state.dataToGenerateDocxWith = action.payload;
    },
  },
});

export const getFilteredReportBoards = (state: IRootState) => {
  const reportBoards = state.reportReducer.reportBoards;
  const loggedInUser = state.globalReducer.user;
  const filterReportsByLoggedInUserChecked = state.reportReducer.filterReportsByLoggedInUserChecked;
  if (filterReportsByLoggedInUserChecked === true) {
    return reportBoards?.filter((report) => report.createdBy?._id === loggedInUser?._id) ?? [];
  } else {
    return reportBoards ?? [];
  }
};

export const {
  resetReportApp,
  setReportsInRedux,
  setActiveReportInRedux,
  setReportTemplatesInRedux,
  setActiveReportVersionInRedux,
  updateUpdatedFiles,
  setFilterReportsByLoggedInUserCheckedInRedux,
  setEditedDataInRedux,
  setDataToGenerateDocxWithInRedux,
} = reportSlice.actions;
