import { apiSlice } from "./apiSlice";
import { IAzureBlobFile } from "mc-shared/zod/azureSchema";
import { IUser } from "../../types/global.types";
import { setUserInRedux } from "./global/globalSlice";
import { ICreateDoksamChecklistArgs, IDoksamChecklistResponse } from "@backend/src/schemas/doksamChecklistSchema";
import { ICreateServiceAccountArgs } from "@backend/src/schemas/serviceAccountSchema";

export const globalRTK = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLoggedInUser: builder.query<IUser, void>({
      query(input) {
        return {
          url: `/loggedin`,
          method: "GET",
        };
      },
      providesTags: ["IssueBoard"],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const resp = await queryFulfilled;
          dispatch(setUserInRedux(resp.data));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    getOfficalMulticonsultMeetingTempaltes: builder.query<IAzureBlobFile[], string>({
      query: (projectId) => ({
        url: `/offical-multiconsult-meeting-templates`,
        method: "GET",
      }),
      providesTags: ["Global"],
    }),
    deleteAzFile: builder.mutation<void, string>({
      query: (blobName) => ({
        url: `/delete-az-file`,
        method: "POST",
        body: {
          fullBlobPath: blobName,
        },
      }),
      invalidatesTags: ["Global"],
    }),
    updateUserName: builder.mutation<{ userName: string }, { userName: string }>({
      query: (newUsername) => ({
        url: "/user/update-name",
        method: "POST",
        body: newUsername,
      }),
    }),
    uploadOfficalMulticonsultIssueTempalte: builder.mutation<void, FormData>({
      query: (formData) => {
        const data = new FormData();
        data.append("fileToUpload", formData.get("fileToUpload"));
        return {
          url: "/offical-multiconsult-issue-docx-templates.upload",
          method: "POST",
          body: data,
          /*          headers: {
            "content-type": `multipart/form-data`,
          },*/
        };
      },
      invalidatesTags: ["Global"],
    }),
    getOfficalMulticonsultIssueTempaltes: builder.query<IAzureBlobFile[], string>({
      query: (projectId) => ({
        url: `/offical-multiconsult-issue-templates.get`,
        method: "POST",
      }),
      providesTags: ["Global"],
    }),
    createOrGetDoksamChecklist: builder.query<IDoksamChecklistResponse, ICreateDoksamChecklistArgs>({
      query: (input) => ({
        url: "/doksamchecklist",
        method: "POST",
        body: input,
      }),
    }),
    createServiceAccount: builder.mutation<{ apiKey: string }, ICreateServiceAccountArgs>({
      query: (input) => ({
        url: "/create-service-account",
        method: "POST",
        body: input,
      }),
    }),
  }),
});

export const {
  useGetOfficalMulticonsultMeetingTempaltesQuery,
  useUpdateUserNameMutation,
  useUploadOfficalMulticonsultIssueTempalteMutation,
  useGetOfficalMulticonsultIssueTempaltesQuery,
  useDeleteAzFileMutation,
  useCreateOrGetDoksamChecklistQuery,
  useCreateServiceAccountMutation,
} = globalRTK;
