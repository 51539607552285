import { ReactNode, useEffect, useState } from "react";
import { Button } from "reactstrap";
import { HierarchyNode } from "../../app/hierarchyNodeUtils";
import McInput from "../McInput/McInput";
import "./HierarchyEditor.css";

interface IHierarchyEditor<T> {
  rootNode?: HierarchyNode<T>;
  onNodeChange?: (node: HierarchyNode<T>) => void;
  onSave?: (rootNode: HierarchyNode<T>) => void;
  importButton?: ReactNode;
}

function HierarchyEditor<T>({ rootNode, importButton, onNodeChange, onSave }: IHierarchyEditor<T>) {
  const [nodes, setNodes] = useState<HierarchyNode<T>[]>([]);
  const [selectedNode, setSelectedNode] = useState<HierarchyNode<T>>(null);

  useEffect(() => {
    if (rootNode == null) {
      return;
    }

    setNodes([rootNode]);
  }, [rootNode]);

  const updateTable = (rootNode: HierarchyNode<T>) => {
    setNodes([rootNode]);
  };

  const addNode = (node: HierarchyNode<T>) => {
    if (node == null) {
      return;
    }

    const newProps = {};

    Object.keys(node.props).forEach((key) => {
      newProps[key] = "";
    });

    const newNode = new HierarchyNode<T>({
      _id: null,
      props: newProps as T,
      parent: node,
      children: [],
    });

    node.addChild(newNode);
    const root = node.getRoot();
    updateTable(root);
    onNodeChange && onNodeChange(newNode);
  };

  const removeNode = (node: HierarchyNode<T>) => {
    if (node == null || node.parent == null) {
      return;
    }

    setSelectedNode(node.getClosestSibling());
    const root = node.getRoot();
    node.parent.removeChild(node);
    updateTable(root);
    onNodeChange && onNodeChange(root);
  };

  const moveNode = (node: HierarchyNode<T>, direction: "up" | "down" | "left" | "right") => {
    if (node == null) {
      return;
    }

    switch (direction) {
      case "up":
        node.moveUp();
        break;
      case "down":
        node.moveDown();
        break;
      case "left":
        node.dedent();
        break;
      case "right":
        node.indent();
        break;
      default:
        return;
    }

    const root = node.getRoot();
    updateTable(root);
    onNodeChange && onNodeChange(node);
  };

  const saveRoot = () => {
    if (onSave != null) {
      onSave(nodes[0]);
    }
  };

  return (
    <div className="d-flex flex-column h-100">
      <div className="d-flex flex-row mb-2">
        <div className="d-flex">
          <Button disabled={selectedNode == null} className="mr-1" onClick={() => addNode(selectedNode)}>
            Legg til
          </Button>
          <Button
            disabled={selectedNode == null || selectedNode.levelId === "1"}
            className="mr-2"
            color="danger"
            onClick={() => removeNode(selectedNode)}
          >
            Slette
          </Button>
          <Button
            disabled={selectedNode == null || selectedNode.levelId === "1"}
            className="mr-1"
            onClick={() => moveNode(selectedNode, "left")}
          >
            <i className="fa fa-caret-left fa-fw" />
          </Button>
          <Button
            disabled={selectedNode == null || selectedNode.levelId === "1"}
            className="mr-1"
            onClick={() => moveNode(selectedNode, "up")}
          >
            <i className="fa fa-caret-up fa-fw" />
          </Button>
          <Button
            disabled={selectedNode == null || selectedNode.levelId === "1"}
            className="mr-1"
            onClick={() => moveNode(selectedNode, "down")}
          >
            <i className="fa fa-caret-down fa-fw" />
          </Button>
          <Button
            disabled={selectedNode == null || selectedNode.levelId === "1"}
            className="mr-2"
            onClick={() => moveNode(selectedNode, "right")}
          >
            <i className="fa fa-caret-right fa-fw" />
          </Button>
        </div>
        <div className="d-flex flex-grow-1"></div>
        <div className="d-flex">
          {importButton != null && importButton}
          <Button className="ml-2" color="success" onClick={saveRoot}>
            Lagre
          </Button>
        </div>
      </div>
      <div className="d-flex overflow-auto">
        <HierarchyTable
          onNodeChange={onNodeChange}
          selectedNodeChanged={setSelectedNode}
          selectedNode={selectedNode}
          data={nodes}
        />
      </div>
    </div>
  );
}

export default HierarchyEditor;

function createIndentedRows<T extends {}>(
  node: HierarchyNode<T>,
  depth: number,
  rows: JSX.Element[],
  selectedNode: HierarchyNode<T>,
  setSelected: (node: HierarchyNode<T>) => void,
  onNodeChange: (node: HierarchyNode<T>) => void,
) {
  if (node == null) {
    return;
  }

  const rowSelected = node.id === selectedNode?.id;

  const valueChanged = (key: string, value: string) => {
    node.props[key] = value;
    onNodeChange && onNodeChange(node);
  };

  rows.push(
    <tr key={node.id} onClick={() => setSelected(node)} className={rowSelected ? "selected-row" : ""}>
      <td style={{ paddingLeft: `${8 + depth * 20}px` }}>
        <span>{node.levelId}</span>
      </td>
      {Object.keys(node.props).map((key, index) => {
        return (
          <EditableCell
            editMode={rowSelected}
            key={index}
            value={node.props[key]}
            onChange={(newValue) => valueChanged(key, newValue)}
          />
        );
      })}
    </tr>,
  );

  for (let i = 0; i < node.children.length; i++) {
    const child = node.children[i];
    createIndentedRows(child, depth + 1, rows, selectedNode, setSelected, onNodeChange);
  }
}

function HierarchyTable<T>({
  data,
  selectedNode,
  selectedNodeChanged,
  onNodeChange,
}: {
  data: HierarchyNode<T>[];
  selectedNode: HierarchyNode<T>;
  selectedNodeChanged: (node: HierarchyNode<T>) => void;
  onNodeChange: (node: HierarchyNode<T>) => void;
}) {
  const [rows, setRows] = useState<JSX.Element[]>([]);

  useEffect(() => {
    const rows: JSX.Element[] = [];
    data.forEach((node) => createIndentedRows(node, 0, rows, selectedNode, selectedNodeChanged, onNodeChange));
    setRows(rows);
  }, [data, selectedNode]);

  return (
    <table className="hierarchy-editor table">
      <thead>
        <tr>
          <th>NIVÅ</th>
          {data.length > 0 &&
            Object.keys(data[0]?.props || {}).map((key, index) => {
              return <th key={index}>{key.toUpperCase()}</th>;
            })}
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
}

function EditableCell({
  value,
  onChange,
  editMode,
}: {
  value: string;
  onChange: (newValue: string) => void;
  editMode: boolean;
}) {
  const [focusInput, setFocusInput] = useState<boolean>(false);

  return (
    <td
      onClick={() => {
        setFocusInput(true);
      }}
      onBlur={() => {
        setFocusInput(false);
      }}
    >
      {editMode === true ? (
        <McInput
          autoFocus={focusInput}
          style={{
            backgroundColor: "white",
            fontSize: "inherit",
            fontWeight: "inherit",
          }}
          type="text"
          value={value}
          onFocus={(e) => {
            e.target.select();
          }}
          onBlur={(e) => {
            onChange(e.target.value);
          }}
        />
      ) : (
        <span>{value}</span>
      )}
    </td>
  );
}
