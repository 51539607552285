import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export const DocxErrorModal = ({ toggle, errors }) => {
  const [formattedError, setFormattedError] = useState(null);
  useEffect(() => {
    if (errors != null) {
      try {
        const arr = errors.errors.map((error) => {
          delete error.stack;
          return error;
        });
        const _formattedError = {
          errors: arr,
          ...errors,
        };
        setFormattedError(_formattedError);
      } catch (error) {
        console.log(error);
        setFormattedError(errors);
      }
    }
  }, [errors]);

  return (
    <Modal isOpen>
      <ModalHeader toggle={() => toggle(null)}>FEIL I MALER. RETT FEIL OG PRØV IGJEN</ModalHeader>
      <ModalBody className="bg-warning">
        <pre>{JSON.stringify(formattedError, null, 2)}</pre>
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => toggle(null)}>Ok</Button>
      </ModalFooter>
    </Modal>
  );
};
