import React from "react";
import { Badge, Button, ButtonGroup, ButtonGroupProps } from "reactstrap";
import { HSpace, TaskCompletedCount } from "..";
import { IButtonWithBadge } from "../../types";

const ButtonGroupWithBadge: React.FC<
  {
    buttons: IButtonWithBadge[];
    activeButtonName: string;
    setSelectedButtonName: (string) => void;
  } & ButtonGroupProps
> = ({ buttons, activeButtonName, setSelectedButtonName, ...props }) => {
  return (
    <ButtonGroup {...props}>
      {buttons.map((button) => (
        <Button
          key={button.name}
          color={activeButtonName === button.name ? "primary" : "secondary"}
          onClick={() => setSelectedButtonName(button.name)}
          active={activeButtonName === button.name}
        >
          <div className="d-flex p-0 m-0 align-items-center justify-content-center">
            {button.name}
            <HSpace />
            <BadgeIcon buttonWithBadge={button} active={activeButtonName === button.name} />
            {button.taskCompleteCount && (
              <TaskCompletedCount
                taskCompleteCount={button.taskCompleteCount}
                darkVariant
                active={activeButtonName === button.name}
              />
            )}
          </div>
        </Button>
      ))}
    </ButtonGroup>
  );
};

const BadgeIcon: React.FC<{ buttonWithBadge: IButtonWithBadge; active: boolean }> = ({ buttonWithBadge, active }) => {
  return (
    <>
      {(buttonWithBadge.badgeIcon != null || buttonWithBadge.badgeCount != null) && (
        <>
          <Badge className={`${active ? "secondary" : "gray-400"}`}>
            {buttonWithBadge.badgeIcon != null && <i className={`fa ${buttonWithBadge.badgeIcon}`} />}
            {buttonWithBadge.badgeCount != null && <span>{buttonWithBadge.badgeCount}</span>}
          </Badge>
        </>
      )}
    </>
  );
};

export default ButtonGroupWithBadge;
