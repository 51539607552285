import { z } from "zod";
import {
  LuftmengdeMaalingerEnum,
  LuftmoensterEnum,
  RomAktivitetEnum,
  RomEllerUtstyrEnum,
  UtstyrEnum,
  ValideringEnum,
} from "./gkReportConstants";

export const utstyrEnumSchema = z.nativeEnum(UtstyrEnum);

export const romEllerUtstyrEnumSchema = z.nativeEnum(RomEllerUtstyrEnum);

export const valideringEnumSchema = z.nativeEnum(ValideringEnum);

export const luftmoensterEnumSchema = z.nativeEnum(LuftmoensterEnum);

export const romAktivitetEnumSchema = z.nativeEnum(RomAktivitetEnum);

export const luftmengdeMaalingerEnumSchema = z.nativeEnum(LuftmengdeMaalingerEnum);

export const GKTestInnstillingerSchema = z.object({
  valgteTester: z.record(z.boolean()),
  valgtUtstyr: utstyrEnumSchema.nullable(),
  valgtRomEllerUtstyr: romEllerUtstyrEnumSchema.nullable(),
  valgtTestValidering: valideringEnumSchema.nullable(),
  valgtLuftmoenster: luftmoensterEnumSchema.nullable(),
  valgtRomAktivitet: romAktivitetEnumSchema.nullable(),
});

export type IGKTestInnstillinger = z.infer<typeof GKTestInnstillingerSchema>;

export const GKFotometerResultaterSchema = z.object({
  maalepunktNr: z.string(),
  konstruksjonstype: z.string(),
  maaleresultaterUrenSone: z.number().nullable(),
  godkjent: z.boolean().nullable(),
  maaleresultaterRenSone: z.number().nullable(),
  maksimalLekkasjeverdi: z.number().nullable(),
  beskrivelseAvLekkasjeLokasjon: z.string(),
});

export type IGKFotometerResultater = z.infer<typeof GKFotometerResultaterSchema>;

export const GKResultaterPerVentilSchema = z.object({
  ventilnummer: z.number().nullable(),
  maaleomraadeAreal: z.number().nullable(),
  maaleresultater: z.array(z.number().nullable()),
  beregninger: z.object({
    minimumAntallRuterIRutenett: z.number().nullable(),
    gjennomsnittligHastighet: z.number().nullable(),
    varians: z.number().nullable(),
    standardAvvik: z.number().nullable(),
    lufthastighetUniformitet: z.number().nullable(),
  }),
});

export type IGKResultaterPerVentil = z.infer<typeof GKResultaterPerVentilSchema>;

export const GKResultaterPerVentilAnemometerSchema = z.object({
  ventilnummer: z.number().nullable(),
  maaleomraadeAreal: z.number().nullable(),
  andelFrittAreal: z.number().nullable(),
  maaleresultater: z.array(z.number().nullable()),
  beregninger: z.object({
    minimumAntallRuterIRutenett: z.number().nullable(),
    frittArealIHverRute: z.number().nullable(),
    gjennomsnittligHastighet: z.number().nullable(),
    totalLuftmengde: z.number().nullable(),
  }),
});

export type IGKResultaterPerVentilAnemometer = z.infer<typeof GKResultaterPerVentilAnemometerSchema>;

export const GKLuftmengdemaalingResultaterSchema = z.object({
  inndata: z.object({
    prosjektertLuftmengde: z.number().nullable(),
    prosjektertLuftskifte: z.number().nullable(),
    romdimensjoner: z.object({
      romhoeyde: z.number().nullable(),
      romareal: z.number().nullable(),
    }),
    valgtLuftmengdeMaalingsType: luftmengdeMaalingerEnumSchema.nullable(),
    kommentar: z.string(),
    anemometerResultater: z.object({
      antallTilluftsventiler: z.number().nullable(),
      totalLuftmengde: z.number().nullable(),
      resultaterPerVentil: z.array(GKResultaterPerVentilAnemometerSchema),
    }),
    tilluftsKanalResultater: z.object({
      maaleomraadeAreal: z.number().nullable(),
      antallMaalepunkter: z.number().nullable(),
      maaleresultater: z.array(z.number()),
    }),
    balometerResultater: z.object({
      antallTilluftsventiler: z.number().nullable(),
      tilluftsventilAreal: z.number().nullable(),
      filtertype: z.string(),
      maaleresultater: z.array(z.number()),
    }),
  }),
  utdata: z.object({
    felles: z.object({
      romvolum: z.number().nullable(),
      luftmengdeFraLuftskifter: z.number().nullable(),
    }),
    tilluftsKanalBeregninger: z.object({
      arealPerMaalepunkt: z.number().nullable(),
      totalLuftmengde: z.number().nullable(),
    }),
    balometerBeregninger: z.object({
      totalLuftmengde: z.number().nullable(),
    }),
  }),
});

export type IGKLuftmengdemaalingResultater = z.infer<typeof GKLuftmengdemaalingResultaterSchema>;

export const GKLufthastighetskontrollResultaterSchema = z.object({
  inndata: z.object({
    antallVentiler: z.number().nullable(),
    kommentar: z.string(),
    resultaterPerVentil: z.array(GKResultaterPerVentilSchema),
  }),
});

export type IGKLufthastighetskontrollResultater = z.infer<typeof GKLufthastighetskontrollResultaterSchema>;

export const GKLufthastighetskontrollLAFTakResultaterSchema = z.object({
  inndata: z.object({
    maaleomraadeAreal: z.number().nullable(),
    kommentar: z.string(),
    andelFrittAreal: z.number().nullable().optional(),
    maaleresultater: z.array(z.number().nullable()),
  }),
  utdata: z.object({
    minimumAntallRuterIRutenett: z.number().nullable(),
    frittArealIHverRute: z.number().nullable().optional(),
    gjennomsnittligHastighet: z.number().nullable(),
    varians: z.number().nullable(),
    standardAvvik: z.number().nullable(),
    totalLuftmengde: z.number().nullable().optional(),
    lufthastighetUniformitet: z.number().nullable(),
  }),
});

export type IGKLufthastighetskontrollLAFTakResultater = z.infer<typeof GKLufthastighetskontrollLAFTakResultaterSchema>;

export const GKFiltertestResultaterSchema = z.object({
  filterNr: z.string(),
  maaleresultaterOppstroems: z.number().nullable(),
  godkjent: z.boolean().nullable(),
  maaleresultaterNedstroems: z.number().nullable().nullable(),
  maksimalLekkasjeverdi: z.number().nullable(),
  beskrivelseAvLekkasjeLokasjon: z.string().nullable(),
  konsentrasjonFoerFilterEtterTest: z.number().nullable(),
});

export type IGKFiltertestResultater = z.infer<typeof GKFiltertestResultaterSchema>;

export const GKKontrollAvLuftStroemResultaterSchema = z.object({
  inndata: z.object({
    valgtMetode: z.string(),
    kommentar: z.string(),
    streamerMetodeResultater: z.object({
      beskrivelseAvLuftstroem: z.string(),
      godkjent: z.boolean().nullable(),
    }),
    lufthastighetsMetodeResultater: z.object({
      maaleresultater: z.array(z.number().nullable()),
    }),
  }),
  utdata: z.object({
    gjennomsnittligHastighet: z.number().nullable(),
    varians: z.number().nullable(),
    standardAvvik: z.number().nullable(),
    lufthastighetUniformitet: z.number().nullable(),
  }),
});

export type IGKKontrollAvLuftStroemResultater = z.infer<typeof GKKontrollAvLuftStroemResultaterSchema>;

export const GKRecoveryTestMaalepunktResultaterSchema = z.object({
  maalepunktNr: z.string(),
  maaleresultatStart: z.number().nullable(),
  maaleresultatSlutt: z.number().nullable(),
  maaletid: z.number().nullable(),
});

export type IGKRecoveryTestMaalepunktResultater = z.infer<typeof GKRecoveryTestMaalepunktResultaterSchema>;

export const GKPartikkelTellingEuGmpOptionsSchema = z.union([
  z.literal(""),
  z.literal("A"),
  z.literal("B"),
  z.literal("C"),
  z.literal("D"),
]);

export type IGKPartikkelTellingEuGmpOptions = z.infer<typeof GKPartikkelTellingEuGmpOptionsSchema>;

export const GKPartikkelTellingIsoOptionsSchema = z.union([
  z.literal(""),
  z.literal("1"),
  z.literal("2"),
  z.literal("3"),
  z.literal("4"),
  z.literal("5"),
  z.literal("6"),
  z.literal("7"),
  z.literal("8"),
  z.literal("9g"),
]);

export type IGKPartikkelTellingIsoOptions = z.infer<typeof GKPartikkelTellingIsoOptionsSchema>;

export const GKPartikkelMaaleresultaterSchema = z.object({
  maalepunktNr: z.string(),
  maaleresultat: z.number().nullable(),
  sammenligningMellomKonsentrasjonOgKlassegrensenivaa: z.boolean().nullable(),
});

export type IGKPartikkelMaaleresultater = z.infer<typeof GKPartikkelMaaleresultaterSchema>;

export const GKVerdierPerProevetakingResultaterSchema = z.object({
  partikkelstoerrelse: z.string(),
  minimumsvolumPerProevetakingISO: z.number().nullable(),
  klasseGrenseNivaaISO: z.number().or(z.string()).nullable(),
});

export type IGKVerdierPerProevetakingResultater = z.infer<typeof GKVerdierPerProevetakingResultaterSchema>;

export const GKPartikkelTellingForUtstyrResultaterSchema = z.object({
  inndata: z.object({
    euGmpKlasse: GKPartikkelTellingEuGmpOptionsSchema,
    maalehasighetTilPartikkelteller: z.number().nullable(),
    maaletid: z.number().nullable(),
    maaleresultater: z.object({
      size05: z.array(GKPartikkelMaaleresultaterSchema),
      size5: z.array(GKPartikkelMaaleresultaterSchema),
    }),
    kommentar: z.string(),
  }),
  utdata: z.object({
    volumPerProevetaking: z.number().nullable(),
    minimumsvolumPerProevetakingEUGMP_05: z.number().nullable(),
    minimumsvolumPerProevetakingEUGMP_5: z.number().nullable(),
    klasseGrenseNivaaEUGMP_05: z.number().or(z.string()).nullable(),
    klasseGrenseNivaaEUGMP_5: z.number().or(z.string()).nullable(),
  }),
});

export type IGKPartikkelTellingForUtstyrResultater = z.infer<typeof GKPartikkelTellingForUtstyrResultaterSchema>;

export const GKTemperaturmaalingResultaterSchema = z.object({
  inndata: z.object({
    settpunktsTemp: z.number().nullable(),
    maaleresultat: z.number().nullable(),
    tidTilTempErStabil: z.number().nullable(),
    godkjent: z.boolean().nullable(),
    kommentar: z.string(),
  }),
  utdata: z.object({
    temperaturDiff: z.number().nullable(),
  }),
});

export type IGKTemperaturmaalingResultater = z.infer<typeof GKTemperaturmaalingResultaterSchema>;

export const GKRelativeFuktighetsmaalingResultaterSchema = z.object({
  inndata: z.object({
    settpunktsLuftfuktighet: z.number().nullable(),
    maaleresultat: z.number().nullable(),
    tidTilLuftfuktighetErStabil: z.number().nullable(),
    godkjent: z.boolean().nullable(),
    kommentar: z.string(),
  }),
  utdata: z.object({
    relativLuftfuktighetDiff: z.number().nullable(),
  }),
});

export type IGKRelativeFuktighetsmaalingResultater = z.infer<typeof GKRelativeFuktighetsmaalingResultaterSchema>;

export const GKKontrollAvLuftStroemVedArbeidsaapningResultaterSchema = z.object({
  inndata: z.object({
    valgtMetode: z.string(),
    kommentar: z.string(),
    resultater: z.object({
      beskrivelseAvLuftstroem: z.string(),
      godkjent: z.boolean().nullable(),
    }),
  }),
});

export type IGKKontrollAvLuftStroemVedArbeidsaapningResultater = z.infer<
  typeof GKKontrollAvLuftStroemVedArbeidsaapningResultaterSchema
>;

export const GKLuftmengdemaalingGjennomArbeidsaapningResultaterSchema = z.object({
  inndata: z.object({
    arealLukeaapning: z.number().nullable(),
    valgtMetode: z.string(),
    kommentar: z.string(),
    maaleresultater: z.array(z.number().nullable()),
    avtrekkskanalResultater: z.object({
      diameter: z.number().nullable(),
    }),
  }),
  utdata: z.object({
    gjennomsnittligHastighet: z.number().nullable(),
    arbeidsaapningBeregninger: z.object({
      luftmengdeGjennomLukeaapning: z.number().nullable(),
    }),
    avtrekkskanalBeregninger: z.object({
      tverrsnittareal: z.number().nullable(),
      arealForHvertPunkt: z.number().nullable(),
      avkastLuftmengde: z.number().nullable(),
    }),
  }),
});

export type IGKLuftmengdemaalingGjennomArbeidsaapningResultater = z.infer<
  typeof GKLuftmengdemaalingGjennomArbeidsaapningResultaterSchema
>;

export const GKLufthastigetsmaalingMaaleresultaterSchema = z.object({
  maaleresultat: z.number().nullable(),
  forholdMellomMaaleresultatOgGjennomsnittshastighet: z.number().nullable(),
});

export type IGKLufthastigetsmaalingMaaleresultater = z.infer<typeof GKLufthastigetsmaalingMaaleresultaterSchema>;

export const GKBeregningAvLufthastighetGjennomArbeidsaapningMaaleresultaterSchema = z.object({
  maaleresultat: z.number().nullable(),
  forholdMellomMaaleresultatOgGjennomsnittshastighet: z.number().nullable(),
});

export type IGKBeregningAvLufthastighetGjennomArbeidsaapningMaaleresultater = z.infer<
  typeof GKBeregningAvLufthastighetGjennomArbeidsaapningMaaleresultaterSchema
>;

export const GKHepafilterMaalepunktResultaterSchema = z.object({
  filterNr: z.string(),
  maaleresultat: z.number().nullable(),
});

export type IGKHepafilterMaalepunktResultater = z.infer<typeof GKHepafilterMaalepunktResultaterSchema>;

export const GKKontrollAvAlarmResultaterSchema = z.object({
  inndata: z.object({
    beskrivelseAvHvordanAlarmFremprovoseres: z.string(),
    godkjent: z.boolean().nullable(),
    kommentar: z.string(),
  }),
});

export type IGKKontrollAvAlarmResultater = z.infer<typeof GKKontrollAvAlarmResultaterSchema>;

export const GKBruddtestResultaterSchema = z.object({
  inndata: z.object({
    maaleresultatVenstre: z.number().nullable(),
    maaleresultatHoeyre: z.number().nullable(),
    kommentar: z.string(),
  }),
});

export type IGKBruddtestResultater = z.infer<typeof GKBruddtestResultaterSchema>;

export const GKMaalingAvLuftmengdeGjennomInntaksfilterResultaterSchema = z.object({
  inndata: z.object({
    valgtMetode: z.string(),
    kabinettvolum: z.number().nullable(),
    maaleresultater: z.array(z.number().nullable()),
    kommentar: z.string(),
    avkastkanalResultater: z.object({
      diameter: z.number().nullable(),
    }),
    inntaksfilterResultater: z.object({
      arealetTilFilterflaten: z.number().nullable(),
    }),
  }),
  utdata: z.object({
    luftmengdePerVolum: z.number().nullable(),
    avkastkanalBeregninger: z.object({
      tverrsnittareal: z.number().nullable(),
      arealForHvertPunkt: z.number().nullable(),
      avkastLuftmengde: z.number().nullable(),
    }),
    inntaksfilterBeregninger: z.object({
      gjennomsnittligHastighet: z.number().nullable(),
      luftmengdeGjennomFilter: z.number().nullable(),
    }),
  }),
});

export type IGKMaalingAvLuftmengdeGjennomInntaksfilterResultater = z.infer<
  typeof GKMaalingAvLuftmengdeGjennomInntaksfilterResultaterSchema
>;

export const GKMaalingAvArbeidstrykkOgKontrollAvInternTrykkmaalerResultaterSchema = z.object({
  inndata: z.object({
    avlestTrykkHovedkammer: z.number().nullable(),
    maaltTrykkHovedkammer: z.number().nullable(),
    maaltInterntrykkHoeyreSluse: z.number().nullable(),
    avlestInterntrykkHoeyreSluse: z.number().nullable(),
    maaltInterntrykkVenstreSluse: z.number().nullable(),
    avlestInterntrykkVenstreSluse: z.number().nullable(),
    maaltInterntrykkHovedfilter: z.number().nullable().optional(),
    avlestInterntrykkHovedfilter: z.number().nullable().optional(),
    ihenholdTilFabrikant: z.boolean().nullable(),
    kommentar: z.string(),
  }),
  utdata: z.object({
    trykkDiffHovedkammer: z.number().nullable(),
    trykkDiffHoeyreSluse: z.number().nullable(),
    trykkDiffVenstreSluse: z.number().nullable(),
    trykkDiffHovedfilter: z.number().nullable().optional(),
  }),
});

export type IGKMaalingAvArbeidstrykkOgKontrollAvInternTrykkmaalerResultater = z.infer<
  typeof GKMaalingAvArbeidstrykkOgKontrollAvInternTrykkmaalerResultaterSchema
>;

export const GKLufthastighetmaalingIArbeidsomraadeMaaleresultaterSchema = z.object({
  maaleresultat: z.number().nullable(),
  forholdMellomMaaleresultatOgGjennomsnittshastighet: z.number().nullable(),
});

export type IGKLufthastighetmaalingIArbeidsomraadeMaaleresultater = z.infer<
  typeof GKLufthastighetmaalingIArbeidsomraadeMaaleresultaterSchema
>;

export const GKMaalingAvInnstroemningsHastighetResultaterSchema = z.object({
  inndata: z.object({
    maaleresultaterVenstre: z.object({
      arealetTilFilterflaten: z.number().nullable(),
      volum: z.number().nullable(),
      luftskifter: z.number().nullable(),
      innstiltInterlockTid: z.number().nullable(),
      maaleresultater: z.array(z.number().nullable()),
    }),
    maaleresultaterHoeyre: z.object({
      arealetTilFilterflaten: z.number().nullable(),
      volum: z.number().nullable(),
      luftskifter: z.number().nullable(),
      innstiltInterlockTid: z.number().nullable(),
      maaleresultater: z.array(z.number().nullable()),
    }),
    kommentar: z.string(),
  }),
  utdata: z.object({
    beregningerVenstre: z.object({
      gjennomsnittligHastighet: z.number().nullable(),
      luftmengdeGjennomFilter: z.number().nullable(),
      luftmengdeFraLuftskifter: z.number().nullable(),
    }),
    beregningerHoeyre: z.object({
      gjennomsnittligHastighet: z.number().nullable(),
      luftmengdeGjennomFilter: z.number().nullable(),
      luftmengdeFraLuftskifter: z.number().nullable(),
    }),
  }),
});

export type IGKMaalingAvInnstroemningsHastighetResultater = z.infer<
  typeof GKMaalingAvInnstroemningsHastighetResultaterSchema
>;

export const GKLekkasjetestMaaleresultaterSchema = z.object({
  starttrykk: z.number().nullable(),
  sluttrykk: z.number().nullable(),
});

export type IGKLekkasjetestMaaleresultater = z.infer<typeof GKLekkasjetestMaaleresultaterSchema>;

export const GKMaalingAvLufthastighetILukeaapningResultaterSchema = z.object({
  inndata: z.object({
    lufthastighetFraFabrikant: z.number().nullable(),
    dimensjoner: z.object({
      skapbredde: z.number().nullable(),
      lukeaapninghoeydeHoey: z.number().nullable(),
      lukeaapninghoeydeLav: z.number().nullable(),
    }),
    maaleresultaterHoeyLukeaapning: z.array(
      z.object({
        maalepunktNummer: z.number().nullable(),
        maaleresultat: z.number().nullable(),
      }),
    ),
    maaleresultaterLavLukeaapning: z.array(
      z.object({
        maalepunktNummer: z.number().nullable(),
        maaleresultat: z.number().nullable(),
      }),
    ),
    kommentar: z.string(),
  }),
  utdata: z.object({
    beregningerHoeyLukeaapning: z.object({
      luftmengde: z.number().nullable(),
      gjennomsnittligHastighet: z.number().nullable(),
      varians: z.number().nullable(),
      standardAvvik: z.number().nullable(),
      lukeaapningAreal: z.number().nullable(),
    }),
    beregningerLavLukeaapning: z.object({
      gjennomsnittligHastighet: z.number().nullable(),
      varians: z.number().nullable(),
      standardAvvik: z.number().nullable(),
    }),
  }),
});

export type IGKMaalingAvLufthastighetILukeaapningResultater = z.infer<
  typeof GKMaalingAvLufthastighetILukeaapningResultaterSchema
>;

export const GKMaalingAvAvtrekksmengdeRutinekontrollResultaterSchema = z.object({
  inndata: z.object({
    arealLukeaapning: z.number().nullable(),
    maaleresultat: z.number().nullable(),
    kommentar: z.string(),
  }),
  utdata: z.object({
    avtrekksLuftmengde: z.number().nullable(),
  }),
});

export type IGKMaalingAvAvtrekksmengdeRutinekontrollResultater = z.infer<
  typeof GKMaalingAvAvtrekksmengdeRutinekontrollResultaterSchema
>;

export const GKTrykkfallIAvtrekksskapResultaterSchema = z.object({
  inndata: z.object({
    antallMaalepunkter: z.number().nullable(),
    beskrivelseAvMaalepunkter: z.string(),
    diameterPaaAvtrekkskanal: z.number().nullable(),
    maaleresultatTilkoblingspunkt: z.number().nullable(),
    maaleresultaterMaalenipler: z.array(z.number().nullable()),
    avtrekksLuftmengde: z.number().nullable(),
    kommentar: z.string(),
  }),
  utdata: z.object({
    tverrsnittareal: z.number().nullable(),
  }),
});

export type IGKTrykkfallIAvtrekksskapResultater = z.infer<typeof GKTrykkfallIAvtrekksskapResultaterSchema>;

export const GKVisualiseringAvLuftstroemResultaterSchema = z.object({
  inndata: z.object({
    beskrivelseAvLuftstroem: z.string(),
    godkjent: z.boolean().nullable(),
    kommentar: z.string(),
  }),
});

export type IGKVisualiseringAvLuftstroemResultater = z.infer<typeof GKVisualiseringAvLuftstroemResultaterSchema>;

export const GKTestAvAlarmsystemerResultaterSchema = z.object({
  inndata: z.object({
    valgtMetode: z.record(z.boolean()),
    lukeaapningResultater: z.object({
      godkjent: z.boolean().nullable(),
      kommentar: z.string(),
    }),
    lufthastighetsIndikatorResultater: z.object({
      godkjent: z.boolean().nullable(),
      kommentar: z.string(),
    }),
  }),
});

export type IGKTestAvAlarmsystemerResultater = z.infer<typeof GKTestAvAlarmsystemerResultaterSchema>;

export const GKTestAvSkapreguleringensResponstidResultaterSchema = z.object({
  inndata: z.object({
    trykkfallFoerTest: z.number().nullable(),
    trykkfallEtterTest: z.number().nullable(),
    tidFoerTrykkfallHarRetunert: z.number().nullable(),
    kommentar: z.string(),
  }),
});

export type IGKTestAvSkapreguleringensResponstidResultater = z.infer<
  typeof GKTestAvSkapreguleringensResponstidResultaterSchema
>;

export const GKMaalingAvAvtrekksmengdeIgangkjoeringResultaterSchema = z.object({
  inndata: z.object({
    valgtMetode: z.record(z.boolean()),
    lukeaapningResultater: z.object({
      lufthastighetFraFabrikant: z.number().nullable(),
      arealLukeaapning: z.number().nullable(),
      maaleresultater: z.array(z.number().nullable()),
      kommentar: z.string(),
    }),
    differanseTrykkResultater: z.object({
      antallMaalepunkter: z.number().nullable(),
      beskrivelseAvMaalepunkter: z.string(),
      diameterPaaAvtrekkskanal: z.number().nullable(),
      maaleresultatTilkoblingspunkt: z.number().nullable(),
      maaleresultaterMaalenipler: z.array(z.number().nullable()),
      avtrekksmengdeFraFabrikant: z.number().nullable(),
      kommentar: z.string(),
    }),
  }),
  utdata: z.object({
    lukeaapningBeregninger: z.object({
      gjennomsnittligHastighet: z.number().nullable(),
      varians: z.number().nullable(),
      standardAvvik: z.number().nullable(),
      avtrekksmengde: z.number().nullable(),
    }),
    differanseTrykkBeregninger: z.object({
      tverrsnittareal: z.number().nullable(),
    }),
  }),
});

export type IGKMaalingAvAvtrekksmengdeIgangkjoeringResultater = z.infer<
  typeof GKMaalingAvAvtrekksmengdeIgangkjoeringResultaterSchema
>;

export const GKMaalingAvLufthastighetIRommetResultaterSchema = z.object({
  inndata: z.object({
    maaleresultater: z.array(z.number().nullable()),
    kommentar: z.string(),
  }),
});

export type IGKMaalingAvLufthastighetIRommetResultater = z.infer<
  typeof GKMaalingAvLufthastighetIRommetResultaterSchema
>;

export const GKMaalingAvLydtrykkResultaterSchema = z.object({
  inndata: z.object({
    hoeydePaaLukeaapning: z.number().nullable(),
    maaltLydtrykk: z.number().nullable(),
    kommentar: z.string(),
  }),
});

export type IGKMaalingAvLydtrykkResultater = z.infer<typeof GKMaalingAvLydtrykkResultaterSchema>;

export const GKMaalingAvLufthastighetPunktavsugResultaterSchema = z.object({
  inndata: z.object({
    maaleresultat: z.number().nullable(),
    kommentar: z.string(),
  }),
});

export type IGKMaalingAvLufthastighetPunktavsugResultater = z.infer<
  typeof GKMaalingAvLufthastighetPunktavsugResultaterSchema
>;

export const GKMaalingAvTrykkPunktavsugResultaterSchema = z.object({
  inndata: z.object({
    maaleresultat: z.number().nullable(),
    kommentar: z.string(),
  }),
});

export type IGKMaalingAvTrykkPunktavsugResultater = z.infer<typeof GKMaalingAvTrykkPunktavsugResultaterSchema>;

export const GKVisualiseringMedRoeykPunktavsugResultaterSchema = z.object({
  inndata: z.object({
    beskrivelseAvLuftstroem: z.string(),
    godkjent: z.boolean().nullable(),
    kommentar: z.string(),
  }),
});

export type IGKVisualiseringMedRoeykPunktavsugResultater = z.infer<
  typeof GKVisualiseringMedRoeykPunktavsugResultaterSchema
>;

export const GKEksternOgInternRoeykvisualiseringResultaterSchema = z.object({
  inndata: z.object({
    intern: z.object({
      beskrivelseAvLuftstroem: z.string(),
      godkjent: z.boolean().nullable(),
      kommentar: z.string(),
    }),
    ekstern: z.object({
      beskrivelseAvLuftstroem: z.string(),
      godkjent: z.boolean().nullable(),
      kommentar: z.string(),
    }),
  }),
});

export type IGKEksternOgInternRoeykvisualiseringResultater = z.infer<
  typeof GKEksternOgInternRoeykvisualiseringResultaterSchema
>;

export const GKDekontamineringAvRomResultaterSchema = z.object({
  inndata: z.object({
    utfoertSyklus: z.boolean().nullable(),
    syklusstart: z.string().nullable(),
    syklusslutt: z.string().nullable(),
    hoeyestePPM: z.number().nullable(),
    luftfuktighet: z.number().nullable(),
    temperatur: z.number().nullable(),
    kommentar: z.string(),
  }),
});

export type IGKDekontamineringAvRomResultater = z.infer<typeof GKDekontamineringAvRomResultaterSchema>;

export const GKDekontamineringAvSikkerhetskabinettResultaterSchema = z.object({
  inndata: z.object({
    valgtMetode: z.string(),
    utfoertSyklus: z.boolean().nullable(),
    syklusstart: z.string().nullable(),
    syklusslutt: z.string().nullable(),
    hoeyestePPM: z.number().nullable(),
    luftfuktighet: z.number().nullable(),
    temperatur: z.number().nullable(),
    kommentar: z.string(),
  }),
});

export type IGKDekontamineringAvSikkerhetskabinettResultater = z.infer<
  typeof GKDekontamineringAvSikkerhetskabinettResultaterSchema
>;

export const GKFunksjonskontrollSikkerhetskabinettResultaterSchema = z.object({
  inndata: z.object({
    valgtMetode: z.record(z.boolean()),
    visuellKontrollAvOverflaterResultater: z.object({
      godkjent: z.boolean().nullable(),
      kommentar: z.string(),
    }),
    visuellKontrollAvAvtrekkskanalerResultater: z.object({
      godkjent: z.boolean().nullable(),
      kommentar: z.string(),
    }),
    kontrollAvTilbakeslagsspjeldResultater: z.object({
      godkjent: z.boolean().nullable(),
      kommentar: z.string(),
    }),
    maalingAvBelysningResultater: z.object({
      godkjent: z.boolean().nullable(),
      maaleresultat: z.number().nullable(),
      kommentar: z.string(),
    }),
    maalingAvLydnivaaerResultater: z.object({
      godkjent: z.boolean().nullable(),
      maaleresultat: z.number().nullable(),
      kommentar: z.string(),
    }),
    kontrollOgKalibreringResultater: z.object({
      maaltLuftmengde: z.number().nullable(),
      avlestLuftmengde: z.number().nullable(),
      maaltTrykk: z.number().nullable(),
      avlestTrykk: z.number().nullable(),
      kommentar: z.string(),
    }),
    dekontamineringsarrangement: z.object({
      godkjent: z.boolean().nullable(),
      kommentar: z.string(),
    }),
  }),
});

export type IGKFunksjonskontrollSikkerhetskabinettResultater = z.infer<
  typeof GKFunksjonskontrollSikkerhetskabinettResultaterSchema
>;

export const GKFunksjonskontrollIsolatorResultaterSchema = z.object({
  inndata: z.object({
    valgtMetode: z.record(z.boolean()),
    lyspaererFrontpanelResultater: z.object({
      godkjent: z.boolean().nullable(),
      kommentar: z.string(),
    }),
    lyspaererInterlockResultater: z.object({
      godkjent: z.boolean().nullable(),
      kommentar: z.string(),
    }),
    internvifterResultater: z.object({
      godkjent: z.boolean().nullable(),
      maaleresultat: z.number().nullable(),
      kommentar: z.string(),
    }),
    eksternvifteResultater: z.object({
      godkjent: z.boolean().nullable(),
      maaleresultat: z.number().nullable(),
      kommentar: z.string(),
    }),
    tilbakeslagsventilResultater: z.object({
      godkjent: z.boolean().nullable(),
      kommentar: z.string(),
    }),
    alarmForInterntrykkResultater: z.object({
      godkjent: z.boolean().nullable(),
      kommentar: z.string(),
    }),
    alarmForLuftvekslingerResultater: z.object({
      godkjent: z.boolean().nullable(),
      kommentar: z.string(),
    }),
    skifteForfilterISluseResultater: z.object({
      godkjent: z.boolean().nullable(),
      filterSkiftet: z.boolean().nullable(),
      kommentar: z.string(),
    }),
    skiftePakningerSluserUtvendigResultater: z.object({
      godkjent: z.boolean().nullable(),
      pakningerSkiftet: z.boolean().nullable(),
      kommentar: z.string(),
    }),
    etterstrammeUtsatteKomponenterResultater: z.object({
      godkjent: z.boolean().nullable(),
      kommentar: z.string(),
    }),
    smoereSylinderResultater: z.object({
      godkjent: z.boolean().nullable(),
      kommentar: z.string(),
    }),
    pusseMagneterResultater: z.object({
      godkjent: z.boolean().nullable(),
      magneterPusset: z.boolean().nullable(),
      kommentar: z.string(),
    }),
    dekontamineringsarrangement: z.object({
      godkjent: z.boolean().nullable(),
      kommentar: z.string(),
    }),
  }),
});

export type IGKFunksjonskontrollIsolatorResultater = z.infer<typeof GKFunksjonskontrollIsolatorResultaterSchema>;

export const GKFunksjonskontrollAvtrekksskapResultaterSchema = z.object({
  inndata: z.object({
    valgtMetode: z.record(z.boolean()),
    kontrollAvBetjeningspanelResultater: z.object({
      godkjent: z.boolean().nullable(),
      kommentar: z.string(),
    }),
    noedtestResultater: z.object({
      godkjent: z.boolean().nullable(),
      kommentar: z.string(),
    }),
    lysnivaaResultater: z.object({
      godkjent: z.boolean().nullable(),
      maaleresultat: z.number().nullable(),
      kommentar: z.string(),
    }),
    lydnivaaResultater: z.object({
      godkjent: z.boolean().nullable(),
      maaleresultat: z.number().nullable(),
      kommentar: z.string(),
    }),
    dekontamineringsarrangement: z.object({
      godkjent: z.boolean().nullable(),
      kommentar: z.string(),
    }),
  }),
});

export type IGKFunksjonskontrollAvtrekksskapResultater = z.infer<
  typeof GKFunksjonskontrollAvtrekksskapResultaterSchema
>;

export const GKFunksjonskontrollLAFTakResultaterSchema = z.object({
  inndata: z.object({
    valgtMetode: z.record(z.boolean()),
    kontrollAvBetjeningspanelResultater: z.object({
      paaAvKnappGodkjent: z.boolean().nullable(),
      flowKnappGodkjent: z.boolean().nullable(),
      lysKnappGodkjent: z.boolean().nullable(),
      kommentar: z.string(),
    }),
    lyspaererResultater: z.object({
      godkjent: z.boolean().nullable(),
      kommentar: z.string(),
    }),
    displayResultater: z.object({
      godkjent: z.boolean().nullable(),
      kommentar: z.string(),
    }),
    skaderResultater: z.object({
      godkjent: z.boolean().nullable(),
      kommentar: z.string(),
    }),
    lekkasjeResultater: z.object({
      godkjent: z.boolean().nullable(),
      kommentar: z.string(),
    }),
    posisjonResultater: z.object({
      godkjent: z.boolean().nullable(),
      kommentar: z.string(),
    }),
    lydResultater: z.object({
      godkjent: z.boolean().nullable(),
      kommentar: z.string(),
    }),
    forfilterResultater: z.object({
      godkjent: z.boolean().nullable(),
      kommentar: z.string(),
    }),
  }),
});

export type IGKFunksjonskontrollLAFTakResultater = z.infer<typeof GKFunksjonskontrollLAFTakResultaterSchema>;

export const GKInneslutningsLekkasjetestMedFotometerResultaterSchema = z.object({
  inndata: z.object({
    maaleresultater: z.array(GKFotometerResultaterSchema),
    kommentar: z.string(),
  }),
});

export type IGKInneslutningsLekkasjetestMedFotometerResultater = z.infer<
  typeof GKInneslutningsLekkasjetestMedFotometerResultaterSchema
>;

export const GKTilleggsromResultaterSchema = z.object({
  romNr: z.string(),
  romNavn: z.string(),
  prosjektertTrykkdifferanse: z.number().nullable(),
  maaltTrykkdifferanse: z.number().nullable(),
  trykkdifferanseFraSDOvervaakning: z.number().nullable(),
  trykkDifferanseMellomMaaltOgOvervaakning: z.number().nullable(),
});

export type IGKTilleggsromResultater = z.infer<typeof GKTilleggsromResultaterSchema>;

export const GKKontrollAvRomtrykkResultaterSchema = z.object({
  inndata: z.object({
    tilleggsRomResultater: z.array(GKTilleggsromResultaterSchema),
    kommentar: z.string(),
  }),
});

export type IGKKontrollAvRomtrykkResultater = z.infer<typeof GKKontrollAvRomtrykkResultaterSchema>;

export const GKTilleggsromLAFTakResultaterSchema = z.object({
  romNr: z.string(),
  romNavn: z.string(),
  prosjektertTrykkdifferanse: z.number().nullable(),
  maaltTrykkdifferanse: z.number().nullable(),
});

export type IGKTilleggsromLAFTakResultater = z.infer<typeof GKTilleggsromLAFTakResultaterSchema>;

export const GKMaalingAvOvertrykkResultaterSchema = z.object({
  inndata: z.object({
    tilleggsRomResultater: z.array(GKTilleggsromLAFTakResultaterSchema),
    kommentar: z.string(),
  }),
});

export type IGKMaalingAvOvertrykkResultater = z.infer<typeof GKMaalingAvOvertrykkResultaterSchema>;

export const GKLekkasjeskanningAvHepaResultaterSchema = z.object({
  inndata: z.object({
    maaleresultater: z.array(GKFiltertestResultaterSchema),
    kommentar: z.string(),
  }),
});

export type IGKLekkasjeskanningAvHepaResultater = z.infer<typeof GKLekkasjeskanningAvHepaResultaterSchema>;

export const GKRecoveryTestResultaterSchema = z.object({
  inndata: z.object({
    bakgrunnskonsentrasjonFoerTest: z.number().nullable(),
    luftskifterIRommet: z.number().nullable(),
    startkonsentrasjon: z.number().nullable(),
    maaleresultater: z.array(GKRecoveryTestMaalepunktResultaterSchema),
    kommentar: z.string(),
  }),
  utdata: z.object({
    teoretiskUttynningstid: z.number().nullable(),
  }),
});

export type IGKRecoveryTestResultater = z.infer<typeof GKRecoveryTestResultaterSchema>;

export const GKPartikkelTellingIRomResultaterSchema = z.object({
  inndata: z.object({
    euGmpKlasse: GKPartikkelTellingEuGmpOptionsSchema,
    isoKlasse: GKPartikkelTellingIsoOptionsSchema,
    romAreal: z.number().nullable(),
    valgtePartikkelstoerrelser: z.record(z.boolean()),
    maalehasighetTilPartikkelteller: z.number().nullable(),
    maaletid: z.number().nullable(),
    maaleresultater: z.object({
      size01: z.array(GKPartikkelMaaleresultaterSchema),
      size02: z.array(GKPartikkelMaaleresultaterSchema),
      size03: z.array(GKPartikkelMaaleresultaterSchema),
      size05: z.array(GKPartikkelMaaleresultaterSchema),
      size1: z.array(GKPartikkelMaaleresultaterSchema),
      size5: z.array(GKPartikkelMaaleresultaterSchema),
    }),
    kommentar: z.string(),
  }),
  utdata: z.object({
    minimumAntallProevetakingspunkter: z.number().nullable(),
    volumPerProevetaking: z.number().nullable(),
    minimumsvolumPerProevetakingEUGMP_05: z.number().nullable(),
    minimumsvolumPerProevetakingEUGMP_5: z.number().nullable(),
    verdierPerProevetakingISO: z.array(GKVerdierPerProevetakingResultaterSchema),
    klasseGrenseNivaaEUGMP_05: z.number().or(z.string()).nullable(),
    klasseGrenseNivaaEUGMP_5: z.number().or(z.string()).nullable(),
  }),
});

export type IGKPartikkelTellingIRomResultater = z.infer<typeof GKPartikkelTellingIRomResultaterSchema>;

export const GKLufthastighetsmaalingOgDokuAvLaminaerLuftstroemResultaterSchema = z.object({
  inndata: z.object({
    valgtMetode: z.record(z.boolean()),
    kommentar: z.string(),
    lufthastighetsMaalingResultater: z.object({
      maaleresultater: z.array(GKLufthastigetsmaalingMaaleresultaterSchema),
    }),
    dokumentasjonAvLuftstroemResultater: z.object({
      beskrivelseAvLuftstroem: z.string(),
      godkjent: z.boolean().nullable(),
    }),
  }),
  utdata: z.object({
    gjennomsnittligHastighet: z.number().nullable(),
    varians: z.number().nullable(),
    standardAvvik: z.number().nullable(),
    lufthastighetUniformitet: z.number().nullable(),
  }),
});

export type IGKLufthastighetsmaalingOgDokuAvLaminaerLuftstroemResultater = z.infer<
  typeof GKLufthastighetsmaalingOgDokuAvLaminaerLuftstroemResultaterSchema
>;

export const GKBeregningAvLufthastighetGjennomArbeidsaapningResultaterSchema = z.object({
  inndata: z.object({
    arealLukeaapning: z.number().nullable(),
    valgtMetode: z.string(),
    kommentar: z.string(),
    maaleresultater: z.array(GKBeregningAvLufthastighetGjennomArbeidsaapningMaaleresultaterSchema),
    avtrekkskanalResultater: z.object({
      diameter: z.number().nullable(),
    }),
  }),
  utdata: z.object({
    gjennomsnittligHastighet: z.number().nullable(),
    avtrekkskanalBeregninger: z.object({
      tverrsnittareal: z.number().nullable(),
      arealForHvertPunkt: z.number().nullable(),
      avkastLuftmengde: z.number().nullable(),
    }),
    lukeaapningBeregninger: z.object({
      luftmengdeGjennomLukeaapning: z.number().nullable(),
      varians: z.number().nullable(),
      standardAvvik: z.number().nullable(),
    }),
  }),
});

export type IGKBeregningAvLufthastighetGjennomArbeidsaapningResultater = z.infer<
  typeof GKBeregningAvLufthastighetGjennomArbeidsaapningResultaterSchema
>;

export const GKMaalingAvTrykkfallOverHepafilterResultaterSchema = z.object({
  inndata: z.object({
    maaleresultater: z.array(GKHepafilterMaalepunktResultaterSchema),
    kommentar: z.string(),
  }),
});

export type IGKMaalingAvTrykkfallOverHepafilterResultater = z.infer<
  typeof GKMaalingAvTrykkfallOverHepafilterResultaterSchema
>;

export const GKLufthastighetmaalingIArbeisomraadeResultaterSchema = z.object({
  inndata: z.object({
    maaleresultater: z.array(GKLufthastighetmaalingIArbeidsomraadeMaaleresultaterSchema),
    displayVerdi: z.number().nullable(),
    kommentar: z.string(),
  }),
  utdata: z.object({
    gjennomsnittligHastighet: z.number().nullable(),
  }),
});

export type IGKLufthastighetmaalingIArbeisomraadeResultater = z.infer<
  typeof GKLufthastighetmaalingIArbeisomraadeResultaterSchema
>;

export const GKLekkasjetestResultaterSchema = z.object({
  inndata: z.object({
    maaleresultater: z.array(GKLekkasjetestMaaleresultaterSchema),
    godkjent: z.boolean().nullable(),
    kommentar: z.string(),
  }),
});

export type IGKLekkasjetestResultater = z.infer<typeof GKLekkasjetestResultaterSchema>;

export const GKMaalingAvLufthastighetVedOperasjonssengResultaterSchema = z.object({
  inndata: z.object({
    maaleresultater: z.array(z.number().nullable()),
    kommentar: z.string(),
  }),
});

export type IGKMaalingAvLufthastighetVedOperasjonssengResultater = z.infer<
  typeof GKMaalingAvLufthastighetVedOperasjonssengResultaterSchema
>;

export const GKRapportSchema = z.object({
  generellInfo: z.object({
    oppdragsnavn: z.string(),
    emne: z.string(),
    oppdragsgiver: z.string(),
    kontaktperson: z.string(),
    kopi: z.string(),
    dokumentkode: z.string(),
    tilgjengelighet: z.string(),
    oppdragsleder: z.string(),
    utarbeidetAv: z.string(),
    ansvarligEnhet: z.string(),
    generelt: z.string(),
    sammendrag: z.string(),
    romNr: z.string(),
    etasje: z.string(),
    dato: z.string(),
    sted: z.string(),
    utstyr: z.string(),
    serienummerUtstyr: z.string(),
  }),
  testInnstillinger: GKTestInnstillingerSchema,
  testResultater: z.object({
    inneslutningsLekkasjeTestMedFotometer: GKInneslutningsLekkasjetestMedFotometerResultaterSchema,
    luftmengdeMaalingIRom: GKLuftmengdemaalingResultaterSchema,
    lufthastighetskontroll: GKLufthastighetskontrollResultaterSchema,
    kontrollAvRomtrykk: GKKontrollAvRomtrykkResultaterSchema,
    lekkasjeskanningAvHepa: GKLekkasjeskanningAvHepaResultaterSchema,
    kontrollAvLuftstroem: GKKontrollAvLuftStroemResultaterSchema,
    recoveryTest: GKRecoveryTestResultaterSchema,
    partikkelTellingIRom: GKPartikkelTellingIRomResultaterSchema,
    partikkelTellingForUtstyr: GKPartikkelTellingForUtstyrResultaterSchema,
    temperaturmaaling: GKTemperaturmaalingResultaterSchema,
    relativFuktighetsmaaling: GKRelativeFuktighetsmaalingResultaterSchema,
    kontrollAvLuftstroemVedArbeidsaapning: GKKontrollAvLuftStroemVedArbeidsaapningResultaterSchema,
    luftmengdemaalingGjennomArbeidsaapning: GKLuftmengdemaalingGjennomArbeidsaapningResultaterSchema,
    lufthastighetsmaalingOgDokuAvLaminaerLuftstroem: GKLufthastighetsmaalingOgDokuAvLaminaerLuftstroemResultaterSchema,
    beregningAvLufthastighetGjennomArbeidsaapning: GKBeregningAvLufthastighetGjennomArbeidsaapningResultaterSchema,
    maalingAvTrykkfallOverHepafilter: GKMaalingAvTrykkfallOverHepafilterResultaterSchema,
    kontrollAvAlarm: GKKontrollAvAlarmResultaterSchema,
    bruddtest: GKBruddtestResultaterSchema,
    maalingAvLuftmengdeGjennomInntaksfilter: GKMaalingAvLuftmengdeGjennomInntaksfilterResultaterSchema,
    maalingAvArbeidstrykkOgKontrollAvInternTrykkmaaler:
      GKMaalingAvArbeidstrykkOgKontrollAvInternTrykkmaalerResultaterSchema,
    lufthastighetsMaalingIArbeidsomraade: GKLufthastighetmaalingIArbeisomraadeResultaterSchema,
    maalingAvInnstroemningsHastighet: GKMaalingAvInnstroemningsHastighetResultaterSchema,
    maalingAvDriftstrykk: GKMaalingAvArbeidstrykkOgKontrollAvInternTrykkmaalerResultaterSchema,
    lekkasjetest: GKLekkasjetestResultaterSchema,
    maalingAvLufthastighetILukeaapningRutinekontroll: GKMaalingAvLufthastighetILukeaapningResultaterSchema,
    maalingAvAvtrekksmengdeRutinekontroll: GKMaalingAvAvtrekksmengdeRutinekontrollResultaterSchema,
    trykkfallIAvtrekksskap: GKTrykkfallIAvtrekksskapResultaterSchema,
    visualiseringAvLuftstroem: GKVisualiseringAvLuftstroemResultaterSchema,
    testAvAlarmsystemer: GKTestAvAlarmsystemerResultaterSchema,
    testAvSkapreguleringensResponstid: GKTestAvSkapreguleringensResponstidResultaterSchema,
    maalingAvLufthastighetILukeaapningIgangkjoering: GKMaalingAvLufthastighetILukeaapningResultaterSchema,
    maalingAvAvtrekksmengdeIgangkjoering: GKMaalingAvAvtrekksmengdeIgangkjoeringResultaterSchema,
    maalingAvLufthastighetIRommet: GKMaalingAvLufthastighetIRommetResultaterSchema,
    maalingAvLydtrykk: GKMaalingAvLydtrykkResultaterSchema,
    maalingAvLufthastighetPunktavsug: GKMaalingAvLufthastighetPunktavsugResultaterSchema,
    maalingAvTrykkPunktavsug: GKMaalingAvTrykkPunktavsugResultaterSchema,
    visualiseringMedRoeykPunktavsug: GKVisualiseringMedRoeykPunktavsugResultaterSchema,
    eksternOgInternRoeykVisualisering: GKEksternOgInternRoeykvisualiseringResultaterSchema,
    dekontamineringAvRom: GKDekontamineringAvRomResultaterSchema,
    dekontamineringAvSikkerhetskabinett: GKDekontamineringAvSikkerhetskabinettResultaterSchema,
    funksjonskontrollSikkerhetskabinett: GKFunksjonskontrollSikkerhetskabinettResultaterSchema,
    funksjonskontrollIsolator: GKFunksjonskontrollIsolatorResultaterSchema,
    funksjonskontrollAvtrekksskap: GKFunksjonskontrollAvtrekksskapResultaterSchema,
    maalingAvOvertrykkLAFTak: GKMaalingAvOvertrykkResultaterSchema,
    partikkelTellingUnderLAFTak: GKPartikkelTellingIRomResultaterSchema,
    lekkasjeskanningAvHepaLAFTak: GKLekkasjeskanningAvHepaResultaterSchema,
    lufthastighetskontrollLAFTak: GKLufthastighetskontrollLAFTakResultaterSchema,
    maalingAvLufthastighetVedOperasjonssengLAFTak: GKMaalingAvLufthastighetVedOperasjonssengResultaterSchema,
    funksjonskontrollLAFTak: GKFunksjonskontrollLAFTakResultaterSchema,
  }),
});

export type IGKRapport = z.infer<typeof GKRapportSchema>;

export const GKEUGMPTabellSchema = z.object({
  size05: z.object({
    atRest: z.object({
      gradeA: z.number().nullable(),
      gradeB: z.number().nullable(),
      gradeC: z.number().nullable(),
      gradeD: z.number().nullable(),
    }),
    inOperation: z.object({
      gradeA: z.number().nullable(),
      gradeB: z.number().nullable(),
      gradeC: z.number().nullable(),
      gradeD: z.string(),
    }),
  }),
  size5: z.object({
    atRest: z.object({
      gradeA: z.string(),
      gradeB: z.string(),
      gradeC: z.number().nullable(),
      gradeD: z.number().nullable(),
    }),
    inOperation: z.object({
      gradeA: z.string(),
      gradeB: z.number().nullable(),
      gradeC: z.number().nullable(),
      gradeD: z.string(),
    }),
  }),
});

export type IGKEUGMPTabell = z.infer<typeof GKEUGMPTabellSchema>;

export const GKMaalepunkterTabellSchema = z.object({
  areal: z.number().nullable(),
  maalepunkter: z.number().nullable(),
});

export type IGKMaalepunkterTabell = z.infer<typeof GKMaalepunkterTabellSchema>;

export const GKISOTabellSchema = z.object({
  ISO_1: z.object({
    size01: z.number(),
    size02: z.string(),
    size03: z.string(),
    size05: z.string(),
    size1: z.string(),
    size5: z.string(),
  }),
  ISO_2: z.object({
    size01: z.number(),
    size02: z.number(),
    size03: z.number(),
    size05: z.string(),
    size1: z.string(),
    size5: z.string(),
  }),
  ISO_3: z.object({
    size01: z.number(),
    size02: z.number(),
    size03: z.number(),
    size05: z.number(),
    size1: z.string(),
    size5: z.string(),
  }),
  ISO_4: z.object({
    size01: z.number(),
    size02: z.number(),
    size03: z.number(),
    size05: z.number(),
    size1: z.number(),
    size5: z.string(),
  }),
  ISO_5: z.object({
    size01: z.number(),
    size02: z.number(),
    size03: z.number(),
    size05: z.number(),
    size1: z.number(),
    size5: z.string(),
  }),
  ISO_6: z.object({
    size01: z.number(),
    size02: z.number(),
    size03: z.number(),
    size05: z.number(),
    size1: z.number(),
    size5: z.number(),
  }),
  ISO_7: z.object({
    size01: z.string(),
    size02: z.string(),
    size03: z.string(),
    size05: z.number(),
    size1: z.number(),
    size5: z.number(),
  }),
  ISO_8: z.object({
    size01: z.string(),
    size02: z.string(),
    size03: z.string(),
    size05: z.number(),
    size1: z.number(),
    size5: z.number(),
  }),
  ISO_9g: z.object({
    size01: z.string(),
    size02: z.string(),
    size03: z.string(),
    size05: z.number(),
    size1: z.number(),
    size5: z.number(),
  }),
});

export type IGKISOTabell = z.infer<typeof GKISOTabellSchema>;
