import { IssueDisabledColumnsEnum } from "mc-shared/enums/enums";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Checkbox, McDropdown, McInput, McTitle, SimpleTags } from "../";
import {
  FlatHierarchy,
  IBoardBucket,
  IBoardType,
  IIssue,
  IPNSProps,
  issuePriortyEnums,
  onIssueChange,
  TIssueStatus,
} from "../../../../types/global.types";
import { IMcBimMongo } from "../../../../types/mcbimMono.types";
import {
  ISSUE_COMPLETED_VALUE,
  ISSUE_PRIORTY_OPTIONS,
  ISSUE_STATES_OPTIONS,
} from "../../app/routes/issuesApp/issueConstants";
import { selectSingleIssueBoard } from "../../app/routes/issuesApp/issueSlice";
import McTypeahead from "../McTypeahead/McTypeahead";
import SpaceTypeAhead from "../SpaceTypeAhead/SpaceTypeAhead";

const IssueDetailsSection: React.FC<{
  issue: IIssue;
  showConclusion: boolean;
  boardBuckets: IBoardBucket[];
  boardTypes: IBoardType[];
  onChange: onIssueChange;
  onTagChange: (oldValue: string[], newValue: string[]) => void;
  tagOptions: string[];
  pnsItems: FlatHierarchy<IPNSProps>;
  mcbimSpaces: IMcBimMongo.SpaceBasicInfo[];
  onSpaceTypeAheadFocus: () => void;
  showSpaceInput: boolean;
  disabledColumns?: string[];
}> = ({
  issue,
  showConclusion,
  boardBuckets,
  boardTypes,
  onChange,
  onTagChange,
  tagOptions,
  pnsItems,
  mcbimSpaces,
  onSpaceTypeAheadFocus,
  showSpaceInput,
  disabledColumns,
}) => {
  const issueBoard = useSelector(selectSingleIssueBoard);

  const typeAheadPNSItems = useMemo(() => {
    return (pnsItems || []).map((pns) => ({
      ...pns,
      comboLabel: `${pns.props.pnsId} - ${pns.props.title}`,
    }));
  }, [pnsItems]);

  const defaultSelectedPNS = useMemo(() => {
    const filteredItems = (typeAheadPNSItems || []).filter((p) => p.comboLabel === issue.pns);

    return filteredItems.length > 0
      ? filteredItems
      : [
          {
            comboLabel: issue.pns ?? "",
            _id: "",
            parentId: "",
            props: {
              pnsId: "",
              title: "",
            },
          },
        ];
  }, [typeAheadPNSItems, issue.pns]);

  return (
    <div className="d-flex flex-column">
      <div className="even-columns mt-2">
        <div className="d-flex flex-column mr-4">
          <h5 className="truncateHeaderText">Status</h5>
          <McDropdown
            currentValue={ISSUE_STATES_OPTIONS.find((s) => s.value === issue.status)}
            options={ISSUE_STATES_OPTIONS}
            type="colordot"
            displayKey="value"
            onChange={(oldValue, newValue) => onChange(oldValue.value, newValue.value as TIssueStatus, "status")}
          />
        </div>
        <div className="d-flex flex-column mr-4">
          <h5 className="truncateHeaderText">Prioritet</h5>
          <McDropdown
            currentValue={ISSUE_PRIORTY_OPTIONS.find((p) => p.key === issue?.priority)}
            options={ISSUE_PRIORTY_OPTIONS}
            type="colordot"
            displayKey="displayName"
            onChange={(oldValue, newValue) => onChange(oldValue?.key, newValue?.key as issuePriortyEnums, "priority")}
          />
        </div>
        <div className="d-flex flex-column mr-4">
          {disabledColumns?.includes(IssueDisabledColumnsEnum.REFERENCEURL) === false && (
            <>
              <div className="d-flex align-items-center" style={{ justifyContent: "space-between" }}>
                <h5 className="truncateHeaderText">Referanse URL</h5>
                <h5>
                  {/https?:\/\/.*/i.test(issue.referenceUrl) === true ? (
                    <a href={issue.referenceUrl} target="_blank" rel="noreferrer">
                      <i className="fa fa-external-link-square fa-fw" />
                    </a>
                  ) : (
                    <i className="fa fa-external-link-square fa-fw gray-400-text" />
                  )}
                </h5>
              </div>
              <McInput
                placeholder="http://"
                onBlur={(e) => onChange(issue.referenceUrl, e.target.value, IssueDisabledColumnsEnum.REFERENCEURL)}
                value={issue.referenceUrl}
              />
            </>
          )}
        </div>
      </div>
      <div className="even-columns mt-2">
        <div className="d-flex flex-column mr-4">
          {disabledColumns?.includes(IssueDisabledColumnsEnum.BUCKET) === false && (
            <>
              <h5 className="truncateHeaderText">{issueBoard?.bucketNameSetting}</h5>
              <McDropdown
                currentValue={boardBuckets.find((b) => b.value === issue.bucket)}
                options={boardBuckets}
                displayKey="value"
                onChange={(oldValue, newValue) => onChange(oldValue?.value, newValue?.value, "bucket")}
                disabled={issue.taskOrIssue === "TASK"}
              />
            </>
          )}
        </div>
        <div className="d-flex flex-column mr-4">
          {disabledColumns?.includes(IssueDisabledColumnsEnum.TYPEOFISSUE) === false && (
            <>
              <h5>Type</h5>
              <McDropdown
                currentValue={boardTypes.find((t) => t.name === issue.typeOfIssue)}
                options={boardTypes}
                displayKey="name"
                onChange={(oldValue, newValue) =>
                  onChange(oldValue?.name, newValue?.name, IssueDisabledColumnsEnum.TYPEOFISSUE)
                }
              />
            </>
          )}
        </div>
        <div className="d-flex flex-column mr-4">
          {disabledColumns?.includes(IssueDisabledColumnsEnum.PNS) === false && (
            <>
              <h5>PNS</h5>
              <McTypeahead
                id="issuepnstypeahead"
                clearButton
                labelKey="comboLabel"
                options={typeAheadPNSItems.map((item) => {
                  return { comboLabel: `${item.props.pnsId} - ${item.props.title}` };
                })}
                defaultSelected={defaultSelectedPNS ?? []}
                onChange={(items) => {
                  onChange(issue.pns, items[0]?.comboLabel || "", IssueDisabledColumnsEnum.PNS);
                }}
                allowNew
              />
            </>
          )}
        </div>
      </div>
      {disabledColumns?.includes(IssueDisabledColumnsEnum.SPACE) === false && (
        <>
          {showSpaceInput === true && (
            <div className="even-columns mt-2">
              <div className="d-flex flex-column mr-4">
                <McTitle title="Rom">
                  <SpaceTypeAhead
                    value={issue.space}
                    onChange={(val) => onChange(issue.space, val, IssueDisabledColumnsEnum.SPACE)}
                    mcbimSpaces={mcbimSpaces}
                    onSpaceTypeAheadFocus={onSpaceTypeAheadFocus}
                  />
                </McTitle>
              </div>
            </div>
          )}
        </>
      )}
      <div className="d-flex flex-row mt-2">
        <div className="d-flex flex-column w-100 mr-4">
          <div className="d-flex m-0 p-0">
            <h5 className="truncateHeaderText">Beskrivelse</h5>
            <div className="flex-fill" />
            <Checkbox
              color="#ccc"
              label="Vis i kanban"
              isChecked={issue.showTodosInKanban !== true}
              setChecked={() => onChange(issue.showTodosInKanban, !issue.showTodosInKanban, "showTodosInKanban")}
            />
          </div>
          <McInput
            onBlur={(e) => onChange(issue.description, e.target.value, "description")}
            value={issue.description}
            type="textarea"
            dynamicHeight={true}
          />
        </div>
      </div>
      {(issue.status === ISSUE_COMPLETED_VALUE || showConclusion === true) && (
        <div className="d-flex flex-row mt-2">
          <div className="d-flex flex-column w-100 mr-4">
            <h5 className="truncateHeaderText">Konklusjon</h5>
            <McInput
              onBlur={(e) => onChange(issue.conclusion, e.target.value, "conclusion")}
              value={issue.conclusion}
              type="textarea"
              dynamicHeight={true}
            />
          </div>
        </div>
      )}
      <div className="d-flex flex-row mt-2">
        <div className="d-flex flex-column w-100 mr-4">
          <h5 className="truncateHeaderText">Etiketter</h5>
          <SimpleTags
            activeTags={issue.tags}
            tagOptions={tagOptions}
            onChange={(value) => onTagChange(issue.tags, value)}
          />
        </div>
      </div>
    </div>
  );
};

export default IssueDetailsSection;
