import { IGKLuftmengdemaalingResultater, IGKRapport } from "mc-shared/zod/report/GK/gkReportSchema";
import React from "react";
import { Button } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../../../types/global.types";
import { HSpace } from "../../../../../../../components";
import LuftventilAnemometer from "./LuftventilAnemometer";

const AnemometerTest: React.FC<{
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
  data: IGKLuftmengdemaalingResultater;
}> = ({ data, onUpdateEditData }) => {
  const addNewRow = () => {
    const newMaaleresultaterArray = {
      ventilnummer: null,
      maaleomraadeAreal: null,
      andelFrittAreal: null,
      maaleresultater: [],
      beregninger: {
        minimumAntallRuterIRutenett: null,
        frittArealIHverRute: null,
        gjennomsnittligHastighet: null,
        totalLuftmengde: null,
      },
    };

    const updatedMaaleresultaterArray = [
      ...data.inndata.anemometerResultater.resultaterPerVentil,
      newMaaleresultaterArray,
    ];

    onUpdateEditData(
      "testResultater.luftmengdeMaalingIRom.inndata.anemometerResultater.resultaterPerVentil",
      updatedMaaleresultaterArray,
    );

    return updatedMaaleresultaterArray;
  };

  const removeLastRow = () => {
    const updatedMaaleresultaterArray = [...data.inndata.anemometerResultater.resultaterPerVentil];

    if (updatedMaaleresultaterArray.length > 0) {
      updatedMaaleresultaterArray.pop(); // Remove the last element
      onUpdateEditData(
        "testResultater.luftmengdeMaalingIRom.inndata.anemometerResultater.resultaterPerVentil",
        updatedMaaleresultaterArray,
      );
    }
  };

  const onUpdateVentilData = (updatedData?: any, ventilIndex?: number) => {
    const resultaterPerVentil = [...data.inndata.anemometerResultater.resultaterPerVentil];
    resultaterPerVentil[ventilIndex] = updatedData;

    const totalLuftmengde = resultaterPerVentil.reduce((acc, curr) => acc + curr.beregninger.totalLuftmengde, 0);

    const anemometerResultater = {
      ...data.inndata.anemometerResultater,
      resultaterPerVentil: resultaterPerVentil,
      totalLuftmengde: totalLuftmengde,
    };
    onUpdateEditData("testResultater.luftmengdeMaalingIRom.inndata.anemometerResultater", anemometerResultater);
    return totalLuftmengde;
  };

  return (
    <div>
      <h4>Anemometermåling</h4>
      <div className="mt-0 mb-4">
        <div className="d-flex flex-column mb-4">
          <b>Legg til og trekk fra ventiler:</b>
          <div className="d-flex">
            <Button color="success" onClick={addNewRow}>
              Legg til ventil
            </Button>
            <HSpace />
            <Button
              color="danger"
              onClick={removeLastRow}
              disabled={data.inndata?.anemometerResultater?.resultaterPerVentil?.length < 1}
            >
              Fjern siste ventil
            </Button>
          </div>
        </div>
      </div>
      {data.inndata.anemometerResultater.resultaterPerVentil != null &&
        data.inndata.anemometerResultater.resultaterPerVentil?.length > 0 && (
          <>
            {data.inndata.anemometerResultater.resultaterPerVentil.map((resultat, index) => (
              <div className="mb-4" key={index}>
                <b>Luftventil {index + 1}</b>
                <LuftventilAnemometer onUpdateVentilData={onUpdateVentilData} data={resultat} ventilIndex={index} />
              </div>
            ))}
          </>
        )}
      <div className="d-flex justify-content-between mt-2">
        <b className="mb-0">
          Total luftmengde &#40;m<sup>3</sup>/s&#41; ={" "}
          <b>{data.inndata.anemometerResultater.totalLuftmengde?.toFixed(3)}</b>
        </b>
        <p className="mb-0">Formel: &#40;Q = SUM&#40;Vn * Ac&#41;&#41;</p>
      </div>
    </div>
  );
};

export default AnemometerTest;
