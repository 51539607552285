import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IssueViewEnum } from "mc-shared/enums/enums";
import { IAllFilter } from "mc-shared/zod/commonSchema";
import { ISortColumn, ITableTemplate } from "mc-shared/zod/issueSchema";
import * as R from "ramda";
import { IBoard, IDateRange } from "../../../../../types/global.types";
import { getCacheKey } from "../../../apiSlice";
import { AppThunk, IRootState } from "../../../store";
import { IAllIssueColumnStates, ISelectedGisMap, IStoredIssueColumnState } from "../../../types";
import { issueBoardRTK } from "./IssueBoardRTK";
import {
  ISSUE_COLUMN_LS_KEY,
  ISSUE_DATAFILEDS_ENUM,
  ISSUE_FILTERS_LS_KEY,
  ISSUE_VIEW_LS_KEY,
  IssueKanbanGroupingViewEnum,
  IssueSortValueEnum,
} from "./issueConstants";
import { getAllFiltesWithIsActiveModifiedByTableTemplate } from "./issueUtils";

export type IssueDataFieldsObject = {
  [key in keyof typeof ISSUE_DATAFILEDS_ENUM]: boolean;
};

export interface IssueReducer {
  allFilters: IAllFilter[];
  selectedPnsFilterItem: string; // 1.1.1
  freeTextSearch: string;
  activeTableTemplate: ITableTemplate;
  activeView: IssueViewEnum;
  kanbanGroupingView: IssueKanbanGroupingViewEnum;
  selectedGisMap: ISelectedGisMap;
  allColumnState: IAllIssueColumnStates;
  sortedCol: ISortColumn;
  gant: {
    dateRange: IDateRange;
    sortValue: IssueSortValueEnum;
  };
  activeDashboard: string;
}

const initialState: IssueReducer = {
  allFilters: null,
  selectedPnsFilterItem: null,
  freeTextSearch: "",
  activeTableTemplate: null,
  activeView: (localStorage.getItem(ISSUE_VIEW_LS_KEY) as IssueViewEnum) || IssueViewEnum.TABLE,
  kanbanGroupingView: IssueKanbanGroupingViewEnum.STATUS,
  selectedGisMap: null,
  allColumnState: JSON.parse(localStorage.getItem(ISSUE_COLUMN_LS_KEY) || "{}"),
  sortedCol: null,
  gant: {
    dateRange: {
      startDate: null, //formatHtmlInputDateString(new Date().toISOString()),
      endDate: null, //formatHtmlInputDateString(moment().add("3", "month").toISOString()),
      zoomLevel: null, //"all",
    },
    sortValue: null,
  },
  activeDashboard: null,
};

export const clearAllIssueFilterThunk = (): AppThunk => async (dispatch: any, getState) => {
  localStorage.removeItem(ISSUE_FILTERS_LS_KEY);
  let allFilters = getState().issueReducerNew.allFilters;

  const _allFilters = R.clone(allFilters).map((allFilterItem) => {
    if (allFilterItem.options != null) {
      allFilterItem.options = allFilterItem.options.map((opt) => {
        opt.isActive = false;
        return opt;
      });
    }
    if (allFilterItem.bool != null) {
      allFilterItem.bool = false;
    }
    return allFilterItem;
  });
  dispatch(setIssueAllFiltersInRedux(_allFilters));
};

export const loadIssueTableTemplateThunk =
  (tableTemplate: ITableTemplate, issueBoardId: string): AppThunk =>
  async (dispatch: any, getState) => {
    dispatch(setIssueActiveTableTemplateInRedux(tableTemplate));
    if (tableTemplate != null) {
      const allFilters = getState().issueReducerNew.allFilters;
      const _allFilters = getAllFiltesWithIsActiveModifiedByTableTemplate(allFilters, tableTemplate);
      dispatch(setIssueAllFiltersInRedux(_allFilters));

      dispatch(setIssueSortedColInRedux(tableTemplate.sortedCol));
    }
    if (tableTemplate == null) {
      dispatch(updateColumnStateThunk(null, issueBoardId));
      dispatch(clearAllIssueFilterThunk());
      dispatch(setIssueSortedColInRedux(null));
    }
  };

export const toggleIssueBoardBoolFilterThunk = (dataField: string): AppThunk => {
  return (dispatch: any, getState) => {
    const allFilters = R.clone(getState().issueReducerNew.allFilters);
    const filterIndex = allFilters.findIndex((filter) => filter.dataField === dataField);
    if (filterIndex === -1) {
      console.warn("filterIndex === -1");
      return;
    }
    const bool = allFilters[filterIndex].bool;
    allFilters[filterIndex].bool = !bool;
    dispatch(setIssueAllFiltersInRedux(allFilters));
  };
};
export const updateColumnStateThunk = (columnState: IStoredIssueColumnState[], issueBoardId): AppThunk => {
  return (dispatch: any, getState) => {
    const allColumnStateCopy = R.clone(getState().issueReducerNew.allColumnState);
    const updatedData = {
      ...allColumnStateCopy,
      [issueBoardId]: columnState,
    };
    if (columnState === null) {
      delete updatedData[issueBoardId];
    }
    localStorage.setItem(ISSUE_COLUMN_LS_KEY, JSON.stringify(updatedData));
    dispatch(setIssueColumnStateInRedux(updatedData));
  };
};
export const issueSlice = createSlice({
  name: "issue",
  initialState,
  reducers: {
    setIssueAllFiltersInRedux: (state, action: PayloadAction<IAllFilter[]>) => {
      state.allFilters = action.payload;
    },
    toggleOptionInAllFiltersInRedux: (state, action: PayloadAction<{ dataField: string; optionValue: string }>) => {
      const { dataField, optionValue } = action.payload;
      const filterIndex = state.allFilters.findIndex((filter) => filter.dataField === dataField.split(".")[0]);
      const optionIndex = state.allFilters[filterIndex].options.findIndex((option) => option.value === optionValue);
      state.allFilters[filterIndex].options[optionIndex].isActive =
        !state.allFilters[filterIndex].options[optionIndex].isActive;
    },
    setIssueFreeTextSearchInRedux: (state, action: PayloadAction<string>) => {
      state.freeTextSearch = action.payload;
    },
    toggleAllMultiIssuesFilterInRedux: (state, action: PayloadAction<{ dataField: string; isActive: boolean }>) => {
      const { dataField, isActive } = action.payload;
      const filterIndex = state.allFilters.findIndex((filter) => filter.dataField === dataField);
      if (filterIndex === -1) {
        console.warn("filterIndex === -1");
        return;
      }
      state.allFilters[filterIndex].options = state.allFilters[filterIndex].options.map((option) => {
        return { ...option, isActive: isActive };
      });
    },
    setIssueActiveTableTemplateInRedux: (state, action: PayloadAction<ITableTemplate>) => {
      state.activeTableTemplate = action.payload;
    },
    setIssueActiveViewInRedux: (state, action: PayloadAction<IssueViewEnum>) => {
      localStorage.setItem(ISSUE_VIEW_LS_KEY, action.payload);
      state.activeView = action.payload;
    },
    setKanbanGroupingViewInRedux: (state, action: PayloadAction<IssueKanbanGroupingViewEnum>) => {
      state.kanbanGroupingView = action.payload;
    },
    resetIssueRedux: (state) => {
      state.allFilters = null;
      state.freeTextSearch = "";
      state.activeTableTemplate = null;
      state.kanbanGroupingView = IssueKanbanGroupingViewEnum.STATUS;
    },
    setIssueSelectedGisMapInRedux: (state, action: PayloadAction<ISelectedGisMap>) => {
      state.selectedGisMap = action.payload;
    },
    setIssueColumnStateInRedux: (state, action: PayloadAction<IAllIssueColumnStates>) => {
      state.allColumnState = action.payload;
    },
    setDateRangeInRedux: (state, action: PayloadAction<IDateRange>) => {
      state.gant.dateRange = action.payload;
    },
    setSelectedPnsFilterItemInRedux: (state, action: PayloadAction<string>) => {
      state.selectedPnsFilterItem = action.payload;
    },
    setIssueSortedColInRedux: (state, action: PayloadAction<ISortColumn>) => {
      state.sortedCol = action.payload;
    },
    setSortValueInRedux: (state, action: PayloadAction<IssueSortValueEnum>) => {
      state.gant.sortValue = action.payload;
    },
    setActiveDashboardInRedux: (state, action: PayloadAction<string>) => {
      state.activeDashboard = action.payload;
    },
  },
});

export const selectSingleIssueBoard = (state: IRootState): IBoard => {
  const queries = state.api.queries;
  const cacheKey = getCacheKey(queries, "getSingleIssueBoard");

  const resp = issueBoardRTK.endpoints.getSingleIssueBoard.select(cacheKey);
  return resp(state).data;
};

export const {
  setIssueAllFiltersInRedux,
  toggleOptionInAllFiltersInRedux,
  setIssueFreeTextSearchInRedux,
  toggleAllMultiIssuesFilterInRedux,
  setIssueActiveTableTemplateInRedux,
  setIssueActiveViewInRedux,
  setKanbanGroupingViewInRedux,
  resetIssueRedux,
  setIssueSelectedGisMapInRedux,
  setDateRangeInRedux,
  setSelectedPnsFilterItemInRedux,
  setIssueSortedColInRedux,
  setSortValueInRedux,
  setActiveDashboardInRedux,
  setIssueColumnStateInRedux,
} = issueSlice.actions;

export default issueSlice.reducer;
