import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import React from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { ConfirmDialog, HSpace, VSpace } from "../";
import { IDocxTemplate } from "../../../../types/global.types";
import { AG_GRID_LOCALE_NOR } from "../../agGridLocale";
import { formatShortDate } from "../../app/routes/admin/selectors";
import NewDocxUpload from "./NewDocxUpload/NewDocxUpload";

const DocxReport: React.FC<{
  docxTemplates: IDocxTemplate[];
  uploadFunc: (event: React.ChangeEvent<HTMLInputElement>, level?: string) => void;
  uploadCompleedCb: () => void;
  generateDocx: (docxTemplate: IDocxTemplate) => void;
  downloadTemplate: (docxTemplate: IDocxTemplate) => void;
  deleteDocx: (docxTemplate) => void;
  fetchAndSet?: () => void;
  closeModal: () => void;
  demoDocxData: any;
}> = ({
  docxTemplates,
  uploadFunc,
  uploadCompleedCb,
  generateDocx,
  downloadTemplate,
  deleteDocx,
  fetchAndSet,
  closeModal,
  demoDocxData,
}) => {
  const deleteFile = async (docxTemplate: IDocxTemplate) => {
    await deleteDocx(docxTemplate);
    if (fetchAndSet != null) {
      fetchAndSet();
    }
  };

  const upload = async (event: React.ChangeEvent<HTMLInputElement>, level?: string) => {
    await uploadFunc(event, level);
    if (fetchAndSet != null) {
      fetchAndSet();
    }
  };

  return (
    <Modal isOpen size="lg" style={{ width: "800px" }}>
      <ModalHeader toggle={closeModal}> Rapportmaler</ModalHeader>
      <ModalBody>
        {demoDocxData != null && (
          <>
            <NewDocxUpload
              demoData={demoDocxData}
              uploadFunc={upload}
              uploadCompleedCb={uploadCompleedCb}
              docxTemplates={docxTemplates}
            />
            <VSpace />
            <DocxTable
              docxTemplates={docxTemplates}
              downloadFile={downloadTemplate}
              generateReport={generateDocx}
              deleteFile={deleteFile}
            />
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default DocxReport;

const DocxTable: React.FC<{
  docxTemplates: IDocxTemplate[];
  downloadFile: (docxTemplate: IDocxTemplate) => void;
  generateReport: (docxTemplate: IDocxTemplate) => void;
  deleteFile: (docxTemplate: IDocxTemplate) => void;
}> = ({ docxTemplates, downloadFile, generateReport, deleteFile }) => {
  const columns: ColDef<IDocxTemplate>[] = [
    {
      field: "_id",
      headerName: "",
      hide: true,
    },
    {
      field: "isMulticonsult",
      headerName: "",
      hide: true,
    },
    {
      field: "fileName",
      headerName: "Navn",
      flex: 1,
    },
    {
      field: "uploadedDate",
      headerName: "Opprettet",
      width: 100,
      cellRenderer: (params) => formatShortDate(params.value),
    },
    {
      field: "uploadedBy.name",
      headerName: "Opplastet av",
      width: 160,
      cellRenderer: (params) => <span>{params.value == null ? "Multiconsult" : params.value}</span>,
    },
    {
      field: "templateLevel",
      headerName: "Nivå",
      width: 80,
      cellRenderer: (params) => {
        const level = params.value;
        if (level === "BOARD") return <span>Register</span>;
        if (level === "PROJECT") return <span>Prosjekt</span>;
      },
    },
    {
      field: "_id",
      headerName: "",
      cellRenderer: (params) => {
        return (
          <div className="d-flex">
            <Button title="Last ned mal" onClick={() => downloadFile(params.data)} color="mc-blue" outline>
              <i className="fa fa-download fa-fw" />
            </Button>
            <HSpace />
            <Button title="Test mal med testdata" onClick={() => generateReport(params.data)} color="mc-blue" outline>
              <i className="fa fa-play fa-fw" />
            </Button>
            <HSpace />
            {params.data.isMulticonsult === false && (
              <ConfirmDialog
                title="Slette mal?"
                message="Er du sikker på at du vil slette malen?"
                confirmCb={() => deleteFile(params.data)}
              >
                <Button onClick={() => {}} color="danger" outline>
                  <i className="fa fa-trash fa-fw" />
                </Button>
              </ConfirmDialog>
            )}
          </div>
        );
      },
      width: 180,
      cellStyle: { textAlign: "center" },
    },
  ];

  return (
    <div className="ag-theme-quartz h-100 w-100">
      <AgGridReact
        domLayout={"autoHeight"}
        rowData={docxTemplates}
        defaultColDef={{
          suppressHeaderMenuButton: true,
          cellClass: "d-block",
        }}
        headerHeight={35}
        columnDefs={columns}
        suppressClickEdit={true}
        animateRows={false}
        suppressContextMenu={true}
        localeText={AG_GRID_LOCALE_NOR}
      />
    </div>
  );
};
