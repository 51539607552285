import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";
import { useLocalStorage } from "usehooks-ts";
import { IActiveAssignedItemsCount, INotification, IProject, IUser } from "../../../types/global.types";
import { Avatar, ContactForm } from "../components/";
import NotificationCard from "../components/NotificationCard/NotificationCard";
import SidePanel from "../components/SidePanel/SidePanel";
import TaskBanner from "../components/TaskBanner/TaskBanner";
import UpdateUserNameCard from "../components/UserSettings/UpdateUserNameCard";
import { AppModalEnums } from "../frontendConstants";
import {
  addAppModalThunk,
  logoutThunk,
  selectIsUserMutilconsult,
  setShowSuccessMessageThunk,
  toggleAllNotificationsAsReadThunk,
  toggleNotificationAndReplaceNotificationThunk,
} from "../global/globalSlice";
import { useAppDispatch, useAppSelector } from "../hooks";
import NotificationButton from "./NotificationsButton";
import ReleaseNotesButton from "./ReleaseNotesButton";
import { doSendUserMessage } from "./routes/admin/restQueries";
import { sortArrayByString } from "./utils";

const MCNavbar: React.FC<{ isStartPage: boolean }> = ({ isStartPage = false }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showNotificationTab, setShowNotificationTab] = useState<boolean>(true);
  const [showMarkedAsRead, setShowMarkedAsRead] = useLocalStorage("showMarkedAsRead", true);
  const [openNotificationsTimeStamp, setOpenNotificationsTimeStamp] = useLocalStorage("notificationsLastOpen", null);
  const [openReleaseNotesTimeStamp, setOpenReleaseNotesTimeStamp] = useLocalStorage("releaseNotesLastOpen", null);
  const toggle = () => setIsOpen(!isOpen);

  const userIsMulticonsult = useAppSelector(selectIsUserMutilconsult);

  const project = useAppSelector((state) => state.adminReducer.project);
  const user = useAppSelector((state) => state.globalReducer.user);
  const projects = useAppSelector((state) => state.globalReducer.projects);

  const dispatch = useAppDispatch();
  const _logout = () => {
    dispatch(logoutThunk());
  };

  let history = useHistory();

  const goToProject = async (project: IProject) => {
    if (project.useDashboardAsDefaultStartPage) {
      history.push(`/project/${project._id}/dashboard`);
    } else {
      history.push(`/project/${project._id}/apps`);
    }
  };

  const goToProjectSelection = () => {
    history.push(`/projects`);
  };

  const [projectsLoaded, setProjectLoaded] = useState<boolean>();
  useEffect(() => {
    const _projectLoaded = projects != null && project != null && isStartPage === false;
    setProjectLoaded(_projectLoaded);
  }, [projects, project, isStartPage]);

  useEffect(() => {
    if (openNotificationsTimeStamp === null) {
      setOpenNotificationsTimeStamp(moment().format());
    }
  }, [openNotificationsTimeStamp]);

  useEffect(() => {
    if (openReleaseNotesTimeStamp === null) {
      setOpenReleaseNotesTimeStamp(moment().format());
    }
  }, [openReleaseNotesTimeStamp]);

  const toggleNotificationTab = () => {
    setOpenNotificationsTimeStamp(moment().format());
    setShowNotificationTab(!showNotificationTab);
  };

  const onClickReleaseNotesTimeStamp = () => {
    setOpenReleaseNotesTimeStamp(moment().format());
  };

  const openModalAndMarkAsRead = (notification: INotification) => {
    const projectId = notification.project as IProject;
    if (notification.markedAsRead?.value === false) {
      dispatch(toggleNotificationAndReplaceNotificationThunk(notification._id));
    }
    if (notification.app === "DASHBOARD") {
      history.push(`/project/${project._id}/dashboard/?layout=${notification.itemDBid}`);
      return;
    }
    if (notification.app === "MEETING") {
      history.push(`/project/${project._id}/meetings/${notification.boardId}`);
      return;
    }

    if (notification.app === "CHECKLIST") {
      history.push(`/project/${project._id}/checklist/instance/${notification.boardId}?id=${notification.itemDBid}`);
      return;
    }
    dispatch(
      addAppModalThunk({
        itemId: notification.itemDBid,
        boardId: notification.boardId,
        projectId: projectId._id,
        app: AppModalEnums.ISSUE,
      }),
    );
  };

  const toggleMarkedAsRead = (notification: INotification) => {
    dispatch(toggleNotificationAndReplaceNotificationThunk(notification._id));
  };
  const checkCondition = (notification: INotification) => {
    return showMarkedAsRead === true ? true : !notification.markedAsRead.value;
  };

  const markAllNotificationsAsRead = () => {
    dispatch(toggleAllNotificationsAsReadThunk());
  };

  const sendUserMessage = async (message: string, subject: string, closeModal: () => void) => {
    try {
      await doSendUserMessage(subject, message);
      dispatch(setShowSuccessMessageThunk("Takk for tilbakemeldingen.\nVi vil komme tilbake til deg så snart vi kan."));
      closeModal();
    } catch (error) {
      dispatch(setShowSuccessMessageThunk("Noe gikk galt! Prøv å sende en e-post i stedet."));
    }
  };

  return (
    <div>
      <Navbar color="dark" dark expand="md">
        {isStartPage && <NavbarBrand href="/" />}
        <div className="d-flex align-items-center flex-grow-1">
          <NavbarToggler onClick={toggle} />
          <div>
            <Collapse isOpen={isOpen} navbar>
              {projectsLoaded && (
                <Nav className="mr-auto w-100" navbar>
                  <NavItem className="mr-2">
                    {project != null && (
                      <ProjectsSelectorDropdown
                        activeProject={project}
                        projects={projects}
                        goToProject={goToProject}
                        goToProjectSelection={goToProjectSelection}
                      />
                    )}
                  </NavItem>
                </Nav>
              )}
            </Collapse>
          </div>
          <div style={{ display: "flex", flexGrow: 1, margin: "0px 10px", justifyContent: "center" }}>
            <MCAppsLogo />
          </div>
          <div>
            <Nav className="mr-auto d-flex align-items-center flex-row" navbar>
              <NavItem className="d-flex align-items-center">
                <ReleaseNotesButton
                  onClickReleaseNotesTimeStamp={onClickReleaseNotesTimeStamp}
                  lastOpenedDate={openReleaseNotesTimeStamp}
                />
              </NavItem>
              <NavItem>
                <SupportMenu userIsMultiConsult={userIsMulticonsult} sendUserMessage={sendUserMessage} />
              </NavItem>
              <NavItem className="d-flex align-items-center">
                <NotificationButton
                  toggleNotificationTab={toggleNotificationTab}
                  notifications={user.notifications}
                  lastOpenedDate={openNotificationsTimeStamp}
                />{" "}
              </NavItem>
              <NavItem className="d-flex align-items-center">
                <MyTasksButton assignedItems={user.activeAssignedItemsCount} />
              </NavItem>
              <UserMenu user={user} logout={_logout} />
            </Nav>
          </div>
        </div>
      </Navbar>
      <SidePanel collapsed={showNotificationTab}>
        <>
          <div className="d-flex justify-content-between">
            <Button id="toggleNotificationPanel" onClick={() => toggleNotificationTab()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
                fill="currentColor"
                className="customIcon"
                style={{ height: "1.3em" }}
              >
                <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
              </svg>
            </Button>
            <UncontrolledTooltip delay={300} target="toggleNotificationPanel">
              Skjul notifikasjoner
            </UncontrolledTooltip>
            <div>
              <Button id="markAllNotificationsAsRead" onClick={() => markAllNotificationsAsRead()}>
                <i className="fa fa-envelope-open" />
              </Button>
              <UncontrolledTooltip delay={300} target="markAllNotificationsAsRead">
                Sett alle notifikasjoner som lest
              </UncontrolledTooltip>
              <Button id="toggleReadButton" onClick={() => setShowMarkedAsRead(!showMarkedAsRead)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  fill={`${showMarkedAsRead ? "orange" : "currentColor"}`}
                  className="customIcon"
                >
                  <path d="M32 32H480c17.7 0 32 14.3 32 32V96c0 17.7-14.3 32-32 32H32C14.3 128 0 113.7 0 96V64C0 46.3 14.3 32 32 32zm0 128H480V416c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V160zm128 80c0 8.8 7.2 16 16 16H336c8.8 0 16-7.2 16-16s-7.2-16-16-16H176c-8.8 0-16 7.2-16 16z" />
                </svg>
              </Button>
              <UncontrolledTooltip delay={300} target="toggleReadButton">
                Vis / skjul leste meldinger
              </UncontrolledTooltip>
            </div>
          </div>
          <div className="flex-1 overflow-auto px-2 my-2">
            {user?.notifications?.map((notification, index) => {
              return (
                checkCondition(notification) && (
                  <NotificationCard
                    key={index}
                    notification={notification}
                    onCardClick={(notification) => openModalAndMarkAsRead(notification)}
                    toggleMarkedAsRead={toggleMarkedAsRead}
                  />
                )
              );
            })}
          </div>
        </>
      </SidePanel>
      {!window.location.href.replace(/\/+$/, "").endsWith("tasks") && user.activeAssignedItemsCount?.overdue > 0 && (
        <TaskBanner
          userName={user.surname ? user.surname : user.name}
          overdue={user.activeAssignedItemsCount?.overdue}
        />
      )}
    </div>
  );
};

export default MCNavbar;

const MCAppsLogo = () => (
  <span className="text-white font-weight-bold" style={{ opacity: 0.5, fontSize: "18px" }}>
    <i>MCAPPS</i>
  </span>
);

export const UserMenu: React.FC<{ user: IUser; logout: any }> = ({ user, logout }) => {
  const [showUserSettings, setShowUserSettings] = useState<boolean>(false);
  return (
    <>
      <UncontrolledDropdown>
        <DropdownToggle className="d-flex d-inline-block" tag="span" data-toggle="dropdown">
          <span className="d-flex align-items-center ml-2" style={{ cursor: "pointer" }}>
            <Avatar id={user._id} />
          </span>
        </DropdownToggle>
        <DropdownMenu end>
          <DropdownItem onClick={() => setShowUserSettings(true)}>Instillinger</DropdownItem>
          <DropdownItem onClick={() => logout()}>Logg ut</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      {showUserSettings === true && (
        <Modal style={{ maxWidth: "60vw" }} isOpen={showUserSettings} toggle={() => setShowUserSettings(false)}>
          <ModalHeader toggle={() => setShowUserSettings(false)}>Brukerinstillinger</ModalHeader>
          <ModalBody>
            <UpdateUserNameCard user={user} />
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export const ProjectsSelectorDropdown: React.FC<{
  projects: IProject[];
  goToProject: (project: IProject) => void;
  activeProject: IProject;
  goToProjectSelection: () => void;
}> = ({ projects, goToProject, activeProject, goToProjectSelection }) => {
  return (
    <UncontrolledDropdown>
      <DropdownToggle tag="a" className="text-light font-weight-bold" caret>
        {activeProject.maconomy.name}
      </DropdownToggle>
      <DropdownMenu style={{ zIndex: 10000, maxHeight: "50vh", overflow: "auto" }}>
        <DropdownItem onClick={() => goToProjectSelection()}>Gå til dine prosjekter</DropdownItem>
        <DropdownItem divider />
        {sortArrayByString(projects, "maconomy.name").map((project) => (
          <DropdownItem
            key={project._id}
            active={activeProject._id === project._id}
            onClick={() => goToProject(project)}
          >
            {project.maconomy.name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

const SupportMenu: React.FC<{
  userIsMultiConsult: boolean;
  sendUserMessage: (message: string, subject: string, closeModal: () => void) => Promise<void>;
}> = ({ userIsMultiConsult, sendUserMessage }) => {
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(true);
  const [showContactForm, setShowContactForm] = useState<boolean>(false);

  const supportItems = {
    teams: {
      name: "Teams",
      icon: "images/teams_icon.svg",
      link: "https://teams.microsoft.com/l/team/19%3a160b1c8d39394b65b06f619a179f0599%40thread.skype/conversations?groupId=72448e5d-7b2c-4ed5-933b-2d939c4d87a1&tenantId=81ab7478-8d3c-4736-8b54-7f2bfd9fa20e",
    },
    yammer: {
      name: "Yammer",
      icon: "images/yammer_icon.svg",
      link: "https://web.yammer.com/main/org/multiconsult.no/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIxMTQ1NDg2NjIyNzIifQ",
    },
    email: {
      name: "E-post",
      link: "mailto:mcapps@multiconsult.no",
    },
  };

  const send = async (message: string, subject: string) => {
    sendUserMessage(message, subject, () => setShowContactForm(false));
  };

  return (
    <>
      {userIsMultiConsult === true ? (
        <UncontrolledDropdown>
          <DropdownToggle nav style={{ padding: "0px" }}>
            <Button
              id="navbarSupportButton"
              color="transparent"
              className="nav-button nav-button-hover"
              style={{ fontSize: "16px", padding: "0.4rem" }}
              onFocus={() => setTooltipVisible(!tooltipVisible)}
              onBlur={() => setTooltipVisible(!tooltipVisible)}
            >
              <i className="fa fa-question-circle fa-fw" />
            </Button>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => setShowContactForm(true)} key={supportItems.email.name}>
              <div className="w-100">
                <i className="fa fa-envelope fa-fw mr-2" />
                {supportItems.email.name}
              </div>
            </DropdownItem>
            <DropdownItem
              onClick={() =>
                window.open(supportItems.teams.link, "_blank") || window.location.replace(supportItems.teams.link)
              }
              key={supportItems.teams.name}
            >
              <div className="w-100">
                <img
                  className=" mr-2"
                  style={{ width: "1.3em", height: "1.3em", color: "white", filter: "invert(1)" }}
                  alt={supportItems.teams.name}
                  src={supportItems.teams.icon}
                ></img>
                {supportItems.teams.name}
              </div>
            </DropdownItem>
            <DropdownItem
              onClick={() =>
                window.open(supportItems.yammer.link, "_blank") || window.location.replace(supportItems.yammer.link)
              }
              key={supportItems.yammer.name}
            >
              <div className="w-100">
                <img
                  className=" mr-2"
                  style={{ width: "1.3em", height: "1.3em", color: "white", filter: "invert(1)" }}
                  alt={supportItems.yammer.name}
                  src={supportItems.yammer.icon}
                ></img>
                {supportItems.yammer.name}
              </div>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ) : (
        <Button
          id="navbarSupportButton"
          color="transparent"
          className="nav-button nav-button-hover"
          style={{ fontSize: "16px", fontWeight: 300, padding: "8px" }}
          onClick={() => setShowContactForm(true)}
          onFocus={() => setTooltipVisible(!tooltipVisible)}
          onBlur={() => setTooltipVisible(!tooltipVisible)}
        >
          <i className="fa fa-question-circle fa-fw" />
        </Button>
      )}
      {tooltipVisible && <UncontrolledTooltip target="navbarSupportButton">Få hjelp</UncontrolledTooltip>}
      <Modal style={{ maxWidth: "60vw" }} isOpen={showContactForm} toggle={() => setShowContactForm(false)}>
        <ModalHeader toggle={() => setShowContactForm(false)}>Kontakt</ModalHeader>
        <ModalBody>
          <ContactForm sendMessage={send} />
        </ModalBody>
      </Modal>
    </>
  );
};

export const MyTasksButton: React.FC<{ assignedItems: IActiveAssignedItemsCount }> = ({ assignedItems }) => {
  return (
    <Button
      id="tasks"
      color="transparent"
      className={`nav-button nav-button-hover ${assignedItems?.total > 0 && "d-flex align-items-baseline"}`}
      style={{ fontSize: "16px", padding: "0.4rem" }}
      href="#/tasks/"
    >
      <i className={`fa fa-check-square fa-fw ${assignedItems?.overdue > 0 ? "text-primary-clickable" : ""}`} />
      {assignedItems?.total > 0 && <div className="text-white ml-1 nav-button-hover">{assignedItems.total}</div>}
      <UncontrolledTooltip target="tasks">Mine oppgaver</UncontrolledTooltip>
    </Button>
  );
};
