import React from "react";
import { Col, Nav, NavItem, NavLink, Row } from "reactstrap";
import classnames from "classnames";
import McBimFilePage from "./McBimFilePage/McBimFilePage";
import { useGetProjectQuery } from "../../../projectRTK";
import { useGetMcBimProjectQuery } from "./McBimRTK";
import { getProjectIdFromUrl } from "../../../utils";
import { useHistory } from "react-router-dom";
import McBimConfigPage from "./McBimConfigPage/McBimConfigPage";
import McBimMongoExtractedFiles from "./MCBimExtractedFiles/McBimMongoExtractedFiles";
import McBimJobbPage from "./McBimJobbPage/McBimJobbPage";
import McBimOverviewPage from "./McBimOverviewPage";
import McBimDocumentation from "../../../../components/DocumentMenu/McBimDocumentation";
import McBimActivate from "./McBimActivate";
import { FailedAlert, MCLoader } from "../../../../components";

const MCBIMSettingsPage = () => {
  const [activeTab, setActiveTab] = React.useState<
    "ACTIVE_FILES" | "SETTINGS" | "EXTRACTED_FILES" | "JOBS" | "OVERVIEW" | "DOCS"
  >("ACTIVE_FILES");

  const history = useHistory();
  const projectId = getProjectIdFromUrl(history.location.pathname);

  const { data: project } = useGetProjectQuery(projectId);

  const {
    data: mcbimProject,
    isLoading: mcbimProjectLoading,
    error: mcbimProjectError,
  } = useGetMcBimProjectQuery(projectId, {
    skip: project?.mcbim?.activated !== true,
  });

  console.log(mcbimProject);
  const mcbimIsActivated = project?.mcbim?.activated;

  return (
    <div className="d-flex flex-column h-100">
      {mcbimProjectLoading && <MCLoader />}
      {mcbimIsActivated && mcbimProjectError && <FailedAlert />}
      {mcbimIsActivated !== true && (
        <Row className="w-100 h-100 mt-5">
          <Col xs="3" />
          <Col xs="6">
            <McBimActivate />
          </Col>
          <Col xs="3" />
        </Row>
      )}
      {mcbimIsActivated && mcbimProject != null && project != null && (
        <>
          <Nav tabs className="pt-2 px-2">
            <NavItem className="custom">
              <NavLink
                className={classnames({ active: activeTab === "ACTIVE_FILES", custom: true })}
                onClick={() => {
                  setActiveTab("ACTIVE_FILES");
                }}
              >
                Aktive filer
              </NavLink>
            </NavItem>
            <NavItem className="custom">
              <NavLink
                className={classnames({ active: activeTab === "SETTINGS", custom: true })}
                onClick={() => {
                  setActiveTab("SETTINGS");
                }}
              >
                Innstillinger
              </NavLink>
            </NavItem>
            <NavItem className="custom">
              <NavLink
                className={classnames({ active: activeTab === "EXTRACTED_FILES", custom: true })}
                onClick={() => {
                  setActiveTab("EXTRACTED_FILES");
                }}
              >
                Hentede data
              </NavLink>
            </NavItem>
            <NavItem className="custom">
              <NavLink
                className={classnames({ active: activeTab === "JOBS", custom: true })}
                onClick={() => {
                  setActiveTab("JOBS");
                }}
              >
                Synkronisering
              </NavLink>
            </NavItem>
            <NavItem className="custom">
              <NavLink
                className={classnames({ active: activeTab === "OVERVIEW", custom: true })}
                onClick={() => {
                  setActiveTab("OVERVIEW");
                }}
              >
                Oversikt
              </NavLink>
            </NavItem>
            <NavItem className="custom">
              <NavLink
                className={classnames({ active: activeTab === "DOCS", custom: true })}
                onClick={() => {
                  setActiveTab("DOCS");
                }}
              >
                Dokumentasjon
              </NavLink>
            </NavItem>
          </Nav>
          <>
            {activeTab === "ACTIVE_FILES" && <McBimFilePage project={project} mcbimProject={mcbimProject} />}
            {activeTab === "SETTINGS" && <McBimConfigPage project={project} mcBimProject={mcbimProject} />}
            {activeTab === "EXTRACTED_FILES" && (
              <McBimMongoExtractedFiles project={project} mcBimProject={mcbimProject} />
            )}
            {activeTab === "JOBS" && <McBimJobbPage project={project} mcbimProject={mcbimProject} />}
            {activeTab === "OVERVIEW" && <McBimOverviewPage />}
            {activeTab === "DOCS" && <McBimDocumentation />}
          </>
        </>
      )}
    </div>
  );
};

export default MCBIMSettingsPage;
