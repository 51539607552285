import { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import OrgChart from "../../app/routes/orgChart/OrgChart";
import { useAppSelector } from "../../hooks";
import WidgetWrapper from "../WidgetWrapper/WidgetWrapper";

const OrgChartWidget: React.FC<any> = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  const project = useAppSelector((state) => state.adminReducer.project);
  return (
    <>
      <WidgetWrapper title="Organisasjonskart" widgetKey="orgChart" onFullscreenClick={() => setOpenModal(true)}>
        {project.orgChart && project.orgChart.items && project.orgChart.items.length > 0 && (
          <>
            <OrgChart orgChart={project.orgChart} projectId={project._id} members={project.members} />
            <Modal style={{ maxWidth: "80vw", height: "88vh" }} isOpen={openModal} toggle={() => setOpenModal(false)}>
              <ModalHeader toggle={() => setOpenModal(false)}>Organisasjonskart</ModalHeader>
              <ModalBody>
                <div className="gray-100" style={{ height: "86vh", borderRadius: "6px" }}>
                  <OrgChart orgChart={project.orgChart} projectId={project._id} members={project.members} />
                </div>
              </ModalBody>
            </Modal>
          </>
        )}
      </WidgetWrapper>
    </>
  );
};
export default OrgChartWidget;
