import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBatchjob } from "../../../../../types/batchjob.types";
import { IFolderAgGrid } from "../../../../../types/global.types";
import { ToastMessagesEnums } from "../../../frontendConstants";
import {
  doGetBatchJobs,
  doGetBatchjobTemplates,
  doGetSingleBatchJob,
  doUpdateBatchJobGeneralSettings,
  doUpdateBim360ExportFolder,
  doUpdateRevitToIfcSettingsJobParams,
  doUpdateRevitToIfcSettingsPropertyMapping,
} from "./batchJobAPI";
import {
  doUpdateBatchJobBim360ExportBim360Files,
  doUpdateBatchJobBim360ExportBim360RevitFileView,
} from "./templates/Bim360RevitToIfc/bim360RevitToIfcAPI";
import { AppThunk } from "../../../store";
import { setShowSuccessMessageThunk } from "../../../global/globalSlice";

export interface IBatchJobReducer {
  activeBatchJob: IBatchjob.IBatchJobBoardPopulated;
  batchJobs: IBatchjob.IBatchJobBoardPopulated[];
  templates: string[];
}

const initialState: IBatchJobReducer = {
  activeBatchJob: null,
  batchJobs: null,
  templates: [],
};

export const fetchAndSetBatchJobsThunk = (): AppThunk => async (dispatch: any, getState) => {
  const project = getState().adminReducer.project;
  const batchJobBoards = await doGetBatchJobs(project._id);
  dispatch(setBatchJobsInRedux(batchJobBoards));
};

export const fetchTemplatesThunk =
  (): AppThunk =>
  async (dispatch: any, getState): Promise<string[]> => {
    const project = getState().adminReducer.project;

    if (project == null) {
      return;
    }

    const templates = await doGetBatchjobTemplates(project._id);
    dispatch(setTemplatesInRedux(templates));
  };

export const getBatchJobBoardThunk =
  (batchJobBoardId: string): AppThunk =>
  async (dispatch: any, getState) => {
    const project = getState().adminReducer.project;
    const batchJobBoard = await doGetSingleBatchJob(project._id, batchJobBoardId);
    dispatch(setActiveBatchJobRedux(batchJobBoard));
  };

export const updateBatchJobGeneralThunk =
  (batchJob: IBatchjob.IBatchJobBoardPopulated): AppThunk =>
  async (dispatch: any, getState) => {
    const project = getState().adminReducer.project;
    await doUpdateBatchJobGeneralSettings(project._id, batchJob);
    dispatch(refreshActiveBatchjobThunk());
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.SETTINGS_UPDATED));
  };

export const updateBatchJobBim360ExportFolderThunk =
  (folder: IFolderAgGrid): AppThunk =>
  async (dispatch: any, getState) => {
    const project = getState().adminReducer.project;
    const batchJob = getState().batchJobReducer.activeBatchJob;

    await doUpdateBim360ExportFolder(project._id, batchJob, folder);

    dispatch(refreshActiveBatchjobThunk());
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.SETTINGS_UPDATED));
  };

export const updateBatchJobBim360ExportBim360ExportFilesThunk =
  (bim360RevitExportFiles: IBatchjob.IBim360RevitExportFile[]): AppThunk =>
  async (dispatch: any, getState) => {
    const project = getState().adminReducer.project;
    const bim360RevitToIfc = getState().batchJobReducer.activeBatchJob.bim360Settings;
    await doUpdateBatchJobBim360ExportBim360Files(project._id, bim360RevitToIfc._id, bim360RevitExportFiles);
    dispatch(refreshActiveBatchjobThunk());
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.SETTINGS_UPDATED));
  };

export const updateBatchJobBim360ExportBim360RevitFileViewThunk =
  (fileId: string, revitViewValue: string): AppThunk =>
  async (dispatch: any, getState) => {
    const project = getState().adminReducer.project;
    const bim360RevitToIfc = getState().batchJobReducer.activeBatchJob.bim360Settings;
    await doUpdateBatchJobBim360ExportBim360RevitFileView(project._id, fileId, revitViewValue, bim360RevitToIfc._id);
    dispatch(refreshActiveBatchjobThunk());
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.SETTINGS_UPDATED));
  };

export const updateRevitToIfcSettingsJobParamsThunk =
  (key: string, value): AppThunk =>
  async (dispatch: any, getState) => {
    const project = getState().adminReducer.project;
    const batchJob = getState().batchJobReducer.activeBatchJob;
    await doUpdateRevitToIfcSettingsJobParams(project._id, batchJob.bim360Settings.revitToIfcSettings._id, key, value);
    dispatch(refreshActiveBatchjobThunk());
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.SETTINGS_UPDATED));
  };

export const updateRevitToIfcSettingsPropertyMappingThunk =
  (propertyMapping: IBatchjob.IPropertyMapping): AppThunk =>
  async (dispatch: any, getState) => {
    const project = getState().adminReducer.project;
    const batchJob = getState().batchJobReducer.activeBatchJob;
    await doUpdateRevitToIfcSettingsPropertyMapping(
      project._id,
      batchJob.bim360Settings.revitToIfcSettings._id,
      propertyMapping,
    );
    dispatch(refreshActiveBatchjobThunk());
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.SETTINGS_UPDATED));
  };

const refreshActiveBatchjobThunk = (): AppThunk => async (dispatch: any, getState) => {
  const project = getState().adminReducer.project;
  const batchJob = getState().batchJobReducer.activeBatchJob;
  const updatedBatchjob = await doGetSingleBatchJob(project._id, batchJob._id);
  dispatch(setActiveBatchJobRedux(updatedBatchjob));
};

export const batchJobSlice = createSlice({
  name: "batchjob",
  initialState,
  reducers: {
    resetBatchJobApp: (state) => {
      state = initialState;
    },
    setActiveBatchJobRedux: (state, action: PayloadAction<IBatchjob.IBatchJobBoardPopulated>) => {
      state.activeBatchJob = action.payload;
    },
    setBatchJobsInRedux: (state, action: PayloadAction<IBatchjob.IBatchJobBoardPopulated[]>) => {
      state.batchJobs = action.payload;
    },
    setTemplatesInRedux: (state, action: PayloadAction<string[]>) => {
      state.templates = action.payload;
    },
  },
});

export const { setActiveBatchJobRedux, setBatchJobsInRedux, resetBatchJobApp, setTemplatesInRedux } =
  batchJobSlice.actions;

export default batchJobSlice.reducer;
