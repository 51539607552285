import React, { useMemo } from "react";
import {
  IChecklistItemPopulated,
  IChecklistItemRow,
  IStampSettings,
  onChecklistRowChange,
} from "../../../../../../../types/checklist.types";
import { IMinUser, Paths, PathValue } from "../../../../../../../types/global.types";
import { McDropdownSingleUser, McInput } from "../../../../../components";
import StampCheck from "../../StampCheck";
import { ICheckTypes, ISingleCheckType } from "mc-shared/zod/checklistSchema";

const ChecklistDetailsRowChecksSection: React.FC<
  React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> & {
    checkTypes: ICheckTypes;
    users: IMinUser[];
    isItem: boolean;
    isTemplate: boolean;
    editable: boolean;
    checklistRow: IChecklistItemRow;
    onValueChange: onChecklistRowChange;
    onAssignedToChange: <T extends keyof IChecklistItemRow>(
      path: string,
      newValue: IChecklistItemRow[T],
      oldValue: string,
    ) => void;
    updateRowAttribute: <P extends Paths<IChecklistItemPopulated, 4>>(
      rowId: string,
      path: P,
      oldValue: string,
      newValue: PathValue<IChecklistItemPopulated, P>,
    ) => void;
    stampSettings: IStampSettings;
  }
> = ({
  editable,
  onValueChange,
  onAssignedToChange,
  checklistRow,
  isItem,
  isTemplate,
  checkTypes,
  users,
  updateRowAttribute,
  stampSettings,
}) => {
  const checkColumns = useMemo(() => {
    const res: (ISingleCheckType & { prop: keyof ICheckTypes })[] = [];

    Object.keys(checkTypes).forEach((e: keyof ICheckTypes) => {
      const checkType: ISingleCheckType & { prop: keyof ICheckTypes } = { ...checkTypes[e], prop: e };

      if (checkType.active === true) {
        res.push(checkType);
      }
    });

    while (res.length < 3) {
      res.push(null);
    }

    return res;
  }, [checkTypes]);

  return (
    <div className="d-flex flex-column">
      {isItem === true ? (
        <>
          <div className="even-columns mt-2">
            <div className="d-flex flex-column mr-4">
              <>
                <h5 className="truncateHeaderText">Nummer</h5>
                <div className="truncateHeaderText pt-1">{checklistRow.itemId}</div>
              </>
            </div>
            <div className="d-flex flex-column mr-4">
              <h5 className="truncateHeaderText">Kategori</h5>
              <div className="truncateHeaderText pt-1">{`${checklistRow.categoryId}: ${checklistRow.originalCategory.title}`}</div>
            </div>
            <div className="d-flex flex-column mr-4"></div>
          </div>
          {isTemplate === false && (
            <div className="d-flex flex-column mt-2">
              {checkColumns.map((checkType, i) => (
                <div className="d-flex flex-column mr-4" key={i}>
                  {checkType != null && (
                    <>
                      <h5 className="truncateHeaderText">{checkType?.name}</h5>
                      <StampCheck
                        disabled={checklistRow.inactive.isChecked === true}
                        toggle={(rowId, oldValue, newValue) =>
                          //@ts-ignore
                          updateRowAttribute(rowId, `checks.${checkType.prop}.status`, oldValue, newValue)
                        }
                        checkItem={checklistRow?.originalItem?.checks[checkType.prop]}
                        row={checklistRow}
                        stampSettings={stampSettings}
                      />
                    </>
                  )}
                </div>
              ))}
            </div>
          )}
        </>
      ) : (
        <>
          <div className="even-columns mt-2">
            <div className="d-flex flex-column mr-4">
              <h5 className="truncateHeaderText">Kategorinummer</h5>
              {editable === true ? (
                <McInput
                  required={true}
                  value={checklistRow.categoryId}
                  pattern="^\d+$"
                  onBlur={(e) => onValueChange(checklistRow.categoryId, Number(e.target.value), "categoryId")}
                />
              ) : (
                <div className="truncateHeaderText pt-1">{checklistRow.categoryId}</div>
              )}
            </div>
            <div className="d-flex flex-column mr-4">
              {isTemplate === false && (
                <>
                  <h5 className="truncateHeaderText">Frist</h5>
                  <McInput
                    type="date"
                    value={
                      checklistRow.originalCategory.dueDate != null
                        ? new Date(checklistRow.originalCategory.dueDate).toISOString()
                        : ""
                    }
                    onBlur={(e) =>
                      onValueChange(checklistRow.dueDate, new Date(e.target.value).toISOString(), "dueDate")
                    }
                  />
                </>
              )}
            </div>
            <div className="d-flex flex-column mr-4"></div>
          </div>
          {isTemplate === false && (
            <div className="even-columns mt-2">
              {checkColumns.map((checkType, i) => (
                <div className="d-flex flex-column mr-4" key={i}>
                  {checkType != null && (
                    <>
                      <h5 className="truncateHeaderText">Ansvarlig {checkType?.name}</h5>
                      <McDropdownSingleUser
                        filterActive={users.length > 10}
                        options={users}
                        selected={checklistRow?.originalCategory?.checks?.[checkType.prop]?.assignedTo}
                        onClear={
                          checklistRow.originalCategory?.checks?.[checkType.prop]?.assignedTo?.name != null
                            ? () =>
                                onAssignedToChange(
                                  `checks.${checkType.prop}.assignedTo`,
                                  null,
                                  checklistRow?.originalCategory?.checks?.[checkType.prop]?.assignedTo?.name,
                                )
                            : null
                        }
                        onChange={(user) =>
                          onAssignedToChange(
                            `checks.${checkType.prop}.assignedTo`,
                            user?._id,
                            checklistRow?.originalCategory?.checks?.[checkType.prop]?.assignedTo?.name,
                          )
                        }
                      />
                    </>
                  )}
                </div>
              ))}
            </div>
          )}
        </>
      )}
      {isTemplate === false && isItem === true && (
        <div className="d-flex flex-row mt-2">
          <div className="d-flex flex-column w-100 mr-4">
            <h5 className="truncateHeaderText">Rapportkommentar</h5>
            <McInput
              onBlur={(e) => onValueChange(checklistRow.comment, e.target.value, "comment")}
              value={checklistRow.comment}
              type="textarea"
              dynamicHeight={true}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ChecklistDetailsRowChecksSection;
