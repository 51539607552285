import {
  IGKMaalingAvArbeidstrykkOgKontrollAvInternTrykkmaalerResultater,
  IGKRapport,
} from "mc-shared/zod/report/GK/gkReportSchema";
import React, { useEffect } from "react";
import { Card, CardBody, CardTitle, FormGroup, Input, Label } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../../types/global.types";
import { McInput } from "../../../../../../components";
import { beregnetDiffMellomToVerdier } from "../gkGlobalUtils";

const MaalingAvArbeidstrykkOgKontrollAvInternTrykkmaaler: React.FC<{
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
  data: IGKMaalingAvArbeidstrykkOgKontrollAvInternTrykkmaalerResultater;
}> = ({ data, onUpdateEditData }) => {
  useEffect(() => {
    utdataBeregninger();
  }, [
    data.inndata.avlestTrykkHovedkammer,
    data.inndata.maaltInterntrykkHoeyreSluse,
    data.inndata.maaltInterntrykkHoeyreSluse,
    data.inndata.avlestInterntrykkHoeyreSluse,
    data.inndata.maaltInterntrykkVenstreSluse,
    data.inndata.avlestInterntrykkVenstreSluse,
  ]);

  const utdataBeregninger = () => {
    const trykkDiffHovedkammer = beregnetDiffMellomToVerdier(
      data.inndata.maaltTrykkHovedkammer,
      data.inndata.avlestTrykkHovedkammer,
    );
    const trykkDiffHoeyreSluse = beregnetDiffMellomToVerdier(
      data.inndata.maaltInterntrykkHoeyreSluse,
      data.inndata.avlestInterntrykkHoeyreSluse,
    );
    const trykkDiffVenstreSluse = beregnetDiffMellomToVerdier(
      data.inndata.maaltInterntrykkVenstreSluse,
      data.inndata.avlestInterntrykkVenstreSluse,
    );
    const utdata = {
      trykkDiffHovedkammer: trykkDiffHovedkammer,
      trykkDiffHoeyreSluse: trykkDiffHoeyreSluse,
      trykkDiffVenstreSluse: trykkDiffVenstreSluse,
    };
    onUpdateEditData("testResultater.maalingAvArbeidstrykkOgKontrollAvInternTrykkmaaler.utdata", utdata);
  };

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Måling av interntrykk og kontroll av intern trykkmåler</CardTitle>
        <div>
          <div className="d-flex flex-column mt-4">
            <h5 className="mb-0">Hovedkammer:</h5>
            <b>Fyll inn avlest trykk &#40;Pa&#41;:</b>
            <McInput
              value={data.inndata.avlestTrykkHovedkammer}
              normalizeNumbers={true}
              type="text"
              onBlur={(e) =>
                onUpdateEditData(
                  "testResultater.maalingAvArbeidstrykkOgKontrollAvInternTrykkmaaler.inndata.avlestTrykkHovedkammer",
                  Number(e.target.value),
                )
              }
              className="w-25"
            />
          </div>
          <div className="d-flex flex-column mt-2">
            <b>Fyll inn målt trykk &#40;Pa&#41;:</b>
            <McInput
              value={data.inndata.maaltTrykkHovedkammer}
              normalizeNumbers={true}
              type="text"
              onBlur={(e) =>
                onUpdateEditData(
                  "testResultater.maalingAvArbeidstrykkOgKontrollAvInternTrykkmaaler.inndata.maaltTrykkHovedkammer",
                  Number(e.target.value),
                )
              }
              className="w-25"
            />
          </div>
          <div className="d-flex justify-content-between">
            <p className="mb-0">
              Trykkdifferanse &#40;Pa&#41; =
              <b>
                {" "}
                {beregnetDiffMellomToVerdier(
                  data.inndata.avlestTrykkHovedkammer,
                  data.inndata.maaltTrykkHovedkammer,
                )?.toFixed(2) ?? ""}
              </b>
            </p>
            <p className="mb-0">Formel: &#40;Pd = Pm - Pa&#41;</p>
          </div>
        </div>
        <hr className="mt-0" />
        <div>
          <div className="d-flex flex-column mt-0">
            <h5 className="mb-0">Høyre sluse:</h5>
            <b>Fyll inn avlest trykk &#40;Pa&#41;:</b>
            <McInput
              value={data.inndata.avlestInterntrykkHoeyreSluse}
              normalizeNumbers={true}
              type="text"
              onBlur={(e) =>
                onUpdateEditData(
                  "testResultater.maalingAvArbeidstrykkOgKontrollAvInternTrykkmaaler.inndata.avlestInterntrykkHoeyreSluse",
                  Number(e.target.value),
                )
              }
              className="w-25"
            />
          </div>
          <div className="d-flex flex-column mt-2">
            <b>Fyll inn målt trykk &#40;Pa&#41;:</b>
            <McInput
              value={data.inndata.maaltInterntrykkHoeyreSluse}
              normalizeNumbers={true}
              type="text"
              onBlur={(e) =>
                onUpdateEditData(
                  "testResultater.maalingAvArbeidstrykkOgKontrollAvInternTrykkmaaler.inndata.maaltInterntrykkHoeyreSluse",
                  Number(e.target.value),
                )
              }
              className="w-25"
            />
          </div>
          <div className="d-flex justify-content-between">
            <p className="mb-0">
              Trykkdifferanse &#40;Pa&#41; =
              <b>
                {" "}
                {beregnetDiffMellomToVerdier(
                  data.inndata.avlestInterntrykkHoeyreSluse,
                  data.inndata.maaltInterntrykkHoeyreSluse,
                )?.toFixed(2) ?? ""}
              </b>
            </p>
            <p className="mb-0">Formel: &#40;Pd = Pm - Pa&#41;</p>
          </div>
        </div>
        <hr className="mt-0" />
        <div>
          <div className="d-flex flex-column mt-0">
            <h5 className="mb-0">Venstre sluse:</h5>
            <b>Fyll inn avlest trykk &#40;Pa&#41;:</b>
            <McInput
              value={data.inndata.avlestInterntrykkVenstreSluse}
              normalizeNumbers={true}
              type="text"
              onBlur={(e) =>
                onUpdateEditData(
                  "testResultater.maalingAvArbeidstrykkOgKontrollAvInternTrykkmaaler.inndata.avlestInterntrykkVenstreSluse",
                  Number(e.target.value),
                )
              }
              className="w-25"
            />
          </div>
          <div className="d-flex flex-column mt-2">
            <b>Fyll inn målt trykk &#40;Pa&#41;:</b>
            <McInput
              value={data.inndata.maaltInterntrykkVenstreSluse}
              normalizeNumbers={true}
              type="text"
              onBlur={(e) =>
                onUpdateEditData(
                  "testResultater.maalingAvArbeidstrykkOgKontrollAvInternTrykkmaaler.inndata.maaltInterntrykkVenstreSluse",
                  Number(e.target.value),
                )
              }
              className="w-25"
            />
          </div>
          <div className="d-flex justify-content-between">
            <p className="mb-0">
              Trykkdifferanse &#40;Pa&#41; =
              <b>
                {" "}
                {beregnetDiffMellomToVerdier(
                  data.inndata.avlestInterntrykkVenstreSluse,
                  data.inndata.maaltInterntrykkVenstreSluse,
                )?.toFixed(2) ?? ""}
              </b>
            </p>
            <p className="mb-0">Formel: &#40;Pd = Pm - Pa&#41;</p>
          </div>
        </div>
        <hr className="mt-0" />
        <div>
          <h5 className="mb-0">I henhold til fabrikantens spesifikasjoner?</h5>
          <FormGroup className="d-flex flex-column" check>
            <Label check>
              <Input
                type="radio"
                checked={data.inndata.ihenholdTilFabrikant === true}
                onChange={() =>
                  onUpdateEditData(
                    "testResultater.maalingAvArbeidstrykkOgKontrollAvInternTrykkmaaler.inndata.ihenholdTilFabrikant",
                    true,
                  )
                }
              />
              Godkjent
            </Label>
            <Label check>
              <Input
                type="radio"
                checked={data.inndata.ihenholdTilFabrikant === false}
                onChange={() =>
                  onUpdateEditData(
                    "testResultater.maalingAvArbeidstrykkOgKontrollAvInternTrykkmaaler.inndata.ihenholdTilFabrikant",
                    false,
                  )
                }
              />
              Ikke godkjent
            </Label>
          </FormGroup>
        </div>
        <div className="w-50 mt-4">
          <b>Kommentar:</b>
          <McInput
            value={data.inndata.kommentar}
            onChange={(e) =>
              onUpdateEditData(
                "testResultater.maalingAvArbeidstrykkOgKontrollAvInternTrykkmaaler.inndata.kommentar",
                e.target.value,
              )
            }
            type="textarea"
            dynamicHeight={true}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default MaalingAvArbeidstrykkOgKontrollAvInternTrykkmaaler;
