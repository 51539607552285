import React, { useMemo, useState } from "react";
import { Button, Card, CardBody, CardTitle, Modal, ModalBody, ModalHeader } from "reactstrap";
import { IError } from "../../../../../../types/global.types";
import { formatDate } from "../../admin/selectors";
import { AgGridReact } from "ag-grid-react";
import { AG_GRID_LOCALE_NOR } from "../../../../agGridLocale";
import { ColDef } from "ag-grid-enterprise";

const FlatErrorTable: React.FC<{ errors: IError[]; onDeleteError: (_id: string) => void }> = ({
  errors,
  onDeleteError,
}) => {
  const [error, setError] = useState<IError>(null);
  const errorsReversed = useMemo(() => {
    return errors.reverse();
  }, [errors]);

  const columns: ColDef[] = [
    {
      field: "_id",
      hide: true,
    },
    {
      field: "undefined1",
      headerName: "",
      cellRenderer: (params) => (
        <Button color="danger" onClick={() => deleteError(params.data._id)}>
          <i className="fa fa-trash fa-fw" />
        </Button>
      ),
      width: 80,
      resizable: false,
      sortable: false,
    },
    {
      field: "undefined2",
      headerName: "",
      cellRenderer: (params) => (
        <Button color="info" onClick={() => setError(params.data)}>
          Show
        </Button>
      ),
      width: 100,
      resizable: false,
      sortable: false,
    },
    {
      field: "createdAt",
      headerName: "Created",
      cellRenderer: (params) => formatDate(params.value),
      width: 140,
    },
    {
      field: "user.name",
      headerName: "User",
      width: 140,
    },
    {
      field: "routeMessage",
      minWidth: 250,
      autoHeight: true,
      cellStyle: {
        whiteSpace: "normal",
        lineHeight: "1.5",
        wordBreak: "break-word",
      },
    },
    {
      field: "errorMessage",
      cellClass: "d-block",
      minWidth: 300,
      autoHeight: true,
      cellStyle: {
        whiteSpace: "normal",
        lineHeight: "1.5",
        wordBreak: "break-word",
      },
    },
    {
      field: "requestParams.method",
      headerName: "Method",
      width: 60,
    },
    {
      field: "requestParams.originalUrl",
      cellClass: "d-block",
      headerName: "Url",
      minWidth: 300,
    },
  ];

  const toggle = () => {
    setError(null);
  };

  const deleteError = async (_id: string) => {
    toggle();
    onDeleteError(_id);
  };

  return (
    <Card style={{ height: "100%", overflow: "auto" }}>
      <CardBody>
        <CardTitle tag="h3">{errorsReversed.length} Errors</CardTitle>
        {errorsReversed.length === 0 && <p className="text-success">No errors</p>}
        {errorsReversed.length > 0 && (
          <div className="ag-theme-quartz h-100 w-100">
            <AgGridReact
              domLayout={"autoHeight"}
              rowData={errorsReversed || []}
              defaultColDef={{
                suppressHeaderMenuButton: true,
                headerClass: "px-2 py-1",
              }}
              rowHeight={50}
              columnDefs={columns}
              suppressClickEdit={true}
              animateRows={false}
              suppressContextMenu={true}
              localeText={AG_GRID_LOCALE_NOR}
              pagination={errorsReversed.length > 25}
              paginationPageSize={50}
            />
          </div>
        )}
        {error != null && (
          <Modal style={{ maxWidth: "1600px", width: "80%" }} isOpen toggle={() => toggle()}>
            <ModalHeader toggle={toggle}>Error info</ModalHeader>
            <ModalBody className="bg-dark">
              <pre className="text-white">errorMessage: {error.errorMessage}</pre>
              <pre className="text-white">routeMessage: {error.routeMessage}</pre>
              <pre className="text-white">requestParams: {JSON.stringify(error.requestParams, null, 2)}</pre>
              <pre className="text-white">user: {JSON.stringify(error.user, null, 2)}</pre>
              <pre className="text-white">Stacktrace: {error.stackTrace}</pre>
            </ModalBody>
          </Modal>
        )}
      </CardBody>
    </Card>
  );
};

export default FlatErrorTable;
