import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const DocxDemodataModal: React.FC<{ docxDemoData: any; close: () => void }> = ({ docxDemoData, close }) => {
  return (
    <Modal size="lg" isOpen toggle={close}>
      <ModalHeader toggle={close}>Datamodell</ModalHeader>
      <ModalBody style={{ height: "1000px", overflow: "auto" }}>
        <div className="bg-dark d-flex flex-column">
          <pre className="text-white">{JSON.stringify(docxDemoData, null, 2)}</pre>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DocxDemodataModal;
