import { ColDef } from "ag-grid-community";
import { IFeatureToggle, IValueLabelGeneric } from "mc-shared/types/global";
import { useState } from "react";
import { Button, ButtonGroup } from "reactstrap";
import { McDropdown, McTitle } from "../../../components";
import ViewEditTable from "../../../components/ViewEditTable/ViewEditTable";
import { useAppDispatch } from "../../../hooks";
import { doGetFeatureToggle, doUpdateFeatureToggle } from "../admin/restQueries";
import { BATCHJOBTEMPLATES } from "../batchjob/batchJobConstants";
import { setShowSuccessMessageThunk } from "../../../global/globalSlice";

type TProjectId = { projectId: string };

export const ActiveBatchJob = () => {
  const dispatch = useAppDispatch();
  const [currentFeatureToggle, setCurrentFeatureToggle] = useState<IFeatureToggle>(null);

  const TEMPLATES: IValueLabelGeneric<string>[] = BATCHJOBTEMPLATES.map((jobName) => {
    return {
      value: jobName,
      label: jobName,
    };
  });

  const save = async (feature: IFeatureToggle) => {
    try {
      const updated = await doUpdateFeatureToggle(feature);
      setCurrentFeatureToggle(updated);
      dispatch(setShowSuccessMessageThunk("Feature updated!"));
    } catch (e) {
      dispatch(setShowSuccessMessageThunk("Error updating feature!"));
    }
  };

  const columns: ColDef<TProjectId>[] = [
    {
      field: "projectId",
      headerName: "Id",
      editable: true,
      flex: 1,
    },
  ];

  const updateFeatureStatus = async (isActive: boolean) => {
    if (currentFeatureToggle === null) {
      return;
    }
    currentFeatureToggle.active = isActive;
    await save(currentFeatureToggle);
  };

  const getFeatureToggle = async (featureName: string) => {
    if (featureName === currentFeatureToggle?.featureName) {
      setCurrentFeatureToggle(null);
      return;
    }
    const _featureToggle = await doGetFeatureToggle("batchJob", featureName);
    setCurrentFeatureToggle(_featureToggle);
  };

  const updateProjects = async (projects: TProjectId[]) => {
    const updatedFeatureToggle: IFeatureToggle = {
      ...currentFeatureToggle,
      projects: projects
        .map((item) => item.projectId)
        .reduce((acc: string[], curr: string) => {
          // remove duplicates and empty strings
          if (curr != null && curr !== "" && acc.includes(curr) === false) {
            return acc.concat([curr]);
          } else {
            return acc;
          }
        }, [])
        // make sure strings are mongodb object ids
        .filter((id: string) => id.length === 24),
    };

    await save(updatedFeatureToggle);
  };

  return (
    <div className="d-flex flex-column">
      <div className="d-flex" style={{ maxWidth: "50vw" }}>
        <McTitle title="Template" className="mr-2" style={{ width: "300px" }}>
          <McDropdown
            style={{ width: "300px" }}
            currentValue={TEMPLATES.find((b) => b.value === currentFeatureToggle?.featureName)}
            options={TEMPLATES}
            displayKey="label"
            onChange={(_, newValue) => getFeatureToggle(newValue.value)}
          />
        </McTitle>
        <McTitle title="Active" className="mr-2">
          <div className="d-flex align-items-center h-100">
            <CheckMark
              icon="check"
              status={currentFeatureToggle?.active}
              activeColor="#00975f"
              disabled={currentFeatureToggle === null}
              handleOnChange={(active) => updateFeatureStatus(active)}
            />
          </div>
        </McTitle>
        <McTitle title="Access" className="mr-2">
          <ButtonGroup>
            <Button
              disabled={currentFeatureToggle === null || currentFeatureToggle?.active === false}
              color={currentFeatureToggle?.access === "PROJECT" ? "primary" : "secondary"}
              onClick={() => {
                const updatedFeatureToggle: IFeatureToggle = {
                  ...currentFeatureToggle,
                  access: "PROJECT",
                };
                save(updatedFeatureToggle);
              }}
              active={currentFeatureToggle?.access === "PROJECT"}
            >
              PROJECT
            </Button>
            <Button
              disabled={currentFeatureToggle === null || currentFeatureToggle?.active === false}
              color={currentFeatureToggle?.access === "GLOBAL" ? "primary" : "secondary"}
              onClick={() => {
                const updatedFeatureToggle: IFeatureToggle = {
                  ...currentFeatureToggle,
                  access: "GLOBAL",
                };
                save(updatedFeatureToggle);
              }}
              active={currentFeatureToggle?.access === "GLOBAL"}
            >
              GLOBAL
            </Button>
          </ButtonGroup>
        </McTitle>
      </div>
      {currentFeatureToggle?.access === "PROJECT" && currentFeatureToggle.active === true && (
        <McTitle title="Projects" className="mt-2">
          <ViewEditTable
            columns={columns}
            data={currentFeatureToggle?.projects.map((p) => ({ projectId: p })) ?? []}
            onSave={(items) => updateProjects(items)}
            newItemTemplateObject={{ projectId: "" }}
            canEdit={true}
          />
        </McTitle>
      )}
    </div>
  );
};

const CheckMark = ({
  icon,
  status,
  activeColor,
  disabled,
  handleOnChange,
}: {
  status: boolean;
  icon: string;
  disabled: boolean;
  activeColor: string;
  handleOnChange: (status: boolean) => void;
}) => {
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      onClick={() => disabled === false && handleOnChange(!status)}
      style={{
        color: "#fff",
        width: "25px",
        height: "25px",
        borderRadius: "50%",
        cursor: disabled === false ? "pointer" : "default",
        backgroundColor: disabled === false && status === true ? activeColor : "#ccc",
      }}
    >
      <i className={`fa fa-${icon}`} />
    </div>
  );
};
