import moment from "moment";
import React from "react";
import { Alert } from "reactstrap";
import DateCountDown from "../DateCountDown";
import { HSpace } from "../index";

moment.locale("nb");

const BatchJobSettingsBanner: React.FC<{
  nrOfSettingsComplete: number;
  totalNumberOfSettings: number;
  nextScheduledBatchJob: string;
}> = ({ nrOfSettingsComplete, totalNumberOfSettings, nextScheduledBatchJob }) => {
  return (
    <>
      {nrOfSettingsComplete !== totalNumberOfSettings ? (
        <Alert color="warning">
          <b>
            {nrOfSettingsComplete} av {totalNumberOfSettings} innstillinger er utført
          </b>
        </Alert>
      ) : (
        <Alert color="success" className="d-flex">
          {nextScheduledBatchJob != null && (
            <>
              <span className="d-flex">
                Den neste batchjobben er planlagt
                <HSpace />
                <b>{moment(nextScheduledBatchJob).format("dddd, MMMM Do H:mm")}</b>
              </span>

              <div className="flex-fill" />
              <DateCountDown date={new Date(nextScheduledBatchJob)} />
            </>
          )}
        </Alert>
      )}
    </>
  );
};

export default BatchJobSettingsBanner;
