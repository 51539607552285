import React, { useRef, useState } from "react";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";

const EmbeddedMongoDbAtlasChart: React.FC<{
  chartId: string;
  filters: any;
  clickEnabled?: boolean;
  onChartClick?: (event, currentFilters: any) => void;
}> = ({ chartId, filters, clickEnabled, onChartClick }) => {
  const sdk = new ChartsEmbedSDK({ baseUrl: "https://charts.mongodb.com/charts-mcapps-ihimm" });
  const chartDiv1 = useRef(null);
  const [rendered1, setRendered1] = useState(false);
  const [chart1] = useState(
    sdk.createChart({
      chartId,
      showAttribution: false,
    }),
  );

  React.useEffect(() => {
    chart1
      .render(chartDiv1.current)
      .then(() => setRendered1(true))
      .then(() => clickEnabled == true && chart1.addEventListener("click", (event) => onChartClick(event, filters)))
      .catch((err) => console.log("Error during Charts rendering.", err));
  }, [chart1]);

  React.useEffect(() => {
    if (rendered1 && filters != null) {
      chart1.setFilter(filters).catch((err) => console.log("Error while filtering.", err));
    }
    if (rendered1 && filters == null) {
      chart1.setFilter({}).catch((err) => console.log("Error while filtering.", err));
    }
  }, [chart1, rendered1, filters]);

  return (
    <>
      <div className="d-flex flex-column h-100 w-100" ref={chartDiv1} />
    </>
  );
};

export default EmbeddedMongoDbAtlasChart;
