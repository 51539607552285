import React from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { IDisciplin } from "../../../../../../types/global.types";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { loadProject } from "../../../actions";
import { doUpdateMemberDisciplinInProject } from "../../../projectApi";
import { sortArrayByString } from "../../../utils";
import { setShowSuccessMessageThunk } from "../../../../global/globalSlice";

const DisciplineSelector: React.FC<{ discipline?: string; disciplines: IDisciplin[]; memberId: string }> = ({
  discipline,
  disciplines,
  memberId,
}) => {
  const project = useAppSelector((state) => state.adminReducer.project);

  const dispatch = useAppDispatch();
  const onDisciplineChange = async (disciplin: IDisciplin, removeDiscipline?: boolean) => {
    await doUpdateMemberDisciplinInProject(project._id, memberId, disciplin.short, removeDiscipline);
    dispatch(loadProject(project._id));
    dispatch(setShowSuccessMessageThunk("Disiplin satt"));
  };

  const handleClearDiscipline = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onDisciplineChange({ short: null, long: "" }, true);
  };
  return (
    <UncontrolledDropdown>
      <DropdownToggle className="w-100 d-flex align-items-center" caret outline color="secondary">
        {discipline == null ? "Ikke satt" : discipline}
        <div className="flex-grow-1" />
        {discipline != null && <i onClick={(e) => handleClearDiscipline(e)} className="fa fa-close mx-1 dd-clear" />}
      </DropdownToggle>
      <DropdownMenu style={{ maxHeight: "300px", overflow: "auto" }}>
        {sortArrayByString(disciplines, "disc.short").map((disc, index) => (
          <DropdownItem key={index} onClick={() => onDisciplineChange(disc)}>
            {disc.short} - {disc.long}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default DisciplineSelector;
