import React, { useRef, useState } from "react";
import { Alert } from "reactstrap";
import { ConfirmDialog, DeleteButton, VSpace } from "../";

interface SingleImageUploadProps {
  title: string;
  onFileUpload: (file: File) => void;
  onFileDelete: () => void;
  path: string;
  disableUpload?: boolean;
}

const SingleImageUpload: React.FC<SingleImageUploadProps> = ({
  title,
  onFileUpload,
  onFileDelete,
  path,
  disableUpload,
}) => {
  const [imageRefreshKey, setImageRefreshKey] = useState(Date.now());

  const pathWithRefreshKey = `${path}?key=${imageRefreshKey}`;
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];
    await onFileUpload(file);
    setImageRefreshKey(Date.now());
    if (fileInputRef.current != null) {
      fileInputRef.current.value = "";
    }
  };

  const deleteImage = async () => {
    await onFileDelete();
    setImageRefreshKey(Date.now());
    if (fileInputRef.current != null) {
      fileInputRef.current.value = "";
    }
  };
  return (
    <div className="d-flex">
      {disableUpload === true && <Alert color="info">Filopplasting er ikke tilgjengelig</Alert>}
      {disableUpload !== true && (
        <div>
          <div>
            <h5>{title}</h5>
            <p>Last opp et bilde:</p>
            <input type="file" accept="image/*" onChange={handleFileChange} ref={fileInputRef} />
          </div>
          <VSpace />
          <div className="flex-column" style={{ width: "min-content", minWidth: "200px" }}>
            <div className="d-flex px-2 justify-content-between">
              <h5 className="p-0 m-0 mt-2 mr-3">Bilde i bruk:</h5>

              <ConfirmDialog
                confirmCb={deleteImage}
                message="Vil du slette bildet? Dette kan ikke angres!"
                title="Slette bildet?"
              >
                <DeleteButton onClick={deleteImage} outline />
              </ConfirmDialog>
            </div>
            <div className="p-3 d-flex justify-content-center" style={{ borderRadius: "6px", background: "#f7f7f7" }}>
              <img style={{ height: "200px" }} alt="" src={pathWithRefreshKey} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleImageUpload;
