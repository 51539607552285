import Axios from "axios";
import { IIssueSearchResult } from "../../../../../types/issue.types";
import { IRegister } from "../../../../../types/register.types";
import { IAllFilter } from "mc-shared/zod/commonSchema";

export const doGetIssueBoardsDepricated = async (projectId: string): Promise<IRegister.BoardPopulated[]> => {
  try {
    const logFetch = await Axios.get(`/api/project/${projectId}/issueboard`);
    return logFetch.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};

// TODO: integrate with RTK instead
export const doSearchIssueBoardsDepricated = async (
  projectId: string,
  query: string,
  allFilters: IAllFilter[],
): Promise<IIssueSearchResult[]> => {
  try {
    const result = await Axios.post(`/api/project/${projectId}/issueboard/search`, { query, allFilters });
    return result.data;
  } catch (error) {
    return Promise.reject({ type: "backend", message: error.response?.data });
  }
};
