import { IGKRapport, IGKTrykkfallIAvtrekksskapResultater } from "mc-shared/zod/report/GK/gkReportSchema";
import { useEffect } from "react";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../../types/global.types";
import { HSpace, McInput } from "../../../../../../components";
import ReportDocxImageContainer from "../../../ReportBoard/ReportDocxImageContainer";
import { beregnetTverrsnittareal } from "../gkGlobalUtils";

const TrykkfallIAvtrekksskap: React.FC<{
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
  data: IGKTrykkfallIAvtrekksskapResultater;
}> = ({ data, onUpdateEditData }) => {
  useEffect(() => {
    utdataBeregninger();
  }, [data.inndata.diameterPaaAvtrekkskanal]);

  const addNewRow = () => {
    const maaleresultaterArray = data.inndata.maaleresultaterMaalenipler;
    maaleresultaterArray.push(null);

    onUpdateEditData("testResultater.trykkfallIAvtrekksskap.inndata.maaleresultaterMaalenipler", maaleresultaterArray);

    return maaleresultaterArray;
  };

  const removeLastRow = () => {
    const updatedMaaleresultater = data.inndata.maaleresultaterMaalenipler;

    if (updatedMaaleresultater.length > 0) {
      updatedMaaleresultater.pop(); // Remove the last element
      onUpdateEditData(
        "testResultater.trykkfallIAvtrekksskap.inndata.maaleresultaterMaalenipler",
        updatedMaaleresultater,
      );
    }
  };

  const updateMaaleresultater = (resultat, i) => {
    const maaleresultater = [...data.inndata.maaleresultaterMaalenipler];
    maaleresultater[i] = resultat;
    onUpdateEditData("testResultater.trykkfallIAvtrekksskap.inndata.maaleresultaterMaalenipler", maaleresultater);
  };

  const utdataBeregninger = () => {
    const tverrsnittareal = beregnetTverrsnittareal(data.inndata.diameterPaaAvtrekkskanal);
    const utdata = {
      tverrsnittareal: tverrsnittareal,
    };
    onUpdateEditData("testResultater.trykkfallIAvtrekksskap.utdata", utdata);
  };

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Trykkfall i avtrekksskap</CardTitle>
        <h5>Avtrekkskanal:</h5>
        <div className="d-flex flex-column mt-0 mb-4">
          <b>Antall målepunkter på avtrekkskanal &#40;Minimum 4&#41;:</b>
          <McInput
            value={data.inndata.antallMaalepunkter}
            onBlur={(e) =>
              onUpdateEditData(
                "testResultater.trykkfallIAvtrekksskap.inndata.antallMaalepunkter",
                Number(e.target.value),
              )
            }
            normalizeNumbers={true}
            type="text"
            className="w-25"
          />
        </div>
        <div className="d-flex flex-column mt-2">
          <b>Måletiden er 60 sekunder &#40;s&#41;:</b>
        </div>
        <div className="d-flex flex-column mt-2 w-50">
          <b>Beskrivelse av målepunkter:</b>
          <McInput
            value={data.inndata.beskrivelseAvMaalepunkter}
            onChange={(e) =>
              onUpdateEditData(
                "testResultater.trykkfallIAvtrekksskap.inndata.beskrivelseAvMaalepunkter",
                e.target.value,
              )
            }
            type="textarea"
            dynamicHeight={true}
          />
        </div>
        <div className="mt-4 d-flex flex-column">
          <b>Diameter på avtrekkskanal hvor målinger ble utført &#40;m&#41;:</b>
          <McInput
            value={data.inndata.diameterPaaAvtrekkskanal}
            onBlur={(e) =>
              onUpdateEditData(
                "testResultater.trykkfallIAvtrekksskap.inndata.diameterPaaAvtrekkskanal",
                Number(e.target.value),
              )
            }
            normalizeNumbers={true}
            type="text"
            className="w-25"
          />
        </div>
        <div className="d-flex justify-content-between">
          <p className="mb-0">
            Tversnittsareal kanal &#40;m<sup>2</sup>&#41; ={" "}
            <b>{beregnetTverrsnittareal(data.inndata.diameterPaaAvtrekkskanal)?.toFixed(3) ?? ""}</b>
          </p>
          <p className="mb-0">
            Formel: &#40;Ak = PI * &#40;D / 2&#41;<sup>2</sup>&#41;
          </p>
        </div>
        <hr className="mt-0" />
        <div className="mt-4 d-flex flex-column">
          <b>Måleresultat tilkoblingspunkt på avtrekkskanal &#40;Pa&#41;:</b>
          <McInput
            value={data.inndata.maaleresultatTilkoblingspunkt}
            onBlur={(e) =>
              onUpdateEditData(
                "testResultater.trykkfallIAvtrekksskap.inndata.maaleresultatTilkoblingspunkt",
                Number(e.target.value),
              )
            }
            normalizeNumbers={true}
            type="text"
            className="w-25"
          />
        </div>
        <div className="mt-4">
          <h5>Målenippel:</h5>
          <b>Legg til innebygd målenippel:</b>
          <div className="d-flex">
            <Button color="success" onClick={addNewRow}>
              Legg til rad
            </Button>
            <HSpace />
            <Button color="danger" onClick={removeLastRow}>
              Fjern siste rad
            </Button>
          </div>
        </div>
        {data.inndata.maaleresultaterMaalenipler.length > 0 && (
          <div className="mt-4 d-flex flex-column">
            <b>Fyll inn måleresultater &#40;Pa&#41;:</b>
            {data.inndata.maaleresultaterMaalenipler.map((a, i) => (
              <McInput
                className="mb-1 w-25"
                key={i}
                value={a}
                normalizeNumbers={true}
                type="text"
                onBlur={(e) => updateMaaleresultater(Number(e.target.value), i)}
              />
            ))}
          </div>
        )}
        <div className="mt-4 d-flex flex-column mb-4">
          <b>
            Avtrekksluftmengde &#40;m<sup>3</sup>/s&#41;:
          </b>
          <McInput
            value={data.inndata.avtrekksLuftmengde}
            onBlur={(e) =>
              onUpdateEditData(
                "testResultater.trykkfallIAvtrekksskap.inndata.avtrekksLuftmengde",
                Number(e.target.value),
              )
            }
            normalizeNumbers={true}
            type="text"
            className="w-25"
          />
        </div>
        <div className="mt-2">
          <ReportDocxImageContainer docxImageTag="trykkfallIAvtrekksskap" title="Målepunkter" />
          <div className="w-50">
            <b>Kommentar:</b>
            <McInput
              value={data.inndata.kommentar}
              onChange={(e) =>
                onUpdateEditData("testResultater.trykkfallIAvtrekksskap.inndata.kommentar", e.target.value)
              }
              type="textarea"
              dynamicHeight={true}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default TrykkfallIAvtrekksskap;
