import { useState } from "react";
import { Avatar, McDropdownBase } from "..";
import { IMinUser } from "../../../../types/global.types";
import { IMcDropdownBase } from "../McDropdownBase/McDropdownBase";
import { escapeRegExp } from "lodash";
import { filterPattern } from "../../app/utils";

const McDropdownSingleUser = ({
  onChange,
  options,
  selected,
  color = "white",
  ...props
}: {
  onChange: (selected: IMinUser) => void;
  options: IMinUser[];
  selected: IMinUser;
  color?: string;
  filterActive?: boolean;
} & Omit<IMcDropdownBase, "children">) => {
  const [filterValue, setFilterValue] = useState<string>("");

  return (
    <McDropdownBase
      onFilter={(val) => setFilterValue(val)}
      onToggle={() => setFilterValue("")}
      {...props}
      color={color}
    >
      <McDropdownBase.Display>
        <Avatar id={selected?._id} height="20px" />
        <div className="truncateHeaderText">{selected?.name}</div>
      </McDropdownBase.Display>
      {options.reduce((acc, user) => {
        if (user != null && (filterValue === "" || filterPattern(filterValue).test(user?.name))) {
          acc.push(
            <McDropdownBase.Option key={user._id} selected={user._id === selected?._id} onClick={() => onChange(user)}>
              <div key={user._id} className={`d-flex w-100 align-items-center`}>
                <Avatar id={user._id} height="20px" />
                <div className="truncateHeaderText">{user.name}</div>
              </div>
            </McDropdownBase.Option>,
          );
        }

        return acc;
      }, [])}
    </McDropdownBase>
  );
};

export default McDropdownSingleUser;
