import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import React, { useEffect, useState } from "react";
import { IIssueTrend } from "../../../../../../../../types/global.types";
import { getIssueTrendHighchartOptions } from "./utils";

const TrendChart: React.FC<{ issueTrend: IIssueTrend }> = ({ issueTrend }) => {
  const [options, setOptions] = useState(null);

  useEffect(() => {
    init();
  }, [issueTrend]);

  const init = async () => {
    const chartOptions = getIssueTrendHighchartOptions(issueTrend);
    setOptions(chartOptions);
  };

  return options != null && <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default TrendChart;
