import React from "react";

const SimpleStampCheck: React.FC<{
  disabled?: boolean;
  toggled: boolean | null;
  toggle: (toggled: boolean | null) => void;
}> = ({ toggled, toggle, disabled }) => {
  return (
    <>
      <div
        className="d-flex align-items-center justify-content-center mr-2"
        onClick={() => disabled === false && toggle(toggled === false ? null : false)}
        style={{
          color: "#fff",
          cursor: disabled === true ? "not-allowed" : "pointer",
          width: "25px",
          height: "25px",
          borderRadius: "50%",
          backgroundColor: toggled === false ? "#dc3545" : "#ccc",
          opacity: disabled === true ? 0.5 : 1,
        }}
      >
        <i className={`fa fa-close`} />
      </div>
      <div
        className="d-flex align-items-center justify-content-center"
        onClick={() => disabled === false && toggle(toggled === true ? null : true)}
        style={{
          color: "#fff",
          cursor: disabled === true ? "not-allowed" : "pointer",
          width: "25px",
          height: "25px",
          borderRadius: "50%",
          backgroundColor: toggled === true ? "#00975f" : "#ccc",
          opacity: disabled === true ? 0.5 : 1,
        }}
      >
        <i className={`fa fa-check`} />
      </div>
    </>
  );
};

export default SimpleStampCheck;
