import React, { useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { Button, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { IBasicItem } from "../../../../types/global.types";

const ConnectItemToGisModal: React.FC<{
  items: IBasicItem[];
  close: () => void;
  connectItemToGis: (item: IBasicItem) => void;
  placeholder: string;
  typeOfItemToLink: string;
  itemIdsToShow?: string[];
}> = ({ items, close, connectItemToGis, placeholder, typeOfItemToLink, itemIdsToShow }) => {
  const [singleSelections, setSingleSelections] = useState([]);

  return (
    <Modal isOpen className="w-100">
      <ModalHeader toggle={() => close()}>Koble til GIS</ModalHeader>
      <ModalBody className="w-100">
        <Label className="gray-700-text">
          <i className="fa fa-map-marker fa-fw" />
          {typeOfItemToLink} å linke til GIS
        </Label>
        <Typeahead
          // @ts-ignore
          className="w-100"
          allowNew={false}
          selected={singleSelections}
          id="1"
          onChange={setSingleSelections}
          placeholder={placeholder}
          labelKey="displayValue"
          options={itemIdsToShow == null ? items : items.filter((itm) => itemIdsToShow.indexOf(itm._id) !== -1)}
        />
      </ModalBody>
      <ModalFooter>
        <Button onClick={close} outline>
          Avbryt
        </Button>
        <Button
          onClick={() => connectItemToGis(singleSelections[0])}
          disabled={singleSelections == null || singleSelections[0] == null}
        >
          <i className="fa fa-map-marker fa-fw" />
          Koble til GIS
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConnectItemToGisModal;
