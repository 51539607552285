import { ColDef } from "ag-grid-community";
import React from "react";
import { IMilestone } from "../../../../../types/global.types";
import { FoldableSection } from "../../../components";
import ViewEditTable, { VETEmptyValidator } from "../../../components/ViewEditTable/ViewEditTable";
import { ToastMessagesEnums } from "../../../frontendConstants";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { setMilestonesInRedux } from "../../actions";
import { doUpdateMilestones } from "./restQueries";
import { setShowSuccessMessageThunk } from "../../../global/globalSlice";

const ProjectMilestoneSettingsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const project = useAppSelector((state) => state.adminReducer.project);

  const updateMilestones = async (milestones: IMilestone[]) => {
    const _milestones = await doUpdateMilestones(project._id, milestones);
    dispatch(setMilestonesInRedux(_milestones));
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.UPDATED));
  };

  const columns: ColDef<IMilestone>[] = [
    {
      field: "name",
      headerName: "Navn",
      editable: true,
    },
    {
      field: "description",
      headerName: "Beskrivelse",
      editable: true,
    },
    {
      field: "dueDate",
      headerName: "Frist",
      editable: true,
      cellDataType: "dateString",
    },
  ];

  const newItemTemplateObject: IMilestone = {
    _id: null,
    name: "",
    description: "",
    dueDate: "",
  };

  return (
    <div className="d-flex flex-column w-100">
      <FoldableSection>
        <FoldableSection.Header>
          <h5 className="mt-2">Milepæler for prosjekt</h5>
        </FoldableSection.Header>
        <FoldableSection.Body>
          <ViewEditTable
            validators={[VETEmptyValidator, VETEmptyValidator]}
            columns={columns}
            data={project.milestones || []}
            onSave={updateMilestones}
            newItemTemplateObject={newItemTemplateObject}
            canEdit={true}
          />
        </FoldableSection.Body>
      </FoldableSection>
    </div>
  );
};

export default ProjectMilestoneSettingsPage;
