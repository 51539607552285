import moment from "moment/moment";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import { Alert, Badge, Progress } from "reactstrap";
import { IMCBIMAPI } from "../../../../../../../types/mcbim.types";
import {
  COL_WIDTHS,
  createHeaderStyle,
  headerSortAndDropDownFilter,
  headerSortAndFilterFormatter,
  sortCaret,
} from "../../../../../components/columnGlobal";
import { IBootstrapTableColumnConfig } from "../../../../../types";
import { STANDARD_COLORS } from "../../../../globalConstants";
import { formatMillisecondsToString } from "../../../../utils";
import { getMcBimFileJobStatusColor } from "./McJobTable";
import { FailedAlert } from "../../../../../components";
import { useGetSingleMcBimJobQuery } from "../McBimRTK";

const McBimSingleJob: React.FC<{ jobId: string; projectId: string; onComplete?: () => void }> = ({
  jobId,
  projectId,
  onComplete,
}) => {
  const {
    data: files,
    isLoading: filesLoading,
    error: filesError,
  } = useGetSingleMcBimJobQuery(
    { projectId, jobId },
    {
      pollingInterval: 5000,
    },
  );

  const isProcessing = files?.find((job) => job.status === "queued" || job.status === "in-progress") != null;

  React.useEffect(() => {
    if (isProcessing === false && onComplete != null) {
      onComplete();
    }
  }, [isProcessing]);

  const columns: IBootstrapTableColumnConfig[] = [
    {
      headerFormatter: headerSortAndFilterFormatter,
      dataField: "_id",
      text: "",
      hidden: true,
    },
    {
      headerFormatter: headerSortAndFilterFormatter,
      dataField: "filePath",
      sort: true,
      sortCaret: sortCaret,
      text: "Sti til fil",
      headerStyle: {
        width: "450",
      },
    },
    {
      headerFormatter: headerSortAndFilterFormatter,
      dataField: "fileName",
      sort: true,
      sortCaret: sortCaret,
      headerStyle: createHeaderStyle(COL_WIDTHS.FILL),
      text: "Filnavn",
    },
    {
      headerFormatter: headerSortAndFilterFormatter,
      dataField: "createdAt",
      text: "Synkronisering startet",
      sort: true,
      sortCaret: sortCaret,
      headerStyle: {
        width: "150px",
      },
      formatter: (cell, row: IMCBIMAPI.IJob) => {
        return <div>{moment(row.createdAt).format("YYYY.MM.DD-HH:mm")}</div>;
      },
    },
    {
      dataField: "status",
      sort: true,
      sortCaret: sortCaret,
      headerFormatter: headerSortAndDropDownFilter,
      text: `Status`,
      headerStyle: createHeaderStyle(COL_WIDTHS.FILL),
      formatter: (cell, row: IMCBIMAPI.IJob) => {
        return (
          <Badge style={{ padding: "8px 12px" }} pill className="d-flex greybadge">
            <div className="statusdot" style={{ backgroundColor: getMcBimFileJobStatusColor(row.status) }}></div>
            <div style={{ color: STANDARD_COLORS.grey, fontSize: "12px" }}>{row.status.toUpperCase()}</div>
          </Badge>
        );
      },
    },
    {
      headerFormatter: headerSortAndFilterFormatter,
      dataField: "duration",
      text: "Tid brukt [min:sek:ms]",
      sort: true,
      sortCaret: sortCaret,
      headerStyle: createHeaderStyle(COL_WIDTHS.ID),
      formatter: formatMillisecondsToString,
    },
    {
      headerFormatter: headerSortAndFilterFormatter,
      dataField: "insertedDocuments",
      text: "Installerte objekter",
      sort: true,
      sortCaret: sortCaret,
      headerStyle: createHeaderStyle(COL_WIDTHS.ID),
    },
    {
      //TODO display error message when hovering over error
      headerFormatter: headerSortAndFilterFormatter,
      dataField: "errorMessage",
      text: "Feilmelding",
      headerStyle: createHeaderStyle(COL_WIDTHS.ID),
      // formatter: (cell, row: IMCBIMAPI.IJobFile) => {
      //   if (row.errorMessage) {
      //     return <div>{row.errorMessage}</div>;
      //   } else {
      //     return null;
      //   }
      // },
    },
  ];

  const filesWithZeroInsertedDocuments =
    files?.filter((file) => file.insertedDocuments === 0 && file.status === "completed").length > 0;
  return (
    <>
      {filesError != null && <FailedAlert />}
      {filesWithZeroInsertedDocuments === true && (
        <Alert color="info">
          <i className="fa fa-info-circle fa-fw" />
          Kun filer som ikke allerede er i databasen settes inn.
        </Alert>
      )}
      {isProcessing === true && (
        <div style={{ height: "50px" }}>
          <Progress className="h-100" animated color="primary" value={100}>
            <h3 className="text-white">Filer bearbeides...</h3>
          </Progress>
        </div>
      )}
      {files != null && (
        <BootstrapTable
          wrapperClasses="smalltable"
          defaultSorted={[
            {
              dataField: "updatedAt",
              order: "desc",
            },
          ]}
          filter={filterFactory()}
          bootstrap4
          bordered={false}
          striped
          hover
          keyField="_id"
          data={files || []}
          columns={columns}
          classes={"table-sm"}
        />
      )}
    </>
  );
};

export default McBimSingleJob;
