import { IGKKontrollAvRomtrykkResultater, IGKRapport } from "mc-shared/zod/report/GK/gkReportSchema";
import React from "react";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import { Paths, PathValue } from "../../../../../../../../../types/global.types";
import { HSpace, McInput } from "../../../../../../../components";
import ReportDocxImageContainer from "../../../../ReportBoard/ReportDocxImageContainer";
import { beregnetDiffMellomToVerdier } from "../../gkGlobalUtils";

const KontrollAvRomtrykk: React.FC<{
  onUpdateEditData: <P extends Paths<IGKRapport, 4>>(path: P, value: PathValue<IGKRapport, P>) => void;
  data: IGKKontrollAvRomtrykkResultater;
}> = ({ data, onUpdateEditData }) => {
  const addNewRow = () => {
    const newTilleggsRomResultat = {
      romNr: "",
      romNavn: "",
      prosjektertTrykkdifferanse: null,
      maaltTrykkdifferanse: null,
      trykkdifferanseFraSDOvervaakning: null,
      trykkDifferanseMellomMaaltOgOvervaakning: null,
    };

    const updatedMaaleresultater = [...data.inndata.tilleggsRomResultater, newTilleggsRomResultat];

    onUpdateEditData("testResultater.kontrollAvRomtrykk.inndata.tilleggsRomResultater", updatedMaaleresultater);

    return updatedMaaleresultater;
  };

  const removeLastRow = () => {
    const updatedMaaleresultater = [...data.inndata.tilleggsRomResultater];

    if (updatedMaaleresultater.length > 0) {
      updatedMaaleresultater.pop(); // Remove the last element
      onUpdateEditData("testResultater.kontrollAvRomtrykk.inndata.tilleggsRomResultater", updatedMaaleresultater);
    }
  };

  const updateMaaleresultater = (resultat: string | number, i: number, parameter: string) => {
    const maaleresultater = [...data.inndata.tilleggsRomResultater];
    maaleresultater[i][parameter] = resultat;
    maaleresultater[i].trykkDifferanseMellomMaaltOgOvervaakning = beregnetDiffMellomToVerdier(
      maaleresultater[i].trykkdifferanseFraSDOvervaakning,
      maaleresultater[i].maaltTrykkdifferanse,
    );
    onUpdateEditData("testResultater.kontrollAvRomtrykk.inndata.tilleggsRomResultater", maaleresultater);
  };

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Kontroll av romtrykk</CardTitle>
        <div className="d-flex flex-column">
          <b>Hvor mange trykkforskjeller skal måles? &#40;-&#41;:</b>
          <div className="d-flex">
            <Button color="success" onClick={addNewRow}>
              Legg til rad
            </Button>
            <HSpace />
            <Button color="danger" onClick={removeLastRow}>
              Fjern siste rad
            </Button>
          </div>
        </div>
        {Number(data.inndata.tilleggsRomResultater.length) > 0 && (
          <div className="mt-4 d-flex flex-column">
            <b>Fyll inn måleresultater:</b>
            <table>
              <thead>
                <tr>
                  <th>Romnummer &#40;-&#41;</th>
                  <th>Romnavn &#40;-&#41;</th>
                  <th>Prosjektert trykkdifferanse &#40;Pa&#41;</th>
                  <th>Målt trykkdifferanse &#40;Pa&#41;</th>
                  <th>Trykkdifferanse fra SD &#40;Pa&#41;</th>
                </tr>
              </thead>
              {data.inndata.tilleggsRomResultater.map((rom, i) => (
                <tbody key={i}>
                  <tr>
                    <td>
                      <McInput
                        value={rom.romNr}
                        type="text"
                        onChange={(e) => updateMaaleresultater(e.target.value, i, "romNr")}
                        placeholder="Romnummer"
                      />
                    </td>
                    <td>
                      <McInput
                        value={rom.romNavn}
                        type="text"
                        onChange={(e) => updateMaaleresultater(e.target.value, i, "romNavn")}
                        placeholder="Romnavn"
                      />
                    </td>
                    <td>
                      <McInput
                        value={rom.prosjektertTrykkdifferanse}
                        normalizeNumbers={true}
                        type="text"
                        onBlur={(e) => updateMaaleresultater(Number(e.target.value), i, "prosjektertTrykkdifferanse")}
                        placeholder="Prosjektert trykkdifferanse"
                      />
                    </td>
                    <td>
                      <McInput
                        value={rom.maaltTrykkdifferanse}
                        normalizeNumbers={true}
                        type="text"
                        onBlur={(e) => updateMaaleresultater(Number(e.target.value), i, "maaltTrykkdifferanse")}
                        placeholder="Målt trykkdifferanse"
                      />
                    </td>
                    <td>
                      <McInput
                        value={rom.trykkdifferanseFraSDOvervaakning}
                        normalizeNumbers={true}
                        type="text"
                        onBlur={(e) =>
                          updateMaaleresultater(Number(e.target.value), i, "trykkdifferanseFraSDOvervaakning")
                        }
                        placeholder="Trykkdifferanse fra SD"
                      />
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
            <div className="mt-4">
              <ReportDocxImageContainer docxImageTag="kontrollAvRomtrykk" title="Målepunkter mellom rom" />
              <div className="w-50">
                <b>Kommentar:</b>
                <McInput
                  value={data.inndata.kommentar}
                  onChange={(e) =>
                    onUpdateEditData("testResultater.kontrollAvRomtrykk.inndata.kommentar", e.target.value)
                  }
                  type="textarea"
                  dynamicHeight={true}
                />
              </div>
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default KontrollAvRomtrykk;
