import React, { useMemo, useState } from "react";
import { IBasicItem, IGisCamera, IGisPoint, IGisPointAndCamera, IIssue } from "../../../../../../../types/global.types";
import { Viewpoints } from "../../../../../components";
import ArcGisMap from "../../../gis/ArcGisMap";
import AddIssueModal from "../AddIssueModal";
import ConnectItemToGisModal from "../../../../../components/ConnectItemToGisModal/ConnectItemToGisModal";
import { doGetBasicIssueInfo } from "../../issueAPIDepricated";
import { Alert } from "reactstrap";
import { AppModalEnums } from "../../../../../frontendConstants";
import { useAppDispatch } from "../../../../../hooks";
import { convertIssuesToViewPoints, getIssueUrlPath } from "../../issueUtils";
import { useHistory } from "react-router-dom";
import { useDoUpdateIssueMutation } from "../../issueRTK";
import { addAppModalThunk, removeAppModalInRedux } from "../../../../../global/globalSlice";

const IssuesGis: React.FC<{ projectId: string; boardId: string; mapId: string; issues: IIssue[] }> = ({
  projectId,
  boardId,
  mapId,
  issues,
}) => {
  const [addIssueModalOpen, setAddIssueModalOpen] = useState<boolean>(false);
  const [connectIssueModalOpen, setConnectIssueModalOpen] = useState<boolean>(false);

  const [clickedGis, setClickedGis] = useState<IGisPointAndCamera>(null);

  const [selectedVpId, setSelectedVpId] = React.useState<string>();

  const [basicTypeAheadItems, setBasicTypeAheadItems] = React.useState<IBasicItem[]>(null);

  const [zoomToGisPoint, setZoomToGisPoint] = useState<IGisPoint>(null);
  const [cameraPosition, setCameraPosition] = useState<IGisCamera>(null);
  const [gisImage, setGisImage] = useState<string>();

  const [updateIssue, { isLoading: isUpdating }] = useDoUpdateIssueMutation();

  const dispatch = useAppDispatch();

  const viewPoints = useMemo(() => {
    return convertIssuesToViewPoints(issues);
  }, [issues]);

  React.useEffect(() => {
    fetchAndSetBasicTypeAheadItems();
  }, []);

  const fetchAndSetBasicTypeAheadItems = async () => {
    let _basicTypeAheadItems = await doGetBasicIssueInfo(projectId, boardId);
    _basicTypeAheadItems = _basicTypeAheadItems.reduce((acc, item) => {
      const sakIssue = item.type === "TASK" ? "Aksjon" : "Sak";
      if (viewPoints.find((vp) => vp._id === item._id) == null) {
        acc.push({
          displayValue: `${sakIssue} ${item.id}: ${item.title}`,
          ...item,
        });
      }

      return acc;
    }, []);
    setBasicTypeAheadItems(_basicTypeAheadItems);
  };

  const openAddIssueModalWithGisPoint = (gisImage: string, gisObject: IGisPointAndCamera) => {
    setClickedGis(gisObject);
    setGisImage(gisImage);
    setAddIssueModalOpen(true);
  };

  const openConnectIssueModalWithGis = (gisImage: string, gisObject: IGisPointAndCamera) => {
    setClickedGis(gisObject);
    setGisImage(gisImage);
    setConnectIssueModalOpen(true);
  };

  const closeAddIssueModal = () => {
    setAddIssueModalOpen(false);
    setClickedGis(null);
  };

  const onCreateIssue = async (issue: IIssue) => {
    setAddIssueModalOpen(false);
    setClickedGis(null);
    openIssue(issue._id);
  };

  const connectToGis = (issueId: string, boardId: string) => {
    setConnectIssueModalOpen(false);
    setClickedGis(null);
    updateIssue({
      projectId: projectId,
      issueBoardId: boardId,
      issueId: issueId,
      attr: "gis",
      value: clickedGis,
      oldValue: null,
      gisImage: gisImage,
    });
    openIssue(issueId);
  };

  const zoomToGisPointOrMoveCameraToPosition = (viewPointId: string) => {
    const viewpoint = viewPoints.find((vp) => vp._id === viewPointId);

    if (viewpoint?.gis?.gisPoint?.x != null) {
      setZoomToGisPoint(viewpoint.gis.gisPoint);
    }
    if (viewpoint?.gis?.gisCamera?.position?.x != null) {
      setCameraPosition(viewpoint.gis.gisCamera);
    }
    setSelectedVpId(viewPointId);
    setTimeout(() => {
      setCameraPosition(null);
      setZoomToGisPoint(null);
    }, 100);
  };

  const deleteViewPoint = (_id: string) => {
    const issue = issues.find((_issue) => _issue._id === _id);
    updateIssue({
      projectId: projectId,
      issueBoardId: boardId,
      issueId: _id,
      attr: "gis",
      value: null,
      oldValue: JSON.stringify(issue.gis),
    });
    dispatch(removeAppModalInRedux(_id));
  };

  const history = useHistory();
  const openIssue = (_id: string) => {
    history.push(getIssueUrlPath(history.location.pathname, _id));
    dispatch(
      addAppModalThunk({
        itemId: _id,
        boardId: boardId,
        projectId: projectId,
        app: AppModalEnums.ISSUE,
      }),
    );
  };

  const handleSelectViewPoint = (viewPointId: string) => {
    setSelectedVpId(viewPointId);
  };

  return (
    <>
      {mapId == null && <Alert color="danger">Kart-id ikke spesifisert</Alert>}
      {mapId != null && (
        <>
          {connectIssueModalOpen && basicTypeAheadItems != null && (
            <ConnectItemToGisModal
              items={basicTypeAheadItems}
              close={() => setConnectIssueModalOpen(false)}
              connectItemToGis={(basicItem: IBasicItem) => connectToGis(basicItem._id, basicItem.boardId)}
              placeholder="Skriv inn ID eller søk etter saker eller aksjoner"
              typeOfItemToLink="Sak/Aksjon"
              itemIdsToShow={issues.map((issue) => issue._id)}
            />
          )}
          {addIssueModalOpen === true && (
            <AddIssueModal
              toggle={closeAddIssueModal}
              isOpen={addIssueModalOpen}
              issueBoardId={boardId}
              gis={clickedGis}
              onCreateItem={onCreateIssue}
              gisImage={gisImage}
            />
          )}
          <div className="d-flex h-100 w-100">
            <ArcGisMap
              clickedGis={clickedGis}
              label="Sak/Aksjon"
              setclickedGis={setClickedGis}
              selectedVpId={selectedVpId}
              mapId={mapId}
              createItem={(gisImage, gisObject: IGisPointAndCamera) =>
                openAddIssueModalWithGisPoint(gisImage, gisObject)
              }
              connectItem={(gisImage, gisObject: IGisPointAndCamera) =>
                openConnectIssueModalWithGis(gisImage, gisObject)
              }
              viewPoints={viewPoints}
              onDoubleClickItem={openIssue}
              onSingleClickItem={handleSelectViewPoint}
              zoomToGisPoint={zoomToGisPoint}
              cameraPosition={cameraPosition}
              onLoaded={() => console.log("loaded")}
            />
            <Viewpoints
              viewPoints={viewPoints}
              selectedViewPointId={selectedVpId}
              onDeleteViewPoint={deleteViewPoint}
              onClickOnRow={(_id) => zoomToGisPointOrMoveCameraToPosition(_id)}
              onClickOnOpen={openIssue}
            />
          </div>
        </>
      )}
    </>
  );
};

export default IssuesGis;
