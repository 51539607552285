import React from "react";
import { Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";
import { ScheduleSelector } from "../../../../../components";
import { IMCBIMAPI } from "../../../../../../../types/mcbim.types";

const McBimScheduler: React.FC<{
  disabled: boolean;
  onSave: (jobEnabled: boolean, jobInterval: string) => void;
  mcBimProject: IMCBIMAPI.Project;
}> = ({ disabled, onSave, mcBimProject }) => {
  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Planlegg synkronisering</CardTitle>
        <CardSubtitle tag="h6" className="mb-2 text-muted">
          Planlegge hvor ofte synkronisering skal kjøres
        </CardSubtitle>
        <div className="d-flex">
          <ScheduleSelector
            scheduleActive={mcBimProject.jobEnabled === true && disabled === false}
            onSave={onSave}
            expression={mcBimProject?.jobInterval ?? ""}
            disabled={disabled}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default McBimScheduler;
