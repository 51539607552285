import { IPNSProps } from "../../../../types/global.types";
import { HierarchyNode } from "../../app/hierarchyNodeUtils";
import { ValidationResult } from "../ExcelImportValidator/ExcelImportValidator";

export const createTree = (
  list: ValidationResult,
  pnsIdColumn: number,
  titleColumn: number,
): HierarchyNode<IPNSProps> => {
  if (list.validatedData.length === 0) {
    return null;
  }

  const pnsColumnIndex = pnsIdColumn > titleColumn ? 1 : 0;
  const titleColumnIndex = pnsIdColumn > titleColumn ? 0 : 1;

  // calculate the length of the shortest pnsId in list and check if there are more than one of that length
  let shortestFoudLength = Number.MAX_VALUE;
  const arrayWithShortestLength = [];

  list.validatedData.forEach((item) => {
    const pnsId = String(item[pnsColumnIndex].value);
    const length = pnsId.split(".").length;

    if (length < shortestFoudLength) {
      shortestFoudLength = length;
      arrayWithShortestLength.length = 0;
    } else if (length === shortestFoudLength) {
      arrayWithShortestLength.push(pnsId);
    }
  });

  let rootNode: HierarchyNode<IPNSProps> = null;

  if (arrayWithShortestLength.length > 1) {
    rootNode = new HierarchyNode<IPNSProps>({
      _id: null,
      parent: null,
      children: [],
      props: {
        pnsId: "root",
        title: "root",
      },
    });
  } else {
    // assume that the array is sorted and the first element is the root
    const pnsId = String(list.validatedData[0][pnsColumnIndex].value);
    const title = String(list.validatedData[0][titleColumnIndex].value);

    rootNode = new HierarchyNode<IPNSProps>({
      _id: null,
      parent: null,
      children: [],
      props: {
        pnsId: pnsId,
        title: title,
      },
    });
  }

  if (list.validatedData.length === 1) {
    return rootNode;
  }

  for (let i = arrayWithShortestLength.length > 1 ? 0 : 1; i < list.validatedData.length; i++) {
    const cells = list.validatedData[i];
    const rowPns = String(cells[pnsColumnIndex].value);
    const rowTitle = String(cells[titleColumnIndex].value);

    const currentNode = new HierarchyNode<IPNSProps>({
      _id: null,
      parent: null,
      children: [],
      props: {
        pnsId: rowPns,
        title: rowTitle,
      },
    });

    if (rootNode == null) {
      rootNode = currentNode;
    }

    const parentNode = getParentNode(rootNode, currentNode);

    if (parentNode == null) {
      rootNode.addChild(currentNode);
    } else {
      parentNode.addChild(currentNode);
    }
  }

  return rootNode;
};

const getParentNode = (
  parentNode: HierarchyNode<IPNSProps>,
  currentNode: HierarchyNode<IPNSProps>,
  level: number = 0,
): HierarchyNode<IPNSProps> => {
  const pnsPartsLength = currentNode.props.pnsId.split(".").length;

  if (pnsPartsLength === level + 1 && currentNode.props.pnsId.startsWith(parentNode.props.pnsId)) {
    return parentNode;
  }

  for (const child of parentNode.children) {
    const found = getParentNode(child, currentNode, level + 1);

    if (found != null) {
      return found;
    }
  }

  return null;
};
