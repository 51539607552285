import classnames from "classnames";
import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import { setActiveRiskoTabRedux } from "./actions";
import RisikoBoardSelector from "./RisikoBoardSelector";
import { useAppDispatch, useAppSelector } from "../../../hooks";

const RisikoTabs: React.FC = () => {
  const dispatch = useAppDispatch();
  const activeTab = useAppSelector((state) => state.risikoReducer.activeTab);

  return (
    <div>
      <Nav tabs>
        <NavItem className="custom">
          <NavLink
            className={classnames({ active: activeTab === "DASHBOARD", custom: true })}
            onClick={() => dispatch(setActiveRiskoTabRedux("DASHBOARD"))}
          >
            Dashboard
          </NavLink>
        </NavItem>
        <NavItem className="custom">
          <NavLink
            className={classnames({ active: activeTab === "RISIKOS", custom: true })}
            onClick={() => dispatch(setActiveRiskoTabRedux("RISIKOS"))}
          >
            Risikoer
          </NavLink>
        </NavItem>
        <NavItem className="custom">
          <NavLink
            className={classnames({ active: activeTab === "TILTAK", custom: true })}
            onClick={() => dispatch(setActiveRiskoTabRedux("TILTAK"))}
          >
            Tiltak
          </NavLink>
        </NavItem>
        <NavItem className="custom">
          <NavLink
            className={classnames({ active: activeTab === "STATUS", custom: true })}
            onClick={() => dispatch(setActiveRiskoTabRedux("STATUS"))}
          >
            Statuslogger
          </NavLink>
        </NavItem>
        <NavItem className="custom">
          <NavLink
            className={classnames({ active: activeTab === "SETTINGS", custom: true })}
            onClick={() => dispatch(setActiveRiskoTabRedux("SETTINGS"))}
          >
            Innstillinger
          </NavLink>
        </NavItem>
        <div className="flex-fill" />
        <RisikoBoardSelector />
      </Nav>
    </div>
  );
};

export default RisikoTabs;
