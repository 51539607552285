import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { HSpace } from "../../../../../components";
import { ActionIcon, IssueIcon } from "../../../../../components/CustomIcons/CustomIcons";
import { AppModalEnums } from "../../../../../frontendConstants";
import { addAppModalThunk, removeAppModalInRedux } from "../../../../../global/globalSlice";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { useGetProjectQuery } from "../../../../projectRTK";
import { useGetIssueBoardsQuery, useGetSingleIssueBoardQuery } from "../../IssueBoardRTK";
import { useDoGetSingleIssueQuery } from "../../issueRTK";
import { getIssueRegisterNameOption } from "../../issueUtils";
import IssueItem from "./IssueItem/IssueItem";

const IssueItemModal: React.FC<{
  itemId: string;
  issueBoardId: string;
  projectId: string;
}> = ({ itemId, issueBoardId, projectId }) => {
  const user = useAppSelector((state) => state.globalReducer.user);

  const { data, isLoading: issueLoading } = useDoGetSingleIssueQuery({
    issueId: itemId,
    projectId: projectId,
  });

  const { data: issueBoard } = useGetSingleIssueBoardQuery({
    projectId: projectId,
    issueBoardId: issueBoardId,
  });

  const dispatch = useAppDispatch();

  const { data: project, isLoading: projectLoading } = useGetProjectQuery(projectId);

  const { data: issueBoards, isLoading: issueBoardsLoading } = useGetIssueBoardsQuery({ projectId: projectId });

  const isLoading = issueLoading || projectLoading || issueBoardsLoading;

  const history = useHistory();

  const closeIssueItemModal = () => {
    history.push(`${history.location.pathname}`);
    dispatch(removeAppModalInRedux(data?._id));
  };

  return (
    <Modal fade={false} style={{ maxWidth: "1000px", width: "80%" }} isOpen toggle={closeIssueItemModal}>
      {data != null && isLoading === false && (
        <>
          {data.taskOrIssue === "ISSUE" && (
            <ModalHeader toggle={closeIssueItemModal}>
              <IssueIcon style={{ marginRight: "6px", marginBottom: "3px" }} />
              {getIssueRegisterNameOption(issueBoard?.issueNameSetting, "singular")} {data.id}: {data.title}
            </ModalHeader>
          )}
          {data.taskOrIssue === "TASK" && (
            <ModalHeader toggle={closeIssueItemModal}>
              <ActionIcon style={{ marginRight: "2px", marginBottom: "4px", fontSize: "1.2em", color: "#b6b6b6" }} />
              {issueBoard?.taskNameSetting} {data.id}: {data.title}
            </ModalHeader>
          )}
          <ModalBody>
            {data.taskOrIssue === "TASK" && (
              <div className="d-flex text-center align-content-center">
                <h4 className="gray-600-text mt-1">
                  Del av {getIssueRegisterNameOption(issueBoard?.issueNameSetting, "singular")?.toLowerCase()}:
                </h4>
                <HSpace />
                <Button
                  style={{ marginTop: "1px" }}
                  className="p-0"
                  color="link"
                  onClick={() =>
                    dispatch(
                      addAppModalThunk({
                        itemId: data.connectedIssue._id,
                        boardId: issueBoardId,
                        projectId: projectId,
                        app: AppModalEnums.ISSUE,
                      }),
                    )
                  }
                >
                  <h4 className="primary-text" style={{ marginBottom: "4px" }}>
                    <IssueIcon className="mr-1" style={{ fontSize: ".8em", marginBottom: "4px" }} />
                    {data.connectedIssue.id}
                  </h4>
                </Button>
                <h4 className="gray-600-text mt-1 text-truncate" style={{ maxWidth: "15ch", marginLeft: "4px" }}>
                  {" "}
                  {data.connectedIssue?.title}
                </h4>
                <hr />
              </div>
            )}
            {issueBoard != null && (
              <IssueItem
                members={project.members}
                projectId={projectId}
                issue={data}
                user={user}
                issueBoard={issueBoard}
                issueOrTask={data.taskOrIssue}
                issueBoards={issueBoards}
              />
            )}
          </ModalBody>
        </>
      )}
    </Modal>
  );
};

export default IssueItemModal;
