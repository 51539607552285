import React from "react";
import MCBimOverview from "../../../../components/MCBimOverview/MCBimOverview";
import { MCLoader } from "../../../../components";
import { IMcBimMongo } from "../../../../../../types/mcbimMono.types";
import { doGetMcBimProjectInfo } from "./McBimMongoAPI";
import { useLocation } from "react-router-dom";
import { getProjectIdFromUrl } from "../../../utils";
import { useGetProjectQuery } from "../../../projectRTK";

const McBimOverviewPage: React.FC = () => {
  const [projectInfo, setProjectInfo] = React.useState<IMcBimMongo.ProjectInfo | null>(null);

  const location = useLocation();
  const projectId = getProjectIdFromUrl(location.pathname);

  const { data: project } = useGetProjectQuery(projectId);
  React.useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const _projectInfo = await doGetMcBimProjectInfo(project._id);
    setProjectInfo(_projectInfo);
  };

  return (
    <div>
      {projectInfo != null ? (
        <MCBimOverview data={projectInfo} projectNo={project.maconomy.projectNo.toString()} />
      ) : (
        <MCLoader loading />
      )}
    </div>
  );
};

export default McBimOverviewPage;
