import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import Switch from "react-switch";
import { Button, Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import { IMember, IUserActiveItem } from "../../../../../../types/global.types";
import { AG_GRID_LOCALE_NOR } from "../../../../agGridLocale";
import { Avatar, Checkbox } from "../../../../components";
import ExcelExport from "../../../../components/ExcelExport/ExcelExport";
import MemberCard from "../../../../components/MemberCard/MemberCard";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { loadProject } from "../../../actions";
import { MEMBER_GROUPS } from "../../../globalConstants";
import { doGetProjectMembers, doUpdateMemberIsPassive } from "../../../projectApi";
import { sortArrayByDate } from "../../../utils";
import { DeleteConfirmDepricated } from "../../DeleteConfirmDepricated";
import { doDeleteMemberFromProject } from "../../issuesApp/issueAPIDepricated";
import { doGetActiveAssignedItemsDepricated } from "../../projects/restQueries";
import { DISCIPLINER } from "../../start/constants";
import { getEmailDomain } from "../selectors";
import DisciplineSelector from "./DisciplineSelector";
import ProjectGroupSelector from "./ProjectGroupSelector";
import { selectUserMember, setShowSuccessMessageThunk } from "../../../../global/globalSlice";

export interface IMemberWithDomain extends IMember {
  domain: string;
}
const MembersTable: React.FC<{ members: IMember[]; projectId: string; showSettings?: boolean }> = ({
  members,
  projectId,
  showSettings = true,
}) => {
  const [editMode, setEditMode] = React.useState<boolean>(false);
  const [showAllMembers, setShowAllMembers] = React.useState<boolean>(false);
  const [memberFormatted, setMemberFormatted] = useState<IMemberWithDomain[]>([]);
  const [hasBeenUpdated, setHasBeenUpdated] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedMember, setSelectedMember] = useState<IMemberWithDomain>(null);
  const [assignedItems, setAssignedItems] = useState<IUserActiveItem[]>(null);
  const [filterText, setFilterText] = useState<string>("");
  const dispatch = useAppDispatch();

  const project = useAppSelector((state) => state.adminReducer.project);
  const user = useAppSelector((state) => state.globalReducer.user);
  const loggedInUserMember = useAppSelector(selectUserMember);
  const history = useHistory();

  const deleteMember = async (memberId: string) => {
    await doDeleteMemberFromProject(projectId, memberId);
    dispatch(loadProject(projectId));
    dispatch(setShowSuccessMessageThunk("Medlem fjernet"));
  };

  const toggleMemberIsPassive = async (memberId: string, isPassive) => {
    const memberFormattedUpdated = memberFormatted.map((member) =>
      member._id === memberId ? { ...member, isPassive } : member,
    );
    setMemberFormatted(memberFormattedUpdated);
    setHasBeenUpdated(true);
    doUpdateMemberIsPassive(projectId, memberId, isPassive);
  };

  const getDisciplines = useMemo(() => {
    const projectDisciplines = project.disciplines == null ? [] : project.disciplines;
    if (project.useDefaultDisciplines === true) {
      return [...DISCIPLINER, ...projectDisciplines];
    } else {
      return projectDisciplines;
    }
  }, [project]);

  const formatMembers = (members: IMember[]) => {
    const _membersFormatted = members
      .filter((member) => member.user != null)
      .map((member) => {
        const domain = getEmailDomain(member.user && member.user.email);
        return {
          domain: domain.substr(1),
          ...member,
        };
      });

    return _membersFormatted;
  };

  const fetchAndSetAssignedItems = async (member: IMember) => {
    const _assignedItems = await doGetActiveAssignedItemsDepricated(projectId, member.user._id);
    setAssignedItems(sortArrayByDate(_assignedItems, "createdAt"));
  };

  const getandFormatAllMembers = async () => {
    const allMembers = await doGetProjectMembers(projectId);
    setMemberFormatted(formatMembers(allMembers));
  };

  useEffect(() => {
    const activeMembers = members.filter((member) => member.isPassive !== true);
    if (editMode === true || showAllMembers === true) {
      getandFormatAllMembers();
    } else {
      memberFormatted.length < 1
        ? setMemberFormatted(formatMembers(activeMembers))
        : setMemberFormatted(memberFormatted.filter((member) => member.isPassive !== true));
    }
  }, [editMode, showAllMembers]);

  React.useEffect(() => {
    const unlisten = history.listen(() => {
      if (hasBeenUpdated === true) {
        dispatch(loadProject(projectId));
      }
    });
    return () => {
      unlisten();
    };
  }, [history, hasBeenUpdated]);

  const handleOnClick = (member: IMemberWithDomain) => {
    fetchAndSetAssignedItems(member);
    setSelectedMember(member);
    setShowModal(true);
  };

  const defaultColDef = {
    suppressHeaderMenuButton: true,
    minWidth: 120,
    flex: 1,
    resizable: true,
    cellStyle: {
      whiteSpace: "normal",
      lineHeight: "1.5",
      wordBreak: "break-word",
    },
  };

  const columns: ColDef<IMemberWithDomain>[] = [
    {
      field: "isPassive",
      headerName: "",
      maxWidth: 80,
      hide: editMode === false,
      cellRenderer: (params) => {
        const isExternal = params.data.user.email.endsWith("multiconsult.no") === false;
        return (
          <div className="d-flex" style={{ transform: "scale(0.9)", margin: "-8px" }}>
            <Switch
              onColor="#00a76d"
              onChange={() => toggleMemberIsPassive(params.data._id, !params.data.isPassive)}
              checked={params.data.isPassive !== true}
            />
            {loggedInUserMember.group === "ADMIN" && isExternal && (
              <>
                <DeleteConfirmDepricated id={params.data._id} text="" deleteCb={() => deleteMember(params.data._id)} />
              </>
            )}
          </div>
        );
      },
    },
    {
      field: "_id",
      headerName: "memberId",
      hide: true,
    },
    {
      field: "user._id",
      headerName: "id",
      hide: true,
    },
    {
      field: "user.name",
      flex: 2,
      filter: "agTextColumnFilter",
      minWidth: 160,
      menuTabs: ["filterMenuTab"],
      headerName: "Navn",
      cellRenderer: (params) => {
        return (
          <div className="d-flex pointer hoverText align-items-center" onClick={() => handleOnClick(params.data)}>
            {params.data.user && <Avatar id={params.data.user._id} />}
            <div className="two-line-text-truncate">{params.value}</div>
            {params.data.isPassive && (
              <span className="badge badge-danger ml-2 my-1 d-flex align-items-center">Passiv</span>
            )}
          </div>
        );
      },
    },
    {
      field: "user.email",
      headerName: "Epost",
    },
    {
      field: "user.phoneNumber",
      headerName: "Tlf",
    },
    {
      field: "domain",
      headerName: "Domene",
      filter: true,
    },
    {
      field: "group",
      headerName: "Gruppe",
      cellClass: "grid-display-block",
      autoHeight: true,
      filter: true,
      hide: isUserMemberAdminOrInternal(loggedInUserMember) === false,
      cellRenderer: (params) => {
        const cell = params.value;
        const row = params.data;
        const memberGroup = MEMBER_GROUPS.find((member) => member.value === cell);
        if ((loggedInUserMember && loggedInUserMember.group !== "ADMIN") || user.email === row.user.email) {
          return (
            <span className="text-secondary" style={{ padding: ".5rem 1rem", fontWeight: 400 }}>
              {memberGroup && memberGroup.label}
            </span>
          );
        }
        return <ProjectGroupSelector memberId={row._id} group={cell} />;
      },
    },
    {
      field: "disciplin",
      headerName: "Disiplin",
      cellClass: "grid-display-block",
      autoHeight: true,
      filter: true,
      cellRenderer: (params) => {
        const cell = params.value;
        const row = params.data;
        return <DisciplineSelector memberId={row._id} disciplines={getDisciplines} discipline={cell} />;
      },
    },
  ];
  const getRowStyle = (params) => {
    if (params.data?.isPassive === true) {
      return { opacity: "0.4" };
    }
  };
  return (
    <>
      {memberFormatted != null && columns != null && (
        <div className="d-flex flex-column border h-100">
          {showSettings === true && (
            <div className="d-flex p-1">
              <Input
                type="text"
                placeholder="Søk"
                className="mr-2"
                onChange={(e) => setFilterText(e.target.value)}
                style={{ maxWidth: "300px" }}
              />
              <Button color="secondary" onClick={() => setShowAllMembers(!showAllMembers)}>
                <Checkbox
                  label="Vis passiv"
                  isChecked={showAllMembers}
                  setChecked={(event) => {
                    event.preventDefault();
                  }}
                  size="small"
                  className="flex-row-reverse"
                />
              </Button>
              <div className="flex-fill"></div>

              <div className="mr-2">
                {loggedInUserMember.group === "ADMIN" && (
                  <Button color="secondary" onClick={() => setEditMode(!editMode)}>
                    <i className="fa fa-gear fa-lg" />
                  </Button>
                )}
              </div>
              <ExcelExport
                columns={columns.filter((col, index) => index > 1)}
                data={memberFormatted}
                filePrefix="Prosjektmedlemmer"
                isAgGrid={true}
              />
            </div>
          )}
          <div className="ag-theme-quartz h-100" id="myGrid">
            <AgGridReact
              cellSelection={true}
              rowData={memberFormatted}
              getRowId={(p) => p.data._id}
              columnDefs={columns}
              defaultColDef={defaultColDef}
              animateRows={true}
              suppressContextMenu={true}
              localeText={AG_GRID_LOCALE_NOR}
              pagination={true}
              paginationPageSize={50}
              rowBuffer={50}
              getRowStyle={getRowStyle}
              quickFilterText={filterText}
            />
          </div>
          <Modal
            fade={false}
            isOpen={selectedMember != null && showModal === true}
            toggle={() => setShowModal(!showModal)}
            style={{ maxWidth: "550px" }}
          >
            <ModalHeader toggle={() => setShowModal(!showModal)}>
              <div className="modal-title">Prosjektmedlem</div>
            </ModalHeader>
            <ModalBody>
              <MemberCard member={selectedMember} assignedItems={assignedItems} projectId={projectId} />
            </ModalBody>
          </Modal>
        </div>
      )}
    </>
  );
};

export default MembersTable;

const isUserMemberAdminOrInternal = (member: IMember) => {
  if (member == null) {
    return false;
  }
  return member.group === "ADMIN" || member.group === "INTERNAL";
};
