import { useState } from "react";
import { Button } from "reactstrap";
import { McInput } from "../../../components";
import { doAddAdminUserToProject } from "../../projectApi";
import { useAppDispatch } from "../../../hooks";
import { setShowSuccessMessageThunk } from "../../../global/globalSlice";

export const AddUserToProject = () => {
  const dispatch = useAppDispatch();
  const [userId, setUserId] = useState<string>("");
  const [projectId, setProjectId] = useState<string>("");

  const save = async () => {
    await doAddAdminUserToProject(userId, projectId);
    dispatch(setShowSuccessMessageThunk("User added!"));
  };

  return (
    <>
      <div>
        <div>User id</div>
        <McInput
          style={{ width: "100%" }}
          type="text"
          value={userId}
          onChange={(e) => setUserId(e.target.value)}
          name="text"
        />
        <div>Project id</div>
        <McInput
          style={{ width: "100%" }}
          type="text"
          value={projectId}
          onChange={(e) => setProjectId(e.target.value)}
          name="text"
        />
        <div className="d-flex align-items-center mt-2">
          <Button disabled={userId === "" || projectId === ""} color="success" onClick={save}>
            Save
          </Button>
        </div>
      </div>
    </>
  );
};
