import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import { CellEditFormatter } from "../columnGlobal";
import { IPermissionRows } from "./types";

const permissionFormatter = (cell: boolean) => {
  if (cell === true) {
    return <i className="fa fa-check text-success" aria-hidden="true" />;
  } else if (cell === false) {
    return <i className="fa fa-times text-danger" aria-hidden="true" />;
  }
};

export const BIM360DirectoryAccessTableColumns = [
  {
    dataField: "folderName",
    text: "Folder",
    sort: true,
    formatter: CellEditFormatter,
  },
  {
    dataField: "path",
    text: "Path",
    sort: true,
    formatter: CellEditFormatter,
  },
  {
    dataField: "permissions.view",
    text: "View",
    sort: true,
    formatter: (cell) => permissionFormatter(cell),
  },
  {
    dataField: "permissions.publish",
    text: "Publish",
    sort: true,
    formatter: (cell) => permissionFormatter(cell),
  },
  {
    dataField: "permissions.download",
    text: "Download",
    sort: true,
    formatter: (cell) => permissionFormatter(cell),
  },
  {
    dataField: "permissions.collaborate",
    text: "Collaborate",
    sort: true,
    formatter: (cell) => permissionFormatter(cell),
  },
  {
    dataField: "permissions.control",
    text: "Control",
    sort: true,
    formatter: (cell) => permissionFormatter(cell),
  },
  {
    dataField: "permissions.edit",
    text: "Edit",
    sort: true,
    formatter: (cell) => permissionFormatter(cell),
  },
];

const BIM360DirectoryAccessTable: React.FC<{ permissionRows: IPermissionRows[] }> = ({ permissionRows }) => {
  return (
    <>
      <BootstrapTable
        bootstrap4
        keyField="index"
        hover
        data={permissionRows || []}
        columns={BIM360DirectoryAccessTableColumns}
        bordered={false}
        filter={filterFactory()}
      />
    </>
  );
};

export default BIM360DirectoryAccessTable;
