import React from "react";
import { GridLoader } from "react-spinners";
import { VSpace } from "..";

const MCLoader: React.FC<{ loading?: boolean; text?: string; size?: number }> = ({
  loading = true,
  text,
  size = 10,
}) => (
  <>
    <div className="d-flex align-items-center">
      <GridLoader loading={loading} color={"#f8992e"} size={size} />
      {text != null && (
        <>
          <VSpace />
          <div>{text}</div>
        </>
      )}
    </div>
  </>
);

export default MCLoader;
