import { IssueViewEnum } from "mc-shared/enums/enums";
import React, { useEffect } from "react";
import { Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";
import { IBoard } from "../../../../../../types/global.types";
import { EditSaveAbort, FailedAlert, McDropdown, McInput, MCLoader, McTitle, VSpace } from "../../../../components";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { useUpdateIssueBoardSettingsMutation } from "../IssueBoardRTK";
import {
  ISSUE_ACTION_NAME_OPTIONS,
  ISSUE_BOARD_STATUSES,
  ISSUE_DASHBOARD_LS_KEY,
  ISSUE_DASHBOARD_OPTIONS,
  ISSUE_REGISTER_NAME_OPTIONS,
  ISSUE_VIEWS,
  YES_NO,
} from "../issueConstants";
import { setActiveDashboardInRedux } from "../issueSlice";

const IssueCommonSettings: React.FC<{ issueBoard: IBoard }> = ({ issueBoard }) => {
  const [editMode, setEditMode] = React.useState(false);
  const [name, setName] = React.useState<string>("");
  const [status, setStatus] = React.useState<string>("");
  const [showConclusion, setShowConclusion] = React.useState<boolean>(false);
  const [defaultView, setDefaultView] = React.useState<IssueViewEnum>(null);
  const [defaultTemplate, setDefaultTemplate] = React.useState<string>("");
  const [issueNameSetting, setIssueNameSetting] = React.useState<string>("");
  const [taskNameSetting, setTaskNameSetting] = React.useState<string>("");
  const [dashboardSetting, setDashboardSetting] = React.useState<string>("");
  const [category, setCategory] = React.useState<string>("");
  const [description, setDescription] = React.useState<string>("");
  const [bucketNameSetting, setBucketNameSetting] = React.useState<string>("");
  const [showCompletedTodosInKanban, setCompletedTodosInKanban] = React.useState<boolean>(false);

  const project = useAppSelector((state) => state.adminReducer.project);
  const dispatch = useAppDispatch();
  const [updateIssueBoard, { isLoading: updateIssueBoardLoading, error: updateBoardError }] =
    useUpdateIssueBoardSettingsMutation();
  const error = updateBoardError;
  const isLoading = updateIssueBoardLoading;
  const tableTemplates = issueBoard.tableTemplates;
  const activeTableTemplate = tableTemplates.find((template) => template._id === issueBoard.defaultTemplate);

  useEffect(() => {
    setName(issueBoard.name);
    setStatus(issueBoard.status);
    setShowConclusion(issueBoard.showConclusion);
    setDefaultView(issueBoard.defaultView);
    setDefaultTemplate(issueBoard.defaultTemplate);
    setIssueNameSetting(issueBoard.issueNameSetting);
    setTaskNameSetting(issueBoard.taskNameSetting);
    setDashboardSetting(issueBoard.dashboardSetting);
    setCategory(issueBoard.category);
    setDescription(issueBoard.description);
    setBucketNameSetting(issueBoard.bucketNameSetting);
    setCompletedTodosInKanban(issueBoard.showCompletedTodosInKanban);
  }, [issueBoard]);

  const _save = async () => {
    await updateIssueBoard({
      projectId: project._id,
      issueBoardId: issueBoard._id,
      name: name,
      status: status,
      showConclusion: showConclusion,
      defaultView: defaultView,
      defaultTemplateId: defaultTemplate,
      issueNameSetting: issueNameSetting,
      taskNameSetting: taskNameSetting,
      dashboardSetting: dashboardSetting,
      category: category,
      description: description,
      bucketNameSetting: bucketNameSetting,
      showCompletedTodosInKanban: showCompletedTodosInKanban,
    });
    setEditMode(false);
  };

  const defaultViewIsValid = ISSUE_VIEWS.map((item) => item.key).includes(defaultView);

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h3">Innstillinger</CardTitle>
        {isLoading === true && <MCLoader loading={isLoading} />}
        {error != null && <FailedAlert />}
        {error == null && (
          <>
            <CardSubtitle tag="h6" className="text-muted" />
            <VSpace />
            <div style={{ width: "300px" }}>
              <McTitle title="Navn">
                {editMode === true ? (
                  <McInput title="Navn" type="text" value={name} onBlur={(e) => setName(e.target.value)} />
                ) : (
                  <p className="mb-0">{name}</p>
                )}
              </McTitle>
            </div>
            <VSpace />
            <div style={{ width: "300px" }}>
              <McTitle title="Status">
                {editMode === true ? (
                  <McDropdown
                    currentValue={ISSUE_BOARD_STATUSES.find((item) => item.value === status)}
                    options={ISSUE_BOARD_STATUSES}
                    onChange={(_, newValue) => setStatus(newValue.value)}
                    displayKey="label"
                  />
                ) : (
                  <p className="mb-0">{ISSUE_BOARD_STATUSES.find((item) => item.value === status)?.label}</p>
                )}
              </McTitle>
            </div>
            <VSpace />
            <div style={{ width: "300px" }}>
              <McTitle title="Vis konklusjon kun hvis status fullført">
                {editMode === true ? (
                  <McDropdown
                    currentValue={YES_NO.find((x) => x.value === showConclusion) || YES_NO[1]}
                    options={YES_NO}
                    onChange={(_, newValue) => setShowConclusion(newValue.value)}
                    displayKey="label"
                  />
                ) : (
                  <p className="mb-0">{showConclusion === true ? YES_NO[0].label : YES_NO[1].label}</p>
                )}
              </McTitle>
            </div>
            <VSpace />
            <div>
              <McTitle title="Standardvisning">
                {editMode === true ? (
                  <div style={{ width: "300px" }}>
                    <McDropdown
                      currentValue={ISSUE_VIEWS.find((item) => item.key === defaultView)}
                      options={ISSUE_VIEWS}
                      onChange={(_, newValue) => setDefaultView(newValue.key)}
                      displayKey="label"
                    />
                  </div>
                ) : (
                  <p className="mb-0">
                    {defaultViewIsValid ? ISSUE_VIEWS.find((item) => item.key === defaultView)?.label : "Ikke satt"}
                  </p>
                )}
              </McTitle>
            </div>
            <VSpace />
            <div>
              <McTitle title="Standardmal">
                {editMode === true ? (
                  <div style={{ width: "300px" }}>
                    <McDropdown
                      currentValue={tableTemplates.find((f) => f._id === defaultTemplate)}
                      options={tableTemplates}
                      onChange={(_, newValue) => setDefaultTemplate(newValue._id)}
                      displayKey="name"
                      onClear={defaultTemplate != null ? () => setDefaultTemplate(null) : null}
                    />
                  </div>
                ) : (
                  <p className="mb-0">
                    {tableTemplates.find((f) => f._id === activeTableTemplate?._id)?.name ?? "Ikke satt"}
                  </p>
                )}
              </McTitle>
            </div>
            <VSpace />
            <div style={{ width: "300px" }}>
              <McTitle title="Navn på hovednivå">
                {editMode === true ? (
                  <div style={{ width: "300px" }}>
                    <McDropdown
                      currentValue={issueNameSetting}
                      options={ISSUE_REGISTER_NAME_OPTIONS.map((item) => item.type)}
                      onChange={(_, newValue) => setIssueNameSetting(newValue)}
                      displayKey="singular"
                    />
                  </div>
                ) : (
                  <p className="mb-0">{issueBoard.issueNameSetting}</p>
                )}
              </McTitle>
            </div>
            <VSpace />
            <div style={{ width: "300px" }}>
              <McTitle title="Navn på undernivå">
                {editMode === true ? (
                  <div style={{ width: "300px" }}>
                    <McDropdown
                      currentValue={taskNameSetting}
                      options={ISSUE_ACTION_NAME_OPTIONS.map((item) => item.singular)}
                      onChange={(_, newValue) => setTaskNameSetting(newValue)}
                      displayKey="singular"
                    />
                  </div>
                ) : (
                  <p className="mb-0">{issueBoard.taskNameSetting}</p>
                )}
              </McTitle>
            </div>
            <VSpace />
            <div style={{ width: "300px" }}>
              <McTitle title="Velg standard dashboard">
                {editMode === true ? (
                  <div style={{ width: "300px" }}>
                    <McDropdown
                      currentValue={dashboardSetting}
                      options={ISSUE_DASHBOARD_OPTIONS}
                      onChange={(_, newValue) => {
                        localStorage.removeItem(ISSUE_DASHBOARD_LS_KEY);
                        setDashboardSetting(newValue);
                        dispatch(setActiveDashboardInRedux(newValue));
                      }}
                    />
                  </div>
                ) : (
                  <p className="mb-0">{issueBoard.dashboardSetting}</p>
                )}
              </McTitle>
            </div>
            <VSpace />
            <div style={{ width: "300px" }}>
              <McTitle title="Type register">
                {editMode === true ? (
                  <McInput
                    title="Typ register"
                    type="text"
                    value={category}
                    onBlur={(e) => setCategory(e.target.value)}
                  />
                ) : (
                  <p className="mb-0">{issueBoard.category}</p>
                )}
              </McTitle>
            </div>
            <VSpace />
            <div style={{ width: "300px" }}>
              <McTitle title="Navn på bucket">
                {editMode === true ? (
                  <McInput
                    title="Navn"
                    type="text"
                    value={bucketNameSetting}
                    onBlur={(e) => setBucketNameSetting(e.target.value)}
                  />
                ) : (
                  <p className="mb-0">{issueBoard.bucketNameSetting}</p>
                )}
              </McTitle>
            </div>
            <VSpace />
            <div style={{ width: "300px" }}>
              <McTitle title="Vis fullførte sjekklistepunkter i kanban">
                {editMode === true ? (
                  <McDropdown
                    currentValue={YES_NO.find((x) => x.value === showCompletedTodosInKanban) || YES_NO[1]}
                    options={YES_NO}
                    onChange={(_, newValue) => setCompletedTodosInKanban(newValue.value)}
                    displayKey="label"
                  />
                ) : (
                  <p className="mb-0">{showCompletedTodosInKanban === true ? YES_NO[0].label : YES_NO[1].label}</p>
                )}
              </McTitle>
            </div>
            <VSpace />
            <div style={{ width: "600px" }}>
              <McTitle title="Beskrivelse">
                {editMode === true ? (
                  <McInput
                    value={description}
                    type="textarea"
                    placeholder="Skriv inn beskrivelse"
                    dynamicHeight={true}
                    onBlur={(e) => setDescription(e.target.value)}
                  />
                ) : (
                  <p className="mb-0">{issueBoard.description !== "" ? issueBoard.description : "Ingen beskrivelse"}</p>
                )}
              </McTitle>
            </div>
            <VSpace />
            <EditSaveAbort
              disabled={false}
              editable
              big
              saveCb={_save}
              editMode={editMode}
              editModeCb={(bool) => setEditMode(bool)}
            />
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default IssueCommonSettings;
