import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { IChecklistPopulated } from "../../../../../types/checklist.types";
import { McDropdownBase } from "../../../components";
import { useAppSelector } from "../../../hooks";
import { useGetProjectChecklistsQuery } from "./checklistRTK";
import { ChecklistStatusEnum } from "./constants";

const ChecklistSelector: React.FC = () => {
  const { projectChecklistTemplates, checklist, allFilters, selectedPnsFilterItem } = useAppSelector(
    (state) => state.checklistReducer,
  );
  const project = useAppSelector((state) => state.adminReducer.project);
  const [list, setList] = useState<IChecklistPopulated[]>([]);

  const history = useHistory();

  const { data: projectChecklists = [] } = useGetProjectChecklistsQuery({
    projectId: project._id,
    allFilters: allFilters,
    pns: selectedPnsFilterItem,
  });

  const goToChecklist = (checklistId: string) => {
    history.push(`/project/${project._id}/checklist/instance/${checklistId}`);
  };

  const goToChecklistSelection = () => {
    history.push(`/project/${project._id}/checklist`);
  };

  useEffect(() => {
    let checklists = checklist.isTemplate === true ? projectChecklistTemplates : projectChecklists;

    const filteredLists = checklists?.filter(
      (checklist) =>
        checklist.status !== ChecklistStatusEnum.REMOVED &&
        checklist.status !== ChecklistStatusEnum.DELIVERED &&
        checklist.templateType !== "MC",
    );

    setList(filteredLists);
  }, [projectChecklistTemplates]);

  return (
    <McDropdownBase>
      <McDropdownBase.Display>
        <div>{checklist.name}</div>
      </McDropdownBase.Display>
      {list.map((cl) => (
        <McDropdownBase.Option key={cl._id} selected={cl?._id === checklist?._id} onClick={() => goToChecklist(cl._id)}>
          <div className="truncateHeaderText">{cl.name}</div>
        </McDropdownBase.Option>
      ))}
      {list.length > 0 && <McDropdownBase.Option divider key="__mc_divider" />}
      <McDropdownBase.Option key="__mc_settings" onClick={() => goToChecklistSelection()}>
        <i className="fa fa-caret-square-o-left fa-fw" />
        Gå til Utvalg
      </McDropdownBase.Option>
    </McDropdownBase>
  );
};

export default ChecklistSelector;
