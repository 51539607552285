import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  LexicalTypeaheadMenuPlugin,
  MenuOption,
  MenuTextMatch,
  useBasicTypeaheadTriggerMatch,
} from "@lexical/react/LexicalTypeaheadMenuPlugin";
import { $getNodeByKey, TextNode } from "lexical";
import { useCallback, useEffect, useMemo, useState } from "react";
import * as ReactDOM from "react-dom";
import { $createMentionNode, MentionNode } from "../nodes/mentionNode";
import { IMember } from "../../../../../types/global.types";

const PUNCTUATION = "\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%'\"~=<>_:;";
const NAME = "\\b[A-Z][^\\s" + PUNCTUATION + "]";

const DocumentMentionsRegex = {
  NAME,
  PUNCTUATION,
};

const CapitalizedNameMentionsRegex = new RegExp("(^|[^#])((?:" + DocumentMentionsRegex.NAME + "{" + 1 + ",})$)");

const PUNC = DocumentMentionsRegex.PUNCTUATION;

const TRIGGERS = ["@"].join("");

// Chars we expect to see in a mention (non-space, non-punctuation).
const VALID_CHARS = "[^" + TRIGGERS + PUNC + "\\s]";

// Non-standard series of chars. Each series must be preceded and followed by
// a valid char.
const VALID_JOINS =
  "(?:" +
  "\\.[ |$]|" + // E.g. "r. " in "Mr. Smith"
  " |" + // E.g. " " in "Josh Duck"
  "[" +
  PUNC +
  "]|" + // E.g. "-' in "Salier-Hellendag"
  ")";

const LENGTH_LIMIT = 75;

const AtSignMentionsRegex = new RegExp(
  "(^|\\s|\\()(" + "[" + TRIGGERS + "]" + "((?:" + VALID_CHARS + VALID_JOINS + "){0," + LENGTH_LIMIT + "})" + ")$",
);

// 50 is the longest alias length limit.
const ALIAS_LENGTH_LIMIT = 50;

// Regex used to match alias.
const AtSignMentionsRegexAliasRegex = new RegExp(
  "(^|\\s|\\()(" + "[" + TRIGGERS + "]" + "((?:" + VALID_CHARS + "){0," + ALIAS_LENGTH_LIMIT + "})" + ")$",
);

// At most, 5 suggestions are shown in the popup.
const SUGGESTION_LIST_LENGTH_LIMIT = 5;

const mentionsCache = new Map();

function customLookupService(mentions: IMember[], searchString: string): Promise<IMember[]> {
  return new Promise((resolve) => {
    setTimeout(() => {
      const filteredMentions = mentions?.filter((mention) =>
        mention.user?.name?.toLowerCase().includes(searchString.toLowerCase()),
      );
      resolve(filteredMentions);
    }, 500);
  });
}

const useMentionLookupService = (mentionData: IMember[]) => {
  const [results, setResults] = useState<IMember[]>([]);

  const searchMentions = useCallback(
    async (mentionString: string | null) => {
      if (mentionString === null) {
        setResults([]);
        return;
      }

      const cachedResults = mentionsCache.get(mentionString);
      if (cachedResults) {
        setResults(cachedResults);
        return;
      }

      const filteredMentions = await customLookupService(mentionData, mentionString);
      mentionsCache.set(mentionString, filteredMentions);
      setResults(filteredMentions);
    },
    [mentionData],
  );

  return { results, searchMentions };
};

function checkForCapitalizedNameMentions(text: string, minMatchLength: number): MenuTextMatch | null {
  const match = CapitalizedNameMentionsRegex.exec(text);
  if (match !== null) {
    // The strategy ignores leading whitespace but we need to know it's
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1];

    const matchingString = match[2];
    if (matchingString != null && matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: matchingString,
      };
    }
  }
  return null;
}

function checkForAtSignMentions(text: string, minMatchLength: number): MenuTextMatch | null {
  let match = AtSignMentionsRegex.exec(text);

  if (match === null) {
    match = AtSignMentionsRegexAliasRegex.exec(text);
  }
  if (match !== null) {
    // The strategy ignores leading whitespace but we need to know it's
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1];

    const matchingString = match[3];
    if (matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: match[2],
      };
    }
  }
  return null;
}

function getPossibleQueryMatch(text: string): MenuTextMatch | null {
  const match = checkForAtSignMentions(text, 1);
  return match === null ? checkForCapitalizedNameMentions(text, 3) : match;
}

class MentionTypeaheadOption extends MenuOption {
  name: string;
  picture: JSX.Element;
  _id: string;

  constructor(name: string, picture: JSX.Element, id: string) {
    super(name);
    this.name = name;
    this.picture = picture;
    this._id = id;
  }
}

function MentionsTypeaheadMenuItem({
  index,
  isSelected,
  onClick,
  onMouseEnter,
  option,
}: {
  index: number;
  isSelected: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  option: any;
}) {
  let className = "dropdown-item";
  if (isSelected) {
    className += " active";
  }
  return (
    <li
      key={option.key}
      tabIndex={-1}
      className={className}
      ref={option.setRefElement}
      role="option"
      aria-selected={isSelected}
      id={"typeahead-item-" + index}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
    >
      {/* {option.picture} */}
      <span className="text">{option.name}</span>
    </li>
  );
}

export default function NewMentionsPlugin({ handleMentions, members }): JSX.Element | null {
  const [editor] = useLexicalComposerContext();
  const [mentionNodeList, setMentionNodeList] = useState<any[]>([]);
  const [queryString, setQueryString] = useState<string | null>(null);

  const { results, searchMentions } = useMentionLookupService(members);
  useEffect(() => {
    const metadataArray = mentionNodeList.map((node) => node.mentionNode.__metadata);
    handleMentions(metadataArray);
  }, [mentionNodeList]);

  useEffect(() => {
    searchMentions(queryString);
  }, [queryString, searchMentions]);

  useEffect(() => {
    if (!editor.hasNodes([MentionNode])) {
      throw new Error("MentionsPlugin: MentionNode not registered on editor");
    }

    return editor.registerMutationListener(MentionNode, (nodeMutations) => {
      //checks mentions in editor, and adds/removes them from mentionIdArray
      for (const [nodeKey, mutation] of nodeMutations) {
        if (mutation === "created") {
          editor.update(() => {
            const mentionNode = $getNodeByKey(nodeKey);
            setMentionNodeList([...mentionNodeList, { nodeKey: nodeKey, mentionNode: mentionNode }]);
          });
        } else if (mutation === "destroyed") {
          const updatedNodeList = mentionNodeList.filter((node) => node.nodeKey !== nodeKey);
          setMentionNodeList(updatedNodeList);
        }
      }
    });
  }, [mentionNodeList, editor]);

  const checkForSlashTriggerMatch = useBasicTypeaheadTriggerMatch("/", {
    minLength: 0,
  });

  const options = useMemo(() => results.map((result) => result.user).slice(0, SUGGESTION_LIST_LENGTH_LIMIT), [results]);
  const onSelectOption = useCallback(
    (selectedOption: MentionTypeaheadOption, nodeToReplace: TextNode | null, closeMenu: () => void) => {
      editor.update(() => {
        const mentionNode = $createMentionNode(selectedOption.name, selectedOption._id);
        if (nodeToReplace) {
          nodeToReplace.replace(mentionNode);
        }
        mentionNode.select();
        closeMenu();
      });
    },
    [editor],
  );

  const checkForMentionMatch = useCallback(
    (text: string) => {
      const slashMatch = checkForSlashTriggerMatch(text, editor);
      if (slashMatch !== null) {
        return null;
      }
      return getPossibleQueryMatch(text);
    },
    [checkForSlashTriggerMatch, editor],
  );

  return (
    <LexicalTypeaheadMenuPlugin<any>
      onQueryChange={setQueryString}
      onSelectOption={onSelectOption}
      triggerFn={checkForMentionMatch}
      options={options}
      menuRenderFn={(anchorElementRef, { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }) =>
        anchorElementRef.current && results.length
          ? ReactDOM.createPortal(
              <ul className="typeahead-popover">
                {options.map((option, i: number) => (
                  <MentionsTypeaheadMenuItem
                    index={i}
                    isSelected={selectedIndex === i}
                    onClick={() => {
                      setHighlightedIndex(i);
                      selectOptionAndCleanUp(option);
                    }}
                    onMouseEnter={() => {
                      setHighlightedIndex(i);
                    }}
                    key={option._id}
                    option={option}
                  />
                ))}
              </ul>,
              anchorElementRef.current,
            )
          : null
      }
    />
  );
}
