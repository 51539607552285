import React from "react";
import { Alert } from "reactstrap";
import { ConfirmDialog, DeleteButton, VSpace } from "../../../../components";
import { ToastMessagesEnums } from "../../../../frontendConstants";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { doDeleteReportFile, doReportFileUpload } from "../reportBoardAPI";
import { fetchAndSetSingleReportThunk } from "../reportSlice";
import { setShowSuccessMessageThunk } from "../../../../global/globalSlice";

const ReportDocxImageContainer: React.FC<{ docxImageTag: string; title: string }> = ({ docxImageTag, title }) => {
  const files = useAppSelector((state) => state.reportReducer.activeReport?.uploadedFiles);
  const image = files?.find((file) => file.docxImageTag === docxImageTag);
  const report = useAppSelector((state) => state.reportReducer.activeReport);
  const path = `/api/reportimage/${report?._id}/${image?._id}?key=${Math.floor(Math.random() * 100000) + 1}`;
  const project = useAppSelector((state) => state.adminReducer.project);

  const dispatch = useAppDispatch();

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    await doReportFileUpload(file, project?._id, report?._id, docxImageTag);
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.FILE_UPLOADED));
    dispatch(fetchAndSetSingleReportThunk(report._id));
  };

  const deleteImage = async () => {
    await doDeleteReportFile(project._id, report._id, image?._id);
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.FILE_DELETED));
    dispatch(fetchAndSetSingleReportThunk(report._id));
  };

  return (
    <div className="d-flex">
      {project == null && <Alert color="info">Filopplasting er ikke tilgjengelig i admin-modus</Alert>}
      {project != null && (
        <div>
          {image == null ? (
            <div>
              <h5 className="p-0 m-0">{title}</h5>
              <p>Last opp et bilde</p>
              <input type="file" accept="image/*" onChange={handleFileChange} />
            </div>
          ) : (
            <div className="flex-column">
              <div className="d-flex">
                <h5 className="p-0 m-0 mt-2">{title}</h5>
                <div className="flex-grow-1" />
                <ConfirmDialog
                  confirmCb={deleteImage}
                  message="Vil du slette bildet? Dette kan ikke angres!"
                  title="Slette bildet?"
                >
                  <DeleteButton onClick={deleteImage} outline />
                </ConfirmDialog>
              </div>
              <VSpace />
              <img style={{ height: "500px" }} alt="" src={path} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ReportDocxImageContainer;
