import React, { useState } from "react";
import McTypeahead from "../McTypeahead/McTypeahead";
import { IMcBimMongo } from "../../../../types/mcbimMono.types";

const SpaceTypeAhead: React.FC<{
  value: string;
  mcbimSpaces: IMcBimMongo.SpaceBasicInfo[];
  onChange: (newValue) => void;
  onSpaceTypeAheadFocus: () => void;
}> = ({ value, onChange, mcbimSpaces, onSpaceTypeAheadFocus }) => {
  const [typeAheadSpaceItems, setTypeAheadSpaceItems] = useState([]);

  const getComboLabel = (space: IMcBimMongo.SpaceBasicInfo) => {
    if (space.extraProp != null) {
      return `${space.name}_${space.extraProp}_${space.longName} `;
    } else {
      return `${space.name}_${space.longName} `;
    }
  };

  React.useEffect(() => {
    const _typeAheadItems: ISpaceTypeAheadProps[] = mcbimSpaces?.map((space) => ({
      ...space,
      comboLabel: getComboLabel(space),
    }));
    setTypeAheadSpaceItems(_typeAheadItems);
  }, [mcbimSpaces?.length]);

  React.useEffect(() => {
    if (mcbimSpaces != null) {
      setLoading(false);
    }
  }, [mcbimSpaces?.length]);

  const onTypeaheadChange = (changedValue: string) => {
    onChange(changedValue);
  };

  const val: ISpaceTypeAheadProps = {
    id: 0,
    longName: "",
    name: "",
    comboLabel: value ?? "",
  };

  const [loading, setLoading] = useState(false);

  const onFocus = () => {
    if (mcbimSpaces == null) {
      setLoading(true);
    }
    onSpaceTypeAheadFocus();
  };

  return (
    <McTypeahead
      id="spacetypeahead"
      clearButton={value !== "" && value != null}
      labelKey="comboLabel"
      options={typeAheadSpaceItems ?? []}
      defaultSelected={[val]}
      onChange={(items) => {
        onTypeaheadChange(items[0]?.comboLabel);
      }}
      isLoading={loading}
      allowNew
      onFocus={onFocus}
    />
  );
};

export default SpaceTypeAhead;

interface ISpaceTypeAheadProps extends IMcBimMongo.SpaceBasicInfo {
  comboLabel: string;
}
