import React, { useEffect } from "react";
import { useAppDispatch } from "../../../hooks";
import MCNavbar from "../../MCNavbar";
import { resetProjectInRedux } from "../../actions";
import UserItemsInProject from "../projectdashboard/ActiveAssignedItems/UserItemsInProject";

export const MyTasksPage: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetProjectInRedux());
  }, []);

  return (
    <>
      <MCNavbar isStartPage />
      <div className="p-3 h-100 d-flex flex-column pb-8">
        <UserItemsInProject app={"mytaskspage"} />
      </div>
    </>
  );
};
