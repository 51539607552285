import React, { ErrorInfo } from "react";
import { Alert } from "reactstrap";
import { doLogError } from "../app/routes/admin/restQueries";

interface IState {
  hasError: boolean;
}

class LocalErrorBoundary extends React.Component<{}, IState> {
  constructor(props: {}) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  errorHappend = async (errorMessage: string, errorStack: string) => {
    await doLogError(errorMessage, errorStack, window.location.href);
  };

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({
      hasError: true,
    });

    this.errorHappend(error.message, `${info.componentStack}\n\n${error.stack}`);
  }

  render() {
    return this.state.hasError === true ? (
      <Alert color="danger" style={{ fontSize: "0.8em" }}>
        <div>
          <b>Det oppstod en uventet feil!</b>
        </div>
        <div>Utviklingsteamet er kontaktet.</div>{" "}
      </Alert>
    ) : (
      this.props.children
    );
  }
}

export default LocalErrorBoundary;
