import { useEffect, useMemo, useState } from "react";
import { Button } from "reactstrap";
import { Checkbox } from "../";
import McDropdown from "../McDropdown/McDropdown";
import "./ScheduleSelector.css";

interface IScheduleSelector {
  expression?: string;
  onSave: (jobEnabled: boolean, jobInterval: string) => void;
  scheduleActive: boolean;
  disabled: boolean;
}

interface ICronParts {
  time: string;
  dayOfWeek: number;
  daily: boolean;
}

function ScheduleSelector({ expression, onSave, scheduleActive, disabled }: IScheduleSelector) {
  const dayList = useMemo(() => {
    return [
      { id: 1, name: "Mandag" },
      { id: 2, name: "Tirsdag" },
      { id: 3, name: "Onsdag" },
      { id: 4, name: "Torsdag" },
      { id: 5, name: "Fredag" },
      { id: 6, name: "Lørdag" },
      { id: 0, name: "Søndag" },
    ];
  }, []);

  const hourList = useMemo(() => {
    return Array.from({ length: 24 }, (_, i) => (i++).toString().padStart(2, "0"));
  }, []);

  const generateCronExpression = ({ time, dayOfWeek, daily }: ICronParts): string => {
    let _time = time;
    let _dayOfWeek = dayOfWeek;

    if (/^([0-1][0-9]|2[0-3]):[0-5][0-9]$/.test(time) === false) {
      console.warn("Invalid time format");
      _time = "00:00";
    }

    if (_dayOfWeek < 0 || _dayOfWeek > 6) {
      console.warn("Invalid day of week");
      _dayOfWeek = 1;
    }

    const [hours, minutes] = _time.split(":");
    const dayOfWeekField = daily === true ? "*" : _dayOfWeek;

    return `${minutes} ${hours} * * ${dayOfWeekField}`;
  };

  const parseCronExpression = (cronExpression: string): ICronParts => {
    if (cronExpression == null) {
      return null;
    }

    const parts = cronExpression.split(" ");

    if (parts.length !== 5) {
      console.warn("Invalid cron expression");
      return {
        time: "00:00",
        dayOfWeek: 1,
        daily: false,
      };
    }

    const minutesPart = parts[0] !== "*" ? parts[0] : "00";
    const hoursPart = parts[1] !== "*" ? parts[1] : "00";
    const dayOfWeekPart = parts[4];

    const daily = dayOfWeekPart === "*";
    const time = `${hoursPart.padStart(2, "0")}:${minutesPart.padStart(2, "0")}`;
    const dayOfWeek = daily === true ? 1 : parseInt(dayOfWeekPart, 10);

    return {
      time,
      dayOfWeek,
      daily,
    };
  };

  const [cronParts, setCronParts] = useState<ICronParts>(null);

  useEffect(() => {
    const parts = parseCronExpression(expression);

    if (parts == null) {
      return;
    }

    setDaily(parts.daily);
    setDayOfWeek(parts.dayOfWeek);
    setTime(parts.time);
    setCronParts(parts);
  }, [expression]);

  const updateCronExpression = (parts: ICronParts) => {
    const cronExpression = generateCronExpression(parts);
    onSave(jobEnabled, cronExpression);
  };

  const saveDisabled = () => {
    return (
      scheduleActive === jobEnabled &&
      cronParts?.daily === daily &&
      cronParts?.dayOfWeek === dayOfWeek &&
      cronParts?.time === time
    );
  };

  const [jobEnabled, setJobEnabled] = useState<boolean>(null);
  const [daily, setDaily] = useState<boolean>(false);
  const [dayOfWeek, setDayOfWeek] = useState<number>(1);
  const [time, setTime] = useState<string>("00:00");

  useEffect(() => {
    setJobEnabled(scheduleActive);
  }, [scheduleActive]);

  return (
    <div className="d-flex">
      <div className="d-flex flex-column mr-2">
        <h5>Aktiv</h5>
        <div className="d-flex h-100">
          <Checkbox
            isChecked={jobEnabled}
            setChecked={() => setJobEnabled(jobEnabled === false)}
            disabled={disabled === true}
          />
        </div>
      </div>
      <div className="d-flex flex-column mr-2">
        <h5>Frekvens</h5>
        <div className="d-flex h-100">
          <McDropdown
            disabled={disabled === true || jobEnabled === false}
            currentValue={daily === false ? "Hver uke" : "Hver dag"}
            options={["Hver dag", "Hver uke"]}
            displayKey="name"
            onChange={(_, newValue) => setDaily(newValue === "Hver dag")}
          />
        </div>
      </div>
      {daily === false && (
        <div className="d-flex flex-column mr-2" style={{ width: "10rem" }}>
          <h5>Dag</h5>
          <McDropdown
            disabled={disabled === true || jobEnabled === false}
            currentValue={dayList.find((day) => day.id === dayOfWeek)}
            options={dayList}
            displayKey="name"
            onChange={(_, newValue) => setDayOfWeek(newValue.id)}
          />
        </div>
      )}
      <div className="d-flex flex-column mr-2">
        <h5>Time</h5>
        <McDropdown
          disabled={disabled === true || jobEnabled === false}
          currentValue={time?.split(":")[0] ?? "00"}
          options={hourList}
          onChange={(_, newValue) => setTime(`${newValue}:00`)}
        />
      </div>
      <div className="d-flex align-items-end">
        <Button
          color="success"
          disabled={disabled === true || saveDisabled()}
          onClick={() => updateCronExpression({ time, dayOfWeek, daily })}
        >
          <i className="fa fa-save fa-fw" />
        </Button>
      </div>
    </div>
  );
}

export default ScheduleSelector;
