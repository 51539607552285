import moment from "moment";
import React, { useEffect, useState } from "react";
import { Alert, Button, UncontrolledTooltip } from "reactstrap";
import { ConfirmDialog, HSpace } from "..";
import { IComment } from "../../../../types/global.types";
import Avatar from "../Avatar";
import { SendIcon } from "../CustomIcons/CustomIcons";
import { IEditorMetadata } from "../MultiComment/MultiComment";
import RichTextEditor, {
  lexicalStateToPlainText,
  plainTextToLexicalState,
  RichTextReader,
} from "../RichTextEditor/RichTextEditor";
import ErrorBoundary from "../../util/ErrorBoundary";

const SingleComment: React.FC<{
  comment: IComment;
  editEnabled: boolean;
  deleteEnabled: boolean;
  onSave: (text: string, metadata: IEditorMetadata) => void;
  onDelete: () => void;
}> = ({ comment, editEnabled, deleteEnabled, onSave, onDelete }) => {
  const [editMode, setEditMode] = useState<Boolean>(false);

  return (
    <>
      <div className="d-flex w-100 mb-3">
        <Avatar id={comment.createdBy?._id} />
        <div className="flex-grow-1 mr-2">
          <h5 style={{ marginBottom: ".3em" }} className="gray-600-text commendCardHeader">
            <b className="mr-2">{comment.createdBy?.name}</b>
            <small>{moment(comment.createdDate || comment.createdAt).format("YYYY.MM.DD - HH:mm")}</small>
          </h5>
          <ErrorBoundary customComponent={<Alert color="danger">En feil skjedde! Melding kan ikke vises.</Alert>}>
            {editMode === false && <RichTextReader richTextTree={comment.text} />}
            <EditComment editMode={editMode} setEditMode={setEditMode} text={comment.text} onSave={onSave} />
            {editMode === false && (
              <>
                {deleteEnabled === true && (
                  <ConfirmDialog
                    confirmCb={() => onDelete()}
                    title="Slett kommentar"
                    message="Er du sikker på at du vil slette denne kommentar?"
                  >
                    <Button className="p-0 m-0" size="sm" color="outline">
                      <i className="fa fa-trash fa-fw" />
                    </Button>
                  </ConfirmDialog>
                )}
                {editEnabled === true && (
                  <span className="gray-600-text" style={{ cursor: "pointer" }} onClick={() => setEditMode(true)}>
                    <i className="fa fa-pencil fa-fw text-primary" />
                  </span>
                )}
              </>
            )}
          </ErrorBoundary>
        </div>
      </div>
    </>
  );
};

export default SingleComment;

const EditComment: React.FC<{
  editMode: Boolean;
  setEditMode: (bool: Boolean) => void;
  text: string;
  onSave: (text: string, metadata: IEditorMetadata) => void;
}> = ({ editMode, setEditMode, text, onSave }) => {
  const [editedText, setEditedText] = useState(null);
  const [editorMetadata, setEditorMetadata] = useState<IEditorMetadata>(null);
  const [hasImages, setHasImages] = useState(false);

  const abortUpdate = () => {
    setEditedText(null);
    setEditMode(false);
    setEditorMetadata(null);
  };

  const save = async () => {
    const plainText = await lexicalStateToPlainText(editedText);
    onSave(JSON.stringify(editedText), { ...editorMetadata, plainText: plainText });
    setEditMode(false);
    setEditorMetadata(null);
  };

  useEffect(() => {
    const formatText = async () => {
      const result = await plainTextToLexicalState(text);
      setEditedText(result);
    };
    if (editedText === null) {
      formatText();
    }
    setTimeout(() => {
      if (editedText === null) {
        formatText();
      }
    }, 100);
  }, [editedText]);

  const handleChange = (editorState, plainText, hasImages) => {
    setEditedText(editorState);
    setHasImages(hasImages);
  };
  return (
    <div className="d-flex p-0 w-100">
      {editMode === true && (
        <div className="d-flex flex-column w-100">
          <RichTextEditor
            richTextTree={editedText}
            handleMentions={(e) => setEditorMetadata({ ...editorMetadata, mentionIdArray: e })}
            onChange={handleChange}
          />

          <div className="d-flex mt-1">
            <Button
              className="d-flex align-items-center"
              id="saveButton"
              disabled={editedText.length < 1 && hasImages === false}
              onClick={() => save()}
              color="success"
              size="sm"
            >
              Send
              <SendIcon />
            </Button>
            <UncontrolledTooltip target="saveButton" delay={{ show: 350, hide: 100 }}>
              Lagre
            </UncontrolledTooltip>
            <HSpace />
            <Button id="closeButton" onClick={() => abortUpdate()} size="sm">
              <i className="fa fa-close fa-fw" />
            </Button>
            <UncontrolledTooltip target="closeButton" delay={{ show: 350, hide: 100 }}>
              Avbryt
            </UncontrolledTooltip>
          </div>
        </div>
      )}
    </div>
  );
};
